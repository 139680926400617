import React from 'react';
import CellFenToYuan from '@/vcomps/ShowCellMoney';
import { Switch } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import styles from './TableData.module.scss';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
  {
    title: '持卡人姓名',
    dataIndex: 'cardHolder',
    key: 'cardHolder',
    align: 'center',
    width: '10%',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '账户类型',
    dataIndex: 'accountTypeValue',
    key: 'accountTypeValue',
    align: 'center',
    width: '10%',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '银行名称',
    dataIndex: 'bankNameValue',
    key: 'bankNameValue',
    align: 'center',
    width: '10%',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '开户行',
    dataIndex: 'openAccountBank',
    key: 'openAccountBank',
    align: 'center',
    width: '10%',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '银行账号/支付宝',
    dataIndex: 'bankAccount',
    key: 'bankAccount',
    align: 'center',
    width: '20%',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '预留手机',
    dataIndex: 'mobile',
    key: 'mobile',
    align: 'center',
    width: '10%',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '设为默认',
    dataIndex: 'setDefault',
    key: 'setDefault',
    align: 'center',
    width: '10%',
    onCell: tooltipStyle,
    render: (text, item) => (SwitchCell(text, item, actionClickHandle))
  },
  {
    title: '操作',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: '10%',
    onCell: tooltipStyle,
    render: (id, item) => TableAction(id, item, actionClickHandle)
  },
];

export const takeDataSource = (data) => {
  // 解析表格数据，使符合columns格式要求
  // let tableSource = data.map((item)=>{

  //   return tableCell;
  // });
  return data;
};

const SwitchCell = (sw, item, actionClickHandle) => {
    let checked = true;
    if (sw == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={checked}
                onChange={() => {
                    actionClickHandle(sw, item, 'switch');
                }}
            />
        </div>
    );
};
const TableAction = (id, item, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, item, 'edit')}>编辑</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, item, 'unbind')}>解绑</div>
        </div>
    );
};
export const takeColumns = (actionClickHandle) => {
  return columns(actionClickHandle);
};

const TableCell = (text) => {
  return (
    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
      {text}
    </div>
  );
};
