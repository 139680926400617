import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

// 获取员工列表
export const getEmplList = function* () {
    yield takeEvery(T.GET_EMPL_LIST, function* requestData(action) {
        try {
            let result = yield http('/admin/empl/listEmpls', action.payload, 'POST');
            yield put({ type: T.SET_EMPL_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取员工详情
export const getEmplDetail = function* () {
    yield takeEvery(T.GET_EMPL_DETAIL, function* requestData(action) {
        try {
            const id = action.payload.id;
            let result = yield http('/admin/empl/getEmplInfo/' + id, action.payload, 'POST');
            yield put({ type: T.SET_EMPL_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取部门下拉列表
export const getDeptSelect = function* () {
    yield takeEvery(T.GET_DEPT_SELECT, function* requestData(action) {
        try {
            let result = yield http('/admin/dept/getDropDownlistDept', action.payload, 'POST');
            yield put({ type: T.SET_DEPT_SELECT, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取员工状态
export const getEmplState = function* () {
    yield takeEvery(T.GET_EMPL_STATE, function* requestData(action) {
        try {
            let result = yield http('/enum/StateEnum', action.payload, 'POST');
            yield put({ type: T.SET_EMPL_STATE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取员工所属分类
export const getEmplType = function* () {
    yield takeEvery(T.GET_EMPL_TYPE, function* requestData(action) {
        try {
            let result = yield http('/admin/company/listCompanyType', action.payload, 'POST');
            yield put({ type: T.SET_EMPL_TYPE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 未分配仓库信息
export const getPermissionNoAllots = function* () {
    yield takeEvery(T.GET_PERMISSION_NOALLOTS, function* requestData({ payload }) {
        try {
            let result = yield http('/emplwarehouse/listUncheckedEmplWarehouses', payload, 'POST');
            yield put({ type: T.SET_PERMISSION_NOALLOTS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 已分配仓库信息
export const getPermissionAlloedts = function* () {
    yield takeEvery(T.GET_PERMISSION_ALLOTEDS, function* requestData({ payload }) {
        try {
            let result = yield http('/emplwarehouse/listSelectedEmplWarehouses', payload, 'POST');
            yield put({ type: T.SET_PERMISSION_ALLOTEDS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取店铺下拉列表
export const getShopSelect = function* () {
    yield takeEvery(T.GET_SHOP_SELECT, function* requestData(action) {
        try {
            let result = yield http('/shop/getShopDropDownList', action.payload, 'POST');
            yield put({ type: T.SET_SHOP_SELECT, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};