import React from 'react';
import styles from './CostDateTable.module.scss';
import PropTypes from 'prop-types';
import { Table, } from 'antd';
import { tooltipStyle, tooltip, cellWidth, } from '@/components/TableCell';

class CostDateTable extends React.Component {

    state = {

    }

    columns = () => ([
        {
            title: '费用项',
            dataIndex: 'c1',
            key: 'c1',
            align: 'center',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '金额(元)',
            dataIndex: 'c2',
            key: 'c2',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
    ]);


    // 解析表格数据
    _takeDataSource = (data) => {
        if (data) {
            return data.map(item => {
                return item;
            });
        }
    }

    render() {
        const { renderData, } = this.props;

        return (
            <div className={styles.container}>
                <div className={styles.table_box}>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        rowKey={(record, index) => index}
                        columns={this.columns()}
                        dataSource={this._takeDataSource(renderData)}
                        pagination={false}
                        scroll={{ y: 350 }}
                    />
                </div>
            </div>
        );
    }
}

CostDateTable.propTypes = {
    renderData: PropTypes.array, // 渲染数据源(双向绑定)
};

export default CostDateTable;