/**
 * 合同协议弹框
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message } from 'antd';
import styles from './PDF.module.scss';

export default class PDF extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
        };
    }

    static propTypes = {
        visible: PropTypes.bool.isRequired, // 是否显示弹框
        closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
        modeParams: PropTypes.object.isRequired//数据
    };

    //确定取消
    _handleOk = () => {
        const { modeParams } = this.props;
        http('/goods/goodsrate/submitRateGoodsIsOK', { goodsRateId: modeParams.goodsRateId }, 'POST')
            .then(() => {
                message.success('确认成功。');
                this.setState({ visible: false });
                this.props.closeMode(true);
            }).catch((e) => {
                message.error(e.message);
            });
    }
    _handleCancel = () => {
        this.setState({ visible: false, });
        this.props.closeMode(false);
    }


    render() {
        const { modeParams } = this.props;
        const { visible, confirmLoading } = this.state;
        return (
            <Modal
                width={800}
                centered
                title='协议确认'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'>
                <div className={styles.container}>
                    <iframe src={modeParams.contractContent} width='100%' height='100%'></iframe>
                </div>
            </Modal>
        );
    }
}