import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Audit from './actiontypes';
//集采活动审核列表
export const getPurchasingList = function* () {
    yield takeEvery(Audit.GET_PURCHASING_LIST, function* requestData({ payload }) {
        try {
            let result = yield http('/marketing/activityMerchantInfo/collectiveActivityAuditList', payload, 'POST');
            yield put({ type: Audit.SET_PURCHASING_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//集采活动审核详情
export const getPurchasingDetail = function* () {
    yield takeEvery(Audit.GET_PURCHASING_DETAIL, function* requestData({ payload }) {
        try {
            let result = yield http('/marketing/activityMerchantInfo/auditDetailLog', payload, 'POST');
            yield put({ type: Audit.SET_PURCHASING_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//集采活动审核信息
export const getPurchasingAuditInfo = function* () {
    yield takeEvery(Audit.GET_PURCHASING_AUDITINDO, function* requestData({ payload }) {
        try {
            let result = yield http('/marketing/activityMerchantInfo/auditDetail', payload, 'POST');
            yield put({ type: Audit.SET_PURCHASING_AUDITINDO, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


