import React from 'react';
import styles from './OrderSearch.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message } from 'antd';
import moment from 'moment';
import { getProductOrderType, getProductOrderStatus, getProductOrderFlagEnum } from '@/reducers/ordermanage/productOrder/actions';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker } from '@/components/xqxc_ui';

// const orderStateSelect = [{id:1, name:'待付款'},{id:2, name:'待发款'}];
// const orderTypeSelect = [{id:1, name:'普通订单'},{id:2, name:'报税订单'}];
// const invoiceSelect = [{id:1, name:'是'},{id:0, name:'否'}];

class OrderSearch extends React.Component {

    state = {
        telephone: '', // 会员手机
        orderCode: '', // 订单编号
        orderStartTime: null, // 下单时间开始日期
        orderEndTime: null, // 下单结束日期
        orderState: {}, // 订单状态
        orderType: {}, // 订单类型
        invoice: {}, // 是否开票
        productName: '', // 商品名称
        productCode: '', // 商品货号
    }
    componentDidMount() {
        this.props.getProductOrderType();
        this.props.getProductOrderStatus();
        this.props.getProductOrderFlagEnum();
    }
    _renderOrderStateSelect = (orderState) => {
        const { productOrderStatus } = this.props;
        if(this.props.orderState == 1){
            return(
                <XSelect 
                    style={{marginLeft:'20px', width: '218px'}} 
                    selectStyle={{width: '150px'}}
                    label='订单状态' 
                    placeholder='请选择订单状态' 
                    renderData={productOrderStatus}
                    dataIndex='value'
                    keyIndex='code'
                    value={orderState.value}
                    showSearch={true}
                    bindThis={this}
                    bindName='orderState'
                />
            );
        }else{
            return null;
        }
    }

   // 查询
   _searchHandle = () => {
    const { telephone, orderCode, orderState, orderType, invoice, productName, productCode } = this.state;
    const startTime = this.state.orderStartTime;
    const endTime = this.state.orderEndTime;
    let orderStartTime = '';
    let orderEndTime = '';
    if (startTime) {
        orderStartTime = moment(moment(startTime).format()).format('YYYY-MM-DD HH:mm:ss');
    }
    if (endTime) {
        orderEndTime = moment(moment(endTime).format()).format('YYYY-MM-DD HH:mm:ss');
    }
    const statusList = orderState.code ? [orderState.code] : [];
    const isInvoice = invoice.code ? invoice.code : '';
    if (!orderStartTime && !orderEndTime) {
        this.props.searchOrder({
            accountTel: telephone, orderId: orderCode,
            goodsName: productName, isInvoice, itemNo: productCode, orderType: orderType.code, statusList
        });
    } else if (orderStartTime && orderEndTime) {
        this.props.searchOrder({
            accountTel: telephone, gmtCreateF: orderStartTime, gmtCreateT: orderEndTime, orderId: orderCode,
            goodsName: productName, isInvoice, itemNo: productCode, orderType: orderType.code, statusList
        });
    } else if (!orderStartTime || !orderEndTime) {
        message.error('时间必须输入完整！');
    }
    console.log(this.state);
}


    // 重置
    _resetHandle = () => {
        this.setState({
            telephone: '', 
            orderCode: '', 
            orderStartTime: null, 
            orderEndTime: null, 
            orderState: {}, 
            orderType: {}, 
            invoice: {}, 
            productName: '', 
            productCode: '', 
        });
        this.props.searchOrder({}, true);
    }

    render(){
        const { telephone, orderCode, orderStartTime, orderEndTime, orderState, orderType, invoice, productName, productCode } = this.state;
        const { productOrderType, productOrderFlagEnum } = this.props;
        return(
            <div className={styles.container}>
                <div className={styles.row1}>
                    <XInput 
                        style={{width:'248px'}}
                        inputStyle={{width:'180px'}} 
                        label='会员手机' 
                        placeholder='请输入手机号码' 
                        value={telephone}
                        bindThis={this}
                        bindName='telephone'
                    />
                    <XInput 
                        style={{width:'248px', marginLeft:'20px'}}
                        inputStyle={{width:'180px'}} 
                        label='订单编号' 
                        placeholder='请输入订单编号' 
                        value={orderCode}
                        bindThis={this}
                        bindName='orderCode'
                    />
                    <XDatePicker 
                        style={{width:'264px', marginLeft:'20px'}} 
                        label='下单时间' 
                        value={orderStartTime} 
                        bindThis={this}
                        bindName='orderStartTime'
                    />
                    <div className={styles.timeLine} />
                    <XDatePicker  
                        label=''
                        value={orderEndTime} 
                        bindThis={this}
                        bindName='orderEndTime'
                    />
                    {this._renderOrderStateSelect(orderState)}
                    <XSelect 
                        style={{marginLeft:'20px', width: '218px'}} 
                        selectStyle={{width: '150px'}}
                        label='订单类型' 
                        placeholder='请选择订单类型' 
                        renderData={productOrderType}
                        dataIndex='value'
                        keyIndex='code'
                        value={orderType.value}
                        showSearch={true}
                        bindThis={this}
                        bindName='orderType'
                    />
                </div>
                <div className={styles.row2}>
                    <XSelect 
                        style={{width: '248px'}} 
                        selectStyle={{width: '180px'}}
                        label='是否开票' 
                        placeholder='请选择是否开票' 
                        renderData={productOrderFlagEnum}
                        dataIndex='value'
                        keyIndex='code'
                        value={invoice.value}
                        showSearch={true}
                        bindThis={this}
                        bindName='invoice'
                    />
                    <XInput 
                        style={{width:'248px', marginLeft:'20px'}}
                        inputStyle={{width:'180px'}} 
                        label='商品名称' 
                        placeholder='请输入商品名称' 
                        value={productName}
                        bindThis={this}
                        bindName='productName'
                    />
                    <XInput 
                        style={{width:'248px', marginLeft:'20px'}}
                        inputStyle={{width:'180px'}} 
                        label='商品货号' 
                        placeholder='请输入商品货号' 
                        value={productCode}
                        bindThis={this}
                        bindName='productCode'
                    />
                    <XOKButton 
                        style={{marginLeft:'20px', width:'60px'}} 
                        label='查询'
                        onClick={this._searchHandle} 
                    />
                    <XCancelButton 
                        style={{marginLeft:'20px', width:'60px'}} 
                        label='重置' 
                        onClick={this._resetHandle} 
                    />
                </div>
            </div>
        );
    }
}

OrderSearch.propTypes = {
    orderState: PropTypes.number,
    searchOrder: PropTypes.func,
    getProductOrderType: PropTypes.func,
    getProductOrderStatus: PropTypes.func,
    getProductOrderFlagEnum: PropTypes.func,
    productOrderType: PropTypes.array,
    productOrderStatus: PropTypes.array,
    productOrderFlagEnum: PropTypes.array
};
const mapStateToProps = (state) => ({
    productOrderType: state.productOrder.productOrderType,
    productOrderStatus: state.productOrder.productOrderStatus,
    productOrderFlagEnum: state.productOrder.productOrderFlagEnum
});
export default connect(mapStateToProps, { getProductOrderType, getProductOrderStatus, getProductOrderFlagEnum })(OrderSearch);