/**
 * 判断用户身份
 * accountType：MT商户、WE仓库、SP店铺
 * warehouseType：GENERAL_WAREHOUSE普通仓、LUXURY奢批仓、EZOONET蚁购仓 FENLAI纷来仓库
 */
import { getLocalStorage } from '@/assets/js/storage';
import http from '@/assets/api/http';

// 判断用户身份是否商户管理员
export const isAdmin = () => {
    let boolean = false;
    if (getLocalStorage('auth')) {
        const adminType = JSON.parse(getLocalStorage('auth')).userInfo.adminType;
        if (adminType == '1') {
            boolean = true;
        }
    }
    return boolean;
};

// 判断用户身份是否商户供应链
export const isGY = () => {
    let boolean = false;
    if (getLocalStorage('auth')) {
        const merchantType = JSON.parse(getLocalStorage('auth')).userInfo.merchantType;
        if (merchantType == 'GY') {
            boolean = true;
        }
    }
    return boolean;
};

// 判断用户身份是否商户加盟商
export const isJM = () => {
    let boolean = false;
    if (getLocalStorage('auth')) {
        const merchantType = JSON.parse(getLocalStorage('auth')).userInfo.merchantType;
        if (merchantType == 'JM') {
            boolean = true;
        }
    }
    return boolean;
};

// 判断用户身份是否商户实体店
export const isEJ = () => {
    let boolean = false;
    if (getLocalStorage('auth')) {
        const merchantType = JSON.parse(getLocalStorage('auth')).userInfo.merchantType;
        if (merchantType == 'EJ') {
            boolean = true;
        }
    }
    return boolean;
};

//判断用户身份是否商户
export const isMT = () => {
    let boolean = false;
    if (getLocalStorage('auth')) {
        const accountType = JSON.parse(getLocalStorage('auth')).userInfo.accountType;
        if (accountType == 'MT') {
            boolean = true;
        }
    }
    return boolean;
};

//判断用户身份是否仓库
export const isWE = () => {
    let boolean = false;
    if (getLocalStorage('auth')) {
        const accountType = JSON.parse(getLocalStorage('auth')).userInfo.accountType;
        if (accountType == 'WE') {
            boolean = true;
        }
    }
    return boolean;
};

//判断用户身份是否蚁购仓库
export const isYG = () => {
    let boolean = false;
    if (getLocalStorage('auth')) {
        const warehouseType = JSON.parse(getLocalStorage('auth')).userInfo.warehouseType;
        const accountType = JSON.parse(getLocalStorage('auth')).userInfo.accountType;
        if (warehouseType == 'EZOONET' && accountType == 'WE') {
            boolean = true;
        }
    }
    return boolean;
};
//判断用户身份是否纷来仓库
export const isFenLai = () => {
    let boolean = false;
    if (getLocalStorage('auth', 'WE')) {
        const warehouseType = JSON.parse(getLocalStorage('auth', 'WE')).userInfo.warehouseType;
        const accountType = JSON.parse(getLocalStorage('auth', 'WE')).userInfo.accountType;
        if (warehouseType == 'FENLAI' && accountType == 'WE') {
            boolean = true;
        }
    }
    return boolean;
};

//判断用户身份是否候鸟仓库
export const isHouNiao = () => {
    let boolean = false;
    if (getLocalStorage('auth', 'WE')) {
        const warehouseType = JSON.parse(getLocalStorage('auth', 'WE')).userInfo.warehouseType;
        const accountType = JSON.parse(getLocalStorage('auth', 'WE')).userInfo.accountType;
        if (warehouseType == 'HOUNIAO' && accountType == 'WE') {
            boolean = true;
        }
    }
    return boolean;
};

// 判断用户身份是否酒店商户
export const isHOTEL = () => {
    let boolean = false;
    if (getLocalStorage('auth')) {
        const merchantType = JSON.parse(getLocalStorage('auth')).userInfo.merchantType;
        if (merchantType == 'HOTEL') {
            boolean = true;
        }
    }
    return boolean;
};


// 判断用户身份是否同城商户
export const isCity = () => {
    let boolean = false;
    if (getLocalStorage('auth')) {
        const merchantType = JSON.parse(getLocalStorage('auth')).userInfo.merchantType;
        if (merchantType == 'CE') {
            boolean = true;
        }
    }
    return boolean;
};

// 判断用户身份是否供应商
export const isSR = () => {
    let boolean = false;
    if (getLocalStorage('auth')) {
        const accountType = JSON.parse(getLocalStorage('auth')).userInfo.accountType;
        if (accountType == 'SR') {
            boolean = true;
        }
    }
    return boolean;
};

// 判断是否显示该用户的营销服务费信息,返回Promise对象
// 用法：
// getIsMarketingPromise().then((response)=>{
//     console.log(response); // false:隐藏营销服务费信息 true:营销服务费信息
// });
export const getIsMarketingPromise = async function () {
    const result = await requestIsMarketing();
    return result;
};

function requestIsMarketing() {
    return new Promise(function (resolve) {
        http('/marketing/merchantInfo', {}, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result && response.result.isMarketing) {
                    if (response.result.isMarketing.code == 1) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }
            }
        }).catch(() => {
            resolve(false);
        });
    });
}

// 判断是否显示GDC虚拟商品相关信息,返回Promise对象
// 用法：
// getIsGDCPromise().then((response)=>{
//     console.log(response); // false:隐藏GDC虚拟商品相关信息 true:显示GDC虚拟商品相关信息
// });
export const getIsGDCPromise = async function () {
    const result = await requestIsGDC();
    return result;
};

function requestIsGDC() {
    return new Promise(function (resolve) {
        const merchantId = JSON.parse(getLocalStorage('auth')).userInfo.merchantId;
        http('/goods/goods/isGDC/' + merchantId).then((res) => {
            resolve(!!(res || {}).result);
        }).catch(() => {
            resolve(false);
        });
    });
}

//获取登录账户配置信息
export function getConfigInfo() {
    const boolValue = (value) => {
        return value == 1 ? true : false;
    };
    return new Promise(function (resolve, reject) {
        http('/merchant/getConfig', {}, 'POST')
            .then((res) => {
                let configInfo = res.result.settleParamSetting;
                resolve({
                    ...configInfo,//saleServiceRatio销售服务费率，serviceRatio服务商服务费率，markupRatio分销加价比率
                    platService: boolValue(configInfo.platService),//是否收取平台服务费
                    technique: boolValue(configInfo.technique),//是否收取技术服务费
                    showService: boolValue(configInfo.showService),//是否收取秀场服务费
                    favorite: boolValue(configInfo.favorite),//是否收取心选服务费
                    starValueRatio: res.result.starValueRatio,//营销费用兑换人气值比率
                    isMarketing: boolValue(res.result.isMarketing),//是否收取营销服务费
                    isWhiteAudit: boolValue(res.result.isWhiteAudit)//是否限购
                });
            }).catch(err => {
                reject(err);
            });
    });
}