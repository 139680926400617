import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getPurchasingList } from '@/reducers/auditManage/purchasing/actions';

class Auditing extends Component {
    //初始数据
    constructor(props) {
        super(props);
        this.state = {
            activityTitle: '',
            goodsName: '',
            goodsNo: '',
            state: 'PENDING',
            productStatus: {},
            startTime: null,
            endTime: null
        };
    }
    //异步数据
    componentDidMount() {
    }
    static propTypes = {
        updateContent: PropTypes.func,
        getPurchasingList: PropTypes.func,
        purchasingList: PropTypes.object
    };
    // 查询触发
    _searchHandle = (useCache) => {
        const { goodsName, goodsNo, startTime, endTime, activityTitle, state, pageNum, pageSize } = this.state;
        this.searchCache = {
            goodsName, goodsNo, startTime, endTime, activityTitle, state,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getPurchasingList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 重置
    _resetHandle = () => {
        this.setState({ goodsName: '', goodsNo: '', startTime: null, endTime: null, activityTitle: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'audit':
                console.log(id, type, params);
                this.props.updateContent('audit', id, params);
                break;
        }
    }
    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        const { goodsName, goodsNo, startTime, endTime, activityTitle, state } = this.state;
        this.props.getPurchasingList({ goodsName, goodsNo, startTime, endTime, activityTitle, state });
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    render() {
        const { purchasingList } = this.props;
        const { goodsName, activityTitle, goodsNo, startTime, endTime } = this.state;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <XInput
                            style={{ width: '300px' }}
                            inputStyle={{ width: '200px' }}
                            label='集采活动'
                            placeholder='请输入集采活动'
                            bindThis={this}
                            bindName='activityTitle'
                            value={activityTitle}
                        />
                        <XInput
                            style={{ width: '300px' }}
                            inputStyle={{ width: '200px' }}
                            label='商品名称'
                            placeholder='请输入商品名称'
                            bindThis={this}
                            bindName='goodsName'
                            value={goodsName}
                        />
                        <XInput
                            style={{ width: '300px' }}
                            inputStyle={{ width: '200px' }}
                            label='商品货号'
                            placeholder='请输入商品货号'
                            bindThis={this}
                            bindName='goodsNo'
                            value={goodsNo}
                        />
                        <XDatePicker
                            style={{ marginLeft: '30px', marginRight: '15px' }}
                            label='活动时间'
                            bindThis={this}
                            bindName='startTime'
                            value={startTime}
                            isFormat={true}
                        />
                        <span>—</span>
                        <XDatePicker
                            style={{ marginLeft: '5px' }}
                            bindThis={this}
                            bindName='endTime'
                            value={endTime}
                            isFormat={true}
                        />
                        {/* <XSelect
                            style={{ marginLeft: '30px', width: '300px' }}
                            selectStyle={{ width: '200px' }}
                            label='状态'
                            placeholder='请选择'
                            renderData={illegalSele}
                            onChange={this._productStatusChange}
                            dataIndex='value'
                            keyIndex='code'
                            value={productStatus.merchantName}
                        /> */}
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    {/* <div className={styles.operate_box}>
                        <XOKButton style={{ width: '90px' }} label='导出Excel' />
                    </div> */}
                    <TableContent paginationChange={this._paginationChange} renderData={purchasingList} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}
const mapStateToProps = (state) => ({
    purchasingList: state.purchasingAudit.purchasingList,
});
export default connect(mapStateToProps, { getPurchasingList })(Auditing);