import { createModel } from '@/store/tools';

const dataList = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };
const model = {
    namespace: 'accountCustomer',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getlistMerchantKfInfo': T('getlistMerchantKfInfo'),
            'getMerchantKfInfo': T('getMerchantKfInfo'),
        },
        actions: {
            'getlistMerchantKfInfo': A('getlistMerchantKfInfo'),
            'getMerchantKfInfo': A('getMerchantKfInfo'),
        },
        sagas: {
            'getlistMerchantKfInfo': S('getlistMerchantKfInfo', '/merchantKfInfo/listMerchantKfInfo'),
            'getMerchantKfInfo': S('getMerchantKfInfo', '/merchantKfInfo/getMerchantKfInfo', 'POST', 'id'),
        },
        reducers: {
            'listMerchantKfInfo': R('getlistMerchantKfInfo', dataList),
            'MerchantKfInfo': R('getMerchantKfInfo', {}),
        }
    })
};
export default createModel(model);