/**
 * 打款验证-银联
 */
import React from 'react';
import styles from './step.module.scss';
import PropTypes from 'prop-types';
import { Form, Button, InputNumber, message } from 'antd';
import Ensure from './../components/Ensure';
import getUnionInfo from './../components/getUnionInfo';
import http from '@/assets/api/http';
import { regYuanToFen } from '@/util/money';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

const formItemLayout = {
    labelCol: {
        xs: { span: 9 },
        sm: { span: 9 },
    },
    wrapperCol: {
        xs: { span: 12 },
        sm: { span: 12 },
    },
};

class step extends React.Component {

    state = {
        required: true,
        checkMoneyBoolean: false, // 是否已经打款
        id: '', // 打款验证ID
        gmtCreate: '', // 上次打款时间
        captcha: '', // 打款数额
        alreadySetCaptcha: true, // 时间未到4小时，打款按钮隐藏
        loading: false, // 加载中
        show: false, // 显示界面，优化用户体验防止界面多次闪动
    }

    componentDidMount() {
        const oldtime = moment('2020-11-20 11:12:00').valueOf();
        const newtime = (new Date()).valueOf();
        const time = newtime - oldtime;
        const hour = parseFloat((time / 1000 / 60 / 60).toFixed(2));

        getUnionInfo().then((response) => {
            const { type, data } = response;
            if (data.companyAccountVerifyStatus == 1) {
                this.setState({ checkMoneyBoolean: true });
            } else {
                http('/union/merchant/companyAccountVerifyInfo').then((response) => {
                    if (response.status == 200) {
                        const { id, gmtCreate } = response.result;
                        let alreadySetCaptcha = true;
                        const oldtime = moment(gmtCreate).valueOf();
                        const newtime = (new Date()).valueOf();
                        const time = newtime - oldtime;
                        const hour = parseFloat((time / 1000 / 60 / 60).toFixed(2));
                        if ((hour > 4)) {
                            alreadySetCaptcha = false;
                        }
                        this.setState({ id, gmtCreate, alreadySetCaptcha });
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
            }
        });
    }

    // 下一步
    _handleSubmit = e => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const { captcha } = fieldsValue;
                this.setState({ loading: true });
                getUnionInfo().then((response) => {
                    const { type, data } = response;
                    if (data.companyAccountVerifyStatus == 1) {
                        this.setState({ loading: false });
                        this.props.next();
                    } else {
                        const id = this.state.id;
                        const transAmt = regYuanToFen(captcha);
                        http('/union/merchant/companyAccountVerify', { id, transAmt }).then((response) => {
                            if (response.status == 200) {
                                this.setState({ loading: false });
                                message.success('打款验证成功');
                                this.props.next();
                            }
                        }).catch((e) => {
                            this.setState({ loading: false });
                            message.error(e.message);
                        });
                    }
                });
            }
        });
    }

    // 重新打款
    _getcaptcha = e => {
        this.setState({ alreadySetLoading: true });
        http('/union/merchant/requestAccountVerify').then((response) => {
            if (response.status == 200) {
                message.success('已重新打款');
                this.setState({ alreadySetCaptcha: true });
            }
        }).catch((e) => {
            this.setState({ alreadySetLoading: false });
            message.error(e.message);
        });
    }

    render() {
        const { checkMoneyBoolean, required, loading, gmtCreate, alreadySetCaptcha, alreadySetLoading } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <div className={styles.step2}>
                {!checkMoneyBoolean
                    ?
                    <div>
                        <div style={{ marginLeft: '28px' }}>
                            <Form autoComplete='off' {...formItemLayout} onSubmit={this._handleSubmit}>
                                <Form.Item
                                    colon={false}
                                    htmlFor='captcha'
                                    label={<span>打款验证(单位/元)：</span>}
                                >
                                    <div className={styles.yzm}>
                                        {getFieldDecorator('captcha', {
                                            rules: [
                                                { required, message: '请输入打款验证金额' },
                                            ],
                                        })(<InputNumber
                                            style={{ width: '328px' }}
                                            placeholder='请输入打款验证金额(元)'
                                            min={0}
                                            max={10000}
                                            precision={2}
                                            step={0.01}
                                        />)}
                                        {alreadySetCaptcha ? null : <Button type="primary" loading={alreadySetLoading} className={styles.getcaptcha} onClick={this._getcaptcha}>重新打款</Button>}
                                    </div>
                                </Form.Item>
                            </Form>
                        </div>
                        <div className={styles.time}>
                            最近一次打款时间：{gmtCreate}
                        </div>
                        <div className={styles.desc}>
                            说明：银行系统已向您的对公账户打入一笔资金，请及时查询具体金额并填写在上面的输入框中，系统将对输入金额进行校验，请确保填写无误，超过4小时未验证请重新打款，输入错误超过5次将会锁定，请联系客服400-135-8587。
                    </div>
                    </div>
                    :
                    <div className={styles.txt}>
                        您已经打款验证过，点击下一步签订电子协议
                    </div>
                }
                <div className={styles.btn}>
                    <Button type="primary" htmlType="submit" loading={loading} onClick={this._handleSubmit}>下一步</Button>
                </div>
            </div>
        );
    }
}

step.propTypes = {
    previous: PropTypes.func, // 上一步
    next: PropTypes.func, // 下一步
    form: PropTypes.object, // 表单校验
};
const stepForm = Form.create({ name: 'step-form' })(step);
export default stepForm;


