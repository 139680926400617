import * as T from './actiontypes';
/** 
 * 平台活动列表数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} activityTitle 活动名称	
  * @param {string} activityType 活动类型
 * @param {string} state 状态
 * @param {string} attendState 区分 --传“”则查询所有，“1”：参与的活动，“2”：参与已结束
 */
export const getadvocacytaskList = (dataload) => ({
    type: T.GET_ADVOCACYLIST_DATA,
    payload: {
        ...dataload
    }
});
/**
 * 平台活动下拉框'数据源(saga)-代言类型、代言状态
 * @param 暂无
 */
export const getadvocacytaskDropDown = (dataload) => ({
    type: T.GET_DROPDIWN_DATA,
    payload: {
        ...dataload,
    }
});

/**
 *平台活动新建-选择商品
 * @param {number}  暂无
 */
export const getadvocacyRaiseTable = (dataload) => ({
    type: T.GET_RAISETABALE_DATA,
    payload: {
        ...dataload,
    }
});
/**
 *平台活动参与活动详情获取数据'数据源(saga)
 * @param {number} id  获取某个用户情对应的id
 */
export const getadvocacyDetails = (id) => ({
    type: T.GET_ADVOCACYDETAILS_DATA,
    payload: {
        id,
    }
});
/**
 *平台活动详情获取数据'数据源(saga)
 * @param {number} id  获取某个用户情对应的id
 * @param {string} goodsName  参与活动商品的名字
 * @param {string} goodsNo  参与活动商品的货号
 */
export const getadvocacyParticipateDetails = (id, goodsName, goodsNo) => ({
    type: T.GET_ADVOCACYPARTICIDETAILS_DATA,
    payload: {
        id,
        goodsName,
        goodsNo
    }
});
