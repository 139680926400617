import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import Table from './components/Table';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/taxdeclaration/model';
import { XInput, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import styles from './index.module.scss';

class Main extends Component {

    state = {
        ownerName: undefined,
        startDate: undefined,
        endDate: undefined,

        selectedRowKeys: [],
        selectedRows: []
    };

    //props校验
    static propTypes = {
        history: PropTypes.object,//路由对象
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航

        getReconciliationList: PropTypes.func,//获取列表
        reconciliationList: PropTypes.object,//列表数据

    }

    //挂载后
    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    getCache = () => ({
        ownerName: this.state.ownerName,
        startDate: this.state.startDate,
        endDate: this.state.endDate
    });

    // 查询
    _searchHandle = (useCache) => {
        let cache = this.getCache();

        if (useCache !== 'useCache') {
            cache.pageNum = autoPageNum;// 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            cache.pageSize = autopageSize;// 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        }

        this.searchCache = cache;

        this.props.getReconciliationList(this.searchCache);

        KeepAlive.saveCache({ ...this.getCache(), pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ renderMode: '' });
        if (update) {
            this._searchHandle('useCache');
        }
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            ownerName: undefined, //对账单号
            startDate: undefined,//对账周期起始时间
            endDate: undefined,//对账周期结束时间
        }, () => KeepAlive.saveResetFunc(this._searchHandle)); // 【第4步：将是否调用重置交给KeepAlive控制】
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => this._searchHandle('useCache'));
        KeepAlive.saveCache({ ...this.getCache(), pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    exportExcel = (exportType) => {

        let cache = this.getCache();
        cache.pageNum = this.searchCache.pageNum;
        cache.pageSize = this.searchCache.pageSize;

        http('/plat/withdrawal/exportTaxDeclaration', { exportType, withdrawalIds: this.state.selectedRowKeys, ...cache }, 'POST').then((response) => {
            if (response.status == 200) {
                window.location.href = response.result;
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys, selectedRows });
        },
    };

    //渲染组件
    render() {
        const { ownerName, startDate, endDate } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XInput
                            style={{ width: '200px', marginRight: '15px' }}
                            label='姓名'
                            placeholder='请输入姓名'
                            value={ownerName}
                            bindThis={this}
                            bindName='ownerName'
                        />
                        <XDatePicker
                            label='所得时间'
                            value={startDate}
                            bindThis={this}
                            bindName='startDate'
                            showTime={false}
                            defaultTime='00:00:00'
                            isFormat
                        />
                        <XDatePicker
                            value={endDate}
                            bindThis={this}
                            bindName='endDate'
                            showTime={false}
                            defaultTime='23:59:59'
                            isFormat
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: '120px' }}
                            label='导出个税信息'
                            onClick={() => this.exportExcel(1)}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '120px' }}
                            label='导出人员信息'
                            onClick={() => this.exportExcel(0)}
                        />
                    </div>
                    <Table rowSelection={this._rowSelection} renderData={this.props.reconciliationList} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

//映射数据源
const mapStateToProps = (state) => ({
    reconciliationList: state.taxdeclaration.reconciliationList,
    stateList: state.taxdeclaration.stateList,
    supplierList: state.taxdeclaration.supplierList,
});

//暴露组件
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
