import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import LiveTable from './components/LiveTable';
import { OperateLiveModal } from '../../modal/OperateLiveModal';
import { OnlinePlayModal } from '../../modal/OnlinePlayModal';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/commercialcollege/livelist/model';
import { XInput, XSelect, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import { Operation } from '../../_utils';
import { timeToMinute } from '@/util/format';

class Main extends Component {

    state = {
        renderMode: '', // 渲染弹框
        modeParams: {}, // 弹框入参

        broadcasTitle: '', // 直播间标题
        liveCategory: {}, // 上下架
        liveState: {}, // 直播状态
    };

    //挂载后
    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getLiveState();
        this.props.getLiveCategory();
    }


    // 查询
    _searchHandle = (useCache) => {
        const { broadcasTitle, liveCategory, liveState, pageNum, pageSize } = this.state;
        const query = {
            broadcasTitle,
            classId: liveCategory.id,
            state: liveState.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };

        this.props.getLiveList(query);
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize });
    }

    // 重置
    _resetHandle = () => {
        this.setState({ broadcasTitle: '', liveCategory: {}, liveState: {} }, () => KeepAlive.saveResetFunc(this._searchHandle));
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => this._searchHandle('useCache'));
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum });
    }

    // 报错
    initErrHandle = (res = {}, err) => {
        message.error(res.message || err);
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        switch (type) {
            case Operation.Live:
                this.setState({ modeParams: value, renderMode: Operation.Live });
                break;
            case Operation.Play:
                this.setState({ modeParams: value, renderMode: Operation.Play });
                break;
            case Operation.Publish:
                http('/liveCharge/createLiveChargeInitInfo', { count: 100, initType: 1 }).then((res) => {
                    if (res.code == 200) {
                        let { seconds } = res.result;
                        let secondsStr = (timeToMinute(seconds) || '').replace(':', '时').replace(':', '分') + '秒';
                        showConfirm(`您今天还可以直播：${secondsStr}，是否发起直播`, '', () => {
                            http('/liveCharge/liveChargePublishLive', { liveChargeId: value.liveChargeId, streamName: value.streamName }).then(res => {
                                if (res.code === 200) {
                                    message.success('发布成功');
                                    this._searchHandle();
                                }
                            }).catch(e => this.initErrHandle(e, '发布异常'));
                        });
                    }
                }).catch(e => this.initErrHandle(e, '获取直播时长异常'));
                break;
            case Operation.Finish:
                http('/liveCharge/liveChargeStopLive', { liveChargeId: value.liveChargeId, streamName: value.streamName }).then(res => {
                    if (res.code === 200) {
                        message.success('结束成功');
                        this._searchHandle();
                    }
                }).catch(e => this.initErrHandle(e, '操作异常'));
                break;
            case Operation.Update:
                this.setState({ modeParams: value, renderMode: Operation.Update });
                break;
            case Operation.Delete:
                showConfirm('您确定删除该直播？', '', () => {
                    http('/liveCharge/liveChargeDelete', { liveChargeId: value.liveChargeId, streamName: value.streamName }).then(res => {
                        if (res.code === 200) {
                            message.success('删除成功');
                            this._searchHandle();
                        }
                    }).catch(e => this.initErrHandle(e, '操作异常'));
                });
                break;
        }
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { modeParams, renderMode } = this.state;
        switch (renderMode) {
            case Operation.Create:
                return <OperateLiveModal title='发起直播' mode={Operation.Create} closeMode={this._closeMode} category={this.props.liveCategoryList} modeParams={modeParams} />;
            case Operation.Update:
                return <OperateLiveModal title='编辑直播' mode={Operation.Update} closeMode={this._closeMode} category={this.props.liveCategoryList} modeParams={modeParams} />;
            case Operation.Live:
            case Operation.Play:
                return <OnlinePlayModal visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ renderMode: '' });
        update && this._searchHandle('useCache');
    }

    //渲染组件
    render() {
        const { broadcasTitle, liveState, liveCategory } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XInput
                            style={{ width: '250px' }}
                            label='直播标题'
                            placeholder='请输入直播标题'
                            value={broadcasTitle}
                            bindThis={this}
                            bindName='broadcasTitle'
                        />
                        <XSelect
                            style={{ width: '250px', marginLeft: '20px' }}
                            selectStyle={{ width: '100%' }}
                            label='所属分类'
                            placeholder='请选择所属分类'
                            renderData={this.props.liveCategoryList.list}
                            keyIndex='id'
                            dataIndex='className'
                            value={liveCategory.className}
                            bindThis={this}
                            bindName='liveCategory'
                        />
                        <XSelect
                            style={{ width: '250px', marginLeft: '20px' }}
                            selectStyle={{ width: '100%' }}
                            label='直播状态'
                            placeholder='请选择直播状态'
                            renderData={this.props.liveStateList}
                            dataIndex='value'
                            keyIndex='code'
                            value={liveState.value}
                            bindThis={this}
                            bindName='liveState'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: '100px' }}
                            label='发起直播'
                            onClick={() => this.setState({ renderMode: Operation.Create })}
                        />
                    </div>
                    <LiveTable renderData={this.props.liveList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由对象
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getLiveList: PropTypes.func, // 获取直播间列表
    getLiveState: PropTypes.func, // 获取直播间状态
    getLiveCategory: PropTypes.func, // 获取直播间分类
    liveList: PropTypes.object, // 直播间列表数据
    liveStateList: PropTypes.array, // 直播间状态
    liveCategoryList: PropTypes.object // 直播间分类
};

//映射数据源
const mapStateToProps = (state) => ({
    liveList: state.livelist.liveList,
    liveStateList: state.livelist.liveStateList,
    liveCategoryList: state.livelist.liveCategoryList
});

//暴露组件
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
