/**
 * 更新商品库存弹框 
 * */
import React from 'react';
import { Modal, Table, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import styles from './UpdateReserveModal.module.scss';
import http from '@/assets/api/http';
import { message } from 'antd';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

class UpdateReserveModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        skunumber: null,//库存数量
        skuDataSource: this.props.data || [],//列表数据
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _columns = () => ([
        {
            title: '图片',
            dataIndex: 'imgUrl',
            key: 'imgUrl',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: ImgCell
        },
        {
            title: '商品编码',
            dataIndex: 'skuNo',
            key: 'skuNo',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '规格',
            dataIndex: 'skuSpec',
            key: 'skuSpec',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '占用库存',
            dataIndex: 'occupyQuantity',
            key: 'occupyQuantity',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: (text) => (text ? tooltip(text) : tooltip(0))
        },
        {
            title: '数量',
            dataIndex: 'invQuantity',
            key: 'invQuantity',
            width: '15%',
            align: 'center',
            render: (text, item) => (this._renderInputCell(text, item))
        },
        {
            title: '更新时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
    ]);

    _renderInputCell = (text, item) => {
        return (
            <div>
                <InputNumber
                    className={styles.input}
                    value={text}
                    onChange={(value) => {
                        let souce = this.state.skuDataSource.map(v => {
                            if (`${item.goodsId},${item.skuId}` == `${v.goodsId},${v.skuId}`) {
                                item.invQuantity = value;
                            }
                            return v;
                        });
                        this.setState({
                            skuDataSource: souce
                        });
                    }}
                    min={0}
                    max={100000}
                    precision={0}
                />
            </div>
        );
    }
    // 确认
    _handleOk = () => {
        this.setState({ confirmLoading: true }, () => {
            http('/goods/inventory/updateSKUInventory', {
                skuList: this._stockData(),
            }, 'POST').then(() => {
                message.success('更新成功');
                this.setState({
                    visible: false,
                    confirmLoading: false
                });
                this.props.closeMode(true);
            }).catch((res = {}) => {
                message.warn(res.message || '更新异常');
                this.setState({
                    visible: false,
                    confirmLoading: false
                });
                this.props.closeMode();
            });
        });
    }

    //数据处理
    _stockData = () => {
        const { skuDataSource, } = this.state;
        let stockObj = {};
        let skuList = [];
        for (var j = 0, len = skuDataSource.length; j < len; j++) {
            stockObj = {
                goodsId: skuDataSource[j].goodsId,
                skuId: skuDataSource[j].skuId,
                inventory: skuDataSource[j].invQuantity,
            };
            skuList.push(stockObj);
        }
        return skuList;
    }

    //全部修改
    _skuModify = () => {
        const { skunumber } = this.state;
        const { data, } = this.props;
        if (skunumber === null) {
            message.warn('请输入批量修改库存数量。');
        } else {
            this.setState({
                skuDataSource: data.map(item => {
                    item.invQuantity = skunumber;
                    return item;
                })
            });
        }
    }

    render() {
        const { visible, confirmLoading, skuDataSource } = this.state;
        return (
            <Modal
                width={1155}
                centered
                title='更新商品库存'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.header}>
                        {/* <div className={styles.title}>
                            销售规格
                        </div> */}
                        <div className={styles.update}>
                            <div>批量修改库存数量：</div>
                            <div className={styles.updateNum}>
                                <InputNumber
                                    className={styles.input}
                                    placeholder='请输入库存数量值'
                                    min={0}
                                    max={100000}
                                    onChange={(value) => {
                                        this.setState({ skunumber: value });
                                    }}
                                    precision={0}
                                    value={this.state.skunumber}
                                />
                            </div>
                            <div className={styles.button} onClick={this._skuModify}>
                                全部修改
                            </div>
                        </div>
                    </div>
                    <div className={styles.table}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            // rowSelection={this._rowSelection}
                            rowKey={(record, index) => index}
                            columns={this._columns()}
                            dataSource={skuDataSource}
                            pagination={false}
                            scroll={{ y: 530 }}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

UpdateReserveModal.propTypes = {
    data: PropTypes.array,
    visible: PropTypes.bool.isRequired,
    closeMode: PropTypes.func.isRequired,
};

export default UpdateReserveModal;