/** 仓库商品结算价Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';


/**
 * 库存列表'数据源(saga)
 */
export const WarehousePriceList = function* () {
    yield takeEvery(T.GET_STOCKPRICELIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/goods/inventory/findGoodsSettleList', action.payload, 'POST');
            yield put({ type: T.SET_STOCKPRICELIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 审核列表'数据源(saga)
 */
export const WarehouseAuditList = function* () {
    yield takeEvery(T.GET_STOCKAUDITLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/goods/inventory/findGoodsSettleHistoryList', action.payload, 'POST');
            yield put({ type: T.SET_STOCKAUDITLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 审核状态 Saga
 */
export const auditSelectData = function* (){
    yield takeEvery(T.GET_AUDITSELECT_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象 
        try{
            let result = yield http('/enum/GoodsSettleRecordAuditStatusEnum', action.payload,'POST');
            yield put({type:T.SET_AUDITSELECT_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};
