/**
 *  商品管理 - 标签
 * */ 
import React from 'react';
import PropTypes from 'prop-types'; 
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const Mode = {
    CHANNEL_LIST_HISTORY: 'CHANNEL_LIST_HISTORY', // 直播间历史记录
};

class ProductLabelList extends React.Component {

    componentDidMount(){
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch(mode){
            case Mode.CHANNEL_LIST_HISTORY: 
                addOneBreadcrumbPath({
                    title:'直播间历史记录',
                    path:'/home/showmanage/ProductLabelList/history/'
                });
                history.push({
                    pathname: '/home/showmanage/ProductLabelList/history/'
                });
                break;
        }
    }
    
    render(){
        return(
            <Main updateContent={this._updateContent} history = {this.props.history} /> 
        );
    }
}

ProductLabelList.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(ProductLabelList);