export const Operation = {
    /** 发起直播 */
    Create: 'Create',

    /** 编辑 */
    Update: 'Update',

    /** 删除 */
    Delete: 'Delete',

    /** 复制推流码 */
    Copy: 'Copy',

    /** 发布直播 */
    Publish: 'Publish',

    /** 结束直播 */
    Finish: 'Finish',

    /** 播放 */
    Live: 'Live',

    /** 回放 */
    Play: 'Play'
};

export const LiveState = {
    LIVE: 'LIVE',
    END: 'END',
    NO_VIEWING: 'NO_VIEWING',
    NONE: 'NONE'
};