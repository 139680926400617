//获取并保存费率列表
export const GET_PRODUCTRATE_LISTDATA = 'RATE/GET_PRODUCTRATE_LISTDATA';
export const SET_PRODUCTRATE_LISTDATA = 'RATE/SET_PRODUCTRATE_LISTDATA';
export const BATCH_SET_LISTDATA = 'RATE/BATCH_SET_LISTDATA';
export const REMOVE_LISTDATA = 'RATE/REMOVE_LISTDATA';
export const SINGLE_SET_LISTDATA = 'RATE/SINGLE_SET_LISTDATA';
export const MINIMUM_RATE_LISTDATA = 'RATE/MINIMUM_RATE_LISTDATA';

//获取并保存费率状态下拉
export const GET_PRODUCTRATE_STATUS = 'RATE/GET_PRODUCTRATE_STATUS';
export const SET_PRODUCTRATE_STATUS = 'RATE/SET_PRODUCTRATE_STATUS';

//获取并保存费率业务类型下拉
export const GET_PRODUCTRATE_BUSINESSTYPE = 'RATE/GET_PRODUCTRATE_BUSINESSTYPE';
export const SET_PRODUCTRATE_BUSINESSTYPE = 'RATE/SET_PRODUCTRATE_BUSINESSTYPE';

//获取并保存费率标签下拉
export const GET_PRODUCTRATE_LABELSELE = 'RATE/GET_PRODUCTRATE_LABELSELE';
export const SET_PRODUCTRATE_LABELSELE = 'RATE/SET_PRODUCTRATE_LABELSELE';

//获取并保存费率分类三级联动
export const GET_PRODUCTRATE_CLASSIFYSELE = 'RATE/GET_PRODUCTRATE_CLASSIFYSELE';
export const SET_PRODUCTRATE_CLASSIFYSELE = 'RATE/SET_PRODUCTRATE_CLASSIFYSELE';


//获取并保存费率详情
export const GET_PRODUCTRATE_DETAIL = 'RATE/GET_PRODUCTRATE_DETAIL';
export const SET_PRODUCTRATE_DETAIL = 'RATE/SET_PRODUCTRATE_DETAIL';
export const CLEAN_PRODUCT_DETAIL = 'RATE/CLEAN_PRODUCTRATE_DETAIL';

//获取并保存活动费率详情
export const GET_ACTIVERATE_DETAIL = 'RATE/GET_ACTIVERATE_DETAIL';
export const SET_ACTIVERATE_DETAIL = 'RATE/SET_ACTIVERATE_DETAIL';

//获取兑换比例
export const GET_CONVERSION_RATE = 'RATE/GET_CONVERSION_RATE';
export const SET_CONVERSION_RATE = 'RATE/SET_CONVERSION_RATE';
