import React, { Fragment } from 'react';
import styles from './OrderTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class OrderTable extends React.Component {
    _takeColumns = () => ([
        {
            title: '订单编号',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: (text, record) => {
                return <div style={{ textAlign: 'right' }}>{record.isSelfSponsor == 1 && <img src={require('@/assets/image/drainage.png')} style={{ verticalAlign: 'top' }} />}{text}</div>;
            }
        },
        {
            title: '会员账号',
            dataIndex: 'buyerTel',
            key: 'buyerTel',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '单价(元)',
            dataIndex: 'goodsList',
            key: 'goodsList',
            width: '6%',
            align: 'center',
            render: (obj) => {
                return obj && this._renderTextMoneyCell(obj[0].goodsPrice);
            }
        },
        {
            title: '入住天数',
            dataIndex: 'liveNum',
            key: 'liveNum',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '房间数',
            dataIndex: 'goodsNum',
            key: 'goodsNum',
            width: '6%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单金额',
            dataIndex: 'totalFee',
            key: 'totalFee',
            width: '8%',
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '订单状态',
            dataIndex: 'statusValue',
            key: 'statusValue',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '下单时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '支付方式',
            dataIndex: 'channelTypeValue',
            key: 'channelTypeValue',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '支付流水号',
            dataIndex: 'payItemId',
            key: 'payItemId',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'key',
            width: '26%',
            align: 'center',
            render: (text, record) => this._renderActionCell(text, record)
        }
    ]);
    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    _renderActionCell = (key, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
                <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>历史操作记录</div>
                <div className={styles.item} onClick={() => tableAction(key, 'business')}>联系买家</div>
                {
                    record.status === 'PAIED' &&
                    record.sonStatus === 'UNCONFIRM' &&
                    <Fragment>
                        <div className={styles.item} onClick={() => tableAction(key, 'affirm', record)}>确认订单</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'cancel', record)}>取消订单</div>
                    </Fragment>
                }
                {(record.status === 'CANCELE' || record.status === 'HOSTE') && <div className={styles.item} onClick={() => tableAction(key, 'del', record)}>删除</div>}
            </div>
        );
    };

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
        }
    };

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default OrderTable;