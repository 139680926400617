import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

class TableContent extends Component {
    _columns = () => [
        {
            title: '打款单号',
            dataIndex: 'remitId',
            key: 'remitId',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '姓名',
            dataIndex: 'subjectName',
            key: 'subjectName',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '手机号',
            dataIndex: 'subjectPhone',
            key: 'subjectPhone',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '申请来源',
            dataIndex: 'source.value',
            key: 'source.value',
            align: 'center',
            width: '12%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '申请金额',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '账号信息',
            dataIndex: 'bindAccountNo',
            key: 'bindAccountNo',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '开户银行',
            dataIndex: 'bindAccountName',
            key: 'bindAccountName',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '支付账户',
            dataIndex: 'accessType',
            key: 'accessType',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '申请时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '打款状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '打款时间',
            dataIndex: 'remitFinishTime',
            key: 'remitFinishTime',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '失败原因',
            dataIndex: 'failReason',
            key: 'failReason',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '打款凭证',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '10%',
            render: (id) => this._renderActionCell(id)
        },
    ];

    _imgCell = (text) => {
        return <img src={text} width='50px' height='50px'></img>;
    }

    _takeDataSource = (data = []) => {
        return data.map((item) => {
            switch (item.remitStatus) {
                case 'TODO':
                    item.status = '待打款';
                    break;
                case 'DOING':
                    item.status = '汇款中';
                    break;
                case 'SUCCESS':
                    item.status = '汇款成功';
                    break;
                case 'FAILED':
                    item.status = '汇款失败';
                    break;
            }
            return item;
        });
    }

    _renderActionCell = (key) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'detail')}>查看详情</div>
            </div>
        );
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey='id'
                    columns={this._columns()}
                    pagination={false}
                    dataSource={this._takeDataSource(dataList)}
                    scroll={{ x: '120%', y: true }} />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
};

export default TableContent;