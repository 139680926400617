/**
 * 【对接参数设置页】
 */
import React from 'react';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, XTextArea } from '@/components/xqxc_ui';
import { Radio, message } from 'antd';
import http from '@/assets/api/http';

const RadioGroup = Radio.Group;
class Main extends React.Component {

    state = {
        isNew: true, // 是否为新建
        id: '', // 配置ID
        openDock: 1, // 开启对接（开启：1；不开启：0）
        apiUrl: '', // api路径
        appKey: '',
        appSecret: '',
        appSessionKey: '',
        remark: '', // 备注
        autoInventoryTime: 0, // 库存接口自动执行间隔(分)
        autoOrderTime: 0, // 下订单接口自动执行间隔(分)
        autoQueryOrderTime: 0, // 订单查询接口自动执行间隔(分)
        autoProductTime: 0, // 商品接口自动执行间隔(分)
    }

    componentDidMount() {
        this._init();
    }

    // 初始化信息
    _init = () => {
        http('/thirdpaty/query', {}, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result && response.result.id != '') {
                    const { apiUrl, appKey, appSecret, appSessionKey, remark, openDock, id } = response.result;
                    this.setState({
                        isNew: false,
                        id,
                        apiUrl,
                        appKey,
                        appSecret,
                        appSessionKey,
                        remark,
                        openDock
                    });
                } else {
                    this.setState({ isNew: true });
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 开启对接单选框切换
    _radioChangeHandle = (e) => {
        this.setState({ openDock: e.target.value });
    }

    // 开启对接时的输入内容
    _inputChangeHandle = (e) => {
        this.setState({ openDock: e.target.value });
    }

    // 保存
    _saveHandle = () => {
        const { isNew, apiUrl, appKey, appSecret, appSessionKey, remark, openDock, id } = this.state;
        const data = { apiUrl, appKey, appSecret, appSessionKey, remark, openDock };
        if (isNew) {
            http('/thirdpaty/save', data, 'POST').then((response) => {
                if (response.status == 200) {
                    message.success('创建成功');
                    this._init();
                }
            }).catch((e) => {
                message.error(e.message);
            });
        } else {
            http('/thirdpaty/update', { ...data, id }, 'POST').then((response) => {
                if (response.status == 200) {
                    message.success('编辑成功');
                }
            }).catch((e) => {
                message.error(e.message);
            });
        }
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            openDock: 1, // 开启对接（开启：1；不开启：0）
            appKey: '',
            appSecret: '',
            appSessionKey: '',
            remark: '', // 备注
        });
    }

    render() {
        const { openDock, apiUrl, appKey, appSecret, appSessionKey, remark } = this.state;
        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.row1}>
                    <div className={styles.radioBox}>
                        <div>开启对接：</div>
                        <RadioGroup style={{ marginLeft: '8px' }} onChange={this._radioChangeHandle} value={openDock}>
                            <Radio value={1}>开启</Radio>
                            <Radio value={0}>不开启</Radio>
                        </RadioGroup>
                    </div>
                    {/* <Input className={styles.input} value={openDock} placeholder='开启后设置相关对接参数' disabled={!openDock} onChange={this._inputChangeHandle} /> */}
                </div>
                <div className={styles.row2}>
                    <div className={styles.redstar}>*</div>
                    <XInput
                        style={{ width: '860px' }}
                        inputStyle={{ width: '800px' }}
                        label='api路径'
                        placeholder='请输入api路径'
                        value={apiUrl}
                        bindThis={this}
                        bindName='apiUrl'
                    />
                </div>
                <div className={styles.row2}>
                    <div className={styles.redstar}>*</div>
                    <XInput
                        style={{ width: '860px' }}
                        inputStyle={{ width: '800px' }}
                        label='appKey'
                        placeholder='请输入appKey'
                        value={appKey}
                        bindThis={this}
                        bindName='appKey'
                    />
                </div>
                <div className={styles.row3}>
                    <div className={styles.redstar}>*</div>
                    <XInput
                        style={{ width: '860px' }}
                        inputStyle={{ width: '800px' }}
                        label='appSecret'
                        placeholder='请输入appSecret'
                        value={appSecret}
                        bindThis={this}
                        bindName='appSecret'
                    />
                </div>
                <div className={styles.row4}>
                    <div className={styles.redstar}>*</div>
                    <XInput
                        style={{ width: '860px' }}
                        inputStyle={{ width: '800px' }}
                        label='appSessionKey'
                        placeholder='请输入appSessionKey'
                        value={appSessionKey}
                        bindThis={this}
                        bindName='appSessionKey'
                    />
                </div>
                <XTextArea
                    style={{ width: '850px', marginLeft: '118px', marginTop: '30px' }}
                    inputStyle={{ width: '800px', resize: 'none' }}
                    label='备注'
                    placeholder='请输入备注'
                    value={remark}
                    bindThis={this}
                    bindName='remark'
                />
                <div className={styles.row5}>
                    <XOKButton style={{ marginLeft: '156px' }} label='保存' onClick={this._saveHandle} />
                    <XCancelButton style={{ marginLeft: '26px' }} label='重置' onClick={this._resetHandle} />
                </div>
            </div>
        );
    }
}

Main.propTypes = {

};

export default Main;