/**
 * 新建部门
 */
import React from 'react';
import { Modal, Input, Form, Select, InputNumber, Button } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import numSwitch from '@/util/numSwitch';
import TextArea from 'antd/lib/input/TextArea';
import { formValids } from '@/assets/js/utils';
import { regYuanToFen, regFenToYuan } from '@/util/money';
import styles from './CreateBatchPayModal.module.scss';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};

class CreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
        };
    }

    _handleOk = (start) => {

        const { selectedRowKeys, totalPay } = this.props.data;

        let { paymentAmount, remark } = this.props.form.getFieldsValue();

        if (formValids({ paymentAmount }, {
            paymentAmount: '请输入付款金额',
        })) {
            http('/settle/paymentApplication/add', { paymentAmount: regYuanToFen(paymentAmount), remark, reconciliationIds: selectedRowKeys, toBePaymentAmount: regYuanToFen(totalPay), start });
            this.props.closeMode(true);
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;

        const totalPay = regFenToYuan(this.props.data.totalPay);
        const paiedVal = this.props.form.getFieldValue('paymentAmount');

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
                footer={
                    <div>
                        <Button onClick={this._handleCancel}>取消</Button>
                        <Button onClick={() => this._handleOk(false)}>确定</Button>
                        <Button type="primary" onClick={() => this._handleOk(true)}>确定并提交审核</Button>
                    </div>
                }
            >
                <div className={styles.container}>
                    <div style={{ textAlign: 'left', paddingLeft: 23 }}>
                        <span>待付款金额</span>
                        <span style={{ marginLeft: 20, color: 'rgba(0, 0, 0, 0.65)' }}>{totalPay}</span>
                    </div>
                    <div style={{ marginBottom: 10, textAlign: 'left', paddingLeft: 66 }}>
                        <span>大写</span>
                        <span style={{ marginLeft: 20, color: 'rgba(0, 0, 0, 0.65)' }}>{totalPay ? numSwitch(totalPay) : ''}</span>
                    </div>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon={false}
                            htmlFor='paymentAmount'
                            label={<span>付款金额</span>}
                        >
                            {getFieldDecorator('paymentAmount', {
                                rules: [{ required: true, message: '请输入付款金额' }],
                            })(<InputNumber placeholder='请输入付款金额' style={{ width: 220 }} max={totalPay} />)}
                            <div style={{ textAlign: 'left', width: 320, marginLeft: -37 }}>
                                <span style={{ color: '#000' }}>大写</span>
                                <span style={{ paddingLeft: 23 }}>{paiedVal ? numSwitch(paiedVal) : ''}</span>
                            </div>
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='remark'
                            label={<span>付款说明</span>}
                        >
                            {getFieldDecorator('remark', {
                            })(<TextArea style={{ paddingLeft: 20 }} placeholder="请输入付款说明" rows={4} />)}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );

    }
}

CreateModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(CreateModal);// 校验

export default connect(null, null)(ForgotProducDetails);
