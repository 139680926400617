/**
 * 延迟下架
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import styles from './modalStyle.module.scss';
import { Modal, message, InputNumber, Alert, Typography, Row, Col } from 'antd';
import model from '@/reducers/productmanage/wholesalegoods/model';

const TipInventory = ({
    closeMode,
    getInventoryNumber,
    inventoryNumber
}) => {

    const [loading, setLoading] = useState(false);
    const [inventoryWarning, setInventoryWarning] = useState(0);

    useEffect(() => {
        getInventoryNumber();
    }, []);

    useEffect(() => {
        setInventoryWarning(inventoryNumber);
    }, [inventoryNumber]);


    const onSubmit = () => {
        if (!inventoryWarning) {
            message.warning('请输入安全数量');
            return;
        }
        setLoading(true);
        http('/merchant/wholesale/updateOrSaveInventoryWarning', { inventoryWarning }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };


    return <Modal
        width={500}
        centered
        title='库存提醒设置'
        visible={true}
        confirmLoading={loading}
        onOk={onSubmit}
        onCancel={() => closeMode(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div className={styles.container}>
            <Alert type='error' message='可设置当商品库存低于或等于多少时，系统提示补仓！' />
            <Row style={{ marginTop: 20 }}>
                <Col span={24}>
                    <div>
                        <Typography.Text strong>设置安全数量：</Typography.Text>
                        <InputNumber
                            placeholder='请输入数字'
                            value={inventoryWarning}
                            max={9999}
                            min={1}
                            style={{ width: 300, marginRight: 10 }}
                            onChange={value => setInventoryWarning(value)}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    </Modal >;
};
TipInventory.propTypes = {
    closeMode: PropTypes.func,
    getInventoryNumber: PropTypes.func,
    inventoryNumber: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};
export default connect(state => ({
    inventoryNumber: state.wholesaleGoods.inventoryNumber
}), {
    ...model.actions
})(TipInventory);