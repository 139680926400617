import React from 'react';
import styles from './AdvertTable.module.scss';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { Table, Alert } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';

class AdvertTable extends React.Component {
    state = {
        account: '',
        selectedRowKeys: [],
    }

    _takeColumns = () => ([
        {
            title: '提现单号',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '提现金额（元）',
            dataIndex: 'amount',
            key: 'amount',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '手续费（元）',
            dataIndex: 'fee',
            key: 'fee',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '银行卡',
            dataIndex: 'bankCardNo',
            key: 'bankCardNo',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'statusName',
            key: 'statusName',
            width: '10%',
            align: 'center',
            render: (text, item) => {
                return <span>{text && text}</span>;
            }
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '提现时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
    ]);
    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }
    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }
    render() {
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Alert message="平台定期提现商户当前账户余额到商户绑定的银行卡" banner style={{ marginBottom: 10 }} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    // onChange={this.onChange}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    settlementStatus: PropTypes.array,
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default AdvertTable;