import { createModel } from '@/store/tools';

const model = {
    namespace: 'records',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getRecordList': T('getRecordList'),
            'getRecordDetail': T('getRecordDetail'),
            'getRecordTemplates': T('getRecordTemplates'),
        },
        actions: {
            'getRecordList': A('getRecordList'), // 列表数据
            'getRecordCleanDetail': C('getRecordDetail'), // 清除详情数据
            'getRecordDetail': A('getRecordDetail'), // 详情
            'getRecordTemplates': A('getRecordTemplates'), // 选择模板列表
            'cleanRecordTemplates': C('getRecordTemplates'), // 清理模板列表
        },
        sagas: {
            'getRecordList': S('getRecordList', '/report/record/list'),
            'getRecordDetail': S('getRecordDetail', '/report/record/info'),
            'getRecordTemplates': S('getRecordTemplates', '/report/template/selecList'),
        },
        reducers: {
            'recordList': R('getRecordList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'recordDetail': R('getRecordDetail', {}),
            'recordTemplate': R('getRecordTemplates', []),
        }
    })
};
export default createModel(model);