import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Input, Checkbox, message, Select } from 'antd';
import http from '@/assets/api/http';

const BatchRemark = ({ closeMode, form: { getFieldDecorator, validateFields } = {} }) => {

    const [loading, setLoading] = useState(false);
    // const [remarkEnum, setRemarkEnum] = useState([]);

    useEffect(() => {
        // http('/enum/DeliverRemarkEnum').then(res => setRemarkEnum(res.result));
    }, []);

    const onSubmit = () => {
        validateFields((err, values) => {
            if (!err) {
                const params = { ...values, orderIds: values.orderIds.replace(/，/ig, ',').split(','), check: values.check ? 1 : 0 };
                setLoading(true);
                http('/merchant/order/deliverRemark', { ...params }, 'POST')
                    .then(res => { message.success(res.message); setLoading(false); closeMode(true); })
                    .catch(err => { message.error(err.message); setLoading(false); });
            }
        });
    };

    return <Modal title='批量备注' width={600} visible={true} confirmLoading={loading} centered
        onOk={onSubmit} onCancel={() => closeMode(false)}
        destroyOnClose={true} keyboard={true} maskClosable={false} cancelText='取消' okText='确认'
    >
        <Form>
            <Form.Item label='订单编号' labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                {getFieldDecorator('orderIds', {
                    rules: [{ required: true, message: '请输入订单编号' }]
                })(<Input.TextArea placeholder='请输入订单编号，如有多个订单编号，请用英文逗号隔开' />)}
            </Form.Item>
            <Form.Item label='备注' labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} extra='（此备注在用户端，订单详情页显示）'>
                {getFieldDecorator('deliverRemark', {
                    rules: [{ required: true, message: '请输入备注' }]
                })(
                    <Input.TextArea placeholder='请输入备注' />
                    // <Select placeholder='请选择备注'>
                    //     {remarkEnum.map(item => {
                    //         return <Select.Option key={item.code} value={item.value}>{item.value}</Select.Option>;
                    //     })}
                    // </Select>
                )}
            </Form.Item>
            <Form.Item>
                {getFieldDecorator('check')(<Checkbox>需要给用户发送短信</Checkbox>)}
            </Form.Item>
        </Form>
    </Modal>;
};
BatchRemark.propTypes = {
    closeMode: PropTypes.func,
    form: PropTypes.object
};
export default Form.create({ name: 'BatchRemark' })(BatchRemark);
