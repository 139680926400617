import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Table, message } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import {tooltipStyle, tooltip,  ImgCell } from '@/components/TableCell';

class TableData extends Component {

    state = {
        selectedRowKeys: [], // 选中的单元行
    };

    _takeColumns = () => ([
        {
            title: '分类名称',
            dataIndex: 'categoryName',
            key: 'categoryName',
            width: '35%',
            align: 'center',
            render: (text, item) => (this._renderInputCell(text, item)),
        },
        {
            title: '分类图片',
            dataIndex: 'imgUrl',
            key: 'imgUrl',
            width: '15%',
            align: 'center',
            render: ImgCell
        },
        {
            title: '技术服务费率(%)',
            dataIndex: 'platRate',
            key: 'platRate',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
			render: tooltip
        },
        {
            title: '平台服务费率(%)',
            dataIndex: 'agentRate',
            key: 'agentRate',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
			render: tooltip
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
			render: tooltip
        },
    ]);

    // 数据源处理
    _takeDataSource = (dataList, treeOpen) => {
        let list = [];
        if (dataList) {
            for (let i = 0; i < dataList.length; i++) {
                dataList[i].visible = true;
                dataList[i].depth = 1;
                list.push(dataList[i]);
                let secondChildren = dataList[i].categoryInfoList;
                if (secondChildren && secondChildren.length > 0) {
                    for (let m = 0; m < secondChildren.length; m++) {
                        secondChildren[m].visible = treeOpen;
                        secondChildren[m].depth = 2;
                        list.push(secondChildren[m]);
                        let thirdChildren = secondChildren[m].categoryInfoList;
                        if (thirdChildren && thirdChildren.length > 0) {
                            for (let n = 0; n < thirdChildren.length; n++) {
                                thirdChildren[n].visible = treeOpen;
                                thirdChildren[n].depth = 3;
                                list.push(thirdChildren[n]);
                            }
                        }
                    }
                }
            }
            // 过滤掉visible:false的内容
            list = list.filter((item) => {
                return item.visible;
            });
            return list;
        } else {
            return list;
        }
    }

    // 渲染表格单元格（分类名称）
    _renderInputCell = (text, item) => {
        const depth = item.depth;
        let left = '0px';
        if (depth === 2 || depth === 'secondAdd') {
            left = '40px';
        } else if (depth === 3 || depth === 'thirdAdd') {
            left = '100px';
        }
        if (depth === 1) {
            return (
                <div className={styles.textCell}>
                    <div>
                        {text}
                    </div>
                </div>
            );
        } else if (depth === 2 || depth === 3) {
            return (
                <div className={styles.textCell}>
                    <div style={{ marginLeft: left }} className={styles.icon}></div>
                    <div>
                        {text}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    render() {
        const { paginationChange, treeOpen } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList, treeOpen)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableData.defaultProps = {
    treeOpen: true
};

TableData.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
    treeOpen: PropTypes.bool, // 表格树形结构状态（展开：true，收起：false）
};

export default TableData;