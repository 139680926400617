import React from 'react';
import styles from './TableData.module.scss';
import { regFenToYuan } from '@/util/money';
import { tooltipStyle, tooltip, tooltipClick, priceFenToYuanCell } from '@/components/TableCell';

const columns = (actionClickHandle) => [
  {
    title: '订单号',
    dataIndex: 'orderId',
    key: 'orderId',
    width: 140,
    align: 'center',
    render: (orderId, record) => tooltipClick(orderId, () => actionClickHandle(record, 'DETAIL'))
  },
  {
    title: '商品名称',
    dataIndex: 'goodsName',
    key: 'goodsName',
    width: 200,
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '手机号',
    dataIndex: 'accountTel',
    key: 'accountTel',
    width: 140,
    align: 'center',
    render: tooltip
  },
  {
    title: '认购数量',
    dataIndex: 'number',
    key: 'number',
    width: 140,
    align: 'center',
    render: tooltip
  },
  {
    title: '商品单位',
    dataIndex: 'unit',
    key: 'unit',
    width: 140,
    align: 'center',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '商品单价(元)',
    dataIndex: 'goodsPrice',
    key: 'goodsPrice',
    width: 140,
    align: 'center',
    render: priceFenToYuanCell
  },
  {
    title: '订单金额(元)',
    dataIndex: 'totalFee',
    key: 'totalFee',
    width: 140,
    align: 'center',
    render: priceFenToYuanCell
  },
  {
    title: '订单状态',
    dataIndex: 'status',
    key: 'status',
    width: 140,
    align: 'center',
    render: tooltip
  },
  {
    title: '订单来源',
    dataIndex: 'source.value',
    key: 'source.value',
    width: 140,
    align: 'center',
    render: tooltip
  },
  {
    title: '下单时间',
    dataIndex: 'gmtCreate',
    key: 'gmtCreate',
    width: 140,
    align: 'center',
    render: tooltip
  },
  {
    title: '订金支付时间',
    dataIndex: 'depositPayTime',
    key: 'depositPayTime',
    width: 140,
    align: 'center',
    render: tooltip
  },
  {
    title: '尾款支付时间',
    dataIndex: 'balancePayTime',
    key: 'balancePayTime',
    width: 140,
    align: 'center',
    render: tooltip
  },
  {
    title: '结算时间',
    dataIndex: 'settleTime',
    key: 'settleTime',
    width: 140,
    align: 'center',
    render: tooltip
  },
  {
    title: '操作',
    dataIndex: 'orderId',
    key: 'orderId',
    align: 'center',
    width: 140,
    render: (text, item) => TableAction(item, actionClickHandle)
  },
];

export const takeColumns = (actionClickHandle) => {
  return columns(actionClickHandle);
};

const TableAction = (record, actionClickHandle) => {
  return (
    <div className={styles.action}>
      <div className={styles.item} onClick={() => actionClickHandle(record, 'DETAIL')}>查看详情</div>
    </div>
  );
};