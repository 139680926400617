import * as T from './actiontypes';

export const getCollectMoneyList = (value) => ({
    type: T.GET_COLLECT_LIST,
    payload: value
});

export const getCollectMoneyInfo = (value) => ({
    type: T.GET_COLLECT_INFO,
    payload: value
});
