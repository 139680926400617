import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { XTabNav, XOKButton } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import SetBusinessCategory from '../../modal/SetBusinessCategory';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { Modal, Input, InputNumber, message, Tree } from 'antd';

const { TextArea } = Input;
const { TreeNode } = Tree;


const Main = ({
    addOneBreadcrumbPath,
    history,
    keepSecondNavBreadcrumb
}) => {

    const [loading, setLoading] = useState(false);
    const [curTabID, setCurTabID] = useState(1);
    const [treeData, setTreeData] = useState([]);
    const [treeList, setTreeList] = useState([]);
    const [currentOpenMode, setCurrentOpenMode] = useState();
    const tab = [
        { id: 1, label: '线上商品分类' },
        // { id: 2, label: '同城商品分类' },
    ];


    useEffect(() => {
        getData(curTabID);
    }, []);

    const tabChangeHandle = (req) => {
        setCurTabID(req);
        setTreeData();
        getData(req);
    };

    const getData = (tabId) => {
        http('/goods/category/listCategoryItem', {
            categorySubType: tabId == 1 ? 'ON_LINE' : 'CITY',
            pageSize: 1000,
        }, 'POST')
            .then(res => {
                setTreeData(res.result.dataList);
            }).catch((e = {}) => {
                message.error(e.message);
            });
    };

    const renderTreeNodes = (data) => {
        if (!data) return;
        let list = data.map(item => {
            if (item.categoryInfoList) {
                return (
                    <TreeNode title={item.categoryName} key={item.id} dataRef={item}>
                        {renderTreeNodes(item.categoryInfoList)}
                    </TreeNode>
                );
            }
            return <TreeNode title={item.categoryName} key={item.id} />;
        });
        return list;
    };

    // 打开弹窗
    const renderMode = () => {
        const modals = {
            SetBusinessCategory: <SetBusinessCategory closeMode={closeMode} />,
        };
        return modals[currentOpenMode];
    };

    const closeMode = () => {
        setCurrentOpenMode('');
    };

    const openBusinessCategory = () => {
        setCurrentOpenMode('SetBusinessCategory');
    };

    const openApplyList = () => {
        const baseUrl = '/home/systemmanage/businessCategory/businessCategoryList';
        addOneBreadcrumbPath({
            title:'申请记录',
            path: `${baseUrl}`
        });
        history.push({
            pathname: `${baseUrl}`
        });
    };

    return (
        <div className={styles.flexBoxContainer}>
            <XTabNav renderData={tab} onChange={tabChangeHandle} activedID={curTabID} />
            <div className={styles.itemButton}>
                <XOKButton
                    style={{ marginTop: '20px', width: '120px' }}
                    label='申请经营品类'
                    onClick={openBusinessCategory}
                />
                <XOKButton
                    style={{ marginTop: '20px', marginLeft: '20px', width: '120px' }}
                    label='申请记录'
                    onClick={openApplyList}
                />
            </div>
            <div className={styles.treeItem}>
                {treeData && treeData.length > 0 ? <Tree>
                    {renderTreeNodes(treeData)}
                </Tree> : <div className={styles.noData}>暂无分类数据</div>}
            </div>
            {renderMode()}
        </div>
    );
};

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);