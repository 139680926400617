/**
 * 终止合作弹框
 * */
import React from 'react';
import { Modal, Radio } from 'antd';
import { XInput, XInputNum, XSelect, XTextArea } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './NewWarehouse.module.scss';
import { connect } from 'react-redux';
import { getWarehouseType, getListAreaByParentId } from '@/reducers/suppliermanage/supplierlist/actions';
import http from '@/assets/api/http';
import { message } from 'antd';
import md5 from 'js-md5';

const RadioGroup = Radio.Group;
class NewWarehouse extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
            warehouseSort: 0, // 排序
            warehouseName: '', // 仓库名称
            accountName: '', // 管理员账号
            loginPwd: '', // 账号密码
            warehouseType: 'GENERAL_WAREHOUSE', // 仓库类型
            province: {}, // 省份
            city: {}, // 城市
            street: {}, // 区域
            addrDetail: '', // 详细地址
            contactName: '', // 联系人
            contactTelephone: '', // 联系电话
            warehouseRemark: '', // 备注
        };
    }

    componentDidMount() {
        this.props.getWarehouseType();
        this.props.getListAreaByParentId({ id: 100000, type: 'province' });
    }

    // 确认
    _handleOk = () => {
        const { warehouseSort, warehouseName, accountName, loginPwd, warehouseType, province, city, street, addrDetail, contactName, contactTelephone, warehouseRemark } = this.state;
        const provinceId = province.id ? province.id : '';
        const cityId = city.id ? city.id : '';
        const areaId = street.id ? street.id : '';
        const accountInfo = { accountName, loginPwd: md5(loginPwd).toUpperCase(), };
        const { modeParam } = this.props;

        const data = {
            warehouseSort,
            warehouseName,
            accountInfo,
            warehouseType,
            provinceId,
            cityId,
            areaId,
            addrDetail,
            contactName,
            contactTelephone,
            warehouseRemark,
            supplierId: modeParam
        };
        http('/merchant/warehouse/save', data, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('创建成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode();
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // 仓库类型单选
    _radioChangeHandle = (e) => {
        this.setState({ warehouseType: e.target.value });
    }

    // 省份输入
    _provinceChangeHandle = (selectedItem) => {
        this.setState({ province: selectedItem });
        this.props.getListAreaByParentId({ id: selectedItem.id, type: 'city' });
    }

    // 城市输入
    _cityChangeHandle = (selectedItem) => {
        this.setState({ city: selectedItem });
        this.props.getListAreaByParentId({ id: selectedItem.id, type: 'street' });
    }

    // 区域输入
    _streetChangeHandle = (selectedItem) => {
        this.setState({ street: selectedItem });
    }

    // 渲染单选框
    _renderRadio = (list) => {
        return list.map((item) => {
            return <Radio key={item.code} value={item.code}>{item.value}</Radio>;
        });
    }

    render() {
        const { visible, confirmLoading, warehouseSort, warehouseName, accountName, loginPwd, warehouseType, addrDetail, contactName, contactTelephone, warehouseRemark } = this.state;
        const { warehouseTypeEnum, address } = this.props;
        const { province, city, street } = address;

        return (
            <Modal
                width={800}
                centered
                title='新建仓库'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInputNum
                        style={{ width: '430px', marginLeft: '30px' }}
                        inputStyle={{ width: '380px' }}
                        label='排序'
                        placeholder='请输入排序'
                        value={warehouseSort}
                        bindThis={this}
                        bindName='warehouseSort'
                        isRequired={true}
                        min={0}
                    />
                    <XInput
                        style={{ width: '456px', marginLeft: '2px', marginTop: '20px' }}
                        inputStyle={{ width: '380px' }}
                        label='仓库名称'
                        placeholder='请输入仓库名称'
                        value={warehouseName}
                        bindThis={this}
                        bindName='warehouseName'
                        isRequired={true}
                    />
                    <XInput
                        style={{ width: '470px', marginLeft: '-12px', marginTop: '20px' }}
                        inputStyle={{ width: '380px' }}
                        label='管理员账号'
                        placeholder='请输入管理员账号'
                        value={accountName}
                        bindThis={this}
                        bindName='accountName'
                        isRequired={true}
                    />
                    <XInput
                        style={{ width: '460px', marginLeft: '2px', marginTop: '20px' }}
                        inputStyle={{ width: '380px' }}
                        label='账号密码'
                        placeholder='请输入账号密码'
                        value={loginPwd}
                        bindThis={this}
                        bindName='loginPwd'
                        isRequired={true}
                    />
                    <div className={styles.reason}>
                        <div>仓库类型<span style={{ color: 'red' }}>*</span></div>
                        <RadioGroup style={{ marginLeft: '8px' }} onChange={this._radioChangeHandle} value={warehouseType}>
                            {this._renderRadio(warehouseTypeEnum)}
                        </RadioGroup>
                    </div>
                    <div className={styles.address}>
                        <XSelect
                            style={{ width: '278px' }}
                            selectStyle={{ width: '200px' }}
                            label='发货地址'
                            placeholder='省份'
                            renderData={province}
                            onChange={this._provinceChangeHandle}
                            dataIndex='areaName'
                            keyIndex='id'
                            value={this.state.province.areaName}
                            isRequired={true}
                        />
                        <XSelect
                            selectStyle={{ width: '200px' }}
                            placeholder='城市'
                            renderData={city}
                            onChange={this._cityChangeHandle}
                            dataIndex='areaName'
                            keyIndex='id'
                            value={this.state.city.areaName}
                        />
                        <XSelect
                            selectStyle={{ width: '200px' }}
                            placeholder='区域'
                            renderData={street}
                            onChange={this._streetChangeHandle}
                            dataIndex='areaName'
                            keyIndex='id'
                            value={this.state.street.areaName}
                        />
                    </div>
                    <XInput
                        style={{ width: '460px', marginLeft: '0px', marginTop: '20px' }}
                        inputStyle={{ width: '380px' }}
                        label='详情地址'
                        placeholder='请输入详情地址'
                        value={addrDetail}
                        bindThis={this}
                        bindName='addrDetail'
                        isRequired={true}
                    />
                    <XInput
                        style={{ width: '460px', marginLeft: '14px', marginTop: '20px' }}
                        inputStyle={{ width: '380px' }}
                        label='联系人'
                        placeholder='请输入联系人'
                        value={contactName}
                        bindThis={this}
                        bindName='contactName'
                        isRequired={true}
                    />
                    <XInput
                        style={{ width: '460px', marginLeft: '0px', marginTop: '20px' }}
                        inputStyle={{ width: '380px' }}
                        label='联系电话'
                        placeholder='请输入联系电话'
                        value={contactTelephone}
                        bindThis={this}
                        bindName='contactTelephone'
                        isRequired={true}
                    />
                    <XTextArea
                        style={{ width: '385px', marginLeft: '33px', marginTop: '20px' }}
                        inputStyle={{ width: '335px', resize: 'none' }}
                        label='备注'
                        placeholder='请输入备注'
                        value={warehouseRemark}
                        bindThis={this}
                        bindName='warehouseRemark'
                    />
                </div>
            </Modal>
        );
    }
}

NewWarehouse.propTypes = {
    visible: PropTypes.bool.isRequired, // 弹框是否可见
    closeMode: PropTypes.func.isRequired, // 关闭弹框
    modeParam: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]), // 弹框入参
    getWarehouseType: PropTypes.func, // 获取仓库类型
    warehouseTypeEnum: PropTypes.array, // 仓库类型枚举
    getListAreaByParentId: PropTypes.func, // 获取省市区
    address: PropTypes.object, // 省市区
};

const mapStateToProps = (state) => ({
    address: state.supplierlist.address,
    warehouseTypeEnum: state.supplierlist.getWarehouseType
});

export default connect(mapStateToProps, { getWarehouseType, getListAreaByParentId })(NewWarehouse);
