/**
 * 官网列表
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import TableContent from './TableContent';
import model from '@/reducers/gdcmanage/gdclist/model';
import EditModel from './EditModel';

class Main extends React.Component {

    state = {
        operation: {},
        company: {},
        openEdit: false,
        openEditData: {}
    };

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getReport();
    }

    // 表格操作
    _tableAction = (type, record) => {
        const { websiteUrl } = record;
        switch (type) {
            case 'edit':
                this.setState({ openEditData: record, openEdit: true });
                break;
            case 'open':
                window.open(websiteUrl);
                break;
        }
    }

    // 渲染弹框
    _renderMode = () => {
        const { openEdit, openEditData } = this.state;
        if (openEdit) {
            return <EditModel visible={true} data={openEditData} closeMode={this._closeMode} />;
        }
        return null;
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ openEditData: {}, openEdit: false });
        if (update) {
            this.props.getReport();
        }
    }

    render() {
        const { report } = this.props;

        return (
            <div className={styles.flexBoxContainer}>
                <TableContent renderData={report} tableAction={this._tableAction} />
                {this._renderMode()}
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getReport: PropTypes.func,
    report: PropTypes.array,
};
const mapStateToProps = (state) => ({
    report: state.gdclist.getReport,
});
export default connect(mapStateToProps, { ...model.actions, keepSecondNavBreadcrumb })(Main);