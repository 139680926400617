import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, priceFenToYuanCell, ImgCell } from '@/components/TableCell';

class TableContent extends React.Component {

    state = {
        selectedRowKeys: [], // 选中的单元行
    };

    _takeColumns = () => ([
        {
            title: '货品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        // {
        //     title: '货品规格ID',
        //     dataIndex: 'skuId',
        //     key: 'skuId',
        //     width: '10%',
        //     align: 'center',
        //     render: (text) => (this._renderTextCell(text))
        // },
        {
            title: '商品货号',
            dataIndex: 'goodsNo',
            key: 'goodsNo',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '库存量',
            dataIndex: 'invQuantity',
            key: 'invQuantity',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        // {
        //     title: '结算价',
        //     dataIndex: 'costPrice',
        //     key: 'costPrice',
        //     width: '13%',
        //     align: 'center',
        //     render: priceFenToYuanCell
        // },
        {
            title: '状态',
            dataIndex: 'invUpdateState',
            key: 'invUpdateState',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '备注',
            dataIndex: 'invUpdateRemark',
            key: 'invUpdateRemark',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '更新时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        // {
        //     title: '操作',
        //     dataIndex: 'outerSkuId',
        //     key: 'outerSkuId',
        //     align: 'center',
        //     width: '10%',
        //     render: (text, item) => this._renderActionCell(text)
        // }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderImgCell = (url) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <div className={styles.imgbox}>
                    <img className={styles.img} src={url} />
                </div>
            </div>
        );
    };

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderNumberCell = (text) => {
        const price = parseInt(text) / 100;
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {price}
            </div>
        );
    };

    _renderActionCell = (key, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'update', record)}>更新库存</div>
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
            this.props.tableAction(null, 'batch',selectedRowKeys);
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
        this.props.tableAction(null, 'batch', []);
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={record => record.outerSkuId}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;