import * as T from './actiontypes';

/**
 * 出库单列表
 */
const plistInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

// 已分配出库单
export const invoiceList = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_INVOICELIST:
            return payload.result;
        default:
            return state;
    }
};

// 发货清单
export const deliverListTableSource = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_DELIVERLIST_TABLESOURCE:
            return payload.result;
        default:
            return state;
    }
};

// 对账单
export const validateStatementTableSource = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_VALIDATESTATEMENT_TABLESOURCE:
            return payload.result;
        default:
            return state;
    }
};

// 对账详情
export const validateStatementDetail = (state = {}, { type, payload }) => {
    switch (type) {
        case T.SET_VALIDATESTATEMENT_DETAIL:
            return payload.result;
        default:
            return state;
    }
};

// 对账详情
export const validateStatementDetailTableSource = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_VALIDATESTATEMENT_DETAIL_TABLESOURCE:
            return payload.result;
        default:
            return state;
    }
};

export const supplierSelectSource = (state = [], { type, payload }) => {
    switch (type) {
        case T.SET_SUPPLISER_SELECTSOURCE:
            return payload.result;
        default:
            return state;
    }
};

// 合同管理
export const contractManageTableSource = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_CONTRACTMANAGE_TABLESOURCE:
            return payload.result;
        default:
            return state;
    }
};

export const contractDetail = (state = {}, { type, payload }) => {
    switch (type) {
        case T.SET_CONTRACTDETAIL:
            return payload.result;
        default:
            return state;
    }
};

export const contractStates = (state = [], { type, payload }) => {
    switch (type) {
        case T.SET_CONTRACTSTATES:
            return payload.result;
        default:
            return state;
    }
};