/**
 * 集采结算
 * */
import React from 'react';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import ReadySettle from '../readysettle';
import AlreadySettle from '../alreadysettle';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: [
                { id: 1, label: '待结算' },
                { id: 2, label: '已结算' },
            ],
            curTabID: 1
        };
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        switch (this.state.curTabID) {
            case 1:
                return <ReadySettle curTabID={this.state.curTabID} />;
            case 2:
                return <AlreadySettle curTabID={this.state.curTabID} />;
            default:
                return <ReadySettle curTabID={this.state.curTabID} />;
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={`${styles.page} ${styles.flexBoxContainer}`}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

export default Main;