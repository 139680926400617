import React from 'react';
import styles from './OrderSearch.module.scss';
import moment from 'moment';
import PropTypes from 'prop-types';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker } from '@/components/xqxc_ui';
import ConsumeTypes from '@/vcomps/ConsumeTypes';
import { connect } from 'react-redux';
import model from '@/reducers/ordermanage/wholesaleorder/model';
import { getDistributionFlagEnum } from '@/reducers/salemanage/saleorder/actions';
import { getProductOrderType, getProductOrderStatus, getProductOrderFlagEnum } from '@/reducers/ordermanage/productOrder/actions';
import { message } from 'antd';

// const orderStateSelect = [{ id: 1, name: '待付款' }, { id: 2, name: '待发款' }];
// const orderTypeSelect = [{ id: 1, name: '普通订单' }, { id: 2, name: '报税订单' }];
// const invoiceSelect = [{ id: 1, name: '是' }, { id: 0, name: '否' }];

class OrderSearch extends React.Component {

    state = {
        telephone: '', // 会员手机
        orderCode: '', // 订单编号
        orderStartTime: undefined, // 下单时间开始日期
        orderEndTime: undefined, // 下单结束日期
        payTimeF: undefined, // 支付时间开始日期
        payTimeT: undefined, // 支付结束日期
        orderState: {}, // 订单状态
        orderType: {}, // 订单类型
        invoice: {}, // 是否开票
        productName: '', // 商品名称
        productCode: '', // 商品货号
        consumeType: {},
        ismerchantSponsorFlag: { code: 2, value: '全部' }, // 是否引流
        payType: {}, // 支付方式
        // payItemId: '', // 支付流水号
    }

    componentDidMount() {
        this.props.getProductOrderType();
        this.props.getProductOrderStatus();
        this.props.getProductOrderFlagEnum();
        // 引流
        this.props.getDistributionFlagEnum();
         // 支付方式
         this.props.getPaymentTypeEnum();
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState(newProps.searchState.param);
    }
    _renderOrderStateSelect = (orderState) => {
        const { productOrderStatus } = this.props;
        if (this.props.orderState == 1) {
            return (
                <XSelect
                    style={{ marginLeft: '20px', width: '248px' }}
                    selectStyle={{ width: '180px', marginLeft: 8 }}
                    label='订单状态'
                    placeholder='请选择订单状态'
                    renderData={productOrderStatus}
                    dataIndex='value'
                    keyIndex='code'
                    value={orderState.value}
                    showSearch={true}
                    bindThis={this}
                    bindName='orderState'
                />
            );
        } else {
            return null;
        }
    }

    // 查询
    _searchHandle = () => {
        const { payType, ismerchantSponsorFlag, telephone, orderCode, orderState, orderType, invoice, productName, productCode, orderStartTime, orderEndTime, payTimeF, payTimeT, consumeType } = this.state;
        const { status } = this.props;
        this.searchCache = {
            telephone,
            orderCode,
            orderState,
            orderType,
            invoice,
            productName,
            productCode,
            orderStartTime,
            orderEndTime,
            payTimeF,
            payTimeT,
            consumeType,
            ismerchantSponsorFlag,
            payType
        };
        this.props.searchOrder({
            accountTel: telephone,
            gmtCreateF: orderStartTime && moment(moment(orderStartTime).format()).format('YYYY-MM-DD HH:mm:ss'),
            gmtCreateT: orderEndTime && moment(moment(orderEndTime).format()).format('YYYY-MM-DD HH:mm:ss'),
            payTimeF: payTimeF && moment(moment(payTimeF).format()).format('YYYY-MM-DD HH:mm:ss'),
            payTimeT: payTimeT && moment(moment(payTimeT).format()).format('YYYY-MM-DD HH:mm:ss'),
            orderId: orderCode,
            goodsName: productName,
            isInvoice: invoice.code ? invoice.code : '',
            itemNo: productCode,
            orderType: orderType.code,
            statusList: orderState.code ? [orderState.code] : status,
            consumeType: consumeType.code,
            merchantSponsorFlag: ismerchantSponsorFlag.code ? ismerchantSponsorFlag.code == 2 ? '' : ismerchantSponsorFlag.code : '',
            payType: payType.code
        }, this.searchCache);
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            telephone: '',
            orderCode: '',
            orderStartTime: undefined,
            orderEndTime: undefined,
            payTimeF: undefined,
            payTimeT: undefined,
            orderState: {},
            orderType: {},
            invoice: {},
            productName: '',
            productCode: '',
            consumeType: {},
            payType: {},
            ismerchantSponsorFlag: { code: 2, value: '全部' },
        });
        this.searchCache = {
            telephone: '',
            orderCode: '',
            orderStartTime: undefined,
            orderEndTime: undefined,
            payTimeF: undefined,
            payTimeT: undefined,
            orderState: {},
            orderType: {},
            invoice: {},
            productName: '',
            productCode: '',
            consumeType: {},
            payType: {},
            ismerchantSponsorFlag: { code: 2, value: '全部' },
        };
        this.props.searchOrder({}, this.searchCache, true);
    }

    render() {
        const { payType, ismerchantSponsorFlag, telephone, orderCode, orderStartTime, orderEndTime, payTimeF, payTimeT, orderState, orderType, invoice, productName, productCode, consumeType } = this.state;
        const { DistributionFlagEnum, productOrderType, productOrderFlagEnum, paymentTypeEnum } = this.props;
        return (
            <div className={styles.container}>
                <div className={styles.row1}>
                    <XInput
                        style={{ width: '248px' }}
                        inputStyle={{ width: '180px' }}
                        label='会员手机'
                        placeholder='请输入手机号码'
                        value={telephone}
                        bindThis={this}
                        bindName='telephone'
                    />
                    <XInput
                        style={{ width: '248px', marginLeft: '20px' }}
                        inputStyle={{ width: '180px' }}
                        label='订单编号'
                        placeholder='请输入订单编号'
                        value={orderCode}
                        bindThis={this}
                        bindName='orderCode'
                    />
                    {/* <XInput
                        style={{ marginLeft: 13, marginRight: 10 }}
                        inputStyle={{ width: '180px' }}
                        label='支付流水号'
                        placeholder='请输入支付流水号'
                        value={payItemId}
                        bindThis={this}
                        bindName='payItemId'
                    /> */}
                    {this._renderOrderStateSelect(orderState)}
                    <XSelect
                        style={{ marginLeft: '20px', width: '218px' }}
                        selectStyle={{ width: '150px' }}
                        label='订单类型'
                        placeholder='请选择订单类型'
                        renderData={productOrderType}
                        dataIndex='value'
                        keyIndex='code'
                        value={orderType.value}
                        showSearch={true}
                        bindThis={this}
                        bindName='orderType'
                    />
                </div>
                <div className={styles.row2}>
                    <XSelect
                        style={{ width: '248px' }}
                        selectStyle={{ width: '180px' }}
                        label='是否开票'
                        placeholder='请选择是否开票'
                        renderData={productOrderFlagEnum}
                        dataIndex='value'
                        keyIndex='code'
                        value={invoice.value}
                        showSearch={true}
                        bindThis={this}
                        bindName='invoice'
                    />
                    <XInput
                        style={{ width: '248px', marginLeft: '20px' }}
                        inputStyle={{ width: '180px' }}
                        label='商品名称'
                        placeholder='请输入商品名称'
                        value={productName}
                        bindThis={this}
                        bindName='productName'
                    />
                    <XInput
                        style={{ width: '248px', marginLeft: '20px' }}
                        inputStyle={{ width: '180px', marginLeft: 8 }}
                        label='商品货号'
                        placeholder='请输入商品货号'
                        value={productCode}
                        bindThis={this}
                        bindName='productCode'
                    />
                    <ConsumeTypes
                        style={{ marginLeft: '22px' }}
                        selectStyle={{ width: '150px' }}
                        label='消费类型'
                        placeholder='请选消费类型'
                        value={consumeType}
                        showSearch={true}
                        bindThis={this}
                        bindName='consumeType'
                    />
                     <XSelect
                        style={{ marginRight: '20px', width: '218px', marginLeft: '20px' }}
                        selectStyle={{ width: '150px' }}
                        label='支付方式'
                        placeholder='请选择支付方式'
                        renderData={paymentTypeEnum}
                        dataIndex='value'
                        keyIndex='code'
                        value={payType.value}
                        showSearch={true}
                        bindThis={this}
                        bindName='payType'
                    />
                </div>
                <div className={styles.row2}>
                    <XSelect
                        style={{ marginRight: '20px', width: '218px' }}
                        selectStyle={{ width: '150px' }}
                        label='商户引流'
                        placeholder='请选择'
                        renderData={DistributionFlagEnum}
                        dataIndex='value'
                        keyIndex='code'
                        value={ismerchantSponsorFlag.value}
                        showSearch={true}
                        bindThis={this}
                        bindName='ismerchantSponsorFlag'
                    />
                    <XDatePicker
                        style={{ width: '264px' }}
                        label='下单时间'
                        value={orderStartTime}
                        bindThis={this}
                        bindName='orderStartTime'
                    />
                    <div className={styles.timeLine} />
                    <XDatePicker
                        label=''
                        value={orderEndTime}
                        bindThis={this}
                        bindName='orderEndTime'
                    />
                    <XDatePicker
                        style={{ width: '264px', marginLeft: '20px' }}
                        label='付款时间'
                        value={payTimeF}
                        bindThis={this}
                        bindName='payTimeF'
                    />
                    <div className={styles.timeLine} />
                    <XDatePicker
                        label=''
                        value={payTimeT}
                        bindThis={this}
                        bindName='payTimeT'
                    />
                    <XOKButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='查询'
                        onClick={this._searchHandle}
                    />
                    <XCancelButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='重置'
                        onClick={this._resetHandle}
                    />
                </div>
            </div>
        );
    }
}

OrderSearch.propTypes = {
    orderState: PropTypes.number,
    searchOrder: PropTypes.func,
    status: PropTypes.array,
    getProductOrderType: PropTypes.func,
    getProductOrderStatus: PropTypes.func,
    getProductOrderFlagEnum: PropTypes.func,

    productOrderType: PropTypes.array,
    productOrderStatus: PropTypes.array,
    productOrderFlagEnum: PropTypes.array,
    getDistributionFlagEnum: PropTypes.func,
    DistributionFlagEnum: PropTypes.array,
    getPaymentTypeEnum: PropTypes.func,
    paymentTypeEnum: PropTypes.array,
};

const mapStateToProps = (state) => ({
    productOrderType: state.productOrder.productOrderType,
    productOrderStatus: state.productOrder.productOrderStatus,
    productOrderFlagEnum: state.productOrder.productOrderFlagEnum,
    DistributionFlagEnum: state.saleorder.DistributionFlagEnum,
    paymentTypeEnum: state.wholesaleOrder.paymentTypeEnum,
});

export default connect(mapStateToProps, { ...model.actions, getProductOrderType, getProductOrderStatus, getProductOrderFlagEnum, getDistributionFlagEnum })(OrderSearch);