import { getWarehouseType, getListAreaByParentId, getSupplierListTableSource, getShopTypes, getGoodsTypes, getInvoicelSelect, getlistServiceProviderKetName, radiotype } from './supplierlist/saga';

export default [
    getSupplierListTableSource,
    getShopTypes,
    getWarehouseType,
    getListAreaByParentId,
    getGoodsTypes,
    getInvoicelSelect,
    getlistServiceProviderKetName,
    radiotype
];
