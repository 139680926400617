import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Radio, Table, message } from 'antd';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';
import styles from './BuyPopularity.module.scss';

const Mode = {
    Start: 1,
    Stop: 0,

    Null: null,
};

const RadioBtn = (onRadioChange, value, id) => <Radio checked={value == id} onClick={() => onRadioChange(id)}></Radio>;

function getColumns(onRadioChange, value) {
    return [{
        title: '选择',
        key: 'id',
        dataIndex: 'id',
        width: 50,
        render: id => RadioBtn(onRadioChange, value, id)
    }, {
        title: '规则封面',
        key: 'coverPage',
        dataIndex: 'coverPage',
        width: 70,
        render: ImgCell
    }, {
        title: '规则名称',
        key: 'virtualName',
        dataIndex: 'virtualName',
        width: 150,
        onCell: tooltipStyle,
        render: tooltip
    }, {
        title: '触发条件',
        key: 'virtualDesc',
        dataIndex: 'virtualDesc',
        width: 350,
        onCell: tooltipStyle,
        render: tooltip
    }, {
        title: '魅力值',
        key: 'starlightNumber',
        dataIndex: 'starlightNumber',
        width: 100,
    }, {
        title: '已关联',
        key: 'isExist',
        dataIndex: 'isExist',
        rendre: (text) => ({ 1: '是', 0: '否' }[text || '0'])
    }];
}


const BuyPopularity = ({
    value,
    defaultValue,
    onChange
}) => {

    const [modeState, setModeState] = useState(value ? Mode.Start : Mode.Stop);
    value && modeState === Mode.Stop && setModeState(Mode.Start);

    const [dataSource, setDataSource] = useState([]);

    function onRadioChange(id) {
        onChange && onChange(id);
    }

    function onModeChange(e) {
        setModeState(e.target.value);
        onChange && onChange(e.target.value === Mode.Start ? defaultValue : undefined);
    }

    useEffect(() => {
        http('/liveCharge/createLiveChargeVirtualConfigList', {})
            .then(res => {
                if (res.code === 200) {
                    setDataSource(res.result);
                }
            })
            .catch(() => message.error('加载异常'));
    }, []);

    const dataList = value && defaultValue ? dataSource.filter(v => v.id == value) : dataSource;

    return (
        <div>
            <Radio.Group value={modeState} onChange={onModeChange} style={{ marginBottom: 15 }}>
                <Radio value={Mode.Start}>是</Radio>
                <Radio value={Mode.Stop}>否</Radio>
            </Radio.Group>
            {modeState === Mode.Start
                ? <Table columns={getColumns(onRadioChange, value)} dataSource={dataList} pagination={false} />
                : null
            }
        </div>
    );
};

BuyPopularity.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object,
    defaultValue: PropTypes.any
};

export { BuyPopularity };