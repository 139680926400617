import * as T from './actiontypes';

/**
 * 查询蚁购余额明细
 */
export const getAntBuyBalanceDetail = (value = {}) => ({
    type: T.GET_ACCOUNT_LIST,
    payload: value
});

