import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { getIsMarketingPromise } from '@/assets/js/authType';

class TableContent extends React.Component {

    state = {
        selectedRowKeys: [], // 选中的单元行
        cols: [
            {
                title: '店铺',
                dataIndex: 'shopName',
                key: 'shopName',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '交易订单号',
                dataIndex: 'orderId',
                key: 'orderId',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '支付流水号',
                dataIndex: 'payItemId',
                key: 'payItemId',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '收付款日期',
                dataIndex: 'payTime',
                key: 'payTime',
                width: 180,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '商品名称',
                dataIndex: 'goodsName',
                key: 'goodsName',
                align: 'center',
                width: 250,
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '货号',
                dataIndex: 'itemNo',
                key: 'itemNo',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '数量',
                dataIndex: 'goodsNumber',
                key: 'goodsNumber',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '零售价(元)',
                dataIndex: 'retailPrice',
                key: 'retailPrice',
                width: 140,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '人气值',
                dataIndex: 'starDiscount',
                key: 'starDiscount',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '实付金额(元)',
                dataIndex: 'realPrice',
                key: 'realPrice',
                width: 140,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '付款渠道',
                dataIndex: 'payType',
                key: 'payType',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '技术服务费(元)',
                dataIndex: 'serviceFee',
                key: 'serviceFee',
                width: 140,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '平台服务费(元)',
                dataIndex: 'distributionServiceFee',
                key: 'distributionServiceFee',
                width: 140,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '秀场服务费(元)',
                dataIndex: 'showServiceFee',
                key: 'showServiceFee',
                width: 140,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '免单均摊金额(元)',
                dataIndex: 'freeOfCharge',
                key: 'freeOfCharge',
                width: 140,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '实际应收付金额（元）',
                dataIndex: 'unSetted',
                key: 'unSetted',
                width: 140,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '营销服务费（元）',
                dataIndex: 'marketServiceFee',
                key: 'marketServiceFee',
                width: 140,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '售后单号',
                dataIndex: 'bizId',
                key: 'bizId',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '结算状态',
                dataIndex: 'settleType',
                key: 'settleType',
                width: 140,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            }
        ]
    };

    componentDidMount() {
        getIsMarketingPromise().then(res => {
            if (!res) {
                this.state.cols.splice(16, 1);
                this.setState({ cols: this.state.cols.concat() });
            }
        });
    }

    _takeDataSource = (dataList = []) => {
        return dataList;
    }

    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderNumberCell = (text) => {
        const price = parseInt(text) / 100;
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {price}
            </div>
        );
    };

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { cols } = this.state;
        // this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={cols}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: '200%', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;