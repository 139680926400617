import { createModel } from '@/store/tools';

const List = {
    dataList: [],
    pageNum: 1,
    pageSize: 100,
    resultTotal: 0
};

const model = {
    namespace: 'goodsGenlock',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getGoodsGenlockData': T('getGoodsGenlockData'),
            'getGoodsStateEnum': T('getGoodsStateEnum'),
        },
        actions: {
            'getGoodsGenlockData': A('getGoodsGenlockData'),
            'getGoodsStateEnum': A('getGoodsStateEnum'),
        },
        sagas: {
            'getGoodsGenlockData': S('getGoodsGenlockData', '/goods/inventory/sync/lock/list'),
            'getGoodsStateEnum': S('getGoodsStateEnum', '/enum/GoodsStateEnum'),
        },
        reducers: {
            'goodsGenlockData': R('getGoodsGenlockData', List),
            'goodsStateEnum': R('getGoodsStateEnum', []),
        }
    })
};
export default createModel(model);