/**
 * 填写基础信息-银联
 */
import React from 'react';
import styles from './step.module.scss';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as AllActions from '@/reducers/home/actions';
import { Form, Radio, Button, Input, message, Icon, DatePicker, Select, Spin } from 'antd';
import { XMediaFn } from '@/components/xqxc_ui';
import CustomUpload, { generateFileList } from '@/components/NewUpload';
import getUnionInfo from './../components/getUnionInfo';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

// 上传银联照片信息
// 身份证正面图片名称(0001)
// 身份证反面图片名称(0011)
// 商户营业执照(0002)
// 门头照片(0005)
// 室内照片(0015)
// 辅助证明材料-银行卡(0008)

// state = {
//     loading: false, // 加载中
//     st: '', // S1企业1证、S3企业3证、P个人 
//     required: true,
//     unitType: 'BUSINESS', // 商户类型：PERSON个人、BUSINESS企业
//     authType: '2', // 认证类型：2一证、1三证
//     companyName: '', // 单位名称
//     companyAddress: '', // 注册地址
//     taxNum: '', // 统一社会信用证编号
//     taxUrl: [], // 统一社会信用证编号图片url
//     taxTime: null, // 统一社会信用证编号有效期
//     bankCardUrl: [], // 银行开户证明、银行卡证明照片
//     idNo: '', // 企业营业执照号
//     idNoUrl: [], // 营业执照号图片url
//     idNoTime: null, // 营业执照号有效期
//     taxCert: '', // 税务登记号
//     taxCertUrl: [], // 税务登记号图片url
//     orgCode: '', // 组织机构代码
//     orgCodeUrl: [], // 组织机构代码图片url
//     phone: '', // 联系电话
//     corpName: '', // 法人姓名
//     mobile: '', // 法人手机
//     legPerId: '', // 法人身份证号
//     identity: { legPerIdFrontUrl: [], legPerIdBackUrl: [] }, // 身份证正反面
//     shopImg: { shopFrontUrl: [], shopIndoorUrl: [] }, // 门头照片、室内照片
//     bankCardNo: '', // 银行卡账号
//     proviceList: [], // 省份下拉列
//     proviceData: { code: '', areaName: undefined }, // 省份选项
//     cityList: [], // 城市下拉列
//     cityData: { code: '', areaName: undefined }, // 城市选项
//     areaList: [], // 区域下拉列
//     areaData: { code: '', areaName: undefined }, // 区域选项
//     email: '', // 收件人邮箱地址
//     invoiceAddress: '', // 收件人地址
//     invoiceName: '', // 收票人姓名
//     invoicePhone: '', // 收票人手机
//     branchInputBo: false, // 开户支行名称是否通过手动输入
//     branchList: [],  // 开户行支行数据
//     branchBankName: undefined, // 开户支行名称
//     unionBank: '', // 12位数字，支行号
//     fetching: false, // 开户银行动态查询loading
//     picList: [],
//     picEnum: [
//         { document_type: '0002', document_name: '商户营业执照' },
//         { document_type: '0001', document_name: '身份证正面图片名称' },
//         { document_type: '0011', document_name: '身份证反面图片名称' },
//         { document_type: '0005', document_name: '门头照片' },
//         { document_type: '0015', document_name: '室内照片' },
//         { document_type: '0007', document_name: '手持身份证自拍照' },
//         { document_type: '0008', document_name: '辅助证明材料(银行卡)' },
//     ],
//     auditSuccess: true,
//     errorMessage: ''
// }

const Option = Select.Option;
const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 10 },
        sm: { span: 10 },
    },
};

const RadioGroup = Radio.Group;
class step extends React.Component {

    timeCount = 0;
    timeID = null;
    state = {
        loading: false, // 加载中
        st: '', // S1企业1证、S3企业3证、P个人 
        required: true,
        unitType: 'BUSINESS', // 商户类型：PERSON个人、BUSINESS企业  
        authType: '2', // 认证类型：2一证、1三证
        companyName: '', // 单位名称
        companyAddress: '', // 注册地址
        taxNum: '', // 统一社会信用证编号
        taxUrl: [], // 统一社会信用证编号图片url
        taxTime: null, // 统一社会信用证编号有效期
        bankCardUrl: [], // 银行开户证明、银行卡证明照片
        idNo: '', // 企业营业执照号
        idNoUrl: [], // 营业执照号图片url
        idNoTime: null, // 营业执照号有效期
        taxCert: '', // 税务登记号
        taxCertUrl: [], // 税务登记号图片url
        orgCode: '', // 组织机构代码
        orgCodeUrl: [], // 组织机构代码图片url
        phone: '', // 联系电话
        corpName: '', // 法人姓名
        mobile: '', // 法人手机
        legPerId: '', // 法人身份证号
        identity: { legPerIdFrontUrl: [], legPerIdBackUrl: [], idInHandUrl: [] }, // 身份证正面、反面、手持身份证
        shopImg: { shopFrontUrl: [], shopIndoorUrl: [] }, // 门头照片、室内照片
        otherImgUrl: [], // 其它材料
        bankCardNo: '', // 银行卡账号
        proviceList: [], // 省份下拉列
        proviceData: { code: '', areaName: undefined }, // 省份选项
        cityList: [], // 城市下拉列
        cityData: { code: '', areaName: undefined }, // 城市选项
        areaList: [], // 区域下拉列
        areaData: { code: '', areaName: undefined }, // 区域选项
        email: '', // 收件人邮箱地址
        invoiceAddress: '', // 收件人地址
        invoiceName: '', // 收票人姓名
        invoicePhone: '', // 收票人手机
        branchInputBo: false, // 开户支行名称是否通过手动输入
        branchList: [],  // 开户行支行数据
        branchBankName: undefined, // 开户支行名称
        unionBank: '', // 12位数字，支行号
        fetching: false, // 开户银行动态查询loading
        picList: [],
        picEnum: [
            { document_type: '0002', document_name: '商户营业执照' },
            { document_type: '0001', document_name: '身份证正面图片名称' },
            { document_type: '0011', document_name: '身份证反面图片名称' },
            { document_type: '0005', document_name: '门头照片' },
            { document_type: '0015', document_name: '室内照片' },
            { document_type: '0007', document_name: '手持身份证自拍照' },
            { document_type: '0008', document_name: '辅助证明材料(银行卡)' },
            { document_type: '0099', document_name: '其他材料' }
        ],
        auditSuccess: true,
        errorMessage: ''
    }

    componentDidMount() {
        this.props.getRegisterEnum();
        this.props.getYunstAuthTypeEnum();
        this._getAddressList();
        this._showDiffIncome(this.state.unitType, this.state.authType);
        // 如果审核失败，则回填数据
        getUnionInfo().then((response) => {
            const { type, data } = response;
            const { status } = data;
            if (status == 'FAILD') {
                this.setState({ auditSuccess: false });
            }
            this._init(type, status);
        });
    }

    componentWillUnmount() {
        clearInterval(this.timeID);
        this.timeID = null;
    }

    _init = (type, status) => {
        http('/merchant/info/union/getUnionAccountInfo').then((response) => {
            if (response.status == 200) {
                const {
                    authType, unitName, registerAddr, taxNumCode, taxNumCodeUrl, taxNumCodeTime, bankCardUrl, idNo, idNoUrl, idNoTime, taxCert, taxCertUrl,
                    orgCode, orgCodeUrl, registerTel, legalName, legalTel, legalIdentity, legPerIdFrontUrl, legPerIdBackUrl, idInHandUrl, shopFrontUrl, shopIndoorUrl,
                    otherImgUrl, bankAccount, shopProvinceId, shopProvince, shopCityId, shopCity, shopCountryId, shopCountry, receiveMail, receiveAddr, receiveName,
                    receivePhone, bankName, unionBank, bankCode, commitUionRequest, remark
                } = response.result;
                this.setState({
                    unitType: type ? type : 'BUSINESS', // 商户类型
                    authType: authType ? authType : 2, // 认证类型
                    companyName: unitName, // 单位名称
                    companyAddress: registerAddr, // 注册地址
                    taxNum: taxNumCode, // 社会统一信用编号
                    taxUrl: taxNumCodeUrl ? generateFileList(taxNumCodeUrl.toString()) : [], // 统一社会信用证编号图片url
                    taxTime: taxNumCodeTime ? moment(taxNumCodeTime) : null, // 统一社会信用证编号有效期
                    bankCardUrl: bankCardUrl ? generateFileList(bankCardUrl.toString()) : [], // 银行开户证明、银行卡证明照片
                    idNo, // 企业营业执照号
                    idNoUrl: idNoUrl ? generateFileList(idNoUrl.toString()) : [],// 营业执照号图片url
                    idNoTime: idNoTime ? moment(idNoTime) : null, // 营业执照号有效期
                    taxCert, // 税务登记号
                    taxCertUrl: taxCertUrl ? generateFileList(taxCertUrl.toString()) : [], // 税务登记号图片url
                    orgCode, // 组织机构代码
                    orgCodeUrl: orgCodeUrl ? generateFileList(orgCodeUrl.toString()) : [], // 组织机构代码图片url
                    phone: registerTel, // 联系电话
                    corpName: legalName, // 法人姓名
                    mobile: legalTel, // 法人手机
                    legPerId: legalIdentity, // 法人身份证
                    identity: { legPerIdFrontUrl: legPerIdFrontUrl ? generateFileList(legPerIdFrontUrl.toString()) : [], legPerIdBackUrl: legPerIdBackUrl ? generateFileList(legPerIdBackUrl.toString()) : [], idInHandUrl: idInHandUrl ? generateFileList(idInHandUrl.toString()) : [] },
                    shopImg: { shopFrontUrl: shopFrontUrl ? generateFileList(shopFrontUrl.toString()) : [], shopIndoorUrl: shopIndoorUrl ? generateFileList(shopIndoorUrl.toString()) : '' },
                    otherImgUrl: otherImgUrl ? generateFileList(otherImgUrl.toString()) : [],
                    bankCardNo: bankAccount, // 银行账号
                    proviceData: { code: shopProvinceId, areaName: shopProvince ? shopProvince : undefined },
                    cityData: { code: shopCityId, areaName: shopCity ? shopCity : undefined },
                    areaData: { code: shopCountryId, areaName: shopCountry ? shopCountry : undefined },
                    email: receiveMail, // 收件人邮箱
                    invoiceAddress: receiveAddr, // 收件人地址
                    invoiceName: receiveName, // 收票人姓名
                    invoicePhone: receivePhone, // 收票人手机
                    branchBankName: bankName, // 开户行支行名称
                    unionBank: unionBank, // // 12位数字，支行号
                    picList: (commitUionRequest.pic_list && Array.isArray(commitUionRequest.pic_list)) ? commitUionRequest.pic_list : [], // 图片信息
                    errorMessage: remark // 审核失败错误信息
                }, () => {
                    this._showDiffIncome(this.state.unitType, this.state.authType);
                });
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 请求支行下拉列
    _getBankList = (cityId = '', keyword = '') => {
        http('/union/merchant/branchBankList', { cityId, keyword }).then((response) => {
            if (response.status == 200) {
                this.setState({ branchList: response.result, fetching: false });
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 请求省市区
    _getAddressList = (levelType = 1, parentId = undefined) => {
        http('/union/merchant/areaList', { levelType, parentId }).then((response) => {
            if (response.status == 200) {
                if (levelType == 1) {
                    this.setState({ proviceList: response.result });
                } else if (levelType == 2) {
                    this.setState({ cityList: response.result });
                } else if (levelType == 3) {
                    this.setState({ areaList: response.result });
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 下一步
    _handleSubmit = e => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                if (this.state.st == 'S1') {
                    this.httpS1(fieldsValue);
                } else if (this.state.st == 'S3') {
                    this.httpS3(fieldsValue);
                } else if (this.state.st == 'P') {
                    this.httpP(fieldsValue);
                } else {
                    message.error('无法识别商户类型或认证类型');
                }
            }
        });
    }

    // 补偿图片功能
    _compensatePicList = (query, callback) => {
        this.setState({ loading: true });
        const { picList } = query;
        let picEnum = this.state.picEnum;
        let otherImgUrl = this.state.otherImgUrl;

        // 企业没有手持身份证
        if (this.state.st == 'S1' || this.state.st == 'S3') {
            picEnum = picEnum.filter((item) => {
                return item.document_type == '0007' ? false : true;
            });
            query.picList = query.picList.filter((item) => {
                return item.document_type == '0007' ? false : true;
            });
        }

        // 其它材料(选填)
        if (otherImgUrl && Array.isArray(otherImgUrl) && otherImgUrl.length == 0) {
            picEnum = picEnum.filter((item) => {
                return item.document_type == '0099' ? false : true;
            });
            query.picList = query.picList.filter((item) => {
                return item.document_type == '0099' ? false : true;
            });
        }

        let noUploadToUnionImg = [];
        for (let i = 0; i < picEnum.length; i++) {
            let hasUnionImg = false;
            for (let m = 0; m < picList.length; m++) {
                if (picEnum[i].document_type == picList[m].document_type) {
                    hasUnionImg = true;
                    break;
                }
            }
            // 银联上没有图片
            if (!hasUnionImg) {
                let unionUploadData = {};
                unionUploadData.document_type = picEnum[i].document_type;
                unionUploadData.document_name = picEnum[i].document_name;
                unionUploadData.url = this._getDocumentUrl(picEnum[i].document_type, query);
                noUploadToUnionImg.push(unionUploadData);
            }
        }

        if (noUploadToUnionImg.length > 0) {
            const promiseList = noUploadToUnionImg.map(item => {
                return http('/union/merchant/picUpload', { imgUrl: item.url });
            });
            Promise.all(promiseList).then(response => {
                if (noUploadToUnionImg.length == response.length) {
                    let list = [];
                    for (let i = 0; i < noUploadToUnionImg.length; i++) {
                        let pic = {};
                        pic.document_type = noUploadToUnionImg[i].document_type;
                        pic.document_name = noUploadToUnionImg[i].document_name;
                        pic.file_path = response[i].result.filePath;
                        pic.file_size = response[i].result.fileSize;
                        list.push(pic);
                    }
                    query.picList = [...query.picList].concat(list);
                    callback(query);
                } else {
                    this.setState({ loading: false });
                    message.error('图片上传接口union/merchant/picUpload调用失败，请稍后再试');
                }
            }).catch(e => {
                console.log(e.message);
                this.setState({ loading: false });
                message.error('图片上传接口union/merchant/picUpload调用失败，请稍后再试');
            });
        } else {
            callback(query);
        }
    }

    // 获取相应document_type的URL
    _getDocumentUrl = (document_type, query) => {
        switch (document_type) {
            case '0002': // 营业执照
                return this.state.st == 'P' ? query.businessLicenseUrl : query.taxUrl;
            case '0001': // 身份证正面
                return query.legPerIdFrontUrl;
            case '0011': // 身份证反面
                return query.legPerIdBackUrl;
            case '0005': // 门头照
                return query.shopFrontUrl;
            case '0015': // 室内照
                return query.shopIndoorUrl;
            case '0007': // 手持自拍照
                return query.idInHandUrl;
            case '0008': // 银行卡开户证明
                return query.bankCardUrl;
            case '0099': // 其它材料
                return query.otherImgUrl;
            default:
                break;
        }
    }

    // 一证请求
    httpS1 = (fieldsValue) => {
        const { proviceData, cityData, areaData, picList, unionBank } = this.state;
        const query = {};
        query.authType = fieldsValue.authType; // 认证类型
        query.companyName = fieldsValue.companyName; // 单位名称
        query.companyAddress = fieldsValue.companyAddress; // 注册地址 
        query.taxNum = fieldsValue.taxNum; // 统一社会信用证编号
        query.taxUrl = fieldsValue.taxUrl[0].url; // 统一社会信用证
        query.taxTime = fieldsValue.taxTime ? moment(fieldsValue.taxTime).format('YYYY-MM-DD') + ' ' + '00:00:00' : null; // 证照有效截止日期
        query.phone = fieldsValue.phone; // 证照有效截止日期
        query.corpName = fieldsValue.corpName; // 法人姓名
        query.mobile = fieldsValue.mobile; // 法人手机
        query.legPerId = fieldsValue.legPerId; // 法人身份证号
        query.legPerIdFrontUrl = fieldsValue.identity.legPerIdFrontUrl[0].url; // 身份证人像面
        query.legPerIdBackUrl = fieldsValue.identity.legPerIdBackUrl[0].url; // 身份证国徽面
        query.shopFrontUrl = fieldsValue.shopImg.shopFrontUrl[0].url; // 门头照
        query.shopIndoorUrl = fieldsValue.shopImg.shopIndoorUrl[0].url;// 室内照
        query.otherImgUrl = fieldsValue.otherImgUrl && Array.isArray(fieldsValue.otherImgUrl) && fieldsValue.otherImgUrl.length > 0 ? fieldsValue.otherImgUrl[0].url : ''; // 其它材料
        query.bankCardNo = fieldsValue.bankCardNo.replace(/\s+/g, ''); // 去空格; 银行账号
        query.bankCardUrl = fieldsValue.bankCardUrl[0].url; // 银行卡开户证明
        query.shopProvince = proviceData.areaName; // 省份名称
        query.shopProvinceId = proviceData.code; // 省份code
        query.shopCity = cityData.areaName; // 市名称
        query.shopCityId = cityData.code; // 市code
        query.shopCountry = areaData.areaName; // 区名称
        query.shopCountryId = areaData.code; // 区code
        query.bankName = fieldsValue.branchBankName.replace(/\s+/g, ''); // 去空格; 开户支行名称
        query.unionBank = unionBank; // 支行号
        query.email = fieldsValue.email; // 收件人邮箱
        query.invoiceAddress = fieldsValue.invoiceAddress; // 收件人地址
        query.invoiceName = fieldsValue.invoiceName; // 收件人姓名
        query.invoicePhone = fieldsValue.invoicePhone; // 收件人手机
        query.picList = picList;

        // 补偿银联图片（用于检测银联所需参数picList是否已经包含所需图片）
        this._compensatePicList(query, (data) => {
            this.request('/merchant/info/union/createUnionCompanyAccount', data);
        });
    }

    // 三证请求
    httpS3 = (fieldsValue) => {
        const { proviceData, cityData, areaData, picList, unionBank } = this.state;
        const query = {};
        query.authType = fieldsValue.authType; // 认证类型
        query.companyName = fieldsValue.companyName; // 单位名称
        query.companyAddress = fieldsValue.companyAddress; // 注册地址 
        query.idNo = fieldsValue.idNo; // 营业执照号
        query.idNoUrl = fieldsValue.idNoUrl[0].url; // 营业执照图片
        query.idNoTime = fieldsValue.idNoTime ? moment(fieldsValue.idNoTime).format('YYYY-MM-DD') + ' ' + '00:00:00' : null; // 营业执照有效时间
        query.orgCode = fieldsValue.orgCode; // 组织机构代码
        query.orgCodeUrl = fieldsValue.orgCodeUrl[0].url; // 组织机构代码证
        query.taxCert = fieldsValue.taxCert; // 税务登记证号
        query.taxCertUrl = fieldsValue.taxCertUrl[0].url; // 税务登记证照片
        query.phone = fieldsValue.phone; // 联系电话
        query.corpName = fieldsValue.corpName; // 法人姓名
        query.mobile = fieldsValue.mobile; // 法人手机
        query.legPerId = fieldsValue.legPerId; // 法人身份证号
        query.legPerIdFrontUrl = fieldsValue.identity.legPerIdFrontUrl[0].url; // 身份证人像面
        query.legPerIdBackUrl = fieldsValue.identity.legPerIdBackUrl[0].url; // 身份证国徽面
        query.shopFrontUrl = fieldsValue.shopImg.shopFrontUrl[0].url; // 门头照
        query.shopIndoorUrl = fieldsValue.shopImg.shopIndoorUrl[0].url;// 室内照
        query.otherImgUrl = fieldsValue.otherImgUrl && Array.isArray(fieldsValue.otherImgUrl) && fieldsValue.otherImgUrl.length > 0 ? fieldsValue.otherImgUrl[0].url : ''; // 其它材料
        query.bankCardNo = fieldsValue.bankCardNo.replace(/\s+/g, ''); // 去空格; 银行账号
        query.bankCardUrl = fieldsValue.bankCardUrl[0].url; // 银行卡开户证明
        query.shopProvince = proviceData.areaName; // 省份名称
        query.shopProvinceId = proviceData.code; // 省份code
        query.shopCity = cityData.areaName; // 市名称
        query.shopCityId = cityData.code; // 市code
        query.shopCountry = areaData.areaName; // 区名称
        query.shopCountryId = areaData.code; // 区code
        query.bankName = fieldsValue.branchBankName.replace(/\s+/g, ''); // 去空格; 开户支行名称
        query.unionBank = unionBank; // 支行号
        query.email = fieldsValue.email; // 收件人邮箱
        query.invoiceAddress = fieldsValue.invoiceAddress; // 收件人地址
        query.invoiceName = fieldsValue.invoiceName; // 收件人姓名
        query.invoicePhone = fieldsValue.invoicePhone; // 收件人手机
        query.picList = picList;

        // 补偿银联图片（用于检测银联所需参数picList是否已经包含所需图片）
        this._compensatePicList(query, (data) => {
            this.request('/merchant/info/union/createUnionCompanyAccount', data);
        });
    }

    // 个人请求
    httpP = (fieldsValue) => {
        const { proviceData, cityData, areaData, picList, unionBank } = this.state;
        const query = {};

        query.companyName = fieldsValue.companyName; // 单位名称
        query.companyAddress = fieldsValue.companyAddress; // 注册地址
        query.businessLicense = fieldsValue.idNo; // 营业执照号
        query.businessLicenseUrl = fieldsValue.idNoUrl[0].url; // 营业执照图片
        query.phone = fieldsValue.phone; // 联系电话
        query.corpName = fieldsValue.corpName; // 法人姓名
        query.mobile = fieldsValue.mobile; // 法人手机
        query.legPerId = fieldsValue.legPerId; // 法人身份证号
        query.idInHandUrl = fieldsValue.identity.idInHandUrl[0].url; // 手持身份证
        query.legPerIdFrontUrl = fieldsValue.identity.legPerIdFrontUrl[0].url; // 身份证人像面
        query.legPerIdBackUrl = fieldsValue.identity.legPerIdBackUrl[0].url; // 身份证国徽面
        query.shopFrontUrl = fieldsValue.shopImg.shopFrontUrl[0].url; // 门头照
        query.shopIndoorUrl = fieldsValue.shopImg.shopIndoorUrl[0].url;// 室内照
        query.otherImgUrl = fieldsValue.otherImgUrl && Array.isArray(fieldsValue.otherImgUrl) && fieldsValue.otherImgUrl.length > 0 ? fieldsValue.otherImgUrl[0].url : ''; // 其它材料
        query.bankCardNo = fieldsValue.bankCardNo.replace(/\s+/g, ''); // 去空格; 银行账号
        query.bankCardUrl = fieldsValue.bankCardUrl[0].url; // 银行卡开户证明
        query.shopProvince = proviceData.areaName; // 省份名称
        query.shopProvinceId = proviceData.code; // 省份code
        query.shopCity = cityData.areaName; // 市名称
        query.shopCityId = cityData.code; // 市code
        query.shopCountry = areaData.areaName; // 区名称
        query.shopCountryId = areaData.code; // 区code
        query.bankName = fieldsValue.branchBankName.replace(/\s+/g, ''); // 去空格; 开户支行名称
        query.unionBank = unionBank; // 支行号
        query.email = fieldsValue.email; // 收件人邮箱
        query.invoiceAddress = fieldsValue.invoiceAddress; // 收件人地址
        query.invoiceName = fieldsValue.invoiceName; // 收件人姓名
        query.invoicePhone = fieldsValue.invoicePhone; // 收件人手机
        query.picList = picList;

        // 补偿银联图片（用于检测银联所需参数picList是否已经包含所需图片）
        this._compensatePicList(query, (data) => {
            this.request('/merchant/info/union/createUnionPersonalAccount', data);
        });

        // 备用代码:当不用上传手持身份证照时用身份证正面照代替，暂时注释
        // let picNewList = [...picList];
        // picNewList = picNewList.filter((item) => {
        //     return (item.document_type == '0007' || item.document_type == '') ? false : true;
        // });

        // let picHardImg = {};
        // let picHardAdd = false;
        // for (let i = 0; i < picList.length; i++) {
        //     if (picList[i].document_type == '0001') {
        //         picHardImg.file_path = picList[i].file_path;
        //         picHardImg.file_size = picList[i].file_size;
        //         picHardImg.document_type = '0007';
        //         picHardImg.document_name = '手持身份证自拍照';
        //         picHardAdd = true;
        //         break;
        //     }
        // }
        // if (picHardAdd) {
        //     picNewList.push(picHardImg);
        // }
        // query.picList = picNewList;
    }

    request = (url, query) => {
        this.setState({ loading: true });
        getUnionInfo().then((response) => {
            const { type, data } = response;
            if (data.commitInfoStatus == 1) {
                // 账号已存在
                this.setState({ loading: false });
                message.success('保存成功');
                this.props.next();
            } else {
                // 创建银联账号
                http(url, query).then((response) => {
                    if (response.status == 200) {
                        this.setState({ loading: false });
                        message.success('保存成功');
                        this.props.next();
                    }
                }).catch((e) => {
                    this.setState({ loading: false });
                    message.error(e.message);
                });
            }
        });
    }

    // 上传图片校验-返回值
    _normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    // 上传图片校验-提示
    beforeUpload = (file, sizeNum = 1) => {
        const isType = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
        const isLt5M = file.size / 1024 / 1024 < sizeNum;
        if (!isType.includes(file.type)) {
            message.error('只能上传png、jpg、gif格式的图片');
            file.status = 'error';
            return false;
        } else if (!isLt5M) {
            message.error('图片必须小于1MB');
            file.status = 'error';
            return false;
        } else {
            return isType && isLt5M;
        }
    }

    _showDiffIncome = (unitType, authType) => {
        if (unitType == 'BUSINESS' && authType == '1') {
            this.setState({ st: 'S3' });
        } else if (unitType == 'BUSINESS' && authType == '2') {
            this.setState({ st: 'S1' });
        } else {
            this.setState({ st: 'P' });
        }
    }

    // 上传图片至银联
    _unionUpload = (imgUrl, resolve, reject) => {
        http('/union/merchant/picUpload', { imgUrl }).then((response) => {
            if (response.status == 200) {
                resolve(response.result);
            }
        }).catch((e) => {
            message.error(e.message);
            reject();
        });
    }

    // 上传给银联的图片参数
    _setPicList = (document_type, file_path, file_size) => {
        const { picEnum, picList } = this.state;
        // 获取指定document_type的document_name
        let document_name = '';
        for (let i = 0; i < picEnum.length; i++) {
            if (document_type == picEnum[i].document_type) {
                document_name = picEnum[i].document_name;
                break;
            }
        }
        // 判断是否已存在银联图片入参变量中
        let inUnionPicListBoolean = false;
        let unionPicList = [...picList];
        for (let u = 0; u < unionPicList.length; u++) {
            if (document_type == unionPicList[u].document_type) {
                unionPicList[u].document_name = document_name;
                unionPicList[u].file_path = file_path;
                unionPicList[u].file_size = file_size;
                inUnionPicListBoolean = true;
                break;
            }
        }
        if (!inUnionPicListBoolean) {
            unionPicList.push({ document_type, file_path, file_size, document_name });
        }
        // 重新赋值至状态变量中
        this.setState({ picList: unionPicList });
    }

    // 清理银联的图片参数（document_type银联指定的类型参数，prop_name图片保存状态字段，attr_name比如保存在身份证这类的二级字段）
    _cleanPicList = (document_type, prop_name, attr_name) => {
        if (this.state.picList && Array.isArray(this.state.picList)) {
            this._setPicList(document_type, '', '');

            this.props.form.resetFields(prop_name, []);
            if (attr_name) {
                this.setState({ [prop_name]: { ...this.state[prop_name], [attr_name]: [] } });
            } else {
                this.setState({ [prop_name]: [] });
            }
        }
    }

    render() {
        const { loading, st, required, unitType, authType, companyName, companyAddress, taxNum, taxUrl, bankCardUrl, taxTime,
            phone, corpName, mobile, legPerId, identity, shopImg, otherImgUrl, bankCardNo, bankBranchObj, email, invoiceAddress, invoiceName, invoicePhone,
            idNo, idNoUrl, idNoTime, taxCert, taxCertUrl, orgCode, orgCodeUrl, branchInputBo, branchBankName, auditSuccess, errorMessage
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { menuRegister, yunstAuthTypeEnum } = this.props;

        return (
            <div className={styles.step1}>
                <Form autoComplete='off' {...formItemLayout} onSubmit={this._handleSubmit}>
                    {!auditSuccess ? <Form.Item
                        colon={false}
                        htmlFor='errorMessage'
                        label={<span className={styles.errorMsg}>失败退回原因：</span>}
                    >
                        <div className={styles.errorMsg}>{errorMessage}</div>
                    </Form.Item> : ''}
                    <Form.Item
                        colon={false}
                        htmlFor='unitType'
                        label={<span>商户类型：</span>}
                    >
                        {getFieldDecorator('unitType', {
                            initialValue: unitType,
                            rules: [{ required, message: '请选择商户类型' }],
                        })(<RadioGroup onChange={(e) => {
                            this.setState({ unitType: e.target.value });
                            this.props.onChange(e.target.value);
                            this._showDiffIncome(e.target.value, authType);
                        }}>
                            {menuRegister.map((item) => (<Radio key={item.code} value={item.code}>{item.value}</Radio>))}
                        </RadioGroup>)}
                    </Form.Item>
                    {st != 'P' &&
                        <Form.Item
                            colon={false}
                            htmlFor='authType'
                            label={<span>认证类型：</span>}
                        >
                            {getFieldDecorator('authType', {
                                initialValue: authType.toString(),
                                rules: [{ required, message: '请选择认证类型' }],
                            })(<RadioGroup onChange={(e) => {
                                this.setState({ authType: e.target.value });
                                this._showDiffIncome(unitType, e.target.value);
                            }}>
                                {yunstAuthTypeEnum.map((item) => (<Radio key={item.code} value={item.code}>{item.value}</Radio>))}
                            </RadioGroup>)}
                            <div className={styles.desp}>一证：统一社会信用</div>
                            <div className={styles.desp}>三证：营业执照号、组织机构代码、税务登记证</div>
                        </Form.Item>
                    }
                    <Form.Item
                        colon={false}
                        htmlFor='companyName'
                        label={<span>单位名称<span style={{ color: 'red' }}>（营业执照上的名称）</span>：</span>}
                    >
                        {getFieldDecorator('companyName', {
                            rules: [{ required, message: '请输入单位名称（营业执照上的名称）' }],
                            initialValue: companyName
                        })(<Input placeholder='请输入单位名称（营业执照上的名称）' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='companyAddress'
                        label={<span>注册地址：</span>}
                    >
                        {getFieldDecorator('companyAddress', {
                            rules: [{ required, message: '请输入注册地址' }],
                            initialValue: companyAddress
                        })(<Input placeholder='请输入注册地址' />)}
                    </Form.Item>
                    {st == 'S1' &&
                        <Form.Item
                            colon={false}
                            htmlFor='taxNum'
                            label={<span>统一社会信用证编号：</span>}
                        >
                            {getFieldDecorator('taxNum', {
                                rules: [
                                    { required, message: '请输入统一社会信用证编号' },
                                    { pattern: /[A-Z0-9]{18}/g, message: '请输入18位数字加大写字母的统一社会信用证编号' },
                                ],
                                initialValue: taxNum
                            })(<Input placeholder='请输入18位数字加大写字母的统一社会信用证编号' />)}
                        </Form.Item>
                    }
                    {st == 'S1' &&
                        <Form.Item
                            colon={false}
                            htmlFor='taxUrl'
                            label={<span>统一社会信用证：</span>}
                        >
                            <div>
                                {getFieldDecorator('taxUrl', {
                                    initialValue: taxUrl,
                                    valuePropName: 'fileList',
                                    getValueFromEvent: this._normFile,
                                    rules: [
                                        { type: 'array', required, message: '请上传统一社会信用证' },
                                    ]
                                })(
                                    <CustomUpload
                                        accept='.png, .jpg, .jpeg'
                                        listType="picture-card"
                                        wallMode="single"
                                        removeError
                                        qiniuUploadProps={{
                                            uploadType: 'image'
                                        }}
                                        onChange={(info) => {
                                            const taxUrl = info.fileList;
                                            taxUrl.length == '0' && this.props.form.resetFields('taxUrl', []);
                                            this.setState({ taxUrl });
                                        }}
                                        onFileListChange={(info, customFile) => {
                                            const document_type = '0002';
                                            if (customFile && customFile.status === 'success') {
                                                // 二次上传至银联，如果失败则原已上传七牛图片同步置空
                                                const imgUrl = customFile.url;
                                                this._unionUpload(imgUrl, (data) => {
                                                    this._setPicList(document_type, data.filePath, data.fileSize); // 组装成银联所需的图片格式入参
                                                }, () => {
                                                    this._cleanPicList(document_type, 'taxUrl');  // 上传失败，清空图片
                                                });
                                            }
                                            if (customFile == undefined) {
                                                this._cleanPicList(document_type, 'taxUrl');   // 上传失败，清空图片
                                            }
                                        }}
                                        beforeUpload={(file) => this.beforeUpload(file, 1)}
                                    >
                                        <div>
                                            <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                            <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                            <div style={{ color: '#999999', fontSize: '12px' }}>信用证</div>
                                        </div>
                                    </CustomUpload>
                                )}
                                <span className={styles.oneline}>请上传营业执照原件，如果为复印件需加盖公章</span>
                                <span className={styles.oneline}>每次仅支持上传一张图片，大小不超过1M</span>
                            </div>
                        </Form.Item>
                    }
                    {st == 'S1' &&
                        <Form.Item
                            colon={false}
                            htmlFor='taxTime'
                            label={<span>证照有效截止日期：</span>}
                        >
                            {getFieldDecorator('taxTime', {
                                rules: [
                                    {
                                        validator: (rule, value = {}, callback) => {
                                            const selectTime = moment(value).valueOf();
                                            const nowTime = new Date().getTime();
                                            const restTime = selectTime - nowTime;
                                            if (restTime != 0 && (restTime < 0 || Math.round(restTime / 1000 / 60 / 60 / 24) < 60)) {
                                                callback('证照有效截止日期需大于60天，如为长期或无可不填写');
                                            } else {
                                                callback();
                                            }
                                        }
                                    }
                                ],
                                initialValue: taxTime
                            })(
                                <DatePicker
                                    style={{ width: 392 }}
                                    placeholder='证照有效截止日期需大于60天，如为长期或无可不填写'
                                    showTime={false}
                                />
                            )}
                        </Form.Item>
                    }
                    {st != 'S1' &&
                        <Form.Item
                            colon={false}
                            htmlFor='idNo'
                            label={<span>营业执照号：</span>}
                        >
                            {getFieldDecorator('idNo', {
                                rules: [
                                    { required, message: '请输入营业执照号' },
                                    { pattern: /^[a-zA-Z0-9]{0,50}$/, message: '最大长度不能超过50位' },
                                ],
                                initialValue: idNo
                            })(<Input placeholder='请输入营业执照号' />)}
                        </Form.Item>
                    }
                    {st != 'S1' &&
                        <Form.Item
                            colon={false}
                            htmlFor='idNoUrl'
                            label={<span>营业执照：</span>}
                        >
                            <div>
                                {getFieldDecorator('idNoUrl', {
                                    initialValue: idNoUrl,
                                    valuePropName: 'fileList',
                                    getValueFromEvent: this._normFile,
                                    rules: [
                                        { type: 'array', required, message: '请上传营业执照' },
                                    ],
                                })(
                                    <CustomUpload
                                        accept='.png, .jpg, .jpeg'
                                        listType="picture-card"
                                        wallMode="single"
                                        removeError
                                        qiniuUploadProps={{
                                            uploadType: 'image'
                                        }}
                                        onChange={(info) => {
                                            const idNoUrl = info.fileList;
                                            idNoUrl.length == '0' && this.props.form.resetFields('idNoUrl', []);
                                            this.setState({ idNoUrl });
                                        }}
                                        onFileListChange={(info, customFile) => {
                                            const document_type = '0002';
                                            if (customFile && customFile.status === 'success') {
                                                // 二次上传至银联，如果失败则原已上传七牛图片同步置空
                                                const imgUrl = customFile.url;
                                                this._unionUpload(imgUrl, (data) => {
                                                    this._setPicList(document_type, data.filePath, data.fileSize); // 组装成银联所需的图片格式入参
                                                }, () => {
                                                    this._cleanPicList(document_type, 'idNoUrl');  // 上传失败，清空图片
                                                });
                                            }
                                            if (customFile == undefined) {
                                                this._cleanPicList(document_type, 'idNoUrl');   // 上传失败，清空图片
                                            }
                                        }}
                                        beforeUpload={(file) => this.beforeUpload(file, 1)}
                                    >
                                        <div>
                                            <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                            <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                            <div style={{ color: '#999999', fontSize: '12px' }}>营业执照</div>
                                        </div>
                                    </CustomUpload>
                                )}
                                <span className={styles.oneline}>请上传营业执照原件，如果为复印件需加盖公章</span>
                                <span className={styles.oneline}>每次仅支持上传一张图片，大小不超过1M</span>
                            </div>
                        </Form.Item>
                    }
                    {st == 'S3' &&
                        <Form.Item
                            colon={false}
                            htmlFor='idNoTime'
                            label={<span>营业执照有效截止日期：</span>}
                        >
                            {getFieldDecorator('idNoTime', {
                                rules: [
                                    { required, message: '请输入营业执照有效截止日期' },
                                    {
                                        validator: (rule, value = {}, callback) => {
                                            const selectTime = moment(value).valueOf();
                                            const nowTime = new Date().getTime();
                                            const restTime = selectTime - nowTime;
                                            if (restTime != 0 && (restTime < 0 || Math.round(restTime / 1000 / 60 / 60 / 24) < 60)) {
                                                callback('营业执照有效截止日期需大于60天，如为长期或无可不填');
                                            } else {
                                                callback();
                                            }
                                        }
                                    }
                                ],
                                initialValue: idNoTime
                            })(
                                <DatePicker
                                    style={{ width: 392 }}
                                    placeholder='营业执照有效截止日期需大于60天，如为长期或无可不填'
                                    showTime={false}
                                />
                            )}
                        </Form.Item>
                    }
                    {st == 'S3' &&
                        <Form.Item
                            colon={false}
                            htmlFor='orgCode'
                            label={<span>组织机构代码：</span>}
                        >
                            {getFieldDecorator('orgCode', {
                                rules: [
                                    { required, message: '请输入组织机构代码' },
                                    { pattern: /^[a-zA-Z0-9]{0,50}$/, message: '最大长度不能超过50位' },
                                ],
                                initialValue: orgCode
                            })(<Input placeholder='请输入组织机构代码' />)}
                        </Form.Item>
                    }
                    {st == 'S3' &&
                        <Form.Item
                            colon={false}
                            htmlFor='orgCodeUrl'
                            label={<span>组织机构代码证：</span>}
                        >
                            <div>
                                {getFieldDecorator('orgCodeUrl', {
                                    initialValue: orgCodeUrl,
                                    valuePropName: 'fileList',
                                    getValueFromEvent: this._normFile,
                                    rules: [
                                        { type: 'array', required, message: '请上传组织机构代码证' },
                                    ],
                                })(
                                    <CustomUpload
                                        accept='.png, .jpg, .jpeg'
                                        listType="picture-card"
                                        wallMode="single"
                                        removeError
                                        qiniuUploadProps={{
                                            uploadType: 'image'
                                        }}
                                        onChange={(info) => {
                                            const orgCodeUrl = info.fileList;
                                            orgCodeUrl.length == '0' && this.props.form.resetFields('orgCodeUrl', []);
                                            this.setState({ orgCodeUrl });
                                        }}
                                        beforeUpload={(file) => this.beforeUpload(file, 1)}
                                    >
                                        <div>
                                            <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                            <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                            <div style={{ color: '#999999', fontSize: '12px' }}>代码证</div>
                                        </div>
                                    </CustomUpload>
                                )}
                                <span className={styles.oneline}>每次仅支持上传一张图片，大小不超过1M</span>
                            </div>
                        </Form.Item>
                    }
                    {st == 'S3' &&
                        <Form.Item
                            colon={false}
                            htmlFor='taxCert'
                            label={<span>税务登记证号：</span>}
                        >
                            {getFieldDecorator('taxCert', {
                                rules: [
                                    { required, message: '请输入税务登记证号' },
                                    { pattern: /^[a-zA-Z0-9]{0,50}$/, message: '最大长度不能超过50位' },
                                ],
                                initialValue: taxCert
                            })(<Input placeholder='请输入税务登记证号' />)}
                        </Form.Item>
                    }
                    {st == 'S3' &&
                        <Form.Item
                            colon={false}
                            htmlFor='taxCertUrl'
                            label={<span>税务登记证：</span>}
                        >
                            <div>
                                {getFieldDecorator('taxCertUrl', {
                                    initialValue: taxCertUrl,
                                    valuePropName: 'fileList',
                                    getValueFromEvent: this._normFile,
                                    rules: [
                                        { type: 'array', required, message: '请上传税务登记证' },
                                    ],
                                })(
                                    <CustomUpload
                                        accept='.png, .jpg, .jpeg'
                                        listType="picture-card"
                                        wallMode="single"
                                        removeError
                                        qiniuUploadProps={{
                                            uploadType: 'image'
                                        }}
                                        onChange={(info) => {
                                            const taxCertUrl = info.fileList;
                                            taxCertUrl.length == '0' && this.props.form.resetFields('taxCertUrl', []);
                                            this.setState({ taxCertUrl });
                                        }}
                                        beforeUpload={(file) => this.beforeUpload(file, 1)}
                                    >
                                        <div>
                                            <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                            <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                            <div style={{ color: '#999999', fontSize: '12px' }}>税务登记证</div>
                                        </div>
                                    </CustomUpload>
                                )}
                                <span className={styles.oneline}>每次仅支持上传一张图片，大小不超过1M</span>
                            </div>
                        </Form.Item>
                    }
                    <Form.Item
                        colon={false}
                        htmlFor='phone'
                        label={<span>联系电话：</span>}
                    >
                        {getFieldDecorator('phone', {
                            initialValue: phone,
                            rules: [
                                { required, message: '请输入固话(区号+号码，如010-1234567)或手机号码' },
                                {
                                    validator: (rule, value = '', callback) => {
                                        var RegExp = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/;
                                        if (value == '') {
                                            callback();
                                        } else if (RegExp.test(value) == false) {
                                            callback('请输入正确的固话(区号+号码，如010-1234567)或手机号码');
                                        } else {
                                            callback();
                                        }
                                    }
                                }
                            ]
                        })(<Input placeholder='请输入固话(区号+号码，如010-1234567)或手机号码' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='corpName'
                        label={<span>法人姓名：</span>}
                    >
                        {getFieldDecorator('corpName', {
                            rules: [
                                { required, message: '请输入2-6位姓名(仅支持中文)' },
                                { pattern: /^[\u4e00-\u9fa5]{2,6}$/, message: '请输入正确的2-6位姓名(仅支持中文)' },
                            ],
                            initialValue: corpName
                        })(<Input placeholder='请输入2-6位姓名(仅支持中文)' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='mobile'
                        label={<span>法人手机：</span>}
                    >
                        {getFieldDecorator('mobile', {
                            rules: [
                                { required, message: '请输入11位法人手机号码' },
                                { pattern: /^[1-9]\d{10}$/, message: '请输入正确的手机号码' },
                            ],
                            initialValue: mobile
                        })(<Input placeholder='请输入11位法人手机号码' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='legPerId'
                        label={<span>法人身份证号：</span>}
                    >
                        {getFieldDecorator('legPerId', {
                            rules: [
                                { required, message: '请输入法人身份证号' },
                                { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确的身份证号' },
                            ],
                            initialValue: legPerId
                        })(<Input placeholder='请输入法人身份证号' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        label={<span>身份证：</span>}
                    >
                        <div className={styles.upload}>
                            {getFieldDecorator('identity', {
                                initialValue: identity,
                                rules: [
                                    { required, message: '请上传身份证照片' },
                                    {
                                        validator: (rule, value = {}, callback) => {
                                            if (!required) {
                                                callback();
                                            }
                                            let identityField = this.props.form.getFieldsValue().identity;
                                            if (identityField.legPerIdFrontUrl.length == 0) {
                                                callback('请上传身份证人像面');
                                            } else if (identityField.legPerIdBackUrl.length == 0) {
                                                callback('请上传身份证国徽面');
                                            } else if (this.state.unitType == 'PERSON' && identityField.idInHandUrl.length == 0) {
                                                callback('请上传手持身份证');
                                            } else {
                                                callback();
                                            }
                                        }
                                    }
                                ],
                                getValueFromEvent: () => {
                                    return this.props.form.getFieldsValue().identity;
                                }
                            })(
                                <div className={styles.upload}>
                                    <CustomUpload
                                        accept='.png, .jpg, .jpeg'
                                        fileList={identity.legPerIdFrontUrl}
                                        listType="picture-card"
                                        wallMode="single"
                                        removeError
                                        qiniuUploadProps={{
                                            uploadType: 'image'
                                        }}
                                        onChange={(info) => {
                                            let identityField = this.props.form.getFieldsValue().identity;
                                            const legPerIdFrontUrl = info.fileList;
                                            if (legPerIdFrontUrl.length == '0') {
                                                identityField = { ...identityField, legPerIdFrontUrl: [] };
                                            } else {
                                                identityField = { ...identityField, legPerIdFrontUrl };
                                            }
                                            this.props.form.setFieldsValue({ identity: identityField });
                                            this.setState({ identity: identityField });
                                        }}
                                        onFileListChange={(info, customFile) => {
                                            const document_type = '0001';
                                            if (customFile && customFile.status === 'success') {
                                                // 二次上传至银联，如果失败则原已上传七牛图片同步置空
                                                const imgUrl = customFile.url;
                                                this._unionUpload(imgUrl, (data) => {
                                                    this._setPicList(document_type, data.filePath, data.fileSize); // 组装成银联所需的图片格式入参
                                                }, () => {
                                                    this._cleanPicList(document_type, 'identity', 'legPerIdFrontUrl');  // 上传失败，清空图片
                                                });
                                            }
                                            if (customFile == undefined) {
                                                this._cleanPicList(document_type, 'identity', 'legPerIdFrontUrl');   // 上传失败，清空图片
                                            }
                                        }}
                                        beforeUpload={(file) => this.beforeUpload(file, 1)}
                                    >
                                        <div>
                                            <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                            <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                            <div style={{ color: '#999999', fontSize: '12px' }}>人像面</div>
                                        </div>
                                    </CustomUpload>
                                    <CustomUpload
                                        accept='.png, .jpg, .jpeg'
                                        fileList={identity.legPerIdBackUrl}
                                        listType="picture-card"
                                        wallMode="single"
                                        removeError
                                        qiniuUploadProps={{
                                            uploadType: 'image'
                                        }}
                                        onChange={(info) => {
                                            let identityField = this.props.form.getFieldsValue().identity;
                                            const legPerIdBackUrl = info.fileList;
                                            if (legPerIdBackUrl.length == '0') {
                                                identityField = { ...identityField, legPerIdBackUrl: [] };
                                            } else {
                                                identityField = { ...identityField, legPerIdBackUrl };
                                            }
                                            this.props.form.setFieldsValue({ identity: identityField });
                                            this.setState({ identity: identityField });
                                        }}
                                        onFileListChange={(info, customFile) => {
                                            const document_type = '0011';
                                            if (customFile && customFile.status === 'success') {
                                                // 二次上传至银联，如果失败则原已上传七牛图片同步置空
                                                const imgUrl = customFile.url;
                                                this._unionUpload(imgUrl, (data) => {
                                                    this._setPicList(document_type, data.filePath, data.fileSize); // 组装成银联所需的图片格式入参
                                                }, () => {
                                                    this._cleanPicList(document_type, 'identity', 'legPerIdBackUrl');  // 上传失败，清空图片
                                                });
                                            }
                                            if (customFile == undefined) {
                                                this._cleanPicList(document_type, 'identity', 'legPerIdBackUrl');   // 上传失败，清空图片
                                            }
                                        }}
                                        beforeUpload={(file) => this.beforeUpload(file, 1)}
                                    >
                                        <div>
                                            <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                            <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                            <div style={{ color: '#999999', fontSize: '12px' }}>国徽面</div>
                                        </div>
                                    </CustomUpload>
                                    {
                                        this.state.unitType == 'PERSON' && <CustomUpload
                                            accept='.png, .jpg, .jpeg'
                                            fileList={identity.idInHandUrl}
                                            listType="picture-card"
                                            wallMode="single"
                                            removeError
                                            qiniuUploadProps={{
                                                uploadType: 'image'
                                            }}
                                            onChange={(info) => {
                                                let identityField = this.props.form.getFieldsValue().identity;
                                                const idInHandUrl = info.fileList;
                                                if (idInHandUrl.length == '0') {
                                                    identityField = { ...identityField, idInHandUrl: [] };
                                                } else {
                                                    identityField = { ...identityField, idInHandUrl };
                                                }
                                                this.props.form.setFieldsValue({ identity: identityField });
                                                this.setState({ identity: identityField });
                                            }}
                                            onFileListChange={(info, customFile) => {
                                                const document_type = '0007';
                                                if (customFile && customFile.status === 'success') {
                                                    // 二次上传至银联，如果失败则原已上传七牛图片同步置空
                                                    const imgUrl = customFile.url;
                                                    this._unionUpload(imgUrl, (data) => {
                                                        this._setPicList(document_type, data.filePath, data.fileSize); // 组装成银联所需的图片格式入参
                                                    }, () => {
                                                        this._cleanPicList(document_type, 'identity', 'idInHandUrl');  // 上传失败，清空图片
                                                    });
                                                }
                                                if (customFile == undefined) {
                                                    this._cleanPicList(document_type, 'identity', 'idInHandUrl');   // 上传失败，清空图片
                                                }
                                            }}
                                            beforeUpload={(file) => this.beforeUpload(file, 1)}
                                        >
                                            <div>
                                                <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                                <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                                <div style={{ color: '#999999', fontSize: '12px' }}>手持身份证</div>
                                            </div>
                                        </CustomUpload>
                                    }
                                </div>
                            )}
                        </div>
                        <div className={styles.identity}>每次仅支持上传一张图片，大小不超过1M</div>
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        label={<span>门头照片：</span>}
                    >
                        <div className={styles.upload}>
                            {getFieldDecorator('shopImg', {
                                initialValue: shopImg,
                                rules: [
                                    { required, message: '请上传门头照片' },
                                    {
                                        validator: (rule, value = {}, callback) => {
                                            if (!required) {
                                                callback();
                                            }
                                            let shopImgField = this.props.form.getFieldsValue().shopImg;
                                            if (shopImgField.shopFrontUrl.length == 0) {
                                                callback('请上传门头照');
                                            } else if (shopImgField.shopIndoorUrl.length == 0) {
                                                callback('请上传室内照');
                                            } else {
                                                callback();
                                            }
                                        }
                                    }
                                ],
                                getValueFromEvent: () => {
                                    return this.props.form.getFieldsValue().shopImg;
                                }
                            })(
                                <div>
                                    <div className={styles.upload}>
                                        <CustomUpload
                                            accept='.png, .jpg, .jpeg'
                                            fileList={shopImg.shopFrontUrl}
                                            listType="picture-card"
                                            wallMode="single"
                                            removeError
                                            qiniuUploadProps={{
                                                uploadType: 'image'
                                            }}
                                            onChange={(info) => {
                                                let shopImgField = this.props.form.getFieldsValue().shopImg;
                                                const shopFrontUrl = info.fileList;
                                                if (shopFrontUrl.length == '0') {
                                                    shopImgField = { ...shopImgField, shopFrontUrl: [] };
                                                } else {
                                                    shopImgField = { ...shopImgField, shopFrontUrl };
                                                }
                                                this.props.form.setFieldsValue({ shopImg: shopImgField });
                                                this.setState({ shopImg: shopImgField });
                                            }}
                                            onFileListChange={(info, customFile) => {
                                                const document_type = '0005';
                                                if (customFile && customFile.status === 'success') {
                                                    // 二次上传至银联，如果失败则原已上传七牛图片同步置空
                                                    const imgUrl = customFile.url;
                                                    this._unionUpload(imgUrl, (data) => {
                                                        this._setPicList(document_type, data.filePath, data.fileSize); // 组装成银联所需的图片格式入参
                                                    }, () => {
                                                        this._cleanPicList(document_type, 'shopImg', 'shopFrontUrl');  // 上传失败，清空图片
                                                    });
                                                }
                                                if (customFile == undefined) {
                                                    this._cleanPicList(document_type, 'shopImg', 'shopFrontUrl');   // 上传失败，清空图片
                                                }
                                            }}
                                            beforeUpload={(file) => this.beforeUpload(file, 2)}
                                        >
                                            <div>
                                                <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                                <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                                <div style={{ color: '#999999', fontSize: '12px' }}>门头照</div>
                                            </div>
                                        </CustomUpload>
                                        <CustomUpload
                                            accept='.png, .jpg, .jpeg'
                                            fileList={shopImg.shopIndoorUrl}
                                            listType="picture-card"
                                            wallMode="single"
                                            removeError
                                            qiniuUploadProps={{
                                                uploadType: 'image'
                                            }}
                                            onChange={(info) => {
                                                let shopImgField = this.props.form.getFieldsValue().shopImg;
                                                const shopIndoorUrl = info.fileList;
                                                if (shopIndoorUrl.length == '0') {
                                                    shopImgField = { ...shopImgField, shopIndoorUrl: [] };
                                                } else {
                                                    shopImgField = { ...shopImgField, shopIndoorUrl };
                                                }
                                                this.props.form.setFieldsValue({ shopImg: shopImgField });
                                                this.setState({ shopImg: shopImgField });
                                            }}
                                            onFileListChange={(info, customFile) => {
                                                const document_type = '0015';
                                                if (customFile && customFile.status === 'success') {
                                                    // 二次上传至银联，如果失败则原已上传七牛图片同步置空
                                                    const imgUrl = customFile.url;
                                                    this._unionUpload(imgUrl, (data) => {
                                                        this._setPicList(document_type, data.filePath, data.fileSize); // 组装成银联所需的图片格式入参
                                                    }, () => {
                                                        this._cleanPicList(document_type, 'shopImg', 'shopIndoorUrl');  // 上传失败，清空图片
                                                    });
                                                }
                                                if (customFile == undefined) {
                                                    this._cleanPicList(document_type, 'shopImg', 'shopIndoorUrl');   // 上传失败，清空图片
                                                }
                                            }}
                                            beforeUpload={(file) => this.beforeUpload(file, 2)}
                                        >
                                            <div>
                                                <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                                <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                                <div style={{ color: '#999999', fontSize: '12px' }}>商品照</div>
                                            </div>
                                        </CustomUpload>
                                    </div>
                                    <div className={styles.desp}>
                                        <div>门头照：需要显示公司名称的门面照或内部办公场所的整体照片。商品照：<span style={{ color: '#FF0000', fontWeight: 'bold' }}>请拍摄货架或仓库中的商品照，照片中需要清晰看到所售卖商品的外包装或商品实物</span>，每次仅支持上传一张图片，大小不超过2M</div>
                                    </div>
                                    <div>示例如下：门头照、室内照、商品照</div>
                                    <div>
                                        <XMediaFn
                                            key='shopFrontUrl'
                                            dataType='image'
                                            dataSource={require('@/assets/image/shopFrontUrl.png')}
                                        />
                                        <XMediaFn
                                            key='shopFrontUrl'
                                            dataType='image'
                                            dataSource={require('@/assets/image/shopIndoorUrl.png')}
                                        />
                                        <XMediaFn
                                            key='goods'
                                            dataType='image'
                                            dataSource={require('@/assets/image/goods2.jpg')}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='otherImgUrl'
                        label={<span>其它材料：</span>}
                    >
                        <div>
                            {getFieldDecorator('otherImgUrl', {
                                initialValue: otherImgUrl,
                                valuePropName: 'fileList',
                                getValueFromEvent: this._normFile,
                                rules: [
                                    { type: 'array', message: '请上传统一社会信用证' },
                                ]
                            })(
                                <CustomUpload
                                    accept='.png, .jpg, .jpeg'
                                    listType="picture-card"
                                    wallMode="single"
                                    removeError
                                    qiniuUploadProps={{
                                        uploadType: 'image'
                                    }}
                                    onChange={(info) => {
                                        const otherImgUrl = info.fileList;
                                        otherImgUrl.length == '0' && this.props.form.resetFields('otherImgUrl', []);
                                        this.setState({ otherImgUrl });
                                    }}
                                    onFileListChange={(info, customFile) => {
                                        const document_type = '0099';
                                        if (customFile && customFile.status === 'success') {
                                            // 二次上传至银联，如果失败则原已上传七牛图片同步置空
                                            const imgUrl = customFile.url;
                                            this._unionUpload(imgUrl, (data) => {
                                                this._setPicList(document_type, data.filePath, data.fileSize); // 组装成银联所需的图片格式入参
                                            }, () => {
                                                this._cleanPicList(document_type, 'otherImgUrl');  // 上传失败，清空图片
                                            });
                                        }
                                        if (customFile == undefined) {
                                            this._cleanPicList(document_type, 'otherImgUrl');   // 上传失败，清空图片
                                        }
                                    }}
                                    beforeUpload={(file) => this.beforeUpload(file, 2)}
                                >
                                    <div>
                                        <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                        <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                        <div style={{ color: '#999999', fontSize: '12px' }}>其它材料</div>
                                    </div>
                                </CustomUpload>
                            )}
                            <div className={styles.other}>如果门头不能体现公司名称，请拍摄法人或公司员工手持营业执照在办公场所的照片，需要清晰看到营业执照中的企业名称，每次仅支持上传一张图片，大小不超过2M</div>
                            <div>示例如下：其它材料</div>
                            <div>
                                <XMediaFn
                                    key='otherImg'
                                    dataType='image'
                                    dataSource={require('@/assets/image/otherImg.jpg')}
                                />
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='bankCardNo'
                        label={<span>银行账号：</span>}
                    >
                        {getFieldDecorator('bankCardNo', {
                            rules: [
                                { required, message: '请输入24位或以下数字组成的银行账号(企业对公账号)' },
                                {
                                    validator: (rule, value = '', callback) => {
                                        var pattern = /^\d{0,24}$/;
                                        // 去除空格
                                        var str = value.replace(/\s+/g, '');
                                        if (pattern.test(str)) {
                                            callback();
                                        } else {
                                            callback('请输入24位或以下数字组成的银行账号(企业对公账号)');
                                        }
                                    }
                                }
                            ],
                            initialValue: bankCardNo
                        })(<Input placeholder='请输入24位或以下数字组成的银行账号(企业对公账号)' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        label={<span><span style={{ color: 'red' }}> * </span>省份/城市/区域：</span>}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Select
                                style={{ marginRight: '10px' }}
                                placeholder="省份"
                                showSearch
                                value={this.state.proviceData.areaName}
                                onChange={(value, item) => {
                                    this.setState({ proviceData: item.props.data, cityData: {}, areaData: {}, cityList: [], areaList: [], branchBankName: '' }, () => {
                                        this.props.form.setFieldsValue({ branchBankName: undefined });
                                        this._getAddressList(2, item.props.data.id);
                                    });
                                }}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.proviceList && this.state.proviceList.map((item, index) => {
                                    return <Option key={index} value={item.areaName} data={item}>{item.areaName}</Option>;
                                })}
                            </Select>
                            <Select
                                style={{ marginRight: '10px' }}
                                placeholder="城市"
                                showSearch
                                value={this.state.cityData.areaName}
                                onChange={(value, item) => {
                                    this.setState({ cityData: item.props.data, areaData: {}, areaList: [], branchBankName: '' }, () => {
                                        this.props.form.setFieldsValue({ branchBankName: undefined });
                                        this._getAddressList(3, item.props.data.id);
                                    });
                                }}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.cityList && this.state.cityList.map((item, index) => {
                                    return <Option key={index} value={item.areaName} data={item}>{item.areaName}</Option>;
                                })}
                            </Select>
                            <Select
                                placeholder="区域"
                                showSearch
                                value={this.state.areaData.areaName}
                                onChange={(value, item) => {
                                    this.setState({ areaData: item.props.data, branchBankName: '' }, () => {
                                        this.props.form.setFieldsValue({ branchBankName: undefined });
                                    });
                                }}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.areaList && this.state.areaList.map((item, index) => {
                                    return <Option key={index} value={item.areaName} data={item}>{item.areaName}</Option>;
                                })}
                            </Select>
                        </div>
                    </Form.Item>
                    {!branchInputBo &&
                        <Form.Item
                            colon={false}
                            htmlFor='branchBankName'
                            label={<span>开户行支行名称：</span>}
                        >
                            {getFieldDecorator('branchBankName', {
                                rules: [
                                    { required, message: '请填写开户行支行名称' },
                                ],
                                initialValue: branchBankName
                            })(
                                <Select
                                    showSearch
                                    showArrow={false}
                                    placeholder="根据输入结果智能搜索开户行支行名称"
                                    notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onSearch={(value) => {
                                        if (this.state.areaData.code) {
                                            this.setState({ data: [], fetching: true });
                                            this._getBankList(this.state.areaData.code, value);
                                        } else {
                                            message.error('请先选择开户支行对应的省、市、区位置');
                                        }
                                    }}
                                    onChange={(value, item) => {
                                        this.setState({ branchBankName: value, unionBank: item.key, branchList: [], fetching: false });
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {this.state.branchList.map(item => (
                                        <Option key={item.code} value={item.bankBranchName}>{item.bankBranchName}</Option>
                                    ))}
                                </Select>
                            )}
                            {/* <div
                                style={{ color: '#3094FB', lineHeight: '15px', cursor: 'pointer', width: 'fit-content' }}
                                onClick={() => {
                                    this.setState({ branchInputBo: true, unionBank: '' });
                                    this.props.form.setFieldsValue({ unionBank: '' });
                                }}>手动输入支行信息</div> */}
                        </Form.Item>
                    }
                    {branchInputBo &&
                        <Form.Item
                            colon={false}
                            htmlFor='branchBankName'
                            label={<span>开户行支行名称：</span>}
                        >
                            {getFieldDecorator('branchBankName', {
                                rules: [
                                    { required, message: '请输入开户行支行名称' },
                                ],
                                initialValue: branchBankName
                            })(<Input placeholder='开户行支行名称' />)}
                            <div
                                style={{ color: '#3094FB', lineHeight: '15px', cursor: 'pointer', width: 'fit-content' }}
                                onClick={() => {
                                    this.setState({ branchInputBo: false, unionBank: '', bankBranchObj: {} });
                                    this.props.form.setFieldsValue({ unionBank: '', bankBranchObj: {} });
                                }}>选择支行信息</div>
                        </Form.Item>
                    }
                    <Form.Item
                        colon={false}
                        htmlFor='bankCardUrl'
                        label={<span>{st == 'P' ? '银行卡正面' : '开户行证明'}：</span>}
                    >  <div>
                            {getFieldDecorator('bankCardUrl', {
                                initialValue: bankCardUrl,
                                getValueFromEvent: this._normFile,
                                valuePropName: 'fileList',
                                rules: [
                                    { type: 'array', required, message: '请上传照片' },
                                ],
                            })(<CustomUpload
                                accept='.png, .jpg, .jpeg'
                                listType="picture-card"
                                wallMode="single"
                                removeError
                                qiniuUploadProps={{
                                    uploadType: 'image'
                                }}
                                onChange={(info) => {
                                    const bankCardUrl = info.fileList;
                                    bankCardUrl.length == '0' && this.props.form.resetFields('bankCardUrl', []);
                                    this.setState({ bankCardUrl });
                                }}
                                onFileListChange={(info, customFile) => {
                                    const document_type = '0008';
                                    if (customFile && customFile.status === 'success') {
                                        // 二次上传至银联，如果失败则原已上传七牛图片同步置空
                                        const imgUrl = customFile.url;
                                        this._unionUpload(imgUrl, (data) => {
                                            this._setPicList(document_type, data.filePath, data.fileSize); // 组装成银联所需的图片格式入参
                                        }, () => {
                                            this._cleanPicList(document_type, 'bankCardUrl');  // 上传失败，清空图片
                                        });
                                    }
                                    if (customFile == undefined) {
                                        this._cleanPicList(document_type, 'bankCardUrl');   // 上传失败，清空图片
                                    }
                                }}
                                beforeUpload={(file) => this.beforeUpload(file, 1)}
                            >
                                <div>
                                    <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                    <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                    <div style={{ color: '#999999', fontSize: '12px' }}>{st == 'P' ? '卡正面照' : '开户证明'}</div>
                                </div>
                            </CustomUpload>)}
                            <div className={styles.oneline}>{st == 'P' ? '请上传银行卡正面照' : '请上传银行开户证明'}，每次仅支持上传一张，大小不超过1M</div>
                        </div>
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='email'
                        label={<span>收件人邮箱地址：</span>}
                    >
                        {getFieldDecorator('email', {
                            rules: [
                                { required, message: '请输入收件人邮箱地址' },
                                { pattern: /^[0-9a-zA-Z._-]+[@][0-9a-zA-Z._-]+([.][a-zA-Z]+){1,2}$/, message: '请输入正确的邮箱地址' },
                            ],
                            initialValue: email
                        })(<Input placeholder='请输入收件人邮箱地址' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='invoiceAddress'
                        label={<span>收件人地址：</span>}
                    >
                        {getFieldDecorator('invoiceAddress', {
                            rules: [
                                { required, message: '请输入收件人地址' },
                                { pattern: /^[^\s]*$/, message: '禁止输入空格' }
                            ],
                            initialValue: invoiceAddress
                        })(<Input placeholder='请输入收件人地址' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='invoiceName'
                        label={<span>收票人姓名：</span>}
                    >
                        {getFieldDecorator('invoiceName', {
                            rules: [
                                { required, message: '请输入2-6位姓名(仅支持中文)' },
                                { pattern: /^[\u4e00-\u9fa5]{2,6}$/, message: '请输入正确的2-6位姓名(仅支持中文)' },
                            ],
                            initialValue: invoiceName
                        })(<Input placeholder='请输入2-6位姓名(仅支持中文)' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='invoicePhone'
                        label={<span>收票人手机：</span>}
                    >
                        {getFieldDecorator('invoicePhone', {
                            rules: [
                                { required, message: '请输入11位收票人手机号码' },
                                { pattern: /^[1-9]\d{10}$/, message: '请输入正确的手机号码' },
                            ],
                            initialValue: invoicePhone
                        })(<Input placeholder='请输入11位收票人手机号码' />)}
                    </Form.Item>
                    <div className={styles.btn}>
                        <Button type="primary" htmlType="submit" loading={loading}>下一步</Button>
                    </div>
                </Form>
            </div >
        );
    }
}

step.propTypes = {
    onChange: PropTypes.func, // 商户类型切换
    next: PropTypes.func, // 下一步
    form: PropTypes.object, // 表单校验
    getRegisterEnum: PropTypes.func,
    menuRegister: PropTypes.array,
    getYunstAuthTypeEnum: PropTypes.func,
    yunstAuthTypeEnum: PropTypes.array,
};
const stepForm = Form.create({ name: 'step-form' })(step);
const mapStateToProps = (state) => ({
    menuRegister: state.home.getRegisterEnum,
    yunstAuthTypeEnum: state.home.getYunstAuthTypeEnum,
});

export default connect(mapStateToProps, AllActions)(stepForm);

