/**
 * 广告弹框
 */
import React from 'react';
import styles from './AdvertisementModal.module.scss';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';

class AdvertisementModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        image: '',
    }

    componentDidMount() {
        http('/merchant/getTechServiceFeeChangeImg', {}).then((response) => {
            if (response.status == 200 && response.result) {
                this.setState({ image: response.result });
            } else {
                this.props.closeMode('gg');
            }
        }).catch((e) => {
            if (e) message.error(e.message);
            this.props.closeMode('gg');
        });
    }

    _handleCancel = () => {
        this.props.closeMode('gg');
    }

    _jump = () => {
        http('/instruction/listQuestion', { keyword: '银行系统升级', pageNum: 1, pageSize: 1 }).then((response) => {
            if (response.status == 200) {
                const { dataList } = response.result;
                if (Array.isArray(dataList) && dataList.length > 0) {
                    const key = dataList[0].id;
                    let path = '/home/strategymanage/merchantnotice/search/' + key + '/银行系统升级';
                    this.props.history.push({ pathname: path });
                    this.props.closeMode('gg');
                }
            }
        }).catch((e) => {
            if (e) message.error(e.message);
        });
    }

    render() {
        const { visible, confirmLoading, image } = this.state;

        return (
            <Modal
                width={680}
                centered
                title='温馨提示'
                visible={visible}
                destroyOnClose={true}
                keyboard={false}
                maskClosable={false}
                footer={null}
                onCancel={this._handleCancel}
            >
                <div className={styles.container}>
                    <img className={styles.img} src={image} onClick={this._jump} />
                </div>
            </Modal>
        );
    }
}

AdvertisementModal.propTypes = {
    history: PropTypes.object, // 路由history对象
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框回调事件
};

export default AdvertisementModal;