import { combineReducers } from 'redux';
import {
	productRateListData,
	productRateLabelSele,
	productRateStatus,
	productRateBusinessType,
	productRateDetail,
	activeRateDetail,
	conversionRate
} from './reducer';

export default combineReducers({
	productRateListData,
	productRateLabelSele,
	productRateStatus,
	productRateBusinessType,
	productRateDetail,
	activeRateDetail,
	conversionRate
});