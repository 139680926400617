import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { XTabNav, XPagination, XInput, XDatePicker, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { Table, DatePicker } from 'antd';
import moment from 'moment';
import { takeDataSource, takeColumns } from './components/TableData';
import AcceptModal from '../../modal/AcceptModal';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';
import { getSaleList, getSaleStatus, getSaleType, getDistributionFlagEnum } from '@/reducers/salemanage/saleorder/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '全部售后订单' },
            { id: 'WAITE_MERCHANT', label: '待处理' },
            { id: 'PROCESSING', label: '处理中' },
            { id: 'COMPLETE', label: '已完成' },
            { id: 'CLOSE', label: '已关闭' },
        ],
        curTabID: 1,  // 默认显示的tab，1：全部售后订单 2：待处理 3：处理中 4：已完成 5：已关闭
        pageNum: 1,
        pageSize: 10,
        tel: null,
        orderSaleId: null,
        startDate: null,
        endDate: null,
        salesType: {},
        process: {},
        goodsName: null,
        itemNo: null,
        shopName: null,
        currentOpenMode: null,
        orderId: null,
        orderTime: [],
        isWholesale: {code: 2, value: '全部'}
    }

    componentDidMount() {
        // this.props.getSaleList({ pageNum: 1, pageSize: 10 });
        this.props.getSaleStatus();
        this.props.getSaleType();
        this.props.getDistributionFlagEnum();
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        const { pageNum, pageSize, tel, orderSaleId, startDate, isWholesale,
            endDate,
            salesType,
            goodsName,
            itemNo,
            orderId,
            shopName } = this.state;
        this.searchCache = {
            pageNum,
            pageSize,
            tel,
            orderSaleId,
            startDate,
            endDate,
            salesType: salesType.code,
            isWholesale: isWholesale.code,
            goodsName,
            itemNo,
            shopName,
            orderId,
            process: tabID === 1 ? '' : tabID,
        };
        // this.props.getSaleList(this.searchCache);
        // CLOSE 已关闭, COMPLETE 已完成, WAITE_MERCHANT 待处理, PROCESSING 处理中
        this.setState({ curTabID: tabID, pageNum: 1 }, () => {
            this._searchHandle('useCache');
        });
    }
    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, tel, orderSaleId, startDate, curTabID, isWholesale,
            endDate,
            salesType,
            goodsName,
            itemNo,
            process,
            orderId,
            shopName } = this.state;
        this.searchCache = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            tel,
            orderSaleId,
            startDate: startDate && moment(moment(startDate).format()).format('YYYY-MM-DD HH:mm:ss'),
            endDate: endDate && moment(moment(endDate).format()).format('YYYY-MM-DD HH:mm:ss'),
            salesType: salesType.code,
            isWholesale: isWholesale.code,
            goodsName,
            itemNo,
            shopName,
            orderId,
            process: curTabID === 1 ? process.code : curTabID,
        };
        this.props.getSaleList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 重置
    _resetHandle = () => {
        this.setState({
            pageNum: 1,
            pageSize: 10,
            tel: null,
            orderSaleId: null,
            startDate: null,
            endDate: null,
            salesType: {},
            isWholesale: {code: 2, value: '全部'},
            goodsName: null,
            itemNo: null,
            shopName: null,
            process: {},
            orderId: null,
            orderTime: [],
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        // this.props.getSaleList({ pageNum: 1, pageSize: 10 });
    }
    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize });
        const { tel, orderSaleId, startDate, isWholesale,
            endDate,
            salesType,
            goodsName,
            itemNo,
            process,
            orderId,
            shopName } = this.state;
        this.searchCache = {
            pageNum,
            pageSize,
            tel,
            orderSaleId,
            startDate,
            endDate,
            salesType: salesType.code,
            isWholesale: isWholesale.code,
            goodsName,
            itemNo,
            shopName,
            orderId,
            process: process.code,
        };
        this.props.getSaleList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    // 售后类型
    _saleChangeHandle = e => {
        this.setState({
            salesType: e,
        });
    }
    // 处理状态
    _dealChangeHandle = e => {
        this.setState({
            process: e,
        });
    }
    // 操作
    _actionClickHandle = (record, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'DETAIL':
                addOneBreadcrumbPath({
                    title: '售后详情',
                    path: '/home/salemanage/saleorder/contentInfo'
                });
                history.push({
                    pathname: `/home/salemanage/saleorder/contentInfo/${record.id}/${record.buyerId}/${record.isWholesale.code}`
                });
                break;
            case 'ACCEPT':
                this.setState({ 
                    currentOpenMode: 'ACCEPT',
                    currentOpenData: {
                        id: record.id,
                      sellerId: record.sellerId,
                      saleType: record.salesType,
                    }
                });
                break;
        }
    }
    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { currentOpenData } = this.state;
        switch (this.state.currentOpenMode) {
            case 'ACCEPT':
                return <AcceptModal title='审核' visible={true}
                    refresh={this._SearchHandle}
                    data={currentOpenData}
                    closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }
    render() {
        const { DistributionFlagEnum, saleStatus, saleType, saleList: { dataList = [], resultTotal, pageSize, pageNum } } = this.props;
        const { isWholesale, startDate, endDate, curTabID, tel, orderSaleId, salesType, process, goodsName, itemNo, shopName, orderId, orderTime } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={styles.line}></div>
                <div className={styles.pro_commont} style={{marginTop:'10px'}}>
                    <XInput
                        style={{ width: '230px' }}
                        inputStyle={{ width: '150px' }}
                        label='会员手机号'
                        placeholder='请输入'
                        // onChange={this._fulltelChangeHandle}
                        value={tel}
                        bindThis={this}
                        bindName='tel'
                    />
                    <XInput
                        style={{ marginLeft: '20px', width: '216px', }}
                        inputStyle={{ width: '150px', }}
                        label='服务单号'
                        placeholder='请输入'
                        value={orderSaleId}
                        bindThis={this}
                        bindName='orderSaleId'
                    />
                    <XInput
                        style={{ marginLeft: '20px', width: '216px', }}
                        inputStyle={{ width: '150px', marginLeft: 13  }}
                        label='订单号'
                        placeholder='请输入'
                        value={orderId}
                        bindThis={this}
                        bindName='orderId'
                    />
                    <XDatePicker
                        style={{ marginLeft: '20px', color: '#252525' }}
                        label='下单时间'
                        // onChange={this._orderStartTimeChangeHandle}
                        value={startDate}
                        bindThis={this}
                        bindName='startDate'
                    // disabled={startPickerDisabled}
                    />
                    <XDatePicker
                        // label='下单时间' 
                        // onChange={this._orderEndTimeChangeHandle}
                        value={endDate}
                        bindThis={this}
                        bindName='endDate'
                    // disabled={endPickerDisabled}
                    />
                    <XSelect
                        style={{ marginLeft: '20px', width: '216px' }}
                        selectStyle={{ width: '150px' }}
                        label='售后类型'
                        placeholder='请选择'
                        renderData={saleType}
                        onChange={this._saleChangeHandle}
                        dataIndex='value'
                        keyIndex='code'
                        value={salesType.value}
                        showSearch={true}
                    />
                </div>
                <div className={styles.pro_commont}>
                    <XInput
                        style={{ width: '230px', }}
                        inputStyle={{ width: '150px', marginLeft: '13px' }}
                        label='商品货号'
                        placeholder='请输入'
                        value={itemNo}
                        bindThis={this}
                        bindName='itemNo'
                    />
                    <XInput
                        style={{ marginLeft: '20px', width: '216px', }}
                        inputStyle={{ width: '150px' }}
                        label='商品名称 '
                        placeholder='请输入'
                        value={goodsName}
                        bindThis={this}
                        bindName='goodsName'
                    />
                    <XInput
                        style={{ marginLeft: '20px', width: '216px', }}
                        inputStyle={{ width: '150px' }}
                        label='店铺名称'
                        placeholder='请输入'
                        value={shopName}
                        bindThis={this}
                        bindName='shopName'
                    />
                    {curTabID === 1 ? <XSelect
                        style={{ marginLeft: '20px', width: '216px' }}
                        selectStyle={{ width: '150px' }}
                        label='处理状态'
                        placeholder='请选择'
                        renderData={saleStatus}
                        onChange={this._dealChangeHandle}
                        dataIndex='value'
                        keyIndex='code'
                        value={process.value}
                        showSearch={true}
                    /> : null}
                    <XSelect
                        style={{ marginLeft: '20px', width: '216px' }}
                        selectStyle={{ width: '150px' }}
                        label='是否批发'
                        placeholder='请选择'
                        renderData={DistributionFlagEnum}
                        dataIndex='value'
                        keyIndex='code'
                        bindThis={this}
                        bindName='isWholesale'
                        value={isWholesale.value}
                        showSearch={true}
                    /> 
                    <XOKButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='查询'
                        onClick={this._searchHandle}
                    />
                    <XCancelButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='重置'
                        onClick={this._resetHandle}
                    />
                </div>
                <div className={styles.tableContainer}>
                    <Table
                        // bordered
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        // rowSelection={this._rowSelection} 
                        rowKey={(record) => record.id}
                        columns={takeColumns(this._actionClickHandle)}
                        dataSource={takeDataSource(dataList)}
                        pagination={false}
                        scroll={{ x: '120%', y: true }}
                    />
                    <XPagination
                        resultTotal={resultTotal}
                        pageSize={pageSize}
                        pageNum={pageNum}
                        onChange={this._paginationChange}
                    />
                </div>
                {this._renderMode()}
            </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 跳转其它路由页面
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    getSaleList: PropTypes.func,
    getSaleStatus: PropTypes.func,
    getSaleType: PropTypes.func,
    getDistributionFlagEnum: PropTypes.func,
    saleList: PropTypes.object,
    saleStatus: PropTypes.array,
    saleType: PropTypes.array,
    DistributionFlagEnum: PropTypes.array,
};
const mapStateToProps = (state) => {
    return {
        saleList: state.saleorder.saleList,
        saleStatus: state.saleorder.saleStatus,
        saleType: state.saleorder.saleType,
        DistributionFlagEnum: state.saleorder.DistributionFlagEnum,
    };
};
export default connect(mapStateToProps, { addOneBreadcrumbPath, getSaleList, getSaleStatus, getSaleType, getDistributionFlagEnum })(Main);