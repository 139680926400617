/**
 * [手动签名]
 * @author: Fu Xiaochun
 * @date: 2022-10-28 
 */

import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message, Button } from 'antd';
import styles from './SignatureModal.module.scss';
import { useEffect } from 'react';
import Signature from '@/assets/js/signature';
import { useRef } from 'react';
import qiniuUpload from '@/components/NewUpload/qiniu_Upload';


function SignatureModal(props){

    const signatureIns = useRef(null);

    useEffect(()=>{
        setTimeout(()=>{
            if(!signatureIns.current){
                signatureIns.current = new Signature('#signature');
            }
        }, 0);
        return ()=>{
            signatureIns.current.destroy();
            signatureIns.current = null;
        };
    }, []);

    const onSubmit = ()=>{
        let imgInfo = signatureIns.current.getSignatureImage();
        if(!imgInfo){
            message.error('请签名！');
            return false;
        }
        qiniuUpload(imgInfo.imgFile, {
            uploadType: 'image',
            error: (err)=>{
                message.error(err);
            },
            complete: (res)=>{
                // 签名图片七牛云地址
                props.onOk(res.fileUrl);
            }
        });
    };

    const onClose = ()=>{
        props.onClose();
    };

    const onReset = ()=>{
        signatureIns.current.clean();
    };

    const renderFooter=()=>{
        return <>
            <div className={styles.footer}>
                <Button type="primary" onClick={onReset}>重写</Button>
                <div>
                    <Button onClick={onClose} style={{marginRight: '10px'}}>取消</Button>
                    <Button onClick={onSubmit} type="primary">确认</Button>
                </div>
            </div>
        </>;
    };

    return <Modal title='手动签名' visible={true} width={800} height={300} bodyStyle={{padding: '10px'}} maskClosable={false} centered onCancel={onClose} footer={renderFooter()}>
        <div className={styles.canvasBox}>
            <div id="signature"></div>
        </div>
    </Modal>;
}

SignatureModal.propTypes = {
    data: PropTypes.object,
    onOk: PropTypes.func,
    onClose: PropTypes.func
};

export default SignatureModal;