import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

const TableContent = ({ renderData, paginationChange, expandedKeys, handleOnExpand }) => {

    const _takeColumns = () => {
        return [{
            title: '分类名称',
            dataIndex: 'categoryName',
            key: 'categoryName',
            width: '40%',
        }, {
            title: '分类图片',
            dataIndex: 'imgUrl',
            key: 'imgUrl',
            width: '20%',
            align: 'center',
            render: ImgCell
        }, {
            title: '技术服务费率(%)',
            dataIndex: 'platRate',
            key: 'platRate',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '平台服务费率(%)',
            dataIndex: 'agentRate',
            key: 'agentRate',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }];
    };

    return (<div className={styles.tableContainer}>
        <Table
            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
            rowKey={record => record.id}
            columns={_takeColumns()}
            childrenColumnName='childCategory'
            defaultExpandAllRows
            expandedRowKeys={expandedKeys}
            onExpand={handleOnExpand}
            dataSource={renderData.dataList}
            pagination={false}
            scroll={{ y: true }}
        />
        <XPagination
            resultTotal={renderData.resultTotal}
            pageSize={renderData.pageSize}
            pageNum={renderData.pageNum}
            onChange={paginationChange}
        />
    </div>);
};

TableContent.defaultProps = {
    renderData: { dataList: [{ childCategory: [{ childCategory: [] }] }], pageNum: 1, pageSize: 20, resultTotal: 0 },
};

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
    expandedKeys: PropTypes.array.isRequired,
    handleOnExpand: PropTypes.func.isRequired
};

export default TableContent;