import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

class TableContent extends Component {
    state = {
        selectedRowKeys: [],
    };

    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.selectedRowKeys) !== JSON.stringify(state.selectedRowKeys)) {
            return {
                selectedRowKeys: props.selectedRowKeys
            };
        }
        return null;
    }

    _columns = () => [
        {
            title:'姓名',
            dataIndex: 'subjectName',
            key: 'subjectName',
            align: 'center',
            width: '12',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '手机号',
            dataIndex: 'subjectPhone',
            key: 'subjectPhone',
            align: 'center',
            width: '12',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '申请来源',
            dataIndex: 'source.value',
            key: 'source.value',
            align: 'center',
            width: '12',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '申请金额',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center',
            width: '12%',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '账号信息',
            dataIndex: 'bindAccountNo',
            key: 'bindAccountNo',
            align: 'center',
            width: '12',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '开户银行',
            dataIndex: 'bindAccountName',
            key: 'bindAccountName',
            align: 'center',
            width: '12',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '支付账户',
            dataIndex: 'accessType',
            key: 'accessType',
            align: 'center',
            width: '12',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '申请时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: '12',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'action',
            align: 'center',
            width: '12',
            render: (id) => this._renderActionCell(id)
        }
    ];

    // 渲染action
    _renderActionCell = (id) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(id, 'Reject')}>驳回</div>
            </div>
        );
    }


    // 渲染图片
    _imgCell = (text) => {
        return <img src={text} width='50px' height='50px'></img>;
    }

    // 表格复选输入
    _rowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, rowItems) => {
                this.setState({ selectedRowKeys });
                this.props.selectedRowKeysChange(selectedRowKeys, rowItems);
            }
        };
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] }, () => {
            this.props.selectedRowKeysChange([], []);
        });
    }

    _takeDataSource = (data = []) => {
        return data;
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={this.state.selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection()}
                    rowKey='id'
                    columns={this._columns()}
                    pagination={false}
                    dataSource={this._takeDataSource(dataList)}
                    scroll={{ y: true }} />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
    selectedRowKeys: PropTypes.array,
    selectedRowKeysChange: PropTypes.func,
    tableAction: PropTypes.func.isRequired, // 表格操作
};

export default TableContent;