import * as T from './actiontypes';

export const getLiveGoodsList = (value) => ({
    type: T.GET_LIVEGOODS_LIST,
    payload: value
});
export const getLiveClassList = (value) => ({
    type: T.GET_LIVEGOODSCLASS_LIST,
    payload: value
});
export const getLiveGoodsOrderList = (value) => ({
    type: T.GET_LIVEGOODSORDER_LIST,
    payload: value
});