import * as T from './actiontypes';

export const getInvoiceList = (value) => ({
    type: T.GET_INVOICELIST,
    payload: value
});

export const getPlatformReconciliationTableSource = (value) => ({
    type: T.GET_PLATFORMRECONCILIATION_TABLESOURCE,
    payload: value
});

export const getStateSelectSource = (value) => ({
    type: T.GET_STATE_SELECTSOURCE,
    payload: value
});

export const getDetail = (value) => ({
    type: T.GET_DETAIL,
    payload: value
});

export const getDetailTableSource = (value) => ({
    type: T.GET_DETAIL_TABLESOURCE,
    payload: value
});