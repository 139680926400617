import { createModel } from '@/store/tools';

const initList = { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 };

const model = {
    namespace: 'productmanage',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getSkuGoodsData': T('getSkuGoodsData'),
            'getDistributionRec': T('getDistributionRec'),
            'getDistributionState': T('getDistributionState'),
            'getDistributionBrand': T('getDistributionBrand'),
            'getGoodsTypeSele': T('getGoodsTypeSele'),
            'getDistributionRecList': T('getDistributionRecList'),
            'getServiceTypeEnum': T('getServiceTypeEnum'),
            'getSalaAfterTypeEnum': T('getSalaAfterTypeEnum'),
        },
        actions: {//服务清单详情
            'getSkuGoodsData': A('getSkuGoodsData'),
            'getDistributionRec': A('getDistributionRec'),
            'getDistributionState': A('getDistributionState'),
            'getDistributionBrand': A('getDistributionBrand'),
            'getGoodsTypeSele': A('getGoodsTypeSele'),
            'getDistributionRecList': A('getDistributionRecList'),
            'getServiceTypeEnum': A('getServiceTypeEnum'),
            'getSalaAfterTypeEnum': A('getSalaAfterTypeEnum'),
        },
        sagas: {
            'getSkuGoodsData': S('getSkuGoodsData', '/goods/goods/retailSkuList'),
            'getDistributionRec': S('getDistributionRec', '/enum/FlagEnum'),
            'getDistributionState': S('getDistributionState', '/enum/RetailGoodsStateEnum'),
            'getDistributionBrand': S('getDistributionBrand', '/goods/brand/listBrandItem'),
            'getGoodsTypeSele': S('getGoodsTypeSele', '/enum/MallGoodsTypeEnum'),
            'getDistributionRecList': S('getDistributionRecList', '/goods/retail/listRetailGoodsReco'),
            'getServiceTypeEnum': S('getServiceTypeEnum', '/goods/service/listServiceItem'),
            'getSalaAfterTypeEnum': S('getSalaAfterTypeEnum', '/enum/SalesAfterTypeEnum'),
        },
        reducers: {
            'skuGoodsData': R('getSkuGoodsData', initList),
            'distributionRec': R('getDistributionRec', []),
            'distributionState': R('getDistributionState', []),
            'distributionBrand': R('getDistributionBrand', []),
            'goodsTypeSele': R('getGoodsTypeSele', []),
            'distributionRecList': R('getDistributionRecList', initList),
            'serviceTypeEnum': R('getServiceTypeEnum', []),
            'salaAfterTypeEnum': R('getSalaAfterTypeEnum', []),
        }
    })
};
export default createModel(model);