import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { priceFenToYuanCell, tooltip } from '@/components/TableCell';
import { getIsMarketingPromise } from '@/assets/js/authType';
import styles from './AdvertTable.module.scss';


class AdvertTable extends React.Component {
    state = {
        account: '',
        selectedRowKeys: [],
        cols: [
            {
                title: '支付流水号',
                dataIndex: 'payItemId',
                key: 'payItemId',
                width: 130,
                align: 'center'
            },
            {
                title: '订单编号',
                dataIndex: 'orderId',
                key: 'orderId',
                width: 130,
                align: 'center'
            },
            {
                title: '订单完成时间',
                dataIndex: 'finishTime',
                key: 'finishTime',
                width: 160,
                align: 'center'
            },
            {
                title: '付款日期',
                dataIndex: 'payDate',
                key: 'payDate',
                width: 160,
                align: 'center'
            },
            {
                title: '商品总金额（元）',
                dataIndex: 'goodsFee',
                key: 'goodsFee',
                width: 130,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '税费（元）',
                dataIndex: 'comprehensiveTax',
                key: 'comprehensiveTax',
                width: 120,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '邮费（元）',
                dataIndex: 'freight',
                key: 'freight',
                width: 120,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '优惠金额(元)',
                dataIndex: 'discount',
                key: 'discount',
                width: 120,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '人气值',
                dataIndex: 'starValue',
                key: 'starValue',
                width: 120,
                align: 'center'
            },
            {
                title: '实付金额(元)',
                dataIndex: 'payFee',
                key: 'payFee',
                width: 120,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '付款渠道',
                dataIndex: 'payType',
                key: 'payType',
                width: 120,
                align: 'center',
                render: tooltip
            },
            {
                title: '技术服务费(元)',
                dataIndex: 'serviceFee',
                key: 'serviceFee',
                width: 120,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '平台服务费(元)',
                dataIndex: 'distributionServiceFee',
                key: 'distributionServiceFee',
                width: 120,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '秀场服务费(元)',
                dataIndex: 'showServiceFee',
                key: 'showServiceFee',
                width: 120,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '心选推荐费(元)',
                dataIndex: 'heartSelectionRecommendationFee',
                key: 'heartSelectionRecommendationFee',
                width: 120,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '免单均摊金额(元)',
                dataIndex: 'freeOfCharge',
                key: 'freeOfCharge',
                width: 130,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '手续费(元)',
                dataIndex: 'chargeAmount',
                key: 'chargeAmount',
                width: 100,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '营销服务费(元)',
                dataIndex: 'marketServiceFee',
                key: 'marketServiceFee',
                width: 120,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '余额(元)',
                dataIndex: 'balance',
                key: 'balance',
                width: 100,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '售后服务单号',
                dataIndex: 'afterSaleId',
                key: 'afterSaleId',
                width: 120,
                align: 'center'
            },
            {
                title: '结算状态',
                dataIndex: 'settleType',
                key: 'settleType',
                width: 150,
                align: 'center',
                render: tooltip
            },
        ]
    }

    componentDidMount() {
        getIsMarketingPromise().then(res => {
            if (!res) {
                this.state.cols.splice(17, 1);
                this.setState({ cols: this.state.cols.concat() });
            }
        });
    }

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }
    render() {
        const { selectedRowKeys, cols } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={record => record.payItemId}
                    columns={cols}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true, x: '180%' }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default AdvertTable;