import React, { useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import { Modal, message, Input, Row, Col, Typography } from 'antd';
import { XInfoLabel } from '@/components/xqxc_ui';

const { Text, Title } = Typography;

const VerifyModal = ({ visible, data: { id = '' } = {}, setRenderModal, setModalParams }) => {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [verificationCode, setVerificationCode] = useState();
    const [title, setTitle] = useState('验证核销');
    const [verifyInfo, setVerifyInfo] = useState({});

    // 确认
    const onSubmit = () => {
        if (!verificationCode) return message.warning('请输入核销码');
        setConfirmLoading(true);
        if (verifyInfo.id) {
            http('/marketing/orderVerification/verification', { verificationCode }, 'POST')
                .then(res => { message.success(res.message); setConfirmLoading(false); setRenderModal(false), setModalParams(); })
                .catch(err => { message.error(err.message); setConfirmLoading(false); });
        } else {
            http('/marketing/orderVerification/verifiyCode', { id, verificationCode }, 'POST')
            .then(res => { setTitle('核销信息'); setVerifyInfo(res.result); setConfirmLoading(false); })
            .catch(err => { message.error(err.message); setConfirmLoading(false); });
        }
    };

    return (
        <Modal width={560} centered title={title} visible={visible} confirmLoading={confirmLoading}
            onOk={onSubmit} onCancel={() => { setRenderModal(false); setModalParams(); }}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'
        >
            <div className={styles.container}>
                {!verifyInfo.id ? <Row gutter={[16, 16]}>
                    <Col span={24} style={{ textAlign: 'center' }}><Title level={4}>请输入核销码</Title></Col>
                    <Col span={24} style={{ textAlign: 'center' }}><Text>可以提示，让用户打开“每日说APP&gt;&gt;同城订单&gt;&gt;待消费”查看核销码</Text></Col>
                    <Col span={24}><Input placeholder='请输入核销码' value={verificationCode} onChange={e => setVerificationCode(e.target.value)} /></Col>
                </Row> : <Row gutter={[16, 16]}>
                    <Col span={12}><XInfoLabel label='订单号：' value={verifyInfo.orderId} /></Col>
                    <Col span={12}><XInfoLabel label='下单时间：' value={verifyInfo.orderPayTime} /></Col>
                    <Col span={12}><XInfoLabel label='预留手机：' value={verifyInfo.tel} /></Col>
                    <Col span={12}><XInfoLabel label='会员账号：' value={verifyInfo.accountName} /></Col>
                    <Col span={12}><XInfoLabel label='核销商品：' value={verifyInfo.goodsName} /></Col>
                </Row>}
            </div>
        </Modal>
    );
};
VerifyModal.propTypes = {
    data: PropTypes.object,
    visible: PropTypes.bool,
    setRenderModal: PropTypes.func,
    setModalParams: PropTypes.func
};
export default VerifyModal;