/**
 *  【仓库库存对接】入口页
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const ReserveConnect = ({ history }) => {
    return <Main history={history} />;
};

ReserveConnect.propTypes = {
    history: PropTypes.object
};

export default ReserveConnect;