export const GET_MARKINGLIST_DATA = 'ACTIVIES/GET_MARKINGLIST_DATA'; // 营销活动列表
export const SET_MARKINGLIST_DATA = 'ACTIVIES/SET_MARKINGLIST_DATA'; // 营销活动列表

export const GET_DROPDIWN_DATA = 'ACTIVIES/GET_DROPDIWN_DATA'; // 营销活动下拉框
export const SET_DROPDIWN_DATA = 'ACTIVIES/SET_DROPDIWN_DATA'; //营销活动下拉框


export const SET_RAISETABALE_DATA = 'ACTIVIES/SET_RAISETABALE_DATA'; //商户活动新建数据
export const GET_RAISETABALE_DATA = 'ACTIVIES/GET_RAISETABALE_DATA'; //商户活动新建数据

export const SET_SKUTABALE_DATA = 'ACTIVIES/SET_SKUTABALE_DATA'; //商户活动新增sku设置条件
export const GET_SKUTABALE_DATA = 'ACTIVIES/GET_SKUTABALE_DATA'; //商户活动新增sku设置条件

export const GET_MARKETINGDETAIL_DATA = 'ACTIVIES/GET_MARKETINGDETAIL_DATA'; // 营销活动编辑获取新建详情
export const SET_MARKETINGDETAIL_DATA = 'ACTIVIES/SET_MARKETINGDETAIL_DATA'; //营销活动编辑获取新建详情

export const GET_MARKINGPRODUCTLIST_DATA = 'ACTIVIES/GET_MARKINGPRODUCTLIST_DATA'; // 营销活动》详情》活动商品列表
export const SET_MARKINGPRODUCTLIST_DATA = 'ACTIVIES/SET_MARKINGPRODUCTLIST_DATA'; // 营销活动》详情》活动商品列表

export const GET_MARKINGPRODUCTDETAIL_DATA = 'ACTIVIES/GET_MARKINGPRODUCTDETAIL_DATA'; // 营销活动编辑获取新建详情
export const SET_MARKINGPRODUCTDETAIL_DATA = 'ACTIVIES/SET_MARKINGPRODUCTDETAIL_DATA'; //营销活动编辑获取新建详情

export const GET_RAISEALL_DATA = 'ACTIVIES/GET_RAISEALL_DATA'; // 营销活动新建-分类（新人专区）会员等级
export const SET_ALL_DATA = 'ACTIVIES/SET_ALL_DATA'; //营销活动新建-分类（新人专区）会员等级