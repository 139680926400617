import * as T from './actiontypes';

/**
 * 列表初始化格式
 */
const listInitail = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

/**
 * 查询蚁购余额明细
 */
export const AntBuyBalanceDetail = (state = { ...listInitail, dataList: [] }, action) => {
    switch (action.type) {
        case T.SET_ACCOUNT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
