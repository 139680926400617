/**
 * 创建销售分类
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './modalStyle.module.scss';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { Modal, message, Form, Select, Typography } from 'antd';
import model from '@/reducers/businessmanage/cityshopgoods/model';

const { Option } = Select;
const { Text } = Typography;

const GoodsClassify = ({
    closeMode,
    modalParams,
    getCityClassSele,
    cityClassSele,
    form: { validateFieldsAndScroll, getFieldDecorator } }) => {

    const [confirmLoading, setConfirmLoading] = useState(false);

    useEffect(() => {
        getCityClassSele();
    }, []);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
    };

    const handleSubmit = e => {
        e && e.preventDefault();
        validateFieldsAndScroll((err, values) => {
            if (!err) {
                setConfirmLoading(true);
                http('/goods/cityShopGoods/changeShopGoodsCategory',
                    { goodsIds: modalParams, shopCategoryId: values.className }, 'POST')
                    .then(res => {
                        message.success(res.message);
                        setConfirmLoading(false);
                        closeMode();
                    })
                    .catch(err => {
                        message.error(err.message);
                        setConfirmLoading(false);
                    });
            }
        });
    };

    return (
        <Modal
            width={400}
            centered
            title='批量修改商品分类'
            visible={true}
            bodyStyle={{ paddingBottom: 0 }}
            confirmLoading={confirmLoading}
            onOk={handleSubmit}
            onCancel={closeMode}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'>
            <Form id='goodsClassify' {...formItemLayout} onSubmit={handleSubmit}>
                <Form.Item>
                    <Text strong>共选择{modalParams.length}件商品</Text>
                </Form.Item>
                <Form.Item label='目标分类'>
                    {getFieldDecorator('className', {
                        rules: [
                            {
                                required: true,
                                message: '请选择分类',
                            },
                        ],
                    })(
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {
                                cityClassSele.map(item => {
                                    return <Option key={item.categoryId} value={item.categoryId}>{item.categoryName}</Option>;
                                })
                            }
                        </Select>
                    )}
                </Form.Item>
            </Form>
        </Modal>
    );
};

GoodsClassify.propTypes = {
    closeMode: PropTypes.func.isRequired,
    modalParams: PropTypes.array,
    form: PropTypes.object.isRequired,
    getCityClassSele: PropTypes.func,//获取分类树
    cityClassSele: PropTypes.array,//分类树
};

export default connect(state => ({
    cityClassSele: state.cityShopGoods.cityClassSele,
}), {
    ...model.actions
})(Form.create({ name: 'goodsClassify' })(GoodsClassify));