/**
 * 出库单
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message } from 'antd';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, XDatePicker, XTabNav } from '@/components/xqxc_ui';
import OrderTable from './components/OrderTable';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getOutDeliveryOrderList, getCancelledDeliveryOrderList } from '@/reducers/storehousemanage/outdeliveryorder/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {

    state = {
        tel: null,
        accountTel: '',
        orderId: '',
        storehouseName: '',
        startDate: null,
        endDate: null,
        orderState: {}, // 订单状态
        orderType: {}, // 订单类型
        invoice: {}, // 是否开票
        orderTypeSelect: [], //订单类型
        orderStateSelect: [], //订单状态
        invoiceSelect: [], // 是否开票
        tab: [
            { id: 1, label: '全部订单' },
            { id: 2, label: '已取消' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
        selectedRowKeys: [], // 复选框
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this._orderTypeHandle();        // 获取订单类型
        this._orderStatusHandle();        // 获取订单状态
        this._isInvoiceSelect();        // 获取是否开票
    }

    // 订单类型
    _orderTypeHandle = () => {
        http('/enum/OrderTypeEnum', {}, 'POST')
            .then((res) => {
                this.setState({
                    orderTypeSelect: res.result || [],
                });
            }).catch((reject) => {
                message.error(reject.message);
            });
    }

    // 订单状态
    _orderStatusHandle = () => {
        http('/enum/OrderStatusEnum', {}, 'POST')
            .then((res) => {
                this.setState({
                    orderStateSelect: res.result || [],
                });
            }).catch((reject) => {
                message.error(reject.message);
            });
    }

    // 是否开票
    _isInvoiceSelect = () => {
        http('/enum/FlagEnum', {}, 'POST')
            .then((res) => {
                this.setState({
                    invoiceSelect: res.result || [],
                });
            }).catch((reject) => {
                message.error(reject.message);
            });
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID }, () => {
            this._searchHandle('useCache');
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { curTabID, pageNum, pageSize } = this.state;
        const { tel, accountTel, orderId, storehouseName, startDate, endDate, orderState, orderType, invoice } = this.state;
        this.searchCache = {
            tel, accountTel, orderId, storehouseName, startDate, endDate, status: orderState.code, orderType: orderType.code, isInvoice: invoice.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        if (curTabID === 1) {
            this.props.getOutDeliveryOrderList(this.searchCache);
        } else {
            this.props.getCancelledDeliveryOrderList(this.searchCache);
        }
        KeepAlive.saveCache({ ...this.state, curTabID, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】

    }

    // 重置
    _resetHandle = () => {
        this.setState({
            tel: null,
            accountTel: '',
            orderId: '',
            storehouseName: '',
            startDate: null,
            endDate: null,
            orderState: {},
            orderType: {},
            invoice: {},
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 导出Excel
    _exportOrder = () => {
        const {
            storehouseName,
            tel,
            accountTel,
            orderId,
            startDate,
            endDate,
            orderState,
            orderType,
            invoice,
            selectedRowKeys
        } = this.state;
        const data = {
            accountTel,
            orderId,
            startDate,
            endDate,
            status: orderState.code,
            orderType: orderType.code,
            tel,
            isInvoice: invoice.code,
            storehouseName,
            ids: selectedRowKeys
        };
        http('/merchant/order/exportboundOrderList', data, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result == '') {
                    message.error('没有订单数据');
                } else {
                    window.location.href = response.result;
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        const { tel, accountTel, orderId, storehouseName, startDate, endDate, orderState, orderType, invoice, curTabID } = this.state;
        this.searchCache = { tel, accountTel, orderId, storehouseName, startDate, endDate, status: orderState.code, orderType: orderType.code, isInvoice: invoice.code, pageSize, pageNum };
        if (curTabID === 1) {
            this.props.getOutDeliveryOrderList(this.searchCache);
        } else {
            this.props.getCancelledDeliveryOrderList(this.searchCache);
        }
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (id, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info':
                addOneBreadcrumbPath({
                    title: '出库单详情',
                    path: '/home/storehousemanage/outdeliveryorder/detail/'
                });
                history.push({
                    pathname: `/home/storehousemanage/outdeliveryorder/detail/${id}`
                });
                break;
        }
    }

    render() {
        const {
            storehouseName,
            tel, accountTel,
            orderId,
            startDate,
            endDate,
            orderState,
            orderType,
            invoice,
            orderTypeSelect,
            orderStateSelect,
            invoiceSelect,
            curTabID,
            selectedRowKeys
        } = this.state;
        const { outdeliveryorderList = {}, canceldeliveryorderList = {} } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <div className={styles.searchCriteria1}>
                        <XInput
                            style={{ width: '248px' }}
                            inputStyle={{ width: '180px' }}
                            label='会员手机'
                            placeholder='请输入会员手机'
                            value={accountTel}
                            bindThis={this}
                            bindName='accountTel'
                        />
                        <XInput
                            style={{ marginLeft: '20px', width: '248px' }}
                            inputStyle={{ width: '180px' }}
                            label='订单编号'
                            placeholder='请输入订单编号'
                            value={orderId}
                            bindThis={this}
                            bindName='orderId'
                        />
                        <XDatePicker
                            style={{ width: '264px', marginLeft: '20px' }}
                            label='下单时间'
                            value={startDate}
                            bindThis={this}
                            bindName='startDate'
                            isFormat={true}
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            label=''
                            value={endDate}
                            bindThis={this}
                            bindName='endDate'
                            isFormat={true}
                        />
                        <XSelect
                            style={{ marginLeft: '20px', width: '218px' }}
                            selectStyle={{ width: '150px' }}
                            label='订单状态'
                            placeholder='请选择订单状态'
                            renderData={orderStateSelect}
                            dataIndex='value'
                            keyIndex='code'
                            value={orderState.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='orderState'
                        />
                    </div>
                    <div className={styles.searchCriteria2}>
                        <XInput
                            style={{ width: '248px' }}
                            inputStyle={{ width: '180px' }}
                            label='收件手机'
                            placeholder='请输入收件人手机'
                            value={tel}
                            bindThis={this}
                            bindName='tel'
                        />
                        <XSelect
                            style={{ marginLeft: '20px', width: '248px' }}
                            selectStyle={{ width: '180px' }}
                            label='是否开票'
                            placeholder='请选择是否开票'
                            renderData={invoiceSelect}
                            dataIndex='value'
                            keyIndex='code'
                            value={invoice.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='invoice'
                        />
                        <XInput
                            style={{ marginLeft: '20px', width: '248px' }}
                            inputStyle={{ width: '180px' }}
                            label='仓库名称'
                            placeholder='请输入仓库名称'
                            value={storehouseName}
                            bindThis={this}
                            bindName='storehouseName'
                        />
                        <XSelect
                            style={{ marginLeft: '20px', width: '218px' }}
                            selectStyle={{ width: '150px' }}
                            label='订单类型'
                            placeholder='请选择订单类型'
                            renderData={orderTypeSelect}
                            dataIndex='value'
                            keyIndex='code'
                            value={orderType.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='orderType'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.otherFeatures}>
                        <XOKButton
                            style={{ width: '120px' }}
                            label='导出出库单'
                            onClick={this._exportOrder}
                        />
                    </div>
                    <OrderTable
                        orderTypeSelect={orderTypeSelect}
                        invoiceSelect={invoiceSelect}
                        orderStateSelect={orderStateSelect}
                        renderData={curTabID === 1 ? outdeliveryorderList : canceldeliveryorderList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                        selectedRowKeys={selectedRowKeys}
                        selectedRowKeysChange={(selectedRowKeys) => { this.setState({ selectedRowKeys }); }}
                    />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getOutDeliveryOrderList: PropTypes.func,
    outdeliveryorderList: PropTypes.object,
    getCancelledDeliveryOrderList: PropTypes.func,
    canceldeliveryorderList: PropTypes.object,
};
const mapStateToProps = (state) => ({
    outdeliveryorderList: state.outdeliveryorder.outdeliveryorderList,
    canceldeliveryorderList: state.outdeliveryorder.canceldeliveryorderList
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getOutDeliveryOrderList, getCancelledDeliveryOrderList })(Main);