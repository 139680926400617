import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, Form, Select, Input, Typography, Checkbox, message } from 'antd';
import model from '@/reducers/systemmanage/delivertemplate/model';

const { Option } = Select;
const { Text } = Typography;

const EditOrCreate = ({
    title = '创建',
    id = '',
    onCloseModal = () => { },
    form: {
        getFieldDecorator = () => { },
        validateFields = () => { },
        setFieldsValue = () => { },
    } = {},
    getCompanySele,
    companySele = [],
    getTemplateSele,
    templateSele = [],
    getUserDefinedEnum,
    userDefinedEnum = [],
    getDeliverTemplateInfo,
    deliverTemplateInfo = {},
    clearDeliverTemplateInfo,
    clearTemplateSele
}) => {

    const [branch, setBranch] = useState('根据发货地加载出对应的发货网点');
    const [branchAccounts, setBranchAccounts] = useState([]);
    const [submitInfo, setSubmitInfo] = useState({});
    const [printList, setPrintList] = useState([]);
    const [filterCompany, setFilterCompany] = useState([]);
    let timeout = undefined;

    const getPrinters = () => {
        let socket = new WebSocket('ws://localhost:13528');
        //如果是https的话，端口是13529
        //socket = new WebSocket('wss://localhost:13529');
        // 打开Socket
        socket.onopen = (event) => {
            clearTimeout(timeout);
            getPrintList();
            // 监听消息
            socket.onmessage = (event) => {
                console.log('Client received a message', JSON.parse(event.data).printers.filter(i => i.status === 'enable'));
                if (JSON.parse(event.data).printers.length == 0) {
                    message.warning('未找到您的打印机，请确保打印插件已连接上打印机');
                    return;
                }
                setPrintList(JSON.parse(event.data).printers.filter(i => i.status === 'enable') || []);
                socket.onclose();
            };
            // 监听Socket的关闭
            socket.onclose = (event) => {
                console.log('Client notified socket has closed', event);
            };
        };

        /***
        * 
        * 获取请求的UUID，指定长度和进制,如 
        * getUUID(8, 2)   //'01001010' 8 character (base=2)
        * getUUID(8, 10) // '47473046' 8 character ID (base=10)
        * getUUID(8, 16) // '098F4D35'。 8 character ID (base=16)
        *   
        */
        function getUUID(len, radix) {
            let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
            let uuid = [],
                i;
            radix = radix || chars.length;
            if (len) {
                for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
            } else {
                let r;
                uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
                uuid[14] = '4';
                for (i = 0; i < 36; i++) {
                    if (!uuid[i]) {
                        r = 0 | Math.random() * 16;
                        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
                    }
                }
            }
            return uuid.join('');
        }

        /**
         * 请求打印机列表demo
         * */
        function getPrintList() {
            let request = new Object();
            request.requestID = getUUID(8, 16);
            request.version = '1.0';
            request.cmd = 'getPrinters';
            if (socket.readyState === 1) {
                socket.send(JSON.stringify(request));
            }
        }

        timeout = setTimeout(() => {
            message.warning('您的打印插件未开启，请先开启后，再选择打印机');
        }, 3000);
    };

    useEffect(() => {
        getCompanySele();
        getPrinters();
        getUserDefinedEnum();
        clearDeliverTemplateInfo();
        if (id) {
            getDeliverTemplateInfo({ templateId: id });
        }
        return () => {
            clearTemplateSele();
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        if (id) {
            const { cpCode } = deliverTemplateInfo;
            let list = companySele.filter(i => i.cpCode == cpCode)[0] || {};
            cpCode && getTemplateSele({ cpCode });
            list.branchAccounts && list.branchAccounts.length && setBranchAccounts(list.branchAccounts);
        }
    }, [deliverTemplateInfo]);

    useEffect(() => {
        if (id) {
            const { address } = deliverTemplateInfo;
            const item = branchAccounts.filter(i => i.shipAddress == address)[0] || {};
            item.branchName && setBranch(item.branchName);
            item.shipAddress && setFieldsValue({ addressInfo: item.shipAddress });
        }
    }, [branchAccounts]);

    useEffect(() => {
        if (id) {
            const { templateType } = deliverTemplateInfo;
            templateType && setFieldsValue({ templateType });
        }
    }, [templateSele]);

    useEffect(() => {
        if (companySele.length) {
            let nohasnames = companySele.filter(i => !i.cpName).map(i => i.cpCode).join(',');
            if (nohasnames) {
                setFilterCompany(nohasnames.split(','));
                message.warning(`未找到${nohasnames}相关的物流信息，请联系运营管理员添加`);
            }
        }
    }, [companySele]);

    const onSaveInfo = (e) => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                const typeInfo = templateSele.filter(i => i.templateType == values.templateType)[0] || {};
                let data = {
                    id: id ? id : undefined,
                    templateName: values.templateName,
                    cpCode: values.cpCode,
                    address: submitInfo.shipAddress,
                    branches: submitInfo.branchName,
                    city: submitInfo.city,
                    detail: submitInfo.detail,
                    district: submitInfo.district,
                    extContent: values.extContent.join(','),
                    printDevice: values.printDevice,
                    province: submitInfo.province,
                    town: submitInfo.town,
                    cloudTemplateName: typeInfo.templateName,
                    templateType: values.templateType,
                    templateUrl: typeInfo.templateUrl,
                    defaultStatus: values.defaultStatus ? '1' : '2'
                };
                http('/merchant/cainiao/setting/template/edit', { ...data }, 'POST')
                    .then(res => {
                        message.success(res.message);
                        onCloseModal(true);
                    });
            }
        });
    };

    const getAddressList = (value) => {
        let list = companySele.filter(i => i.cpCode == value)[0] || {};
        getTemplateSele({ cpCode: value });
        setBranchAccounts(list.branchAccounts || []);
    };

    const getBranches = value => {
        let list = branchAccounts.filter(i => i.id == value);
        setSubmitInfo(list[0] ? list[0] : {});
        setBranch(list[0] ? list[0].branchName : '根据发货地加载出对应的发货网点');
    };

    return <Modal
        width={600}
        centered
        title={title}
        visible={true}
        confirmLoading={false}
        onOk={onSaveInfo}
        onCancel={() => onCloseModal(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <Form
            autoComplete='off'
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            colon={false}
            labelAlign='right'
            onSubmit={onSaveInfo}
        >
            <Form.Item label='模板名称' >
                {getFieldDecorator('templateName', {
                    initialValue: deliverTemplateInfo.templateName,
                    rules: [
                        { required: true, message: '请输入模板名称' }
                    ],
                })(<Input placeholder='请输入' />)}
            </Form.Item>
            <Form.Item label='物流公司'>
                {getFieldDecorator('cpCode', {
                    initialValue: deliverTemplateInfo.cpCode,
                    rules: [{ required: true, message: '请选择物流公司' }],
                })(
                    <Select placeholder='请选择' onChange={getAddressList}>
                        {
                            companySele.filter(i => !filterCompany.includes(i.cpCode)).map(i => {
                                return <Option key={i.cpCode} value={i.cpCode}>{i.cpName}</Option>;
                            })
                        }
                    </Select>
                )}
            </Form.Item>
            <Form.Item label='发货地'>
                {getFieldDecorator('addressInfo', {
                    rules: [{ required: true, message: '请选择发货地' }],
                })(
                    <Select placeholder='请选择' onChange={getBranches}>
                        {
                            branchAccounts.map((item, index) => {
                                return <Option key={index} value={item.id}>{item.shipAddress}</Option>;
                            })
                        }
                    </Select>
                )}
            </Form.Item>
            <Form.Item label='发货网点'>
                <Text>{branch}</Text>
            </Form.Item>
            <Form.Item label='电子面单类型'>
                {getFieldDecorator('templateType', {
                    rules: [{ required: true, message: '请选择电子面单类型' }],
                })(
                    <Select placeholder='请选择' >
                        {
                            templateSele.map((item, index) => {
                                return <Option key={index} value={item.templateType}>{item.templateName}</Option>;
                            })
                        }
                    </Select>
                )}
            </Form.Item>
            <Form.Item label='打印机'>
                {getFieldDecorator('printDevice', {
                    initialValue: deliverTemplateInfo.printDevice,
                    rules: [{ required: true, message: '请选择打印机' }],
                })(
                    <Select placeholder='请选择' >
                        {
                            printList.map(i => {
                                return <Option key={i.name} value={i.name}>{i.name}</Option>;
                            })
                        }
                    </Select>
                )}
            </Form.Item>
            <Form.Item label='面单自定义区域设置'>
                <Text type='secondary'>（勾选下列字段，可以打印到电子面单自定义区域中）</Text>
                {getFieldDecorator('extContent', {
                    initialValue: deliverTemplateInfo.extContent && deliverTemplateInfo.extContent.split(','),
                    rules: [{ required: true, message: '请勾选面单自定义区域' }],
                })(
                    <Checkbox.Group style={{ width: '100%' }}>
                        {
                            userDefinedEnum.map(item => {
                                return <Checkbox key={item.key} value={item.key}>{item.value}</Checkbox>;
                            })
                        }
                    </Checkbox.Group>,
                )}
            </Form.Item>
            <Form.Item label='是否默认'>
                {getFieldDecorator('defaultStatus', {
                    valuePropName: 'checked',
                    initialValue: deliverTemplateInfo.defaultStatus == 1 ? true : false,
                })(<Checkbox>设为默认</Checkbox>)}
            </Form.Item>
        </Form>
    </Modal>;
};
EditOrCreate.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    title: PropTypes.string,
    form: PropTypes.object,
    onCloseModal: PropTypes.func,
    getCompanySele: PropTypes.func,
    companySele: PropTypes.array,
    getTemplateSele: PropTypes.func,
    templateSele: PropTypes.array,
    getUserDefinedEnum: PropTypes.func,
    userDefinedEnum: PropTypes.array,
    getDeliverTemplateInfo: PropTypes.func,
    deliverTemplateInfo: PropTypes.object,
    clearDeliverTemplateInfo: PropTypes.func,
    clearTemplateSele: PropTypes.func
};
export default connect(state => ({
    deliverTemplateInfo: state.delivertemplate.deliverTemplateInfo,
    companySele: state.delivertemplate.companySele,
    templateSele: state.delivertemplate.templateSele,
    userDefinedEnum: state.delivertemplate.userDefinedEnum
}), {
    ...model.actions
})(Form.create({ name: 'editOrCreate' })(EditOrCreate));
