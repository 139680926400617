/**
 * 供应商品牌对接
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/supplierwarehouse/visitantbrand/model';

class VisitantBrand extends React.Component {

    static propTypes = {
        history: PropTypes.object,
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getConnectBrands: PropTypes.func,
        connectBrands: PropTypes.object
    }

    state = {
        outBrandName: '', //品牌名称
        batchKeys: [],//批量
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, } = this.props;
        keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
    }

    // 查询
    _searchHandle = (useCache) => {
        const { outBrandName, pageNum, pageSize } = this.state;
        this.searchCache = {
            outBrandName,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getConnectBrands(this.searchCache);
        KeepAlive.saveCache(this.searchCache); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ outBrandName: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //表格操作
    _tableOperate = (type, values) => {
        if (type === 'batch') {
            this.setState({ batchKeys: values });
        }
    }

    //请求函数
    _sendRequest = (url, tip, code) => {
        const { batchKeys } = this.state;
        let data = { warehouseType: code };
        if (!code && !batchKeys.length) {
            message.warn('请勾选品牌');
            return;
        }
        if (!code) {
            data = { ids: batchKeys };
        }
        http(url, data, 'POST')
            .then(() => {
                message.success(tip);
                if (!code) {
                    this.setState({ batchKeys: [] }, () => {
                        this._searchHandle('useCache');
                    });
                } else {
                    this._searchHandle('useCache');
                }
            })
            .catch(error => {
                message.error(error.message);
            });
    }

    //分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { outBrandName, batchKeys } = this.state;
        const { connectBrands } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria1}>
                        <XInput
                            inputStyle={{ width: '150px', marginRight: '20px' }}
                            label='品牌名称'
                            placeholder='请输入品牌名称'
                            value={outBrandName}
                            bindThis={this}
                            bindName='outBrandName'
                        />
                        <XOKButton
                            style={{ marginRight: '20px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.export}>
                        <XOKButton
                            style={{ marginRight: '20px' }}
                            label='获取供应商品牌'
                            onClick={() => this._sendRequest('/out/supplier/brand/download', '获取供应商品牌操作成功', 'HOUNIAO')}
                        />
                        <XOKButton
                            label='导入品牌库'
                            onClick={() => this._sendRequest('/out/supplier/brand/import', '导入品牌库操作成功')}
                        />
                    </div>
                    <TableContent
                        renderData={connectBrands}
                        paginationChange={this._paginationChange}
                        batchKeys={batchKeys}
                        tableOperate={this._tableOperate}
                    />
                </div>
            </KeepAlive>
        );
    }
}
export default connect(({ visitantBrand }) => ({
    connectBrands: visitantBrand.connectBrands
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(VisitantBrand);