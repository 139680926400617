import * as T from './actiontypes';

/**
 * 获取集采收益待打款列表
 */
export const getReadyPayList = (value = {}) => ({
    type: T.GET_READY_PAY_LIST,
    payload: value
});

/**
 * 获取集采收益已打款列表
 */
export const getAlreadyPayList = (value = {}) => ({
    type: T.GET_ALREADY_PAY_LIST,
    payload: value
});

/**
 * 获取集采收益详情
 */
export const getPayDetail = (value = {}) => ({
    type: T.GET_PAY_DETAIL,
    payload: value
});

/**
 * 获取通道枚举
 */
export const getPayChannelEnum = (value = {}) => ({
    type: T.GET_PAY_CHANNEL_ENUM,
    payload: value
});