/**
 * 【售后设置页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { getListAreaByParentId } from '@/reducers/suppliermanage/supplierlist/actions';
import { message } from 'antd';

class Main extends React.Component {

    state = {
        isNew: true, // 是否为新建
        recipient: '', // 收件人
        recipientPhone: '', // 收件电话
        recipientAddr: '', // 收件地址
        zipCode: '', // 邮编
        remark: '', // 备注
        province: {}, // 省份
        city: {}, // 城市
        street: {}, // 区域
    }

    componentDidMount() {
        this._init();
    }

    // 初始化信息
    _init = () => {
        http('/warehouse/after/info', {}, 'GET').then((response) => {
            if (response.status == 200) {
                if (response.result && response.result.id != '') {
                    const { id, recipient, recipientPhone, recipientAddr, zipCode, remark, provinceId, cityId, areaId } = response.result;
                    this.props.getListAreaByParentId({ id: 100000, type: 'province' });
                    this.props.getListAreaByParentId({ id: provinceId, type: 'city' });
                    this.props.getListAreaByParentId({ id: cityId, type: 'street' });
                    this.setState({
                        isNew: false,
                        id,
                        recipient,
                        recipientPhone,
                        recipientAddr,
                        zipCode,
                        remark,
                        province: { id: provinceId },
                        city: { id: cityId },
                        street: { id: areaId }
                    });
                } else {
                    this.props.getListAreaByParentId({ id: 100000, type: 'province' });
                    this.setState({ isNew: true });
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 保存
    _saveHandle = () => {
        const { id, recipient, recipientPhone, recipientAddr, zipCode, remark, province, city, street, isNew } = this.state;
        const data = { recipient, recipientPhone, recipientAddr, zipCode, remark, provinceId: province.id, cityId: city.id, areaId: street.id };
        if (isNew) {
            http('/warehouse/after/save', data, 'POST').then((response) => {
                if (response.status == 200) {
                    message.success('创建成功');
                    this._init();
                }
            }).catch((e) => {
                message.error(e.message);
            });
        } else {
            http('/warehouse/after/update', { ...data, id }, 'POST').then((response) => {
                if (response.status == 200) {
                    message.success('编辑成功');
                }
            }).catch((e) => {
                message.error(e.message);
            });
        }
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            recipient: '', // 收件人
            recipientPhone: '', // 收件电话
            recipientAddr: '', // 收件地址
            zipCode: '', // 邮编
            remark: '', // 备注
            province: {}, // 省份
            city: {}, // 城市
            street: {}, // 区域 });
        });
    }

    // 省份输入
    _provinceChangeHandle = (selectedItem) => {
        this.setState({ province: selectedItem });
        this.props.getListAreaByParentId({ id: selectedItem.id, type: 'city' });
    }

    // 城市输入
    _cityChangeHandle = (selectedItem) => {
        this.setState({ city: selectedItem });
        this.props.getListAreaByParentId({ id: selectedItem.id, type: 'street' });
    }

    // 区域输入
    _streetChangeHandle = (selectedItem) => {
        this.setState({ street: selectedItem });
    }

    render() {
        const { recipient, recipientPhone, recipientAddr, zipCode, remark } = this.state;
        const { address } = this.props;
        const { province, city, street } = address;

        console.log(province);
        console.log(this.state.province);


        return (
            <div className={styles.flexBoxContainer} >
                <XInput
                    style={{ width: '860px', marginLeft: '14px' }}
                    inputStyle={{ width: '800px' }}
                    label='收件人'
                    placeholder='请输入收件人'
                    value={recipient}
                    bindThis={this}
                    bindName='recipient'
                    isRequired={true}
                />
                <XInput
                    style={{ width: '860px', marginTop: '30px' }}
                    inputStyle={{ width: '800px' }}
                    label='收件电话'
                    placeholder='请输入收件电话'
                    value={recipientPhone}
                    bindThis={this}
                    bindName='recipientPhone'
                    isRequired={true}
                />
                <div className={styles.address}>
                    <XSelect
                        style={{ width: '278px' }}
                        selectStyle={{ width: '200px' }}
                        label='收件地址'
                        placeholder='省份'
                        renderData={province}
                        onChange={this._provinceChangeHandle}
                        dataIndex='areaName'
                        keyIndex='id'
                        value={this.state.province.id}
                        isRequired={true}
                        isOptionBindID={true}
                        showSearch={true}
                    />
                    <XSelect
                        selectStyle={{ width: '200px' }}
                        placeholder='城市'
                        renderData={city}
                        onChange={this._cityChangeHandle}
                        dataIndex='areaName'
                        keyIndex='id'
                        value={this.state.city.id}
                        isOptionBindID={true}
                        showSearch={true}
                    />
                    <XSelect
                        selectStyle={{ width: '200px' }}
                        placeholder='区域'
                        renderData={street}
                        onChange={this._streetChangeHandle}
                        dataIndex='areaName'
                        keyIndex='id'
                        value={this.state.street.id}
                        isOptionBindID={true}
                        showSearch={true}
                    />
                </div>
                <XInput
                    style={{ width: '860px', marginTop: '30px' }}
                    inputStyle={{ width: '800px' }}
                    label='详细地址'
                    placeholder='请输入详细地址'
                    value={recipientAddr}
                    bindThis={this}
                    bindName='recipientAddr'
                    isRequired={true}
                />
                <XInput
                    style={{ width: '860px', marginTop: '30px', marginLeft: '33px' }}
                    inputStyle={{ width: '800px' }}
                    label='邮编'
                    placeholder='请输入邮编'
                    value={zipCode}
                    bindThis={this}
                    bindName='zipCode'
                    isRequired={false}
                />
                <XInput
                    style={{ width: '860px', marginTop: '33px' }}
                    inputStyle={{ width: '800px' }}
                    label='售后说明'
                    placeholder='请输入售后说明'
                    value={remark}
                    bindThis={this}
                    bindName='remark'
                    isRequired={false}
                />
                <div className={styles.btns}>
                    <XOKButton style={{ marginLeft: '74px' }} label='保存' onClick={this._saveHandle} />
                    <XCancelButton style={{ marginLeft: '26px' }} label='重置' onClick={this._resetHandle} />
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    getListAreaByParentId: PropTypes.func, // 获取省市区
    address: PropTypes.object, // 省市区
};

const mapStateToProps = (state) => ({
    address: state.supplierlist.address,
});

export default connect(mapStateToProps, { getListAreaByParentId })(Main);