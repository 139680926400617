/**
 * 密码弹框
 */
import React from 'react';
import { Modal, message, Icon, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './RejectRemit.module.scss';
import http from '@/assets/api/http';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    }
};
const { TextArea } = Input;

class RejectRemit extends React.Component {

    state = {
        visible: this.props.visible,
    }

    // 提交
    _handleSubmit = (e) => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const { rejectReason } = fieldsValue;
                const query = { withdrawIds: this.props.rejectIDs, rejectReason };
                http('/settle/gatherWithdraw/batchRejectRemit', query, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('驳回成功!');
                        this._handleCancel();
                        this.props.refsh('useCache');
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
            }
        });
    }

    // 确认
    _handleOk = () => {
        this._handleSubmit();
    }

    // 取消
    _handleCancel = () => {
        this.setState({ visible: false, });
        this.props.closeMode();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { visible } = this.state;

        return (
            <Modal
                width={600}
                centered
                title='确认驳回'
                visible={visible}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.title}><Icon type="exclamation-circle" style={{ color: '#3094FB' }} /> 确认驳回提现金额</div>
                    </div>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit}>
                        <Form.Item
                            colon={false}
                            label={<span className={styles.formLabel}>驳回原因</span>}
                        >
                            {getFieldDecorator('rejectReason', {
                                rules: [
                                    { required: true, message: '请输入驳回原因' },
                                ],
                            })(<TextArea placeholder='请输入驳回原因' autoSize={{ minRows: 3 }} />)}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

RejectRemit.propTypes = {
    form: PropTypes.object.isRequired, // 校验
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    rejectIDs: PropTypes.array.isRequired, // 需要驳回的项的ID数组
    refsh: PropTypes.func,
};

const RejectRemitForm = Form.create({ name: 'RegisterForm' })(RejectRemit);// 校验
export default RejectRemitForm;