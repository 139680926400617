import React, { Component } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './Table.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { OperateType } from '../_utils';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

export default class GroupTable extends Component {

    //props校验
    static propTypes = {
        renderData: PropTypes.object.isRequired, // 表格数据源
        tableAction: PropTypes.func.isRequired, // 表格操作
        paginationChange: PropTypes.func.isRequired, // 分页操作
    };

    //表头
    _columns = () => [
        {
            title: '合同号',
            dataIndex: 'id',
            key: 'id',
            width: 200,
            align: 'center'
        },
        {
            title: '供应商',
            dataIndex: 'supplierName',
            key: 'supplierName',
            width: 200,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '采购总数量',
            dataIndex: 'totalPurchaseNum',
            key: 'totalPurchaseNum',
            width: 200,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '采购总金额',
            dataIndex: 'totalPurchaseAmount',
            key: 'totalPurchaseAmount',
            width: 200,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '修改时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: 200,
            align: 'center'
        },
        {
            title: '状态',
            dataIndex: 'state.value',
            key: 'state.value',
            width: 200,
            align: 'center'
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (id, record) => this._tableAction(record.id, record)
        },
    ];

    //处理返回数据
    _takeDataSource = (data) => {
        return data ? data : [];
    }

    //表格操作
    _tableAction = (id, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(id, OperateType.Detail, record)}>详情</div>
            </div>
        );
    };

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}