/**
 *  商户会员列表
 * */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import InviteSuccess from './invitesuccess';
import InviteFail from './invitefail';

const tabs = [
    { id: 1, label: '邀请成功' },
    { id: 2, label: '邀请失败' },
];

const MemberList = ({ history }) => {

    const [curTabID, setCurTabID] = useState(1);

    return (
        <div className={styles.flexBoxContainer}>
            <XTabNav renderData={tabs} onChange={value => setCurTabID(value)} activedID={curTabID} />
            {
                curTabID == '1' ?
                    <InviteSuccess curTabID={1} history={history} /> :
                    <InviteFail curTabID={2} history={history} />
            }
        </div>
    );
};
MemberList.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
};
export default MemberList;