/**
 *  订单应收金额明细
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './main';

const OrderReceivable = ({ history }) => {
    return <Main history={history} />;
};

OrderReceivable.propTypes = {
    history: PropTypes.object
};

export default OrderReceivable;