import React, { Component } from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Tooltip } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';
import { regFenToYuan, } from '@/util/money';

export default class TypeTable extends Component {
	//props校验
	static propTypes = {
		renderData: PropTypes.object.isRequired, // 渲染数据源(双向绑定)
		paginationChange: PropTypes.func.isRequired,//分页
	};
	//表头
	_columns = () => [
		{
			title: '活动名称',
			dataIndex: 'activityTitle',
			key: 'activityTitle',
			width: '9%',
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '活动类型',
			dataIndex: 'activityTypeValue',
			key: 'activityTypeValue ',
			width: '8%',
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '商品货号',
			dataIndex: 'itemNo',
			key: 'itemNo',
			align: 'center',
			width: '8%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '商品名称',
			dataIndex: 'goodsName',
			key: 'goodsName',
			width: '9%',
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '所属店铺',
			dataIndex: 'shopName',
			key: 'shopName',
			width: '8%',
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '商品价格(元)',
			dataIndex: 'goodsPriceValue',
			key: 'goodsPriceValue',
			width: '8%',
			align: 'center',
			render: tooltip
		},
		{
			title: '技术服务费(%)',
			dataIndex: 'serviceFeeValue',
			key: 'serviceFeeValue',
			width: '8%',
			align: 'center',
			render: tooltip
		},
		{
			title: '平台服务费(%)',
			dataIndex: 'distributionFeeValue',
			key: 'distributionFeeValue',
			width: '8%',
			align: 'center',
			render: tooltip
		},
		{
			title: '商品库存(件)',
			dataIndex: 'stock',
			key: 'stock',
			width: '8%',
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '退出申请状态',
			dataIndex: 'withdrawStateValue',
			key: 'withdrawStateValue',
			width: '8%',
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '审核意见',
			dataIndex: 'auditOpinion',
			key: 'auditOpinion',
			width: '9%',
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '退出时间',
			dataIndex: 'withdrawTime',
			key: 'withdrawTime',
			width: '8%',
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		}
	];

	// 解析表格数据
	_takeDataSource = (data) => {
		if (data) {
			return data.map(item => {
				item.goodsPriceValue = regFenToYuan(item.goodsPrice);
				item.serviceFeeValue = item.serviceFee / 10;
				item.distributionFeeValue = item.distributionFee / 10;
				return item;
			});
		}
	}

	//渲染页面
	render() {
		const { paginationChange } = this.props;
		const { dataList, pageSize, pageNum, resultTotal } = this.props.renderData;
		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowSelection={this._rowSelection}
					rowKey={record => record.activityGoodsId}
					columns={this._columns()}
					dataSource={this._takeDataSource(dataList)}
					pagination={false}
					scroll={{ y: true }}
				/>
				<XPagination
					resultTotal={resultTotal}
					pageSize={pageSize}
					pageNum={pageNum}
					onChange={paginationChange}
				/>
			</div>
		);
	}
}