import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table, InputNumber } from 'antd';
import PropTypes from 'prop-types';

class TableContent extends React.Component {

    state = {
        selectedRowKeys: [], // 选中的单元行
    };

    _takeColumns = () => {
        let columns = [
            {
                title: '货品名称',
                dataIndex: 'goodsName',
                key: 'goodsName',
                width: 140,
                align: 'center',
                // fixed: 'left',
                render: (text) => (this._renderTextCell(text))
            },
            {
                title: '商品规格ID',
                dataIndex: 'skuId',
                key: 'skuId',
                width: 140,
                align: 'center',
                render: (text) => (this._renderTextCell(text))
            },
            {
                title: '商品编码',
                dataIndex: 'goodsNo',
                key: 'goodsNo',
                width: 140,
                align: 'center',
                render: (text) => (this._renderTextCell(text))
            },
            {
                title: '历史价格',
                dataIndex: 'beforeSettlePrice',
                key: 'beforeSettlePrice',
                width: 140,
                align: 'center',
                render: (text) => (this._renderNumberCell(text))
            },
            {
                title: '销售价',
                dataIndex: 'salePrice',
                key: 'salePrice',
                width: 140,
                align: 'center',
                render: (text) => (this._renderNumberCell(text))
            },
            {
                title: '结算价',
                dataIndex: 'costPrice',
                key: 'costPrice',
                width: 140,
                align: 'center',
                render: (text) => (this._renderNumberCell(text))
            },
            {
                title: '调整金额',
                dataIndex: 'adjustPrice',
                key: 'adjustPrice',
                width: 140,
                align: 'center',
                render: (text) => (this._renderNumberCell(text))
            },
            {
                title: '调整比例',
                dataIndex: 'adjustRate',
                key: 'adjustRate',
                width: 140,
                align: 'center',
                render: (text) => (this._renderRatioCell(text))
            },
            {
                title: '建议零售价',
                dataIndex: 'suggestPrice',
                key: 'suggestPrice',
                align: 'center',
                render: (text, record) => (this._renderInputCell(text, record))
            },
            {
                title: '当前系统零售价',
                dataIndex: 'currSalePrice',
                key: 'currSalePrice',
                width: 140,
                align: 'center',
                render: (text) => (this._renderNumberCell(text))
            },
            {
                title: '状态',
                dataIndex: 'updateState',
                key: 'updateState',
                width: 140,
                align: 'center',
                render: (text) => (this._renderStateCell(text))
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
                width: 140,
                align: 'center',
                render: (text) => (this._renderTextCell(text))
            },
            {
                title: '下载时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: 140,
                align: 'center',
                render: (text) => (this._renderTextCell(text))
            },
            {
                title: '更新时间',
                dataIndex: 'gmtModified',
                key: 'gmtModified',
                width: 140,
                align: 'center',
                render: (text) => (this._renderTextCell(text))
            }
        ];
        if (this.props.curTabID != 2) {
            columns.push({
                title: '操作',
                dataIndex: 'key',
                key: 'action',
                align: 'center',
                width: 140,
                // fixed: 'right',
                render: (text, item) => this._renderActionCell(text, item)
            });
        }
        return columns;
    };

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderNumberCell = (text) => {
        return text / 100;
    }

    _renderStateCell = (text) => {
        return text.value;
    }

    _renderRatioCell = (text) => {
        return `${Number(text) * 100}%`;
    }

    _renderInputCell = (text, record) => {
        return (
            <div className={styles.input}>
                <InputNumber
                    style={{ width: '100%' }}
                    min={0}
                    defaultValue={text / 100}
                    onChange={(value) => {
                        record.suggestPrice = value;
                    }}
                />
            </div>
        );
    }

    _renderImgCell = (url) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <div className={styles.imgbox}>
                    <img className={styles.img} src={url} />
                </div>
            </div>
        );
    };

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderNumberCell = (text) => {
        const price = parseInt(text) / 100;
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {price}
            </div>
        );
    };

    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'updatePrice', item)}>更新销售价</div>
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
            this.props.tableAction(null, 'batch', selectedRows);
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
        this.props.tableAction(null, 'batch', []);
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={record => record.skuId}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: '150%', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    curTabID: PropTypes.number, // 当前TabID
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;