/**
 * 账号绑定客服
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import http from '@/assets/api/http';
import { message } from 'antd';
import { XInput, XDatePicker, XOKButton, XCancelButton, XInputNum, showConfirm } from '@/components/xqxc_ui';
import KeepAlive from '@/routes/KeepAlive';
import TableContent from './components/TableContent';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/customermanage/accountCustomer/model';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { connect } from 'react-redux';

class Main extends React.Component {

    state = {
        startTime: null,
        endTime: null
    };

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }
    _searchHandle = (useCache) => {
        const { id, merchantLoginName, account, startTime, endTime, pageNum, pageSize } = this.state;
        this.searchCache = {
            id,
            merchantLoginName,
            account,
            startTime,
            endTime,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.props.getlistMerchantKfInfo(this.searchCache);
    }
    // 重置
    _resetHandle = () => {
        this.setState({
            pageNum: 1,
            pageSize: 30,
            id: '',
            merchantLoginName: '',
            account: '',
            startTime: null,
            endTime: null,
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    _newHandle = () => {
        const { addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建绑定',
            path: '/home/customermanage/accountcustomer/create'
        });
        this.props.history.push({
            pathname: '/home/customermanage/accountcustomer/create',
        });
    }
    _tableAction = (id, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'edit':
                addOneBreadcrumbPath({
                    title: '编辑绑定',
                    path: `/home/customermanage/accountcustomer/edit/${id}`
                });
                history.push({
                    pathname: `/home/customermanage/accountcustomer/edit/${id}`,
                });
                break;
            case 'del':
                showConfirm('删除确认', '客服信息删除后无法恢复，确定删除此客服信息吗？', () => {
                    http(`/merchantKfInfo/delShopKfGroupInfo/${id}`, {}, 'POST')
                        .then((res) => {
                            if (res.status == 200) {
                                message.success('删除成功!');
                                this._searchHandle('useCache');
                            }
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
        }
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {

        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });

    }

    render() {
        const { id, merchantLoginName, account, startTime, endTime } = this.state;
        const { listMerchantKfInfo } = this.props;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.table_body}>
                        <XInputNum
                            style={{ width: '220px' }}
                            inputStyle={{ width: '180px' }}
                            label='编号'
                            placeholder='请输入编号'
                            value={id}
                            bindThis={this}
                            bindName='id'
                            min={0}
                            precision={0}
                            maxLength={16}
                        />

                        <XInputNum
                            style={{ width: '248px', marginLeft: '20px' }}
                            inputStyle={{ width: '180px' }}
                            label='商户账号'
                            placeholder='请输入商户账号'
                            value={merchantLoginName}
                            bindThis={this}
                            bindName='merchantLoginName'
                            min={0}
                            precision={0}
                            maxLength={16}
                        />

                        <XInput
                            style={{ width: '248px', marginLeft: '20px' }}
                            inputStyle={{ width: '180px' }}
                            label='客服账号'
                            placeholder='请输入客服账号'
                            value={account}
                            bindThis={this}
                            bindName='account'
                            min={0}
                            precision={0}
                            maxLength={16}
                        />

                        <XDatePicker
                            style={{ width: '248px', marginLeft: '20px' }}
                            label='更新时间'
                            value={startTime}
                            bindThis={this}
                            bindName='startTime'
                            isFormat
                        />
                        <XDatePicker
                            style={{ marginLeft: '20px', marginRight: '20px', color: '#252525' }}
                            value={endTime}
                            bindThis={this}
                            bindName='endTime'
                            isFormat
                        />

                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.table_body}>
                        <XOKButton style={{ width: 90 }} label='新建绑定' onClick={this._newHandle} />
                    </div>
                    <TableContent
                        renderData={listMerchantKfInfo}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                    />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getlistMerchantKfInfo: PropTypes.func,
    listMerchantKfInfo: PropTypes.object
};
const mapStateToProps = (state) => ({
    listMerchantKfInfo: state.accountCustomer.listMerchantKfInfo
});

export default connect(mapStateToProps, { ...model.actions, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);