import * as T from './actionTypes';

/**
 * 奢批物流出仓信息列表
 */
const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const getSpDeliverList = (state = initData, action) => {
    switch (action.type) {
        case T.SET_SPDELIVER_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 奢批物流出仓详细信息
 */
export const getSpDeliverDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_SPDELIVER_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};