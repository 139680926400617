import * as T from './actionTypes';

/**
 * 获取待发货出库单列表
 */
export const getReadyDeliverList = (value = {}) => ({
    type: T.GET_READYDELIVER_LIST,
    payload: value
});

/**
 * 获取快递公司下拉列
 */
export const getLogisticsSelect = (value = {}) => ({
    type: T.GET_LOGISTICS_SELECT,
    payload: value
});

/**
 * 获取批发订单下拉数据（ 待发货出库单、已发货出库单、已取消出库单共用）
 */
export const getWholesaleSelect = (value = {}) => ({
    type: T.GET_WHOLESALE_SELECT,
    payload: value
});
