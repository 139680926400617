/**
 * 仓库管理 Saga集合
 */
import { getStoreHouseList, getStoreHouseType, getStoreHouseDetail } from './storehouselist/saga';
import { getOutDeliveryOrderList, getCancelDeliveryOrderList } from './outdeliveryorder/saga';
import { getUnassignedoOrderList } from './unassignedorder/saga';


export default [
    getStoreHouseList,
    getStoreHouseType,
    getStoreHouseDetail,
    getOutDeliveryOrderList,
    getCancelDeliveryOrderList,
    getUnassignedoOrderList
];
