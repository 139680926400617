/**
 * 上传充值凭证
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message, Input, Form, InputNumber } from 'antd';
import CustomUpload from '@/components/NewUpload';

const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
    },
};

class CreateUploadKey extends Component {
    //初始数据
    state = {
        visible: this.props.visible,
        confirmLoading: false
    };

    //props校验
    static propTypes = {
        visible: PropTypes.bool.isRequired, // 是否显示弹框
        closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
        form: PropTypes.object.isRequired//form表单
    };

    // 点击确认
    _handleOk = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ confirmLoading: true });
                http('/settle/advances/received/upload', {
                    ...values,
                    amount: parseFloat(values.amount) * 100,
                    img: (values.img || []).map(v => v.url).join(','),
                }, 'POST').then(() => {
                    message.success('上传成功');
                    this.setState({ visible: false, confirmLoading: false });
                    this.props.closeMode(true);
                }).catch((e) => {
                    message.error(e.message);
                    this.setState({ confirmLoading: false });
                });
            }
        });
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({ visible: false, });
        this.props.closeMode(false);
    }

    //上传事件
    _onFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    render() {
        const { visible, confirmLoading } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={500}
                centered
                title='上传凭证'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <Form {...formItemLayout} autoComplete='off' onSubmit={this.handleSubmit} colon={false}>
                    <FormItem label="充值金额">
                        {getFieldDecorator('amount', {
                            rules: [{ required: true, message: '请输入充值金额', }],
                        })(<InputNumber style={{ width: 300 }} min={0} precision={2} placeholder='请输入' />)}
                    </FormItem>
                    <FormItem label="上传凭证">
                        <div style={{ height: 102 }}>
                            {getFieldDecorator('img', {
                                valuePropName: 'fileList',
                                getValueFromEvent: this._onFile,
                                rules: [{ required: true, message: '请上传凭证' }],
                            })(
                                <CustomUpload
                                    accept='.png, .jpg, .jpeg'
                                    listType="picture-card"
                                    wallMode="single"
                                    qiniuUploadProps={{
                                        uploadType: 'image'
                                    }}
                                >
                                    <div style={{ color: '#999999', fontSize: '12px' }}>上传凭证</div>
                                </CustomUpload>
                            )}
                        </div>
                    </FormItem>
                    <FormItem label="转账账号">
                        {getFieldDecorator('cardNo', {
                            rules: [{ required: true, message: '请输入转账账号' }]
                        })(<Input style={{ width: 300 }} />)}
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}
export default Form.create({ name: 'CreateUploadKey' })(CreateUploadKey);