/**
 * 待结算
 * */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { connect } from 'react-redux';
import { getSettleList } from '@/reducers/storehousefinancialmanage/collectsettle/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import SettleModal from './modal/SettleModal';

class ReadySettle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            settleState: 0, // 集采状态("1", "已结算"),("0", "未结算")
            activityTitle: '', // 集采活动
            goodsName: '', // 商品名称
            settleStartTime: undefined, // 开始时间
            settleEndTime: undefined, // 结束时间
            modeName: '', // 弹框名称
            modeParam: {}, // 弹框入参
        };
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getSettleList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { activityTitle, goodsName, settleStartTime, settleEndTime, pageNum, pageSize } = this.state;
        this.searchCache = {
            settleState: 0,
            activityTitle,
            goodsName,
            settleStartTime,
            settleEndTime,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getSettleList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ activityTitle: '', goodsName: '', settleStartTime: undefined, settleEndTime: undefined }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 批量结算
    _tableAction = (key, type, value) => {
        switch (type) {
            case 'settle':
                this.setState({
                    modeName: type,
                    modeParam: {
                        activityId: value.activityId,
                        minRate: value.minRate,
                        maxRate: value.maxRate,
                        orderTotalAmount: value.orderTotalAmount
                    }
                });
                break;
        }
    }

    // 渲染弹框
    _renderMode = () => {
        const { modeName, modeParam } = this.state;
        switch (modeName) {
            case 'settle':
                return <SettleModal visible={true} data={modeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ modeName: '' });
        if (update) {
            this.props.getSettleList(this.searchCache);
        }
    }

    render() {
        const { activityTitle, goodsName, settleStartTime, settleEndTime } = this.state;
        const { settleList } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='集采活动'
                            placeholder='请输入集采活动'
                            bindThis={this}
                            bindName='activityTitle'
                            value={activityTitle}
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '200px' }}
                            label='商品名称'
                            placeholder='请输入商品名称'
                            bindThis={this}
                            bindName='goodsName'
                            value={goodsName}
                        />
                        <XDatePicker
                            style={{ marginLeft: '20px' }}
                            label='结算时间'
                            bindThis={this}
                            bindName='settleStartTime'
                            value={settleStartTime}
                            isFormat={true}
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            bindThis={this}
                            bindName='settleEndTime'
                            value={settleEndTime}
                            isFormat={true}
                        />
                        <XOKButton
                            style={{ marginLeft: '30px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <TableContent
                        renderData={settleList}
                        paginationChange={this._paginationChange}
                        tableAction={this._tableAction}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

ReadySettle.propTypes = {
    history: PropTypes.object,
    getSettleList: PropTypes.func,
    settleList: PropTypes.object,
};
const mapStateToProps = (state) => ({
    settleList: state.collectsettle.settleList
});
export default connect(mapStateToProps, { getSettleList })(ReadySettle);