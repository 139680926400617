/**
 * 合同协议弹框
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Table, Alert } from 'antd';
import styles from './Fail.module.scss';
import { tooltipStyle, tooltip } from '@/components/TableCell';

export default class Fail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
        };
    }

    static propTypes = {
        visible: PropTypes.bool.isRequired, // 是否显示弹框
        closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
        modeParams: PropTypes.array.isRequired//数据
    };

    //表头
    _columns = () => {
        return [
            {
                title: '商品货号',
                dataIndex: 'goodsNo',
                key: 'goodsNo',
                width: '20%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '商品名称',
                dataIndex: 'goodsName',
                key: 'goodsName',
                width: '20%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '失败原因',
                dataIndex: 'remark',
                key: 'remark',
                width: '60%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            }
        ];
    }

    //处理表格数据
    _dealTableData = (data) => {
        return data || [];
    }

    //确定取消
    _backHandle = () => {
        this.setState({ visible: false });
        this.props.closeMode(false);
    }

    render() {
        const { visible, confirmLoading } = this.state;
        return (
            <Modal
                width={1200}
                centered
                title='导入失败'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._backHandle}
                onCancel={this._backHandle}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'>
                <div className={styles.container} >
                    <Alert type='error' message='请您关注异常描述，按提示处理后重新导入。' showIcon ></Alert>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        columns={this._columns()}
                        pagination={false}
                        rowKey={(record, index) => index}
                        dataSource={this._dealTableData(this.props.modeParams)}
                        scroll={{ y: 500 }} />
                </div>
            </Modal>
        );
    }
}