import * as T from './actionTypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

/**
 * 获取已发货出库单
 * */
export const getAlreadyDeliverList = (state = initData, action) => {
    switch (action.type) {
        case T.SET_ALREADYDELIVER_LIST:
            return action.payload.result;
        default:
            return state;
    }
};