import * as T from './actiontypes';

const initListData = {
    dataList: [{
        retailFlag: {},
        goodsType: {},
        retailGoodsState: {},
        goodsState: {}
    }],
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    resultTotal: 0
};

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    resultTotal: 0
};

export const productListData = (state = initListData, action) => {
    switch (action.type) {
        case T.SET_PRODUCT_LISTDATA:
            return action.payload.result;
        default:
            return state;
    }
};
export const productStatus = (state = [], action) => {
    switch (action.type) {
        case T.SET_PRODUCT_STATUS:
            return action.payload.result;
        default:
            return state;
    }
};


export const productUsername = (state = {}, action) => {
    switch (action.type) {
        case T.SET_PRODUCT_USERNAME:
            return action.payload.result;
        default:
            return state;
    }
};

export const productClassifyOne = (state = [], action) => {
    switch (action.type) {
        case T.SET_PRODUCT_CLASSIFYSELE:
            return action.payload.result;
        default:
            return state;
    }
};
export const productClassifyTwo = (state = [], action) => {
    switch (action.type) {
        case T.SET_PRODUCT_CLASSIFYSELE:
            return action.payload.result;
        default:
            return state;
    }
};
export const productClassifyThree = (state = [], action) => {
    switch (action.type) {
        case T.SET_PRODUCT_CLASSIFYSELE:
            return action.payload.result;
        default:
            return state;
    }
};

export const productBusinessType = (state = [], action) => {
    switch (action.type) {
        case T.SET_PRODUCT_BUSINESSTYPE:
            return action.payload.result;
        default:
            return state;
    }
};

export const productLabelSele = (state = {}, action) => {
    switch (action.type) {
        case T.SET_PRODUCT_LABELSELE:
            return action.payload.result;
        default:
            return state;
    }
};

export const productWareHouse = (state = [], action) => {
    switch (action.type) {
        case T.SET_PRODUCT_WAREHOUSE:
            return action.payload.result;
        default:
            return state;
    }
};

export const productDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_PRODUCT_DETAIL:
            return action.payload.result;
        case T.CLEAN_PRODUCT_DETAIL:
            return {};
        default:
            return state;
    }
};

export const productAuditLog = (state = initData, action) => {
    switch (action.type) {
        case T.SET_PRODUCT_AUDITLOG:
            return action.payload.result;
        default:
            return state;
    }
};

export const productFreeSele = (state = [], action) => {
    switch (action.type) {
        case T.SET_PRODUCT_FREESELE:
            return action.payload.result;
        default:
            return state;
    }
};

