import { createModel } from '@/store/tools';

const initList = { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 };

const model = {
    namespace: 'delivertemplate',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getDeliverTemplateData': T('getDeliverTemplateData'),
            'getCompanySele': T('getCompanySele'),
            'getTemplateSele': T('getTemplateSele'),
            'getDeliverTemplateInfo': T('getDeliverTemplateInfo'),
            'getUserDefinedEnum': T('getUserDefinedEnum'),
        },
        actions: {
            'getDeliverTemplateData': A('getDeliverTemplateData'),
            'getCompanySele': A('getCompanySele'),
            'getTemplateSele': A('getTemplateSele'),
            'getDeliverTemplateInfo': A('getDeliverTemplateInfo'),
            'getUserDefinedEnum': A('getUserDefinedEnum'),
            'clearDeliverTemplateInfo': C('getDeliverTemplateInfo'),
            'clearTemplateSele': C('getTemplateSele'),
        },
        sagas: {
            'getDeliverTemplateData': S('getDeliverTemplateData', '/merchant/cainiao/setting/template/list'),
            'getCompanySele': S('getCompanySele', '/merchant/cainiao/setting/company'),
            'getTemplateSele': S('getTemplateSele', '/merchant/cainiao/setting/template'),
            'getDeliverTemplateInfo': S('getDeliverTemplateInfo', '/merchant/cainiao/setting/template/single'),
            'getUserDefinedEnum': S('getUserDefinedEnum', '/merchant/cainiao/setting/goods'),
        },
        reducers: {
            'deliverTemplateData': R('getDeliverTemplateData', []),
            'companySele': R('getCompanySele', []),
            'templateSele': R('getTemplateSele', []),
            'deliverTemplateInfo': R('getDeliverTemplateInfo', {}),
            'userDefinedEnum': R('getUserDefinedEnum', []),
        }
    })
};
export default createModel(model);