import * as T from './actiontypes';

/**
 * 获取一级、二级菜单列表
 */
export const getMenuList = (state = [], action) => {
    switch (action.type) {
        case T.SET_MENU_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取全部菜单列表
 */
export const getAllMenuList = (state = [], action) => {
    switch (action.type) {
        case T.SET_MENU_ALL_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取菜单详情
 */
export const getMenuDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_MENU_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取一级菜单下拉列表
 */
export const getMenuSelect = (state = [], action) => {
    switch (action.type) {
        case T.SET_MENU_SELECT:
            return action.payload.result;
        default:
            return state;
    }
};


