import * as T from './actiontypes';

/**
 * 集采本金退款列表
 */
const plistInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
// 集采本金退款
export const collectMoneyList = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_COLLECT_LIST:
            return payload.result;
        default:
            return state;
    }
};

export const collectMoneyInfo = (state = {}, { type, payload }) => {
    switch (type) {
        case T.SET_COLLECT_INFO:
            return payload.result;
        default:
            return state;
    }
};
