//获取/保存订单列表
export const GET_HOTELORDER_LIST = 'ORDERMANAGE/GET_HOTELORDER_LIST';
export const SET_HOTELORDER_LIST = 'ORDERMANAGE/SET_HOTELORDER_LIST';

// 支付方式
export const GET_PAY_WAYS = 'ORDERMANAGE/GET_PAY_WAYS';
export const SET_PAY_WAYS = 'ORDERMANAGE/SET_PAY_WAYS';

// 房间数
export const GET_ROOM_NUM = 'ORDERMANAGE/GET_ROOM_NUM';
export const SET_ROOM_NUM = 'ORDERMANAGE/SET_ROOM_NUM';
// 房型
export const GET_ROOM_INFO = 'ORDERMANAGE/GET_ROOM_INFO';
export const SET_ROOM_INFO = 'ORDERMANAGE/SET_ROOM_INFO';