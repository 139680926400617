/**
 * 集采结算
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const CollectSettle = ({ history }) => {
    return <Main history={history} />;
};

CollectSettle.propTypes = {
    history: PropTypes.object
};

export default CollectSettle;