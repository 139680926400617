/**
 * 【库存对接页】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XInput, XSelect, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import DownloadNrModal from '../../modal/DownloadNrModal';
import { getInventoryConnectList, getInventoryConnectState } from '@/reducers/warehousemanage/inventoryConnect/actions';

import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

// 弹框枚举类型
const Mode = {
    PRODUCT_DOWNLOAD: 'PRODUCT_DOWNLOAD', // 选择商品分类弹框
};

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            skuId: '',//商品规格ID
            goodsName: '', // 商品名称
            goodsNo: '',//商品货号
            prodState: {}, // 状态
            batch: [],
            orderStartTime: null, // 更新开始日期
            orderEndTime: null, // 更新结束日期
            currentMode: '', // 当前弹框
            modeParam: '', // 弹框入参
        };
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getInventoryConnectState();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, skuId, goodsName, goodsNo, prodState, orderStartTime, orderEndTime } = this.state;
        this.searchCache = {
            skuId,
            goodsName,
            goodsNo,
            startTime: orderStartTime,
            endTime: orderEndTime,
            invUpdateState: prodState.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getInventoryConnectList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ skuId: '', goodsName: '', goodsNo: '', prodState: {}, orderStartTime: null, orderEndTime: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //请求函数
    _sendReq = (data) => {
        http('/goods/outer/upOuterGoodsStock', data, 'POST')
            .then(() => {
                this.props.getInventoryConnectList(this.searchCache);
                message.success('更新成功。');
                this.forceUpdate();
            })
            .catch(error => {
                message.error(error.message);
            });
    }

    // 表格操作
    _tableAction = (key, type, params) => {
        console.log(key, type);
        switch (type) {
            case 'batch':
                this.setState({ batch: params });
                break;
            case 'update':
                this._sendReq({ outerSkuIds: [key] });
                break;
        }
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // // 下载最新库存
    // _downloadHandle = () => {
    //     this.setState({ currentMode: Mode.PRODUCT_DOWNLOAD });
    // }

    // 批量更新库存
    _updateHandle = () => {
        const { batch } = this.state;
        if(!batch.length){
            message.warn('请勾选商品');
            return;
        }
        this._sendReq({ outerSkuIds: batch });
    }

    // 渲染弹框
    _renderMode = () => {
        switch (this.state.currentMode) {
            case Mode.PRODUCT_DOWNLOAD:
                return <DownloadNrModal visible={true} closeMode={this._closeMode} modeParam={this.state.modeParam} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (type) => {
        this.setState({ currentMode: '' });
        switch (type) {
            case 'ok':
                this.props.getInventoryConnectList(this.searchCache);
                break;
        }
    }

    render() {
        const { skuId, goodsName, goodsNo, prodState, orderStartTime, orderEndTime } = this.state;
        const { inventoryConnectList, inventoryConnectState } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria1}>
                        {/* <XInput
                            inputStyle={{ width: '180px' }}
                            label='货品规格ID'
                            placeholder='请输入货品规格ID'
                            value={skuId}
                            bindThis={this}
                            bindName='skuId'
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '180px' }}
                            label='商品名称'
                            placeholder='请输入商品名称'
                            value={goodsName}
                            bindThis={this}
                            bindName='goodsName'
                        /> */}
                        <XInput
                            inputStyle={{ width: '180px' }}
                            label='商品货号'
                            placeholder='请输入商品货号'
                            value={goodsNo}
                            bindThis={this}
                            bindName='goodsNo'
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '180px' }}
                            label='状态'
                            placeholder='请选择状态'
                            renderData={inventoryConnectState}
                            dataIndex='value'
                            keyIndex='code'
                            value={prodState.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='prodState'
                        />
                        <XDatePicker
                            style={{ width: '264px', marginLeft: '20px' }}
                            label='更新时间'
                            value={orderStartTime}
                            bindThis={this}
                            bindName='orderStartTime'
                            isFormat={true}
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            label=''
                            value={orderEndTime}
                            bindThis={this}
                            bindName='orderEndTime'
                            isFormat={true}
                        />
                        <XOKButton style={{ marginLeft: '20px' }} label='查询' onClick={this._searchHandle} />
                        <XCancelButton style={{ marginLeft: '20px' }} label='重置' onClick={this._resetHandle} />
                    </div>
                    {/* <div className={styles.searchCriteria2}>
                    <XDatePicker
                        style={{ width: '264px' }}
                        label='更新时间'
                        value={orderStartTime}
                        bindThis={this}
                        bindName='orderStartTime'
                        isFormat={true}
                    />
                    <div className={styles.timeLine} />
                    <XDatePicker
                        label=''
                        value={orderEndTime}
                        bindThis={this}
                        bindName='orderEndTime'
                        isFormat={true}
                    />
                </div> */}
                    <div className={styles.export}>
                        {/* <XOKButton label='下载最新库存' onClick={this._downloadHandle} /> */}
                        <XOKButton label='批量更新库存' onClick={this._updateHandle} />
                    </div>
                    <TableContent renderData={inventoryConnectList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getInventoryConnectList: PropTypes.func,
    getInventoryConnectState: PropTypes.func,

    inventoryConnectList: PropTypes.object,
    inventoryConnectState: PropTypes.array
};

const mapStateToProps = (state) => ({
    inventoryConnectList: state.inventoryConnect.inventoryConnectList,
    inventoryConnectState: state.inventoryConnect.inventoryConnectState,
});

const allReq = { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getInventoryConnectList, getInventoryConnectState };

export default connect(mapStateToProps, allReq)(Main);