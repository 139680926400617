/**
 * 查看物流弹框
 */
import React, { Fragment, useEffect, useState } from 'react';
import { Modal, Steps, Icon, Alert, message, Divider, Button, Spin } from 'antd';
import PropTypes from 'prop-types';
import styles from './LogisticsModal.module.scss';
import { XInfoLabel } from '@/components/xqxc_ui';
import copy from 'copy-to-clipboard';
import http from '@/assets/api/http';
import { GenerateUUID } from '@/util/utils';

const { Step } = Steps;

const LogisticsModal = ({ data: { orderId } = {}, visible, closeMode }) => {

    const [logisticsRespList, setLogisticsRespList] = useState([]);
    const [details, setDetails] = useState([]);
    const [isNext, setIsNext] = useState(false);
    const [nextInfo, setNextInfo] = useState({});
    const [spinning, setSpinning] = useState(false);

    useEffect(() => {
        setSpinning(true);
        http('/merchant/order/getOrderShipmentList', { orderId }, 'POST')
            .then(res => {
                setSpinning(false);
                const { logisticsRespList = [], expressList = [], deliveryMethod, logisticsCompany, expressNo, packageTips } = res.result[0];
                if (!logisticsRespList.length) {
                    setDetails(expressList); setIsNext(true);
                }
                setNextInfo({ deliveryMethod, logisticsCompany, expressNo, packageTips });
                setLogisticsRespList(logisticsRespList.map(i => ({ ...i, id: GenerateUUID() })));
            });
    }, []);

    const onListChange = (id) => {
        const call = pre => {
            return pre.map(j => {
                if (j.id === id) return { ...j, isShow: !j.isShow };
                return j;
            });
        };
        setLogisticsRespList(call);
    };

    // 复制快递单号
    const onCopy = (id) => {
        if (copy(id)) message.info('复制成功');
        else message.info('复制失败');
    };

    const onNextModal = ({ logisticsCode, expressNo, storehouseId, logisticsCompany }) => {
        setSpinning(true);
        http('/merchant/order/queryExpress', { com: logisticsCode, num: expressNo, storehouseId }, 'POST')
            .then(res => {
                setDetails(res.result || []); setIsNext(true);
                setSpinning(false); setNextInfo(pre => {
                    return { ...pre, logisticsCompany, expressNo };
                });
            });
    };

    const listNode = () => {
        const { packageTips } = nextInfo;
        const node = <Fragment>
            {packageTips && <Alert message={packageTips} type='info' showIcon />}
            {logisticsRespList.map(i => {
                return <div className={styles.items} key={i.id}>
                    <div className={styles.fedexItem} onClick={() => onListChange(i.id)}>
                        <span className={styles.text}>快递公司：</span>
                        <span className={styles.text}>{i.packageName}</span>
                        {i.isShow ? <Icon type='down' className={styles.icon} /> : <Icon type='right' className={styles.icon} />}
                    </div>
                    {i.isShow && i.packageList.map(j => {
                        return <div key={j.id} className={styles.childItem}>
                            <span className={styles.text}>快递单号：</span>
                            <span className={styles.noText} onClick={() => onNextModal(j)}>{j.expressNo}</span>
                            <span className={styles.copy} onClick={() => onCopy(j.expressNo)}>复制</span>
                        </div>;
                    })}
                </div>;
            })}
        </Fragment>;
        return node;
    };

    const infoNode = () => {
        const description = (text, time, actived) => {
            return <div className={styles[actived ? 'description' : 'descriptionFinish']}>
                <div>{text}</div>
                <div>{time}</div>
            </div>;
        };
        const { deliveryMethod, logisticsCompany, expressNo } = nextInfo;
        const node = <Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <XInfoLabel style={{ marginBottom: '10px' }} label='配送方式：' value={deliveryMethod} />
                    <XInfoLabel style={{ marginBottom: '10px' }} label='快递公司：' value={logisticsCompany} />
                    <XInfoLabel style={{ marginBottom: '10px' }} label='快递单号：' value={expressNo} />
                </div>
                {!!logisticsRespList.length && <Button onClick={() => setIsNext(false)}>返回</Button>}
            </div>
            <Divider />
            <Steps className={styles.steps} progressDot current={0} direction='vertical'>
                {details.map((i, index) => {
                    return <Step key={index} className={styles.step} description={description(i.context, i.ftime, true)} />;
                })}
            </Steps>
        </Fragment>;
        return node;
    };

    return <Modal width={540} centered title='查看物流' visible={visible} confirmLoading={false} bodyStyle={{ padding: '15px' }}
        onCancel={() => closeMode()} destroyOnClose={true} keyboard={true} maskClosable={false} footer={null}
    >
        <div className={styles.container}>
            <Spin spinning={spinning}>
                {isNext ? infoNode() : listNode()}
            </Spin>
        </div>
    </Modal>;
};
LogisticsModal.propTypes = {
    data: PropTypes.object, // 传递入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};
export default LogisticsModal;