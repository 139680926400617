/**
 * 在线播放弹框
 */
import React from 'react';
import { Modal, message } from 'antd';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
import styles from './OnlinePlayModal.module.scss';

class OnlinePlayModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
        };
        this.player = React.createRef(); // 创建ref, 存储 player DOM 元素
    }

    componentDidMount() {
        let { liveState, playbackUrl, liveId } = this.props.modeParams;
        if (liveState === 'LIVE') {
            http('/live/getBroadcasUrl', { liveBroadcasId: liveId, protocal: 'hls' }, 'POST')
                .then((resole) => {
                    this._playM3U8(resole.result.url);
                }).catch((reject) => {
                    message.error(reject.message);
                });
        } else if (liveState === 'END' || liveState === 'NO_VIEWING') {
            this._playM3U8(playbackUrl);
        }
    }

    _playM3U8 = (url) => {
        if (url == '') {
            message.error('视频播放路径为空');
            return;
        }
        setTimeout(() => {
            var container = this.player.current;
            // eslint-disable-next-line no-undef
            new QPlayer({
                url,
                container: container,
                autoplay: true,
            });
        }, 100);
    }

    _handleOk = () => {
        this.setState({
            visible: false,
            url: ''
        });
        this.props.closeMode();
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
            url: ''
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading } = this.state;
        return (
            <Modal
                width={900}
                centered
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
                footer={null}
            >
                <div className={styles.container}>
                    <div ref={this.player} style={{ width: '800px', height: '600px' }} />
                </div>
            </Modal>
        );
    }
}

OnlinePlayModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    modeParams: PropTypes.object,
    url: PropTypes.string
};

export { OnlinePlayModal };