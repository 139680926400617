/**
 * 预览缩略媒体文件-图片、视频、音频、PDF、file
 */
import React, { useState, useEffect, memo } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Icon, } from 'antd';
import PreviewModal from './modal/PreviewModal';

const Modalmedia = ({ style, dataType, dataSource, previewType, ...resetProps }) => {
    const [countParams, setcountParams] = useState({
        indexs: Math.round(Math.random() * 10),//标识key
        reveal: false,//请求响应错误
        previewVisible: false,//modal图片缩略弹框 
        previewItem: '',//modal图片缩略弹框数据url
    });

    const [previewDataType, setPreviewDataType] = useState(dataType);//数据类型
    const [previewDataSource, setPreviewDataSource] = useState(dataSource);//数据url
    const [previewSuffixName, setPreviewSuffixName] = useState('');//数据url后缀


    useEffect(() => {
        //根据url后缀判断相应类型
        let videoList = ['.mp4', '.avi', '.mov', '.rmvb', '.rm', '.flv', '.3gp'];
        let audioList = ['.m4a', '.mp3', '.cda', '.wav', '.flac', '.ape', 'wma'];
        let imageList = ['.jpg', '.png', '.jpeg', '.bmp', '.tif', '.gif', '.webp'];

        if (previewDataType === undefined) {
            let suffixName;
            if ((determineType() === 'string' && previewDataSource.match(/(\.(\w+)\?)|(\.(\w+)$)/i))) {//暂时不能识别数组类型，必须字符串类型-string
                let suffixNameCase;
                suffixName = previewDataSource.match(/(\.(\w+)\?)|(\.(\w+)$)/i)[0].split('?')[0];
                suffixNameCase = suffixName.toLowerCase();
                setPreviewSuffixName(suffixNameCase);
                switch (suffixNameCase) {
                    case imageList.includes(suffixNameCase) === true ? suffixNameCase : '':
                        setPreviewDataType('image');
                        break;
                    case videoList.includes(suffixNameCase) === true ? suffixNameCase : '':
                        setPreviewDataType('video');
                        break;
                    case audioList.includes(suffixNameCase) === true ? suffixNameCase : '':
                        setPreviewDataType('audio');
                        break;
                    case '.pdf':
                        setPreviewDataType('pdf');
                        break;
                    case '.zip':
                        setPreviewDataType('zip');
                        break;
                    default: return setPreviewDataType('image');//如果没有dataType和匹配后缀其他未知，默认为图片类型 image
                }
            } else {
                setPreviewDataType('image');//如果没有dataType和匹配后缀失败，默认为图片类型 image
            }
        }
    }, [previewDataSource]);

    useEffect(() => {
        setPreviewDataSource(dataSource);
        setcountParams({//判断由于没有标准url,导致reveal一直为true
            ...countParams,
            reveal: false
        });
    }, [dataSource]);//更新数据url


    //图片Array类型-数据包括图片，文件（PDF）类型
    const imageArray = () => {
        const { reveal } = countParams;
        //图片error,pdf-error
        if (reveal) return revealResponse(true, 'file');
        return previewDataSource.length > 0 && previewDataSource.map((item, index) => {
            //判断url是否有pdf后缀
            if (item.toLowerCase().indexOf('.pdf') > -1) {
                return (
                    <div style={style} className={styles.media} key={index}>
                        {previewType == 'modal' && <Icon onClick={() => { previewhandleClick(item); }} title='PDF预览' style={{ color: '#fff', cursor: 'pointer' }} type="file-pdf" />}
                        {previewType == 'preview' && <a rel='noopener noreferrer' target='_blank' href={item}><Icon title='PDF预览' style={{ color: '#fff', cursor: 'pointer' }} type="file-pdf" /></a>}
                        <img src={require('@/assets/image/nodata/PDF.png')} alt='pdf' />
                    </div>
                );
            } else {
                return (
                    <div style={style} className={styles.media} key={index}>
                        {previewType == 'modal' && <Icon onClick={() => { previewhandleClick(item); }} title='图片预览' style={{ color: '#fff', cursor: 'pointer' }} type="eye" />}
                        {previewType == 'preview' && <a rel='noopener noreferrer' target='_blank' href={item}><Icon title='图片预览' style={{ color: '#fff', cursor: 'pointer' }} type="eye" /></a>}
                        <img style={{ objectFit: 'cover' }} src={item} alt='图片' onError={() => { _handleImageErrored(); }} />
                    </div>
                );
            }
        });
    };


    //图片string类型
    const imageString = () => {
        const { reveal } = countParams;
        if (reveal) return revealResponse(true);
        return (
            <div style={style} className={styles.media}>
                {previewType == 'modal' && <Icon onClick={() => { previewhandleClick(previewDataSource); }} title='图片预览' style={{ color: '#fff', cursor: 'pointer' }} type="eye" />}
                {previewType == 'preview' && <a rel='noopener noreferrer' target='_blank' href={previewDataSource}><Icon title='图片预览' style={{ color: '#fff', cursor: 'pointer' }} type="eye" /></a>}
                <img style={{ objectFit: 'cover' }} src={previewDataSource} alt='图片' onError={() => { _handleImageErrored(); }} />
            </div>
        );
    };


    //视频string类型
    const videoString = () => {
        const { reveal } = countParams;
        if (reveal) return revealResponse(true);
        return (
            <div style={style} className={styles.media}>
                {previewType == 'modal' && <Icon onClick={() => { previewhandleClick(previewDataSource); }} title='视频预览' style={{ color: '#fff', cursor: 'pointer' }} type="play-circle" />}
                {previewType == 'preview' && <a rel='noopener noreferrer' target='_blank' href={previewDataSource}><Icon title='视频预览' style={{ color: '#fff', cursor: 'pointer' }} type="play-circle" /></a>}
                <video src={previewDataSource} type="video/mp4" preload='auto' onError={() => { _handleImageErrored(); }} />
            </div>
        );
    };


    //音频string类型
    const audioString = () => {
        const { reveal } = countParams;
        if (reveal) return revealResponse(true);
        return (
            <div style={style} className={`${styles.audiobg} ${styles.media}`}>
                {previewType == 'modal' && <Icon onClick={() => { previewhandleClick(previewDataSource); }} title='音频预览' style={{ color: '#fff', cursor: 'pointer' }} type="play-circle" />}
                {previewType == 'preview' && <a rel='noopener noreferrer' target='_blank' href={previewDataSource}><Icon title='音频预览' style={{ color: '#fff', cursor: 'pointer' }} type="play-circle" /></a>}
                <audio src={previewDataSource} onError={() => { _handleImageErrored(); }} />
                <img src={require('@/assets/image/nodata/audio.png')} alt='音乐' />
            </div>
        );
    };


    //PDF-string类型
    const PDFString = () => {
        //判断url是否有pdf后缀 
        if ((previewDataSource.toLowerCase().indexOf('.pdf') < 1)) {
            return revealResponse(true);
        }
        return (
            <div style={style} className={styles.media}>
                {previewType == 'modal' && <Icon onClick={() => { previewhandleClick(previewDataSource); }} title='PDF预览' style={{ color: '#fff', cursor: 'pointer' }} type="file-pdf" />}
                {previewType == 'preview' &&
                    <a rel='noopener noreferrer' target='_blank' href={previewDataSource}><Icon title='PDF预览' style={{ color: '#fff', cursor: 'pointer' }} type="file-pdf" /></a>}
                <img src={require('@/assets/image/nodata/PDF.png')} alt='pdf' />
            </div>
        );
    };


    //files-string类型
    const fileString = () => {
        if ((previewDataSource.indexOf('.pdf') > -1)) {
            return (
                <div style={style} className={styles.media}>
                    {previewType == 'modal' && <Icon onClick={() => { previewhandleClick(previewDataSource); }} title='PDF预览' style={{ color: '#fff', cursor: 'pointer' }} type="file-pdf" />}
                    {previewType == 'preview' &&
                        <a rel='noopener noreferrer' target='_blank' href={previewDataSource}><Icon title='PDF预览' style={{ color: '#fff', cursor: 'pointer' }} type="file-pdf" /></a>}
                    <img src={require('@/assets/image/nodata/PDF.png')} alt='pdf' />
                </div>
            );
        } else {
            return (
                <div style={style} className={styles.media}>
                    <a rel='noopener noreferrer' target='_blank' href={previewDataSource}><Icon title='文件预览' style={{ color: '#fff', cursor: 'pointer' }} type="file" /></a>
                    <img src={require('@/assets/image/nodata/files.png')} alt='file' />
                </div>
            );
        }
    };


    //请求响应错误
    const _handleImageErrored = () => {
        setcountParams({
            ...countParams,
            reveal: true
        });
    };


    //请求响应错误和数据错误 @whetherErrored（boolean）---服务器返回错误信息
    const revealResponse = (whetherErrored, typeError) => {
        const { indexs } = countParams;
        let errorIcon;
        if (previewDataType == 'image') {
            if (typeError == 'file') {
                whetherErrored ? errorIcon = require('@/assets/image/nodata/filesError.png') : errorIcon = require('@/assets/image/nodata/filesnoData.png');
            } else {
                whetherErrored ? errorIcon = require('@/assets/image/nodata/imageError.png') : errorIcon = require('@/assets/image/nodata/imagenoData.png');
            }
        } else if (previewDataType == 'video') {
            whetherErrored ? errorIcon = require('@/assets/image/nodata/videoError.png') : errorIcon = require('@/assets/image/nodata/videonoData.png');
        } else if (previewDataType == 'audio') {
            whetherErrored ? errorIcon = require('@/assets/image/nodata/audioError.png') : errorIcon = require('@/assets/image/nodata/audionoData.png');
        } else if (previewDataType == 'pdf') {
            whetherErrored ? errorIcon = require('@/assets/image/nodata/PDFError.png') : errorIcon = require('@/assets/image/nodata/PDFnoData.png');
        } else if (previewDataType == 'file') {
            whetherErrored ? errorIcon = require('@/assets/image/nodata/filesError.png') : errorIcon = require('@/assets/image/nodata/filesnoData.png');
        }
        return (
            <div style={style} className={styles.mediaData} key={indexs}>
                <img src={errorIcon} />
                <p className={styles.nodataSource}>暂无数据</p>
            </div>
        );
    };


    //判断dataSource类型
    const determineType = () => {
        if (Array.isArray(previewDataSource) && previewDataSource.length > 0) {
            return 'array';
        } else if (typeof previewDataSource === 'string' && previewDataSource.length > 0) {
            return 'string';
        } else {
            return 'null';
        }
    };


    //modal图片缩略弹框
    const renderModal = () => {
        const { previewVisible, previewItem } = countParams;
        let objData = { previewItem, dataType: previewDataType };
        if (previewVisible) {
            return (
                <PreviewModal
                    previewVisible={true}
                    data={objData}
                    previewClose={(parentNodes) => previewhandleCancel(parentNodes)}
                >
                </PreviewModal>);
        }
    };

    //图片缩略关闭
    const previewhandleCancel = (parentNodes) => {
        setcountParams({
            ...countParams,
            previewVisible: false,
            // previewItem: ''  可以不用清除，避免关闭效果图片加载不成功过渡体验
        });
        if (previewDataType == 'audio') {
            parentNodes.pause();
            parentNodes.load();
        } else if (previewDataType == 'video') {
            parentNodes.pause();
            parentNodes.load();
        }
    };


    //图片缩略
    const previewhandleClick = (item) => {
        setcountParams({
            ...countParams,
            previewVisible: true,
            previewItem: item
        });
    };


    //渲染不同类型下的dom
    const renderDates = () => {
        if (previewDataType !== undefined) {
            switch (previewDataType) {
                case 'image':
                    if (determineType() === 'string') {
                        return imageString();
                    } else if (determineType() === 'array') {
                        return imageArray();
                    } else {
                        return revealResponse(false);
                    }
                case 'video':
                    if (determineType() === 'string') {
                        return videoString();
                    } else {
                        return revealResponse(false);
                    }
                case 'audio':
                    if (determineType() === 'string') {
                        return audioString();
                    } else {
                        return revealResponse(false);
                    }
                case 'pdf':
                    if (determineType() === 'string') {
                        return PDFString();
                    } else {
                        return revealResponse(false);
                    }
                case 'file':
                case 'zip':
                    if (determineType() === 'string') {
                        return fileString();
                    } else {
                        return revealResponse(false);
                    }
                default: return null;
            }
        }
    };

    return (
        <div id='previewtype' className={styles.modalmedia} >
            {renderDates()}
            {renderModal()}
        </div >
    );

};

Modalmedia.propTypes = {
    style: PropTypes.object, // 总样式
    bodyStyle: PropTypes.object, // 总样式
    Modalwidth: PropTypes.number, // 弹框宽度样式
    dataSource: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),//数据渲染
    dataType: PropTypes.string,//判断类型  video-视频，audio-音频，image-图片，files-（pdf）,zip-压缩包，file-文件
    previewType: PropTypes.string,//预览类型--浏览器另起窗口预览@-preview，弹框预览@-modal
};

Modalmedia.defaultProps = {
    dataSource: '',
    previewType: 'modal'
};

export default memo(Modalmedia);