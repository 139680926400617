/**
 * 付款确认 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, Input, Alert, Form, Select, message } from 'antd';
import numSwitch from '@/util/numSwitch';
import { regFenToYuan } from '@/util/money';
import styles from './AddRole.module.scss';
import md5 from 'js-md5';

const { TextArea } = Input;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
    // labelAlign: 'left',
};
const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 2 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
    // labelAlign: 'left',
};
class NoLogin extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        status: '',
        reason: ''
    }
    // 点击确认
    _handleOk = () => {
        let props = this.props.form.getFieldsValue();
        const { id } = this.props.data || {};
        http('/settle/paymentApplication/confirm', {
            paymentApplicationId: id,
            ...props,
            password: md5(props.password).toUpperCase()
        }, 'POST').then(() => {
            message.success('确认付款成功');
            this.setState({
                visible: false,
            });
            this.props.closeMode(true);
        }).catch((res = {}) => {
            message.error('确认付款失败，原因：' + res.message);
        });
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;

        let { id, paymentAmount } = this.props.data || {};
        let showPaymentAmount = regFenToYuan(paymentAmount);
        let computedPaymentAmount = regFenToYuan(paymentAmount, false);

        return (
            <Modal
                width={600}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Alert message="确认后,付款完成" type="info" showIcon style={{ textAlign: 'left' }} />
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon
                            htmlFor='type'
                            label={<span>付款单号</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {id}
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='type'
                            label={<span>合计转账金额</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {showPaymentAmount} ({paymentAmount || paymentAmount == '0' ? numSwitch(Number(computedPaymentAmount)) : ''})
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='password'
                            label={<span>财务密码</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: '请输入' }],
                            })(
                                <Input.Password placeholder="请输入" />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='remark'
                            label={<span>备注</span>}
                        >
                            {getFieldDecorator('remark', {
                                // rules: [{ required: true, message: '请输入策略名称' }],
                            })(
                                <TextArea rows={4} />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

NoLogin.propTypes = {
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(NoLogin);// 校验
export default connect(null, null)(ForgotProducDetails);