import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

// 获取一级、二级菜单列表
export const getMenuList = function* () {
    yield takeEvery(T.GET_MENU_LIST, function* requestData(action) {
        try {
            let result = yield http('/admin/menu/listMenu', action.payload, 'POST');
            yield put({ type: T.SET_MENU_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取全部菜单列表
export const getAllMenuList = function* () {
    yield takeEvery(T.GET_MENU_ALL_LIST, function* requestData(action) {
        try {
            let result = yield http('/admin/menu/listMenuAll', action.payload, 'POST');
            yield put({ type: T.SET_MENU_ALL_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取菜单详情
export const getMenuDetail = function* () {
    yield takeEvery(T.GET_MENU_DETAIL, function* requestData(action) {
        try {
            const id = action.payload.id;
            let result = yield http('/admin/menu/getMenuById/' + id, action.payload, 'POST');
            yield put({ type: T.SET_MENU_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取一级菜单下拉列表
export const getMenuSelect = function* () {
    yield takeEvery(T.GET_MENU_SELECT, function* requestData(action) {
        try {
            let result = yield http('/admin/menu/listMenuLevelOne', action.payload, 'POST');
            yield put({ type: T.SET_MENU_SELECT, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

