import * as T from './actiontypes';

//获取商品费率列表
export const getProductRateListData = (params = {}) => ({
    type: T.GET_PRODUCTRATE_LISTDATA,
    payload: params
});
//批量设置
export const batchSetListData = (params = {}) => ({
    type: T.BATCH_SET_LISTDATA,
    payload: params
});
//单独设置
export const singleSetListData = (params = {}) => ({
    type: T.SINGLE_SET_LISTDATA,
    payload: params
});
//设置最小费率
export const minimumRateListData = (params = {}) => ({
    type: T.MINIMUM_RATE_LISTDATA,
    payload: params
});
//移除
export const removeListData = (params = '') => ({
    type: T.REMOVE_LISTDATA,
    payload: params
});

export const getProductRateStatus = () => ({
    type: T.GET_PRODUCTRATE_STATUS,
    payload: {}
});

export const getProductRateBusinessType = () => ({
    type: T.GET_PRODUCTRATE_BUSINESSTYPE,
    payload: {}
});

export const getProductRateLabelSele = () => ({
    type: T.GET_PRODUCTRATE_LABELSELE,
    payload: {}
});

export const getProductRateDetail = (goodsId) => ({
    type: T.GET_PRODUCTRATE_DETAIL,
    payload: { goodsId }
});

export const cleanProductDetail = () => ({
    type: T.CLEAN_PRODUCT_DETAIL,
});

export const getActiveRateDetail = (goodsId) => ({
    type: T.GET_ACTIVERATE_DETAIL,
    payload: { goodsId }
});

export const getConversionRate = () => ({
    type: T.GET_CONVERSION_RATE,
});
