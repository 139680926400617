import { createModel } from '@/store/tools';

const tableData = { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 };

const model = {
    namespace: 'chargeofflist',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getTableData': T('getTableData'),
            'getStateEnum': T('getStateEnum'),
            'getDetailData': T('getDetailData'),
        },
        actions: {
            'getTableData': A('getTableData'),
            'getStateEnum': A('getStateEnum'),
            'getDetailData': A('getDetailData'),
        },
        sagas: {
            'getTableData': S('getTableData', '/marketing/orderVerification/findPageOrderVerification'),
            'getStateEnum': S('getStateEnum', '/enum/VerificationStateEnum'),
            'getDetailData': S('getDetailData', '/marketing/orderVerification/findVerificationDetail'),
        },
        reducers: {
            'tableData': R('getTableData', tableData),
            'stateEnum': R('getStateEnum', []),
            'detailData': R('getDetailData', { verificationCodeResps: [] }),
        }
    })
};
export default createModel(model);