/**
 * 绑定主播帐号
 */
import React from 'react';
import { Modal, Radio, Input, message, Statistic } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { getLocalStorage } from '@/assets/js/storage';
import styles from './index.module.scss';
import * as QRCode from 'easyqrcodejs';
import { XOKButton, XInput, XInfoLabel } from '@/components/xqxc_ui';

const { Countdown } = Statistic;
const RadioGroup = Radio.Group;
const { TextArea } = Input;
let captchaIns;
class BindAccountModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
            tel: '',
            openAnchor: false, // 开通主播
            alreadyAnchor: false,
            openCustomer: false, // 开通客服座席
            openAppDowload: false, // app下载
            isOpen: true,
            loginName: '',
            loading: false,
            yztime: 59,
        };
        this.qrcode = React.createRef();
        this.qrcodeDowload = React.createRef();
        this.qrcodeCust = React.createRef();
    }
    componentDidMount() {
        const { data } = this.props;
        this.getserviceAccount();
        if (data && data.flag === 1 && data.openCustomerServiceState !== 'NOT_BUY') {
            // 已开通主播，已开通客服坐席
            this.setState({ isOpen: false });
            this.getAppUrl();
        } else if (data && data.flag === 1 && data.openCustomerServiceState === 'NOT_BUY') {
            // 已开通主播，未开通坐席
            this.getMerchantBindAnchorUrl();
        } else if (data && data.flag === 0) {
            // 未开通主播
            this.getMerchantBindAnchorUrl();
        }
        this.initNECaptcha();
    }
    initNECaptcha = () => {
        var _this = this;
        window.initNECaptcha({
            element: '#captcha',
            captchaId: 'bc010deb2aca4d67ad5da81dc83503f7',
            mode: 'popup',
            width: '320px',
            // enableClose: true, // 由业务方控制验证码弹框关闭
            // feedbackEnable: false, // 业务方关闭反馈入口
            onClose: function () {
                // 弹出关闭结束后将会触发该函数
            },
            onVerify: function (err, data) {
                if (!err) {
                    // 验证成功后，调用close方法关闭弹框（enableClose为true时调用）
                    // captchaIns.close();
                    _this.getVerifyCode(data.validate);
                }
            }
        }, function (instance) {
            // 初始化成功后得到验证实例instance，可以调用实例的方法
            captchaIns = instance;
        }, function (err) {
            // 初始化失败后触发该函数，err对象描述当前错误信息
        });
    };
    // 商户开通主播资格url
    getMerchantBindAnchorUrl = () => {
        http('/admin/param/getMerchantBindAnchorUrl', {}, 'POST').then((response) => {
            if (response.status == 200) {
                this.setState({ AnchorUrl: response.result }, () => {
                    const { data } = this.props;
                    if (data && data.flag === 1 && data.openCustomerServiceState === 'NOT_BUY') {
                        this.openCustomer();
                    }
                });
            }
        }).catch((e) => {
            message.error(e.message);
        });
    };
    // App下载
    getAppUrl = () => {
        this.setState({ openAppDowload: true, isOpen: false }, () => {
            http('/admin/param/getParamValueByCode/DOWNLOAD_ERCODE_URL_CUSTOMER_APP', {}, 'POST').then((response) => {
                if (response.status == 200) {
                    this.setState({ appUrl: response.result });
                    let options = {
                        text: response.result,
                    };
                    new QRCode(this.qrcodeDowload.current, options);
                    // setTimeout(() => {
                    //     new QRCode(this.qrcodeDowload.current, options);
                    // }, 1000);
                }
            }).catch((e) => {
                message.error(e.message);
            });
        });
    };
    // 获取商户账号
    getserviceAccount = () => {
        const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
        this.setState({ loginName: userInfo && userInfo.loginName });
    };
    // 马上开通主播资格
    openAnchor = () => {
        const { AnchorUrl } = this.state;
        this.setState({ openAnchor: true, isOpen: false }, () => {
            // Options
            let options = {
                text: AnchorUrl,
            };
            // Create new QRCode Object
            new QRCode(this.qrcode.current, options);
        });
    };
    // 开通客服坐席
    openCustomer = () => {
        const { AnchorUrl } = this.state;
        this.setState({ openCustomer: true, isOpen: false }, () => {
            // Options
            let options = {
                text: AnchorUrl,
            };
            // Create new QRCode Object
            new QRCode(this.qrcodeCust.current, options);
        });
    };
    // 已有主播账号
    alreadyAnchor = () => {
        const { data } = this.props;
        if (data.openCustomerServiceState === 'NOT_BUY') {
            // 未开通客服座席 跳到我已有主播帐号
            this.openCustomer();
        } else {
            this.setState({ alreadyAnchor: true, isOpen: false });
        }
    };
    // 获取验证码
    getVerifyCode = (validate) => {
        const { tel } = this.state;
        if (tel) {
            http('/live/getTelCaptcha', { tel, validate }, 'POST').then((response) => {
                if (response.status == 200) {
                    console.log('验证码');
                    this.setState({ loading: true });
                    this.count();
                } else {
                    message.error('您输入的帐号还未开通主播资格服务，请检查输入帐号是否有误。');
                }
            }).catch((e) => {
                message.error(e.message);
            });
        } else {
            message.warn('请输入手机号!');
        }
    };
    getVerifyCodeBtn = () => {
        const { tel, loading } = this.state;
        if (tel && !loading) {
            captchaIns && captchaIns.refresh(); // 密码错误等原因导致登陆失败，业务上若需要刷新验证码，可调用refresh方法
            captchaIns && captchaIns.popUp();
        } else if (tel === '' && !loading) {
            message.warn('请输入手机号!');
        }
    };
    // 绑定主播
    OkHandle = () => {
        const { tel, code } = this.state;
        const { data } = this.props;
        if (tel && code) {
            http('/live/merchantBindAnchor', { tel, code }, 'POST').then((response) => {
                if (response.status == 200) {
                    message.success('绑定成功!');
                    if (data.openCustomerServiceState === 'NOT_BUY') {
                        // 未开通客服座席 跳到我已有主播帐号
                        this.setState({ alreadyAnchor: false }, () => {
                            this.openCustomer();
                        });
                    } else {
                        // app下载
                        this.setState({ alreadyAnchor: false }, () => {
                            this.getAppUrl();
                        });
                    }
                    // this.setState({
                    //     visible: false,
                    // });
                    // this.props.closeMode();
                }
            }).catch((e) => {
                message.error(e.message);
            });
        } else {
            message.warn('手机或验证码不为空!');
        }
    };
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    };
    //倒计60s
    count = () => {
        let { yztime } = this.state;
        let siv = setInterval(() => {
            this.setState({ yztime: (yztime--) }, () => {
                if (yztime <= -1) {
                    clearInterval(siv);//倒计时( setInterval() 函数会每秒执行一次函数)，用 clearInterval() 来停止执行:
                    this.setState({ loading: false, yztime: 59 });
                }
            });
        }, 1000);
    };
    render() {
        const { visible, confirmLoading, isOpen, tel, code, openAppDowload } = this.state;
        const { data } = this.props;
        return (
            <Modal
                className={styles.modalContainer}
                width={650}
                centered
                title={null}
                visible={visible}
                confirmLoading={confirmLoading}
                // onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                footer={null}
            >
                {isOpen ?
                    <div className={styles.container}>
                        <div className={styles.top}>
                            <div className={styles.title}>主播资格开通</div>
                            <div className={styles.tips}>您的帐号还不是主播帐号，想直播带货，赶紧开通主播资格成为秀商吧</div>

                            <div>
                                {/* 4/25隐藏入口 */}
                                {/* <XOKButton
                                    style={{width: '140px', display: 'inline-block'}}
                                    label='我已有主播帐号'
                                    onClick={this.alreadyAnchor}
                                /> */}
                                <XOKButton
                                    style={{ width: '140px', display: 'inline-block' }}
                                    label='马上开通主播资格'
                                    onClick={this.openAnchor}
                                />
                            </div>
                        </div>
                    </div> : openAppDowload ?
                        /*// APP下载二维码*/
                        <div className={styles.container}>
                            <div className={styles.top}>
                                <div className={styles.title}>APP下载二维码</div>
                                <div className={styles.tips}>您是否还没有下载【每日说】APP呢，使用手机浏览器扫描下方二维码
                    赶紧去直播吧</div>
                                <div ref={this.qrcodeDowload} id='qrcodeDowload'></div>
                            </div>
                        </div> : null
                }
                {/*马上开通主播资格*/}
                {this.state.openAnchor &&
                    <div className={styles.container}>
                        <div className={styles.top}>
                            <div className={styles.title}>主播资格帐号开通</div>
                            <div className={styles.tips}>请用手机浏览器或微信扫一扫，扫描下方二维码开通主播资格帐号吧</div>
                            <div ref={this.qrcode}></div>
                        </div>
                    </div>}
                {/*马上开通客服座席*/}
                {this.state.openCustomer &&
                    <div className={styles.container}>
                        <div className={styles.top}>
                            <div className={styles.title}>温馨提示</div>
                            <div className={styles.tips}>您尚未开通客服座席，客服座席的开通方便买家与卖家的沟通，
                            提升良好的卖家形象，发掘市场机会，赶紧去开通吧</div>
                            <div ref={this.qrcodeCust}></div>
                        </div>
                    </div>}
                {/*// 我已有主播帐号*/}
                {this.state.alreadyAnchor &&
                    <div className={styles.container}>
                        <div className={styles.top}>
                            <div className={styles.title}>主播帐号绑定</div>
                            <div style={{ color: '#252525', margin: '20px auto', width: 340, textAlign: 'left' }}>
                                <span style={{ marginLeft: 10 }}>您已开通的商户帐号:</span>
                                <span style={{ marginLeft: 10 }}>{this.state.loginName}</span>
                            </div>
                            <XInput
                                style={{ justifyContent: 'center', marginLeft: 17 }}
                                inputStyle={{ width: '200px' }}
                                label='输入已有的主播帐号:'
                                placeholder=''
                                value={tel}
                                bindThis={this}
                                bindName='tel'
                            />
                            <div style={{ margin: '20px auto', width: 340, textAlign: 'left' }}>
                                <XInput
                                    style={{ display: 'inline-block', }}
                                    inputStyle={{ width: '200px', }}
                                    label=''
                                    placeholder=''
                                    value={code}
                                    bindThis={this}
                                    bindName='code'
                                />
                                <span id='j-popup' style={{ marginLeft: 40, color: '#1890ff', cursor: 'pointer' }} onClick={this.getVerifyCodeBtn}>
                                    {this.state.loading ? this.state.yztime + '秒' : '获取验证码'}
                                </span>
                            </div>
                            <XOKButton
                                style={{ width: '100px', display: 'inline-block' }}
                                label='确定'
                                onClick={this.OkHandle}
                            />
                        </div>
                    </div>}
                <div id="captcha"></div>
            </Modal>
        );
    }
}

BindAccountModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};

export default BindAccountModal;