import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';

const List = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    resultTotal: 0
};

const model = {
    namespace: 'visitantBrand',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getConnectBrands': T('getConnectBrands'),
            'getBalanceValue': T('getBalanceValue'),
        },
        actions: {
            'getConnectBrands': A('getConnectBrands'),
            'getBalanceValue': A('getBalanceValue'),
        },
        sagas: {
            'getConnectBrands': S('getConnectBrands', '/out/supplier/brand/list'),
            'getBalanceValue': S('getBalanceValue', '/out/supplier/goods/query/balance'),
        },
        reducers: {
            'connectBrands': R('getConnectBrands', List),
            'balanceValue': R('getBalanceValue', {}),
        }
    })
};
export default createModel(model);