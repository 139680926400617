import { createModel } from '@/store/tools';

const model = {
    namespace: 'invitemember',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getMerchantShareAccountInfo': T('getMerchantShareAccountInfo'),
        },
        actions: {
            'getMerchantShareAccountInfo': A('getMerchantShareAccountInfo'), // 邀请会员
        },
        sagas: {
            'getMerchantShareAccountInfo': S('getMerchantShareAccountInfo', '/merchant/getMerchantShareAccountInfo'),
        },
        reducers: {
            'MerchantShareAccountInfo': R('getMerchantShareAccountInfo', {}),
        }
    })
};
export default createModel(model);