import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell, ImgCell } from '@/components/TableCell';
import { getConfigInfo } from '@/assets/js/authType';

const insert = (arrfirst, arrlast, index) => {
    if (index < 0) {
        index = 0;
    } else if (index > arrfirst.length) {
        index = arrfirst.length;
    }
    for (let i = arrlast.length - 1; i >= 0; i--) {
        //在index位置用arrlast[i]替换掉arrfirst数组中的0个元素
        arrfirst.splice(index, 0, arrlast[i]);
    }
    return arrfirst;
};

export default class TableContent extends Component {

    state = {
        selectedRowKeys: [],
        hasTechnical: false,//是否收取技术服务费
        hasPlatService: false,//是否收取平台服务费
        hasShowService: false,//是否收取秀场服务费
        hasFavorite: false,//是否收取心选服务费
    };

    componentDidMount() {
        getConfigInfo().then(res => {
            this.setState({
                hasPlatService: res.platService,
                hasTechnical: res.technique,
                hasShowService: res.showService,
                hasFavorite: res.favorite,
            });
        }).catch((err = {}) => {
            console.log(err.message);
        });
    }

    //表头
    _columns = () => {
        let arrList = [
            {
                title: '商品图片',
                dataIndex: 'mainImgUrl',
                key: 'mainImgUrl',
                width: 100,
                align: 'center',
                render: ImgCell
            },
            {
                title: '商品标题',
                dataIndex: 'goodsName',
                key: 'goodsName',
                width: 200,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '货号',
                dataIndex: 'goodsNo',
                key: 'goodsNo',
                width: 200,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '商品分类',
                dataIndex: 'categoryName',
                key: 'categoryName',
                width: 200,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '销售价格（元）',
                dataIndex: 'salePrice',
                key: 'salePrice',
                width: 150,
                align: 'center',
                render: priceFenToYuanCell
            },
            // {
            //     title: '技术服务费（元）',
            //     dataIndex: 'platRateValue',
            //     key: 'platRateValue',
            //     width: 150,
            //     align: 'center',
            //     render: priceFenToYuanCell
            // },
            // {
            //     title: '平台服务费（元）',
            //     dataIndex: 'agentRateValue',
            //     key: 'agentRateValue',
            //     width: 150,
            //     align: 'center',
            //     render: priceFenToYuanCell
            // },
            // {
            //     title: '秀场服务费（元）',
            //     dataIndex: 'showRatioValue',
            //     key: 'showRatioValue',
            //     width: 150,
            //     align: 'center',
            //     render: priceFenToYuanCell
            // },
            // {
            //     title: '心选推荐费（元）',
            //     dataIndex: 'serviceRatioValue',
            //     key: 'serviceRatioValue',
            //     width: 150,
            //     align: 'center',
            //     render: priceFenToYuanCell
            // },
            {
                title: '人气值金额（元）',
                dataIndex: 'starValue',
                key: 'starValue',
                width: 150,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '销售价格预估（元）',
                dataIndex: 'estimatedValue',
                key: 'estimatedValue',
                width: 150,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '费用模式',
                dataIndex: 'rateMode',
                key: 'rateMode',
                width: 150,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '分销技术服务费（%）',
                dataIndex: 'retailPlatRate',
                key: 'retailPlatRate',
                width: 200,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '分销平台服务费（%）',
                dataIndex: 'retailAgentRate',
                key: 'retailAgentRate',
                width: 200,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '状态',
                dataIndex: 'auditState',
                key: 'auditState',
                width: 100,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '操作',
                dataIndex: 'goodsId',
                key: 'goodsId',
                width: 100,
                align: 'center',
                render: (id, record) => this._tableAction(id, record)
            }
        ];

        const { hasTechnical, hasPlatService, hasShowService, hasFavorite } = this.state;
        let technical, platService, showService, favorite;
        let hasArr = [];
        technical = {
            title: '技术服务费（元）',
            dataIndex: 'platRateValue',
            key: 'platRateValue',
            width: 150,
            align: 'center',
            render: priceFenToYuanCell
        };
        platService = {
            title: '平台服务费（元）',
            dataIndex: 'agentRateValue',
            key: 'agentRateValue',
            width: 150,
            align: 'center',
            render: priceFenToYuanCell
        };
        showService = {
            title: '秀场服务费（元）',
            dataIndex: 'showRatioValue',
            key: 'showRatioValue',
            width: 150,
            align: 'center',
            render: priceFenToYuanCell
        };
        favorite = {
            title: '心选推荐费（元）',
            dataIndex: 'serviceRatioValue',
            key: 'serviceRatioValue',
            width: 150,
            align: 'center',
            render: priceFenToYuanCell
        };

        if (hasTechnical) hasArr.push(technical);
        if (hasPlatService) hasArr.push(platService);
        if (hasShowService) hasArr.push(showService);
        if (hasFavorite) hasArr.push(favorite);

        insert(arrList, hasArr, 5);
        return arrList;
    }

    // 表格操作
    _tableAction = (id, record) => {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => this.props.tableAction(id, 'detail', record)}>详情</div>
            </div>
        );
    };

    //校验props
    static propTypes = {
        tableAction: PropTypes.func,
        paginationChange: PropTypes.func,
        renderData: PropTypes.object,
        batchData: PropTypes.array
    };

    _dealTableData = (data) => {
        return data || [];
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
            this.props.tableAction(null, 'batch', selectedRowKeys);
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    render() {
        const { renderData, paginationChange } = this.props;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;

        return (
            <div className={styles.tableContainer} >
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    columns={this._columns()}
                    pagination={false}
                    rowKey={record => record.goodsId}
                    dataSource={this._dealTableData(renderData.dataList)}
                    scroll={{ x: 2500, y: 'true' }} />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={paginationChange} />
                    
            </div>
        );
    }
}