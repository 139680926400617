/**
 *  【仓库商品对接】入口页
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const ProductConnect = ({ history }) => {
    return <Main history={history} />;
};

ProductConnect.propTypes = {
    history: PropTypes.object
};

export default ProductConnect;