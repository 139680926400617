/**
 * 订单管理 Saga集合
 */
import { getProductOrderList, getProductOrderType, getProductOrderStatus, getProductSubOrderStatus, getProductOrderFlagEnum, getFenxiaoOrderList, getBusinessType } from './productOrder/saga';
import { getTranscationList, getStoreList, getMerchantOrderAmount, getTranscationList2 } from './transcation/saga';
import { getShopOrderList, getExpressCompanyList } from './shopOrder/saga';
import { getCollectMoneyList, getCollectMoneyInfo } from './collecttrade/saga';
import { getHotelOrderList, getPayWays, getRoomNum, getRoomInfo } from './hotelOrder/saga';
import stockearOrder from './stockearorder/model';
import wholesaleorder from './wholesaleorder/model';
import cityOrder from './cityOrder/model';
export default [
    getProductOrderList,
    getProductOrderType,
    getProductOrderStatus,
    getProductOrderFlagEnum,
    getStoreList,
    getMerchantOrderAmount,
    getTranscationList,
    getTranscationList2,
    getShopOrderList,
    getExpressCompanyList,
    getCollectMoneyInfo,
    getCollectMoneyList,
    getProductSubOrderStatus,
    getFenxiaoOrderList,
    getHotelOrderList,
    getPayWays,
    getRoomNum,
    getBusinessType,
    getRoomInfo,
    ...Object.values(stockearOrder.sagas),
    ...Object.values(wholesaleorder.sagas),
    ...Object.values(cityOrder.sagas),
];
