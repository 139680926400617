import { createModel } from '@/store/tools';

const model = {
    namespace: 'fightrecord',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getFightrecordList': T('getFightrecordList'),
            'getFightrecordCost': T('getFightrecordCost'),
        },
        actions: {
            'getFightrecordList': A('getFightrecordList'), //抢拍记录列表数据
            'getFightrecordCost': A('getFightrecordCost'), //抢拍记录-平台费用数据
        },
        sagas: {
            'getFightrecordList': S('getFightrecordList', '/merchant/order/getQPOrderList'),
            'getFightrecordCost': S('getFightrecordCost', '/settle/reconciliationSource/platFeeDetail'),
        },
        reducers: {
            'fightrecordList': R('getFightrecordList', { orderList: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 } }),
            'fightrecordCost': R('getFightrecordCost', {}),
        }
    })
};
export default createModel(model);