/**
 * 【酒店订单页】
 */
import React from 'react';
import { XTabNav, showConfirm } from '@/components/xqxc_ui';
import OrderSearch from './components/OrderSearch';
import { message } from 'antd';
import http from '@/assets/api/http';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import LogisticsModal from '../../modal/LogisticsModal';
import LeaveMsg from '../../modal/LeaveMsg';
import { connect } from 'react-redux';
import WebIMModal from '@/vcomps/WebIMModal';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getHotelOrderList } from '@/reducers/ordermanage/hotelOrder/actions';
import CancelOrder from './modal/CancelOrder';

import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {
    state = {
        tab: [
            { id: 1, label: '全部订单' },
            { id: 2, label: '待付款' },
            { id: 6, label: '待确认' },
            { id: 3, label: '待入住' },
            { id: 4, label: '已完成' },
            { id: 5, label: '已取消' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
        renderMode: '', // 是否显示弹框（查看物流）
        modeParams: {},
        orderID: '', // 当前操作的订单ID 
        pageNum: 1,
        pageSize: 50,
        searchData: {},
        visible: false,
    }

    componentDidMount() {
        // const { pageNum, pageSize } = this.state;
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getProductOrderList({ pageNum, pageSize });
    }

    // 表格操作
    _tableAction = (key, type, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            // 查看详情
            case 'info':
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: '/home/ordermanage/hotelorder/orderdetail/'
                });
                history.push({
                    pathname: '/home/ordermanage/hotelorder/orderdetail',
                    search: `?buyerId=${record.buyerId}&&orderId=${record.id}`,
                });
                break;
            // 查看物流
            case 'logistics':
                this.setState({ renderMode: 'logistics', orderID: key });
                break;
            // 删除
            case 'del':
                showConfirm('是否确认删除？', '', () => {
                    http('/hotel/order/delete', { buyerId: record.buyerId, id: record.id }, 'POST')
                        .then((res) => {
                            if (res.status == 200) {
                                message.success('删除成功!');
                                this._searchHandle('useCache');
                            }
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            // 操作记录
            case 'history':
                addOneBreadcrumbPath({
                    title: '操作记录',
                    path: '/home/ordermanage/hotelorder/orderhistory/'
                });
                history.push({
                    pathname: '/home/ordermanage/hotelorder/orderhistory',
                    search: `?buyerId=${record.buyerId}&&orderId=${record.id}`,
                });
                break;
            case 'leaveMsg':
                this.setState({ renderMode: 'leaveMsg', modeParams: record });
                break;
            // 联系买家
            case 'business':
                this.setState({ visible: true });
                break;
            //确认订单
            case 'affirm':
                showConfirm('确认后订单将正式生效，请确保您的房源充足。', '', () => {
                    http('/hotel/order/confirmOrder', { buyerId: record.buyerId, id: record.id }, 'POST')
                        .then((res) => {
                            if (res.status == 200) {
                                message.success(res.message);
                                this._searchHandle('useCache');
                            } else {
                                message.warning(res.message);
                            }
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            //取消订单
            case 'cancel':
                this.setState({ renderMode: 'cancelOrder', modeParams: record });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        const { searchData } = this.state;
        const reqParams = { ...searchData, pageNum, pageSize };
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.setState({ pageSize, pageNum, reqParams });
        this.props.getHotelOrderList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 打开查看物流弹框
    _renderMode = () => {
        const { renderMode, modeParams } = this.state;
        switch (renderMode) {
            case 'logistics':
                return <LogisticsModal visible={true} data={this.state.orderID} closeMode={this._closeMode} />;
            case 'leaveMsg':
                return <LeaveMsg visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
            case 'cancelOrder':
                return <CancelOrder
                    modeParams={modeParams}
                    closeMode={this._closeMode}
                />;
            default:
                return null;
        }
    }

    // 关闭查看物流弹框
    _closeMode = () => {
        this.setState({ renderMode: '', modeParams: {} }, () => {
            this._searchHandle('useCache');
        });
    }

    _searchHandle = (useCache) => {
        const { searchData, pageNum, pageSize } = this.state;
        this.searchCache = {
            ...searchData,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.props.getHotelOrderList(this.searchCache);
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        const { searchData } = this.state;
        this.setState({ curTabID: tabID });
        switch (tabID) {
            case 1://全部
                this.setState({
                    curTabIDStatus: '',
                    searchData: {
                        ...searchData,
                        status: '',
                        sonStatus: undefined
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 2://待付款
                this.setState({
                    curTabIDStatus: 'NEW',
                    searchData: {
                        ...searchData,
                        status: 'NEW',
                        sonStatus: undefined
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 6://待确认
                this.setState({
                    curTabIDStatus: 'PAIED',
                    searchData: {
                        ...searchData,
                        status: 'PAIED',
                        sonStatus: 'UNCONFIRM'
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 3://待入住
                this.setState({
                    curTabIDStatus: 'PAIED',
                    searchData: {
                        ...searchData,
                        status: 'PAIED',
                        sonStatus: 'CONFIRMED'
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 4://已完成
                this.setState({
                    curTabIDStatus: 'HOSTE',
                    searchData: {
                        ...searchData,
                        status: 'HOSTE',
                        sonStatus: undefined
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 5://已取消
                this.setState({
                    curTabIDStatus: 'CANCLED',
                    searchData: {
                        ...searchData,
                        status: 'CANCLED',
                        sonStatus: undefined
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
        }
    }

    _searchOrder = (searchData, param, flag) => {
        const { pageNum, pageSize, curTabID, curTabIDStatus } = this.state;
        let reqParams = {};
        if (curTabID === 1) {
            reqParams = { ...searchData, pageNum, pageSize };
        } else if (curTabID === 6 || curTabID === 3) {
            reqParams = { ...searchData, pageNum, pageSize, status: curTabIDStatus, sonStatus: curTabID === 6 ? 'UNCONFIRM' : 'CONFIRMED' };
        } else {
            reqParams = { ...searchData, pageNum, pageSize, status: curTabIDStatus };
        }
        this.setState({ searchData: reqParams, reqParams, param }, () => {
            if (flag) {
                // 重置
                KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
            } else {
                this._searchHandle('useCache');
            }
        });
    }

    render() {
        const { hotelOrderList } = this.props;
        const { curTabID, reqParams, visible } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <OrderSearch searchState={this.state} orderState={this.state.curTabID} searchOrder={this._searchOrder} />
                    {/* <Export reqParams={reqParams} /> */}
                    <OrderTable renderData={hotelOrderList} tableAction={this._tableAction} paginationChange={this._paginationChange} curTabID={curTabID} />
                    {this._renderMode()}
                    <WebIMModal title='联系买家' visible={visible} onCancel={() => this.setState({ visible: false })} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getHotelOrderList: PropTypes.func,
    hotelOrderList: PropTypes.object
};

const mapStateToProps = (state) => ({
    hotelOrderList: state.hotelOrder.hotelOrderList,
});

export default connect(mapStateToProps, { getHotelOrderList, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);
