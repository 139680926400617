/**
 * 订单应收金额明细
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { isGY, isJM, isEJ } from '@/assets/js/authType';
import GYOrder from './components/GYOrder';
import JMOrder from './components/JMOrder';

class Main extends React.Component {

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    // 渲染相应内容(不同的用户身份查看的内容不同)
    _renderPage = () => {
        const { history } = this.props;
        let element = null;
        if (isGY()) {
            // 供应商身份
            console.log('供应商身份');
            element = <GYOrder history={history} />;
        } else if (isJM() || isEJ()) {
            // 加盟商身份
            console.log('加盟商身份');
            element = <JMOrder history={history} />;
        } else {
            element = '不属于商户后台，没有查看该模块权限！若需查看请登录商户后台';
        }
        return element;
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                {this._renderPage()}
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
};

export default connect(null, { keepSecondNavBreadcrumb })(Main);