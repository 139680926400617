import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Table, Alert } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const FailMode = ({ closeMode, failList }) => {

    const _columns = () => {
        return [
            {
                title: '商品名称',
                dataIndex: 'goodsName',
                key: 'goodsName',
                align: 'center',
                width: '20%',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '商品货号',
                dataIndex: 'goodsNo',
                key: 'goodsNo',
                align: 'center',
                width: '20%',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: 'sku编码',
                dataIndex: 'skuNo',
                key: 'skuNo',
                align: 'center',
                width: '20%',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '异常描述',
                dataIndex: 'errorMsg',
                key: 'errorMsg',
                align: 'center',
                width: '40%',
                onCell: tooltipStyle,
                render: tooltip
            },
            // {
            //     title: '导入时间',
            //     dataIndex: 'time',
            //     key: 'time',
            //     align: 'center',
            //     width: '20%',
            //     onCell: tooltipStyle,
            //     render: tooltip
            // },
            // {
            //     title: '操作人',
            //     dataIndex: 'operater',
            //     key: 'operater',
            //     align: 'center',
            //     width: '20%',
            //     onCell: tooltipStyle,
            //     render: tooltip
            // }
        ];
    };

    //确定取消
    const _backHandle = () => {
        closeMode();
    };

    return <Modal
        width={1200}
        centered
        title='分销商品导入异常'
        visible={true}
        confirmLoading={false}
        onOk={_backHandle}
        onCancel={_backHandle}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'>
        <div>
            {/* <Alert type='error' message='请您关注异常描述，按提示处理后重新导入。' showIcon style={{ marginBottom: '20px' }} /> */}
            <Table
                columns={_columns()}
                pagination={false}
                rowKey={(record, index) => index}
                dataSource={failList}
                scroll={{ y: 500 }}
            />
        </div>
    </Modal>;
};

FailMode.defaultProps = {
    failList: []
};

FailMode.propTypes = {
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    failList: PropTypes.array.isRequired//数据
};

export default FailMode;
