/**
 * [手绘签名]
 * @author: Fu Xiaochun
 * @date: 2022-10-28 
 */

export default class Signature{
    constructor(selector){
        this.$canvasBox = document.querySelector(selector);
        this.width = this.$canvasBox.clientWidth;
        this.height = this.$canvasBox.clientHeight;
        this.drawing = false;
        this.left = 0;
        this.top = 0;        
        this.init();
    }
    init(){
        this.canvas = document.createElement('canvas');
        this.canvas.width = this.width;
        this.canvas.height = this.height;
        this.canvas.style.backgroundColor = 'rgba(255,255,255,0)';
        this.$canvasBox.appendChild(this.canvas);
        this.getCanvasPosition();
        this.ctx = this.canvas.getContext('2d');
        this.bindEvent();
    }
    getCanvasPosition(){
        let _position = this.$canvasBox.getBoundingClientRect();
        this.left = _position.left;
        this.top = _position.top;
    }
    bindEvent(){
        window.addEventListener('resize', ()=>{
            this.getCanvasPosition();
        });
        // this.canvas.addEventListener('touchstart', this.pathStart.bind(this));
        // this.canvas.addEventListener('touchmove', this.pathMove.bind(this));
        // this.canvas.addEventListener('touchend', this.pathEnd.bind(this));
        this.canvas.addEventListener('mousedown', this.pathStart.bind(this));
        this.canvas.addEventListener('mousemove', this.pathMove.bind(this));
        this.canvas.addEventListener('mouseup', this.pathEnd.bind(this));
    }
    offEvent(){
        // this.canvas.removeEventListener('touchstart', this.pathStart.bind(this));
        // this.canvas.removeEventListener('touchmove', this.pathMove.bind(this));
        // this.canvas.removeEventListener('touchend', this.pathEnd.bind(this));
        this.canvas.removeEventListener('mousedown', this.pathStart.bind(this));
        this.canvas.removeEventListener('mousemove', this.pathMove.bind(this));
        this.canvas.removeEventListener('mouseup', this.pathEnd.bind(this));
    }
    pathStart(e){
        this.isDrawing = true;
        // let touch = e.touches[0];
        let x = e.clientX - this.left,
            y = e.clientY - this.top;
        this.ctx.lineWidth = '3';
        this.ctx.lineCap = 'round';
        this.ctx.lineJoin = 'round';
        this.ctx.shadowBlur = 1;
        this.ctx.shadowColor = '#000';
        this.ctx.beginPath();
        this.ctx.moveTo(x, y);
    }
    pathMove(e){
        // let touch = e.touches[0];
        if(!this.isDrawing){
            return;
        }
        let x = e.clientX - this.left,
            y = e.clientY - this.top;
        this.ctx.lineTo(x, y);
        this.ctx.stroke();
    }
    pathEnd(e){
        this.ctx.closePath();
        this.isDrawing = false;
    }
    clean(){
        this.ctx.clearRect(0,0,this.width,this.height);
    }
    _uuid(){
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }
    getImageFile(imgDataUrl, filename){
        const arr = imgDataUrl.split(',');
        const mimeType = arr[0].match(/:(.*?);/)[1];
        const bStr = atob(arr[1]);
        let n = bStr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bStr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mimeType });
    }
    getSignatureImage(){
        let imgData = this.ctx.getImageData(0,0,this.canvas.width, this.canvas.height);
        let data = imgData.data;

        let lOffset = this.canvas.width, rOffset = 0,tOffset = this.canvas.height, bOffset = 0;
        for (let i = 0; i < this.canvas.width; i++) {
            for (let j = 0; j < this.canvas.height; j++) {
                let pos = (i + this.canvas.width * j) * 4;
                if (data[pos + 3] > 0) {
                    // 这个条件说第j行第i列的像素不是透明的
                    bOffset = Math.max(j, bOffset); // 找到不透明区域最底部的纵坐标
                    rOffset = Math.max(i, rOffset); // 找到不透明区域的最右端
                    tOffset = Math.min(j, tOffset); // 找到不透明区域的最上端
                    lOffset = Math.min(i, lOffset); // 找到不透明区域的最左端
                }
            }
        }
        lOffset++; 
        rOffset++;
        tOffset++;
        bOffset++;
        
        // 未签名，画布上无内容
        if(lOffset > this.width || tOffset > this.height){
            return null;
        }
        
        // 裁剪签名内容周围空白区域
        let tempCanvas = document.createElement('canvas');
        tempCanvas.height = bOffset - tOffset;
        tempCanvas.width = rOffset - lOffset;
        let tempCtx = tempCanvas.getContext('2d');
        let newImgData = this.ctx.getImageData(lOffset, tOffset, (rOffset - lOffset), (bOffset - tOffset));
        tempCtx.putImageData(newImgData, 0, 0 );

        // 旋转签名
        // let tempCanvas2 = document.createElement('canvas');
        // tempCanvas2.width = tempCanvas.height;
        // tempCanvas2.height = tempCanvas.width;
        // let tempCtx2 = tempCanvas2.getContext("2d");
        // tempCtx2.rotate(-90*Math.PI/180);
        // tempCtx2.translate(-tempCanvas2.height, 0);
        // tempCtx2.drawImage(tempCanvas, 0, 0);
        
        let imgFile = this.getImageFile(tempCanvas.toDataURL('image/png'), this._uuid()+'.png');
        let imgDataUrl = tempCanvas.toDataURL('image/png');

        // 清除临时对象
        tempCanvas = null;
        // tempCanvas2 = null;
        tempCtx = null;
        // tempCtx2 = null;

        return {imgFile, imgDataUrl};
    }
    
    destroy(){
        this.offEvent();
        this.$canvasBox.removeChild(this.canvas);
        this.ctx = null;
        this.canvas = null;
    }
}