import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm } from '@/components/xqxc_ui';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import TableContent from './components/TableContent';
import BanHouse from './modal/BanHouse';
import model from '@/reducers/houseresourcemanage/houselist/model';
import { setSessionStorage } from '@/assets/js/storage';

class HouseList extends Component {

    state = {
        renderMode: '',
        modeParams: null,
        batchData: [],
        goodsName: '',
        houseTypes: {},
        houseStates: {}
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getHouseTypeSele, getHouseStateSele } = this.props;
        keepSecondNavBreadcrumb();
        getHouseTypeSele();
        getHouseStateSele();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { goodsName, houseTypes, houseStates, pageNum, pageSize } = this.state;
        this.searchCache = {
            goodsName, categoryId: houseTypes.id,
            goodsState: houseStates.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getHouseResources(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ goodsName: '', houseTypes: {}, houseStates: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 分页发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { modeParams, renderMode } = this.state;
        switch (renderMode) {
            case 'banHouse':
                return <BanHouse closeMode={this._closeMode} modeParams={modeParams} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ renderMode: '', modeParams: null }, () => {
            update && this._searchHandle('useCache');
        });
    }

    //请求函数
    _sendReq = (url, data, tip) => {
        http(url, { ...data }, 'POST')
            .then(() => {
                message.success(tip);
                this._searchHandle('useCache');
            })
            .catch(e => {
                message.error(e.message);
            });
    }

    //更新页面
    _updatePage = (title, url, paramas) => {
        const { history, addOneBreadcrumbPath } = this.props;
        setSessionStorage('putawayHouseGoodsIds', paramas);
        addOneBreadcrumbPath({
            title,
            path: '/home/houseresourcemanage/houselist' + url
        });
        history.push({
            pathname: '/home/houseresourcemanage/houselist' + url,
        });
    }

    //表格操作
    _tableOperate = (id, active, value) => {
        switch (active) {
            case 'detail':
                this._updatePage('房源详情', `/detail/${id}`);
                break;
            case 'edit':
                this._updatePage('编辑房源', `/edit/${id}`);
                break;
            case 'delete':
                showConfirm('您确定要删除该房源？', '', () => {
                    this._sendReq(`/hotel/goods/delete/${id}`, {}, '房源删除操作成功');
                });
                break;
            case 'banHouse':
                this.setState({ renderMode: 'banHouse', modeParams: { id } });
                break;
            case 'switch':
                this._sendReq(`/hotel/upGoodsRecommend/${id}`, {}, '列表推荐操作成功');
                break;
            case 'putaway':
                this._updatePage('确认服务清单', '/servicelist', [id]);
                break;
            case 'soldOut':
                showConfirm('您是否下架当前商品，下架后该房源无法在前端展示', '', () => {
                    this._sendReq('/hotel/goods/upGoodsStatus', { goodsIds: [id], goodsStatus: 'PULL_OFF_SHELVES' }, '房源下架操作成功');
                });
                break;
            case 'batch':
                this.setState({ batchData: value });
                break;
            default:
                break;
        }
    }

    //批量操作
    _batch = (type) => {
        const { batchData } = this.state;
        if (batchData.length === 0) {
            message.warning('请勾选房源！');
        } else {
            switch (type) {
                case 'putaway':
                    this._updatePage('确认服务清单', '/servicelist', batchData);
                    break;
                case 'soldOut':
                    showConfirm('您是否下架当前商品，下架后该房源无法在前端展示', '', () => {
                        this._sendReq('/hotel/goods/upGoodsStatus', { goodsIds: batchData, goodsStatus: 'PULL_OFF_SHELVES' }, '房源批量下架操作成功');
                    });
                    break;
            }
        }
    }

    render() {
        const { houseTypeSele, houseStateSele, houseResources } = this.props;
        const { goodsName, houseTypes, houseStates } = this.state;
        return (
            <KeepAlive id='houseList' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XInput
                            inputStyle={{ width: '150px' }}
                            label='房源名称'
                            placeholder='请输入房源名称'
                            bindName='goodsName'
                            bindThis={this}
                            value={goodsName}
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '150px' }}
                            label='房源房型'
                            placeholder='请选择'
                            renderData={houseTypeSele}
                            bindName='houseTypes'
                            bindThis={this}
                            dataIndex='categoryName'
                            keyIndex='id'
                            value={houseTypes.categoryName}
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '150px' }}
                            label='状态'
                            placeholder='请选择'
                            renderData={houseStateSele}
                            dataIndex='value'
                            keyIndex='code'
                            bindName='houseStates'
                            bindThis={this}
                            value={houseStates.value}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.operateBox}>
                        <XOKButton style={{ width: '100px' }} label='新建房源' onClick={() => this._updatePage('新建房源', '/create')} />
                        <XOKButton style={{ width: '100px', marginLeft: '20px' }} label='批量上架' onClick={() => this._batch('putaway')} />
                        <XOKButton style={{ width: '100px', marginLeft: '20px' }} label='批量下架' onClick={() => this._batch('soldOut')} />
                    </div>
                    <TableContent
                        paginationChange={this._paginationChange}
                        renderData={houseResources}
                        tableAction={this._tableOperate} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }

}

HouseList.propTypes = {
    history: PropTypes.object, // 路由history对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func, // 保持2级面包屑导航信息
    getHouseTypeSele: PropTypes.func,//获取房源房型下拉
    houseTypeSele: PropTypes.array,//房源房型
    getHouseStateSele: PropTypes.func,//获取房源状态下拉
    houseStateSele: PropTypes.array,//房源状态
    getHouseResources: PropTypes.func,//获取房源列表
    houseResources: PropTypes.object,//房源列表
};

const mapStateToProps = (state) => ({
    houseResources: state.houseResources.houseResources,
    houseTypeSele: state.houseResources.houseTypeSele,
    houseStateSele: state.houseResources.houseStateSele,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(HouseList);

