/**
 * 禁止登陆弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, Input, message, Radio, Form } from 'antd';
import styles from './NoLogin.module.scss';

const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

class NoLogin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
        };
    }

    // 点击确认
    _handleOk = () => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const { id } = this.props;
                const data = {
                    ...fieldsValue,
                    id
                };

                http('/admin/empl/disableEmpl', data, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('禁用成功！');
                        this.setState({
                            visible: false,
                        });
                        this.props.closeMode(true);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
            }
        });

    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={900}
                centered
                title='禁用'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} className="create-form">
                        <Form.Item
                            colon={false}
                            htmlFor='disableType'
                            label={<span>禁止类型</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('disableType', {
                                rules: [{ required: true, message: '请选择禁止类型' }],
                                initialValue: 'STOP'
                            })(
                                <Radio.Group style={{ marginLeft: 6 }}>
                                    <Radio id='STOP' value='STOP'>停用</Radio>
                                    <Radio id='DISABLELOGIN' value='DISABLELOGIN'>禁止登录</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='disableReason'
                            label={<span>封禁理由</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('disableReason', {
                                rules: [{ required: true, message: '请选择封禁理由' }],
                                initialValue: 'DIMISSION'
                            })(
                                <Radio.Group style={{ marginLeft: 6 }}>
                                    <Radio id='DIMISSION' value='DIMISSION'>离职</Radio>
                                    <Radio id='LEAVEWITHOUTPAY' value='LEAVEWITHOUTPAY'>停薪离职</Radio>
                                    <Radio id='OTHER' value='OTHER'>其他</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='disableRemark'
                            label={<span>禁止原因</span>}
                        >
                            {getFieldDecorator('disableRemark', {
                                // rules: [{ required: true, message: '请输入策略名称' }],
                            })(
                                <TextArea rows={4} />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

NoLogin.propTypes = {
    id: PropTypes.number, // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(NoLogin);// 校验
export default connect(null, null)(ForgotProducDetails);