import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
export default class TableContent extends Component {
	state = {
		selectedRowKeys: [],
	};
	static propTypes = {
		tableAction: PropTypes.func,
		paginationChange: PropTypes.func,
		renderData: PropTypes.object,
		batchData: PropTypes.array
	};
	_columns = () => [
		{
			title: '商品图片',
			dataIndex: 'mainImgUrl',
			key: 'mainImgUrl',
			align: 'center',
			width: '10%',
			render: (text) => this._imgCell(text)
		},
		{
			title: '商品标题',
			dataIndex: 'goodsName',
			key: 'goodsName',
			align: 'center',
			width: '20%'
		},
		{
			title: '货号',
			dataIndex: 'goodsNo',
			key: 'goodsNo',
			align: 'center',
			width: '20%'
		},
		{
			title: '商品分类',
			dataIndex: 'categoryName',
			key: 'categoryName',
			align: 'center',
			width: '15%',
		},
		{
			title: '操作人',
			dataIndex: 'salePrice',
			key: 'salePrice',
			align: 'center',
			width: '10%',
		},
		{
			title: '操作时间',
			dataIndex: 'gmtModified',
			key: 'gmtModified',
			align: 'center',
			width: '15%'
		},
		{
			title: '操作',
			dataIndex: 'goodsId',
			key: 'goodsId',
			align: 'center',
			width: '10%',
			render: (id, record) => this._tableAction(id, record)
		},
	];
	// 表格项内容为图片
	_imgCell = (url) => {
		return (
			<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
				<div className={styles.imgbox}>
					<img className={styles.img} src={url} />
				</div>
			</div>
		);
	};
	// 表格操作
	_tableAction = (id) => {
		const { tableAction } = this.props;
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => tableAction(id, 'detail')}>详情</div>
				<div className={styles.item} onClick={() => tableAction(id, 'delete')}>移除</div>
			</div>
		);
	};
	//列表数据处理
	_dealTableData = (data) => {
		return data ? data : [];
	}
	// 表格复选输入
	_rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			console.log(selectedRows);
			this.setState({ selectedRowKeys });
			this.props.tableAction(null, 'batch', selectedRowKeys);
		}
	};
	// 清空表格复选框
	_cleanAllSelected = () => {
		this.setState({ selectedRowKeys: [] });
		this.props.tableAction(null, 'batch', []);
	}
	render() {
		const { renderData, paginationChange } = this.props;
		const { selectedRowKeys } = this.state;
		this._rowSelection.selectedRowKeys = selectedRowKeys;
		return (
			<div className={`${styles.tableContainer} ${styles.wrap}`} >
				<XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowSelection={this._rowSelection}
					columns={this._columns()}
					pagination={false}
					rowKey={record => record.goodsId}
					dataSource={this._dealTableData(renderData.dataList)}
					scroll={{ y: true }} />
				{/* <XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} /> */}
			</div>
		);
	}
}