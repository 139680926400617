import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class MarkTable extends React.Component {

    _columns = () => ([
        {
            title: '任务单号',
            dataIndex: 'taskId',
            key: 'taskId',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '代言任务',
            dataIndex: 'taskName',
            key: 'taskName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '代言类型',
            dataIndex: 'taskTypeName',
            key: 'taskTypeName',
            width: '7%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'stateName',
            key: 'stateName',
            align: 'center',
            width: '7%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '发布时间',
            dataIndex: 'startTime',
            key: 'startTime',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '结束时间',
            dataIndex: 'endTime',
            key: 'endTime',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '发行量(单)',
            dataIndex: 'publishNum',
            key: 'publishNum',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '已领取(单)',
            dataIndex: 'receivedNum',
            key: 'receivedNum',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '奖励总量',
            dataIndex: 'totalRewardNum',
            key: 'totalRewardNum',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '创建人',
            dataIndex: 'createUserName',
            key: 'createUserName',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '14%',
            render: (id, record) => this._TableAction(id, record)
        }
    ]);

    // 解析表格数据，使符合columns格式要求
    _takeDataSource = (data) => {
        if (data) {
            return data.map(item => {
                item.taskId = item.id;
                return item;
            });
        }
    }

    _TableAction = (id, record) => {
        const { tableAction } = this.props;
        const { spokesmanTaskStatus, checkState } = record;
        if (spokesmanTaskStatus == 'NOT_STARTED') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'detail')}>详情</div>
                </div >
            );
        } else if (spokesmanTaskStatus == 'WORK') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'detail')}>详情</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'getDetail', record)}>领取详情</div>
                </div >
            );
        } else if (spokesmanTaskStatus == 'ENDED') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'detail')}>详情</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'getDetail', record)}>领取详情</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'delete', record)}>删除</div>
                </div >
            );
        } else if (spokesmanTaskStatus == 'DISABLED') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'detail')}>详情</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'getDetail', record)}>领取详情</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'complaint', record)}>申述</div>
                </div >
            );
        } else if (spokesmanTaskStatus == 'CHECK') {
            return (
                <div className={styles.action}>
                    {checkState == 'CHECK_WORK' && <div className={styles.item} onClick={() => tableAction(id, 'detail')}>详情</div>}
                    {checkState == 'CHECK_REFUSE' && <div className={styles.item} onClick={() => tableAction(id, 'edit')}>编辑</div>}
                    {checkState == 'CHECK_REFUSE' && <div className={styles.item} onClick={() => tableAction(id, 'delete', record)}>删除</div>}
                </div >
            );
        }
    };

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record) => record.id}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

MarkTable.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default MarkTable;