import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import AllGoods from './allgoods';
// import LowInventory from './lowinventory';
// import AuditFail from './auditfail';
import SoldOuted from './soldouted';
import Trailergoods from './trailergoods';
import { getLocalStorage, removeLocalStorage } from '@/assets/js/storage';

const Main = ({ history }) => {

    const tabs = [
        { id: 1, label: '所有商品' },
        { id: 5, label: '预告中' },
        // { id: 2, label: '低库存预警' },
        // { id: 3, label: '审核失败' },
        { id: 4, label: '已下架' },
    ];

    const [curTabID, setCurTabID] = useState(1);

    useEffect(() => {
        if (getLocalStorage('productManageWholesaleGoods')) {
            setCurTabID(Number(getLocalStorage('productManageWholesaleGoods')));
            removeLocalStorage('productManageWholesaleGoods');
        }
    }, []);

    const renderPage = () => {
        switch (curTabID) {
            case 1:
                return <AllGoods history={history} />;
            // case 2:
            //     return <LowInventory history={history} />;
            // case 3:
            //     return <AuditFail history={history} />;
            case 4:
                return <SoldOuted history={history} />;
            case 5:
                return <Trailergoods history={history} />;
            default:
                return <AllGoods history={1} />;
        }
    };

    return <div className={styles.flexBoxContainer}>
        <XTabNav
            renderData={tabs}
            onChange={tabID => setCurTabID(tabID)}
            activedID={curTabID} />
        {renderPage()}
    </div>;
};
Main.propTypes = {
    history: PropTypes.object, // 路由history对象
};
export default Main;