/**
 * 部门、运营中心/区域公司 编辑弹框
 */
import React from 'react';
import { Modal, message } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './EditModal.module.scss';
import http from '@/assets/api/http';
import { XInput, XTextArea } from '@/components/xqxc_ui';
import { getDeptDetail, getCompanyDetail } from '@/reducers/permissionsmanage/structure/actions';

class EditModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        name: '', // 名称
        person: '', // 负责人
        tel: '', // 手机号
        remark: '', // 备注
    }

    componentDidMount() {
        const { id, type } = this.props.data;
        if (type == 'DEPT') {
            this.props.getDeptDetail({ id });
        } else {
            this.props.getCompanyDetail({ id });
        }
    }

    UNSAFE_componentWillReceiveProps(newProp) {
        if (newProp.detail) {
            const { type } = this.props.data;
            if (type == 'DEPT') {
                const { deptName, principal, mobile, remark } = newProp.detail;
                this.setState({
                    name: deptName,
                    person: principal,
                    tel: mobile,
                    remark
                });
            } else {
                const { companyName, contactName, companyTel, remark } = newProp.detail;
                this.setState({
                    name: companyName,
                    person: contactName,
                    tel: companyTel,
                    remark
                });
            }
        }
    }

    _request = (url, data) => {
        http(url, data, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('编辑成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _handleOk = () => {
        const { id, type } = this.props.data;
        const { name, person, tel, remark } = this.state;
        var RegExp = /^(1[3456789]\d{9})$/;
        if (RegExp.test(tel) == false) {
            message.error('请输出正确的手机号');
            return;
        }
        if (name == '') {
            if (type == 'DEPT') {
                message.error('部门名称不能为空');
            } else {
                message.error('公司名称不能为空');
            }
            return;
        }
        if (type == 'DEPT') {
            this._request('/admin/dept/updateDept', { id, deptName: name, principal: person, mobile: tel, remark });
        } else {
            this._request('/admin/dept/updateCompany', { id, contactName: person, contactTel: tel, remark });
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, name, person, tel, remark } = this.state;
        const { type } = this.props.data;
        const label = type == 'DEPT' ? '部门名称' : '公司名称';
        const disabled = type == 'DEPT' ? false : true;
        return (
            <Modal
                width={470}
                centered
                title={this.props.title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '405px' }}
                        inputStyle={{ width: '335px' }}
                        label={label}
                        placeholder='请输入名称'
                        value={name}
                        bindThis={this}
                        bindName='name'
                        isRequired={true}
                        disabled={disabled}
                    />
                    <XInput
                        style={{ width: '405px', marginTop: '10px', marginLeft: '19px' }}
                        inputStyle={{ width: '335px' }}
                        label='负责人'
                        placeholder='请输入负责人'
                        bindThis={this}
                        value={person}
                        bindName='person'
                    />
                    <XInput
                        style={{ width: '405px', marginTop: '10px', marginLeft: '19px' }}
                        inputStyle={{ width: '335px' }}
                        label='手机号'
                        placeholder='请输入手机号'
                        value={tel}
                        bindThis={this}
                        bindName='tel'
                    />
                    <XTextArea
                        style={{ width: '385px', marginTop: '10px', marginLeft: '32px' }}
                        inputStyle={{ width: '335px', resize: 'none' }}
                        label='备注'
                        placeholder='请输入备注'
                        value={remark}
                        bindThis={this}
                        bindName='remark'
                    />
                </div>
            </Modal>
        );
    }
}

EditModal.propTypes = {
    title: PropTypes.string, // 弹框名称
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getDeptDetail: PropTypes.func, // 获取部门详情
    getCompanyDetail: PropTypes.func, // 获取公司或运营中心详情
    detail: PropTypes.object, // 详情
};

const mapStateToProps = (state, newProp) => {
    if (newProp.data && newProp.data.type == 'DEPT') {
        return {
            detail: state.structure.getDeptDetail,
        };
    } else {
        return {
            detail: state.structure.getCompanyDetail,
        };
    }
};

export default connect(mapStateToProps, { getDeptDetail, getCompanyDetail })(EditModal);
