import * as T from './actiontypes';

//初始数据
const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    resultTotal: 0
};


//获取供应商结算价审核列表
export const balanceAuditList = (state = initData, action) => {
    switch (action.type) {
        case T.SET_BALANCEAUDIT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

//供应商审核结算价详情
export const balanceAuditDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_BALANCEAUDIT_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

//供应商审核结算价业务类型下拉
export const balanceAuditBusinessSele = (state = [], action) => {
    switch (action.type) {
        case T.SET_BALANCEAUDIT_BUSINESSSELE:
            return action.payload.result;
        default:
            return state;
    }
};

//供应商审核结算价标签下拉
export const balanceAuditLabelSele = (state = initData, action) => {
    switch (action.type) {
        case T.SET_BALANCEAUDIT_LABELSELS:
            return action.payload.result;
        default:
            return state;
    }
};

//供应商审核结算价状态下拉
export const balanceAuditStateSele = (state = [], action) => {
    switch (action.type) {
        case T.SET_BALANCEAUDIT_STATESELE:
            return action.payload.result;
        default:
            return state;
    }
};



