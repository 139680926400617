/**
 * 【电子面单模板】
 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import http from '@/assets/api/http';
import { message, Table } from 'antd';
import { XSelect, XOKButton, XCancelButton, XPagination, showConfirm } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import EditOrCreate from './modal/editOrCreate';
import model from '@/reducers/systemmanage/delivertemplate/model';

const DeliverTemplate = ({
    getDeliverTemplateData,
    deliverTemplateData = {},
    getCompanySele,
    companySele = []
}) => {

    const [companyItem, setCompanyItem] = useState({});
    const [renderModal, setRenderModal] = useState(false);
    const [title, setTitle] = useState('');
    const [id, setId] = useState('');

    const onSearch = () => {
        getDeliverTemplateData({ cpCode: companyItem.cpCode });
    };

    useEffect(() => {
        getCompanySele();
        onSearch();
    }, []);

    const onReset = () => {
        setCompanyItem({});
    };

    const onCreate = () => {
        setId('');
        setTitle('新建菜鸟电子面单模板');
        setRenderModal(true);
    };

    const onEdit = (id) => {
        setId(id);
        setRenderModal(true);
    };

    const onOpenBan = (id, tip, templateStatus) => {
        showConfirm('', tip, () => {
            http('/merchant/cainiao/setting/template/update/status', { id, templateStatus }, 'POST')
                .then(res => {
                    message.success(res.message);
                    onSearch();
                });
        }, () => { }, '确定', '关闭');
    };

    const onTableAction = (t, r) => {

        return <div className={styles.action}>
            <div className={styles.item} onClick={() => onEdit(r.id)}>编辑</div>
            {
                r.templateStatus == 1 ?
                    <div className={styles.item} onClick={() => onOpenBan(r.id, '确认要禁用该数据吗？', 0)}>禁用</div> :
                    <div className={styles.item} onClick={() => onOpenBan(r.id, '确认要启用该数据吗？', 1)}>启用</div>
            }
        </div>;
    };

    const columns = [
        {
            title: '模板名称',
            dataIndex: 'templateName',
            key: 'templateName',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否默认',
            dataIndex: 'defaultStatus',
            key: 'defaultStatus',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: text => tooltip(text == 1 ? '是' : '否')
        },
        {
            title: '物流公司',
            dataIndex: 'cpText',
            key: 'cpText',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '发货地',
            dataIndex: 'address',
            key: 'address',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '发货网点',
            dataIndex: 'branches',
            key: 'branches',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '标准面单类型',
            dataIndex: 'cloudTemplateName',
            key: 'cloudTemplateName',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '打印机',
            dataIndex: 'printDevice',
            key: 'printDevice',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '自定义内容',
            dataIndex: 'extContentText',
            key: 'extContentText',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作人',
            dataIndex: 'operateName',
            key: 'operateName',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'templateStatus',
            key: 'templateStatus',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: text => tooltip(text == 1 ? '启用' : '禁用')
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: onTableAction
        },
    ];

    const onCloseModal = (update) => {
        setRenderModal(false);
        update && onSearch();
    };

    return <div className={styles.flexBoxContainer}>
        <div className={styles.searchBox}>
            <XSelect
                style={{ marginRight: '20px' }}
                selectStyle={{ width: '150px' }}
                label='物流公司'
                placeholder='请选择物流公司'
                renderData={companySele}
                dataIndex='cpName'
                keyIndex='cpCode'
                value={companyItem.cpName}
                // bindThis={setCompanyItem}
                // bindName='companyItem'
                onChange={item => {
                    if (item.cpName) {
                        setCompanyItem(item);
                    } else {
                        message.warning(`未找到${item.cpCode}相关的物流信息，请联系运营管理员添加`);
                    }
                }}
            />
            <XOKButton
                style={{ width: '60px', marginRight: '20px' }}
                label='查询'
                onClick={() => onSearch()}
            />
            <XCancelButton
                style={{ width: '60px' }}
                label='重置'
                onClick={onReset}
            />
        </div>
        <XOKButton
            style={{ width: '90px', marginTop: '10px' }}
            label='新建模板'
            onClick={onCreate}
        />
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(r, i) => i % 2 === 0 ? '' : styles.tdcolor}
                rowKey={r => r.id}
                columns={columns}
                dataSource={deliverTemplateData}
                pagination={false}
                scroll={{ x: 1800, y: true }}
            />
            {/* <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={onPageChange}
            /> */}
        </div>
        {renderModal && <EditOrCreate title={title} onCloseModal={onCloseModal} id={id} />}
    </div>;
};
DeliverTemplate.propTypes = {
    history: PropTypes.object,
    getDeliverTemplateData: PropTypes.func,
    deliverTemplateData: PropTypes.array,
    getCompanySele: PropTypes.func,
    companySele: PropTypes.array
};
export default connect(state => ({
    deliverTemplateData: state.delivertemplate.deliverTemplateData,
    companySele: state.delivertemplate.companySele
}), {
    ...model.actions
})(DeliverTemplate);
