/**
 * 营销活动数据
 */
import React from 'react';
import { Modal, Tabs } from 'antd';
import { connect } from 'react-redux';
import { XInput, XOKButton } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './ActivityDate.module.scss';
import ActivityDateTable from '../components/ActivityDateTable';
import OrderDateTable from '../components/OrderDateTable';
// import { getListVideoProduct } from '@/reducers/showmanage/channellist/actions';

const { TabPane } = Tabs;

//模拟表格数据   
const tableDataSource = [];
for (let i = 0; i < 50; i++) {
    tableDataSource.push({
        c1: i,
        c2: '75767689',
        c3: '在苍茫的大海上，狂风卷集着乌云。在乌云和大海之间，海燕像黑色的闪电，在高傲地飞翔。',
        c4: '在苍茫的大海上，狂风卷集着乌云。在乌云和大海之间，海燕像黑色的闪电，在高傲地飞翔。',
        c5: '￥165662',
        c6: '165662',
        c7: '￥263661',
        c8: '333000000',
        c9: '23323',
    });
}
const ordertableDataSource = []; 
for (let i = 0; i < 50; i++) {
    ordertableDataSource.push({
        c1: i,
        c2: '75767689',
        c3: '在苍茫的大海上，狂风卷集着乌云。在乌云和大海之间，海燕像黑色的闪电，在高傲地飞翔。',
        c4: '在苍茫的大海上，狂风卷集着乌云。在乌云和大海之间，海燕像黑色的闪电，在高傲地飞翔。',
        c5: '￥165662',
        c6: '￥165662',
        c7: '263661',
        c8: '333000000'
    });
}
class ActivityDate extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        keyword: '',
        pageNum: 1,
        pageSize: 10,
        goodsList: [],
    }

    componentDidMount() {
        const { pageNum, pageSize } = this.state;
        this.props.getListVideoProduct({ pageNum, pageSize });
    }

    _tableAction = (id, data) => {
        console.log(data);
        const goodsList = [...this.state.goodsList];
        this.setState({
            goodsList: [...new Set(goodsList.concat(data))]
        });
    }
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _inputChangeHandle = (value) => {
        this.setState({ keyword: value });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        const { keyword, isCheck } = this.state;
        if (!isCheck) {
            this.props.getListVideoProduct({ keyword: '', pageNum, pageSize });
        } else {
            this.props.getListVideoProduct({ keyword, pageNum, pageSize });
        }
    }
    //营销数据与 订单数据切换
    TabsCallback = (activeKey) => {
        console.log(activeKey);
    }

    render() {
        const { visible, title, confirmLoading, keyword, } = this.state;

        return (
            <Modal
                width={860}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                footer={null}
            >
                <div className={styles.container}>
                    <div className={`${styles.layout} ${styles.dataTop}`}>
                        <div className={`${styles.layout}`}>
                            <div><h4>总订单数：</h4><span>112320</span></div>
                            <div><h4>订单总额：</h4><span>￥112320</span></div>
                        </div>
                        <div className={`${styles.layout}`}>
                            <div><h4>已成交订单：</h4><span>112320</span></div>
                            <div><h4>成交金额：</h4><span>￥112320.00</span></div>
                        </div>
                    </div>
                    {/* <div className={styles.input}>
                        <XOKButton style={{ width: '104px' }} label='导出excel' />
                    </div> */}
                    <Tabs defaultActiveKey="1" onChange={this.TabsCallback}>
                        <TabPane tab="营销数据" key="1">
                            <div className={styles.table}>
                                <ActivityDateTable tableAction={this._tableAction} paginationChange={this._paginationChange} renderData={tableDataSource} />
                            </div>
                        </TabPane>
                        <TabPane tab="订单数据" key="2">
                            <div className={styles.table}>
                                <OrderDateTable tableAction={this._tableAction} paginationChange={this._paginationChange} renderData={ordertableDataSource} />
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </Modal>
        );
    }
}

ActivityDate.propTypes = {
    title: PropTypes.object,// 弹框标题
    data: PropTypes.object, // 需要修改魅力值的会员对象数组
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    tableAction: PropTypes.func,

    getListVideoProduct: PropTypes.func,
    listVideoProduct: PropTypes.object
};

const mapStateToProps = (state) => ({
    // listVideoProduct: state.channellist.listVideoProduct
});

export default connect(mapStateToProps, { })(ActivityDate);