/**
 * 【仓库列表】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { getWarehouseList } from '@/reducers/storehousefinancialmanage/warehousestatement/actions';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import AdvertTable from './components/AdvertTable';
import ConfirBill from './modal/ConfirBill';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';


const  statusArr = [{ code: 'INIT', value: '新建'},
{ code:'TO_CONFIRM', value: '待确认'},{ code: 'CONFIRM_SUCCESS', value: '确认成功'}, { code: 'CONFIRM_FAIL', value: '确认失败'},
{ code: 'PARTIAL_PAYMENT', value: '部分付款'}, { code: 'PAYMENT_COMPLETED', value: '付款完成'}];
class Main extends React.Component {

    state = {
        companyStatus: {},
        reconciliationId: '',
        pageNum: 1,
        pageSize: 10,
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getWarehouseList();
    }
    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }
    // 查询
    _searchHandle = (useCache) => { 
        const { reconciliationId, pageNum, pageSize, companyStatus } = this.state;
        this.searchCache = {
            reconciliationId,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            status: companyStatus.code,
        };
        this.props.getWarehouseList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ reconciliationId: '', pageNum: 1, pageSize: 20, companyStatus: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        }, () => {
            this._searchHandle('useCache');
        });
    }
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    _newHandle = () => {
        this.setState({ currentOpenMode: 'new' });
    };
    // 表格操作
    _tableAction = (id, type, provider) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info':
                // this.setState({ currentOpenMode: 'info', openModeParam: id, provider });
                if (provider) {
                    addOneBreadcrumbPath({
                        title: '奢批账单明细',
                        path: '/home/storehousefinancialmanage/warehousestatement/luxurybill/detail/'
                    });
                    history.push({
                        pathname: `/home/storehousefinancialmanage/warehousestatement/luxurybill/detail/${id}`
                    });
                } else {
                    addOneBreadcrumbPath({
                        title: '仓库账单明细',
                        path: '/home/storehousefinancialmanage/warehousestatement/bill/detail/'
                    });
                    history.push({
                        pathname: `/home/storehousefinancialmanage/warehousestatement/bill/detail/${id}`
                    });
                }

                break;
            case 'confir':
                this.setState({ currentOpenMode: 'confir', openModeParam: id });
                break;
        }
    }
    // 状态
    _companyStatusChange = e => {
        this.setState({
            companyStatus: e,
        });
    }
    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'confir': // 确认对账单
                return <ConfirBill title='确认对账单' refresh={this._searchHandle} visible={true} closeMode={this._closeMode} data={this.state.openModeParam} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }
    render() {
        const { companyStatus, reconciliationId } = this.state;
        const { warehouseList } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
            <div className={styles.flexBoxContainer}>
                <div className={styles.row1}>
                    <XInput
                        style={{ width: '248px' }}
                        inputStyle={{ width: '180px' }}
                        label='对账单号'
                        placeholder='请输入'
                        value={reconciliationId}
                        bindThis={this}
                        bindName='reconciliationId'
                        mode='number'
                        maxLength={16}
                        showLength={false}
                    />
                    <XSelect
                        style={{ marginLeft: '10px', width: '250px' }}
                        selectStyle={{ width: '200px' }}
                        label='状态'
                        placeholder='请选择'
                        renderData={statusArr}
                        onChange={this._companyStatusChange}
                        dataIndex='value'
                        keyIndex='code'
                        value={companyStatus.value}
                    />
                    <XOKButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='查询'
                        onClick={this._searchHandle}
                    />
                    <XCancelButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='重置'
                        onClick={this._resetHandle}
                    />
                </div>
                {/* <div className={styles.row2}>
                    <XCancelButton
                        style={{ width: '60px' }}
                        label='导出'
                        onClick={this._newHandle}
                    />
                </div> */}
                <AdvertTable renderData={warehouseList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                {this._renderMode()}
            </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getWarehouseList: PropTypes.func,
    warehouseList: PropTypes.object,

};
const mapStateToProps = (state) => ({
    warehouseList: state.warehousestatement.warehouseList,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getWarehouseList })(Main);