/**
 * 【服务商详情页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import Dept from './dept';
import Operation from './operation';
import Company from './company';

class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '部门管理' },
            // { id: 2, label: '运营中心管理' },
            // { id: 3, label: '区域公司管理' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染内容
    _renderContent = () => {
        const { curTabID } = this.state;
        switch (curTabID) {
            case 1:
                return (<Dept history={this.props.history} />);
            case 2:
                return (<Operation history={this.props.history} />);
            case 3:
                return (<Company history={this.props.history} />);
            default:
                return (<Dept history={this.props.history} />);
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                {this._renderContent()}
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // router history
    match: PropTypes.object, // router match
};

export default Main;