/**
 * 推荐商品设置-列表
 */
import React from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XOKButton, showConfirm } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import GoodsModal from '../../modal/GoodsModal';
import model from '@/reducers/productmanage/recommendGoodSet/model';
class Main extends React.Component {
    state = {
        modeType: ''
    }
    //异步请求
    componentDidMount() {
        this.props.recommendGoodSet({ isShopReco: 1 });
    }
    // 表格操作
    _tableAction = (id, type, value) => {
        const { updateContent, recommendGoodSet } = this.props;
        switch (type) {
            case 'detail':
                console.log(id, type, value);
                updateContent('detail', id);
                break;
            case 'delete':
                console.log(id, type, value);
                showConfirm('您是否要删除该推荐商品设置？', '', () => {
                    http('/goods/shopGoods/recoGoodsByShop', { goodsIds: [id], operation: 'REMOVE' }, 'POST')
                        .then(() => {
                            recommendGoodSet({ isShopReco: 1 });
                            message.success('删除推荐商品设置成功。');
                        }).catch((e) => {
                            message.error(e.message);
                        });
                });
                break;
        }
    }
    //新增推荐商品设置
    _addGoods = () => {
        this.setState({ modeType: 'GOODS_MODEL' });
    }
    // 打开弹窗  
    _renderMode = () => {
        const { productList } = this.props;
        switch (this.state.modeType) {
            case 'GOODS_MODEL':
                return <GoodsModal productList={productList.dataList} closeMode={this._closeMode} />;
        }
    }
    // 关闭弹框
    _closeMode = (update) => {
        update && this.props.recommendGoodSet({ isShopReco: 1 });
        this.setState({ modeType: '' });
    }
    render() {
        const { productList } = this.props;
        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.search_box}>
                    <XOKButton
                        style={{ width: '160px' }}
                        label='新增推荐商品设置'
                        onClick={this._addGoods}
                    />
                </div>
                <TableContent paginationChange={this._paginationChange} renderData={productList} tableAction={this._tableAction} />
                {this._renderMode()}
            </div>
        );
    }
}
Main.propTypes = {
    updateContent: PropTypes.func,
    recommendGoodSet: PropTypes.func,
    productList: PropTypes.object,
};
const mapStateToProps = (state) => ({
    productList: state.recommendGoodSet.recommendGoodSet,
});
export default connect(mapStateToProps, { ...model.actions })(Main);