import React from 'react';
import styles from './AdvertTable.module.scss';
import { XTableSelectionCount, XPagination } from '@/components/xqxc_ui';
import { regFenToYuan } from '@/util/money';
import { Table, Badge } from 'antd';
import PropTypes from 'prop-types';

class AdvertTable extends React.Component {
    state = {
        account: '',
        selectedRowKeys: [],
    }

    _takeColumns = () => ([
        {
            title: '变动时间',
            dataIndex: 'createtime',
            key: 'createtime',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '订单号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '变动金额（元）',
            dataIndex: 'money',
            key: 'money',
            align: 'center',
            width: '20%',
            render: (text) => (this._renderTextMoneyCell(text))
          },
          {
            title: '变动原因',
            dataIndex: 'remarks',
            key: 'remarks',
            align: 'center',
            width: '40%',
            render: (text) => (this._renderTextCell(text))
          }, 
    ]);
    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)} 元
            </div>
        );
      };
    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    onChange = (pagination, filters, sorter) => {
        console.log('params', pagination, filters, sorter);
    };
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }
    render() {
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    // onChange={this.onChange}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default AdvertTable;