import { combineReducers } from 'redux';
import model from './model';
import { goodsConnectList, goodsConnectImport, goodsConnectState, goodsConnectDetail } from './reducer';

export default combineReducers({
	goodsConnectList,
	goodsConnectImport,
	goodsConnectState,
	goodsConnectDetail,
	...model.reducers
});