/**
 * 【商户支取申请】
 */
import React, { Component } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import model from '@/reducers/beanshopmanage/beanshop/model';
import { XInput, XSelect, XOKButton, XCancelButton, XDatePicker, showConfirm } from '@/components/xqxc_ui';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import TableContent from './components/TableContent';
import Create from './modal/Create';
import { Modal } from 'antd';

class BeanDrawApply extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: null, // 当前需要打开的弹窗的入参
        id: null,
        startDate: null,
        endDate: null,
        stateObj: {}
    }

    // 查询
    _searchHandle = (useCache) => {
        const { id, startDate, endDate, stateObj, pageNum, pageSize } = this.state;
        this.searchCache = {
            id, startDate, endDate, state: stateObj.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getBeanDrawEnum();
        this.props.getBeanDrawApplys(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发 
    _ruleresetHandle = () => {
        this.setState({ id: null, startDate: null, endDate: null, stateObj: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    //表格操作
    _tableOperate = (id, type) => {
        const { history } = this.props;
        switch (type) {
            case 'detail':
                history.push({
                    pathname: '/home/beanshopmanage/beanshop/beanddrawapply/detail/' + id,
                });
                break;
        }
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { currentOpenMode } = this.state;
        switch (currentOpenMode) {
            case 'create':
                return <Create closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status, value) => {
        if (status === true) {
            this.props.getBeanDrawApplys(this.searchCache);
            Modal.success({
                title: '支付成功',
                content: `${value}信用值`,
                okText: '确定'
            });
        }
        this.setState({ currentOpenMode: '', openModeParam: {} });
    }

    render() {
        const { id, startDate, endDate, stateObj } = this.state;
        const { beanDrawApplys, beanDrawEnum } = this.props;
        return (
            <KeepAlive id='beandrawapply' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='申请单号'
                            placeholder='请输入'
                            bindThis={this}
                            bindName='id'
                            value={id}
                        />
                        <XDatePicker
                            style={{ marginLeft: '20px', color: '#252525' }}
                            label='申请日期'
                            value={startDate}
                            bindThis={this}
                            bindName='startDate'
                            isDateFormat={true}
                            showTime={false}
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            label=''
                            value={endDate}
                            bindThis={this}
                            bindName='endDate'
                            isDateFormat={true}
                            showTime={false}
                        />
                        <XSelect
                            style={{ marginLeft: '20px', }}
                            selectStyle={{ width: '200px' }}
                            label='状态'
                            placeholder='请选择'
                            renderData={beanDrawEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={stateObj.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='stateObj'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._ruleresetHandle}
                        />
                    </div>
                    {/* <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: '150px' }}
                            label='新建人气值支取'
                            onClick={() => this.setState({ currentOpenMode: 'create' })}
                        />
                    </div> */}
                    <TableContent tableData={beanDrawApplys} paginationChange={this._paginationChange} tableOperate={this._tableOperate} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

BeanDrawApply.propTypes = {
    history: PropTypes.object,
    getBeanDrawApplys: PropTypes.func,
    beanDrawApplys: PropTypes.object,
    getBeanDrawEnum: PropTypes.func,
    beanDrawEnum: PropTypes.array,
};
const mapStateToProps = (state) => {
    return ({
        beanDrawApplys: state.beanshop.beanDrawApplys,
        beanDrawEnum: state.beanshop.beanDrawEnum,
    });
};
export default connect(mapStateToProps, { ...model.actions })(BeanDrawApply);