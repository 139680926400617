import * as W from './actiontypes';

export const getWarehouseList = (value) => ({
    type: W.GET_WAREHOUSE_LIST,
    payload: value
});
export const getWarehouseDetail = (value) => ({
    type: W.GET_WAREHOUSE_DETAIL,
    payload: value
});
export const getWarehouseChargesDetail = (value) => ({
    type: W.GET_WAREHOUSE_CHARGE_DETAIL,
    payload: value
});
export const getWarehouseLuxuryOrders = (value) => ({
    type: W.GET_WAREHOUSE_LUXURYORDERS,
    payload: value
});