/**
 *  商品管理-运费模板
 * */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import Main from './pages/main';
import WarehouseOrMerchant from './pages/main/warehouseormerchant';
import { isGY, isWE, isMT } from '@/assets/js/authType';
import { getSessionStorage, removeSessionStorage } from '@/assets/js/storage';
const FreightTenplate = ({
    history = {},
}) => {
    const [tabs, setTabs] = useState([
        { id: 'self', label: '自建模板' },
    ]);
    const [curTabID, setCurTabID] = useState('self');

    useEffect(() => {
        if (isWE()) {
            setTabs([...tabs, { id: 'other', label: '商户模板' }]);
        } else if (isMT() && isGY()) {
            setTabs([...tabs, { id: 'other', label: '仓库模板' }]);
        }
        if (isWE() && !isGY()) {
            setCurTabID('other');
        }
        if (getSessionStorage('spglyfmbcurtabid')) {
            setCurTabID(getSessionStorage('spglyfmbcurtabid'));
            removeSessionStorage('spglyfmbcurtabid');
        }
    }, []);

    // 渲染相应Tab导航下的内容
    const _renderPage = () => {
        switch (curTabID) {
            case 'self':
                return <Main history={history} curTabID={curTabID} />;
            case 'other':
                return <WarehouseOrMerchant history={history} curTabID={curTabID} />;
            default:
                return <Main history={history} curTabID={curTabID} />;
        }
    };

    return (
        <div className={styles.flexBoxContainer}>
            {
                isGY() && <XTabNav renderData={tabs} onChange={tab => setCurTabID(tab)} activedID={curTabID} />
            }
            <div className={styles.flexBoxContainer} style={{ marginTop: '10px' }}>
                {_renderPage()}
            </div>
        </div>

    );


};
FreightTenplate.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
};
export default FreightTenplate;
