/**
 * 仓库商品管理saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as T from './actiontypes';
import http from '@/assets/api/http';

//商品库存对接列表
export const getInventoryConnectList = function* () {
    yield takeEvery(T.GET_INVENTORYCONNECT_LIST, function* requestData(action) {
        try {
            let result = yield http('/goods/outer/findOuterGoodsStockList', action.payload, 'POST');
            yield put({ type: T.SET_INVENTORYCONNECT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


//商品对接商品状态
export const getInventoryConnectState = function* () {
    yield takeEvery(T.GET_INVENTORYCONNECT_STATE, function* requestData(action) {
        try {
            let result = yield http('/enum/OuterSkuRelationInvUpdateStateEnum', action.payload, 'POST');
            yield put({ type: T.SET_INVENTORYCONNECT_STATE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


