/**
 * 售后管理 Saga集合
 */
import { getSaleList, getSaleStatus, getSaleType, getSaleOnlyMoneyDetail,
    getSaleGoodsandmoneyDetail,
    getSaleChangeGoodsDetail, getProcessedDetail, getSaleAddressList, getSaleAddressDetail, getDistributionFlagEnum } from './saleorder/saga';
export default [
    getSaleList,
    getSaleStatus,
    getSaleType,
    getSaleOnlyMoneyDetail,
    getSaleGoodsandmoneyDetail,
    getSaleChangeGoodsDetail,
    getProcessedDetail,
    getSaleAddressList,
    getSaleAddressDetail,
    getDistributionFlagEnum
];