import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table, } from 'antd';
import { XPagination, XMediaFn } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class TableContent extends Component {
    state = {
        selectedRowKeys: [],
    };

    _columns = () => [
        {
            title: '流水号',
            dataIndex: 'violationId',
            key: 'violationId',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品图片',
            dataIndex: 'mainImgUrl',
            key: 'mainImgUrl',
            align: 'center',
            width: '10%',
            render: (text, item) => (this._imgCell(text, item))
        },
        {
            title: '货号',
            dataIndex: 'goodsNo',
            key: 'goodsNo',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否批发',
            dataIndex: 'isWholesale.value',
            key: 'isWholesale.value',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        // {
        // 	title: '行为',
        // 	dataIndex: 'categoryName',
        // 	key: 'categoryName',
        // 	align: 'center',
        // 	width: '10%',
        // },
        {
            title: '平台意见',
            dataIndex: 'violationReason',
            key: 'violationReason',
            align: 'center',
            width: '20%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'violationState',
            key: 'violationState',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '新建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'keyId',
            key: 'keyId',
            align: 'center',
            width: '10%',
            render: (text, record) => this._tableAction(text, record)
        },
    ];

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
            this._actionHandle(null, 'batchBan', selectedRowKeys);
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    // 表格项内容为图片
    _imgCell = (url, item) => {
        return (
            <div style={{ display: 'inline-flex' }}>
                <XMediaFn
                    style={{ width: '50px', height: '50px', marginRight: '0' }}
                    dataType='image'
                    dataSource={url}
                    key={item.violationId}
                />
            </div>
        );
    };

    // 表格操作
    _tableAction = (key, record) => {
        const { tableAction } = this.props;
        if (record.violationState == '商户申诉' || record.violationState == '平台解禁') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
                </div>
            );
        } else {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
                    <div className={styles.item} onClick={() => tableAction(key, 'appeal', record)}>申诉</div>
                </div>
            );
        }

    };

    _tableData = (data) => {
        return data;
    }

    render() {
        const { renderData, paginationChange } = this.props;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={record => record.violationId}
                    columns={this._columns()}
                    pagination={false}
                    dataSource={this._tableData(renderData.dataList)}
                    scroll={{ y: true }} />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={paginationChange} />
            </div>
        );
    }
}


TableContent.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
};

export default TableContent;