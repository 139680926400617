import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

export default class TableData extends Component {

	static propTypes = {
		tableAction: PropTypes.func,
		paginationChange: PropTypes.func,
		tableHandle: PropTypes.func,
		renderData: PropTypes.object
	};

	_columns = () => [
		{
			title: '品牌LOGO',
			dataIndex: 'logoUrl',
			key: 'logoUrl',
			align: 'center',
			width: '10%',
			render: ImgCell
		},
		{
			title: '品牌名称',
			dataIndex: 'brandName',
			key: 'brandName',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '品牌首字母',
			dataIndex: 'firstLetter',
			key: 'firstLetter',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '品牌代码',
			dataIndex: 'brandCode',
			key: 'brandCode',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '品牌故事',
			dataIndex: 'brandDesc',
			key: 'brandDesc',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
	];

	// 表格项内容为图片
	_imgCell = (url) => {
		return (
			<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
				<div className={styles.imgbox}>
					<img className={styles.img} src={url} />
				</div>
			</div>
		);
	};
	render() {
		const { renderData, paginationChange } = this.props;
		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					columns={this._columns()}
					pagination={false}
					rowKey={record => record.id}
					dataSource={renderData.dataList}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}