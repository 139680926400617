/**
 *  商品管理 - 列表
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const Mode = {
    LIST_DETAIL: 'LIST_DETAIL',
    LIST_EDIT: 'LIST_EDIT'
};

class ProductList extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case Mode.LIST_DETAIL:
                addOneBreadcrumbPath({
                    title: '商品详情',
                    path: '/home/productmanage/productlist/detail'
                });
                history.push({
                    pathname: '/home/productmanage/productlist/detail/' + value
                });
                break;
            case Mode.LIST_EDIT:
                addOneBreadcrumbPath({
                    title: '商品编辑',
                    path: '/home/productmanage/productlist/edit'
                });
                history.push({
                    pathname: '/home/productmanage/productlist/edit/' + value
                });
                break;
            case 'allSkuList':
                addOneBreadcrumbPath({
                    title: '所有分销商品SKU列表',
                    path: '/home/productmanage/productlist/allskulist'
                });
                history.push({
                    pathname: '/home/productmanage/productlist/allskulist'
                });
                break;
            case 'skuList':
                addOneBreadcrumbPath({
                    title: '分销商品SKU列表',
                    path: '/home/productmanage/productlist/skulist'
                });
                history.push({
                    pathname: '/home/productmanage/productlist/skulist/' + value
                });
                break;
            case 'distributionRec':
                addOneBreadcrumbPath({
                    title: '分销推荐列表',
                    path: '/home/productmanage/productlist/distributionrec'
                });
                history.push({
                    pathname: '/home/productmanage/productlist/distributionrec'
                });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

ProductList.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(ProductList);