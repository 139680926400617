/**
 * 【财务设置创建页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, Form, Button, message } from 'antd';
import styles from './index.module.scss';
import { XCancelButton } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { getLocalStorage } from '@/assets/js/storage';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

class addFinancialContent extends React.Component {
    state = {
        isNew: true, // 是否为新建
        id: '', // 配置ID
        unitName: '', // 单位名称
        taxNum: '', // 社会统一信用代码
        registerAddr: '', // 注册地址
        registerTel: '', // 注册电话
        openBank: '', // 开户银行
        bankAccount: '', // 银行账号
        legalName: '', // 法人姓名
        legalTel: '', // 法人手机号
        legalIdentity: '', // 法人身份证
        receiveMail: '', // 收件人邮箱地址
        receiveAddr: '', // 收件地址
        receiveName: '', // 收票人姓名
        receivePhone: '', // 收票人手机号
        accountType: '', // 财务信息类型：个人PERSON、商户BUSINESS
    }

    componentDidMount() {
        this.userType = 'MT';
        const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
        if (userInfo) {
            this.userType = userInfo.accountType;
        }
        this._init();
    }

    // 初始化
    _init = () => {
        let url = '';
        if (this.userType == 'MT') {
            url = '/merchant/info/getFinancial';
        } else {
            url = '/warehouse/info/getFinancial';
        }
        http(url, {}, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result && response.result.id != '') {
                    const { id, unitName, taxNum, registerAddr, registerTel, openBank, bankAccount, legalName, legalTel, legalIdentity, receiveMail, receiveAddr, receiveName, receivePhone, accountType } = response.result;
                    this.setState({
                        isNew: false,
                        id,
                        unitName,
                        taxNum,
                        registerAddr,
                        registerTel,
                        openBank,
                        bankAccount,
                        legalName,
                        legalTel,
                        legalIdentity,
                        receiveMail,
                        receiveAddr,
                        receiveName,
                        receivePhone,
                        accountType
                    });
                } else {
                    this.setState({ isNew: true });
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 提交
    _handleSubmit = e => {
        let saveURL = '';
        let updateURL = '';
        if (this.userType == 'MT') {
            saveURL = '/merchant/info/saveMerchantFinancial';
            updateURL = '/merchant/info/updateMerchantFinancial';
        } else {
            saveURL = '/warehouse/info/saveWarehouseFinancial';
            updateURL = '/warehouse/info/updateWarehouseFinancial';
        }

        const { isNew, id, accountType } = this.state;
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                if (isNew) {
                    http(saveURL, { ...fieldsValue, accountType: 'BUSINESS' }, 'POST').then((response) => {
                        if (response.status == 200) {
                            message.success('创建成功');
                            this._init();
                        }
                    }).catch((e) => {
                        message.error(e.message);
                    });
                } else {
                    http(updateURL, { ...fieldsValue, id, accountType }, 'POST').then((response) => {
                        if (response.status == 200) {
                            message.success('编辑成功');
                            this._init();
                        }
                    }).catch((e) => {
                        message.error(e.message);
                    });
                }
            }
        });
    }

    // 重置
    _resetHandle = () => {
        this.props.form.resetFields();
        this.setState({
            unitName: '', // 单位名称
            taxNum: '', // 社会统一信用代码
            registerAddr: '', // 注册地址
            registerTel: '', // 注册电话
            openBank: '', // 开户银行
            bankAccount: '', // 银行账号
            legalName: '', // 法人姓名
            legalTel: '', // 法人手机号
            legalIdentity: '', // 法人身份证
            receiveMail: '', // 收件人邮箱地址
            receiveAddr: '', // 收件地址
            receiveName: '', // 收票人姓名
            receivePhone: '', // 收票人手机号
        });
    }

    _okHandle = () => {
        let url = '';
        if (this.userType == 'MT') {
            url = '/merchant/info/acvite';
        } else {
            url = '/warehouse/info/acvite';
        }

        http(url, {}, 'GET').then((response) => {
            if (response.status == 200) {
                message.success(response.message);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    regAccount = (rule, value, callback) => {
        console.log(rule, value, 'rule, value,');
        var pattern = /^\d{0,24}$/;
        // 去除空格
        var str = value.replace(/\s+/g, '');
        if (pattern.test(str)) {
            callback();
        } else {
            callback('请输入25位以下的银行卡号');
        }
    }

    render() {
        const { isNew, unitName, taxNum, registerAddr, registerTel, openBank, bankAccount, legalName, legalTel, legalIdentity, receiveMail, receiveAddr, receiveName, receivePhone, accountType } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <div style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }} >
                <div className={styles.title}>财务信息</div>
                <div className={styles.line}></div>
                <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                    {accountType == 'PERSON' ? null : <>
                        <Form.Item
                            colon={false}
                            htmlFor='unitName'
                            label={<span>单位名称</span>}
                        >
                            {getFieldDecorator('unitName', {
                                rules: [{ required: true, message: '请输入单位名称' }],
                                initialValue: unitName
                            })(<Input placeholder='请输入单位名称' style={{ width: 400 }} />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='taxNum'
                            label={<span>社会统一信用代码</span>}
                        >
                            {getFieldDecorator('taxNum', {
                                rules: [
                                    { required: true, message: '请输入社会统一信用代码' },
                                    { pattern: /[A-Z0-9]{18}/g, message: '请输入18位数字加大写字母的社会统一信用代码' },
                                ],
                                initialValue: taxNum
                            })(<Input placeholder='请输入18位数字加大写字母的社会统一信用代码' style={{ width: 400 }} />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='registerAddr'
                            label={<span>注册地址</span>}
                        >
                            {getFieldDecorator('registerAddr', {
                                rules: [{ required: true, message: '请输入注册地址' }],
                                initialValue: registerAddr
                            })(<Input placeholder='请输入注册地址' style={{ width: 400 }} />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='registerTel'
                            label={<span>注册电话</span>}
                        >
                            {getFieldDecorator('registerTel', {
                                rules: [
                                    { required: true, message: '请输入注册电话' },
                                    {
                                        validator: (rule, value='', callback) => {
                                            var RegExp = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/;
                                            if (value == '') {
                                                callback();
                                            } else if (RegExp.test(value) == false) {
                                                callback('请输入正确的固话（格式：区号+号码,如010-1234567）或正确的手机号码');
                                            } else {
                                                callback();
                                            }
                                        }
                                    }
                                    // { pattern: /((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\d{2,4}-?\d{7,8}/
                                    // , message: '请输入正确的固话（格式：区号+号码,如010-1234567）或正确的手机号码' },
                                ],
                                initialValue: registerTel
                            })(<Input placeholder='请输入正确的固话（格式：区号+号码,如010-1234567）或正确的手机号码' style={{ width: 400 }} />)}
                        </Form.Item></>}
                    <Form.Item
                        colon={false}
                        htmlFor='openBank'
                        label={<span>开户银行</span>}
                    >
                        {getFieldDecorator('openBank', {
                            rules: [
                                { required: true, message: '请输入开户银行' },
                                { pattern: /^[\u4e00-\u9fa5]+$/, message: '请输入中文' },
                            ],
                            initialValue: openBank
                        })(<Input placeholder='请输入开户银行' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='bankAccount'
                        label={<span>银行账号</span>}
                    >
                        {getFieldDecorator('bankAccount', {
                            rules: [
                                { required: true, message: '请输入银行账号' },
                                { validator: this.regAccount, message: '请输入25位以下的银行卡号' }
                            ],
                            initialValue: bankAccount
                        })(<Input placeholder='请输入25位以下的银行卡号' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='legalName'
                        label={<span>法人姓名</span>}
                    >
                        {getFieldDecorator('legalName', {
                            rules: [
                                { required: true, message: '请输入法人姓名(仅支持中文)' },
                                { pattern: /^[\u4e00-\u9fa5]{2,6}$/, message: '请输入正确2-6位的姓名(仅支持中文)' },
                            ],
                            initialValue: legalName
                        })(<Input placeholder='请输入正确2-6位的姓名(仅支持中文)' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='legalTel'
                        label={<span>法人手机</span>}
                    >
                        {getFieldDecorator('legalTel', {
                            rules: [
                                { required: true, message: '请输入法人手机' },
                                { pattern: /^[1-9]\d{10}$/, message: '请输入正确的手机号' },
                            ],
                            initialValue: legalTel
                        })(<Input placeholder='请输入法人手机' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='legalIdentity'
                        label={<span>法人身份证</span>}
                    >
                        {getFieldDecorator('legalIdentity', {
                            rules: [
                                { required: true, message: '请输入法人身份证' },
                                { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确的身份证' },
                            ],
                            initialValue: legalIdentity
                        })(<Input placeholder='请输入法人身份证' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='receiveMail'
                        label={<span>收件人邮箱地址</span>}
                    >
                        {getFieldDecorator('receiveMail', {
                            rules: [
                                { required: true, message: '请输入收件人邮箱地址' },
                                { pattern: /^[0-9a-zA-Z._-]+[@][0-9a-zA-Z._-]+([.][a-zA-Z]+){1,2}$/, message: '请输入正确的邮箱地址' },
                            ],
                            initialValue: receiveMail
                        })(<Input placeholder='请输入收件人邮箱地址' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='receiveAddr'
                        label={<span>收件人地址</span>}
                    >
                        {getFieldDecorator('receiveAddr', {
                            rules: [
                                { required: true, message: '请输入收件人地址' },
                                { pattern: /^[^\s]*$/, message: '禁止输入空格' }
                            ],
                            initialValue: receiveAddr
                        })(<Input placeholder='请输入收件人地址' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='receiveName'
                        label={<span>收票人姓名</span>}
                    >
                        {getFieldDecorator('receiveName', {
                            rules: [
                                { required: true, message: '请输入收票人姓名(仅支持中文)' },
                                { pattern: /^[\u4e00-\u9fa5]{2,6}$/, message: '请输入正确2-6位的姓名(仅支持中文)' },
                            ],
                            initialValue: receiveName
                        })(<Input placeholder='请输入收票人姓名(仅支持中文)' style={{ width: 400 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='receivePhone'
                        label={<span>收票人手机</span>}
                    >
                        {getFieldDecorator('receivePhone', {
                            rules: [
                                { required: true, message: '请输入收票人手机' },
                                { pattern: /^[1-9]\d{10}$/, message: '请输入正确的手机号' },
                            ],
                            initialValue: receivePhone
                        })(<Input placeholder='请输入收票人手机' style={{ width: 400 }} />)}
                    </Form.Item>
                    <div style={{ display: 'flex' }}>
                        <Button style={{ marginLeft: '24%', marginRight: '20px', width: '120px', display: 'inline-block' }} type="primary" htmlType="submit">保存</Button>
                        <XCancelButton style={{ width: '120px', display: 'inline-block' }} label='重置' onClick={this._resetHandle} />
                        {/* <Button style={{ marginLeft: '20px', width: '220px', display: 'inline-block' }} disabled={isNew} type="primary" onClick={this._okHandle}>确认已按要求打款后的激活</Button> */}
                    </div>
                </Form>
            </div >
        );
    }
}
addFinancialContent.propTypes = {
    history: PropTypes.object, // 路由history对象
    form: PropTypes.object.isRequired, // 校验
    match: PropTypes.object, // 路由match对象
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(addFinancialContent);// 校验

export default connect(null, null)(ForgotProducDetails);