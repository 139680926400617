/**
 * 更新商品结算价格弹框 
 * */
import React from 'react';
import { Modal, Table, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import styles from './UpdataPriceModal.module.scss';
import { XMediaFn, } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { message } from 'antd';
import { regYuanToFen, } from '@/util/money';

class UpdataPriceModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        skunumber: null,//价格
        skuDataSource: this.props.data||[],//列表数据
    }

    
    _columns = () => ([
        {
            title: '图片',
            dataIndex: 'imgUrl',
            key: 'imgUrl',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderImgCell(text))
        },
        {
            title: '商品编码',
            dataIndex: 'skuNo',
            key: 'skuNo',
            width: '15%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '规格',
            dataIndex: 'skuSpec',
            key: 'skuSpec',
            width: '15%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        // {
        //     title: '数量',
        //     dataIndex: 'c5',
        //     key: 'c5',
        //     width: '10%',
        //     align: 'center',
        //     render: (text) => (this._renderTextCell(text))
        // },
        {
            title: '结算价格(元)',
            dataIndex: 'settlePrice',
            key: 'settlePrice',
            width: '20%',
            align: 'center',
            render: (text, item) => (this._renderInputCell(text, item))
        },
        {
            title: '更新时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
    ]);

    _renderInputCell = (text, item) => {
        return (
            <div>
                <InputNumber
                    className={styles.input}
                    value={text}
                    onChange={(value) => {
                        let souce = this.state.skuDataSource.map(v => {
                            if (`${item.goodsId},${item.skuId}` == `${v.goodsId},${v.skuId}`) {
                                item.settlePrice = value;
                            }
                            return v;
                        });
                        this.setState({
                            skuDataSource: souce
                        });
                    }}
                    min={0}
                    precision={2}
                />
            </div>
        );
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderImgCell = (url) => {
        return (
            <div style={{ display: 'inline-flex' }}>
                <XMediaFn
                    style={{ width: '36px', height: '36px', marginRight: '0' }}
                    dataType='image'
                    dataSource={url}
                />
            </div>
        );
    };

    // 确认
    _handleOk = () => {
        http('/goods/inventory/editGoodsSettle', {
            skuSettleList: this._stockData(),
        }, 'POST').then(() => {
            message.success('更新成功,请前往结算价审核', 3);
            this.setState({
                visible: false,
            });
            this.props.closeMode(true);
        }).catch((error={}) => {
            message.warn(error.message);
        });
    }

    //数据处理
    _stockData = () => {
        const { skuDataSource, } = this.state;
        let stockObj = {};
        let skuSettleList = [];
        for (var j = 0, len = skuDataSource.length; j < len; j++) {
            stockObj = {
                goodsId: skuDataSource[j].goodsId,
                skuId: skuDataSource[j].skuId,
                settlePrice: regYuanToFen(skuDataSource[j].settlePrice),
            };
            skuSettleList.push(stockObj);
        }
        return skuSettleList;
    }

    //全部修改
    _skuModify = () => {
        const { skunumber } = this.state;
        const { data, } = this.props;
        if (skunumber === null) {
            message.warn('请输入批量修改结算价格。');
        } else {
            this.setState({
                skuDataSource: data.map(item => {
                    item.settlePrice = skunumber;
                    return item;
                })
            });
        }
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading,skuDataSource } = this.state;
        return (
            <Modal
                width={1105}
                centered
                title='更新SKU结算价'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.header}>
                        {/* <div className={styles.title}>
                            销售规格
                        </div> */}
                        <div className={styles.update}>
                            <div>批量修改结算价格：</div>
                            <div className={styles.updateNum}>
                                <InputNumber
                                    className={styles.input}
                                    placeholder='请输入库结算价格'
                                    min={0}
                                    onChange={(value) => {
                                        this.setState({ skunumber: value });
                                    }}
                                    precision={2}
                                    value={this.state.skunumber}
                                />
                            </div>
                            <div className={styles.button} onClick={this._skuModify}>
                                全部修改
                            </div>
                        </div>
                    </div>
                    <div className={styles.table}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            // rowSelection={this._rowSelection}
                            rowKey={(record, index) => index}
                            columns={this._columns()}
                            dataSource={skuDataSource}
                            pagination={false}
                            scroll={{ y: 530 }}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

UpdataPriceModal.propTypes = {
    data: PropTypes.array,
    visible: PropTypes.bool.isRequired,
    closeMode: PropTypes.func.isRequired,
};

export default UpdataPriceModal;