import { createModel } from '@/store/tools';

const model = {
    namespace: 'livelist',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getLiveList': T('getLiveList'),
            'getLiveState': T('getLiveState'),
            'getLiveCategory': T('getLiveCategory')
        },
        actions: {
            'getLiveList': A('getLiveList'),
            'getLiveState': A('getLiveState'),
            'getLiveCategory': A('getLiveCategory'),
        },
        sagas: {
            'getLiveList': S('getLiveList', '/liveCharge/liveChargeInfoList'),
            'getLiveState': S('getLiveState', '/liveCharge/liveChargeStateList'),
            'getLiveCategory': S('getLiveCategory', '/liveCharge/liveChargeShowClassList') //getLiveCategory(T)
        },
        reducers: {
            'liveList': R('getLiveList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'liveStateList': R('getLiveState', []),
            'liveCategoryList': R('getLiveCategory', { list: [], default: {} }),
        }
    })
};
export default createModel(model);