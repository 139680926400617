/**
 * 【批发订单页】
 */
import React from 'react';
import { XTabNav } from '@/components/xqxc_ui';
import OrderSearch from './components/OrderSearch';
import Export from './components/Export';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import LogisticsModal from '../../modal/LogisticsModal';
import LeaveMsg from '../../modal/LeaveMsg';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/ordermanage/wholesaleorder/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {
    state = {
        tab: [
            { id: 1, label: '全部订单' },
            { id: 2, label: '待付款' },
            { id: 3, label: '待收货' },
            { id: 4, label: '已完成' },
            { id: 5, label: '已取消' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
        renderMode: '', // 是否显示弹框（查看物流）
        modeParams: {},
        orderID: '', // 当前操作的订单ID 
        pageNum: 1,
        pageSize: 20,
        status: [],
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getProductOrderList({ pageNum, pageSize });
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        const { reqParams } = this.state;
        this.setState({ curTabID: tabID });
        switch (tabID) {
            case 1:
                this.setState({
                    status: [],
                    pageNum: 1,
                    reqParams: {
                        ...reqParams,
                        statusList: [],
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 2:
                this.setState({
                    status: ['PAYING'],
                    pageNum: 1,
                    reqParams: {
                        ...reqParams,
                        statusList: ['PAYING'],
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 3:
                this.setState({
                    status: ['PAIED', 'PART_GOODS_SENT', 'GOODS_SENT'],
                    pageNum: 1,
                    reqParams: {
                        ...reqParams,
                        statusList: ['PAIED', 'PART_GOODS_SENT', 'GOODS_SENT'],
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 4:
                this.setState({
                    status: ['FINISHED', 'GOODS_CONFIRMED'],
                    pageNum: 1,
                    reqParams: {
                        ...reqParams,
                        statusList: ['FINISHED', 'GOODS_CONFIRMED'],
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 5:
                this.setState({
                    status: ['CANCELLED'],
                    pageNum: 1,
                    reqParams: {
                        ...reqParams,
                        statusList: ['CANCELLED'],
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
        }
        // switch (tabID) {
        //     case 1:
        //         this.props.getProductOrderList({ pageNum, pageSize });
        //         break;
        //     case 2:
        //         this.props.getProductOrderList({ statusList: ['PAYING'], pageNum, pageSize });
        //         break;
        //     case 3:
        //         this.props.getProductOrderList({ statusList: ['PAIED', 'PART_GOODS_SENT', 'GOODS_SENT'], pageNum, pageSize });
        //         break;
        //     case 4:
        //         this.props.getProductOrderList({ statusList: ['FINISHED', 'GOODS_CONFIRMED'], pageNum, pageSize });
        //         break;
        //     case 5:
        //         this.props.getProductOrderList({ statusList: ['CANCELLED'], pageNum, pageSize });
        //         break;
        // }
    }

    // 表格操作
    _tableAction = (key, type, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            // 查看详情
            case 'info':
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: '/home/ordermanage/wholesaleorder/orderdetail/'
                });
                history.push({
                    pathname: '/home/ordermanage/wholesaleorder/orderdetail',
                    search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                });
                break;
            // 查看物流
            case 'logistics':
                this.setState({ renderMode: 'logistics', orderID: key });
                break;
            // 联系商户
            case 'business':
                addOneBreadcrumbPath({
                    title: '新建工单',
                    path: '/home/ordermanage/wholesaleorder/workorder/'
                });
                history.push({
                    pathname: '/home/ordermanage/wholesaleorder/workorder/'
                });
                break;
            // 操作记录
            case 'history':
                addOneBreadcrumbPath({
                    title: '操作记录',
                    path: '/home/ordermanage/wholesaleorder/orderhistory/'
                });
                history.push({
                    pathname: '/home/ordermanage/wholesaleorder/orderhistory',
                    search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                });
                break;
            case 'leaveMsg':
                this.setState({ renderMode: 'leaveMsg', modeParams: record });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        const { searchData } = this.state;
        const reqParams = { ...searchData, pageNum, pageSize };
        this.searchCache = { ...searchData, pageNum, pageSize };
        this.setState({ pageSize, pageNum, reqParams });
        this.props.getWholesaleList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 打开查看物流弹框
    _renderMode = () => {
        const { renderMode, modeParams } = this.state;
        switch (renderMode) {
            case 'logistics':
                return <LogisticsModal visible={true} data={this.state.orderID} closeMode={this._closeMode} />;
            case 'leaveMsg':
                return <LeaveMsg visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭查看物流弹框
    _closeMode = () => {
        this.setState({ renderMode: '' });
    }
    _searchHandle = (useCache) => {
        const { reqParams, pageNum, pageSize } = this.state;
        this.searchCache = {
            ...reqParams,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.props.getWholesaleList(this.searchCache);
    }
    _searchOrder = (searchData, param, flag) => {
        const { pageNum, pageSize } = this.state;
        const reqParams = { ...searchData, pageNum: 1, pageSize };
        this.setState({ searchData, reqParams, param, pageNum: 1 }, () => {
            if (flag) {
                // 重置
                KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
            } else {
                this._searchHandle('useCache');
            }
        });
        // switch (curTabID) {
        //     case 1:
        //         this.props.getProductOrderList(reqParams);
        //         break;
        //     case 2:
        //         this.props.getProductOrderList({ ...reqParams, statusList: ['PAYING'] });
        //         break;
        //     case 3:
        //         this.props.getProductOrderList({ ...reqParams, statusList: ['PAIED', 'PART_GOODS_SENT', 'GOODS_SENT'] });
        //         break;
        //     case 4:
        //         this.props.getProductOrderList({ ...reqParams, statusList: ['FINISHED', 'GOODS_CONFIRMED'] });
        //         break;
        //     case 5:
        //         this.props.getProductOrderList({ ...reqParams, statusList: ['CANCELLED'] });
        //         break;
        // }
    }

    render() {
        const { WholesaleList } = this.props;
        const { curTabID } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <OrderSearch searchState={this.state} orderState={this.state.curTabID} status={this.state.status} searchOrder={this._searchOrder} />
                    {/* <Export /> */}
                    <OrderTable renderData={WholesaleList} tableAction={this._tableAction} paginationChange={this._paginationChange} curTabID={curTabID} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getWholesaleList: PropTypes.func,
    WholesaleList: PropTypes.object
};

const mapStateToProps = (state) => ({
    WholesaleList: state.wholesaleOrder.WholesaleList,
});

export default connect(mapStateToProps, { ...model.actions, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);
