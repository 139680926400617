import React from 'react';
import styles from './AdvertTable.module.scss';
import { XTableSelectionCount, XPagination } from '@/components/xqxc_ui';
import { Table, Badge } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import { getIsMarketingPromise } from '@/assets/js/authType';

class AdvertTable extends React.Component {
    state = {
        account: '',
        selectedRowKeys: [],
        cols: [
            {
                title: '对账单号',
                dataIndex: 'id',
                key: 'id',
                width: 130,
                align: 'center',
                render: (text) => (this._renderTextCell(text))
            },
            {
                title: '往来单位',
                dataIndex: 'targetName',
                key: 'targetName',
                width: 100,
                align: 'center',
                render: (text) => (this._renderTextCell(text))
            },
            {
                title: '对账周期',
                dataIndex: 'rangeDate',
                key: 'rangeDate',
                width: 140,
                align: 'center',
                render: (text, record) => (
                    <div>
                        <div>{record.startDate}</div>
                        <div>{record.endDate}</div>
                    </div>
                )
            },
            {
                title: '技术服务费(元)',
                dataIndex: 'serviceFee',
                key: 'serviceFee',
                width: 120,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '平台服务费(元)',
                dataIndex: 'distributionFee',
                key: 'distributionFee',
                width: 120,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '秀场服务费(元)',
                dataIndex: 'showServiceFee',
                key: 'showServiceFee',
                width: 120,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '心选推荐费(元)',
                dataIndex: 'heartSelectionRecommendationIncome',
                key: 'heartSelectionRecommendationIncome',
                width: 120,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '免单均摊金额(元)',
                dataIndex: 'freeOfCharge',
                key: 'freeOfCharge',
                width: 130,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '其他费用(元)',
                dataIndex: 'otherFee',
                key: 'otherFee',
                width: 100,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '应收(元)',
                dataIndex: 'revenue',
                key: 'revenue',
                width: 100,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '开票金额(元)',
                dataIndex: 'settleAmount',
                key: 'settleAmount',
                width: 120,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '未票金额(元)',
                dataIndex: 'unSettleAmount',
                key: 'unSettleAmount',
                width: 120,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '营销服务费(元)',
                dataIndex: 'marketServiceFee',
                key: 'marketServiceFee',
                width: 120,
                align: 'center',
                render: (text) => (this._renderTextMoneyCell(text))
            },
            {
                title: '生成时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: 140,
                align: 'center',
                render: (text) => (this._renderTextCell(text))
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                width: 150,
                align: 'center',
                render: (text) => (this._renderStatusCell(text))
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                width: 180,
                align: 'center',
                render: (id, item) => this._renderActionCell(item.id, item)
            },
        ]
    }

    componentDidMount() {
        getIsMarketingPromise().then(res => {
            if (!res) {
                this.state.cols.splice(12, 1);
                this.setState({ cols: this.state.cols.concat() });
            }
        });
    }

    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }
    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderStatusCell = (text) => {
        var statusArr = ['INIT', 'TO_CONFIRM', 'CONFIRM_SUCCESS', 'CONFIRM_FAIL'];
        var statusText = ['新建', '发起对账', '确认初审通过', '确认初审不通过'];
        var statusBadge = ['processing', 'processing', 'processing', 'error'];
        const index = statusArr.indexOf(text);
        return index === -1 ? null : <Badge status={statusBadge[index]} text={statusText[index]} />;
    }
    _renderActionCell = (id, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                {item.status === 'TO_CONFIRM' ? <div className={styles.item} onClick={() => tableAction(id, 'confir', item)}>确认对账</div> : null}
                <div className={styles.item} onClick={() => tableAction(id, 'info', item)}>详情</div>
            </div>
        );
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    onChange = (pagination, filters, sorter) => {
        console.log('params', pagination, filters, sorter);
    };
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }
    render() {
        const { selectedRowKeys, cols } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={cols}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    // onChange={this.onChange}
                    scroll={{ y: true, x: '110%' }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default AdvertTable;