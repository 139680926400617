import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';

class TableContent extends React.Component {

    _takeColumns = () => ([
        {
            title: '员工账号',
            dataIndex: 'loginName',
            key: 'loginName',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        // {
        //     title: '所属分类',
        //     dataIndex: 'companyName',
        //     key: 'companyName',
        //     width: '20%',
        //     align: 'center',
        //     render: (text) => (this._renderTextCell(text))
        // },
        {
            title: '所属部门',
            dataIndex: 'deptName',
            key: 'deptName',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '姓名',
            dataIndex: 'emplName',
            key: 'emplName',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '职位',
            dataIndex: 'position',
            key: 'position',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: '20%',
            render: (text, item) => this._renderActionCell(item.id, item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderActionCell = (key, item) => {
        const { tableAction, isEmplEdit, emplTableType } = this.props;
        if (isEmplEdit) {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(key, 'edit')}>编辑</div>
                    <div className={styles.item} onClick={() => tableAction(key, 'del')} style={emplTableType == 2 ? { display: 'none' } : {}}>移除</div>
                </div>
            );
        } else {
            return (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                    无
                </div>
            );
        }

    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: 500 }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    emplTableType: PropTypes.number, // 0: 本公司管理员员工  1：本公司子级部门或子级公司员工  2：未分类员工
    isEmplEdit: PropTypes.bool, // // 该角色下的员工是否可编辑（系统默认的顶级角色不可编辑，新建的二级角色可以编辑）
};

export default TableContent;