/**
 * 审核失败商品
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import { message } from 'antd';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm } from '@/components/xqxc_ui';
import TableContent from '../../components/TableContent';
import Reupload from '../../modal/Reupload';
import * as actions from '@/reducers/auditManage/activityProduct/actions';

class AllProduct extends Component {
    //初始数据
    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
            activityTitle: '', // 活动名称
            activityType: {}, // 活动类型
            activityStatus: {},
            goodsName: '',//商品名称
        };
        this.reqParams = { pageNum: 1, pageSize: 20, auditState: 'FAIL' };
    }
    //props检验
    static propTypes = {
        getActivityGoods: PropTypes.func.isRequired,//异步获取列表数据
        getActivityType: PropTypes.func.isRequired,//异步获取类型枚举数据
        getActivityStatus: PropTypes.func.isRequired,//异步获取状态枚举数据
        activityGoods: PropTypes.object.isRequired,//列表数据
        activityTypes: PropTypes.array.isRequired,//类型枚举数据
        activityStatuses: PropTypes.array.isRequired//状态枚举数据
    };
    //异步请求
    componentDidMount() {
        const { getActivityGoods, getActivityType, getActivityStatus } = this.props;
        getActivityGoods(this.reqParams);
        getActivityType();
        getActivityStatus();
    }
    // 查询触发
    _searchHandle = () => {
        const { getActivityGoods } = this.props;
        const { activityType, activityTitle, goodsName } = this.state;
        getActivityGoods({ ...this.reqParams, activityType: activityType.key, activityTitle, goodsName });
    }
    // 重置触发
    _resetHandle = () => {
        const { getActivityGoods } = this.props;
        this.setState({ activityType: {}, activityTitle: '', goodsName: '' });
        getActivityGoods(this.reqParams);
    }
    // 表格操作
    _tableAction = (id, activityType, params) => {
        switch (activityType) {
            case 'reupload':
                this.setState({ currentOpenMode: 'REUPLOAD', openModeParam: params });
                break;
            case 'delete':
                showConfirm('您确定删除该活动商品？', '', () => {
                    http('/marketing/activityMerchantInfo/deleteActivityGoods', { activityId: params.activityId, shopId: params.shopId, goodsId: params.goodsId }, 'POST').then(() => {
                        this.props.getActivityGoods(this.reqParams);
                        message.success('活动商品删除成功。');
                    }).catch(error => {
                        message.error(error.message);
                    });
                });
                break;
        }
    }
    //打开弹窗
    _renderMode = () => {
        const { openModeParam } = this.state;
        switch (this.state.currentOpenMode) {
            case 'REUPLOAD':
                return <Reupload
                    title='活动商品重新上传审核'
                    visible={true}
                    openModeParam={openModeParam}
                    closeMode={this._closeMode} />;
            default:
                return null;
        }
    }
    // 关闭弹窗
    _closeMode = (update) => {
        const { getActivityGoods } = this.props;
        const { activityType, activityTitle, goodsName } = this.state;
        update && getActivityGoods({ ...this.reqParams, activityType: activityType.key, activityTitle, goodsName });
        this.setState({ currentOpenMode: '' });
    }
    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        const { getActivityGoods } = this.props;
        const { activityType, activityTitle, goodsName } = this.state;
        getActivityGoods({ ...this.reqParams, activityType: activityType.key, activityTitle, goodsName, pageSize, pageNum });
    }
    //渲染页面
    render() {
        const { activityGoods, activityTypes, activityStatuses } = this.props;
        const { activityType, activityTitle, goodsName, activityStatus } = this.state;
        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.search_box}>
                    <XInput
                        inputStyle={{ width: '200px' }}
                        label='活动名称'
                        placeholder='请输入'
                        bindThis={this}
                        bindName='activityTitle'
                        value={activityTitle}
                    />
                    <XSelect
                        style={{ marginLeft: '20px' }}
                        selectStyle={{ width: '200px' }}
                        label='活动类型'
                        placeholder='请选择'
                        renderData={activityTypes}
                        bindThis={this}
                        bindName='activityType'
                        dataIndex='value'
                        keyIndex='key'
                        value={activityType.value}
                    />
                    {/* <XSelect
                        style={{ marginLeft: '30px', width: '216px' }}
                        selectStyle={{ width: '150px' }}
                        label='活动状态'
                        placeholder='请选择'
                        renderData={activityStatuses}
                        bindThis={this}
                        bindName='activityStatus'
                        dataIndex='value'
                        keyIndex='key'
                        value={activityStatus.value}
                    /> */}
                    <XInput
                        style={{ marginLeft: '20px' }}
                        inputStyle={{ width: '200px' }}
                        label='商品名称'
                        placeholder='请输入'
                        bindThis={this}
                        bindName='goodsName'
                        value={goodsName}
                    />
                    <XOKButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='查询'
                        onClick={this._searchHandle}
                    />
                    <XCancelButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='重置'
                        onClick={this._resetHandle}
                    />
                </div>
                <TableContent paginationChange={this._paginationChange} renderData={activityGoods} tableAction={this._tableAction} />
                {this._renderMode()}
            </div>
        );
    }
}
//映射异步数据
const mapStateToProps = (state) => ({
    activityGoods: state.activityGoodsData.activityGoods,
    activityTypes: state.activityGoodsData.activityType,
    activityStatuses: state.activityGoodsData.activityStatus
});
export default connect(mapStateToProps, actions)(AllProduct);