import React from 'react';
import styles from './TableData.module.scss';
import { message } from 'antd';
import { Switch } from 'antd';
import copy from 'copy-to-clipboard';
import { tooltipStyle, tooltip } from '@/components/TableCell';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
    {
        title: '编号',
        dataIndex: 'id',
        key: 'merchantKey',
        width: '15%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '店铺名称',
        dataIndex: 'shopName',
        key: 'shopName',
        width: '20%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '店铺简介',
        dataIndex: 'shopDesc',
        key: 'shopDesc',
        width: '20%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '联系电话',
        dataIndex: 'contactTel',
        key: 'contactTel',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '店铺类型',
        dataIndex: 'shopType',
        key: 'shopType',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: text => tooltip(text ? text.value : '')
    },
    {
        title: '店铺状态',
        dataIndex: 'shopState',
        key: 'shopState',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: (text, item) => (SwitchCell(text, item, actionClickHandle))
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '15%',
        onCell: tooltipStyle,
        render: (id, item) => TableAction(id, item, actionClickHandle)
    },
];

export const takeDataSource = (data) => {
    // 解析表格数据，使符合columns格式要求
    return data && data.map((item) => {
        let key = 0;
        item.merchantKey = key++;//操作key
        return item;
    });
};

export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};

// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
    let checked = true;
    if (sw == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={checked}
                onChange={() => {
                    actionClickHandle(sw, item, 'switch');
                }}
            />
        </div>
    );
};

const copyLink = (expressNo) => {
    if (copy(expressNo)) {
        message.info('复制成功');
    } else {
        message.info('复制失败');
    }
};

const TableAction = (id, item, actionClickHandle) => {
    return (
        <div className={styles.action}>
            {
                item.shopState == '1' && <div className={styles.item} onClick={() => copyLink(item.shopAddress)}>复制链接</div>
            }
            <div className={styles.item} onClick={() => actionClickHandle(id, item, 'MER_EDITOR')}>编辑</div>
            {/* <div className={styles.item} onClick={() => actionClickHandle(id, item, 'MER_DELETE')}>删除</div> */}
            <div className={styles.item} onClick={() => actionClickHandle(id, item, 'MER_STOR')}>进入店铺</div>
        </div>
    );
};