/**
 * 修改客服密码
 */
import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './ResetPwdModal.module.scss';
import { XInputPassword } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import md5 from 'js-md5';

class ResetPwdModal extends React.Component {

    state = {
        visible: this.props.visible,
        outerLoginPwd: '', // 新密码
        ensureLoginPwd: '', // 确认密码
    }

    // 确认
    _handleOk = () => {
        const { outerLoginPwd, ensureLoginPwd } = this.state;
        if (outerLoginPwd != ensureLoginPwd) {
            message.success('两次输入的新密码不一致！');
            return;
        }
        const data = {
            id: this.props.id,
            outerLoginPwd//: md5(outerLoginPwd).toUpperCase(),
        };
        http('/merchant/customer/updateOuterCustomerPwd', data, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('修改成功！');
                this._handleCancel(true, outerLoginPwd);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 取消
    _handleCancel = (update, pwd) => {
        this.setState({ visible: false });
        this.props.closeModal(update, pwd);
    }

    render() {
        const { visible, outerLoginPwd, ensureLoginPwd } = this.state;

        return (
            <Modal
                width={460}
                centered
                title='客服密码修改'
                visible={visible}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInputPassword
                        style={{ marginLeft: '28px' }}
                        inputStyle={{ width: '300px' }}
                        label='新密码'
                        placeholder='请输入新密码'
                        value={outerLoginPwd}
                        bindThis={this}
                        bindName='outerLoginPwd'
                        isRequired={true}
                    />
                    <XInputPassword
                        style={{ marginTop: '30px' }}
                        inputStyle={{ width: '300px' }}
                        label='确认新密码'
                        placeholder='请输入新密码'
                        value={ensureLoginPwd}
                        bindThis={this}
                        bindName='ensureLoginPwd'
                        isRequired={true}
                    />
                </div>
            </Modal>
        );
    }
}

ResetPwdModal.propTypes = {
    id: PropTypes.number.isRequired, // 客服ID
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeModal: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default ResetPwdModal;