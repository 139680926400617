/**
 *  直播带货管理
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

class Purchasing extends React.Component {

    render() {
        return (
            <Main history={this.props.history} />
        );
    }
    static propTypes = {
        history: PropTypes.object, // 路由history对象
        match: PropTypes.object, // 路由match对象
    };
}

export default Purchasing;