import React, { useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import Allchargeoff from './Allchargeoff';
import Waitchargeoff from './Waitchargeoff';
import Ovechargeoff from './Overchargeoff';

const ChargeoffList = ({ history }) => {

    const tabList = [
        { id: 'Allchargeoff', label: '全部' },
        { id: 'Waitchargeoff', label: '待核销' },
        { id: 'Ovechargeoff', label: '已核销' }
    ];

    const [tabId, setTabId] = useState('Allchargeoff');

    const components = {
        Allchargeoff: <Allchargeoff history={history} />,
        Waitchargeoff: <Waitchargeoff history={history} />,
        Ovechargeoff: <Ovechargeoff history={history} />,
    };

    return <div className={styles.flexBoxContainer}>
        <XTabNav renderData={tabList} onChange={v => setTabId(v)} activedID={tabId} />
        {components[tabId]}
    </div>;
};
ChargeoffList.propTypes = {
    history: PropTypes.object,
};
export default ChargeoffList;