/**
 * 仓库管理saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as W from './actiontypes';
import http from '@/assets/api/http';

// 仓库对账单列表
export const getWarehouseList = function* () {
    yield takeEvery(W.GET_WAREHOUSE_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationInfo/warehouseQueryReconciliationInfos', action.payload, 'POST');
            yield put({ type: W.SET_WAREHOUSE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 仓库对账单详情
export const getWarehouseDetail = function* () {
    yield takeEvery(W.GET_WAREHOUSE_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationInfo/getReconciliation', action.payload, 'POST');
            yield put({ type: W.SET_WAREHOUSE_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 应收，应付明细
export const getWarehouseChargesDetail = function* () {
    yield takeEvery(W.GET_WAREHOUSE_CHARGE_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationSource/warehouseGetCharges', action.payload, 'POST');
            yield put({ type: W.SET_WAREHOUSE_CHARGE_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 奢批订单列表
export const getWarehouseLuxuryOrders = function* () {
    yield takeEvery(W.GET_WAREHOUSE_LUXURYORDERS, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationSource/getLuxuryOrders', action.payload, 'POST');
            yield put({ type: W.SET_WAREHOUSE_LUXURYORDERS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};