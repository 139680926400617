import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table, Tooltip } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { isEmpty } from '@/assets/js/utils';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

export default class TableContent extends Component {
    state = {
        selectedRowKeys: [],
    };
    static propTypes = {
        tableAction: PropTypes.func,
        paginationChange: PropTypes.func,
        renderData: PropTypes.object,
    };
    _columns = () => [
        {
            title: '集采活动',
            dataIndex: 'activityTitle',
            key: 'activityTitle',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '活动星级(星)',
            dataIndex: 'starLevel',
            key: 'starLevel',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品货号',
            dataIndex: 'goodsNo',
            key: 'goodsNo',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品单位',
            dataIndex: 'unit',
            key: 'unit',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '集采总数',
            dataIndex: 'stock',
            key: 'stock',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品价格(元)',
            dataIndex: 'goodsPrice',
            key: 'goodsPrice',
            align: 'center',
            width: 150,
            render: priceFenToYuanCell
        },
        // {
        //     title: '能量值价格',
        //     dataIndex: 'gatherBeans',
        //     key: 'gatherBeans',
        //     align: 'center',
        //     width: 150,
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        {
            title: '每人限购数量',
            dataIndex: 'goodsNum',
            key: 'goodsNum',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '预热时间',
            dataIndex: 'warmingTime',
            key: 'warmingTime',
            align: 'center',
            width: 200,
            render: (text, record) => this._jointCell(record, 'warmingTime')
        },
        {
            title: '活动时间',
            dataIndex: 'activityTime',
            key: 'activityTime',
            align: 'center',
            width: 200,
            render: (text, record) => this._jointCell(record, 'activityTime')
        },
        {
            title: '结算时间',
            dataIndex: 'balanceTime',
            key: 'balanceTime',
            align: 'center',
            width: 200,
            render: (text, record) => this._jointCell(record, 'balanceTime')
        },
        {
            title: '预计收益范围',
            dataIndex: 'earningsScope',
            key: 'earningsScope',
            align: 'center',
            width: 150,
            render: (text, record) => this._jointCell(record, 'earningsScope')
        },
        {
            title: '操作',
            dataIndex: 'activityGoodsId',
            key: 'activityGoodsId',
            align: 'center',
            width: 150,
            render: this._tableAction
        },
    ];

    //换行时间展示
    nowrapDate = (start, end) => {
        return <div className={styles.dates}>
            <span>{isEmpty(start) ? '-' : start}</span><br />
            <span>{isEmpty(end) ? '-' : end}</span>
        </div>;
    };
    //拼接数据
    _jointCell = (item, type) => {
        switch (type) {
            case 'warmingTime':
                return this.nowrapDate(item.warmingStartTime, item.warmingEndTime);
            case 'activityTime':
                return this.nowrapDate(item.startTime, item.endTime);
            case 'balanceTime':
                return this.nowrapDate(item.settleStartTime, item.settleEndTime);
            case 'earningsScope':
                return <p>{item.minRate ? item.minRate / 10 : 0}%<span style={{ padding: '0 10px' }}>~</span>{item.maxRate ? item.maxRate / 10 : 0}%</p>;
            default:
                return null;
        }
    }
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
            // this._actionHandle(null, 'batchBan', selectedRowKeys);
        }
    };
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }
    // 表格操作
    _tableAction = (key, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
            </div>
        );
    };
    //数据处理
    _tableData = (data) => {
        return data ? data : [];
    }
    render() {
        const { renderData, paginationChange } = this.props;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._columns()}
                    pagination={false}
                    dataSource={this._tableData(renderData.dataList)}
                    scroll={{ x: 1650, y: true }} />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={paginationChange} />
            </div>
        );
    }
}