/**
 * 已取消出库单
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import OrderTable from './components/OrderTable';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { OrderState, OrderType } from '@/vcomps';
import { getCancelDeliverList } from '@/reducers/delivermanage/canceldeliver/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getWholesaleSelect } from '@/reducers/delivermanage/readydeliver/actions';
import http from '@/assets/api/http';
import { message } from 'antd';

const invoiceSelect = [{ id: 1, name: '是' }, { id: 0, name: '否' }];
const saleStatus = [{ code: 'CANCELLED', value: '已取消' }];
class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDeliverModal: false, // 是否打开弹窗
            openModalParam: {}, // 弹框传参
            accountTel: '', // 会员手机
            orderId: '', // 订单编号
            startDate: undefined, // 下单开始时间
            endDate: undefined, // 下单结束时间
            status: {}, // 订单状态
            orderType: {}, // 订单类型
            isInvoice: {}, // 是否开票
            selectedRows: [], // 复选框
            wholesaleOrder: { code: '2', value: '全部' }, // 批发订单
        };
        this.searchCache = { pageNum: 1, pageSize: 20 }; // 查询缓存
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
        this.props.getWholesaleSelect();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { accountTel, orderId, startDate, endDate, status, orderType, isInvoice, pageNum, pageSize, wholesaleOrder } = this.state;
        this.searchCache = {
            accountTel,
            orderId,
            startDate: startDate ? startDate.format('YYYY-MM-DD HH:mm:ss') : '',
            endDate: endDate ? endDate.format('YYYY-MM-DD HH:mm:ss') : '',
            status: status.code,
            orderType: orderType.code,
            isInvoice: isInvoice.id,
            isDistribution: wholesaleOrder.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getCancelDeliverList(this.searchCache); // 发起请求
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            accountTel: '',
            orderId: '',
            startDate: undefined,
            endDate: undefined,
            status: {},
            orderType: {},
            isInvoice: {},
            selectedRows: [],
            openModalParam: {},
            wholesaleOrder: { code: '2', value: '全部' },
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        // this.searchCache = { pageNum: 1, pageSize: 20 }; // 查询缓存
        // this.props.getCancelDeliverList(this.searchCache);
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum });
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getCancelDeliverList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (id, item, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info': // 查看详情
                addOneBreadcrumbPath({
                    title: '出库单详情',
                    path: '/home/delivermanage/canceldeliver/info/'
                });
                history.push({
                    pathname: '/home/delivermanage/canceldeliver/info/' + id
                });
                break;
        }
    }

    // 批量复选框
    _rowSelection = (selectedRows) => {
        this.setState({ selectedRows });
    }
    // 订单状态
    _StatusChangeHandle = e => {
        this.setState({
            status: e,
        });
    }

    //导出
    _exportHandle = () => {
        const { wholesaleOrder,selectedRows, accountTel, orderId, startDate, endDate, status, orderType, isInvoice } = this.state;
        const param = {
            accountTel, //电话
            orderId,//订单ID
            startDate: startDate ? startDate.format('YYYY-MM-DD HH:mm:ss') : '',//开始时间
            endDate: endDate ? endDate.format('YYYY-MM-DD HH:mm:ss') : '',//结束时间

            status: status.code,//订单状态
            orderType: orderType.code, //订单类型

            isInvoice: isInvoice.id, //是否开票
            ids: selectedRows,
            isDistribution:wholesaleOrder.code  //批发订单
        };
        console.log('-----------------------', param);
        http('/merchant/order/exportCancelledOutboundOrderList', param, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result == '') {
                    message.error('没有订单数据');
                } else {
                    window.location.href = response.result;
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    render() {
        const { accountTel, orderId, startDate, endDate, status, orderType, isInvoice, wholesaleOrder } = this.state;
        const { wholesaleSelectList } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria1}>
                        <XInput
                            style={{ width: '248px' }}
                            inputStyle={{ width: '180px' }}
                            label='会员手机'
                            placeholder='请输入会员手机'
                            value={accountTel}
                            bindThis={this}
                            bindName='accountTel'
                        />
                        <XInput
                            style={{ marginLeft: '20px', width: '248px' }}
                            inputStyle={{ width: '180px' }}
                            label='订单编号'
                            placeholder='请输入订单编号'
                            value={orderId}
                            bindThis={this}
                            bindName='orderId'
                        />
                        <XDatePicker
                            style={{ width: '264px', marginLeft: '20px' }}
                            label='下单时间'
                            value={startDate}
                            bindThis={this}
                            bindName='startDate'
                        />
                        <XDatePicker
                            label=''
                            value={endDate}
                            bindThis={this}
                            bindName='endDate'
                            
                        />
                        {/* <OrderState
                        style={{ marginLeft: '20px', width: '258px' }}
                        selectStyle={{ width: '180px' }}
                        label='订单状态'
                        placeholder='请输入订单状态'
                        bindThis={this}
                        bindName='status'
                        value={status}
                    /> */}
                        <XSelect
                            style={{ marginLeft: '30px', width: '216px' }}
                            selectStyle={{ width: '150px' }}
                            label='订单状态'
                            placeholder='请选择'
                            renderData={saleStatus}
                            onChange={this._StatusChangeHandle}
                            dataIndex='value'
                            keyIndex='code'
                            value={status.value}
                            showSearch={true}
                        />
                        <OrderType
                            style={{ marginLeft: '20px', width: '258px' }}
                            selectStyle={{ width: '180px' }}
                            label='订单类型'
                            placeholder='请输入订单类型'
                            bindThis={this}
                            bindName='orderType'
                            value={orderType}
                        />
                    </div>
                    <div className={styles.searchCriteria2}>
                        <XSelect
                            style={{ width: '248px' }}
                            selectStyle={{ width: '180px' }}
                            label='是否开票'
                            placeholder='请选择是否开票'
                            renderData={invoiceSelect}
                            dataIndex='name'
                            keyIndex='id'
                            value={isInvoice.name}
                            showSearch={true}
                            bindThis={this}
                            bindName='isInvoice'
                        />
                        <XSelect
                            style={{ marginLeft: '20px', width: '248px' }}
                            selectStyle={{ width: '180px' }}
                            label='批发订单'
                            placeholder='请选择'
                            renderData={wholesaleSelectList}
                            dataIndex='value'
                            keyIndex='code'
                            value={wholesaleOrder.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='wholesaleOrder'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />

                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.otherFeatures}>
                        <XOKButton
                            style={{ width: '120px', marginRight: 20 }}
                            label='导出出库单'
                             onClick={this._exportHandle}
                        />
                    </div>
                    <OrderTable
                        renderData={this.props.cancelDeliverList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                        onRowSelection={this._rowSelection}
                    />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getCancelDeliverList: PropTypes.func,
    cancelDeliverList: PropTypes.object,
    getWholesaleSelect: PropTypes.func, // 批发订单下拉列action 
    wholesaleSelectList: PropTypes.array, // 批发订单下拉列数据
};

const mapStateToProps = (state) => ({
    cancelDeliverList: state.canceldeliver.getCancelDeliverList,
    wholesaleSelectList: state.readydeliver.getWholesaleSelect,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getCancelDeliverList, getWholesaleSelect })(Main);