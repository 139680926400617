/**
 * 【预收款管理】
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import BalanceWarn from './balancewarn/main';
import DrawIncome from './drawincome/main';
import UploadRechargeKey from './uploadrechargekey/main';

class Main extends React.Component {
    state = {
        modeParams: {},
        tab: [
            { id: 1, label: '收支明细' },
            { id: 2, label: '余额预警提醒' },
            { id: 3, label: '上传充值凭证' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // if (this.props.match) {
        //     let { type } = this.props.match.params || {};
        //     if (type) {
        //         this.setState({ curTabID: +type });
        //     }
        // }
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    render() {
        const { curTabID } = this.state;
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={curTabID} />
                {curTabID === 1 && <DrawIncome {...this.props} />}
                {curTabID === 2 && <BalanceWarn {...this.props} />}
                {curTabID === 3 && <UploadRechargeKey {...this.props} />}
            </div>
        );
    }
}

Main.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
};
export default connect(() => ({}), { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);
