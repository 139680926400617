/**
 *  批发商品
 **/
import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import LowInventory from './lowinventory';
import AuditFail from './auditfail';
import { getLocalStorage, removeLocalStorage } from '@/assets/js/storage';

const tabList = [
    { id: 'lowInventory', label: '低库存预警' },
    { id: 'auditFail', label: '审核失败' },
];

const WholesaleGoods = ({ history }) => {

    const [curTabID, setCurTabID] = useState('lowInventory');

    useEffect(() => {
        if (getLocalStorage('productManageWholesaleGoods')) {
            setCurTabID(getLocalStorage('productManageWholesaleGoods'));
            removeLocalStorage('productManageWholesaleGoods');
        }
    }, []);

    const tables = {
        lowInventory: <LowInventory history={history} curTabID={curTabID} />,
        auditFail: <AuditFail history={history} curTabID={curTabID} />
    };

    return <div className={styles.flexBoxContainer}>
        <XTabNav renderData={tabList} onChange={tabID => setCurTabID(tabID)} activedID={curTabID} />
        {tables[curTabID]}
    </div>;
};

WholesaleGoods.propTypes = {
    history: PropTypes.object, // 路由history对象
};

export default WholesaleGoods;