import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

//获取违规的店铺列表
export const getIllegalShopList = function* () {
    yield takeEvery(T.GET_ILLEGAL_SHOPLIST, function* requestData(action) {
        try {
            let result = yield http('/violation/list', action.payload, 'POST');
            yield put({ type: T.SET_ILLEGAL_SHOPLIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//获取违规的列表的下拉
export const getIllegalSele = function* () {
    yield takeEvery(T.GET_ILLEGAL_SELE, function* requestData(action) {
        try {
            let result = yield http('/enum/ViolantionStateEnum', action.payload, 'POST');
            yield put({ type: T.SET_ILLEGAL_SELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//获取违规的店铺列表详情
export const getIllegalShopDetail = function* () {
    yield takeEvery(T.GET_ILLEGAL_SHOPDETAIL, function* requestData(action) {
        try {
            let result = yield http('/violation/representations/info', action.payload, 'POST');
            yield put({ type: T.SET_ILLEGAL_SHOPDETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//获取违规的商品列表
export const getIllegalProductList = function* () {
    yield takeEvery(T.GET_ILLAGEL_PRODUCTLIST, function* requestData(action) {
        try {
            let result = yield http('/goods/goods/findViolationGoodsList', action.payload, 'POST');
            yield put({ type: T.SET_ILLAGEL_PRODUCTLIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//获取违规的商品列表详情
export const getIllegalProductDetail = function* () {
    yield takeEvery(T.GET_ILLEGAL_PRODUCTDETAIL, function* requestData(action) {
        try {
            let result = yield http('/goods/goods/appealReplyInfo', action.payload, 'POST');
            yield put({ type: T.SET_ILLEGAL_PRODUCTDETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};