/**
 * 编辑仓库 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, Input, Form, Select, Radio, Icon, message, InputNumber } from 'antd';
import styles from './NewStorehouse.module.scss';
import { getStoreHouseDetail, getStoreHouseType } from '@/reducers/storehousemanage/storehouselist/actions';


const { TextArea } = Input;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
    // labelAlign: 'left',
};
class NoLogin extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        status: '',
        reason: '',
        province: [],
        city: [],
        county: [],
        oneCity: '',
        secondCity: '',
        threeCity: '',
        providerRequire: false,
        count: 1,
    }
    componentDidMount() {
        const { openModeParam } = this.props;
        this._getAreahandle(100000, 'province');
        this.props.getStoreHouseType();
        this.props.getStoreHouseDetail({ id: openModeParam });
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { count } = this.state;
        const { storehouseDetail: { provinceId = '', cityId = '' } } = nextProps;
        if (provinceId !== '' && cityId !== '' && count === 1) {
            this.setState({ count: count + 1 });
            this._getAreahandle(provinceId, 'city');
            this._getAreahandle(cityId, 'county');
        }
    }
    // 点击确认
    _handleOk = () => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                console.log('提交', fieldsValue);
                const { openModeParam, storehouseDetail } = this.props;
                var param = {
                    // accountInfo: {
                    //     accountName: fieldsValue.accountName,
                    //     loginPwd: fieldsValue.loginPwd,
                    // },
                    id: openModeParam,
                    addrDetail: fieldsValue.addrDetail,
                    areaId: fieldsValue.areaId,
                    cityId: fieldsValue.cityId,
                    contactName: fieldsValue.contactName,
                    contactTelephone: fieldsValue.contactTelephone,
                    provinceId: fieldsValue.provinceId,
                    // 供应商id(从供应商带过来)
                    supplierId: storehouseDetail.supplierId,
                    warehouseName: fieldsValue.warehouseName,
                    warehouseRemark: fieldsValue.warehouseRemark,
                    warehouseSort: fieldsValue.warehouseSort,
                    warehouseType: fieldsValue.warehouseType,
                };
                console.log('提交', param);
                http('/merchant/warehouse/updateInfo', param, 'POST')
                    .then(() => {
                        message.success('编辑成功!');
                        this.props.refresh();
                    }).catch((reject) => {
                        message.error(reject.message);
                    });
                this.props.closeMode();
            }
        });

    };
    // 获取区域
    _getAreahandle = (parentId, type) => {
        http(`/merchant/area/listAreaByParentId/${parentId}`, {}, 'POST')
            .then((obj) => {
                var res = obj.result || [];
                if (type === 'province') {
                    this.setState({
                        province: res || [],
                    });
                } else if (type === 'city') {
                    this.setState({
                        city: res || [],
                    });
                } else if (type === 'county') {
                    this.setState({
                        county: res || [],
                    });
                }
            }).catch((reject) => {
                message.error(reject.message);
            });
    }
    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    handleProvinceChange = id => {
        this._getAreahandle(id, 'city');
        // this.setState({
        //     oneCity: id,
        // });
        this.props.form.setFieldsValue({
            cityId: '',
            areaId: '',
        });
    };

    onSecondCityChange = id => {
        this._getAreahandle(id, 'county');
        // this.setState({
        //     secondCity: id,
        // });
        this.props.form.setFieldsValue({
            areaId: '',
        });
    };
    // onThreeCityChange = id => {
    //     this.setState({
    //         threeCity: id,
    //     });
    // }
    // 仓库类型
    // onTypeChange = e => {
    //     var val = e.target.value;
    //     if (val === 2 || val === 5) {
    //         this.setState({
    //             providerRequire: true,
    //         });
    //     } else {
    //         this.setState({
    //             providerRequire: false,
    //         });
    //     }
    // }
    render() {
        const { visible, confirmLoading, title, province, city, county } = this.state;
        const { storehouseDetail = {}, storehouseType } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={800}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} className="create-form">
                        <Form.Item     
                            label={<span>排序</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('warehouseSort', {
                                initialValue: storehouseDetail.warehouseSort,
                                // rules: [{ required: true, message: '请输入' }],
                            })(
                                <InputNumber placeholder="请输入" step={1} style={{ width: '100%' }} />
                            )}
                        </Form.Item>
                        <Form.Item
                            label={<span>仓库名称</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('warehouseName', {
                                initialValue: storehouseDetail.warehouseName,
                                rules: [{ required: true, message: '请输入' }],
                            })(
                                <Input placeholder="请输入" />
                            )}
                        </Form.Item>
                        <Form.Item
                            label={<span>管理员账户</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('loginName', {
                                initialValue: storehouseDetail.loginName,
                                rules: [{ required: true, message: '请输入' }],
                            })(
                                <Input placeholder="请输入" disabled />
                            )}
                        </Form.Item>
                        {/* <Form.Item
                            colon
                            htmlFor='accountName'
                            label={<span>管理员账号</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('accountName', {
                                rules: [{ required: true, message: '请输入' }],
                            })(
                                <Input placeholder="请输入" />
                            )}
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='loginPwd'
                            label={<span>账号密码</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('loginPwd', {
                                rules: [{ required: true, message: '请输入' }],
                            })(
                                <Input.Password placeholder="请输入" />
                            )}
                        </Form.Item> */}
                        <Form.Item
                            label={<span>仓库类型</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('warehouseType', {
                                initialValue: storehouseDetail.warehouseType && storehouseDetail.warehouseType.code,
                                rules: [{ required: true, message: '请选择' }],
                            })(
                                <Radio.Group disabled>
                                    {storehouseType.map(i => {
                                        return <Radio value={i.code} key={i.code}>{i.value}</Radio>;
                                    })}
                                </Radio.Group>
                            )}
                        </Form.Item>
                        {/* <Form.Item
                            colon
                            htmlFor='supplierId'
                            label={<span>供应商</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('supplierId', {
                                rules: [{ required: this.state.providerRequire, message: '请选择' }],
                            })(
                                <Select>
                                    <Option value="0">0</Option>
                                </Select>
                            )}
                        </Form.Item> */}
                        <Form.Item
                            label={<span>发货地址</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('provinceId', {
                                initialValue: storehouseDetail.provinceId,
                                rules: [{ required: true, message: '请选择' }],
                            })(
                                <Select
                                    showSearch
                                    style={{ width: 150, marginRight: 19 }}
                                    onChange={this.handleProvinceChange}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {province.map(province => (
                                        <Option value={province.id} key={province.id}>{province.areaName}</Option>
                                    ))}
                                </Select>
                            )}
                            {getFieldDecorator('cityId', {
                                initialValue: storehouseDetail.cityId,
                                rules: [{ required: true, message: '请选择' }],
                            })(
                                <Select
                                    style={{ width: 150, marginRight: 20 }}
                                    showSearch
                                    onChange={this.onSecondCityChange}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {city.map(city => (
                                        <Option key={city.id} value={city.id}>{city.areaName}</Option>
                                    ))}
                                </Select>
                            )}
                            {getFieldDecorator('areaId', {
                                initialValue: storehouseDetail.areaId,
                                rules: [{ required: true, message: '请选择' }],
                            })(
                                <Select
                                    style={{ width: 150 }}
                                    showSearch
                                    onChange={this.onThreeCityChange}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {county.map(county => (
                                        <Option key={county.id} value={county.id}>{county.areaName}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item
                            label={<span>详细地址</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('addrDetail', {
                                initialValue: storehouseDetail.addrDetail,
                                rules: [{ required: true, message: '请输入' }],
                            })(
                                <Input
                                    placeholder="请输入"
                                    prefix={<Icon type="environment" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            label={<span>联系人</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('contactName', {
                                initialValue: storehouseDetail.contactName,
                                rules: [{ required: true, message: '请输入' }],
                            })(
                                <Input placeholder="请输入" />
                            )}
                        </Form.Item>
                        <Form.Item
                            label={<span>联系电话</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('contactTelephone', {
                                initialValue: storehouseDetail.contactTelephone,
                                rules: [{ required: true, message: '请输入' }],
                            })(
                                <Input placeholder="请输入" />
                            )}
                        </Form.Item>
                        <Form.Item
                            label={<span>备注（可选）</span>}
                        >
                            {getFieldDecorator('warehouseRemark', {
                                initialValue: storehouseDetail.warehouseRemark,
                                // rules: [{ required: true, message: '请输入策略名称' }],
                            })(
                                <TextArea rows={4} />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

NoLogin.propTypes = {
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    getListAreaByParentId: PropTypes.func, // 请求地区信息
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    getStoreHouseDetail: PropTypes.func,
    storehouseDetail: PropTypes.object,
    openModeParam: PropTypes.number,
    refresh: PropTypes.func,
    getStoreHouseType: PropTypes.func,
    storehouseType: PropTypes.array
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(NoLogin);// 校验
const mapStateToProps = (state) => ({
    storehouseType: state.storehouselist.storehouseType,
    storehouseDetail: state.storehouselist.storehouseDetail
});
export default connect(mapStateToProps, { getStoreHouseDetail, getStoreHouseType })(ForgotProducDetails);