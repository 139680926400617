import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Switch, Icon, message, Tooltip } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import copy from 'copy-to-clipboard';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const columns = (TableAction, actionClickHandle) => [
    {
        title: '活动名称',
        dataIndex: 'activityTitle',
        key: 'activityTitle',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '活动类型',
        dataIndex: 'activityType',
        key: 'activityType',
        width: '10%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '活动时间',
        dataIndex: 'enrollment',
        key: 'enrollment',
        align: 'center',
        width: '20%',
        render: (text, record) => (TableCellTime(text, 'signTime', actionClickHandle, record))
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '6%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '10%',
        render: (id, record) => TableAction(id, record, actionClickHandle)
    }
];

const copyLink = (expressNo) => {
    if (copy(expressNo)) {
        message.info('复制成功');
    } else {
        message.info('复制失败');
    }
};

const TableAction = (id, record, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'detail')}>详情</div>
            {(record.state == '启用' || record.activityEndFlag) ? null : <div className={styles.item} onClick={() => actionClickHandle(id, 'edit', record)}>编辑</div>}
            {!record.activityEndFlag && <div className={styles.item} onClick={() => actionClickHandle(id, 'stateType', record)}>{record.state == '启用' ? '禁用' : '启用'}</div>}
            {/* <div className={styles.item} onClick={() => actionClickHandle(id, 'link',record)}>链接</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'data')}>数据</div> */}
            {record.state == '启用' && <div className={styles.item} onClick={() => copyLink(record.activityUrl)}>复制链接</div>}
            {record.activityTypeCode == 'FREE_ACTIVITY' && <div className={styles.item} onClick={() => actionClickHandle(id, 'listing')}>服务清单</div>}
        </div >
    );
};

// 表格项时间内容为文本弹框  
const TableCellTime = (text, parame, actionClickHandle, record) => {
    if (record.activityEndFlag) {
        return <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}> {text}</div>;
    } else {
        return (
            <div onClick={() => actionClickHandle(text, parame, record)} className={styles.TableCellTime} style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
                <Icon type="edit" style={{ fontSize: '18px', marginLeft: '2%' }} />
            </div>
        );
    }
};


class TypeTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data.map(item => {
            item.enrollment = `${item.startTime ? item.startTime : ''}—${item.endTime ? item.endTime : ''}`;//活动开始
            return item;
        });
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }

    render() {
        const { renderData, pagiNation, paginationChange, } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={columns(TableAction, this._actionHandle)}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={pagiNation.resultTotal}
                    pageSize={pagiNation.pageSize}
                    pageNum={pagiNation.pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.array, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    pagiNation: PropTypes.object, // 分页数据
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TypeTable;