import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

//供应商合同审核列表
export const getContractAuditList = function* () {
    yield takeEvery(T.GET_CONTRACTAUDIT_LIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/contract/listContract', action.payload, 'POST');
            yield put({ type: T.SET_CONTRACTAUDIT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//合同状态下拉
export const getContractStateSele = function* () {
    yield takeEvery(T.GET_CONTRACTSTATE_SELE, function* requestData(action) {
        try {
            let result = yield http('/enum/ContractAuditTypeEnum', action.payload, 'POST');
            yield put({ type: T.SET_CONTRACTSTATE_SELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//供应商下拉
export const getSupplierSele = function* () {
    yield takeEvery(T.GET_SUPPLIER_SELE, function* requestData(action) {
        try {
            let result = yield http('/merchant/supplier/listSuppliseIdAndName', action.payload, 'GET');
            yield put({ type: T.SET_SUPPLIER_SELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//获取供应商审核详情
export const getContractAuditDetail = function* () {
    yield takeEvery(T.GET_CONTRACTAUDIT_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/merchant/contract/getContract/'+action.payload.id, {}, 'GET');
            yield put({ type: T.SET_CONTRACTAUDIT_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};



