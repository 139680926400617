/**
 * 带标题的数字输入框组件
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { InputNumber } from 'antd';

class InputNumberModule extends React.Component {

    _onChange = (value) => {
        const { bindThis, bindName, onChange } = this.props;
        if (onChange) {
            onChange(value);
        }
        if (bindThis && bindName) {
            if (typeof bindThis === 'function') {
                bindThis(value);
            } else {
                bindThis.setState({ [bindName]: value });
            }
        }
    }

    render() {
        const { label, style, inputStyle, isRequired, bindThis, bindName, ...otherProps } = this.props;
        return (
            <div style={style} className={styles.container}>
                <span className={styles.star} style={isRequired ? { display: 'block' } : { display: 'none' }}>*</span>
                <div className={styles.label}>{label}</div>
                <div className={styles.inputbox}>
                    <InputNumber
                        {...otherProps}
                        style={inputStyle}
                        className={styles.input}
                        onChange={this._onChange}
                    />
                </div>
            </div>
        );
    }
}

InputNumberModule.defaultProps = {
    disabled: false,
    isRequired: false,
};

InputNumberModule.propTypes = {
    label: PropTypes.string, // 标题名称
    style: PropTypes.object, // 总样式
    inputStyle: PropTypes.object, // 输入框样式
    isRequired: PropTypes.bool,
    bindThis: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object
    ]), // 父级this
    bindName: PropTypes.string, // 双向绑定的属性名称
    onChange: PropTypes.func, // 输入框内容变化时的回调函数
};

export default InputNumberModule;