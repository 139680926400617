import * as supplierlist from './supplierlist/saga';
import * as suppliersettlement from './suppliersettlement/saga';
import * as invoicelist from './invoicelist/saga';
import * as invoicemanage from './invoicemanage/saga';
import * as platformreconciliation from './platformreconciliation/saga';
import * as walletreconciliation from './walletreconciliation/saga';
import * as warehousestatement from './warehousestatement/saga';
import * as collecttrade from './collecttrade/saga';
import * as collectsettle from './collectsettle/saga';
import * as collectpay from './collectpay/saga';
import * as paymanage from './paymanage/saga';
import * as account from './account/saga';
import purchasecontract from './purchasecontract/model';
import taxdeclaration from './taxdeclaration/model';
import billAuery from './billquery/model';
import reconciliationdata from './reconciliationdata/model';
import merchantwallet from './merchantwallet/model';
import dailystatement2 from './dailystatement2/model';
import previewcollectionmanage from './previewcollectionmanage/model';
export default [
    ...Object.values(supplierlist),
    ...Object.values(suppliersettlement),
    ...Object.values(invoicelist),
    ...Object.values(invoicemanage),
    ...Object.values(platformreconciliation),
    ...Object.values(walletreconciliation),
    ...Object.values(warehousestatement),
    ...Object.values(collecttrade),
    ...Object.values(collectsettle),
    ...Object.values(collectpay),
    ...Object.values(paymanage),
    ...Object.values(account),
    ...Object.values(purchasecontract.sagas),
    ...Object.values(taxdeclaration.sagas),
    ...Object.values(billAuery.sagas),
    // 对账数据
    ...Object.values(reconciliationdata.sagas),
    ...Object.values(merchantwallet.sagas),
    ...Object.values(dailystatement2.sagas),
    ...Object.values(previewcollectionmanage.sagas)
];

