import { upload } from 'qiniu-js';
import config from './config';

export default function qiniuUpload(file, options={}){
    let uploadType = options.uploadType || 'image';
    let fileName = file.name;
    return config[uploadType].getToken({ fileName }).then(result=>{
        const observable = upload(file, fileName, result.result, {}, {});
        let subscription = observable.subscribe({
            next(res){
                // console.log(res);
            },
            error(res) {
                options.error(res);
            },
            complete(res) {
                config[uploadType].getFile({ key: res.key, fileName }).then((fileRes) => {
                    options.complete({ fileUrl: fileRes.result });
                }).catch(err=>{
                    options.error(err);
                });
            }
        });
        return { observable, subscription };
    });
}