/**
 * 审核管理  Saga集合
 */
import {
    getSupplierAuditList,
    getSupplierAuditSele,
    getSupplierAuditDetail,
} from './supplierAudit/saga';
import {
    getContractAuditList,
    getContractStateSele,
    getSupplierSele,
    getContractAuditDetail
} from './contractAudit/saga';
import {
    getBalanceAuditList,
    getBalanceAuditBusinessSele,
    getBalanceAuditDetail,
    getBalanceAuditStateSele,
    getBalanceAuditLabelSele
} from './balanceAudit/saga';
import {
    getActivityGoods,
    getActivityType,
    getActivityStatus,
    getActivityDetail
} from './activityProduct/saga';
import { getPurchasingList, getPurchasingDetail, getPurchasingAuditInfo } from './purchasing/saga';
import activityProductModel from './activityProduct/model';

export default [
    //供应商
    getSupplierAuditList,
    getSupplierAuditSele,
    getSupplierAuditDetail,

    //供应商合同
    getContractAuditList,
    getContractStateSele,
    getSupplierSele,
    getContractAuditDetail,

    //供应商结算价
    getBalanceAuditList,
    getBalanceAuditBusinessSele,
    getBalanceAuditDetail,
    getBalanceAuditStateSele,
    getBalanceAuditLabelSele,
    //活动商品审核
    getActivityGoods,
    getActivityType,
    getActivityStatus,
    getActivityDetail,
    ...Object.values(activityProductModel.sagas),

    //集采活动审核
    getPurchasingList,
    getPurchasingDetail,
    getPurchasingAuditInfo
];