import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { XTabNav } from '@/components/xqxc_ui';
import model from '@/reducers/beanshopmanage/beanshop/model';
import { keepThirdNavBreadcrumb } from '@/reducers/home/actions';
import Budget from './budget';//收支明细
import Settlement from './settlement';//结算
import Frozen from './frozen';//冻结

class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '收支明细' },
            { id: 2, label: '结算明细' },
            { id: 3, label: '冻结明细' },
        ],
        curTabID: 1,  //
    }

    componentDidMount() {
         // this.props.getStarvalueSummary();
         this.props.keepThirdNavBreadcrumb(); // 面包屑导航保留为三级
         this.props.getStarvalueInOutSummary();
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        switch (this.state.curTabID) {
            case 1:
                return <Budget history={this.props.history} curTabID={this.state.curTabID} />;
            case 2:
                return <Settlement history={this.props.history} curTabID={this.state.curTabID} />;
            case 3:
                return <Frozen history={this.props.history} curTabID={this.state.curTabID} />;
            default:
                return <Budget history={this.props.history} curTabID={this.state.curTabID} />;
        }
    }

    render() {
        const { StarvalueSummary, StarvalueInOutSummary } = this.props;
        return (
            <div className={styles.flexBoxContainer}>
             <div className={styles.titles}>
             <span className={styles.titleStyle}>总收入</span>
             <span className={styles.titleStyle}>总支出</span>
              <span className={styles.titleStyle}>待结算</span>
             <span className={styles.titleStyle}>冻结信用值</span>
            </div>
            <div>
             <span className={styles.titleStyles} style={{ color: '#03A613', verticalAlign: 'top', fontSize: 24, fontWeight: 'bold' }}>
            {StarvalueInOutSummary.totalIncomeDesc}
             </span>
             <span className={styles.titleStyles} style={{ color: '#EC808D', verticalAlign: 'top', fontSize: 24, fontWeight: 'bold' }}>
               {StarvalueInOutSummary.totalExpendDesc}
             </span>
              <span className={styles.titleStyles} style={{ color: '#252525', verticalAlign: 'top', fontSize: 24, fontWeight: 'bold' }}>
                 {StarvalueInOutSummary.totalUnsettleDesc} 
              </span>
             <span className={styles.titleStyles} style={{ color: '#252525', verticalAlign: 'top', fontSize: 24, fontWeight: 'bold' }}>
               {StarvalueInOutSummary.totalLockDesc} 
             </span>
            </div>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={styles.line} style={{ marginBottom: 10 }}></div>
                <div className={styles.flexBoxContainer}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 跳转其它路由页面
    keepThirdNavBreadcrumb: PropTypes.func,
    getStarvalueSummary: PropTypes.func,
    StarvalueSummary: PropTypes.object,
    getStarvalueInOutSummary: PropTypes.func,
    StarvalueInOutSummary: PropTypes.object,
};

const mapStateToProps = (state) => {
    return ({
        StarvalueSummary: state.beanshop.StarvalueSummary,
        StarvalueInOutSummary: state.beanshop.StarvalueInOutSummary,
    });
};
export default connect(mapStateToProps, { keepThirdNavBreadcrumb, ...model.actions })(Main);