import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

// 获取登录时菜单
export const getMenuList = function* () {
    yield takeEvery(T.GET_MENUS_LIST, function* requestData() {
        try {
            let result = yield http('/admin/menu/listMenus', {}, 'POST');
            yield put({ type: T.SET_MENUS_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取注册财务信息类型枚举
export const getRegisterEnum = function* () {
    yield takeEvery(T.GET_REGISTER_ENUM, function* requestData() {
        try {
            let result = yield http('/enum/FinancialAccountTypeEnum', {}, 'POST');
            yield put({ type: T.SET_REGISTER_ENUM, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取认证类型枚举
export const getYunstAuthTypeEnum = function* () {
    yield takeEvery(T.GET_YUNSTAUTH_ENUM, function* requestData() {
        try {
            let result = yield http('/enum/YunstAuthTypeEnum', {}, 'POST');
            yield put({ type: T.SET_YUNSTAUTH_ENUM, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取银行卡列表
export const getBankNameList = function* () {
    yield takeEvery(T.GET_BANKNAME_LIST, function* requestData() {
        try {
            let result = yield http('/merchant/info/yums/bankNameList', {}, 'POST');
            yield put({ type: T.SET_BANKNAME_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取银行的省列表（通联）
export const getBankProviceList = function* () {
    yield takeEvery(T.GET_BANKPROVICE_LIST, function* requestData() {
        try {
            let result = yield http('/merchant/info/yums/listProvice', {}, 'POST');
            yield put({ type: T.SET_BANKPROVICE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取银行的地区列表（通联）
export const getBankAreaList = function* () {
    yield takeEvery(T.GET_BANKAREA_LIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/info/yums/listBankAreaCode', action.payload, 'POST');
            yield put({ type: T.SET_BANKAREA_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};