// 供应商列表
export const GET_SUPPLIERLIST_TABLESOURCE = 'SUPPLIERLISTA/GET_SUPPLIERLIST_TABLESOURCE';
export const SET_SUPPLIERLIST_TABLESOURCE = 'SUPPLIERLISTA/SET_SUPPLIERLIST_TABLESOURCE';

// 店铺类型
export const GET_SHOPTYPES = 'SUPPLIERLISTA/GET_SHOPTYPES';
export const SET_SHOPTYPES = 'SUPPLIERLISTA/SET_SHOPTYPES';

// 仓库类型
export const GET_WAREHOUSE_TYPE = 'SUPPLIERLISTA/GET_WAREHOUSE_TYPE';
export const SET_WAREHOUSE_TYPE = 'SUPPLIERLISTA/SET_WAREHOUSE_TYPE';

// 获取地域：省市区
export const GET_ACCOUNT_AREA = 'SUPPLIERLISTA/GET_ACCOUNT_AREA';
export const SET_PROVINCE = 'SUPPLIERLISTA/SET_PROVINCE';
export const SET_CITY = 'SUPPLIERLISTA/SET_CITY';
export const SET_STREET = 'SUPPLIERLISTA/SET_STREET';

// 经营品类
export const GET_GOODSTYPES = 'SUPPLIERLISTA/GET_GOODSTYPES';
export const SET_GOODSTYPES = 'SUPPLIERLISTA/SET_GOODSTYPES';

// 开票单位下拉列
export const GET_INVOICEL_SELECT = 'SUPPLIERLISTA/GET_INVOICEL_SELECT';
export const SET_INVOICEL_SELECT = 'SUPPLIERLISTA/SET_INVOICEL_SELECT';

// 服务商下拉列
export const GET_SUPPLIER_SELECT = 'SUPPLIERLISTA/GET_SUPPLIER_SELECT';
export const SET_SUPPLIER_SELECT = 'SUPPLIERLISTA/SET_SUPPLIER_SELECT';


//实体加盟中运营中心、区域、数据依据地址的市，区参数
export const GET_RADIOTYPE_DATA = 'MERCHANTLIST/GET_RADIOTYPE_DATA';
export const SET_RADIOTYPE_DATA = 'MERCHANTLIST/SET_RADIOTYPE_DATA';