/**
 * 商品管理 - 费率
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';


export const getProductRateListData = function* () {
    yield takeEvery(T.GET_PRODUCTRATE_LISTDATA, function* requestData(action) {
        try {
            let result = yield http('/goods/goods/findRateGoodsList', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCTRATE_LISTDATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductRateStatus = function* () {
    yield takeEvery(T.GET_PRODUCTRATE_STATUS, function* requestData(action) {
        try {
            let result = yield http('/enum/GoodsStateEnum', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCTRATE_STATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductRateBusinessType = function* () {
    yield takeEvery(T.GET_PRODUCTRATE_BUSINESSTYPE, function* requestData(action) {
        try {
            let result = yield http('/enum/GoodsTypeEnum', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCTRATE_BUSINESSTYPE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getProductRateLabelSele = function* () {
    yield takeEvery(T.GET_PRODUCTRATE_LABELSELE, function* requestData(action) {
        try {
            let result = yield http('/goods/tag/listTagItem', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCTRATE_LABELSELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


export const getProductRateDetail = function* () {
    yield takeEvery(T.GET_PRODUCTRATE_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/goods/goods/findGoodsRateInfo', action.payload, 'POST');
            yield put({ type: T.SET_PRODUCTRATE_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getActiveRateDetail = function* () {
    yield takeEvery(T.GET_ACTIVERATE_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/goods/goods/findGoodsOtherRate', action.payload, 'POST');
            yield put({ type: T.SET_ACTIVERATE_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getConversionRate = function* () {
    yield takeEvery(T.GET_CONVERSION_RATE, function* requestData(action) {
        try {
            let result = yield http('/goods/goodsrate/getMarketServiceFeeExchangeStarValueRatio', action.payload, 'POST');
            yield put({ type: T.SET_CONVERSION_RATE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


