import * as S from './actiontypes';

/**
 * 仓库列表
 */
const plistInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const storehouseList = (state = plistInital, { type, payload }) => {
    switch (type) {
        case S.SET_STOREHOUSE_LIST:
            return payload.result;
        default:
            return state;
    }
};
// 仓库类型
export const storehouseType = (state = [], { type, payload }) => {
    switch (type) {
        case S.SET_STOREHOUSE_TYPE:
            return payload.result;
        default:
            return state;
    }
};
export const storehouseDetail = (state = {}, { type, payload }) => {
    switch (type) {
        case S.SET_STOREHOUSE_DETAIL:
            return payload.result;
        default:
            return state;
    }
};