import React from 'react';
import styles from './OrderTable.module.scss';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import PropTypes from 'prop-types';

class OrderTable extends React.Component {

    state = {
        selectedRowKeys: [],
    };

    static getDerivedStateFromProps(props, state) {
        if (props.selectedRowKeys !== state.selectedRowKeys) {
            return {
                selectedRowKeys: props.selectedRowKeys
            };
        }
        return null;
    }

    _takeColumns = () => ([
        {
            title: '订单号',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员账号',
            dataIndex: 'accountName',
            key: 'accountName',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: '5%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '付款时间',
            dataIndex: 'payTime',
            key: 'payTime',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '收货人姓名',
            dataIndex: 'consigneeName',
            key: 'consigneeName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '联系电话',
            dataIndex: 'tel',
            key: 'tel',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '收货地址',
            dataIndex: 'addrDetail',
            key: 'addrDetail',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '买家留言',
            dataIndex: 'remark',
            key: 'remark',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '卖家留言',
            dataIndex: 'sellerRemark',
            key: 'sellerRemark',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '分配情况',
            dataIndex: 'failType',
            key: 'failType',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'action',
            align: 'center',
            // width: '10%',
            render: (text, item) => this._renderActionCell(item.id, item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderActionCell = (key, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
                <div className={styles.item} onClick={() => tableAction(key, 'assign', record)}>重新分配</div>
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
        }
    };

    // 表格复选输入
    _rowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys) => {
                this.setState({ selectedRowKeys });
                this.props.selectedRowKeysChange(selectedRowKeys);
            }
        };
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] }, () => {
            this.props.selectedRowKeysChange([]);
        });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                {/* <XTableSelectionCount selectedNum={this.state.selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection()}
                    rowKey='id'
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    orderTypeSelect: PropTypes.array,
    invoiceSelect: PropTypes.array,
    orderStateSelect: PropTypes.array,
    selectedRowKeys: PropTypes.array,
    selectedRowKeysChange: PropTypes.func
};

export default OrderTable;