import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import styles from './liststyle.module.scss';
import SuccessTable from './components/SuccessTable';
import model from '@/reducers/marketingmanage/memberlist/model';
import { message } from 'antd';

class InviteSuccess extends Component {

    static propTypes = {
        proceedsData: PropTypes.object,
        curTabID: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ])
    }

    static defaultProps = {
        successInviteData: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }
    }

    state = {
        startTime: '',
        endTime: ''
    }

    //props校验
    static propTypes = {
        history: PropTypes.object,//路由对象
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getSuccessInviteData: PropTypes.func,
        successInviteData: PropTypes.object,
    }

    //挂载后
    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, startTime, endTime } = this.state;
        if (startTime > endTime) {
            message.warning('开始时间不能小于结束时间');
            return;
        }
        this.searchCache = {
            startTime, endTime,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getSuccessInviteData(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ startTime: '', endTime: '' }, () => KeepAlive.saveResetFunc(this._searchHandle)); // 【第4步：将是否调用重置交给KeepAlive控制】
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => this._searchHandle('useCache'));
    }

    //马上邀请
    _toInvite = () => {
        this.props.history.push({
            pathname: '/home/marketingmanage/invitemember'
        });
    }

    //收益明细
    _tableOperate = (buyerId) => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '商户会员收益明细',
            path: '/home/marketingmanage/memberlist/returnsinfo'
        });
        history.push({
            pathname: '/home/marketingmanage/memberlist/returnsinfo/' + buyerId
        });
    }

    //渲染组件
    render() {
        const { history, successInviteData } = this.props;
        const { startTime, endTime } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XDatePicker
                            label='注册日期'
                            showTime={false}
                            value={startTime}
                            bindThis={this}
                            bindName='startTime'
                            defaultTime='00:00:00'
                            isFormat
                        />
                        <XDatePicker
                            showTime={false}
                            value={endTime}
                            bindThis={this}
                            bindName='endTime'
                            defaultTime='23:59:59'
                            isFormat
                        />
                        <XOKButton
                            style={{ marginLeft: 20 }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: 20 }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: 20 }}
                            label='马上邀请'
                            onClick={this._toInvite}
                        />
                    </div>
                    <SuccessTable
                        history={history}
                        tableOperate={this._tableOperate}
                        renderData={successInviteData}
                        paginationChange={this._paginationChange}

                    />
                </div>
            </KeepAlive>
        );
    }
}
//暴露组件
export default connect(state => ({
    successInviteData: state.memberlist.successInviteData
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(InviteSuccess);
