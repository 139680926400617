/**
 * 支取前，判断用户是否已经完善相关银联信息
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message, Alert } from 'antd';
import styles from './AddUnionInfo.module.scss';

class AddUnionInfo extends React.Component {

    state = {
        confirmLoading: false
    }

    // 点击确认
    _handleOk = () => {
        const { url } = this.props.modeParams;
        this.props.history.push(url);
        this.props.closeMode(false);
    }

    // 点击取消
    _handleCancel = () => {
        this.props.closeMode(false);
    }

    render() {
        const { confirmLoading } = this.state;
        const { title } = this.props.modeParams;

        return (
            <Modal
                width={700}
                centered
                title='温馨提示'
                visible={true}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='继续'
            >
                <div className={styles.container}>
                    <Alert
                        message={title}
                        description="点击“继续”前往办理"
                        type="info"
                        showIcon
                    />
                </div>
            </Modal>
        );
    }
}

AddUnionInfo.propTypes = {
    history: PropTypes.object.isRequired, // 路由跳转
    modeParams: PropTypes.object.isRequired, // 弹框入参
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default AddUnionInfo;