import React from 'react';
import moment from 'moment';
import styles from './OrderSearch.module.scss';
import PropTypes from 'prop-types';
import { XInput, XOKButton, XCancelButton, XSelect, XDatePicker, XInputNum } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import model from '@/reducers/ordermanage/wholesaleorder/model';
import { getProductOrderStatus, getProductOrderFlagEnum } from '@/reducers/ordermanage/productOrder/actions';
import { message } from 'antd';

// const orderStateSelect = [{ id: 1, name: '待付款' }, { id: 2, name: '待发款' }];
// const orderTypeSelect = [{ id: 1, name: '普通订单' }, { id: 2, name: '报税订单' }];
// const invoiceSelect = [{ id: 1, name: '是' }, { id: 2, name: '否' }];

class OrderSearch extends React.Component {

    state = {
        expandBoolean: false, // 是否展开
        telephone: '', // 会员手机
        orderCode: '', // 订单编号
        orderStartTime: null, // 下单时间开始日期
        orderEndTime: null, // 下单结束日期
        orderState: {}, // 订单状态
        invoice: {}, // 是否开票
        isAppointmentTimeOut: {}, // 是否超时
        payType: {},
    }

    componentDidMount() {
        this.props.getProductOrderStatus();
        this.props.getProductOrderFlagEnum();
         // 支付方式
         this.props.getPaymentTypeEnum();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState(newProps.searchState.param);
    }

    _renderOrderStateSelect = (orderState) => {
        const { productOrderStatus } = this.props;
        if (this.props.orderState == 1) {
            return (
                <XSelect
                    style={{ marginLeft: '20px', width: '248px' }}
                    selectStyle={{ width: '180px', marginLeft: 8 }}
                    label='订单状态'
                    placeholder='请选择订单状态'
                    renderData={productOrderStatus}
                    dataIndex='value'
                    keyIndex='code'
                    value={orderState.value}
                    showSearch={true}
                    bindThis={this}
                    bindName='orderState'
                />
            );
        } else {
            return null;
        }
    }
    // 展开或收缩搜索条件
    _expandChangeHandle = (expandBoolean) => {
        this.setState({ expandBoolean });
    }
    // 查询
    _searchHandle = () => {
        const { payType, isAppointmentTimeOut, telephone, orderCode, orderState, invoice, orderStartTime, orderEndTime } = this.state;
        this.searchCache = {
            telephone,
            orderCode,
            orderState,
            invoice,
            payType,
            orderStartTime,
            orderEndTime,
            isAppointmentTimeOut,
        };
        this.props.searchOrder({
            accountTel: telephone, payTimeF: orderStartTime, payTimeT: orderEndTime, orderId: orderCode,
            isInvoice: invoice.code ? invoice.code : '',
            isAppointmentTimeOut: isAppointmentTimeOut.code ? isAppointmentTimeOut.code : '',
            statusList: orderState.code ? [orderState.code] : [],
            payType: payType.code,
        }, this.searchCache);
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            telephone: '',
            orderCode: '',
            orderStartTime: null,
            orderEndTime: null,
            orderState: {},
            invoice: {},
            payType: {},
            pageNum: 1,
            pageSize: 50,
            isAppointmentTimeOut: {},
        });
        this.searchCache = {
            telephone: '',
            orderCode: '',
            orderStartTime: null,
            orderEndTime: null,
            orderState: {},
            invoice: {},
            payType: {},
            pageNum: 1,
            pageSize: 50,
            isAppointmentTimeOut: {},
        };
        this.props.searchOrder({}, this.searchCache, true);
    }

    render() {
        const { payType, isAppointmentTimeOut, telephone, orderCode, orderStartTime, orderEndTime, orderState, invoice } = this.state;
        const { productOrderFlagEnum, paymentTypeEnum } = this.props;
        return (
            <div className={styles.container} style={{ marginTop: 10 }}>
                <div className={styles.row1}>
                    <XInputNum
                        style={{ width: '248px' }}
                        inputStyle={{ width: '180px' }}
                        label='订单编号'
                        placeholder='请输入订单编号'
                        value={orderCode}
                        bindThis={this}
                        bindName='orderCode'
                        min={0}
                        precision={0}
                        maxLength={16}
                    />
                    <XInput
                        style={{ width: '248px', marginLeft: '20px' }}
                        inputStyle={{ width: '180px' }}
                        label='会员手机'
                        placeholder='请输入手机号码'
                        value={telephone}
                        bindThis={this}
                        bindName='telephone'
                    />

                    {this._renderOrderStateSelect(orderState)}
                    <XSelect
                        style={{ width: '248px', marginLeft: '20px' }}
                        selectStyle={{ width: '180px' }}
                        label='是否超时'
                        placeholder='请选择是否超时'
                        renderData={productOrderFlagEnum}
                        dataIndex='value'
                        keyIndex='code'
                        value={isAppointmentTimeOut.value}
                        showSearch={true}
                        bindThis={this}
                        bindName='isAppointmentTimeOut'
                    />

                    <XSelect
                        style={{ width: '248px', marginLeft: '20px' }}
                        selectStyle={{ width: '180px' }}
                        label='是否开票'
                        placeholder='请选择是否开票'
                        renderData={productOrderFlagEnum}
                        dataIndex='value'
                        keyIndex='code'
                        value={invoice.value}
                        showSearch={true}
                        bindThis={this}
                        bindName='invoice'
                    />
                </div>
                <div className={styles.row1}>
                    <XSelect
                        style={{ marginRight: '20px', width: '218px' }}
                        selectStyle={{ width: '150px' }}
                        label='支付方式'
                        placeholder='请选择支付方式'
                        renderData={paymentTypeEnum}
                        dataIndex='value'
                        keyIndex='code'
                        value={payType.value}
                        showSearch={true}
                        bindThis={this}
                        bindName='payType'
                    />
                    <XDatePicker
                        style={{ width: '264px' }}
                        label='付款时间'
                        value={orderStartTime}
                        bindThis={this}
                        bindName='orderStartTime'
                        isFormat
                    />
                    <div className={styles.timeLine} />
                    <XDatePicker
                        style={{ marginRight: 20 }}
                        label=''
                        value={orderEndTime}
                        bindThis={this}
                        bindName='orderEndTime'
                        isFormat
                    />

                    <XOKButton
                        label='查询'
                        onClick={this._searchHandle}
                    />
                    <XCancelButton
                        style={{ marginLeft: 20 }}
                        label='重置'
                        onClick={this._resetHandle}
                    />
                </div>
            </div>
        );
    }
}

OrderSearch.propTypes = {
    orderState: PropTypes.number,
    searchOrder: PropTypes.func,
    getProductOrderStatus: PropTypes.func,
    getProductOrderFlagEnum: PropTypes.func,
    getMerchantList: PropTypes.func,
    productOrderStatus: PropTypes.array,
    productOrderFlagEnum: PropTypes.array,
    merchantList: PropTypes.object,
    getPaymentTypeEnum: PropTypes.func,
    paymentTypeEnum: PropTypes.array
};

const mapStateToProps = (state) => ({
    productOrderStatus: state.productOrder.productOrderStatus,
    productOrderFlagEnum: state.productOrder.productOrderFlagEnum,
    merchantList: state.Transcation.merchantList,
    paymentTypeEnum: state.wholesaleOrder.paymentTypeEnum,
});

export default connect(mapStateToProps, { ...model.actions, getProductOrderStatus, getProductOrderFlagEnum })(OrderSearch);