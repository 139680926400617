export const GET_INVOICELIST = 'SUPPLIERSETTLEMENT/GET_INVOICELIST';
export const SET_INVOICELIST = 'INVOICELIST/SET_INVOICELIST';

// 发货清单
export const GET_DELIVERLIST_TABLESOURCE = 'SUPPLIERSETTLEMENT/GET_DELIVERLIST_TABLESOURCE';
export const SET_DELIVERLIST_TABLESOURCE = 'SUPPLIERSETTLEMENT/SET_DELIVERLIST_TABLESOURCE';

// 对账单
export const GET_VALIDATESTATEMENT_TABLESOURCE = 'SUPPLIERSETTLEMENT/GET_VALIDATESTATEMENT_TABLESOURCE';
export const SET_VALIDATESTATEMENT_TABLESOURCE = 'SUPPLIERSETTLEMENT/SET_VALIDATESTATEMENT_TABLESOURCE';

// 对账详情
export const GET_VALIDATESTATEMENT_DETAIL = 'SUPPLIERSETTLEMENT/GET_VALIDATESTATEMENT_DETAIL';
export const SET_VALIDATESTATEMENT_DETAIL = 'SUPPLIERSETTLEMENT/SET_VALIDATESTATEMENT_DETAIL';

// 对账明细列表
export const GET_VALIDATESTATEMENT_DETAIL_TABLESOURCE = 'SUPPLIERSETTLEMENT/GET_VALIDATESTATEMENT_DETAIL_TABLESOURCE';
export const SET_VALIDATESTATEMENT_DETAIL_TABLESOURCE = 'SUPPLIERSETTLEMENT/SET_VALIDATESTATEMENT_DETAIL_TABLESOURCE';

// 供应商列表
export const GET_SUPPLISER_SELECTSOURCE = 'SUPPLIERSETTLEMENT/GET_SUPPLISER_SELECTSOURCE';
export const SET_SUPPLISER_SELECTSOURCE = 'SUPPLIERSETTLEMENT/SET_SUPPLISER_SELECTSOURCE';

// 合同管理
export const GET_CONTRACTMANAGE_TABLESOURCE = 'SUPPLIERSETTLEMENT/GET_CONTRACTMANAGE_TABLESOURCE';
export const SET_CONTRACTMANAGE_TABLESOURCE = 'SUPPLIERSETTLEMENT/SET_CONTRACTMANAGE_TABLESOURCE';

// 合同详情
export const GET_CONTRACTDETAIL = 'SUPPLIERSETTLEMENT/GET_CONTRACTDETAIL';
export const SET_CONTRACTDETAIL = 'SUPPLIERSETTLEMENT/SET_CONTRACTDETAIL';

// 合同状态
export const GET_CONTRACTSTATES = 'SUPPLIERSETTLEMENT/GET_CONTRACTSTATES';
export const SET_CONTRACTSTATES = 'SUPPLIERSETTLEMENT/SET_CONTRACTSTATES';