import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { Modal, Alert, message } from 'antd';
import styles from './Draw.module.scss';
import numSwitch from '@/util/numSwitch';
import { regFenToYuan } from '@/util/money';
import { XInfoLabel, XInput, XInputPassword } from '@/components/xqxc_ui';
import model from '@/reducers/storehousefinancialmanage/merchantwallet/model';
import md5 from 'js-md5';

const Draw = ({
    modeParams: { allAmount } = {},
    closeMode,
    getSaleProceedsDraw,
    saleProceedsDraw: {
        bankCardNo = '',
        estimateSettlementAmount = 0,
        serviceFee = 0,
        settlementInfo = '',
        taxAmount = 0,
        settlementAvailableAmount = 0,
        settlementAmount = 0
    } = {} }) => {

    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [remark, setRemark] = useState('');

    useEffect(() => {
        getSaleProceedsDraw({ settlementAmount: allAmount });
    }, []);

    console.log(settlementInfo);

    // 点击确认
    const submit = () => {
        if (!remark) {
            message.warning('请输入摘要');
            return;
        }
        if (!password) {
            message.warning('请输入交易密码');
            return;
        }
        setLoading(true);
        http('/wallet/memberSaleIncomeSettlementWithdraw', { password: md5(password).toUpperCase(), remark }, 'POST')
            .then((res = {}) => {
                if (res.code === 200) {
                    message.success('确认支取成功');
                    setLoading(false);
                    closeMode();
                }
            }).catch((res = {}) => {
                setLoading(false);
                message.error('确认支取失败，原因：' + res.message);
            });
    };
    return (
        <Modal
            width={780}
            centered
            title='商户会员销售收益结算'
            visible={true}
            confirmLoading={loading}
            onOk={submit}
            onCancel={() => closeMode(false)}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'
        >
            <div className={styles.container}>
                <Alert
                    message={settlementInfo}
                    type='info' />
                <XInfoLabel
                    style={{ marginTop: 20 }}
                    labelWith={100}
                    label='可结算金额：'
                    value={regFenToYuan(settlementAvailableAmount)} />
                <div className={styles.capital}>{numSwitch(regFenToYuan(settlementAvailableAmount))}</div>
                <XInfoLabel
                    style={{ marginTop: 20 }}
                    labelWith={100}
                    label='本次结算金额：'
                    value={regFenToYuan(settlementAmount)} />
                <div className={styles.capital}>{numSwitch(regFenToYuan(settlementAmount))}</div>
                <XInfoLabel
                    style={{ marginTop: 20 }}
                    labelWith={100}
                    label='个税金额：'
                    value={regFenToYuan(taxAmount)} />
                <div className={styles.capital}>{numSwitch(regFenToYuan(taxAmount))}</div>
                <XInfoLabel
                    style={{ marginTop: 20 }}
                    labelWith={100}
                    label='手续费：'
                    value={`${regFenToYuan(serviceFee)}元`} />
                <XInfoLabel
                    style={{ marginTop: 20 }}
                    labelWith={100}
                    label='预计到账金额：'
                    value={regFenToYuan(estimateSettlementAmount)} />
                <div className={styles.capital}>{numSwitch(regFenToYuan(estimateSettlementAmount))}</div>
                <XInfoLabel
                    style={{ marginTop: 20 }}
                    labelWith={100}
                    label='银行卡号：'
                    value={bankCardNo} />
                <XInput
                    style={{ marginTop: 20 }}
                    label='摘要：'
                    bindThis={setRemark}
                    bindName='remark'
                    value={remark}
                    isRequired={true}
                />
                <XInputPassword
                    style={{ marginTop: 20 }}
                    label='交易密码：'
                    bindThis={setPassword}
                    bindName='password'
                    value={password}
                    isRequired={true}
                />
            </div>
        </Modal>
    );
};
Draw.DefaultProps = {
    saleProceedsDraw: {}
};
Draw.propTypes = {
    modeParams: PropTypes.object.isRequired, // 弹框入参
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getSaleProceedsDraw: PropTypes.func,
    saleProceedsDraw: PropTypes.object
};
export default connect(state => ({
    saleProceedsDraw: state.merchantwallet.saleProceedsDraw,
}), { ...model.actions })(Draw);