import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';
//获取活动商品审核列表
export const getActivityGoods = function* () {
    yield takeEvery(T.GET_AUDIT_ACTIVITYGOODS, function* requestData(action) {
        try {
            let result = yield http('/marketing/activityMerchantInfo/listGoodsAudit', action.payload, 'POST');
            yield put({ type: T.SET_AUDIT_ACTIVITYGOODS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//获取活动类型
export const getActivityType = function* () {
    yield takeEvery(T.GET_AUDIT_ACTIVITYTYPE, function* requestData(action) {
        try {
            let result = yield http('/activity/info/selectActivityType', action.payload, 'POST');
            yield put({ type: T.SET_AUDIT_ACTIVITYTYPE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//获取活动状态
export const getActivityStatus = function* () {
    yield takeEvery(T.GET_AUDIT_ACTIVITYSTATUS, function* requestData(action) {
        try {
            let result = yield http('/activity/info/selectActivityState', action.payload, 'POST');
            yield put({ type: T.SET_AUDIT_ACTIVITYSTATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//获取活动详情
export const getActivityDetail = function* () {
    yield takeEvery(T.GET_AUDIT_ACTIVITYDETAIL, function* requestData(action) {
        try {
            let result = yield http('/marketing/activityMerchantInfo/auditFailReason', action.payload, 'POST');
            yield put({ type: T.SET_AUDIT_ACTIVITYDETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};




