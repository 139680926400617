/**
 * 待打款
 * */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { XInput, XOKButton, XCancelButton, XDatePicker, XSelect } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import Pwd from './modal/Pwd';
import RejectRemit from './modal/RejectRemit';
import { connect } from 'react-redux';
import { getReadyPayList, getPayChannelEnum } from '@/reducers/storehousefinancialmanage/collectpay/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';
import { isGY } from '@/assets/js/authType';
import { getLocalStorage } from '@/assets/js/storage';
import BigNumber from 'bignumber.js';

class ReadyPay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            servicerName: '', // 服务商名称
            applyStartDate: undefined, // 申请开始时间
            applyEndDate: undefined, // 申请结束时间
            selectedRowKeys: [], // 表格中复选框选中key数组
            gatherRemitReqs: [], // 表格中复选框选中对象数组
            curMode: '',
            rejectIDs: [], // 驳回的iD
            totalAmount: 0,
            merchantBalance: 0,
            payChannel: { code: 'ALI_PAY', value: '支付宝' },
            sourceType: {},
            sourceTypeList: [],
            levyItem: {},
            serviceList: []
        };
    }
    componentDidMount() {
        this.setState({ curMode: '', gatherRemitReqs: [], rejectIDs: [] });
        this._reqBalance();
        this.props.getPayChannelEnum();
        this.getSourceTypeList();
    }

    getSourceTypeList = () => {
        http('/enum/GatherWithdrawSourceEnum').then(res => {
            this.setState({ sourceTypeList: res.result || [] });
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, isAnchor: 0, pageSize, pageNum };
        this.props.getReadyPayList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { servicerName, applyStartDate, applyEndDate, sourceType, pageNum, pageSize } = this.state;
        this.searchCache = {
            servicerName,
            applyStartDate,
            applyEndDate,
            sourceType: sourceType.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getReadyPayList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ servicerName: '', applyStartDate: undefined, applyEndDate: undefined, sourceType: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 批量付款
    _batchPay = () => {
        const { selectedRowKeys } = this.state;
        const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
        const { isFinancial } = userInfo;

        if (isGY() && isFinancial == '1') {
            if (selectedRowKeys.length > 0) {
                this.setState({ curMode: 'Pwd' });
                http('/settle/gatherWithdraw/calculateRemitAmount', { withdrawIds: selectedRowKeys }, 'POST').then((response) => {
                    if (response.status == 200) {
                        this.setState({ totalAmount: response.result.totalAmount });
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
            } else {
                message.warn('请选择要打款的项!');
            }
        } else {
            message.warn('非财务人员禁止打款操作!');
        }
    }

    // 批量驳回
    _batchReject = () => {
        const { selectedRowKeys } = this.state;
        const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
        const { isFinancial } = userInfo;

        if (isGY() && isFinancial == '1') {
            if (selectedRowKeys.length > 0) {
                this.setState({ curMode: 'Reject', rejectIDs: selectedRowKeys });
            } else {
                message.warn('请选择要驳回的项!');
            }
        } else {
            message.warn('非财务人员禁止驳回操作!');
        }
    }

    // 渲染弹框
    _renderMode = () => {
        switch (this.state.curMode) {
            case 'Pwd':
                return <Pwd
                    visible={true}
                    refsh={() => {
                        this.setState({ selectedRowKeys: [] }, () => {
                            this._searchHandle();
                        });
                    }}
                    gatherRemitReqs={this.state.gatherRemitReqs}
                    totalAmount={this.state.totalAmount}
                    closeMode={this._closeMode}
                />;
            case 'Reject':
                return <RejectRemit visible={true} rejectIDs={this.state.rejectIDs} closeMode={this._closeMode}
                    refsh={() => {
                        this.setState({ selectedRowKeys: [] }, () => {
                            this._searchHandle();
                        });
                    }}
                />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ curMode: '' });
    }

    // 表格操作
    _tableAction = (id, type) => {
        switch (type) {
            case 'Reject':
                this.setState({ curMode: 'Reject', rejectIDs: [id] });
                break;
        }
    }

    //请求余额
    _reqBalance = (tip) => {
        const { payChannel = {}, levyItem = {} } = this.state;
        if (payChannel.code === 'YOU_FU_PAY' && !levyItem.code) return message.warn('请选择身边云服务商');
        http('/collectMiningRefund/balance', { accessType: payChannel.code, levyId: levyItem.code }, 'POST')
            .then(res => {
                tip && message.success(res.message);
                this.setState({
                    merchantBalance: new BigNumber(res.result || 0).dividedBy(100).toFixed(2)
                });
            });
    }

    //支付通道改变
    _channelChange = e => {
        if (e.code === 'YOU_FU_PAY') {
            http('/settle/gatherWithdraw/getPayChannelMap', {}, 'POST').then(({ result = {} } = {}) => {
                let serviceList = [];
                const map = new Map(new Map(Object.entries(result)));
                Object.keys(result).forEach(i => {
                    serviceList.push({ code: i, value: map.get(i) });
                });
                this.setState({ serviceList });
            });
        } else { this.setState({ serviceList: [], levyItem: {} }); }
    }

    render() {
        const { servicerName, applyStartDate, applyEndDate, selectedRowKeys, gatherRemitReqs, payChannel, merchantBalance, sourceType, sourceTypeList, serviceList, levyItem } = this.state;
        const { readyPayList, payChannelEnum } = this.props;
        return (
            <KeepAlive id='readypay' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='服务商'
                            placeholder='请输入服务商'
                            bindThis={this}
                            bindName='servicerName'
                            value={servicerName}
                        />
                        <XDatePicker
                            style={{ marginLeft: '20px' }}
                            label='申请时间'
                            bindThis={this}
                            bindName='applyStartDate'
                            value={applyStartDate}
                            isFormat={true}
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            bindThis={this}
                            bindName='applyEndDate'
                            value={applyEndDate}
                            isFormat={true}
                        />
                        <XSelect
                            style={{ marginLeft: 20 }}
                            selectStyle={{ width: '200px' }}
                            label='申请来源'
                            placeholder='请选择'
                            renderData={sourceTypeList}
                            dataIndex='value'
                            keyIndex='code'
                            value={sourceType.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='sourceType'
                        />
                        <XOKButton
                            style={{ marginLeft: '30px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '100px' }} label='批量打款' onClick={this._batchPay} />
                        <XOKButton style={{ width: '100px', marginLeft: '20px' }} label='批量驳回' onClick={this._batchReject} />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '100px' }}
                            label='付款通道'
                            placeholder='请选择付款通道'
                            renderData={payChannelEnum || []}
                            dataIndex='value'
                            keyIndex='code'
                            value={payChannel.value}
                            bindThis={this}
                            bindName='payChannel'
                            onChange={this._channelChange}
                        />
                        {
                            payChannel.code === 'YOU_FU_PAY' && <XSelect
                                selectStyle={{ width: '120px' }}
                                placeholder='请选择'
                                renderData={serviceList}
                                dataIndex='value'
                                keyIndex='code'
                                value={levyItem.value}
                                bindThis={this}
                                bindName='levyItem'
                            />
                        }
                        <XOKButton
                            label='余额查询'
                            style={{ width: '90px', marginLeft: '20px', marginRight: '20px' }}
                            onClick={() => this._reqBalance(true)}
                        />
                        <span>当前余额：{merchantBalance}元</span>
                    </div>
                    <TableContent
                        renderData={readyPayList}
                        paginationChange={this._paginationChange}
                        selectedRowKeys={selectedRowKeys}
                        selectedRowKeysChange={(selectedRowKeys, rowItems) => {
                            let remitReqs = [];
                            if (selectedRowKeys.length > 0) {
                                for (let i = 0; i < selectedRowKeys.length; i++) {
                                    let remit = {};
                                    for (let m = 0; m < rowItems.length; m++) {
                                        if (rowItems[m].id == selectedRowKeys[i]) {
                                            remit.withdrawId = rowItems[m].id;
                                            remit.version = rowItems[m].version;
                                            break;
                                        }
                                    }
                                    if (!remit.withdrawId) {
                                        for (let n = 0; n < gatherRemitReqs.length; n++) {
                                            if (gatherRemitReqs[n].withdrawId == selectedRowKeys[i]) {
                                                remit = gatherRemitReqs[n];
                                                break;
                                            }
                                        }
                                    }
                                    if (remit.withdrawId) {
                                        remitReqs.push(remit);
                                    }
                                }
                            }
                            this.setState({
                                selectedRowKeys,
                                gatherRemitReqs: remitReqs
                            });
                        }}
                        tableAction={this._tableAction}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

ReadyPay.propTypes = {
    history: PropTypes.object,
    getReadyPayList: PropTypes.func,
    readyPayList: PropTypes.object,
    getPayChannelEnum: PropTypes.func,
    payChannelEnum: PropTypes.array
};
const mapStateToProps = (state) => ({
    readyPayList: state.collectpay.readyPayList,
    payChannelEnum: state.collectpay.payChannelEnum
});
export default connect(mapStateToProps, { getReadyPayList, getPayChannelEnum })(ReadyPay);