import { createModel } from '@/store/tools';

const dataSource = {
    dataList: [],
    pageNum: 1,
    pageSize: 30,
    resultTotal: 0
};

const model = {
    namespace: 'marketingmanage',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getSuccessInviteData': T('getSuccessInviteData'),
            'getFailInviteData': T('getFailInviteData'),
            'getSettleInfo': T('getSettleInfo'),
            'getSettleSubtotal': T('getSettleSubtotal'),
        },
        actions: {
            'getSuccessInviteData': A('getSuccessInviteData'),
            'getFailInviteData': A('getFailInviteData'),
            'getSettleInfo': A('getSettleInfo'),
            'getSettleSubtotal': A('getSettleSubtotal'),
        },
        sagas: {
            'getSuccessInviteData': S('getSuccessInviteData', '/merchantInvite/listSuccessMerchantInviteAccount'),
            'getFailInviteData': S('getFailInviteData', '/merchantInvite/listFailMerchantInviteAccount'),
            'getSettleInfo': S('getSettleInfo', '/settle/reconciliationSource/queryMerchantMemberInfo'),
            'getSettleSubtotal': S('getSettleSubtotal', '/settle/reconciliationSource/merchantSponsor'),
        },
        reducers: {
            'successInviteData': R('getSuccessInviteData', dataSource),
            'failInviteData': R('getFailInviteData', dataSource),
            'settleInfo': R('getSettleInfo', dataSource),
            'settleSubtotal': R('getSettleSubtotal', {}),
        }
    })
};
export default createModel(model);