/**
 * 商品费率编辑弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { Modal, message, InputNumber, Form, Select, Input, Checkbox } from 'antd';
import styles from './setRate.module.scss';
import { XTitle, XInput, XSelect } from '@/components/xqxc_ui';
import { getActiveRateDetail } from '@/reducers/productmanage/rate/actions';
import { getProductFreeSele } from '@/reducers/productmanage/list/actions';

class SetRate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
            goodsName: '',
            serviceRatio: '',
            showShow: false,
            showRatio: '',
            freeShow: false,
            starShow: false,
            starValue: '',
            isServiceRecommend: true,

            goodsMarketingList: [],
            activityObj: {
                id: '',
                activityTitle: ''
            },
        };

    }

    componentDidMount() {
        const { getActiveRateDetail, getProductFreeSele, data } = this.props;
        getActiveRateDetail(data.modeParams.goodsId);
        getProductFreeSele();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { activeRateDetail } = newProps;
        const isServiceRecommend = activeRateDetail.isServiceRecommend == 1 ? true : false;
        this.setState({ goodsName: activeRateDetail.goodsName, serviceRatio: activeRateDetail.serviceRatio, isServiceRecommend: isServiceRecommend });
        const initalList = (activeRateDetail.goodsMarketingList || []).map(item => {
            if (item.marketingCode === 'STAR') {
                this.setState({ starValue: item.starValue, starShow: true });
            }
            if (item.marketingCode === 'SHOW') {
                this.setState({ showRatio: item.showRatio, showShow: true });
            }
            if (item.marketingCode === 'FREE') {
                this.setState({
                    freeShow: true,
                    activityObj: {
                        id: item.activityId,
                        activityTitle: item.marketingName
                    }
                });
            }
            return item;
        });
        this.setState({goodsMarketingList:initalList});
    }

    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST').then(() => {
            message.success(winTxt);
            this.setState({
                visible: false,
            });
            this.props.closeMode(true);
        }).catch((reject) => {
            message.error(reject.message);
        });
    }

    // 点击确认
    _handleOk = () => {
        const { data } = this.props;
        const { goodsMarketingList, serviceRatio } = this.state;
        this._sendReq('/goods/goods/editGoodsOtherRate', { goodsId: data.modeParams.goodsId, goodsMarketingList, serviceRatio }, '修改活动费率成功。');
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }

    _formChange = (value, type) => {
        let goodsMarketingList = [...this.state.goodsMarketingList];
        switch (type) {
            case 'serviceRatio':
                console.log(value);
                this.setState({ serviceRatio: value });
                break;
            case 'showShow':
                console.log(value.target.checked);
                this.setState({ showShow: value.target.checked });
                if (!value.target.checked) {
                    this.setState({ goodsMarketingList: goodsMarketingList.filter(item => item.marketingCode !== 'SHOW') });
                }
                break;
            case 'showRatio':
                console.log(value);
                goodsMarketingList = goodsMarketingList.filter((item) => {
                    return item.marketingCode != 'SHOW' ? true : false;
                });
                goodsMarketingList.push(
                    {
                        marketingCode: 'SHOW',//商品营销编码
                        marketingName: '秀场活动',//营销名称
                        showRatio: value,//秀场推广比例
                    }
                );
                this.setState({ goodsMarketingList, showRatio: value });
                break;
            case 'starShow':
                console.log(value);
                this.setState({ starShow: value.target.checked });
                if (!value.target.checked) {
                    this.setState({ goodsMarketingList: goodsMarketingList.filter(item => item.marketingCode !== 'STAR') });
                }
                break;
            case 'starValue':
                console.log(value);
                goodsMarketingList = goodsMarketingList.filter((item) => {
                    return item.marketingCode != 'STAR' ? true : false;
                });
                goodsMarketingList.push(
                    {
                        marketingCode: 'STAR',//商品营销编码
                        marketingName: '人气值活动',//营销名称
                        starValue: value,//人气值
                    }
                );
                this.setState({ goodsMarketingList, starValue: value });
                break;
            case 'freeShow':
                console.log(value);
                this.setState({ freeShow: value.target.checked });
                if (!value.target.checked) {
                    this.setState({ goodsMarketingList: goodsMarketingList.filter(item => item.marketingCode !== 'FREE') });
                }
                break;
            case 'freeValue':
                console.log(value);
                goodsMarketingList = goodsMarketingList.filter((item) => {
                    return item.marketingCode != 'FREE' ? true : false;
                });
                goodsMarketingList.push(
                    {
                        marketingCode: 'FREE',//商品营销编码
                        marketingName: value.activityTitle,//营销名称
                        activityId: value.id,//免单活动id
                    }
                );
                this.setState({ goodsMarketingList, activityObj: value });
                break;
            // case 'platRate':
            //     if (/^(\d{1,9}(\.\d{1,1})?|100)$/.test(value)) {
            //         let platCost = value * salePrice;
            //         this.setState({ platRateState: 'success', platRate: value, platCost });
            //     } else {
            //         message.warn('请输入1-100的数字，支持一位小数！');
            //         this.setState({ platRateState: 'error' });
            //     }
            //     break;
            // case 'saleRate':
            //     if (/^(\d{1,9}(\.\d{1,1})?|100)$/.test(value)) {
            //         let agentCost = value * salePrice;
            //         this.setState({ saleRateState: 'success', agentRate: value, agentCost });
            //     } else {
            //         message.warn('请输入1-100的数字，支持一位小数！');
            //         this.setState({ saleRateState: 'error' });
            //     }
            //     break;
            // case 'pattern':
            //     if (value.rateModeCode === 'BASIS') {
            //         this.setState({ rateHide: true });
            //     } else {
            //         this.setState({ rateHide: false });
            //     }
            //     console.log(value);
            //     this.setState({ rateModeItem: value });
            //     break;
        }
    }

    render() {
        const { productFreeSele } = this.props;
        const { visible, confirmLoading, title, goodsName, serviceRatio, showShow, showRatio, starShow, starValue, freeShow, activityObj, isServiceRecommend } = this.state;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'>
                <div className={styles.wrapper}>
                    <div className={styles.row}>
                        <XInput
                            inputStyle={{ width: '300px' }}
                            disabled={true}
                            label='商品名称：：'
                            value={goodsName}
                        />
                    </div>
                    <div className={styles.row}>
                        <XInput
                            inputStyle={{ width: '260px' }}
                            label='心选推荐费（%）：'
                            disabled={!isServiceRecommend}
                            value={serviceRatio}
                            onChange={(value) => this._formChange(value, 'serviceRatio')}
                        ></XInput>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.rowBox}>
                            <div className={styles.checkBox}>
                                <Checkbox
                                    checked={showShow}
                                    onChange={value => this._formChange(value, 'showShow')}
                                >参与秀场</Checkbox>
                            </div>
                            <div className={styles.inputBox}>
                                <XInput
                                    label='秀场服务费比例（%）：'
                                    inputStyle={{ width: '140px' }}
                                    value={showRatio}
                                    disabled={!showShow}
                                    onChange={(value) => this._formChange(value, 'showRatio')}
                                ></XInput>
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.rowBox}>
                            <div className={styles.checkBox}>
                                <Checkbox
                                    checked={starShow}
                                    onChange={value => this._formChange(value, 'starShow')}
                                >参与人气值</Checkbox>
                            </div>
                            <div className={styles.inputBox}>
                                <XInput
                                    label='商品最高人气值：'
                                    inputStyle={{ width: '180px' }}
                                    disabled={!starShow}
                                    value={starValue}
                                    onChange={(value) => this._formChange(value, 'starValue')}
                                ></XInput>
                            </div>
                        </div>
                    </div>
                    {/* <div className={styles.row}>
                        <div className={styles.rowBox}>
                            <div className={styles.checkBox}>
                                <Checkbox
                                    checked={freeShow}
                                    onChange={value => this._formChange(value, 'freeShow')}
                                >参与免单</Checkbox>
                            </div>
                            <div className={styles.inputBox}>
                                <XSelect
                                    label='免费活动：'
                                    selectStyle={{ width: '210px' }}
                                    disabled={!freeShow}
                                    renderData={productFreeSele}
                                    onChange={(value) => this._formChange(value, 'freeValue')}
                                    dataIndex='activityTitle'
                                    keyIndex='id'
                                    value={activityObj.activityTitle}
                                />
                            </div>
                        </div>
                    </div> */}
                </div>
            </Modal>
        );
    }
}

SetRate.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    getActiveRateDetail: PropTypes.func,//获取详情
    getProductFreeSele: PropTypes.func,

    activeRateDetail: PropTypes.object,//详情
    productFreeSele: PropTypes.array
};

const mapStateToProps = (state) => ({
    activeRateDetail: state.productRate.activeRateDetail,
    productFreeSele: state.productListData.productFreeSele || [],
});

export default connect(mapStateToProps, { getActiveRateDetail, getProductFreeSele })(SetRate);
