import { createModel } from '@/store/tools';

const model = {
    namespace: 'handbook',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getHandbookInfo': T('getHandbookInfo'),
        },
        actions: {
            'getHandbookInfo': A('getHandbookInfo'),
        },
        sagas: {
            'getHandbookInfo': S('getHandbookInfo', '/merchant/cainiao/authorization', 'GET'),
        },
        reducers: {
            'handbookInfo': R('getHandbookInfo', {}),
        }
    })
};
export default createModel(model);