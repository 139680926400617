import React from 'react';
import styles from './TableData.module.scss';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
  {
    title: '合同号',
    dataIndex: 'id',
    key: 'id',
    width: 150,
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '合同名称',
    dataIndex: 'contractName',
    key: 'contractName',
    width: 150,
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '供应商',
    dataIndex: 'supplierName',
    key: 'supplierName',
    width: 150,
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '签订日期',
    dataIndex: 'signDate',
    key: 'signDate',
    width: '9%',
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '执行时间',
    dataIndex: 'time',
    key: 'time',
    width: '9%',
    align: 'center',
    render: (text, record) => (
      <div>
        <div>{record['startTime']}</div>
        <div>{record['endTime']}</div>
      </div>
    )
  },
  {
    title: '有效期',
    dataIndex: 'billPeriod',
    key: 'billPeriod',
    width: '12%',
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '审核状态',
    dataIndex: 'contractState.value',
    key: 'contractState.value',
    width: '9%',
    align: 'center',
    render: (text) => (TableCell(text))
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    width: '9%',
    align: 'center',
    render: (text) => (TableCell(text))
  },

  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    width: '13%',
    render: (id, record) => TableAction(id, actionClickHandle, record)
  },
];

export const takeDataSource = (data) => {
  // 解析表格数据，使符合columns格式要求
  // let tableSource = data.map((item)=>{

  //   return tableCell;
  // });
  return data;
};



export const takeColumns = (actionClickHandle) => {
  return columns(actionClickHandle);
};

const TableCell = (text) => {
  return (
    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
      {text}
    </div>
  );
};

const TableAction = (id, actionClickHandle, record) => {
  return (
    <div className={styles.action}>
      <div className={styles.item} onClick={() => actionClickHandle(id, 'DETAIL', record)}>查看详情</div>
      {['AUDIT_TYPE_NEW', 'AUDIT_TYPE_NOT_APPROVED'].includes((record.contractState || {}).code) ? <div className={styles.item} onClick={() => actionClickHandle(id, 'EDIT', record)}>编辑</div> : null}
      <div className={styles.item} onClick={() => actionClickHandle(id, 'STOP', record)}>终止合同</div>
    </div>
  );
};