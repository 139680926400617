/**
 * 安全设置
 */
import React from 'react';
import styles from './index.module.scss';
import { XTitle } from '@/components/xqxc_ui';
import SafeItem from './components/SafeItem';
import UserPwdModal from '../../modal/UserPwdModal';
import FinancialPwd from '../../modal/FinancialPwd';
import { getLocalStorage } from '@/assets/js/storage';
import { isGY } from '@/assets/js/authType';

const Modal = {
    UserPwd: 'UserPwd',
    FinancialPwd: 'FinancialPwd'
};

class SafeSetting extends React.Component {

    state = {
        curMode: '',
        initData: [
            {
                id: '1',
                icon: require('@/assets/image/safeicon1.png'),
                title: '账户密码',
                subTitle: '可以作为登录，找回密码以及其它安全保护',
                content: '',
                btnLabel: '设置'
            }
        ]
    }

    componentDidMount() {
        const { initData } = this.state;
        const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
        const { isFinancial } = userInfo;
        if (isGY() && isFinancial == '1') {
            let newInitData = [...initData];
            newInitData.push({
                id: '2',
                icon: require('@/assets/image/safeicon2.png'),
                title: '财务密码',
                subTitle: '可以作为财务付款校验',
                content: '',
                btnLabel: '设置'
            });
            this.setState({ initData: newInitData });
        }
    }

    // 渲染弹框
    _renderMode = () => {
        switch (this.state.curMode) {
            case Modal.UserPwd:
                return <UserPwdModal visible={true} closeMode={this._closeMode} />;
            case Modal.FinancialPwd:
                return <FinancialPwd visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ curMode: '' });
    }

    // 渲染安全设置的项
    _renderSafeItem = (initData) => {
        return initData.map((data, index) => {
            return <SafeItem key={index} data={data} onClick={this._clickHandle} />;
        });
    }

    // 安全设置的项被点击
    _clickHandle = (id) => {
        switch (id) {
            case '1':
                this.setState({ curMode: Modal.UserPwd });
                break;
            case '2':
                this.setState({ curMode: Modal.FinancialPwd });
                break;
        }
    }

    render() {
        const { initData } = this.state;
        return (
            <div className={styles.flexBoxContainer}>
                <XTitle label='安全设置' />
                <div style={{ marginTop: '10px' }}>
                    {this._renderSafeItem(initData)}
                </div>
                {this._renderMode()}
            </div>
        );
    }
}

export default SafeSetting;