import * as T from './actiontypes';

//初始数据
const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    resultTotal: 0
};

//初始数据
const initDetail = {
    images:[],
    auditHisResps:[]
};

//保存供应商合同审核列表
export const contractAuditList = (state = initData, action) => {
    switch (action.type) {
        case T.SET_CONTRACTAUDIT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

//保存合同状态下拉
export const contractStateSele = (state = [], action) => {
    switch (action.type) {
        case T.SET_CONTRACTSTATE_SELE:
            return action.payload.result;
        default:
            return state;
    }
};

//保存供应商下拉
export const supplierSele = (state = [], action) => {
    switch (action.type) {
        case T.SET_SUPPLIER_SELE:
            return action.payload.result;
        default:
            return state;
    }
};

//保存供应商详情
export const contractAuditDetail = (state = initDetail, action) => {
    switch (action.type) {
        case T.SET_CONTRACTAUDIT_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};



