import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Form, Button, message, Icon } from 'antd';
import CustomUpload, { generateFileList } from '@/components/NewUpload';

class Main extends React.Component {

    state = {
        loading: false, // 加载中
        required: true,
        template: '', // 委托书模板
        imgUrl: [], // 委托书
        identity: { cardFront: [], cardBack: [] }, // 身份证正面、反面、手持身份证
        auditOpinion: '', // 驳回原因
        showSignature: false, // 委托书模糊，需重新填写
        showIDCard: false, // 身份证模糊，需重新填写
    }

    componentDidMount() {
        http('/walletUnion/getStandingOrderTemplate', {}, 'GET').then((response) => {
            if (response.status == 200) {
                this.setState({ template: response.result });
            }
        }).catch((e) => {
            message.error(e.message);
        });

        http('/walletUnion/getTaxCommission').then((response) => {
            if (response.status == 200) {
                const {
                    taxCommissionUrl, // 委托书
                    cardFront, // 身份证正面
                    cardBack, // 身份证反面
                    auditOpinion, // 驳回原因
                    rejectType, // 驳回类型
                } = response.result;
                let showSignature = false;
                let showIDCard = false;
                rejectType.forEach((type) => {
                    if (type == 'FUZZY_SIGNATURE') {
                        showSignature = true;
                    } else if (type == 'ID_CARD') {
                        showIDCard = true;
                    }
                });
                this.setState({
                    imgUrl: taxCommissionUrl ? generateFileList(taxCommissionUrl.toString()) : [],
                    identity: { cardFront: cardFront ? generateFileList(cardFront.toString()) : [], cardBack: cardBack ? generateFileList(cardBack.toString()) : [] },
                    auditOpinion,
                    showSignature,
                    showIDCard,
                });
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _handleSubmit = e => {
        const { showSignature, showIDCard } = this.state;
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const query = {};
                if (showSignature) {
                    query.imgUrl = fieldsValue.imgUrl[0].url; // 委托书
                }
                if (showIDCard) {
                    query.cardFront = fieldsValue.identity.cardFront[0].url; // 身份证人像面
                    query.cardBack = fieldsValue.identity.cardBack[0].url; // 身份证国徽面
                }
                http('/walletUnion/submitMerchantTaxCommission', query).then((response) => {
                    if (response.status == 200) {
                        message.success('提交成功');
                        this.props.update();
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
            }
        });
    }

    // 上传图片校验-返回值
    _normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    // 上传图片校验-提示
    beforeUpload = (file, sizeNum = 1) => {
        const isType = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
        const isLt5M = file.size / 1024 / 1024 < sizeNum;
        if (!isType.includes(file.type)) {
            message.error('只能上传png、jpg、gif格式的图片');
            file.status = 'error';
            return false;
        } else if (!isLt5M) {
            message.error('图片必须小于1MB');
            file.status = 'error';
            return false;
        } else {
            return isType && isLt5M;
        }
    }

    render() {
        const { loading, required, imgUrl, identity, auditOpinion, showSignature, showIDCard, template } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <div className={styles.container}>
                <Form autoComplete='off' onSubmit={this._handleSubmit}>
                    {showSignature ? <Form.Item
                        colon={false}
                        htmlFor='imgUrl'
                        label={<span>委托书：<a onClick={() => { window.open(template); }}>委托书模板下载</a></span>}
                    >
                        <div>
                            {getFieldDecorator('imgUrl', {
                                initialValue: imgUrl,
                                valuePropName: 'fileList',
                                getValueFromEvent: this._normFile,
                                rules: [
                                    { type: 'array', required, message: '请上传委托书' },
                                ],
                            })(
                                <CustomUpload
                                    accept='.png, .jpg, .jpeg'
                                    listType="picture-card"
                                    wallMode="single"
                                    removeError
                                    qiniuUploadProps={{
                                        uploadType: 'image'
                                    }}
                                    onChange={(info) => {
                                        const imgUrl = info.fileList;
                                        imgUrl.length == '0' && this.props.form.resetFields('imgUrl', []);
                                        this.setState({ imgUrl });
                                    }}
                                    beforeUpload={(file) => this.beforeUpload(file, 1)}
                                >
                                    <div>
                                        <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                        <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                        <div style={{ color: '#999999', fontSize: '12px' }}>委托书</div>
                                    </div>
                                </CustomUpload>
                            )}
                            <span className={styles.oneline}>每次仅支持上传一张图片，大小不超过1M</span>
                        </div>
                    </Form.Item> : null}
                    {showIDCard ? <Form.Item
                        colon={false}
                        label={<span>身份证：</span>}
                    >
                        <div className={styles.upload}>
                            {getFieldDecorator('identity', {
                                initialValue: identity,
                                rules: [
                                    { required, message: '请上传身份证照片' },
                                    {
                                        validator: (rule, value = {}, callback) => {
                                            if (!required) {
                                                callback();
                                            }
                                            let identityField = this.props.form.getFieldsValue().identity;
                                            if (identityField.cardFront.length == 0) {
                                                callback('请上传身份证人像面');
                                            } else if (identityField.cardBack.length == 0) {
                                                callback('请上传身份证国徽面');
                                            } else {
                                                callback();
                                            }
                                        }
                                    }
                                ],
                                getValueFromEvent: () => {
                                    return this.props.form.getFieldsValue().identity;
                                }
                            })(
                                <div className={styles.upload}>
                                    <CustomUpload
                                        accept='.png, .jpg, .jpeg'
                                        fileList={identity.cardFront}
                                        listType="picture-card"
                                        wallMode="single"
                                        removeError
                                        qiniuUploadProps={{
                                            uploadType: 'image'
                                        }}
                                        onChange={(info) => {
                                            let identityField = this.props.form.getFieldsValue().identity;
                                            const cardFront = info.fileList;
                                            if (cardFront.length == '0') {
                                                identityField = { ...identityField, cardFront: [] };
                                            } else {
                                                identityField = { ...identityField, cardFront };
                                            }
                                            this.props.form.setFieldsValue({ identity: identityField });
                                            this.setState({ identity: identityField });
                                        }}
                                        beforeUpload={(file) => this.beforeUpload(file, 1)}
                                    >
                                        <div>
                                            <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                            <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                            <div style={{ color: '#999999', fontSize: '12px' }}>人像面</div>
                                        </div>
                                    </CustomUpload>
                                    <CustomUpload
                                        accept='.png, .jpg, .jpeg'
                                        fileList={identity.cardBack}
                                        listType="picture-card"
                                        wallMode="single"
                                        removeError
                                        qiniuUploadProps={{
                                            uploadType: 'image'
                                        }}
                                        onChange={(info) => {
                                            let identityField = this.props.form.getFieldsValue().identity;
                                            const cardBack = info.fileList;
                                            if (cardBack.length == '0') {
                                                identityField = { ...identityField, cardBack: [] };
                                            } else {
                                                identityField = { ...identityField, cardBack };
                                            }
                                            this.props.form.setFieldsValue({ identity: identityField });
                                            this.setState({ identity: identityField });
                                        }}
                                        beforeUpload={(file) => this.beforeUpload(file, 1)}
                                    >
                                        <div>
                                            <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                            <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                            <div style={{ color: '#999999', fontSize: '12px' }}>国徽面</div>
                                        </div>
                                    </CustomUpload>
                                </div>
                            )}
                        </div>
                        <div className={styles.identity}>每次仅支持上传一张图片，大小不超过1M</div>
                    </Form.Item> : null}
                    <div style={{ marginBottom: '30px' }}>驳回原因：{auditOpinion}</div>
                    <Button style={{ width: '150px' }} type="primary" htmlType="submit" loading={loading}>提交</Button>
                </Form>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    form: PropTypes.object, // 表单校验
    update: PropTypes.func
};

const stepForm = Form.create({ name: 'step-form' })(Main);
export default stepForm;