/**
 * 签署协议-银联
 */
import React from 'react';
import styles from './step.module.scss';
import PropTypes from 'prop-types';
import { Form, Button, Input, message } from 'antd';
import Ensure from './../components/Ensure';
import getUnionInfo from './../components/getUnionInfo';
import http from '@/assets/api/http';

const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 10 },
        sm: { span: 10 },
    },
};

class step extends React.Component {

    state = {
        visible: false,
        loading: false, // 加载中
    }

    // 下一步
    _handleSubmit = e => {
        this._signContract();
    }

    // 渲染签约确认弹框
    _renderEnsureComp = () => {
        const { visible } = this.state;
        return <Ensure signSuccess={() => this.props.next()} visible={visible} closeMode={() => { this.setState({ visible: false }); }} />;
    }

    // 请求电子协议
    _signContract = () => {
        http('/union/merchant/agreementSign').then((response) => {
            if (response.status == 200) {
                const http = (response.result.toString()).substring(0, 4);
                if (http == null || http == 'null') {
                    message.error('无法获取签约地址或地址有误');
                    this.setState({ loading: false });
                } else {
                    try {
                        window.open(response.result);
                        this.setState({ visible: true, loading: false });
                    } catch (e) {
                        this.setState({ loading: false });
                        message.error('弹出窗口被拦截，请点击地址栏下”允许阻止的内容“设置');
                    }
                }
            }
        }).catch((e) => {
            this.setState({ loading: false });
            message.error(e.message);
        });
    }

    render() {
        const { loading } = this.state;

        return (
            <div className={styles.step3}>
                <div className={styles.txt}>您已经验证成功，点击下一步签订电子协议</div>
                <div className={styles.btn}>
                    <Button type="primary" htmlType="submit" loading={loading} onClick={this._handleSubmit}>下一步</Button>
                </div>
                {this._renderEnsureComp()}
            </div>
        );
    }
}

step.propTypes = {
    previous: PropTypes.func, // 上一步
    next: PropTypes.func, // 下一步
    form: PropTypes.object, // 表单校验
};
const stepForm = Form.create({ name: 'step-form' })(step);
export default stepForm;


