/**
 * 【商品订单页】
 */
import React from 'react';
import { XTabNav } from '@/components/xqxc_ui';
import OrderSearch from './components/OrderSearch';
import Export from './components/Export';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import LogisticsModal from '../../modal/LogisticsModal';
import LeaveMsg from '../../modal/LeaveMsg';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getProductOrderList } from '@/reducers/ordermanage/productOrder/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getLocalStorage } from '@/assets/js/storage';

class Main extends React.Component {
    state = {
        tab: [
            { id: 1, label: '全部订单', code: 'ordermanage/productorder/tabqbdd' },
            { id: 2, label: '待付款', code: 'ordermanage/productorder/tabdfk' },
            { id: 3, label: '待收货', code: 'ordermanage/productorder/tabdsk' },
            { id: 4, label: '已完成', code: 'ordermanage/productorder/tabywc' },
            { id: 5, label: '已取消', code: 'ordermanage/productorder/tabyqx' },
        ], // tab配置
        showTab: [], // 显示的tab
        curTabID: 1,  // 当前显示的Tab页ID
        renderMode: '', // 是否显示弹框（查看物流）
        modeParams: {}, // 弹框入参
        orderID: '', // 当前操作的订单ID 
        pageNum: 1,
        pageSize: 20,
        status: [],
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this._getShowTab(); // Tab菜单权限管理
    }

    _getShowTab = () => {
        const _that = this;
        let btnPremission = JSON.parse(getLocalStorage('btnPremission')) || [];
        if (btnPremission.length == 0) {
            setTimeout(() => { _that._getShowTab(); }, 500);
            return;
        }
        const { tab } = this.state;
        let showTab = [];
        for (let i = 0; i < btnPremission.length; i++) {
            const code = btnPremission[i];
            for (let m = 0; m < tab.length; m++) {
                if (tab[m].code == code) {
                    showTab.push(tab[m]);
                    break;
                }
            }
        }
        this.setState({ showTab });
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        const { reqParams } = this.state;
        this.setState({ curTabID: tabID });
        switch (tabID) {
            case 1:
                this.setState({
                    status: [],
                    pageNum: 1,
                    reqParams: {
                        ...reqParams,
                        statusList: [],
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 2:
                this.setState({
                    status: ['PAYING'],
                    pageNum: 1,
                    reqParams: {
                        ...reqParams,
                        statusList: ['PAYING'],
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 3:
                this.setState({
                    status: ['PAIED', 'PART_GOODS_SENT', 'GOODS_SENT'],
                    pageNum: 1,
                    reqParams: {
                        ...reqParams,
                        statusList: ['PAIED', 'PART_GOODS_SENT', 'GOODS_SENT'],
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 4:
                this.setState({
                    status: ['FINISHED', 'GOODS_CONFIRMED'],
                    pageNum: 1,
                    reqParams: {
                        ...reqParams,
                        statusList: ['FINISHED', 'GOODS_CONFIRMED'],
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 5:
                this.setState({
                    status: ['CANCELLED'],
                    pageNum: 1,
                    reqParams: {
                        ...reqParams,
                        statusList: ['CANCELLED'],
                    }
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
        }
    }

    // 表格操作
    _tableAction = (key, type, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            // 查看详情
            case 'info':
                if (record.consumeType.includes('同城')) {
                    addOneBreadcrumbPath({
                        title: '查看详情',
                        path: '/home/ordermanage/cityorder/orderdetail/'
                    });
                    history.push({
                        pathname: '/home/ordermanage/cityorder/orderdetail',
                        search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                    });
                } else {
                    addOneBreadcrumbPath({
                        title: '查看详情',
                        path: '/home/ordermanage/productorder/orderdetail/'
                    });
                    history.push({
                        pathname: '/home/ordermanage/productorder/orderdetail',
                        search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                    });
                }
                break;
            // 查看物流
            case 'logistics':
                this.setState({ renderMode: 'logistics', orderID: key });
                break;
            // 联系商户
            case 'business':
                addOneBreadcrumbPath({
                    title: '新建工单',
                    path: '/home/ordermanage/productorder/workorder/'
                });
                history.push({
                    pathname: '/home/ordermanage/productorder/workorder/'
                });
                break;
            // 操作记录
            case 'history':
                addOneBreadcrumbPath({
                    title: '操作记录',
                    path: '/home/ordermanage/productorder/orderhistory/'
                });
                history.push({
                    pathname: '/home/ordermanage/productorder/orderhistory',
                    search: `?buyerId=${record.buyerId}&&orderId=${record.orderId}`,
                });
                break;
            case 'leaveMsg':
                this.setState({ renderMode: 'leaveMsg', modeParams: record });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        const { searchData } = this.state;
        const reqParams = { ...searchData, pageNum, pageSize };
        this.searchCache = { ...searchData, pageNum, pageSize };
        this.setState({ pageSize, pageNum, reqParams });
        this.props.getProductOrderList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 打开查看物流弹框
    _renderMode = () => {
        const { renderMode, modeParams } = this.state;
        switch (renderMode) {
            case 'logistics':
                return <LogisticsModal visible={true} data={this.state.orderID} closeMode={this._closeMode} />;
            case 'leaveMsg':
                return <LeaveMsg visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭查看物流弹框
    _closeMode = () => {
        this.setState({ renderMode: '' });
    }

    _searchHandle = (useCache) => {
        const { reqParams, pageNum, pageSize } = this.state;
        this.searchCache = {
            ...reqParams,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.props.getProductOrderList(this.searchCache);
    }

    _searchOrder = (searchData, param, flag) => {
        const { pageNum, pageSize } = this.state;
        const reqParams = { ...searchData, pageNum, pageSize };
        this.setState({ searchData, reqParams, param }, () => {
            if (flag) {
                // 重置
                KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
            } else {
                this._searchHandle('useCache');
            }
        });
    }

    render() {
        const { productOrderList } = this.props;
        const { curTabID } = this.state;
        // 全部订单 ordermanage/productorder/tabqbdd
        // 待付款 ordermanage/productorder/tabdfk
        // 待收货 ordermanage/productorder/tabdsk
        // 已完成 ordermanage/productorder/tabywc
        // 已取消 ordermanage/productorder/tabyqx

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    {
                        this.state.showTab && this.state.showTab.length > 0 ? <>
                            <XTabNav renderData={this.state.showTab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                            <OrderSearch searchState={this.state} orderState={this.state.curTabID} status={this.state.status} searchOrder={this._searchOrder} />
                            <OrderTable renderData={productOrderList} tableAction={this._tableAction} paginationChange={this._paginationChange} curTabID={curTabID} />
                            {this._renderMode()}
                        </> : '您没有该页面权限，请联系管理员'
                    }
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getProductOrderList: PropTypes.func,
    productOrderList: PropTypes.object,
};

const mapStateToProps = (state) => ({
    productOrderList: state.productOrder.productOrderList,
});

export default connect(mapStateToProps, { getProductOrderList, addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);
