import React from 'react';
import styles from './tableStyle.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

const objCell = (text) => {
    return tooltip(text ? text.value : '');
};

const BillQueryTable = ({ renderData, tableOperate, paginationChange }) => {

    //操作列
    const onTableOperate = (id, { recondTm = '' }) => {
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => tableOperate('钱包对账明细表', `/walletinfo/${recondTm}`)}>明细</div>
        </div>;
    };

    const columns = () => ([
        {
            title: '结算时间',
            dataIndex: 'settTm',
            key: 'settTm',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '业务流水号',
            dataIndex: 'bussinessNo',
            key: 'bussinessNo ',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '交易订单类型',
            dataIndex: 'transactionType',
            key: 'transactionType',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: objCell
        },
        {
            title: '交易金额(元)',
            dataIndex: 'transactionAmount',
            key: 'transactionAmount',
            align: 'center',
            width: '10%',
            render: priceFenToYuanCell
        },
        {
            title: '结算状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: objCell
        },
        {
            title: '账期',
            dataIndex: 'recondTm',
            key: 'recondTm',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '10%',
            render: onTableOperate
        }
    ]);


    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.id}
                columns={columns()}
                dataSource={renderData.dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={renderData.resultTotal}
                pageSize={renderData.pageSize}
                pageNum={renderData.pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};

BillQueryTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableOperate: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default BillQueryTable;