import { createModel } from '@/store/tools';

const model = {
    namespace: 'wholesaleOrder',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getWholeOrderDetails': T('getWholeOrderDetails'),
            'getWholesaleList': T('getWholesaleList'),
            'getOrderCostDetailList': T('getOrderCostDetailList'),
            'getMerchantCostSettingEnum': T('getMerchantCostSettingEnum'),
            'getPaymentTypeEnum': T('getPaymentTypeEnum'), // 支付方式
            'getPaymentAccessTypeEnum': T('getPaymentAccessTypeEnum'), // 支付通道
        },
        actions: {
            'getWholeOrderDetails': A('getWholeOrderDetails'), //  商户后台 - 批发订单详情
            'getWholesaleList': A('getWholesaleList'), // 商户后台 - 批发订单列表
            'getOrderCostDetailList': A('getOrderCostDetailList'), // 商户后台 - 费用明细
            'getMerchantCostSettingEnum': A('getMerchantCostSettingEnum'), // 商户后台 - 费用名称
            'getPaymentTypeEnum': A('getPaymentTypeEnum'), // 支付方式
            'getPaymentAccessTypeEnum': A('getPaymentAccessTypeEnum'), // 支付通道
        },
        sagas: {
            'getWholeOrderDetails': S('getWholeOrderDetails', '/merchant/distribution/order/getOrderDetails'),
            'getWholesaleList': S('getWholesaleList', '/merchant/distribution/order/getPage'), //商户后台 - 批发订单列表
            'getOrderCostDetailList': S('getOrderCostDetailList', '/settle/reconciliationSource/listOrderCostDetail'), //商户后台 - 费用明细
            'getMerchantCostSettingEnum': S('getMerchantCostSettingEnum', '/enum/MerchantCostSettingEnum'), //商户后台 - 费用名称
            'getPaymentTypeEnum': S('getPaymentTypeEnum', '/enum/PaymentChannelType'), //支付方式
            'getPaymentAccessTypeEnum': S('getPaymentAccessTypeEnum', '/enum/PaymentAccessType'), //支付通道
        },
        reducers: {
            'WholeOrderDetails': R('getWholeOrderDetails', {}),
            'WholesaleList': R('getWholesaleList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'orderCostDetaiList': R('getOrderCostDetailList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'MerchantCostSettingEnum': R('getMerchantCostSettingEnum', []),
            'paymentTypeEnum': R('getPaymentTypeEnum', []),
            'paymentAccessTypeEnum': R('getPaymentAccessTypeEnum', []),
        }
    })
};
export default createModel(model);