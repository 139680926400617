import * as T from './actiontypes';
const defaultState = {
    tableSource: [],// 列表
    paginations: {}, //列表分页对象

    tableSourceAudit: [],// 审核列表
    paginationsAudit: {}, //审核列表分页对象
    selectData: [],//审核状态
};


/**
 * 存储'仓库商品结算价-'数据源
 * @param {*} state 
 * @param {*} action 
 */
export const warehousePriceListDate = (state = defaultState, action) => {
    let newTableSource, newPaginations, newselect;
    switch (action.type) {
        case T.SET_STOCKPRICELIST_DATA: // 列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_STOCKAUDITLIST_DATA: // 审核列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSourceAudit: newTableSource, paginationsAudit: newPaginations };
        case T.SET_AUDITSELECT_DATA: //审核状态
            newselect = action.payload.result;
            return { ...state, selectData: newselect };
        default:
            return state;
    }
};

