/**
 * 仓库商品管理saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as T from './actiontypes';
import http from '@/assets/api/http';

// 获取商品列表
export const getProdList = function* () {
    yield takeEvery(T.GET_PRO_LIST, function* requestData(action) {
        try {
            let result = yield http('/goods/inventory/listGoods', action.payload, 'POST');
            yield put({ type: T.SET_PRO_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

