/**
 *  店铺活动
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';


class Showset extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        
        switch (mode) {
            case 'freight'://创建立减运费
                addOneBreadcrumbPath({
                    title: '创建立减运费',
                    path: '/home/marketingmanage/storeActivity/freight'
                });
                history.push({
                    pathname: '/home/marketingmanage/storeActivity/freight'
                });
                break;
            case 'shopReduce'://创建店铺满减
                addOneBreadcrumbPath({
                    title: '创建店铺满减',
                    path: '/home/marketingmanage/storeActivity/shopReduce'
                });
                history.push({
                    pathname: '/home/marketingmanage/storeActivity/shopReduce'
                });
                break;
            case 'freightDetail'://立减运费详情
                addOneBreadcrumbPath({
                    title: '立减运费详情',
                    path: '/home/marketingmanage/storeActivity/freightDetail/' + value
                });
                history.push({
                    pathname: '/home/marketingmanage/storeActivity/freightDetail/' + value
                });
                break;
            case 'shopReduceDetail'://店铺满减详情
                addOneBreadcrumbPath({
                    title: '店铺满减详情',
                    path: '/home/marketingmanage/storeActivity/shopReduceDetail/' + value
                });
                history.push({
                    pathname: '/home/marketingmanage/storeActivity/shopReduceDetail/' + value
                });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} history={this.props.history} />
        );
    }
}

Showset.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Showset);