// 仓库对账单列表
export const GET_WAREHOUSE_LIST = 'WAREHOUSE/GET_WAREHOUSE_LIST';
export const SET_WAREHOUSE_LIST = 'WAREHOUSE/SET_WAREHOUSE_LIST';
// 仓库对账单详情
export const GET_WAREHOUSE_DETAIL = 'WAREHOUSE/GET_WAREHOUSE_DETAIL';
export const SET_WAREHOUSE_DETAIL = 'WAREHOUSE/SET_WAREHOUSE_DETAIL';
// 仓库应收，应付明细
export const GET_WAREHOUSE_CHARGE_DETAIL = 'WAREHOUSE/GET_WAREHOUSE_CHARGE_DETAIL';
export const SET_WAREHOUSE_CHARGE_DETAIL = 'WAREHOUSE/SET_WAREHOUSE_CHARGE_DETAIL';
// 奢批订单列表
export const GET_WAREHOUSE_LUXURYORDERS = 'WAREHOUSE/GET_WAREHOUSE_LUXURYORDERS';
export const SET_WAREHOUSE_LUXURYORDERS = 'WAREHOUSE/SET_WAREHOUSE_LUXURYORDERS';

