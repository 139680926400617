import { combineReducers } from 'redux';
import { getWarehouseType, address, supplierListTableSource, shopTypes, goodsTypes, getInvoicelSelect, listServiceProviderKetName,radiotypes } from './reducer';

export default combineReducers({
	getWarehouseType, // 获取仓库类型
	address, // 获取省市区
	supplierListTableSource,
	shopTypes,
	goodsTypes,
	getInvoicelSelect,
    listServiceProviderKetName,
    radiotypes
});