import React, { Component } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { XInput, XOKButton, XCancelButton, XTextArea } from '@/components/xqxc_ui';
import SoldOutedTable from './components/SoldOutedTable';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import SetPrice from './modal/SetPrice';
import model from '@/reducers/productmanage/wholesalegoods/model';

class SoldOuted extends Component {

    static propTypes = {
        history: PropTypes.object,
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getWholesaleGoodsList: PropTypes.func,
        wholesaleGoodsList: PropTypes.object
    }

    static defaultProps = {
        wholesaleGoodsList: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }
    }

    state = {
        goodsNos: '',
        goodsName: '',
        skuNos: '',
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const {
            goodsName,
            goodsNos,
            skuNos,
            pageNum,
            pageSize
        } = this.state;
        this.searchCache = {
            goodsName,
            goodsNos: goodsNos && goodsNos.replace(/，/g, ','),
            skuNos: skuNos && skuNos.replace(/，/g, ','),
            status: 'LOSE_EFFICACY',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getWholesaleGoodsList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({
            goodsName: '',
            goodsNos: '',
            skuNos: '',
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    //请求函数
    _request = (url, data) => {
        http(url, data, 'POST')
            .then(res => {
                message.success(res.message);
                this._searchHandle('useCache');
            })
            .catch(err => {
                message.error(err.message);
            });
    }


    //路由跳转
    _routeJump = (title, url) => {
        const { addOneBreadcrumbPath, history } = this.props;
        const baseURL = '/home/productmanage/wholesalegoods';
        addOneBreadcrumbPath({ title, path: baseURL });
        history.push({ pathname: `${baseURL}${url}` });
    }

    // 表格操作
    _tableAction = (id, type, rowValue) => {
        switch (type) {
            case 'detail':
                this._routeJump('商品详情', `/goodsinfo/${id}/4`);
                break;
            case 'goodslist':
                this.setState({ renderModal: 'setPrice', modalParams: rowValue });
                break;
            default:
                break;
        }
    }
    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 渲染弹窗
    _renderMode = () => {
        const { renderModal, modalParams } = this.state;
        switch (renderModal) {
            case 'setPrice':
                return <SetPrice
                    modalParams={modalParams}
                    closeMode={this._closeMode} />;
            default:
                return null;
        }
    }
    // 关闭弹窗
    _closeMode = (refresh) => {
        this.setState({ renderModal: '', modalParams: null }, () => {
            if (refresh) {
                this._searchHandle('useCache');
            }
        });

    }

    render() {
        const { wholesaleGoodsList } = this.props;
        const {
            goodsName,
            goodsNos,
            skuNos
        } = this.state;
        return (
            <KeepAlive id='soldOuted' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XTextArea
                            label='货号'
                            inputStyle={{ marginRight: 20, width: 250 }}
                            placeholder='请输入货号，多个用逗号隔开，最多100条'
                            value={goodsNos}
                            rows={2}
                            onChange={value => {
                                if (value.replace(/，/g, ',').split(',').length < 100) {
                                    this.setState({ goodsNos: value });
                                } else {
                                    message.warning('商品货号最多100条');
                                }
                            }}
                        />
                        <XTextArea
                            label='SKU编码'
                            inputStyle={{ marginRight: 20, width: 250 }}
                            placeholder='请输入SKU编码，多个用逗号隔开，最多100条'
                            value={skuNos}
                            rows={2}
                            onChange={value => {
                                if (value.replace(/，/g, ',').split(',').length < 100) {
                                    this.setState({ skuNos: value });
                                } else {
                                    message.warning('SKU编码最多100条');
                                }
                            }}
                        />
                        <XInput
                            label='商品名称'
                            inputStyle={{ marginRight: 20, width: 150 }}
                            placeholder='请输入商品名称'
                            bindThis={this}
                            bindName='goodsName'
                            value={goodsName}
                        />
                        <XOKButton
                            label='查询'
                            style={{ marginRight: 10 }}
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <SoldOutedTable
                        paginationChange={this._paginationChange}
                        screenY={screenY}
                        renderData={wholesaleGoodsList}
                        tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

export default connect(state => ({
    wholesaleGoodsList: state.wholesaleGoods.wholesaleGoodsList
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(SoldOuted);