/**
 * 营销活动数据
 */
import React from 'react';
import { Modal, Tabs, Slider, InputNumber, } from 'antd';
import { connect } from 'react-redux';
import { XInput, XOKButton } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './ActivityDate.module.scss';
import ActivityDateTable from '../components/ActivityDateTable';
import OrderDateTable from '../components/OrderDateTable';


const { TabPane } = Tabs;

const marks = {
    0: {
        style: {
            color: '#f50',
        },
        label: <strong>0%</strong>,
    },
    100: {
        style: {
            color: '#f50',
        },
        label: <strong>100%</strong>,
    },
};
//模拟表格数据   
const tableDataSource = [];
for (let i = 0; i < 50; i++) {
    tableDataSource.push({
        c1: i,
        c2: '滑头鬼之孙',
        c3: '1432352325',
        c4: '2019.07.10  17:35',
        c5: '2019.07.10  17:35',
        c6: '未使用',
    });
}
const ordertableDataSource = [];
for (let i = 0; i < 50; i++) {
    ordertableDataSource.push({
        c1: i,
        c2: '滑头鬼之孙',
        c3: '1432352325',
        c4: '585757656456',
        c5: '￥165662',
        c6: '2019.07.10  17:35',
    });
}
class ActivityDate extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        keyword: '',
        pageNum: 1,
        pageSize: 10,
        goodsList: [],
        inputserviceValue: 0,//领取 100张
        inputfeesValue: 0,//领取 50张
    }

    componentDidMount() {
        const { pageNum, pageSize } = this.state;
    }

    _tableAction = (id, data) => {
        console.log(data);
        const goodsList = [...this.state.goodsList];
        this.setState({
            goodsList: [...new Set(goodsList.concat(data))]
        });
    }
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _inputChangeHandle = (value) => {
        this.setState({ keyword: value });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        const { keyword, isCheck } = this.state;
        if (!isCheck) {
            // this.props.getListVideoProduct({ keyword: '', pageNum, pageSize });
        } else {
            // this.props.getListVideoProduct({ keyword, pageNum, pageSize });
        }
    }
    //营销数据与 订单数据切换
    TabsCallback = (activeKey) => {
        console.log(activeKey);
    }
    //领取 100张 
    onserviceChange = value => {
        this.setState({
            inputserviceValue: value,
        });
    };
    onService = value => {
        this.setState({
            inputserviceValue: value,
        });
    };
    //自定义提示
    formatter = (value) => {
        return `${value}%`;
    }
    //领取 50张 
    onfeesChange = value => {
        this.setState({
            inputfeesValue: value,
        });
    };
    onFees = value => {
        this.setState({
            inputfeesValue: value,
        });
    };

    render() {
        const { visible, title, confirmLoading, keyword, inputserviceValue, inputfeesValue, } = this.state;

        return (
            <Modal
                width={860}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                footer={null}
            >
                <div className={styles.container}>
                    <div className={`${styles.layout} ${styles.dataTop}`}>
                        <div className={`${styles.layout}`}>
                            <div><h4>发行量：</h4><span>1000张</span></div>
                        </div>
                    </div>
                    <div className={styles.rangemark}>
                        <p>领取 100张</p>
                        <div className={styles.left}>
                            <Slider
                                marks={marks}
                                min={0}
                                max={100}
                                tipFormatter={this.formatter}
                                onChange={this.onService}
                                value={typeof inputserviceValue === 'number' ? inputserviceValue : 0}
                            />
                        </div>
                        <div className={styles.right}>
                            <InputNumber
                                min={0}
                                max={100}
                                style={{ marginLeft: 16 }}
                                value={inputserviceValue}
                                onChange={this.onserviceChange}
                            />
                        </div>
                    </div>
                    <div className={styles.rangemark}>
                        <p>领取 50张</p>
                        <div className={styles.left}>
                            <Slider
                                marks={marks}
                                min={0}
                                max={100}
                                tipFormatter={this.formatter}
                                onChange={this.onFees}
                                value={typeof inputfeesValue === 'number' ? inputfeesValue : 0}
                            />
                        </div>
                        <div className={styles.right}>
                            <InputNumber
                                min={0}
                                max={100}
                                style={{ marginLeft: 16 }}
                                value={inputfeesValue}
                                onChange={this.onfeesChange}
                            />
                        </div>
                    </div>
                    {/* <div className={styles.input}>
                        <XOKButton style={{ width: '104px' }} label='导出excel' />
                    </div> */}
                    <Tabs defaultActiveKey="1" onChange={this.TabsCallback}>
                        <TabPane tab="领取详情" key="1">
                            <div className={styles.table}>
                                <ActivityDateTable tableAction={this._tableAction} paginationChange={this._paginationChange} renderData={tableDataSource} />
                            </div>
                        </TabPane>
                        <TabPane tab="使用详情" key="2">
                            <div className={styles.table}>
                                <OrderDateTable tableAction={this._tableAction} paginationChange={this._paginationChange} renderData={ordertableDataSource} />
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </Modal >
        );
    }
}

ActivityDate.propTypes = {
    title: PropTypes.object,// 弹框标题
    data: PropTypes.object, // 需要修改魅力值的会员对象数组
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    tableAction: PropTypes.func,
};

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, null)(ActivityDate);