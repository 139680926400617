import * as T from './actiontypes';
/** 
 * 营销活动列表数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} activityTitle 活动标题
  * @param {string} activityType 活动类型
 * @param {string} state 状态
 * @param {string} activityState 区分 全部、结束，未开始等--不传或传“”则查询所有，“1”：点击未开始后的列表，“2”：点击进行中后的列表，3"：点击已结束的列表
 */
export const getMarketingList = (dataload) => ({
    type: T.GET_MARKINGLIST_DATA,
    payload: {
        ...dataload,
    }
});

/**
 * 营销活动编辑获取新建详情'数据源
 * @param {number} id  获取某个用户情对应的id
 */
export const getMarketingDetails = (id) => ({
    type: T.GET_MARKETINGDETAIL_DATA,
    payload: {
        id,
    }
});
/**
 * 营销活动下拉框'数据源(saga)-活动类型、推送平台、活动状态
 * @param 暂无
 */
export const getMarketingDropDown = (only) => ({
    type: T.GET_DROPDIWN_DATA,
    payload: {
        only,
    }
});
/**
 *商户活动新建数据
 */
export const getproductRaiseTable = (dataload) => ({
    type: T.GET_RAISETABALE_DATA,
    payload: {
        ...dataload,
    }
});
/**
 *商户活动新增sku设置条件
 */
export const getproductSkuTable = (dataload) => ({
    type: T.GET_SKUTABALE_DATA,
    payload: {
        ...dataload,
    }
});
/** 
 * 商户营销活动》详情》活动商品列表
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {number} activityId 对应活动id
* @param {string} name 商品名称或店铺名称
 */

export const getMarketingProductList = (dataload) => ({
    type: T.GET_MARKINGPRODUCTLIST_DATA,
    payload: {
        ...dataload,
    }
});

/** 
 * 营销活动》详情》活动商品列表> 详情
 * @param {number} activityGoodsId 对应活动详情id
 */

export const getMarketingProductDetails = (id) => ({
    type: T.GET_MARKINGPRODUCTDETAIL_DATA,
    payload: {
        activityGoodsId: id,
    }
});
/**
 * 营销活动新建-分类（新人专区）会员等级'数据源
 * @param {number}  暂无
 */
export const getproductRaiseAll = () => ({
    type: T.GET_RAISEALL_DATA,
});
