import { createModel } from '@/store/tools';

const dataList = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };

const model = {
    namespace: 'merchantwallet',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getReconciliationData': T('getReconciliationData'),
            'getDetailData': T('getDetailData'),
            'getCommonDetailData': T('getCommonDetailData'),
            'getSaleProceedsCard': T('getSaleProceedsCard'),
            'getSaleProceedsData': T('getSaleProceedsData'),
            'getSaleProceedsDraw': T('getSaleProceedsDraw'),
            'getSaleProceedsStatus': T('getSaleProceedsStatus'),
            'getDetailYLData': T('getDetailYLData'),
            'getSettleConfigInfo': T('getSettleConfigInfo'),
            'getDetailLLData': T('getDetailLLData'),
        },
        actions: {
            'getReconciliationData': A('getReconciliationData'),
            'getDetailData': A('getDetailData'),
            'getCommonDetailData': A('getCommonDetailData'),
            'getSaleProceedsCard': A('getSaleProceedsCard'),
            'getSaleProceedsData': A('getSaleProceedsData'),
            'getSaleProceedsDraw': A('getSaleProceedsDraw'),
            'getSaleProceedsStatus': A('getSaleProceedsStatus'),
            'getDetailYLData': A('getDetailYLData'),
            'getSettleConfigInfo': A('getSettleConfigInfo'),
            'getDetailLLData': A('getDetailLLData'),
        },
        sagas: {
            'getReconciliationData': S('getReconciliationData', '/wallet/list'), //列表
            'getDetailData': S('getDetailData', '/wallet/order/list'), //明细
            'getCommonDetailData': S('getCommonDetailData', '/wallet/order/standardPayList'),
            'getSaleProceedsCard': S('getSaleProceedsCard', '/wallet/walletSalesStatistics'), //销售收益card数据
            'getSaleProceedsData': S('getSaleProceedsData', '/wallet/query'), //销售收益数据
            'getSaleProceedsDraw': S('getSaleProceedsDraw', '/wallet/memberSaleIncomeSettlementInfo'), //销售收益支取
            'getSaleProceedsStatus': S('getSaleProceedsStatus', '/enum/RemitStatus'), //销售收益状态下拉
            'getDetailYLData': S('getDetailYLData', '/wallet/order/unionList'),
            'getSettleConfigInfo': S('getSettleConfigInfo', '/wallet/settmentInfo'),
            'getDetailLLData': S('getDetailLLData', '/wallet/order/llList'),
        },
        reducers: {
            'reconciliationData': R('getReconciliationData', dataList),
            'detailData': R('getDetailData', dataList),
            'commonDetailData': R('getCommonDetailData', dataList),
            'saleProceedsCard': R('getSaleProceedsCard', {}),
            'saleProceedsData': R('getSaleProceedsData', dataList),
            'saleProceedsDraw': R('getSaleProceedsDraw', {}),
            'saleProceedsStatus': R('getSaleProceedsStatus', []),
            'detailYLData': R('getDetailYLData', dataList),
            'settleConfigInfo': R('getSettleConfigInfo', {}),
            'detailLLData': R('getDetailLLData', dataList),
        }
    })
};
export default createModel(model);