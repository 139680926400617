import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import LiveTable from './components/LiveTable';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/commercialcollege/merchantAnchor/model';
import { XInput, XSelect, XOKButton, XCancelButton, XTabNav, showConfirm } from '@/components/xqxc_ui';
import styles from './index.module.scss';

class Main extends Component {

    state = {
        renderMode: '',//渲染弹框
        modeParams: {},//弹框入参

        tel: '',
        anchorType: {},
        tab: [
            { id: 1, label: '主播绑定' },
            { id: 2, label: '历史记录' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
    };

    //props校验
    static propTypes = {
        history: PropTypes.object,//路由对象
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getlistMerchantAnchor: PropTypes.func,//获取列表
        listMerchantAnchor: PropTypes.object,//列表数据
        getAnchorAssistantTypeEnums: PropTypes.func,
        AnchorAssistantTypeEnums: PropTypes.array,
        getAnchorAssistantRecord: PropTypes.func,
        AnchorAssistantRecord: PropTypes.object,
    }

    //挂载后
    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getAnchorAssistantTypeEnums();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, tel, anchorType, curTabID } = this.state;
        this.searchCache = {
            tel,
            type: anchorType.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        if (curTabID == 1) {
            this.props.getlistMerchantAnchor(this.searchCache);
        } else {
            this.props.getAnchorAssistantRecord(this.searchCache);
        }

        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            tel: '',
            anchorType: {},
        }, () => KeepAlive.saveResetFunc(this._searchHandle)); // 【第4步：将是否调用重置交给KeepAlive控制】
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => this._searchHandle('useCache'));
    }

    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID }, () => {
            this._searchHandle('useCache');
        });
    }
    // 表格操作
    _tableAction = (id, type, value) => {

        switch (type) {
            case 'unbind':
                if (value.type && value.type.code == 'ANCHOR') {
                    // 解绑主播
                    showConfirm('是否确定解绑主播?', '', () => {
                        http('/live/unbindAnchor', {}, 'POST').then(() => {
                            message.success('解绑成功!');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                    });
                } else if (value.type && value.type.code == 'ASSISTANT') {
                    // 解绑助手
                    showConfirm('是否确定解绑助手?', '', () => {
                        http(`/live/unbindAssistant/${value.accountId}`, {}, 'POST').then(() => {
                            message.success('解绑成功!');
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                    });
                }

                break;
        }
    }
    //渲染组件
    render() {
        const {
            tel,
            anchorType,
        } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <div className={styles.searchBox}>
                        <XInput
                            style={{ width: '250px' }}
                            label='主播账号'
                            placeholder='请输入主播账号'
                            value={tel}
                            bindThis={this}
                            bindName='tel'
                        />
                        <XSelect
                            style={{ width: '250px', marginLeft: '20px' }}
                            selectStyle={{ width: '100%' }}
                            label='类型'
                            placeholder='请选择类型'
                            renderData={this.props.AnchorAssistantTypeEnums}
                            dataIndex='value'
                            keyIndex='code'
                            value={anchorType.value}
                            showSearch
                            bindThis={this}
                            bindName='anchorType'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <LiveTable curTabID={this.state.curTabID} renderData={this.state.curTabID == 1 ? this.props.listMerchantAnchor : this.props.AnchorAssistantRecord} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

//映射数据源
const mapStateToProps = (state) => ({
    listMerchantAnchor: state.merchantAnchor.listMerchantAnchor,
    AnchorAssistantTypeEnums: state.merchantAnchor.AnchorAssistantTypeEnums,
    AnchorAssistantRecord: state.merchantAnchor.AnchorAssistantRecord,
});

//暴露组件
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
