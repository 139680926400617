//获取/保存订单列表
export const GET_PRODUCTORDER_LIST = 'ORDERMANAGE/GET_PRODUCTORDER_LIST';
export const SET_PRODUCTORDER_LIST = 'ORDERMANAGE/SET_PRODUCTORDER_LIST';

//获取/保存订单类型
export const GET_PRODUCTORDER_TYPE = 'ORDERMANAGE/GET_PRODUCTORDER_TYPE';
export const SET_PRODUCTORDER_TYPE = 'ORDERMANAGE/SET_PRODUCTORDER_TYPE';

//获取/保存订单状态
export const GET_PRODUCTORDER_STATUS = 'ORDERMANAGE/GET_PRODUCTORDER_STATUS';
export const SET_PRODUCTORDER_STATUS = 'ORDERMANAGE/SET_PRODUCTORDER_STATUS';

//获取/保存是否开票
export const GET_PRODUCTORDER_FLAGENUM = 'ORDERMANAGE/GET_PRODUCTORDER_FLAGENUM';
export const SET_PRODUCTORDER_FLAGENUM = 'ORDERMANAGE/SET_PRODUCTORDER_FLAGENUM';

// 获取/保存认购订单状态
export const GET_PRODUCTSUBORDER_STATUS = 'ORDERMANAGE/GET_PRODUCTSUBORDER_STATUS';
export const SET_PRODUCTSUBORDER_STATUS = 'ORDERMANAGE/SET_PRODUCTSUBORDER_STATUS';

//获取/保存分销订单列表
export const GET_FENXIAOORDER_LIST = 'ORDERMANAGE/GET_FENXIAOORDER_LIST';
export const SET_FENXIAOORDER_LIST = 'ORDERMANAGE/SET_FENXIAOORDER_LIST';

//获取/保存业务类型
export const GET_BUSINESS_TYPE = 'ORDERMANAGE/GET_BUSINESS_TYPE';
export const SET_BUSINESS_TYPE = 'ORDERMANAGE/SET_BUSINESS_TYPE';