/**
 * 密码弹框
 */
import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './Pwd.module.scss';
import { XInputPassword, XSelect } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { regFenToYuan } from '@/util/money';
import md5 from 'js-md5';

class FinancialPwd extends React.Component {

    state = {
        visible: this.props.visible,
        financialPwd: '',
        channelItem: {},
        serviceList: []
    }

    componentDidMount() {
        http('/settle/gatherWithdraw/getPayChannelMap', {}, 'POST').then(({ result = {} } = {}) => {
            let serviceList = [];
            const map = new Map(new Map(Object.entries(result)));
            Object.keys(result).forEach(i => {
                serviceList.push({ id: i, name: map.get(i) });
            });
            this.setState({ serviceList });
        });
    }

    // 确认
    _handleOk = () => {
        const { financialPwd, channelItem } = this.state;
        const { gatherRemitReqs } = this.props;
        if (!financialPwd) {
            message.error('密码必填！');
            return;
        }
        const data = {
            gatherRemitReqs, channelId: channelItem.id,
            financialPwd: md5(financialPwd).toUpperCase(),
        };
        http('/settle/gatherWithdraw/batchGatherRemitNew', data, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('打款成功');
                this.props.closeMode();
                this.setState({
                    visible: false,
                });
                this.props.refsh('useCache');
            }
        }).catch((e) => {
            message.error(e.message);
            this.props.closeMode();
            this.setState({
                visible: false,
            });
            this.props.refsh('useCache');
        });
    }

    // 取消
    _handleCancel = () => {
        this.props.closeMode();
        this.setState({
            visible: false,
        });
    }

    render() {
        const { visible, financialPwd, serviceList, channelItem } = this.state;
        const { totalAmount } = this.props;
        return (
            <Modal
                width={460}
                centered
                title='确认打款'
                visible={visible}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div style={{ display: 'flex', color: '#252525' }}>
                        <div>打款总额：</div>
                        <div>{regFenToYuan(totalAmount)} 元</div>
                    </div>
                    <XSelect
                        style={{ marginTop: '30px' }}
                        selectStyle={{ width: '300px' }}
                        label='服务商'
                        placeholder='请选择'
                        renderData={serviceList}
                        bindThis={this}
                        bindName='channelItem'
                        dataIndex='name'
                        keyIndex='id'
                        value={channelItem.name}
                    />
                    <XInputPassword
                        style={{ marginTop: '30px' }}
                        inputStyle={{ width: '300px' }}
                        label='支付密码'
                        placeholder='请输入密码'
                        value={financialPwd}
                        bindThis={this}
                        bindName='financialPwd'
                        isRequired={true}
                    />
                </div>
            </Modal>
        );
    }
}

FinancialPwd.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    gatherRemitReqs: PropTypes.array,
    totalAmount: PropTypes.number,
    refsh: PropTypes.func,
};

export default FinancialPwd;