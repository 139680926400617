/**
 * 商品订单
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Order from './actionTypes';

//商品订单列表
export const getProductOrderList = function* () {
    yield takeEvery(Order.GET_PRODUCTORDER_LIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/getOrderList', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_PRODUCTORDER_LIST, payload: result || {} });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//分销订单列表
export const getFenxiaoOrderList = function* () {
    yield takeEvery(Order.GET_FENXIAOORDER_LIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/getPartitionOrderList', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_FENXIAOORDER_LIST, payload: result || {} });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//商品订单类型
export const getProductOrderType = function* () {
    yield takeEvery(Order.GET_PRODUCTORDER_TYPE, function* requestData(action) {
        try {
            let result = yield http('/enum/OrderTypeEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_PRODUCTORDER_TYPE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//商品订单状态
export const getProductOrderStatus = function* () {
    yield takeEvery(Order.GET_PRODUCTORDER_STATUS, function* requestData(action) {
        try {
            let result = yield http('/enum/OrderStatusEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_PRODUCTORDER_STATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//商品是否开票
export const getProductOrderFlagEnum = function* () {
    yield takeEvery(Order.GET_PRODUCTORDER_FLAGENUM, function* requestData(action) {
        try {
            let result = yield http('/enum/FlagEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_PRODUCTORDER_FLAGENUM, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//认购订单状态
export const getProductSubOrderStatus = function* () {
    yield takeEvery(Order.GET_PRODUCTSUBORDER_STATUS, function* requestData(action) {
        try {
            let result = yield http('/enum/OrderCrowdfundingStatusEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_PRODUCTSUBORDER_STATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//业务类型
export const getBusinessType = function* () {
    yield takeEvery(Order.GET_BUSINESS_TYPE, function* requestData(action) {
        try {
            let result = yield http('/enum/OrderPartitionBusinessTypeEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_BUSINESS_TYPE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};