import React, { useState } from 'react';
import styles from './SPDModal.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message, DatePicker, Input, Select, Button, Row, Col, Radio } from 'antd';

const { RangePicker } = DatePicker;
const { Option } = Select;

const SPDModal = ({ closeMode }) => {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [dateTime, setDateTime] = useState(null);
    const [brandName, setBrandName] = useState(null);
    const [isDel, setIsDel] = useState(null);
    const [goodsShow, setGoodsShow] = useState(null);
    const [goodsId, setGoodsId] = useState('');
    const [dayType, setDayType] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [goodsSpecId, setGoodsSpecId] = useState(null);
    const [goodsSerial, setGoodsSerial] = useState(null);

    const dateSwitch = (date) => {
        return date.format('YYYY-MM-DD HH:mm:ss');
    };

    const onDownLoad = () => {
        setConfirmLoading(true);
        let startTime = null;
        let endTime = null;
        if (dateTime) {
            startTime = dateSwitch(dateTime[0]);
            endTime = dateSwitch(dateTime[1]);
        }
        const paramJson = JSON.stringify({
            dayType,
            startTime,
            endTime,
            brandName,
            isDel,
            goodsShow,
            goodsSpecId,
            goodsSerial,
            goodsIds: goodsId ? goodsId.replace(/,/g, ',').split(',') : null,
        });
        http('/goods/outer/downloadOutGoods', { paramJson }, 'POST')
            .then((res) => {
                setConfirmLoading(false);
                message.success(res.message);
                closeMode();
            })
            .catch(error => {
                setConfirmLoading(false);
                message.error(error.message);
            });
    };

    return <Modal
        width={600}
        centered
        title='请选择下载奢批商品的条件'
        bodyStyle={{ paddingTop: 0, paddingBottom: 20 }}
        visible={true}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        onCancel={closeMode}
        footer={false}
    >
        <div className={styles.container}>
            <Row className={styles.itemBox}>
                <Col span={4}>
                    <div>商品ID集合</div>
                </Col>
                <Col span={20}>
                    <div className={styles.content}>
                        <Input.TextArea
                            style={{ width: '600px' }}
                            placeholder='请输入商品ID，多个逗号隔开,最多50个'
                            value={goodsId}
                            onChange={({ target: { value } }) => {
                                if (goodsId.replace(/,/g, ',').split(',').length < 50) {
                                    setGoodsId(value);
                                } else {
                                    message.warning('商品id最多50个');
                                }
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row className={styles.itemBox}>
                <Col span={4}>
                    <div className={styles.label}>更新时间</div>
                </Col>
                <Col span={20}>
                    <div className={styles.content}>
                        <RangePicker
                            disabled={disabled}
                            showTime={{ format: 'HH:mm:ss' }}
                            format="YYYY-MM-DD HH:mm:ss"
                            placeholder={['开始时间', '结束时间']}
                            onOk={value => {
                                setDateTime(value);
                                setDayType(null);
                            }}
                            onChange={value => setDateTime(value)}
                            value={dateTime}
                        />
                    </div>
                </Col>
            </Row>
            <Row className={styles.itemBox}>
                <Col span={20} offset={4}>
                    <div className={styles.content}>
                        <Radio.Group
                            value={dayType}
                            buttonStyle='solid'
                            onChange={({ target: { value } }) => {
                                setDateTime(null);
                                setDayType(value);
                                setDisabled(true);
                            }}>
                            <Radio.Button value={1}>近7日</Radio.Button>
                            <Radio.Button value={2} style={{ marginLeft: '20px' }}>近30日</Radio.Button>
                        </Radio.Group>
                    </div>
                </Col>
            </Row>
            <Row className={styles.itemBox}>
                <Col span={4}>
                    <div className={styles.label}>品牌名称</div>
                </Col>
                <Col span={20}>
                    <div className={styles.content}>
                        <Input
                            placeholder='请输入品牌名称'
                            value={brandName}
                            onChange={({ target: { value } }) => setBrandName(value)}
                        />
                    </div>
                </Col>
            </Row>
            <Row className={styles.itemBox}>
                <Col span={4}>
                    <div className={styles.label}>删除状态</div>
                </Col>
                <Col span={8}>
                    <div className={styles.content}>
                        <Select
                            style={{ width: 160 }}
                            placeholder='请选择'
                            onChange={value => setIsDel(value)}
                            value={isDel}
                        >
                            <Option value={0}>未删除</Option>
                            <Option value={1}>删除</Option>
                        </Select>
                    </div>
                </Col>
                <Col span={4}>
                    <div className={styles.label}>是否上架</div>
                </Col>
                <Col span={8}>
                    <div className={styles.content}>
                        <Select
                            style={{ width: 160 }}
                            placeholder='请选择'
                            onChange={value => setGoodsShow(value)}
                            value={goodsShow}
                        >
                            <Option value={1}>上架</Option>
                            <Option value={0}>下架</Option>
                        </Select>
                    </div>
                </Col>
            </Row>
            <Row className={styles.itemBox}>
                <Col span={4}>
                    <div className={styles.label}>平台编码</div>
                </Col>
                <Col span={8}>
                    <div className={styles.content}>
                        <Input
                            style={{ width: 160 }}
                            placeholder='请输入'
                            onChange={e => setGoodsSpecId(e.target.value)}
                            value={goodsSpecId}
                        />
                    </div>
                </Col>
                <Col span={4}>
                    <div className={styles.label}>货号</div>
                </Col>
                <Col span={8}>
                    <div className={styles.content}>
                        <Input
                            style={{ width: 160 }}
                            placeholder='请输入'
                            onChange={e => setGoodsSerial(e.target.value)}
                            value={goodsSerial}
                        />
                    </div>
                </Col>
            </Row>
            <Row className={styles.itemBox}>
                <Col span={6} offset={6}>
                    <div className={styles.btn}>
                        <Button
                            type='primary'
                            onClick={onDownLoad}
                        >下载商品</Button>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.btn}>
                        <Button
                            style={{ marginLeft: '10px' }}
                            onClick={() => {
                                setDateTime(null);
                                setBrandName(null);
                                setIsDel(null);
                                setGoodsShow(null);
                                setGoodsId('');
                                setDayType(null);
                                setDisabled(false);
                                setGoodsSpecId(null);
                                setGoodsSerial(null);
                            }}
                        >重置</Button>
                    </div>
                </Col>
            </Row>
        </div>
    </Modal>;
};

SPDModal.propTypes = {
    closeMode: PropTypes.func.isRequired
};
export default SPDModal;