/**
 * 操作记录弹框
 * */
import React from 'react';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './operation.module.scss';
import { message } from 'antd';
import model from '@/reducers/supplierwarehouse/saleorder/model';
import TableContent from './components/TableContent';
import http from '@/assets/api/http';

class Operation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
            reason: ''
        };
    }
    componentDidMount() {
        const { modeParam } = this.props;
        this.props.getOperationList({id: modeParam});
    }
    _paginationChange = (pageSize, pageNum) => {
        const { modeParam } = this.props;
        this.setState({ pageSize, pageNum}, () => {
            this.props.getOperationList({id: modeParam, pageSize, pageNum});
        });
    }

    // 确认
    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
   
    render() {
        const { visible, confirmLoading } = this.state;

        return (
            <Modal
                width={850}
                height={500}
                centered
                title='操作记录'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='返回'
                okText='确认'
            >
                <div className={styles.flexBoxContainer}>
                    {/* <div className={styles.title}>操作记录</div> */}
                    <TableContent
                        renderData={this.props.OperationList}
                        // tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                    />
                </div>
            </Modal>
        );
    }
}

Operation.propTypes = {
    visible: PropTypes.bool.isRequired,
    closeMode: PropTypes.func.isRequired,
    modeParam: PropTypes.number,
    getOperationList: PropTypes.func,
    OperationList: PropTypes.object
};
const mapStateToProps = (state) => ({
    OperationList: state.saleorderwarehouse.OperationList,
});
export default connect(mapStateToProps, {
    ...model.actions
})(Operation);