import { createModel } from '@/store/tools';

const model = {
    namespace: 'taxdeclaration',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getReconciliationList': T('getReconciliationList')
        },
        actions: {
            'getReconciliationList': A('getReconciliationList')
        },
        sagas: {
            'getReconciliationList': S('getReconciliationList', '/plat/withdrawal/listTaxDeclaration')
        },
        reducers: {
            'reconciliationList': R('getReconciliationList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
        }
    })
};
export default createModel(model);