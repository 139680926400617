/**
 * 供应商品同步锁定
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XTextArea, XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import model from '@/reducers/supplierwarehouse/goodsgenlock/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
class GoodsGenlock extends React.Component {

    static propTypes = {
        history: PropTypes.object,
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getGoodsGenlockData: PropTypes.func,
        goodsGenlock: PropTypes.object,
        getGoodsStateEnum: PropTypes.func,
        goodsStateEnum: PropTypes.array,
    }

    static defaultProps = {
        goodsGenlock: { dataList: [], pageNum: 1, pageSize: 100, resultTotal: 0 },
        goodsStateEnum: []
    }

    state = {
        goodsNos: '',//货号集合
        goodsName: '', //货品名称
        stateItem: {},//状态
        batchKeys: [],
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getGoodsStateEnum } = this.props;
        getGoodsStateEnum();
        keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
    }

    // 查询
    _searchHandle = (useCache) => {
        const { goodsNos, goodsName, stateItem, pageNum, pageSize } = this.state;
        this.searchCache = {
            goodsNoStr: goodsNos.replace(/，/g, ','),
            goodsName, goodsStatus: stateItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : 100, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getGoodsGenlockData(this.searchCache);
        KeepAlive.saveCache(this.searchCache); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ goodsNos: '', goodsName: '', stateItem: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //请求函数
    _sendRequest = (url, data) => {
        let params = data;
        const { batchKeys } = this.state;
        if (!data && !batchKeys.length) {
            message.warn('请勾选商品');
            return;
        }
        if (!data) {
            params = { ids: batchKeys };
        }
        http(url, params, 'POST')
            .then(res => {
                message.success(res.message);
                this.setState({ batchKeys: [] }, () => {
                    this._searchHandle('useCache');
                });
            })
            .catch(error => {
                message.error(error.message);
            });
    }

    //分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    //页面跳转
    _jumpPage = (key) => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '商品详情',
            path: '/home/supplierwarehouse/goodsgenlock/goodsinfo/' + key
        });
        history.push({
            pathname: '/home/supplierwarehouse/goodsgenlock/goodsinfo/' + key
        });
    }

    render() {
        const { goodsNos, goodsName, stateItem, batchKeys } = this.state;
        const { goodsGenlock, goodsStateEnum } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle(); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria1}>
                        <XTextArea
                            style={{ marginRight: '20px' }}
                            label='货号集合'
                            inputStyle={{ width: '300px' }}
                            placeholder='请输入货号，多个逗号隔开,最多100个'
                            value={goodsNos}
                            rows={1}
                            onChange={value => {
                                if (value.replace(/，/g, ',').split(',').length < 100) {
                                    this.setState({ goodsNos: value });
                                } else {
                                    message.warning('货号集合最多100个');
                                }
                            }}
                        />
                        <XInput
                            inputStyle={{ width: '150px', marginRight: '20px' }}
                            label='货品名称'
                            placeholder='请输入货品名称'
                            value={goodsName}
                            bindThis={this}
                            bindName='goodsName'
                        />
                        <XSelect
                            selectStyle={{ width: '150px', marginRight: '20px' }}
                            label='是否上架'
                            placeholder='请选择状态'
                            renderData={goodsStateEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={stateItem.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='stateItem'
                        />
                        <XOKButton
                            style={{ marginRight: '20px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.export}>
                        <XOKButton
                            label='移除锁定'
                            onClick={() => this._sendRequest('/goods/inventory/lock/rmmove')}
                        />
                    </div>
                    <TableContent
                        renderData={goodsGenlock}
                        paginationChange={this._paginationChange}
                        jumpPage={this._jumpPage}
                        sendRequest={this._sendRequest}
                        batchKeys={batchKeys}
                        bindThis={this}
                    />
                </div>
            </KeepAlive>
        );
    }
}
export default connect(({ goodsGenLock }) => ({
    goodsGenlock: goodsGenLock.goodsGenlockData,
    goodsStateEnum: goodsGenLock.goodsStateEnum
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(GoodsGenlock);