import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';


const Preview = ({
    data = '',
    closeMode = () => { }
}) => {



    return <Modal
        width={600}
        centered
        bodyStyle={{ padding: '10px' }}
        title='预览'
        visible={true}
        confirmLoading={false}
        onOk={() => closeMode(true, 'printer')}
        onCancel={() => closeMode(false, 'printer')}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div style={{ width: '100%' }}>
            <iframe width='100%' height='600px' src={data}>您的浏览器不支持iframe，请更换浏览器查看</iframe>
        </div>
    </Modal>;
};
Preview.propTypes = {
    data: PropTypes.string,
    closeMode: PropTypes.func
};
export default Preview;
