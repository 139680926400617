import * as T from './actiontypes';

//获取商品品牌列表
export const getProductBrand = (params = {}) => ({
    type: T.GET_PRODUCT_BRAND,
    payload: params
});

export const getProductBrandSele = (isApply) => ({
    type: T.GET_PRODUCT_BRANDSELE,
    payload: { isApply }
});

export const getProductBrandDetail = ({ auditId, merchantId }) => ({
    type: T.GET_PRODUCT_BRANDDETAIL,
    payload: {
        auditId, merchantId
    }
});