/**
 * 仓库管理saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as T from './actiontypes';
import http from '@/assets/api/http';

// 获取已分配出库单列表
export const getSupplierListTableSource = function* () {
    yield takeEvery(T.GET_SUPPLIERLIST_TABLESOURCE, function* requestData(action) {
        try {
            let result = yield http('/merchant/supplier/listSupplier', action.payload, 'POST');
            yield put({ type: T.SET_SUPPLIERLIST_TABLESOURCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getShopTypes = function* () {
    yield takeEvery(T.GET_SHOPTYPES, function* requestData(action) {
        try {
            let result = yield http('/enum/ShopTypeEnum', action.payload, 'POST');
            yield put({ type: T.SET_SHOPTYPES, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getGoodsTypes = function* () {
    yield takeEvery(T.GET_GOODSTYPES, function* requestData(action) {
        try {
            let result = yield http('/goods/category/linkage', action.payload, 'POST');
            yield put({ type: T.SET_GOODSTYPES, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取仓库类型
export const getWarehouseType = function* () {
    yield takeEvery(T.GET_WAREHOUSE_TYPE, function* requestData(action) {
        try {
            let result = yield http('/enum/MerchantWarehouseTypeEnum', action.payload, 'POST');
            yield put({ type: T.SET_WAREHOUSE_TYPE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取区域：省市区
export const getListAreaByParentId = function* () {
    yield takeEvery(T.GET_ACCOUNT_AREA, function* requestData(action) {
        try {
            let result = yield http('/merchant/area/listAreaByParentId/' + action.payload.id, { id: action.payload.id }, 'POST');
            switch (action.payload.type) {
                case 'province':
                    yield put({ type: T.SET_PROVINCE, payload: result });
                    break;
                case 'city':
                    yield put({ type: T.SET_CITY, payload: result });
                    break;
                case 'street':
                    yield put({ type: T.SET_STREET, payload: result });
                    break;
            }
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getInvoicelSelect = function* () {
    yield takeEvery(T.GET_INVOICEL_SELECT, function* requestData(action) {
        try {
            let result = yield http('/merchant/supplier/invoicelUnitList', action.payload, 'POST');
            yield put({ type: T.SET_INVOICEL_SELECT, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
export const getlistServiceProviderKetName = function* () {
    yield takeEvery(T.GET_SUPPLIER_SELECT, function* requestData(action) {
        try {
            let result = yield http('/merchant/supplier/listServiceProviderKetName', action.payload, 'POST');
            yield put({ type: T.SET_SUPPLIER_SELECT, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 实体加盟中运营中心、区域、数据依据地址的市，区参数
 */
export const radiotype = function* () {
    yield takeEvery(T.GET_RADIOTYPE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象 
        try {
            let data = yield http('/admin/company/regionalCompany', action.payload, 'POST');
            console.log(data);
            yield put({ type: T.SET_RADIOTYPE_DATA, payload: data });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};