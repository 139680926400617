import * as T from './actiontypes';
//仓库商品库存数据源(saga)

/**
 *  列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} goodsName 商品名
 * @param {string} goodsState 商品状态
 * @param {string} goodsNo 货号
 * @param {string} goodsType 业务类型 
 * @param {array} tagIds 标签ID
 * @param {string} categoryId 分类ID
 */
export const getWarehouseStockList = (dataload) => ({
    type: T.GET_STOCKLIST_DATA,
    payload: {
        ...dataload
    }
});


