/**
 * 【商户提现记录列表】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm, XDatePicker } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/reconciliationdata/model';
import AdvertTable from './components/AdvertTable';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {

    state = {
        companyStatus: {},
        status: {},
        chargeValue: [],
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getOutAccountWithdrawStatusEnum();
    }
    // 查询
    _searchHandle = (useCache) => {
        const { startTime, entTime, id, pageNum, pageSize, status } = this.state;
        this.searchCache = {
            startTime,
            entTime,
            id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            status: status.code,
            subjectType: 'MERCHANT',
            merchantId: 11,
        };
        this.props.getwithdrawListData(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ startTime: undefined, entTime: undefined, id: '', pageNum: 1, pageSize: 10, status: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { id, status, startTime, entTime } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.row1}>
                        <XInput
                            style={{ width: '246px' }}
                            inputStyle={{ width: '195px' }}
                            label='提现单号'
                            placeholder='请输入'
                            value={id}
                            bindThis={this}
                            bindName='id'
                            mode='number'
                            maxLength={16}
                            showLength={false}
                        />
                        <XSelect
                            showSearch
                            style={{ width: '246px', marginLeft: 20 }}
                            selectStyle={{ width: '195px' }}
                            label='状态'
                            placeholder='请选择'
                            renderData={this.props.OutAccountWithdrawStatusEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={status.value}
                            bindThis={this}
                            bindName='status'
                        />

                        <XDatePicker
                            label='提现日期'
                            value={startTime}
                            bindThis={this}
                            bindName='startTime'
                            isFormat={true}
                        />
                        <XDatePicker
                            style={{ marginRight: 20 }}
                            value={entTime}
                            bindThis={this}
                            bindName='entTime'
                            isFormat={true}
                        />

                        <XOKButton
                            style={{ width: '60px', marginLeft: '15px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <AdvertTable renderData={this.props.withdrawListData} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getwithdrawListData: PropTypes.func,
    withdrawListData: PropTypes.object,
    getOutAccountWithdrawStatusEnum: PropTypes.func,
    OutAccountWithdrawStatusEnum: PropTypes.array
};

const mapStateToProps = (state) => ({
    withdrawListData: state.reconciliationdata.withdrawListData,
    OutAccountWithdrawStatusEnum: state.reconciliationdata.OutAccountWithdrawStatusEnum
});

export default connect(mapStateToProps, {
    addOneBreadcrumbPath, keepSecondNavBreadcrumb,...model.actions
})(Main);