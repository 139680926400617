import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

// 获取集采收益待打款列表
export const getReadyPayList = function* () {
    yield takeEvery(T.GET_READY_PAY_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/gatherWithdraw/listWaitRemit', action.payload, 'POST');
            yield put({ type: T.SET_READY_PAY_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取集采收益已打款列表
export const getAlreadyPayList = function* () {
    yield takeEvery(T.GET_ALREADY_PAY_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/gatherWithdraw/listDoneRemit', action.payload, 'POST');
            yield put({ type: T.SET_ALREADY_PAY_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取集采收益待打款详情
export const getPayDetail = function* () {
    yield takeEvery(T.GET_PAY_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/settle/gatherWithdraw/getRemitDetail', action.payload, 'POST');
            yield put({ type: T.SET_PAY_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取通道枚举
export const getPayChannelEnum = function* () {
    yield takeEvery(T.GET_PAY_CHANNEL_ENUM, function* requestData(action) {
        try {
            let result = yield http('/enum/getQueryBalanceTypeEnum', action.payload, 'POST');
            yield put({ type: T.SET_PAY_CHANNEL_ENUM, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};