import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Form, Button, message, Icon } from 'antd';
import CustomUpload, { generateFileList } from '@/components/NewUpload';
import { XMediaFn } from '@/components/xqxc_ui';

class Main extends React.Component {

    state = {
        loading: false, // 加载中
        required: true,
        taxCommissionUrl: '', // 委托书
        cardFront: '',
        cardBack: '',
    }

    componentDidMount() {
        http('/walletUnion/getTaxCommission').then((response) => {
            if (response.status == 200) {
                const {
                    taxCommissionUrl, // 委托书
                    cardFront, // 身份证正面
                    cardBack, // 身份证反面
                } = response.result;

                this.setState({
                    taxCommissionUrl,
                    cardFront,
                    cardBack
                });
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    render() {
        const { loading, required, taxCommissionUrl, cardFront, cardBack } = this.state;

        return (
            <div className={styles.container}>
                <div style={{ display: 'flex' }}>
                    <div>委托书：</div>
                    <XMediaFn key={taxCommissionUrl} dataSource={taxCommissionUrl} />
                </div>
                {/* <div style={{ display: 'flex', marginTop: '20px' }}>
                    <div>身份证：</div>
                    <XMediaFn dataType='image' dataSource={cardFront} />
                    <XMediaFn dataType='image' dataSource={cardBack} />
                </div> */}
                <div style={{ display: 'flex', marginTop: '20px' }}>
                    <div>当前状态：您提交的资料正在审核中，请耐心等待。</div>
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    form: PropTypes.object, // 表单校验
    update: PropTypes.func,
};

export default Main;