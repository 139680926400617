import React from 'react';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './OrderTable.module.scss';
import { regFenToYuan } from '@/util/money';

const Sign = {
    RECHARGE: '+',
    MALL_CONSUME: '-',
    MALL_CONSUME_RERURN: '+'
};
class OrderTable extends React.Component {

    state = {}

    _takeColumns = () => [
        {
            title: '业务单号',
            dataIndex: 'bussinessId',
            key: 'bussinessId',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '完成时间',
            dataIndex: 'gmtFinish',
            key: 'gmtFinish',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '收支类型',
            dataIndex: 'type.value',
            key: 'type.value',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '金额',
            dataIndex: 'amount',
            key: 'amount',
            width: cellWidth.time,
            align: 'center',
            render: (text, item) => tooltip((Sign[item.type.code] || '') + regFenToYuan(text))
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ x: '100%' }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default OrderTable;
