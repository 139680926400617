import * as C from './actiontypes';

// 酒店列表
export const getHotelCommentList = (param) => ({
    type: C.GET_HOTELCOMMENT_LIST,
    payload: param
});
// 评论详情
export const getHotelCommentDetail = (param) => ({
    type: C.GET_HOTELCOMMENT_DETAIL,
    payload: param
});
// 商品分类
export const getHotelClassify = (param) => ({
    type: C.GET_HOTELCOMMENT_CLASSIFY,
    payload: param
});