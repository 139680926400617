import Login from '../views/login';
import Home from '../views/home';
import Welcome from '../views/home/welcome';
import PayFail from '../views/home/welcome/modal/AllPay/components/PayFail';
import PaySucess from '../views/home/welcome/modal/AllPay/components/PaySucess';
import AsyncComponent from './asyncComponent.js';
import Setting from '../views/setting';
import { ProductOrder, Transaction, ShopOrder, CollectTrade, Ordersales, Stockearorder, Fenxiaoorder, Wholesaleorder, HotelOrder, Cityorder } from '../views/ordermanage';
import {
    ProductBrand, ProductLabel, ProductList, ProductClassify,
    FreightTemplate, Inventory, ProductRate, BrandApply,
    RecommendGoodSet, AnchorGoods, WholesaleGoods
} from '../views/productmanage';
import { DataGraph } from '../views/workbench';
import { merchantlist, storelist, storefitting, ShopGoods, ShopRecGoods, Standardproduct } from '../views/businessmanage';
import { salelist, saleorder, } from '../views/salemanage';
import {
    Logistics, Secretkey, financialsetting, AfterSales, MaterialCategory, MaterialList, Paramsetting, Systemsetting, HotelSetting, SetUnionPay, BindCard, TaxCertificate,
    MerchantDeal, Supplement, DeliverGoodsSet, DeliverTemplate, UpdateEnterInfo, PrinterSetting, EntryInformation, BusinessCategory
} from '../views/system';
import { online, customerList, accountCustomer } from '../views/customermanage';
import { EmployeeList, AssignAuthority, structureManage } from '../views/permissionsmanage';
import { productcomment, hotelcomment } from '../views/commentmanage';
import { storehouselist, outdeliveryorder, unassignedorder } from '../views/storehousemanage';
import {
    orderreceivable, warehousestatement, invoicelist, invoicemanage, paymanage, platformreconciliation, suppliersettlement, CollectMoneyTrade, CollectSettle,
    CollectPay, Account, PurchaseContract, walletreconciliation, taxdeclaration, BillQuery, Merchantwithdrawalrecords, DailyStatement, MerchantWallet, DailyStatement2,
    PreviewCollectionManage, UserInvoice, SupplierWithdraw
} from '../views/storehousefinancialmanage';
import { supplierlist } from '../views/suppliermanage';
import { WarehouseProductList, WarehouseProductReserve, WarehouseProductPrice, WarehouseProductkind, ProductConnect, ReserveConnect, PriceAdjustment, WholesaleWholesaleGoods } from '../views/warehousemanage';
import { ReadyDeliver, AlreadyDeliver, CancelDeliver, SpDeliver } from '../views/delivermanage';
import { ActivityProductAudit, SupplierAudit, ContractAudit, BalanceAudit, PurchasingAudit, StoreAudit } from '../views/auditmanage';
import { IllegalList } from '../views/illegalRecord';
import { Activities, CardTicket, Advocacytask, Purchasing, Livegoods, AdvocacyMerchants, StoreActivity, InviteMember, Memberlist, Fightrecord } from '../views/marketingmanage';
import { ServiceList, ServiceHistory } from '../views/servicelistingmanage';
import { MerchantNotice } from '../views/strategymanage';
import { GDCList } from '../views/gdcmanage';
import { LiveList, MerchantAnchor, MerchantLive } from '../views/commercialcollege';//直播管理
import { BeanShopList, BeanShopListIncomeDetails, BeanShopListDetails, BeanShopListCard, BeanShopWapply } from '../views/beanshopmanage'; //人气值管理
import { Contractlist } from '../views/contractmanage'; // 合同管理
import { TemplateForm, RecordsForm } from '../views/statementmanage';
import { HouseList } from '../views/houseresourcemanage'; //房源管理
import { VerificationList } from '../views/checkinverification'; //入住核销
import { ReceiveOrderList } from '../views/receiveordermanage'; // 接单管理
import { MessageList } from '../views/messagemanage';// 消息列表
import { SupplierWarehouseOutDelivery, SupplierSaleList, VisitantBrand, BalanceenQuiry, GoodsGenlock } from '../views/supplierwarehouse';//供应商仓库
import { OperationManual, Authorization } from '../views/expresswaybill'; //电子面单
import { ChargeoffList } from '../views/chargeoffmanage'; // 核销管理

// 工作台
const workbench = [
    { path: '/datagraph', title: '数据统计', component: DataGraph },
];

// 店铺管理
const businessmanageRoutes = [
    { path: '/merchantlist', title: '店铺列表', component: merchantlist },
    { path: '/storelist', title: '店铺商品列表', component: storelist },
    { path: '/standardproduct', title: '标准商品库', component: Standardproduct },
    { path: '/standardproduct/detail/:id', title: '标准商品详情', component: AsyncComponent(() => import('@/views/businessmanage/standardproduct/pages/standardDetail')), show: 'none' },
    { path: '/storelist/detailInfo/:id', title: '店铺商品详情', component: AsyncComponent(() => import('@/views/businessmanage/storelist/detailInfo')), show: 'none' },
    { path: '/merchantlist/createInfo', title: '新建店铺', component: AsyncComponent(() => import('@/views/businessmanage/merchantlist/createInfo')), show: 'none' },
    { path: '/merchantlist/editorInfo/:id', title: '编辑店铺', component: AsyncComponent(() => import('@/views/businessmanage/merchantlist/editorInfo')), show: 'none' },
    { path: '/storefitting', title: '店铺装修', component: storefitting },
    { path: '/storefitting/customentrance/', title: 'APP模版选择', component: AsyncComponent(() => import('@/views/businessmanage/storefitting/pages/customentrance')), show: 'none' },
    { path: '/storefitting/customlanding/', title: 'APP自定义编辑', component: AsyncComponent(() => import('@/views/businessmanage/storefitting/pages/customlanding')), show: 'none' },
    { path: '/storefitting/customlist/', title: 'APP自定义列表', component: AsyncComponent(() => import('@/views/businessmanage/storefitting/pages/customlist')), show: 'none' },
    { path: '/shopgoods', title: '同城商品列表', component: ShopGoods },
    { path: '/shopgoods/goodsinfo/:goodsId', title: '同城商品详情', component: AsyncComponent(() => import('@/views/businessmanage/shopgoods/detail')), show: 'none' },
    { path: '/shopgoods/goodslibrary/:classid', title: '从商品库上架商品', component: AsyncComponent(() => import('@/views/businessmanage/shopgoods/goodsLibrary')), show: 'none' },
    { path: '/shopgoods/ratemodification/:type', title: '上架确认服务清单', component: AsyncComponent(() => import('@/views/warehousemanage/productlist/pages/main/components/BatchPutAway')), show: 'none' },
    { path: '/shopgoods/creategoods/:classid/:className', title: '创建同城商品', component: AsyncComponent(() => import('@/views/businessmanage/shopgoods/createGoods')), show: 'none' },
    { path: '/shopgoods/editgoods/:goodsId', title: '编辑同城商品', component: AsyncComponent(() => import('@/views/businessmanage/shopgoods/editGoods')), show: 'none' },
    { path: '/shoprecgoods', title: '同城推荐列表', component: ShopRecGoods },
];

// 商品管理
const productmanageRoutes = [
    { path: '/productlist', title: '商品列表', component: ProductList },
    { path: '/productlist/detail/:id', title: '商品详情', component: AsyncComponent(() => import('@/views/productmanage/productList/pages/detail')), show: 'none' },
    // { path: '/productlist/allskulist', title: '所有分销商品SKU列表', component: AsyncComponent(() => import('@/views/productmanage/productList/pages/allskulist')), show: 'none' },
    { path: '/productlist/skulist/:id', title: '分销商品SKU列表', component: AsyncComponent(() => import('@/views/productmanage/productList/pages/skulist')), show: 'none' },
    { path: '/productlist/distributionrec', title: '分销推荐列表', component: AsyncComponent(() => import('@/views/productmanage/productList/pages/distributionrec')), show: 'none' },
    { path: '/productlist/edit/:id', title: '商品编辑', component: AsyncComponent(() => import('@/views/productmanage/productList/pages/edit')), show: 'none' },
    { path: '/productbrand', title: '商品品牌', component: ProductBrand },
    { path: '/productbrand/apply', title: '申请商品品牌', component: AsyncComponent(() => import('@/views/productmanage/productBrand/pages/apply')), show: 'none' },
    { path: '/productbrand/detail/:id', title: '商品品牌详情', component: AsyncComponent(() => import('@/views/productmanage/productBrand/pages/detail')), show: 'none' },
    { path: '/productlabel', title: '商品标签', component: ProductLabel },
    { path: '/productclassify', title: '商品分类', component: ProductClassify },
    { path: '/productclassify/apply', title: '申请商品分类', component: AsyncComponent(() => import('@/views/productmanage/productClassify/pages/apply')), show: 'none' },
    { path: '/inventory', title: '商品库存', component: Inventory },
    // { path:'/inventory/detail/:id', title:'商品详情', component: AsyncComponent(() => import('@/views/productmanage/inventory/pages/detail')), show: 'none'},
    { path: '/productRate', title: '商品费率列表', component: ProductRate },
    { path: '/productRate/detail/:id', title: '费率详情', component: AsyncComponent(() => import('@/views/productmanage/productRate/pages/detail')), show: 'none' },
    // { path: '/productRate/edit/:id', title: '费率编辑', component: AsyncComponent(() => import('@/views/productmanage/ProductRate/pages/edit')), show: 'none' },
    { path: '/recommendgoodset', title: '推荐商品设置', component: RecommendGoodSet },
    { path: '/recommendgoodset/detail/:id', title: '商品详情', component: AsyncComponent(() => import('@/views/productmanage/recommendGoodSet/pages/detail')), show: 'none' },
    { path: '/productbrandapply', title: '品牌申请', component: BrandApply },
    { path: '/productbrandapply/apply/:id', title: '品牌申请详情', component: AsyncComponent(() => import('@/views/productmanage/brandApply/pages/detail')), show: 'none' },
    { path: '/productlist/allskulist', title: '分销商品SKU列表', component: AsyncComponent(() => import('@/views/productmanage/productList/pages/allskulist')) },
    { path: '/anchorgoods', title: '主播商品列表', component: AnchorGoods },
    { path: '/anchorgoods/creategoods', title: '创建主播商品', component: AsyncComponent(() => import('@/views/productmanage/anchorgoods/createGoods')), show: 'none' },
    { path: '/anchorgoods/editgoods/:id', title: '编辑主播商品', component: AsyncComponent(() => import('@/views/productmanage/anchorgoods/editGoods')), show: 'none' },
    { path: '/anchorgoods/ratemodification/:type', title: '上架确认服务清单', component: AsyncComponent(() => import('@/views/warehousemanage/productlist/pages/main/components/BatchPutAway')), show: 'none' },
    { path: '/anchorgoods/detail/:id', title: '主播商品详情', component: AsyncComponent(() => import('@/views/productmanage/anchorgoods/detail')), show: 'none' },
    { path: '/wholesalegoods', title: '批发商品', component: WholesaleGoods },
    { path: '/wholesalegoods/goodsinfo/:goodsId/:curTabID', title: '批发商品详情', component: AsyncComponent(() => import('@/views/productmanage/wholesalegoods/detail')), show: 'none' },
    { path: '/wholesalegoods/goodslist', title: '商品列表', component: AsyncComponent(() => import('@/views/productmanage/wholesalegoods/goodslist')), show: 'none' },
];

// 订单管理
const ordermanageRoutes = [
    { path: '/productorder', title: '商品订单', component: ProductOrder },
    { path: '/productorder/orderhistory', title: '操作记录', component: AsyncComponent(() => import('@/views/ordermanage/productorder/pages/orderhistory')), show: 'none' },
    { path: '/productorder/workorder', title: '新建工单', component: AsyncComponent(() => import('@/views/ordermanage/productorder/pages/workorder')), show: 'none' },
    { path: '/productorder/orderdetail', title: '查看详情', component: AsyncComponent(() => import('@/views/ordermanage/productorder/pages/orderdetail')), show: 'none' },
    { path: '/transaction', title: '订单交易明细', component: Transaction },
    { path: '/transaction/:orderId', title: '订单交易明细', component: AsyncComponent(() => import('@/views/ordermanage/transaction/pages/main')), show: 'none' },
    { path: '/ordersales', title: '订单销售明细', component: Ordersales },
    { path: '/shoporder', title: '人气值商城订单', component: ShopOrder },
    { path: '/shoporder/orderhistory', title: '操作记录', component: AsyncComponent(() => import('@/views/ordermanage/shoporder/pages/orderhistory')), show: 'none' },
    { path: '/shoporder/orderdetail', title: '查看详情', component: AsyncComponent(() => import('@/views/ordermanage/shoporder/pages/orderdetail')), show: 'none' },
    { path: '/collecttrade', title: '集采交易', component: CollectTrade },
    { path: '/collecttrade/detail/:buyerId/:orderId', title: '查看详情', component: AsyncComponent(() => import('@/views/ordermanage/collecttrade/pages/detail')), show: 'none' },
    { path: '/hotelorder', title: '酒店订单', component: HotelOrder },
    { path: '/hotelorder/orderhistory', title: '操作记录', component: AsyncComponent(() => import('@/views/ordermanage/hotelorder/pages/orderhistory')), show: 'none' },
    { path: '/hotelorder/orderdetail', title: '查看详情', component: AsyncComponent(() => import('@/views/ordermanage/hotelorder/pages/orderdetail')), show: 'none' },
    { path: '/stockearorder', title: '股票收益订单', component: Stockearorder },
    { path: '/fenxiaoorder', title: '分销订单', component: Fenxiaoorder },
    { path: '/fenxiaoorder/orderhistory', title: '操作记录', component: AsyncComponent(() => import('@/views/ordermanage/fenxiaoorder/pages/orderhistory')), show: 'none' },
    { path: '/fenxiaoorder/orderdetail', title: '查看详情', component: AsyncComponent(() => import('@/views/ordermanage/fenxiaoorder/pages/orderdetail')), show: 'none' },
    { path: '/wholesaleorder', title: '批发订单', component: Wholesaleorder },
    { path: '/wholesaleorder/orderhistory', title: '操作记录', component: AsyncComponent(() => import('@/views/ordermanage/wholesaleorder/pages/orderhistory')), show: 'none' },
    { path: '/wholesaleorder/orderdetail', title: '查看详情', component: AsyncComponent(() => import('@/views/ordermanage/wholesaleorder/pages/orderdetail')), show: 'none' },
    { path: '/cityorder', title: '同城订单', component: Cityorder },
    { path: '/cityorder/orderhistory', title: '操作记录', component: AsyncComponent(() => import('@/views/ordermanage/cityorder/pages/orderhistory')), show: 'none' },
    { path: '/cityorder/orderdetail', title: '查看详情', component: AsyncComponent(() => import('@/views/ordermanage/cityorder/pages/orderdetail')), show: 'none' },
];

// 营销管理
const marketingmanageRoutes = [
    { path: '/activities', title: '商户活动', component: Activities },
    { path: '/activities/createInfo', title: '新建商户活动', component: AsyncComponent(() => import('@/views/marketingmanage/activities/pages/createInfo')), show: 'none' },
    { path: '/activities/detailInfo/:id', title: '商户活动详情', component: AsyncComponent(() => import('@/views/marketingmanage/activities/pages/detailInfo')), show: 'none' },
    { path: '/activities/editorInfo/:id/:code', title: '商户活动编辑', component: AsyncComponent(() => import('@/views/marketingmanage/activities/pages/editorInfo')), show: 'none' },
    { path: '/activities/detailInfo/listdata/:id', title: '活动商品列表详情', component: AsyncComponent(() => import('@/views/marketingmanage/activities/pages/detailInfo/detailpages/listdata/')), show: 'none' },
    { path: '/cardticket', title: '卡券管理', component: CardTicket },
    { path: '/cardticket/createInfo', title: '新建卡券', component: AsyncComponent(() => import('@/views/marketingmanage/cardticket/pages/createInfo')), show: 'none' },
    { path: '/cardticket/detailInfo/:id', title: '卡券详情', component: AsyncComponent(() => import('@/views/marketingmanage/cardticket/pages/detailInfo')), show: 'none' },
    { path: '/cardticket/editorInfo/:id', title: '卡券编辑', component: AsyncComponent(() => import('@/views/marketingmanage/cardticket/pages/editorInfo')), show: 'none' },
    { path: '/advocacytask', title: '平台活动', component: Advocacytask },
    // { path: '/advocacytask/createInfo', title: '新建平台活动', component: AsyncComponent(() => import('@/views/marketingmanage/advocacytask/pages/createInfo')), show: 'none' },
    { path: '/advocacytask/detailInfo/:id/:code', title: '平台活动详情', component: AsyncComponent(() => import('@/views/marketingmanage/advocacytask/pages/detailInfo')), show: 'none' },
    { path: '/advocacytask/editorInfo/:id', title: '参与平台活动', component: AsyncComponent(() => import('@/views/marketingmanage/advocacytask/pages/editorInfo')), show: 'none' },
    { path: '/purchasing', title: '集采管理', component: Purchasing },
    { path: '/purchasing/createInfo', title: '新建集采', component: AsyncComponent(() => import('@/views/marketingmanage/purchasing/pages/createInfo')), show: 'none' },
    { path: '/purchasing/detailInfo/:id', title: '集采详情', component: AsyncComponent(() => import('@/views/marketingmanage/purchasing/pages/detailInfo')), show: 'none' },
    { path: '/advocacyMerchants', title: '代言任务', component: AdvocacyMerchants },
    { path: '/advocacyMerchants/createInfo', title: '新建代言任务', component: AsyncComponent(() => import('@/views/marketingmanage/advocacyMerchants/pages/createInfo')), show: 'none' },
    { path: '/advocacyMerchants/detailInfo/:id', title: '代言任务详情', component: AsyncComponent(() => import('@/views/marketingmanage/advocacyMerchants/pages/detailInfo')), show: 'none' },
    { path: '/advocacyMerchants/editorInfo/:id', title: '代言任务编辑', component: AsyncComponent(() => import('@/views/marketingmanage/advocacyMerchants/pages/editorInfo')), show: 'none' },
    { path: '/livegoods', title: '直播带货', component: Livegoods },
    { path: '/livegoods/detailInfo/:id', title: '本场订单交易', component: AsyncComponent(() => import('@/views/marketingmanage/livelist/pages/detail')), show: 'none' },

    { path: '/storeActivity', title: '店铺活动', component: StoreActivity },
    { path: '/storeActivity/freight', title: '创建立减运费', component: AsyncComponent(() => import('@/views/marketingmanage/storeActivity/pages/freightpure/freight')), show: 'none' },
    { path: '/storeActivity/shopReduce', title: '创建店铺满减', component: AsyncComponent(() => import('@/views/marketingmanage/storeActivity/pages/shopReducepure/shopReduce')), show: 'none' },
    { path: '/storeActivity/freightDetail/:id', title: '立减运费详情', component: AsyncComponent(() => import('@/views/marketingmanage/storeActivity/pages/freightpure/freightDetail')), show: 'none' },
    { path: '/storeActivity/shopReduceDetail/:id', title: '店铺满减详情', component: AsyncComponent(() => import('@/views/marketingmanage/storeActivity/pages/shopReducepure/shopReduceDetail')), show: 'none' },
    { path: '/invitemember', title: '邀请会员', component: InviteMember },
    { path: '/invitemember/qrcode', title: '下载二维码', component: AsyncComponent(() => import('@/views/marketingmanage/invitemember/qrcode')), show: 'none' },
    { path: '/memberlist', title: '商户会员列表', component: Memberlist },
    { path: '/memberlist/returnsinfo/:buyerId', title: '商户会员收益明细', component: AsyncComponent(() => import('@/views/marketingmanage/memberlist/returnsinfo')), show: 'none' },
    { path: '/fightrecord', title: '抢拍记录', component: Fightrecord },
];

// 权限管理
const privilegemanageRoutes = [
    { path: '/structureManage', title: '架构管理', component: structureManage },
    { path: '/employeelist', title: '员工列表', component: EmployeeList },
    { path: '/employeelist/add', title: '新建员工', component: AsyncComponent(() => import('@/views/permissionsmanage/employeelist/pages/add')), show: 'none' },
    { path: '/employeelist/edit/:id', title: '编辑员工', component: AsyncComponent(() => import('@/views/permissionsmanage/employeelist/pages/edit')), show: 'none' },
    { path: '/employeelist/permission/:emplId', title: '仓库权限', component: AsyncComponent(() => import('@/views/permissionsmanage/employeelist/pages/permission')), show: 'none' },
    { path: '/assignauthority', title: '分配权限', component: AssignAuthority },
    // { path: '/menuConfigure', title: '菜单配置', component: menuConfigure },
];

// 售后管理
const salemanageRoutes = [
    // { path: '/salelist', title: '售后地址管理', component: salelist },
    // { path: '/salelist/edit/:warehouseId', title: '修改售后地址', component: AsyncComponent(() => import('@/views/salemanage/salelist/pages/edit')), show: 'none' },
    { path: '/saleorder', title: '商户售后列表', component: saleorder },
    { path: '/saleorder/contentInfo/:id/:buyerId/:isWholesale', title: '查看详情', component: AsyncComponent(() => import('@/views/salemanage/saleorder/pages/contentInfo')), show: 'none' },
];

// 客服管理
const customermanageRoutes = [
    { path: '/online', title: '在线客服', component: online },
    { path: '/customerlist', title: '店铺绑客服组', component: customerList },
    { path: '/customerlist/create', title: '新建绑定', component: AsyncComponent(() => import('@/views/customermanage/customerList/pages/create')), show: 'none' },
    { path: '/customerlist/edit/:entityId/:entityType', title: '编辑绑定', component: AsyncComponent(() => import('@/views/customermanage/customerList/pages/edit')), show: 'none' },
    { path: '/accountcustomer', title: '账号绑客服', component: accountCustomer },
    { path: '/accountcustomer/create', title: '新建绑定', component: AsyncComponent(() => import('@/views/customermanage/accountCustomer/pages/create')), show: 'none' },
    { path: '/accountcustomer/edit/:id', title: '编辑绑定', component: AsyncComponent(() => import('@/views/customermanage/accountCustomer/pages/edit')), show: 'none' },
    // { path: '/workOrder', title: '工单管理', component: workOrder },
    // { path: '/workOrder/detail/:id', title: '工单详情', component: AsyncComponent(() => import('@/views/customermanage/workOrder/pages/orderdetail')), show: 'none' },
    // { path: '/workOrder/add', title: '新建工单', component: AsyncComponent(() => import('@/views/customermanage/workOrder/pages/add')), show: 'none' },
];

// 仓库管理
const storehousemanageRoutes = [
    { path: '/storehouselist', title: '仓库列表', component: storehouselist },
    { path: '/outdeliveryorder', title: '出库单分配', component: outdeliveryorder },
    { path: '/outdeliveryorder/detail/:id', title: '出库单详情', component: AsyncComponent(() => import('@/views/storehousemanage/outdeliveryorder/pages/info')), show: 'none' },
    { path: '/unassignedorder', title: '未分配订单', component: unassignedorder },
    { path: '/unassignedorder/detail', title: '未分配订单详情', component: AsyncComponent(() => import('@/views/storehousemanage/unassignedorder/pages/info')), show: 'none' },
];

// 供应商管理
const suppliermanageRoutes = [
    { path: '/supplierlist', title: '供应商', component: supplierlist },
    { path: '/supplierlist/newshop/:id', title: '新建店铺', component: AsyncComponent(() => import('@/views/suppliermanage/supplierlist/pages/shop')), show: 'none' },
    { path: '/supplierlist/detail/:id', title: '查看详情', component: AsyncComponent(() => import('@/views/suppliermanage/supplierlist/pages/detail')), show: 'none' },
    { path: '/supplierlist/create', title: '新建供应商', component: AsyncComponent(() => import('@/views/suppliermanage/supplierlist/pages/create')), show: 'none' },
    { path: '/supplierlist/edit/:id', title: '编辑供应商', component: AsyncComponent(() => import('@/views/suppliermanage/supplierlist/pages/edit')), show: 'none' },
];

// 财务管理
const storehousefinancialmanageRoutes = [
    { path: '/orderreceivable', title: '订单应收金额明细', component: orderreceivable },
    { path: '/warehousestatement', title: '仓库对账', component: warehousestatement },
    { path: '/warehousestatement/luxurybill/detail/:id', title: '奢批对账单明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/warehousestatement/pages/info/luxurybill')), show: 'none' },
    { path: '/warehousestatement/bill/detail/:id', title: '仓库账单明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/warehousestatement/pages/info/bill')), show: 'none' },
    { path: '/platformreconciliation', title: '平台对账', component: platformreconciliation },
    { path: '/platformreconciliation/detail/:id', title: '对账详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/platformreconciliation/pages/info')), show: 'none' },
    { path: '/suppliersettlement', title: '供应商结算', component: suppliersettlement },
    { path: '/suppliersettlement/createcontract', title: '创建合同', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/suppliersettlement/pages/createcontract')), show: 'none' },
    { path: '/suppliersettlement/createcontract/:id', title: '合同详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/suppliersettlement/pages/createcontract')), show: 'none' },
    { path: '/suppliersettlement/validatestatement/detail/:id/:separateMethod', title: '对账详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/suppliersettlement/pages/info')), show: 'none' },
    { path: '/paymanage', title: '付款管理', component: paymanage },
    { path: '/paymanage/detail/:id', title: '付款详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/paymanage/pages/info')), show: 'none' },
    { path: '/invoicelist', title: '发票清单', component: invoicelist },
    { path: '/suppliersettlement/invoicelist/detail/:id', title: '详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/suppliersettlement/pages/info')), show: 'none' },
    { path: '/invoicelist/createinvoice', title: '新建发票', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/invoicemanage/pages/create')), show: 'none' },
    { path: '/invoicemanage', title: '发票管理', component: invoicemanage },
    { path: '/invoicemanage/createinvoice/:id', title: '编辑发票', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/invoicemanage/pages/create')), show: 'none' },
    { path: '/collecttrade', title: '集采本金退款', component: CollectMoneyTrade },
    { path: '/collecttrade/detail/:id', title: '查看详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/collecttrade/pages/detail')), show: 'none' },
    { path: '/collectsettle', title: '集采结算管理', component: CollectSettle },
    { path: '/collectpay', title: '服务商打款管理', component: CollectPay },
    { path: '/collectpay/detail/:id', title: '查看详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/collectpay/pages/alreadypay/detail')), show: 'none' },
    { path: '/purchasecontract', title: '采购合同列表', component: PurchaseContract },
    { path: '/purchasecontract/detail/:id', title: '查看详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/purchasecontract/pages/info')), show: 'none' },
    { path: '/account', title: '蚁购账户查询', component: Account },
    { path: '/walletreconciliation', title: '钱包对账', component: walletreconciliation },
    { path: '/taxdeclaration', title: '个税申报表', component: taxdeclaration },
    { path: '/billquery', title: '账单查询', component: BillQuery },
    { path: '/billquery/accountinfo', title: '账户交易明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/billquery/accountinfo')), show: 'none' },
    { path: '/billquery/walletinfo/:finishDate', title: '钱包对账明细表', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/billquery/walletinfo')), show: 'none' },
    { path: '/merchantwithdrawalrecords', title: '商户提现记录', component: Merchantwithdrawalrecords },
    { path: '/dailystatement', title: '商户日交易及结算对账单', component: DailyStatement },
    { path: '/dailystatement/detail', title: '交易流水详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/dailystatement/pages/detail')), show: 'none' },
    { path: '/dailystatement/saleorder/contentInfo/:id', title: '查看详情', component: AsyncComponent(() => import('@/views/salemanage/saleorder/pages/contentInfo')), show: 'none' },
    { path: '/dailystatement/statementdetail/:ids', title: '对账单详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/dailystatement2/pages/detail')), show: 'none' },
    { path: '/dailystatement/advanceinfo/:id/:merchantId/:billingCycle', title: '对账单详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/dailystatement/advanceinfo')), show: 'none' },
    // { path: '/dailystatement2', title: '银联日结对账单', component: DailyStatement2 },路由合并到商户日交易及结算对账单
    // { path: '/dailystatement2/detail/:ids', title: '对账单详情', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/dailystatement2/pages/detail')), show: 'none' },路由合并到商户日交易及结算对账单
    { path: '/merchantwallet', title: '商户钱包', component: MerchantWallet },
    { path: '/merchantwallet/detail/:id', title: '订单明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/merchantwallet/pages/detail')), show: 'none' },
    { path: '/merchantwallet/hoteldetail', title: '订单详情', component: AsyncComponent(() => import('@/views/ordermanage/hotelorder/pages/orderdetail')), show: 'none' },
    { path: '/merchantwallet/detailYL/:accessType/:id', title: '订单明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/merchantwallet/pages/detailYL')), show: 'none' },
    { path: '/merchantwallet/detailCommon/:accessType/:id/:merOrderId', title: '订单明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/merchantwallet/pages/detailCommon')), show: 'none' },
    { path: '/merchantwallet/transaction/:orderId', title: '订单交易明细', component: AsyncComponent(() => import('@/views/ordermanage/transaction/pages/main')), show: 'none' },

    { path: '/previewcollectionmanage', title: '预售款管理', component: PreviewCollectionManage },
    { path: '/userinvoice', title: '用户发票管理', component: UserInvoice },
    { path: '/supplierwithdrawal', title: '供应商提现', component: SupplierWithdraw },
    { path: '/supplierwithdrawal/detail/:id', title: '提现明细', component: AsyncComponent(() => import('@/views/storehousefinancialmanage/supplierwithdraw/withdrawinfo')), show: 'none' },
];

// 仓库商品管理
const warehouseRoutes = [
    { path: '/wplist', title: '仓库商品列表', component: WarehouseProductList },
    { path: '/wplist/ratemodification/', title: '批量上架确认服务清单', component: AsyncComponent(() => import('@/views/warehousemanage/productlist/pages/main/components/BatchPutAway')), show: 'none' },
    { path: '/wplist/prodcreate/:type', title: '新建商品', component: AsyncComponent(() => import('@/views/warehousemanage/productlist/pages/prodcreate')), show: 'none' },
    { path: '/wplist/prodedit/:id', title: '编辑商品', component: AsyncComponent(() => import('@/views/warehousemanage/productlist/pages/prodedit')), show: 'none' },
    { path: '/wplist/proddetail/:id', title: '商品详情', component: AsyncComponent(() => import('@/views/warehousemanage/productlist/pages/proddetail')), show: 'none' },
    { path: '/wplist/fastdetail/:id', title: '商品详情', component: AsyncComponent(() => import('@/views/productmanage/anchorgoods/detail')), show: 'none' },
    { path: '/wpreserve', title: '仓库商品库存', component: WarehouseProductReserve },
    { path: '/wpprice', title: '仓库商品结算价', component: WarehouseProductPrice },
    { path: '/wpradjustment', title: '供应商结算价调整提示', component: PriceAdjustment },
    { path: '/wholesalegoods', title: '批发商品', component: WholesaleWholesaleGoods },
    { path: '/wholesalegoods/goodsinfo/:goodsId/:curTabID', title: '批发商品详情', component: AsyncComponent(() => import('@/views/productmanage/wholesalegoods/detail')), show: 'none' },
];

// 仓库发货管理
const deliverRoutes = [
    { path: '/readydeliver', title: '待发货出库单', component: ReadyDeliver },
    { path: '/readydeliver/info/:id', title: '出库单详情', component: AsyncComponent(() => import('@/views/delivermanage/readydeliver/pages/info')), show: 'none' },
    { path: '/alreadydeliver', title: '已发货出库单', component: AlreadyDeliver },
    { path: '/alreadydeliver/orderhistory', title: '操作记录', component: AsyncComponent(() => import('@/views/delivermanage/alreadydeliver/pages/orderhistory')), show: 'none' },
    { path: '/alreadydeliver/info/:id', title: '出库单详情', component: AsyncComponent(() => import('@/views/delivermanage/alreadydeliver/pages/info')), show: 'none' },
    { path: '/canceldeliver', title: '已取消出库单', component: CancelDeliver },
    { path: '/canceldeliver/info/:id', title: '出库单详情', component: AsyncComponent(() => import('@/views/delivermanage/canceldeliver/pages/info')), show: 'none' },
    { path: '/spdeliver', title: '第三方出库单', component: SpDeliver },
    { path: '/spdeliver/detail/:id', title: '出库单详情', component: AsyncComponent(() => import('@/views/delivermanage/spdeliver/pages/detail')), show: 'none' },
    { path: '/spdeliver/info/:id', title: '出库单详情', component: AsyncComponent(() => import('@/views/delivermanage/spdeliver/pages/info')), show: 'none' }, // 蚁购出库单详情
];

// 审核管理
const auditRoutes = [
    { path: '/activityProductAudit', title: '活动商品审核', component: ActivityProductAudit },
    { path: '/supplierAudit', title: '供应商审核', component: SupplierAudit },
    { path: '/supplierAudit/detail/:id', title: '供应商审核详情', component: AsyncComponent(() => import('@/views/auditmanage/supplierAudit/pages/detail')), show: 'none' },
    { path: '/supplierAudit/audit/:id', title: '供应商审核操作', component: AsyncComponent(() => import('@/views/auditmanage/supplierAudit/pages/audit')), show: 'none' },
    { path: '/contractAudit', title: '供应商合同审核', component: ContractAudit },
    { path: '/contractAudit/detail/:id', title: '供应商合同审核详情', component: AsyncComponent(() => import('@/views/auditmanage/contractAudit/pages/detail')), show: 'none' },
    { path: '/contractAudit/audit/:id', title: '供应商合同审核操作', component: AsyncComponent(() => import('@/views/auditmanage/contractAudit/pages/audit')), show: 'none' },
    { path: '/contractAudit/create', title: '新建供应商', component: AsyncComponent(() => import('@/views/auditmanage/contractAudit/pages/create')), show: 'none' },
    { path: '/balanceAudit', title: '供应商商品结算价审核', component: BalanceAudit },
    { path: '/purchasing', title: '集采活动审核', component: PurchasingAudit },
    { path: '/purchasing/detail/:activityGoodsId', title: '集采活动详情', component: AsyncComponent(() => import('@/views/auditmanage/purchasing/pages/audited/detail')), show: 'none' },
    { path: '/purchasing/audit/:activityGoodsId/:activityId', title: '集采活动审核', component: AsyncComponent(() => import('@/views/auditmanage/purchasing/pages/auditing/audit')), show: 'none' },
    { path: '/storeAudit', title: '门店审核', component: StoreAudit },
    { path: '/storeAudit/detail/:id', title: '门店详情', component: AsyncComponent(() => import('@/views/auditmanage/storeAudit/detail')), show: 'none' },
    { path: '/storeAudit/audit/:id', title: '门店审核', component: AsyncComponent(() => import('@/views/auditmanage/storeAudit/audit')), show: 'none' },
];

// 评论管理
const commentmanageRoutes = [
    { path: '/productcomment', title: '商品评论', component: productcomment },
    { path: '/productcomment/detail/:goodsId/:goodsScoreId', title: '评论详情', component: AsyncComponent(() => import('@/views/commentmanage/productcomment/pages/info')), show: 'none' },
    { path: '/hotelcomment', title: '酒店评论', component: hotelcomment },
    { path: '/hotelcomment/detail/:goodsId/:goodsScoreId', title: '评论详情', component: AsyncComponent(() => import('@/views/commentmanage/hotelcomment/pages/info')), show: 'none' }
];

// 违规记录
const illegalRoutes = [
    { path: '/illegalList', title: '违规列表', component: IllegalList },
    { path: '/illegalList/shopDetail/:id', title: '违规店铺详情', component: AsyncComponent(() => import('@/views/illegalRecord/illegalList/pages/shopIllegal/detail')), show: 'none' },
    // { path: '/illegalList/dispose', title: '违规处置', component: AsyncComponent(() => import('@/views/delivermanage/readydeliver/pages/info')), show: 'none' },
    { path: '/illegalList/shopAppeal/:id', title: '违规店铺申诉', component: AsyncComponent(() => import('@/views/illegalRecord/illegalList/pages/shopIllegal/appeal')), show: 'none' },
    { path: '/illegalList/productDetail/:id', title: '违规善品详情', component: AsyncComponent(() => import('@/views/illegalRecord/illegalList/pages/productIllegal/detail')), show: 'none' },
    { path: '/illegalList/productAppeal/:id', title: '违规商品申诉', component: AsyncComponent(() => import('@/views/illegalRecord/illegalList/pages/productIllegal/appeal')), show: 'none' },
];

// 系统管理系统设置
const systemRoutes = [
    { path: '/logistics', title: '物流管理', component: Logistics },
    { path: '/secretkey', title: '对接参数设置', component: Secretkey },
    { path: '/financialsetting', title: '财务设置', component: financialsetting },
    { path: '/aftersales', title: '售后地址设置', component: AfterSales },
    { path: '/materialcategory', title: '素材分类', component: MaterialCategory },
    { path: '/materiallist', title: '素材列表', component: MaterialList },
    { path: '/paramsetting', title: '蚁购对接参数设置', component: Paramsetting },
    { path: '/systemsetting', title: '系统设置', component: Systemsetting },
    { path: '/hotelsetting', title: '酒店设置', component: HotelSetting },
    { path: '/setunionpay', title: '资料补充', component: Supplement },
    { path: '/bindcard', title: '绑定银行卡', component: BindCard },
    { path: '/taxcertificate', title: '临时税务登记委托书', component: TaxCertificate },
    { path: '/merchantdeal', title: '商户协议', component: MerchantDeal },
    { path: '/delivergoodsset', title: '发货设置', component: DeliverGoodsSet },
    { path: '/delivertemplate', title: '电子面单发货模板', component: DeliverTemplate },
    { path: '/updateenterinfo', title: '修改入驻资料', component: UpdateEnterInfo },
    { path: '/freightTemplate', title: '运费模板', component: FreightTemplate },
    { path: '/freightTemplate/create', title: '新建运费模板', component: AsyncComponent(() => import('@/views/productmanage/freightTemplate/pages/create')), show: 'none' },
    { path: '/freightTemplate/edit/:id', title: '编辑运费模板', component: AsyncComponent(() => import('@/views/productmanage/freightTemplate/pages/create')), show: 'none' },
    { path: '/freightTemplate/info/:type/:id', title: '运费模板详情', component: AsyncComponent(() => import('@/views/productmanage/freightTemplate/pages/create')), show: 'none' },
    { path: '/printersetting', title: '打印机设置', component: PrinterSetting },
    { path: '/printersetting/add', title: '添加打印机', component: AsyncComponent(() => import('@/views/system/printersetting/add')), show: 'none' },
    { path: '/printersetting/edit/:id', title: '修改打印机', component: AsyncComponent(() => import('@/views/system/printersetting/update')), show: 'none' },
    { path: '/entryinformation', title: '入驻资料', component: EntryInformation },
    { path: '/businessCategory', title: '经营品类', component: BusinessCategory },
    { path: '/businessCategory/businessCategoryList', title: '申请记录', component: AsyncComponent(() => import('@/views/system/businessCategory/pages/businessCategoryList')), show: 'none' },
    { path: '/businessCategory/businessCategoryDetail/:id', title: '记录详情', component: AsyncComponent(() => import('@/views/system/businessCategory/pages/businessCategoryDetail')), show: 'none' },
];

//服务清单管理
const servicelistingRoutes = [
    { path: '/servicelist', title: '服务清单列表', component: ServiceList },
    { path: '/servicelist/detail/:id', title: '服务清单详情', component: AsyncComponent(() => import('@/views/servicelistingmanage/serviceList/pages/detail')), show: 'none' },
    { path: '/servicehistory', title: '服务清单历史记录', component: ServiceHistory },
    { path: '/servicehistory/detailInfo/:id', title: '详情', component: AsyncComponent(() => import('@/views/servicelistingmanage/serviceHistory/pages/detailInfo')), show: 'none' },
    { path: '/servicehistory/theSales/:id', title: '销售情况', component: AsyncComponent(() => import('@/views/servicelistingmanage/serviceHistory/pages/theSales')), show: 'none' },
];

//攻略管理
const starStrategyRoutes = [
    { path: '/merchantnotice', title: '商户须知', component: MerchantNotice },
    { path: '/merchantnotice/:searchKey', title: '商户须知', component: AsyncComponent(() => import('@/views/strategymanage/merchantnotice/pages/main')), show: 'none' },
    { path: '/merchantnotice/promotion/:classId/:id', title: '促销推广', component: AsyncComponent(() => import('@/views/strategymanage/merchantnotice/pages/promotion')), show: 'none' },
    { path: '/merchantnotice/detail/:classId/:titleId/:id', title: '详情', component: AsyncComponent(() => import('@/views/strategymanage/merchantnotice/pages/detail')), show: 'none' },
    { path: '/merchantnotice/search/:searchId/:searchKey', title: '详情', component: AsyncComponent(() => import('@/views/strategymanage/merchantnotice/pages/detail')), show: 'none' },
];

// 官网管理
const gdcRoutes = [
    { path: '/gdclist', title: '官网列表', component: GDCList },
];

// 直播管理
const commercialcollegeRoutes = [
    { path: '/livelist', title: '直播列表', component: LiveList },//直播列表
    { path: '/merchantAnchor', title: '商户主播管理', component: MerchantAnchor },//商户主播管理
    { path: '/merchantLive', title: '商户直播间', component: MerchantLive },//商户直播间
    { path: '/merchantLive/starLight/:id', title: '直播间魅力值收入', component: AsyncComponent(() => import('@/views/commercialcollege/merchantLive/pages/youdouIncome')), show: 'none' },
    { path: '/merchantLive/sales/:id', title: '直播间销售情况', component: AsyncComponent(() => import('@/views/commercialcollege/merchantLive/pages/sales')), show: 'none' },
];

// 合同管理
const contractmanageRoutes = [
    { path: '/contractlist', title: '合同列表', component: Contractlist }, //合同列表
    { path: '/contractlist/detailInfo/:id', title: '合同详情', component: AsyncComponent(() => import('@/views/contractmanage/contractlist/pages/detail')), show: 'none' },
];

const houseresourcemanageRoutes = [
    { path: '/houselist', title: '房源列表', component: HouseList },
    { path: '/houselist/detail/:id', title: '房源详情', component: AsyncComponent(() => import('@/views/houseresourcemanage/houselist/detail')), show: 'none' },
    { path: '/houselist/create', title: '新建房源', component: AsyncComponent(() => import('@/views/houseresourcemanage/houselist/create')), show: 'none' },
    { path: '/houselist/edit/:id', title: '编辑房源', component: AsyncComponent(() => import('@/views/houseresourcemanage/houselist/edit')), show: 'none' },
    { path: '/houselist/servicelist', title: '确认服务清单', component: AsyncComponent(() => import('@/views/houseresourcemanage/houselist/servicelist')), show: 'none' },
];

//入住核销
const checkinverificationRoutes = [
    { path: '/verificationlist', title: '核销列表', component: VerificationList }, //核销列表
    { path: '/verificationlist/detail/:buyerId/:id', title: '核销详情', component: AsyncComponent(() => import('@/views/checkinverification/verificationlist/detail')), show: 'none' },
];

// 接单管理
const receiveOrderRoutes = [
    { path: '/receiveorderlist', title: '接单列表', component: ReceiveOrderList },
    { path: '/receiveorderlist/waitreceive/detail/:id', title: '待接单详情', component: AsyncComponent(() => import('@/views/receiveordermanage/receiveorderlist/pages/waitreceive/detail')), show: 'none' },
    { path: '/receiveorderlist/receiverecord/detail/:id', title: '接单记录详情', component: AsyncComponent(() => import('@/views/receiveordermanage/receiveorderlist/pages/receiverecord/detail')), show: 'none' }
];
// 人气值管理
const beanshopmanageRoutes = [
    { path: '/beanshop', title: '信用值商城', component: BeanShopList },
    { path: '/beanshopincomedetail', title: '收益明细', component: BeanShopListIncomeDetails },
    { path: '/beanshopdetail', title: '信用值明细', component: BeanShopListDetails },
    { path: '/beanshopcard', title: '卡包管理', component: BeanShopListCard },
    { path: '/beanshop/detail', title: '信用值明细', component: AsyncComponent(() => import('@/views/beanshopmanage/details')), show: 'none' },
    { path: '/beanshop/stockdivide', title: '股票分红', component: AsyncComponent(() => import('@/views/beanshopmanage/beanshop/pages/stockdivide')), show: 'none' },
    { path: '/beanshop/stockshares', title: '股票收益申购', component: AsyncComponent(() => import('@/views/beanshopmanage/beanshop/pages/stockshares')), show: 'none' },
    { path: '/beanshop/stockconfir', title: '股票确认', component: AsyncComponent(() => import('@/views/beanshopmanage/beanshop/pages/stockshares/stockconfir')), show: 'none' },
    { path: '/beanshop/stockconfir/result', title: '支付成功', component: AsyncComponent(() => import('@/views/beanshopmanage/beanshop/pages/stockshares/stockconfir/result')), show: 'none' },
    { path: '/beanshop/cashapplication', title: '提现申请', component: AsyncComponent(() => import('@/views/beanshopmanage/beanshop/pages/cashapplication')), show: 'none' },
    { path: '/beanddrawapply', title: '信用值支取申请', component: BeanShopWapply },
    { path: '/beanshop/beanddrawapply/detail/:id', title: '支取申请详情', component: AsyncComponent(() => import('@/views/beanshopmanage/beandrawapply/detail')), show: 'none' },
];
//报表管理
const statementmanageRoutes = [
    { path: '/template', title: '报表模板管理', component: TemplateForm },//模板管理
    { path: '/template/detailInfo', title: '详情', component: AsyncComponent(() => import('@/views/statementmanage/template/pages/detailInfo')), show: 'none' },//详情
    { path: '/records', title: '报表生成记录', component: RecordsForm },//报表生成记录
    { path: '/records/recordInfo', title: '生成报表', component: AsyncComponent(() => import('@/views/statementmanage/records/pages/recordInfo')), show: 'none' },
];

// 消息管理
const messagemanageRoutes = [
    { path: '/messageList', title: '消息列表', component: MessageList },
    { path: '/messageList/detail/:id', title: '消息详情', component: AsyncComponent(() => import('@/views/messagemanage/messageList/pages/details')), show: 'none' },
];

// 供应商仓库
const supplierwarehouseRoutes = [
    { path: '/classifyconnect', title: '商品分类对接', component: WarehouseProductkind },
    { path: '/goodsconnect', title: '商品对接', component: ProductConnect },
    { path: '/goodsconnect/goodsinfo/:id', title: '商品对接SKU详情', component: AsyncComponent(() => import('@/views/warehousemanage/productconnect/pages/goodsinfo')), show: 'none' },
    { path: '/goodsconnect/visitantInfo/:id', title: '商品对接SKU详情', component: AsyncComponent(() => import('@/views/warehousemanage/productconnect/pages/visitantInfo')), show: 'none' },
    { path: '/inventoryconnect', title: '库存对接', component: ReserveConnect },
    { path: '/outdeliveryorder', title: '供应商出库单', component: SupplierWarehouseOutDelivery },
    { path: '/outdeliveryorder/detail/:id', title: '供应商出库单详情', component: AsyncComponent(() => import('@/views/supplierwarehouse/deliveryorder/detail')), show: 'none' },
    { path: '/supplierlist', title: '供应商售后单', component: SupplierSaleList },
    { path: '/visitantbrand', title: '供应商品牌对接', component: VisitantBrand },
    { path: '/balanceenquiry', title: '余额查询', component: BalanceenQuiry },
    { path: '/goodsgenlock', title: '供应商商品同步锁定', component: GoodsGenlock },
    { path: '/goodsgenlock/goodsinfo/:id', title: '商品对接SKU详情', component: AsyncComponent(() => import('@/views/warehousemanage/productconnect/pages/visitantInfo')), show: 'none' },
];

//电子面单
const expresswaybillRoutes = [
    { path: '/operationmanual', title: '电子面单操作手册', component: OperationManual },
    { path: '/authorization', title: '电子面单授权', component: Authorization },
];

//核销管理
const chargeoffmanageRoutes = [
    { path: '/chargeofflist', title: '核销列表', component: ChargeoffList },
    { path: '/chargeofflist/chargeoffinfo/:id', title: '核销详情', component: AsyncComponent(() => import('@/views/chargeoffmanage/chargeofflist/detail')), show: 'none' },
];

// 路由配置
const routes = [
    { path: '/', component: Login, exact: true },
    { path: '/login', component: Login },
    { path: '/payfail', component: PayFail },
    { path: '/paysucess', component: PaySucess },
    {
        path: '/home', component: Home, routes: [
            { path: '/', title: '首页', component: Welcome, exact: true },
            { path: '/setting', title: '个人设置', component: Setting },
            { path: '/workbench', icon: require('@/assets/image/menu/gzt.png'), icona: require('@/assets/image/menu/gzta.png'), title: '工作台', childrenNode: workbench },
            { path: '/businessmanage', icon: require('@/assets/image/menu/dpgl.png'), icona: require('@/assets/image/menu/dpgla.png'), title: '店铺管理', childrenNode: businessmanageRoutes },
            { path: '/productmanage', icon: require('@/assets/image/menu/spgl.png'), icona: require('@/assets/image/menu/spgla.png'), title: '商品管理', childrenNode: productmanageRoutes },
            { path: '/ordermanage', icon: require('@/assets/image/menu/ddgl.png'), icona: require('@/assets/image/menu/ddgla.png'), title: '订单管理', childrenNode: ordermanageRoutes },
            { path: '/marketingmanage', icon: require('@/assets/image/menu/yxgl.png'), icona: require('@/assets/image/menu/yxgla.png'), title: '营销管理', childrenNode: marketingmanageRoutes },
            { path: '/privilegemanage', icon: require('@/assets/image/menu/qxgl.png'), icona: require('@/assets/image/menu/qxgla.png'), title: '权限管理', childrenNode: privilegemanageRoutes },
            { path: '/salemanage', icon: require('@/assets/image/menu/shgl.png'), icona: require('@/assets/image/menu/shgla.png'), title: '售后管理', childrenNode: salemanageRoutes },
            { path: '/customermanage', icon: require('@/assets/image/menu/kfgl.png'), icona: require('@/assets/image/menu/kfgla.png'), title: '客服管理', childrenNode: customermanageRoutes },
            { path: '/storehousemanage', icon: require('@/assets/image/menu/ckgl.png'), icona: require('@/assets/image/menu/ckgla.png'), title: '仓库管理', childrenNode: storehousemanageRoutes },
            { path: '/suppliermanage', icon: require('@/assets/image/menu/gysgl.png'), icona: require('@/assets/image/menu/gysgla.png'), title: '供应商管理', childrenNode: suppliermanageRoutes },
            { path: '/storehousefinancialmanage', icon: require('@/assets/image/menu/cwgl.png'), icona: require('@/assets/image/menu/cwgla.png'), title: '财务管理', childrenNode: storehousefinancialmanageRoutes },
            { path: '/warehousemanage', icon: require('@/assets/image/menu/spgl.png'), icona: require('@/assets/image/menu/spgla.png'), title: '仓库商品管理', childrenNode: warehouseRoutes },
            { path: '/delivermanage', icon: require('@/assets/image/menu/ckfhgl.png'), icona: require('@/assets/image/menu/ckfhgla.png'), title: '仓库发货管理', childrenNode: deliverRoutes },
            { path: '/auditmanage', icon: require('@/assets/image/menu/shenhgl.png'), icona: require('@/assets/image/menu/shenhgla.png'), title: '审核管理', childrenNode: auditRoutes },
            { path: '/commentmanage', icon: require('@/assets/image/menu/plgl.png'), icona: require('@/assets/image/menu/plgla.png'), title: '评论管理', childrenNode: commentmanageRoutes },
            { path: '/illegalRecord', icon: require('@/assets/image/menu/wgjl.png'), icona: require('@/assets/image/menu/wgjla.png'), title: '违规记录', childrenNode: illegalRoutes },
            { path: '/systemmanage', icon: require('@/assets/image/menu/xtgl.png'), icona: require('@/assets/image/menu/xtgla.png'), title: '系统管理', childrenNode: systemRoutes },
            { path: '/servicelistingmanage', icon: require('@/assets/image/menu/fwqdgl.png'), icona: require('@/assets/image/menu/fwqdgla.png'), title: '服务清单管理', childrenNode: servicelistingRoutes },
            { path: '/strategymanage', icon: require('@/assets/image/menu/glgl.png'), icona: require('@/assets/image/menu/glgla.png'), title: '攻略管理', childrenNode: starStrategyRoutes },
            { path: '/gdcmanage', icon: require('@/assets/image/menu/gwgl.png'), icona: require('@/assets/image/menu/gwgla.png'), title: '官网管理', childrenNode: gdcRoutes },
            { path: '/commercialcollege', icon: require('@/assets/image/menu/zbgl.png'), icona: require('@/assets/image/menu/zbgla.png'), title: '直播管理', childrenNode: commercialcollegeRoutes },
            { path: '/contractmanage', icon: require('@/assets/image/menu/htgl.png'), icona: require('@/assets/image/menu/htgla.png'), title: '合同管理', childrenNode: contractmanageRoutes },
            { path: '/beanshopmanage', icon: require('@/assets/image/menu/xmgl.png'), icona: require('@/assets/image/menu/xmgla.png'), title: '信用值管理', childrenNode: beanshopmanageRoutes },
            { path: '/statementmanage', icon: require('@/assets/image/menu/bbgl.png'), icona: require('@/assets/image/menu/bbgla.png'), title: '报表管理', childrenNode: statementmanageRoutes },
            { path: '/houseresourcemanage', icon: require('@/assets/image/menu/spgl.png'), icona: require('@/assets/image/menu/spgla.png'), title: '房源管理', childrenNode: houseresourcemanageRoutes },
            { path: '/checkinverification', icon: require('@/assets/image/menu/rzhx.png'), icona: require('@/assets/image/menu/rzhxa.png'), title: '入住核销', childrenNode: checkinverificationRoutes },
            { path: '/receiveordermanage', icon: require('@/assets/image/menu/jdgl.png'), icona: require('@/assets/image/menu/jdgla.png'), title: '接单管理', childrenNode: receiveOrderRoutes },
            { path: '/messagemanage', icon: require('@/assets/image/menu/plgl.png'), icona: require('@/assets/image/menu/plgla.png'), title: '消息管理', childrenNode: messagemanageRoutes },
            { path: '/supplierwarehouse', icon: require('@/assets/image/menu/gysckgl.png'), icona: require('@/assets/image/menu/gysckgla.png'), title: '供应商仓库', childrenNode: supplierwarehouseRoutes },
            { path: '/expresswaybill', icon: require('@/assets/image/menu/gysckgl.png'), icona: require('@/assets/image/menu/gysckgla.png'), title: '电子面单', childrenNode: expresswaybillRoutes },
            { path: '/chargeoffmanage', icon: require('@/assets/image/menu/rzhx.png'), icona: require('@/assets/image/menu/rzhxa.png'), title: '核销管理', childrenNode: chargeoffmanageRoutes },
        ]
    }
];

export default routes;
