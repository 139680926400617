/**
 * 余额查询
 */
import React, { useEffect } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { XOKButton } from '@/components/xqxc_ui';
import model from '@/reducers/supplierwarehouse/visitantbrand/model';
import { getLocalStorage } from '@/assets/js/storage';

const BalanceenQuiry = ({
    getBalanceValue,
    balanceValue: {
        balance = 0
    } = {}
}) => {

    useEffect(() => { getBalanceValue({ warehouseId: JSON.parse(getLocalStorage('auth')).userInfo.warehouseId }); }, []);

    return <div className={styles.container}>
        <XOKButton
            style={{ width: '90px' }}
            label='余额查询'
            onClick={() => getBalanceValue({ warehouseId: JSON.parse(getLocalStorage('auth')).userInfo.warehouseId })}
        />
        <div className={styles.title}>余额</div>
        <div className={styles.text}>{balance}元</div>
    </div>;
};
BalanceenQuiry.propTypes = {
    getBalanceValue: PropTypes.func,
    balanceValue: PropTypes.object
};
export default connect(({ visitantBrand }) => ({
    balanceValue: visitantBrand.balanceValue
}), {
    ...model.actions
})(BalanceenQuiry);