import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';


class TableContent extends Component {
	state = {
		selectedRowKeys: [],
	};

	_columns = () => [
		{
			title: '流水号',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			width: '15%',
		},
		{
			title: '店铺名称',
			dataIndex: 'merchantName',
			key: 'merchantName',
			align: 'center',
			width: '15%',
		},
		{
			title: '行为',
			dataIndex: 'violationMode',
			key: 'violationMode',
			align: 'center',
			width: '15%',
		},
		{
			title: '平台意见',
			dataIndex: 'violationReason',
			key: 'violationReason',
			align: 'center',
			width: '20%',
		},
		{
			title: '状态',
			dataIndex: 'violationState',
			key: 'violationState',
			align: 'center',
			width: '10%',
			render: (text) => (this._statusCell(text))
		},
		{
			title: '新建时间',
			dataIndex: 'gmtModified',
			key: 'gmtModified',
			align: 'center',
			width: '15%',
		},
		{
			title: '操作',
			dataIndex: 'keyId',
			key: 'keyId',
			align: 'center',
			width: '10%',
			render: (text, record) => this._tableAction(text, record)
		},
	];

	_statusCell = (text) => {
		return text.value;
	}

	// 表格复选输入
	_rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			console.log(selectedRows);
			this.setState({ selectedRowKeys });
			this._actionHandle(null, 'batchBan', selectedRowKeys);
		}
	};

	// 清空表格复选框
	_cleanAllSelected = () => {
		this.setState({ selectedRowKeys: [] });
	}

	// 表格操作
	_tableAction = (key, record) => {
		const { tableAction } = this.props;
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
				<div className={styles.item} onClick={() => tableAction(key, 'appeal', record)}>申诉</div>
			</div>
		);
	};

	_tableData = (data) => {
		return data;
	}

	render() {
		const { renderData, paginationChange } = this.props;
		const { selectedRowKeys } = this.state;
		this._rowSelection.selectedRowKeys = selectedRowKeys;
		return (
			<div className={styles.tableContainer}>
				{/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					// rowSelection={this._rowSelection}
					rowKey={(record, index) => index}
					columns={this._columns()}
					pagination={false}
					dataSource={this._tableData(renderData.dataList)}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}


TableContent.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
};

export default TableContent;