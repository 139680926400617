import React, { useEffect, useState } from 'react';
import { message, Modal } from 'antd';
import PropTypes from 'prop-types';
import { getLocalStorage } from '@/assets/js/storage';
import http from '@/assets/api/http';
import './index.module.scss';

const WebIMUrl = `${process.env.WebIMUrl}`;

export default function WebIMModal(props) {

    function onLoad() {
        const { userInfo = {} } = JSON.parse(getLocalStorage('auth'));
        const { merchantId } = userInfo;
        http('/im/getUserSig/' + merchantId).then((res) => {
            window.frames['WebIMModal'].postMessage({
                mode: 'connectBuyer',
                data: {
                    userID: merchantId + '',
                    userSig: res.result,
                    buyUserId: props.buyUserId
                }
            }, WebIMUrl);
        });
    }

    return (
        <Modal
            width={1300}
            style={{ top: '20px' }}
            bodyStyle={{ height: '800px', padding: 0 }}
            title='web im'
            footer={null}
            {...props}
        >
            <iframe name='WebIMModal' onLoad={onLoad} style={{ border: 'none', width: '100%', height: '100%' }} src={WebIMUrl} />
        </Modal>
    );
}

WebIMModal.propTypes = {
    buyUserId: PropTypes.any,
    visible: PropTypes.bool
};