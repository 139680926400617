import { combineReducers } from 'redux';
import {
	illegalShopList,
	illegalSele,
	illegalShopDetail,
	illegalProductList,
	illegalProductDetail
} from './reduce';

export default combineReducers({
	illegalShopList,
	illegalSele,
	illegalShopDetail,
	illegalProductList,
	illegalProductDetail
});