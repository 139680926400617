/**
 * 店铺管理  Saga集合
 */
import { 
    getProvinces
} from './saga';


export default [
    getProvinces
];