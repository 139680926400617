import React from 'react';
import { XOKButton } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { message } from 'antd';

export default function WalletButton(props) {
    return (
        <XOKButton
            style={{ width: '80px' }}
            label='钱包'
            {...props}
            onClick={() => {
                var tempwin = window.open('about:blank');
                http('/merchant/info/walletUrlQuery', {}, 'POST').then((response) => {
                    if (response.code === 200 && (response.result || {}).walletUrl) {
						tempwin.location = response.result.walletUrl;
					} else {
                        message.error('路径异常');
                        tempwin.close();
					}
                }).catch((e) => {
                    message.error('获取地址异常');
                    tempwin.close();
                });
            }}
        />
    );
}