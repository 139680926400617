/**
 * 商品标签创建或编辑弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message, Input } from 'antd';
import styles from './SetInventory.module.scss';

class CreateEdit extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        serviceQuantity: '',
        inventoryInfo: {}
    }

    componentDidMount() {
        http('/goods/inventory/serviceInventoryInfo', { skuId: this.props.data }, 'POST').then((resole) => {
            console.log(resole);
            this.setState({ inventoryInfo: resole.result });
        }).catch(() => {
            message.error('获取详情失败,马上返回列表页');
            this.setState({
                visible: false,
            });
            this.props.closeMode(false);
        });
    }

    _serviceQuantity = (e) => {
        this.setState({ serviceQuantity: e.target.value });
    }

    // 点击确认
    _handleOk = () => {
        const { serviceQuantity } = this.state;
        if (serviceQuantity === '') {
            message.error('服务商库存不能为空');
            return;
        }
        http('/goods/inventory/editServiceInventory', { skuId: this.props.data, serviceQuantity }, 'POST').then(() => {
            message.success('设置成功');
            this.setState({
                visible: false,
            });
            this.props.closeMode(true);
        }).catch((error) => {
            message.error(error.message);
            this.setState({
                visible: false,
            });
            this.props.closeMode(false);
        });
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }

    render() {
        const { visible, confirmLoading, title, serviceQuantity, inventoryInfo } = this.state;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'>
                <div className={styles.container}>
                    <div className={styles.content}>
                        <p className={styles.labelName}>商品名称：</p>
                        <p className={styles.text}>
                            {
                                inventoryInfo.goodsName
                            }
                        </p>
                    </div>
                    <div className={styles.content}>
                        <p className={styles.labelName}>商家编码：</p>
                        <p className={styles.text}>
                            {
                                inventoryInfo.skuNo
                            }
                        </p>
                    </div>
                    <div className={styles.content}>
                        <p className={styles.labelName}>总库存：</p>
                        <p className={styles.text}>
                            {
                                inventoryInfo.totalQuantity
                            }
                        </p>
                    </div>
                    <div className={styles.content}>
                        <p className={styles.labelName}>服务商库存：</p>
                        <p className={styles.text}>
                            <Input onChange={this._serviceQuantity} value={serviceQuantity}></Input>
                        </p>
                    </div>
                </div>
            </Modal>
        );
    }
}

CreateEdit.propTypes = {
    data: PropTypes.number, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};

export default CreateEdit;