import { createModel } from '@/store/tools';

const verificationData = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };
const verificationDetail = {};

const model = {
    namespace: 'checkinverification',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getVerificationState': T('getVerificationState'),
            'getVerificationData': T('getVerificationData'),
            'getVerificationDetail': T('getVerificationDetail'),
        },
        actions: {
            'getVerificationState': A('getVerificationState'),
            'getVerificationData': A('getVerificationData'),
            'getVerificationDetail': A('getVerificationDetail'),
        },
        sagas: {
            'getVerificationState': S('getVerificationState', '/enum/HotelOrderVerificationStatusEnum'),
            'getVerificationData': S('getVerificationData', '/hotel/verification/list'),
            'getVerificationDetail': S('getVerificationDetail', '/hotel/verification/info'),
        },
        reducers: {
            'verificationState': R('getVerificationState', []),
            'verificationData': R('getVerificationData', verificationData),
            'verificationDetail': R('getVerificationDetail', verificationDetail),
        }
    })
};
export default createModel(model);