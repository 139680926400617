import React from 'react';
import { Modal, Input, message, Form, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import styles from './EditModel.module.scss';
import http from '@/assets/api/http';

const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
    }
};

class EditModal extends React.Component {

    state = {
        visible: this.props.visible,
        id: this.props.data ? this.props.data.id : '',
        websiteName: this.props.data ? this.props.data.websiteName : '', // 网站名称
        contactName: this.props.data ? this.props.data.contactName : '',
        contactTel: this.props.data ? this.props.data.contactTel : '',
        shopDesc: this.props.data ? this.props.data.shopDesc : '',
    }

    _handleOk = () => {
        this._handleSubmit();
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        }, () => {
            this.props.closeMode(false);
        });
    }

    _handleSubmit = (e) => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                http('/merchant/website/update', { ...fieldsValue, id: this.state.id }, 'POST').then(() => {
                    message.success('编辑成功');
                    this.setState({ visible: false }, () => {
                        this.props.closeMode(true);
                    });
                }).catch((error) => {
                    message.error(error.message);
                });
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { visible, websiteName, contactName, contactTel, shopDesc } = this.state;

        return (
            <Modal
                width={500}
                centered
                title='编辑'
                visible={visible}
                confirmLoading={false}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <Form {...formItemLayout} onSubmit={this._handleSubmit} className={styles.container}>
                    <Form.Item
                        colon={false}
                        label={<span>网站名称</span>}
                    >
                        {getFieldDecorator('websiteName', {
                            initialValue: websiteName,
                            rules: [{ required: true, message: '网站名称不能为空' }],
                        })(<Input placeholder='网站名称' style={{ width: '90%' }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        label={<span>联系人</span>}
                    >
                        {getFieldDecorator('contactName', {
                            initialValue: contactName,
                            rules: [
                                { required: true, message: '联系人不能为空' },
                                { min: 0, max: 30, message: '字数超出长度' }
                            ],
                        })(<Input placeholder='联系人' style={{ width: '90%' }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        label={<span>联系电话</span>}
                    >
                        {getFieldDecorator('contactTel', {
                            initialValue: contactTel,
                            rules: [
                                { required: true, message: '联系电话不能为空' },
                                {
                                    validator: (rule, value = '', callback) => {
                                        const telLength = value.toString().length;
                                        if (telLength > 0 && telLength != 11) {
                                            callback('请输入11位电话号码');
                                        } else {
                                            callback();
                                        }
                                    }
                                }
                            ],
                        })(<InputNumber placeholder='联系电话' style={{ width: '90%' }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        label={<span>网站简介</span>}
                    >
                        {getFieldDecorator('shopDesc', {
                            initialValue: shopDesc,
                        })(<TextArea placeholder='网站简介' style={{ width: '90%' }} rows={4} />)}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

EditModal.propTypes = {
    form: PropTypes.object.isRequired, // 校验
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    data: PropTypes.object // 入参
};

const EditModalForm = Form.create({ name: 'EditModalForm' })(EditModal);// 校验
export default EditModalForm;