/**
 * 虚拟销量规则
 */
import React from 'react';
import { Modal, InputNumber, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './VirtualSalesModal.module.scss';
import http from '@/assets/api/http';

class VirtualSalesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            count: this.props.data.virtualUnit,//件数
            virtualCount: this.props.data.virtualNum,//增加虚拟销量
        };
    }
    _handleOk = () => {
        const { count, virtualCount } = this.state;
        const { activityId, } = this.props.data;

        http('/marketing/activityMerchantInfo/setSalesVolume', {
            activityId,
            virtualUnit: count,
            virtualNum: virtualCount
        }, 'POST').then(() => {
            message.success('提交成功');
            this.setState({
                visible: false,
            });
            this.props.closeMode(true);
        }).catch((reject) => {
            message.warn(reject.message);
            this.setState({
                visible: false,
            });
            this.props.closeMode();
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title, count, virtualCount } = this.state;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div>
                        <span>每销售</span>
                        <InputNumber
                            className={styles.top}
                            placeholder='请输入(件)'
                            precision={0}
                            min={0}
                            step={1}
                            value={count}
                            onChange={(value) => {
                                this.setState({
                                    count: value
                                });
                            }}
                        />
                        <span>件商品</span>
                    </div>
                    <div>
                        <span>增加虚拟销量</span>
                        <InputNumber
                            className={styles.bot}
                            placeholder='请输入(件)'
                            precision={0}
                            min={0}
                            step={1}
                            value={virtualCount}
                            onChange={(value) => {
                                this.setState({
                                    virtualCount: value
                                });
                            }}
                        />
                        <span>件</span>
                    </div>
                </div>
            </Modal>
        );

    }
}

VirtualSalesModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default VirtualSalesModal;