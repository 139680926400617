/**
 *  【仓库财务】入口页
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const merchantnotice = ({ history, match }) => {
    return <Main history={history} match={match} />;
};

merchantnotice.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object
};

export default merchantnotice;