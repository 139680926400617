/**
 * sku库存信息
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './modalStyle.module.scss';
import { Modal, message, Table, InputNumber, Typography, Icon } from 'antd';
import { tooltipStyle, tooltip, ImgCell, priceFenToYuanCell } from '@/components/TableCell';

const SetSkuInfo = ({ closeMode,
    modalParams: {
        goodsSkuList = [],
        goodsName = '',
    } = {}
}) => {

    const [loading, setLoading] = useState(false);
    const [dataList, setDataList] = useState([]);

    useEffect(() => {
        setDataList(goodsSkuList.map(item => {
            return { ...item, addInventory: 0, showInput: false };
        }));
    }, []);

    const onSubmit = () => {
        const wholesaleSkuIds = dataList.map(item => {
            return { wholesaleSkuId: item.wholesaleSkuId, totalQuantity: item.addInventory };
        });
        setLoading(true);
        http('/goods/wholesale/addTotalQuantity', { wholesaleSkuIds }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    const renderEditCell = (text, rowValue) => {
        return rowValue.showInput ?
            <InputNumber
                key={rowValue.skuId}
                defaultValue={text}
                min={0}
                onBlur={e => {
                    setDataList(dataList.map(item => {
                        if (item.skuId === rowValue.skuId) {
                            return { ...item, addInventory: e.target.value, showInput: false };
                        }
                        return { ...item };
                    }));
                }}
            /> :
            <div key={rowValue.skuId}>
                <Typography.Text>{text}</Typography.Text>
                <Icon
                    style={{ marginLeft: 5 }}
                    type='edit'
                    theme='twoTone'
                    onClick={() => {
                        setDataList(dataList.map(item => {
                            if (item.skuId === rowValue.skuId) {
                                return { ...item, showInput: true };
                            }
                            return { ...item };
                        }));
                    }}
                />
            </div>;
    };

    const columns = () => {
        return [
            {
                title: '商品图片',
                dataIndex: 'imgUrl',
                key: 'imgUrl',
                align: 'center',
                width: '10%',
                render: ImgCell
            }, {
                title: 'SKU编码',
                dataIndex: 'skuNo',
                key: 'skuNo',
                align: 'center',
                width: '18%',
                onCell: tooltipStyle,
                render: tooltip
            }, {
                title: '规格属性',
                dataIndex: 'spec',
                key: 'spec',
                align: 'center',
                width: '18%',
                onCell: tooltipStyle,
                render: tooltip
            }, {
                title: '结算价（元）',
                dataIndex: 'wholesalePrice',
                key: 'wholesalePrice',
                align: 'center',
                width: '18%',
                onCell: tooltipStyle,
                render: priceFenToYuanCell
            },
            //  {
            //     title: '增加库存',
            //     dataIndex: 'addInventory',
            //     key: 'addInventory',
            //     align: 'center',
            //     width: '18%',
            //     onCell: tooltipStyle,
            //     render: renderEditCell
            // },
            {
                title: '批发库存',
                dataIndex: 'totalQuantity',
                key: 'totalQuantity',
                align: 'center',
                width: '18%',
                onCell: tooltipStyle,
                render: tooltip
            }];
    };

    return <Modal
        width={1000}
        centered
        title={`"${goodsName}"SKU信息`}
        visible={true}
        confirmLoading={loading}
        onOk={onSubmit}
        onCancel={() => closeMode(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div className={styles.container}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(record, index) => index}
                columns={columns()}
                pagination={false}
                dataSource={dataList}
                scroll={{ y: 400 }}
            />
        </div>
    </Modal>;
};
SetSkuInfo.propTypes = {
    closeMode: PropTypes.func,
    modalParams: PropTypes.object
};
export default SetSkuInfo;