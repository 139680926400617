/**
 * 平台费用明细
 */
import React from 'react';
import { Modal, Button } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './CostDate.module.scss';
import CostDateTable from '../components/CostDateTable';
import model from '@/reducers/marketingmanage/fightrecord/model';
import { regFenToYuan } from '@/util/money';

class CostDate extends React.PureComponent {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        tableDataSource: [],//模拟表格数据
    }

    componentDidMount() {
        const { data, getFightrecordCost } = this.props;
        getFightrecordCost(data.orderId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.fightrecordCost !== prevProps.fightrecordCost) {
            const { platformServiceFee, technologyFormServiceFee, marketingFormServiceFee, marketingFormRecommendFee, total, } = this.props.fightrecordCost;

            let tableData = [
                { c1: '平台服务费', c2: regFenToYuan(platformServiceFee) },
                { c1: '技术服务费', c2: regFenToYuan(technologyFormServiceFee) },
                { c1: '营销服务费', c2: regFenToYuan(marketingFormServiceFee) },
                { c1: '营销推广费', c2: regFenToYuan(marketingFormRecommendFee) },
                { c1: '小计', c2: regFenToYuan(total) },
            ];
            this.setState({ tableDataSource: tableData });
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, title, confirmLoading, tableDataSource } = this.state;

        return (
            <Modal
                width={860}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                destroyOnClose={true}
                onCancel={this._handleCancel}
                keyboard={true}
                maskClosable={false}
                footer={[
                    <Button key="back" onClick={this._handleCancel}>
                        返回
                    </Button>
                ]}
            >
                <div className={styles.container}>
                    <CostDateTable renderData={tableDataSource} />
                </div>
            </Modal>
        );
    }
}

CostDate.propTypes = {
    title: PropTypes.string,// 弹框标题
    data: PropTypes.object, // 需要修改魅力值的会员对象数组
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数

    fightrecordCost: PropTypes.object,
    getFightrecordCost: PropTypes.func,//抢拍记录-平台费用数据(action)
};

const mapStateToProps = (state) => ({
    fightrecordCost: state.fightrecord.fightrecordCost,
});

export default connect(mapStateToProps, model.actions)(CostDate);