import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './TableBox.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

const TableBox = ({ renderData: {
    dataList = [],
    pageNum,
    pageSize,
    resultTotal
},
    paginationChange
}) => {

    //表头
    const columns = [
        {
            title: '付款单号',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '结算金额(元)',
            dataIndex: 'amount',
            key: 'amount',
            width: '10%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '个税(元)',
            dataIndex: 'taxAmount',
            key: 'taxAmount',
            width: '8%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '手续费(元)',
            dataIndex: 'transferCharge',
            key: 'transferCharge',
            width: '10%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '预计到账金额(元)',
            dataIndex: 'paymentAmount',
            key: 'paymentAmount',
            width: '10%',
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '银行卡',
            dataIndex: 'bankCardNo',
            key: 'bankCardNo',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'payStatusDesc',
            key: 'payStatusDesc',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '摘要',
            dataIndex: 'remark',
            key: 'remark',
            width: '14%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '申请日期',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '完成日期',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(record, index) => index}
                columns={columns}
                dataSource={dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};
TableBox.propTypes = {
    paginationChange: PropTypes.func.isRequired,
    renderData: PropTypes.object.isRequired, // 表格数据源
};
export default TableBox;