import React, { Component } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './Table.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { OperateType } from '../_utils';
import { priceFenToYuanCell, tooltip, tooltipStyle } from '@/components/TableCell';

export default class GroupTable extends Component {

    //props校验
    static propTypes = {
        renderData: PropTypes.object.isRequired, // 表格数据源
        tableAction: PropTypes.func, // 表格操作
        paginationChange: PropTypes.func.isRequired, // 分页操作
        rowSelection: PropTypes.object
    };

    //表头
    _columns = () => [
        {
            title: '姓名',
            dataIndex: 'ownerName',
            key: 'ownerName',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '证照号码',
            dataIndex: 'identification',
            key: 'identification',
            width: 180,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所得项目',
            dataIndex: 'incomeItem',
            key: 'incomeItem',
            width: 160,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所得期间起',
            dataIndex: 'startDate',
            key: 'startDate',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所得期间止',
            dataIndex: 'endDate',
            key: 'endDate',
            width: 160,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '免税收入（元）',
            dataIndex: 'taxFeeIncome',
            key: 'taxFeeIncome',
            width: 150,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '实际捐赠额（元）',
            dataIndex: 'actualDonation',
            key: 'actualDonation',
            width: 150,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '捐赠方式',
            dataIndex: 'donationWay',
            key: 'donationWay',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '准予扣除的捐赠额（元）',
            dataIndex: 'allowanceForDeduction',
            key: 'allowanceForDeduction',
            width: 180,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '扣除及减除项目合计（元）',
            dataIndex: 'totalDeductionsAndDeductions',
            key: 'totalDeductionsAndDeductions',
            width: 180,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '应纳税所得额（元）',
            dataIndex: 'taxableAmount',
            key: 'taxableAmount',
            width: 150,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '税率',
            dataIndex: 'ratio',
            key: 'ratio',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '应纳税额（元）',
            dataIndex: 'taxAmount',
            key: 'taxAmount',
            width: 150,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '减免税额（元）',
            dataIndex: 'taxDeduction',
            key: 'taxDeduction',
            width: 150,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '应扣缴税额（元）',
            dataIndex: 'taxDeductible',
            key: 'taxDeductible',
            width: 150,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '已扣缴税额（元）',
            dataIndex: 'withholdingTax',
            key: 'withholdingTax',
            width: 150,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '应补（退）税额（元）',
            dataIndex: 'taxPayable',
            key: 'taxPayable',
            width: 150,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '会员身份',
            dataIndex: 'identity',
            key: 'identity',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '新建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    //处理返回数据
    _takeDataSource = (data) => {
        return data ? data : [];
    }

    //表格操作
    _tableAction = (key, record) => {
        const { tableAction } = this.props;
        let status = record.status || {};
        return (
            <div className={styles.action}>
                <a onClick={() => tableAction(key, OperateType.Detail, record)}>详情</a>
                {(status.code === 'INIT' || status.code === 'CONFIRM_FAIL') && <a onClick={() => tableAction(key, OperateType.Delete, record)}>删除</a>}
                {status.code === 'TO_CONFIRM' ? <a onClick={() => tableAction(key, OperateType.ConfirmReconciliation, record)}>确认对账单</a> : null}
                <a onClick={() => tableAction(key, OperateType.Reconciliation, record)}>导出对账单明细</a>
            </div>
        );
    };

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    rowSelection={this.props.rowSelection}
                    pagination={false}
                    scroll={{ y: true, x: '200%' }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}