import React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import styles from './TableData.module.scss';
import { tooltip, priceFenToYuanCell, tooltipStyle, cellWidth } from '@/components/TableCell';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
  {
    title: '订单号',
    dataIndex: 'orderId',
    key: 'orderId',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '商品名称',
    dataIndex: 'goodsName',
    key: 'goodsName',
    align: 'center',
    width: cellWidth.big,
    onCell: tooltipStyle,
    render: (text) => {
      return <Ellipsis tooltip={text} lines={1}>{text}</Ellipsis>;
    }
  },
  {
    title: '手机号',
    dataIndex: 'sellerTel',
    key: 'sellerTel',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '姓名',
    dataIndex: 'userName',
    key: 'userName',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '认购数量',
    dataIndex: 'goodsNum',
    key: 'goodsNum',
    align: 'center',
    width: cellWidth.small,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '商品单价(元)',
    dataIndex: 'goodsPrice',
    key: 'goodsPrice',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: priceFenToYuanCell
  },
  {
    title: '订单金额(元)',
    dataIndex: 'orderAmount',
    key: 'orderAmount',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: priceFenToYuanCell
  },
  {
    title: '下单时间',
    dataIndex: 'gmtCreate',
    key: 'gmtCreate',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '结算时间',
    dataIndex: 'settleStartTime',
    key: 'settleStartTime',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: (text, item) => {
      return <div>
        <p>{text}</p>
        <p>{item.settleEndTime}</p>
      </div>;
    }
  },
  {
    title: '打款失败原因',
    dataIndex: 'refundFaildRemarks',
    key: 'refundFaildRemarks',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '操作',
    dataIndex: 'c4',
    key: 'c4',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: (text, item) => TableAction(item, actionClickHandle)
  },
];

const columns2 = (actionClickHandle) => [
  {
    title: '订单号',
    dataIndex: 'orderId',
    key: 'orderId',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '商品名称',
    dataIndex: 'goodsName',
    key: 'goodsName',
    align: 'center',
    width: cellWidth.big,
    onCell: tooltipStyle,
    render: (text) => {
      return <Ellipsis tooltip={text} lines={1}>{text}</Ellipsis>;
    }
  },
  {
    title: '手机号',
    dataIndex: 'sellerTel',
    key: 'sellerTel',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '姓名',
    dataIndex: 'userName',
    key: 'userName',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '认购数量',
    dataIndex: 'goodsNum',
    key: 'goodsNum',
    align: 'center',
    width: cellWidth.small,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '商品单价(元)',
    dataIndex: 'goodsPrice',
    key: 'goodsPrice',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: priceFenToYuanCell
  },
  {
    title: '订单金额(元)',
    dataIndex: 'orderAmount',
    key: 'orderAmount',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: priceFenToYuanCell
  },
  {
    title: '下单时间',
    dataIndex: 'gmtCreate',
    key: 'gmtCreate',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '退款时间',
    dataIndex: 'refundTime',
    key: 'refundTime',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '退款方式',
    dataIndex: 'accessTypeName',
    key: 'accessTypeName',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '结算时间',
    dataIndex: 'settleStartTime',
    key: 'settleStartTime',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: (text, item) => {
      return <div>
        <p>{text}</p>
        <p>{item.settleEndTime}</p>
      </div>;
    }
  },
  {
    title: '操作',
    dataIndex: 'c4',
    key: 'c4',
    align: 'center',
    width: cellWidth.normal,
    onCell: tooltipStyle,
    render: (text, item) => TableAction(item, actionClickHandle)
  },
];

export const takeDataSource = (data = []) => {
  return data;
};

export const takeColumns = (actionClickHandle, state) => {
  return state === 'PAYED' ? columns(actionClickHandle) : columns2(actionClickHandle);
};

const TableAction = (record, actionClickHandle) => {
  return (
    <div className={styles.action}>
      <div className={styles.item} onClick={() => actionClickHandle(record, 'DETAIL')}>查看详情</div>
    </div>
  );
};