import * as T from './actiontypes';

//供应商合同审核列表
export const getContractAuditList = (params = {}) => ({
    type: T.GET_CONTRACTAUDIT_LIST,
    payload: params
});

//合同状态下拉
export const getContractStateSele = () => ({
    type: T.GET_CONTRACTSTATE_SELE,
    payload: {}
});

//供应商下拉
export const getSupplierSele = () => ({
    type: T.GET_SUPPLIER_SELE,
    payload: {}
});

//供应商合同审核详情
export const getContractAuditDetail = (id) => ({
    type: T.GET_CONTRACTAUDIT_DETAIL,
    payload: { id }
});