import * as Audit from './actiontypes';

//集采活动审核列表
export const getPurchasingList = (params = {}) => ({
    type: Audit.GET_PURCHASING_LIST,
    payload: params
});
//集采活动审核详情
export const getPurchasingDetail = (activityGoodsId) => ({
    type: Audit.GET_PURCHASING_DETAIL,
    payload: { activityGoodsId }
});
//集采活动审核信息
export const getPurchasingAuditInfo = (activityGoodsId) => ({
    type: Audit.GET_PURCHASING_AUDITINDO,
    payload: { activityGoodsId }
});