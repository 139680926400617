/**
 * 欢迎页
 * */
import React from 'react';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
import { getLocalStorage } from '@/assets/js/storage';
import { message } from 'antd';
import RegisterModal from './modal/RegisterModal';
import AllPay from './modal/AllPay';
import UnionPay from './modal/UnionPay';
import AdvertisementModal from './modal/AdvertisementModal';
import FadadaModal from './modal/Fadada';
import Background from '@/assets/image/home-inner.png';
import { getIsGDCPromise, isGY, isMT, isHOTEL } from '@/assets/js/authType';
import getMemberInfo from './modal/AllPay/components/getMemberInfo';
import getUnionInfo from './modal/UnionPay/components/getUnionInfo';
import MerchantAgreement from '@/vcomps/MerchantAgreement/Agreement';

class Welcome extends React.Component {

    state = {
        openModal: '', // zlx中联信、tl通联、gg广告、yl银联
        showUnionClose: false, // 是否显示银联关闭按钮
        h5Url: '', // 法大大注册地址
        merchantAgreementData: {}, // 商户结算协议
    }

    openModalStack = [];

    componentDidMount() {
        // 正式环境时，如果用户未注册财务账号，则弹出注册框 15205003060
        // if (process.env.NODE_ENV == 'production') {
        getIsGDCPromise().then((response) => {
            // 如果是GDC用户，则不会弹出财务注册弹框
            if (!response) {
                // 供应链商户不弹出注册框
                if (!isGY()) {
                    // 弹出法大大+银联注册框场景
                    // this._register();商户入驻改造task2405
                    http('/merchantEntering/merchantEnteringFinish', {}, 'POST')
                        .then(res => {
                            if (res.result.url) {
                                this.setState({ openModal: 'fdd' });
                            } else {
                                this._openAdvertisementModal(); // 如果已经注册法大大，弹出广告弹窗
                            }
                        })
                        .catch(err => {
                            message.error(err.message);
                            this.getAgreementSignature();
                        });
                }

                // 弹出银联注册框场景(已废弃)
                // getUnionInfo().then((unionInfo) => {
                //     const { match } = this.props;
                //     const { data } = unionInfo;
                //     const { auditStatus } = data;

                //     // 如果从'/home/systemmanage/setunionpay'路由进入，则一定弹出银联注册，并且显示出关闭按钮
                //     let showUinonPage = data.showUinonPage; // 0 不弹出银联注册；1 弹出银联注册
                //     let showUnionClose = false;
                //     if (match.url == '/home/systemmanage/setunionpay') {
                //         showUnionClose = true; // 显示关闭按钮
                //         showUinonPage = 1; // 弹出银联注册
                //     }

                //     // 是否银联入网审核通过
                //     if (auditStatus != 1 && showUinonPage == 1) {
                //         this.setState({ openModal: 'yl', showUnionClose }); // 打开银联注册
                //     } else {
                //         // 是否弹出通联注册框
                //         this._opentlmode();
                //     }
                // });

                // 弹出中联信场景(已废弃)
                // const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
                // if (userInfo != null) {
                //     const accountType = userInfo.accountType;
                //     if (accountType == 'MT') {
                //         http('/merchant/info/checkOutAccountExist', {}, 'POST').then((response) => {
                //             if (response.status == 200) {
                //                 // 未注册中联信支付账号
                //                 if (response.result.status == 'WAIT_REGISTER' || response.result.status == 'WAIT_ACTIVE') {
                //                     this.setState({ openModal: 'zlx' });
                //                 }
                //             }
                //         }).catch((e) => {
                //             if (e) message.error(e.message);
                //         });
                //     }
                //     // 仓库暂不弹出
                //     // else if (accountType == 'WE' && isGY()) {
                //     //     http('/warehouse/info/getFinancial', {}, 'POST').then((response) => {
                //     //         if (response.status == 200) {
                //     //             if (!(response.result && response.result.id != '')) {
                //     //                 this.setState({ openModal: 'zlx' });
                //     //             }
                //     //         }
                //     //     }).catch((e) => {
                //     //         if (e) message.error(e.message);
                //     //     });
                //     // }
                // }
            }
        });
        // }
    }

    // 法大大+银联注册流程
    _register = () => {
        // 情况1：已入驻到平台的商户，但还没有注册法大大和入网银联，到资料补充去填写
        // 情况2：已入网银联的商户，在每次登录商户后台时弹出二维码窗口
        http('/contract/fadada/contractSignStatus').then((response) => {
            const { isUnionFinish, isFadadaFinish, h5Url } = response.result;
            const { match } = this.props;
            this.setState({ h5Url });
            getUnionInfo().then((unionInfo) => {
                const { data } = unionInfo;
                const { auditStatus } = data;
                const showUinonPage = data.showUinonPage; // 0 不弹出银联注册；1 弹出银联注册
                if (match.url == '/home/systemmanage/setunionpay') {
                    // 已入驻到平台的商户，但还没有注册法大大和入网银联，到资料补充去填写
                    if (isFadadaFinish == 0) {
                        // 打开法大大弹框
                        this.setState({ openModal: 'fdd' });
                    }
                    // else if (isHOTEL() && isUnionFinish == 0) {
                    //     this.setState({ openModal: 'yl', showUnionClose: true }); // 打开银联注册
                    // } else if (isHOTEL() && isUnionFinish == 1) {
                    //     message.success('您的资料已全部补充完成');
                    // }
                    else if (isFadadaFinish == 1 && isUnionFinish == 0) {
                        this.setState({ openModal: 'yl', showUnionClose: true }); // 打开银联注册
                    } else if (isFadadaFinish == 1 && isUnionFinish == 1) {
                        message.success('您的资料已全部补充完成');
                    }
                } else {
                    // 已入网银联的商户，在每次登录商户后台时弹出二维码窗口
                    if (isFadadaFinish == 0) {
                        // 打开法大大弹框
                        this.setState({ openModal: 'fdd' });
                    }
                    // else if (isHOTEL() && isUnionFinish == 0 && showUinonPage == 1) {
                    //     this.setState({ openModal: 'yl', showUnionClose: false }); // 打开银联注册
                    // } else if (isHOTEL() && isUnionFinish == 1) {
                    //     this._openAdvertisementModal();
                    // }
                    else if (isFadadaFinish == 1 && isUnionFinish == 0 && showUinonPage == 1) {
                        // 打开银联入网弹框
                        this.setState({ openModal: 'yl', showUnionClose: false }); // 打开银联注册
                    } else if (isFadadaFinish == 1 && isUnionFinish == 1) {
                        this._openAdvertisementModal();
                    }
                }
            });
        }).catch((e) => {
            message.error(e);
        });
    }

    // 渲染弹框组件
    _renderModal = () => {
        const { openModal, showUnionClose, h5Url, merchantAgreementData } = this.state;
        if (openModal == 'fdd') {
            return <FadadaModal visible={true} closeMode={this._closeMode} h5Url={h5Url} />; // 法大大弹框
        } else if (openModal == 'yl') {
            return <UnionPay visible={true} closeMode={this._closeMode} showUnionClose={showUnionClose} />; // 银联弹框
        } else if (openModal == 'gg') {
            return <AdvertisementModal visible={true} closeMode={this._closeMode} history={this.props.history} />; // 广告弹框
        } else if (openModal == 'merchantAgreement') {
            return <MerchantAgreement visible={true} closeMode={this._closeMode} data={merchantAgreementData} />;
        } else {
            return null;
        }
    }

    // 关闭弹框组件
    _closeMode = (openWindow) => {
        let _curModal = this.state.openModal;
        let temp = this.openModalStack.filter(v=>v !== _curModal) || [];
        if(temp.length){
            _curModal = temp.shift();
            this.setState({ openModal: _curModal });
            this.openModalStack = temp;
        }else{
            this.setState({ openModal: '' });
        }
        if (openWindow != 'gg') {
            if (openWindow == 'yl') {
                this.setState({ openModal: 'yl', showUnionClose: true }); // 打开银联注册
            } else {
                // 检测银联是否已经注册，如果未注册则弹出系统升级弹框(当前广告弹框公共仅针对银联是否已注册)
                http('/contract/fadada/contractSignStatus').then((response) => {
                    const { isUnionFinish } = response.result;
                    if (isUnionFinish == 0) {
                        this._openAdvertisementModal();
                    }
                }).catch((e) => {
                    message.error(e);
                });
            }
        }
    }

    // 是否弹出通联框
    // _opentlmode = () => {
    //     http('/merchant/info/yums/tonlyMerchantSwitch').then((response) => {
    //         if (response.status == 200) {
    //             // 是否弹出通联注册框
    //             if (response.result == 1) {
    //                 getMemberInfo().then((memberInfo) => {
    //                     const { data } = memberInfo;
    //                     const { signContract } = data;
    //                     // 是否签署通联协议
    //                     if (!signContract) {
    //                         this.setState({ openModal: 'tl' }); // 打开通联注册
    //                     } else {
    //                         this._openggmode(); // 打开广告弹框
    //                     }
    //                 });
    //             } else {
    //                 this._openggmode(); // 打开广告弹框
    //             }
    //         }
    //     }).catch((e) => {
    //         message.error(e.message);
    //     });
    // }

    // 是否弹出广告弹框
    _openAdvertisementModal = (finish) => {
        http('/merchant/getTechServiceFeeChangeImg', {}).then((response) => {
            if (response.status == 200 && response.result) {
                this.openModalStack.push('gg');
                this.setState({ openModal: 'gg' });
            }
        }).catch((e) => {
            message.error(e.message);
        }).finally(()=>{
            this.getAgreementSignature();
        });
    }

    // 是否重新签署商户结算协议
    getAgreementSignature = ()=>{
        http('/merchant/contract/merchantSettleUnconfirmedAgreement').then((response) => {
            if (response.status == 200 && response.result) {
                this.openModalStack.push('merchantAgreement');
                this.setState({ openModal: 'merchantAgreement', merchantAgreementData: response.result });
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    render() {
        return (
            <div style={{ backgroundImage: `url(${Background})`, height: '100%', backgroundSize: 'revert' }}>
                {this._renderModal()}
            </div>
        );
    }
}

Welcome.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object
};

export default Welcome;