import React from 'react';
import { Table } from 'antd';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const TableComponent = ({ history, renderData: { dataList = [], pageNum = 1, pageSize = 30, resultTotal = 0 } = {}, opearteFunc, addOneBreadcrumbPath }) => {

    const onJumpage = (id = '') => {
        const path = `/home/chargeoffmanage/chargeofflist/chargeoffinfo/${id}`;
        addOneBreadcrumbPath({ title: '核销详情', path });
        history.push({ pathname: path });
    };

    const operateCell = (t, { state: { code = '' } = {} } = {}) => {
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => onJumpage(t)}>查看详情</div>
            {code === 'UNCONSUME' && <div className={styles.item} onClick={() => opearteFunc('verify', { id: t })}>核销</div>}
        </div>;
    };

    const columns = [
        { title: '会员账号', dataIndex: 'accountName', key: 'accountName', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '订单号', dataIndex: 'orderId', key: 'orderId', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '预留手机号', dataIndex: 'tel', key: 'tel ', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '商品名称', dataIndex: 'goodsName', key: 'goodsName', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '生成时间', dataIndex: 'orderPayTime', key: 'orderPayTime', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '使用时间', dataIndex: 'verificationTime', key: 'verificationTime', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '核销状态', dataIndex: 'state.value', key: 'state.value', align: 'center', onCell: tooltipStyle, render: tooltip },
        { title: '操作', dataIndex: 'id', key: 'id ', align: 'center', onCell: tooltipStyle, render: operateCell },
    ];

    return <div className={styles.tableContainer}>
        <Table
            rowClassName={(r, i) => i % 2 === 0 ? '' : styles.tdcolor}
            rowKey={r => r.id}
            columns={columns}
            dataSource={dataList}
            pagination={false}
            scroll={{ y: true }}
        />
        <XPagination
            resultTotal={resultTotal}
            pageSize={pageSize}
            pageNum={pageNum}
            onChange={(pageSize, pageNum) => opearteFunc('page', { pageSize, pageNum })} />
    </div>;
};
TableComponent.propTypes = {
    history: PropTypes.object,
    renderData: PropTypes.object,
    pageChange: PropTypes.func,
    opearteFunc: PropTypes.func,
    addOneBreadcrumbPath: PropTypes.func
};
export default TableComponent;