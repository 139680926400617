import React from 'react';
import styles from './OrderTable.module.scss';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, priceFenToYuanCell, tooltipClick } from '@/components/TableCell';

class OrderTable extends React.Component {

    state = {
        selectedRowKeys: [],
        selectedRowValues: [],
    };

    static getDerivedStateFromProps(props, state) {
        if (props.selectedRowKeys !== state.selectedRowKeys) {
            return {
                selectedRowKeys: props.selectedRowKeys,
                selectedRowValues: props.selectedRowValues
            };
        }
        return null;
    }

    _takeColumns = () => ([
        {
            title: '操作',
            dataIndex: 'action',
            width: 280,
            key: 'action',
            align: 'center',
            render: (text, item) => this._renderActionCell(item.id, item)
        },
        {
            title: '出库单号',
            dataIndex: 'id',
            key: 'id',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '批次号',
            dataIndex: 'batchNo',
            key: 'batchNo',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: (t, i) => tooltipClick(t, () => {
                this.props.tableAction(t, i, 'batchNo');
            })
        },
        {
            title: '订单编号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员手机',
            dataIndex: 'accountTel',
            key: 'accountTel',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品数量',
            dataIndex: 'number',
            key: 'number',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '实付金额(元)',
            dataIndex: 'payFee',
            key: 'payFee',
            width: 120,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '发货仓库',
            dataIndex: 'storehouseName',
            key: 'storehouseName',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单状态',
            dataIndex: 'statusValue',
            key: 'statusValue',
            width: 80,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单类型',
            dataIndex: 'orderTypeValue',
            key: 'orderTypeValue',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '下单时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单发货时间',
            dataIndex: 'logisticsCreateTime',
            key: 'logisticsCreateTime',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '打印次数',
            dataIndex: 'printCount',
            key: 'printCount',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否菜鸟面单',
            dataIndex: 'logisticsTypeTest.value',
            key: 'logisticsTypeTest.value',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '电子面单模板名称',
            dataIndex: 'templateName',
            key: 'templateName',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: (t, i) => tooltipClick(t, () => {
                this.props.tableAction(t, i, 'templateName');
            })
        },
        {
            title: '物流信息',
            dataIndex: 'logisticsCompany',
            key: 'logisticsCompany',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '物流单号',
            dataIndex: 'expressNo',
            key: 'expressNo',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单完成时间',
            dataIndex: 'finishedTime',
            key: 'finishedTime',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否批发',
            dataIndex: 'isDistribution',
            key: 'isDistribution',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否开票',
            dataIndex: 'isInvoiceValue',
            key: 'isInvoiceValue',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderActionCell = (key, item) => {
        const { tableAction, status } = this.props;
        const hasEdit = ['GOODS_CONFIRMED', 'GOODS_SENT', 'PART_GOODS_SENT'].includes(item.status);
        return (
            <div className={styles.action}>
                {
                    item.logisticsTypeTest && item.logisticsTypeTest.code == '2' && status == 1 &&
                    <div className={styles.item} onClick={() => tableAction(key, item, 'expressWaybill')}>重打菜鸟面单</div>
                }
                <div className={styles.item} onClick={() => tableAction(key, item, 'info')}>查看详情</div>
                <div className={styles.item} onClick={() => tableAction(key, item, 'logistics')}>查看物流</div>
                {hasEdit && (!item.logisticsTypeTest || item.logisticsTypeTest.code != '2') && <div className={styles.item} onClick={() => tableAction(key, item, 'deliver')}>修改快递信息</div>}
                {/* <div className={styles.item} onClick={() => tableAction(key, item, 'history')}>操作记录</div> */}
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRowValues) => {
                this.setState({ selectedRowKeys, selectedRowValues });
                this.props.selectedRowKeysChange(selectedRowKeys, selectedRowValues);
            }
        };
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [], selectedRowValues: [] }, () => {
            this.props.selectedRowKeysChange([], []);
        });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={this.state.selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection()}
                    rowKey={r => r.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    selectedRowKeys: PropTypes.array,
    selectedRowKeysChange: PropTypes.func,
    selectedRowValues: PropTypes.array,
    status: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default OrderTable;