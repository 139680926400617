/**
 * 商户人气值支取申请
 */
import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { XInput, XInputNum, XTextArea } from '@/components/xqxc_ui';
import model from '@/reducers/beanshopmanage/beanshop/model';
import { connect } from 'react-redux';

const Create = ({ closeMode, getBeanDrawApplyRatio, beanDrawApplyRatio }) => {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [starValueNum, setStarValueNum] = useState(0);
    const [remark, setRemark] = useState('');

    useEffect(() => {
        getBeanDrawApplyRatio({ starValue: starValueNum });
    }, [starValueNum]);

    const _handleOk = () => {
        setConfirmLoading(true);
        http('/xiudou/withdrawXiudou', { remark, starValueNum }, 'POST')
            .then(() => {
                message.success('商户申请人气值支取成功');
                setConfirmLoading(false);
                closeMode(true, starValueNum);
            }).catch((error) => {
                message.error(error.message);
                setConfirmLoading(false);
            });
    };

    const _handleCancel = () => {
        setConfirmLoading(false);
        closeMode('');
    };

    return (
        <Modal
            width={500}
            centered
            title='商户申请人气值支取'
            visible={true}
            confirmLoading={confirmLoading}
            onOk={_handleOk}
            onCancel={_handleCancel}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认支取'
        >
            <div className={styles.container}>
                <div className={StyleSheet.top}>人气值支取服务费{beanDrawApplyRatio.serviceRate ? beanDrawApplyRatio.serviceRate / 100 : 0}%，按本次支取的人气值数额外扣除服务费需要的人气值</div>
                <XInput
                    label='商户本次可用人气值'
                    style={{ marginTop: '20px' }}
                    inputStyle={{ width: '300px' }}
                    disabled={true}
                    value={beanDrawApplyRatio.availableStarValue} />
                <XInputNum
                    isRequired={true}
                    label='本次支取人气值'
                    style={{ marginTop: '20px' }}
                    inputStyle={{ marginLeft: '20px', width: '300px' }}
                    bindThis={setStarValueNum}
                    bindName='starValueNum'
                    value={starValueNum} />
                <XInput
                    label='服务费'
                    style={{ marginTop: '20px' }}
                    disabled={true}
                    inputStyle={{ marginLeft: '70px', width: '300px' }}
                    value={beanDrawApplyRatio.serviceFee} suffix='人气值' />
                <XInput
                    label='支付人气值'
                    style={{ marginTop: '20px' }}
                    disabled={true}
                    inputStyle={{ marginLeft: '60px', width: '300px' }}
                    value={beanDrawApplyRatio.payStarValue} />
                <XTextArea
                    label='申请备注'
                    style={{ marginTop: '20px' }}
                    placeholder='请输入申请备注'
                    onChange={(e) => {
                        console.log(e);
                        if (e.length > 255) {
                            message.warn('备注长度不超过255个汉字');
                        } else {
                            setRemark(e);
                        }
                    }}
                    inputStyle={{ marginLeft: '60px', width: '300px' }}
                    value={remark} />
            </div>
        </Modal>
    );
};

Create.propTypes = {
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getBeanDrawApplyRatio: PropTypes.func,
    beanDrawApplyRatio: PropTypes.object,
};

const mapStateToProps = (state) => {
    return ({
        beanDrawApplyRatio: state.beanshop.beanDrawApplyRatio,
    });
};
export default connect(mapStateToProps, { ...model.actions })(Create);