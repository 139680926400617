import { message } from 'antd';

/**
 * 如果函数不为空就调用
 * @param fn 调用的函数
 * @param fn 的参数
 */
export function callFn(fn, ...props) {
    if (typeof fn === 'function') return fn(...props);
}

export function DateFormat(date, format) {
    var o = {
        'M+': date.getMonth() + 1,                 //月份   
        'd+': date.getDate(),                    //日   
        'h+': date.getHours(),                   //小时   
        'm+': date.getMinutes(),                 //分   
        's+': date.getSeconds(),                 //秒   
        'q+': Math.floor((date.getMonth() + 3) / 3), //季度   
        'S': date.getMilliseconds()             //毫秒   
    };
    if (/(y+)/.test(format))
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp('(' + k + ')').test(format))
            format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
    return format;
}

export function timeToMinute(times) {
    let result = '00:00:00';
    let hour, minute, second;
    if (times > 0) {
        hour = Math.floor(times / 3600);
        if (hour < 10) {
            hour = '0' + hour;
        }
        minute = Math.floor((times - 3600 * hour) / 60);
        if (minute < 10) {
            minute = '0' + minute;
        }

        second = Math.floor((times - 3600 * hour - 60 * minute) % 60);
        if (second < 10) {
            second = '0' + second;
        }
        if (hour == '00') {
            result = minute + ':' + second;
        } else if (minute == '00') {
            result = hour + ':' + minute + ':' + second;
        } else {
            result = second;
        }
    }
    return result;
}

export function formValids(values, tips) {
    for (let key in tips) {
        if (values[key] === '' || values[key] === undefined || values[key] === null) {
            message.warning(tips[key]);
            return false;
        }
    }
    return true;
}
export function formValidFns(values, tips) {
    for (let key in tips) {
        let valid = false;
        if (callFn(tips[key].required, values[key]) !== false) {
            if ((values[key] === '' || values[key] === undefined || values[key] === null)) valid = true;
        }
        if (tips[key].validator) {
            valid = !tips[key].validator(values[key]);
        }
        if (valid) {
            message.warning(typeof tips[key] === 'string' ? tips[key] : tips[key].message);
            return false;
        }
    }
    return true;
}

export function tryJSONParse(jsonStr, empty, catchFn) {
    let result = empty || {};
    try {
        result = JSON.parse(jsonStr) || empty;
    } catch (e) { callFn(catchFn, e); }
    return result;
}

export function isEmpty(value) {
    return value === undefined || value === null || value === '';
}