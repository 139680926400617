/**
 * 【员工列表】
 */
import React from 'react';
import { message } from 'antd';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import TableContent from './components/TableContent';
import NoLogin from './modal/NoLogin';
import ResetPassword from './modal/ResetPassword';
import DeleteModal from './modal/DeleteModal';
import { getEmplList, getDeptSelect, getEmplState } from '@/reducers/permissionsmanage/employeelist/actions';
import http from '@/assets/api/http';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '', // 员工姓名
            status: {}, // 状态
            dept: {}, // 部门
            currentOpenMode: '', // 当前弹框
            openModeParam: '', // 弹框入参 
        };
        this.searchCache = { pageNum: 1, pageSize: 25 }; // 查询缓存
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getEmplList(this.searchCache);
        this.props.getEmplState();
        this.props.getDeptSelect();
    }

    // 查询
    _searchHandle = () => {
        const { name, status, dept } = this.state;

        this.searchCache = {
            emplName: name,
            state: status.code,
            deptId: dept.id,
            pageNum: 1,
            pageSize: 25
        };
        this.props.getEmplList(this.searchCache);
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            name: '',
            status: {},
            dept: {},
            openMode: '',
            modeParam: ''
        });
        this.searchCache = { pageNum: 1, pageSize: 25 };
        this.props.getEmplList(this.searchCache);
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getEmplList(this.searchCache);
    }

    // 表格操作
    _tableAction = (id, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'edit':
                addOneBreadcrumbPath({
                    title: '编辑员工信息',
                    path: '/home/privilegemanage/employeelist/edit/'
                });
                history.push({
                    pathname: `/home/privilegemanage/employeelist/edit/${id}`
                });
                break;
            case 'disable': // 有两种情况：禁止和启用
                this.setState({ currentOpenMode: 'state', openModeParam: id });
                break;
            case 'able':
                http('/admin/empl/activeEmpl', { id }, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('启用成功！');
                        this.props.getEmplList(this.searchCache);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
            case 'reset':
                this.setState({ currentOpenMode: 'reset', openModeParam: id });
                break;
            case 'del':
                this.setState({ currentOpenMode: 'del', openModeParam: id });
                break;
            case 'permission':
                addOneBreadcrumbPath({
                    title: '仓库权限',
                    path: '/home/privilegemanage/employeelist/permission/'
                });
                history.push({
                    pathname: `/home/privilegemanage/employeelist/permission/${id}`
                });
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'state':
                return <NoLogin
                    title='禁止登录'
                    visible={true}
                    id={this.state.openModeParam}
                    closeMode={this._closeMode}
                />;
            case 'reset':
                return <ResetPassword
                    title='重置密码'
                    visible={true}
                    id={this.state.openModeParam}
                    closeMode={this._closeMode}
                />;
            case 'del':
                return <DeleteModal
                    title='系统提示'
                    visible={true}
                    id={this.state.openModeParam}
                    closeMode={this._closeMode}
                />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' });
        if (update) {
            this.props.getEmplList(this.searchCache);
        }
    }

    // 新建员工
    _newHandle = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建员工',
            path: '/home/privilegemanage/employeelist/add'
        });
        history.push({
            pathname: '/home/privilegemanage/employeelist/add'
        });
    }

    render() {
        const { name, status, dept } = this.state;
        const { emplList, emplState, deptSelect } = this.props;

        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.row1}>
                    <XInput
                        style={{ width: '270px' }}
                        inputStyle={{ width: '180px' }}
                        label='员工名称'
                        placeholder='请输入员工名称'
                        value={name}
                        bindThis={this}
                        bindName='name'
                    />
                    <XSelect
                        style={{ width: '220px' }}
                        selectStyle={{ width: '150px' }}
                        label='状态'
                        placeholder='请选择状态'
                        renderData={emplState}
                        dataIndex='value'
                        keyIndex='code'
                        value={status.value}
                        bindThis={this}
                        bindName='status'
                    />
                    <XSelect
                        style={{ width: '220px' }}
                        selectStyle={{ width: '150px' }}
                        label='所属部门'
                        placeholder='请选择所属'
                        renderData={deptSelect}
                        dataIndex='deptName'
                        keyIndex='id'
                        value={dept.deptName}
                        bindThis={this}
                        bindName='dept'
                    />
                    <XOKButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='查询'
                        onClick={this._searchHandle}
                    />
                    <XCancelButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='重置'
                        onClick={this._resetHandle}
                    />
                </div>
                <div className={styles.row2}>
                    <XOKButton
                        style={{ width: '100px' }}
                        label='新建员工'
                        onClick={this._newHandle}
                    />
                </div>
                <TableContent renderData={emplList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                {this._renderMode()}
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getEmplList: PropTypes.func, // 获取员工列表
    emplList: PropTypes.object, // 员工列表
    getDeptSelect: PropTypes.func, // 获取部门下拉列
    deptSelect: PropTypes.array, // 部门下拉列
    getEmplState: PropTypes.func,  // 获取员工状态
    emplState: PropTypes.array, // 员工状态
};

const mapStateToProps = (state) => ({
    emplList: state.employee.getEmplList,
    deptSelect: state.employee.getDeptSelect,
    emplState: state.employee.getEmplState,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getEmplList, getDeptSelect, getEmplState })(Main);