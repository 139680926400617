/**
 * 商户营销活动 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

/**
 *  商户营销活动列表 Saga
 */
export const MarketingList = function* () {
    yield takeEvery(T.GET_MARKINGLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/marketing/activityMerchantInfo/list', action.payload, 'POST');
            yield put({ type: T.SET_MARKINGLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 *  商户营销活动编辑获取新建详情 Saga
 */
export const marketingDetails = function* () {
    yield takeEvery(T.GET_MARKETINGDETAIL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/marketing/activityMerchantInfo/detailAndGoods', action.payload, 'POST');
            yield put({ type: T.SET_MARKETINGDETAIL_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 *  商户营销活动下拉框 Saga 
 */
export const marketingDropDown = function* () {
    yield takeEvery(T.GET_DROPDIWN_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let activity, activeState;
            if (action.payload.only == 'only') {
                activity = yield http('/activity/info/selectMerchantActivityType', action.payload, 'POST');//活动类型
            } else {
                activity = yield http('/activity/info/selectMerchantActivityType', action.payload, 'POST');//活动类型
                activeState = yield http('/activity/info/selectActivityIsUse', action.payload, 'POST');//活动状态
                activity.activeState = activeState.result;
            }
            yield put({ type: T.SET_DROPDIWN_DATA, payload: activity });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 *  商户活动新建数据Saga
 */
export const productRaiseTable = function* () {
    yield takeEvery(T.GET_RAISETABALE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let table = yield http('/goods/query/queryGoodsItem', action.payload, 'POST');//商户活动新建数据Saga
            yield put({ type: T.SET_RAISETABALE_DATA, payload: table });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 *  商户活动新增sku设置条件saga
 */
export const productSkuTable = function* () {
    yield takeEvery(T.GET_SKUTABALE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let table = yield http('/goods/goods/findGoodsSkuAndStock', action.payload, 'POST');//商户活动新增sku设置条件saga
            yield put({ type: T.SET_SKUTABALE_DATA, payload: table });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 *   商户营销活动》详情》活动商品列表 Saga
 */
export const marketingProductList = function* () {
    yield takeEvery(T.GET_MARKINGPRODUCTLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/marketing/activityMerchantInfo/goodsList', action.payload, 'POST');
            yield put({ type: T.SET_MARKINGPRODUCTLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 *   商户营销活动》详情》活动商品列表 > 详情Saga
 */
export const marketingProductDetails = function* () {
    yield takeEvery(T.GET_MARKINGPRODUCTDETAIL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/marketing/activityMerchantInfo/activityGoodsDetail', action.payload, 'POST');
            yield put({ type: T.SET_MARKINGPRODUCTDETAIL_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 营销活动新建-分类（新人专区）会员等级 Saga
 */
export const productRaiseAll = function* () {
    yield takeEvery(T.GET_RAISEALL_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let checkall = yield http('/user/level/listAccountLevel', action.payload, 'POST');
            yield put({ type: T.SET_ALL_DATA, payload: checkall });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
