import React from 'react';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class TableContent extends React.Component {

    _takeColumns = () => ([
        {
            title: '网站名称',
            dataIndex: 'websiteName',
            key: 'websiteName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '网站简介',
            dataIndex: 'shopDesc',
            key: 'shopDesc',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '联系人',
            dataIndex: 'contactName',
            key: 'contactName',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '联系人电话',
            dataIndex: 'contactTel',
            key: 'contactTel',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            align: 'center',
            render: (text, record) => this._tableAction(text, record)
        },
    ]);

    _takeDataSource = (dataList = []) => {
        return dataList;
    }

    _tableAction = (text, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction('edit', record)}>编辑</div>
                <div className={styles.item} onClick={() => tableAction('open', record)}>进入官网后台</div>
            </div>
        );
    };

    render() {
        const dataList = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.array, // 表格数据源
    tableAction: PropTypes.func, // 对表格的操作
};

export default TableContent;