import * as T from './actiontypes';

export const getInvoiceList = (value) => ({
    type: T.GET_INVOICELIST,
    payload: value
});

export const getPayManageTableSource = (value) => ({
    type: T.GET_PAYMANAGETABLESOURCE,
    payload: value
});

export const getPayManageDetail = (value) => ({
    type: T.GET_PAYMANAG_EDETAIL,
    payload: value
});