import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableData from './components/TableData';
import { getProductBrand } from '@/reducers/productmanage/brand/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class ProductBrand extends Component {

    state = {
        brandName: '',
    }

    // 查询
    _searchHandle = (useCache) => {
        const { brandName, pageNum, pageSize } = this.state;
        this.searchCache = {
            brandName,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        
        this.props.getProductBrand(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ brandName: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //申请商品品牌
    _applyBrand = () => {
        this.props.updateContent('BRAND_APPLY');
    }

    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        const { brandName } = this.state;
        this.props.getProductBrand({ brandName, pageNum, pageSize });
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    _tableHandle = (id, type, params) => {
        switch (type) {
            case 'detail':
                this.props.updateContent('BRAND_DETAIL', params.id);
                break;
        }
    }

    render() {
        const { brandName } = this.state;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='品牌名称'
                            placeholder='请输入品牌名称'
                            bindThis={this}
                            bindName='brandName'
                            value={brandName}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton label='申请商品品牌' onClick={this._applyBrand} />
                    </div>
                    <TableData paginationChange={this._paginationChange} tableHandle={this._tableHandle} renderData={this.props.productBrand} />
                </div>
            </KeepAlive>
        );
    }
}

ProductBrand.propTypes = {
    updateContent: PropTypes.func,
    getProductBrand: PropTypes.func,
    productBrand: PropTypes.object
};

const mapStateToProps = (state) => ({
    productBrand: state.productBrandData.productBrand
});

export default connect(mapStateToProps, { getProductBrand })(ProductBrand);