import * as T from './actiontypes';

// 结算价调整列表
export const getBalanceHintList = (value = {}) => ({
    type: T.GET_BALANCEHINT_LIST,
    payload: value
});

// 结算价调整列表
export const getNotBalanceHintList = (value = {}) => ({
    type: T.GET_NOTBALANCEHINT_LIST,
    payload: value
});

// 结算价调整信息
export const getAutoAdjustInfo = () => ({
    type: T.GET_BALANCEHINT_INFO,
    payload: {}
});