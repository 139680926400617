import * as T from './actiontypes';

/**
 * 获取商品分类列表
 */
export const getProductClassify = (params = {}) => ({
    type: T.GET_PRODUCT_CLASSIFY,
    payload: params
});

export const addNullCell = (value) => ({
    type: T.ADD_PRODUCT_NULLCELL,
    payload: value
});

export const getClassifySele = () => ({
    type: T.GET_PRODUCT_CLASSIFYSELE,
    payload: { categoryGrade: '', parentId: 0 }
});


