import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './TableBox.module.scss';
import { Table, Icon, InputNumber, Typography, Tooltip } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell, priceFenToYuanCell } from '@/components/TableCell';
import { getConfigInfo } from '@/assets/js/authType.js';

const LowInventoryTable = ({
    tableOperate,
    renderData: {
        dataList,
        pageNum,
        pageSize,
        resultTotal
    },
    paginationChange,
    showHideEdit,
    batchKeys = []
}) => {

    const [keys, setKeys] = useState([]);
    const [limit, setLimit] = useState(false);

    getConfigInfo()
        .then(res => {
            setLimit(res.isWhiteAudit);
        })
        .catch(err => {
            console.log(err.message);
        });

    useEffect(() => { setKeys(batchKeys); }, [batchKeys]);

    const renderEditCell = (text, { active = false, wholesaleGoodsSkuId = 0 }) => {
        return active ?
            <InputNumber
                key={wholesaleGoodsSkuId}
                defaultValue={text}
                min={0}
                onBlur={e => {
                    tableOperate('inventory', {
                        wholesaleSkuIds: [{
                            totalQuantity: e.target.value,
                            wholesaleSkuId: wholesaleGoodsSkuId
                        }]
                    });
                }}
            /> :
            <Fragment>
                <Typography.Text>{text}</Typography.Text>
                <Icon
                    style={{ marginLeft: 5 }}
                    type='edit'
                    theme='twoTone'
                    onClick={() => showHideEdit({ wholesaleGoodsSkuId, bool: true })}
                />
            </Fragment>;
    };

    const limitTitle = <Fragment>
        <span>限购（每人/件）</span>
        <Tooltip
            placement='topRight'
            title='每件商品用户在每个销售渠道的可购买数量'>
            <Icon
                type='question-circle'
                theme='filled'
            />
        </Tooltip>
    </Fragment>;


    let columns = [
        {
            title: '商品图片',
            dataIndex: 'imgUrl',
            key: 'imgUrl',
            align: 'center',
            width: '10%',
            render: ImgCell
        }, {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'center',
            width: '12%',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: 'SKU编码',
            dataIndex: 'skuNo',
            key: 'skuNo',
            align: 'center',
            width: '12%',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: limitTitle,
            dataIndex: 'limitNumber',
            key: 'limitNumber',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '规格属性',
            dataIndex: 'spec',
            key: 'spec',
            align: 'center',
            width: '12%',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '市场价（元）',
            dataIndex: 'marketPrice',
            key: 'marketPrice',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        }, {
            title: '销售价（元）',
            dataIndex: 'salePrice',
            key: 'salePrice',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        }, {
            title: '结算价（元）',
            dataIndex: 'wholesalePrice',
            key: 'wholesalePrice',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        }, {
            title: '批发库存',
            dataIndex: 'totalQuantity',
            key: 'totalQuantity',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '下架时间',
            dataIndex: 'autoOffTime',
            key: 'autoOffTime',
            align: 'center',
            width: '12%',
            onCell: tooltipStyle,
            render: tooltip
        },
        // {
        //     title: '预警时间',
        //     dataIndex: 'time',
        //     key: 'time',
        //     align: 'center',
        //     width: '12%',
        //     onCell: tooltipStyle,
        //     render: tooltip
        // }
    ];

    // 表格复选输入
    const rowSelection = {
        selectedRowKeys: keys,
        onChange: (selectedRowKeys) => {
            setKeys(selectedRowKeys);
            tableOperate('batchKeys', { batchKeys: selectedRowKeys });
        }
    };

    return (
        <div className={styles.tableContainer}>
            <XTableSelectionCount
                selectedNum={keys.length}
                onClean={() => {
                    setKeys([]);
                    tableOperate('batchKeys', { batchKeys: [] });
                }} />
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.wholesaleGoodsSkuId}
                rowSelection={rowSelection}
                columns={limit ? columns : columns.filter(item => item.key != 'limitNumber')}
                pagination={false}
                dataSource={dataList}
                scroll={{ x: limit ? '108%' : '100%', y: true }} />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange} />
        </div>
    );
};

LowInventoryTable.propTypes = {
    tableOperate: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
    showHideEdit: PropTypes.func,
    batchKeys: PropTypes.array
};

export default LowInventoryTable;