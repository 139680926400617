import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, message, Input, Button } from 'antd';
import styles from './Batch.module.scss';
import { getBalanceAuditDetail } from '@/reducers/auditManage/balanceAudit/actions';

const { TextArea } = Input;

class Audit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
            reason: ''
        };
    }

    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST').then(() => {
            message.success(winTxt);
            this.setState({
                visible: false,
            });
            this.props.closeMode(true);
        }).catch((reject) => {
            message.error(reject.message);
        });
    }
    //审核通过
    _auditPass = () => {
        const { batch } = this.props;
        const { reason } = this.state;
        if (!reason) {
            message.error('请输入原因！');
        } else {
            this._sendReq('/goods/inventory/goodsSettleAudit', { auditDesc: reason, auditStatus: 'PASS_AUDIT', settleRecordIds: [...batch] }, '审核操作成功。');
        }
    }
    //审核不通过
    _auditNotPass = () => {
        const { batch } = this.props;
        const { reason } = this.state;
        if (!reason) {
            message.error('请输入原因！');
        } else {
            this._sendReq('/goods/inventory/goodsSettleAudit', { auditDesc: reason, auditStatus: 'FAIL_PASS', settleRecordIds: [...batch] }, '审核操作成功。');
        }
    }
    // 点击确认
    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }
    _reasonChange = (e) => {
        this.setState({ reason: e.target.value });
    }

    render() {
        const { batch } = this.props;
        const { visible, confirmLoading, reason } = this.state;
        return (
            <Modal
                width={470}
                centered
                title='供应商结算价审核'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                footer={false}>
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        <div className={styles.label}>商品数量：</div>
                        <div className={styles.text}>{batch.length}</div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.label}><b>*</b>原因：</div>
                        <div className={styles.text}>
                            <TextArea
                                placeholder='请输入原因！'
                                onChange={this._reasonChange}
                                value={reason}
                                style={{ width: '300px' }}
                            ></TextArea>
                        </div>
                    </div>
                    <div className={styles.btn}>
                        <Button type='primary' onClick={this._auditPass}>审核通过</Button>
                        <Button style={{ marginLeft: '20px' }} onClick={this._auditNotPass}>审核不通过</Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

Audit.propTypes = {
    data: PropTypes.object, // 弹框入参
    batch: PropTypes.array,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getBalanceAuditDetail: PropTypes.func,

    balanceAuditDetail: PropTypes.object,
};

const mapStateToProps = (state) => ({
    balanceAuditDetail: state.balanceAudit.balanceAuditDetail,
});

export default connect(mapStateToProps, { getBalanceAuditDetail })(Audit);