/**
 * 通联注册弹框
 */
import React from 'react';
import styles from './index.module.scss';
import { Modal, Steps, Spin, Alert } from 'antd';
import PropTypes from 'prop-types';
import getMemberInfo from './components/getMemberInfo';
import { Step1, Step2, Step3 } from './step';

const { Step } = Steps;
class AllPay extends React.Component {

    state = {
        show: false,
        visible: this.props.visible,
        confirmLoading: false,
        unitType: '', // 类型：PERSON个体户、BUSINESS企业
        curStep: 0, // 当前指引步骤数
    }

    componentDidMount() {
        getMemberInfo().then((response) => {
            const { type, data } = response;
            // identityChecked是否实名，phoneChecked是否绑定手机，signContract是否签署协议
            if (type == 'PERSON') {
                const { identityChecked, phoneChecked, signContract } = data;
                if (!identityChecked) {
                    // 未实名认证
                    this.setState({ unitType: 'PERSON', curStep: 1 });
                } else if (identityChecked && !phoneChecked) {
                    // 未绑定手机
                    this.setState({ unitType: 'PERSON', curStep: 2 });
                } else if (phoneChecked && !signContract) {
                    // 未签署协议
                    this.setState({ unitType: 'PERSON', curStep: 3 });
                } else if (signContract) {
                    // 已经签署协议
                    this.props.closeMode();
                }
            } else {
                const { status, phoneChecked, signContract } = data;
                const identityChecked = status == '2' ? true : false;
                if (!identityChecked) {
                    // 未实名认证
                    this.setState({ unitType: 'BUSINESS', curStep: 1 });
                } else if (identityChecked && !phoneChecked) {
                    // 未绑定手机
                    this.setState({ unitType: 'BUSINESS', curStep: 2 });
                } else if (phoneChecked && !signContract) {
                    // 未签署协议
                    this.setState({ unitType: 'BUSINESS', curStep: 2 });
                } else if (signContract) {
                    // 已经签署协议
                    this.props.closeMode();
                }
            }
            this.setState({ show: true });
        });
    }

    _handleCancel = () => {
        this.props.closeMode();
    }

    _renderStepContent = () => {
        const { curStep, unitType } = this.state;
        switch (curStep) {
            case 1:
                return <Step1 next={() => { this.setState({ curStep: 2 }); }} onChange={(utype) => this.setState({ unitType: utype })} />;
            case 2:
                return <Step2 signSuccess={this.props.closeMode} previous={() => { this.setState({ curStep: 1 }); }} next={() => { this.setState({ curStep: unitType == 'PERSON' ? 3 : 4 }); }} />;
            case 3:
                return <Step3 signSuccess={this.props.closeMode} previous={() => { this.setState({ curStep: 2 }); }} />;
        }
    }

    render() {
        const { show, visible, unitType, curStep } = this.state;
        return (
            <Modal
                width={1000}
                centered
                title='注册财务账号（流程二）'
                visible={visible}
                destroyOnClose={true}
                keyboard={false}
                maskClosable={false}
                closable={false}
                footer={null}
                onCancel={this._handleCancel}
            >
                {show ? <> <Steps current={(curStep - 1)}>
                    <Step title="填写基础信息" />
                    <Step title="绑定手机" />
                    {unitType == 'PERSON' ? <Step title="绑定银行卡" /> : null}
                    <Step title="签订电子协议" />
                </Steps>
                    <div className={styles.stepContent}>
                        {this._renderStepContent()}
                    </div></> : <Spin tip="加载财务配置信息...">
                        <div className={styles.spinContent} />
                    </Spin>}
            </Modal>
        );
    }
}

AllPay.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框回调事件
};

export default AllPay;

