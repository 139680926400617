import * as Order from './actionTypes';

// 订单交易明细列表
export const getTranscationList = (param) => ({
    type: Order.GET_TRANSCATION_LIST,
    payload: param,
});
// 获取店铺
export const getStoreList = (param) => ({
    type: Order.GET_STORE_LIST,
    payload: param,
});
// 订单交易明细列表（统计用）
export const getTranscationList2 = (param) => ({
    type: Order.GET_TRANSCATION_LIST2,
    payload: param,
});
// 订单应收明细合计
export const getMerchantOrderAmount = (param) => ({
    type: Order.GET_ORDER_AMOUNT,
    payload: param,
});
