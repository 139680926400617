/**
 * 代言任务
 */
import React from 'react';
import styles from './index.module.scss';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm, XTabNav } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import PopconfirmModal from '../../modal/PopconfirmModal';
import ActivityDate from '../../modal/ActivityDate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';
import { message } from 'antd';
import { parse } from 'qs';
import model from '@/reducers/marketingmanage/advocacyMerchants/model';

class Marketing extends React.Component {
    state = {
        type: {},//代言类型  
        status: {},//全部代言状态
        state: {},//审核状态
        task: '',//代言任务
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参

        tabs: [
            { id: 1, label: '全部代言' },
            { id: 5, label: '未开始' },
            { id: 2, label: '进行中' },
            { id: 3, label: '已结束' },
            { id: 4, label: '禁用' },
            { id: 6, label: '审核' },
        ],
        curt: 1,//tab默认
    };

    componentDidMount() {
        const { getAdvocacyType, getAdvocacyState, getAdvocacyStatus, location } = this.props;
        getAdvocacyType();
        getAdvocacyState();
        getAdvocacyStatus();
        const { logName } = parse(location.search.substr(1));
        if (logName == 'create') {
            // 新建成功了统一跳转审核tabs
            this._anchorClickHandle(6);
        }
    }

    // 导航切换回调
    _anchorClickHandle = (id) => {
        this.setState({ type: {}, state: {}, status: {}, task: '', curt: id, }, () => {
            this._searchHandle();// 重新渲染更新列表
        });
    }

    // 导航切换
    _anchorMoveHandle = () => {
        const { curt } = this.state;
        return (
            this.state.tabs.map((item, index) => {
                if (index == 0) {
                    return (
                        <span key={item.id} onClick={() => this._anchorClickHandle(item.id)} className={curt == item.id ? styles.curt : ''}>{item.label}</span>
                    );
                } else {
                    return (
                        <span key={item.id} onClick={() => this._anchorClickHandle(item.id)} className={curt == item.id ? styles.curt : ''}>{item.label}</span>
                    );
                }
            })
        );
    }
    // 新建攻略
    _createStudioRoom = () => {
        this.props.updateContent('CREAT');
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { task, type, state, status, pageSize, pageNum, curt, } = this.state;
        let curType;//活动区分 全部、结束，未开始等
        if (curt == '1') {
            curType = '*';
        } else if (curt == '2') {
            curType = 'WORK';
        } else if (curt == '3') {
            curType = 'ENDED';
        } else if (curt == '4') {
            curType = 'DISABLED';
        } else if (curt == '5') {
            curType = 'NOT_STARTED';
        } else if (curt == '6') {
            curType = 'CHECK';
        }
        this.searchCache = {
            taskName: task,//代言任务  
            taskType: type.code,//任务类型(直播、视频、图文)	  
            checkState: state.code,//审核状态  
            state: status.code,//全部代言状态  
            spokesmanTaskStatus: curType,//区分 全部、结束，未开始等
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getAdvocacyTableList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ type: {}, state: {}, status: {}, task: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getAdvocacyTableList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作  
    _tableAction = (id, type, value) => {
        switch (type) {
            case 'detail':
                this.props.updateContent('DETAIL', id);
                break;
            case 'delete'://删除
                this.setState({
                    currentOpenMode: 'DELETE',
                    openModeParam: { id }
                });
                break;
            case 'edit':
                this.props.updateContent('EDIT', id);
                break;
            case 'getDetail'://领取详情
                {
                    const { taskName, ...restStat } = value;
                    http('/spokesmantask/config/getUserSpokesmanTaskStatistics', { id }, 'POST')
                        .then((res) => {
                            this.setState({
                                currentOpenMode: 'data',
                                openModeParam: { ...res.result, taskName, id }
                            });
                        }).catch((reject = {}) => {
                            message.error(reject.message);
                        });
                }
                break;
            case 'complaint'://申述
                {
                    const complaintext = <span>{value.disableReasons}，请联系客服！<br />客服电话：400-000-1111</span>;
                    showConfirm('禁用原因', complaintext, () => { });
                }
                break;
        }
    }

    // 打开弹窗  
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'DELETE':
                return <PopconfirmModal visible={true} closeMode={this._closeMode} data={this.state.openModeParam} />;
            case 'data':
                return <ActivityDate title={this.state.openModeParam.taskName} visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getAdvocacyTableList(this.searchCache);
        }
    }

    render() {
        const { type, task, state, curt, status, tabs } = this.state;
        const { advocacyType, advocacyList, advocacyState, advocacyStatus } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    {/* <div className={`${styles.top_title} ${styles.page}`}>
                        {this._anchorMoveHandle()}
                    </div> */}
                    <XTabNav style={{ marginBottom: '10px' }} renderData={tabs} onChange={tab => this._anchorClickHandle(tab)} activedID={curt} />
                    <div className={styles.search_box} style={{ marginTop: '10px' }}>
                        <XInput
                            inputStyle={{ width: '150px' }}
                            label='代言任务'
                            placeholder='请输入'
                            value={task}
                            bindThis={this}
                            bindName='task'
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '150px' }}
                            label='代言类型'
                            placeholder='请选择'
                            renderData={advocacyType}
                            dataIndex='name'
                            keyIndex='code'
                            value={type.name}
                            bindThis={this}
                            bindName='type'
                            showSearch={true}
                        />
                        {curt == '1' && <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '150px' }}
                            label='状态'
                            placeholder='请选择'
                            renderData={advocacyStatus}
                            dataIndex='value'
                            keyIndex='code'
                            value={status.value}
                            bindThis={this}
                            bindName='status'
                            showSearch={true}
                        />}
                        {curt == '6' && <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '150px' }}
                            label='审核状态'
                            placeholder='请选择'
                            renderData={advocacyState}
                            dataIndex='name'
                            keyIndex='code'
                            value={state.name}
                            bindThis={this}
                            bindName='state'
                            showSearch={true}
                        />}
                        <XOKButton
                            style={{ marginLeft: '30px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '30px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '90px' }} label='新建代言' onClick={this._createStudioRoom} />
                    </div>
                    <TableContent renderData={advocacyList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Marketing.propTypes = {
    history: PropTypes.object, // 路由history对象
    location: PropTypes.object,
    match: PropTypes.object,
    updateContent: PropTypes.func, // 路由跳转
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,

    advocacyType: PropTypes.array,
    getAdvocacyType: PropTypes.func,//代言类型数据(action)
    getAdvocacyTableList: PropTypes.func,//列表数据(action)
    advocacyList: PropTypes.object,
    advocacyState: PropTypes.array,
    getAdvocacyState: PropTypes.func,//代言审核状态数据(action)
    advocacyStatus: PropTypes.array,
    getAdvocacyStatus: PropTypes.func,//全部代言状态数据(action)
};

const mapStateToProps = (state) => {
    return {
        advocacyList: state.advocacyMerchants.advocacyTableList, // 列表数据
        advocacyType: state.advocacyMerchants.advocacyType, // 代言类型数据
        advocacyState: state.advocacyMerchants.advocacyState, // 代言审核状态数据
        advocacyStatus: state.advocacyMerchants.advocacyStatus, // 全部代言状态数据
    };
};

export default connect(mapStateToProps, { ...model.actions })(Marketing);
