/**
 * 订单交易明细
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Order from './actionTypes';

//订单交易明细列表
export const getTranscationList = function* () {
    yield takeEvery(Order.GET_TRANSCATION_LIST, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationSource/settleOrderDetail', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_TRANSCATION_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//店铺列表
export const getStoreList = function* () {
    yield takeEvery(Order.GET_STORE_LIST, function* requestData(action) {
        try {
            let result = yield http('/shop/getShopDropDownList', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_STORE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//订单交易明细列表（统计用）
export const getTranscationList2 = function* () {
    yield takeEvery(Order.GET_TRANSCATION_LIST2, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationSource/settleOrderDetail2', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_TRANSCATION_LIST2, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 订单应收明细合计
export const getMerchantOrderAmount = function* () {
    yield takeEvery(Order.GET_ORDER_AMOUNT, function* requestData(action) {
        try {
            let result = yield http('/settle/reconciliationSource/getMerchantOrderAmount', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_ORDER_AMOUNT, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
