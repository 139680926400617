/**
 * 收支明细
 */
import React from 'react';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { SearchBox, SearchItem } from '@/components/Layout';
import { XOKButton, XDatePicker, XCancelButton, XSelect } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/previewcollectionmanage/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';
import { regFenToYuan } from '@/util/money';

class Main extends React.Component {
    state = {
        renderMode: '',
        modeParams: {},

        payStartTime: null,
        payEndTime: null,
        stateItem: {},

        balance: 0,
        totalBalance: 0,
        withdrawedBalance: 0
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getDrawIncomeEnum();

        http('/settle/advances/received/balance').then(res => {
            if (res.code === 200) {
                this.setState({
                    balance: regFenToYuan(res.result.balance),
                    totalBalance: regFenToYuan(res.result.totalBalance),
                    withdrawedBalance: regFenToYuan(res.result.withdrawedBalance)
                });
            }
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { payStartTime, payEndTime, pageNum, pageSize, stateItem } = this.state;
        this.searchCache = {
            starTime: payStartTime,
            endTime: payEndTime,
            type: stateItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getDrawIncomeList(this.searchCache);
        KeepAlive.saveCache({ ...this.searchCache, stateItem }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            stateItem: {},
            payStartTime: null,
            payEndTime: null,
            pageNum: 1,
            pageSize: 10
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }


    render() {
        const { payStartTime, payEndTime, stateItem, balance, totalBalance, withdrawedBalance } = this.state;
        const { drawIncomeList, drawIncomeEnum } = this.props;

        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.balanceInfo}>
                        <div>余额：{balance}</div>
                        <div>总充值：{totalBalance}</div>
                        <div>总支出：{withdrawedBalance}</div>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <SearchBox>
                            <SearchItem>
                                <XSelect
                                    selectStyle={{ width: 200, marginRight: 15 }}
                                    label='状态'
                                    placeholder='请选择'
                                    renderData={drawIncomeEnum}
                                    bindThis={this}
                                    bindName='stateItem'
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={stateItem.value}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XDatePicker
                                    allowClear
                                    label='时间'
                                    value={payStartTime}
                                    bindThis={this}
                                    bindName='payStartTime'
                                    isFormat
                                />
                                <XDatePicker
                                    allowClear
                                    style={{ marginRight: 20 }}
                                    value={payEndTime}
                                    bindThis={this}
                                    bindName='payEndTime'
                                    isFormat
                                />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                    style={{ marginRight: 15 }}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                    </div>
                    <OrderTable renderData={drawIncomeList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getDrawIncomeList: PropTypes.func,
    drawIncomeList: PropTypes.object,
    getDrawIncomeEnum: PropTypes.func,
    drawIncomeEnum: PropTypes.array
};
const mapStateToProps = (state) => ({
    drawIncomeList: state.previewcollectionmanage.drawIncomeList,
    drawIncomeEnum: state.previewcollectionmanage.drawIncomeEnum,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
