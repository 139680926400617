/**
 *
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as T from './actiontypes';
import http from '@/assets/api/http';

// 直播带货列表
export const getLiveGoodsList = function* () {
    yield takeEvery(T.GET_LIVEGOODS_LIST, function* requestData(action) {
        try {
            let result = yield http('/live/listMerchantLive', action.payload, 'POST');
            yield put({ type: T.SET_LIVEGOODS_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 直播分类
export const getLiveClassList = function* () {
    yield takeEvery(T.GET_LIVEGOODSCLASS_LIST, function* requestData(action) {
        try {
            let result = yield http('/show/showClass/listPullDownShowClass', action.payload, 'POST');
            yield put({ type: T.SET_LIVEGOODSCLASS_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 本场订单
export const getLiveGoodsOrderList = function* () {
    yield takeEvery(T.GET_LIVEGOODSORDER_LIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/getLiveGoodsList', action.payload, 'POST');
            yield put({ type: T.SET_LIVEGOODSORDER_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


