import { createModel, createSaga, createReducer } from '@/store/tools';

const model = {
    namespace: 'merchantAnchor',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getlistMerchantAnchor': T('getlistMerchantAnchor'), //商户主播
            'getAnchorAssistantTypeEnums': T('getAnchorAssistantTypeEnums'), // 类型
            'getlistMerchantLiveBroadcas': T('getlistMerchantLiveBroadcas'), //直播间
            'getstarLightListInfo': T('getstarLightListInfo'), //魅力值收入
            'getLiveIncomeLogs': T('getLiveIncomeLogs'), //销售收入
            'getAnchorAssistantRecord': T('getAnchorAssistantRecord'), //历史记录
        },
        actions: {
            'getlistMerchantAnchor': A('getlistMerchantAnchor'),
            'getAnchorAssistantTypeEnums': A('getAnchorAssistantTypeEnums'),
            'getlistMerchantLiveBroadcas': A('getlistMerchantLiveBroadcas'), //直播间
            'getstarLightListInfo': A('getstarLightListInfo'), //魅力值收入
            'getLiveIncomeLogs': A('getLiveIncomeLogs'), //销售收入
            'getAnchorAssistantRecord': A('getAnchorAssistantRecord'), //历史记录
        },
        sagas: {
            'getlistMerchantAnchor': S('getlistMerchantAnchor', '/live/listMerchantAnchor'),
            'getAnchorAssistantTypeEnums': S('getAnchorAssistantTypeEnums', '/enum/AnchorAssistantTypeEnum'),
            'getlistMerchantLiveBroadcas': S('getlistMerchantLiveBroadcas', '/live/listMerchantLiveBroadcas'), //直播间
            'getstarLightListInfo': S('getstarLightListInfo', '/live/starLightListInfo'), //直播间
            'getLiveIncomeLogs': S('getLiveIncomeLogs', '/live/queryLiveIncomeLogs'), //销售收入
            'getAnchorAssistantRecord': S('getAnchorAssistantRecord', '/live/listAnchorAssistantRecord'), //历史记录
        },
        reducers: {
            'listMerchantAnchor': R('getlistMerchantAnchor', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'AnchorAssistantTypeEnums': R('getAnchorAssistantTypeEnums', []),
            'listMerchantLiveBroadcas': R('getlistMerchantLiveBroadcas', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'starLightListInfo': R('getstarLightListInfo', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'liveIncomeLogs': R('getLiveIncomeLogs', {}),
            'AnchorAssistantRecord': R('getAnchorAssistantRecord', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
        }
    })
};
export default createModel(model);