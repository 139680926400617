import React from 'react';
import styles from './OrderTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class OrderTable extends React.Component {
    _takeColumns = () => ([
        {
            title: '出库单号',
            dataIndex: 'id',
            key: 'id',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单编号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员手机',
            dataIndex: 'accountTel',
            key: 'accountTel',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品数量',
            dataIndex: 'number',
            key: 'number',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '发货仓库',
            dataIndex: 'storehouseName',
            key: 'storehouseName',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单状态',
            dataIndex: 'statusValue',
            key: 'statusValue',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单类型',
            dataIndex: 'orderTypeValue',
            key: 'orderTypeValue',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '下单时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 180,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单分配时间',
            dataIndex: 'distributionTime',
            key: 'distributionTime',
            width: 180,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否批发',
            dataIndex: 'isDistribution',
            key: 'isDistribution',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否开票',
            dataIndex: 'isInvoiceValue',
            key: 'isInvoiceValue',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 180,
            render: (text, item) => this._renderActionCell(item.id, item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }



    _renderInvoiceCell = (number) => {
        let text = '否';
        if (number == 1) {
            text = '是';
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, item, 'info')}>查看详情</div>
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            const rows = selectedRows.map((item) => {
                return item.id;
            });
            this.props.onRowSelection(rows);
        }
    };

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    onRowSelection: PropTypes.func, // 复选框事件
};

export default OrderTable;