/**
 * 创建销售分类
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, message, Input, Radio, Form, Select } from 'antd';
import model from '@/reducers/businessmanage/cityshopgoods/model';

const { Option } = Select;

const CreateClassify = ({ closeMode,
    form: { validateFieldsAndScroll, getFieldDecorator, getFieldValue },
    getCityClassTree, cityClassTree, modalParams }) => {

    const [confirmLoading, setConfirmLoading] = useState(false);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
    };

    useEffect(() => {
        getCityClassTree();
    }, []);

    const handleSubmit = e => {
        e.preventDefault();
        validateFieldsAndScroll((err, values) => {
            if (!err) {
                setConfirmLoading(true);
                http('/city/shopCategoryInfo/saveShopCategory',
                    {
                        categoryName: values.categoryName,
                        parentCategoryId: values.parentCategoryId ? values.parentCategoryId : 0
                    }, 'POST')
                    .then(res => {
                        message.success(res.message);
                        setConfirmLoading(false);
                        closeMode('reqClass');
                    })
                    .catch(err => {
                        message.error(err.message);
                        setConfirmLoading(false);
                    });
            }
        });
    };

    return (
        <Modal
            width={600}
            bodyStyle={{ paddingBottom: 0 }}
            centered
            title='新建分类'
            visible={true}
            confirmLoading={confirmLoading}
            onOk={handleSubmit}
            onCancel={closeMode}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'>
            <Form id='CreateClassify' {...formItemLayout} onSubmit={handleSubmit}>
                <Form.Item>
                    {getFieldDecorator('categoryGrade', {
                        initialValue: modalParams ? 1 : 0,
                        rules: [
                            {
                                required: true,
                                message: '请选择创建分类',
                            },
                        ],
                    })(
                        <Radio.Group
                            disabled={modalParams ? true : false}>
                            <Radio value={0}>新建一级分类</Radio>
                            <Radio value={1}>新建二级分类</Radio>
                        </Radio.Group>
                    )}
                </Form.Item>
                <Form.Item label='分类名称'>
                    {getFieldDecorator('categoryName', {
                        rules: [
                            {
                                required: true,
                                message: '请输入分类名称',
                            },
                            {
                                pattern: /^(?!.*\/.*$)/,
                                message: '分类名称不能含有斜杠',
                            }
                        ],
                    })(
                        <Input style={{ width: 360 }} placeholder='4个字以内展示最佳' />
                    )}
                </Form.Item>
                <Form.Item
                    label='归属一级分类'
                    style={{
                        display: getFieldValue('categoryGrade') ? 'block' : 'none'
                    }}>
                    {getFieldDecorator('parentCategoryId', {
                        initialValue: modalParams && modalParams.categoryId,
                        rules: [
                            {
                                required: getFieldValue('categoryGrade') ? true : false,
                                message: '请选择一级分类',
                            },
                        ],
                    })(
                        <Select
                            placeholder='请选择一级分类'
                            style={{ width: 360 }}
                            disabled={modalParams ? true : false}>
                            {
                                cityClassTree.map(item => {
                                    return <Option
                                        key={item.categoryId}
                                        value={item.categoryId}
                                    >{item.categoryName}</Option>;
                                })
                            }
                        </Select>
                    )}
                </Form.Item>
            </Form>
        </Modal>
    );
};

CreateClassify.propTypes = {
    closeMode: PropTypes.func.isRequired,
    modalParams: PropTypes.object,//弹框入参
    form: PropTypes.object.isRequired,
    getCityClassTree: PropTypes.func,//获取分类树
    cityClassTree: PropTypes.array,//分类树
};

export default connect(state => ({
    cityClassTree: state.cityShopGoods.cityClassTree,
}), {
    ...model.actions
})(Form.create({
    name: 'createClassify'
})(CreateClassify));