/**
 * 预览缩略媒体文件-图片、视频、音频、PDF、file
 */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as qiniu from 'qiniu-js'; // 七牛云
import uploadObjectConfig from '@/components/NewUpload/config';

function uuid() {
    var s = [];
    var hexDigits = '0123456789abcdef';
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-';

    var uuid = s.join('');
    return uuid;
}

async function uploadFn(file, fileName, onChange) {
    let cf = uploadObjectConfig['image'];
    let token = (await cf.getToken({ fileName }) || {}).result;
    if (token) {
        const observer = {
            complete({ key }) {
                cf.getFile({ key, fileName }).then(({ result } = {}) => {
                    onChange(key, result);
                });
            }
        };
        const UUID = uuid();
        qiniu.upload(file, UUID, token).subscribe(observer);
    }
}

function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

export default function CutVideoToImg(props) {

    const [visible, setVisible] = useState(false);
    const video = useRef();

    function captureImage() {
        if (video.current) {
            var canvas = document.createElement('canvas');
            canvas.width = video.current.videoWidth;
            canvas.height = video.current.videoHeight;
            canvas.getContext('2d').drawImage(video.current, 0, 0, canvas.width, canvas.height);
            let img = canvas.toDataURL('image/png');
            let file = dataURLtoBlob(img);
            uploadFn(file, new Date().getTime() + '', props.onChange);
            setVisible(false);
        }
    }

    const videoCallback = useCallback((node) => {
        if (node) {
            video.current = node;
        }
    });

    useEffect(() => {
        setVisible(!!props.value);
    }, [props.value]);

    return visible && <video autoPlay muted style={{ width: 0, height: 0 }} crossOrigin='anonymous' src={props.value} ref={videoCallback} onCanPlay={captureImage} />;
}


CutVideoToImg.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
};
