/**
 *  【已发货出库单】入口页
 * */ 
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const AlreadyDeliver = ({history}) => {
    return <Main history={history} />;
};

AlreadyDeliver.propTypes = { 
    history: PropTypes.object
};

export default AlreadyDeliver;