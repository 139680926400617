/**
 * 商品类目三级联查
 */
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import http from '@/assets/api/http';
import { Select } from 'antd';

const { Option } = Select;

const NewPartyCategory = ({ style, label, onClassifyChange }, ref) => {

    const [firstLevel, setFirstLevel] = useState([]);
    const [secondLevel, setSecondLevel] = useState([]);
    const [thirdLevel, setThirdLevel] = useState([]);
    const [firstItem, setFirstItem] = useState({});
    const [secondItem, setSecondItem] = useState({});
    const [thirdItem, setThirdItem] = useState({});

    const onReq = (categoryGrade, parentId, setFunc) => {
        let url = '/goods/category/listItem';
        http(url, { categoryGrade, parentId }, 'POST')
            .then(res => {
                if (res.status == 200) {
                    setFunc(res.result);
                } else {
                    console.log('请求三方商品分类失败');
                }
            })
            .catch(() => {
                console.log('请求三方商品分类失败');
            });
    };

    useEffect(() => {
        onReq(1, 0, setFirstLevel);
    }, []);

    const onFirstChange = (value, el) => {
        let item = {id: el.props.value, categoryCode: el.props.code, categoryName: el.props.children};
        onClassifyChange(value,'first', item);
        setFirstItem(el.props);
        setSecondItem({});
        setThirdItem({});
        onReq(2, value, setSecondLevel);
    };
    const onSecondChange = (value, el) => {
        let item = {id: el.props.value, categoryCode: el.props.code, categoryName: el.props.children};
        onClassifyChange(value,'second', item);
        setSecondItem(el.props);
        setThirdItem({});
        onReq(3, value, setThirdLevel);
    };
    const onthirdChange = (value, el) => {
        let item = {id: el.props.value, categoryCode: el.props.code, categoryName: el.props.children};
        onClassifyChange(value,'third', item);
        setThirdItem(el.props);
    };

    useImperativeHandle(ref, () => ({
        clean: () => {
            setFirstItem({});
            setSecondItem({});
            setThirdItem({});
            setSecondLevel([]);
            setThirdLevel([]);
        }
    }));

    const renderLabel = (index = 0)=>{
        if(typeof label === 'string' && index == 0){
            return (<div className={styles.title}>{label}</div>);
        }
        if(Array.isArray(label) && label[index]){
            return (<div className={styles.title}>{label[index]}</div>);
        }
        return null;
    };

    return <div className={styles.container} style={style}>
        <div className={styles.item}>
            {renderLabel(0)}
            <div className={styles.cascade}>
                <Select
                    showSearch
                    style={{ width: 150 }}
                    placeholder='请选择'
                    optionFilterProp='children'
                    onChange={onFirstChange}
                    value={firstItem.children}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {firstLevel.map(fl => (
                        <Option key={fl.id} value={fl.id} code={fl.categoryCode} >{fl.categoryName}</Option>
                    ))}
                </Select>
            </div>
        </div>
        <div className={styles.item}>
            {renderLabel(1)}
            <div className={styles.cascade}>
                <Select
                    showSearch
                    style={{ width: 150 }}
                    placeholder='请选择'
                    optionFilterProp='children'
                    onChange={onSecondChange}
                    value={secondItem.children}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {secondLevel.map(sl => (
                        <Option key={sl.id} value={sl.id} code={sl.categoryCode} >{sl.categoryName}</Option>
                    ))}
                </Select>
            </div>
        </div>
        <div className={styles.item}>
            {renderLabel(2)}
            <div className={styles.cascade}>
                <Select
                    showSearch
                    style={{ width: 150 }}
                    placeholder='请选择'
                    optionFilterProp='children'
                    onChange={onthirdChange}
                    value={thirdItem.children}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {thirdLevel.map(tl => (
                        <Option key={tl.id} value={tl.id} code={tl.categoryCode} >{tl.categoryName}</Option>
                    ))}
                </Select>
            </div>
        </div>
    </div >;
};


NewPartyCategory.defaultProps = {
    firstLevel: [],
    secondLevel: [],
    thirdLevel: [],
    categoryType: 'MALL'
};

NewPartyCategory.propTypes = {
    style: PropTypes.object,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    categoryType: PropTypes.string,
    onClassifyChange: PropTypes.func
};

export default forwardRef(NewPartyCategory);