export function GenerateUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}

export function nonEmptyCall(fn, ...props) {
    if (typeof fn === 'function')
        return fn(...props);
}
export function mapNumber(start, end, fn) {
    let result = [];
    let realIndex = 0;
    if (start < end) {
        for (let i = start; i <= end; i++) {
            result.push(fn(i, realIndex));
            realIndex++;
        }
    }
    else if (start > end) {
        for (let i = start; i >= end; i--) {
            result.push(fn(i, realIndex));
            realIndex++;
        }
    }
    else {
        result.push(fn(start, 0));
    }
    return result;
}
export function deepIf(fn) {
    return fn() !== false;
}
export function arrToObj(arr, key, obj = {}) {
    arr.forEach((v) => obj[v[key]] = v);
    return obj;
}
export function tryJSONParse(jsonStr, empty, catchFn) {
    let result = empty || {};
    try {
        result = JSON.parse(jsonStr);
    }
    catch (e) {
        nonEmptyCall(catchFn, e);
    }
    return result;
}
export function stopPropagationCall(event, fn, ...props) {
    fn(...props);
    event.stopPropagation();
}

export function arraySplit(array, size) {
    let splits = [], targets = [].concat(array);

    if (targets.length > 0) {
        while (targets.length > size)
            splits.push(targets.splice(0, size));
        splits.push(targets);
    }

    return splits;
}

/** 匹配返回两个字符串的交集，xbc abcd => bc */
export function getStringOverlap(source, target) {

    if (!source || !target) return undefined;

    let index = source.length;
    while (index) {
        let temp = source.slice(0, index--);
        if (temp && target.indexOf(temp) > -1) return temp;
    }
}