/**
 * 【供应商售后单】
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/supplierwarehouse/saleorder/model';
import HistoryModal from './modal/operation';
import { XDatePicker, XSelect, XOKButton, XCancelButton, XInputNum, XInput } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { message } from 'antd';

class SaleOrder extends Component {

    state = {
        afterSaleId: '',
        buyerName: '',
        orderId: '',
        outAfterSaleId: '',
        outOrderId: '',
        stateItem: {},
    };
    componentDidMount() {
      this.props.getFenlaiAfterSaleStatusEnum();
    }
    // 查询
    _searchHandle = (useCache) => {
        const {
            afterSaleId,
            buyerName,
            orderId,
            outAfterSaleId,
            outOrderId,
            stateItem,
            pageNum,
            pageSize
        } = this.state;
        this.searchCache = {
            afterSaleId,
            buyerName,
            orderId,
            outAfterSaleId,
            outOrderId,
            supplierStatus: stateItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getSupplierSaleOrder(this.searchCache);
        KeepAlive.saveCache(this.searchCache); // 【第3步，缓存查询状态】
    }

    // 重置触发 
    _ruleresetHandle = () => {
        this.setState({
            afterSaleId: '',
            buyerName: '',
            orderId: '',
            outAfterSaleId: '',
            outOrderId: '',
            stateItem: {},
            pageNum:1,
            pageSize:10
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        switch (type) {
            case 'history':
                this.setState({ modeParam: id, currentMode: 'history' });
                break;
            case 'resend':
                http(`/out/supplier/${id}`, {}, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('推送成功！');
                        this._searchHandle('useCache');
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
        }
    }
      // 渲染弹框
      _renderMode = () => {
        switch (this.state.currentMode) {
            case 'history':
                return <HistoryModal visible={true} closeMode={this._closeMode} modeParam={this.state.modeParam} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentMode: '' });
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }


    render() {
        const {
            afterSaleId,
            orderId,
            buyerName,
            outOrderId,
            outAfterSaleId,
            stateItem
        } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XInputNum
                            style={{ marginRight: 20, marginBottom: 10 }}
                            inputStyle={{ width: 150 }}
                            label='服务单号'
                            placeholder='请输入服务单号'
                            value={afterSaleId}
                            bindThis={this}
                            bindName='afterSaleId'
                            min={0}
                            precision={0}
                            maxLength={16}
                        />
                        <XInputNum
                            style={{ marginRight: 20, marginBottom: 10 }}
                            inputStyle={{ width: 150 }}
                            label='订单号'
                            placeholder='请输入订单号'
                            value={orderId}
                            bindThis={this}
                            bindName='orderId'
                            min={0}
                            precision={0}
                            maxLength={16}
                        />
                        <XInputNum
                            style={{ marginRight: 20, marginBottom: 10 }}
                            inputStyle={{ width: 150 }}
                            label='会员账号'
                            placeholder='请输入会员账号'
                            value={buyerName}
                            bindThis={this}
                            bindName='buyerName'
                            min={0}
                            precision={0}
                            maxLength={16}
                        />
                        <XInput
                            style={{ marginRight: 20, marginBottom: 10 }}
                            inputStyle={{ width: 150 }}
                            label='供应商订单号'
                            placeholder='请输入供应商订单号'
                            value={outOrderId}
                            bindThis={this}
                            bindName='outOrderId'
                            // min={0}
                            // precision={0}
                            // maxLength={16}
                        />
                        <XInput
                            style={{ marginRight: 20, marginBottom: 10 }}
                            inputStyle={{ width: 150 }}
                            label='供应商售后单号'
                            placeholder='请输入供应商售后单号'
                            value={outAfterSaleId}
                            bindThis={this}
                            bindName='outAfterSaleId'
                            // min={0}
                            // precision={0}
                            // maxLength={16}
                        />
                        <XSelect
                            style={{ marginRight: 20, marginBottom: 10 }}
                            selectStyle={{ width: 150 }}
                            label='供应商状态'
                            placeholder='请选择供应商状态'
                            allowClear
                            renderData={this.props.FenlaiAfterSaleStatusEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={stateItem.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='stateItem'
                            isOptionBindID={true}
                        />

                        <div className={styles.dateStyle}>
                            <XOKButton
                                style={{ marginRight: 20 }}
                                label='查询'
                                onClick={this._searchHandle} />
                            <XCancelButton
                                label='重置'
                                onClick={this._ruleresetHandle} />
                        </div>
                    </div>
                    <TableContent
                        renderData={this.props.SupplierSaleOrder}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                    />
                     {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}
SaleOrder.propTypes = {
    history: PropTypes.object, // 路由history对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func, // 保持2级面包屑导航信息
    getSupplierSaleOrder: PropTypes.func,//获取售后单
    SupplierSaleOrder: PropTypes.object,//售后单
    getFenlaiAfterSaleStatusEnum: PropTypes.func,
    FenlaiAfterSaleStatusEnum: PropTypes.array
};
const mapStateToProps = (state) => ({
    SupplierSaleOrder: state.saleorderwarehouse.SupplierSaleOrder,
    FenlaiAfterSaleStatusEnum: state.saleorderwarehouse.FenlaiAfterSaleStatusEnum
});
export default connect(mapStateToProps, {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(SaleOrder);