/**
 * 【订单交易明细页】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getTranscationList, getStoreList } from '@/reducers/ordermanage/transcation/actions';
import { getProductOrderStatus } from '@/reducers/ordermanage/productOrder/actions';
import { XInput, XSelect, XOKButton, XCancelButton, XExpandOrContract, XDatePicker } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

const orderStatus = [{
    code: 'SETTLED',
    value: '已结算',
}, {
    code: 'UNSETTLED',
    value: '未结算',
}];
class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expandBoolean: false, // 是否展开
            orderId: '', // 订单交易号
            orderState: {}, // 订单状态
            userState: {}, // 商户
            itemNo: '', // 货号
            goodsName: '',// 商品名称
            pageNum: 1,
            pageSize: 10,
            startDate: null,
            endDate: null,
            payStartDate: null,
            payEndDate: null,
        };
        this.searchCache = { pageNum: 1, pageSize: 20 }; // 查询缓存
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getTranscationList({ pageNum: 1, pageSize: 10 });
        this.props.getStoreList();
        this.props.getProductOrderStatus();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { orderState, userState, orderId, itemNo, goodsName, pageNum, pageSize,
            startDate,
            endDate, payStartDate, payEndDate } = this.state;
        this.searchCache = {
            settleType: orderState.code,
            shopId: userState.shopId,
            orderId,
            itemNo,
            goodsName,
            startDate: startDate && moment(moment(startDate).format()).format('YYYY-MM-DD HH:mm:ss'),
            endDate: endDate && moment(moment(endDate).format()).format('YYYY-MM-DD HH:mm:ss'),
            payStartDate: payStartDate && moment(moment(payStartDate).format()).format('YYYY-MM-DD HH:mm:ss'),
            payEndDate: payEndDate && moment(moment(payEndDate).format()).format('YYYY-MM-DD HH:mm:ss'),
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getTranscationList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            orderState: {},
            userState: {},
            orderId: null,
            itemNo: null,
            goodsName: null,
            pageNum: 1,
            pageSize: 10,
            startDate: null,
            endDate: null,
            payStartDate: null,
            payEndDate: null,
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        //   this.props.getTranscationList({ pageNum: 1, pageSize: 10 });
    }

    // 展开或收缩搜索条件
    _expandChangeHandle = (expandBoolean) => {
        this.setState({ expandBoolean });
    }

    // 导出
    _exportHandle = () => {

    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        }, () => {
            this._searchHandle('useCache');
        });
        // const { orderState, userState, orderId, goodsId, goodsName } = this.state;
        // this.searchCache = {
        //     settleType: orderState.code,
        //     shopId: userState.shopId,
        //     orderId,
        //     goodsId,
        //     goodsName,
        //     pageNum,
        //     pageSize
        // };
        // this.props.getTranscationList(this.searchCache);
        // KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { expandBoolean, orderId, itemNo, goodsName, orderState, userState, payStartDate,
            payEndDate, startDate, endDate, } = this.state;
        const { transcationList, storeList } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    const { orderId } = this.props.match.params;
                    this.setState({ ...state, orderId: orderId === undefined ? state.orderId : orderId, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria}>
                        <XInput
                            style={{ width: '265px' }}
                            inputStyle={{ width: '180px' }}
                            label='交易订单号'
                            placeholder='请输入交易订单号'
                            value={orderId}
                            bindThis={this}
                            bindName='orderId'
                        />
                        <XSelect
                            style={{ marginLeft: '20px', width: '265px' }}
                            selectStyle={{ width: '180px' }}
                            label='订单状态'
                            placeholder='请选择订单状态'
                            renderData={orderStatus}
                            dataIndex='value'
                            keyIndex='code'
                            value={orderState.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='orderState'
                        />
                        <XOKButton style={{ marginLeft: '10px' }} label='查询' onClick={this._searchHandle} />
                        <XCancelButton style={{ marginLeft: '10px' }} label='重置' onClick={this._resetHandle} />
                        <XExpandOrContract style={{ marginLeft: '20px' }} onChange={this._expandChangeHandle} />
                    </div>
                    <div className={`${styles.searchCriteriaHidden} ${expandBoolean ? styles.moreShow : styles.moreHidden}`}>
                        <div className={styles.criteria1}>
                            <XInput
                                style={{ width: '250px' }}
                                inputStyle={{ width: '180px' }}
                                label='商品名称'
                                placeholder='请输入商品名称'
                                value={goodsName}
                                bindThis={this}
                                bindName='goodsName'
                            />
                            <XSelect
                                style={{ marginLeft: '22px', width: '230px' }}
                                selectStyle={{ width: '180px' }}
                                label='店铺'
                                placeholder='请选择店铺'
                                renderData={storeList || []}
                                dataIndex='shopName'
                                keyIndex='shopId'
                                value={userState.shopName}
                                showSearch={true}
                                bindThis={this}
                                bindName='userState'
                            />
                            <XInput
                                style={{ marginLeft: '10px', width: '265px' }}
                                inputStyle={{ width: '180px' }}
                                label='货号'
                                placeholder='请输入货号'
                                value={itemNo}
                                bindThis={this}
                                bindName='itemNo'
                            />
                        </div>
                        <div className={`${styles.searchCriteriaHidden} ${expandBoolean ? styles.moreShow : styles.moreHidden}`}>
                            <div className={styles.criteria2}>
                                <XDatePicker
                                    placeholder='请选择开始时间'
                                    style={{ width: '265px' }}
                                    label='业务日期'
                                    value={startDate}
                                    bindThis={this}
                                    bindName='startDate'
                                />
                                <XDatePicker
                                    placeholder='请选择结束时间'
                                    label=''
                                    value={endDate}
                                    bindThis={this}
                                    bindName='endDate'
                                />
                            </div>
                            <div className={styles.criteria2}>
                                <XDatePicker
                                    placeholder='请选择开始时间'
                                    style={{ width: '265px' }}
                                    label='付款日期'
                                    value={payStartDate}
                                    bindThis={this}
                                    bindName='payStartDate'
                                />
                                <XDatePicker
                                    placeholder='请选择结束时间'
                                    label=''
                                    value={payEndDate}
                                    bindThis={this}
                                    bindName='payEndDate'
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className={styles.export}>
                    <XOKButton label='导出' onClick={this._exportHandle} />
                    </div> */}
                    <TableContent renderData={transcationList || {}} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getTranscationList: PropTypes.func,
    getStoreList: PropTypes.func,
    getProductOrderStatus: PropTypes.func,
    transcationList: PropTypes.object,
    storeList: PropTypes.array,
    productOrderStatus: PropTypes.array,
};
const mapStateToProps = (state) => {
    return ({
        storeList: state.Transcation.storeList,
        transcationList: state.Transcation.transcationList,
        productOrderStatus: state.productOrder.productOrderStatus,
    });
};
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getTranscationList, getStoreList, getProductOrderStatus })(Main);