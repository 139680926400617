/**
 * 选择商品分类弹框 
 * */
import React from 'react';
import { Modal, Button, message } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './ProductClassifyModal.module.scss';
import { GoodsCategory } from '@/vcomps';
import { isHouNiao } from '@/assets/js/authType';

class ProductClassifyModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
            prodCascade: '', // 商品联级分类
            prodCascadeDetail: [], // 商品联级分类详细信息
        };
    }

    // 确认
    _handleOk = () => {
        const { prodCascadeDetail } = this.state;
        const { modeParam } = this.props;
        const category = prodCascadeDetail.filter(item => item.categoryGrade === 3);
        let data = { categoryId: category[0].id, id: modeParam };
        let url = '/goods/outer/relationOutGoodsCategory';
        if (isHouNiao()) {
            url = '/out/supplier/category/set/platform';
        }
        http(url, data, 'POST')
            .then(() => {
                message.success('设置成功。');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            })
            .catch(error => {
                message.error(error.message);
            });
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }

    // 分类搜索
    _onCascadeSearch = (value) => {
        console.log(value);
        this.setState({ prodCascade: value });
    }

    render() {
        const { visible, confirmLoading } = this.state;

        return (
            <Modal
                width={706}
                centered
                title='商品分类'
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                footer={[
                    <Button key="back" type="primary" onClick={this._handleOk}>
                        确认
                    </Button>
                ]}
            >
                <div className={styles.container}>
                    <div className={styles.title}>请选择商品的所属分类：</div>
                    <GoodsCategory
                        style={{ marginTop: '0px' }}
                        onChange={(value) => this.setState({ prodCascade: value })}
                        onGetItems={(value) => this.setState({ prodCascadeDetail: value })}
                    />
                </div>
            </Modal>
        );
    }
}

ProductClassifyModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    closeMode: PropTypes.func.isRequired,
    modeParam: PropTypes.number,
};

export default ProductClassifyModal;