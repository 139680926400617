//商品对接列表
export const GET_GOODSCONNECT_LIST = 'GOODSCONNECT/GET_GOODSCONNECT_LIST';
export const SET_GOODSCONNECT_LIST = 'GOODSCONNECT/SET_GOODSCONNECT_LIST';

//商品对接导入状态
export const GET_GOODSCONNECT_IMPORT = 'GOODSCONNECT/GET_GOODSCONNECT_IMPORT';
export const SET_GOODSCONNECT_IMPORT = 'GOODSCONNECT/SET_GOODSCONNECT_IMPORT';

//商品对接商品状态
export const GET_GOODSCONNECT_GOODSSTATE = 'GOODSCONNECT/GET_GOODSCONNECT_GOODSSTATE';
export const SET_GOODSCONNECT_GOODSSTATE = 'GOODSCONNECT/SET_GOODSCONNECT_GOODSSTATE';

//商品对接详情
export const GET_GOODSCONNECT_DETAIL = 'GOODSCONNECT/GET_GOODSCONNECT_DETAIL';
export const SET_GOODSCONNECT_DETAIL = 'GOODSCONNECT/SET_GOODSCONNECT_DETAIL';
