/**
 * 【仓库商品结算价】
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XInput, XTabNav, XOKButton, XCancelButton, XExpandOrContract, XSelect, } from '@/components/xqxc_ui';
import ProductTable from './components/ProductTable';
import UpdataPriceModal from '../../modal/UpdataPriceModal';
import { GoodsStateEnum, GoodsTypeEnum, GoodsTags, GoodsCategory } from '@/vcomps';
import * as actions from '@/reducers/warehousemanage/productprice/actions';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { regFenToYuan, } from '@/util/money';

// 弹框枚举类型
const Mode = {
    UPDATE_PRICE: 'UPDATE_PRICE', // 更新结算价弹框
};

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: [
                { id: 1, label: '商品结算价' },
                { id: 2, label: '结算价审核记录' },
            ],
            curTabID: 1,  // 当前显示的Tab页ID
            expandBoolean: false, // 是否展开
            goodsName: '', // 商品名称
            goodsStateEnum: {}, // 商品状态
            auditStateEnum: {}, // 审核状态
            goodsNo: '', // 商品货号
            tags: [], // 商品标签
            goodsTypeEnum: {}, // 商品业务类型
            categoryId: '', // 商品联级分类
            currentMode: '', // 弹框枚举
            modalParam: '',  // 弹框入参
        };
    }

    componentDidMount() {
        this.props.getAuditSelectData();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { getWarehousePriceList, getWarehouseAuditList, } = this.props;
        const { curTabID } = this.state;
        let searchCache = {};

        if (curTabID == 1) {
            const { pageSize, pageNum, goodsName, goodsNo, tags, categoryId } = this.state;
            //商品标签数据处理
            let shopTags = new Array();
            for (let i = 0; i < tags.length; i++) {
                shopTags.push(tags[i].id);
            }

            searchCache = {
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                goodsName,
                goodsNo,
                tagIds: shopTags,
                categoryId
            };
            getWarehousePriceList(searchCache);
        } else {
            const { pageSize, pageNum, goodsName, auditStateEnum, goodsNo, tags, categoryId } = this.state;
            //商品标签数据处理
            let shopTags = new Array();
            for (let i = 0; i < tags.length; i++) {
                shopTags.push(tags[i].id);
            }

            searchCache = {
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                goodsName,
                auditState: auditStateEnum.code,
                goodsNo,
                tagIds: shopTags,
                categoryId
            };
            getWarehouseAuditList(searchCache);
        }
        KeepAlive.saveCache({ ...this.state, pageNum: searchCache.pageNum, pageSize: searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        const { curTabID } = this.state;
        if (curTabID == 1) {
            this.setState({ goodsName: '', goodsNo: '', tags: [], categoryId: '', prodCascade: '' }, () => {
                KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
            });
        } else {
            this.setState({ goodsName: '', auditStateEnum: {}, goodsNo: '', tags: [], categoryId: '', prodCascade: '' }, () => {
                KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
            });
        }
    }

    // 展开与收缩 监听
    _expandChangeHandle = (expandBoolean) => {
        this.setState({ expandBoolean });
    }

    // 分类搜索
    _onCascadeSearch = (value) => {
        this.setState({ prodCascade: value });
    }

    // 导出
    _exportHandle = () => {
        console.log('导出');
    }

    // 表格操作
    _tableAction = (key, type) => {
        switch (type) {
            case 'updatePrice':
                http('/goods/inventory/findGoodsSettleInfo', {
                    goodsId: key,
                }, 'POST').then((res) => {
                    //处理金额兑换
                    for (let i = 0; i < res.result.length; i++) {
                        res.result[i].settlePrice = regFenToYuan(res.result[i].settlePrice);
                    }
                    this.setState({
                        currentMode: Mode.UPDATE_PRICE,
                        modalParam: res.result,
                    });
                }).catch(() => {
                });
                break;
        }
    }

    // 渲染弹框
    _renderMode = () => {
        const { currentMode, modalParam } = this.state;
        switch (currentMode) {
            case Mode.UPDATE_PRICE:
                return <UpdataPriceModal visible={true} data={modalParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentMode: '' });
        const { goodsName, goodsNo, tags, categoryId } = this.state;
        const { getWarehousePriceList, paginations } = this.props;
        if (status) {
            //商品标签数据处理
            let shopTags = new Array();
            for (let i = 0; i < tags.length; i++) {
                shopTags.push(tags[i].id);
            }
            getWarehousePriceList({ pageSize: paginations.pageSize, pageNum: paginations.pageNum, goodsName, goodsNo, tagIds: shopTags, categoryId });
        }
    }


    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID, goodsName: '', goodsNo: '', auditStateEnum: {}, tags: [], categoryId: '', prodCascade: '' }, () => {
            this._searchHandle();
        });
    }

    // 渲染商品状态组件
    _renderGoodsState = () => {
        const { goodsStateEnum, curTabID, auditStateEnum, } = this.state;
        const { selectData, } = this.props;
        if (curTabID == 2) {
            return (
                <XSelect
                    style={{ margin: '0 20px', width: '216px' }}
                    selectStyle={{ width: '150px' }}
                    label='审核状态'
                    placeholder='请选择审核状态'
                    renderData={selectData}
                    dataIndex='value'
                    keyIndex='code'
                    value={auditStateEnum.value}
                    bindThis={this}
                    bindName='auditStateEnum'
                />
            );
            // return (
            //     <GoodsStateEnum
            //         style={{ marginLeft: '20px', width: '258px' }}
            //         selectStyle={{ width: '180px' }}
            //         label='商品状态'
            //         placeholder='请输入商品状态'
            //         bindThis={this}
            //         bindName='goodsStateEnum'
            //         value={goodsStateEnum}
            //     />
            // );
        } else {
            return null;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        // const { curTabID } = this.state;
        // if (curTabID == 1) {
        //     this.setState({ goodsName: '', goodsNo: '', tags: [], categoryId: '', prodCascade: '', });
        //     this.props.getWarehousePriceList({ pageSize, pageNum });
        // } else {
        //     this.setState({ goodsName: '', auditStateEnum: {}, goodsNo: '', tags: [], categoryId: '', prodCascade: '', });
        //     this.props.getWarehouseAuditList({ pageSize, pageNum });
        // }

        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { goodsName, goodsNo, tags, goodsTypeEnum, expandBoolean } = this.state;
        const { tableSource, paginations, tableSourceAudit, paginationsAudit, } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <div className={styles.searchCriteria}>
                        <XInput
                            inputStyle={{ width: '180px' }}
                            label='商品名称'
                            placeholder='请输入商品名称'
                            value={goodsName}
                            bindThis={this}
                            bindName='goodsName'
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '180px' }}
                            label='商品货号'
                            placeholder='请输入商品货号'
                            value={goodsNo}
                            bindThis={this}
                            bindName='goodsNo'
                        />
                        {this._renderGoodsState()}
                        <XOKButton style={{ marginLeft: '20px' }} label='查询' onClick={this._searchHandle} />
                        <XCancelButton style={{ marginLeft: '20px' }} label='重置' onClick={this._resetHandle} />
                        <XExpandOrContract style={{ marginLeft: '20px' }} onChange={this._expandChangeHandle} />
                    </div>
                    <div className={`${styles.searchCriteriaHidden} ${expandBoolean ? styles.moreShow : styles.moreHidden}`}>
                        {/* <div className={styles.criteria1}>
                        <XInput
                            style={{ width: '248px' }}
                            inputStyle={{ width: '180px' }}
                            label='商品货号'
                            placeholder='请输入商品货号'
                            value={goodsNo}
                            bindThis={this}
                            bindName='goodsNo'
                        />
                        <GoodsTypeEnum
                            style={{ marginLeft: '20px', width: '258px' }}
                            selectStyle={{ width: '180px' }}
                            label='业务类型'
                            placeholder='请输入业务类型'
                            bindThis={this}
                            bindName='goodsTypeEnum'
                            value={goodsTypeEnum}
                        />
                    </div> */}
                        <div>
                            <GoodsTags
                                style={{ width: '516px', marginTop: '20px' }}
                                selectStyle={{ width: '448px' }}
                                label='商品标签'
                                placeholder='请输入商品标签'
                                bindThis={this}
                                bindName='tags'
                                value={tags}
                            />
                        </div>
                        <GoodsCategory
                            label='商品分类'
                            ref={this.goodsCategory}
                            style={{ marginTop: '20px' }}
                            onChange={(value) => this.setState({ categoryId: value })}
                        />
                    </div>
                    {/* <div className={styles.otherFeatures}>
                        <XOKButton label='导出' onClick={this._exportHandle} />
                    </div> */}
                    <ProductTable renderData={this.state.curTabID == 1 ? tableSource : tableSourceAudit} tableAction={this._tableAction} paginationChange={this._paginationChange} curTabID={this.state.curTabID}
                        paginations={this.state.curTabID == 1 ? paginations : paginationsAudit} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,//路由对象
    getAuditSelectData: PropTypes.func, // 审核状态
    getWarehouseAuditList: PropTypes.func, // 审核表格数据源的Action
    getWarehousePriceList: PropTypes.func, // 库存表格数据源的Action
    tableSource: PropTypes.array, // 表格数据源
    paginations: PropTypes.object, // 分页数据
    tableSourceAudit: PropTypes.array, // 审核表格数据源
    paginationsAudit: PropTypes.object, // 审核分页数据
    selectData: PropTypes.array, // 审核状态
};

const mapStateToProps = (state) => ({
    tableSource: state.productPrice.warehousePriceListDate.tableSource,
    paginations: state.productPrice.warehousePriceListDate.paginations,
    tableSourceAudit: state.productPrice.warehousePriceListDate.tableSourceAudit,
    paginationsAudit: state.productPrice.warehousePriceListDate.paginationsAudit,
    selectData: state.productPrice.warehousePriceListDate.selectData,
});

export default connect(mapStateToProps, actions)(Main);