import { combineReducers } from 'redux';
import auth from './auth';
import home from './home';
import addressData from './address';

// 店铺管理
import merchantlist from './businessmanage/merchantlist';
import merstorelist from './businessmanage/storelist';
import cityShopGoods from './businessmanage/cityshopgoods';
import standardProduct from './businessmanage/standardproduct';

// 商品管理
import productBrandData from './productmanage/brand';
import productLabelData from './productmanage/labellist';
import productClassifyData from './productmanage/classify';
import brandApply from './productmanage/brandApply';
import templateData from './productmanage/template';
import productListData from './productmanage/list';
import inventoryData from './productmanage/inventory';
import productRate from './productmanage/rate';
import recommendGoodSet from './productmanage/recommendGoodSet';
import anchorGoods from './productmanage/anchorgoods';
import wholesaleGoods from './productmanage/wholesalegoods';

// 订单管理
import productOrder from './ordermanage/productOrder';
import Transcation from './ordermanage/transcation';
import collecttrading from './ordermanage/collecttrade';
import ShopOrder from './ordermanage/shopOrder';
import stockearOrder from './ordermanage/stockearorder';
import wholesaleOrder from './ordermanage/wholesaleorder';
import hotelOrder from './ordermanage/hotelOrder';
import cityOrder from './ordermanage/cityOrder';

// 营销管理
import appcustom from './marketingmanage/appcustom';
import cardticket from './marketingmanage/cardticket';//卡劵管理
import activities from './marketingmanage/activities';//商户活动
import advocacytask from './marketingmanage/advocacytask';//平台活动
import purchasing from './marketingmanage/purchasing';//集采管理
import advocacyMerchants from './marketingmanage/advocacyMerchants';//代言任务
import livegoods from './marketingmanage/livegoods';//直播带货
import storeActivity from './marketingmanage/storeActivity';//店铺活动
import invitemember from './marketingmanage/invitemember';//邀请会员
import marketingCommon from './marketingmanage/common';//营销活动公共数据源
import memberlist from './marketingmanage/memberlist';//推荐会员
import fightrecord from './marketingmanage/fightrecord';//抢拍记录

// 权限管理
import structure from './permissionsmanage/structure'; // 架构管理
import employee from './permissionsmanage/employeelist'; // 员工管理
import assignauthority from './permissionsmanage/assignauthority'; // 角色管理
import menuconfigure from './permissionsmanage/menuconfigure'; // 菜单管理

// 售后管理
import saleorder from './salemanage/saleorder';

// 仓库管理
import storehouselist from './storehousemanage/storehouselist';
import outdeliveryorder from './storehousemanage/outdeliveryorder';
import unassignedorder from './storehousemanage/unassignedorder';

// 供应商仓库
import saleorderwarehouse from './supplierwarehouse/saleorder';
import supplierDeliveryOrder from './supplierwarehouse/deliveryorder';
import visitantBrand from './supplierwarehouse/visitantbrand';
import goodsGenLock from './supplierwarehouse/goodsgenlock';

// 供应商管理
import supplierlist from './suppliermanage/supplierlist';

// 财务管理
import invoicelist from './storehousefinancialmanage/invoicelist';
import invoicemanage from './storehousefinancialmanage/invoicemanage';
import paymanage from './storehousefinancialmanage/paymanage';
import platformreconciliation from './storehousefinancialmanage/platformreconciliation';
import suppliersettlement from './storehousefinancialmanage/suppliersettlement';
import warehousestatement from './storehousefinancialmanage/warehousestatement';
import collecttrade from './storehousefinancialmanage/collecttrade';
import collectsettle from './storehousefinancialmanage/collectsettle';
import collectpay from './storehousefinancialmanage/collectpay';
import account from './storehousefinancialmanage/account';
import purchasecontract from './storehousefinancialmanage/purchasecontract';
import walletreconciliation from './storehousefinancialmanage/walletreconciliation';
import taxdeclaration from './storehousefinancialmanage/taxdeclaration';
import billQuery from './storehousefinancialmanage/billquery';
import reconciliationdata from './storehousefinancialmanage/reconciliationdata';
import merchantwallet from './storehousefinancialmanage/merchantwallet';
import dailystatement2 from './storehousefinancialmanage/dailystatement2';
import previewcollectionmanage from './storehousefinancialmanage/previewcollectionmanage';

// 仓库商品管理
import outerGoodsClassify from './warehousemanage/productClassify';
import goodsConnect from './warehousemanage/goodsConnect';
import inventoryConnect from './warehousemanage/inventoryConnect';
import balanceHint from './warehousemanage/balanceHint';
import productlist from './warehousemanage/productlist';
import productReserve from './warehousemanage/productreserve';
import productPrice from './warehousemanage/productprice';

// 仓库发货管理
import alreadydeliver from './delivermanage/alreadydeliver';
import canceldeliver from './delivermanage/canceldeliver';
import readydeliver from './delivermanage/readydeliver';
import spdeliver from './delivermanage/spdeliver';

// 审核管理
import activityGoodsData from './auditManage/activityProduct';
import supplierAudit from './auditManage/supplierAudit';
import contractAudit from './auditManage/contractAudit';
import balanceAudit from './auditManage/balanceAudit';
import purchasingAudit from './auditManage/purchasing';

// 评论管理
import productcomment from './commentmanage/productcomment';
import hotelcomment from './commentmanage/hotelcomment';

// 违规记录
import illegalRecordData from './illegalRecord/illegalList';

// 系统设置
import materialcategory from './systemmanage/materialcategory';
import materiallist from './systemmanage/materiallist';
import logistics from './systemmanage/logistics';
import delivergoodsset from './systemmanage/delivergoodsset';
import delivertemplate from './systemmanage/delivertemplate';
import businessCategory from './systemmanage/businessCategory';

//服务清单管理
import serviceList from './servicelistingmanage/servicelist';
import servicehistory from './servicelistingmanage/servicehistory';

//攻略管理
import merchantnotice from './strategymanage/merchantnotice';

//GDC官网管理
import gdclist from './gdcmanage/gdclist';

// 直播管理
import livelist from './commercialcollege/livelist';
// 商户直播管理
import merchantAnchor from './commercialcollege/merchantAnchor';
// 合同管理
import contractlist from './contractmanage/contractlist';

// 人气值商城
import beanshop from './beanshopmanage/beanshop';

// 报表管理
import template from './statementmanage/template';
import records from './statementmanage/records';

// 待接订单列表
import receiveorderlist from './receiveordermanage/receiveorderlist';

//房源管理
import houseResources from './houseresourcemanage/houselist';

//入驻管理
import verification from './checkinverification/verificationlist';

//消息管理
import messagelist from './messagemanage/messagelist';

//电子面单
import authorization from './expresswaybill/authorization';
import handbook from './expresswaybill/handbook';

//客服管理
// 账号绑客服
import accountCustomer from './customermanage/accountCustomer';
// 客服分组绑定关系
import customerList from './customermanage/customerList';

//核销管理
import chargeofflist from './chargeoffmanage/chargeofflist';

export default combineReducers({
    // 全局
    auth, home, addressData,

    // 店铺管理
    merchantlist, merstorelist, cityShopGoods, standardProduct,

    // 商品管理 
    productBrandData, productLabelData, brandApply, productClassifyData,
    productRate, productListData, templateData, inventoryData, recommendGoodSet, anchorGoods,
    wholesaleGoods,

    // 订单管理
    productOrder, Transcation, collecttrading, ShopOrder, stockearOrder, wholesaleOrder, hotelOrder, cityOrder,

    // 营销管理
    appcustom, cardticket, activities, advocacytask, purchasing, livegoods, advocacyMerchants, storeActivity, marketingCommon, memberlist, invitemember, fightrecord,

    // 权限管理
    structure, employee, assignauthority, menuconfigure,

    // 售后管理
    saleorder,

    // 仓库管理
    storehouselist, outdeliveryorder, unassignedorder,
    // 供应商仓库
    saleorderwarehouse,
    supplierDeliveryOrder,
    visitantBrand,
    goodsGenLock,

    // 供应商管理
    supplierlist,

    // 财务管理
    invoicelist, invoicemanage, paymanage, platformreconciliation, suppliersettlement,
    warehousestatement, collecttrade, collectsettle, collectpay, account, purchasecontract, walletreconciliation,
    taxdeclaration, billQuery, reconciliationdata, merchantwallet, dailystatement2, previewcollectionmanage,

    // 仓库商品管理
    outerGoodsClassify, goodsConnect, inventoryConnect, balanceHint, productlist,
    productReserve, productPrice,

    // 仓库发货管理
    readydeliver,
    alreadydeliver,
    canceldeliver,
    spdeliver,

    // 审核管理
    activityGoodsData, supplierAudit, contractAudit, balanceAudit, purchasingAudit,

    // 评论管理
    productcomment, hotelcomment,

    // 违规记录
    illegalRecordData,

    // 系统设置
    materialcategory, materiallist, logistics, delivergoodsset, delivertemplate, businessCategory,

    // 服务清单管理
    serviceList,
    servicehistory,

    // 攻略管理
    merchantnotice,

    // GDC官网管理
    gdclist,

    // 直播管理
    livelist,

    merchantAnchor,
    // 合同管理
    contractlist,

    //报表管理
    template,
    records,

    receiveorderlist,
    beanshop,

    //房源管理
    houseResources,

    //入驻管理
    verification,

    // 消息管理
    messagelist,


    //电子面单
    authorization,
    handbook,

    // 账号绑客服
    accountCustomer,
    customerList,
    //核销管理
    chargeofflist
});