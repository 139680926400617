import * as T from './actiontypes';

//初始数据
const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    resultTotal: 0
};

//初始数据
const initDetail = {
    merchantSupplier: {},
    images: [],
    financialInfo:{},
    auditHisResps:[]
};

//保存供应商列表
export const supplierAuditList = (state = initData, action) => {
    switch (action.type) {
        case T.SET_SUPPLIERAUDIT_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

//保存供应商下拉
export const supplierAuditSele = (state = [], action) => {
    switch (action.type) {
        case T.SET_SUPPLIERAUDIT_SELE:
            return action.payload.result;
        default:
            return state;
    }
};

//保存供应商详情
export const supplierAuditDetail = (state = initDetail, action) => {
    switch (action.type) {
        case T.SET_SUPPLIERAUDIT_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};



