/**
 * 已核销
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import styles from './commonstyle.module.scss';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';
import { XInput, XOKButton, XDatePicker, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import AlreadyTable from './components/AlreadyTable';
import model from '@/reducers/checkinverification/verificationlist/model';

class AlreadyVerify extends React.Component {
    state = {
        renderMode: '',
        modeParams: null,
        buyerTel: '',
        hostName: '',
        startTime: null,
        endTime: null,
        verificationStatus: 'WRITTEN_OFF'
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { buyerTel, hostName, startTime, endTime, verificationStatus, pageNum, pageSize } = this.state;
        this.searchCache = {
            buyerTel, hostName, startTime, endTime, verificationStatus,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getVerificationData(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ buyerTel: '', hostName: '', startTime: null, endTime: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    // 分页发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 发送请求
    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST')
            .then(() => {
                message.success(winTxt);
                this._searchHandle('useCache');
            }).catch((e) => {
                message.error(e.message);
            });
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '核销详情',
                    path: '/home/checkinverification/verificationlist/detail'
                });
                history.push({
                    pathname: `/home/checkinverification/verificationlist/detail/${params.buyerId}/${params.id}`,
                    curTabID: 3
                });
                break;
            case 'delete':
                showConfirm('您是否删除该入住信息', '', () => {
                    this._sendReq('/hotel/verification/delete', { buyerId: params.buyerId, id: params.id }, '删除入住信息操作成功');
                });
                break;
        }
    }

    render() {
        const { verificationData } = this.props;
        const { buyerTel, hostName, startTime, endTime } = this.state;
        return (
            <KeepAlive id='alreadyVerify' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XInput
                            inputStyle={{ width: '150px' }}
                            label='会员账号'
                            placeholder='请输入会员账号'
                            value={buyerTel}
                            bindThis={this}
                            bindName='buyerTel'
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '150px' }}
                            label='入住人员'
                            placeholder='请输入入住人员姓名'
                            value={hostName}
                            bindThis={this}
                            bindName='hostName'
                        />
                        <XDatePicker
                            style={{ marginLeft: '20px' }}
                            label='入住时间'
                            value={startTime}
                            bindThis={this}
                            bindName='startTime'
                            isFormat
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            label=''
                            value={endTime}
                            bindThis={this}
                            bindName='endTime'
                            isFormat
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <AlreadyTable renderData={verificationData} paginationChange={this._paginationChange} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}

AlreadyVerify.propTypes = {
    history: PropTypes.object, // 路由history对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    getVerificationData: PropTypes.func,//获取核销列表
    verificationData: PropTypes.object,//核销列表
};

AlreadyVerify.defalutProps = {
    verificationData: { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 }
};

const mapStateToProps = (state) => ({
    verificationData: state.verification.verificationData,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, ...model.actions })(AlreadyVerify);