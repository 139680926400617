/**
 * 【蚁购对接参数设置创建页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, message, Radio, Row, Col } from 'antd';
import http from '@/assets/api/http';
import styles from './index.module.scss';

class addFinancialContent extends React.Component {

    render() {

        return (
            <div style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }} >
                <div className={styles.infoItem}>
                    <div className={styles.label}>认证状态：</div>
                    <div>已认证</div>
                </div>
                <div className={styles.infoItem}>
                    <div className={styles.label}>姓   名：</div>
                    <div>已认证</div>
                </div>
                <div className={styles.infoItem}>
                    <div className={styles.label}>身份证：</div>
                    <div>350521**********33</div>
                </div>
                <Button type='primary'>立即认证</Button>
            </div>
        );
    }
}
addFinancialContent.propTypes = {
    history: PropTypes.object, // 路由history对象
    form: PropTypes.object.isRequired, // 校验
    match: PropTypes.object, // 路由match对象
};

export default connect(null, null)(addFinancialContent);