import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Table, Tooltip } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { getLocalStorage } from '@/assets/js/storage';
import { tooltipStyle, tooltip, priceFenToYuanCell, ImgCell } from '@/components/TableCell';

export default class TableContent extends Component {
	state = {
		selectedRowKeys: this.props.batchData,
	};

	static propTypes = {
		tableAction: PropTypes.func,
		paginationChange: PropTypes.func,
		renderData: PropTypes.object,
		batchData: PropTypes.array
	};

	_columns = () => {
		const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
		const merchantType = userInfo.merchantType; // GY：供应链身份；JM：加盟商身份
		const columns = [
			{
				title: '商品图片',
				dataIndex: 'mainImgUrl',
				key: 'mainImgUrl',
				align: 'center',
				width: 150,
				render: ImgCell
			},
			{
				title: '商品标题',
				dataIndex: 'goodsName',
				key: 'goodsName',
				align: 'center',
				width: 150,
				onCell: () => {
					return {
						style: {
							maxWidth: 65,
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							cursor: 'pointer'
						}
					};
				},
				render: (text) => this._tableTipCell(text)
			},
			{
				title: '货号',
				dataIndex: 'goodsNo',
				key: 'goodsNo',
				align: 'center',
				width: 150,
				onCell: () => {
					return {
						style: {
							maxWidth: 65,
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							cursor: 'pointer'
						}
					};
				},
				render: (text) => this._tableTipCell(text)
			},
			{
				title: 'sku编码',
				dataIndex: 'skuNo',
				key: 'skuNo',
				align: 'center',
				width: 150,
				onCell: () => {
					return {
						style: {
							maxWidth: 65,
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							cursor: 'pointer'
						}
					};
				},
				render: (text) => this._tableTipCell(text)
			},
			{
				title: '商品规格',
				dataIndex: 'skuSpec',
				key: 'skuSpec',
				align: 'center',
				width: 150,
			},
			{
				title: '商品分类',
				dataIndex: 'categoryName',
				key: 'categoryName',
				align: 'center',
				width: 150,
			},
			{
				title: '分类类型',
				dataIndex: 'categorySubTypeName',
				key: 'categorySubTypeName',
				align: 'center',
				width: 150,
			},
			{
				title: '销售价格（元）',
				dataIndex: 'salePrice',
				key: 'salePrice',
				align: 'center',
				width: 150,
				render: priceFenToYuanCell
			},
			{
				title: '商品库存',
				dataIndex: 'totalQuantity',
				key: 'totalQuantity',
				align: 'center',
				width: 150,
			},

			{
				title: '占用库存',
				dataIndex: 'occupyQuantity',
				key: 'occupyQuantity',
				align: 'center',
				width: 150,
			}
		];
		const gy_columns = [{
			title: '服务商库存',
			dataIndex: 'serviceQuantity',
			key: 'serviceQuantity',
			align: 'center',
			width: 150,
		},
		{
			title: '操作',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			width: 150,
			render: (id, record) => this._tableAction(id, record)
		}];

		// 当用户身份为供应商时，加上后两列
		if (merchantType == 'GY') {
			return columns.concat(gy_columns);
		} else {
			return columns;
		}
	}

	// 表格项内容为文本
	_tableTipCell = (text) => {
		return (
			<Tooltip placement='topLeft' title={text}>{text}</Tooltip>
		);
	};
	_moneyCell = (text) => {
		return text ? text / 100 : 0;
	};

	// 表格项内容为图片
	_imgCell = (url) => {
		return (
			<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
				<div className={styles.imgbox}>
					<img className={styles.img} src={url} />
				</div>
			</div>
		);
	};
	// 表格操作
	_tableAction = (id, record) => {
		const { tableAction } = this.props;
		return (
			<div className={styles.action}>
				{/* <div className={styles.item} onClick={() => tableAction (record.goodsId, 'detail')}>详情</div> */}
				<div className={styles.item} onClick={() => tableAction(record.goodsId, 'setInventory', record)}>设置服务商库存</div>
			</div>
		);
	}

	_dealTableData = (data) => {
		return data;
	}

	// 表格复选输入
	_rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			console.log(selectedRows);
			this.setState({ selectedRowKeys });
			this.props.tableAction(null, 'batch', selectedRowKeys);
		}
	};

	// 清空表格复选框
	_cleanAllSelected = () => {
		this.setState({ selectedRowKeys: [] });
	}
	render() {
		const { renderData, paginationChange } = this.props;
		// const { selectedRowKeys } = this.state;
		// this._rowSelection.selectedRowKeys = selectedRowKeys;
		return (
			<div className={`${styles.tableContainer} ${styles.wrap}`} >
				{/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					// rowSelection={this._rowSelection}
					columns={this._columns()}
					pagination={false}
					rowKey={(record, index) => index}
					dataSource={this._dealTableData(renderData.dataList)}
					scroll={{ x: 'max-content', y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}