import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

class TableContent extends Component {

    _columns = () => [
        {
            title: '集采活动',
            dataIndex: 'activityTitle',
            key: 'activityTitle',
            align: 'center',
            width: '9%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'center',
            width: '9%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '集采总数',
            dataIndex: 'goodsTotalNum',
            key: 'goodsTotalNum',
            align: 'center',
            width: '9%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '集采价格(元)',
            dataIndex: 'goodsPrice',
            key: 'goodsPrice',
            align: 'center',
            width: '9%',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '交易总额(元)',
            dataIndex: 'orderTotalAmount',
            key: 'orderTotalAmount',
            align: 'center',
            width: '9%',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '结算比例区间(%)',
            dataIndex: 'rate',
            key: 'rate',
            align: 'center',
            width: '9%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '活动开始时间',
            dataIndex: 'startTime',
            key: 'startTime',
            align: 'center',
            width: '9%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '活动结束时间',
            dataIndex: 'endTime',
            key: 'endTime',
            align: 'center',
            width: '9%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '结算开始时间',
            dataIndex: 'settleStartTime',
            key: 'settleStartTime',
            align: 'center',
            width: '9%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '结算结束时间',
            dataIndex: 'settleEndTime',
            key: 'settleEndTime',
            align: 'center',
            width: '9%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'activityId',
            key: 'activityId',
            align: 'center',
            width: '9%',
            render: (key, item) => this._renderActionCell(key, item)
        },
    ];

    _imgCell = (text) => {
        return <img src={text} width='50px' height='50px'></img>;
    }

    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        const { settleState } = item;
        let element = null;
        if (settleState == '1') {
            element = <div>已结算</div>;
        } else if (settleState == '2') {
            element = <div>结算中</div>;
        } else {
            element = <div className={styles.item} onClick={() => tableAction(key, 'settle', item)}>结算</div>;
        }
        return (
            <div className={styles.action}>
                {element}
            </div>
        );
    }

    // 表格数据渲染
    _takeDataSource = (data = []) => {
        return data.map((item) => {
            item.rate = (item.minRate / 10).toFixed(1) + ' ~ ' + (item.maxRate / 10).toFixed(1);
            return item;
        });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey='activityId'
                    columns={this._columns()}
                    pagination={false}
                    dataSource={this._takeDataSource(dataList)}
                    scroll={{ y: true }} />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
};

export default TableContent;