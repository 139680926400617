/**
 * 仓库商品管理saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as T from './actiontypes';
import http from '@/assets/api/http';

//商品对接列表
export const getGoodsConnectList = function* () {
    yield takeEvery(T.GET_GOODSCONNECT_LIST, function* requestData(action) {
        try {
            let result = yield http('/goods/outer/findOuterGoodsList', action.payload, 'POST');
            yield put({ type: T.SET_GOODSCONNECT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//商品对接导入状态
export const getGoodsConnectImport = function* () {
    yield takeEvery(T.GET_GOODSCONNECT_IMPORT, function* requestData(action) {
        try {
            let result = yield http('/enum/OuterImportStateEnum', action.payload, 'POST');
            yield put({ type: T.SET_GOODSCONNECT_IMPORT, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//商品对接商品状态
export const getGoodsConnectGoodsState = function* () {
    yield takeEvery(T.GET_GOODSCONNECT_GOODSSTATE, function* requestData(action) {
        try {
            let result = yield http('/enum/OuterGoodsStateEnum', action.payload, 'POST');
            yield put({ type: T.SET_GOODSCONNECT_GOODSSTATE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//商品对接详情
export const getGoodsConnectDetail = function* () {
    yield takeEvery(T.GET_GOODSCONNECT_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/goods/outer/finOuterSku', action.payload, 'POST');
            yield put({ type: T.SET_GOODSCONNECT_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


