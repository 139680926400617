/**
 * 商品管理 - 库存异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

export const getInventoryData = function* (){
    yield takeEvery(T.GET_INVENTORY_DATA, function* requestData(action){
        try{
            let result = yield http('/goods/inventory/findServiceInventory', action.payload, 'POST');
            yield put({type:T.SET_INVENTORY_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    }); 
};


