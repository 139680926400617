// 集采收益待打款列表
export const GET_READY_PAY_LIST = 'COLLECTPAY/GET_READY_PAY_LIST';
export const SET_READY_PAY_LIST = 'COLLECTPAY/SET_READY_PAY_LIST';

// 集采收益已打款列表
export const GET_ALREADY_PAY_LIST = 'COLLECTPAY/GET_ALREADY_PAY_LIST';
export const SET_ALREADY_PAY_LIST = 'COLLECTPAY/SET_ALREADY_PAY_LIST';

// 集采收益详情
export const GET_PAY_DETAIL = 'COLLECTPAY/GET_PAY_DETAIL';
export const SET_PAY_DETAIL = 'COLLECTPAY/SET_PAY_DETAIL';

// 获取通道枚举
export const GET_PAY_CHANNEL_ENUM = 'COLLECTPAY/GET_PAY_CHANNEL_ENUM';
export const SET_PAY_CHANNEL_ENUM = 'COLLECTPAY/SET_PAY_CHANNEL_ENUM';