/**
 * 处理结果
 */
import React, { Frament } from 'react';
import { Modal, Radio, Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './ResultModal.module.scss';
import { XInput } from '@/components/xqxc_ui';

const RadioGroup = Radio.Group;
const { TextArea } = Input;

class ResultModal extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        typeInput: '',
        radioValue: 1,
        imgs: [
            'https://desk-fd.zol-img.com.cn/t_s208x130c5/g5/M00/0F/0D/ChMkJ1gyrq2IQiRcAAxu-yQ2xsMAAX8QQM6NvgADG8T113.jpg',
            'https://desk-fd.zol-img.com.cn/t_s208x130c5/g5/M00/0F/0D/ChMkJ1gyrq2IQiRcAAxu-yQ2xsMAAX8QQM6NvgADG8T113.jpg',
            'https://desk-fd.zol-img.com.cn/t_s208x130c5/g5/M00/0F/0D/ChMkJ1gyrq2IQiRcAAxu-yQ2xsMAAX8QQM6NvgADG8T113.jpg',
            'https://desk-fd.zol-img.com.cn/t_s208x130c5/g5/M00/0F/0D/ChMkJ1gyrq2IQiRcAAxu-yQ2xsMAAX8QQM6NvgADG8T113.jpg',
            'https://desk-fd.zol-img.com.cn/t_s208x130c5/g5/M00/0F/0D/ChMkJ1gyrq2IQiRcAAxu-yQ2xsMAAX8QQM6NvgADG8T113.jpg',
            'https://desk-fd.zol-img.com.cn/t_s208x130c5/g5/M00/0F/0D/ChMkJ1gyrq2IQiRcAAxu-yQ2xsMAAX8QQM6NvgADG8T113.jpg',
        ],
        areaValue: '',
    }

    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _radioValueChangeHandle = (e) => {
        this.setState({ radioValue: e.target.value });
    }
    _renderImages = () => {
        return (
            this.state.imgs.map((item, index) => {
                return (
                    <img key={index} src={item} alt='凭证图片' />
                );

            })
        );
    }
    _renderNOaccept = () => {
        const { radioValue, areaValue } = this.state;
        return (
            <Frament>
                <TextArea
                    className={styles.textArea}
                    placeholder='请输入不受理原因'
                    rows={4}
                    onChange={this._areaChangeHandle}
                    value={areaValue}
                />
                <div className={styles.items}>
                    <p>凭证图片</p>
                    <div className={styles.imgs}>
                        {this._renderImages}
                    </div>
                </div>
            </Frament>
        );
    }
    _areaChangeHandle = (e) => {
        this.setState({ areaValue: e.target.value });
    }

    render() {
        const { visible, confirmLoading, title, radioValue } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.top}>
                        <span>申请原因</span>
                        <p>商家不作处理，请平台帮忙解决。商家不作处理，请平台帮忙解决。商家不作处理，请平台帮忙解决。商家不作处理，请平台帮忙解决。</p>
                    </div>
                    <div className={styles.top}>
                        <span>问题描述</span>
                        <p>商家不作处理，请平台帮忙解决。商家不作处理，请平台帮忙解决。商家不作处理，请平台帮忙解决。商家不作处理，请平台帮忙解决。</p>
                    </div>
                    <div className={styles.items}>
                        <p>凭证图片</p>
                        <div className={styles.imgs}>
                            {this._renderImages}
                        </div>
                    </div>
                    <div className={styles.result}>
                        <span>受理结果</span>
                        <p>{this._renderNOaccept}</p>
                    </div>
                </div>
            </Modal>
        );
    }
}

ResultModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string // 弹框标题
};

export default ResultModal;