import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { XMediaFn, XInfoLabel } from '@/components/xqxc_ui';
import { message } from 'antd';

class Main extends React.Component {

    state = {
        taxCommissionUrl: '',
        approvalTime: '',
        expiredTime: '',
    }

    componentDidMount() {
        http('/walletUnion/getTaxCommission').then((response) => {
            if (response.status == 200) {
                const { taxCommissionUrl, approvalTime, expiredTime } = response.result;
                this.setState({
                    taxCommissionUrl,
                    approvalTime,
                    expiredTime
                });
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    render() {
        return (
            <div className={styles.successContainer}>
                <div className={styles.pdf}>
                    <div>委托书：</div>
                    <XMediaFn key={this.state.taxCommissionUrl} dataSource={this.state.taxCommissionUrl} />
                </div>
                <br />
                <XInfoLabel label="开通时间：" value={this.state.approvalTime} />
                <br />
                <XInfoLabel label="到期时间：" value={this.state.expiredTime} />
            </div>
        );
    }
}

export default Main;