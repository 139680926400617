/**
 * 商品管理 - 标签异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';


export const getProductLabel = function* (){
    yield takeEvery(T.GET_PRODUCT_LABEL, function* requestData(action){
        try{
            let result = yield http('/goods/tag/listTag', action.payload, 'POST');
            yield put({type:T.SET_PRODUCT_LABEL, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    }); 
};

export const getProductLabelDetail = function* (){
    yield takeEvery(T.GET_PRODUCT_LABELDETAIL, function* requestData(action){
        try{
            let result = yield http('/goods/tag/infoTag', action.payload, 'POST');
            yield put({type:T.SET_PRODUCT_LABELDETAIL, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    }); 
};

