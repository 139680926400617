import React from 'react';
import PropTypes from 'prop-types';
import { XTableSelectionCount, XPagination } from '@/components/xqxc_ui';
import { Table, Badge } from 'antd';
import CellFenToYuan from '@/vcomps/ShowCellMoney';
import styles from './AdvertTable.module.scss';


class AdvertTable extends React.Component {
    state = {
        account: '',
        selectedRowKeys: [],
    }

    _takeColumns = () => ([
        {
            title: '开票单号',
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '单位',
            dataIndex: 'targetName',
            key: 'targetName',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '发票数量',
            dataIndex: 'invoiceNum',
            key: 'invoiceNum',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '发票金额(元)',
            dataIndex: 'settleAmount',
            key: 'settleAmount',
            width: '10%',
            align: 'center',
            render: CellFenToYuan
        },
        {
            title: '物流单号',
            dataIndex: 'expressNumber',
            key: 'expressNumber',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '新建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: '20%',
            align: 'center',
            render: (text, item) => this._renderActionCell(item.id, item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderStatusCell = (text) => {
        var statusArr = [0, 1, 2, 3];
        var statusText = ['未开始', '审核中', '审核成功', '审核失败'];
        var statusBadge = ['default', 'processing', 'success', 'error'];
        if (statusArr.indexOf(text) !== -1) {
            return (
                <Badge status={statusBadge[text]} text={statusText[text]} />
            );
        } else {
            return null;
        }
    }
    _renderActionCell = (key, item) => {
        // status 0 未开始 1 审核中 2 审核成功 3 审核失败
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                {/* <div className={styles.item} onClick={() => tableAction(key, 'detail', item)}>详情</div> */}
                <div className={styles.item} onClick={() => tableAction(key, 'send', item)}>寄送发票</div>
                <div className={styles.item} onClick={() => tableAction(key, 'edit', item)}>编辑</div>
                <div className={styles.item} onClick={() => tableAction(key, 'delete', item)}>删除</div>
            </div>
        );
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    onChange = (pagination, filters, sorter) => {
        console.log('params', pagination, filters, sorter);
    };
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }
    render() {
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    // onChange={this.onChange}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default AdvertTable;