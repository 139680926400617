import { createModel } from '@/store/tools';

const model = {
    namespace: 'cityOrder',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getCityOrderList': T('getCityOrderList'), // 同城订单列表
            'getCityOrderDeliveryList': T('getCityOrderDeliveryList'), // 同城配送订单列表
        },
        actions: {
            'getCityOrderList': A('getCityOrderList'),
            'getCityOrderDeliveryList': A('getCityOrderDeliveryList'),
        },
        sagas: {
            'getCityOrderList': S('getCityOrderList', '/merchant/order/getCityOrderList'),
            'getCityOrderDeliveryList': S('getCityOrderDeliveryList', '/merchant/order/getCityOrderDeliveryList'),
        },
        reducers: {
            'CityOrderList': R('getCityOrderList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'CityOrderDeliveryList': R('getCityOrderDeliveryList', []),
        }
    })
};
export default createModel(model);