import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Tooltip, } from 'antd';
import { XPagination } from '@/components/xqxc_ui';

const columns = (TableAction, actionClickHandle) => [
    {
        title: '卡劵名称',
        dataIndex: 'couponName',
        key: 'couponName',
        width: '10%',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    maxWidth: 70,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            };
        },
        render: (text) => (TableTipCell(text))
    },
    {
        title: '卡劵类型',
        dataIndex: 'couponTypeName',
        key: 'couponTypeName',
        width: '7%',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    maxWidth: 70,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            };
        },
        render: (text) => (TableTipCell(text))
    },
    {
        title: '领取状态',
        dataIndex: 'stateName',
        key: 'stateName',
        align: 'center',
        width: '7%',
        render: (text) => (TableCell(text))
    },
    {
        title: '面额(元/折扣)',
        dataIndex: 'amountDiscount',
        key: 'amountDiscount',
        width: '7%',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    maxWidth: 70,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            };
        },
        render: (text, record) => (TablePriceCell(text, record))
    },
    {
        title: '门槛(元)',
        dataIndex: 'threshold',
        key: 'threshold',
        width: '7%',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    maxWidth: 70,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            };
        },
        render: (text, record) => (TablethresholdCell(text, record))
    },
    {
        title: '发放时间',
        dataIndex: 'enrollment',
        key: 'enrollment',
        width: '10%',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    maxWidth: 70,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            };
        },
        render: (text) => (TableTipCell(text))
    },
    {
        title: '有效期',
        dataIndex: 'validity',
        key: 'validity',
        width: '10%',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    maxWidth: 70,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            };
        },
        render: (text) => (TableTipCell(text))
    },
    {
        title: '限领',
        dataIndex: 'receiveNum',
        key: 'receiveNum',
        width: '7%',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    maxWidth: 70,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            };
        },
        render: (text) => (TableTipCell(text))
    },
    {
        title: '发行量',
        dataIndex: 'totalNum',
        key: 'totalNum',
        width: '7%',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    maxWidth: 70,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            };
        },
        render: (text) => (TableTipCell(text))
    },
    {
        title: '已领取',
        dataIndex: 'receivedNum',
        key: 'receivedNum',
        width: '7%',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    maxWidth: 70,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            };
        },
        render: (text) => (TableTipCell(text))
    },
    {
        title: '创建人',
        dataIndex: 'createUserName',
        key: 'createUserName',
        width: '7%',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    maxWidth: 70,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            };
        },
        render: (text) => (TableTipCell(text))
    },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '17%',
        render: (id, record) => TableAction(id, record, actionClickHandle)
    }
];

const TableAction = (id, record, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} style={{ width: '24px', cursor: 'default' }}>
                <div className={record.stateCode != 'ENDED' ? null : styles.hide} onClick={() => actionClickHandle(id, 'edit')}>编辑</div>
            </div>
            <div className={styles.item}>
                <div className={styles.item} onClick={() => actionClickHandle(id, 'detail')}>详情</div>
            </div>
            <div className={styles.item}>
                <div className={styles.item} onClick={() => actionClickHandle(id, 'stateType', record)}>{record.state == '1' ? '暂停' : '开启'}</div>
            </div>
            {/* <div className={styles.item} onClick={() => actionClickHandle(id, 'link')}>链接</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'data')}>数据</div> */}
        </div >
    );
};
// 表格项内容为文本
const TableTipCell = (text) => {
    return (
        <Tooltip placement='topLeft' title={text}>{text}</Tooltip>
    );
};

// 表格项内容为文本
const TableCell = (text) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {text}
        </div>
    );
};
const TablePriceCell = (text, record) => {
    return (
        <Tooltip placement='topLeft' title={record.couponType == 'COUPON' ? (text / 100) : `${text}折`}> {record.couponType == 'COUPON' ? (text / 100) : `${text}折`}</Tooltip>
    );
};
const TablethresholdCell = (text, record) => {
    return (
        <Tooltip placement='topLeft' title={record.threshold == '0' ? '无门槛' : text}> {record.threshold == '0' ? '无门槛' : (text / 100)}</Tooltip>
    );
};
class TypeTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data.map(item => {
            item.enrollment = `${item.startTime ? item.startTime : ''}—${item.endTime ? item.endTime : ''}`;//发放时间
            //面额折扣数据切换
            item.amountDiscount = item.couponType == 'COUPON' ? item.denomination : item.discount;
            if (item.termValidity == 'FIXED_DURATION') {
                item.validity = item.duration == '0' ? '永久有效' : `${item.duration}天`;//有效期---"FIXED_DATE"="固定日期"
            } else {
                item.validity = `${item.effectiveTime ? item.effectiveTime : ''}—${item.overdueTime ? item.overdueTime : ''}`;//有效期---"FIXED_DURATION"="固定时长"
            }
            return item;
        });
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }

    render() {
        const { renderData, pagiNation, paginationChange } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={columns(TableAction, this._actionHandle)}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={pagiNation.resultTotal}
                    pageSize={pagiNation.pageSize}
                    pageNum={pagiNation.pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.array, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    pagiNation: PropTypes.object, // 分页数据
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TypeTable;