/**
 * 音乐分类
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Table, Switch, Icon, Input, Popconfirm, message } from 'antd';
import { XSelect, XOKButton, XCancelButton, XInput } from '@/components/xqxc_ui';
import TypeModal from '../../modal/TypeModal';
import AutoPagination, { AutoPaginationSource } from '@/components/Pagination/AutoPagination';
import { getCategoryTableSource } from '@/reducers/systemmanage/materialcategory/actions';
import http from '@/assets/api/http';

const Mode = {
    CREAT: 'CREAT',
    EDITE: 'EDITE',
};

const columns = (TableAction, actionClickHandle, type) => [
    {
        title: '分类ID',
        dataIndex: type + 'CategoryId',
        key: type + 'CategoryId',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '分类名称',
        dataIndex: type + 'CategoryName',
        key: type + 'CategoryName ',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '排序',
        dataIndex: 'sort',
        key: 'sort',
        width: '10%',
        align: 'center',
        render: (text, item) => (SortCell(text, item, actionClickHandle, type))
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '10%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle, type))
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: '10%',
        render: (id, item) => TableAction(item[type + 'CategoryId'], actionClickHandle, item)
    }
];

const TableAction = (id, actionClickHandle, record) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'edit', record)}>编辑</div>
            <div className={styles.item} >
                <Popconfirm
                    title="确认是否删除？"
                    onConfirm={() => actionClickHandle(id, 'delete')}
                    okText="是"
                    cancelText="否"
                >删除</Popconfirm>
            </div>
        </div>
    );
};

// 表格项内容为文本
const TableCell = (text) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {text}
        </div>
    );
};

// 表格项内容为可编辑
const SortCell = (value, item, actionClickHandle, type) => {
    const label = React.createRef();
    const box = React.createRef();
    const input = React.createRef();
    const id = item[type + 'CategoryId'];

    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <div className={styles.sort_box}>
                <div className={styles.label} ref={label}>{value}</div>
                <div className={styles.input} ref={box}>
                    <Input
                        key={id}
                        style={{ width: '50px' }}
                        ref={input}
                        defaultValue={value}
                        onBlur={(e) => {
                            box.current.style.display = 'none';
                            label.current.style.display = 'block';
                            if (value != e.target.value) {
                                actionClickHandle(id, 'sort', e.target.value, item);
                            }
                        }}
                    />
                </div>
                <div
                    className={styles.icon}
                    onClick={() => {
                        label.current.style.display = 'none';
                        box.current.style.display = 'block';
                        input.current.focus();
                    }}
                >
                    <Icon type="edit" style={{ fontSize: '18px' }} />
                </div>
            </div>
        </div>
    );
};

// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle, type) => {
    let checked = sw == 0 ? false : true;
    const id = item[type + 'CategoryId'];
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                key={id + item['state']}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                defaultChecked={checked}
                onChange={(value) => actionClickHandle(id, 'switch', value)}
            />
        </div>
    );
};


class MusicType extends React.PureComponent {

    state = {
        type: {},
        typeName: '',
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: [], // 当前需要打开的弹窗的入参
    }

    componentDidMount() {
        this.updateConditions(null, { updateData: true });
    }

    // 分类状态下拉列改变时触发
    _typeChangeHandle = (selectedItem) => {
        this.setState({ type: selectedItem });
    }

    // 分类名称输出框改变时触发
    _typeNameInputChangeHandle = (value) => {
        this.setState({ typeName: value });
    }

    // 创建直播间分类
    _createStudioRoom = () => {
        this.setState({ currentOpenMode: Mode.CREAT });
    }

    // 查询触发
    _searchHandle = () => {
        const { type, typeName } = this.state;
        console.log(type, typeName);
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ type: {}, typeName: '' });
    }

    updateConditions = (conditions, options) => {
        this.props.paginationProps.updateConditions({ ...conditions, type: this.props.curTabID, isExcludeLocalUpload: '1' }, { ...options, keepConditions: ['type'] });
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        switch (type) {
            case 'edit':
                this.setState({
                    currentOpenMode: Mode.EDITE,
                    openModeParam: { type: this.props.curTabID, ...value }
                });
                break;
            case 'delete':
                http('/custom/iconCategory/del', { id }, 'POST').then(response => {
                    this.props.paginationProps.updatePageData();
                    message.success('删除成功');
                }).catch(e => message.error('删除异常'));
                break;
            case 'switch':
                this.updateVals(id, { state: value === true ? 1 : 0 });
                break;
            case 'sort':
                this.updateVals(id, { sort: value });
                break;
        }
    }

    updateVals = (id, vals) => {
        const { curTabID } = this.props;
        http(`/custom/${curTabID}Category/save`, { [curTabID + 'CategoryId']: id, ...vals }).then(response => this.props.paginationProps.updatePageData());
    }

    // 操作表格
    _actionHandle = (id, type, value, item) => {
        this._tableAction(id, type, value, item);
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.CREAT:
                return <TypeModal title={'创建' + this.props.title} visible={true} data={{ state: '1', type: this.props.curTabID }} closeMode={this._closeMode} />;
            case Mode.EDITE:
                return <TypeModal title={'编辑' + this.props.title} visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    _closeMode = (reload) => {
        this.setState({ currentOpenMode: '' });
        if (reload) this.props.paginationProps.updatePageData();
    }

    render() {
        const { dataList = [], ...otherTableProps } = this.props.categoryTable;
        const { __state, className, ...otherProps } = this.props.paginationProps;
        const { curTabID } = this.props;

        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.search_box}>
                    <XSelect
                        style={{ marginLeft: '0px', width: '216px' }}
                        selectStyle={{ width: '150px' }}
                        label='分类状态'
                        placeholder='请选择分类状态'
                        renderData={[{ value: '启用', code: '1' }, { value: '禁用', code: '0' }]}
                        onChange={v => this.updateConditions({ state: v.code, __state: v.value })}
                        dataIndex='value'
                        keyIndex='code'
                        value={__state}
                    />
                    <XInput
                        style={{ marginLeft: '30px', width: '216px' }}
                        inputStyle={{ width: '150px' }}
                        label='分类名称'
                        placeholder='请输入分类名称'
                        onChange={className => this.updateConditions({ className })}
                        value={className}
                    />
                    <XOKButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='查询'
                        onClick={() => this.updateConditions(null, { updateData: true })}
                    />
                    <XCancelButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='重置'
                        onClick={() => this.updateConditions(null, { updateData: true, clearConditions: true })}
                    />
                </div>
                {curTabID === 'icon' ?
                    < div className={styles.operate_box}>
                        {/* <XOKButton style={{ width: '120px' }} label={'创建' + this.props.title} onClick={this._createStudioRoom} /> */}
                        <XOKButton style={{ width: '120px' }} label={'创建图标分类'} onClick={this._createStudioRoom} />
                    </div> : null
                }
                <div className={styles.tableContainer}>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        rowSelection={this._rowSelection}
                        columns={columns(TableAction, this._tableAction, this.props.curTabID)}
                        dataSource={dataList.filter(d => d[curTabID + 'CategoryName'] !== '本地上传')}
                        pagination={false}
                        scroll={{ y: true }}
                    />
                    <AutoPagination {...otherProps} {...otherTableProps} />
                </div>
                {this._renderMode()}
            </div>
        );
    }
}

MusicType.propTypes = {
    curTabID: PropTypes.string,
    title: PropTypes.string,
    tableAction: PropTypes.func,
    paginationProps: PropTypes.object,
    categoryTable: PropTypes.object
};

export default AutoPaginationSource(MusicType, {
    mapStateToProps: state => ({
        categoryTable: state.materialcategory.categoryTable
    }),
    mapDispatchToProps: { paginationAction: getCategoryTableSource }
});