import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const TableContent = ({
    renderData: {
        dataList,
        pageNum,
        pageSize,
        resultTotal
    },
    paginationChange,
    tableAction
}) => {

    const tableOperate = (record) => {
        return (
            <div className={styles.action}>
                <div
                    className={styles.item}
                    onClick={() => tableAction(record.id, 'history')}
                >操作记录</div>
                { (record.supplierStatus.code == '13' || record.supplierStatus.code == '14') && <div
                    className={styles.item}
                    onClick={() => tableAction(record.id, 'resend')}
                >
                    重新推送</div>}
            </div>
        );
    };

    const columns = [
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: (text, record) => tableOperate(record)
        },
        {
            title: '服务单号',
            dataIndex: 'afterSaleId',
            key: 'afterSaleId',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员账号',
            dataIndex: 'buyerName',
            key: 'buyerName',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '供应商订单号',
            dataIndex: 'outOrderId',
            key: 'outOrderId',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '供应商售后单号',
            dataIndex: 'outAfterSaleId',
            key: 'outAfterSaleId',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: 'SKUID',
            dataIndex: 'outSkuId',
            key: 'outSkuId',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '售后类型',
            dataIndex: 'salesType.value',
            key: 'salesType.value',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '售后原因',
            dataIndex: 'salesExplain',
            key: 'salesExplain',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '退回物流单号',
            dataIndex: 'expressNo',
            key: 'expressNo',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        // {
        //     title: '处理状态',
        //     dataIndex: 'status',
        //     key: 'status',
        //     width: 150,
        //     align: 'center',
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        {
            title: '供应商状态',
            dataIndex: 'supplierStatus.value',
            key: 'supplierStatus.value',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '响应消息',
            dataIndex: 'supplierResponse',
            key: 'supplierResponse',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '审核留言',
            dataIndex: 'reviewMessage',
            key: 'reviewMessage',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '供应商信息',
            dataIndex: 'supplierInfo',
            key: 'supplierInfo',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '供应商物流单号',
            dataIndex: 'outExpressNo',
            key: 'outExpressNo',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '更新时间',
            dataIndex: 'gmtModify',
            key: 'gmtModify',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(record, index) => record.id}
                columns={columns}
                dataSource={dataList}
                pagination={false}
                scroll={{x:2100, y: true }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};
TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};
export default TableContent;