import * as T from './actiontypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 3,
    pages: 0,
    resultTotal: 0
};

export const templateData = (state = initData, action) => {
    switch (action.type) {
        case T.SET_TEMPLATE_DATA:
            return action.payload.result;
        default:
            return state;
    }
};

export const templateDetail = (state = { templateItems: [], postConfigs: [] }, action) => {
    switch (action.type) {
        case T.SET_TEMPLATE_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};
