/**
 * 银行支行下拉列
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message, Select } from 'antd';

const Option = Select.Option;
class BankBranch extends React.Component {

    state = {
        renderData: [], // 数据源
        pageNum: 1, // 默认第1页
        pageSize: 50, // 默认每页100条
        bankName: '', // 用户模糊查询
        loading: false, // 加载中
        bankNo: '', // 父级银行代码
        bank: '', // 父级银行名称
        areaCode: '', // 父级开户银行城市编码
    }

    componentDidUpdate() {
        const { bankNo, bankName, areaCode } = this.props.parentBankObj;
        if (this.state.bankNo == bankNo && this.state.bank == bankName && this.state.areaCode == areaCode) {
            return;
        } else {
            this.setState({ bankName: '', pageNum: 1, bankNo, bank: bankName, areaCode, renderData: [] }, () => {
                this._getHttpList('SearchType');
            });
        }
    }

    componentDidMount() {
        const { bankNo, bankName, areaCode } = this.props.parentBankObj;
        this.setState({ bankName: '', pageNum: 1, bankNo, bank: bankName, areaCode, renderData: [] }, () => {
            this._getHttpList('SearchType');
        });
    }

    _getHttpList = (type) => {
        const { pageSize, renderData, bankName, bankNo, bank, areaCode } = this.state;

        // 如果是搜索状态，只搜索第一条
        let pageNum = this.state.pageNum;
        if (type == 'SearchType') {
            pageNum = 1;
        }

        this.setState({ loading: true }, () => {
            http('/merchant/info/listBankBranch', { pageNum, pageSize, bankName, bankNo, bank, areaCode }, 'POST').then((response) => {
                if (response.status == 200) {
                    if (response.result.dataList && response.result.dataList.length == 0) {
                        this.setState({ loading: true }); // 当已经没有数据，则不再下拉加载
                    } else {
                        this.setState({ loading: false });
                    }
                    const list = this.unique([...renderData, ...response.result.dataList]);
                    this.setState({ renderData: list });
                }
            }).catch((e) => {
                message.error(e.message);
                this.setState({ loading: false });
            });
        });
    }

    // 数组去重
    unique = (list) => {
        let result = {};
        let finalResult = [];
        for (let i = 0; i < list.length; i++) {
            result[list[i].bankNo] = list[i]; //因为songs[i].bankNo不能重复,达到去重效果,且这里必须知晓"bankNo"或是其他键名
        }
        for (let item in result) {
            finalResult.push(result[item]);
        }
        return finalResult;
    }

    _handleScroll = (e) => {
        e.persist();
        const { target } = e;
        // scrollHeight：代表包括当前不可见部分的元素的高度
        // scrollTop：代表当有滚动条时滚动条向下滚动的距离，也就是元素顶部被遮住的高度
        // clientHeight：包括padding但不包括border、水平滚动条、margin的元素的高度
        const rmHeight = target.scrollHeight - target.scrollTop;
        const clHeight = target.clientHeight;
        // 当下拉框失焦的时候，也就是不下拉的时候

        // 当下拉框下拉并且滚动条到达底部的时候
        // 可以看成是分页，当滚动到底部的时候就翻到下一页
        const { loading, pageNum } = this.state;
        if (rmHeight < clHeight + 5 && !loading) {
            this.setState({ pageNum: pageNum + 1 }, () => {
                this._getHttpList();
            });
        }
    }

    _onSearch = (value) => {
        this.setState({ bankName: value }, () => {
            this._getHttpList('SearchType');
        });
    }

    render() {
        const { renderData } = this.state;
        const { value, ...otherProps } = this.props;

        let name = undefined;
        let haveName = false;
        for (let i = 0; i < renderData.length; i++) {
            if (renderData[i].bankNo == value.bankNo) {
                name = renderData[i].bankName;
                haveName = true;
                break;
            }
        }
        if (!name && !haveName && value.bankName) {
            name = value.bankName;
        }

        console.log('----------renderData:', renderData);

        return (
            <Select
                {...otherProps}
                value={name}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onPopupScroll={this._handleScroll}
                onSearch={this._onSearch}
            >
                {renderData && renderData.map((item, index) => {
                    return <Option key={item.bankNo} value={item.bankName}>{item.bankName}</Option>;
                })}
            </Select>
        );
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }
}

BankBranch.propTypes = {
    parentBankObj: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
};

export default BankBranch;