/**
 * 报表生成记录
 */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import styles from './index.module.scss';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { connect } from 'react-redux';

class LecturerManage extends React.Component {

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case 'regenerate':
                addOneBreadcrumbPath({
                    title: '生成报表',
                    pathname: '/home/statementmanage/records/recordInfo',
                    search: 'recordId=' + value
                });
                history.push({
                    pathname: '/home/statementmanage/records/recordInfo',
                    search: 'recordId=' + value
                });
                break;
        }
    };

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <Main updateContent={this._updateContent} history={this.props.history} match={this.props.match} />
            </div>
        );
    }
}

LecturerManage.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(LecturerManage);