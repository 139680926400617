import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell, ImgCell } from '@/components/TableCell';
import { getIsMarketingPromise } from '@/assets/js/authType.js';

class TableContent extends Component {

	state = {
		isMarketing: true
	}

	componentDidMount() {
		getIsMarketingPromise().then((response) => {
			console.log(response); // false:隐藏营销服务费信息 true:营销服务费信息
			this.setState({ isMarketing: response });
		});
	}

	_columns = () => [
		{
			title: '商品图片',
			dataIndex: 'mainImgUrl',
			key: 'mainImgUrl',
			width: 100,
			align: 'center',
			render: ImgCell
		},
		{
			title: '商品标题',
			dataIndex: 'goodsName',
			key: 'goodsName',
			width: 250,
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '货号',
			dataIndex: 'goodsNo',
			key: 'goodsNo',
			width: 200,
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '商品分类',
			dataIndex: 'categoryName',
			key: 'categoryName',
			width: 200,
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '分类类型',
			dataIndex: 'categorySubTypeName',
			key: 'categorySubTypeName',
			align: 'center',
			width: 150,
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '销售价格（元）',
			dataIndex: 'salePrice',
			key: 'salePrice',
			width: 150,
			align: 'center',
			render: priceFenToYuanCell
		},
		{
			title: '技术服务费（元）',
			dataIndex: 'platRateValue',
			key: 'platRateValue',
			width: 150,
			align: 'center',
			render: priceFenToYuanCell
		},
		{
			title: '平台服务费（元）',
			dataIndex: 'agentRateValue',
			key: 'agentRateValue',
			width: 150,
			align: 'center',
			render: priceFenToYuanCell
		},
		{
			title: '营销服务费（元）',
			dataIndex: 'marketRateValue',
			key: 'marketRateValue',
			width: 150,
			align: 'center',
			render: priceFenToYuanCell
		},
		{
			title: '秀场服务费（元）',
			dataIndex: 'showRatioValue',
			key: 'showRatioValue',
			width: 150,
			align: 'center',
			render: priceFenToYuanCell
		},
		{
			title: '心选推荐费（元）',
			dataIndex: 'serviceRatioValue',
			key: 'serviceRatioValue',
			width: 150,
			align: 'center',
			render: priceFenToYuanCell
		},
		{
			title: '人气值金额（元）',
			dataIndex: 'starValue',
			key: 'starValue',
			width: 150,
			align: 'center',
			render: priceFenToYuanCell
		},
		{
			title: '销售价格预估（元）',
			dataIndex: 'estimatedValue',
			key: 'estimatedValue',
			width: 150,
			align: 'center',
			render: priceFenToYuanCell
		},
		{
			title: '费用模式',
			dataIndex: 'rateMode',
			key: 'rateMode',
			width: 150,
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '待审核技术服务费率（%）',
			dataIndex: 'auditPlatRate',
			key: 'auditPlatRate',
			width: 200,
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '待审核平台服务费率（%）',
			dataIndex: 'auditAgentRate',
			key: 'auditAgentRate',
			width: 200,
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '状态',
			dataIndex: 'auditState',
			key: 'auditState',
			width: 100,
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'goodsId',
			key: 'goodsId',
			width: 250,
			align: 'center',
			render: (id, record) => this._tableAction(id, record)
		}
	];

	// 表格操作
	_tableAction = (id, record) => {
		const { tableAction } = this.props;
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => tableAction(id, 'detail')}>详情</div>
				{
					record.auditState === '编辑中' ?
						<div className={styles.item} onClick={() => tableAction(id, 'submit')}>提交平台</div> :
						null
				}
			</div>
		);
	};

	//控制表头显示
	_takeColumns = () => {
		if (this.state.isMarketing) {
			return this._columns();
		} else {
			return this._columns().filter(item => item.key != 'marketRateValue');
		}
	};

	render() {
		const { renderData, paginationChange } = this.props;
		return (
			<div className={styles.tableContainer} >
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					columns={this._takeColumns()}
					pagination={false}
					rowKey={record => record.goodsId}
					dataSource={renderData.dataList}
					scroll={{ x: 'max-content', y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}


TableContent.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,

	renderData: PropTypes.object,
	batchData: PropTypes.array
};

export default TableContent;