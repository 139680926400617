/**
 * 商品管理 Saga集合
 */
import { getProductBrand, getProductBrandSele, getProductBrandDetail } from './brand/saga';//品牌
import { getAccountLevel, getClassifySele } from './classify/saga';//分类
import {
    getProductListData,
    getProductStatus,
    getProductBusinessType,
    getProductUsername,
    getProductLabelSele,
    getProductDetail,
    getProductClassifySele,
    getProductAuditLog,
    getProductFreeSele,
    getProductWareHouse
} from './list/saga';//列表
import { getProductLabel, getProductLabelDetail } from './labellist/saga';//标签列表
import { getTemplateData, getTemplateDetail } from './template/saga';//运费模板
import { getInventoryData } from './inventory/saga';//运费模板
import { getBrandApplyList, getBrandApplyStateSele, getBrandApplyBrandSele, getBrandApplyDetail } from './brandApply/saga';//品牌申请
import {
    getProductRateListData,
    getProductRateStatus,
    getProductRateBusinessType,
    getProductRateLabelSele,
    getProductRateDetail,
    getActiveRateDetail,
    getConversionRate
} from './rate/saga';//费率
import recommendGoodSet from './recommendGoodSet/model';
import productmanage from './list/model';
import goodsclassify from './classify/model';
import anchorgoods from './anchorgoods/model';
import wholesaleGoods from './wholesalegoods/model';

export default [
    ...Object.values(recommendGoodSet.sagas),
    getProductBrand,
    getProductBrandSele,
    getProductBrandDetail,

    getAccountLevel,
    getProductListData,
    getProductStatus,
    getProductBusinessType,
    getProductUsername,
    getProductLabelSele,
    getProductDetail,
    getProductAuditLog,
    getProductFreeSele,
    getProductClassifySele,
    getClassifySele,
    getProductWareHouse,

    getProductLabel,
    getProductLabelDetail,

    getTemplateData,
    getTemplateDetail,

    getInventoryData,

    getBrandApplyList,
    getBrandApplyStateSele,
    getBrandApplyBrandSele,
    getBrandApplyDetail,

    getProductRateListData,
    getProductRateStatus,
    getProductRateBusinessType,
    getProductRateLabelSele,
    getProductRateDetail,
    getActiveRateDetail,
    getConversionRate,
    ...Object.values(productmanage.sagas),
    ...Object.values(goodsclassify.sagas),
    ...Object.values(anchorgoods.sagas),
    ...Object.values(wholesaleGoods.sagas),
];
