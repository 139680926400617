import React, { useState } from 'react';
import styles from './index.module.scss';
import KeepAlive from '@/routes/KeepAlive';
import http from '@/assets/api/http';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { tooltip, tooltipClick } from '@/components/TableCell';
import { XOKButton, XCancelButton, XInput, XDatePicker, XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import InvoiceModal from './InvoiceModal';

const getTableData = async (params = {}) => {
    const { modifyStart, modifyEnd, ...other } = params;
    return http('/merchant/orderInvoice/findPage', {
        ...other,
        modifyStart: modifyStart && modifyStart.format('YYYY-MM-DD 00:00:00'),
        modifyEnd: modifyEnd && modifyEnd.format('YYYY-MM-DD 23:59:59'),
    }, 'POST')
        .then(res => { return res.result; });
};

const UserInvoice = () => {

    const [requery, setRequery] = useState({
        orderId: undefined, modifyStart: null, modifyEnd: null, pageNum: autoPageNum, pageSize: autopageSize
    });
    const [tableData, setTableData] = useState({
        dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0
    });
    const [renderModal, setRenderModal] = useState(false);
    const [modalParams, setModalParams] = useState({});

    const onSearch = async (params = {}) => {
        const query = { ...requery, ...params };
        const result = await getTableData(query);
        setTableData(result);
        KeepAlive.saveCache(query);
    };

    const onReset = () => {
        const query = { orderId: undefined, modifyStart: null, modifyEnd: null, pageNum: autoPageNum, pageSize: autopageSize };
        setRequery(query);
        KeepAlive.saveResetFunc(() => onSearch(query));
    };

    const onPageChange = (pageSize, pageNum) => {
        const callback = pre => {
            return { ...pre, pageSize, pageNum };
        };
        setRequery(callback);
        onSearch({ ...requery, pageSize, pageNum });
    };

    const onInit = async (params = {}) => {
        const query = { ...requery, ...params };
        const result = await getTableData(query);
        setRequery(query);
        setTableData(result);
    };

    const onChange = (params = {}) => {
        const query = { ...requery, ...params };
        setRequery(query);
    };

    const operateCell = (t, { invoiceUrl, orderId }) => {
        const titles = { 0: '开发票', 1: '修改' };
        const callback = () => {
            setRenderModal(true); setModalParams({ title: invoiceUrl ? '修改' : '开发票', orderId, invoiceUrl });
        };
        return <div className={styles.action}>
            {invoiceUrl ?
                <div className={styles.item} onClick={callback}>修改</div> :
                <div className={styles.item} onClick={callback}>开发票</div>
            }
        </div>;
    };

    const columns = [
        {
            title: '订单编号', dataIndex: 'orderId', key: 'orderId', align: 'center', render: tooltip
        },
        {
            title: '电子发票下载地址', dataIndex: 'invoiceUrl', key: 'invoiceUrl', align: 'center',
            render: (t, r) => tooltipClick(t, () => window.open(t))
        },
        {
            title: '操作时间', dataIndex: 'gmtModify', key: 'gmtModify', align: 'center', render: tooltip
        },
        {
            title: '操作', dataIndex: 'id', key: 'id', align: 'center', render: operateCell
        },
    ];

    const onCloseModal = () => {
        setModalParams({}); setRenderModal(false);
        onSearch({});
    };

    return <KeepAlive render={onInit}>
        <div className={styles.flexBoxContainer}>
            <SearchBox>
                <SearchItem>
                    <XInput label='订单编号' placeholder='请输入' value={requery.orderId}
                        onChange={orderId => onChange({ orderId })} style={{ marginRight: '20px' }}
                    />
                </SearchItem>
                <SearchItem>
                    <XDatePicker label='操作时间' placeholder='请选择开始时间'
                        value={requery.modifyStart} showTime={false}
                        onChange={modifyStart => onChange({ modifyStart })}
                    />
                    <XDatePicker placeholder='请选择结束时间' value={requery.modifyEnd} showTime={false}
                        onChange={modifyEnd => onChange({ modifyEnd })} style={{ marginRight: '20px' }}
                    />
                </SearchItem>
                <SearchItem>
                    <XOKButton label='查询' style={{ marginRight: '20px' }} onClick={() => onSearch({})} />
                    <XCancelButton label='重置' onClick={onReset} />
                </SearchItem>
            </SearchBox>
            <div className={styles.tableContainer}>
                <Table rowClassName={(r, i) => i % 2 === 0 ? '' : styles.tdcolor}
                    rowKey='orderIOd' columns={columns} dataSource={tableData.dataList}
                    pagination={false} scroll={{ y: true }}
                />
                <XPagination resultTotal={tableData.resultTotal} pageSize={tableData.pageSize}
                    pageNum={tableData.pageNum} onChange={onPageChange}
                />
            </div>
            {renderModal && <InvoiceModal data={modalParams} closeModal={onCloseModal} />}
        </div>
    </KeepAlive>;
};
export default UserInvoice;