/**
 * 【人气值商城】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { regFenToYuan } from '@/util/money';
import Create from './modal/Create';
import { Row, Col, Tooltip, Icon, Card, Button, Avatar, InputNumber, Tag, Modal, Statistic } from 'antd';
import model from '@/reducers/beanshopmanage/beanshop/model';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const { Meta } = Card;
const { Countdown } = Statistic;
class Main extends React.Component {
    state = {
        openflag: true,
        openwalletflag: true,
        visible: false,
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getStarvalueSummary();
        this.props.getStarvalueInOutSummary();
        this.props.getXiudouEarnings({ pageSize: 5 }); // 股票分红
        this.props.getProfitInOutSummary(); //我的钱包
        this.props.getRuleInfoMoney(); // 1人气值=多少元
        this.props.getQuestionDetail({ id: 1 }); // 获取问题详情
    }
    // 交易明细
    _detailsHandle = (id) => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '信用值明细',
            path: '/home/beanshopmanage/beanshop/detail/'
        });
        history.push({
            pathname: '/home/beanshopmanage/beanshop/detail/',
        });

    }

    // 人气值支取
    _beandraw = () => {
        const { StarvalueSummary: { remainCount } } = this.props;
        if (remainCount != 0) {
            this.setState({ currentOpenMode: 'create' });
        }
        // const { history, addOneBreadcrumbPath } = this.props;
        // addOneBreadcrumbPath({
        //     title: '商户人气值支取申请',
        //     path: '/home/beanshopmanage/beanshop/beanddrawapply/'
        // });
        // history.push({
        //     pathname: '/home/beanshopmanage/beanshop/beanddrawapply/',
        // });

    }

    // 收益明细
    _detailsincomeHandle = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '收益明细',
            path: '/home/beanshopmanage/beanshopincomedetail'
        });
        history.push({
            pathname: '/home/beanshopmanage/beanshopincomedetail',
        });
    }
    // 提现
    cashHandle = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '提现',
            path: '/home/beanshopmanage/beanshop/cashapplication'
        });
        history.push({
            pathname: '/home/beanshopmanage/beanshop/cashapplication',
        });
    }
    // 我的钱包密钥
    walletHideHandle = (flag) => {
        this.setState({ openwalletflag: flag });
    }
    // 我的钱包链接到银行卡管理
    mywallet = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '卡包管理',
            path: '/home/beanshopmanage/beanshopcard'
        });
        history.push({
            pathname: '/home/beanshopmanage/beanshopcard',
        });
    }
    // 剩余人气值密钥
    sumHideHandle = (flag) => {
        this.setState({ openflag: flag });
    };
    // 购买
    buydetails = (e, i) => {
        e.stopPropagation();
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '股票收益申购',
            path: '/home/beanshopmanage/beanshop/stockshares'
        });
        history.push({
            pathname: '/home/beanshopmanage/beanshop/stockshares',
            search: `?stockId=${i.id}`
        });
    }
    showTipModal = () => {
        const config = {
            title: '提示',
            content: '您的信用值账户余额不满足股票分红购买条件'
        };
        Modal.info(config);
    }
    showModal = () => {
        this.setState({ visible: true });
    }
    handleCancel = () => {
        this.setState({ visible: false });
    }
    DateDifference(faultDate, completeTime) {
        // Date.now() + moment(completeTime).valueOf() - moment(faultDate).valueOf();
        const dd = moment(completeTime).diff(moment(faultDate));
        const deadline = Date.now() + moment(completeTime).diff(moment(faultDate));
        // console.log(deadline, 'deadline', dd, 'dd');
        return { flag: dd, deadline };
    }
    dateCount(date) {
        const deadline = Date.now() + date * 1000;
        return deadline;
    }
    onFinish = () => {
        this.props.getStarvalueSummary();
    }
    onSalesFinish = () => {
        var that = this;
        setTimeout(() => {
            that.props.getXiudouEarnings({ pageSize: 5 }); // 股票分红
        }, 1000);
    }
    // 查看更多
    checkmore = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '股票分红',
            path: '/home/beanshopmanage/beanshop/stockdivide/'
        });
        history.push({
            pathname: '/home/beanshopmanage/beanshop/stockdivide/',
        });
    }
    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { currentOpenMode } = this.state;
        switch (currentOpenMode) {
            case 'create':
                return <Create
                closeMode={this._closeMode}
                refshStarvalueSummary={() => this.props.getStarvalueSummary()}
                refshStarvalueInOutSummary={() => this.props.getStarvalueInOutSummary()}
                refshProfitInOutSummary={() => this.props.getProfitInOutSummary()}
                />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status, value) => {
        if (status === true) {
            Modal.success({
                title: '支取成功',
                content: `${value}信用值`,
                okText: '确定'
            });
        }
        this.setState({ currentOpenMode: '', openModeParam: {} });
    }
    DateRange(currentTime, saleStartTime, saleEndTime) {
        var flag = false;
        const start = moment(saleStartTime).diff(moment(currentTime));
        const end = moment(saleEndTime).diff(moment(currentTime));
        if (start < 0 && end > 0) {
            flag = true;
        }
        return flag;
    }
    overNum(salesNum, totalNum) {
        // 发行量 = 销售量
        const remainderNum = Number(totalNum) - Number(salesNum);
        return remainderNum;
    }
    checkOrder = () => {
        const { history } = this.props;
        history.push({
            pathname: '/home/ordermanage/stockearorder',
        });
    }
    render() {
        const { StarvalueSummary, StarvalueInOutSummary, xiudouEarnings, ProfitInOutSummary, RuleInfoMoney = 0, QuestionDetail } = this.props;
        const { openflag, openwalletflag } = this.state;
        const topColResponsiveProps = {
            xs: 24,
            sm: 6,
            md: 6,
            lg: 6,
            xl: 6,
            style: { marginBottom: 12, marginTop: 12 },
        };
        const topColResponsiveProps2 = {
            xs: 24,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
            style: { marginBottom: 12, marginTop: 12 },
        };
        const topColResponsiveProps3 = {
            xs: 24,
            sm: 18,
            md: 18,
            lg: 18,
            xl: 18,
            style: { marginBottom: 12, marginTop: 12 },
        };
        const _titleRender = (
            <div dangerouslySetInnerHTML={{ __html: QuestionDetail && QuestionDetail.questionContent }} />
        );
        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.tableContainer} style={{ overflow: 'auto' }}>
                    <Row gutter={24} className={styles.rowStyle}>
                        <Col {...topColResponsiveProps}>
                            <Card
                                style={{ height: 220, borderRadius: 5 }}
                                title={
                                    <div className={styles.title}>
                                        剩余信用值
                             {openflag ?
                                            <Icon type="eye-o" onClick={() => this.sumHideHandle(false)} />
                                            : <Icon type="eye-invisible" onClick={() => this.sumHideHandle(true)} />
                                        }
                                    </div>
                                }
                                extra={
                                    <div className={styles.astyle} onClick={() => this._detailsHandle()} >
                                        交易明细 <Icon type="right" />
                                    </div>
                                }
                            >
                                <div>
                                    <span className={styles.beanStyles} >
                                        账户总信用值
                            </span>
                                    <span className={styles.beanStyles}>
                                        可用信用值
                            </span>
                                </div>
                                <div>
                                    <span className={styles.beanStyles} style={{ color: '#F59A23', fontWeight: 'bold', fontSize: 20 }}>
                                        {openflag ?
                                            <span>{StarvalueSummary.totalBalance}</span>
                                            : <span>****</span>
                                        }
                                    </span>
                                    <span className={styles.beanStyles} style={{ color: '#02A7F0', fontWeight: 'bold', fontSize: 20 }}>
                                        {openflag ?
                                            <span>{StarvalueSummary.remainLimit}</span>
                                            :
                                            <span>****</span>
                                        }
                                    </span>

                                    <div className={styles.btnbenStyle} onClick={this._beandraw} style={{ width: 310, marginTop: 20 }}>信用值支取: {StarvalueSummary.remainCountDesc}
                                        <span className={styles.dayscount}><Countdown title="" className={styles.countDown} valueStyle={{ color: '#d7d7d7', fontSize: 12, marginLeft: 5 }} value={this.dateCount(StarvalueSummary.remainSeconds)} format="D 天HH:mm:ss" onFinish={this.onFinish} />  后更新</span>
                                        <Tooltip title={_titleRender} arrowPointAtCenter overlayStyle={{ maxWidth: 1200 }}>
                                            <Icon type="question-circle-o" style={{ float: 'right', paddingTop: 9, paddingRight: 8, cursor: 'pointer' }} />
                                        </Tooltip>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col {...topColResponsiveProps2}>
                            <Card
                                style={{ height: 220, borderRadius: 5 }}
                                title={
                                    <div className={styles.title}>信用值收支</div>
                                }
                                extra={
                                    <div className={styles.astyle} onClick={() => this._detailsHandle()} >
                                        交易明细 <Icon type="right" />
                                    </div>
                                }
                            >
                                <div>
                                    <span className={styles.beanStyles2}>
                                        总收入
                            </span>
                                    <span className={styles.beanStyles2}>
                                        总支出
                            </span>
                                    <span className={styles.beanStyles2}>
                                        待结算
                            </span>
                                    <span className={styles.beanStyles2}>
                                        冻结信用值
                            </span>
                                </div>
                                <div>
                                    <span className={styles.beanStyles3}>
                                        {StarvalueInOutSummary.totalIncomeDesc}
                                    </span>
                                    <span className={styles.beanStyles3}>
                                        {StarvalueInOutSummary.totalExpendDesc}
                                    </span>
                                    <span className={styles.beanStyles3}>
                                        {StarvalueInOutSummary.totalUnsettleDesc}
                                    </span>
                                    <span className={styles.beanStyles3}>
                                        {StarvalueInOutSummary.totalLockDesc}
                                    </span>
                                </div>
                            </Card>
                        </Col>
                        <Col {...topColResponsiveProps}>
                            <Card
                                style={{ height: 220, borderRadius: 5 }}
                                title={
                                    <div className={styles.title}>
                                        <span onClick={() => this.mywallet()} style={{ cursor: 'pointer' }}>我的钱包</span>
                                        {openwalletflag ?
                                            <Icon type="eye-o" onClick={() => this.walletHideHandle(false)} />
                                            : <Icon type="eye-invisible" onClick={() => this.walletHideHandle(true)} />
                                        }
                                    </div>
                                }
                                extra={
                                    <div className={styles.astyle} onClick={() => this._detailsincomeHandle()} >
                                        收益明细 <Icon type="right" />
                                    </div>
                                }
                            >
                                <div>
                                    <span className={styles.beanStyles} >
                                        账户余额
                            </span>
                                    <span className={styles.beanStyles}>
                                        支取中
                            </span>
                                </div>
                                <div>
                                    <span className={styles.beanStyles} style={{ color: '#F59A23', fontWeight: 'bold', fontSize: 20 }}>
                                        {openwalletflag ?
                                            <span>{regFenToYuan(ProfitInOutSummary.totalBalance)}</span>
                                            : <span>****</span>
                                        }
                                    </span>
                                    <span className={styles.beanStyles} style={{ color: '#333', fontWeight: 'bold', fontSize: 20 }}>
                                        {openwalletflag ?
                                            <span>{regFenToYuan(ProfitInOutSummary.totalWithdrawing)}</span>
                                            :
                                            <span>****</span>
                                        }
                                    </span>
                                    <Button type="primary" style={{ width: 312, marginTop: 20 }} onClick={() => this.cashHandle()}>提现</Button>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={24} className={styles.rowStyle}>
                        <Col {...topColResponsiveProps3}>
                            <Card
                                style={{ borderRadius: 5 }}
                                title={
                                    <div className={styles.title}>
                                        股票分红兑换
                             <span style={{ marginLeft: 20, fontSize: 14, color: '#FE3838' }}>1 信用值 = {regFenToYuan(RuleInfoMoney)} 元</span>
                                    </div>
                                }
                                extra={
                                    <div className={styles.astyle} onClick={this.checkOrder}>
                                        我的订单 <Icon type="right" />
                                    </div>
                                }
                            >
                                <div>
                                    {xiudouEarnings.dataList.map(i => {
                                        return <Card
                                            key={i.id}
                                            style={{ width: '100%', marginTop: 16, borderBottom: '1px solid rgba(238,238,238,1)' }}
                                            bordered={false}
                                        >
                                            <Meta
                                                avatar={
                                                    <Avatar style={{ width: 107, height: 107, borderRadius: 0 }} src={i.stockUrl} />
                                                }
                                                title={
                                                    <div className={styles.beanTitle}>{i.stockName}
                                                        <span className={styles.smallTite}>（代码:{i.id}）</span>
                                                        {this.DateDifference(i.currentTime, i.saleStartTime).flag < 0 ?
                                                            this.DateRange(i.currentTime, i.saleStartTime, i.saleEndTime) ?
                                                                // 进行中
                                                                this.overNum(i.soldShares, i.stockTotal) > 0 ?
                                                                    <Button type="primary" style={{ width: 200, float: 'right' }} onClick={e => this.buydetails(e, i)}>购买</Button> :
                                                                    <Button type="default" style={{ width: 200, float: 'right' }} onClick={e => this.buydetails(e, i)}>已售罄</Button> :
                                                                // 已结束
                                                                <Button type="default" style={{ width: 200, float: 'right' }} onClick={e => this.buydetails(e, i)}>已结束</Button> :
                                                            // 待开始
                                                            <Button type="primary" ghost style={{ width: 200, float: 'right' }} onClick={e => this.buydetails(e, i)}><Countdown title="" className={styles.countDown} valueStyle={{ color: '#1890ff', fontSize: 14 }} value={this.DateDifference(i.currentTime, i.saleStartTime).deadline} format="H 时 m 分 s 秒" onFinish={this.onSalesFinish} />  后开售</Button>
                                                        }
                                                    </div>
                                                }
                                                description={
                                                    <div style={{ cursor: 'pointer' }}>
                                                        <div className={styles.beanNum}>{i.price} <span style={{ fontSize: 12, color: '#aaa' }}>信用值</span></div>
                                                        {this.DateDifference(i.currentTime, i.saleStartTime).flag < 0 ?
                                                            <div className={styles.smallTite} style={{ marginTop: 12 }}>已售：<span className={styles.colorStyle}>{i.sold}/股</span>   <span className={styles.marginLR}>剩余：<span className={styles.colorStyle}>{i.remainShares}/股</span></span>        <span className={styles.marginLR}>预期收益：<span className={styles.colorStyle}>{regFenToYuan(i.incomeRangeStart)}%~{regFenToYuan(i.incomeRangeEnd)}%</span></span> 销售日期：<span className={styles.colorStyle}>{i.saleStartTime}~{i.saleEndTime}</span></div> :
                                                            <div className={styles.smallTite} style={{ marginTop: 12 }}>发行量：<span className={styles.colorStyle}>{i.stockTotal}/股</span>      <span className={styles.marginLR}>预期收益：<span className={styles.colorStyle}>{regFenToYuan(i.incomeRangeStart)}%~{regFenToYuan(i.incomeRangeEnd)}%</span></span> 销售日期：<span className={styles.colorStyle}>{i.saleStartTime}~{i.saleEndTime}</span></div>
                                                        }
                                                    </div>
                                                }
                                            />
                                        </Card>;

                                    })}
                                    <div style={{ textAlign: 'center' }}>
                                        {xiudouEarnings.dataList.length > 0 && <Button style={{ width: 300, marginTop: 20 }} onClick={() => this.checkmore()}>查看更多</Button>}
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Modal
                        title="购买"
                        visible={this.state.visible}
                        footer={null}
                        onCancel={this.handleCancel}
                    >
                        <div className={styles.lineHeight} style={{ fontSize: 20, color: '#333', fontWeight: 700 }}>这里是股票名称...</div>
                        <div className={styles.lineHeight}>价格：66人气值/股</div>
                        <div className={styles.lineHeight}>数量： <InputNumber min={1} max={10} defaultValue={3} onChange={this.onChange} /></div>
                        <div className={styles.lineHeight}>可买2891股： <Tag>1/2</Tag> <Tag color='#108ee9'>1/4</Tag> <Tag>全部</Tag></div>
                        <div className={styles.sumHeight}>总额<span style={{ color: '#F59A23', marginRight: 30 }}>6600人气值</span>   账户余额788800人气值</div>
                        <div style={{ textAlign: 'center' }}>
                            <Button type="primary" style={{ width: 180, marginTop: 20 }}>确认购买</Button>
                        </div>
                    </Modal>
                </div>
                {this._renderMode()}
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getStarvalueSummary: PropTypes.func,
    StarvalueSummary: PropTypes.object,
    getStarvalueInOutSummary: PropTypes.func,
    StarvalueInOutSummary: PropTypes.object,
    getXiudouEarnings: PropTypes.func,
    xiudouEarnings: PropTypes.object,
    getProfitInOutSummary: PropTypes.func,
    ProfitInOutSummary: PropTypes.object,
    getRuleInfoMoney: PropTypes.func,
    RuleInfoMoney: PropTypes.number,
    getQuestionDetail: PropTypes.func,
    QuestionDetail: PropTypes.object,
};
const mapStateToProps = (state) => {
    return ({
        StarvalueSummary: state.beanshop.StarvalueSummary,
        StarvalueInOutSummary: state.beanshop.StarvalueInOutSummary,
        xiudouEarnings: state.beanshop.xiudouEarnings,
        ProfitInOutSummary: state.beanshop.ProfitInOutSummary,
        RuleInfoMoney: state.beanshop.RuleInfoMoney,
        QuestionDetail: state.beanshop.QuestionDetail,
    });
};
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);