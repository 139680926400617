import { createModel } from '@/store/tools';

const model = {
    namespace: 'delivergoodsset',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getContactsInfo': T('getContactsInfo'),
        },
        actions: {
            'getContactsInfo': A('getContactsInfo'),
        },
        sagas: {
            'getContactsInfo': S('getContactsInfo', '/merchant/cainiao/setting/person'),
        },
        reducers: {
            'contactsInfo': R('getContactsInfo', {}),
        }
    })
};
export default createModel(model);