import React, { useEffect, useRef, useState } from 'react';
import styles from './SPDModal.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, message, Radio, Cascader } from 'antd';
import { XSelect, XTextArea, XDatePicker } from '@/components/xqxc_ui';
import model from '@/reducers/warehousemanage/goodsConnect/model';

const VisitantModal = ({
    closeMode,
    getClassSels,
    getStateEnum,
    getTypeEnum,
    classSels = [],
    stateEnum = [],
    typeEnum = []
}) => {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [categoryIds, setCategoryIds] = useState([]);
    const [outGoodsName, setOutGoodsName] = useState('');
    const [keywords, setKeywords] = useState('');
    const [typeItem, setTypeItem] = useState({});
    const [startTime, setStartTime] = useState(undefined);
    const [endTime, setEndTime] = useState(undefined);
    const [options, setOptions] = useState([]);
    const [outGoodsState, setOutGoodsState] = useState('');

    useEffect(() => {
        getClassSels();
        getTypeEnum();
        getStateEnum();
    }, []);

    useEffect(() => { setOptions(classSels); }, [classSels]);

    const onDownLoad = () => {
        setConfirmLoading(true);
        http('/out/supplier/goods/download', {
            outCategoryId: categoryIds[2],
            outGoodsName, keywords, startTime, endTime,
            outGoodsState, tradeType: typeItem.code
        }, 'POST')
            .then((res) => {
                setConfirmLoading(false);
                message.success(res.message);
                closeMode();
            })
            .catch(error => {
                setConfirmLoading(false);
                message.error(error.message);
            });
    };

    return <Modal
        width={550}
        centered
        title='下载供应商商品'
        visible={true}
        bodyStyle={{ paddingBottom: '10px' }}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        onOk={onDownLoad}
        onCancel={closeMode}
        okText='确定'
        cancelText='取消'
    >
        <div className={styles.container}>
            <div className={styles.component}>
                <div className={styles.label}>商品分类：</div>
                <div className={styles.picker}>
                    <Cascader
                        style={{ width: '400px' }}
                        fieldNames={{
                            label: 'outCategoryName',
                            value: 'outerCategoryId',
                            children: 'childList'
                        }}
                        options={options}
                        onChange={value => setCategoryIds(value)}
                        placeholder='请选择商品分类'
                    />
                </div>
            </div>
            <XTextArea
                label='商品名称：'
                style={{ marginBottom: '20px' }}
                inputStyle={{ width: '400px' }}
                placeholder='请输入商品名称最多100字'
                value={outGoodsName}
                bindName='outGoodsName'
                bindThis={setOutGoodsName}
                rows={1}
            />
            <XTextArea
                style={{ marginBottom: '20px' }}
                label='关键字：'
                inputStyle={{ width: '400px' }}
                placeholder='请输入关键字最多100字'
                value={keywords}
                bindName='keywords'
                bindThis={setKeywords}
                rows={1}
            />
            <div className={styles.component}>
                <div className={styles.label}>上下架状态：</div>
                <div className={styles.picker}>
                    <Radio.Group onChange={e => setOutGoodsState(e.target.value)}>
                        {
                            stateEnum.map(item => {
                                return <Radio value={item.code} key={item.code}>{item.value}</Radio>;
                            })
                        }
                    </Radio.Group>
                </div>
            </div>
            <div className={styles.component}>
                <XDatePicker
                    label='时间：'
                    value={startTime}
                    bindThis={setStartTime}
                    bindName='startTime'
                    showTime={false}
                    isDateFormat={true}
                />
                <XDatePicker
                    value={endTime}
                    bindThis={setEndTime}
                    bindName='endTime'
                    showTime={false}
                    isDateFormat={true}
                />
            </div>
            <XSelect
                style={{ marginBottom: '20px' }}
                selectStyle={{ width: '400px' }}
                label='贸易类型：'
                placeholder='请选择贸易类型'
                renderData={typeEnum}
                bindThis={setTypeItem}
                bindName='typeItem'
                dataIndex='value'
                keyIndex='code'
                value={typeItem.value}
            />
        </div>
    </Modal>;
};

VisitantModal.propTypes = {
    closeMode: PropTypes.func.isRequired,
    getStateEnum: PropTypes.func,
    stateEnum: PropTypes.array,
    getTypeEnum: PropTypes.func,
    typeEnum: PropTypes.array,
    getClassSels: PropTypes.func,
    classSels: PropTypes.array,
};
export default connect(({ goodsConnect }) => ({
    stateEnum: goodsConnect.stateEnum,
    typeEnum: goodsConnect.typeEnum,
    classSels: goodsConnect.classSels,
}), {
    ...model.actions
})(VisitantModal);