//供应商结算价审核列表
export const GET_BALANCEAUDIT_LIST = 'AUDITMANAGE/GET_BALANCEAUDIT_LIST';
export const SET_BALANCEAUDIT_LIST = 'AUDITMANAGE/SET_BALANCEAUDIT_LIST';

//供应商审核结算价详情
export const GET_BALANCEAUDIT_DETAIL = 'AUDITMANAGE/GET_BALANCEAUDIT_DETAIL';
export const SET_BALANCEAUDIT_DETAIL = 'AUDITMANAGE/SET_BALANCEAUDIT_DETAIL';

//供应商审核结算价业务类型下拉
export const GET_BALANCEAUDIT_BUSINESSSELE = 'AUDITMANAGE/GET_BALANCEAUDIT_BUSINESSSELE';
export const SET_BALANCEAUDIT_BUSINESSSELE = 'AUDITMANAGE/SET_BALANCEAUDIT_BUSINESSSELE';

//供应商审核结算价标签下拉
export const GET_BALANCEAUDIT_LABELSELS = 'AUDITMANAGE/GET_BALANCEAUDIT_LABELSELS';
export const SET_BALANCEAUDIT_LABELSELS = 'AUDITMANAGE/SET_BALANCEAUDIT_LABELSELS';

//供应商审核结算价状态下拉
export const GET_BALANCEAUDIT_STATESELE = 'AUDITMANAGE/GET_BALANCEAUDIT_STATESELE';
export const SET_BALANCEAUDIT_STATESELE = 'AUDITMANAGE/SET_BALANCEAUDIT_STATESELE';