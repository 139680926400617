/**
 * 供应商仓库管理 Saga集合
 */
import saleorderWarehouse from './saleorder/model';
import supplierDeliveryOrder from './deliveryorder/model';
import visitantBrand from './visitantbrand/model';
import goodsGenlock from './goodsgenlock/model';

export default [
    ...Object.values(saleorderWarehouse.sagas),
    ...Object.values(supplierDeliveryOrder.sagas),
    ...Object.values(visitantBrand.sagas),
    ...Object.values(goodsGenlock.sagas),
];
