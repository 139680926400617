/**
 * 股票收益订单
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import StockTable from './components/StockTable';
import { XInput, XOKButton, XCancelButton, XTabNav, XDatePicker, XSelect } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import model from '@/reducers/ordermanage/stockearorder/model';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stockOrder: '',//股票名称 
            state: {},//状态
            tab: [
                { id: 1, label: '全部' },
                { id: 2, label: '已结算' },
                { id: 3, label: '未结算' },
            ],
            curTabID: 1,  // 当前显示的Tab页ID  
            orderStartTime: null,//下单日期-开始时间
            orderEndTime: null,//下单日期-结束时间
            settlementStartTime: null,//结算日期-开始时间
            settlementEndTime: null,//结算日期-结束时间
        };
    }

    componentDidMount() {
        this.props.getStockState();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, stockOrder, state, curTabID, orderStartTime, orderEndTime, settlementStartTime, settlementEndTime } = this.state;
        let curType;//区分全部、已结算，未结算等
        if (curTabID == '1') {
            if (Object.keys(state).length > 0) {
                curType = [state.code];//状态
            } else {
                curType = [];
            }
        } else if (curTabID == '2') {
            curType = ['FINISHED'];
        } else if (curTabID == '3') {
            curType = ['INVOICING', 'PAIED'];
        }
        this.searchCache = {
            statusList: curType,//对应tabs状态
            stockName: stockOrder,//股票名称
            createStartTime: orderStartTime != null ? orderStartTime.format('YYYY-MM-DD HH:mm:ss') : null, //下单日期
            createEndTime: orderEndTime != null ? orderEndTime.format('YYYY-MM-DD HH:mm:ss') : null, // 下单日期
            settleStartTime: settlementStartTime != null ? settlementStartTime.format('YYYY-MM-DD HH:mm:ss') : null, //结算日期
            settleEndTime: settlementEndTime != null ? settlementEndTime.format('YYYY-MM-DD HH:mm:ss') : null, // 结算日期
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getStockTableList(this.searchCache);//商户须知列表
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ stockOrder: '', state: {}, orderStartTime: null, orderEndTime: null, settlementStartTime: null, settlementEndTime: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getStockTableList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getStockTableList(this.searchCache);
        }
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({
            curTabID: tabID,
            stockOrder: '',
            orderStartTime: null,
            orderEndTime: null,
            settlementStartTime: null,
            settlementEndTime: null,
            state: {},
        }, () => {
            this._searchHandle();// 重新渲染更新列表
        });
    }

    render() {
        const { stockOrder, state, curTabID, orderStartTime, orderEndTime, settlementStartTime, settlementEndTime } = this.state;
        const { stockList, stockState } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <div className={styles.searchBox}>
                        <XInput
                            inputStyle={{ width: '150px' }}
                            label='股票名称'
                            placeholder='请输入'
                            value={stockOrder}
                            bindThis={this}
                            bindName='stockOrder'
                        />
                        <XDatePicker
                            style={{ marginLeft: '20px' }}
                            label='下单日期'
                            value={orderStartTime}
                            bindThis={this}
                            bindName='orderStartTime'
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            label=''
                            value={orderEndTime}
                            bindThis={this}
                            bindName='orderEndTime'
                        />
                        {curTabID != '3' && <>
                            <XDatePicker
                                style={{ marginLeft: '20px' }}
                                label='结算日期'
                                value={settlementStartTime}
                                bindThis={this}
                                bindName='settlementStartTime'
                            />
                            <div className={styles.timeLine} />
                            <XDatePicker
                                label=''
                                value={settlementEndTime}
                                bindThis={this}
                                bindName='settlementEndTime'
                            />
                        </>}
                        {curTabID == '1' && <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '150px' }}
                            label='状态'
                            placeholder='请选择'
                            renderData={stockState}
                            dataIndex='value'
                            keyIndex='code'
                            value={state.value}
                            bindThis={this}
                            bindName='state'
                            showSearch={true}
                        />}
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <StockTable renderData={stockList} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}


Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象

    getStockTableList: PropTypes.func,//列表数据(action)
    stockList: PropTypes.object,
    getStockState: PropTypes.func,//状态数据(action)
    stockState: PropTypes.array,
};

const mapStateToProps = (state) => ({
    stockList: state.stockearOrder.stockTableList, // 列表
    stockState: state.stockearOrder.stockState, // 状态
});

export default connect(mapStateToProps, { ...model.actions })(Main);
