import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { XTabNav, XPagination, XInput, XOKButton, XCancelButton, XTableSelectionCount, XDatePicker } from '@/components/xqxc_ui';
import { Table, message } from 'antd';
import moment from 'moment';
import { takeDataSource, takeColumns } from './components/TableData';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getProductOrderType, getProductOrderStatus, getProductOrderFlagEnum } from '@/reducers/ordermanage/productOrder/actions';
import { getCollectMoneyList } from '@/reducers/storehousefinancialmanage/collecttrade/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';
import BigNumber from 'bignumber.js';

class Main extends React.Component {
    state = {
        tab: [
            { id: 1, label: '待退采金' },
            { id: 2, label: '已退采金' },
        ],
        curTabID: 1,
        tel: '',
        orderStartTime: null,
        orderEndTime: null,
        state: 'PAYED',
        currentOpenMode: '',
        orderId: '',
        goodsNo: '',
        orderTime: [],
        selectedRowKeys: [],
        // task-3039
        balance: 0,
        loading: false,
        totalPrice: 0,
        isLianlian: false,
        isExited: false
    }

    componentDidMount() {
        this.props.getProductOrderType();
        this.props.getProductOrderStatus();
        this.props.getProductOrderFlagEnum();
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        /**task-3039 */
        this._reqBalance();
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        const { pageNum, pageSize, tel,
            orderStartTime,
            orderEndTime,
            orderId,
            goodsName,
            goodsNo,
        } = this.state;

        this.searchCache = {
            pageNum,
            pageSize,
            tel,
            orderStartTime,
            orderEndTime,
            orderId,
            goodsName,
            goodsNo,
            state: tabID === 1 ? 'PAYED' : 'REFUNDED',
        };
        this.setState({ curTabID: tabID, state: tabID === 1 ? 'PAYED' : 'REFUNDED', pageNum: 1 }, () => {
            this._searchHandle('useCache');
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, tel, orderId, orderStartTime, orderEndTime, goodsName, state, goodsNo } = this.state;
        this.searchCache = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            tel,
            orderId,
            orderStartTime: orderStartTime && moment(moment(orderStartTime).format()).format('YYYY-MM-DD HH:mm:ss'),
            orderEndTime: orderEndTime && moment(moment(orderEndTime).format()).format('YYYY-MM-DD HH:mm:ss'),
            goodsName,
            state,
            goodsNo,
        };
        this.props.getCollectMoneyList(this.searchCache);
        KeepAlive.saveCache({ ...this.searchCache, orderStartTime, orderEndTime }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            pageNum: 1,
            pageSize: 30,
            tel: '',
            orderId: '',
            orderStartTime: null,
            orderEndTime: null,
            goodsName: '',
            orderTime: [],
            goodsNo: '',
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize });
        const { tel, orderId, orderStartTime, goodsName, state, goodsNo, orderEndTime } = this.state;
        this.searchCache = {
            pageNum,
            pageSize,
            tel,
            orderId,
            orderStartTime: orderStartTime && moment(moment(orderStartTime).format()).format('YYYY-MM-DD HH:mm:ss'),
            orderEndTime: orderEndTime && moment(moment(orderEndTime).format()).format('YYYY-MM-DD HH:mm:ss'),
            goodsName,
            state,
            goodsNo,
        };
        this.props.getCollectMoneyList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 操作
    _actionClickHandle = (record, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'DETAIL':
                addOneBreadcrumbPath({
                    title: '集采本金退款详情',
                    path: '/home/storehousefinancialmanage/collecttrade/detail'
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/collecttrade/detail/${record.id}`,
                });
                break;
        }
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            selectedRowKeys.length && http('/collectMiningRefund/refundAmount', { ids: selectedRowKeys }, 'POST').then(res => {
                this.setState({ totalPrice: new BigNumber(res.result || 0).dividedBy(100).toFixed(2) });
            }).catch(err => message.error(err.message));
            !selectedRowKeys.length && this.setState({ totalPrice: 0 });
            this.setState({ selectedRowKeys, selectedRows });
        },
        getCheckboxProps: record => ({
            disabled: record.isRefund === '0',
        }),
    };

    _requestLoading = (url, refundChannelType, filed) => {
        this.setState({ [filed]: true }, () => {
            this._refundHandle(url, refundChannelType);
        });
    }

    // 批量退回
    _refundHandle = (url, refundChannelType) => {
        const { selectedRowKeys } = this.state;
        if (!selectedRowKeys.length) {
            this.setState({ loading: false, isLianlian: false, isExited: false });
            return message.warning('请选择需要操作的订单');
        }
        http(url, { ids: selectedRowKeys, refundChannelType }).then(res => {
            message.success(res.message);
            this.setState({ loading: false, isLianlian: false, selectedRowKeys: [], totalPrice: 0, isExited: false });
            this._searchHandle('useCache');
        }).catch((res) => {
            message.error(res.message);
            this.setState({ loading: false, isLianlian: false, isExited: false });
        });
    }

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [], totalPrice: 0 });
    }

    //请求余额
    _reqBalance = (tip) => {
        http('/collectMiningRefund/balance', {}).then(res => {
            tip && message.success(res.message);
            this.setState({
                balance: new BigNumber(res.result || 0).dividedBy(100).toFixed(2)
            });
        }).catch((res) => {
            message.error(res.message);
        });
    }

    render() {
        const { collectMoneyList: { dataList = [], resultTotal, pageSize, pageNum } } = this.props;
        const { tel, goodsName, orderId, goodsNo, curTabID, state, selectedRowKeys, orderStartTime, orderEndTime, balance, loading, totalPrice, isLianlian, isExited } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <div className={styles.line}></div>
                    <div className={styles.row1}>
                        <XInput
                            style={{ width: '248px' }}
                            inputStyle={{ width: '195px', marginLeft: 14 }}
                            label='手机号'
                            placeholder='请输入手机号码'
                            value={tel}
                            bindThis={this}
                            bindName='tel'
                        />
                        <XInput
                            style={{ width: '248px', marginLeft: '30px' }}
                            inputStyle={{ width: '195px' }}
                            label='订单号'
                            placeholder='请输入订单号'
                            value={orderId}
                            bindThis={this}
                            bindName='orderId'
                            mode='number'
                            maxLength={16}
                            showLength={false}
                        />
                        <XInput
                            style={{ width: '248px', marginLeft: '20px' }}
                            inputStyle={{ width: '180px' }}
                            label='商品名称'
                            placeholder='请输入商品名称'
                            value={goodsName}
                            bindThis={this}
                            bindName='goodsName'
                        />
                        <XInput
                            style={{ width: '248px', marginLeft: '20px' }}
                            inputStyle={{ width: '180px' }}
                            label='商品货号'
                            placeholder='请输入商品货号'
                            value={goodsNo}
                            bindThis={this}
                            bindName='goodsNo'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.row2}>
                        {/* <div className={styles.payDate}>下单时间</div> */}
                        {/* <RangePicker
                            showTime
                            value={orderTime}
                            style={{ width: 356 }}
                            format={dateFormat}
                            placeholder={['开始日期', '结束日期']}
                            onChange={this._onChargeChange}
                        /> */}
                        <XDatePicker
                            style={{ width: '265px', color: '#252525' }}
                            label='下单时间'
                            value={orderStartTime}
                            bindThis={this}
                            bindName='orderStartTime'
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            label=''
                            value={orderEndTime}
                            bindThis={this}
                            bindName='orderEndTime'
                        />
                    </div>
                    {curTabID === 1 && <div className={styles.row2}>
                        <XOKButton
                            loading={isExited}
                            style={{ width: '120px', marginBottom: '10px' }}
                            label='已线下退回'
                            disabled={isLianlian || loading}
                            onClick={() => this._requestLoading('/collectMiningRefund/refund', 'OFFLINE', 'isExited')}
                        />
                        <XOKButton
                            loading={isLianlian}
                            style={{ width: '120px', marginBottom: '10px', marginLeft: '20px' }}
                            label='连连退款'
                            disabled={loading || isExited}
                            onClick={() => this._requestLoading('/collectMiningRefund/refund', 'BANK_CARD', 'isLianlian')}
                        />
                        <XOKButton
                            loading={loading}
                            style={{ width: '120px', marginBottom: '10px', marginLeft: '20px' }}
                            label='支付宝退款'
                            disabled={isLianlian || isExited}
                            onClick={() => this._requestLoading('/collectMiningRefund/refund', 'ALI_PAY', 'loading')}
                        />
                        <XOKButton
                            style={{ width: '150px', marginBottom: '10px', marginLeft: '20px' }}
                            label='支付宝余额查询'
                            disabled={loading || isLianlian || isExited}
                            onClick={() => this._reqBalance(true)}
                        />
                        <div style={{ height: '30px', marginBottom: '10px', marginLeft: '20px', display: 'flex', alignItems: 'center' }}>支付宝余额：￥{balance}</div>
                    </div>}
                    {curTabID === 1 &&
                        <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} suffixText={totalPrice ? `合计：${totalPrice}元` : null} />}
                    <div className={styles.tableContainer}>
                        <Table
                            // bordered
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowSelection={curTabID === 1 ? { ...this._rowSelection, selectedRowKeys } : null}
                            rowKey={(record) => record.id}
                            columns={takeColumns(this._actionClickHandle, state)}
                            dataSource={takeDataSource(dataList)}
                            pagination={false}
                            scroll={{x:2000, y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 跳转其它路由页面
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getCollectMoneyList: PropTypes.func,
    collectMoneyList: PropTypes.object,

    getProductOrderType: PropTypes.func,
    getProductOrderStatus: PropTypes.func,
    getProductOrderFlagEnum: PropTypes.func,

    productOrderType: PropTypes.array,
    productOrderStatus: PropTypes.array,
    productOrderFlagEnum: PropTypes.array
};
const mapStateToProps = (state) => {
    return {
        collectMoneyList: state.collecttrade.collectMoneyList,
        productOrderType: state.productOrder.productOrderType,
        productOrderStatus: state.productOrder.productOrderStatus,
        productOrderFlagEnum: state.productOrder.productOrderFlagEnum
    };
};
export default connect(mapStateToProps, { addOneBreadcrumbPath, getCollectMoneyList, getProductOrderType, getProductOrderStatus, getProductOrderFlagEnum, keepSecondNavBreadcrumb })(Main);