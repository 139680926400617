import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { XTabNav, XPagination, XInput, XTableSelectionCount, XSelect, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import { Table, DatePicker, message } from 'antd';
import http from '@/assets/api/http';
import moment from 'moment';
import { takeDataSource, takeColumns } from './components/TableData';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getCollectMoneyList } from '@/reducers/ordermanage/collecttrade/actions';
import { getProductSubOrderStatus } from '@/reducers/ordermanage/productOrder/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '全部' },
            { id: 2, label: '待付款' },
            { id: 3, label: '已付款' },
            { id: 4, label: '已结算' },
            { id: 5, label: '已取消' },
        ],
        curTabID: 1,  // 默认显示的tab，1：全部 2.待付款 3：已付款 4：已结算 5：已取消
        pageNum: 1,
        pageSize: 10,
        accountTel: null,
        gmtCreateF: null,
        gmtCreateT: null,
        orderType: {},
        invoice: {},
        orderState: {},
        currentOpenMode: null,
        orderId: null,
        orderTime: [],
        status: '',
        selectedRowKeys: [],
        sourceType: {},
        sourceTypeList: [],
    }

    componentDidMount() {
        this.props.getProductSubOrderStatus();
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.getSourceTypeList();
    }

    getSourceTypeList = () => {
        http('/enum/BossGatherWithdrawSourceEnum').then(res => {
            let sourceTypeList = (res.result || []).filter(v => ['SERVICE_PROVIDER', 'WAREHOUSE_CLOUD'].includes(v.code));
            this.setState({ sourceTypeList });
        });
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
        switch (tabID) {
            case 1:
                this.setState({
                    status: '',
                    pageNum: 1,
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 2:
                this.setState({
                    status: 'PAYING',
                    pageNum: 1,
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 3:
                this.setState({
                    status: 'PAIED',
                    pageNum: 1,
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 4:
                this.setState({
                    status: 'FINISHED',
                    pageNum: 1,
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 5:
                this.setState({
                    status: 'CANCELLED',
                    pageNum: 1,
                }, () => {
                    this._searchHandle('useCache');
                });
                break;
        }
    }
    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, accountTel, orderId, gmtCreateF,
            gmtCreateT, goodsName, itemNo, status, curTabID, orderState, sourceType } = this.state;
        this.searchCache = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            accountTel,
            orderId,
            gmtCreateF: gmtCreateF && moment(moment(gmtCreateF).format()).format('YYYY-MM-DD HH:mm:ss'),
            gmtCreateT: gmtCreateT && moment(moment(gmtCreateT).format()).format('YYYY-MM-DD HH:mm:ss'),
            goodsName,
            itemNo,
            sourceList: sourceType.code && [sourceType.code],
            status: curTabID === 1 ? orderState.code : status,
        };
        this.props.getCollectMoneyList(this.searchCache);
        KeepAlive.saveCache({ ...this.searchCache, orderState }); // 【第3步，缓存查询状态】
    }
    // 重置
    _resetHandle = () => {
        const { curTabID, status } = this.state;
        this.setState({
            pageNum: 1,
            pageSize: 10,
            accountTel: null,
            orderId: null,
            gmtCreateF: null,
            gmtCreateT: null,
            goodsName: null,
            itemNo: null,
            orderTime: [],
            orderState: {},
            sourceType: {},
            status: curTabID === 1 ? '' : status,
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }
    // 操作
    _actionClickHandle = (record, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'DETAIL':
                addOneBreadcrumbPath({
                    title: '集采交易详情',
                    path: '/home/ordermanage/collecttrade/detail/'
                });
                history.push({
                    pathname: `/home/ordermanage/collecttrade/detail/${record.buyerId}/${record.orderId}`
                });
                break;
        }
    }
    // 下单时间
    _onChargeChange = (value, dateString) => {
        this.setState({
            gmtCreateF: dateString[0],
            gmtCreateT: dateString[1],
            orderTime: value,
        });
    }
    _renderOrderStateSelect = (orderState) => {
        const { productSubOrderStatus = [] } = this.props;
        if (this.state.curTabID == 1) {
            return (
                <XSelect
                    style={{ marginLeft: '20px', width: '248px' }}
                    selectStyle={{ width: '150px' }}
                    label='订单状态'
                    placeholder='请选择订单状态'
                    renderData={productSubOrderStatus}
                    dataIndex='value'
                    keyIndex='code'
                    value={orderState.value}
                    showSearch={true}
                    bindThis={this}
                    bindName='orderState'
                />
            );
        } else {
            return null;
        }
    }
    _exportHandle = () => {
        const { accountTel, orderId, gmtCreateF, status,
            gmtCreateT, goodsName, itemNo, pageNum, pageSize, selectedRowKeys } = this.state;
        const param = {
            pageNum,
            pageSize,
            accountTel,
            orderId,
            gmtCreateF: gmtCreateF && moment(moment(gmtCreateF).format()).format('YYYY-MM-DD HH:mm:ss'),
            gmtCreateT: gmtCreateT && moment(moment(gmtCreateT).format()).format('YYYY-MM-DD HH:mm:ss'),
            goodsName,
            itemNo,
            status,
            orderIds: selectedRowKeys,
        };
        http('/merchant/order/exportCrowdfundingOrderList', param, 'POST').then((response) => {
            if (response.status == 200) {
                message.success(response.result);
                // if (response.result == '') {
                //     message.error('没有订单数据');
                // } else {
                //     window.location.href = response.result;
                // }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({
                selectedRowKeys,
            });
            console.log(selectedRows);
        }
    };
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    };
    render() {
        const { collectMoneyList: { dataList = [], resultTotal, pageSize, pageNum } } = this.props;
        const { accountTel, goodsName, itemNo, orderId, gmtCreateF, gmtCreateT, orderState, selectedRowKeys, sourceTypeList, sourceType } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                    <div className={styles.line}></div>
                    <div className={styles.row1}>
                        <XInput
                            style={{ width: '248px' }}
                            inputStyle={{ width: '180px' }}
                            label='会员手机号'
                            placeholder='请输入手机号码'
                            value={accountTel}
                            bindThis={this}
                            bindName='accountTel'
                        />
                        <XInput
                            style={{ width: '248px', marginLeft: '20px' }}
                            inputStyle={{ width: '180px' }}
                            label='订单编号'
                            placeholder='请输入订单编号'
                            value={orderId}
                            bindThis={this}
                            bindName='orderId'
                        />
                        {/* <div className={styles.payDate}>下单时间</div>
                        <RangePicker
                            showTime
                            value={orderTime}
                            style={{ width: 356 }}
                            format={dateFormat}
                            placeholder={['开始日期', '结束日期']}
                            onChange={this._onChargeChange}
                        /> */}
                        <XDatePicker
                            style={{ width: '264px', marginLeft: '20px' }}
                            label='下单时间'
                            value={gmtCreateF}
                            bindThis={this}
                            bindName='gmtCreateF'
                        />
                        {/* <div className={styles.timeLine} /> */}
                        <XDatePicker
                            label=''
                            value={gmtCreateT}
                            bindThis={this}
                            bindName='gmtCreateT'
                        />
                        {this._renderOrderStateSelect(orderState)}
                        {/* <XSelect
                            style={{ marginLeft: '20px', width: '218px' }}
                            selectStyle={{ width: '150px' }}
                            label='订单类型'
                            placeholder='请选择订单类型'
                            renderData={productOrderType}
                            dataIndex='value'
                            keyIndex='code'
                            value={orderType.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='orderType'
                        /> */}
                    </div>
                    <div className={styles.row2}>
                        {/* <XSelect
                            style={{ width: '248px' }}
                            selectStyle={{ width: '180px' }}
                            label='是否开票'
                            placeholder='请选择是否开票'
                            renderData={productOrderFlagEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={invoice.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='invoice'
                        /> */}
                        <XInput
                            style={{ width: '248px' }}
                            inputStyle={{ width: '180px' }}
                            label='商品名称'
                            placeholder='请输入商品名称'
                            value={goodsName}
                            bindThis={this}
                            bindName='goodsName'
                        />
                        <XInput
                            style={{ width: '248px', marginLeft: '20px' }}
                            inputStyle={{ width: '180px' }}
                            label='商品货号'
                            placeholder='请输入商品货号'
                            value={itemNo}
                            bindThis={this}
                            bindName='itemNo'
                        />
                        <XSelect
                            style={{ width: '248px', marginLeft: '20px' }}
                            selectStyle={{ width: '180px' }}
                            label='订单来源'
                            placeholder='请选择'
                            renderData={sourceTypeList}
                            dataIndex='value'
                            keyIndex='code'
                            value={sourceType.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='sourceType'
                            isOptionBindID={true}
                        />
                        <XOKButton
                            style={{ marginLeft: '30px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <XOKButton
                        style={{ width: '100px', marginTop: '10px' }}
                        label='生成报表'
                        onClick={this._exportHandle}
                    />
                    <div className={styles.tableContainer}>
                        <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowSelection={this._rowSelection}
                            rowKey={(record) => record.orderId}
                            columns={takeColumns(this._actionClickHandle)}
                            dataSource={dataList}
                            pagination={false}
                            scroll={{ x: '100%', y: true }}
                        />
                        <XPagination
                            resultTotal={resultTotal}
                            pageSize={pageSize}
                            pageNum={pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 跳转其它路由页面
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getCollectMoneyList: PropTypes.func,
    collectMoneyList: PropTypes.object,

    getProductSubOrderStatus: PropTypes.func,

    productSubOrderStatus: PropTypes.array,
};
const mapStateToProps = (state) => {
    return {
        collectMoneyList: state.collecttrading.collectMoneyList,
        productSubOrderStatus: state.productOrder.productSubOrderStatus,
    };
};
export default connect(mapStateToProps, { addOneBreadcrumbPath, getCollectMoneyList, getProductSubOrderStatus, keepSecondNavBreadcrumb })(Main);