import React, { Component } from 'react';
import { Table, } from 'antd';
import PropTypes from 'prop-types';
import styles from './FightTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, cellWidth, tooltipClick, priceFenToYuanCell } from '@/components/TableCell';

export default class Records extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    _columns = () => [
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: cellWidth.big,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '货号',
            dataIndex: 'itemNo',
            key: 'itemNo',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, record) => tooltipClick(text, () => {
                if (text == '') return;
                this.props.tableAction('detail', record);
            })
        },
        {
            title: '销售金额(元)',
            dataIndex: 'totalFee',
            key: 'totalFee',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '平台费用(元)',
            dataIndex: 'payFee',
            key: 'payFee',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, record) => tooltipClick(priceFenToYuanCell(text), () => {
                if (text == '') return;
                this.props.tableAction('cost', record);
            })
        },
        {
            title: '剩余金额(元)',
            dataIndex: 'incomeAmount',
            key: 'incomeAmount',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '付款时间',
            dataIndex: 'payTime',
            key: 'payTime',
            width: cellWidth.time,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    // 解析表格数据，使符合columns格式要求
    _takeDataSource = (data) => {
        if (data) {
            return data.map(item => {
                return item;
            });
        }
    }

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { orderList: { pageNum, pageSize, resultTotal, dataList } = {} } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record) => record.orderId}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

Records.propTypes = {
    renderData: PropTypes.object, // 表格数据源
    paginationChange: PropTypes.func, // 分页
    tableAction: PropTypes.func, // 对表格的操作
};