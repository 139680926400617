/**
 * 直播、视频、社区分类创建或编辑弹框 
 */
import React from 'react';
import { Modal, Slider, Form, Input, Select, message } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import styles from './Reupload.module.scss';
import { getActivityDetail } from '@/reducers/auditManage/activityProduct/actions';

const { Option } = Select;
const limitList = [{ key: 0, value: '否' }, { key: 0, value: '否' }];
class Reupload extends React.Component {
    //初始数据
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
            platMarks: {
                0: '0%',
                100: {
                    style: {
                        color: '#f50',
                    },
                    label: <strong>100%</strong>,
                }
            },
            salaMarks: {
                0: '0%',
                100: {
                    style: {
                        color: '#f50',
                    },
                    label: <strong>100%</strong>,
                }
            }

        };
    }
    //props校验
    static propTypes = {
        visible: PropTypes.bool.isRequired, // 是否显示弹框
        closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
        openModeParam: PropTypes.object.isRequired,//弹框传参
        getActivityDetail: PropTypes.func.isRequired,//异步获取详情
        activityDetail: PropTypes.object.isRequired,//详情
        form: PropTypes.object.isRequired,//表单
        title: PropTypes.string, // 弹框标题
    };
    //异步获取数据
    componentDidMount() {
        const { getActivityDetail, openModeParam } = this.props;
        getActivityDetail(openModeParam.activityGoodsId);
    }
    //提交
    handleSubmit = e => {
        if (e) e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { openModeParam } = this.props;
                console.log(values);
                const reqParams = {};
                reqParams.id = openModeParam.activityGoodsId;
                reqParams.serviceFee = values.serviceFee;
                reqParams.distributionFee = values.distributionFee;
                if (openModeParam.activityType == 'FREE_ACTIVITY') {
                    reqParams.stock = values.stock;
                    reqParams.goodsPrice = values.goodsPrice * 100;
                    reqParams.orderNum = values.orderNum;
                } else if (openModeParam.activityType == 'KILL_ACTIVITY') {
                    reqParams.hasLimit = values.hasLimit;
                    reqParams.limitNum = values.limitNum;
                } else if (openModeParam.activityType == 'MULTI_GROUP') {
                    reqParams.stock = values.stock;
                    reqParams.goodsPrice = values.goodsPrice * 100;
                    reqParams.userNum = values.userNum;
                    reqParams.missionTime = values.missionTime;
                }
                http('/marketing/activityMerchantInfo/appealCommit', reqParams, 'POST')
                    .then(() => {
                        this.setState({
                            visible: false,
                        });
                        this.props.closeMode(true);
                    })
                    .catch((error) => {
                        message.error(error.message);
                    });
            }
        });
    };
    // 点击确认
    _handleOk = () => {
        this.handleSubmit();
    }
    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }
    //渲染表单
    _renderReupload = (activityDetail) => {
        const { openModeParam } = this.props;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { platMarks, salaMarks } = this.state;
        switch (openModeParam.activityType) {
            case 'FREE_ACTIVITY':
                return (
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item label="参与商品平台最低服务费率">
                            {getFieldDecorator('serviceFee', {
                                initialValue: activityDetail.serviceFee / 10,
                                rules: [{ type: 'number', required: true, message: '请输入参与商品平台最低服务费' }]
                            })(<Slider marks={platMarks} />)}
                        </Form.Item>
                        <Form.Item label="参与商品平台最低平台服务费率">
                            {getFieldDecorator('distributionFee', {
                                initialValue: activityDetail.distributionFee / 10,
                                rules: [{ type: 'number', required: true, message: '请输入参与商品平台最低平台服务费' }]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="商品库存">
                            {getFieldDecorator('stock', {
                                initialValue: activityDetail.stock,
                                rules: [{ type: 'number', required: true, message: '请输入商品库存' }]
                            })(<Slider marks={salaMarks} />)}
                        </Form.Item>
                        <Form.Item label="商品价格">
                            {getFieldDecorator('goodsPrice', {
                                initialValue: (activityDetail.goodsPrice ? activityDetail.goodsPrice : 0) / 100,
                                rules: [{ type: 'number', required: true, message: '请输入商品价格' }]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="免单基数">
                            {getFieldDecorator('orderNum', {
                                initialValue: activityDetail.orderNum,
                                rules: [{ type: 'number', required: true, message: '请输入免单基数' }]
                            })(<Input addonAfter='人数' />)}
                        </Form.Item>
                    </Form>
                );
            case 'KILL_ACTIVITY':
                return (
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item label="参与商品平台最低服务费率">
                            {getFieldDecorator('serviceFee', {
                                initialValue: activityDetail.serviceFee / 10,
                                rules: [{ type: 'number', required: true, message: '请输入参与商品平台最低服务费' }]
                            })(<Slider marks={platMarks} />)}
                        </Form.Item>
                        <Form.Item label="参与商品平台最低平台服务费率">
                            {getFieldDecorator('distributionFee', {
                                initialValue: activityDetail.distributionFee / 10,
                                rules: [{ type: 'number', required: true, message: '请输入参与商品平台最低平台服务费' }]
                            })(<Slider marks={this.state.salaMarks} />)}
                        </Form.Item>
                        <Form.Item label="开启限购">
                            {getFieldDecorator('hasLimit', {
                                initialValue: activityDetail.hasLimit,
                                rules: [{ type: 'string', required: true, message: '请输入商品库存' }]
                            })(<Select>
                                {
                                    limitList.map(item => {
                                        return <Option key={item.key} value={item.key}>{item.value}</Option>;
                                    })
                                }

                            </Select>)}
                        </Form.Item>
                        {
                            getFieldValue('hasLimit') == '1' && <Form.Item label="限购数量">
                                {getFieldDecorator('limitNum', {
                                    initialValue: activityDetail.limitNum,
                                    rules: [{ type: 'number', required: true, message: '请输入商品库存' }]
                                })(<Input />)}
                            </Form.Item>
                        }
                    </Form>
                );
            case 'MULTI_GROUP':
                return (
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item label="参与商品平台最低服务费率">
                            {getFieldDecorator('serviceFee', {
                                initialValue: activityDetail.serviceFee / 10,
                                rules: [{ type: 'number', required: true, message: '请输入参与商品平台最低服务费' }]
                            })(<Slider marks={platMarks} />)}
                        </Form.Item>
                        <Form.Item label="参与商品平台最低平台服务费率">
                            {getFieldDecorator('distributionFee', {
                                initialValue: activityDetail.distributionFee / 10,
                                rules: [{ type: 'number', required: true, message: '请输入参与商品平台最低平台服务费' }]
                            })(<Slider marks={salaMarks} />)}
                        </Form.Item>
                        <Form.Item label="商品库存">
                            {getFieldDecorator('stock', {
                                initialValue: activityDetail.stock,
                                rules: [{ type: 'number', required: true, message: '请输入商品库存' }]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="商品价格">
                            {getFieldDecorator('goodsPrice', {
                                initialValue: (activityDetail.goodsPrice ? activityDetail.goodsPrice : 0) / 100,
                                rules: [{ type: 'number', required: true, message: '请输入商品价格' }]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="成团人数">
                            {getFieldDecorator('userNum', {
                                initialValue: activityDetail.userNum,
                                rules: [{ type: 'number', required: true, message: '请输入成团人数' }]
                            })(<Input addonAfter='人数' />)}
                        </Form.Item>
                        <Form.Item label="成团时间">
                            {getFieldDecorator('missionTime', {
                                initialValue: activityDetail.missionTime,
                                rules: [{ type: 'number', required: true, message: '请输入成团时间' }]
                            })(<Input addonAfter='小时' />)}
                        </Form.Item>
                        <Form.Item label="开启限购">
                            {getFieldDecorator('hasLimit', {
                                initialValue: activityDetail.hasLimit,
                                rules: [{ type: 'string', required: true, message: '请输入商品库存' }]
                            })(<Select>
                                {
                                    limitList.map(item => {
                                        return <Option key={item.key} value={item.key}>{item.value}</Option>;
                                    })
                                }

                            </Select>)}
                        </Form.Item>
                        {
                            getFieldValue('hasLimit') == '1' && <Form.Item label="限购数量">
                                {getFieldDecorator('limitNum', {
                                    initialValue: activityDetail.limitNum,
                                    rules: [{ type: 'number', required: true, message: '请输入商品库存' }]
                                })(<Input />)}
                            </Form.Item>
                        }
                    </Form>
                );
            default:
                return (
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item label="参与商品平台最低服务费率">
                            {getFieldDecorator('serviceFee', {
                                initialValue: activityDetail.serviceFee / 10,
                                rules: [{ type: 'number', required: true, message: '请输入参与商品平台最低服务费' }]
                            })(<Slider marks={platMarks} />)}
                        </Form.Item>
                        <Form.Item label="参与商品平台最低平台服务费率">
                            {getFieldDecorator('distributionFee', {
                                initialValue: activityDetail.distributionFee / 10,
                                rules: [{ type: 'number', required: true, message: '请输入参与商品平台最低平台服务费' }]
                            })(<Slider marks={salaMarks} />)}
                        </Form.Item>
                    </Form>
                );
        }

    }

    render() {
        const { activityDetail } = this.props;
        const { visible, confirmLoading, title } = this.state;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.wrapper}>
                    <div className={styles.reson}>
                        <p className={styles.label}>审核失败原因；</p>
                        <p className={styles.text}>{activityDetail.reason}</p>
                    </div>
                    {
                        this._renderReupload(activityDetail)
                    }
                </div>
            </Modal>
        );
    }
}
//映射异步数据
const mapStateToProps = (state) => ({
    activityDetail: state.activityGoodsData.activityDetail,
});
const ReuploadForm = Form.create({ name: 'reupload' })(Reupload);
export default connect(mapStateToProps, { getActivityDetail })(ReuploadForm);