import { createModel } from '@/store/tools';

const model = {
    namespace: 'servicehistory',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getListTableSource': T('getListTableSource'),
            'getHistoryDetail': T('getHistoryDetail'),
            'getHistoryList': T('getHistoryList'),
            'getStoreSelect': T('getStoreSelect'),
            'getTheSales': T('getTheSales'),
        },
        actions: {//服务清单详情
            'getListTableSource': A('getListTableSource'), // 列表数据  
            'getHistoryDetail': A('getHistoryDetail'), // 服务清单详情  
            'getHistoryList': A('getHistoryList'), // 服务清单详情---商品列表  
            'getStoreSelect': A('getStoreSelect'), // 销售情况---店铺下拉  
            'getTheSales': A('getTheSales'), // 销售情况列表   
        },
        sagas: {
            'getListTableSource': S('getListTableSource', '/goods/goodsrate/listGoodsRate'),
            'getHistoryDetail': S('getHistoryDetail', '/goods/goodsrate/goodsRateInfo', 'POST', 'id'),
            'getHistoryList': S('getHistoryList', '/goods/goodsrate/listRateItem'),
            'getStoreSelect': S('getStoreSelect', '/shop/getShopDropDownList'),
            'getTheSales': S('getTheSales', 'settle/reconciliationSource/settleOrderDetail'),
        },
        reducers: {
            'tableList': R('getListTableSource', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'historyDetail': R('getHistoryDetail', {}),
            'historyList': R('getHistoryList', []),
            'getStoreList': R('getStoreSelect', []),
            'theSalesList': R('getTheSales', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
        }
    })
};
export default createModel(model);