/**
 * 修改成长值弹框
 */
import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './SettleModal.module.scss';
import { XInputNum } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

class SettleModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        feeRate: undefined,
    }

    // 确认
    _handleOk = () => {
        const { activityId } = this.props.data;
        const { feeRate } = this.state;
        const data = {
            activityIds: [activityId],
            feeRate: Math.round(feeRate * 10)
        };

        http('/marketing/activityMerchantInfo/collectiveSettle', data, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('结算成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _onChange = (value) => {
        this.setState({ feeRate: value });
    }

    render() {
        const { visible, confirmLoading, feeRate } = this.state;
        const { minRate, maxRate, orderTotalAmount } = this.props.data;
        let settleAmount = 0;
        if (feeRate) {
            settleAmount = parseFloat(orderTotalAmount * (feeRate / 100)) / 100;
        }
        const min = parseFloat((minRate / 10).toFixed(1)); // number类型
        const max = parseFloat((maxRate / 10).toFixed(1)); // number类型

        return (
            <Modal
                width={330}
                centered
                title='结算'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.info}>
                        <div>结算比例区间：</div>
                        <div>{`${min}% ~ ${max}%`}</div>
                    </div>
                    <div className={styles.info}>
                        <XInputNum
                            style={{ marginTop: '10px' }}
                            inputStyle={{ width: '150px' }}
                            label='实际结算比例：'
                            value={feeRate}
                            onChange={this._onChange}
                            min={min}
                            max={max}
                            precision={1}
                        />&nbsp;%
                    </div>
                    <div className={styles.info} style={{ marginTop: '10px' }}>
                        <div>结算总额：</div>
                        <div>{`￥${settleAmount.toFixed(2)}`}</div>
                    </div>
                </div>
            </Modal>
        );
    }
}

SettleModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
};

export default SettleModal;