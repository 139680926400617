import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, message, Radio } from 'antd';
import http from '@/assets/api/http';

const CancelOrder = ({
    closeMode,
    modeParams: {
        buyerId = '',
        id = ''
    }
}) => {

    const [loading, setLoading] = useState(false);
    const [reasons, setReasons] = useState([]);
    const [cancelReason, setCancelReason] = useState('');

    useEffect(() => {
        http('/hotel/reason/listHotelCancelReason', {}, 'POST')
            .then(res => { if (res.status == 200) setReasons(res.result); })
            .catch(() => message.error('获取取消原因数据失败'));
    }, []);

    const handleSubmit = () => {
        setLoading(true);
        http('/hotel/order/cancelOrder', { buyerId, id, cancelReason }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeMode();
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const boxStyle = {
        height: 180,
        overflowY:'auto'
    };

    return <Modal
        width={300}
        centered
        title='取消订单'
        visible={true}
        bodyStyle={{padding:10}}
        confirmLoading={loading}
        onOk={handleSubmit}
        onCancel={closeMode}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'>
        <div style={boxStyle}>
            <Radio.Group
                onChange={e => setCancelReason(e.target.value)}
                value={cancelReason}
            >
                {
                    reasons.map((item, index) => {
                        return <Radio
                            style={radioStyle}
                            key={index} value={item.reasonTitle}
                        >
                            {item.reasonTitle}
                        </Radio>;
                    })
                }
            </Radio.Group>
        </div>
    </Modal>;
};
CancelOrder.propTypes = {
    closeMode: PropTypes.func,
    modeParams: PropTypes.object
};
export default CancelOrder;