import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table, Switch } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';
import titleIcon from '../../../images/fenxiao.png';
import { isGY } from '@/assets/js/authType';
import { regFenToYuan } from '@/util/money';
import GetBtnPression from '@/routes/GetBtnPression';
import labelIcon from '@/assets/image/icon-active.png';

const obgResolve = (obj) => {
	return tooltip(obj ? obj.value : '');
};

export default class TableContent extends Component {

	state = {
		selectedRowKeys: this.props.batchData,
		colsPerssion: []
	};

	componentDidMount() {
		GetBtnPression().then((colsPerssion) => {
			this.setState({ colsPerssion });
		});
	}

	static propTypes = {
		isGDC: PropTypes.bool,
		tableAction: PropTypes.func,
		paginationChange: PropTypes.func,
		renderData: PropTypes.object,
		batchData: PropTypes.array
	};

	_columns = (isGDC) => {
		const { colsPerssion } = this.state;

		// 公共列
		const commondCol = [
			{
				title: '商品图片',
				dataIndex: 'mainImgUrl',
				key: 'mainImgUrl',
				align: 'center',
				width: 100,
				render: ImgCell
			},
			{
				title: '商品标题',
				dataIndex: 'goodsName',
				key: 'goodsName',
				align: 'center',
				width: 200,
				onCell: tooltipStyle,
				render: this._renderTitle
			},
			{
				title: '货号',
				dataIndex: 'goodsNo',
				key: 'goodsNo',
				align: 'center',
				width: 200,
				onCell: tooltipStyle,
				render: tooltip
			},
			{
				title: '是否虚拟商品',
				dataIndex: 'virtualFlag.value',
				key: 'virtualFlag.value',
				align: 'center',
				width: 150,
				onCell: tooltipStyle,
				render: tooltip
			},
			// retailReco
			// servicenvQuantity
			{
				title: '商品状态',
				dataIndex: 'goodsState',
				key: 'goodsState',
				align: 'center',
				width: 150,
				render: obgResolve
			},
			{
				title: '仓库名称',
				dataIndex: 'warehouseName',
				key: 'warehouseName',
				align: 'center',
				width: 200,
				onCell: tooltipStyle,
				render: tooltip
			},
			{
				title: '商品分类',
				dataIndex: 'category',
				key: 'category',
				align: 'center',
				width: 200,
				onCell: tooltipStyle,
				render: tooltip
			},
			{
				title: '分类类型',
				dataIndex: 'categorySubTypeName',
				key: 'categorySubTypeName',
				align: 'center',
				width: 150,
				onCell: tooltipStyle,
				render: tooltip
			},
			{
				title: '品牌',
				dataIndex: 'brandName',
				key: 'brandName',
				align: 'center',
				width: 150,
				onCell: tooltipStyle,
				render: tooltip
			},
			{
				title: '业务类型',
				dataIndex: 'goodsType',
				key: 'goodsType',
				align: 'center',
				width: 150,
				onCell: tooltipStyle,
				render: obgResolve
			},
			{
				title: '库存数量',
				dataIndex: 'invQuantity',
				key: 'invQuantity',
				align: 'center',
				width: 150,
				onCell: tooltipStyle,
				render: tooltip
			},
			// servicenvQuantity
			{
				title: '用户库存数量',
				dataIndex: 'userInvQuantity',
				key: 'userInvQuantity',
				align: 'center',
				width: 150,
				onCell: tooltipStyle,
				render: tooltip
			},
			{
				title: '占用数量',
				dataIndex: 'occupyQuantity',
				key: 'occupyQuantity',
				align: 'center',
				width: 150,
				onCell: tooltipStyle,
				render: tooltip
			},
			{
				title: '销售价（元）',
				dataIndex: 'salePrice',
				key: 'salePrice',
				align: 'center',
				width: 150,
				onCell: tooltipStyle,
				render: (text, item) => this._jointPrice(item.minSalePrice, item.maxSalePrice)
			},
			// setPrice
			// retailPrice
			{
				title: '操作',
				dataIndex: 'goodsId',
				key: 'goodsId',
				align: 'center',
				width: 200,
				render: (goodsId, record) => this._tableAction(goodsId, record)
			}
		];

		const oldColumns = [...commondCol];
		let newColumns = [...commondCol]; // 供应链商户加入更多信息列
		const retailReco = {
			title: '分销推荐',
			dataIndex: 'retailReco',
			key: 'retailReco',
			align: 'center',
			width: 150,
			render: (text, record) => this._renderSwitch(text, record)
		};
		const retailGoodsState = {
			title: '分销商品状态',
			dataIndex: 'retailGoodsState',
			key: 'retailGoodsState',
			align: 'center',
			width: 150,
			onCell: tooltipStyle,
			render: obgResolve
		};
		const servicenvQuantity = {
			title: '服务商库存数量',
			dataIndex: 'servicenvQuantity',
			key: 'servicenvQuantity',
			align: 'center',
			width: 150,
			onCell: tooltipStyle,
			render: tooltip
		};
		const setPrice = {
			title: '结算价（元）',
			dataIndex: 'setPrice',
			key: 'setPrice',
			align: 'center',
			width: 150,
			onCell: tooltipStyle,
			render: (text, item) => this._jointPrice(item.minSetPrice, item.maxSetPrice)
		};
		const retailPrice = {
			title: '分销价（元）',
			dataIndex: 'retailPrice',
			key: 'retailPrice',
			align: 'center',
			width: 150,
			onCell: tooltipStyle,
			render: (text, item) => this._jointPrice(item.minRetailPrice, item.maxRetailPrice)
		};
		newColumns.splice(4, 0, retailReco, retailGoodsState);
		newColumns.splice(12, 0, servicenvQuantity);
		newColumns.splice(15, 0, setPrice, retailPrice);

		return (isGY() ? newColumns : oldColumns).filter(
			(v) => {
				if (v.key == 'virtualFlag.value') {
					return isGDC;
				} else if (v.key == 'setPrice') {
					return colsPerssion.indexOf('productmanage/productlist/jsj') < 0 ? false : true;
				} else if (v.key == 'retailPrice') {
					return colsPerssion.indexOf('productmanage/productlist/fxj') < 0 ? false : true;
				} else {
					return true;
				}
			}
		);
	}

	_jointPrice = (min, max) => {
		if (min === max) {
			const price = regFenToYuan(max);
			return price;
		} else {
			const minPrice = regFenToYuan(min);
			const manPrice = regFenToYuan(max);
			return `${minPrice} - ${manPrice}`;
		}
	};

	//标题
	_renderTitle = (text, { retailFlag, isJoinActivity }) => {
		const oldNode = <div className={styles.titleStyle}>
			{!!isJoinActivity && <img style={{ width: '16px', height: '16px', verticalAlign: 'text-top' }} src={labelIcon} alt='' />}
			<span style={{ paddingLeft: '5px' }}>{text}</span>
		</div>;
		const newNode = <div className={styles.titleStyle}>
			{!!isJoinActivity && <img style={{ width: '16px', height: '16px', verticalAlign: 'text-top', marginRight: '5px' }} src={labelIcon} alt='' />}
			<img style={{ width: '16px', height: '16px', verticalAlign: 'text-top' }} src={titleIcon} alt='' />
			<span style={{ paddingLeft: '5px' }}>{text}</span>
		</div>;
		const code = retailFlag ? retailFlag.code : '';
		return isGY() && code === '1' ? tooltip(newNode) : tooltip(oldNode);
	}

	//分销推荐
	_renderSwitch = (text, { goodsId, retailFlag }) => {
		const code = retailFlag ? retailFlag.code : '';
		const { tableAction } = this.props;
		const isCheck = text ? true : false;
		const disabled = code === '1' ? false : true;
		const recNode = <Switch
			checkedChildren='NO'
			unCheckedChildren='OFF'
			disabled={disabled}
			defaultChecked={isCheck}
			onChange={(checked) => {
				tableAction(goodsId, 'switch', checked);
			}} />;
		return recNode;
	}

	// 表格操作
	_tableAction = (goodsId, { goodsState, retailFlag }) => {
		const { tableAction } = this.props;
		switch (goodsState && goodsState.code) {
			case 'PUT_ON_SALE':
				return (
					<div className={styles.action}>
						<div className={styles.item} onClick={() => tableAction(goodsId, 'detail')}>详情</div>
						<div className={styles.item} onClick={() => tableAction(goodsId, 'soldOut')}>下架</div>
						{
							retailFlag && retailFlag.code === '1' ? <div className={styles.item} onClick={() => tableAction(goodsId, 'skuList')}>设置分销价</div> : null
						}
					</div>
				);
			case 'PULL_OFF_SHELVES':
				return (
					<div className={styles.action}>
						<div className={styles.item} onClick={() => tableAction(goodsId, 'detail')}>详情</div>
						{/* 需求518隐藏编辑功能 */}
						{/* <div className={styles.item} onClick={() => tableAction(goodsId, 'edit')}>编辑</div> */}
						<div className={styles.item} onClick={() => tableAction(goodsId, 'delete')}>删除</div>
						{
							retailFlag.code === '1' ? <div className={styles.item} onClick={() => tableAction(goodsId, 'skuList')}>设置分销价</div> : null
						}
					</div>
				);
			case 'SUCCESS':
				return (
					<div className={styles.action}>
						<div className={styles.item} onClick={() => tableAction(goodsId, 'detail')}>详情</div>
						{/* 需求518隐藏编辑功能 */}
						{/* <div className={styles.item} onClick={() => tableAction(goodsId, 'edit')}>编辑</div> */}
						{
							retailFlag.code === '1' ? <div className={styles.item} onClick={() => tableAction(goodsId, 'skuList')}>设置分销价</div> : null
						}
					</div>
				);
			default:
				break;
		}
	};

	// 表格复选输入
	_rowSelection = {
		onChange: (keys, values) => {
			this.setState({ selectedRowKeys: keys });
			this.props.tableAction(null, 'batch', keys);
		}
	};
	// 清空表格复选框
	_cleanAllSelected = () => {
		this.setState({ selectedRowKeys: [] });
		this.props.tableAction(null, 'batch', []);
	}
	render() {
		const { renderData, paginationChange, isGDC } = this.props;
		const { selectedRowKeys } = this.state;
		this._rowSelection.selectedRowKeys = selectedRowKeys;
		return (
			<div className={`${styles.tableContainer} ${styles.wrap}`} >
				<XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowSelection={this._rowSelection}
					columns={this._columns(isGDC)}
					pagination={false}
					rowKey={item => item.goodsId}
					dataSource={renderData.dataList}
					scroll={{ x: 'max-content', y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}