/**
 * 在线播放弹框
 */
import React from 'react';
import { Modal, Form, Select, Input, message, Alert, DatePicker } from 'antd';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CoverList, BuyPopularity, Charge } from './components';
import { Operation } from '../_utils';
import { timeToMinute } from '@/util/format';
import { regFenToYuan } from '@/util/money';

const day = 60 * 24;
const minute = 60;

class OperateLiveModal extends React.Component {

    state = {
        pageNum: 0,
        pageSize: 8,
        resultTotal: 0,
        dataList: [],
        count: 100,
        money: 0,
        seconds: 0,
        virtualConfigId: undefined,
        alreadyBuyVirtualConfigId: undefined,
    }

    componentDidMount() {
        if (this.props.mode === Operation.Update) {
            this.loadInitial();
            this.loadCoverPage();
            http('/liveCharge/liveChargeInfoById', { liveChargeId: this.props.modeParams.liveChargeId }).then(res => {
                if (res.code === 200) {
                    let { chargeClassId, expectedPlayTime, coverPage, indate, liveCost, liveFreeTime, virtualConfigId, ...params } = res.result || {};

                    this.props.form.setFieldsValue({
                        ...params,
                        expectedPlayTime: expectedPlayTime ? moment(expectedPlayTime) : undefined,
                        broadcasClassId: String(chargeClassId),
                        coverPage: { coverPagePath: coverPage },
                        charge: { indate: indate / day, liveCost, liveFreeTime: liveFreeTime / minute }
                    });
                }
            }).catch(e => this.initErrHandle(e, '加载数据异常'));
        }
    }

    _handleOk = () => {
        this.props.form.validateFields((errors, { coverPage, charge = {}, expectedPlayTime, ...params }) => {

            if (!errors) {
                params['coverPage'] = coverPage && coverPage.coverPagePath;

                let indate = parseFloat(charge['indate']);
                let liveFreeTime = parseFloat(charge['liveFreeTime']);
                let liveCost = charge['liveCost'];

                indate && (indate *= day);
                liveFreeTime && (liveFreeTime *= minute);

                let requestParams = { ...params, expectedPlayTime: expectedPlayTime ? expectedPlayTime.format('YYYY-MM-DD HH:mm:00') : undefined, indate, liveFreeTime, liveCost };

                if (this.props.mode === Operation.Update) {
                    requestParams['liveChargeId'] = this.props.modeParams.liveChargeId;
                    !requestParams.virtualConfigId && (requestParams.virtualConfigId = '');
                    http('/liveCharge/liveChargeUpdate', requestParams).then(res => {
                        if (res.code === 200) {
                            message.success('更新成功');
                            this.props.closeMode(true);
                        }
                    }).catch((e = {}) => message.error(e.message || '更新异常'));
                } else {
                    http('/liveCharge/createLiveChargeInfo', requestParams).then(res => {
                        if (res.code === 200) {
                            message.success('创建成功');
                            this.props.closeMode(true);
                        }
                    }).catch((e = {}) => message.error(e.message || '创建异常'));
                }
            }
        });
    }

    _handleCancel = () => {
        this.props.closeMode();
    }

    loadInitial() {
        http('/liveCharge/createLiveChargeInitInfo', { count: 100, liveChargeId: this.props.modeParams.liveChargeId, initType: this.props.mode === Operation.Update ? 1 : 0 }).then((res) => {
            if (res.code == 200) {
                this.setState({ ...res.result });
                if (res.result.virtualConfigId) {
                    this.props.form.setFieldsValue({
                        virtualConfigId: res.result.virtualConfigId
                            ? res.result.alreadyBuyVirtualConfigId
                            : undefined,
                    });
                }
            }
        }).catch(e => this.initErrHandle(e, '加载数据异常'));
    }

    loadCoverPage = () => {
        const { pageSize, pageNum } = this.state;
        http('/liveCharge/createLiveChargeCoverPageList', { pageSize, pageNum: pageNum + 1 })
            .then((res) => {
                if (res.code === 200) {
                    this.setState(res.result);
                }
            })
            .catch(e => this.initErrHandle(e, '加载海报列表异常'));
    }

    initErrHandle = (res = {}, err) => {
        message.error(res.message || err);
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { pageNum, pageSize, resultTotal, dataList, alreadyBuyVirtualConfigId, count, money } = this.state;
        let hasMore = pageNum * pageSize < resultTotal;
        let seconds = (timeToMinute(this.state.seconds) || '').replace(':', '时').replace(':', '分') + '秒';
        
        return (
            <Modal
                width={950}
                centered
                title={this.props.title}
                visible={true}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <Alert description={`您今天还可以直播：${seconds}，可以前往每日说APP购买直播时长`} type='info' style={{ marginBottom: 15 }} />
                <Form labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} labelAlign='right'>
                    <Form.Item label='直播间标题'>
                        {getFieldDecorator('broadcasTitle', {
                            rules: [{ required: true, message: '请填写直播间标题' }],
                        })(<Input allowClear placeholder='请填写直播间标题' />)}
                    </Form.Item>
                    <Form.Item label='预计开播时间'>
                        {getFieldDecorator('expectedPlayTime', {
                            rules: [{ required: true, message: '请填写预计开播时间' }],
                        })(<DatePicker format='YYYY-MM-DD HH:mm' showTime allowClear placeholder='请填写预计开播时间' style={{ width: '100%' }} />)}
                    </Form.Item>
                    <Form.Item label='直播间分类'>
                        {getFieldDecorator('broadcasClassId', {
                            rules: [{ required: true, message: '请选择直播间分类' }],
                            initialValue: String((this.props.category.default || {}).id)
                        })(
                            <Select placeholder='请选择直播间分类' allowClear>
                                {(this.props.category.list || []).map(item => <Select.Option key={String(item.id)} value={String(item.id)}>{item.className}</Select.Option>)}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label='选择海报'>
                        {getFieldDecorator('coverPage', {
                            rules: [{ required: true, message: '请选择海报' }]
                        })(<CoverList fields={{ key: 'coverPagePath', src: 'coverPagePath' }} hasMore={hasMore} dataSource={dataList} />)}
                    </Form.Item>
                    <Form.Item label='购买人气'>
                        {getFieldDecorator('virtualConfigId', {
                            rules: [{
                                validator: function (rule, value, callback) {
                                    let tip = undefined;
                                    value === null && (tip = '请选择一个规则');
                                    callback(tip);
                                }
                            }],
                        })(<BuyPopularity defaultValue={alreadyBuyVirtualConfigId} />)}
                    </Form.Item>
                    <Form.Item label='收费'>
                        {getFieldDecorator('charge', {
                            rules: [{
                                validator: function (rule, value, callback) {
                                    let tip = undefined;
                                    if (value) {
                                        if (
                                            value.indate === undefined
                                            || value.liveCost === undefined || value.liveCost === -1
                                            || value.liveFreeTime === undefined || value.liveFreeTime === -1
                                        ) tip = '请填写完整的收费信息';
                                    }
                                    callback(tip);
                                }
                            }]
                        })(<Charge count={count} money={regFenToYuan(money)} />)}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

OperateLiveModal.propTypes = {
    title: PropTypes.string, // 弹框标题
    form: PropTypes.object, // 表单支持
    mode: PropTypes.string.isRequired, // 直播间创建||编辑
    closeMode: PropTypes.func.isRequired, // 关闭弹窗
    modeParams: PropTypes.object, // 弹窗入参
    category: PropTypes.array, // 直播间分类
};

const WrappedForm = Form.create({ name: 'operate' })(OperateLiveModal);

export { WrappedForm as OperateLiveModal };