import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { regFenToYuan } from '@/util/money';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

class TableContent extends Component {

    state = {
        selectedRowKeys: [],
    };

    _columns = () => [
        {
            title: '商品图片',
            dataIndex: 'mainImgUrl',
            key: 'mainImgUrl',
            align: 'center',
            width: '10%',
            render: ImgCell
        },
        {
            title: '商品标题',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'center',
            width: '15%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '货号',
            dataIndex: 'goodsNo',
            key: 'goodsNo',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商家编码',
            dataIndex: 'skuNo',
            key: 'skuNo',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品规格',
            dataIndex: 'spec',
            key: 'spec',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '结算价（元）',
            dataIndex: 'settlePriceYuan',
            key: 'settlePriceYuan',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '更新时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            align: 'center',
            width: '15%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'auditState',
            key: 'auditState',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'goodsId',
            key: 'goodsId',
            align: 'center',
            width: '10%',
            render: (id, record) => this._tableAction(id, record)
        },
    ];

    // 表格操作
    _tableAction = (key, record) => {
        if (record.auditState == '待审核') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => this.props.tableAction(key, 'audit', record)}>审核</div>
                </div>
            );
        }
    };

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
            this.props.tableAction(null, 'batch', selectedRowKeys, selectedRows);
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
        this.props.clearRows();
    }

    // 表格数据
    _tableData = (data) => {
        if (data) {
            return data.map((item) => {
                item.settlePriceYuan = regFenToYuan(item.settlePrice);
                return item;
            });
        } else {
            return [];
        }
    }

    render() {
        const { renderData, paginationChange } = this.props;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={record => record.settleRecordId}
                    columns={this._columns()}
                    pagination={false}
                    dataSource={this._tableData(renderData.dataList)}
                    scroll={{ y: true }} />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={paginationChange} />
            </div>
        );
    }
}

TableContent.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    clearRows: PropTypes.func,
    renderData: PropTypes.object,
};

export default TableContent;