import * as T from './actiontypes';
/**
 * 平台活动数据源
 */
const defaultState = {
    tableSource: [],//分类列表数据源
    selectData: {},// 状态下拉选项
    paginations: {}, //分页对象
    advocacyDetails: {},//平台活动参与活动编辑、详情

    RaiseSelect: [],//平台活动新建-选择商品下拉框
    RaiseTable: [],//平台活动新建-选择商品表格数据
    RaisePagination: {},//平台活动新建-选择商品表格数据分页
    participateDetails: {},//平台活动详情获取数据
};

/**
 * @param {*} state 
 * @param {*} action 
 */
export const advocacytaskDate = (state = defaultState, action) => {
    let newTableSource, newSelectData, newPaginations, newDetail, participateDetails, RaiseTable, newRaisePaginations;
    switch (action.type) {
        case T.SET_ADVOCACYLIST_DATA: //列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_ADVOCACYDETAILS_DATA: //平台活动编辑、详情获取
            newDetail = action.payload.result;
            return { ...state, advocacyDetails: newDetail, };
        case T.SET_DROPDIWN_DATA: //平台活动下拉框
            newSelectData = action.payload;
            return { ...state, selectData: newSelectData, };
        case T.SET_RAISETABALE_DATA: //平台活动新建-选择商品表格数据
            RaiseTable = action.payload.result.dataList;
            newRaisePaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, RaiseTable, RaisePagination: newRaisePaginations };
        case T.SET_ADVOCACYPARTICIDETAILS_DATA: //平台活动详情获取数据
            participateDetails = action.payload.result;
            return { ...state, participateDetails, };
        default:
            return state;
    }
};
