/**
 * 已发货出库单
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, XDatePicker, XUploadExcel, showConfirm } from '@/components/xqxc_ui';
import OrderTable from './components/OrderTable';
import LogisticsModal from '../../modal/LogisticsModal';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message, Progress, Modal, Spin } from 'antd';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getWholesaleSelect } from '@/reducers/delivermanage/readydeliver/actions';
import { getAlreadyDeliverList } from '@/reducers/delivermanage/alreadydeliver/actions';
import { OrderType } from '@/vcomps';
import DeliverModal from '../../modal/DeliverModal';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model1 from '@/reducers/systemmanage/delivertemplate/model';
import model2 from '@/reducers/systemmanage/delivergoodsset/model';
import model3 from '@/reducers/expresswaybill/authorization/model';

const { getDeliverTemplateData } = model1.actions;
const { getContactsInfo } = model2.actions;
const { getAuthorizationInfo } = model3.actions;
const invoiceSelect = [{ id: 1, name: '是' }, { id: 0, name: '否' }];
const saleStatus = [{ code: 'GOODS_SENT', value: '已发货' }, { code: 'GOODS_CONFIRMED', value: '已收货' }, { code: 'FINISHED', value: '已完成' }];
const logisticsTypes = [{ id: '2', name: '是' }, { id: '1', name: '否' }];

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.searchCache = { pageNum: 1, pageSize: 20 }; // 查询缓存
        this.printData = [];
        this.timeout = undefined;
    }

    state = {
        showModal: '', // 是否打开弹窗
        openModalParam: {}, // 弹框传参
        accountTel: '', // 会员手机
        orderId: '', // 订单编号
        startDate: undefined, // 下单开始时间
        endDate: undefined, // 下单结束时间
        status: {}, // 订单状态
        orderType: {}, // 订单类型
        isInvoice: {}, // 是否开票
        logisticsCreateStartTime: undefined, // 发货开始时间
        logisticsCreateEndTime: undefined, // 发货结束时间
        expressNo: '', // 物流单号
        selectedRowKeys: [], // 复选框
        selectedRowValues: [], // 复选框
        loading: false,
        progressStatus: 'normal',
        percent: 0,
        wholesaleOrder: { code: '2', value: '全部' }, // 批发订单
        finishedTimeF: undefined,//完成开始时间
        finishedTimeT: undefined,//完成结束时间
        isCainiao: '',//是否菜鸟面单
        batchNo: '',//批次号
        templateItem: {},// 面单打印模板
        tipInfo: '',
        isLoading: false,
    };

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getWholesaleSelect, getDeliverTemplateData, getContactsInfo, getAuthorizationInfo } = this.props;
        keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        getWholesaleSelect();
        getDeliverTemplateData();
        getContactsInfo();
        getAuthorizationInfo();
    }

    // 查询
    _searchHandle = (useCache) => {
        const {
            pageNum, pageSize, accountTel,
            orderId, startDate, endDate,
            status, orderType, isInvoice,
            logisticsCreateStartTime, logisticsCreateEndTime, expressNo,
            wholesaleOrder, finishedTimeF, finishedTimeT,
            isCainiao, batchNo, templateItem
        } = this.state;
        this.searchCache = {
            accountTel,
            orderId,
            startDate,
            endDate,
            status: status.code,
            orderType: orderType.code,
            isInvoice: isInvoice.id,
            logisticsCreateStartTime,
            logisticsCreateEndTime,
            finishedTimeF,
            finishedTimeT,
            expressNo,
            isDistribution: wholesaleOrder.code,
            logisticsType: isCainiao.id, batchNo, templateId: templateItem.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.setState({ selectedRowKeys: [], selectedRowValues: [] });
        this.props.getAlreadyDeliverList(this.searchCache); // 发起请求
        KeepAlive.saveCache({ ...this.searchCache, status, orderType, isInvoice, wholesaleOrder, isCainiao, templateItem }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            accountTel: '',
            orderId: '',
            startDate: undefined,
            endDate: undefined,
            status: {},
            orderType: {},
            isInvoice: {},
            logisticsCreateStartTime: undefined,
            logisticsCreateEndTime: undefined,
            expressNo: '',
            wholesaleOrder: { code: '2', value: '全部' },
            finishedTimeF: undefined,
            finishedTimeT: undefined,
            isCainiao: {},
            batchNo: '',
            templateItem: {}
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    //打印
    _toPrint = (list) => {
        let defaultPrinter;
        const socket = new WebSocket('ws://localhost:13528');

        /***
       * 
       * 获取请求的UUID，指定长度和进制,如 
       * getUUID(8, 2)   //'01001010' 8 character (base=2)
       * getUUID(8, 10) // '47473046' 8 character ID (base=10)
       * getUUID(8, 16) // '098F4D35'。 8 character ID (base=16)
       *   
       */
        const getUUID = (len, radix) => {
            let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
            let uuid = [],
                i;
            radix = radix || chars.length;
            if (len) {
                for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
            } else {
                let r;
                uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
                uuid[14] = '4';
                for (i = 0; i < 36; i++) {
                    if (!uuid[i]) {
                        r = 0 | Math.random() * 16;
                        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
                    }
                }
            }
            return uuid.join('');
        };

        /***
       * 构造request对象
       */
        const getRequestObject = (cmd) => {
            let request = new Object();
            request.requestID = getUUID(8, 16);
            request.version = '1.0';
            request.cmd = cmd;
            return request;
        };

        /**
        * 请求打印机列表demo
        * */
        const getPrintList = () => {
            let request = getRequestObject('getPrinters');
            if (socket.readyState === 1) {
                this.setState({ tipInfo: '正在链接打印机...', isLoading: true }, () => {
                    socket.send(JSON.stringify(request));
                });
            }
        };

        /**
       * 请求任务状态
       * */
        const getTaskStatus = (params) => {
            let request = getRequestObject('getTaskStatus');
            request.taskID = [params.taskID];
            if (socket.readyState === 1) {
                socket.send(JSON.stringify(request));
            }
        };

        /**
      * 打印电子面单
      * printer 指定要使用那台打印机
      * waybillArray 要打印的电子面单的数组
      */

        const doPrint = (printer) => {
            let request = getRequestObject('print');
            request.task = new Object();
            request.task.taskID = getUUID(8, 10);
            request.task.preview = false;
            request.task.printer = printer;
            let documents = new Array();
            for (let i = 0; i < list.length; i++) {
                let doc = new Object();
                doc.documentID = list[i].waybillCode;
                doc.contents = JSON.parse(list[i].printData);
                documents.push(doc);
            }
            request.task.documents = documents;
            console.log('打印传参数据：', request);
            console.log('打印传参JSON数据：', JSON.stringify(request));
            socket.send(JSON.stringify(request));
        };

        //如果是htps
        //socket = new WebSocket('ws://127.0.0.1:13529');



        // 打开Socket
        socket.onopen = (event) => {
            clearTimeout(this.timeout);
            this.setState({ tipInfo: '', isLoading: false }, () => {
                getPrintList(); //打印机列表
            });
            // 监听消息
            socket.onmessage = (event) => {
                let request = JSON.parse(event.data);
                if (request.cmd === 'getPrinters') {
                    defaultPrinter = list[0].printer ? list[0].printer : request.defaultPrinter;
                    this.setState({ tipInfo: '', isLoading: false }, () => {
                        doPrint(defaultPrinter);
                    });
                } else if (request.cmd === 'print') {
                    this.setState({ tipInfo: '正在打印电子面单...', isLoading: true }, () => {
                        getTaskStatus(request);
                    });
                } else if (request.cmd === 'getTaskStatus') {
                    this.setState({ tipInfo: '', isLoading: false });
                    if (request.status === 'failed') {
                        console.log('getTaskStatus，打印失败结果：', request);
                        showConfirm('打印失败', request.msg, () => {
                            doPrint(defaultPrinter);
                        }, () => { }, '重新打印', '关闭');
                    } else if (request.status === 'success') {
                        console.log('getTaskStatus，打印成功结果：', request);
                        message.success('打印成功');
                    }
                }
            };
            // 监听Socket的关闭
            socket.onclose = function (event) {
                console.log('Client notified socket has closed', event);
            };
        };
        this.timeout = setTimeout(() => {
            message.warning('您的打印插件未开启，请先开启后，再进行打印');
            this.setState({ tipInfo: '', isLoading: false });
        }, 3000);
    }

    // 表格操作
    _tableAction = (id, item, type) => {
        const { history, addOneBreadcrumbPath, deliverTemplateData } = this.props;
        let mlist = [];
        switch (type) {
            case 'info': // 查看详情
                addOneBreadcrumbPath({
                    title: '出库单详情',
                    path: '/home/delivermanage/alreadydeliver/info/'
                });
                history.push({
                    pathname: '/home/delivermanage/alreadydeliver/info/' + id
                });
                break;
            case 'deliver': // 修改快递信息
                this.setState({ showModal: 'deliver', openModalParam: item });
                break;
            case 'logistics': // 查看物流
                this.setState({ showModal: 'LogisticsModal', openModalParam: { orderId: item.orderId, id: item.id } });
                break;
            case 'history': // 操作记录
                addOneBreadcrumbPath({
                    title: '操作记录',
                    path: '/home/delivermanage/alreadydeliver/orderhistory/'
                });
                history.push({
                    pathname: '/home/delivermanage/alreadydeliver/orderhistory/'
                });
                break;
            case 'edit':
                this.setState({ showModal: 'Edit', openModalParam: { orderId: item.orderId } });
                break;
            case 'expressWaybill':
                this._onExpressWaybill([id], item.templateId);
                break;
            case 'batchNo':
                this.setState({ batchNo: id }, () => {
                    this._searchHandle('useCache');
                });
                break;
            case 'templateName':
                mlist = deliverTemplateData.filter(i => i.id == item.templateId);
                this.setState({ templateItem: mlist.length ? mlist[0] : {} }, () => {
                    this._searchHandle('useCache');
                });
                break;
        }
    }

    // 打开弹框
    _renderMode = () => {
        const { showModal, openModalParam } = this.state;
        switch (showModal) {
            case 'LogisticsModal':
                return <LogisticsModal visible={true} data={openModalParam} closeMode={this._closeMode} />;
            case 'deliver':
                return <DeliverModal visible={true} data={openModalParam} closeMode={this._closeMode} />;
            default:
                break;
        }
    }

    // 关闭弹框
    _closeMode = (update, type) => {
        this.setState({ showModal: '' }, () => {
            if (update) {
                this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
            }
        });
    }

    // 订单状态
    _StatusChangeHandle = e => {
        this.setState({
            status: e,
        });
    }
    // 导出
    _exportHandle = () => {
        const { selectedRowKeys, accountTel, orderId, startDate, endDate, status, orderType, isInvoice, logisticsCreateStartTime, logisticsCreateEndTime, expressNo } = this.state;
        const param = {
            accountTel,
            orderId,
            startDate,
            endDate,
            status: status.code,
            orderType: orderType.code,
            isInvoice: isInvoice.id,
            logisticsCreateStartTime,
            logisticsCreateEndTime,
            expressNo,
            ids: selectedRowKeys,
        };
        http('/merchant/order/exportAreadlyboundQueryOrderList', param, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result == '') {
                    message.error('没有订单数据');
                } else {
                    window.location.href = response.result;
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    //渲染进度条
    _renderProgress = (data) => {
        if (data.status == 200) {
            this.setState({ percent: 0, loading: true, progressStatus: 'normal' }, () => {
                this._countTime(data);
            });
        }
    }

    //计时器
    _countTime = (data) => {
        const _this = this;
        this.interval = setInterval(() => {
            http('/merchant/order/getImportProgress', { key: data.result.key }, 'POST').then((response) => {
                if (response.result.code == 0) {
                    if (_this.state.percent < 99) {
                        let percent = _this.state.percent + 1;
                        _this.setState({ percent, loading: true });
                    } else {
                        _this.setState({ percent: 99, loading: true });
                    }
                } else if (response.result.code == 2) {
                    clearInterval(_this.interval);
                    // _this.setState({
                    //     loading: false,
                    //     progressStatus: 'exception',
                    //     showModal: 'ImportFail',
                    //     openModalParam: { ...response.result }
                    // });
                    message.error('导入失败');
                } else if (response.result.code == 1) {
                    message.success(response.result.msg);
                    _this.setState({
                        loading: false,
                        progressStatus: 'success',
                        percent: 100
                    });
                    clearInterval(_this.interval);
                } else {
                    message.error(response.result.msg);
                    _this.setState({
                        loading: false,
                        progressStatus: 'normal',
                        percent: 0
                    });
                    clearInterval(_this.interval);
                }
            }).catch((e) => {
                message.error(e.msg);
                clearInterval(_this.interval);
                _this.setState({
                    loading: false,
                    progressStatus: 'exception',
                    percent: 0
                });
            });
        }, 1000);
    }

    //清除定时器
    componentWillUnmount() {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
    }

    //请求批量打印
    _requestDelivery = (params = {}) => {
        http('/merchant/order/cainiao/repeat/print', { ...params }, 'POST')
            .then(res => {
                if (res.result) {
                    this.printData = res.result || [];
                    this._searchHandle('useCache');
                    this.setState({ tipInfo: '正在链接菜鸟物流...', isLoading: true }, () => {
                        this._toPrint(res.result);
                    });
                }
            })
            .catch(err => {
                // message.error('物流模板已被管理员停止服务，请联系管理员开通');
                message.error(err.message);
                this.setState({ tipInfo: '', isLoading: false });
                this._searchHandle('useCache');
            });
    }
    //批量菜鸟面单出库
    _onExpressWaybill = (list, id) => {
        const { contactsInfo, history } = this.props;
        const { templateItem, selectedRowValues } = this.state;
        let templateId = templateItem.id;
        if (!contactsInfo.contactName) {
            showConfirm('', '你还未设置发货联系人，请先去设置', () => {
                history.push({ pathname: '/home/systemmanage/delivergoodsset' });
            }, () => { }, '去设置', '关闭');
        } else {
            if (!list.length) {
                Modal.warning({
                    okText: '关闭',
                    content: '请选择需要打印的订单数据',
                });
                return;
            }
            for (let i of selectedRowValues) {
                if (i.batchNo) templateId = i.templateId;
                if (i.afterSaleId || i.status == 'CANCELLED') return message.warning('批量菜鸟面单出库不能包括申请退款和取消订单的数据');
                if (i.logisticsType && i.logisticsType.code == '1') return message.warning('批量菜鸟面单出库只能勾选菜鸟打单的数据');
            }
            if (id) templateId = id;
            if (!templateId) {
                Modal.warning({
                    okText: '关闭',
                    content: '请选择电子面单打印模板',
                });
                return;
            }
            const keyList = selectedRowValues.map(i => { return i.batchNo; });
            let isSame = true;
            keyList.forEach(i => {
                keyList.forEach(e => {
                    if (i !== e) {
                        isSame = false;
                        return;
                    }
                });
            });
            if (!isSame) {
                Modal.warning({
                    okText: '关闭',
                    content: '一次只能打印相同批次的订单数据，请重新选择',
                });
                return;
            }
            this._requestDelivery({ outOrderIds: list, templateId });
        }
    }

    render() {
        const { accountTel, orderId, startDate, endDate, status, selectedRowValues, selectedRowKeys,
            orderType, isInvoice, logisticsCreateStartTime, logisticsCreateEndTime,
            expressNo, loading, percent, progressStatus, wholesaleOrder, finishedTimeF,
            finishedTimeT, isCainiao, batchNo, templateItem, tipInfo, isLoading } = this.state;
        const { alreadyDeliverList, wholesaleSelectList, deliverTemplateData, authorizationInfo } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <Spin tip={tipInfo} spinning={isLoading}>
                    <div className={styles.flexBoxContainer}>
                        <div className={styles.searchCriteria1}>
                            <XInput
                                style={{ width: '248px' }}
                                inputStyle={{ width: '180px' }}
                                label='会员手机'
                                placeholder='请输入会员手机'
                                value={accountTel}
                                bindThis={this}
                                bindName='accountTel'
                            />

                            <XInput
                                style={{ marginLeft: '20px', width: '248px' }}
                                inputStyle={{ width: '180px' }}
                                label='订单编号'
                                placeholder='请输入订单编号'
                                value={orderId}
                                bindThis={this}
                                bindName='orderId'
                            />

                            <XDatePicker
                                style={{ width: '264px', marginLeft: '20px' }}
                                label='下单时间'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat={true}
                            />

                            <XDatePicker
                                label=''
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                            />
                            {/* <OrderState
                        style={{ marginLeft: '20px', width: '258px' }}
                        selectStyle={{ width: '180px' }}
                        label='订单状态'
                        placeholder='请输入订单状态'
                        bindThis={this}
                        bindName='status'
                        value={status}
                    /> */}
                            <XSelect
                                style={{ marginLeft: '30px', width: '216px' }}
                                selectStyle={{ width: '150px' }}
                                label='订单状态'
                                placeholder='请选择'
                                renderData={saleStatus}
                                onChange={this._StatusChangeHandle}
                                dataIndex='value'
                                keyIndex='code'
                                value={status.value}
                                showSearch={true}
                            />
                            <OrderType
                                style={{ marginLeft: '20px', width: '258px' }}
                                selectStyle={{ width: '180px' }}
                                label='订单类型'
                                placeholder='请输入订单类型'
                                bindThis={this}
                                bindName='orderType'
                                value={orderType}
                            />
                        </div>
                        <div className={styles.searchCriteria2}>
                            <XSelect
                                style={{ width: '248px' }}
                                selectStyle={{ width: '180px' }}
                                label='是否开票'
                                placeholder='请选择是否开票'
                                renderData={invoiceSelect}
                                dataIndex='name'
                                keyIndex='id'
                                value={isInvoice.name}
                                showSearch={true}
                                bindThis={this}
                                bindName='isInvoice'
                            />
                            <XDatePicker
                                style={{ width: '264px', marginLeft: '20px' }}
                                label='发货时间'
                                value={logisticsCreateStartTime}
                                bindThis={this}
                                bindName='logisticsCreateStartTime'
                                isFormat={true}
                            />
                            <XDatePicker
                                label=''
                                value={logisticsCreateEndTime}
                                bindThis={this}
                                bindName='logisticsCreateEndTime'
                                isFormat={true}
                            />
                            <XDatePicker
                                style={{ width: '264px', marginLeft: '20px' }}
                                label='完成时间'
                                value={finishedTimeF}
                                bindThis={this}
                                bindName='finishedTimeF'
                                isFormat={true}
                            />
                            <XDatePicker
                                label=''
                                value={finishedTimeT}
                                bindThis={this}
                                bindName='finishedTimeT'
                                isFormat={true}
                            />
                            <XInput
                                style={{ marginLeft: '20px', width: '248px' }}
                                inputStyle={{ width: '180px' }}
                                label='物流单号'
                                placeholder='请输入物流单号'
                                value={expressNo}
                                bindThis={this}
                                bindName='expressNo'
                            />
                        </div>
                        <div className={styles.searchCriteria2}>
                            <XSelect
                                style={{ width: '248px' }}
                                selectStyle={{ width: '180px' }}
                                label='批发订单'
                                placeholder='请选择'
                                renderData={wholesaleSelectList}
                                dataIndex='value'
                                keyIndex='code'
                                value={wholesaleOrder.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='wholesaleOrder'
                            />
                            <XSelect
                                style={{ marginLeft: '20px', width: '248px' }}
                                selectStyle={{ width: '180px' }}
                                label='菜鸟面单'
                                placeholder='请选择'
                                renderData={logisticsTypes}
                                dataIndex='name'
                                keyIndex='id'
                                value={isCainiao.name}
                                showSearch={true}
                                bindThis={this}
                                bindName='isCainiao'
                            />
                            <XInput
                                style={{ marginLeft: '20px', width: '248px' }}
                                inputStyle={{ width: '180px' }}
                                label='批次号'
                                placeholder='请输入批次号'
                                value={batchNo}
                                bindThis={this}
                                bindName='batchNo'
                            />
                            <XSelect
                                style={{ marginLeft: '20px', width: '308px' }}
                                selectStyle={{ width: '200px' }}
                                label='面单打印模板'
                                placeholder='请选择'
                                renderData={deliverTemplateData}
                                dataIndex='templateName'
                                keyIndex='id'
                                value={templateItem.templateName}
                                showSearch={true}
                                bindThis={this}
                                bindName='templateItem'
                            />
                            <XOKButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </div>
                        <div className={styles.otherFeatures}>
                            {
                                authorizationInfo.status == 1 && <XOKButton
                                    style={{ width: '150px', marginRight: 20 }}
                                    label='批量重打菜鸟面单'
                                    onClick={() => this._onExpressWaybill(selectedRowKeys)}
                                />
                            }
                            <XOKButton
                                style={{ width: '120px', marginRight: 20 }}
                                label='导出出库单'
                                onClick={this._exportHandle}
                            />
                            <XUploadExcel
                                style={{ width: '120px' }}
                                label='导入快递信息'
                                apiurl='merchant/order/outboundByeditImportExcel'
                                successHandle={(info) => {
                                    this._renderProgress(info.file.response);
                                }}
                            />
                            {
                                loading && <div className={styles.progress}>
                                    <Progress type="circle" width={30} percent={percent} status={progressStatus} />
                                </div>
                            }
                        </div>
                        <OrderTable
                            renderData={alreadyDeliverList}
                            tableAction={this._tableAction}
                            paginationChange={this._paginationChange}
                            selectedRowKeys={selectedRowKeys}
                            selectedRowValues={selectedRowValues}
                            status={authorizationInfo.status}
                            selectedRowKeysChange={(selectedRowKeys, selectedRowValues) => { this.setState({ selectedRowKeys, selectedRowValues }); }}
                        />
                        {this._renderMode()}
                    </div>
                </Spin>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getAlreadyDeliverList: PropTypes.func, // 获取已发货出库单
    alreadyDeliverList: PropTypes.object, // 已发货出库单
    getWholesaleSelect: PropTypes.func, // 批发订单下拉列action 
    wholesaleSelectList: PropTypes.array, // 批发订单下拉列数据
    getDeliverTemplateData: PropTypes.func,
    deliverTemplateData: PropTypes.array,
    getContactsInfo: PropTypes.func,
    contactsInfo: PropTypes.object,
    getAuthorizationInfo: PropTypes.func,
    authorizationInfo: PropTypes.object
};

const mapStateToProps = (state) => ({
    alreadyDeliverList: state.alreadydeliver.getAlreadyDeliverList,
    wholesaleSelectList: state.readydeliver.getWholesaleSelect,
    deliverTemplateData: state.delivertemplate.deliverTemplateData,
    contactsInfo: state.delivergoodsset.contactsInfo,
    authorizationInfo: state.authorization.authorizationInfo
});

export default connect(mapStateToProps, {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getAlreadyDeliverList,
    getWholesaleSelect,
    getDeliverTemplateData,
    getContactsInfo,
    getAuthorizationInfo
})(Main);