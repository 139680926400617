/**
 *  【日结账单】入口页
 * */
import React, { useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import Statement from './pages/main';
import { XTabNav } from '@/components/xqxc_ui';
import RunningAccount from '../dailystatement2';
import { getSessionStorage, removeSessionStorage } from '@/assets/js/storage';
import AdvanceTable from './advancetable';

const tabList = [
    { id: '1', label: '日交易流水' },
    { id: '2', label: '日结对账单' },
    { id: '3', label: '预付款对账单' }
];

const storehouselist = ({ history }) => {

    const [curTabID, setCurTabID] = useState('1');

    useState(() => {
        if (getSessionStorage('cwglshrjyjjsdzdcurtabid')) {
            setCurTabID(getSessionStorage('cwglshrjyjjsdzdcurtabid'));
            removeSessionStorage('cwglshrjyjjsdzdcurtabid');
        }
    }, []);

    const pageList = {
        1: <Statement history={history} />,
        2: <RunningAccount history={history} />,
        3: <AdvanceTable history={history} />
    };

    return <div className={styles.flexBoxContainer}>
        <XTabNav renderData={tabList} onChange={tab => setCurTabID(tab)} activedID={curTabID} />
        <div style={{ margin: '5px' }}></div>
        {pageList[curTabID]}
    </div>;
};

storehouselist.propTypes = {
    history: PropTypes.object
};

export default storehouselist;