/**
 * 股票溢价收益
 */
import React from 'react';
import OrderTable from './components/OrderTable';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/previewcollectionmanage/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import Delivery from './modal/Delivery';
import { Button } from 'antd';

class Main extends React.Component {
    state = {
        renderMode: '',
        modeParams: {}
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize } = this.state;
        this.searchCache = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getUploadRechargeKeyList(this.searchCache);
        KeepAlive.saveCache(this.searchCache); // 【第3步，缓存查询状态】
    }

    // 渲染弹框
    _renderMode = () => {
        const { renderMode, modeParams } = this.state;
        switch (renderMode) {
            case 'audit':
                return <Delivery visible modeParams={modeParams} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ renderMode: '', modeParams: {} }, () => {
            update && this._searchHandle('useCache');
        });
    }

    render() {
        const { uploadRechargeKeyList } = this.props;

        return (
            <KeepAlive id='3' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div style={{ marginTop: 10 }}>
                        <Button type='primary' onClick={() => this.setState({ renderMode: 'audit' })}>上传凭证</Button>
                    </div>
                    <OrderTable renderData={uploadRechargeKeyList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getUploadRechargeKeyList: PropTypes.func,
    uploadRechargeKeyList: PropTypes.object,
};
const mapStateToProps = (state) => ({
    uploadRechargeKeyList: state.previewcollectionmanage.uploadRechargeKeyList,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
