import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { message, Cascader } from 'antd';
import { XOKButton, XPagination, XInput, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import { getTemplateData } from '@/reducers/productmanage/template/actions';
import NoDelivery from './modal/NoDelivery';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { setSessionStorage } from '@/assets/js/storage';

class FreightTemplate extends Component {

    static propTypes = {
        history: PropTypes.object,
        addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
        keepSecondNavBreadcrumb: PropTypes.func, // 保持2级面包屑导航信息
        getTemplateData: PropTypes.func,
        templateData: PropTypes.object,
        curTabID: PropTypes.string
    }

    static defaultProps = {
        templateData: { dataList: [], pageNum: 1, pageSize: 3, resultTotal: 0 }
    }

    constructor(props) {
        super(props);
        this.area = null;
    }

    state = {
        renderMode: false,
        modeParames: '',
        templateName: '',
        pageNum: 1,
        pageSize: 3,
        provinceList: [],
        areaIds: []
    }

    componentDidMount() {
        http('/merchant/area/listProvince', {}, 'POST')
            .then(res => {
                if (res.status != 200) return message.error('省份列表获取失败');
                const list = res.result.map(item => {
                    return {
                        value: item.id,
                        label: item.areaName,
                        isLeaf: false,
                    };
                });
                this.setState({ provinceList: list });
            })
            .catch(() => message.error('省份列表获取失败'));
        this.props.keepSecondNavBreadcrumb();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { templateName, areaIds, pageNum, pageSize } = this.state;

        this.searchCache = {
            templateType: 1,
            templateName: templateName ? templateName : undefined,
            nodeliveryCityId: areaIds[1],
            nodeliveryProvinceId: areaIds[0],
            pageNum: useCache == 'useCache' ? pageNum : 1, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : 3, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getTemplateData(this.searchCache);
        KeepAlive.saveCache(this.searchCache); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ templateName: '', areaIds: [], pageNum: 1, pageSize: 3 }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    _delCopyReq = (url, data, winTxt) => {
        http(url, data, 'POST')
            .then(() => {
                message.success(winTxt);
                this._searchHandle('useCache');
            }).catch((reject) => {
                message.error(reject.message);
            });
    }

    _updateContent = (title, url) => {
        const { history, addOneBreadcrumbPath } = this.props;
        const baseUrl = '/home/systemmanage/freightTemplate';
        addOneBreadcrumbPath({
            title,
            path: `${baseUrl}${url}`
        });
        history.push({
            pathname: `${baseUrl}${url}`
        });
    }

    _tableHandle = (id, type, values) => {
        switch (type) {
            case 'del':
                this._delCopyReq('/goods/freightTemplate/deleteFreightTemplate', { id }, '删除成功。');
                break;
            case 'copy':
                this._delCopyReq('/goods/freightTemplate/copyFreightTemplate', { id }, '复制成功。');
                break;
            case 'edit':
                setSessionStorage('spglyfmbcurtabid', this.props.curTabID);
                this._updateContent('编辑运费模板', `/edit/${id}`);
                break;
            case 'noDelivery':
                this.setState({ renderMode: true, modeParames: { values } });
                break;
            case 'info':
                setSessionStorage('spglyfmbcurtabid', this.props.curTabID);
                this._updateContent('运费模板详情', `/info/info/${id}`);
                break;
        }
    }


    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ renderMode: false, modeParames: '' }, () => {
            this._searchHandle('useCache');
        });
    }

    //根据ID获取城市
    _getCityList = selectedOptions => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;
        http('/merchant/area/listAreaByParentId/' + targetOption.value, {}, 'POST')
            .then(res => {
                if (res.status != 200) return message.error('城市列表获取失败');
                targetOption.loading = false;
                targetOption.children = res.result.map(item => {
                    return { label: item.areaName, value: item.id };
                });
                this.setState({
                    provinceList: [...this.state.provinceList]
                });
            })
            .catch(() => message.error('城市列表获取失败'));

    }

    //获取已选择ID
    _onChange = value => {
        this.setState({ areaIds: value });
    }

    render() {
        const { templateData, curTabID } = this.props;
        const { templateName, provinceList, renderMode, modeParames, areaIds } = this.state;

        return (
            <KeepAlive id='main' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle(); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria}>
                        <XInput
                            style={{ marginRight: 20 }}
                            inputStyle={{ width: 200 }}
                            label='模板名称'
                            placeholder='请输入模板名称'
                            bindThis={this}
                            bindName='templateName'
                            value={templateName}
                        />
                        <div className={styles.seleteItem}>
                            <div style={{ paddingRight: 10 }}>不配送地区</div>
                            <Cascader
                                value={areaIds}
                                style={{ marginRight: 20, width: 200 }}
                                options={provinceList}
                                loadData={this._getCityList}
                                onChange={this._onChange}
                                changeOnSelect
                            />
                        </div>
                        <XOKButton
                            style={{ marginRight: 20, width: 60 }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <header><XOKButton
                        style={{ width: 120 }}
                        label='新建运费模板'
                        onClick={() => {
                            setSessionStorage('spglyfmbcurtabid', curTabID);
                            this._updateContent('新建运费模板', '/create');
                        }} />
                    </header>
                    <main className={styles.tableContainer} style={{ overflowY: 'auto' }}>
                        {
                            templateData.dataList.map((item, index) => {
                                return <TableContent
                                    curTabID={curTabID}
                                    key={index}
                                    tableHandle={this._tableHandle}
                                    templateItem={item}
                                />;
                            })
                        }
                    </main>
                    <footer>
                        <XPagination
                            resultTotal={templateData.resultTotal}
                            pageSize={templateData.pageSize}
                            pageNum={templateData.pageNum}
                            onChange={this._paginationChange}
                        />
                    </footer>
                    {renderMode && <NoDelivery closeMode={this._closeMode} modeParames={modeParames} />}
                </div>
            </KeepAlive>
        );
    }
}
export default connect((state) => ({
    templateData: state.templateData.templateData
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getTemplateData
})(FreightTemplate);
