import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';

const houses = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    resultTotal: 0
};
const houseDetail = {
    goodsImagesList: [],
    installationList: []
};

/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const getFacilitySele = (T) => createSaga(T('getFacilitySele'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/hotel/installation/hotelFacilityItem', { ...action.payload }, 'POST');
                let newList = [];
                for (let Item of result.result) {
                    newList.push({ ...Item, active: false });
                }
                yield put({ type: T('originalData'), payload: newList });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const facilitySele = (T) => createReducer((autostate) => {
    return (state = autostate, { type, payload }) => {
        switch (type) {
            case T('originalData'):
                return [...payload];
            case T('whetherCheckAll/GET'):
                return [...state.map(item => ({ ...item, active: payload.active }))];
            case T('changeCheck/GET'):
                return [...state.map(item => {
                    if (item.id === payload.id) {
                        return { ...item, active: payload.active };
                    } else {
                        return item;
                    }
                })];
            case T('batchChange/GET'):
                return [...state.map(item => {
                    if (payload.includes(item.id.toString())) {
                        return { ...item, active: true };
                    } else {
                        return { ...item, active: false };
                    }
                })];
            default:
                return state;
        }
    };
}, []);


//编辑详情
const getEditInfo = (T) => createSaga(T('getEditInfo'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/hotel/houseDtl/' + action.payload.id, {}, 'POST');
                const { goodsImagesList = [], installationList = [], marketPrice = 0, salePrice = 0, breakfastFlag, minPrice = 0 } = result.result;
                let videoUrl = '';
                let imgUrls = [];
                let facilitys = [];
                for (let item of goodsImagesList) {
                    if (item.fileType === 'VIDEO') {
                        videoUrl = item.imgUrl;
                    } else {
                        imgUrls.push(item.imgUrl);
                    }
                }
                for (let fs of installationList) {
                    const keys = Object.keys(fs);
                    const values = Object.values(fs);
                    let newItem = {};
                    newItem.id = keys[0];
                    newItem.name = values[0];
                    newItem.active = true;
                    facilitys.push(newItem);
                }
                yield put({
                    type: T('oldEditInfo'),
                    payload: {
                        ...result.result,
                        videoUrl,
                        imgUrl: imgUrls.toString(),
                        installationList: facilitys,
                        goodsImagesList,
                        marketPrice: marketPrice / 100,
                        salePrice: salePrice / 100,
                        minPrice: minPrice / 100,
                        breakfastFlag: breakfastFlag ? 1 : 0
                    }
                });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

const editInfo = (T) => createReducer((autostate) => {
    return (state = autostate, { type, payload }) => {
        switch (type) {
            case T('oldEditInfo'):
                return payload;
            default:
                return state;
        }
    };
}, houseDetail);

//服务清单
const getServiceList = (T) => createSaga(T('getServiceList'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/hotel/findRateGoodsList', { ...action.payload }, 'POST');
                yield put({
                    type: T('originData'),
                    payload: result
                });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

const serviceList = (T) => createReducer((autostate) => {
    return (state = autostate, { type, payload }) => {
        switch (type) {
            case T('originData'):
                return payload.result;
            case T('batchSet/GET'):
                return state.map(item => {
                    return { ...item, ...payload };
                });
            case T('rateChange/GET'):
                return state.map(item => {
                    if (payload.key == item.goodsId) {
                        return { ...item, ...payload };
                    }
                    return { ...item };
                });
            case T('minimumRate/GET'):
                return state.map(item => {
                    return {
                        ...item,
                        agentRate: item.defaultAgentRate,
                        platRate: item.defaultPlatRate
                    };
                });
            default:
                return state;
        }
    };
}, []);

const model = {
    namespace: 'houseresourcemanage',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getHouseTypeSele': T('getHouseTypeSele'),
            'getWhetherSele': T('getWhetherSele'),
            'getFacilitySele': T('getFacilitySele'),
            'changeCheck': T('changeCheck'),
            'whetherCheckAll': T('whetherCheckAll'),
            batchChange: T('batchChange'),
            'getMerchantSele': T('getMerchantSele'),
            'getHouseStateSele': T('getHouseStateSele'),
            'getHouseResources': T('getHouseResources'),
            'getEditInfo': T('getEditInfo'),
            'getServiceList': T('getServiceList'),
            'batchSet': T('batchSet'),
            'rateChange': T('rateChange'),
            'minimumRate': T('minimumRate'),
        },
        actions: {
            'getHouseTypeSele': A('getHouseTypeSele'),
            'getWhetherSele': A('getWhetherSele'),
            'getFacilitySele': A('getFacilitySele'),
            'changeCheck': A('changeCheck'),
            'whetherCheckAll': A('whetherCheckAll'),
            'batchChange': A('batchChange'),
            'getMerchantSele': A('getMerchantSele'),
            'getHouseStateSele': A('getHouseStateSele'),
            'getHouseResources': A('getHouseResources'),
            'getEditInfo': A('getEditInfo'),
            'getServiceList': A('getServiceList'),
            'batchSet': A('batchSet'),
            'rateChange': A('rateChange'),
            'minimumRate': A('minimumRate'),
        },
        sagas: {
            'getHouseTypeSele': S('getHouseTypeSele', '/goods/category/hotelMerchantCategory'),
            'getWhetherSele': S('getWhetherSele', '/enum/FlagEnum'),
            'getFacilitySele': getFacilitySele(T), // 自定义saga写法，使用依赖注入
            'getMerchantSele': S('getMerchantSele', ''),
            'getHouseStateSele': S('getHouseStateSele', '/enum/HotelGoodsStateEnum'),
            'getHouseResources': S('getHouseResources', '/hotel/houseList'),
            'getEditInfo': getEditInfo(T), // 自定义saga写法，使用依赖注入
            'getServiceList': getServiceList(T),
        },
        reducers: {
            'houseTypeSele': R('getHouseTypeSele', []),
            'whetherSele': R('getWhetherSele', []),
            'facilitySele': facilitySele(T),
            'merchantSele': R('getMerchantSele', []),
            'houseStateSele': R('getHouseStateSele', []),
            'houseResources': R('getHouseResources', houses),
            'editInfo': editInfo(T),
            'serviceList': serviceList(T)
        }
    })
};
export default createModel(model);