import { combineReducers } from 'redux';
import { getMenuList, getAllMenuList, getMenuDetail, getMenuSelect } from './reducer';

export default combineReducers({
	getMenuList, // 获取一级、二级菜单列表
	getAllMenuList, // 获取全部菜单列表
	getMenuDetail, // 获取菜单详情
	getMenuSelect, // 获取一级菜单下拉列表
});

