import * as T from './actiontypes';

const initData = {
    dataList: [],
	pageNum: 1,
	pageSize: 20,
	pages: 0,
	resultTotal: 0
 };

export const productBrand = (state = initData, action) => {
    switch(action.type){
        case T.SET_PRODUCT_BRAND:
            return action.payload.result;
        default:
            return state;
    }
};

export const productBrandSele = (state = [], action) => {
    switch(action.type){
        case T.SET_PRODUCT_BRANDSELE:
            return action.payload.result;
        default:
            return state;
    }
};

export const productBrandDetail = (state = {}, action) => {
    switch(action.type){
        case T.SET_PRODUCT_BRANDDETAIL:
            return action.payload.result;
        default:
            return state;
    }
};