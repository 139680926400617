/**
 * 仓库管理saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as S from './actiontypes';
import http from '@/assets/api/http';

// 获取已分配出库单列表
export const getOutDeliveryOrderList = function* () {
    yield takeEvery(S.GET_OUTDELIVERYORDER_LIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/alloutboundOrderList', action.payload, 'POST');
            yield put({ type: S.SET_OUTDELIVERYORDER_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 获取已取消出库单列表
export const getCancelDeliveryOrderList = function* () {
    yield takeEvery(S.GET_CANCELDELIVERYORDER_LIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/cancelledOutboundOrderList', action.payload, 'POST');
            yield put({ type: S.SET_CANCELDELIVERYORDER_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


