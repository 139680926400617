import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const columns = (TableAction, actionClickHandle) => [
    {
        title: '活动名称',
        dataIndex: 'activityTitle',
        key: 'activityTitle',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '活动类型',
        dataIndex: 'activityType',
        key: 'activityType',
        width: '9%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '报名时间',
        dataIndex: 'enrollment',
        key: 'enrollment',
        width: '17%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '活动发布时间',
        dataIndex: 'released',
        key: 'released',
        width: '17%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '收费方式',
        dataIndex: 'chargeType',
        key: 'chargeType',
        width: '7%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '活动折扣率(%)',
        dataIndex: 'discountRate',
        key: 'discountRate',
        width: '7%',
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '活动状态',
        dataIndex: 'state',
        key: 'state',
        align: 'center',
        width: '8%',
        onCell: tooltipStyle,
        render: tooltip
    },
    // {
    //     title: '活动说明',
    //     dataIndex: 'merchantDesc',
    //     key: 'merchantDesc',
    //     width: '12%',
    //     align: 'center',
    //     onCell: tooltipStyle,
    //     render: tooltip
    // },
    {
        title: '操作',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '15%',
        render: (id, record) => TableAction(id, record, actionClickHandle)
    }
];

const TableAction = (id, record, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'detail', record)}>详情</div>
            {record.state == '报名中' && <div className={styles.item} onClick={() => actionClickHandle(id, 'edit')}>参与活动</div>}
            {/* <div className={styles.item} onClick={() => actionClickHandle(id, 'data')}>数据</div> */}
            {record.activityTypeCode == 'FREE_ACTIVITY' && <div className={styles.item} onClick={() => actionClickHandle(id, 'listing')}>服务清单</div>}
            {(record.state == '已暂停' || record.state == '已开启' || record.state == '未开始') && <div className={styles.item} onClick={() => actionClickHandle(id, 'goods', record)}>追加商品</div>}
            {((record.state == '已暂停' || record.state == '已开启' || record.state == '已结束') && record.activityTypeCode == 'QP_ACTIVITY') && <div className={styles.item}
                onClick={() => actionClickHandle(id, 'fight', record)}>抢拍记录</div>}
        </div >
    );
};

//时间先后数据处理判断参与活动显隐 compareSizes(record.entryStartTime, record.entryEndTime) && record.state != '已暂停'
const compareSizes = (startTime, endTime) => {
    if (startTime || endTime) {
        let start = parseInt(startTime.replace(/(-|:|\s*)/g, ''));
        let end = parseInt(endTime.replace(/(-|:|\s*)/g, ''));
        let currentTime = parseInt(formatDate(new Date()).replace(/(-|:|\s*)/g, ''));
        if (currentTime > start && currentTime < end) {
            return true;
        } else {
            return false;
        }
    }
};
//将时间戳格式化成 yyyy-MM-dd HH:mm:ss
const formatDate = (dateTime) => {
    var date = new Date(dateTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};


class TypeTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data.map(item => {
            item.enrollment = `${item.entryStartTime ? item.entryStartTime : ''}—${item.entryEndTime ? item.entryEndTime : ''}`;//活动开始
            item.released = `${item.startTime ? item.startTime : ''}—${item.endTime ? item.endTime : ''}`;//活动发布
            return item;
        });
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }

    render() {
        const { renderData, pagiNation, paginationChange, } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={columns(TableAction, this._actionHandle)}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={pagiNation.resultTotal}
                    pageSize={pagiNation.pageSize}
                    pageNum={pagiNation.pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.array, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    pagiNation: PropTypes.object, // 分页数据
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TypeTable;