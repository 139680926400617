import http from '@/assets/api/http';
import { getLocalStorage } from '@/assets/js/storage';

const GetBtnPression = () => {
    return new Promise(function (resolve) {
        let btnPremission = JSON.parse(getLocalStorage('btnPremission')) || [];
        if (btnPremission.length == 0) {
            http('/admin/menu/listMenus', {}, 'POST').then((response) => {
                if (response.status == 200) {
                    btnPremission = saveButtonPermission(response.result);
                    resolve(btnPremission);
                }
            }).catch(() => {
                resolve([]);
            });
        } else {
            resolve(btnPremission);
        }
    });
};

function saveButtonPermission(menuList) {
    const premission = getButtonUrlForChildren(menuList);
    return premission;
}

function getButtonUrlForChildren(treeData) {
    let childrenEventKeys = [];
    for (let i = 0; i < treeData.length; i++) {
        if (treeData[i].menuType == '2' || treeData[i].menuType == '3') {
            childrenEventKeys.push(treeData[i].menuUrl);
        }
        if (treeData[i].childrens && treeData[i].childrens.length > 0) {
            const array = this._getButtonUrlForChildren(treeData[i].childrens);
            childrenEventKeys = Array.from(new Set([...childrenEventKeys, ...array]));
        }
    }
    return childrenEventKeys;
}

export default GetBtnPression;
