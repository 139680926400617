import * as T from './actiontypes';

/**
 * 出库单列表
 */
const plistInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const platformReconciliationTableSource = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_WALLETRECONCILIATION_TABLESOURCE:
            return payload.result;
        default:
            return state;
    }
};