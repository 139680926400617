import { combineReducers } from 'redux';
import { productOrderList, productOrderType, productOrderStatus, productOrderFlagEnum, productSubOrderStatus, fenxiaoOrderList, businessType } from './reducer';

export default combineReducers({
    productOrderList,
    productOrderType,
    productOrderStatus,
    productOrderFlagEnum,
    productSubOrderStatus,
    fenxiaoOrderList,
    businessType
});