/**
 * 确认出库弹框
 */
import React from 'react';
import { Modal, Table, Alert } from 'antd';
import PropTypes from 'prop-types';
import styles from './DeliverModal.module.scss';
import { tooltipStyle, tooltip } from '@/components/TableCell';

export default class ImportFail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
        };
    }

    static propTypes = {
        data: PropTypes.object, // 弹框入参
        visible: PropTypes.bool.isRequired, // 是否显示弹框
        closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    };

    _columns = () => {
        return [
            {
                title: '订单号',
                dataIndex: 'orderId',
                key: 'orderId',
                width: '20%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '出库单号',
                dataIndex: 'orderShipmentId',
                key: 'orderShipmentId',
                width: '20%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '失败原因',
                dataIndex: 'msg',
                key: 'msg',
                width: '40%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            }
        ];
    }

    _backHandle = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }

    _dealTableData = (data) => {
        return data ? data : [];
    }

    render() {
        const { visible, confirmLoading } = this.state;
        const { data } = this.props;
        return (
            <Modal
                width={600}
                centered
                title='导入完成'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._backHandle}
                onCancel={this._backHandle}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Alert
                        message={`总共${data.totalNum}个，成功${data.successNum}个，失败${data.failNum}个！`}
                        type="warning"
                        showIcon
                    />
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        columns={this._columns()}
                        pagination={false}
                        rowKey={(record, index) => index}
                        dataSource={this._dealTableData(data.itemList)}
                        scroll={{ y: 450 }} />
                </div>
            </Modal>
        );
    }
}