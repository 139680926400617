/**
 * 修改快递信息弹框
 */
import React from 'react';
import { Modal, message, Button } from 'antd';
import PropTypes from 'prop-types';
import styles from './DeliverModal.module.scss';
import { XInput, XSelect, XTextArea } from '@/components/xqxc_ui';
import { getLogisticsSelect } from '@/reducers/delivermanage/readydeliver/actions';
import { connect } from 'react-redux';
import http from '@/assets/api/http';

class DeliverModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
            originLogistics: [],
            logisticsList: [],
        };
    }

    componentDidMount() {
        this.props.getLogisticsSelect();
        const { data } = this.props;
        http('/merchant/order/get/shipment/logistics', { id: data.id }, 'POST').then(res => {
            const list = res.result || [];
            const logisticsList = list.map((i, index) => ({ ...i, id: index + 1, sellerRemark: i.remark }));
            this.setState({ logisticsList, originLogistics: logisticsList });
        });
    }

    _handleOk = () => {
        const { logisticsList } = this.state;
        const { data: { id } = {} } = this.props;
        for (let i = 0; i < logisticsList.length; i++) {
            const element = logisticsList[i];
            if (!element.expressNo || !element.logisticsCode || !element.logisticsCompany) return message.warn(`请补全第${i + 1}条的物流信息`);
        }
        const confirmaOundReqs = logisticsList.map(i => ({ ...i, id }));
        http('/merchant/order/multiUpdateExpress', { confirmaOundReqs }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('修改成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _addOrderInfo = () => {
        const logisticsList = [...this.state.logisticsList];
        logisticsList.push({ id: logisticsList.length + 1, expressNo: undefined, logisticsCode: undefined, logisticsCompany: undefined, sellerRemark: undefined });
        this.setState({ logisticsList });
    }

    _removeOrderInfo = (id) => {
        const logisticsList = [...this.state.logisticsList];
        this.setState({ logisticsList: logisticsList.filter(i => i.id !== id) });
    }

    _logisticsList = (params = {}) => {
        const logisticsList = this.state.logisticsList.map(i => {
            if (params.id === i.id) return { ...i, ...params };
            return i;
        });
        this.setState({ logisticsList });
    }

    render() {
        const { visible, confirmLoading, logisticsList, originLogistics } = this.state;
        const { logisticsSelect } = this.props;

        return (
            <Modal
                width={500} bodyStyle={{ paddingRight: '10px' }}
                centered
                title='修改快递信息'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    {logisticsList.map((i, index) => {
                        return <div className={styles.itemBox} key={i.id}>
                            <div style={{ display: 'flex' }}>
                                <XSelect
                                    style={{ width: '390px' }}
                                    selectStyle={{ width: '300px' }}
                                    label='物流公司'
                                    placeholder='请选择物流公司'
                                    renderData={logisticsSelect}
                                    dataIndex='optionName'
                                    keyIndex='optionCode'
                                    value={i.logisticsCompany}
                                    showSearch={true}
                                    onChange={e => this._logisticsList({ id: i.id, logisticsCode: e.optionCode, logisticsCompany: e.optionName })}
                                />
                                {index + 1 > originLogistics.length && <Button onClick={() => this._removeOrderInfo(i.key)} style={{ marginLeft: '10px' }}>移除</Button>}
                            </div>
                            <XInput
                                style={{ width: '390px', marginTop: '20px' }}
                                inputStyle={{ width: '300px' }}
                                label='物流单号'
                                placeholder='请输入物流单号'
                                value={i.expressNo}
                                onChange={v => this._logisticsList({ id: i.id, expressNo: v.trim() })}
                            />
                            <XTextArea
                                style={{ width: '390px', marginTop: '20px' }}
                                inputStyle={{ width: '300px', marginLeft: '30px' }}
                                label='备注'
                                placeholder='请输入备注'
                                value={i.sellerRemark}
                                onChange={sellerRemark => this._logisticsList({ id: i.id, sellerRemark })}
                            />
                        </div>;
                    })}
                    <Button type='primary' onClick={this._addOrderInfo} style={{ marginLeft: '250px' }}>添加物流单号</Button>
                </div>
            </Modal>
        );
    }
}

DeliverModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getLogisticsSelect: PropTypes.func, // 获取快递公司下拉列
    logisticsSelect: PropTypes.array, // 快递公司下拉列
};

const mapStateToProps = (state) => ({
    logisticsSelect: state.readydeliver.getLogisticsSelect
});

export default connect(mapStateToProps, { getLogisticsSelect })(DeliverModal);

