//活动商品审核列表
export const GET_AUDIT_ACTIVITYGOODS = 'AUDITMANAGE/GET_AUDIT_ACTIVITYGOODS';
export const SET_AUDIT_ACTIVITYGOODS = 'AUDITMANAGE/SET_AUDIT_ACTIVITYGOODS';
//活动类型
export const GET_AUDIT_ACTIVITYTYPE = 'AUDITMANAGE/GET_AUDIT_ACTIVITYTYPE';
export const SET_AUDIT_ACTIVITYTYPE = 'AUDITMANAGE/SET_AUDIT_ACTIVITYTYPE';
//活动状态
export const GET_AUDIT_ACTIVITYSTATUS = 'AUDITMANAGE/GET_AUDIT_ACTIVITYSTATUS';
export const SET_AUDIT_ACTIVITYSTATUS = 'AUDITMANAGE/SET_AUDIT_ACTIVITYSTATUS';
//活动详情
export const GET_AUDIT_ACTIVITYDETAIL = 'AUDITMANAGE/GET_AUDIT_ACTIVITYDETAIL';
export const SET_AUDIT_ACTIVITYDETAIL = 'AUDITMANAGE/SET_AUDIT_ACTIVITYDETAIL';