// 待发货出库单列表
export const GET_READYDELIVER_LIST = 'READYDELIVER/GET_READYDELIVER_LIST';
export const SET_READYDELIVER_LIST = 'READYDELIVER/SET_READYDELIVER_LIST';

// 快递选择下拉列
export const GET_LOGISTICS_SELECT = 'READYDELIVER/GET_LOGISTICS_SELECT';
export const SET_LOGISTICS_SELECT = 'READYDELIVER/SET_LOGISTICS_SELECT';

// 获取批发订单下拉数据（ 待发货出库单、已发货出库单、已取消出库单共用）
export const GET_WHOLESALE_SELECT = 'READYDELIVER/GET_WHOLESALE_SELECT';
export const SET_WHOLESALE_SELECT = 'READYDELIVER/SET_WHOLESALE_SELECT';
