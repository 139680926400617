/**
 * 服务清单管理
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as SERVICE from './actionTypes';

// 服务清单列表
export const getServiceList = function* () {
    yield takeEvery(SERVICE.GET_SERVICE_LIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/getAfterSaleOrderPage', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SERVICE.SET_SERVICE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

