import React, { Component } from 'react';
import { Table, Alert } from 'antd';
import PropTypes from 'prop-types';
import styles from './LiveTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

export default class GroupTable extends Component {

    //初始化
    constructor(props) {
        super(props);
        this.state = {};
    }

    //props校验
    static propTypes = {
        curTabID: PropTypes.number,
        renderData: PropTypes.object.isRequired, // 表格数据源
        tableAction: PropTypes.func.isRequired, // 表格操作
        paginationChange: PropTypes.func.isRequired, // 分页操作
    };
    _columnsHistory = () => [
        {
            title: '主播账号',
            dataIndex: 'tel',
            key: 'tel',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '主播昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        // {
        //     title: '绑定时间',
        //     dataIndex: 'bindTime',
        //     key: 'bindTime',
        //     width: '20%',
        //     align: 'center',
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        // {
        //     title: '解除绑定时间',
        //     dataIndex: 'className',
        //     key: 'className',
        //     width: '20%',
        //     align: 'center',
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        {
            title: '流水号',
            dataIndex: 'recordId',
            key: 'recordId',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            width: '10%',
            align: 'center',
            render: type => {
                return <span>{type && type.value}</span>;
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            align: 'center',
            render: status => {
                return <span>{status && status.value}</span>;
            }
        },
    ]

    //表头
    _columns = () => [
        {
            title: '主播账号',
            dataIndex: 'tel',
            key: 'tel',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '主播昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '绑定时间',
            dataIndex: 'bindTime',
            key: 'bindTime',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            width: '20%',
            align: 'center',
            render: type => {
                return <span>{type && type.value}</span>;
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (id, record) => this._tableAction(record)
        }
    ];

    //处理返回数据
    _takeDataSource = (data) => {
        return data ? data : [];
    }

    //表格操作
    _tableAction = (record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                { record.type && record.type.code == 'ASSISTANT' && <div className={styles.item} onClick={() => tableAction('', 'unbind', record)}>解除绑定</div>}
            </div>
        );
    };

    //渲染组件
    render() {
        const { paginationChange, curTabID } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Alert message="管理商户的主播" type="info" showIcon style={{ marginBottom: 10, marginTop: 10 }} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={curTabID == 1 ? this._columns() : this._columnsHistory()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}