import * as T from './actiontypes';
//仓库商品结算价(saga)

/**
 *  列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} goodsName 商品名
 * @param {string} goodsNo 货号
 * @param {array} tagIds 标签ID
 * @param {string} categoryId 分类ID
 */
export const getWarehousePriceList = ({ pageSize, pageNum, goodsName, goodsNo, tagIds, categoryId }) => ({
    type: T.GET_STOCKPRICELIST_DATA,
    payload: {
        pageSize,
        pageNum,
        goodsName,
        goodsNo,
        tagIds,
        categoryId,
    }
});

/**
 *  审核列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} goodsName 商品名
 * @param {string} auditState 审核状态
 * @param {string} goodsNo 货号
 * @param {array} tagIds 标签ID
 * @param {string} categoryId 分类ID
 */
export const getWarehouseAuditList = (params = {}) => ({
    type: T.GET_STOCKAUDITLIST_DATA,
    payload: params
});

/**
 * 审核状态'数据源(saga)
 * @param 暂无
 * 
 */
export const getAuditSelectData = () => ({
    type: T.GET_AUDITSELECT_DATA,
});

