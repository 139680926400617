/**
 * 【仓库列表】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message, Input, Card, Carousel, Button, List } from 'antd';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/strategymanage/merchantnotice/model';
import { arraySplit, getStringOverlap } from '@/util/utils';
import { openCustomService, loadYKF } from '@/assets/js/customService';
import http from '@/assets/api/http';

import styles from './index.module.scss';

class Main extends React.Component {

    state = {
        id: undefined,
        customServiceTel: '400 135 8587',
        questionIndex: 1,
        leftDisabled: true,
        rightDisabled: false,
        showSearchResult: false,
        searchText: undefined,
    }

    questionCarousel

    constructor(props) {
        super(props);
        this.questionCarousel = React.createRef();
    }

    componentDidMount() {
        let { searchKey } = this.props.match.params;
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getQuestionList({ pageSize: 999999 });

        searchKey && this.onSearch(searchKey);

        // 如果http请求发生在组件渲染前，则不会被ngx重定向至api
        http('/merchant/customer/getPlatCustomerAccessId').then(res => {
            if (res.code === 200) {
                loadYKF(res.result);
            }
        });
    }

    onSearch = (searchText) => {
        if (searchText) {
            this.setState({ searchText, showSearchResult: true }, () => {
                this.props.getSearchList({ pageSize: 999999, pageNum: 1, keyword: this.state.searchText });
            });
        } else {
            message.info('请输入关键字');
        }
    }

    searchResultClick = (question) => {
        let { history } = this.props;
        let path = `/home/strategymanage/merchantnotice/search/${question.id}/${this.state.searchText}`;
        history.push({ pathname: path });
    }

    returnHome = () => {
        this.setState({ showSearchResult: false, searchText: undefined });
    }

    questionTitleClick = (item, title) => {
        let { history, addOneBreadcrumbPath } = this.props;
        let path = `/home/strategymanage/merchantnotice/promotion/${item.classId}/${title.titleId}`;
        addOneBreadcrumbPath({ title: item.className, path });
        history.push({ pathname: path });
    }

    questionMoreClick = (item, titleList = []) => {
        let { history, addOneBreadcrumbPath } = this.props;
        let titleId = titleList.length ? titleList[0].titleId : -1;
        let path = `/home/strategymanage/merchantnotice/promotion/${item.classId}/${titleId}`;
        addOneBreadcrumbPath({ title: '', path });
        history.push({ pathname: path });
    }

    changeCarousel(direction) {

        let isNext = direction === 1,
            total = Math.ceil(this.props.questionList.length / 10),
            questionIndex = this.state.questionIndex + direction;

        if (questionIndex <= total && isNext) {
            this.setState({ questionIndex, rightDisabled: questionIndex === total, leftDisabled: false });
            this.questionCarousel.current.slick.slickNext();
        }

        if (questionIndex >= 1 && !isNext) {
            this.setState({ questionIndex, rightDisabled: false, leftDisabled: questionIndex === 1 });
            this.questionCarousel.current.slick.slickPrev();
        }
    }

    // 进入客服
    enterCustomService = () => {
        openCustomService ? openCustomService() : message.error('客服加载异常');
    }

    render() {

        let { leftDisabled, rightDisabled, searchText, showSearchResult, customServiceTel } = this.state;

        // 构造问题列表数据结构
        let splits = arraySplit(this.props.questionList, 10).map(arr => arraySplit(arr, 5)),

            // 是否展示左右切换按钮
            hasLR = Math.ceil(this.props.questionList.length / 10) > 1;

        return (
            <div className={styles.merchantMain}>
                {/* 搜索栏目 */}
                <div className={styles.search}>
                    <img className={styles.background} src={require('@/assets/image/merchantnotice/search-background.png')} />
                    <div className={styles.form}>
                        <div className={styles.title}>欢迎使用每日说帮助中心</div>
                        <div><Input.Search size='large' value={searchText} onChange={(e) => this.setState({ searchText: e.target.value })} className={styles.input} placeholder='请输入关键字 例如：发货' enterButton onSearch={this.onSearch} /></div>
                    </div>
                </div>
                {/* 常见问题 */}
                <div className={styles.question}>
                    {/* <div className={styles.titleBar}>常见问题</div> */}
                    <div style={{ marginTop: 10 }}></div>
                    <Carousel dots={false} infinite={false} ref={this.questionCarousel}>
                        {splits.map((arr, index) => (
                            <div key={index} className={styles.cardWrap}>
                                {arr.map((items, index) => (
                                    <div key={index} className={styles.cardRow}>
                                        {items.map(item => (
                                            <Card key={item.classId} className={styles.questionCard}>
                                                <div className={styles.left}>
                                                    <div className={styles.content}>
                                                        <img src={item.classImage} />
                                                        <span>{item.className}</span>
                                                    </div>
                                                </div>
                                                <div className={styles.right}>
                                                    <dl className={styles.questionList}>
                                                        {item.titleList.length
                                                            ? item.titleList.map(title => (<dd key={title.titleId} onClick={() => this.questionTitleClick(item, title)}>{title.titleName || '-'}</dd>))
                                                            : <dd>暂无数据</dd>
                                                        }
                                                    </dl>
                                                    <div className={styles.more} onClick={() => this.questionMoreClick(item, item.titleList)}>更多 &gt;&gt;</div>
                                                </div>
                                            </Card>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </Carousel>
                    {
                        hasLR ?
                            <div className={styles.carouselIndex}>
                                <Button type='primary' shape='circle' icon='left' disabled={leftDisabled} onClick={() => this.changeCarousel(-1)} />
                                <span className={styles.btnSpance}></span>
                                <Button type='primary' shape='circle' icon='right' disabled={rightDisabled} onClick={() => this.changeCarousel(1)} />
                            </div>
                            : null
                    }
                </div>
                {/* 联系我们 */}
                <div className={styles.connect}>
                    <div className={styles.titleBar}>联系我们</div>
                    <div>
                        <Card className={styles.connectCard}>
                            <div className={styles.left}>
                                <img src={require('@/assets/image/merchantnotice/tel.png')} />
                            </div>
                            <div className={styles.right}>
                                <div className={styles.title}>客服热线</div>
                                <div>{customServiceTel}</div>
                            </div>
                        </Card>
                        <Card className={styles.connectCard}>
                            <div className={styles.left}>
                                <img src={require('@/assets/image/merchantnotice/service.png')} />
                            </div>
                            <div className={styles.right}>
                                <Button type='primary' style={{ float: 'right', marginRight: '15px', marginTop: '10px' }} onClick={this.enterCustomService}>立即咨询</Button>
                                <div className={styles.title}>在线客服</div>
                                <div>即时在线沟通，为您解决问题</div>
                            </div>
                        </Card>
                    </div>
                </div>
                {/* 搜索结果 */}
                {showSearchResult ?
                    <div className={styles.result}>
                        <List
                            bordered
                            dataSource={this.props.searchList.dataList}
                            renderItem={item => {
                                let text = '-';
                                if (item.questionName) {
                                    let intersection = getStringOverlap(searchText, item.questionName);
                                    let splits = item.questionName.split(intersection);
                                    text = <>{splits[0]}<span className={styles.strong}>{intersection}</span>{splits[1]}</>;
                                }
                                return (<List.Item key={item.id} onClick={() => this.searchResultClick(item)}>{text}</List.Item>);
                            }}
                            header={
                                <div className={styles.header}>
                                    <span>关于 <span className={styles.strong}>“{searchText}”</span> 一共搜出<span className={styles.strong}> {this.props.searchList.dataList.length} </span>个结果</span>
                                    <Button type='primary' style={{ float: 'right', marginRight: '15px' }} onClick={this.returnHome}>返回</Button>
                                </div>
                            }
                        />
                    </div>
                    : null
                }
            </div>
        );
    }
}

Main.propTypes = {
    match: PropTypes.object, // router match
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航

    questionList: PropTypes.array,
    getQuestionList: PropTypes.func,

    searchList: PropTypes.array,
    getSearchList: PropTypes.func,
};

const mapStateToProps = (state) => ({
    questionList: state.merchantnotice.questionList,
    searchList: state.merchantnotice.searchList
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);