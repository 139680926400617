/**
 * 下拉列加载更多组件
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { XSelect } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { message } from 'antd';

class SelectScroll extends PureComponent {
    constructor(props) {
        super(props);
        const { scrollParams, apiUrl, keyIndex, dataIndex } = props;

        this.state = {
            renderData: [], // 数据源
            loading: false, // 加载中
            apiUrls: apiUrl,//请求url
            scrollParams,//查询条件 
            keyIndex,//类数组对象去重唯一标识
            dataIndex,//onSearch查询标识
            searchParems: '',//onSearch查询标识string
            pageNum: parseInt(scrollParams.pageNum) || 1,//触底下拉加载更多-页码
            resultList: [],//保存返回数据标识
        };
    }

    componentDidMount() {
        const { scrollParams, dataIndex } = this.state;
        //获取onSearch查询标识的数值
        scrollParams && Object.keys(scrollParams).forEach((key) => {
            if (key == dataIndex) {
                this.setState({ searchParems: scrollParams[key] }, () => {
                    this._getHttpList();
                });
            }
        });
    }

    _getHttpList = () => {
        const { renderData, scrollParams, apiUrls, keyIndex, pageNum, searchParems, dataIndex } = this.state;
        this.setState({ loading: true }, () => {
            http(apiUrls, { ...scrollParams, pageNum, [dataIndex]: searchParems }, 'POST').then((response) => {
                if (response.status == 200) {
                    const { result = {} } = response;
                    this.setState({ resultList: result.dataList || [] });//保存返回数据
                    let obj = {};
                    let list = renderData.concat(result.dataList || []).reduce((arr, next) => {
                        obj[next[keyIndex]] ? '' : obj[next[keyIndex]] = true && arr.push(next);
                        return arr;
                    }, []); //设置arr默认类型为数组，并且初始值为空的数组
                    this.setState({ renderData: list, loading: false });
                }
            }).catch((e = {}) => {
                this.setState({ loading: false });
                message.error(`接口${apiUrls}请求失败` || e.message);
            });
        });

    }

    _handleScroll = (e) => {
        e.persist();
        const { target } = e;
        // scrollHeight：代表包括当前不可见部分的元素的高度
        // scrollTop：代表当有滚动条时滚动条向下滚动的距离，也就是元素顶部被遮住的高度
        // clientHeight：包括padding但不包括border、水平滚动条、margin的元素的高度
        const rmHeight = target.scrollHeight - target.scrollTop;
        const clHeight = target.clientHeight;
        // 当下拉框失焦的时候，也就是不下拉的时候

        // 当下拉框下拉并且滚动条到达底部的时候
        // 可以看成是分页，当滚动到底部的时候就翻到下一页
        const { pageNum, resultList } = this.state;
        if (resultList.length > 0 && rmHeight < clHeight + 5) {
            this.setState({ pageNum: pageNum + 1 }, () => {
                this._getHttpList();
            });
        }
    }

    _debounce = (fn, delay = 3000) => {
        //期间间隔执行 节流
        var _this = this;
        return function () {
            clearTimeout(_this.timer);
            _this.timer = setTimeout(() => {
                fn.apply(_this, arguments);
            }, delay);
        };

    }

    _onSearch = (value) => {
        //pageNum 回到第一页
        this.setState({ searchParems: value, pageNum: 1 }, () => {
            let debounceAjax = this._debounce(this._getHttpList, 2000);
            debounceAjax();
        });
    }

    render() {
        const { renderData, loading } = this.state;
        const { apiUrl, ...otherProps } = this.props;

        return (
            <XSelect
                loading={loading}
                key={apiUrl}
                {...otherProps}
                renderData={renderData}
                showSearch={true}
                onPopupScroll={this._handleScroll}
                onSearch={this._onSearch}
            />
        );
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }
}

SelectScroll.propTypes = {
    apiUrl: PropTypes.string.isRequired,//请求的数据url
    scrollParams: PropTypes.object,//查询条件
    keyIndex: PropTypes.string.isRequired,//类数组对象去重唯一标识
    dataIndex: PropTypes.string.isRequired,//onSearch查询标识
};

export default SelectScroll;