/**
 * 【蚁购账户查询】
 */
import React from 'react';
import { Alert, Spin, message } from 'antd';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { regFenToYuan } from '@/util/money';
import { XInput, XDatePicker, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { getAntBuyBalanceDetail } from '@/reducers/storehousefinancialmanage/account/actions';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import AdvertTable from './components/AdvertTable';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';


class Main extends React.Component {

    state = {
        orderId: '',
        pageNum: 1,
        pageSize: 10,
        startDate: null,
        endDate: null,
        loading: false,
        accountNum: 0,
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this._accountQuery(); // 用户余额查询
        // this.props.getWarehouseList();
    }
    // 查询
    _searchHandle = (useCache) => {
        const { orderId, pageNum, pageSize, startDate, endDate } = this.state;
        this.searchCache = {
            orderId,
            startDate,
            endDate,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getAntBuyBalanceDetail(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ orderId: '', pageNum: 1, pageSize: 20, startDate: null, endDate: null, }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        }, () => {
            this._searchHandle('useCache');
        });
    }
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    // 用户余额查询
    _accountQuery = () => {
        this.setState({ loading: true });
        http('/goods/outer/selectAntBuyBalance', {}).then(res => {
            this.setState({ accountNum: res.result });
            this.setState({ loading: false });
        }).catch((res) => {
            this.setState({ loading: false });
            message.error(res.message);
        });
    }
    render() {
        const { orderId, startDate, endDate, accountNum } = this.state;
        const { AntBuyBalanceDetail } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.row1}>
                        <XInput
                            style={{ width: '248px' }}
                            inputStyle={{ width: '180px' }}
                            label='订单号'
                            placeholder='请输入'
                            value={orderId}
                            bindThis={this}
                            bindName='orderId'
                            mode='number'
                            maxLength={16}
                            showLength={false}
                        />
                        <XDatePicker
                            style={{ width: '264px' }}
                            label='选择日期'
                            value={startDate}
                            bindThis={this}
                            bindName='startDate'
                            isFormat={true}
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            label=''
                            value={endDate}
                            bindThis={this}
                            bindName='endDate'
                            isFormat={true}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.row1}>
                        <XOKButton
                            style={{ width: '120px', marginRight: '20px' }}
                            label='用户余额查询'
                            onClick={this._accountQuery}
                        />
                        <Spin spinning={this.state.loading}>
                            <Alert message={`当前账户余额: ${regFenToYuan(accountNum)}元`} type="info" showIcon />
                        </Spin>


                    </div>
                    <AdvertTable renderData={AntBuyBalanceDetail} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getAntBuyBalanceDetail: PropTypes.func,
    AntBuyBalanceDetail: PropTypes.object,

};
const mapStateToProps = (state) => ({
    AntBuyBalanceDetail: state.account.AntBuyBalanceDetail,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getAntBuyBalanceDetail })(Main);