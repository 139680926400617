import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table, Icon, Modal, Alert, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, priceFenToYuanCell, ImgCell } from '@/components/TableCell';
import { isHouNiao } from '@/assets/js/authType';
class TableContent extends React.Component {

    state = {
        selectedRowKeys: [], // 选中的单元行
        renderModal: false
    };

    componentDidMount() {
        const { batch } = this.props;
        this.setState({ selectedRowKeys: batch });
    }

    _takeColumns = () => {
        let columns = [
            {
                title: '货品ID',
                dataIndex: 'outerGoodsId',
                key: 'outerGoodsId',
                width: 150,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '货品名称',
                dataIndex: 'goodsName',
                key: 'goodsName',
                width: 200,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            // {
            //     title: '市场价(元)',
            //     dataIndex: 'marketPrice',
            //     key: 'marketPrice',
            //     width: '9%',
            //     align: 'center',
            //     render: priceFenToYuanCell
            // },
            {
                title: '商品分类ID',
                dataIndex: 'categoryId',
                key: 'categoryId',
                width: 100,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '分类名称',
                dataIndex: 'categoryName',
                key: 'categoryName',
                width: 200,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '品牌名称',
                dataIndex: 'brandName',
                key: 'brandName',
                width: 150,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '商品货号',
                dataIndex: 'goodsNo',
                key: 'goodsNo',
                width: 150,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            // {
            //     title: '销售类型',
            //     dataIndex: 'goodsType',
            //     key: 'goodsType',
            //     width: '9%',
            //     align: 'center',
            //     onCell: tooltipStyle,
            //     render: tooltip
            // },
            // {
            //     title: '删除状态',
            //     dataIndex: 'isDelete',
            //     key: 'isDelete',
            //     width: '9%',
            //     align: 'center',
            //     onCell: tooltipStyle,
            //     render: tooltip
            // },
            {
                title: '上架状态',
                dataIndex: 'goodsState',
                key: 'goodsState',
                width: 100,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '导入状态',
                dataIndex: 'importState',
                key: 'importState',
                width: 100,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '备注',
                dataIndex: 'importMsg',
                key: 'importMsg',
                width: 150,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '更新时间',
                dataIndex: 'gmtModified',
                key: 'gmtModified',
                width: 150,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                render: this._renderActionCell
            }
        ];
        if (isHouNiao()) {
            columns.splice(0, 0, {
                title: '商品图片',
                dataIndex: 'mainImgUrl',
                key: 'mainImgUrl',
                width: 100,
                align: 'center',
                onCell: tooltipStyle,
                render: ImgCell
            });
            columns.splice(4, 0, {
                title: '商品条码',
                dataIndex: 'barcode',
                key: 'barcode',
                width: 150,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            });
            columns.splice(11, 0, {
                title: <div>是否订阅<Icon
                    type='info-circle'
                    theme='twoTone'
                    style={{ fontSize: '14px', marginLeft: '5px', cursor: 'pointer' }}
                    onClick={() => this.setState({ renderModal: !this.state.renderModal })}
                /></div>,
                dataIndex: 'goodsSub.value',
                key: 'goodsSub.value',
                width: 100,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            });
        }
        return columns;
    };

    _renderActionCell = (key, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'sku', record)}>详情</div>
                <div className={styles.item} onClick={() => tableAction(key, 'import')}>导入仓库商品库</div>
                <div className={styles.item} onClick={() => tableAction(key, 'delete')}>删除</div>
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
            this.props.tableAction(null, 'batch', selectedRowKeys);
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
        this.props.tableAction(null, 'batch', []);
    }

    //订阅提示
    _subscribeTip = () => {
        return <Row>
            <Col span={24}>仓库商品上架时，我方发起商品订阅。订阅成功后台更新订阅状态为订阅成功；</Col>
            <Col span={24}>注：目前无取消订阅接口，取消订阅需要线下处理；</Col>
            <Col span={24}>候鸟供应链对应商品的有信息（新增和更新），价格，库存变更则推送通知;我方按通知类型再查询对应接口完成信息同步；</Col>
            <Col span={24}>（1）商品新增 ({0})在接收到推送信息后调用获取商品详情（{0}）接口获取商品信息</Col>
            <Col span={24}>（2）商品更新 ({0})在接收到推送信息后调用获取商品详情（{0}）接口获取商品信息</Col>
            <Col span={24}>（3）商品上架 (r{0})在接收到推送信息后调用获取商品详情（{0}）接口获取商品信息和获取商品价格（{0}）接口获取价格库存更新数据，然后人工上架售卖</Col>
            <Col span={24}>（4）商品下架 ({0})在接收到推送信息后下架相应商品，前端不可以销售</Col>
            <Col span={24}>（5）商品价格 ({0})在接收到推送信息后调用获取商品价格（{0}）接口获取商品价格，触发下架。</Col>
            <Col span={24}>（6）商品库存 ({0})在接收到推送信息后调用获取商品库存（{0}）接口获取商品库存</Col>
        </Row>;
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ x: 2050, y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
                <Modal
                    bodyStyle={{ padding: 10 }}
                    title={null}
                    width={800}
                    visible={this.state.renderModal}
                    onCancel={() => this.setState({ renderModal: false })}
                    footer={false}
                >
                    <div>
                        <Alert
                            message='帮助提示'
                            description={this._subscribeTip()}
                            type='info'
                            showIcon
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    batch: PropTypes.array
};

export default TableContent;