/**
 * 【日结账单】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { message } from 'antd';
import SelectScroll from '../components/selectScroll';
import { XSelect, XOKButton, XCancelButton, XInput, showConfirm, XDatePicker } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/reconciliationdata/model';
import AdvertTable from './components/AdvertTable';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {

    state = {
        channelItem: {}
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getMerchantInvoiceTableSource();
    }
    // 查询
    _searchHandle = (useCache) => {
        const { beginDate, endDate, id, pageNum, pageSize, channelItem } = this.state;
        this.searchCache = {
            beginDate,
            endDate,
            id,
            channelType: channelItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getReconciliationDailyData(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    _tableAction = (id, type, item) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '对账详情',
                    path: {
                        pathname: '/home/storehousefinancialmanage/dailystatement/detail',
                        search: `?gmtAccounting=${item.gmtAccounting}&&merchantId=${item.merchantId}&&id=${item.id}`
                    }
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/dailystatement/detail',
                    search: `?gmtAccounting=${item.gmtAccounting}&&merchantId=${item.merchantId}&&id=${item.id}`
                });
                break;
        }
    }
    // 重置
    _resetHandle = () => {
        this.setState({ beginDate: undefined, endDate: undefined, id: '', pageNum: 1, pageSize: 10, channelItem: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        // this.props.getMerchantInvoiceTableSource();
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { id, beginDate, endDate, channelItem } = this.state;
        const { ReconciliationDailyData } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.serchBox}>
                        <XInput
                            label='流水号'
                            placeholder='请输入'
                            value={id}
                            bindThis={this}
                            bindName='id'
                            mode='number'
                            maxLength={16}
                            showLength={false}
                        />
                        {/* <XSelect
                            style={{ marginLeft: 20 }}
                            selectStyle={{ width: 150 }}
                            label='支付通道'
                            placeholder='请选择'
                            renderData={[]}
                            dataIndex='value'
                            keyIndex='code'
                            value={channelItem.value}
                            bindThis={this}
                            bindName='channelItem'
                        /> */}
                        <XDatePicker
                            style={{ marginLeft: 20 }}
                            label='交易日期'
                            value={beginDate}
                            bindThis={this}
                            bindName='beginDate'
                            isFormat={true}
                            showTime={false}
                            defaultTime='00:00:00'
                        />
                        <XDatePicker
                            style={{ marginRight: 20 }}
                            value={endDate}
                            bindThis={this}
                            bindName='endDate'
                            isFormat={true}
                            showTime={false}
                            defaultTime='23:59:59'
                        />
                        <XOKButton
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: 10 }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <AdvertTable renderData={ReconciliationDailyData} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getReconciliationDailyData: PropTypes.func,
    ReconciliationDailyData: PropTypes.object,
};

const mapStateToProps = (state) => ({
    ReconciliationDailyData: state.reconciliationdata.ReconciliationDailyData,
});

export default connect(mapStateToProps, {
    addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions
})(Main);