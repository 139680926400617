import React from 'react';
import PropTypes from 'prop-types';
import { XTableSelectionCount, XPagination } from '@/components/xqxc_ui';
import { Table, Badge } from 'antd';
import CellFenToYuan from '@/vcomps/ShowCellMoney';
import styles from './AdvertTable.module.scss';

class AdvertTable extends React.Component {
    state = {
        account: '',
        selectedRowKeys: [],
    }

    _takeColumns = () => ([
        {
            title: '付款单号',
            dataIndex: 'id',
            key: 'id',
            width: 150,
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '付款金额(元)',
            dataIndex: 'paymentAmount',
            key: 'paymentAmount',
            width: 150,
            align: 'center',
            render: CellFenToYuan
        },
        {
            title: '收款方数量',
            dataIndex: 'payeeQty',
            key: 'payeeQty',
            width: 150,
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            align: 'center',
            render: (text) => (this._renderStatusCell(text))
        },
        {
            title: '打款说明',
            dataIndex: 'remark',
            key: 'remark',
            width: 300,
            align: 'center',
            render: (text) => (this._renderStatusCell(text))
        },
        {
            title: '新建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 200,
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text, item) => this._renderActionCell(item.id, item)
        },
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderStatusCell = (text) => {
        var index = ['INIT', 'AUDIT', 'FIRST_SUCCESS', 'FIRST_FAIL', 'FINAL_SUCCESS', 'FINAL_FAIL'].indexOf(text);
        var statusText = ['新建', '提交审核', '初审通过', '初审不通过', '复审通过', '复审不通过'];
        var statusBadge = ['processing', 'processing', 'success', 'error', 'success', 'error'];
        return index === -1 ? null : <Badge status={statusBadge[index]} text={statusText[index]} />;
    }
    _renderActionCell = (key, item) => {
        const { status, payStatus } = item;
        const { tableAction } = this.props;

        const buttonElements = [
            <div key="manageAudit" className={styles.item} onClick={() => tableAction(key, 'manageAudit')}>经理审核</div>,
            <div key="directorReview" className={styles.item} onClick={() => tableAction(key, 'directorReview')}>总监复审</div>,
            <div key="edit" className={styles.item} onClick={() => tableAction(key, 'edit')}>编辑</div>,
            <div key="auditSubmit" className={styles.item} onClick={() => tableAction(key, 'auditSubmit')}>提交审核</div>,
            payStatus === 'TODO' ? <div key="pay" className={styles.item} onClick={() => tableAction(key, 'pay', item)}>付款</div> : null,
            <div key="del" className={styles.item} onClick={() => tableAction(key, 'del')}>删除</div>
        ];

        const buttons = {
            INIT: [3, 5],
            AUDIT: [0],
            FIRST_SUCCESS: [1],
            FIRST_FAIL: [3, 5],
            FINAL_SUCCESS: [4],
            FINAL_FAIL: [3, 5]
        }[status] || [];

        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'info')}>详情</div>
                {buttons.map(k => buttonElements[k])}
            </div>
        );
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    onChange = (pagination, filters, sorter) => {
        console.log('params', pagination, filters, sorter);
    };
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }
    render() {
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.tableContainer}>
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    onChange={this.onChange}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default AdvertTable;