import * as T from './actionTypes';

/**
 * 获取待发货出库单列表
 */
const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const getReadyDeliverList = (state = initData, action) => {
    switch (action.type) {
        case T.SET_READYDELIVER_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取快递公司下拉列
 */
export const getLogisticsSelect = (state = [], action) => {
    switch (action.type) {
        case T.SET_LOGISTICS_SELECT:
            return action.payload.result;
        default:
            return state;
    }
};


/**
 * 获取快递公司下拉列
 */
export const getWholesaleSelect = (state = [], action) => {
    switch (action.type) {
        case T.SET_WHOLESALE_SELECT:
            return action.payload.result;
        default:
            return state;
    }
};
