import * as T from './actiontypes';

//初始数据
const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    resultTotal: 0
};

//初始店铺详情
const initShopDetail = {
    auditDesc: '',
    auditTime: '',
    id: 0,
    merchantName: '',
    modifyUserId: 0,
    sourceId: 0,
    sourceName: '',
    violationAttachList: [],
    violationReplyList: [],
    violationState: '',
    violationType: ''
};

const initProductDetatl = {
    appealUserId: 0,
    appealUserName: '',
    disableUserId: 0,
    disableUserName: '',
    gmtCreate: '',
    goodsName: '',
    goodsNo: '',
    mainImgUrl: '',
    merchantId: 0,
    merchantName: '',
    replyAttach: '',
    replyLogs: [],
    replyReason: '',
    violationId: 0,
    violationReason: ''
};

//保存违规的店铺列表
export const illegalShopList = (state = initData, action) => {
    switch (action.type) {
        case T.SET_ILLEGAL_SHOPLIST:
            return action.payload.result;
        default:
            return state;
    }
};

//保存违规的列表下拉
export const illegalSele = (state = [], action) => {
    switch (action.type) {
        case T.SET_ILLEGAL_SELE:
            return action.payload.result;
        default:
            return state;
    }
};

//保存违规的店铺列表详情
export const illegalShopDetail = (state = initShopDetail, action) => {
    switch (action.type) {
        case T.SET_ILLEGAL_SHOPDETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

//保存违规的商品列表
export const illegalProductList = (state = initData, action) => {
    switch (action.type) {
        case T.SET_ILLAGEL_PRODUCTLIST:
            return action.payload.result;
        default:
            return state;
    }
};

//保存违规的店铺列表详情
export const illegalProductDetail = (state = initProductDetatl, action) => {
    switch (action.type) {
        case T.SET_ILLEGAL_PRODUCTDETAIL:
            return action.payload.result;
        default:
            return state;
    }
};