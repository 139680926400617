import * as T from './actiontypes';
//初始数据
const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    resultTotal: 0
};
//保存活动商品列表
export const activityGoods = (state = initData, action) => {
    switch (action.type) {
        case T.SET_AUDIT_ACTIVITYGOODS:
            return action.payload.result;
        default:
            return state;
    }
};
//保存活动类型
export const activityType = (state = [], action) => {
    switch (action.type) {
        case T.SET_AUDIT_ACTIVITYTYPE:
            return action.payload.result;
        default:
            return state;
    }
};
//保存活动类型
export const activityStatus = (state = [], action) => {
    switch (action.type) {
        case T.SET_AUDIT_ACTIVITYSTATUS:
            return action.payload.result;
        default:
            return state;
    }
};
//保存活动类型
export const activityDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_AUDIT_ACTIVITYDETAIL:
            return action.payload.result;
        default:
            return state;
    }
};




