import * as T from './actiontypes';
const defaultState = {
    tableSource: [],// 列表
    paginations: {}, //列表分页对象
};


/**
 * 存储'仓库商品库存数据源-'数据源
 * @param {*} state 
 * @param {*} action 
 */
export const warehouseStockListDate = (state = defaultState, action) => {
    let newTableSource, newPaginations;
    switch (action.type) {
        case T.SET_STOCKLIST_DATA: // 列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        default:
            return state;
    }
};