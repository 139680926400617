import {
    getIllegalShopList,
    getIllegalSele,
    getIllegalShopDetail,
    getIllegalProductList,
    getIllegalProductDetail
} from './illegalList/saga';

export default [
    getIllegalShopList,
    getIllegalSele,
    getIllegalShopDetail,
    getIllegalProductList,
    getIllegalProductDetail
];