import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';

const dataList = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };


/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const getTimeLabelEnum = (T) => createSaga(T('getTimeLabelEnum'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/enum/TimeLabelEnum', { ...action.payload }, 'POST');
                let timeLabelEnum = result.result.map(item => {
                    return { ...item, active: false };
                });
                yield put({ type: T('originalData'), payload: timeLabelEnum });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const timeLabelEnum = (T) => createReducer((autostate) => {
    return (state = autostate, { type, payload }) => {
        switch (type) {
            case T('originalData'):
                return [...payload];
            case T('timeLabelChange/GET'):
                return [...state.map(item => {
                    if (payload === item.code) {
                        return { ...item, active: true };
                    }
                    return { ...item, active: false };
                })];
            case T('resetTimeLabel/GET'):
                return [...state.map(item => {
                    return { ...item, active: false };
                })];
            default:
                return state;
        }
    };
}, []);

const model = {
    namespace: 'billquery',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getBillQueryList': T('getBillQueryList'),
            'getStatusEnum': T('getStatusEnum'),
            'getTypeEnum': T('getTypeEnum'),
            'getTimeLabelEnum': T('getTimeLabelEnum'),
            'timeLabelChange': T('timeLabelChange'),
            'resetTimeLabel': T('resetTimeLabel'),
            'getBalanceQuery': T('getBalanceQuery'),
            'getAccountInfoList': T('getAccountInfoList'),
            'getAccountTypeEnum': T('getAccountTypeEnum'),
            'getWalletInfoList': T('getWalletInfoList'),
            'getWalletSubtotal': T('getWalletSubtotal'),
        },
        actions: {
            'getBillQueryList': A('getBillQueryList'),
            'getStatusEnum': A('getStatusEnum'),
            'getTypeEnum': A('getTypeEnum'),
            'getTimeLabelEnum': A('getTimeLabelEnum'),
            'timeLabelChange': A('timeLabelChange'),
            'resetTimeLabel': A('resetTimeLabel'),
            'getBalanceQuery': A('getBalanceQuery'),
            'getAccountInfoList': A('getAccountInfoList'),
            'getAccountTypeEnum': A('getAccountTypeEnum'),
            'getWalletInfoList': A('getWalletInfoList'),
            'getWalletSubtotal': A('getWalletSubtotal'),
        },
        sagas: {
            'getBillQueryList': S('getBillQueryList', '/mer/reconciliation/findReconciliationPage'),
            'getStatusEnum': S('getStatusEnum', '/enum/ReconciliationSettStatusEnum'),
            'getTypeEnum': S('getTypeEnum', '/enum/ReconciliationOrderTypeEnum'),
            'getTimeLabelEnum': getTimeLabelEnum(T),
            'getBalanceQuery': S('getBalanceQuery', '/pay/outAccount/balanceQuery'),
            'getAccountInfoList': S('getAccountInfoList', '/pay/outAccount/queryOutAccountDetails'),
            'getAccountTypeEnum': S('getAccountTypeEnum', '/enum/QueryOutAccountDetailsTradeBusinessType'),
            'getWalletInfoList': S('getWalletInfoList', '/settle/reconciliationSource/walletSettleOrderDetail'),
            'getWalletSubtotal': S('getWalletSubtotal', '/settle/reconciliationSource/walletSettleOrderDetailSubtotal'),
        },
        reducers: {
            'billQueryList': R('getBillQueryList', dataList),
            'statusEnum': R('getStatusEnum', []),
            'typeEnum': R('getTypeEnum', []),
            'timeLabelEnum': timeLabelEnum(T),
            'balanceQuery': R('getBalanceQuery', { balance: 0 }),
            'accountInfoList': R('getAccountInfoList', dataList),
            'accountTypeEnum': R('getAccountTypeEnum', []),
            'walletInfoList': R('getWalletInfoList', dataList),
            'walletSubtotal': R('getWalletSubtotal', {}),
        }
    })
};
export default createModel(model);