/**
 * 新增商品
 */
import React, { Fragment } from 'react';
import { Modal, Icon, Table } from 'antd';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
import styles from './GoodsModal.module.scss';
import { message, } from 'antd';
import model from '@/reducers/productmanage/recommendGoodSet/model';
import { XSmallPagination, XTableSelectionCount, XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
const columnsRight = (actionClickHandle) => ([
    {
        title: '商品名称',
        dataIndex: 'goodsName',
        key: 'goodsName',
        align: 'center',
        width: '45%'
    },
    {
        title: '商品货号',
        dataIndex: 'goodsNo',
        key: 'goodsNo',
        align: 'center',
        width: '45%'
    },
    {
        title: '操作',
        dataIndex: 'goodsId',
        key: 'goodsId',
        align: 'center',
        width: '10%',
        render: (goodsId, record) => _tableHandle(goodsId, record, actionClickHandle)
    }
]);
const columns = [
    {
        title: '商品名称',
        dataIndex: 'goodsName',
        key: 'goodsName',
        align: 'center',
        width: '50%'
    },
    {
        title: '商品货号',
        dataIndex: 'goodsNo',
        key: 'goodsNo',
        align: 'center',
        width: '50%'
    },
];
const _tableHandle = (goodsId, record, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(goodsId, record)}>删除</div>
        </div >
    );
};
// 解析表格数据
const _takeDataSource = (data) => {
    // 解析表格数据，使符合columns格式要求
    return data;
};
// Customize TableData
class TableData extends React.Component {
    constructor(props) {
        super(props);
        let keys = [];
        props.productList.map(item => {
            keys.push(item.goodsId);
        });
        this.state = {
            populedata: true,//初始化默认数据 作缓存仅作判断
            selectedRowKeys: keys,//对应的key数据-左
            selectedRows: props.productList,//对应的key行数据-左
            goodsName: '',//商品名称
            goodsNo: '',
            dealTransferData: props.productList,//提交数据
            saveDataSource: props.productList,//保存数据源
        };
    }

    //删除数据
    actionClickHandle = (goodsId) => {
        const { dealTransferData } = this.state;
        let TransferData = dealTransferData.filter((v) => v.goodsId != goodsId);//提交数据
        this.setState({
            dealTransferData: TransferData,
        });
        this.props.dealHandleData(TransferData);
    };
    // 页面发生变化
    paginationChange = (pageSize, pageNum) => {
        const { goodsName, goodsNo } = this.state;
        const { goodsList } = this.props;
        goodsList({ pageSize, pageNum, goodsNo, goodsName });
    };
    // 查询触发
    _searchHandle = () => {
        const { goodsName, goodsNo } = this.state;
        const { goodsList } = this.props;
        goodsList({ goodsName, goodsNo });
    }
    // 重置触发
    _saleresetHandle = () => {
        this.setState({ goodsName: '', goodsNo: '' });
        const { goodsList } = this.props;
        goodsList({ pageNum: 1, pageSize: 10 });
    }
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [], selectedRows: [] });
    }
    // 表格单选输入
    onSelectChange = (selectedRowKeys, selectedRows) => {
        //避免用户手动选择/取消选择行 --点击复选框 checkbox时，（只能保存当前分页选中数据），分页进行选中行无法保存
        const { saveDataSource, } = this.state;//保存数据源
        let submitSaveData = saveDataSource.concat(selectedRows);
        let hash = {};
        submitSaveData = submitSaveData.reduce((item, next) => {
            if (!hash[next.goodsId]) {
                hash[next.goodsId] = true;
                item.push(next);
            }
            return item;
        }, []);
        let save = [];
        submitSaveData.map((v) => {
            if (selectedRowKeys.includes(v.goodsId)) {
                save.push(v);
            }
        });
        this.setState({ selectedRowKeys, selectedRows: save, saveDataSource: save });
    };
    // 点击行事件处理
    rowSelected = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        const selectedRows = [...this.state.selectedRows];
        if (selectedRowKeys.indexOf(record.goodsId) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.goodsId), 1);//过滤key-string[]
            selectedRows.splice(selectedRows.indexOf(record), 1);//过滤row[]
        } else {
            selectedRowKeys.push(record.goodsId);
            selectedRows.push(record);
        }
        this.setState({ selectedRowKeys, selectedRows, saveDataSource: selectedRows });
    }

    //选中数据处理
    _dealHandleData = () => {
        const { selectedRows } = this.state;
        if (selectedRows.length > 3) {
            message.error('推荐商品设置不能超过三个！');
            return;
        }
        this.setState({
            dealTransferData: selectedRows,
        }, () => {
            if (this.state.dealTransferData.length > 0) {
                message.success('以选中数据');
                this.props.dealHandleData(this.state.dealTransferData);
            } else {
                message.warn('未选中数据');
            }
        });
    }
    render() {
        const { raisePagination, dataSource } = this.props;
        const { selectedRowKeys, goodsName, dealTransferData, goodsNo } = this.state;
        const rowSelection = {//左
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <Fragment>
                <div className={styles.layout}>
                    <div className={styles.left}>
                        <div className={`${styles.layout} ${styles.dataTop}`}>
                            <XInput
                                style={{ width: '216px' }}
                                inputStyle={{ width: '150px' }}
                                label='商品名称'
                                placeholder='请输入商品名称'
                                value={goodsName}
                                bindThis={this}
                                bindName='goodsName'
                            />
                            <XInput
                                style={{ marginLeft: '20px', width: '216px' }}
                                inputStyle={{ width: '150px' }}
                                label='商品货号'
                                placeholder='请输入商品货号'
                                value={goodsNo}
                                bindThis={this}
                                bindName='goodsNo'
                            />
                            <XOKButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='搜索'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='重置'
                                onClick={this._saleresetHandle}
                            />
                        </div>
                        <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowSelection={rowSelection}
                            rowKey={(record) => record.goodsId}
                            columns={columns}
                            dataSource={_takeDataSource(dataSource)}
                            pagination={false}
                            onRow={(record) => {
                                return {
                                    onClick: () => {
                                        this.rowSelected(record);
                                    },
                                };
                            }}
                        />
                        <XSmallPagination
                            resultTotal={raisePagination.resultTotal}
                            pageSize={raisePagination.pageSize}
                            pageNum={raisePagination.pageNum}
                            onChange={this.paginationChange} />
                        <div className={styles.dealdata} onClick={this._dealHandleData}> <Icon type="right" />选中数据</div>
                    </div>
                    <div className={styles.right}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={(record) => record.goodsId}
                            columns={columnsRight(this.actionClickHandle)}
                            dataSource={dealTransferData}
                            pagination={false}
                            scroll={{ y: 297 }}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}
TableData.propTypes = {
    goodsList: PropTypes.func,//商户活动新建数据
    dataSource: PropTypes.array, //商户活动新建数据
    raisePagination: PropTypes.object, // 商户活动新建数据分页
    dealHandleData: PropTypes.func,//选中的数据回调
    productList: PropTypes.array
};

class GoodsModal extends React.Component {
    state = {
        visible: true,
        confirmLoading: false,
        dealTransferData: [],//提交数据
    }
    componentDidMount() {
        const { goodsList } = this.props;
        goodsList({ pageSize: 10, pageNum: 1 });
    }

    _handleOk = () => {
        const { dealTransferData } = this.state;
        console.log('提交数据', dealTransferData);
        if (dealTransferData.length > 3) {
            message.error('推荐商品设置最多三个！');
            return;
        }
        const goodsIds = [];
        dealTransferData.map(item => {
            goodsIds.push(item.goodsId);
        });
        http('/goods/shopGoods/recoGoodsByShop', { goodsIds, operation: 'ADD' }, 'POST')
            .then(() => {
                message.success('添加推荐商品设置成功。');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }).catch((e) => {
                message.error(e.message);
            });
    }
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }
    //选中的数据回调
    dealHandleData = (data) => {
        this.setState({
            dealTransferData: data,
        });
    }
    render() {
        const { visible, confirmLoading } = this.state;
        const { RaiseTable, RaisePagination, goodsList, productList } = this.props;

        return (
            <Modal
                width={1192}
                centered
                title='添加推荐商品'
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                onOk={this._handleOk}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                okText='确定'
                cancelText='取消'
            >
                <div className={styles.container}>
                    <TableData
                        raisePagination={RaisePagination}
                        goodsList={goodsList}
                        productList={productList}
                        dataSource={RaiseTable}
                        dealHandleData={this.dealHandleData}
                    />
                </div>
            </Modal>
        );
    }
}

GoodsModal.propTypes = {
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    goodsList: PropTypes.func,//商户活动新建数据
    RaiseTable: PropTypes.array, //商户活动新建数据
    RaisePagination: PropTypes.object, // 商户活动新建数据分页
    productList: PropTypes.array
};

const mapStateToProps = (state) => ({
    RaiseTable: state.recommendGoodSet.goodsList.dataList,
    RaisePagination: {
        pageNum: state.recommendGoodSet.goodsList.pageNum,
        pageSize: state.recommendGoodSet.goodsList.pageSize,
        resultTotal: state.recommendGoodSet.goodsList.resultTotal
    },
});

export default connect(mapStateToProps, { ...model.actions })(GoodsModal);

