import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class TableContent extends React.Component {

    state = {
        selectedRowKeys: [], // 选中的单元行
        row: [],
    };

    _takeColumns = () => ([
        {
            title: '供应商订单号',
            dataIndex: 'orderSn',
            key: 'orderSn',
            width: 200,
            align: 'center',
            // fixed: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单状态',
            dataIndex: 'outerState.value',
            key: 'outerState.value',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '响应消息',
            dataIndex: 'orderEssage',
            key: 'orderEssage',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '物流单号',
            dataIndex: 'shippingCode',
            key: 'shippingCode',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '物流公司名称',
            dataIndex: 'logisticsName',
            key: 'logisticsName',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单发货时间',
            dataIndex: 'shippingTime',
            key: 'shippingTime',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '出库单号',
            dataIndex: 'splitSn',
            key: 'splitSn',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单号',
            dataIndex: 'outSn',
            key: 'outSn',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员账号',
            dataIndex: 'accountTel',
            key: 'accountTel',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品数量',
            dataIndex: 'storeNum',
            key: 'storeNum',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '发货仓库',
            dataIndex: 'sourceName',
            key: 'sourceName',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单状态',
            dataIndex: 'orderState.value',
            key: 'orderState.value',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '下单时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 160,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单分配时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 160,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            // width: 140,
            // fixed: 'right',
            render: (text, item) => this._renderActionCell(item.id, item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderImgCell = (url) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <div className={styles.imgbox}>
                    <img className={styles.img} src={url} />
                </div>
            </div>
        );
    };

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderNumberCell = (text) => {
        const price = parseInt(text) / 100;
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {price}
            </div>
        );
    };

    _renderActionCell = (key, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                { record.outerState.value === '已取消' && <div className={styles.item} onClick={() => tableAction(key, 'send')}>重新推送</div>}
                <div className={styles.item} onClick={() => tableAction(key, 'detail')}>查看详情</div>
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            var arr = [];
            var tempArr = [];
            const { row } = this.state;
            selectedRows.map((item) => {
                selectedRowKeys.map(j => {
                    if (item.id === j) {
                        arr.push({id: item.id, code: item.orderState.code});
                    }
                    return j;
                });
                return item;
            });
            const tempData = [...row, ...arr];
            tempData.map(i => {
                selectedRowKeys.map(j => {
                    if (i.id === j) {
                        tempArr.push(i);
                    }
                    return j;
                });
                return i;
            });
            console.log(this.formatConcatArr(tempArr), '122');
            this.props.onRowSelection(selectedRowKeys, this.formatConcatArr(tempArr));
            this.setState({ selectedRowKeys, row: this.formatConcatArr(tempArr) });
        }
    };
    /// 数组去重
    formatConcatArr = (array) => {
        let arr = array;
        let i;
        let obj = {};
        let result = [];
        let len = arr.length;
        for (i = 0; i < len; i++) {
          // 如果能查找到，证明数组元素重复了
          if (!obj[arr[i].id]) {
            obj[arr[i].id] = 1;
            result.push(arr[i]);
          }
        }
        return result;
    }
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: '150%', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    onRowSelection: PropTypes.func, // 复选框事件
};

export default TableContent;