/**
 * 【用户APP界面管理页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Form } from 'antd';
import { getCustomListTableSource } from '@/reducers/marketingmanage/appcustom/actions';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getLocalStorage } from '@/assets/js/storage';
import http from '@/assets/api/http';
import './index.module.scss';

const { Meta } = Card;

const customCategories = [
    {
        category: 'SHOP',
        img: require('@/assets/image/appcustom/home-page.png'),
        title: '首页',
        description: '首页是整个APP的门面，设置完成后商圈主页直接沿用APP商圈首页内容'
    },
    {
        category: 'SUBJECT_MT',
        img: require('@/assets/image/appcustom/subject-page.png'),
        title: '专题活动',
        description: '针对专题活动进行自定义'
    },
];


class Custom extends React.Component {

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
    }

    onClick = async (card) => {

        var formMode = ['SHOP'].includes(card.category) ? 'normal' : card.category.toLowerCase();
        const { userInfo } = JSON.parse(getLocalStorage('auth'));

        switch (card.category) {
            case 'SHOP':
            case 'SUBJECT_MT':
                var result = await http('/custom/appHomePage/getList', { pageSize: 10, pageNum: 1, category: card.category, shopId: userInfo.shopId })
                    .then(res => res || { result: { dataList: [] } })
                    .catch(() => ({ result: { dataList: [] } }));
                var hadData = result.result.dataList.length > 0;

                var target = hadData ? 'customlist' : 'customentrance';
                this.props.addOneBreadcrumbPath({
                    title: card.title + (hadData ? '列表' : ''),
                    path: '/home/businessmanage/storefitting/' + target
                });
                this.props.history.push({
                    pathname: '/home/businessmanage/storefitting/' + target,
                    state: { category: card.category, mode: 'control', formMode: formMode, shopId: userInfo.shopId }
                });
                break;
        }
    }

    render() {

        return (<div style={{ maxWidth: 1080 }}>{
            customCategories.map((card, index) => (
                <Card
                    key={index.toString()}
                    className="appcustom-main-card"
                    hoverable
                    cover={
                        <div style={{ textAlign: 'center' }}>
                            <img style={{ width: 51, height: 51, marginTop: 61 }} src={card.img} />
                        </div>
                    }
                    onClick={() => this.onClick(card)}
                >
                    <Meta title={card.title} description={<div dangerouslySetInnerHTML={{ __html: card.description }}></div>} />
                </Card>
            ))
        }</div>);
    }
}

Custom.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getCustomListTableSource: PropTypes.func,
    form: PropTypes.object
};

const WrappedTimeRelatedForm = Form.create({ name: 'time_related_controls' })(Custom);

export default connect(state => ({
    customListTable: state.appcustom.customListTable
}), { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getCustomListTableSource })(WrappedTimeRelatedForm);