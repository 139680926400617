/**
 * sku信息
 */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './modalStyle.module.scss';
import { Modal, message, Table, InputNumber, Typography, DatePicker } from 'antd';
import { tooltipStyle, tooltip, ImgCell, priceFenToYuanCell } from '@/components/TableCell';
import { XInputNum, XSelect, XOKButton } from '@/components/xqxc_ui';
import { regYuanToFen, regFenToYuan } from '@/util/money';
import { getConfigInfo } from '@/assets/js/authType.js';
import moment from 'moment';

const { Text } = Typography;

const SetSkuInfo = ({ closeMode,
    modalParams: {
        goodsSkuList = [],
        skuInfoList = [],
        goodsName = '',
        goodsId
    } = {}
}) => {

    const [loading, setLoading] = useState(false);
    const [keys, setKeys] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [day, setDay] = useState(0);
    const [limitItem, setLimitItem] = useState({ code: 0, value: '否' });
    const [limitNumber, setLimitNumber] = useState(1);
    const [limit, setLimit] = useState(false);
    const [wholesalePrice, setWholesalePrice] = useState(0.01);
    const [totalQuantity, setTotalQuantity] = useState(1);
    const [typeItem, setTypeItem] = useState({ code: '0', value: '是' });
    const [autoOnTime, setAutoOnTime] = useState(null);

    getConfigInfo()
        .then(res => {
            setLimit(res.isWhiteAudit);
        })
        .catch(err => {
            console.log(err.message);
        });

    useEffect(() => {
        if (skuInfoList.length) {
            setDataList(skuInfoList.map(item => {
                return { ...item, wholesalePrice: 0, totalQuantity: 0 };
            }));
        } else if (goodsSkuList.length) {
            setDataList(goodsSkuList.map(item => {
                return { ...item, wholesalePrice: regFenToYuan(item.wholesalePrice) };
            }));
        }
    }, []);

    const onSubmit = () => {
        if (!keys.length) {
            message.warning('请勾选商品');
            return;
        }
        if (!day) {
            message.warning('请输入上架天数');
            return;
        }
        if (limitItem.code && !limitNumber) {
            message.warning('请设置商品限购数');
            return;
        }
        if (typeItem.code === '1' && !autoOnTime) {
            message.warning('请选择预约上架时间');
            return;
        }
        const skuInfo = dataList.filter(item => keys.includes(item.skuId)).map(item => {
            return { skuId: item.skuId, totalQuantity: item.totalQuantity, wholesalePrice: regYuanToFen(item.wholesalePrice) };
        });
        let data = { day, goodsId, skuInfo, limitNumber: limitItem.code ? limitNumber : 0, isTimer: typeItem.code };
        if (autoOnTime) {
            data.autoOnTime = autoOnTime.format('YYYY-MM-DD HH:mm') + ':00';
        }
        setLoading(true);
        http('/goods/wholesale/addWholesaleGoodsFromGoods', data, 'POST')
            .then(res => {
                message.success(res.result);
                setLoading(false);
                closeMode(true);
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    const renderEditCell = (text, rowValue, labelValue) => {
        return <InputNumber
            key={rowValue.skuId}
            value={text}
            precision={labelValue === 'wholesalePrice' ? 2 : 0}
            min={0}
            onChange={value => {
                setDataList(dataList.map(item => {
                    if (item.skuId === rowValue.skuId) {
                        return { ...item, [labelValue]: value };
                    }
                    return { ...item };
                }));
            }}
        />;
    };

    const columns = () => {
        return [
            {
                title: '商品图片',
                dataIndex: 'imgUrl',
                key: 'imgUrl',
                align: 'center',
                width: '10%',
                render: ImgCell
            }, {
                title: 'SKU编码',
                dataIndex: 'skuNo',
                key: 'skuNo',
                align: 'center',
                width: '16%',
                onCell: tooltipStyle,
                render: tooltip
            }, {
                title: '规格属性',
                dataIndex: 'spec',
                key: 'spec',
                align: 'center',
                width: '16%',
                onCell: tooltipStyle,
                render: tooltip
            }, {
                title: '市场价（元）',
                dataIndex: 'marketPrice',
                key: 'marketPrice',
                align: 'center',
                width: '13%',
                onCell: tooltipStyle,
                render: priceFenToYuanCell
            }, {
                title: '销售价（元）',
                dataIndex: 'salePrice',
                key: 'salePrice',
                align: 'center',
                width: '13%',
                onCell: tooltipStyle,
                render: priceFenToYuanCell
            }, {
                title: '批发价（元）',
                dataIndex: 'wholesalePrice',
                key: 'wholesalePrice',
                align: 'center',
                width: '16%',
                onCell: tooltipStyle,
                render: (text, item) => renderEditCell(text, item, 'wholesalePrice')
            }, {
                title: '批发库存',
                dataIndex: 'totalQuantity',
                key: 'totalQuantity',
                align: 'center',
                width: '16%',
                onCell: tooltipStyle,
                render: (text, item) => renderEditCell(text, item, 'totalQuantity')
            }];
    };

    // 表格复选输入
    const rowSelection = {
        onChange: (selectedRowKeys) => {
            setKeys(selectedRowKeys);
        }
    };

    //批量设置
    const onBatchSet = () => {
        if (!wholesalePrice) {
            message.warning('请输入批发价');
            return;
        }
        if (!totalQuantity) {
            message.warning('请输入批发库存');
            return;
        }
        if (keys.length && keys.length < dataList.length) {
            setDataList(dataList.map(item => {
                if (keys.includes(item.skuId)) {
                    return { ...item, wholesalePrice, totalQuantity };
                }
                return { ...item };
            }));
        } else {
            setDataList(dataList.map(item => {
                return { ...item, wholesalePrice, totalQuantity };
            }));
        }
    };

    return <Modal
        width={1100}
        centered
        title={`"${goodsName}"SKU信息`}
        bodyStyle={{ padding: '20px' }}
        visible={true}
        confirmLoading={loading}
        onOk={onSubmit}
        onCancel={() => closeMode(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div className={styles.container}>
            <div className={styles.searchBox}>
                <XInputNum
                    label='设置上架天数：'
                    style={{ marginRight: '20px' }}
                    placeholder='请输入批上架天数'
                    precision={0}
                    min={0}
                    bindThis={setDay}
                    bindName='day'
                    value={day}
                    isRequired={true}
                />
                <XInputNum
                    label='批发价：'
                    style={{ marginRight: '20px' }}
                    placeholder='请输入限购数量'
                    min={0.01}
                    max={99999.99}
                    precision={2}
                    bindThis={setWholesalePrice}
                    bindName='wholesalePrice'
                    value={wholesalePrice}
                    isRequired={true}
                />
                <XInputNum
                    label='批发库存：'
                    style={{ marginRight: '20px' }}
                    placeholder='请输入限购数量'
                    precision={0}
                    min={1}
                    max={99999}
                    bindThis={setTotalQuantity}
                    bindName='totalQuantity'
                    value={totalQuantity}
                    isRequired={true}
                />
                <XOKButton
                    label='批量设置'
                    onClick={onBatchSet}
                />
            </div>
            <div className={styles.searchBox}>
                {
                    limit && <Fragment>
                        <XSelect
                            label='是否限购：'
                            placeholder='请选择'
                            renderData={[{ code: 0, value: '否' }, { code: 1, value: '是' }]}
                            dataIndex='value'
                            keyIndex='code'
                            onChange={value => setLimitItem(value)}
                            value={limitItem.value}
                            isRequired={true}
                        />
                        {
                            !!limitItem.code &&
                            <Fragment>
                                <XInputNum
                                    placeholder='请输入限购数量'
                                    precision={0}
                                    min={1}
                                    max={100}
                                    bindThis={setLimitNumber}
                                    bindName='limitNumber'
                                    value={limitNumber}
                                />
                                <Text strong style={{ margin: '0 10px' }}>件</Text>
                                <Text type='secondary'>每件商品用户在每个销售渠道的可购买数量，提交后不可修改</Text>
                            </Fragment>
                        }
                        <XSelect
                            label='是否立即上架：'
                            style={{ marginLeft: '20px', marginRight: '10px' }}
                            placeholder='请选择'
                            renderData={[{ code: '0', value: '是' }, { code: '1', value: '否' }]}
                            dataIndex='value'
                            keyIndex='code'
                            onChange={value => setTypeItem(value)}
                            value={typeItem.value}
                            isRequired={true}
                        />
                        {
                            typeItem.code === '1' &&
                            <DatePicker
                                value={autoOnTime}
                                placeholder='请选择时间'
                                allowClear={false}
                                onChange={value => setAutoOnTime(value)}
                                format='YYYY-MM-DD HH:mm'
                                showTime={{ format: 'HH:mm' }}
                                disabledDate={current => {
                                    if (current && current < moment().subtract(1, 'days')) {
                                        return true;
                                    }
                                }}
                                disabledTime={current => {
                                    if (current && current < moment()) {
                                        return true;
                                    }
                                }}
                            />
                        }
                    </Fragment>
                }
            </div>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.skuId}
                rowSelection={rowSelection}
                columns={columns()}
                pagination={false}
                dataSource={dataList}
                scroll={{ y: 400 }}
            />
        </div>
    </Modal>;
};
SetSkuInfo.propTypes = {
    closeMode: PropTypes.func,
    modalParams: PropTypes.object
};
export default SetSkuInfo;