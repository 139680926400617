import * as T from './actionTypes';

/**
 * 奢批物流出仓信息列表
 */
export const getSpDeliverList = (value = {}) => ({
    type: T.GET_SPDELIVER_LIST,
    payload: value
});

/**
 * 奢批物流出仓详细信息
 */
export const getSpDeliverDetail = (value = {}) => ({
    type: T.GET_SPDELIVER_DETAIL,
    payload: value
});
