/**
 * 卡券管理
 */
import React from 'react';
import styles from './index.module.scss';
import { XSelect, XOKButton, XCancelButton, XInput, XDatePicker, XMediaFn } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import ActivityTime from '../../modal/ActivityTime';
import DeleteModal from '../../modal/DeleteModal';
import Linkcopy from '../../modal/Linkcopy';
import PopconfirmModal from '../../modal/PopconfirmModal';
import ActivityDate from '../../modal/ActivityDate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message, } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getcardticketList, getcardticketDropDown, } from '@/reducers/marketingmanage/cardticket/actions';

const Mode = {
    EDITE: 'EDITE',//删除
    DELETE: 'DELETE',//详情
    EDIT: 'EDIT',//编辑
};

class Marketing extends React.Component {

    state = {
        type: {},//卡劵类型
        push: {},//推送平台
        state: {},//卡劵状态
        cardTicket: '',//卡劵名称
        orderStartTime: null, //有效期开始时间
        startPickerDisabled: false, // 有效期开始时间不可选
        orderEndTime: null, // 有效期结束时间
        endPickerDisabled: false, // 有效期结束时间不可选
        currentOpenMode: '', // 当前需要打开的弹窗signTime
        openModeParam: {}, // 当前需要打开的弹窗的入参
        popconParam: {},//启用停用入参
    }


    componentDidMount() {
        const { getcardticketDropDown } = this.props;
        getcardticketDropDown();//分类状态
    }
    // 新建攻略
    _createStudioRoom = () => {
        this.props.updateContent('CREAT');
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, type, state, cardTicket, orderStartTime, orderEndTime } = this.state;
        const { getcardticketList, } = this.props;
        let startTime = orderStartTime != null ? orderStartTime.format('YYYY-MM-DD HH:mm:ss') : null;
        let endTime = orderEndTime != null ? orderEndTime.format('YYYY-MM-DD HH:mm:ss') : null;
        this.searchCache = {
            couponType: type.code,//卡劵类型
            state: state.code,//卡劵状态
            couponName: cardTicket,//卡劵名称
            effectiveTime: startTime, //有效期开始时间
            overdueTime: endTime, // 有效期结束时间
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        getcardticketList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ type: {}, state: {}, cardTicket: '', orderStartTime: null, orderEndTime: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getcardticketList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    // 表格操作  
    _tableAction = (id, type, value) => {
        console.log(id, type, value);
        switch (type) {
            case 'detail':
                this.props.updateContent(Mode.DELETE, id);
                break;
            case 'stateType':// 开启-停用
                this.setState({
                    currentOpenMode: 'stateType',
                    popconParam: value,
                });
                break;
            case 'edit':
                this.props.updateContent(Mode.EDIT, id);
                break;
            case 'link'://链接
                this.setState({
                    currentOpenMode: 'link',
                });
                break;
            case 'data':
                this.setState({
                    currentOpenMode: 'data',
                });
                break;
        }
    }

    // 打开弹窗  
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.DELETE:
                return <DeleteModal title='系统信息' visible={true} closeMode={this._closeMode} />;
            case 'stateType':
                return <PopconfirmModal data={this.state.popconParam} visible={true} closeMode={this._closeMode} />;
            case 'link':
                return <Linkcopy title='链接' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'data':
                return <ActivityDate title={<p>这里是卡券名称<span style={{ marginLeft: '20px' }}>优惠劵</span></p>} visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getcardticketList(this.searchCache);// 列表查询方法
        }
    }

    render() {
        const { type, cardTicket, state, orderStartTime, startPickerDisabled, orderEndTime,
            endPickerDisabled, } = this.state;
        const { tableSource, paginations, selectData, } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <XInput
                            style={{ width: '216px' }}
                            inputStyle={{ width: '150px' }}
                            label='卡券名称'
                            placeholder='请输入卡券名称'
                            value={cardTicket}
                            bindThis={this}
                            bindName='cardTicket'
                        />
                        <XSelect
                            style={{ marginLeft: '30px', width: '216px' }}
                            selectStyle={{ width: '150px' }}
                            label='卡劵类型'
                            placeholder='请选择卡劵类型'
                            renderData={selectData.result ? selectData.result : []}
                            dataIndex='name'
                            keyIndex='code'
                            value={type.name}
                            bindThis={this}
                            bindName='type'
                        />
                        <XSelect
                            style={{ marginLeft: '30px', width: '216px' }}
                            selectStyle={{ width: '150px' }}
                            label='领取状态'
                            placeholder='请选择领取状态'
                            renderData={selectData.couponState ? selectData.couponState : []}
                            dataIndex='name'
                            keyIndex='code'
                            value={state.name}
                            bindThis={this}
                            bindName='state'
                        />
                        <XDatePicker
                            style={{ marginLeft: '30px', marginRight: '15px' }}
                            label='有效期'
                            value={orderStartTime}
                            disabled={startPickerDisabled}
                            bindThis={this}
                            bindName='orderStartTime'
                        />
                        <span>—</span>
                        <XDatePicker
                            style={{ marginLeft: '5px' }}
                            value={orderEndTime}
                            disabled={endPickerDisabled}
                            bindThis={this}
                            bindName='orderEndTime'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '140px' }} label='新建卡劵' onClick={this._createStudioRoom} />
                    </div>
                    <TableContent renderData={tableSource} tableAction={this._tableAction} pagiNation={paginations} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Marketing.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    updateContent: PropTypes.func, // 路由跳转
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getcardticketDropDown: PropTypes.func,// 通过Saga异步请求下拉选项数据方法Action
    tableSource: PropTypes.array, // 表格数据源
    selectData: PropTypes.object, // 分类状态下拉选项
    paginations: PropTypes.object, // 分页数据
    getcardticketList: PropTypes.func, // 通过Saga异步请求表格数据源的Action
};

const mapStateToProps = (state) => {
    return {
        tableSource: state.cardticket.cardticketDate.tableSource,
        selectData: state.cardticket.cardticketDate.selectData,
        paginations: state.cardticket.cardticketDate.paginations,
    };
};
export default connect(mapStateToProps, { getcardticketList, getcardticketDropDown })(Marketing);
