/**
 * 商品品牌下拉搜索框（枚举类型）
 */
import React from 'react';
import PropTypes from 'prop-types';
import { XSelect } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

class GoodsBrand extends React.Component {

    state = {
        renderData: [], // 数据源
        brandName: '',
        pageSize: 30
    }

    _requestList = () => {
        const { brandName, pageSize } = this.state;
        const { isApply = 0 } = this.props;
        http('/goods/brand/listBrandItemPage', { brandName, isApply, pageNum: 1, pageSize }, 'POST')
            .then((response) => {
                const { status, result: { dataList = [], resultTotal = 0 } = {} } = response;
                if (status == 200) {
                    this.setState({ renderData: dataList });
                } else {
                    console.log('/goods/brand/listBrandItemPage请求失败');
                }
            }).catch(() => {
                console.log('/goods/brand/listBrandItemPage请求失败');
            });
    }

    componentDidMount() {
        this._requestList();
    }

    _onChange = (value) => {
        const { bindThis, bindName } = this.props;

        if (bindThis && bindName) {
            this.setState({ brandName: bindThis[bindName] });
            bindThis.setState({ [bindName]: value });
        }

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    _popupScroll = ({ target: { scrollTop = 0, offsetHeight = 0, scrollHeight = 0 } = {} }) => {
        if (scrollTop + offsetHeight === scrollHeight) {
            this.setState({ pageSize: this.state.pageSize + 30 }, () => {
                this._requestList();
            });
        }
    }

    _search = (value) => {
        this.setState({ brandName: value, pageSize: 30 }, () => {
            this._requestList();
        });
    }

    render() {
        const { renderData } = this.state;
        const { style, selectStyle, label, placeholder, isRequired, value } = this.props;

        return (
            <XSelect
                style={style}
                selectStyle={selectStyle}
                label={label}
                placeholder={placeholder}
                renderData={renderData}
                dataIndex='brandName'
                keyIndex='id'
                value={value.brandName}
                onChange={this._onChange}
                isRequired={isRequired}
                showSearch={true}
                onPopupScroll={this._popupScroll}
                onSearch={this._search}
            />
        );
    }
}

GoodsBrand.defaultProps = {
    style: { width: '218px' },
    selectStyle: { width: '150px' },
    isApply: 0,
};

GoodsBrand.propTypes = {
    bindThis: PropTypes.object, // 父级this
    bindName: PropTypes.string, // 双向绑定的属性名称
    value: PropTypes.object,
    style: PropTypes.object,
    selectStyle: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    defaultValue: PropTypes.object,
    isApply: PropTypes.number, // 1查询全部平台品牌 0查询自己商户品牌
};

export default GoodsBrand;