import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber } from 'antd';
import styles from './rangeNumber.module.scss';

export class RangeNumber extends React.Component {

    onChange = (index, value) => {
        const newValue = [...(this.props.value || [undefined, undefined])];
        newValue[index] = parseFloat(value).toFixed(2);
        newValue[index] === 'NaN' && (newValue[index] = undefined);
        this.props.onChange && this.props.onChange(newValue);
    }

    onBlur = () => {
        setTimeout(() => {
            const newValue = [...(this.props.value || [undefined, undefined])];
            if (newValue[0] !== '' && newValue[0] !== undefined && newValue[1] !== '' && newValue[1] !== undefined) {
                let f = parseFloat(newValue[0]),
                    s = parseFloat(newValue[1]);
                if (f >= s) {
                    newValue[0] = s - 0.01;
                    this.props.onChange && this.props.onChange(newValue);
                }
            }
        }, 200);
    }

    render() {
        const values = this.props.value || [undefined, undefined];
        return (
            <div className={styles.wrap}>
                <InputNumber readOnly={this.props.readOnly} value={values[0]} min={0} onBlur={this.onBlur} className={styles.number} placeholder='起始' onChange={(value) => this.onChange(0, value)} />
                <span className={styles.split}> - </span>
                <InputNumber readOnly={this.props.readOnly} value={values[1]} min={0.01} onBlur={this.onBlur} className={styles.number} placeholder='结束' onChange={(value) => this.onChange(1, value)} />
            </div>
        );
    }
}

RangeNumber.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool
};