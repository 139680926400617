import { createModel } from '@/store/tools';
const model = {
    namespace: 'recommendGoodSet',
    build: (T, A, S, R) => ({
        actiontypes: {
            'recommendGoodSet': T('recommendGoodSet'),//获取推荐商品设置列表
            'goodsList': T('goodsList')//获取商品列表
        },
        actions: {
            'recommendGoodSet': A('recommendGoodSet'), // 获取推荐商品设置列表
            'goodsList': A('goodsList')//获取商品列表
        },
        sagas: {
            'recommendGoodSet': S('recommendGoodSet', '/goods/shopGoods/findShopGoodsList'),//获取推荐商品设置列表
            'goodsList': S('goodsList', '/goods/shopGoods/findShopGoodsList')//商品列表
        },
        reducers: {
            'recommendGoodSet': R('recommendGoodSet', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'goodsList': R('goodsList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 })
        }
    })
};
export default createModel(model);