/**
 * 售后管理
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as SALE from './actionTypes';

// 售后列表
export const getSaleList = function* () {
    yield takeEvery(SALE.GET_SALELIST_LIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/getAfterSaleOrderPage', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SALE.SET_SALELIST_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 售后详情仅退款
export const getSaleOnlyMoneyDetail = function* () {
    yield takeEvery(SALE.GET_SALELIST_DETAIL_ONLYMONEY, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/getAfterSaleOrderDetailsOnlyMoney', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SALE.SET_SALELIST_DETAIL_ONLYMONEY, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 售后详情退货退款
export const getSaleGoodsandmoneyDetail = function* () {
    yield takeEvery(SALE.GET_SALELIST_DETAIL_GOODSMONEY, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/getAfterSaleOrderDetailsreturnGoodsandmoney', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SALE.SET_SALELIST_DETAIL_GOODSMONEY, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 售后详情换货
export const getSaleChangeGoodsDetail = function* () {
    yield takeEvery(SALE.GET_SALELIST_DETAIL_CHANGEGOODS, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/getAfterSaleOrderDetailChange', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SALE.SET_SALELIST_DETAIL_CHANGEGOODS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 受理详情
export const getProcessedDetail = function* () {
    yield takeEvery(SALE.GET_PROCESS_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/processDetail', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SALE.SET_PROCESS_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 售后状态
export const getSaleStatus = function* () {
    yield takeEvery(SALE.GET_SALESTATUS, function* requestData(action) {
        try {
            let result = yield http('/enum/SalesProssStatusEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SALE.SET_SALESTATUS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 售后类型
export const getSaleType = function* () {
    yield takeEvery(SALE.GET_SALETYPE, function* requestData(action) {
        try {
            let result = yield http('/enum/SalesAfterTypeEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SALE.SET_SALETYPE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 仓库售后地址
export const getSaleAddressList = function* () {
    yield takeEvery(SALE.GET_SALE_ADRESS_LIST, function* requestData(action) {
        try {
            let result;
            if (action.payload.warehouseIdList.length === 0) {
                result = yield http('/after/sales/listMerchantDetails', {}, 'POST'); // action.payload为传入的参数对象
            } else {
                result = yield http('/after/sales/detailsList', action.payload, 'POST'); // action.payload为传入的参数对象
            }
            yield put({ type: SALE.SET_SALE_ADRESS_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 仓库售后地址详情
export const getSaleAddressDetail = function* () {
    yield takeEvery(SALE.GET_SALE_ADRESS_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/merchant/after/info', action.payload, 'GET'); // action.payload为传入的参数对象
            yield put({ type: SALE.SET_SALE_ADRESS_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 是否批发
export const getDistributionFlagEnum = function* () {
    yield takeEvery(SALE.GET_SALEPIFA_FLAGENUM, function* requestData(action) {
        try {
            let result = yield http('/enum/DistributionFlagEnum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: SALE.SET_SALEPIFA_FLAGENUM, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

