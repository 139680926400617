import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Form, Button, message, Icon } from 'antd';
import CustomUpload, { generateFileList } from '@/components/NewUpload';

class Main extends React.Component {

    state = {
        loading: false, // 加载中
        required: true,
        template: '', // 委托书模板
        imgUrl: [], // 委托书
    }

    componentDidMount() {
        http('/walletUnion/getStandingOrderTemplate', {}, 'GET').then((response) => {
            if (response.status == 200) {
                this.setState({ template: response.result });
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _handleSubmit = e => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const query = {};
                query.imgUrl = fieldsValue.imgUrl[0].url; // 委托书
                http('/walletUnion/submitMerchantTaxCommission', query).then((response) => {
                    if (response.status == 200) {
                        message.success('提交成功');
                        this.props.update();
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
            }
        });
    }

    // 上传图片校验-返回值
    _normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    // 上传图片校验-提示
    beforeUpload = (file, sizeNum = 1) => {
        const isType = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
        const isLt5M = file.size / 1024 / 1024 < sizeNum;
        if (!isType.includes(file.type)) {
            message.error('只能上传png、jpg、gif格式的图片');
            file.status = 'error';
            return false;
        } else if (!isLt5M) {
            message.error('图片必须小于1MB');
            file.status = 'error';
            return false;
        } else {
            return isType && isLt5M;
        }
    }

    render() {
        const { loading, required, imgUrl, template } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <div className={styles.container}>
                <Form autoComplete='off' onSubmit={this._handleSubmit}>
                    <Form.Item
                        colon={false}
                        htmlFor='imgUrl'
                        label={<span>委托书：<a onClick={() => { window.open(template); }}>委托书模板下载</a></span>}
                    >
                        <div>
                            {getFieldDecorator('imgUrl', {
                                initialValue: imgUrl,
                                valuePropName: 'fileList',
                                getValueFromEvent: this._normFile,
                                rules: [
                                    { type: 'array', required, message: '请上传委托书' },
                                ],
                            })(
                                <CustomUpload
                                    accept='.png, .jpg, .jpeg'
                                    listType="picture-card"
                                    wallMode="single"
                                    removeError
                                    qiniuUploadProps={{
                                        uploadType: 'image'
                                    }}
                                    onChange={(info) => {
                                        const imgUrl = info.fileList;
                                        imgUrl.length == '0' && this.props.form.resetFields('imgUrl', []);
                                        this.setState({ imgUrl });
                                    }}
                                    beforeUpload={(file) => this.beforeUpload(file, 1)}
                                >
                                    <div>
                                        <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                        <div style={{ color: '#999999', fontSize: '12px' }}>添加上传图片</div>
                                        <div style={{ color: '#999999', fontSize: '12px' }}>委托书</div>
                                    </div>
                                </CustomUpload>
                            )}
                            <span className={styles.oneline}>每次仅支持上传一张图片，大小不超过1M</span>
                        </div>
                    </Form.Item>
                    <Button style={{ width: '150px' }} type="primary" htmlType="submit" loading={loading}>提交</Button>
                </Form>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    form: PropTypes.object, // 表单校验
    update: PropTypes.func, 
};

const stepForm = Form.create({ name: 'step-form' })(Main);
export default stepForm;