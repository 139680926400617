/**
 * 新建菜单
 */
import React from 'react';
import { Modal, Input, Form, Select, message } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './CreateModal.module.scss';
import http from '@/assets/api/http';
import { getMenuSelect, getMenuDetail } from '@/reducers/permissionsmanage/menuconfigure/actions';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};
let sortOption = [];
for (var i = 1; i < 11; i++) {
    sortOption.push(i);
}
const { Option } = Select;

class CreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
        };
    }

    componentDidMount() {
        const { getMenuSelect, getMenuDetail, systemType, id } = this.props;
        getMenuDetail({ id });
        getMenuSelect({ systemType });
    }

    // 确认
    _handleOk = () => {
        const { id, systemType } = this.props;
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const data = { ...fieldsValue, menuType: 1, systemType, id };
                console.log('提交', data);

                http('/admin/menu/updateMenu', data, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('编辑成功！');
                        this.setState({
                            visible: false,
                        });
                        this.props.closeMode(true);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
            }
        });
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // 渲染父级菜单下拉列
    _renderSelect = (list) => {
        return list.map((item) => {
            return <Option key={item.id} value={item.id}>{item.menuName}</Option>;
        });
    }

    render() {
        const { visible, confirmLoading } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { menuSelect, menuDetail } = this.props;
        const { parentId, menuName, menuUrl, menuCode } = menuDetail;

        return (
            <Modal
                width={570}
                centered
                title='新建菜单'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon={false}
                            htmlFor='parentId'
                            label={<span>父级菜单</span>}
                        >
                            {getFieldDecorator('parentId', {
                                initialValue: parentId
                            })(<Select placeholder='请选择父级菜单，默认创建一级菜单' style={{ width: 320 }} disabled={parentId == 0 ? true : false} >
                                {this._renderSelect(menuSelect)}
                            </Select>)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='menuName'
                            label={<span>菜单名称</span>}
                        >
                            {getFieldDecorator('menuName', {
                                rules: [{ required: true, message: '请输入菜单名称' }],
                                initialValue: menuName
                            })(<Input placeholder='请输入菜单名称' style={{ width: 320 }} />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='menuUrl'
                            label={<span>URL</span>}
                        >
                            {getFieldDecorator('menuUrl', {
                                rules: [{ required: true, message: '请输入URL地址' }],
                                initialValue: menuUrl
                            })(<Input placeholder='请输入URL地址' style={{ width: 320 }} />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='menuCode'
                            label={<span>编号</span>}
                        >
                            {getFieldDecorator('menuCode', {
                                rules: [{ required: true, message: '请输入菜单编号' }],
                                initialValue: menuCode
                            })(<Input placeholder='请输入菜单编号' style={{ width: 320 }} />)}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );

    }
}

CreateModal.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]), // 菜单ID
    systemType: PropTypes.string, // 弹框入参
    form: PropTypes.object.isRequired, // 表单验证
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getMenuSelect: PropTypes.func, // 获取一级菜单下拉列
    menuSelect: PropTypes.array, // 一级菜单下拉列
    getMenuDetail: PropTypes.func, // 获取菜单详情
    menuDetail: PropTypes.object, // 菜单详情
};

const ForgotProducDetails = Form.create({ name: 'create-form' })(CreateModal); // 校验
const mapStateToProps = (state) => ({
    menuSelect: state.menuconfigure.getMenuSelect,
    menuDetail: state.menuconfigure.getMenuDetail,
});

export default connect(mapStateToProps, { getMenuSelect, getMenuDetail })(ForgotProducDetails);
