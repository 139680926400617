import * as T from './actiontypes';

/**
 * 列表初始化格式
 */
const listInitail = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

/**
 * 获取集采收益待打款列表
 */
export const readyPayList = (state = { ...listInitail, dataList: [] }, action) => {
    switch (action.type) {
        case T.SET_READY_PAY_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取集采收益已打款列表
 */
export const alreadyPayList = (state = { ...listInitail, dataList: [] }, action) => {
    switch (action.type) {
        case T.SET_ALREADY_PAY_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取集采收益待打款详情
 */
export const payDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_PAY_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取通道枚举
 */
export const payChannelEnum = (state = [], action) => {
    switch (action.type) {
        case T.SET_PAY_CHANNEL_ENUM:
            return action.payload.result;
        default:
            return state;
    }
};