/**
 * 【仓库列表】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XSelect, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import http from '@/assets/api/http';
import AdvertTable from './components/AdvertTable';
import DeleteModal from './modal/DeleteModal';
import SendInvoice from './modal/SendInvoice';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

import { getInvoiceManageTableSource } from '@/reducers/storehousefinancialmanage/invoicemanage/actions';


class Main extends React.Component {

    state = {
        units: [],
        status: {},
        settlementId: undefined,
        startDate: undefined,
        endDate: undefined
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getInvoiceManageTableSource({ pageNum: 1, pageSize: 15 });
        http('/merchant/supplier/invoicelUnitList').then((res = {}) => {
            this.setState({ units: res.result || [] });
        });
    }
    // 查询
    _searchHandle = (useCache) => {
        const { settlementId, status, startDate, endDate, pageNum, pageSize } = this.state;
        this.searchCache = {
            startDate: startDate ? startDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
            endDate: endDate ? endDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
            targetId: status.id,
            settlementId: settlementId,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getInvoiceManageTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        }, () => {
            this._searchHandle('useCache');
        });
    }

    // 重置
    _resetHandle = () => {
        this.setState({ settlementId: undefined, status: {}, startDate: undefined, endDate: undefined, pageNum: 1, pageSize: 10 }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    _newHandle = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建发票',
            path: '/home/storehousefinancialmanage/invoicemanage/createinvoice'
        });
        history.push({
            pathname: '/home/storehousefinancialmanage/invoicemanage/createinvoice'
        });
    };
    // 表格操作
    _tableAction = (id, type, provider) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'edit':
                addOneBreadcrumbPath({
                    title: '编辑发票',
                    path: '/home/storehousefinancialmanage/invoicemanage/createinvoice/' + id
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/invoicemanage/createinvoice/' + id
                });
                break;
            case 'detail':

                addOneBreadcrumbPath({
                    title: '发票详情',
                    path: '/home/storehousefinancialmanage/invoicemanage/createinvoice/' + id
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/invoicemanage/createinvoice/' + id
                });
                break;
            case 'delete':
                this.setState({ currentOpenMode: 'delete', openModeParam: id });
                break;
            case 'send':
                this.setState({ currentOpenMode: 'send', openModeParam: id });
                break;

        }
    }
    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'delete':
                return <DeleteModal title='删除发票' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'send':
                return <SendInvoice title='寄送发票' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' });
    }
    render() {
        const { units, settlementId, status, startDate, endDate } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.row1}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='开票单号'
                            placeholder='请输入'
                            value={settlementId}
                            bindThis={this}
                            bindName='settlementId'
                            mode='number'
                            maxLength={16}
                            showLength={false}
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '200px' }}
                            label='单位'
                            showSearch
                            placeholder='请选择单位'
                            renderData={units}
                            dataIndex='optionName'
                            keyIndex='id'
                            value={status.optionName}
                            bindThis={this}
                            bindName='status'
                        />
                        <XDatePicker
                            style={{ marginLeft: '20px', marginRight: '10px' }}
                            label='对账周期'
                            value={startDate}
                            bindThis={this}
                            bindName='startDate'
                        />
                        —
                    <XDatePicker
                            value={endDate}
                            bindThis={this}
                            bindName='endDate'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    {/* <div className={styles.row2}>
                    <XOKButton
                        style={{ width: '90px', marginRight: 15 }}
                        label='新建'
                        onClick={this._newHandle}
                    />
                    <XOKButton
                        style={{ width: '90px' }}
                        label='导出Excel'
                        onClick={this._newHandle}
                    />
                </div> */}
                    <AdvertTable renderData={this.props.invoiceManageTableSource} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航

    invoiceManageTableSource: PropTypes.object,
    getInvoiceManageTableSource: PropTypes.func
};

const mapStateToProps = (state) => ({
    invoiceManageTableSource: state.invoicemanage.invoiceManageTableSource
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getInvoiceManageTableSource })(Main);