import { createModel } from '@/store/tools';

const model = {
    namespace: 'storeActivity',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getShopActivityList': T('getShopActivityList'),
            'getActivityState': T('getActivityState'),
            'getFreightDetail': T('getFreightDetail'),
            'getShopReduceDetail': T('getShopReduceDetail'),
        },
        actions: {
            'getShopActivityList': A('getShopActivityList'), // 商户店铺活动列表
            'getActivityState': A('getActivityState'), // 店铺活动状态

            'getShopReduceDetail': A('getShopReduceDetail'), // 店铺活动-获取满减详情数据 
            'getCleanShopReduceDetail': C('getShopReduceDetail'), //店铺活动-获取满减-清除详情数据
            'getFreightDetail': A('getFreightDetail'), // 店铺活动-获取立减运费详情数据  
            'getCleanFreightDetail': C('getFreightDetail'), // 店铺活动-获取立减运费清除详情数据
        },
        sagas: {
            'getShopActivityList': S('getShopActivityList', '/citywide/activityList'),
            'getActivityState': S('getActivityState', '/enum/ListShopActivityStateEnum'),
            'getFreightDetail': S('getFreightDetail', '/citywide/freightDiscountDetail'),
            'getShopReduceDetail': S('getShopReduceDetail', '/citywide/instantRebateDetail'),
        },
        reducers: {
            'shopList': R('getShopActivityList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'activityState': R('getActivityState', []),
            'freightDetail': R('getFreightDetail', {}),
            'shopReduceDetail': R('getShopReduceDetail', {}),
        }
    })
};
export default createModel(model);