import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';


/**
 * 当saga写法需要自定义实现时，请使用依赖注入，把第二个参数变成具体的saga创建实现函数
 */
const getGoodsNumber = (T) => createSaga(T('getGoodsNumber'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/goods/fastGoods/getGoodsNumber', { ...action.payload }, 'POST');
                let timeLabelEnum = result.result.map(item => {
                    if (!item.goodsState) {
                        return { ...item, active: true };
                    }
                    return { ...item, active: false };
                });
                yield put({ type: T('originalData'), payload: timeLabelEnum });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

/**
 * 当reducer写法需要自定义实现时，请使用依赖注入，把第一个参数变成具体的reducer创建实现函数
 */
const goodsNumber = (T) => createReducer((autostate) => {
    return (state = autostate, { type, payload }) => {
        switch (type) {
            case T('originalData'):
                return [...payload];
            case T('goodsNumberChange/GET'):
                return [...state.map(item => {
                    if (payload === item.goodsState) {
                        return { ...item, active: true };
                    }
                    return { ...item, active: false };
                })];
            case T('resetGoodsNumber/GET'):
                return [...state.map(item => {
                    if (!item.goodsState) {
                        return { ...item, active: true };
                    }
                    return { ...item, active: false };
                })];
            default:
                return state;
        }
    };
}, []);

const model = {
    namespace: 'anchorgoods',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getAnchorGoodsList': T('getAnchorGoodsList'),
            'getGoodsNumber': T('getGoodsNumber'),
            'goodsNumberChange': T('goodsNumberChange'),
            'resetGoodsNumber': T('resetGoodsNumber'),
            'getAnchorGoodsInfo': T('getAnchorGoodsInfo'),
        },
        actions: {
            'getAnchorGoodsList': A('getAnchorGoodsList'),
            'getGoodsNumber': A('getGoodsNumber'),
            'goodsNumberChange': A('goodsNumberChange'),
            'resetGoodsNumber': A('resetGoodsNumber'),
            'getAnchorGoodsInfo': A('getAnchorGoodsInfo'),
        },
        sagas: {
            'getAnchorGoodsList': S('getAnchorGoodsList', '/goods/fastGoods/findFastGoodsPageResp'),
            'getGoodsNumber': getGoodsNumber(T),
            'getAnchorGoodsInfo': S('getAnchorGoodsInfo', '/goods/fastGoods/infoFastGoods', 'POST', 'id'),
        },
        reducers: {
            'anchorGoodsList': R('getAnchorGoodsList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'goodsNumber': goodsNumber(T),
            'anchorGoodsInfo': R('getAnchorGoodsInfo', { goodsState: {}, goodsImagesList: [], goodsLongImagesList: [], goodsVideoList: [], skuInfoList: [] }),
        }
    })
};
export default createModel(model);