/**
 * 银联注册弹框
 */
import React from 'react';
import PropTypes from 'prop-types';
import Add from './page/add';
import Apply from './page/apply';
import Success from './page/success';
import Fail from './page/fail';
import { XTitle } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { message } from 'antd';

class TaxCertificate extends React.Component {

    state = {
        curStep: 0, // 当前指引步骤数
    }

    componentDidMount() {
        this._update();
    }

    _update = () => {
        http('/walletUnion/getTaxCommission').then((response) => {
            if (response.status == 200) {
                const { taxCommissionStatus } = response.result;
                if (taxCommissionStatus == '') {
                    this.setState({ curStep: 1 }); // 未开通
                } else if (taxCommissionStatus && taxCommissionStatus.code == 'APPLY') {
                    this.setState({ curStep: 2 }); // 申请中
                } else if (taxCommissionStatus && taxCommissionStatus.code == 'APPROVAL') {
                    this.setState({ curStep: 3 }); // 申请成功
                } else if (taxCommissionStatus && taxCommissionStatus.code == 'REJECT') {
                    this.setState({ curStep: 4 }); // 申请失败
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _renderStepContent = () => {
        const { curStep } = this.state;
        switch (curStep) {
            case 1:
                return <Add update={this._update} />;
            case 2:
                return <Apply update={this._update} />;
            case 3:
                return <Success update={this._update} />;
            case 4:
                return <Fail update={this._update} />;
        }
    }

    render() {
        return (
            <div>
                <XTitle label='临时税务登记委托书' />
                <br />
                {this._renderStepContent()}
            </div>
        );
    }
}

TaxCertificate.propTypes = {
};

export default TaxCertificate;

