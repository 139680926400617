import * as T from './actiontypes';

//商品对接列表
export const getGoodsConnectList = (value = {}) => ({
    type: T.GET_GOODSCONNECT_LIST,
    payload: value
});

//商品对接导入状态
export const getGoodsConnectImport = () => ({
    type: T.GET_GOODSCONNECT_IMPORT,
    payload: {}
});

//商品对接商品状态
export const getGoodsConnectGoodsState = () => ({
    type: T.GET_GOODSCONNECT_GOODSSTATE,
    payload: {}
});

//商品对接详情
export const getGoodsConnectDetail = (outerGoodsId) => ({
    type: T.GET_GOODSCONNECT_DETAIL,
    payload: { outerGoodsId }
});