import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { message } from 'antd';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import PopconfirmModal from '../../modal/PopconfirmModal';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/marketingmanage/storeActivity/model';
import promise from 'redux-promise';

class MyshopActivity extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: [], // 当前需要打开的弹窗的入参
        activityName: '',//活动名称
        type: {},//状态
        rowKeys: [],//选中的keys
        openLoading: false,
    }

    componentDidMount() {
        this.props.getActivityState();
    }

    // 查询
    _searchHandle = (useCache) => {
        this.setState({ isCheck: true });
        const { pageNum, pageSize, activityName, type, } = this.state;
        this.searchCache = {
            activityName: activityName,//活动名称  
            activityState: type.code,//状态  
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getShopActivityList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ activityName: '', type: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //生成报表请求
    validCreate = (id, activityName) => {
        return new Promise((resolve, reject) => {
            let dataParam = {
                purpose: '店铺活动报表',
                templateId: id,
                queryCriteriaList: [
                    {
                        apiUrl: '',
                        condition: '活动名称',
                        conditionFiled: 'activityName',
                        required: false,
                        type: 'TEXTBOX',
                        useGoal: '查询',
                        value: activityName,
                    }
                ],
            };
            http('/report/record/addRecord', dataParam, 'POST').then(() => {
                id === 42 ? resolve('店铺活动商品明细') : resolve('店铺活动汇总明细');
            }).catch((e = {}) => {
                reject(e.message);
            });
        });
    }

    //生成报表
    _openCreateModal = () => {
        const { activityName } = this.state;
        if (activityName == '') return message.warning('请先输入活动名称');
        this.setState({ openLoading: true }, () => {
            Promise.all([this.validCreate(42, activityName), this.validCreate(41, activityName)]).then((res) => {
                if (Array.isArray(res)) {
                    message.success(`${res[0]}，${res[1]}报表生成成功`);
                }
                this.setState({ openLoading: false });
            }).catch((err) => {
                this.setState({ openLoading: false });
            });
        });

    }

    // 表格操作
    _tableAction = (item, type, params) => {
        const { getShopActivityList, updateContent } = this.props;
        switch (type) {
            case 'view'://查看
                if ((params.activityType || {}).code == 'FREIGHT_DISCOUNT') {//立减运费详情
                    updateContent('freightDetail', params.id);
                } else if ((params.activityType || {}).code == 'INSTANT_REBATE') {//店铺满减详情
                    updateContent('shopReduceDetail', params.id);
                }
                break;
            case 'extending'://延长30天、90天
                this.setState({
                    currentOpenMode: 'extending',
                    openModeParam: { extendkey: item, timeExtend: params },
                });
                break;
            case 'use'://可用
                http('/citywide/updateShopActivityState', {
                    id: item.id,
                    state: (params === false) ? 0 : 1,
                }, 'POST').then(() => {
                    message.success('修改成功');
                    getShopActivityList(this.searchCache);// 重新渲染更新列表
                }).catch((e = {}) => {
                    message.error(e.message);
                });
                break;
        }
    }


    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'extending':
                return <PopconfirmModal visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getShopActivityList(this.searchCache);// 创建后重新渲染更新列表
        }
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getShopActivityList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    //选中的keys
    rowKeys = (data) => {
        this.setState({
            rowKeys: data
        });
    }


    render() {
        const { activityName, type, openLoading } = this.state;
        const { shopList, activityState } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <XInput
                            inputStyle={{ width: '150px' }}
                            label='活动名称'
                            placeholder='请输入'
                            value={activityName}
                            bindThis={this}
                            bindName='activityName'
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '150px' }}
                            label='状态'
                            placeholder='请选择'
                            renderData={activityState}
                            dataIndex='value'
                            keyIndex='code'
                            showSearch
                            value={type.value}
                            bindThis={this}
                            bindName='type'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    {/* <div className={styles.operate_box}>
                        <XOKButton loading={openLoading} style={{ minWidth: '90px' }} label='生成报表' onClick={this._openCreateModal} />
                    </div> */}
                    <TableContent
                        paginationChange={this._paginationChange}
                        renderData={shopList}
                        tableAction={this._tableAction}
                        rowKeys={this.rowKeys}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }

}


MyshopActivity.propTypes = {
    getShopActivityList: PropTypes.func, // 商户店铺活动列表Action
    shopList: PropTypes.object, // 商户店铺活动列表
    updateContent: PropTypes.func, // 跳转其它路由页面

    getActivityState: PropTypes.func, // 店铺活动状态Action
    activityState: PropTypes.array, // 店铺活动状态
};


const mapStateToProps = (state) => ({
    shopList: state.storeActivity.shopList,
    activityState: state.storeActivity.activityState,
});

export default connect(mapStateToProps, model.actions)(MyshopActivity);