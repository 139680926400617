import React from 'react';
import styles from './OrderTable.module.scss';
import { XPagination, XTableHOC } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';
import { isPlat } from '@/assets/js/authType';
import ButtonPerssion from '@/routes/ButtonPerssion';

class OrderTable extends React.Component {
    _takeColumns = () => ([
        {
            title: '订单编号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: '9%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员手机',
            dataIndex: 'accountTel',
            key: 'accountTel',
            width: '9%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单金额(元)',
            dataIndex: 'payFee',
            key: 'payFee',
            width: '9%',
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '赠送信用值(粒)',
            dataIndex: 'xiumiNumber',
            key: 'xiumiNumber',
            width: '9%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: '9%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否开票',
            dataIndex: 'isInvoice',
            key: 'isInvoice',
            width: '9%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否超时',
            dataIndex: 'isAppointmentTimeOut',
            key: 'isAppointmentTimeOut',
            width: '9%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '支付方式',
            dataIndex: 'payType',
            key: 'payType',
            width: '9%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '付款时间',
            dataIndex: 'payTime',
            key: 'payTime',
            width: '9%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '预计送达时间',
            dataIndex: 'appointmentTime',
            key: 'appointmentTime',
            width: '9%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '实际送达时间',
            dataIndex: 'riderDeliveredTime',
            key: 'riderDeliveredTime',
            width: '9%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'orderId',
            key: 'id',
            align: 'center',
            width: 260,
            render: (text, record) => this._renderActionCell(text, record)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderActionCell = (key, record) => {
        const { tableAction } = this.props;
        // ****注意
        // 待付款：查看详情 操作历史 取消
        // 已付款（待发货）：查看详情 操作历史 发货 取消
        // 部分发货：查看详情 操作历史 查看物流 取消
        // 已发货(待收货)：查看详情 操作历史 查看物流 取消
        // 已收货：查看详情 操作历史 查看物流 取消
        // 已完成：查看详情 操作历史 查看物流
        // 已取消：查看详情 操作历史
        switch (record.status) {
            case '待付款':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
                        {/* <div className={styles.item} onClick={() => tableAction(key, 'business')}>联系商户</div> */}
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>
                    </div>
                );
            case '已付款':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
                        {/* <div className={styles.item} onClick={() => tableAction(key, 'business')}>联系商户</div> */}
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'copy', record)}>复制地址</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>

                    </div>
                );
            case '部分发货':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
                        {/* <div className={styles.item} onClick={() => tableAction(key, 'logistics', record)}>查看物流</div> */}
                        {/* <div className={styles.item} onClick={() => tableAction(key, 'business')}>联系商户</div> */}
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'copy', record)}>复制地址</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>

                    </div>
                );
            case '已发货':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
                        {/* <div className={styles.item} onClick={() => tableAction(key, 'logistics', record)}>查看物流</div> */}
                        {/* <div className={styles.item} onClick={() => tableAction(key, 'business')}>联系商户</div> */}
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'copy', record)}>复制地址</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>

                    </div>
                );
            case '已收货':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
                        {/* <div className={styles.item} onClick={() => tableAction(key, 'logistics', record)}>查看物流</div> */}
                        {/* <div className={styles.item} onClick={() => tableAction(key, 'business')}>联系商户</div> */}
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'copy', record)}>复制地址</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>

                    </div>
                );
            case '已完成':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
                        {/* <div className={styles.item} onClick={() => tableAction(key, 'logistics', record)}>查看物流</div> */}
                        {/* <div className={styles.item} onClick={() => tableAction(key, 'business')}>联系商户</div> */}
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'copy', record)}>复制地址</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>

                    </div>
                );
            case '已取消':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>

                        <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>

                    </div>
                );
            default:
                return (
                    <div className={styles.action}>
                        {/* <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div> */}

                        <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>

                    </div>
                );
        }
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
        }
    };

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={record => record.orderId}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.string
};
export default OrderTable;

//   onCell: () => {
//     return {
//       style: {
//         maxWidth: 0,
//         overflow: 'hidden',
//         whiteSpace: 'nowrap',
//         textOverflow:'ellipsis',
//         cursor:'pointer'
//       }
//     };
//   },