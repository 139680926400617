import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as C from './actiontypes';

export const getCommentList = function* (){
    yield takeEvery(C.GET_COMMENT_LIST, function* requestData(action){
        try{
            let result = yield http('/goods/comment/goodsCommentListByMerchant', action.payload, 'POST');
            yield put({type:C.SET_COMMENT_LIST, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    }); 
};
export const getCommentDetail = function* (){
    yield takeEvery(C.GET_COMMENT_DETAIL, function* requestData(action){
        try{
            let result = yield http('/goods/comment/goodsCommentDetails', action.payload, 'POST');
            yield put({type:C.SET_COMMENT_DETAIL, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    }); 
};
export const getProductClassify = function* (){
    yield takeEvery(C.GET_PRODUCT_CLASSIFY, function* requestData(action){
        try{
            let result = yield http('/goods/category/linkage', action.payload, 'POST');
            yield put({type:C.SET_PRODUCT_CLASSIFY, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    }); 
};