import * as T from './actiontypes';

export const getInvoiceList = (value) => ({
    type: T.GET_INVOICELIST,
    payload: value
});

export const getInvoiceListTableSource = (value) => ({
    type: T.GET_INVOICELIST_TABLESOURCE,
    payload: value
});
