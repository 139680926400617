/**
 *  合同管理 - 合同列表
 * */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';
import PropTypes from 'prop-types';
import model from '@/reducers/contractmanage/contractlist/model';
import TableData from './components/TableData';


class Contract extends Component {
    state = {
        pageNum: 1,
        pageSize: 10,
    }
    componentDidMount() {
        this.props.getContractList();
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
        case 'info':
            addOneBreadcrumbPath({
                title: '合同详情',
                path: '/home/contractmanage/contractlist/detailInfo/'
            });
            history.push({
                pathname: `/home/contractmanage/contractlist/detailInfo/${id}`
            });
            break;
        default:
            return null;
      }
    };
    render() {
        const { ContractList } = this.props;
        return (
                <div className={styles.flexBoxContainer}>
                    <div className={`${styles.table_body} ${styles.tableContainer}`}>
                        <TableData renderData={ContractList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    </div>
                </div>
        );
    }
}

Contract.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getContractList: PropTypes.func,
    ContractList: PropTypes.array,
};

const mapStateToProps = (state) => {
    return {
        ContractList: state.contractlist.getContractList,
    };
};

export default connect(mapStateToProps, { ...model.actions, addOneBreadcrumbPath })(Contract);