/**
 * 【付款管理】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav, XInput, XSelect, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getPayManageTableSource } from '@/reducers/storehousefinancialmanage/paymanage/actions';
import AdvertTable from './components/AdvertTable';
import DeleteModal from './modal/DeleteModal';
import SubmitAuditModal from './modal/SubmitAuditModal';
import ConfirBill from './modal/ConfirBill';
import PayConfirmate from './modal/PayConfirmate';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';

class Main extends React.Component {

    state = {
        tab: [{ id: 1, label: '会员' }],
        curTabID: 1,  // 当前显示的Tab页ID
        openModeParam: {},
        payStatus: [],

        orderId: undefined,
        status: {},
        targetName: undefined,
        startDate: undefined,
        endDate: undefined
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        http('/enum/PaymentApplicationStatus').then((res = {}) => {
            if (res.code === 200) {
                this.setState({ payStatus: res.result });
            }
        });
    }
    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    getCache = () => ({
        orderId: this.state.orderId,
        status: this.state.status,
        targetName: this.state.targetName,
        startDate: this.state.startDate,
        endDate: this.state.endDate
    });
    // 查询
    _searchHandle = (useCache) => {

        const { orderId, targetName, startDate, endDate, status, pageNum, pageSize } = this.state;

        this.searchCache = {
            orderId,
            targetName,
            status: status.code,
            startDate: startDate ? startDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
            endDate: endDate ? endDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };

        this.props.getPayManageTableSource(this.searchCache); // 发起请求
        KeepAlive.saveCache({ ...this.getCache(), pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ orderId: undefined, targetName: undefined, startDate: undefined, endDate: undefined, status: {}, pageNum: 1, pageSize: 15 }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        }, () => {
            this._searchHandle('useCache');
        });
    }
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    // 表格操作
    _tableAction = (id, type, recrod) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info':
                this.setState({ currentOpenMode: 'info', openModeParam: id });
                addOneBreadcrumbPath({
                    title: '付款详情',
                    path: '/home/storehousefinancialmanage/payManage/detail/'
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/payManage/detail/${id}`
                });
                break;
            case 'manageAudit':
                this.setState({ currentOpenMode: 'manageAudit', openModeParam: id });
                break;
            case 'directorReview':
                this.setState({ currentOpenMode: 'manageAudit', openModeParam: id });
                break;
            case 'auditSubmit':
                this.setState({ currentOpenMode: 'auditSubmit', openModeParam: id });
                break;
            case 'pay':
                this.setState({ currentOpenMode: 'pay', openModeParam: recrod });
                break;
            case 'del':
                this.setState({ currentOpenMode: 'del', openModeParam: id });
                break;
        }
    }
    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'del':
                return <DeleteModal data={this.state.openModeParam} title='系统信息' visible={true} closeMode={this._closeMode} />;
            case 'auditSubmit':
                return <SubmitAuditModal data={this.state.openModeParam} title='提交审核' visible={true} closeMode={this._closeMode} />;
            case 'manageAudit':
                return <ConfirBill data={this.state.openModeParam} title='通过审核' visible={true} closeMode={this._closeMode} />;
            case 'pay':
                return <PayConfirmate data={this.state.openModeParam} title='付款确认' visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        if (update) {
            this._searchHandle();
        }
        this.setState({ currentOpenMode: '' });
    }
    render() {
        const { orderId, status, targetName, startDate, endDate, payStatus } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    {/* <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} /> */}
                    <div className={styles.row1}>
                        <XInput
                            inputStyle={{ width: '150px' }}
                            label='付款单号'
                            placeholder='请输入'
                            value={orderId}
                            bindThis={this}
                            bindName='orderId'
                            mode='number'
                            maxLength={16}
                            showLength={false}
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '150px' }}
                            label='收款方'
                            placeholder='请输入收款方名称'
                            value={targetName}
                            bindThis={this}
                            bindName='targetName'
                        />
                        <XDatePicker
                            style={{ marginLeft: '20px', marginRight: '15px', color: '#000' }}
                            label='新建时间'
                            value={startDate}
                            bindThis={this}
                            bindName='startDate'
                        />
                        <span>—</span>
                        <XDatePicker
                            style={{ marginLeft: '5px' }}
                            onChange={this._orderEndTimeChangeHandle}
                            value={endDate}
                            bindThis={this}
                            bindName='endDate'
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '200px' }}
                            label='状态'
                            placeholder='请选择'
                            renderData={payStatus}
                            dataIndex='value'
                            keyIndex='code'
                            value={status.value}
                            bindThis={this}
                            bindName='status'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={() => this._searchHandle()}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    {/* <div className={styles.row2}>
                    <XCancelButton
                        style={{ width: '60px' }}
                        label='导出'
                        onClick={this._resetHandle}
                    />
                </div> */}
                    <AdvertTable renderData={this.props.payManageTableSource} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航

    payManageTableSource: PropTypes.object,
    getPayManageTableSource: PropTypes.func
};
const mapStateToProps = (state) => ({
    payManageTableSource: state.paymanage.payManageTableSource
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getPayManageTableSource })(Main);