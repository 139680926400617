/**
 * Saga集合
 */
import { getMenuList, getRegisterEnum, getYunstAuthTypeEnum, getBankNameList, getBankProviceList, getBankAreaList } from './saga';

export default [
    getMenuList,
    getRegisterEnum,
    getYunstAuthTypeEnum,
    getBankNameList,
    getBankProviceList,
    getBankAreaList,
];

