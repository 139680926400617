import * as T from './actiontypes';
/**
 * 卡劵管理数据源
 */
const defaultState = {
    tableSource: [],//卡劵管理列表数据源
    selectData: {},// 卡劵管理状态下拉选项
    paginations: {}, //卡劵管理分页对象

    advocacyDetails: {},//代言任务编辑、详情

    RaiseAll: [],//卡劵管理新建-全选复选
    RaiseStores: [],//卡劵管理新建使用门店

    RaiseTable: [],//卡券管理新建-选择商品表格数据
    RaisePagination: {},//卡券管理新建-选择商品表格数据分页
    
    goods: [],//卡劵管理新建-商品范围
    fixedtime: [],//卡劵管理新建-有限期
    deadline: [],//卡劵管理新建-有限期固定时长
    shopMode: [],
    allGoods: [],
    allPagination: {},
};

/**
 * @param {*} state 
 * @param {*} action 
 */
export const cardticketDate = (state = defaultState, action) => {
    let newTableSource, newSelectData, newPaginations, newDetail, RaiseAll, allGoods,
        RaiseStores, RaiseTable, newRaisePaginations, goods, fixedtime, deadline, shopMode,allGoodsPaginations;
    switch (action.type) {
        case T.SET_CARDTICKETLIST_DATA: //卡劵管理列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_CARDTICKDETAILS_DATA: //代言任务编辑、详情获取
            newDetail = action.payload.result;
            return { ...state, advocacyDetails: newDetail, };
        case T.SET_CARDTICKDROPDIWN_DATA: //卡劵管理下拉框
            newSelectData = action.payload;
            return { ...state, selectData: newSelectData, };

        case T.SET_RAISEDATA_DATA: //卡劵管理新建使用门店
            RaiseStores = action.payload.result;
            return { ...state, RaiseStores, };
        case T.SET_ALL_DATA: //卡劵管理新建-全选复选
            RaiseAll = action.payload.result;
            return { ...state, RaiseAll, };
        case T.SET_REWARD_DATA: //卡劵管理新建-商品范围
            goods = action.payload.result;
            return { ...state, goods, };
        case T.SET_COUPONNAME_DATA: //卡劵管理新建-有限期
            fixedtime = action.payload.result;
            return { ...state, fixedtime, };
        case T.SET_COUPONNAMESELECT_DATA: //卡劵管理新建-有限期固定时长
            deadline = action.payload.result;
            return { ...state, deadline, };
        case T.SET_RAISETABALE_DATA: //卡券管理新建-选择商品表格数据
            RaiseTable = action.payload.result.dataList;
            newRaisePaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, RaiseTable, RaisePagination: newRaisePaginations };
        case T.SET_CARDTICKSHOPMODE_DATA: //卡劵门店弹框数据
            shopMode = action.payload.result.infoListResps;
            return { ...state, shopMode };
        case T.SET_CARDTICKALLGOODS_DATA: //卡劵所有商品
            allGoods = action.payload.result.dataList;
            allGoodsPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, allGoods,allPagination: allGoodsPaginations };
        default:
            return state;
    }
};