/**
 * 【仓库列表】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import WalletButton from '@/vcomps/WalletButton';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import {
    //getDealingUnitSelectSource, 
    getStateSelectSource,
    getPlatformReconciliationTableSource
} from '@/reducers/storehousefinancialmanage/platformreconciliation/actions';
import AdvertTable from './components/AdvertTable';
import ConfirBill from './modal/ConfirBill';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';


class Main extends React.Component {

    state = {
        status: {},
        reconciliationId: undefined,
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getStateSelectSource();
        // this.props.getPlatformReconciliationTableSource({ pageNum: 1, pageSize: 10 });
    }
    // Tab页切换
    // _tabChangeHandle = (tabID) => {
    //     this.setState({ curTabID: tabID });
    // }
    // 查询
    _searchHandle = (useCache) => {
        const { reconciliationId, status, pageNum, pageSize } = this.state;
        this.searchCache = {

            status: status.value,
            reconciliationId,

            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getPlatformReconciliationTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ reconciliationId: undefined, status: {}, pageNum: 1, pageSize: 10 }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        // this.props.getPlatformReconciliationTableSource({ pageNum: 1, pageSize: 10 });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageSize,
            pageNum,
        }, () => {
            this._searchHandle('useCache');
        });
    }
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    // 表格操作
    _tableAction = (id, type, item) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info':
                addOneBreadcrumbPath({
                    title: '对账详情',
                    path: '/home/storehousefinancialmanage/platformreconciliation/detail/'
                });
                history.push({
                    pathname: `/home/storehousefinancialmanage/platformreconciliation/detail/${id}`,
                    state: {
                        startDate: item.startDate,
                        endDate: item.endDate,
                        targetType: item.targetType,
                        targetId: item.targetId,
                    }
                });
                break;
            case 'confir':
                this.setState({ currentOpenMode: 'confir', openModeParam: item });
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'confir':
                return <ConfirBill title='确认对账单' visible={true} closeMode={this._closeMode} data={this.state.openModeParam} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' });
        if (update) {
            this._searchHandle();
        }
    }

    render() {
        const { status, reconciliationId } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.row1}>
                        <XInput
                            style={{ width: '248px' }}
                            inputStyle={{ width: '180px' }}
                            label='对账单号'
                            placeholder='请输入'
                            value={reconciliationId}
                            bindThis={this}
                            bindName='reconciliationId'
                            mode='number'
                            maxLength={16}
                            showLength={false}
                        />
                        <XSelect
                            style={{ width: '270px', marginLeft: 10 }}
                            selectStyle={{ width: '200px' }}
                            label='状态'
                            placeholder='请选择'
                            renderData={this.props.stateSelectSource}
                            dataIndex='name'
                            keyIndex='value'
                            value={status.name}
                            bindThis={this}
                            bindName='status'
                        />
                        <XOKButton
                            style={{ width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    {/* 钱包中联信功能，暂时不需要 */}
                    {/* <div className={styles.row2}>
                        <WalletButton />
                    </div> */}
                    <AdvertTable renderData={this.props.platformReconciliationTableSource} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航

    stateSelectSource: PropTypes.array,
    getStateSelectSource: PropTypes.func,

    platformReconciliationTableSource: PropTypes.object,
    getPlatformReconciliationTableSource: PropTypes.func
};

const mapStateToProps = (state) => ({
    stateSelectSource: state.platformreconciliation.stateSelectSource,
    platformReconciliationTableSource: state.platformreconciliation.platformReconciliationTableSource
});

export default connect(mapStateToProps, {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getStateSelectSource,
    getPlatformReconciliationTableSource
})(Main);