/**
 * 仓库商品管理saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as T from './actiontypes';
import http from '@/assets/api/http';

// 获取商品分类列表
export const getOuterGoodsClassifyList = function* () {
    yield takeEvery(T.GET_OUTERGOODS_CLASSIFYLIST, function* requestData(action) {
        try {
            let result = yield http('/goods/outer/findOutGoodsCategoryPage', action.payload, 'POST');
            yield put({ type: T.SET_OUTERGOODS_CLASSIFYLIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getOuterGoodsClassifySele = function* () {
    yield takeEvery(T.GET_OUTERGOODS_CLASSIFYSELE, function* requestData(action) {
        try {
            let result = yield http('/goods/outer/findOutGoodsCategoryPage', action.payload, 'POST');
            yield put({ type: T.SET_OUTERGOODS_CLASSIFYSELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


