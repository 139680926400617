/**
 *  商户钱包
 * */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import Main from './pages/main';
import SaleProceeds from './pages/saleproceeds';

const tabs = [
    { id: 1, label: '销售款' },
    { id: 2, label: '销售收益' },
];

const Merchantwallet = ({ history }) => {

    const [curTabID, setCurTabID] = useState(1);

    return (
        <div className={styles.flexBoxContainer}>
            <XTabNav renderData={tabs} onChange={value => setCurTabID(value)} activedID={curTabID} />
            {
                curTabID == 1 ?
                    <Main history={history} curTabID={1} /> :
                    <SaleProceeds history={history} curTabID={2} />
            }
        </div>
    );
};
Merchantwallet.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
};
export default Merchantwallet;