import * as T from './actiontypes';

//获取商品标签列表
export const getProductLabel = (params = {}) => ({
    type: T.GET_PRODUCT_LABEL,
    payload: params
});

//获取商品标签详情
export const getProductLabelDetail = (id) => ({
    type: T.GET_PRODUCT_LABELDETAIL,
    payload: {
        id
    }
});
