/**
 * 申请退出活动
 */
import React, { Fragment } from 'react';
import { Modal, message, Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './ExitActivity.module.scss';
import http from '@/assets/api/http';

const { TextArea } = Input;

class ExitActivity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
            areaValue: '',//退出原因
        };

    }

    componentDidMount() { }

    _areaChangeHandle = (e) => {
        this.setState({ areaValue: e.target.value });
    }

    _handleOk = () => {
        const { data } = this.props;
        const { areaValue, } = this.state;
        let paramData = {};
        if (areaValue == '') return message.warn('请填写退出原因。');
        this.setState({
            confirmLoading: true,
        });
        paramData = {
            activityId: data.activityId,
            activityGoodsId: data.activityGoodsId,
            withDrawReason: areaValue
        };
        http('/withdrawActivity/addWithdraw', paramData, 'POST').then(() => {
            message.success('申请成功。');
            this.setState({
                visible: false,
                confirmLoading: false,
            });
            this.props.closeMode(true);
        }).catch((reject = {}) => {
            message.error(reject.message);
            this.setState({
                confirmLoading: false,
            });
        });

    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }

    render() {
        const { visible, confirmLoading, title, areaValue, } = this.state;
        const { data, } = this.props;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.label}>
                        <label>商品名称</label>
                        <span>{data.goodsName}</span>
                    </div>
                    <div className={styles.label}>
                        <label>活动名称</label>
                        <span>{data.activityTitle}</span>
                    </div>
                    <div className={styles.result}>
                        <span><i>*</i>退出原因</span>
                        <p>
                            <TextArea
                                placeholder='请输入备注'
                                rows={4}
                                onChange={this._areaChangeHandle}
                                value={areaValue}
                            />
                        </p>
                    </div>
                </div>
            </Modal>
        );

    }
}

ExitActivity.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default ExitActivity;