/**
 * 在线客服
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XOKButton, XInput, XInputPassword } from '@/components/xqxc_ui';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { connect } from 'react-redux';
import { Icon, message } from 'antd';
import ResetPwdModal from './modal/ResetPwdModal';
import { getLocalStorage } from '@/assets/js/storage';
import http from '@/assets/api/http';

class Main extends React.Component {

    state = {
        id: '', // 客服ID
        outerLoginName: '', // 客服账号
        outerLoginPwd: '', // 客服密码
        openModal: false,
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
        const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
        const { shopId } = userInfo;
        http('/merchant/customer/getOuterCustomer', { shopId }, 'POST').then((response) => {
            if (response.status == 200) {
                const { id, outerLoginName, outerLoginPwd } = response.result;
                this.setState({ id, outerLoginName, outerLoginPwd });
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 修改密码
    _onResetPwd = () => {
        this.setState({ openModal: true });
    }

    // 进入客服
    _onClick = () => {
        const { id } = this.state;
        http('/merchant/customer/getOuterCustomerToken', { id }, 'POST').then((response) => {
            if (response.status == 200) {
                window.open(response.result, 'blank');
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 渲染修改密码弹框
    _renderModal = () => {
        const { openModal, id } = this.state;
        if (openModal) {
            return <ResetPwdModal visible={true} closeModal={this._closeModal} id={id} />;
        } else {
            return null;
        }
    }

    // 关闭弹框
    _closeModal = (update, pwd) => {
        this.setState({ openModal: false });
        if (update) {
            this.setState({ outerLoginPwd: pwd });
        }
    }

    render() {
        const { outerLoginName, outerLoginPwd } = this.state;

        return (
            <div className={styles.container}>
                <XInput
                    inputStyle={{ width: '300px' }}
                    label='客服账号'
                    placeholder='请输入客服账号'
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    value={outerLoginName}
                    bindThis={this}
                    bindName='outerLoginName'
                    disabled={true}
                />
                {/* <XInputPassword
                    style={{ marginTop: '20px' }}
                    inputStyle={{ width: '300px' }}
                    label='客服密码'
                    placeholder="请输入客服密码"
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    visibilityToggle={true}
                    value={outerLoginPwd}
                    bindThis={this}
                    bindName='outerLoginPwd'
                /> */}
                <div style={{ display: 'flex', marginTop: '30px', marginLeft: '67px' }}>
                    <XOKButton style={{ width: '120px' }} label='修改客服密码' onClick={this._onResetPwd} />
                    <XOKButton style={{ width: '120px', marginLeft: '20px' }} label='进入客服系统' onClick={this._onClick} />
                </div>
                {this._renderModal()}
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func
};

export default connect(null, { keepSecondNavBreadcrumb })(Main);
