/**
 *  【仓库商品库存】入口页
 * */ 
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const WarehouseProductReserve = ({history}) => {
    return <Main history={history} />;
};

WarehouseProductReserve.propTypes = { 
    history: PropTypes.object
};

export default WarehouseProductReserve;