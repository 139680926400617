import React from 'react';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell, ImgCell } from '@/components/TableCell';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './OrderTable.module.scss';

class OrderTable extends React.Component {

    _takeColumns = () => {
        let cols = [
            {
                title: '上传时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: cellWidth.time,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '金额',
                dataIndex: 'amount',
                key: 'amount',
                width: cellWidth.normal,
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '凭证图片',
                dataIndex: 'img',
                key: 'img',
                width: cellWidth.normal,
                align: 'center',
                render: ImgCell
            },
            {
                title: '转账账号',
                dataIndex: 'cardNo',
                key: 'cardNo',
                width: cellWidth.time,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '审核状态',
                dataIndex: 'status.value',
                key: 'status.value',
                width: cellWidth.normal,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '审核时间',
                dataIndex: 'gmtAudit',
                key: 'gmtAudit',
                width: cellWidth.time,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            }
        ];
        return cols;
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    columns={this._takeColumns()}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ x: '100%' }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired
};

export default OrderTable;
