import React, { Component } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import Auditing from '../auditing';
import Audited from '../audited';

export default class Main extends Component {
    //初始数据
    constructor(props){
        super(props);
        this.state = {
            tab: [
                { id: 1, label: '待审核' },
                { id: 2, label: '已审核' },
            ],
            curTabID: 1
        };
    }
    //props校验
    static propTypes = {
        history: PropTypes.object, // 路由history对象
        updateContent: PropTypes.func, // 跳转其它路由页面
    };
    //异步请求
    componentDidMount() {
        const { history } = this.props;
        if (history.location.state && history.location.state.tabID) {
            this.setState({ curTabID: history.location.state.tabID }); // 如果路由过来的state参数带tabID值，则渲染该tabID值下的内容
        }
    }
    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }
    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        switch (this.state.curTabID) {
            case 1:
                return <Auditing curTabID={this.state.curTabID} updateContent={this.props.updateContent} />;
            case 2:
                return <Audited curTabID={this.state.curTabID} updateContent={this.props.updateContent} />;
            default:
                return <Auditing curTabID={this.state.curTabID} updateContent={this.props.updateContent} />;
        }
    }
    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={`${styles.page} ${styles.flexBoxContainer}`}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}