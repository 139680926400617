import * as T from './actiontypes';
/**
 * 集采管理数据源
 */
const defaultState = {
    tableSource: [],//分类列表数据源
    paginations: {}, //分页对象

    purchasingDetails: {},///集采管理详情
    purchasingTable: [],//集采管理新增商品
    purchasingPagination: {},//集采管理新增商品分页
};

/**
 * @param {*} state 
 * @param {*} action 
 */

export const purchasingDate = (state = defaultState, action) => {
    let newTableSource, newPaginations, marketingDetails, raiseTable, newRaisePaginations;
    switch (action.type) {
        case T.SET_PURCHASINGLIST_DATA: //列表
            newTableSource = action.payload.result.dataList;
            newPaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_PURCHASINGTABALE_DATA: //集采管理新增商品
            raiseTable = action.payload.result.dataList;
            newRaisePaginations = {
                pageNum: action.payload.result.pageNum,
                pageSize: action.payload.result.pageSize,
                resultTotal: action.payload.result.resultTotal
            };
            return { ...state, purchasingTable: raiseTable, purchasingPagination: newRaisePaginations };
        case T.SET_PURCHASINGDETAIL_DATA: //集采管理详情
            marketingDetails = action.payload.result;
            return { ...state, purchasingDetails: marketingDetails, };
        default:
            return state;
    }
};
