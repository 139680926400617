/** 操作类型 */
const OperateType = {

    /** 受理 */
    Accept: 'Accept',

    /** 生成对账单 */
    Generate: 'Generate',

    /** 详情 */
    Detail: 'Detail',

    /** 删除 */
    Delete: 'Delete',

    /** 对账详情 */
    Reconciliation: 'Reconciliation',

    /** 通知书 */
    IncomeRevenue: 'IncomeRevenue',

    /** 发起对账 */
    StartReconciliation: 'StartReconciliation',

    /** 确认对账单 */
    ConfirmReconciliation: 'ConfirmReconciliation',

    /** 导出通知书 */
    ExportNotice: 'ExportNotice',

    /** 导出对账单明细 */
    ExportDetail: 'ExportDetail'
};

export { OperateType };