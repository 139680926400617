/**
 * 主页
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import NavCard from './components/NavCard';
import NavPosition from './components/NavPosition';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import * as AllActions from '@/reducers/home/actions';
import { Avatar, message } from 'antd';
import { setLocalStorage, getLocalStorage } from '@/assets/js/storage';
import http from '@/assets/api/http';
import model from '@/reducers/messagemanage/messagelist/model';
import { removeSessionStorage, getSessionStorage } from '@/assets/js/storage';
import BindAccountModal from './modal/BindAccountModal';
import { closeChat } from '@/assets/js/customService';
import { isWE, isSR } from '@/assets/js/authType';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isToggleCard: false, // 用于区别当前用户点击的是一级导航还是二级导航，true表示点击一级导航
            stretchOpen: this.props.stretchOpen, // 导航栏的伸展状态
            emplName: '', // 用户名
            avatar: '', // 头像
            currentOpenMode: null,
            currentOpenData: {},
            isWE: false,
            isShowIM: false,
            isadminType: false,
            unReadCount: 0, waitDeliver: 0
        };
    }

    componentDidMount() {
        const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
        if (userInfo == null) {
            this.props.history.push({ pathname: '/login' });
        } else {
            const { emplName, avatar } = userInfo;
            this.setState({ emplName, avatar });
            this.props.getMenuList();
        }
        this._isAnchor();
        // 判断是否是仓库
        const isWE = userInfo && userInfo.accountType;
        if (isWE == 'WE') {
            this.setState({ isWE: true });
            http('/merchant/order/waitSendOrderNoticeByWareHouse', {}, 'POST')
                .then(res => {
                    this.setState({ waitDeliver: res.result });
                    this.timer = setInterval(() => {
                        http('/merchant/order/waitSendOrderNoticeByWareHouse', {}, 'POST')
                            .then(res => {
                                this.setState({ waitDeliver: res.result });
                            });
                    }, 120000);
                });
        }
        // 判断 去直播 该按钮只在商户主帐号登录时才显示，商户的员工帐号不显示该按钮
        const adminType = userInfo && userInfo.adminType;
        if (adminType == 1) {
            this.setState({ isadminType: true });
        }
        // 数量
        this.props.getUnReadMessageCount({ systemType: 'HOTEL_MERCHANT' });
        // 消息列表
        this.props.getlistMessage({ pageSize: 10, pageNum: 1 });
        // 判断是否显示IM
        const accountType = userInfo && userInfo.accountType;
        const shopId = userInfo && userInfo.shopId;
        if (accountType == 'SP') {
            http('/merchant/customer/getIsShowByShopId', { id: shopId }, 'POST').then((response) => {
                if (response.status == 200) {
                    if (response.result == 1) {
                        this.setState({ isShowIM: true });
                    }
                }
            }).catch((e) => {
                message.error(e.message);
            });
        }
    }

    // 用户点击一级导航回调函数
    _toggleCardHandle = (path) => {
        this.props.setActivedCardPath(path);
        this.setState({ isToggleCard: true, stretchOpen: true });
    }

    // 用户点击二级导航回调函数
    _putRouteHandle = (activedRoutePath, breadcrumbPaths) => {
        this.props.setBreadcrumbPaths(breadcrumbPaths);
        this.props.setActivedRoutePath(activedRoutePath);
        this.setState({ isToggleCard: false });
        // 清空页面缓存，与KeepAlive配合使用
        const href = window.location.href;
        const list = href.split('/');
        let cacheName = '';
        if (list[list.length - 1] == '') {
            cacheName = list[list.length - 3] + '/' + list[list.length - 2];
        } else {
            cacheName = list[list.length - 2] + '/' + list[list.length - 1];
        }
        removeSessionStorage(cacheName);
        this.props.history.push({
            pathname: activedRoutePath
        });
    }

    // 渲染手风琴导航组件
    _renderNav = (routes) => {
        return (
            <div className={styles.nav} style={this.state.stretchOpen ? { width: '257px' } : { width: '0px' }}>
                {
                    routes.map((rItem) => {
                        let eleNode = null;
                        if (rItem.path != '/' && rItem.path != '/setting') {
                            eleNode = this._renderNavCard(rItem);
                        }
                        return eleNode;
                    })
                }
            </div>
        );
    }

    // 渲染手风琴导航组件各个项
    _renderNavCard = ({ childrenNode = [], path, ...other }) => {
        let item = {
            ...other, path, childrenNode: childrenNode.map(i => {
                if (path === '/storehousefinancialmanage' && i.path === '/suppliersettlement' && isSR()) return { ...i, title: '对账管理' };
                if (path === '/storehousefinancialmanage' && i.path === '/supplierwithdrawal' && isSR()) return { ...i, title: '提现管理' };
                return i;
            })
        };
        return (
            <NavCard
                key={item.path}
                rItem={item}
                isToggleCard={this.state.isToggleCard}
                match={this.props.match}
                history={this.props.history}
                putRouteHandle={this._putRouteHandle}
                toggleCardHandle={this._toggleCardHandle}
                activedCardPath={this.props.activedCardPath}
                activedRoutePath={this.props.activedRoutePath}
                stretchOpen={this.state.stretchOpen}
            />
        );
    }

    // 渲染路由页面，随着导航点击响应页面变化
    _renderRoute = (routes) => {
        let routeList = [];
        routes.forEach((rItem) => {
            let route = {};
            if (rItem.component) {
                route.key = this.props.match.path + rItem.path;
                route.path = this.props.match.path + rItem.path;
                route.component = rItem.component;
                routeList.push(route);
            }
            if (rItem.childrenNode) {
                rItem.childrenNode.forEach((rItemChild) => {
                    let cr = {};
                    cr.key = this.props.match.path + rItem.path + rItemChild.path;
                    cr.path = this.props.match.path + rItem.path + rItemChild.path;
                    cr.component = rItemChild.component;
                    routeList.push(cr);
                });
            }
        });
        return (
            routeList.map((route) => {
                return (
                    <Route
                        exact
                        key={route.key}
                        path={route.path}
                        component={route.component}
                    />
                );
            })
        );
    }

    // 界面伸缩，当该函数触发时导航栏收缩至只剩图标
    _drawerHandle = () => {
        this.props.setStretchOpen(!this.state.stretchOpen);
        this.setState({ stretchOpen: !this.state.stretchOpen });
    }

    // 筛选菜单权限
    _getMenuRoutes = (routes, menuList) => {
        let mRoutes = routes.map((rItem) => {
            if (rItem.path == '/' || rItem.path == '/setting') {
                return rItem;
            } else {
                const pathUrl = rItem.path.substring(1, rItem.path.length);
                let boolean = false;
                let menuItem = {};
                for (let i = 0; i < menuList.length; i++) {
                    if (pathUrl == menuList[i].menuUrl) {
                        menuItem = menuList[i];
                        boolean = true;
                        break;
                    }
                }
                let newRoute = {};
                if (boolean) {
                    newRoute = { ...rItem, childrenNode: [] };
                    const childrenNode = rItem.childrenNode;
                    const menuItemChildrens = menuItem.childrens;
                    for (let m = 0; m < childrenNode.length; m++) {
                        const childrenNodePath = childrenNode[m].path;
                        for (let n = 0; n < menuItemChildrens.length; n++) {
                            const menuUrl = menuItemChildrens[n].menuUrl;
                            if (childrenNodePath == menuUrl || childrenNodePath == ('/' + menuUrl) || (childrenNodePath.indexOf(menuUrl) > -1 && childrenNode[m].show == 'none')) {
                                newRoute.childrenNode.push(childrenNode[m]);
                                break;
                            }
                        }
                    }
                    return newRoute;
                }
            }
        });

        mRoutes = mRoutes.filter((item) => {
            if (item != undefined) {
                return true;
            } else {
                return false;
            }
        });
        // 生产环境下，只能返回 mRoutes
        if (process.env.NODE_ENV === 'production') {
            return mRoutes; // 这段代码不要动
        } else {
            // return mRoutes;
            return routes;
        }
    }

    // 退出
    _logout = () => {
        http('/admin/empl/logout', {}, 'POST').then((response) => {
            if (response.status == 200) {
                closeChat();
                message.success('退出成功');
                this.props.history.push({ pathname: '/' });
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 打开个人设置
    _settingHandle = () => {
        this.props.setBreadcrumbPaths([{
            title: '个人设置',
            path: '/home/usermanage/member/info/'
        }]);
        this.props.history.push({ pathname: '/home/setting' });
    };

    // 打开弹窗
    _renderMode = () => {
        const { currentOpenData } = this.state;
        switch (this.state.currentOpenMode) {
            case 'existAccount':
                return <BindAccountModal title='绑定主播帐号' visible={true}
                    data={currentOpenData}
                    closeMode={this._closeMode} />;
            default:
                return null;
        }
    };

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    };

    // 判断是否绑定主播
    _isAnchor = () => {
        http('/live/judgeMerchantIsAnchor', {}, 'POST').then((response) => {
            if (response.status == 200) {
                this.setState({ currentOpenData: { tel: response.result.tel, flag: response.result.isAnchor, openCustomerServiceState: response.result.openCustomerServiceState } });
            }
        }).catch((e) => {
            message.error(e && e.message);
        });
    };

    // 去直播
    broadcastHandle = () => {
        this.setState({ currentOpenMode: 'existAccount' });
    };

    // 消息详情
    OnDetailsHandle = (id) => {
        const { history } = this.props;
        window.localStorage.setItem('messageId', id);
        history.push({
            pathname: '/home/messagemanage/messageList/detail/' + id,
        });
    }

    // 消息全部
    allHandle = () => {
        const { history } = this.props;
        history.push({
            pathname: '/home/messagemanage/messageList',
        });
    }

    delMessage = (id, e) => {
        e.stopPropagation();
        http('/user/messageinfomis/deleteMessage', { id }, 'POST')
            .then(() => {
                message.success('删除成功!');
                // 消息列表
                this.props.getlistMessage({ pageSize: 10, pageNum: 1 });
                // 数量
                this.props.getUnReadMessageCount({ systemType: 'HOTEL_MERCHANT' });
                const obj = getSessionStorage('messagemanage/messageList') ? JSON.parse(getSessionStorage('messagemanage/messageList')) : null;
                this._searchHandle(obj);
            }).catch((reject) => {
                message.error(reject.message);
            });
    }

    // 查询
    _searchHandle = (obj) => {
        if (obj !== null) {
            const { messageTitle, messageTypeObj, curTabID, pageNum, pageSize } = obj;
            this.searchCache = {
                messageTitle,
                pageNum,
                pageSize,
                messageTypeId: messageTypeObj.id,
            };
            if (curTabID === 1) {
                this.props.getlistUnReadMessage(this.searchCache);
            } else {
                this.props.getlistReadMessage(this.searchCache);
            }
        } else {
            this.searchCache = {
                pageNum: 1,
                pageSize: 10,
            };
            this.props.getlistUnReadMessage(this.searchCache);
            this.props.getlistReadMessage(this.searchCache);
        }
    }

    _saveButtonPermission = (menuList) => {
        const premission = this._getButtonUrlForChildren(menuList);
        setLocalStorage('btnPremission', premission);
    }

    _getButtonUrlForChildren = (treeData) => {
        let childrenEventKeys = [];
        for (let i = 0; i < treeData.length; i++) {
            if (treeData[i].menuType == '2' || treeData[i].menuType == '3') {
                childrenEventKeys.push(treeData[i].menuUrl);
            }
            if (treeData[i].childrens && treeData[i].childrens.length > 0) {
                const array = this._getButtonUrlForChildren(treeData[i].childrens);
                childrenEventKeys = Array.from(new Set([...childrenEventKeys, ...array]));
            }
        }
        return childrenEventKeys;
    }

    // 进入客服
    customerHandle = () => {
        const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
        http('/shop/getShopDetails', { id: userInfo.shopId }, 'POST').then((response) => {
            if (response.status == 200) {
                const { id, shopName, logoUrl } = response.result;
                var tempwin = window.open('about:blank');
                tempwin.location = process.env.CustomerIM + '?userid=' + id + '&shopname=' + shopName + '&profile=' + logoUrl;
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    _checkReadyDeliver = () => {
        const { history } = this.props;
        this.props.setActivedCardPath('/home/delivermanage');
        this.props.setActivedRoutePath('/home/delivermanage/readydeliver');
        this.props.setBreadcrumbPaths([{ title: '仓库发货管理', path: '/home/delivermanage' }, { title: '待发货出库单', path: '/home/delivermanage/readydeliver' }]);
        history.push({ pathname: '/home/delivermanage/readydeliver' });
    }

    render() {
        const { routes, menuList, UnReadMessageCount: { unReadCount }, listMessage: { dataList = [] }, history } = this.props;
        const { emplName, avatar, stretchOpen, currentOpenData: { flag, tel }, waitDeliver } = this.state;
        const mRoutes = this._getMenuRoutes(routes, menuList);
        this._saveButtonPermission(menuList); // 获取按钮权限

        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.logo}>
                        <img src={require('@/assets/image/home-logo.png')} />
                    </div>
                    <div className={styles.help}>
                        <div className={styles.text}>每日说平台欢迎您回来！
                            {isWE() && waitDeliver != 0 && <span style={{ color: '#ff0000' }}>尊敬的管理员，您当前有{waitDeliver}条待发货的订单，请及时处理
                                <span style={{ color: '#40a9ff', paddingLeft: '20px', cursor: 'pointer' }} onClick={this._checkReadyDeliver}>点击查看</span></span>}
                        </div>
                        <div className={styles.other}>
                            {this.state.isShowIM && <div className={styles.userinfo} onClick={this.customerHandle} >
                                <img src={require('@/assets/image/customer.png')} style={{ marginLeft: 10 }} />
                                <div className={styles.userinfo_name} style={{ fontSize: 12, fontWeight: 700, paddingTop: 3, textAlign: 'center' }} >
                                    客服系统
                                </div>
                            </div>}
                            {!this.state.isWE && <div className={styles.userinfo} onClick={this.broadcastHandle} >
                                <img src={require('@/assets/image/video.png')} style={{ marginLeft: 10 }} />
                                <div className={styles.userinfo_name} style={{ fontSize: 12, fontWeight: 700, paddingTop: 3, textAlign: 'center' }} >
                                    去直播
                                    <div style={{ fontSize: 12, textAlign: 'center' }}>{flag == 1 && tel}</div>
                                </div>
                            </div>}
                            <div className={styles.userinfo} onClick={this._settingHandle}>
                                <Avatar style={{ minWidth: '40px', minHeight: '40px', backgroundColor: '#F86720' }} size={40} icon="user" src={avatar} />
                                <p className={styles.userinfo_name}>{emplName}</p>
                            </div>
                            <div className={styles.logout} onClick={this._logout}>
                                <img className={styles.icon} src={require('@/assets/image/exit.png')} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.body}>
                    {this._renderNav(mRoutes)}
                    <div className={styles.content} id='home_content'>
                        <div className={styles.sbox}>
                            <div className={styles.sitem} onClick={this._drawerHandle}><span className={stretchOpen ? styles.letfsicon : styles.rightsicon}></span></div>
                        </div>
                        <div className={styles.rbox}>
                            <NavPosition history={history} menuList={menuList} />
                            <div className={styles.routerstyle}>
                                <Switch>
                                    {this._renderRoute(mRoutes)}
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
                {this._renderMode()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activedCardPath: state.home.activedCardPath, // 活动的一级导航路径
    activedRoutePath: state.home.activedRoutePath, // 活动的二级导航路径
    stretchOpen: state.home.stretchOpen, // 导航栏的伸展状态
    menuList: state.home.getMenuList,
    UnReadMessageCount: state.messagelist.UnReadMessageCount,
    listMessage: state.messagelist.listMessage,
});

Home.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    routes: PropTypes.array, // Home路由中的子路由页面，即一级导航和二级导航详细数据
    activedCardPath: PropTypes.string, // 活动的一级导航路径 
    activedRoutePath: PropTypes.string, // 活动的二级导航路径
    stretchOpen: PropTypes.bool, // 导航栏的伸展状态
    setBreadcrumbPaths: PropTypes.func, // 存储面包屑导航路径集合的函数
    setActivedCardPath: PropTypes.func, // 存储一级导航路径的函数
    setActivedRoutePath: PropTypes.func, // 存储二级导航路径的函数
    setStretchOpen: PropTypes.func, // 存储导航栏的伸展状态的函数
    getMenuList: PropTypes.func,
    menuList: PropTypes.array,
    getUnReadMessageCount: PropTypes.func,
    UnReadMessageCount: PropTypes.object,
    getlistMessage: PropTypes.func,
    listMessage: PropTypes.object,
    getlistUnReadMessage: PropTypes.func,
    getlistReadMessage: PropTypes.func,
};

export default connect(mapStateToProps, { ...AllActions, ...model.actions })(Home);