import { createModel } from '@/store/tools';

const model = {
    namespace: 'advocacy',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getAdvocacyTableList': T('getAdvocacyTableList'),
            'getAdvocacyType': T('getAdvocacyType'),
            'getAdvocacyState': T('getAdvocacyState'),
            'getExecution': T('getExecution'),
            'getTotalService': T('getTotalService'),
            'getChooseGoods': T('getChooseGoods'),
            'getReceiveDetail': T('getReceiveDetail'),
            'getReceiveState': T('getReceiveState'),
            'getAdvocacyDetail': T('getAdvocacyDetail'),
            'getAdvocacyStatus': T('getAdvocacyStatus'),
        },
        actions: {
            'getAdvocacyTableList': A('getAdvocacyTableList'), // 列表数据
            'getAdvocacyType': A('getAdvocacyType'), // 代言类型数据
            'getAdvocacyState': A('getAdvocacyState'), // 代言审核状态数据
            'getReceiveDetail': A('getReceiveDetail'), // 领取详情列表数据
            'getReceiveState': A('getReceiveState'), // 领取详情列表查询状态数据
            'getAdvocacyStatus': A('getAdvocacyStatus'), // 全部代言状态数据

            //新建、编辑、详情
            'getExecution': A('getExecution'), // 任务执行时间类型
            'getTotalService': A('getTotalService'), // 人气值账户总数和服务费比例
            'getChooseGoods': A('getChooseGoods'), // 代言新增选择商品数据
            'getAdvocacyDetail': A('getAdvocacyDetail'), // 获取详情数据 
            'getCleanDetail': C('getAdvocacyDetail'), //清除详情数据  

        },
        sagas: {
            'getAdvocacyTableList': S('getAdvocacyTableList', '/spokesmantask/config/spokesmanTaskConfigList'),
            'getAdvocacyType': S('getAdvocacyType', '/spokesmantask/task/getSpokesmanTaskType'),
            'getExecution': S('getExecution', '/spokesmantask/task/getSpokesmanExecutionType'),
            'getTotalService': S('getTotalService', '/spokesmantask/config/getRateAndAccountFee'),
            'getChooseGoods': S('getChooseGoods', '/goods/goods/simpleGoodsHasStoreList'),
            'getAdvocacyState': S('getAdvocacyState', '/spokesmantask/task/getSpokesmanTaskConfigCheckState'),
            'getReceiveDetail': S('getReceiveDetail', '/spokesmantask/config/getUserSpokesmanTaskList'),
            'getReceiveState': S('getReceiveState', '/spokesmantask/task/getSpokesmanTaskUserStateEnum'),
            'getAdvocacyDetail': S('getAdvocacyDetail', '/spokesmantask/config/infoSpokesmanTaskConfig'),
            'getAdvocacyStatus': S('getAdvocacyStatus', '/enum/SpokesmanTaskConfigListStateEnum'),
        },
        reducers: {
            'advocacyTableList': R('getAdvocacyTableList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'advocacyType': R('getAdvocacyType', []),
            'execution': R('getExecution', []),
            'totalService': R('getTotalService', {}),
            'chooseGoods': R('getChooseGoods', { dataList: [], pageNum: 1, pageSize: 10, resultTotal: 0 }),
            'advocacyState': R('getAdvocacyState', []),
            'receiveDetail': R('getReceiveDetail', { dataList: [], pageNum: 1, pageSize: 10, resultTotal: 0 }),
            'receiveState': R('getReceiveState', []),
            'advocacyDetails': R('getAdvocacyDetail', {}),
            'advocacyStatus': R('getAdvocacyStatus', []),
        }
    })
};
export default createModel(model);