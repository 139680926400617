import React from 'react';
import CellFenToYuan from '@/vcomps/ShowCellMoney';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import styles from './TableData.module.scss';

const columns = () => [
  {
    title: '交易订单号',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: '20%',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '业务日期',
    dataIndex: 'gmtCreate',
    key: 'gmtCreate',
    align: 'center',
    width: '20%',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '业务名称',
    dataIndex: 'businessName',
    key: 'businessName',
    align: 'center',
    width: '20%',
    onCell: tooltipStyle,
    render: tooltip
  },

  {
    title: '数量/粒',
    dataIndex: 'changeBalance',
    key: 'changeBalance',
    align: 'center',
    width: '20%',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    align: 'center',
    width: '20%',
    onCell: tooltipStyle,
    render: tooltip
  },
];
export const takeDataSource = (data) => {
  // 解析表格数据，使符合columns格式要求
  // let tableSource = data.map((item)=>{

  //   return tableCell;
  // });
  return data;
};



export const takeColumns = () => {
  return columns();
};

const TableCell = (text) => {
  return (
    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
      {text}
    </div>
  );
};
