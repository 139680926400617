/**
 * [打印机设置]
 * @author: Fu Xiaochun
 * @date: 2022-08-15 
 */

import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Table, message, Switch, Button, Modal, } from 'antd';
import { useHistory } from 'react-router-dom';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getLocalStorage } from '@/assets/js/storage';

const { confirm } = Modal;

function PrinterSetting(props){

    const history  = useHistory();
    const [dataList, setDataList] = useState();

    const statusColor = {
        ONLINE: '#67c23a',
        OFFLINE: '#F00',
        PRINTER_EPT: '#e6a23c',
    };
    
    useEffect(()=>{
        props.keepSecondNavBreadcrumb();
        getList();
    }, []);

    // 获取打印机列表；
    const getList = ()=>{
        let auth = JSON.parse(getLocalStorage('auth')) || {};
        let shopId = auth.userInfo ? auth.userInfo.shopId : 0;
        http('/printer/listShopPrinterInfo', {shopId}, 'POST').then(res=>{
            setDataList(res.result);
        }).catch(err=>{
            message.error(err.message);
        });
    };

    const onAddPrinter =()=>{
        props.addOneBreadcrumbPath({
            title: '添加打印机',
            path: '/home/systemmanage/printersetting/add'
        });
        history.push('/home/systemmanage/printersetting/add');
    };

    const onEdit = (item)=>{
        props.addOneBreadcrumbPath({
            title: '修改打印机',
            path: `/home/systemmanage/printersetting/edit/${item.id}`
        });
        history.push(`/home/systemmanage/printersetting/edit/${item.id}`);
    };

    const onDel = (item)=>{
        confirm({
            title: `确定要删除打印机"${item.printerName}"？`,
            centered: true,
            onOk: ()=>{
                http('/printer/deletePrinter', {id: item.id}).then(res=>{
                    if(res.result.failReason){
                        message.error(res.result.printerName + '删除失败！'+res.result.failReason);
                    }else{
                        getList();
                    }
                }).catch(err=>{
                    message.error(err.message);
                });
            },
        });
    };

    const onPrint = (item)=>{
        http('/printer/YunOrBtPrinter/yunPrintTest', {printerId: item.id}).then(res=>{
            message.success('打印测试成功,请在打印机查看打印结果');
        }).catch(err=>{
            message.error(err.message);
        });
    };

    const reflushStatus = (item)=>{
        http('/printer/refreshPrinterStatus', {id: item.id}).then(res=>{
            let data = res.result;
            let newList = dataList.map(v=>{
                if(v.id == item.id){
                    v.status = data.status;
                    v.statusStr = data.statusStr;
                }
                return v;
            });
            setDataList(newList);
        });
    };

    const renderStatus = (val, item)=>{
        return (
            <div className={styles.printerStatus}>
                <span style={{color: statusColor[item.status]}}>{item.statusStr}</span>
                <div className={styles.reflush} onClick={()=>{reflushStatus(item);}}></div>
            </div>
        );
    };

    const renderAction = (val, item)=>{
        return (
            <div className={styles.actionBox}>
                <a onClick={()=>{onEdit(item);}}>修改</a>
                <a onClick={()=>{onDel(item);}}>删除</a>
                <a onClick={()=>{onPrint(item);}}>打印测试</a>
            </div>
        );
    };

    const columns = [
        {
            title: '品牌名称',
            dataIndex: 'brand',
            key: 'brand',
            align: 'left',
        },
        {
            title: '打印机名称',
            dataIndex: 'printerName',
            key: 'printerName',
            align: 'left',
        },
        {
            title: '打印机终端号',
            dataIndex: 'printerSn',
            key: 'printerSn',
            align: 'left',
        },
        {
            title: '打印机状态',
            dataIndex: 'statusStr',
            key: 'statusStr',
            align: 'left',
            render: renderStatus,
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'left',
            render: renderAction,
        },
    ];

    return(
        <div className={styles.container}>
            <div className={styles.handleBar}>
                <Button type="primary" onClick={onAddPrinter}>添加打印机</Button>
            </div>
            <div className={styles.tableList}>
                <Table
                    rowKey='id'
                    columns={columns}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ y: true }}
                />
            </div>
        </div>
    );
}

PrinterSetting.propTypes = {
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func, // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(PrinterSetting);