import * as T from './actiontypes';

/**
 * 直播带货
 */
const plistInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
// 直播带货
export const livegoodsList = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_LIVEGOODS_LIST:
            return payload.result;
        default:
            return state;
    }
};
// 直播间分类
export const liveclassList = (state = [], { type, payload }) => {
    switch (type) {
        case T.SET_LIVEGOODSCLASS_LIST:
            return payload.result;
        default:
            return state;
    }
};
// 本场订单
export const livegoodsorderList = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_LIVEGOODSORDER_LIST:
            return payload.result;
        default:
            return state;
    }
};