import { combineReducers } from 'redux';
import {
	invoiceList, deliverListTableSource, validateStatementTableSource, contractManageTableSource,
	contractDetail,
	contractStates,
	validateStatementDetail,
	validateStatementDetailTableSource,
	supplierSelectSource,
	// settlementStatesSelectSource,
} from './reducer';

export default combineReducers({
	invoiceList,
	deliverListTableSource,
	validateStatementTableSource,
	contractManageTableSource,
	contractDetail,
	contractStates,
	supplierSelectSource,
	validateStatementDetail,
	validateStatementDetailTableSource
});