// 员工列表
export const GET_EMPL_LIST = 'EMPLOYEELIST/GET_EMPL_LIST';
export const SET_EMPL_LIST = 'EMPLOYEELIST/SET_EMPL_LIST';

// 员工详情
export const GET_EMPL_DETAIL = 'EMPLOYEELIST/GET_EMPL_DETAIL';
export const SET_EMPL_DETAIL = 'EMPLOYEELIST/SET_EMPL_DETAIL';

// 部门下拉列表
export const GET_DEPT_SELECT = 'EMPLOYEELIST/GET_DEPT_SELECT';
export const SET_DEPT_SELECT = 'EMPLOYEELIST/SET_DEPT_SELECT';

// 员工状态下拉列表
export const GET_EMPL_STATE = 'EMPLOYEELIST/GET_EMPL_STATE';
export const SET_EMPL_STATE = 'EMPLOYEELIST/SET_EMPL_STATE';

// 所属分类下拉列表
export const GET_EMPL_TYPE = 'EMPLOYEELIST/GET_EMPL_TYPE';
export const SET_EMPL_TYPE = 'EMPLOYEELIST/SET_EMPL_TYPE';

// 未分配仓库信息
export const GET_PERMISSION_NOALLOTS = 'EMPLOYEELIST/GET_PERMISSION_NOALLOTS';
export const SET_PERMISSION_NOALLOTS = 'EMPLOYEELIST/SET_PERMISSION_NOALLOTS';

// 已分配仓库信息
export const GET_PERMISSION_ALLOTEDS = 'EMPLOYEELIST/GET_PERMISSION_ALLOTEDS';
export const SET_PERMISSION_ALLOTEDS = 'EMPLOYEELIST/SET_PERMISSION_ALLOTEDS';

// 店铺下拉列表
export const GET_SHOP_SELECT = 'EMPLOYEELIST/GET_SHOP_SELECT';
export const SET_SHOP_SELECT = 'EMPLOYEELIST/SET_SHOP_SELECT';
