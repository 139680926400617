import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';

class TableContent extends React.Component {

    state = {
        selectedRowKeys: [], // 选中的单元行
    };

    _takeColumns = () => ([
        {
            title: '供应商代码',
            dataIndex: 'supplierCode',
            key: 'supplierCode',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '供应商名称',
            dataIndex: 'supplierName',
            key: 'supplierName',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '经营品类',
            dataIndex: 'categoryName',
            key: 'categoryName',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '联系人',
            dataIndex: 'contact',
            key: 'contact',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '联系方式',
            dataIndex: 'contactPhone',
            key: 'contactPhone',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '心选推荐人',
            dataIndex: 'refereeName',
            key: 'refereeName',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '状态',
            dataIndex: 'auditState.value',
            key: 'auditState.value',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: '40%',
            render: (text, item) => this._renderActionCell(item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderActionCell = (item) => {
        const { tableAction } = this.props;
        // 联调时要注意，这里要对审核状态做判断，如果除审核通过情况外，其余都只显示查看详情
        if (item.auditState.value == '审核通过') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(item.id, 'detail', item)}>查看详情</div>
                    <div className={styles.item} onClick={() => tableAction(item.id, 'newShop', item)}>新建店铺</div>
                    <div className={styles.item} onClick={() => tableAction(item.id, 'newWarehouse', item)}>新建仓库</div>
                    <div className={styles.item} onClick={() => tableAction(item.id, 'contract', item)}>合同管理</div>
                    <div className={styles.item} onClick={() => tableAction(item.id, 'deliver', item)}>发货清单</div>
                    <div className={styles.item} onClick={() => tableAction(item.id, 'accountStatement', item)}>对账单</div>
                    <div className={styles.item} onClick={() => tableAction(item.id, 'stop', item)}>终止</div>
                    <div className={styles.item} onClick={() => tableAction(item.id, 'edit')}>编辑</div>
                </div>
            );
        } else {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(item.id, 'detail')}>查看详情</div>
                    {(item.auditState.code == 'AUDIT_TYPE_NEW' || item.auditState.code == 'AUDIT_TYPE_NOT_APPROVED') && <div className={styles.item} onClick={() => tableAction(item.id, 'edit')}>编辑</div>}
                </div>
            );
        }

    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;