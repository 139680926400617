import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Table, Button } from 'antd';
import { XPagination } from '@/components/xqxc_ui';

class TableData extends Component {

	_columns = () => [
		{
			title: '品牌名称',
			dataIndex: 'soucreName',
			key: 'soucreName',
			align: 'center',
			width: '15%',
		},
		{
			title: '申请理由',
			dataIndex: 'applyReason',
			key: 'applyReason',
			align: 'center',
			width: '20%',
		},
		{
			title: '审核意见',
			dataIndex: 'auditReason',
			key: 'auditReason',
			align: 'center',
			width: '20%',
		},
		{
			title: '状态',
			dataIndex: 'auditState',
			key: 'auditState',
			align: 'center',
			width: '15%',
			render: (state) => (this._stateCell(state))
		},
		{
			title: '更新时间',
			dataIndex: 'gmtModified',
			key: 'gmtModified',
			align: 'center',
			width: '15%',
		},
		{
			title: '操作',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			width: '15%',
			render: (id, record) => this._tableHandle(id, record)
		}
	];

	_stateCell = (state) => {
		return state.value;
	}

	_tableHandle = (id, record) => {
		return <Button type='link' style={{ color: '#40a9ff' }} onClick={() => this.props.tableHandle(id, 'detail', record)}>详情</Button>;
	}

	render() {
		const { paginationChange, renderData } = this.props;
		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					columns={this._columns()}
					pagination={false}
					rowKey={(record, index) => index}
					dataSource={renderData.dataList}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}

TableData.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,
	tableHandle: PropTypes.func,
	renderData: PropTypes.object
};

export default TableData;