/**
 * 商品管理 - 品牌异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';


export const getProductBrand = function* (){
    yield takeEvery(T.GET_PRODUCT_BRAND, function* requestData(action){
        try{
            let result = yield http('/goods/brand/listBrands', action.payload, 'POST');
            yield put({type:T.SET_PRODUCT_BRAND, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    }); 
};

export const getProductBrandSele = function* (){
    yield takeEvery(T.GET_PRODUCT_BRANDSELE, function* requestData(action){
        try{
            let result = yield http('/goods/brand/listBrandItem', action.payload, 'POST');
            yield put({type:T.SET_PRODUCT_BRANDSELE, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    }); 
};

export const getProductBrandDetail = function* (){
    yield takeEvery(T.GET_PRODUCT_BRANDDETAIL, function* requestData(action){
        try{
            let result = yield http('/goods/brand/auditInfo', action.payload, 'POST');
            yield put({type:T.SET_PRODUCT_BRANDDETAIL, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    }); 
};