/**
 * Saga集合
 */
import { getReadyDeliverList, getLogisticsSelect, getWholesaleSelect } from './readydeliver/saga';
import { getAlreadyDeliverList } from './alreadydeliver/saga';
import { getCancelDeliverList } from './canceldeliver/saga';
import { getSpDeliverList, getSpDeliverDetail } from './spdeliver/saga';

export default [
    getReadyDeliverList,
    getAlreadyDeliverList,
    getCancelDeliverList,
    getLogisticsSelect,
    getSpDeliverList,
    getSpDeliverDetail,
    getWholesaleSelect,
];
