/**
 * 集采打款
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const CollectPay = ({ history }) => {
    return <Main history={history} />;
};

CollectPay.propTypes = {
    history: PropTypes.object
};

export default CollectPay;