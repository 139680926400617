/**
 * 人气值订单
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Order from './actionTypes';

//人气值商城订单列表
export const getShopOrderList = function* () {
    yield takeEvery(Order.GET_SHOPORDER_LIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/getStarMallOrderList', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_SHOPORDER_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
//快递公司列表
export const getExpressCompanyList = function* () {
    yield takeEvery(Order.GET_EXPRESSCOMPANY_LIST, function* requestData(action) {
        try {
            let result = yield http('/fastpass/list', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_EXPRESSCOMPANY_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
