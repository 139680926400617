import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import MyshopActivity from '../myshop';//我的活动
import ShopActivity from '../shop';//店铺活动
import { getSessionStorage, setSessionStorage, removeSessionStorage } from '@/assets/js/storage';

class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '店铺活动' },
            { id: 2, label: '我的活动' },
        ],
        curTabID: 1,  // 默认显示的tab
    }

    componentDidMount() {
        //缓存tab的标识curTabID
        if (getSessionStorage('tabCurtId') == '2') {
            this.setState({ curTabID: Number(getSessionStorage('tabCurtId')) });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        //更新缓存tab的标识curTabID
        if (this.state.curTabID !== prevState.curTabID) {
            setSessionStorage('tabCurtId', Number(this.state.curTabID));
        }
    }

    componentWillUnmount() {

    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容 
    _renderPage = () => {
        const { curTabID } = this.state;
        switch (curTabID) {
            case 1:
                return <ShopActivity curTabID={this.state.curTabID} history={this.props.history} updateContent={this.props.updateContent} />;
            case 2:
                return <MyshopActivity curTabID={this.state.curTabID} history={this.props.history} updateContent={this.props.updateContent} />;
            default:
                return <ShopActivity curTabID={this.state.curTabID} history={this.props.history} updateContent={this.props.updateContent} />;
        }
    }

    render() {

        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                {this._renderPage()}
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 跳转其它路由页面
};

export default Main;