/**
 * 【仓库奢批结算价调整提示页-未调整】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { message } from 'antd';
import { getNotBalanceHintList } from '@/reducers/warehousemanage/balanceHint/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            skuId: '', // 商品规格ID
            batch: [],
            suggestPrice: '',
            goodsNo: ''
        };
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, skuId, goodsNo } = this.state;
        this.searchCache = {
            skuId,
            goodsNo,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getNotBalanceHintList({ ...this.searchCache, updateState: 'NOT_UPDATED' });
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ skuId: '', goodsNo: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 批量更新销售价
    _batchUpdateHandle = () => {
        const { batch } = this.state;
        const reqData = [];
        batch.map(item => {
            let reqDataitem = {};
            reqDataitem.salePrice = item.suggestPrice * 100;
            reqDataitem.skuId = item.skuId;
            reqData.push(reqDataitem);
        });
        this._sendReq('/goods/outer/batchUpdateSalePrice', reqData, '批量更新成功。');
    }

    _sendReq = (url, data, wintext) => {
        http(url, data, 'POST')
            .then(() => {
                message.success(wintext);
                this.props.getNotBalanceHintList({ ...this.searchCache, updateState: 'NOT_UPDATED' });
            })
            .catch(error => {
                message.error(error.message);
            });
    }

    // 表格操作
    _tableAction = (key, type, params) => {
        switch (type) {
            case 'updatePrice':
                this._sendReq('/goods/outer/updateSalePrice', { salePrice: params.suggestPrice * 100, skuId: params.skuId }, '更新成功。');
                break;
            case 'batch':
                this.setState({ batch: params });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { notBalanceHintList } = this.props;
        const { skuId, goodsNo } = this.state;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria1}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='商品规格ID'
                            placeholder='请输入商品规格ID'
                            value={skuId}
                            bindThis={this}
                            bindName='skuId'
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '200px' }}
                            label='商品货号'
                            placeholder='请输入商品货号'
                            value={goodsNo}
                            bindThis={this}
                            bindName='goodsNo'
                        />
                        <XOKButton style={{ marginLeft: '20px' }} label='查询' onClick={this._searchHandle} />
                        <XCancelButton style={{ marginLeft: '20px' }} label='重置' onClick={this._resetHandle} />
                    </div>
                    <div className={styles.export}>
                        <XOKButton label='批量更新销售价' onClick={this._batchUpdateHandle} />
                    </div>
                    <TableContent
                        curTabID={this.state.curTabID}
                        renderData={notBalanceHintList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                    />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getNotBalanceHintList: PropTypes.func,
    notBalanceHintList: PropTypes.object,
};

const mapStateToProps = (state) => ({
    notBalanceHintList: state.balanceHint.notBalanceHintList,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getNotBalanceHintList })(Main);