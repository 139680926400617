import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell, tooltipClick } from '@/components/TableCell';
import { regFenToYuan } from '@/util/money';

const SuccessTable = ({ paginationChange, renderData: { dataList, pageNum, pageSize, resultTotal }, tableOperate }) => {

    const columns = [
        {
            title: '会员账号',
            dataIndex: 'tel',
            key: 'tel',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '本店消费(笔)',
            dataIndex: 'shopOrderCnt',
            key: 'shopOrderCnt',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '本店消费(元)',
            dataIndex: 'shopOrderAmount',
            key: 'shopOrderAmount',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        }, {
            title: '销售收益(元)',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item) => tooltipClick(regFenToYuan(text), () => tableOperate(item.accountId))
        }, {
            title: '注册时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(record, index) => index}
                columns={columns}
                dataSource={dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};
SuccessTable.propTypes = {
    tableOperate: PropTypes.func,
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default SuccessTable;