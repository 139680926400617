import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Switch, } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { isEmpty } from '@/assets/js/utils';


const columns = (TableAction, actionClickHandle) => [
    {
        title: '集采名称',
        dataIndex: 'activityTitle',
        key: 'activityTitle',
        width: 140,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '活动星级(星)',
        dataIndex: 'starLevel',
        key: 'starLevel',
        width: 140,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '商品名称',
        dataIndex: 'goodsName',
        key: 'goodsName',
        width: 140,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '商品货号',
        dataIndex: 'goodsNo',
        key: 'goodsNo',
        width: 140,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '商品单位',
        dataIndex: 'unit',
        key: 'unit',
        width: 140,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '是否自购',
        dataIndex: 'hasLimitValue',
        key: 'hasLimitValue',
        width: 140,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '预热时间',
        dataIndex: 'warmingTime',
        key: 'warmingTime',
        width: 140,
        align: 'center',
        onCell: tooltipStyle,
        render: (text, record) => (nowrapDate(record.warmingStartTime, record.warmingEndTime))
    },
    {
        title: '活动时间',
        dataIndex: 'activityTime',
        key: 'activityTime',
        width: 140,
        align: 'center',
        onCell: tooltipStyle,
        render: (text, record) => (nowrapDate(record.startTime, record.endTime))
    },
    {
        title: '结算时间',
        dataIndex: 'enrollment',
        key: 'enrollment',
        width: 140,
        align: 'center',
        onCell: tooltipStyle,
        render: (text, record) => (nowrapDate(record.settleStartTime, record.settleEndTime))
    },
    {
        title: '预结算收益(%)',
        dataIndex: 'settlementData',
        key: 'settlementData',
        width: 140,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '发布平台',
        dataIndex: 'targetPlat',
        key: 'targetPlat',
        width: 140,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: 140,
        align: 'center',
        render: (text, record) => (SwitchCell(text, record, actionClickHandle))
    },
    {
        title: '审核状态',
        dataIndex: 'auditStateValue',
        key: 'auditStateValue',
        width: 140,
        align: 'center',
        onCell: tooltipStyle,
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'activityId',
        key: 'activityId',
        align: 'center',
        width: 200,
        onCell: tooltipStyle,
        render: (id, record) => TableAction(id, record, actionClickHandle)
    }
];

const TableAction = (id, record, actionClickHandle) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'detail')}>详情</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'virtualSales', record)}>虚拟销量规则</div>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'addSales', record)}>增加销量</div>
        </div >
    );
};

// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle) => {
    let checked = true;
    if (sw == 0) checked = false;
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                checked={checked}
                onChange={(value) => {
                    actionClickHandle(item.activityId, 'switch', value);
                }}
            />
        </div>
    );
};


//换行时间展示
const nowrapDate = (start, end) => {
    return <div className={styles.dates}>
        <span>{isEmpty(start) ? '-' : start}</span><br />
        <span>{isEmpty(end) ? '-' : end}</span>
    </div>;
};


class TypeTable extends React.Component {

    state = {
        tableDataSource: [], // 表格数据源
    }

    // 解析表格数据
    _takeDataSource = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data.map(item => {
            // item.enrollment = `${item.settleStartTime ? item.settleStartTime : ''} 至 ${item.settleEndTime ? item.settleEndTime : ''}`;//结算时间
            item.settlementData = (item.minRate / 10).toFixed(1) + ' ~ ' + (item.maxRate / 10).toFixed(1); //预结算收益
            return item;
        });
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }

    render() {
        const { renderData, pagiNation, paginationChange, } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={columns(TableAction, this._actionHandle)}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: true }}
                />
                <XPagination
                    resultTotal={pagiNation.resultTotal}
                    pageSize={pagiNation.pageSize}
                    pageNum={pagiNation.pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TypeTable.propTypes = {
    renderData: PropTypes.array, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    pagiNation: PropTypes.object, // 分页数据
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TypeTable;