/**
 * 基本设置
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Avatar, message } from 'antd';
import { XTitle, XInput, XTextArea, XOKButton } from '@/components/xqxc_ui';
import CustomUpload from '@/components/NewUpload';
import { getLocalStorage } from '@/assets/js/storage';
import http from '@/assets/api/http';
import { setLocalStorage } from '@/assets/js/storage';

class BaseSetting extends React.Component {

    state = {
        uploadFiles: [], // 头像上传
        avatar: '', // 头像路径
        emplName: '', // 用户名称
        remark: '', // 用户简介
        tel: '', // 用户电话
        loginData: '', // 用户登录信息
    }

    componentDidMount() {
        const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
        const loginData = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')) : null;
        const { emplName, remark, tel, avatar } = userInfo;
        this.setState({
            emplName, remark, tel, avatar, loginData
        });
    }

    _clickHandle = () => {
        const { uploadFiles, emplName, remark, tel, avatar, loginData } = this.state;
        let avatarURL = avatar;
        if (uploadFiles.length > 0) {
            avatarURL = uploadFiles[0].response.key;
        }
        const data = {
            avatar: avatarURL,
            emplName,
            remark,
            tel
        };
        http('/admin/empl/updateEmplBaseInfo', data, 'POST').then((response) => {
            if (response.status == 200) {
                if (loginData) {
                    let auth = { ...loginData };
                    auth.userInfo = response.result;
                    setLocalStorage('auth', auth);
                }
                message.success('更新成功！');
                location.reload();
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    render() {
        const { uploadFiles, avatar, emplName, remark, tel } = this.state;

        return (
            <div className={styles.flexBoxContainer}>
                <XTitle label='基本设置' />
                <div className={styles.uploadAvatar}>
                    <CustomUpload
                        accept='.png, .jpg, .jpeg'
                        wallMode="single"
                        showUploadList={false}
                        fileList={uploadFiles}
                        qiniuUploadProps={{
                            uploadType: 'image'
                        }}
                        onFileListChange={(info) => {
                            if (info.fileList.length > 0 && info.fileList[(info.fileList.length - 1)].status == 'success') {
                                this.setState({ uploadFiles: info.fileList, avatar: info.fileList[(info.fileList.length - 1)].url });
                            }
                        }}
                    >
                        <Avatar style={{ minWidth: '120px', minHeight: '120px', cursor: 'pointer', backgroundColor: '#F86720' }} size={120} icon="user" src={avatar}></Avatar>
                    </CustomUpload>
                    <div className={styles.layer}></div>
                    <div className={styles.avatarText}>修改头像</div>
                </div>
                <XInput
                    style={{ marginTop: '30px' }}
                    inputStyle={{ width: '500px' }}
                    label='用户姓名'
                    placeholder='请输入用户姓名'
                    value={emplName}
                    bindThis={this}
                    bindName='emplName'
                />
                <XTextArea
                    style={{ marginTop: '30px' }}
                    inputStyle={{ width: '500px' }}
                    label='用户简介'
                    placeholder='请输入用户简介'
                    value={remark}
                    bindThis={this}
                    bindName='remark'
                />
                <XInput
                    style={{ marginTop: '30px' }}
                    inputStyle={{ width: '500px' }}
                    label='联系电话'
                    placeholder='请输入联系电话'
                    value={tel}
                    bindThis={this}
                    bindName='tel'
                />
                <XOKButton style={{ width: '100px', marginTop: '50px' }} label='更新信息' onClick={this._clickHandle} />
            </div>
        );
    }
}

BaseSetting.propTypes = {
    history: PropTypes.object
};

export default BaseSetting;