import * as S from './actiontypes';

/**
 * 出库单列表
 */
const plistInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
// 已分配出库单
export const outdeliveryorderList = (state = plistInital, { type, payload }) => {
    switch (type) {
        case S.SET_OUTDELIVERYORDER_LIST:
            return payload.result;
        default:
            return state;
    }
};
// 已取消出库单
export const canceldeliveryorderList = (state = plistInital, { type, payload }) => {
    switch (type) {
        case S.SET_CANCELDELIVERYORDER_LIST:
            return payload.result;
        default:
            return state;
    }
};
