/**
 * 添加支付宝
 */
import React from 'react';
import { Modal, Input, Icon, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import http from '@/assets/api/http';
import { XInput, XCheckAll, XSelect } from '@/components/xqxc_ui';


const { TextArea } = Input;

class AddBankModal extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        activityType: {},
        bankAccountFlag: false,
    }
    componentDidMount() {
        const { data } = this.props;
        if (data && data.id) {
            this.setState({
                cardHolder: data.cardHolder,
                bankAccount: data.bankAccount,
                bankAccountFlag: true,
            });
        } else {
            this.getFinancialInfo();
        }
    }
    // 获取法人和手机号
    getFinancialInfo = () => {
        http('/merchant/info/getFinancial', {}, 'POST').then((res) => {
            this.setState({
                cardHolder: res.result.legalName,
                mobile: res.result.legalTel,
            });
        }).catch((res) => {
            if (res.code != '200') {
                message.warn(res.message);
            }
        });
    }
    _handleOk = () => {
        const { cardHolder, bankAccount, bankAccountFlag } = this.state;
        const { data } = this.props;
        if (!bankAccountFlag) {
            message.warn('请输入有效的手机号或邮箱');
            return false;
        }
        let param = {
            accountTypeCode: 'ALI_PAY',
            cardHolder,
            bankAccount,
            id: data ? data.id : undefined,
        };
        http('/bankCard/save', param, 'POST').then((res) => {
            if (data && data.id) {
                message.success('编辑成功!');
            } else {
                message.success('添加成功!');
            }
            this.setState({
                visible: false,
            });
            this.props.closeMode();
            this.props.refsh();
        }).catch((res) => {
            message.warn(res.message);
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    _onChange = e => {
        this.setState({ bankAccount: e });
    }
    _bankAccountChange = e => {
        let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
        let regMail = /^[0-9a-zA-Z._-]+[@][0-9a-zA-Z._-]+([.][a-zA-Z]+){1,2}$/;
        if (e.target.value && (reg.test(e.target.value) || regMail.test(e.target.value))) {
            this.setState({ bankAccountFlag: true });
        } else if (e.target.value == '') {
            this.setState({ bankAccountFlag: false });
        } else {
            this.setState({ bankAccountFlag: false });
            message.warn('请输入有效的手机号或邮箱!');
        }
    }

    render() {
        const { visible, title, bankAccount, cardHolder } = this.state;

        return (
            <Modal
                width={470}
                style={{ top: 150 }}
                title={title}
                visible={visible}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ height: 50 }}
                        inputStyle={{ width: '344px' }}
                        label='支付宝实名'
                        disabled
                        placeholder='法人名称,只读'
                        value={cardHolder}
                        bindThis={this}
                        bindName='cardHolder'
                        isRequired
                    />
                    <XInput
                        style={{ height: 50 }}
                        inputStyle={{ width: '344px' }}
                        label='支付宝账号'
                        placeholder='请填写'
                        value={bankAccount}
                        // bindThis={this}
                        // bindName='bankAccount'
                        onChange={this._onChange}
                        onBlur={this._bankAccountChange}
                        isRequired
                    />
                </div>
            </Modal>
        );
    }
}

AddBankModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    refsh: PropTypes.func,
};

export default AddBankModal;