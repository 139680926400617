import { createModel } from '@/store/tools';


const model = {
    namespace: 'activityGoodsData',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getExitList': T('getExitList'),
        },
        actions: {
            'getExitList': A('getExitList'), // 申请退出活动列表数据  
        },
        sagas: {
            'getExitList': S('getExitList', '/withdrawActivity/listWithdraw'),
        },
        reducers: {
            'exitList': R('getExitList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
        }
    })
};
export default createModel(model);