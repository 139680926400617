/**
 * 【蚁购商品对接】
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, XSelect, } from '@/components/xqxc_ui';
import ProductTable from './components/ProductTable';
import *as actions from '@/reducers/warehousemanage/productreserve/actions';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';


// 弹框枚举类型
const Mode = {
    UPDATA_RESERVE: 'UPDATA_RESERVE', // 更新库存弹框
    UPDATE_PRICE: 'UPDATE_PRICE', // 更新结算价弹框
};

class Main extends React.Component {

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         expandBoolean: false, // 是否展开
    //         goodsName: '', // 商品名称
    //         categoryId: '', // 商品联级分类
    //         goodsNo: '', // 商品货号
    //         tags: [], // 商品标签
    //         goodsStateEnum: {}, // 商品状态
    //         goodsTypeEnum: {}, // 商品业务类型
    //         currentMode: '', // 当前弹框
    //         openModeParam: [], // 当前需要打开的弹窗的入参
    //         stockData: {}
    //     };
    //     this.searchCache = { pageNum: 1, pageSize: 15 }; // 查询缓存
    // }
    state = {
        expandBoolean: false, // 是否展开
        goodsName: '', // 商品名称
        categoryId: '', // 商品联级分类
        goodsNo: '', // 商品货号
        tags: [], // 商品标签
        goodsStateEnum: {}, // 商品状态
        goodsTypeEnum: {}, // 商品业务类型
        currentMode: '', // 当前弹框
        openModeParam: [], // 当前需要打开的弹窗的入参
        stockData: {}
    };
    componentDidMount() { }

    // 查询
    _searchHandle = (useCache) => {
        const { getWarehouseStockList } = this.props;
        const { pageNum, pageSize, goodsName, goodsStateEnum, goodsNo, goodsTypeEnum, tags, categoryId } = this.state;
        //商品标签数据处理
        let shopTags = new Array();
        for (let i = 0; i < tags.length; i++) {
            shopTags.push(tags[i].id);
        }
        this.searchCache = {
            goodsState: goodsStateEnum.code,//商品状态  
            goodsName,//商品名称  
            goodsNo,//商品货号  
            goodsType: goodsTypeEnum.code,//商品业务类型
            tagIds: shopTags,//标签ID  
            categoryId,//分类ID  
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        getWarehouseStockList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ goodsName: '', goodsStateEnum: {}, goodsNo: '', goodsTypeEnum: {}, tags: [], categoryId: '', prodCascade: ''}, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 分类搜索
    _onCascadeSearch = (value) => {
        console.log(value);
        this.setState({ prodCascade: value });
    }

    // 展开与收缩 监听
    _expandChangeHandle = (expandBoolean) => {
        this.setState({ expandBoolean });
    }

    // 导入最新库存
    _importReserveHandle = () => {
        console.log('导入最新库存');
    }

    // 导入结算价
    _importPriceHandle = () => {
        console.log('导入结算价');
    }

    // 导出
    _exportHandle = () => {
        console.log('导出');
    }

    // 表格操作
    _tableAction = (key, type) => {
        switch (type) {
            case 'updateReserve':
                http('/goods/inventory/findGoodsInventoryInfo', {
                    goodsId: key,
                }, 'POST').then((res) => {
                    this.setState({
                        currentMode: Mode.UPDATA_RESERVE,
                        openModeParam: res.result,
                    });
                }).catch(() => {
                });
                break;
            case 'updatePrice':
                this.setState({ currentMode: Mode.UPDATE_PRICE });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        // this.searchCache = { ...this.searchCache, pageSize, pageNum };
        // this.props.getWarehouseStockList(this.searchCache);
        // KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】

        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }
    render() {
        const { goodsName, goodsNo, expandBoolean, goodsTypeEnum, goodsStateEnum, tags } = this.state;
        const { tableSource, paginations, } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria}>
                        <XInput
                            style={{ width: '248px' }}
                            inputStyle={{ width: '180px' }}
                            label='分类名称'
                            placeholder='请输入分类名称'
                            value={goodsName}
                            bindThis={this}
                            bindName='goodsName'
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '200px' }}
                            label='状态'
                            placeholder='请选择状态'
                            renderData={[]}
                            dataIndex='value'
                            keyIndex='code'
                            value={''}
                            showSearch={true}
                            bindThis={this}
                            bindName='importState'
                        />
                        <XOKButton style={{ marginLeft: '20px' }} label='查询' onClick={this._searchHandle} />
                        <XCancelButton style={{ marginLeft: '20px' }} label='重置' onClick={this._resetHandle} />
                    </div>
                    <div className={styles.export}>
                        <XOKButton label='下载对接分类' style={{ width: 120, marginTop: 10 }} onClick={this._downloadHandle} />
                    </div>
                    <ProductTable renderData={tableSource} tableAction={this._tableAction} paginationChange={this._paginationChange} paginations={paginations} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    getWarehouseStockList: PropTypes.func, // 表格数据源的Action
    tableSource: PropTypes.array, // 表格数据源
    paginations: PropTypes.object, // 分页数据
};


const mapStateToProps = (state) => ({
    tableSource: state.productReserve.warehouseStockListDate.tableSource,
    paginations: state.productReserve.warehouseStockListDate.paginations,
});

export default connect(mapStateToProps, actions)(Main);