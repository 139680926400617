import React, { useEffect, useState } from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

const TableContent = ({
    renderData: {
        dataList,
        pageNum,
        pageSize,
        resultTotal
    },
    paginationChange,
    jumpPage,
    sendRequest,
    batchKeys = [],
    bindThis = {}
}) => {

    const [keys, setKeys] = useState([]);

    useEffect(() => { setKeys(batchKeys); }, [batchKeys]);

    // 表格复选输入
    const rowSelection = {
        selectedRowKeys: keys,
        onChange: (values) => {
            bindThis.setState({ batchKeys: values });
        }
    };
    // 清空表格复选框
    const cleanAllSelected = () => {
        bindThis.setState({ batchKeys: [] });
    };

    //表格操作
    const operateCell = (key, item) => {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => jumpPage(item.outerGoodsPriId)}>详情</div>
                <div className={styles.item} onClick={() => sendRequest('/goods/inventory/lock/rmmove', { ids: [item.id] })}>移除</div>
            </div>
        );
    };

    const columns = [
        {
            title: '商品图片',
            dataIndex: 'mainImgUrl',
            key: 'mainImgUrl',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: ImgCell
        },
        {
            title: '货品',
            dataIndex: 'goodsBarcode',
            key: 'goodsBarcode',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '货品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '锁定时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '锁定人',
            dataIndex: 'gmtUserName',
            key: 'gmtUserName',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: operateCell
        }
    ];

    return (
        <div className={styles.tableContainer}>
            <XTableSelectionCount
                selectedNum={keys.length}
                onClean={cleanAllSelected}
            />
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowSelection={rowSelection}
                rowKey={(record, index) => record.id}
                columns={columns}
                dataSource={dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};
TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
    jumpPage: PropTypes.func,
    sendRequest: PropTypes.func,
    batchKeys: PropTypes.array,
    bindThis: PropTypes.object
};
export default TableContent;