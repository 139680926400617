import React from 'react';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import CategoryList from '../categorylist';

class Main extends React.Component {

    state = {
        tab: [
            { id: 'icon', label: '图标分类' },
            { id: 'pic', label: '图片分类' },
            { id: 'video', label: '视频分类' }
        ],
        curTabID: 'icon',
        title: '图标分类',

        iconConditions: { type: 'icon', isExcludeLocalUpload: '1' },
        picConditions: { type: 'pic', isExcludeLocalUpload: '1' },
        videoConditions: { type: 'video', isExcludeLocalUpload: '1' },
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID, item) => {
        this.setState({ curTabID: tabID, title: item.label });
    }

    render() {
        const { curTabID, title } = this.state;
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={`${styles.page} ${styles.flexBoxContainer}`}>
                    <CategoryList key={curTabID} curTabID={curTabID} title={title}
                        isRequestPageData
                        initConditions={this.state[curTabID + 'Conditions']}
                        updateConditionCallBack={(CategoryListConditions) => this.setState({ [curTabID + 'Conditions']: CategoryListConditions })}
                    />
                </div>
            </div>
        );
    }
}

export default Main;