import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

const columns = (TableAction, actionClickHandle) => [
	{
		title: '商品图片',
		dataIndex: 'mainImage',
		key: 'mainImage',
		align: 'center',
		width: '10%',
		render: ImgCell
	},
	{
		title: '商品标题',
		dataIndex: 'productName',
		key: 'productName',
		align: 'center',
		width: '15%',
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '同城分类',
		dataIndex: 'platCategoryFullName',
		key: 'platCategoryFullName',
		align: 'center',
		width: '14%',
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '品牌名称',
		dataIndex: 'brandName',
		key: 'brandName',
		align: 'center',
		width: '12%',
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '状态',
		dataIndex: 'isSync',
		key: 'isSync',
		align: 'center',
		width: '10%',
		onCell: tooltipStyle,
		render: (text, item) => renderStatusCell(text, item)
	},
	{
		title: '同步时间',
		dataIndex: 'syncTime',
		key: 'syncTime',
		align: 'center',
		width: '12%',
		onCell: tooltipStyle,
		render: tooltip
	},
	{
		title: '操作',
		dataIndex: 'productId',
		key: 'productId',
		align: 'center',
		width: '12%',
		render: (id) => TableAction(id, actionClickHandle)
	},
];

// 启用禁用分类
const renderStatusCell = (text) => {
	let str = '未已'.charAt(parseInt(text)) + '同步';
	return (<>{str}</>);
};

// 表格操作
const TableAction = (id, actionClickHandle) => {
	return (
		<div className={styles.action}>
			<div className={styles.item} onClick={() => actionClickHandle(id, 'detail')}>详情</div>
		</div>
	);
};

class TableData extends Component {

	// 操作表格
	_actionHandle = (id, type, value) => {
		const { tableAction } = this.props;
		tableAction(id, type, value);
	}

	//处理表格数据
	_processingData = (data) => {
		return data;
	}

	// 表格复选输入
	_rowSelection = {
		onChange: (selectedRowKeys) => {
			this._actionHandle(null, 'select', selectedRowKeys);
		}
	};

	render() {
		const { renderData, selectedRowKeys } = this.props;
		this._rowSelection.selectedRowKeys = selectedRowKeys;
		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey='productId'
					rowSelection={this._rowSelection}
					columns={columns(TableAction, this._actionHandle)}
					pagination={false}
					dataSource={this._processingData(renderData.dataList)}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={this.props.paginationChange} />
			</div>
		);
	}
}


TableData.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
	selectedRowKeys: PropTypes.array
};



export default TableData;