import { createModel } from '@/store/tools';

const dataList = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };
const model = {
    namespace: 'customerList',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getlistShopKfGroupInfo': T('getlistShopKfGroupInfo'), // 列表
            'getfindShopDropDownInfoList': T('getfindShopDropDownInfoList'), // 店铺下拉
            'getShopKfGroupInfo': T('getShopKfGroupInfo'), // 详情
        },
        actions: {
            'getlistShopKfGroupInfo': A('getlistShopKfGroupInfo'),
            'getfindShopDropDownInfoList': A('getfindShopDropDownInfoList'),
            'getShopKfGroupInfo': A('getShopKfGroupInfo'),
        },
        sagas: {
            'getlistShopKfGroupInfo': S('getlistShopKfGroupInfo', '/shopKfGroupInfo/listShopKfGroupInfo'),
            'getfindShopDropDownInfoList': S('getfindShopDropDownInfoList', '/shopKfGroupInfo/findShopDropDownInfoList'),
            'getShopKfGroupInfo': S('getShopKfGroupInfo', '/shopKfGroupInfo/getShopKfGroupInfo'),
        },
        reducers: {
            'listShopKfGroupInfo': R('getlistShopKfGroupInfo', dataList),
            'ShopDropDownInfoList': R('getfindShopDropDownInfoList', []),
            'ShopKfGroupInfo': R('getShopKfGroupInfo', { groupNo: 333 }),
        }
    })
};
export default createModel(model);