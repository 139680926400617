import React, { Component } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';
import { Tag, message, Button, Icon } from 'antd';
import BillQueryTable from './components/BillQueryTable';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XSelect, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import model from '@/reducers/storehousefinancialmanage/billquery/model';
import { regFenToYuan } from '@/util/money';

class BillQuery extends Component {

    static propTypes = {
        history: PropTypes.object,
        addOneBreadcrumbPath: PropTypes.func,
        keepSecondNavBreadcrumb: PropTypes.func,
        getBillQueryList: PropTypes.func,
        billQueryList: PropTypes.object,
        getStatusEnum: PropTypes.func,
        statusEnum: PropTypes.array,
        getTypeEnum: PropTypes.func,
        typeEnum: PropTypes.array,
        getTimeLabelEnum: PropTypes.func,
        timeLabelEnum: PropTypes.array,
        timeLabelChange: PropTypes.func,
        resetTimeLabel: PropTypes.func,
        getBalanceQuery: PropTypes.func,
        balanceQuery: PropTypes.object
    }

    static defaultProps = {
        billQueryList: { dataList: [{ one: 1 }], pageNum: 1, pageSize: 30, resultTotal: 0 },
        statusEnum: [],
        typeEnum: [],
        timeLabelEnum: [],
        balanceQuery: {}
    }

    state = {
        startTime: null,
        endTime: null,
        statusItem: {},
        typeItem: {},
        timeLabel: ''
    }

    componentDidMount() {
        const { getStatusEnum, getTypeEnum, getTimeLabelEnum, getBalanceQuery, keepSecondNavBreadcrumb } = this.props;
        getStatusEnum();
        getTypeEnum();
        getTimeLabelEnum();
        getBalanceQuery();
        keepSecondNavBreadcrumb();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { startTime, endTime, statusItem, typeItem, timeLabel, pageNum, pageSize } = this.state;
        this.searchCache = {
            startTime, endTime, timeLabel,
            status: statusItem.code,
            transactionType: typeItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getBillQueryList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ startTime: null, endTime: null, statusItem: {}, typeItem: {}, timeLabel: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        this.props.resetTimeLabel();
    }

    //跳转页面
    _updatePage = (title, url) => {
        const { history, addOneBreadcrumbPath } = this.props;
        const baseUrl = '/home/storehousefinancialmanage/billquery';
        addOneBreadcrumbPath({
            title, path: baseUrl
        });
        history.push({
            pathname: `${baseUrl}${url}`
        });
    }

    //获取最新数据
    _newestData = () => {
        http('/mer/reconciliation/news/record', {}, 'POST')
            .then(res => {
                message.success(res.message);
                this._searchHandle('useCache');
            })
            .catch(e => {
                message.error(e.message);
            });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { billQueryList, statusEnum, typeEnum, timeLabelEnum, timeLabelChange, resetTimeLabel, balanceQuery } = this.props;
        const { startTime, endTime, statusItem, typeItem } = this.state;
        return (
            <KeepAlive id='BillQuery' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XDatePicker
                            label='结算日期'
                            bindThis={this}
                            bindName='startTime'
                            onChange={() => {
                                resetTimeLabel();
                                this.setState({ timeLabel: '' });
                            }}
                            value={startTime}
                            showTime={false}
                            isFormat
                            isDateFormat
                            defaultTime='00:00:00'
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            bindThis={this}
                            bindName='endTime'
                            value={endTime}
                            onChange={() => {
                                resetTimeLabel();
                                this.setState({ timeLabel: '' });
                            }}
                            showTime={false}
                            isFormat
                            isDateFormat
                            defaultTime='23:59:59'
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '200px' }}
                            label='结算状态'
                            placeholder='请选择结算状态'
                            renderData={statusEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={statusItem.value}
                            bindThis={this}
                            bindName='statusItem'
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '200px' }}
                            label='交易类型'
                            placeholder='请选择交易类型'
                            renderData={typeEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={typeItem.value}
                            bindThis={this}
                            bindName='typeItem'
                        />
                        <XOKButton
                            style={{ width: '60px', marginLeft: '20px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.shortcutBox}>
                        {
                            timeLabelEnum.map(item => {
                                return <Tag
                                    key={item.code}
                                    style={{ width: '60px', textAlign: 'center', cursor: 'pointer' }}
                                    color={item.active ? '#FF6A0A' : ''}
                                    onClick={() => {
                                        this.setState({ startTime: null, endTime: null, timeLabel: item.code }, () => {
                                            this._searchHandle('useCache');
                                        });
                                        timeLabelChange(item.code);
                                    }}
                                >{item.value}</Tag>;
                            })
                        }
                    </div>
                    <div className={styles.shortcutBox}>
                        <XOKButton
                            style={{ width: '150px' }}
                            label='获取银行最新数据'
                            onClick={this._newestData} />
                    </div>
                    <div className={styles.tipInfo}>
                        <Icon type="info-circle" theme="twoTone" />
                        <span style={{ padding: '0 10px' }}>当前账户可用余额：{regFenToYuan(balanceQuery.balance)}元</span>
                        <Button
                            type="link"
                            size='small'
                            onClick={() => this._updatePage('账户交易明细', '/accountinfo')}>查看交易明细</Button>
                    </div>
                    <BillQueryTable
                        renderData={billQueryList}
                        paginationChange={this._paginationChange}
                        tableOperate={this._updatePage}
                    />
                </div>
            </KeepAlive>
        );
    }
}

export default connect(state => ({
    ...state.billQuery
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(BillQuery);