/** 
 * 异步请求总控中心
 * put相当于redux的dispatch的作用
 * */
import { fork, all } from 'redux-saga/effects';
import homeSagas from '@/reducers/home/combineSaga';
import productMageSagas from '@/reducers/productmanage/combineSaga';
import merchantListSagas from '@/reducers/businessmanage/combineSaga';
import addressSagas from '@/reducers/address/combineSaga';
import ordermanageSagas from '@/reducers/ordermanage/combineSaga';
import warehouseSagas from '@/reducers/warehousemanage/combineSaga';
import storehouseSagas from '@/reducers/storehousemanage/combineSaga';
import deliverSagas from '@/reducers/delivermanage/combineSaga';
import systemmanageSgags from '@/reducers/systemmanage/combineSaga';
import marketingmanageSagas from '@/reducers/marketingmanage/combineSaga';
import suppliermanageSagas from '@/reducers/suppliermanage/combineSaga';
import storehousefinancialmanage from '@/reducers/storehousefinancialmanage/combineSaga';
import illegalRecordSagas from '@/reducers/illegalRecord/combineSaga';
import auditManageSagas from '@/reducers/auditManage/combineSaga';
import commentSagas from '@/reducers/commentmanage/combineSaga';
import permissionsSagas from '@/reducers/permissionsmanage/combineSaga';
import saleSagas from '@/reducers/salemanage/combineSaga';
import servicelistingmanageSagas from '@/reducers/servicelistingmanage/combineSaga';
import strategymanage from '@/reducers/strategymanage/combineSaga';
import gdcmanage from '@/reducers/gdcmanage/combineSaga';
import commercialcollege from '@/reducers/commercialcollege/combineSaga';
import beanshopmanage from '@/reducers/beanshopmanage/combineSaga';
import contractmanageSagas from '@/reducers/contractmanage/combineSaga';
import statementmanage from '@/reducers/statementmanage/combineSaga';
import receiveordermanage from '@/reducers/receiveordermanage/combineSaga';
import houseresourcemanage from '@/reducers/houseresourcemanage/combineSaga';
import checkinverification from '@/reducers/checkinverification/combineSaga';
import messagemanage from '@/reducers/messagemanage/combineSaga';
import supplierwarehouseSagas from '@/reducers/supplierwarehouse/combineSaga';
import expresswaybill from '@/reducers/expresswaybill/combineSaga';
import customermanageSagas from '@/reducers/customermanage/combineSaga';
import chargeoffmanage from '@/reducers/chargeoffmanage/combineSaga';

const combineSaga = (sagas, modules) => {
    let result = [...sagas];
    for (let i = 0; i < modules.length; i++) {
        const module = modules[i];
        result = result.concat(module.map((item) => {
            return fork(item);
        }));
    }
    return result;
};

const modules = [
    homeSagas,
    addressSagas,
    productMageSagas,
    merchantListSagas,
    ordermanageSagas,
    warehouseSagas,
    deliverSagas,
    systemmanageSgags,
    marketingmanageSagas,
    storehouseSagas,
    suppliermanageSagas,
    storehousefinancialmanage,
    illegalRecordSagas,
    auditManageSagas,
    commentSagas,
    permissionsSagas,
    saleSagas,
    servicelistingmanageSagas,
    strategymanage,
    gdcmanage,
    commercialcollege,
    contractmanageSagas,
    beanshopmanage,
    houseresourcemanage,
    checkinverification,
    statementmanage,
    receiveordermanage,
    messagemanage,
    supplierwarehouseSagas,
    expresswaybill,
    customermanageSagas,
    chargeoffmanage
];

let sagas = [];
sagas = combineSaga(sagas, modules);
export default function* rootSaga() {
    yield all(sagas);
}

