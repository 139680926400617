import React from 'react';
import styles from './ProductTable.module.scss';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table, Tooltip, message } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, priceFenToYuanCell, ImgCell } from '@/components/TableCell';

class ProductTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [], // 选中的单元行
        };

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.selectedRowKeys.length === 0 && nextProps.goodsIds && nextProps.goodsIds.length) {
            this.setState({ selectedRowKeys: nextProps.goodsIds });
        }
    }

    _takeColumns = (isGDC) => ([
        {
            title: '商品图片',
            dataIndex: 'mainImgUrl',
            key: 'mainImgUrl',
            width: 100,
            align: 'center',
            render: ImgCell
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品货号',
            dataIndex: 'goodsNo',
            key: 'goodsNo',
            width: 120,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品分类',
            dataIndex: 'categoryName',
            key: 'categoryName',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品条码',
            dataIndex: 'barcode',
            key: 'barcode',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品销售价(元)',
            dataIndex: 'salePrice',
            key: 'salePrice',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '业务类型',
            dataIndex: 'goodsType',
            key: 'goodsType',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品状态',
            dataIndex: 'goodsState',
            key: 'goodsState',
            width: 80,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否虚拟商品',
            dataIndex: 'virtualFlag.value',
            key: 'virtualFlag.value',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '更新时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: 120,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'action',
            align: 'center',
            width: 180,
            render: (text, item) => this._renderActionCell(item.goodsId, item)
        }
    ].filter(v => v.key !== 'virtualFlag.value' ? true : isGDC));

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderActionCell = (key, item) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'detail', item)}>详情</div>
                {
                    (item.goodsState != '上架' && item.goodsTypeCode !== 'FAST_GOODS') ? <div className={styles.item} onClick={() => tableAction(key, 'edit')}>编辑</div> : null
                }
                {
                    item.goodsTypeCode === 'FAST_GOODS' ?
                        null :
                        <div className={`${styles.item} ${item.goodsState == '上架' ? styles.showHidden : null}`} onClick={() => tableAction(key, 'delect')}>删除</div>
                }
                {
                    item.goodsTypeCode === 'FAST_GOODS' ?
                        null :
                        <div className={`${styles.item} ${item.goodsState == '上架' ? null : styles.showHidden}`} onClick={() => tableAction(item.goodsId, 'soldOut')}>下架</div>
                }
                {
                    item.goodsTypeCode === 'FAST_GOODS' ?
                        null :
                        <div
                            className={`${styles.item} ${item.goodsState == '审核成功' || item.goodsState == '下架' ? null : styles.showHidden}`}
                            onClick={() => tableAction(item.goodsId, 'putaway')}
                        >上架</div>
                }
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys) => {
                this.setState({ selectedRowKeys });
                this.props.tableAction(null, 'batch', selectedRowKeys);
            },
            getCheckboxProps: record => ({
                disabled: record.goodsTypeCode === 'FAST_GOODS', // Column configuration not to be checked
                name: record.goodsId,
            }),
        };
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
        this.props.tableAction(null, 'batch', []);
    }

    render() {
        const { paginationChange, isGDC } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={this.state.selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection()}
                    rowKey={record => record.goodsId}
                    columns={this._takeColumns(isGDC)}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

ProductTable.propTypes = {
    goodsIds: PropTypes.array,
    isGDC: PropTypes.bool,
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    batchData: PropTypes.array
};

export default ProductTable;