/**
 * 集采交易管理saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as T from './actiontypes';
import http from '@/assets/api/http';

export const getCollectMoneyList = function* () {
    yield takeEvery(T.GET_COLLECT_LIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/getCrowdfundingOrderList', action.payload, 'POST');
            yield put({ type: T.SET_COLLECT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getCollectMoneyInfo = function* () {
    yield takeEvery(T.GET_COLLECT_INFO, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/getCrowdfundingOrderDetails', action.payload, 'POST');
            yield put({ type: T.SET_COLLECT_INFO, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

