import React, { useState, useEffect } from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Icon, InputNumber, Typography } from 'antd';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';

const { Text } = Typography;

const TableContent = ({ tableOperate, pageChange, goodsIds, renderData: { dataList, resultTotal, pageNum, pageSize } }) => {

    const [keys, setKeys] = useState([]);
    const [keyChange, setKeysChange] = useState('');

    useEffect(() => {
        setKeys(goodsIds);
    }, [goodsIds]);

    const tableOperateCell = (id, item) => {
        return <Icon
            style={{ fontSize: 24, fontWeight: 'bolder' }}
            type='vertical-align-top'
            onClick={() => tableOperate('top', item.goodsId)}
        />;
    };

    const sortCell = (text, item) => {
        if (item.goodsId === keyChange) {
            return <>
                <InputNumber
                    precision={0}
                    defaultValue={text ? text : 0}
                    onBlur={e => {
                        setKeysChange('');
                        tableOperate('updateSortNo', { sortNo: e.target.value, goodsId: item.goodsId });
                    }}
                />
                <Icon
                    type='edit'
                    theme='twoTone'
                    style={{ fontSize: 16, marginLeft: 5 }}
                    onClick={() => {
                        setKeysChange('');
                    }}
                />
            </>;
        }
        return <>
            <Text>{text ? text : 0}</Text>
            <Icon
                type='edit'
                theme='twoTone'
                style={{ fontSize: 16, marginLeft: 5 }}
                onClick={() => {
                    setKeysChange(item.goodsId);
                }}
            />
        </>;
    };

    const columns = [
        {
            title: '显示序号',
            dataIndex: 'mainImgUrl',
            key: 'mainImgUrl',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: (text, item, index) => (pageNum > 1 ? index + pageSize + 1 : index + 1)
        },
        {
            title: '商品图片',
            dataIndex: 'mainImgUrl',
            key: 'mainImgUrl',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: ImgCell
        },
        {
            title: '货号',
            dataIndex: 'goodsNo',
            key: 'goodsNo',
            align: 'center',
            width: '15%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'center',
            width: '15%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '推荐时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            align: 'center',
            width: '15%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '排序',
            dataIndex: 'sortNo',
            key: 'sortNo',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: sortCell
        },
        {
            title: '操作',
            dataIndex: 'goodsId',
            key: 'goodsId',
            align: 'center',
            width: '15%',
            onCell: tooltipStyle,
            render: tableOperateCell
        },
    ];

    //表格复选输如
    const rowSelection = {
        selectedRowKeys: keys,
        onChange: selectedRowKeys => {
            setKeys(selectedRowKeys);
            tableOperate('goodsIds', selectedRowKeys);
        },
    };

    // 清空表格复选框
    const onCleanAllSelected = () => {
        setKeys([]);
        tableOperate('goodsIds', []);
    };

    return <div className={styles.tableContainer}>
        <XTableSelectionCount
            selectedNum={keys.length}
            onClean={onCleanAllSelected}
            suffixText='按排序1排在最前面，数字相同时再按推荐时间降序。最多30个商品.'
        />
        <Table
            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
            rowSelection={rowSelection}
            columns={columns}
            pagination={false}
            rowKey={record => record.goodsId}
            dataSource={dataList}
            scroll={{ y: true }}
        />
        <XPagination
            resultTotal={resultTotal}
            pageSize={pageSize}
            pageNum={pageNum}
            onChange={pageChange} />
    </div>;
};

TableContent.propTypes = {
    tableOperate: PropTypes.func,
    renderData: PropTypes.object,
    pageChange: PropTypes.func,
    goodsIds: PropTypes.array
};

export default TableContent;