/**
 * 仓库管理saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as S from './actiontypes';
import http from '@/assets/api/http';

// 未分配订单列表
export const getUnassignedoOrderList = function* () {
    yield takeEvery(S.GET_UNASSIGNED_LIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/getOrderSplitFailList', action.payload, 'POST');
            yield put({ type: S.SET_UNASSIGNED_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


