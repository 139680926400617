import { createModel } from '@/store/tools';

const model = {
    namespace: 'goodsConnect',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getFindFenlaiShopGoodsInfo': T('getFindFenlaiShopGoodsInfo'),
            'getStateEnum': T('getStateEnum'),
            'getTypeEnum': T('getTypeEnum'),
            'getClassSels': T('getClassSels'),
            'getGoodsInfo': T('getGoodsInfo'),
        },
        actions: {
            'getFindFenlaiShopGoodsInfo': A('getFindFenlaiShopGoodsInfo'),
            'getStateEnum': A('getStateEnum'),
            'getTypeEnum': A('getTypeEnum'),
            'getClassSels': A('getClassSels'),
            'getGoodsInfo': A('getGoodsInfo'),
        },
        sagas: {
            'getFindFenlaiShopGoodsInfo': S('getFindFenlaiShopGoodsInfo', '/goods/outer/findFenlaiShopGoodsInfo', 'POST', 'outerGoodsId'),
            'getStateEnum': S('getStateEnum', '/enum/OuterGoodsStateEnum'),
            'getTypeEnum': S('getTypeEnum', '/enum/OutGoodsTypeEnum'),
            'getClassSels': S('getClassSels', '/out/supplier/category/dropDown'),
            'getGoodsInfo': S('getGoodsInfo', '/out/supplier/goods/detail'),
        },
        reducers: {
            'FindFenlaiShopGoodsInfo': R('getFindFenlaiShopGoodsInfo', {}),
            'stateEnum': R('getStateEnum', []),
            'typeEnum': R('getTypeEnum', []),
            'classSels': R('getClassSels', []),
            'goodsInfo': R('getGoodsInfo', {}),
        }
    })
};
export default createModel(model);