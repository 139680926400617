/**
 *  【仓库奢批结算价调整提示】入口页
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const PriceAdjustment = ({ history }) => {
    return <Main history={history} />;
};

PriceAdjustment.propTypes = {
    history: PropTypes.object
};

export default PriceAdjustment;