/**
 * 商品列表-商品库存
 */
import React from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XSelect, XOKButton, XCancelButton, XInput, XExpandOrContract } from '@/components/xqxc_ui';
import TableContent from './components/TableData';
import SetInventory from '../../modal/SetInventory';
import { getProductStatus, getProductBusinessType, getProductClassifySele, getProductLabelSele } from '@/reducers/productmanage/list/actions';
import { getInventoryData } from '@/reducers/productmanage/inventory/actions';
import { GoodsCategory } from '@/vcomps';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectDataSource: [],
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: '', // 当前需要打开的弹窗的入参
            isShowMore: false,
            goodsName: '',
            username: {},
            goodsNo: '',
            status: {},
            professionType: {},
            label: {},
            category: {},
            batchData: [],
            expandBoolean: false, // 是否展开
            categorySubType: {}
        };
        this.goodsCategory = React.createRef();
    }

    componentDidMount() {
        this.props.getProductStatus();
        this.props.getProductBusinessType();
        this.props.getProductLabelSele();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { goodsName, goodsNo, professionType, category, pageNum, pageSize, status, label, categorySubType } = this.state;
        this.searchCache = {
            goodsName, goodsNo, goodsState: status.code, goodsType: professionType.code, categoryId: category.id, tagIds: label.id,
            categorySubType: categorySubType.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getInventoryData(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ goodsName: '', goodsNo: '', professionType: {}, category: {}, status: {}, categorySubType: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        this.goodsCategory.current.clean();
    }

    // _sendRequest = (url, data, winTxt, failTxt) => {
    //     http(url,data,'POST')
    //     .then(()=>{
    //         message.success(winTxt);
    //         const { goodsName,goodsNo,professionType,category,pageNum,pageSize,status,tagIds} =this.state;
    //         this.props.getProductListData({ goodsName,goodsNo,goodsState:status.code,goodsType:professionType.code,categoryId:category.id,tagIds,pageNum,pageSize});
    //         }).catch(() => { 
    //         message.error(failTxt);
    //     });
    // }

    // _batch = (type) => {
    //     const {batchData} = this.state;
    //     switch(type){
    //         case 'submit':
    //             this._sendRequest('/goods/goods/submitAuditGoods',{goodsIds:batchData},'批量提交成功。','批量提交失败！');
    //             break;
    //         case 'putaway':
    //             this._sendRequest('/goods/goods/putOnSaleGoods',{goodsIds:batchData},'批量上架成功。','批量上架失败！');
    //             break;
    //         case 'soldOut':
    //             this._sendRequest('/goods/goods/pullOffShelvesGoods',{goodsIds:batchData},'批量下架成功。','批量下架失败！');
    //             break;
    //     }
    // }

    // 表格操作
    _tableAction = (id, type, value) => {
        switch (type) {
            case 'setInventory':
                this.setState({ currentOpenMode: 'SETINVENTORY', openModeParam: value.skuId });
                break;
            case 'batch':
                this.setState({ batchData: value });
                break;
        }
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    // 打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'SETINVENTORY':
                return <SetInventory title='设置服务商库存' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' }, () => {
            update && this._searchHandle('useCache');
        });
    }

    // 更多搜索条件的展开与收缩
    _expandChangeHandle = (expandBoolean) => {
        this.setState({ expandBoolean });
    }

    render() {
        const { expandBoolean, goodsName, goodsNo, status, professionType, label, batchData, categorySubType } = this.state;
        const { productStatus, productBusinessType, productLabelSele, inventoryData } = this.props;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='商品名称'
                            placeholder='请输入商品名称'
                            bindThis={this}
                            bindName='goodsName'
                            value={goodsName}
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '200px' }}
                            label='商品货号'
                            placeholder='请输入商品货号'
                            bindThis={this}
                            bindName='goodsNo'
                            value={goodsNo}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                        <XExpandOrContract style={{ marginLeft: '30px' }} onChange={this._expandChangeHandle} />
                    </div>
                    <div className={`${styles.searchCriteriaHidden} ${expandBoolean ? styles.moreShow : styles.moreHidden}`}>
                        <div className={styles.criteria1}>
                            <XSelect
                                style={{ marginLeft: '30px' }}
                                selectStyle={{ width: '200px' }}
                                label='状态'
                                placeholder='请选择状态'
                                renderData={productStatus}
                                bindThis={this}
                                bindName='status'
                                dataIndex='value'
                                keyIndex='code'
                                value={status.value}
                            />
                            <XSelect
                                style={{ marginLeft: '20px' }}
                                selectStyle={{ width: '200px' }}
                                label='业务类型'
                                placeholder='请选择业务类型'
                                renderData={productBusinessType}
                                bindThis={this}
                                bindName='professionType'
                                dataIndex='value'
                                keyIndex='code'
                                value={professionType.value}
                            />
                            <XSelect
                                style={{ marginLeft: '20px' }}
                                selectStyle={{ width: '200px' }}
                                label='商品标签'
                                placeholder='请选择商品标签'
                                renderData={productLabelSele}
                                bindThis={this}
                                bindName='label'
                                dataIndex='tagName'
                                keyIndex='id'
                                value={label.tagName}
                            />
                            <XSelect
                                selectStyle={{ width: '150px' }}
                                style={{ marginLeft: '20px' }}
                                label='分类类型'
                                placeholder='请选择商品分类'
                                renderData={[
                                    { code: 'COMMON', value: '通用分类' },
                                    { code: 'ONLINE', value: '线上分类' },
                                    { code: 'CITY', value: '同城分类' }
                                ]}
                                bindThis={this}
                                bindName='categorySubType'
                                dataIndex='value'
                                keyIndex='code'
                                value={categorySubType.value}
                            />
                        </div>
                        <GoodsCategory
                            label='商品分类'
                            ref={this.goodsCategory}
                            type={categorySubType.code}
                            style={{ marginTop: '20px' }}
                            onChange={(value) => this.setState({ prodCascade: value })}
                        />
                    </div>
                    <TableContent paginationChange={this._paginationChange} renderData={inventoryData} batchData={batchData} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    updateContent: PropTypes.func,
    getInventoryData: PropTypes.func,
    getProductStatus: PropTypes.func,
    getProductBusinessType: PropTypes.func,
    getProductClassifySele: PropTypes.func,
    getProductLabelSele: PropTypes.func,
    productStatus: PropTypes.array,
    productBusinessType: PropTypes.array,
    productUsername: PropTypes.array,
    productLabelSele: PropTypes.array,
    inventoryData: PropTypes.object
};

const mapStateToProps = (state) => ({
    inventoryData: state.inventoryData.inventoryData,
    productStatus: state.productListData.productStatus,
    productBusinessType: state.productListData.productBusinessType,
    productUsername: state.productListData.productUsername.merchantIdAndNames || [],
    productLabelSele: state.productListData.productLabelSele.dataList || [],
});

const allReq = { getInventoryData, getProductStatus, getProductBusinessType, getProductClassifySele, getProductLabelSele };

export default connect(mapStateToProps, allReq)(Main);