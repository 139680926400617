import { createModel } from '@/store/tools';

const dataList = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };
var num = 0;

const model = {
    namespace: 'beanshopmanage',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getlistInOutDetail': T('getlistInOutDetail'),
            'getlistBalanceLock': T('getlistBalanceLock'),
            'getTradeTypeEnum': T('getTradeTypeEnum'),
            'getStarValueSettle': T('getStarValueSettle'),
            'getFeeTypeEnum': T('getFeeTypeEnum'),
            'getStarvalueSummary': T('getStarvalueSummary'),
            'getStarvalueInOutSummary': T('getStarvalueInOutSummary'),
            'getListProfitInOutDetail': T('getListProfitInOutDetail'),
            'getProfitTypeEnum': T('getProfitTypeEnum'),
            'getBankCardList': T('getBankCardList'),
            'getBankCardDropDownList': T('getBankCardDropDownList'),
            'getFinancial': T('getFinancial'),
            'getwithdrawalRecord': T('getwithdrawalRecord'),
            'getPaymentStateEnum': T('getPaymentStateEnum'),
            'getProfitInOutSummary': T('getProfitInOutSummary'),
            'getXiudouEarnings': T('getXiudouEarnings'),
            'getXiudouEarningsStateEnum': T('getXiudouEarningsStateEnum'),
            'getMerchantAvailableWithdrawal': T('getMerchantAvailableWithdrawal'),
            'getBankCardAccount': T('getBankCardAccount'),
            'getPurchasPeXiudouOrder': T('getPurchasPeXiudouOrder'),
            'getXiudouEarningsInfo': T('getXiudouEarningsInfo'),
            'getBeanDrawApplys': T('getBeanDrawApplys'),
            'getBeanDrawEnum': T('getBeanDrawEnum'),
            'getBeanDrawApplyInfo': T('getBeanDrawApplyInfo'),
            'getBeanDrawApplyRatio': T('getBeanDrawApplyRatio'),
            'getRuleInfoMoney': T('getRuleInfoMoney'),
            'getBankCardInfo': T('getBankCardInfo'),
            'getQuestionDetail': T('getQuestionDetail'),
        },
        actions: {
            'getlistInOutDetail': A('getlistInOutDetail'),
            'getlistBalanceLock': A('getlistBalanceLock'),
            'getTradeTypeEnum': A('getTradeTypeEnum'),
            'getStarValueSettle': A('getStarValueSettle'),
            'getFeeTypeEnum': A('getFeeTypeEnum'),
            'getStarvalueSummary': A('getStarvalueSummary'),
            'getStarvalueInOutSummary': A('getStarvalueInOutSummary'),
            'getListProfitInOutDetail': A('getListProfitInOutDetail'),
            'getProfitTypeEnum': A('getProfitTypeEnum'),
            'getBankCardList': A('getBankCardList'),
            'getBankCardDropDownList': A('getBankCardDropDownList'),
            'getFinancial': A('getFinancial'),
            'getwithdrawalRecord': A('getwithdrawalRecord'),
            'getPaymentStateEnum': A('getPaymentStateEnum'),
            'getProfitInOutSummary': A('getProfitInOutSummary'),
            'getXiudouEarnings': A('getXiudouEarnings'),
            'getXiudouEarningsStateEnum': A('getXiudouEarningsStateEnum'),
            'getMerchantAvailableWithdrawal': A('getMerchantAvailableWithdrawal'),
            'getBankCardAccount': A('getBankCardAccount'),
            'getPurchasPeXiudouOrder': A('getPurchasPeXiudouOrder'),
            'getXiudouEarningsInfo': A('getXiudouEarningsInfo'),
            'getBeanDrawApplys': A('getBeanDrawApplys'),
            'getBeanDrawEnum': A('getBeanDrawEnum'),
            'getBeanDrawApplyInfo': A('getBeanDrawApplyInfo'),
            'getBeanDrawApplyRatio': A('getBeanDrawApplyRatio'),
            'getRuleInfoMoney': A('getRuleInfoMoney'),
            'getBankCardInfo': A('getBankCardInfo'),
            'getQuestionDetail': A('getQuestionDetail'),
            'getCleanDetail': C('getQuestionDetail')
        },
        sagas: {
            'getlistInOutDetail': S('getlistInOutDetail', '/settle/inoutdetail/listInOutDetail'), //获取收支明细列表
            'getlistBalanceLock': S('getlistBalanceLock', '/settle/balancelock/listBalanceLock'), //获取冻结明细列表
            'getTradeTypeEnum': S('getTradeTypeEnum', '/enum/TradeTypeEnum'), //获取交易类型
            'getStarValueSettle': S('getStarValueSettle', '/settle/reconciliationSource/queryStarValueSettle'), //获取结算明细
            'getFeeTypeEnum': S('getFeeTypeEnum', '/enum/FeeTypeEnum'), //获取状态
            'getStarvalueSummary': S('getStarvalueSummary', '/merchant/info/starvalueSummary'), //获取人气值
            'getStarvalueInOutSummary': S('getStarvalueInOutSummary', '/merchant/info/starvalueInOutSummary'), //获取人气值处理过金额
            'getListProfitInOutDetail': S('getListProfitInOutDetail', '/settle/inoutdetail/listProfitInOutDetail'), //获取收益明细
            'getProfitTypeEnum': S('getProfitTypeEnum', '/enum/ProfitTypeEnum'), //获取收益明细交易类型
            'getBankCardList': S('getBankCardList', '/bankCard/list'), //获取银行卡列表
            'getBankCardDropDownList': S('getBankCardDropDownList', '/enum/BankType'), //获取银行卡下拉
            'getBankCardInfo': S('getBankCardInfo', '/bankCard/edit'), //获取编辑银行卡获取详情
            'getFinancial': S('getFinancial', '/merchant/info/getFinancial'), //获取法人和手机号
            'getwithdrawalRecord': S('getwithdrawalRecord', '/merchant/withdraw/withdrawalRecord'), //获取支取记录
            'getPaymentStateEnum': S('getPaymentStateEnum', '/enum/MerchantWithdrawStatus'), //获取付款状态枚举
            'getProfitInOutSummary': S('getProfitInOutSummary', '/merchant/info/profitInOutSummary'), //获取收益明细的总支出，总收入，支取中
            'getXiudouEarnings': S('getXiudouEarnings', '/xiudouEarnings/list'), //获取股票分红列表
            'getXiudouEarningsStateEnum': S('getXiudouEarningsStateEnum', '/enum/XiudouEarningsStateEnum'), //获取股票分红状态
            'getMerchantAvailableWithdrawal': S('getMerchantAvailableWithdrawal', '/merchant/withdraw/merchantAvailableWithdrawal'), //获取商户账户可提现金额
            'getBankCardAccount': S('getBankCardAccount', '/bankCard/dropDownList'), //获取银行卡下拉
            'getPurchasPeXiudouOrder': S('getPurchasPeXiudouOrder', '/merchant/xiudou/order/purchaseXiudouOrder'), //获取股票确认
            'getXiudouEarningsInfo': S('getXiudouEarningsInfo', '/xiudouEarnings/info'), //获取股票收益详情
            'getBeanDrawApplys': S('getBeanDrawApplys', '/xiudou/listXiudouWithdraw'), //人气值支取申请
            'getBeanDrawEnum': S('getBeanDrawEnum', '/enum/WithdrawXiudouStateEnum'), //人气值支取申请状态
            'getBeanDrawApplyInfo': S('getBeanDrawApplyInfo', '/xiudou/getWithdrawXiudouDetail', 'POST', 'id'), //人气值支取申请状态
            'getBeanDrawApplyRatio': S('getBeanDrawApplyRatio', '/xiudou/getMerchantXiudouRateInfo', 'POST', 'starValue'), //人气值支取申请
            'getRuleInfoMoney': S('getRuleInfoMoney', '/xiudouEarnings/getRuleInfo'), //1人气值=多少元
            'getQuestionDetail': S('getQuestionDetail', '/instruction/questionDetail'), // 获取问题详情
        },
        reducers: {
            'listInOutDetail': R('getlistInOutDetail', dataList),
            'listBalanceLock': R('getlistBalanceLock', dataList),
            'TradeTypeEnum': R('getTradeTypeEnum', []),
            'StarValueSettle': R('getStarValueSettle', dataList),
            'FeeTypeEnum': R('getFeeTypeEnum', []),
            'StarvalueSummary': R('getStarvalueSummary', {}),
            'StarvalueInOutSummary': R('getStarvalueInOutSummary', {}),
            'ListProfitInOutDetail': R('getListProfitInOutDetail', dataList),
            'ProfitTypeEnum': R('getProfitTypeEnum', []),
            'BankCardList': R('getBankCardList', dataList),
            'BankCardDropDownList': R('getBankCardDropDownList', []),
            'Financial': R('getFinancial', {}),
            'withdrawalRecord': R('getwithdrawalRecord', dataList),
            'PaymentStateEnum': R('getPaymentStateEnum', []),
            'ProfitInOutSummary': R('getProfitInOutSummary', {}),
            'xiudouEarnings': R('getXiudouEarnings', dataList),
            'XiudouEarningsStateEnum': R('getXiudouEarningsStateEnum', []),
            'MerchantAvailableWithdrawal': R('getMerchantAvailableWithdrawal', {}),
            'BankCardAccount': R('getBankCardAccount', []),
            'PurchasPeXiudouOrder': R('getPurchasPeXiudouOrder', {}),
            'XiudouEarningsInfo': R('getXiudouEarningsInfo', {}),
            'beanDrawApplys': R('getBeanDrawApplys', dataList),
            'beanDrawEnum': R('getBeanDrawEnum', []),
            'beanDrawApplyInfo': R('getBeanDrawApplyInfo', { auditRecordList: [], state: {} }),
            'beanDrawApplyRatio': R('getBeanDrawApplyRatio', {}),
            'RuleInfoMoney': R('getRuleInfoMoney', num),
            'BankCardInfo': R('getBankCardInfo', {}),
            'QuestionDetail': R('getQuestionDetail', {}),
        }
    })
};
export default createModel(model);