import * as T from './actiontypes';

const initData = {
    dataList: [],
	pageNum: 1,
	pageSize: 20,
	pages: 0,
	resultTotal: 0
 };

export const brandApplyList = (state = initData, action) => {
    switch(action.type){
        case T.SET_BRANDAPPLY_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

export const brandApplyStateSele = (state = [], action) => {
    switch(action.type){
        case T.SET_BRANDAPPLY_STATESELE:
            return action.payload.result;
        default:
            return state;
    }
};

export const brandApplyBrandSele = (state = [], action) => {
    switch(action.type){
        case T.SET_BRANDAPPLY_BRANDSELE:
            return action.payload.result;
        default:
            return state;
    }
};

export const brandApplyDetail = (state = {}, action) => {
    switch(action.type){
        case T.SET_BRANDAPPLY_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};