import React from 'react';
import styles from './OrderTable.module.scss';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, tooltipClick } from '@/components/TableCell';

class OrderTable extends React.Component {
    state = {
        selectedRowKeys: [],
        selectedRowValues: [],
    };

    static getDerivedStateFromProps(props, state) {
        if (props.selectedRowKeys !== state.selectedRowKeys) {
            return {
                selectedRowKeys: props.selectedRowKeys,
                selectedRowValues: props.selectedRowValues
            };
        }
        return null;
    }

    _takeColumns = () => ([
        {
            title: '操作',
            dataIndex: 'key',
            key: 'action',
            align: 'center',
            width: 240,
            render: (text, item) => this._renderActionCell(item.id, item)
        },
        {
            title: '出库单号',
            dataIndex: 'id',
            key: 'id',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '批次号',
            dataIndex: 'batchNo',
            key: 'batchNo',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: (t, i) => tooltipClick(t, () => {
                this.props.tableAction(t, i, 'batchNo');
            })
        },
        {
            title: '订单编号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '会员手机',
            dataIndex: 'accountTel',
            key: 'accountTel',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品数量',
            dataIndex: 'number',
            key: 'number',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '发货仓库',
            dataIndex: 'storehouseName',
            key: 'storehouseName',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单状态',
            dataIndex: 'statusValue',
            key: 'statusValue',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单类型',
            dataIndex: 'orderTypeValue',
            key: 'orderTypeValue',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '下单时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 180,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单分配时间',
            dataIndex: 'distributionTime',
            key: 'distributionTime',
            width: 180,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否批发',
            dataIndex: 'isDistribution',
            key: 'isDistribution',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否开票',
            dataIndex: 'isInvoice',
            key: 'isInvoice',
            width: 100,
            align: 'center',
            render: (text) => (this._renderInvoiceCell(text))
        },
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderInvoiceCell = (number) => {
        let text = '否';
        if (number == 1) {
            text = '是';
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {tooltip(text)}
            </div>
        );
    };

    _renderActionCell = (key, item) => {
        const { tableAction, status } = this.props;
        // 当订单状态为取消时，隐藏确认出库按钮
        let deliver = <div className={styles.item} onClick={() => tableAction(key, item, 'deliver')}>确认出库</div>;
        if (item.status == 'CANCELLED' || item.afterSaleId) {
            deliver = null;
        }
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, item, 'info')}>查看详情</div>
                {deliver}
                {
                    item.status != 'CANCELLED' && !item.afterSaleId && status == 1 && <div className={styles.item} onClick={() => tableAction(key, item, 'expressWaybill')}>菜鸟面单出库</div>
                }
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRowValues) => {
                this.setState({ selectedRowKeys, selectedRowValues });
                this.props.selectedRowKeysChange(selectedRowKeys, selectedRowValues);
            }
        };
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [], selectedRowValues: [] }, () => {
            this.props.selectedRowKeysChange([], []);
        });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={this.state.selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection()}
                    rowKey={r => r.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    selectedRowKeys: PropTypes.array,
    selectedRowKeysChange: PropTypes.func,
    selectedRowValues: PropTypes.array,
    status: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default OrderTable;