import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import { Button, message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import TableBox from './components/TableBox';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/merchantwallet/model';
import { XOKButton, XCancelButton, XDatePicker, XInput, XSelect } from '@/components/xqxc_ui';
import { regFenToYuan } from '@/util/money';
import Draw from './modal/Draw';
import AddUnionInfo from './modal/AddUnionInfo';
import http from '@/assets/api/http';

class Proceeds extends Component {

    //props校验
    static propTypes = {
        history: PropTypes.object,//路由对象
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getSaleProceedsCard: PropTypes.func,
        saleProceedsCard: PropTypes.object,
        getSaleProceedsData: PropTypes.func,
        saleProceedsData: PropTypes.object,
        getSaleProceedsStatus: PropTypes.func,
        saleProceedsStatus: PropTypes.array,
    }

    static defaultProps = {
        saleProceedsData: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
        saleProceedsCard: {},
        saleProceedsStatus: [],
    }

    state = {
        modeParams: null,
        renderMode: '',
        startDate: undefined,
        endDate: undefined,
        paymentId: '',
        stateItem: {},
        confirmLoading: false, // 点击销售收益-支取时触发
    }

    //挂载后
    componentDidMount() {
        const { getSaleProceedsCard, keepSecondNavBreadcrumb, getSaleProceedsStatus } = this.props;
        keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        getSaleProceedsCard();
        getSaleProceedsStatus();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, startDate, endDate, paymentId, stateItem } = this.state;
        this.searchCache = {
            startDate, endDate, paymentId, payStatus: stateItem.code,
            queryPaymentType: 'MERCHANT',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getSaleProceedsData(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ startDate: undefined, endDate: undefined, stateItem: {}, paymentId: '' }, () => KeepAlive.saveResetFunc(this._searchHandle)); // 【第4步：将是否调用重置交给KeepAlive控制】
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => this._searchHandle('useCache'));
    }

    // 打开弹窗
    _renderMode = () => {
        const { modeParams, renderMode } = this.state;
        switch (renderMode) {
            case 'draw': // 付款
                return <Draw
                    modeParams={modeParams}
                    closeMode={this._closeMode}
                />;
            case 'addUnionInfo': // 支取时检验是否已经完善材料
                return <AddUnionInfo
                    modeParams={modeParams}
                    closeMode={this._closeMode}
                    history={this.props.history}
                />;
            default:
                return null;
        }
    };

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ renderMode: '', modeParams: null }, () => {
            update && this._searchHandle('useCache');
        });
    }

    //渲染组件
    render() {
        const { saleProceedsCard, saleProceedsData, saleProceedsStatus } = this.props;
        const { startDate, endDate, paymentId, stateItem, confirmLoading } = this.state;
        return (
            <KeepAlive id='walletproceeds' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.infoBox}>
                        <div className={styles.metaInfo} style={{ backgroundColor: 'rgb(17, 142, 233)' }}>
                            <div>
                                <span className={styles.text}>我的收益</span>
                                <Button
                                    loading={confirmLoading}
                                    style={{ color: 'rgb(17, 142, 233)' }}
                                    onClick={() => {
                                        if (saleProceedsCard.incomeTotalAmount == '0') {
                                            message.warning('当前可以支取的金额为0');
                                            return;
                                        }
                                        // 检验是否已经符合提现条件
                                        // 未入网银联：UNION;未签署临时税务登记：TAX；未绑卡：BANKCARD
                                        this.setState({ confirmLoading: true });
                                        http('/wallet/memberSaleIncomeSettlementInfo', { settlementAmount: saleProceedsCard.incomeTotalAmount }).then((response) => {
                                            this.setState({ confirmLoading: false });
                                            if (response.status == 200) {
                                                const { unableType } = response.result;
                                                if (unableType == 'UNION') {
                                                    this.setState({
                                                        renderMode: 'addUnionInfo',
                                                        modeParams: {
                                                            title: '当前商户暂未绑定财务信息，请先提交资料',
                                                            url: '/home/systemmanage/setunionpay'
                                                        }
                                                    });
                                                } else if (unableType == 'TAX') {
                                                    this.setState({
                                                        renderMode: 'addUnionInfo',
                                                        modeParams: {
                                                            title: '获取销售收益需先办理临时税务登记',
                                                            url: '/home/systemmanage/taxcertificate'
                                                        }
                                                    });
                                                } else if (unableType == 'BANKCARD') {
                                                    this.setState({
                                                        renderMode: 'addUnionInfo',
                                                        modeParams: {
                                                            title: '获取销售收益请先绑定银行卡',
                                                            url: '/home/systemmanage/bindcard'
                                                        }
                                                    });
                                                } else {
                                                    this.setState({
                                                        renderMode: 'draw',
                                                        modeParams: {
                                                            allAmount: saleProceedsCard.incomeTotalAmount
                                                        }
                                                    });
                                                }
                                            }
                                        }).catch((e) => {
                                            message.error(e.message);
                                        });
                                    }}>支取</Button>
                            </div>
                            <div>{regFenToYuan(saleProceedsCard.incomeTotalAmount)}</div>
                        </div>
                        <div className={styles.metaInfo} style={{ backgroundColor: 'rgb(255, 102, 0)', marginLeft: 15 }}>
                            <div>
                                <span className={styles.text}>已支取</span>
                            </div>
                            <div>{regFenToYuan(saleProceedsCard.withdrawAmount)}</div>
                        </div>
                        <div className={styles.metaInfo} style={{ backgroundColor: 'rgb(43, 179, 138)', marginLeft: 15 }}>
                            <div>
                                <span className={styles.text}>待结算</span>
                            </div>
                            <div>{regFenToYuan(saleProceedsCard.toSettleAmount)}</div>
                        </div>
                        <div className={styles.metaInfo} style={{ backgroundColor: 'rgba(255, 45, 71)', marginLeft: 15 }}>
                            <div>
                                <span className={styles.text}>会员数</span>
                            </div>
                            <div>{saleProceedsCard.merchantInviteAccountNum}</div>
                        </div>
                    </div>
                    <div className={styles.searchBox}>
                        <XDatePicker
                            label='日期'
                            showTime={false}
                            value={startDate}
                            bindThis={this}
                            bindName='startDate'
                            defaultTime='00:00:00'
                            isFormat
                        />
                        <XDatePicker
                            showTime={false}
                            value={endDate}
                            bindThis={this}
                            bindName='endDate'
                            defaultTime='23:59:59'
                            isForma
                        />
                        <XInput
                            placeholder='请输入付款单号'
                            style={{ marginLeft: 20 }}
                            label='付款单号'
                            value={paymentId}
                            bindThis={this}
                            bindName='paymentId'
                            mode='number'
                            maxLength={16}
                            showLength={false}
                        />
                        <XSelect
                            style={{ marginLeft: 20 }}
                            selectStyle={{ width: 200 }}
                            label='状态'
                            placeholder='请选择'
                            renderData={saleProceedsStatus}
                            bindThis={this}
                            bindName='stateItem'
                            dataIndex='value'
                            keyIndex='code'
                            value={stateItem.value}
                        />
                        <XOKButton
                            style={{ margin: '0 20px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <TableBox
                        renderData={saleProceedsData}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}
//暴露组件
export default connect(state => ({
    saleProceedsCard: state.merchantwallet.saleProceedsCard,
    saleProceedsData: state.merchantwallet.saleProceedsData,
    saleProceedsStatus: state.merchantwallet.saleProceedsStatus,
}), { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Proceeds);
