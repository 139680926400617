/**
 * 商品列表-商品费率
 */
import React from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XSelect, XOKButton, XCancelButton, XInput, XExpandOrContract, showConfirm } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import Edit from '../../modal/edit';
import SetRate from '../../modal/setRate';
import { GoodsCategory } from '@/vcomps';
import * as actions from '@/reducers/productmanage/rate/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectDataSource: [],
            currentOpenMode: '',
            modeParam: '',
            modeParams: {},
            isShowMore: false,
            goodsName: '',
            username: {},
            goodsNo: '',
            status: {},
            professionType: {},
            label: {},
            goodsIds: [],
            parentId: 0,
            tagIds: [],
            classify: [],
            prodCascade: '',
            batchData: [],
            expandBoolean: false, // 是否展开
            categorySubType: {}
        };
        this.goodsCategory = React.createRef();
    }

    componentDidMount() {
        this.props.getProductRateStatus();
        this.props.getProductRateBusinessType();
        this.props.getProductRateLabelSele();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { goodsName, goodsNo, professionType, prodCascade, pageNum, pageSize, status, label, categorySubType } = this.state;
        const tagIds = label.id ? [label.id] : [];
        this.searchCache = {
            goodsName, goodsNo, goodsState: status.code, goodsType: professionType.code, categoryId: prodCascade, tagIds, categorySubType: categorySubType.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getProductRateListData(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ goodsName: '', goodsNo: '', professionType: {}, pageNum: 1, pageSize: 20, status: {}, categorySubType: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        this.goodsCategory.current.clean();
    }

    _unfold = () => {
        const { isShowMore } = this.state;
        this.setState({ isShowMore: !isShowMore });
    }

    _sendRequest = (url, data, winTxt) => {
        http(url, data, 'POST')
            .then(() => {
                message.success(winTxt);
                this._searchHandle('useCache');
            }).catch((error) => {
                message.error(error.message);
            });
    }

    _batch = (type) => {
        const { batchData } = this.state;
        switch (type) {
            case 'submit':
                this._sendRequest('/goods/goods/submitAuditGoods', { goodsIds: batchData }, '批量提交成功。', '批量提交失败！');
                break;
            case 'putaway':
                this._sendRequest('/goods/goods/putOnSaleGoods', { goodsIds: batchData }, '批量上架成功。', '批量上架失败！');
                break;
            case 'soldOut':
                this._sendRequest('/goods/goods/pullOffShelvesGoods', { goodsIds: batchData }, '批量下架成功。', '批量下架失败！');
                break;
        }
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        switch (type) {
            case 'detail':
                console.log(id, type, value);
                this.props.updateContent('detail', id);
                break;
            case 'edit':
                console.log(id, type, value);
                this.setState({ currentOpenMode: 'EDIT', modeParam: id });
                break;
            case 'submit':
                console.log(id);
                showConfirm('您确定要提交平台？', '', () => {
                    this._sendRequest('/goods/goods/submitRateAudit', { goodsId: id }, '提交平台成功。');
                });
                break;
            case 'setRate':
                console.log(id, type, value);
                this.setState({ currentOpenMode: 'SETRATE', modeParams: value });
                break;
            case 'batch':
                this.setState({ batchData: value });
                break;
        }
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { modeParams, modeParam } = this.state;
        switch (this.state.currentOpenMode) {
            case 'EDIT':
                return <Edit title='编辑商品费率' visible={true}
                    data={{ goodsId: modeParam }}
                    closeMode={this._closeMode} />;
            case 'SETRATE':
                return <SetRate title='编辑商品活动费率' visible={true}
                    data={{ modeParams: modeParams }}
                    closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' }, () => {
            update && this._searchHandle('useCache');
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 更多搜索条件的展开与收缩
    _expandChangeHandle = (expandBoolean) => {
        this.setState({ expandBoolean });
    }

    render() {
        const { expandBoolean, goodsName, goodsNo, status, professionType, label, batchData, categorySubType } = this.state;
        const { productRateListData, productRateStatus, productRateBusinessType, productRateLabelSele } = this.props;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='商品名称'
                            placeholder='请输入商品名称'
                            bindThis={this}
                            bindName='goodsName'
                            value={goodsName}
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '200px' }}
                            label='商品货号'
                            placeholder='请输入商品货号'
                            bindThis={this}
                            bindName='goodsNo'
                            value={goodsNo}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                        <XExpandOrContract style={{ marginLeft: '30px' }} onChange={this._expandChangeHandle} />
                    </div>
                    <div className={`${styles.searchCriteriaHidden} ${expandBoolean ? styles.moreShow : styles.moreHidden}`}>
                        <div className={styles.criteria1}>
                            <XSelect
                                style={{ marginLeft: '30px' }}
                                selectStyle={{ width: '200px' }}
                                label='状态'
                                placeholder='请选择状态'
                                renderData={productRateStatus}
                                bindThis={this}
                                bindName='status'
                                dataIndex='value'
                                keyIndex='code'
                                value={status.value}
                            />
                            <XSelect
                                style={{ marginLeft: '20px' }}
                                selectStyle={{ width: '200px' }}
                                label='业务类型'
                                placeholder='请选择业务类型'
                                renderData={productRateBusinessType}
                                bindThis={this}
                                bindName='professionType'
                                dataIndex='value'
                                keyIndex='code'
                                value={professionType.value}
                            />
                            <XSelect
                                style={{ marginLeft: '20px' }}
                                selectStyle={{ width: '200px' }}
                                label='商品标签'
                                placeholder='请选择商品标签'
                                renderData={productRateLabelSele}
                                bindThis={this}
                                bindName='label'
                                dataIndex='tagName'
                                keyIndex='id'
                                value={label.tagName}
                            />
                            <XSelect
                                selectStyle={{ width: '150px' }}
                                style={{ marginLeft: '20px' }}
                                label='分类类型'
                                placeholder='请选择商品分类'
                                renderData={[
                                    { code: 'COMMON', value: '通用分类' },
                                    { code: 'ONLINE', value: '线上分类' },
                                    { code: 'CITY', value: '同城分类' }
                                ]}
                                bindThis={this}
                                bindName='categorySubType'
                                dataIndex='value'
                                keyIndex='code'
                                value={categorySubType.value}
                            />
                        </div>
                        <GoodsCategory
                            label='商品分类'
                            ref={this.goodsCategory}
                            type={categorySubType.code}
                            style={{ marginTop: '20px' }}
                            onChange={(value) => this.setState({ prodCascade: value })}
                        />
                    </div>
                    {/* <div className={styles.operate_box}>
                        <XOKButton style={{ width: '60px' }} label='导出' onClick={this._export} />
                        <XOKButton style={{ width: '120px', marginLeft: '20px' }} label='批量提交平台' onClick={() => this._batch('submit')} />
                        <XOKButton style={{ width: '120px', marginLeft: '20px' }} label='批量上架' onClick={() => this._batch('putaway')} />
                        <XOKButton style={{ width: '120px', marginLeft: '20px' }} label='批量下架' onClick={() => this._batch('soldOut')} />
                    </div> */}
                    <TableContent paginationChange={this._paginationChange} renderData={productRateListData} batchData={batchData} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    updateContent: PropTypes.func,
    getProductRateListData: PropTypes.func,
    getProductRateStatus: PropTypes.func,
    getProductRateBusinessType: PropTypes.func,
    getProductRateLabelSele: PropTypes.func,
    productRateListData: PropTypes.object,
    productRateStatus: PropTypes.array,
    productRateBusinessType: PropTypes.array,
    productRateLabelSele: PropTypes.array,
};

const mapStateToProps = (state) => ({
    productRateListData: state.productRate.productRateListData,
    productRateStatus: state.productRate.productRateStatus,
    productRateBusinessType: state.productRate.productRateBusinessType,
    productRateLabelSele: state.productRate.productRateLabelSele.dataList || [],
});

export default connect(mapStateToProps, actions)(Main);