import React from 'react';
import PropTypes from 'prop-types';
import { Table, Switch, Popconfirm, message } from 'antd';
import { XSelect, XOKButton, XCancelButton, XInput } from '@/components/xqxc_ui';
import TypeModal from '../../modal/TypeModal';
import AutoPagination, { AutoPaginationSource } from '@/components/Pagination/AutoPagination';
import { getMaterialTableSource } from '@/reducers/systemmanage/materiallist/actions';
import http from '@/assets/api/http';
import styles from './index.module.scss';

const Mode = {
    CREAT: 'CREAT',
    EDITE: 'EDITE',
};

const columns = (TableAction, actionClickHandle, type) => [
    {
        title: '图标名称',
        dataIndex: type + 'Name',
        key: type + 'Name ',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '分类名称',
        dataIndex: type + 'CategoryName',
        key: type + 'CategoryName ',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '图片',
        dataIndex: type + 'Url',
        key: type + 'Url',
        align: 'center',
        width: '10%',
        render: (text) => (ImgCell(text))
    },
    {
        title: '推荐',
        dataIndex: 'recommend',
        key: 'recommend',
        width: '10%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle, type))
    },
    {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: '10%',
        align: 'center',
        render: (text, item) => (SwitchCell(text, item, actionClickHandle, type))
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: '10%',
        render: (id, item) => TableAction(item[type + 'Id'], actionClickHandle, item)
    }
];

const TableAction = (id, actionClickHandle, record) => {
    return (
        <div className={styles.action}>
            <div className={styles.item} onClick={() => actionClickHandle(id, 'edit', record)}>编辑</div>
            <div className={styles.item}>
                <Popconfirm
                    title="确认是否删除？"
                    onConfirm={() => actionClickHandle(id, 'delete')}
                    okText="是"
                    cancelText="否"
                >删除</Popconfirm>
            </div>
        </div>
    );
};

// 表格项内容为文本
const TableCell = (text) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {text}
        </div>
    );
};

// 表格项内容为图片
const ImgCell = (url) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <div className={styles.imgbox}>
                <img className={styles.img} src={url} />
            </div>
        </div>
    );
};

// 表格项内容为开关
const SwitchCell = (sw, item, actionClickHandle, type) => {
    let checked = sw == 0 ? false : true;
    const id = item[type + 'Id'];
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                key={id + item['state']}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                defaultChecked={checked}
                onChange={(value) => actionClickHandle(id, 'switch', value)}
            />
        </div>
    );
};


class MaterialList extends React.PureComponent {

    state = {
        type: {},
        typeName: '',
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: [], // 当前需要打开的弹窗的入参
        selectDataSource: []
    }

    componentDidMount() {
        http(`/custom/${this.props.curTabID}Category/getPageList`, { pageSize: 9999, pageNum: 1, isExcludeLocalUpload: '1' }).then(({ result } = {}) => {
            this.setState({ selectDataSource: result.dataList || [] });
        });

        this.updateConditions(null, { updateData: true });
    }

    updateConditions = (conditions, options) => {
        this.props.paginationProps.updateConditions({ ...conditions, type: this.props.curTabID }, { ...options, keepConditions: ['type'] });
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        switch (type) {
            case 'edit':
                this.setState({
                    currentOpenMode: Mode.EDITE,
                    openModeParam: { type: this.props.curTabID, ...value }
                });
                break;
            case 'delete':
                http('/custom/' + this.props.curTabID + 'List/del', { id }, 'POST').then(response => {
                    this.updateConditions(null, { updateData: true });
                    message.success('删除成功');
                }).catch(e => message.error('删除异常'));
                break;
            case 'switch':
                this.updateVals(id, { state: value === true ? 1 : 0 });
                break;
            case 'sort':
                this.updateVals(id, { sort: value });
                break;
        }
    }

    updateVals = (id, updateVals) => {
        const { curTabID } = this.props;
        http(`/custom/${curTabID}List/save`, { [curTabID + 'Id']: id, ...updateVals }).then(response => this.props.paginationProps.updateConditions(null, { updateData: true }));
    }

    // 操作表格
    _actionHandle = (id, type, value, item) => {
        this._tableAction(id, type, value, item);
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.CREAT:
                return <TypeModal title={'创建' + this.props.title} visible={true} data={{ recommend: '1', state: '1', type: this.props.curTabID }} selectDataSource={this.state.selectDataSource} closeMode={this._closeMode} />;
            case Mode.EDITE:
                return <TypeModal title={'编辑' + this.props.title} visible={true} data={this.state.openModeParam} selectDataSource={this.state.selectDataSource} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    _closeMode = (reload) => {
        this.setState({ currentOpenMode: '' });
        if (reload) this.updateConditions(null, { updateData: true });
    }

    render() {
        const { dataList, ...otherTableProps } = this.props.materialTable;
        const { __recommend, __categoryId, className, uploadUserName, ...otherProps } = this.props.paginationProps;
        const selectSource = [{ value: '启用', code: '1' }, { value: '禁用', code: 0 }];

        const categoryName = this.props.curTabID + 'CategoryName',
            categoryId = this.props.curTabID + 'CategoryId';

        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.search_box}>
                    <XSelect
                        style={{ marginLeft: '0px', width: '216px' }}
                        selectStyle={{ width: '150px' }}
                        label='所属分类'
                        placeholder='请选择所属分类'
                        renderData={this.state.selectDataSource}
                        onChange={v => this.updateConditions({ [categoryId]: v[categoryId], __categoryId: v[categoryName] })}
                        dataIndex={categoryName}
                        keyIndex={categoryId}
                        value={__categoryId}
                    />
                    <XSelect
                        style={{ marginLeft: '30px', width: '216px' }}
                        selectStyle={{ width: '150px' }}
                        label='推荐'
                        placeholder='请选择推荐状态'
                        renderData={selectSource}
                        onChange={v => this.updateConditions({ recommend: v.code, __recommend: v.value })}
                        dataIndex='value'
                        keyIndex='code'
                        value={__recommend}
                    />
                    {
                        this.props.curTabID === 'icon' ?
                            <XInput
                                style={{ width: '216px' }}
                                inputStyle={{ width: '150px' }}
                                label='分类名称'
                                placeholder='请输入分类名称'
                                onChange={className => this.updateConditions({ className })}
                                value={className}
                            /> : null
                    }
                    {
                        this.props.curTabID !== 'icon' ?
                            <XInput
                                style={{ width: '216px' }}
                                inputStyle={{ width: '150px' }}
                                label='用户名称'
                                placeholder='请输入用户名称'
                                onChange={uploadUserName => this.updateConditions({ uploadUserName })}
                                value={uploadUserName}
                            /> : null
                    }
                    <XOKButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='查询'
                        onClick={() => this.updateConditions(null, { updateData: true })}
                    />
                    <XCancelButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='重置'
                        onClick={() => this.updateConditions(null, { updateData: true, clearConditions: true })}
                    />

                </div>
                {
                    this.props.curTabID === 'icon' ?
                        <div className={styles.operate_box}>
                            < XOKButton style={{ width: '120px' }} label={'创建' + this.props.title} onClick={() => this.setState({ currentOpenMode: Mode.CREAT })} />
                        </div> : null
                }
                <div className={styles.tableContainer}>
                    <Table
                        rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                        rowSelection={this._rowSelection}
                        columns={columns(TableAction, this._tableAction, this.props.curTabID)}
                        dataSource={dataList}
                        pagination={false}
                        scroll={{ x: true, y: 450 }}
                    />
                    <AutoPagination {...otherProps} {...otherTableProps} />
                </div>
                {this._renderMode()}
            </div>
        );
    }
}

MaterialList.propTypes = {
    curTabID: PropTypes.string,
    title: PropTypes.string,
    tableAction: PropTypes.func,
    paginationProps: PropTypes.object,
    materialTable: PropTypes.object
};

export default AutoPaginationSource(MaterialList, {
    mapStateToProps: state => ({
        materialTable: state.materiallist.materialTable
    }),
    mapDispatchToProps: { paginationAction: getMaterialTableSource }
});