/**
 *  【商品分类】入口页
 * */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XTabNav } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { keepSecondNavBreadcrumb, addOneBreadcrumbPath } from '@/reducers/home/actions';
import Entity from './pages/entity';
import Virtual from './pages/virtual';

const ProductClassify = ({ keepSecondNavBreadcrumb }) => {

    const [tabs] = useState([
        { id: 1, label: '实物商品分类' },
        { id: 2, label: '虚拟商品分类' },
    ]);
    const [curTabID, setCurTabID] = useState(1);

    useEffect(() => {
        keepSecondNavBreadcrumb();
    }, []);

    // 渲染相应Tab导航下的内容
    const _renderPage = () => {
        switch (curTabID) {
            case 1:
                return <Entity curTabID={curTabID} />;
            case 2:
                return <Virtual curTabID={curTabID} />;
            default:
                return <Entity curTabID={curTabID} />;
        }
    };

    return (
        <div className={styles.flexBoxContainer}>
            <XTabNav renderData={tabs} onChange={tab => setCurTabID(tab)} activedID={curTabID} />
            <div className={styles.flexBoxContainer} style={{ marginTop: '10px' }}>
                {_renderPage()}
            </div>
        </div>
    );
};

ProductClassify.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { keepSecondNavBreadcrumb, addOneBreadcrumbPath })(ProductClassify);