import * as T from './actiontypes';

export const getInvoiceList = (value) => ({
    type: T.GET_INVOICELIST,
    payload: value
});

// 发货清单
export const getDeliverListTableSource = (value) => ({
    type: T.GET_DELIVERLIST_TABLESOURCE,
    payload: value
});

// 对账单
export const getValidateStatementTableSource = (value) => ({
    type: T.GET_VALIDATESTATEMENT_TABLESOURCE,
    payload: value
});

// 对账详情
export const getValidateStatementDetail = (value) => ({
    type: T.GET_VALIDATESTATEMENT_DETAIL,
    payload: value
});

// 对账详情明细
export const getValidateStatementDetailTableSource = (value) => ({
    type: T.GET_VALIDATESTATEMENT_DETAIL_TABLESOURCE,
    payload: value
});

// 供应商下拉列表
export const getSupplierSelectSource = (value) => ({
    type: T.GET_SUPPLISER_SELECTSOURCE,
    payload: value
});

// 合同管理
export const getContractManageTableSource = (value) => ({
    type: T.GET_CONTRACTMANAGE_TABLESOURCE,
    payload: value
});

// 获取合同详情
export const getContractDetail = (value) => ({
    type: T.GET_CONTRACTDETAIL,
    payload: value
});

// 获取合同状态
export const getContractStates = (value) => ({
    type: T.GET_CONTRACTSTATES,
    payload: value
});