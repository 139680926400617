import React from 'react';
import styles from './TableStyle.module.scss';
import PropTypes from 'prop-types';
import { Table, Tag } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const AllTable = ({ renderData, tableAction, paginationChange }) => {

    //表头
    const _columns = () => {
        return [
            {
                title: '会员账号',
                dataIndex: 'buyerTel',
                key: 'buyerTel ',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '订单信息',
                dataIndex: 'id',
                key: 'id',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '入住人员',
                dataIndex: 'hostName',
                key: 'hostName',
                width: '15%',
                align: 'center',
                render: (text) => _tagCell(text)
            },
            {
                title: '房源名称',
                dataIndex: 'shopName',
                key: 'shopName',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '入住时间',
                dataIndex: 'startDate',
                key: 'startDate',
                width: '15%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '离开时间',
                dataIndex: 'endDate',
                key: 'endDate',
                width: '15%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '核销状态',
                dataIndex: 'verificationStatusValue',
                key: 'verificationStatusValue',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '操作',
                dataIndex: 'key',
                key: 'key',
                width: '15%',
                align: 'center',
                render: (id, item) => _tableAction(id, item)
            }
        ];
    };

    //标签显示
    const _tagCell = (tag) => {
        return <Tag>{tag}</Tag>;
    };


    //表格操作
    const _tableAction = (id, item) => {
        if (item.verificationStatus === 'UN_WRITTEN_OFF') {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'verify', item)}>验证入住</div>
                </div>
            );
        } else {
            return (
                <div className={styles.action}>
                    <div className={styles.item} onClick={() => tableAction(id, 'detail', item)}>查看详情</div>
                    <div className={styles.item} onClick={() => tableAction(id, 'delete', item)}>删除</div>
                </div>
            );
        }
    };

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(record, index) => index}
                columns={_columns()}
                dataSource={renderData.dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={renderData.resultTotal}
                pageSize={renderData.pageSize}
                pageNum={renderData.pageNum}
                onChange={paginationChange} />
        </div>
    );
};

AllTable.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func
};

export default AllTable;