import React from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

const TableContent = ({ tableData, tableOperate, paginationChange }) => {

    //表头
    const _columns = () => {
        return [
            {
                title: '申请单号',
                dataIndex: 'id',
                key: 'id',
                width: '12%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '支取信用值',
                dataIndex: 'starValueNum',
                key: 'starValueNum',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '服务费(粒)',
                dataIndex: 'serviceFee',
                key: 'serviceFee',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '总信用值',
                dataIndex: 'allStarValueNum',
                key: 'allStarValueNum',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '申请金额(元)',
                dataIndex: 'applyFee',
                key: 'applyFee',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: priceFenToYuanCell
            },
            {
                title: '状态',
                dataIndex: 'state',
                key: 'state',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: (text) => text.value
            },
            {
                title: '审核意见',
                dataIndex: 'approvalDesc',
                key: 'approvalDesc',
                width: '18%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '申请时间',
                dataIndex: 'gmtCreate',
                key: 'gmtCreate',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '操作',
                dataIndex: 'key',
                key: 'key',
                align: 'center',
                width: '10%',
                render: (key, record) => _tableOperate(record.id, record)
            },
        ];
    };

    //操作栏
    const _tableOperate = (key, item) => {
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => tableOperate(item.id, 'detail')}>详情</div>
        </div>;
    };

    //渲染
    return <div className={styles.tableContainer}>
        <Table
            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
            rowKey={record => record.id}
            columns={_columns()}
            dataSource={tableData.dataList}
            pagination={false}
            scroll={{ y: true }}
        />
        <XPagination
            resultTotal={tableData.resultTotal}
            pageSize={tableData.pageSize}
            pageNum={tableData.pageNum}
            onChange={paginationChange}
        />
    </div>;
};

//props校验
TableContent.propTypes = {
    tableData: PropTypes.object,//表格数据元
    paginationChange: PropTypes.func,//分页逻辑
    tableOperate: PropTypes.func,//表格操作
};

export default TableContent;