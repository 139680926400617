// 获取/保存订单列表
export const GET_TRANSCATION_LIST = 'ORDERMANAGE/GET_TRANSCATION_LIST';
export const SET_TRANSCATION_LIST = 'ORDERMANAGE/SET_TRANSCATION_LIST';
// 获取店铺
export const GET_STORE_LIST = 'ORDERMANAGE/GET_STORE_LIST';
export const SET_STORE_LIST = 'ORDERMANAGE/SET_STORE_LIST';
// 获取/保存订单列表（统计用）
export const GET_TRANSCATION_LIST2 = 'ORDERMANAGE/GET_TRANSCATION_LIST2';
export const SET_TRANSCATION_LIST2 = 'ORDERMANAGE/SET_TRANSCATION_LIST2';
// 订单应收明细合计
export const GET_ORDER_AMOUNT = 'ORDERMANAGE/GET_ORDER_AMOUNT';
export const SET_ORDER_AMOUNT = 'ORDERMANAGE/SET_ORDER_AMOUNT';
