import * as T from './actiontypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    resultTotal: 0
};

export const productRateListData = (state = initData, { type, payload }) => {
    switch (type) {
        case T.SET_PRODUCTRATE_LISTDATA:
            return payload.result;
        case T.BATCH_SET_LISTDATA:
            return {
                ...state, dataList: state.dataList.map(item => {
                    let ele = { ...item };
                    ele.platRate = payload.platRate;
                    ele.agentRate = payload.agentRate;
                    ele.showRatio = payload.showRatio;
                    ele.serviceRatio = payload.serviceRatio;
                    ele.starValue = payload.starValue;
                    ele.marketRate = payload.marketRate;
                    ele.wholesalePlatRate = payload.wholesalePlatRate;
                    ele.wholesaleTechnRate = payload.wholesaleTechnRate;
                    if (payload.isGY) {
                        if (!payload.isMarketing) {
                            ele.marketRate = '';
                        }
                    }
                    if (item.goodsBusinessType.code === 'FAST_GOODS') {
                        ele.showRatio = '';
                        ele.serviceRatio = '';
                        ele.starValue = '';
                        ele.marketRate = '';
                    }
                    return ele;
                })
            };
        case T.SINGLE_SET_LISTDATA:
            return {
                ...state, dataList: state.dataList.map(item => {
                    if (payload.goodsId == item.goodsId) {
                        return { ...item, ...payload };
                    }
                    return item;
                })
            };
        case T.REMOVE_LISTDATA:
            return { ...state, dataList: state.dataList.filter(item => item.goodsId != payload) };
        case T.MINIMUM_RATE_LISTDATA:
            return {
                ...state, dataList: state.dataList.map(item => {
                    let ele = { ...item };
                    ele.platRate = item.defaultPlatRate;
                    ele.agentRate = item.defaultAgentRate;
                    ele.showRatio = 0;
                    ele.serviceRatio = 0;
                    ele.starValue = 0;
                    ele.marketRate = 0;
                    ele.wholesalePlatRate = item.defaultWholesalePlatRate;
                    ele.wholesaleTechnRate = item.defaultWholesaleTechnRate;
                    return ele;
                })
            };
        default:
            return state;
    }
};
export const productRateStatus = (state = [], action) => {
    switch (action.type) {
        case T.SET_PRODUCTRATE_STATUS:
            return action.payload.result;
        default:
            return state;
    }
};

export const productRateBusinessType = (state = [], action) => {
    switch (action.type) {
        case T.SET_PRODUCTRATE_BUSINESSTYPE:
            return action.payload.result;
        default:
            return state;
    }
};

export const productRateLabelSele = (state = {}, action) => {
    switch (action.type) {
        case T.SET_PRODUCTRATE_LABELSELE:
            return action.payload.result;
        default:
            return state;
    }
};

export const productRateDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_PRODUCTRATE_DETAIL:
            return action.payload.result;
        case T.CLEAN_PRODUCT_DETAIL:
            return {};
        default:
            return state;
    }
};

export const activeRateDetail = (state = {}, action) => {
    switch (action.type) {
        case T.SET_ACTIVERATE_DETAIL:
            return action.payload.result;
        default:
            return state;
    }
};

export const conversionRate = (state = '', action) => {
    switch (action.type) {
        case T.SET_CONVERSION_RATE:
            return action.payload.result;
        default:
            return state;
    }
};
