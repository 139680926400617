/**
 * 银行卡管理
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, Button, message } from 'antd';
import AddBankModal from './modal/addBankModal';
import AddPayModal from './modal/addPayModal';
import model from '@/reducers/beanshopmanage/beanshop/model';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination, XDatePicker, showConfirm } from '@/components/xqxc_ui';
import { takeColumns } from './components/TableData';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb, keepThirdNavBreadcrumb } from '@/reducers/home/actions';
import http from '@/assets/api/http';

class ValidateStatement extends Component {
	state = {
	}

	componentDidMount() {
		this.props.keepThirdNavBreadcrumb();
		this.props.getBankCardList();
	}
	_addBankHandle = () => {
		this.setState({ currentOpenMode: 'addBank', openModeParam: {} });
	}
	_addAlipayHandle = () => {
		this.setState({ currentOpenMode: 'addPay', openModeParam: {} });
	}
	// 分页
	_paginationChange = (pageSize, pageNum) => {
		this.setState({ pageNum, pageSize }, () => {
			this.props.getBankCardList({pageNum, pageSize});
		});
	}
	// 表格操作功能回调
    _actionClickHandle = (id, item, type) => {
        switch (type) {
            case 'switch': //表格状态
                http('/bankCard/updateDefault', {
                    id: item.id,
                    setDefault: id === 0 ? 1 : 0,
                }, 'POST').then(() => {
                    message.success('修改成功');
                    this.props.getBankCardList({pageNum: this.state.pageNum, pageSize: this.state.pageSize});
                }).catch((reject) => {
                    message.error(reject.message);
                });
                break;
            case 'edit': //编辑
             if (item.accountTypeCode === 'BANK_CARD') {
                this.setState({ currentOpenMode: 'addBank', openModeParam: item });
             } else {
                this.setState({ currentOpenMode: 'addPay', openModeParam: item });
             }
                break;
             case 'unbind': //解绑
                showConfirm('确定要解除该银行卡/支付宝的绑定？', '您还要继续吗？', () => {
                http('/bankCard/unBind', {
                    id: item.id,
                      }, 'POST').then(() => {
                       message.success('解绑成功');
                    this.props.getBankCardList({pageNum: this.state.pageNum, pageSize: this.state.pageSize});
                    }).catch((reject) => {
                      message.error(reject.message);
                    });
                });
                break;
            default:
                return null;
        }
    }
	// 打开弹窗
    _renderMode = () => {
		const { openModeParam } = this.state;
		const title = openModeParam && openModeParam.id ? '编辑' : '添加';
        switch (this.state.currentOpenMode) {
            case 'addBank':
                return <AddBankModal title={`${title}银行卡`} visible={true} refsh={() => this.props.getBankCardList({pageNum: this.state.pageNum, pageSize: this.state.pageSize})} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'addPay':
                return <AddPayModal title={`${title}支付宝`} visible={true} refsh={() => this.props.getBankCardList({pageNum: this.state.pageNum, pageSize: this.state.pageSize})} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }
	render() {
		const { pageNum, pageSize, resultTotal, dataList } = this.props.BankCardList;
		return (
				<div className={styles.flexBoxContainer}>
				<div className={styles.pro_commont}>
				<XOKButton
							style={{ width: '120px', display: 'inline-block' }}
							label='添加银行卡'
							onClick={this._addBankHandle}
						/>
						<XOKButton
							style={{ marginLeft: '30px', width: '120px', display: 'inline-block' }}
							label='添加支付宝'
							onClick={this._addAlipayHandle}
						/>
						</div>
					<Table
						// bordered
						rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
						rowSelection={this._rowSelection}
						rowKey={(record, index) => record.id}
						columns={takeColumns(this._actionClickHandle)}
						dataSource={dataList}
						pagination={false}
						scroll={{ y: true }}
					/>
					<XPagination
							resultTotal={resultTotal}
							pageSize={pageSize}
							pageNum={pageNum}
							onChange={this._paginationChange}
						/>
					{this._renderMode()}  
				</div>
		);
	}
}

ValidateStatement.propTypes = {
	updateContent: PropTypes.func, // 路由跳转
	history: PropTypes.object, // 路由history
	addOneBreadcrumbPath: PropTypes.func,
	keepSecondNavBreadcrumb: PropTypes.func,
	keepThirdNavBreadcrumb: PropTypes.func,
	getBankCardList: PropTypes.func,
	BankCardList: PropTypes.object,
};

const mapStateToProps = (state) => ({
	BankCardList: state.beanshop.BankCardList
});


export default connect(mapStateToProps, {
	addOneBreadcrumbPath,
	keepSecondNavBreadcrumb,
	keepThirdNavBreadcrumb,
	...model.actions,
})(ValidateStatement);