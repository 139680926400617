/**
 * 删除
 */
import React from 'react';
import { Modal, message, Icon, } from 'antd';
import PropTypes from 'prop-types';
import styles from './PopconfirmModal.module.scss';
import http from '@/assets/api/http';

class DeleteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
        };
    }

    _handleOk = () => {
        const { data } = this.props;
        http('/spokesmantask/config/delete', { id: data.id }, 'POST').then(() => {
            message.success('删除成功');
            this.setState({
                visible: false,
            });
            this.props.closeMode(true);
        }).catch((reject = {}) => {
            message.warn(reject.message || '删除异常');
            this.setState({
                visible: false,
            });
            this.props.closeMode();
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, } = this.state;
        return (
            <Modal
                width={270}
                centered
                // title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='继续'
            >
                <div className={styles.container}>
                    <div className={styles.title}>
                        <Icon type="exclamation-circle" style={{ fontSize: '23px' }} />
                        <h4>确定要删除此任务吗？</h4>
                    </div>
                    <span>你还要继续吗？</span>
                </div>
            </Modal>
        );

    }
}

DeleteModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default DeleteModal;