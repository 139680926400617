import * as Audit from './actiontypes';
//初始列表
const initList = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    resultTotal: 0
};
//初始详情
const initDetail = {
    list: [],
    crowdFundingLimitResps: []
};
//初始信息
const initAuditInfo = {
    crowdFundingLimitResps: []
};
//集采活动审核列表
export const purchasingList = (state = initList, { type, payload }) => {
    switch (type) {
        case Audit.SET_PURCHASING_LIST:
            return payload.result;
        default:
            return state;
    }
};
//集采活动审核详情
export const purchasingDetail = (state = initDetail, { type, payload }) => {
    switch (type) {
        case Audit.SET_PURCHASING_DETAIL:
            return payload.result;
        default:
            return state;
    }
};
//集采活动审核信息
export const purchasingAuditInfo = (state = initAuditInfo, { type, payload }) => {
    switch (type) {
        case Audit.SET_PURCHASING_AUDITINDO:
            return payload.result;
        default:
            return state;
    }
};



