import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table, Switch } from 'antd';
import { XPagination, } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';


class TableContent extends Component {
    state = {
        selectedRowKeys: [],//对应的key数据
        selectedRows: [],//数据
    };

    _columns = () => [
        {
            title: '活动名称',
            dataIndex: 'activityName',
            key: 'activityName',
            align: 'center',
            width: '26%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '活动类型',
            dataIndex: 'activityType.value',
            key: 'activityType.value',
            align: 'center',
            width: '15%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'activityState.value',
            key: 'activityState.value',
            align: 'center',
            width: '12%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '活动时间',
            dataIndex: 'acTime',
            key: 'acTime',
            align: 'center',
            width: '12%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '可用',
            dataIndex: 'state.code',
            key: 'state.code',
            align: 'center',
            width: '12%',
            render: (text, item) => (this._switchCell(text, item))
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '23%',
            render: (id, record) => this._tableAction(id, record)
        },
    ];

    //表格项内容为开关
    _switchCell = (key, item) => {
        let checked = true;
        if (key == '0') {
            checked = false;
        }
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={checked}
                    onChange={(value) => {
                        this.props.tableAction(item, 'use', value);
                    }}
                />
            </div>
        );
    };

    // 表格操作
    _tableAction = (key, record) => {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => this.props.tableAction(key, 'view', record)}>查看</div>
                <div className={styles.item} onClick={() => this.props.tableAction(key, 'extending', '30')}>延长30天</div>
                <div className={styles.item} onClick={() => this.props.tableAction(key, 'extending', '90')}>延长90天</div>
            </div>
        );
    };


    //处理表格数据
    _processingData = (data) => {
        // 解析表格数据，使符合columns格式要求
        return data && data.map((item) => {
            //活动时间
            item.acTime = `${item.startTime} - ${item.endTime}`;
            return item;
        });
    }

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [], selectedRows: [] }, () => {
            const { rowKeys } = this.props;
            const { selectedRowKeys } = this.state;
            selectedRowKeys.length === 0 ? rowKeys([]) : rowKeys(selectedRowKeys);
        });
    }

    // 表格单选输入
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
        this.props.rowKeys(selectedRowKeys);
    };

    render() {
        const { resultTotal, pageSize, pageNum, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => record.id}
                    columns={this._columns()}
                    pagination={false}
                    dataSource={this._processingData(dataList)}
                    scroll={{ y: true }}
                    rowSelection={rowSelection}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={this.props.paginationChange} />
            </div>
        );
    }
}


TableContent.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
    rowKeys: PropTypes.func,//选中的keys 
};



export default TableContent;