import React, { Component } from 'react';
import { Table, Typography, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import styles from './LiveTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';
import { isEmpty } from '@/assets/js/utils';
const { Paragraph } = Typography;
const preStyles = {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    display: 'inline',
};

const titleStyles = {
    display: 'inline-block',
    whiteSpace: 'pre-wrap',
};
export default class LiveTable extends Component {

    //初始化
    constructor(props) {
        super(props);
        this.state = {};
    }

    //props校验
    static propTypes = {
        renderData: PropTypes.object.isRequired, // 表格数据源
        tableAction: PropTypes.func.isRequired, // 表格操作
        paginationChange: PropTypes.func.isRequired, // 分页操作
    };

    //表头
    _columns = () => [
        {
            title: '主播帐号',
            dataIndex: 'tel',
            key: 'tel',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '直播开始时间',
            dataIndex: 'startTime',
            key: 'startTime',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '直播结束时间',
            dataIndex: 'endTime',
            key: 'endTime',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '直播标题',
            dataIndex: 'broadcasTitle',
            key: 'broadcasTitle',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所属分类',
            dataIndex: 'broadcasClassName',
            key: 'broadcasClassName',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '直播时长',
            dataIndex: 'liveTimes',
            key: 'liveTimes',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: (text, record) => {
                return (
                    <div className={styles.action}>
                        <div className={styles.item}>{this.formatSeconds(text)}</div>
                    </div>
                );
            }
        },
        {
            title: '观看人数',
            dataIndex: 'realOnlineNum',
            key: 'realOnlineNum',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '魅力值收入',
            dataIndex: 'starLightNum',
            key: 'starLightNum',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: (text, record) => {
                return <span>{text}</span>;
            }
        },
        {
            title: '本场本店订单数',
            dataIndex: 'shopOrderNum',
            key: 'shopOrderNum',
            width: '8%',
            align: 'center',
            render: (text, record) => {
                return (
                    <div>
                        <div className={styles.item} style={{ color: '#40a9ff', cursor: 'pointer' }} >
                            <Tooltip placement="top" title={<pre style={titleStyles}>{'实时订单数'}</pre>} onClick={() => this.props.tableAction(record, 'detail')}><pre style={preStyles}>{isEmpty(record.shopOrderNum) ? '-' : record.shopOrderNum}</pre></Tooltip>
                            <span>/</span>
                            <Tooltip placement="top" title={<pre style={titleStyles}>{'结束时订单数'}</pre>}><pre style={preStyles}>{isEmpty(record.shopSaleNum) ? '-' : record.shopSaleNum}</pre></Tooltip>
                        </div>
                    </div>
                );
            }
        },
        {
            title: '本场本店销售额',
            dataIndex: 'shopSales',
            key: 'shopSales',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell,
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: '8%',
            render: (id, record) => this._tableAction(record)
        },
    ];

    //处理返回数据
    _takeDataSource = (data) => {
        return data ? data : [];
    };
    //表格操作
    _tableAction = (record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                {record.liveState === 'LIVE' ?
                    <div className={styles.item} style={{ cursor: 'pointer', color: '#40a9ff' }} onClick={() => tableAction(record, 'Live')}>播放</div>
                    : record.liveState === 'END' || record.liveState === 'NO_VIEWING' ?
                        <div className={styles.item} style={{ cursor: 'pointer', color: '#40a9ff' }} onClick={() => tableAction(record, 'Play')}>回放</div>
                        : null
                }
                {record.liveState !== 'LIVE' && record.liveState !== 'NONE' ?
                    <div className={styles.item} style={{ cursor: 'pointer', color: '#40a9ff' }} onClick={() => tableAction(record, 'del')}>删除</div>
                    : record.liveState === 'LIVE' ?
                        <div className={styles.item} style={{ color: '#dfdfdf', cursor: 'pointer' }}>删除</div>
                        : null
                }

            </div>
        );
    };
    formatSeconds(value) {
        let theTime = parseInt(value);// 秒
        //取模（余数）
        let modulo = theTime % (60 * 60 * 24);
        //小时数
        let hours = Math.floor(modulo / (60 * 60)) < 10 ? '0' + Math.floor(modulo / (60 * 60)) : Math.floor(modulo / (60 * 60));
        modulo = modulo % (60 * 60);
        //分钟
        let minutes = Math.floor(modulo / 60) < 10 ? '0' + Math.floor(modulo / 60) : Math.floor(modulo / 60);
        //秒
        let seconds = modulo % 60 < 10 ? '0' + modulo % 60 : modulo % 60;
        let result = `${hours} : ${minutes} : ${seconds}`;
        return result;
    }
    // formatSeconds(value) {
    //     let theTime = parseInt(value);// 秒
    //     let theTime1 = 0;// 分
    //     let theTime2 = 0;// 小时
    //     // alert(theTime);
    //     console.log(theTime);
    //     if (theTime > 60) {
    //         theTime1 = parseInt(theTime / 60);
    //         theTime = parseInt(theTime % 60);
    //         // alert(theTime1+"-"+theTime);
    //         console.log(theTime1 + '-' + theTime);
    //         if (theTime1 > 60) {
    //             theTime2 = parseInt(theTime1 / 60);
    //             theTime1 = parseInt(theTime1 % 60);
    //         }
    //     }
    //     let result = '' + parseInt(theTime);
    //     if (theTime1 > 0) {
    //         result = '' + parseInt(theTime1) + '：' + result;
    //     }
    //     if (theTime2 > 0) {
    //         result = '' + parseInt(theTime2) + '：' + result;
    //     }
    //     return result;
    // }
    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.liveId}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}