import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell, priceFenToYuanCell } from '@/components/TableCell';

const TableContent = ({ tableOperate, paginationChange, renderData, goodsIds }) => {

	const [keys, setKeys] = useState(goodsIds);

	useEffect(() => {
		setKeys(goodsIds);
	}, [goodsIds]);

	const obgResolve = (obj) => {
		return tooltip(obj ? obj.value : '');
	};

	const onTableOperate = (id, item) => {
		return <div className={styles.action}>
			<div className={styles.item} onClick={() => tableOperate(id, item, 'detail')}>详情</div>
			<div className={styles.item} onClick={() => tableOperate(id, item, 'delete')}>删除</div>
			{
				item.goodsState &&
				item.goodsState.code !== 'PUT_ON_SALE' &&
				<div className={styles.item} onClick={() => tableOperate(id, item, 'edit')}>编辑</div>
			}
			<div className={styles.item} onClick={() => tableOperate(id, item, 'updateReserve')}>更新库存</div>
		</div>;
	};

	const columns = [{
		title: '商品图片',
		dataIndex: 'mainImgUrl',
		key: 'mainImgUrl',
		width: '10%',
		align: 'center',
		render: ImgCell
	}, {
		title: '货号',
		dataIndex: 'goodsNo',
		key: 'goodsNo',
		width: '15%',
		align: 'center',
		onCell: tooltipStyle,
		render: tooltip
	}, {
		title: '商品名称',
		dataIndex: 'goodsName',
		key: 'goodsDoc',
		width: '15%',
		align: 'center',
		onCell: tooltipStyle,
		render: tooltip
	}, {
		title: '销售价（元）',
		dataIndex: 'salePrice',
		key: 'salePrice',
		width: '10%',
		align: 'center',
		render: priceFenToYuanCell
	}, {
		title: '库存',
		dataIndex: 'total_quantity',
		key: 'total_quantity',
		width: '10%',
		align: 'center',
		onCell: tooltipStyle,
		render: tooltip
	}, {
		title: '状态',
		dataIndex: 'goodsState',
		key: 'goodsState',
		width: '10%',
		align: 'center',
		onCell: tooltipStyle,
		render: obgResolve
	}, {
		title: '更新时间',
		dataIndex: 'gmtModified',
		key: 'gmtModified',
		width: '15%',
		align: 'center',
		onCell: tooltipStyle,
		render: tooltip
	}, {
		title: '操作',
		dataIndex: 'id',
		key: 'id',
		width: '15%',
		align: 'center',
		render: onTableOperate
	}];

	// 表格复选输入
	const onRowSelection = {
		selectedRowKeys: goodsIds,
		onChange: (keys) => {
			setKeys(keys);
			tableOperate(null, keys, 'batch');
		}
	};
	// 清空表格复选框
	const onCleanAllSelected = () => {
		setKeys([]);
		tableOperate(null, [], 'batch');
	};

	return (
		<div className={styles.tableContainer} >
			<XTableSelectionCount selectedNum={keys.length} onClean={onCleanAllSelected} />
			<Table
				rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
				rowSelection={onRowSelection}
				columns={columns}
				pagination={false}
				rowKey={record => record.id}
				dataSource={renderData.dataList}
				scroll={{ y: true }} />
			<XPagination
				resultTotal={renderData.resultTotal}
				pageSize={renderData.pageSize}
				pageNum={renderData.pageNum}
				onChange={paginationChange} />
		</div>
	);
};

TableContent.propTypes = {
	tableOperate: PropTypes.func,
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
	goodsIds: PropTypes.array
};

export default TableContent;