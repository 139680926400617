import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Card } from 'antd';

const NoDelivery = ({
    closeMode,
    modeParames: {
        values: {
            templateName = '',
            nodeliveryAreaNames = ''
        } = {}
    }
}) => {

    const [list, setList] = useState([]);

    useEffect(() => {
        setList(nodeliveryAreaNames.split(','));
    }, [nodeliveryAreaNames]);

    const baseStyle = {
        width: '100%'
    };

    const tableStyle = {
        width: '100%',
        height: 300,
        overflowY: 'auto'
    };

    const buttonStyle = {
        width: '100%',
        textAlign: 'right',
        marginTop: 20
    };


    return <Modal
        width={400}
        centered
        bodyStyle={{ padding: 10 }}
        title={`${templateName}（不配送地区）`}
        visible={true}
        confirmLoading={false}
        onCancel={closeMode}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        footer={false}
    >
        <div style={baseStyle}>
            <Card
                title='不配送地区'
                style={tableStyle}>
                {
                    list.map((item, index) => {
                        return <p key={index}>{item}</p>;
                    })
                }
            </Card>
            <div style={buttonStyle}>
                <Button
                    type='primary'
                    style={{ width: 60 }}
                    onClick={closeMode}
                >返回</Button>
            </div>
        </div>
    </Modal>;
};
NoDelivery.propTypes = {
    modeParames: PropTypes.object, // 弹框入参
    closeMode: PropTypes.func, // 关闭弹框的回调函数
};
export default NoDelivery;