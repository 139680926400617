/**
 * 银联协议审核状态
 */
import React from 'react';
import styles from './step.module.scss';
import PropTypes from 'prop-types';
import { Icon, Button } from 'antd';
import getUnionInfo from './../components/getUnionInfo';

class step4 extends React.Component {

    state = {
        checkAuditBoolean: false
    }

    componentDidMount() {
        getUnionInfo().then((response) => {
            const { type, data } = response;
            if (data.auditStatus == 1) {
                this.setState({ checkMoneyBoolean: true });
            }
        });
    }

    _handleSubmit = () => {
        this.props.next();
    }

    render() {
        const { checkAuditBoolean } = this.state;
        return (
            <div className={styles.step4}>
                <div className={styles.flexContent}>
                    <div className={styles.mainTitle}>
                        {checkAuditBoolean ? <Icon type="check-circle" theme="filled" className={styles.iconStyles} /> : <Icon type="info-circle" theme="filled" className={styles.iconStyles} />}
                        <div className={styles.title}>{checkAuditBoolean ? '审核通过' : '审核中....'}</div>
                    </div>
                    <div className={styles.desc}>
                        {checkAuditBoolean ? '恭喜您审核通过，请点击【下一步】继续完成其它验证' : '请您耐心等待，审核工作将在2个工作日内完成，遇到周末或法定节假日则顺延。如有疑问请联系客服：400-135-8587'}
                    </div>
                    {checkAuditBoolean ? <div className={styles.btn}><Button type="primary" onClick={this._handleSubmit}>下一步</Button></div> : null}
                </div>
            </div>
        );
    }
}

step4.propTypes = {
    previous: PropTypes.func, // 上一步
    next: PropTypes.func, // 下一步
};

export default step4;

