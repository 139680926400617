import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import LiveTable from './components/LiveTable';
import { OnlinePlayModal } from '../../modal/OnlinePlayModal';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getLiveGoodsList, getLiveClassList } from '@/reducers/marketingmanage/livegoods/actions';
import { XInput, XSelect, XOKButton, XCancelButton, showConfirm, XDatePicker } from '@/components/xqxc_ui';
import styles from './index.module.scss';

class Main extends Component {

    state = {
        renderMode: '',//渲染弹框
        modeParams: {},//弹框入参
        broadcasTitle: undefined,
        liveCategory: {},//上下架
        startDate: null,
        tel: null, //直播账号
        pageNum: 1,
        pageSize: 10,
    };

    //props校验
    static propTypes = {
        history: PropTypes.object,//路由对象
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getLiveGoodsList: PropTypes.func,//获取列表
        livegoodsList: PropTypes.object,//列表数据
        getLiveClassList: PropTypes.func,
        liveclassList: PropTypes.array,
    };

    //挂载后
    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getLiveClassList({classTypeCode:'LIVE_BORADCAS_CLASS'});
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, tel, broadcasTitle, startDate, liveCategory } = this.state;
        this.searchCache = {
            tel,
            broadcasTitle,
            startDate: startDate ? startDate.format('YYYY-MM-DD') : '',
            broadcasClassId: liveCategory.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getLiveGoodsList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    };

    // 重置
    _resetHandle = () => {
        this.setState({
            broadcasTitle: undefined,
            liveCategory: {},
            startDate:null,
            tel: '',
            pageNum: 1,
            pageSize: 10,
        }, () => KeepAlive.saveResetFunc(this._searchHandle)); // 【第4步：将是否调用重置交给KeepAlive控制】
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => this._searchHandle('useCache'));
    };

    initErrHandle = (res = {}, err) => {
        message.error(res.message || err);
    }

    // 表格操作
    _tableAction = (value, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'Live':
                this.setState({ modeParams: value, renderMode: 'Live' });
                break;
            case 'detail':
                addOneBreadcrumbPath({
                    title: '本场订单交易',
                    path: '/home/marketingmanage/livegoods/detailInfo/'
                });
                history.push({
                    pathname: `/home/marketingmanage/livegoods/detailInfo/${value.liveId}`
                });
                break;
            case 'Play':
                this.setState({ modeParams: value, renderMode: 'Play' });
                break;
            case 'del':
                showConfirm('您确定删除该直播？', '', () => {
                    http('/live/deleteLiveBroadcas', { liveId: value.liveId, isDelete: 1, liveType: 'GENERAL' }).then(res => {
                        if (res.status === 200) {
                            message.success('删除成功');
                            this._searchHandle('useCache');
                        }
                    }).catch(e => this.initErrHandle(e, '操作异常'));
                });
                break;
        }
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { modeParams, renderMode } = this.state;
        switch (renderMode) {
            case 'Live': // 播放
                return <OnlinePlayModal visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
            case 'Play': // 回放
                return <OnlinePlayModal visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
            default:
                return null;
        }
    };

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ renderMode: '' });
        update && this._searchHandle('useCache');
    }

    //渲染组件
    render() {
        const {
            tel,
            broadcasTitle,
            startDate,
            liveCategory,
        } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XInput
                            style={{ width: '250px' }}
                            label='主播帐号'
                            placeholder='请输入主播帐号'
                            value={tel}
                            bindThis={this}
                            bindName='tel'
                        />
                        <XInput
                            style={{ width: '250px', marginLeft: '20px' }}
                            label='直播标题'
                            placeholder='请输入直播标题'
                            value={broadcasTitle}
                            bindThis={this}
                            bindName='broadcasTitle'
                        />
                        <XSelect
                            style={{ width: '250px', marginLeft: '20px' }}
                            selectStyle={{ width: '100%' }}
                            showSearch
                            label='直播间分类'
                            placeholder='请选择直播间分类'
                            renderData={this.props.liveclassList}
                            keyIndex='id'
                            dataIndex='className'
                            value={liveCategory.className}
                            bindThis={this}
                            bindName='liveCategory'
                        />
                        <XDatePicker
                            style={{ marginLeft: '30px', }}
                            label='直播日期'
                            showTime={false}
                            // onChange={this._orderStartTimeChangeHandle}
                            value={startDate}
                            bindThis={this}
                            bindName='startDate'
                            // disabled={startPickerDisabled}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <LiveTable renderData={this.props.livegoodsList || {}} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

//映射数据源
const mapStateToProps = (state) => ({
    livegoodsList: state.livegoods.livegoodsList,
    liveclassList: state.livegoods.liveclassList
});

//暴露组件
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getLiveGoodsList, getLiveClassList })(Main);
