/**
 * 发货管理
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actionTypes';

/**
 * 获取待发货出库单列表
 */
export const getReadyDeliverList = function* () {
    yield takeEvery(T.GET_READYDELIVER_LIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/distributionOutboundOrderList', action.payload, 'POST');
            yield put({ type: T.SET_READYDELIVER_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 获取快递公司下拉列
 */
export const getLogisticsSelect = function* () {
    yield takeEvery(T.GET_LOGISTICS_SELECT, function* requestData(action) {
        try {
            let result = yield http('/fastpass/dropDownList', action.payload, 'POST');
            yield put({ type: T.SET_LOGISTICS_SELECT, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 获取批发订单下拉数据（ 待发货出库单、已发货出库单、已取消出库单共用）
 */
export const getWholesaleSelect = function* () {
    yield takeEvery(T.GET_WHOLESALE_SELECT, function* requestData(action) {
        try {
            let result = yield http('/enum/DistributionFlagEnum', action.payload, 'POST');
            yield put({ type: T.SET_WHOLESALE_SELECT, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
