import { getLocalStorage } from '@/assets/js/storage';
import { message } from 'antd';

let openCustomService;
function loadScript(url, callback) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    if (script.readyState) {  // ie游览器
        script.onreadystatechange = function () {
            if (script.readyState == 'loaded' || script.readyState == 'complete') {
                script.onreadystatechange = null;
                callback && callback();
            }
        };
    } else { //其他
        script.onload = function () {
            callback && callback();
        };
    }
    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
}

function loadYKF(accessId) {
    const { userInfo } = JSON.parse(getLocalStorage('auth'));
    window.qimoClientId = userInfo.loginUserId + '';
    if (userInfo && userInfo.loginUserId) {
        loadScript(`https://ykf-webchat.7moor.com/javascripts/7moorInit.js?accessId=${accessId}&autoShow=false&language=ZHCN`, function () {
            let qimoIntercal = setInterval(() => {
                if (window.qimoChatClick) {
                    openCustomService = window.qimoChatClick;
                    clearInterval(qimoIntercal);
                }
            }, 300);
        });
    } else {
        message.error('用户未登录');
    }
}

function closeChat() {
    let chat = document.getElementById('qimo_chatpup');
    chat && chat.remove();
}

export { loadYKF, openCustomService, closeChat };