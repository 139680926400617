import React from 'react';
import styles from './BackGround.module.scss';

const BackGround = () => {
    return (
        <div className={styles.bg}>
            <div className={styles.top_park}>
                <img className={styles.beijing_img} src={require('@/assets/image/background.png')} />
                {/* <img className={styles.beijing_txt} src= {require('@/assets/image/bgtxt.png')} /> */}
                <div className={styles.beijing_txt}>HELLO,WELCOME BACK!</div>
            </div>
            {/* <div className={styles.bottom_park}>
                <div className={styles.companyname}>秀商时代（重庆）科技有限公司</div>
            </div> */}
        </div>
    );
};

export default BackGround;