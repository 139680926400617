/**
 * 【供应商页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Select } from 'antd';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getSupplierListTableSource, getlistServiceProviderKetName } from '@/reducers/suppliermanage/supplierlist/actions';
import { getSupplierAuditSele } from '@/reducers/auditManage/supplierAudit/actions';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import StopContract from '../../modal/StopContract';
import NewWarehouse from '../../modal/NewWarehouse';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import styles from './index.module.scss';

// 弹框枚举类型
const Mode = {
    STOP_CONTRACT: 'STOP_CONTRACT', // 终止合作
    NEW_WAREHOUSE: 'NEW_WAREHOUSE', // 新建仓库
};
const { Option } = Select;
class Main extends React.Component {
    state = {
        supplierName: '', // 供应商名称
        operateType: {}, // 经营品类
        currentMode: '', // 当前弹框
        modeParam: '', // 弹框入参
        supplierObj: {}, // 推荐人
        pageNum: 1,
        pageSize: 20, settleTypeItem: {}
    };

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
        // this.props.getSupplierListTableSource(this.searchCache);
        this.props.getSupplierAuditSele();
        // 服务的
        this.props.getlistServiceProviderKetName();
    }

    // 新建供应商
    _newSupplierHandle = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建供应商',
            path: '/home/suppliermanage/supplierlist/create'
        });
        history.push({
            pathname: '/home/suppliermanage/supplierlist/create'
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { operateType, supplierName, supplierObj, pageNum, pageSize, settleTypeItem } = this.state;
        this.searchCache = {

            supplierName,
            categoryId: operateType.id,
            serviceId: supplierObj.id, settleType: settleTypeItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.props.getSupplierListTableSource(this.searchCache);
    }

    // 重置触发 
    _ruleresetHandle = () => {
        this.setState({ supplierName: '', operateType: {}, pageNum: 1, pageSize: 10, serviceId: null, supplierObj: {}, settleTypeItem: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'accountStatement': // 对账单
                addOneBreadcrumbPath({
                    title: '对账清单',
                    path: '/home/storehousefinancialmanage/suppliersettlement'
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/suppliersettlement',
                    state: { tabID: 2, orderId: id },
                });
                break;
            case 'deliver': // 发货清单
                addOneBreadcrumbPath({
                    title: '发货清单',
                    path: '/home/storehousefinancialmanage/suppliersettlement'
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/suppliersettlement',
                    state: { tabID: 1, orderId: id },
                });
                break;
            case 'contract': // 合同管理
                addOneBreadcrumbPath({
                    title: '合同管理',
                    path: '/home/storehousefinancialmanage/suppliersettlement'
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/suppliersettlement',
                    state: { tabID: 3, orderId: id },
                });
                break;
            case 'stop': // 终止合同
                this.setState({ modeParam: id, currentMode: Mode.STOP_CONTRACT });
                break;
            case 'newWarehouse': // 新建仓库
                this.setState({ modeParam: id, currentMode: Mode.NEW_WAREHOUSE });
                break;
            case 'newShop': // 新建店铺
                addOneBreadcrumbPath({
                    title: '新建店铺',
                    path: '/home/suppliermanage/supplierlist/newshop'
                });
                history.push({
                    pathname: '/home/suppliermanage/supplierlist/newshop/' + id
                });
                break;
            case 'detail': // 查看详情
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: '/home/suppliermanage/supplierlist/detail/'
                });
                history.push({
                    pathname: '/home/suppliermanage/supplierlist/detail/' + id
                });
                break;
            case 'edit': //编辑
                addOneBreadcrumbPath({
                    title: '编辑供应商',
                    path: '/home/suppliermanage/supplierlist/edit'
                });
                history.push({
                    pathname: `/home/suppliermanage/supplierlist/edit/${id}`,
                });
        }
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageSize,
            pageNum
        }, () => {
            this._searchHandle('useCache');
        });
    }

    // 渲染弹框
    _renderMode = () => {
        switch (this.state.currentMode) {
            case Mode.STOP_CONTRACT:
                return <StopContract visible={true} closeMode={this._closeMode} modeParam={this.state.modeParam} />;
            case Mode.NEW_WAREHOUSE:
                return <NewWarehouse visible={true} closeMode={this._closeMode} modeParam={this.state.modeParam} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentMode: '' });
    }
    // onSelectChange = e => {
    //     this.setState({ serviceId: e });
    // }
    render() {
        const { supplierName, operateType, serviceId, supplierObj, settleTypeItem } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria}>
                        <XInput
                            style={{ width: '264px' }}
                            inputStyle={{ width: '180px' }}
                            label='供应商名称'
                            placeholder='请输入供应商名称'
                            value={supplierName}
                            bindThis={this}
                            bindName='supplierName'
                        />
                        <XSelect
                            style={{ marginLeft: '20px', width: '250px' }}
                            selectStyle={{ width: '180px' }}
                            label='经营品类'
                            placeholder='请选择品类'
                            renderData={this.props.supplierAuditSele}
                            dataIndex='categoryName'
                            keyIndex='id'
                            value={operateType.categoryName}
                            showSearch={true}
                            bindThis={this}
                            bindName='operateType'
                        />
                        <XSelect
                            style={{ marginLeft: '20px', width: '250px' }}
                            selectStyle={{ width: '180px' }}
                            label='推荐人'
                            placeholder='请选择推荐人'
                            allowClear
                            renderData={this.props.listServiceProviderKetName}
                            dataIndex='companyName'
                            keyIndex='id'
                            value={supplierObj.id}
                            showSearch={true}
                            bindThis={this}
                            bindName='supplierObj'
                            isOptionBindID={true}
                        />
                        <XSelect style={{ marginLeft: '20px' }} selectStyle={{ width: '200px' }} label='结算方式' bindThis={this} bindName='settleTypeItem'
                            placeholder='请选择' renderData={[{ code: 'ONLINE_SETTLE', value: '线上结算' }, { code: 'OFFLINE_SETTLE', value: '线下结算' }]}
                            dataIndex='value' keyIndex='code' value={settleTypeItem.value}
                        />
                        {/* <div style={{ width: 265, marginLeft: 10 }}>
                         <span style={{ display: 'inline-block', width: 51, marginLeft: 10, color: '#252525' }}>推荐人</span>
                        <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="请选择推荐人"
                            value={serviceId}
                            onChange={this.onSelectChange}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {this.props.listServiceProviderKetName.map(i => {
                                return <Option key={i.id} value={i.id}>{i.companyName}</Option>;
                            })}
                        </Select>
                        </div> */}
                        <XOKButton style={{ marginLeft: '20px' }} label='查询' onClick={this._searchHandle} />
                        <XCancelButton style={{ marginLeft: '10px' }} label='重置' onClick={this._ruleresetHandle} />
                    </div>
                    <div className={styles.export}>
                        <XOKButton label='新建供应商' onClick={this._newSupplierHandle} />
                    </div>
                    <TableContent renderData={this.props.supplierListTableSource} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // history router
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航

    supplierListTableSource: PropTypes.object,
    getSupplierListTableSource: PropTypes.func,

    supplierAuditSele: PropTypes.array,
    getSupplierAuditSele: PropTypes.func,
    getlistServiceProviderKetName: PropTypes.func,
    listServiceProviderKetName: PropTypes.array,
};

const mapStateToProps = (state) => ({
    listServiceProviderKetName: state.supplierlist.listServiceProviderKetName,
    supplierListTableSource: state.supplierlist.supplierListTableSource,
    supplierAuditSele: state.supplierAudit.supplierAuditSele
});


export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getSupplierListTableSource, getSupplierAuditSele, getlistServiceProviderKetName })(Main);