import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import omit from 'omit.js';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import Table from './components/Table';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/purchasecontract/model';
import { XInput, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import { OperateType } from './_utils';
import styles from './index.module.scss';
import { getLocalStorage } from '@/assets/js/storage';

class Main extends Component {

    state = {
        id: undefined,//手机号
        startDate: undefined,//开始时间
        endDate: undefined//结束时间
    };

    //props校验
    static propTypes = {
        history: PropTypes.object,//路由对象
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getPurchaseContractList: PropTypes.func,//获取列表
        purchaseContractList: PropTypes.object,//列表数据
    }

    //挂载后
    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
    }

    getCache = () => omit(this.state, ['__aliveInit']);

    // 查询
    _searchHandle = (useCache) => {
        let cache = this.getCache();

        if (useCache !== 'useCache') {
            cache.pageNum = autoPageNum;// 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            cache.pageSize = autopageSize;// 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        }

        this.searchCache = cache;

        let { warehouseId } = JSON.parse(getLocalStorage('auth')).userInfo;
        this.props.getPurchaseContractList({ ...this.searchCache, warehouseId });

        KeepAlive.saveCache({ ...this.getCache(), pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            id: undefined,//手机号
            startDate: undefined,//开始时间
            endDate: undefined//结束时间
        }, () => KeepAlive.saveResetFunc(this._searchHandle)); // 【第4步：将是否调用重置交给KeepAlive控制】
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => this._searchHandle('useCache'));
        KeepAlive.saveCache({ ...this.getCache(), pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        const { history, addOneBreadcrumbPath } = this.props;

        switch (type) {
            case OperateType.Detail:
                addOneBreadcrumbPath({
                    title: '签到查看',
                    path: '/home/storehousefinancialmanage/purchasecontract/detail/' + id
                });
                history.push({ pathname: '/home/storehousefinancialmanage/purchasecontract/detail/' + id });
                break;
        }
    }

    //渲染组件
    render() {
        const {
            id,
            startDate,
            endDate,
        } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XInput
                            style={{ width: '200px', marginRight: '15px' }}
                            label='合同号'
                            placeholder='请输入合同号'
                            value={id}
                            bindThis={this}
                            bindName='id'
                        />
                        <XDatePicker
                            label='修改日期'
                            value={startDate}
                            bindThis={this}
                            bindName='startDate'
                            isFormat={true}
                        />
                        <XDatePicker
                            value={endDate}
                            bindThis={this}
                            bindName='endDate'
                            isFormat={true}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <Table renderData={this.props.purchaseContractList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

//映射数据源
const mapStateToProps = (state) => ({
    purchaseContractList: state.purchasecontract.purchaseContractList
});

//暴露组件
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
