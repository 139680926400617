import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Divider, Button, Modal, Row, Col } from 'antd';
import { XTitle, XInfoLabel, XMediaFn, XTabNav } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import * as QRCode from 'easyqrcodejs';
import { getLocalStorage } from '@/assets/js/storage';

const getDetailData = async () => {
    const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
    return http(`/merchant/supplier/getSupplier/${userInfo.supplierId}`, {}, 'GET')
        .then(res => { return res.result; });
};

const getUrl = async (url) => {
    return http(url, {}, 'POST')
        .then(res => { return res.result.url; });
};

const UpdateModal = ({ setRenderModal, visible, url }) => {

    useEffect(() => {
        if (url) {
            (async () => {
                const text = await getUrl(url);
                new QRCode(document.getElementById('entryinformationqrcoderef'), { text });
            })();
        }
    }, [url]);

    const modalStyle = {
        width: ' 100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    };

    return <Modal width={600} centered title='修改入驻资料' visible={visible} confirmLoading={false} footer={false}
        onCancel={() => setRenderModal(false)} destroyOnClose={true} keyboard={true} maskClosable={false}
    >
        <div style={modalStyle}>
            <div id='entryinformationqrcoderef' />
            <div style={{ margin: '20px 0' }}>当您的营业资料需要变更或结算方式发生变更时,</div>
            <div>请使用浏览器或微信扫一扫功能扫描上方二维码进行变更修改</div>
        </div>
    </Modal>;
};
UpdateModal.propTypes = {
    setRenderModal: PropTypes.func,
    visible: PropTypes.bool,
    url: PropTypes.string,
};

const tabList = [{ id: 'basic', label: '基本信息' }, { id: 'certification', label: '资质信息' }, { id: 'finance', label: '财务信息' }];

const EntryInformation = () => {

    const [current, setCurrent] = useState('basic');
    const [detailData, setDetailData] = useState({ merchantSupplier: {}, financialInfo: {}, images: [] });
    const [renderModal, setRenderModal] = useState(false);
    const [url, setUrl] = useState();
    const [urla, setUrla] = useState();
    const [urlb, setUrlb] = useState();


    useEffect(() => {
        (async () => {
            const result = await getDetailData();
            const urlc = await getUrl('/merchantEntering/merchantEnteringFinish');
            const urld = await getUrl('/merchantEdit/getEditQRCode');
            setUrla(urlc); setUrlb(urld);
            setDetailData(result);
        })();
    }, []);

    const titleStyle = { marginTop: '10px' };
    const infoStyle = { padding: '5px 0' };
    const topLabel = { marginBottom: '5px' };

    const settleTypes = { ONLINE_SETTLE: '线上结算', OFFLINE_SETTLE: '线下结算' };

    const components = {
        basic: <Row gutter={[0, 16]}>
            <Col span={24}><XTitle style={titleStyle} label='基础信息' /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='供应商名称：' value={detailData.merchantSupplier.supplierName} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='所在地区：' value={detailData.merchantSupplier.belongsArea} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='联系人：' value={detailData.merchantSupplier.contact} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='详细地址：' value={detailData.merchantSupplier.addrDetail} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='联系电话：' value={detailData.merchantSupplier.contactPhone} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='电子邮箱：' value={detailData.merchantSupplier.email} /></Col>
            <Col span={24}><XTitle style={titleStyle} label='经营信息' /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='经营品类：' value={detailData.merchantSupplier.categoryName} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='经营品牌：' value={detailData.merchantSupplier.brandName} /></Col>
            <Col span={12}>
                <div style={{ display: 'flex' }}>
                    <div style={{ color: '#999999' }}>品牌LOGO：</div>
                    <XMediaFn dataType='image' dataSource={detailData.merchantSupplier.logoUrl} />
                </div>
            </Col>
            <Col span={12}>
                <div style={{ display: 'flex' }}>
                    <div style={{ color: '#999999' }}>品牌背景：</div>
                    <XMediaFn dataType='image' dataSource={detailData.merchantSupplier.brandBackgroundUrl} />
                </div>
            </Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='品牌介绍：' value={detailData.merchantSupplier.brandIntroduce} /></Col>
            <Col span={24}><XTitle style={titleStyle} label='合作协议' /></Col>
            <Col span={12}>
                <div style={{ display: 'flex' }}><XMediaFn dataType='image' dataSource={detailData.images} /></div>
            </Col>
            <Col span={24}><XTitle label='结算信息' /></Col>
            <Col span={12}><XInfoLabel style={{ ...infoStyle, marginRight: '20px' }} label='结算模式：' value={settleTypes[detailData.merchantSupplier.settleType]} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='结算通道：' value={detailData.merchantSupplier.settleType === 'OFFLINE_SETTLE' ? '对公转账' : detailData.accessType} /></Col>
        </Row>,
        certification: <Row gutter={[0, 16]}>
            {/* <Col span={24}><XTitle style={titleStyle} label='经营场所' /></Col>
            <Col span={12}> <div className={styles.colBox}>
                <div style={topLabel}>门头照：</div>
                <div style={{ display: 'felx' }}><XMediaFn dataType='image' dataSource={''} /></div>
            </div></Col>
            <Col span={12}><div className={styles.colBox}>
                <div style={topLabel}>室内照：</div>
                <div style={{ display: 'felx' }}><XMediaFn dataType='image' dataSource={''} /></div>
            </div></Col> */}
            <Col span={24}><XTitle style={titleStyle} label='资质信息' /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='企业名称：' value={detailData.merchantSupplier.enterpriseName} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='营业执照号：' value={detailData.merchantSupplier.businessRegisterNum} /></Col>
            {/* <Col span={12}><XInfoLabel style={infoStyle} label='注册地址：' value={detailData.merchantSupplier.registerAddr} /></Col> */}
            <Col span={12}><XInfoLabel style={infoStyle} label='有效期限：' value={detailData.merchantSupplier.licenseExpireTime} /></Col>
            <Col span={24}>
                <div className={styles.colBox}>
                    <div style={topLabel}>营业执照：</div>
                    <div style={{ display: 'felx' }}><XMediaFn dataType='image' dataSource={detailData.merchantSupplier.businessLicenseUrl} /></div>
                </div>
            </Col>
            <Col span={24}><XTitle style={titleStyle} label='身份信息' /></Col>
            {/* <Col span={12}>
                <div className={styles.colBox}>
                    <div style={topLabel}>身份证人像面：</div>
                    <div style={{ display: 'felx' }}><XMediaFn dataType='image' dataSource={} /></div>
                </div>
            </Col>
            <Col span={12}>
                <div className={styles.colBox}>
                    <div style={topLabel}>身份证国徽面：</div>
                    <div style={{ display: 'felx' }}><XMediaFn dataType='image' dataSource={''} /></div>
                </div>
            </Col> */}
            <Col span={12}><XInfoLabel style={infoStyle} label='法人姓名：' value={detailData.financialInfo.legalName} /></Col>
            {/* <Col span={12}><XInfoLabel style={infoStyle} label='性别：' value={detailData.financialInfo.supplierName} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='出生年月日：' value={detailData.financialInfo.supplierName} /></Col> */}
            <Col span={12}><XInfoLabel style={infoStyle} label='身份证号码：' value={detailData.financialInfo.legalIdentity} /></Col>
            {/* <Col span={12}><XInfoLabel style={infoStyle} label='身份证住址：' value={detailData.financialInfo.supplierName} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='签发机关：' value={detailData.financialInfo.supplierName} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='有效期：' value={detailData.financialInfo.supplierName} /></Col>
            <Col span={24}><XTitle style={titleStyle} label='财务信息' /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='结算模式：' value={detailData.financialInfo.supplierName} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='账户名称：' value={detailData.financialInfo.supplierName} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='预留电话：' value={detailData.financialInfo.supplierName} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='开户银行：' value={detailData.financialInfo.supplierName} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='银行账号：' value={detailData.financialInfo.supplierName} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='收票人姓名：' value={detailData.financialInfo.supplierName} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='收票人电话：' value={detailData.financialInfo.supplierName} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='收票人邮箱：' value={detailData.financialInfo.supplierName} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='开户支行：' value={detailData.financialInfo.supplierName} /></Col> */}
        </Row>,
        finance: <Row gutter={[0, 16]}>
            {/* <Col span={12}>
                <div style={{ height: '140px' }}>
                    <div style={topLabel}>银行卡正面照：</div>
                    <div style={{ display: 'felx' }}><XMediaFn dataType='image' dataSource={''} /></div>
                </div>
            </Col>
            <Col span={12}>
                <div style={{ height: '140px' }}>
                    <div style={topLabel}>银行卡反面照：</div>
                    <div style={{ display: 'felx' }}><XMediaFn dataType='image' dataSource={''} /></div>
                </div>
            </Col> */}
            <Col span={24}><XTitle style={titleStyle} label='财务信息' /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='账户名称：' value={detailData.financialInfo.unitName} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='预留电话：' value={detailData.financialInfo.legalTel} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='开户银行：' value={detailData.financialInfo.openBank} /></Col>
            {/* <Col span={12}><XInfoLabel style={infoStyle} label='开户支行：' value={detailData.financialInfo.supplierName} /></Col> */}
            <Col span={12}><XInfoLabel style={infoStyle} label='银行账号：' value={detailData.financialInfo.bankAccount} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='收票人姓名：' value={detailData.financialInfo.receiveName} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='收票人电话：' value={detailData.financialInfo.receivePhone} /></Col>
            <Col span={12}><XInfoLabel style={infoStyle} label='收票人邮箱：' value={detailData.financialInfo.receiveMail} /></Col>
        </Row>
    };

    return <div className={styles.container}>
        <XTabNav renderData={tabList} onChange={v => setCurrent(v)} activedID={current} />
        <div className={styles.inner}>  {components[current]} </div>
        {detailData.merchantSupplier.settleType === 'ONLINE_SETTLE' && <Fragment>
            <Divider style={{ margin: '10px 0' }} />
            <div className={styles.footer}>
                {urla && <Button type='primary' style={{ marginRight: '20px' }} onClick={() => { setRenderModal(true); setUrl('/merchantEntering/merchantEnteringFinish'); }}>去进件</Button>}
                {urlb && <Button type='primary' style={{ marginRight: '20px' }} onClick={() => { setRenderModal(true); setUrl('/merchantEdit/getEditQRCode'); }}>修改</Button>}
            </div>
        </Fragment>}
        <UpdateModal visible={renderModal} setRenderModal={setRenderModal} url={url} />
    </div>;
};
EntryInformation.propTypes = {
    history: PropTypes.object
};
export default EntryInformation;