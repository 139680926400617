import * as T from './actiontypes';

//商品库存对接列表
export const getInventoryConnectList = (params = {}) => ({
    type: T.GET_INVENTORYCONNECT_LIST,
    payload: params
});

//商品对接商品状态
export const getInventoryConnectState = () => ({
    type: T.GET_INVENTORYCONNECT_STATE,
    payload: {}
});