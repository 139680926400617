/**
 * 仓库管理saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as T from './actiontypes';
import http from '@/assets/api/http';

// 获取已分配出库单列表
export const getInvoiceList = function* () {
    yield takeEvery(T.GET_INVOICELIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/distributionOutboundOrderList', action.payload, 'POST');
            yield put({ type: T.SET_INVOICELIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getInvoiceListManageTableSource = function* () {
    yield takeEvery(T.GET_INVOICEMANAGE_TABLESOURCE, function* requestData(action) {
        try {
            let result = yield http('/settle/settle/query', action.payload, 'POST');
            yield put({ type: T.SET_INVOICEMANAGE_TABLESOURCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getInvoiceListManageUnitSelectSource = function* () {
    yield takeEvery(T.GET_INVOICEMANAGE_UNIT_SELECTSOURCE, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/distributionOutboundOrderList', action.payload, 'POST');
            yield put({ type: T.SET_INVOICEMANAGE_UNIT_SELECTSOURCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

