import { createModel } from '@/store/tools';

const dataList = { dataList: [{ childCategory: [] }], pageNum: 1, pageSize: 20, resultTotal: 0 };

const model = {
    namespace: 'goodsclassifymanage',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getVirtualClassList': T('getVirtualClassList'),
        },
        actions: {
            'getVirtualClassList': A('getVirtualClassList'),
        },
        sagas: {
            'getVirtualClassList': S('getVirtualClassList', '/goods/category/tree'),
        },
        reducers: {
            'virtualClassList': R('getVirtualClassList', dataList),
        }
    })
};
export default createModel(model);