/**
 * 添加银行卡
 */
import React from 'react';
import { Modal, Input, Icon, message } from 'antd';
import { connect } from 'react-redux';
import model from '@/reducers/beanshopmanage/beanshop/model';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import http from '@/assets/api/http';
import { XInput, XCheckAll, XSelect } from '@/components/xqxc_ui';


const { TextArea } = Input;

class AddBankModal extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        bankNameObj: {},
        mobileFlag: true,
        bankAccountFlag: false,
        openAccountBankFlag: false,
    }
    componentDidMount() {
        this.props.getBankCardDropDownList();
        // this.props.getFinancial();
        const { data } = this.props;
        if (data && data.id) {
            http('/bankCard/edit', { id: data.id }, 'POST').then((res) => {
                this.setState({
                    cardHolder: res.result.cardHolder,
                    bankAccount: res.result.bankAccount,
                    openAccountBank: res.result.openAccountBank,
                    mobile: res.result.mobile,
                    bankAccountFlag: true,
                    openAccountBankFlag: true,
                    bankNameObj: {
                        code: res.result.bankNameCode,
                        value: res.result.bankNameValue,
                    }
                });
            }).catch((res) => {
                message.warn(res.message);
            });
            // this.props.getBankCardInfo({id: data.id});
        } else {
            this.getFinancialInfo();
        }
    }
    // 获取法人和手机号
    getFinancialInfo = () => {
        http('/merchant/info/getFinancial', {}, 'POST').then((res) => {
            this.setState({
                cardHolder: res.result.legalName,
                mobile: res.result.legalTel,
            });
        }).catch((res) => {
            message.warn(res.message);
        });
    }
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    _handleOk = () => {
        const { bankNameObj, openAccountBank, cardHolder, bankAccount, mobile, mobileFlag, openAccountBankFlag, bankAccountFlag } = this.state;
        const { data } = this.props;
        if (Object.keys(bankNameObj).length === 0) {
            message.warn('银行必选');
            return false;
        } else if (!openAccountBankFlag) {
            message.warn('请输入50位及50位以下的开户银行中文名称!');
            return false;
        } else if (!bankAccountFlag) {
            message.warn('请输入25位及25位以下的银行账号!');
            return false;
        } else if (!mobileFlag) {
            message.warn('请输入有效的手机号!');
            return false;
        }
        http('/bankCard/save', {
            id: data ? data.id : undefined,
            accountTypeCode: 'BANK_CARD',
            bankNameCode: bankNameObj.code,
            openAccountBank,
            cardHolder,
            bankAccount,
            mobile,
        }, 'POST').then((res) => {
            if (data && data.id) {
                message.success('编辑成功!');
            } else {
                message.success('添加成功!');
            }
            this.setState({
                visible: false,
            });
            this.props.closeMode();
            this.props.refsh();
        }).catch((res) => {
            message.warn(res.message);
        });
    }
    _openAccountBankChange = e => {
        this.setState({ openAccountBank: e });
    }
    __openAccountBankBlur = e => {
        let reg = /^[\u4e00-\u9fa5]+$/;
        if (e.target.value && reg.test(e.target.value) && e.target.value.length < 51) {
            this.setState({ openAccountBankFlag: true });
        } else if (e.target.value == '') {
            this.setState({ openAccountBankFlag: false });
        } else {
            this.setState({ openAccountBankFlag: false });
            message.warn('请输入50位及50位以下的开户银行中文名称!');
        }
    }
    _mobileChange = e => {
        let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
        if (e.target.value && reg.test(e.target.value)) {
            this.setState({ mobileFlag: true });
        } else if (e.target.value == ''){
            this.setState({ mobileFlag: false });
        } else {
            this.setState({ mobileFlag: false });
            message.warn('请输入有效的手机号!');
        }
    };
    _bankAccountChange = e => {
        let reg = /^\d{0,25}$/;
        if (e.target.value && reg.test(e.target.value)) {
            this.setState({ bankAccountFlag: true });
        } else if (e.target.value == '') {
            this.setState({ bankAccountFlag: false });
        } else {
            this.setState({ bankAccountFlag: false });
            message.warn('请输入25位及25位以下的银行账号!');
        }
    }
    _accoutbankhandle = e => {
        this.setState({ bankAccount: e });
    }
    _telhandle = e => {
        this.setState({ mobile: e });
    }
    render() {
        const { visible, title, bankNameObj, openAccountBank, cardHolder, bankAccount, mobile } = this.state;
        const { BankCardDropDownList, Financial } = this.props;
        return (
            <Modal
                width={470}
                // centered
                style={{ top: 150 }}
                title={title}
                visible={visible}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XSelect
                        style={{ height: 50 }}
                        selectStyle={{ width: '344px' }}
                        label='选择银行'
                        placeholder='请选择'
                        renderData={BankCardDropDownList}
                        bindThis={this}
                        bindName='bankNameObj'
                        dataIndex='value'
                        keyIndex='code'
                        value={bankNameObj.value}
                        isRequired
                    />
                    <XInput
                        style={{ height: 50 }}
                        inputStyle={{ width: '344px' }}
                        label='开户银行'
                        placeholder='请填写'
                        value={openAccountBank}
                        // bindThis={this}
                        // bindName='openAccountBank'
                        onChange={this._openAccountBankChange}
                        onBlur={this.__openAccountBankBlur}
                        isRequired
                    />
                    <XInput
                        style={{ height: 50 }}
                        inputStyle={{ width: '344px', marginLeft: 14 }}
                        label='持卡人'
                        disabled
                        placeholder='绑定法人名称,只读'
                        value={cardHolder}
                        bindThis={this}
                        bindName='cardHolder'
                        isRequired
                    />
                    <XInput
                        style={{ height: 50 }}
                        inputStyle={{ width: '344px' }}
                        label='银行账号'
                        placeholder='请填写'
                        value={bankAccount}
                        // bindThis={this}
                        // bindName='bankAccount'
                        onChange={this._accoutbankhandle}
                        onBlur={this._bankAccountChange}
                        isRequired
                    />
                    <XInput
                        style={{ height: 50 }}
                        inputStyle={{ width: '344px' }}
                        label='预留手机'
                        placeholder='法人手机号'
                        value={mobile}
                        // bindThis={this}
                        // bindName='mobile'
                        onChange={this._telhandle}
                        onBlur={this._mobileChange}
                        isRequired
                    />
                </div>
            </Modal>
        );
    }
}

AddBankModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    refsh: PropTypes.func,
    getBankCardDropDownList: PropTypes.func,
    BankCardDropDownList: PropTypes.array,
    getFinancial: PropTypes.func,
    Financial: PropTypes.object,
    getBankCardInfo: PropTypes.func,
    BankCardInfo: PropTypes.object,
};
const mapStateToProps = (state) => ({
    BankCardDropDownList: state.beanshop.BankCardDropDownList,
    Financial: state.beanshop.Financial,
    BankCardInfo: state.beanshop.BankCardInfo,
});
export default connect(mapStateToProps, {
    ...model.actions,
})(AddBankModal);
