/**
 * 申请退出活动
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { XSelect, XOKButton, XCancelButton, XInput } from '@/components/xqxc_ui';
import ExitTable from './components/TableContent';
import * as actions from '@/reducers/auditManage/activityProduct/actions';
import model from '@/reducers/auditManage/activityProduct/model';

class ExitActivity extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activityTitle: '', // 活动名称
            activityType: {}, // 活动类型
            goodsName: '',//商品名称
        };
    }

    //异步请求
    componentDidMount() {
        this.props.getActivityType();//活动类型
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, activityType, activityTitle, goodsName } = this.state;
        this.searchCache = {
            activityType: activityType.key,
            activityTitle,
            goodsName,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getExitList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ activityType: {}, activityTitle: '', goodsName: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getExitList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    //渲染页面
    render() {
        const { exitList, activityTypes, } = this.props;
        const { activityType, activityTitle, goodsName, } = this.state;

        return (
            <KeepAlive id='4' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='活动名称'
                            placeholder='请输入'
                            bindThis={this}
                            bindName='activityTitle'
                            value={activityTitle}
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '200px' }}
                            label='活动类型'
                            placeholder='请选择'
                            renderData={activityTypes}
                            bindThis={this}
                            bindName='activityType'
                            dataIndex='value'
                            keyIndex='key'
                            value={activityType.value}
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '200px' }}
                            label='商品名称'
                            placeholder='请输入'
                            bindThis={this}
                            bindName='goodsName'
                            value={goodsName}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <ExitTable paginationChange={this._paginationChange} renderData={exitList} />
                </div>
            </KeepAlive>
        );
    }
}

//映射异步数据
const mapStateToProps = (state) => ({
    exitList: state.activityGoodsData.exitList,
    activityTypes: state.activityGoodsData.activityType,
});

//props检验
ExitActivity.propTypes = {
    getExitList: PropTypes.func,//异步获取列表数据
    getActivityType: PropTypes.func,//异步获取类型枚举数据
    exitList: PropTypes.object,//列表数据
    activityTypes: PropTypes.array,//类型枚举数据
};

export default connect(mapStateToProps, { ...model.actions, ...actions })(ExitActivity);