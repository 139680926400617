//结算价调整列表
export const GET_BALANCEHINT_LIST = 'BALANCEHINT/GET_BALANCEHINT_LIST';
export const SET_BALANCEHINT_LIST = 'BALANCEHINT/SET_BALANCEHINT_LIST';

//结算价调整列表
export const GET_NOTBALANCEHINT_LIST = 'BALANCEHINT/GET_NOTBALANCEHINT_LIST';
export const SET_NOTBALANCEHINT_LIST = 'BALANCEHINT/SET_NOTBALANCEHINT_LIST';

//结算价调整信息
export const GET_BALANCEHINT_INFO = 'BALANCEHINT/GET_BALANCEHINT_INFO';
export const SET_BALANCEHINT_INFO = 'BALANCEHINT/SET_BALANCEHINT_INFO';