/**
 * 付款确认 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, Input, Alert, Form, message } from 'antd';
import numSwitch from '@/util/numSwitch';
import { regFenToYuan } from '@/util/money';
import md5 from 'js-md5';
import styles from './PayConfirmate.module.scss';

const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
    }
};

class NoLogin extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false
    }

    // 点击确认
    _handleOk = () => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.setState({ confirmLoading: true });
                http('/wallet/withdraw', {
                    ...fieldsValue,
                    password: md5(fieldsValue.password).toUpperCase()
                }, 'POST').then((res = {}) => {
                    if (res.code === 200) {
                        message.success('确认付款成功');
                        this.setState({ visible: false, confirmLoading: false });
                        this.props.closeMode(true);
                    }
                }).catch((res = {}) => {
                    this.setState({ confirmLoading: false });
                    message.error('确认付款失败，原因：' + res.message);
                });
            }
        });
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({ visible: false });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;

        let { settleAmount, bankCardNum } = this.props.data || {};
        let showSettleAmount = regFenToYuan(settleAmount);

        return (
            <Modal
                width={700}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Alert message="将当前可结算金额全部转至到商户绑定的银行卡，每笔手续费1元。可结算时间早7点到晚8点" type="info" showIcon style={{ textAlign: 'left' }} />
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item label='合计转账金额' style={{ textAlign: 'left', fontSize: 18, marginTop: 15 }}>
                            {showSettleAmount} ({numSwitch(parseFloat(showSettleAmount))})
                        </Form.Item>
                        <Form.Item label='银行卡号' style={{ textAlign: 'left', fontSize: 18 }}>
                            {bankCardNum}
                        </Form.Item>
                        <Form.Item label='财务密码'>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: '请输入' }],
                            })(<Input.Password placeholder="请输入" />)}
                        </Form.Item>
                        <Form.Item label={<span>摘要</span>}>
                            {getFieldDecorator('remark', {
                                rules: [{ required: true, message: '请输入摘要' }, { max: 20, message: '最多20个字符' }, { min: 2, message: '最少2个字符' }],
                            })(<TextArea rows={4} maxLength={20} />)}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

NoLogin.propTypes = {
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(NoLogin);// 校验
export default connect(null, null)(ForgotProducDetails);