/**
 * 未分配订单
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message } from 'antd';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, XDatePicker, showConfirm } from '@/components/xqxc_ui';
import OrderTable from './components/OrderTable';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getUnassignedoOrderList } from '@/reducers/storehousemanage/unassignedorder/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {

    state = {
        id: '',
        accountName: '',
        payTimeStart: null,
        payTimeEnd: null,
        selectedRowKeys: [], // 复选框
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
    }


    // 查询
    _searchHandle = (useCache) => {
        const { id, accountName, payTimeStart, payTimeEnd, pageNum, pageSize } = this.state;
        this.searchCache = {
            id, accountName, payTimeStart, payTimeEnd,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getUnassignedoOrderList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】

    }

    // 重置
    _resetHandle = () => {
        this.setState({
            id: '',
            accountName: '',
            payTimeStart: null,
            payTimeEnd: null,
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });

    }

    // 表格操作
    _tableAction = (id, type, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info':
                addOneBreadcrumbPath({
                    title: '未分配订单详情',
                    path: '/home/storehousemanage/unassignedorder/detail/'
                });
                history.push({
                    pathname: '/home/storehousemanage/unassignedorder/detail',
                    search: `?buyerId=${record.buyerId}&&orderId=${record.id}`,
                });
                break;
            case 'assign':
                showConfirm('您是否确定重新分配？', '', () => {
                    this._sendReq('/merchant/luxury/restartSplitOrder', { buyerId: record.buyerId, orderId: record.id }, '分配成功。');
                });
                break;
        }
    }
    //请求函数体
    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST').then(() => {
            this._searchHandle('useCache');
            message.success(winTxt);
        }).catch((error) => {
            message.error(error.message);
        });
    }
    render() {
        const {
            id,
            accountName,
            payTimeStart,
            payTimeEnd,
            selectedRowKeys
        } = this.state;
        const { unassignedorderList } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria1}>
                        <XInput
                            style={{ width: '248px' }}
                            inputStyle={{ width: '180px' }}
                            label='订单编号'
                            placeholder='请输入订单编号'
                            value={id}
                            bindThis={this}
                            bindName='id'
                        />
                        <XInput
                            style={{ width: '248px', marginLeft: '20px', }}
                            inputStyle={{ width: '180px' }}
                            label='会员账号'
                            placeholder='请输入会员账号'
                            value={accountName}
                            bindThis={this}
                            bindName='accountName'
                        />
                        <XDatePicker
                            style={{ width: '264px', marginLeft: '20px' }}
                            label='付款日期'
                            value={payTimeStart}
                            bindThis={this}
                            bindName='payTimeStart'
                            isFormat={true}
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            label=''
                            value={payTimeEnd}
                            bindThis={this}
                            bindName='payTimeEnd'
                            isFormat={true}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <OrderTable
                        renderData={unassignedorderList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                        selectedRowKeys={selectedRowKeys}
                        selectedRowKeysChange={(selectedRowKeys) => { this.setState({ selectedRowKeys }); }}
                    />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getUnassignedoOrderList: PropTypes.func,
    unassignedorderList: PropTypes.object,
};
const mapStateToProps = (state) => ({
    unassignedorderList: state.unassignedorder.unassignedorderList,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getUnassignedoOrderList })(Main);