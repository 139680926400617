import {
    getCustomListTableSource,
    // getCustomListHomeTableSource,
    // getCustomListMallTableSource,
    // getCustomListSubjectTableSource,
    getCustomListAdvertTableSource
} from './appcustom/saga';
import {
    cardticketList,
    cardticketDropDown,
    cardticketRaiseAll,
    cardtickDetails,
    cardtickRaiseTable,
    cardtickShopMode,
    cardtickAllGoods,
} from './cardticket/saga';//卡劵管理
import {
    marketingDropDown,
    MarketingList,
    productRaiseTable,
    marketingDetails,
    marketingProductList,
    marketingProductDetails,
    productSkuTable,
    productRaiseAll,
} from './activities/saga';//商户活动
import {
    advocacytaskList,
    advocacyDropDown,
    advocacyRaiseTable,
    advocacyDetails,
    advocacyParticipateDetails,
} from './advocacytask/saga';//平台活动
import {
    purchasingList,
    purchasingTable,
    purchasingDetails,
} from './purchasing/saga';//集采管理
import advocacyMerchants from './advocacyMerchants/model';//代言任务
import { getLiveGoodsList, getLiveClassList, getLiveGoodsOrderList } from './livegoods/saga';
import purchasing from './purchasing/model';
import storeActivity from './storeActivity/model';//店铺活动
import commonRequest from './common/model';//公共请求
import memberlist from './memberlist/model';//推荐会员列表
import invitemember from './invitemember/model';//邀请会员
import fightrecordSaga from './fightrecord/model';//抢拍记录
import advocacyCreate from './advocacytask/model';//新增平台活动

export default [
    getCustomListTableSource,
    // getCustomListHomeTableSource,
    // getCustomListMallTableSource,
    // getCustomListSubjectTableSource,
    getCustomListAdvertTableSource,

    marketingDropDown,//商户活动下拉框
    MarketingList,//商户活动列表
    productRaiseTable,//商户活动新建数据
    productSkuTable,//商户活动新增sku设置条件
    marketingDetails,//商户活动编辑获取新建详情
    marketingProductList,//商户活动》详情》活动商品列表
    marketingProductDetails,// 商户活动》详情》活动商品列表 > 详情Saga
    productRaiseAll,//会员等级

    advocacytaskList,//平台活动列表
    advocacyDropDown,//平台活动下拉框
    advocacyRaiseTable,//平台活动新建-选择商品表格
    advocacyDetails,//平台活动参与活动详情获取数据
    advocacyParticipateDetails,//平台活动详情获取数据Saga

    cardticketList,//卡劵管理列表
    cardticketDropDown,//卡劵管理下拉框
    cardticketRaiseAll,//卡劵管理新建-使用门店、和全选复选、商品范围、有限期固定时长
    cardtickDetails,
    cardtickRaiseTable,
    cardtickShopMode,
    cardtickAllGoods,

    purchasingList,//集采管理列表
    purchasingTable,//集采管理新增商品
    purchasingDetails,//集采管理详情
    ...Object.values(purchasing.sagas),

    ...Object.values(advocacyMerchants.sagas),
    ...Object.values(storeActivity.sagas),
    ...Object.values(invitemember.sagas),

    getLiveGoodsList, // 直播带货
    getLiveClassList, // 直播间分类
    getLiveGoodsOrderList, // 本场订单

    //公共请求
    ...Object.values(commonRequest.sagas),

    //推荐会员列表
    ...Object.values(memberlist.sagas),
    ...Object.values(fightrecordSaga.sagas),
    ...Object.values(advocacyCreate.sagas),
];