/**
 * 【仓库列表】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message } from 'antd';
import { XInput, XSelect, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import AdvertTable from './components/AdvertTable';
import DeleteModal from './modal/DeleteModal';
import StartModal from './modal/StartModal';
import StopModal from './modal/StopModal';
import NewStorehouse from './modal/NewStorehouse';
import EditStorehouse from './modal/EditStorehouse';
import { getStoreHouseList, getStoreHouseType } from '@/reducers/storehousemanage/storehouselist/actions';
import { setLocalStorage } from '@/assets/js/storage';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {

    state = {
        companyStatus: {},
        warehouseName: '',
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this.props.getStoreHouseList();
        this.props.getStoreHouseType();
    }
    // 类型
    _companyStatusChange = value => {
        this.setState({
            companyStatus: value,
        });
    }
    // 查询
    _searchHandle = (useCache) => {
        const { warehouseName, companyStatus, pageSize, pageNum } = this.state;
        this.searchCache = {
            warehouseName,
            warehouseType: companyStatus.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getStoreHouseList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 刷新
    _refresh = () => {
        this._searchHandle('useCache');
        // const { warehouseName, companyStatus, pageNum, pageSize } = this.state;
        // this.props.getStoreHouseList({ warehouseName, warehouseType: companyStatus.code, pageNum, pageSize });
    }
    // 重置
    _resetHandle = () => {
        this.setState({ warehouseName: '', pageNum: 1, pageSize: 10, companyStatus: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        // this.props.getStoreHouseList();
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        const { warehouseName, companyStatus } = this.state;
        this.setState({
            pageNum,
            pageSize,
        });
        this.searchCache = {
            warehouseName,
            warehouseType: companyStatus.code,
            pageNum,
            pageSize,
        };
        this.props.getStoreHouseList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    _newHandle = () => {
        this.setState({ currentOpenMode: 'new' });
    };
    // 表格操作
    _tableAction = (id, type) => {
        switch (type) {
            case 'del':
                // this.setState({ currentOpenMode: 'del', openModeParam: id });
                showConfirm('是否确定删除？', '', () => {
                    http('/merchant/warehouse/delete', { warehouseId: id }, 'POST')
                        .then(() => {
                            message.success('删除成功。');
                            this._refresh();
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'edit':
                this.setState({ currentOpenMode: 'edit', openModeParam: id });
                break;
            case 'start':
                // this.setState({ currentOpenMode: 'start', openModeParam: id });
                showConfirm('是否确定启用？', '', () => {
                    http('/merchant/warehouse/updateState', { warehouseId: id, warehouseState: 1 }, 'POST')
                        .then(() => {
                            message.success('启用成功。');
                            this._refresh();
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'stop':
                // this.setState({ currentOpenMode: 'stop', openModeParam: id });
                showConfirm('是否确定停用？', '', () => {
                    http('/merchant/warehouse/updateState', { warehouseId: id, warehouseState: 0 }, 'POST')
                        .then(() => {
                            message.success('停用成功。');
                            this._refresh();
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'goin':
                var tempwin = window.open('about:blank', 'WE');
                http('/admin/empl/quickLogin', {
                    accountType: 'WE',
                    sourceId: id,
                }, 'POST').then((response) => {
                    setLocalStorage('auth', response.result, 'WE');
                    // window.open('/#/home', 'WE'); // 使用这种方法会被浏览器拦截
                    tempwin.location = '/#/home';
                }).catch((e) => {
                    message.error(e.message);
                    tempwin.close();
                });
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'new':
                return <NewStorehouse title='新建仓库' refresh={this._refresh} visible={true} closeMode={this._closeMode} />;
            case 'edit':
                return <EditStorehouse title='编辑仓库' refresh={this._refresh} openModeParam={this.state.openModeParam} visible={true} closeMode={this._closeMode} />;
            case 'del':
                return <DeleteModal title='系统信息' visible={true} closeMode={this._closeMode} />;
            case 'start':
                return <StartModal title='系统信息' visible={true} closeMode={this._closeMode} />;
            case 'stop':
                return <StopModal title='系统信息' visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }
    render() {
        const { companyStatus, warehouseName } = this.state;
        const { storehouseList, storehouseType } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.row1}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='仓库名称'
                            placeholder='请输入'
                            value={warehouseName}
                            bindThis={this}
                            bindName='warehouseName'
                        />
                        <XSelect
                            style={{ marginLeft: 20 }}
                            selectStyle={{ width: '200px' }}
                            label='仓库类型'
                            placeholder='请选择'
                            renderData={storehouseType}
                            onChange={this._companyStatusChange}
                            dataIndex='value'
                            keyIndex='code'
                            value={companyStatus.value}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    {/* <div className={styles.row2}>
                        <XOKButton
                            style={{ width: '60px' }}
                            label='新建'
                            onClick={this._newHandle}
                        />
                    </div> */}
                    <AdvertTable renderData={storehouseList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getStoreHouseList: PropTypes.func,
    storehouseList: PropTypes.object,
    getStoreHouseType: PropTypes.func,
    storehouseType: PropTypes.array
};
const mapStateToProps = (state) => ({
    storehouseList: state.storehouselist.storehouseList,
    storehouseType: state.storehouselist.storehouseType,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getStoreHouseList, getStoreHouseType })(Main);