import * as T from './actiontypes';

/**
 * 商品库存对接列表
 */
const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

//商品库存对接列表
export const inventoryConnectList = (state = initData, { type, payload }) => {
    switch (type) {
        case T.SET_INVENTORYCONNECT_LIST:
            return payload.result;
        default:
            return state;
    }
};


//商品对接商品状态
export const inventoryConnectState = (state = [], { type, payload }) => {
    switch (type) {
        case T.SET_INVENTORYCONNECT_STATE:
            return payload.result;
        default:
            return state;
    }
};