import * as T from './actiontypes';
//获取活动商品审核列表
export const getActivityGoods = (params = {}) => ({
    type: T.GET_AUDIT_ACTIVITYGOODS,
    payload: params
});
//获取活动类型
export const getActivityType = () => ({
    type: T.GET_AUDIT_ACTIVITYTYPE,
    payload: {}
});
//获取活动状态
export const getActivityStatus = () => ({
    type: T.GET_AUDIT_ACTIVITYSTATUS,
    payload: {}
});
//获取活动详情
export const getActivityDetail = (activityGoodsId) => ({
    type: T.GET_AUDIT_ACTIVITYDETAIL,
    payload: { activityGoodsId }
});