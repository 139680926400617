import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'antd';
import styles from './TableContent.module.scss';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';
import { isWE, isMT, isGY } from '@/assets/js/authType';

let unitItem = {
    'COUNT': '数量',
    'WEIGHT': '重量',
    'VOLUME': '体积',
    'COUNT_FEE': '数量+金额',
    'WEIGHT_FEE': '重量+金额',
    'VOLUME_FEE': '体积+金额',
    'FEE': '金额'
};

let unitItemOne = {
    'COUNT': '数量（件）',
    'WEIGHT': '重量（克）',
    'VOLUME': '体积（立方厘米）',
    'COUNT_FEE': '数量（件）',
    'WEIGHT_FEE': '重量（克）',
    'VOLUME_FEE': '体积（立方厘米）'
};

let unitItemTwo = {
    'COUNT': '首件（件）',
    'WEIGHT': '首重（克）',
    'VOLUME': '首体积（立方厘米）',
};

let unitItemThree = {
    'COUNT': '续件（件）',
    'WEIGHT': '续重（克）',
    'VOLUME': '续体积（立方厘米）',
};

class TableContent extends Component {

    _baseColumns = () => {
        const { templateItem: { chargeMode = '' } = {} } = this.props;
        return [{
            title: '运送方式',
            dataIndex: 'freightWay',
            key: 'freightWay',
            align: 'center',
            onCell: tooltipStyle,
            render: () => ('快递')
        },
        {
            title: '运送到',
            dataIndex: 'areaNames',
            key: 'areaNames',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: unitItemTwo[chargeMode],
            dataIndex: 'baseNumber',
            key: 'baseNumber',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '运费（元）',
            dataIndex: 'baseFreight',
            key: 'baseFreight',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: unitItemThree[chargeMode],
            dataIndex: 'increaseNumber',
            key: 'increaseNumber',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '运费（元）',
            dataIndex: 'increaseFreight',
            key: 'increaseFreight',
            align: 'center',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },];
    }

    _pinkageColumns = () => {
        const { templateItem: { chargeMode = '' } = {} } = this.props;
        return [
            {
                title: '运送到',
                dataIndex: 'areaNames',
                key: 'areaNames',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '包邮方式',
                dataIndex: 'postMode',
                key: 'postMode',
                align: 'center',
                onCell: tooltipStyle,
                render: (text) => unitItem[text]
            },
            {
                title: unitItemOne[chargeMode],
                dataIndex: 'amount',
                key: 'amount',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '金额（元）',
                dataIndex: 'payment',
                key: 'payment',
                align: 'center',
                onCell: tooltipStyle,
                render: priceFenToYuanCell
            },
        ];
    }

    _title = (type) => {
        const { templateItem, tableHandle, curTabID = '' } = this.props;
        switch (type) {
            case 'template':
                return (
                    <div className={styles.headerTitle}>
                        <p>
                            {curTabID === 'other' && isMT() && <span>{`【${templateItem.warehouseName}】`}</span>}
                            <Button className={styles.left} type='link' onClick={() => tableHandle(templateItem.id, 'info')}>{templateItem.templateName}</Button>
                        </p>
                        <p className={styles.right}>
                            <span className={styles.time}>最后编辑时间：{templateItem.gmtModified}</span>
                            <span className={styles.operation}>
                                {
                                    templateItem.isAppiontNodelivery != 0 &&
                                    <Button type='link' onClick={() => tableHandle(templateItem.id, 'noDelivery', templateItem)} >不配送地区</Button>
                                }
                                {(isMT() || (isGY() && isWE())) && <Button type='link' onClick={() => tableHandle(templateItem.id, 'copy')} >复制</Button>}
                                {
                                    (isMT() || (isGY() && isWE() && curTabID === 'self')) && <Fragment>|
                                        <Button type='link' onClick={() => tableHandle(templateItem.id, 'edit')}>修改</Button>|
                                        <Button type='link' onClick={() => tableHandle(templateItem.id, 'del')}>删除</Button>
                                    </Fragment>
                                }
                            </span>
                        </p>
                    </div >
                );

            case 'pinkage':
                return (
                    <div className={styles.headerTitle}>
                        <p className={styles.left}>{`${templateItem.templateName}（包邮）`}</p>
                    </div>
                );
        }

    }

    _dataSource = (data, type) => {
        switch (type) {
            case 'base':
                return data.templateItems;
            case 'pinkage':
                return data.postConfigs;
        }
    }

    render() {
        const { templateItem } = this.props;
        return (
            <div className={styles.wrapper}>
                <div className={styles.base}>
                    <Table
                        columns={this._baseColumns()}
                        rowKey={(record, index) => index}
                        dataSource={this._dataSource(templateItem, 'base')}
                        pagination={false}
                        title={() => this._title('template')}
                    />
                </div>
                <div className={(templateItem.isCustomPost == '0') ? styles.hide : styles.pinkage}>
                    <Table
                        columns={this._pinkageColumns()}
                        rowKey={(record, index) => index}
                        dataSource={this._dataSource(templateItem, 'pinkage')}
                        pagination={false}
                        title={() => this._title('pinkage')}
                    />
                </div>
            </div>
        );
    }
}

TableContent.propTypes = {
    tableHandle: PropTypes.func,
    templateItem: PropTypes.object,
    curTabID: PropTypes.string
};

export default TableContent;