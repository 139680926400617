import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import OnlinePlayModal from './modal/OnlinePlayModal';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import LiveTable from './components/LiveTable';
import { setSessionStorage } from '@/assets/js/storage';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/commercialcollege/merchantAnchor/model';
import { XInput, XSelect, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import styles from './index.module.scss';

class Main extends Component {

    state = {
        renderMode: '',//渲染弹框
        modeParams: {},//弹框入参

        tel: '',
        nickname: '',
        startDate: undefined,
        endDate: undefined,
    };

    //props校验
    static propTypes = {
        history: PropTypes.object,//路由对象
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getlistMerchantLiveBroadcas: PropTypes.func,//获取列表
        listMerchantLiveBroadcas: PropTypes.object,//列表数据
    }

    //挂载后
    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, tel, nickname, startDate, endDate } = this.state;
        this.searchCache = {
            tel,
            nickname,
            startDate: startDate && startDate.format('YYYY-MM-DD HH:mm:ss'),
            endDate: endDate && endDate.format('YYYY-MM-DD HH:mm:ss'),
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getlistMerchantLiveBroadcas(this.searchCache);

        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            tel: '',
            nickname: '',
            startDate: undefined,
            endDate: undefined,
            pageNum: 1,
            pageSize: 25,
        }, () => KeepAlive.saveResetFunc(this._searchHandle)); // 【第4步：将是否调用重置交给KeepAlive控制】
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => this._searchHandle('useCache'));
    }


    // 表格操作
    _tableAction = (id, type, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'play':
                this.setState({ renderMode: 'play', modeParams: { ...value } });
                break;
            case 'starValue':
                setSessionStorage('youdouIncome', value);
                addOneBreadcrumbPath({
                    title: '直播间魅力值收入',
                    path: `/home/commercialcollege/merchantLive/starLight/${value.id}`,
                });
                history.push({
                    pathname: `/home/commercialcollege/merchantLive/starLight/${value.id}`
                });
                break;
            case 'salesValue':
                setSessionStorage('salesIncome', value);
                addOneBreadcrumbPath({
                    title: '直播间销售情况',
                    path: `/home/commercialcollege/merchantLive/sales/${value.id}`,
                });
                history.push({
                    pathname: `/home/commercialcollege/merchantLive/sales/${value.id}`
                });
                break;
        }
    }
    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { modeParams, renderMode } = this.state;
        switch (renderMode) {
            case 'play':
                if (modeParams.liveState.code == 'LIVE') {
                    return <OnlinePlayModal visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
                } else if (modeParams.liveState.code == 'END') {
                    if (modeParams.playbackUrl) {
                        return <OnlinePlayModal visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
                    } else {
                        message.error('视频播放路径为空');
                        return null;
                    }
                } else return null;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ renderMode: '' });
    }
    //渲染组件
    render() {
        const {
            tel,
            nickname,
            startDate,
            endDate
        } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XInput
                            style={{ width: '250px' }}
                            label='手机号码'
                            placeholder='请输入手机号码'
                            value={tel}
                            bindThis={this}
                            bindName='tel'
                        />
                        <XInput
                            style={{ width: '250px' }}
                            label='主播昵称'
                            placeholder='请输入主播昵称'
                            value={nickname}
                            bindThis={this}
                            bindName='nickname'
                        />
                        <XDatePicker
                            style={{ width: '264px', }}
                            label='直播日期'
                            value={startDate}
                            bindThis={this}
                            bindName='startDate'
                        />
                        <XDatePicker
                            label=''
                            value={endDate}
                            bindThis={this}
                            bindName='endDate'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <LiveTable renderData={this.props.listMerchantLiveBroadcas} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

//映射数据源
const mapStateToProps = (state) => ({
    listMerchantLiveBroadcas: state.merchantAnchor.listMerchantLiveBroadcas,
});

//暴露组件
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
