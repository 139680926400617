/**
 * Saga集合
 */
import authorization from './authorization/model';
import handbook from './handbook/model';

export default [
    ...Object.values(authorization.sagas),
    ...Object.values(handbook.sagas),
];
