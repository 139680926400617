/**
 * 【商品分类对接页】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { message } from 'antd';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import ProductClassifyModal from '../../modal/ProductClassifyModal';
import { getOuterGoodsClassifyList } from '@/reducers/warehousemanage/productClassify/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { isHouNiao } from '@/assets/js/authType';

const orderStateSelect = [{ id: 1, name: '已设置' }, { id: 0, name: '未设置' }];

// 弹框枚举类型
const Mode = {
    PRODUCT_CREATE: 'PRODUCT_CREATE', // 选择商品分类弹框
};

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            kindName: '', // 分类名称
            prodState: '', // 商品状态
            currentMode: '', // 当前弹框
            modeParam: '', // 弹框入参
        };
    }

    // 下载奢批分类
    _downloadHandle = () => {
        http(isHouNiao() ? '/out/supplier/category/download' : '/goods/outer/downloadOutCategory', {}, 'POST')
            .then((result) => {
                if (result.status == 200) {
                    message.success('下载成功。');
                }
            })
            .catch(error => {
                message.error(error.message);
            });
    }

    // 表格操作
    _tableAction = (key, type) => {
        switch (type) {
            case 'setkind':
                this.setState({ currentMode: Mode.PRODUCT_CREATE, modeParam: key });
                break;
        }
    }

    // 搜索
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, kindName, prodState } = this.state;
        this.searchCache = {
            categoryName: kindName,
            isImport: prodState.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getOuterGoodsClassifyList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    _resetHandle = () => {
        this.setState({ kindName: '', prodState: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 渲染弹框
    _renderMode = () => {
        switch (this.state.currentMode) {
            case Mode.PRODUCT_CREATE:
                return <ProductClassifyModal visible={true} closeMode={this._closeMode} modeParam={this.state.modeParam} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (update) => {
        this.setState({ currentMode: '' });
        update ? this.props.getOuterGoodsClassifyList(this.searchCache) : null;
        // switch (type) {
        //     case 'ok':
        //         // 存储当前选中的商品分类至LocalStorage
        //         console.log(productCascades, key);
        //         break;
        // }
    }

    render() {
        const { outerGoodsClassifyList } = this.props;
        const { kindName, prodState } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria}>
                        <XInput
                            style={{ width: '248px' }}
                            inputStyle={{ width: '180px' }}
                            label='分类名称'
                            placeholder='请输入分类名称'
                            value={kindName}
                            bindThis={this}
                            bindName='kindName'
                        />
                        <XSelect
                            style={{ marginLeft: '20px', width: '220px' }}
                            selectStyle={{ width: '180px' }}
                            label='状态'
                            placeholder='请选择状态'
                            renderData={orderStateSelect}
                            dataIndex='name'
                            keyIndex='id'
                            value={prodState.name}
                            showSearch={true}
                            bindThis={this}
                            bindName='prodState'
                        />
                        <XOKButton style={{ marginLeft: '20px' }} label='查询' onClick={this._searchHandle} />
                        <XCancelButton style={{ marginLeft: '10px' }} label='重置' onClick={this._resetHandle} />
                    </div>
                    <div className={styles.export}>
                        <XOKButton label='下载供应商分类' onClick={this._downloadHandle} />
                    </div>
                    <TableContent renderData={outerGoodsClassifyList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getOuterGoodsClassifyList: PropTypes.func,

    outerGoodsClassifyList: PropTypes.object
};

const mapStateToProps = (state) => ({
    outerGoodsClassifyList: state.outerGoodsClassify.outerGoodsClassifyList,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getOuterGoodsClassifyList })(Main);