/**
 * 确认出库弹框
 */
import React, { Fragment } from 'react';
import { Modal, message, Button } from 'antd';
import PropTypes, { number } from 'prop-types';
import styles from './DeliverModal.module.scss';
import { XInput, XSelect, XTextArea } from '@/components/xqxc_ui';
import { getLogisticsSelect } from '@/reducers/delivermanage/readydeliver/actions';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
const { confirm } = Modal;

class DeliverModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
            logistics: '', // 物流公司
            expressNo: '', // 物流单号.
            sellerRemark: '', // 备注
            outgoingList: [{ id: 1, expressNo: undefined, logisticsCode: undefined, logisticsCompany: undefined, sellerRemark: undefined }]
        };
    }

    componentDidMount() {
        this.props.getLogisticsSelect();
        //  获取是否有售后
        const { orderId } = this.props.data.item;
        http('/merchant/order/distributionOutboundOrderList', { orderId }, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result.dataList && response.result.dataList.length > 0) {
                    var { afterSaleId, salesStatus } = response.result.dataList[0];
                    this.situation(afterSaleId, salesStatus);
                } else {
                    message.error('订单状态已变更，请刷新页面后再查看');
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _handleOk = () => {
        const { outgoingList } = this.state;
        const { id } = this.props.data.item;
        for (let i = 0; i < outgoingList.length; i++) {
            const element = outgoingList[i];
            if (!element.expressNo || !element.logisticsCode || !element.logisticsCompany) return message.warn(`请补全第${i + 1}条的物流信息`);
        }
        const confirmaOundReqs = outgoingList.map(i => ({ ...i, id }));
        http('/merchant/order/multiConfirmationRetrieva', { confirmaOundReqs }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('出库成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    //提示该订单是否有售后情况
    situation = (afterSaleId, salesStatus) => {
        const _this = this;
        let content = '';
        if (afterSaleId) {
            if (salesStatus == 'WAITE_MERCHANT') {
                content = '订单申请退款，等待客服处理中';
            } else {
                content = '订单已申请退款成功';
            }
            Modal.warning({
                title: '售后情况',
                content: content,
                okText: '我知道了',
                onOk() {
                    _this.setState({
                        visible: false,
                    }, () => {
                        _this.props.closeMode();
                    });
                }
            });
        }
    }

    _outgoingList = (params = {}) => {
        const outgoingList = this.state.outgoingList.map(i => {
            if (params.id === i.id) return { ...i, ...params };
            return i;
        });
        this.setState({ outgoingList });
    }

    _addOrderInfo = () => {
        const outgoingList = [...this.state.outgoingList];
        outgoingList.push({ id: outgoingList.length + 1, expressNo: undefined, logisticsCode: undefined, logisticsCompany: undefined, sellerRemark: undefined });
        this.setState({ outgoingList });
    }

    _removeOrderInfo = (id) => {
        const outgoingList = [...this.state.outgoingList];
        this.setState({ outgoingList: outgoingList.filter(i => i.id !== id) });
    }

    render() {
        const { visible, confirmLoading, outgoingList } = this.state;
        const { logisticsSelect } = this.props;

        return (
            <Modal
                width={500}
                centered
                bodyStyle={{ paddingRight: '10px' }}
                title='确认出库'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    {outgoingList.map((i, index) => {
                        return <div className={styles.itemBox} key={i.id}>
                            <div style={{ display: 'flex' }}>
                                <XSelect
                                    style={{ width: '390px' }}
                                    selectStyle={{ width: '300px' }}
                                    label='物流公司'
                                    placeholder='请选择物流公司'
                                    renderData={logisticsSelect}
                                    dataIndex='optionName'
                                    keyIndex='optionCode'
                                    value={i.logisticsCompany}
                                    showSearch={true}
                                    onChange={e => this._outgoingList({ id: i.id, logisticsCode: e.optionCode, logisticsCompany: e.optionName })}
                                />
                                {!!index && <Button onClick={() => this._removeOrderInfo(i.id)} style={{ marginLeft: '10px' }}>移除</Button>}
                            </div>
                            <XInput
                                style={{ width: '390px', marginTop: '20px' }}
                                inputStyle={{ width: '300px' }}
                                label='物流单号'
                                placeholder='请输入物流单号'
                                value={i.expressNo}
                                onChange={v => this._outgoingList({ id: i.id, expressNo: v.trim() })}
                            />
                            <XTextArea
                                style={{ width: '390px', marginTop: '20px' }}
                                inputStyle={{ width: '300px', marginLeft: '30px' }}
                                label='备注'
                                placeholder='请输入备注'
                                value={i.sellerRemark}
                                onChange={sellerRemark => this._outgoingList({ id: i.id, sellerRemark })}
                            />
                        </div>;
                    })}
                    <Button type='primary' onClick={this._addOrderInfo} style={{ marginLeft: '250px' }}>添加物流单号</Button>
                </div>
            </Modal>
        );
    }
}

DeliverModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getLogisticsSelect: PropTypes.func, // 获取快递公司下拉列
    logisticsSelect: PropTypes.array, // 快递公司下拉列
};

const mapStateToProps = (state) => ({
    logisticsSelect: state.readydeliver.getLogisticsSelect
});

export default connect(mapStateToProps, { getLogisticsSelect })(DeliverModal);

