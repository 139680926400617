import React, { useEffect, useState } from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

const TableContent = ({
    renderData: {
        dataList,
        pageNum,
        pageSize,
        resultTotal
    },
    paginationChange,
    tableOperate,
    batchKeys = []
}) => {

    const [keys, setKeys] = useState([]);

    useEffect(() => { setKeys(batchKeys); }, [batchKeys]);

    // 表格复选输入
    const rowSelection = {
        selectedRowKeys: keys,
        onChange: (values) => {
            tableOperate('batch', values);
        }
    };
    // 清空表格复选框
    const cleanAllSelected = () => {
        tableOperate('batch', []);
    };

    const columns = [
        {
            title: '品牌LOGO',
            dataIndex: 'outBrandImg',
            key: 'outBrandImg',
            width: '12%',
            align: 'center',
            onCell: tooltipStyle,
            render: ImgCell
        },
        {
            title: '品牌名称',
            dataIndex: 'outBrandName',
            key: 'outBrandName',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '品牌描述',
            dataIndex: 'outBrandDesc',
            key: 'outBrandDesc',
            width: '20%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '品牌ID',
            dataIndex: 'outBrandId',
            key: 'outBrandId',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '下载时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '导入时间',
            dataIndex: 'gmtImport',
            key: 'gmtImport',
            width: '16%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    return (
        <div className={styles.tableContainer}>
            <XTableSelectionCount
                selectedNum={keys.length}
                onClean={cleanAllSelected}
            />
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowSelection={rowSelection}
                rowKey={(record, index) => record.id}
                columns={columns}
                dataSource={dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};
TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
    tableOperate: PropTypes.func,
    batchKeys: PropTypes.array
};
export default TableContent;