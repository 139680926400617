/**
 * 售后管理 - 全部退款
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, Button } from 'antd';
import GenerateStatement from '../../modal/GenerateStatement';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination, XDatePicker } from '@/components/xqxc_ui';
import { takeColumns } from './components/TableData';
import { getDeliverListTableSource, getSupplierSelectSource } from '@/reducers/storehousefinancialmanage/suppliersettlement/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';


// 模拟下拉列数据
const selectDataSource = [
	{ code: 'TO_RECON', value: '待对账' },
	{ code: 'IN_RECON', value: '对账中' },
	{ code: 'DONE', value: '已对账' },
];

const Mode = {
	CREATE: 'CREATE',//受理
};

class DeliverList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			orderId: props.orderId,
			startDate: undefined,
			endDate: undefined,
			settleStatus: {},

			selectDataSource: [],
			currentOpenMode: '', // 当前需要打开的弹窗
			openModeParam: {}, // 当前需要打开的弹窗的入参
		};
	}

	componentDidMount() {
		this.props.getSupplierSelectSource();

		http('/enum/ReconciliationSourceStatusEnum').then((res) => {
			if (res.code === 200) {
				this.setState({ selectDataSource: res.result });
			}
		});
	}
	// 分页
	_paginationChange = (pageSize, pageNum) => {
		this.setState({ pageNum, pageSize }, () => {
			this._searchHandle('useCache');
		});
	}

	// 查询
	_searchHandle = (useCache) => {

		const { orderId, startDate, endDate, settleStatus, pageSize, pageNum } = this.state;
		this.searchCache = {
			orderId,
			startDate: startDate ? startDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
			endDate: endDate ? endDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
			settleStatus: settleStatus.code,
			pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
			pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
		};
		KeepAlive.saveCache({ orderId, startDate, endDate, settleStatus, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
		this.props.getDeliverListTableSource(this.searchCache); // 发起请求
	}

	// 重置触发 
	_ruleresetHandle = () => {
		this.setState({ orderId: undefined, startDate: undefined, endDate: undefined, settleStatus: {} }, () => {
			KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
		});
	}

	// 生成对账单被点击
	_recommendHandle = () => {
		this.setState({
			currentOpenMode: Mode.CREATE,
		});
	}

	// 表格操作功能回调
	_actionClickHandle = (id, type) => {
		const { updateContent, } = this.props;
		switch (type) {
			case Mode.ACCEPT://受理
				this.setState({
					currentOpenMode: Mode.ACCEPT,
				});
				break;
			case Mode.RESULT://处理结果
				this.setState({
					currentOpenMode: Mode.RESULT,
				});
				break;
			case Mode.DETAIL://查看详情
				updateContent('DETAIL', id);
				break;
		}
	}


	// 打开弹窗（创建/编辑）
	_renderMode = () => {
		switch (this.state.currentOpenMode) {
			case Mode.CREATE:
				return <GenerateStatement title='生成对账单' supplierList={this.props.supplierSelectSource} visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
			default:
				return null;
		}
	}

	// 关闭弹框
	_closeMode = (status) => {
		if (status) {
			this._searchHandle('useCache');
		}
		this.setState({ currentOpenMode: '' });
	}

	generateTimer = null

	render() {
		const { orderId, startDate, endDate, settleStatus } = this.state;
		const { pageNum, pageSize, resultTotal, dataList } = this.props.deliverListTableSource;

		let generating = !!dataList.filter(v => (v.createStatus || {}).code === '1').length;
		if (generating) {
			if (this.generateTimer === null) {
				this.generateTimer = setInterval(() => this._searchHandle('useCache'), 3000);
			}
		} else {
			this.generateTimer && clearInterval(this.generateTimer);
			this.generateTimer = null;
		}

		return (
			<KeepAlive id='deliverlist' render={(state) => {
				if (!this.state.__aliveInit) {
					this.setState({ ...state, __aliveInit: true }, () => {
						this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
					});
				}
			}}>
				<div className={styles.flexBoxContainer}>
					<div className={styles.pro_commont}>
						<XInput
							style={{ width: '230px' }}
							inputStyle={{ width: '170px' }}
							label='订单编号'
							placeholder='请输入订单编号/流水号'
							value={orderId}
							bindThis={this}
							bindName='orderId'
							mode='number'
							maxLength={16}
							showLength={false}
						/>
						<XDatePicker
							style={{ marginLeft: '20px' }}
							label='订单发货时间'
							value={startDate}
							bindThis={this}
							bindName='startDate'
						/>
						<XDatePicker
							style={{ marginLeft: '0px' }}
							onChange={this._orderEndTimeChangeHandle}
							value={endDate}
							bindThis={this}
							bindName='endDate'
						/>
						<XSelect
							style={{ marginLeft: '20px', width: '216px' }}
							selectStyle={{ width: '150px' }}
							label='结算状态'
							placeholder='请选择结算状态'
							renderData={selectDataSource}
							onChange={this._fullChangeHandle}
							dataIndex='value'
							keyIndex='code'
							value={settleStatus.value}
							showSearch={true}
							bindThis={this}
							bindName='settleStatus'
						/>
						<XOKButton
							style={{ marginLeft: '20px', width: '60px' }}
							label='查询'
							onClick={() => this._searchHandle()}
						/>
						<XCancelButton
							style={{ marginLeft: '20px', width: '60px' }}
							label='重置'
							onClick={this._ruleresetHandle}
						/>
					</div>
					<div>
						{/* <Button style={{ marginRight: 15 }} type="primary">导出Excel</Button> */}
						<Button type="primary" onClick={this._recommendHandle}>生成对账单</Button>
					</div>
					<div className={`${styles.table_body} ${styles.tableContainer}`}>
						<Table
							rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
							// rowSelection={this._rowSelection}
							rowKey={(record, index) => index}
							columns={takeColumns(this._actionClickHandle)}
							dataSource={dataList}
							pagination={false}
							scroll={{ x: '140%', y: true }}
						/>
						<XPagination
							resultTotal={resultTotal}
							pageSize={pageSize}
							pageNum={pageNum}
							onChange={this._paginationChange}
						/>
					</div>
					<div>{this._renderMode()}</div>
				</div>
			</KeepAlive>
		);
	}
}

DeliverList.propTypes = {
	updateContent: PropTypes.func, // 路由跳转
	history: PropTypes.object, // 路由history
	orderId: PropTypes.string,

	deliverListTableSource: PropTypes.object,
	getDeliverListTableSource: PropTypes.func,

	supplierSelectSource: PropTypes.array,
	getSupplierSelectSource: PropTypes.func
};

const mapStateToProps = (state) => ({
	deliverListTableSource: state.suppliersettlement.deliverListTableSource,
	supplierSelectSource: state.suppliersettlement.supplierSelectSource
});


export default connect(mapStateToProps, { getDeliverListTableSource, getSupplierSelectSource })(DeliverList);