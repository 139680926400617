import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, Button } from 'antd';
import ResultModal from '../../modal/ResultModal';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination, XDatePicker } from '@/components/xqxc_ui';
import { takeColumns } from './components/TableData';
import { getContractManageTableSource, getContractStates } from '@/reducers/storehousefinancialmanage/suppliersettlement/actions';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

const Mode = {
	ACCEPT: 'ACCEPT',//受理
	RESULT: 'RESULT',//处理结果
	DETAIL: 'DETAIL',//查看详情
	EDIT: 'EDIT',
	STOP: 'STOP'
};

class ContractManage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contractName: '',
			contractNo: '', // 下单开始时间不可选
			signDateStart: undefined, // 下单结束时间
			signDateEnd: undefined, // 下单结束时间不可选
			contractState: {},

			selectDataSource: [],//模拟下拉列数据
			currentOpenMode: '', // 当前需要打开的弹窗
			openModeParam: {}, // 当前需要打开的弹窗的入参
		};
	}

	componentDidMount() {
		this.props.getContractStates();
	}

	// 分页
	_paginationChange = (pageSize, pageNum) => {
		this.setState({
			pageNum,
			pageSize,
		}, () => {
			this._searchHandle('useCache');
		});
	}

	// 查询
	_searchHandle = (useCache) => {

		const { contractName, contractState, contractNo, signDateStart, signDateEnd, pageNum, pageSize } = this.state;

		this.searchCache = {
			contractName,
			contractNo,
			signDateStart: signDateStart ? signDateStart.format('YYYY-MM-DD HH:mm:ss') : undefined,
			signDateEnd: signDateEnd ? signDateEnd.format('YYYY-MM-DD HH:mm:ss') : undefined,
			contractState: contractState.code,

			pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
			pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
		};
		KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
		this.props.getContractManageTableSource(this.searchCache); // 发起请求
	}

	// 重置触发 
	_ruleresetHandle = () => {
		this.setState({ contractName: '', contractNo: '', signDateStart: undefined, signDateEnd: undefined, contractState: '', pageNum: 1, pageSize: 10 }, () => {
			KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
		});
	}

	// 表格复选框
	_rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			this.setState({ openModeParam: selectedRows });
		}
	};

	// 新建按钮被点击
	_recommendHandle = () => {
		const { history, addOneBreadcrumbPath } = this.props;
		addOneBreadcrumbPath({
			title: '创建合同',
			path: '/home/storehousefinancialmanage/suppliersettlement/createcontract',
		});
		history.push({
			pathname: '/home/storehousefinancialmanage/suppliersettlement/createcontract',
			state: { mode: 'CREATE' }
		});
	}

	// 表格操作功能回调
	_actionClickHandle = (id, type, record) => {
		const { history, addOneBreadcrumbPath } = this.props;
		switch (type) {
			case Mode.ACCEPT://受理
				this.setState({
					currentOpenMode: Mode.ACCEPT,
				});
				break;
			case Mode.RESULT://处理结果
				this.setState({
					currentOpenMode: Mode.RESULT,
				});
				break;
			case Mode.EDIT:
				addOneBreadcrumbPath({
					title: '合同详情',
					path: '/home/storehousefinancialmanage/suppliersettlement/createcontract/' + record.id,
				});
				history.push({
					pathname: '/home/storehousefinancialmanage/suppliersettlement/createcontract/' + record.id,
					state: { mode: 'EDIT', ...record }
				});
				break;
			case Mode.DETAIL://查看详情
				addOneBreadcrumbPath({
					title: '合同详情',
					path: '/home/storehousefinancialmanage/suppliersettlement/createcontract/' + record.id,
				});
				history.push({
					pathname: '/home/storehousefinancialmanage/suppliersettlement/createcontract/' + record.id,
					state: { mode: 'DETAIL', ...record }
				});
				break;
			case Mode.STOP:
				http('/merchant/contract/stop/' + record.id, {}, 'GET').then((res) => {
					console.log(res);
				});
				break;
		}

	}


	// 打开弹窗（创建/编辑）
	_renderMode = () => {
		switch (this.state.currentOpenMode) {
			// case Mode.ACCEPT:
			//   return <AcceptModal title='确认收货' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
			// case Mode.RESULT:
			//   return <ResultModal title='处理结果' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
			default:
				return null;
		}
	}

	// 关闭弹框
	_closeMode = (status) => {
		if (status)
			this.setState({ currentOpenMode: '' });
	}

	render() {
		const { contractName, contractNo, contractState, signDateStart, signDateEnd } = this.state;
		const { pageNum, pageSize, resultTotal, dataList } = this.props.contractManageTableSource;

		return (
			<KeepAlive id='contractmanage' render={(state) => {
				if (!this.state.__aliveInit) {
					this.setState({ ...state, __aliveInit: true }, () => {
						this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
					});
				}
			}}>
				<div className={styles.flexBoxContainer}>
					<div className={styles.pro_commont}>
						<XInput
							style={{ width: '250px' }}
							inputStyle={{ width: '170px' }}
							label='合同名称'
							placeholder='请输入合同名称'
							value={contractName}
							bindThis={this}
							bindName='contractName'
						/>
						<XInput
							style={{ width: '250px' }}
							inputStyle={{ width: '170px' }}
							label='合同号'
							placeholder='请输入合同号'
							value={contractNo}
							bindThis={this}
							bindName='contractNo'
						/>
						<XDatePicker
							style={{ marginRight: '15px' }}
							label='签订日期'
							value={signDateStart}
							bindThis={this}
							bindName='signDateStart'
						/>
						<span>—</span>
						<XDatePicker
							style={{ marginLeft: '5px' }}
							value={signDateEnd}
							bindThis={this}
							bindName='signDateEnd'
						/>
						<XSelect
							style={{ marginLeft: '30px', width: '216px' }}
							selectStyle={{ width: '150px' }}
							label='合同状态'
							placeholder='请选择合同状态'
							renderData={this.props.contractStates}
							dataIndex='value'
							keyIndex='code'
							value={contractState.value}
							showSearch={true}
							bindThis={this}
							bindName='contractState'
						/>
						<XOKButton
							style={{ marginLeft: '30px', width: '60px' }}
							label='查询'
							onClick={this._searchHandle}
						/>
						<XCancelButton
							style={{ marginLeft: '30px', width: '60px' }}
							label='重置'
							onClick={this._ruleresetHandle}
						/>
					</div>
					<div>
						{/* <Button style={{ marginRight: 15 }} type="primary">导出Excel</Button> */}
						<Button type="primary" onClick={this._recommendHandle}>新建合同</Button>
					</div>
					<div className={`${styles.table_body} ${styles.tableContainer}`}>
						<Table
							// bordered
							rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
							rowSelection={this._rowSelection}
							rowKey={(record, index) => index}
							columns={takeColumns(this._actionClickHandle)}
							dataSource={dataList}
							pagination={false}
							scroll={{ y: true }}
						/>
						<XPagination
							resultTotal={resultTotal}
							pageSize={pageSize}
							pageNum={pageNum}
							onChange={this._paginationChange}
						/>
					</div>
					<div>{this._renderMode()}</div>
				</div>
			</KeepAlive>
		);
	}
}

ContractManage.propTypes = {
	updateContent: PropTypes.func, // 路由跳转
	history: PropTypes.object, // 路由history
	addOneBreadcrumbPath: PropTypes.func,
	keepSecondNavBreadcrumb: PropTypes.func,

	contractManageTableSource: PropTypes.object,
	getContractManageTableSource: PropTypes.func,

	contractStates: PropTypes.array,
	getContractStates: PropTypes.func,

};

const mapStateToProps = (state) => ({
	contractManageTableSource: state.suppliersettlement.contractManageTableSource,
	contractStates: state.suppliersettlement.contractStates,
});


export default connect(mapStateToProps, { getContractManageTableSource, addOneBreadcrumbPath, keepSecondNavBreadcrumb, getContractStates })(ContractManage);