import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Radio, Card, Button } from 'antd';
import { getSessionStorage, setSessionStorage, removeSessionStorage } from '@/assets/js/storage';

const cardList = [
    {
        shopCover: require('@/assets/image/activity/shopReduce.png'),
        shopTitle: '店铺满减',
        shopContent: '用户下单满足条件后可享受减免优惠',
        shopType: 'shopReduce',
    },
    {
        shopCover: require('@/assets/image/activity/freight.png'),
        shopTitle: '立减运费',
        shopContent: '用户下单后可享受配送费减免优惠',
        shopType: 'freight',
    }
];

const { Meta } = Card;

const ShopActivity = ({
    updateContent,
    history,
}) => {

    const [activityType, setActivityType] = useState('all');//活动类型
    const [list, setList] = useState(cardList);//活动数据


    useEffect(() => {
        //初始获取缓存activityTab标识
        activityHandleChange(getSessionStorage('activityTab') ? getSessionStorage('activityTab') : 'all');
    }, []);

    //活动类型
    const activityHandleChange = (value) => {
        setActivityType(value);
        setSessionStorage('activityTab', value);
        switch (value) {
            case 'shopReduce':
            case 'freight':
                {
                    const arr = cardList.filter((v) => v.shopType == value);
                    setList(arr);
                }
                break;
            default:
                setList(cardList);
        }
    };

    //创建
    const createActivity = (type) => {
        switch (type) {
            case 'shopReduce':
                updateContent('shopReduce');
                break;
            case 'freight':
                updateContent('freight');
                break;
            default:
                null;
        }
    };

    return (
        <div className={styles.shop}>
            <div className={`${styles.layout} ${styles.alignCenter} ${styles.typeTop}`}>
                <label>活动类型：</label>
                <div>
                    <Radio.Group value={activityType} buttonStyle="solid" onChange={(e) => { activityHandleChange(e.target.value); }}>
                        <Radio.Button value="all">全部</Radio.Button>
                        <Radio.Button value="shopReduce">抬高客单</Radio.Button>
                        <Radio.Button value="freight">提高复购</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
            <div className={styles.shopCard}>
                <div className={`${styles.layout} ${styles.shopCardCurt}`}>
                    {list.map((item, index) => {
                        return (<Card
                            key={index}
                            hoverable
                            className={styles.curt}
                            cover={<img alt='' src={item.shopCover} />}
                            actions={[
                                <Button type='primary' key={index} onClick={() => { createActivity(item.shopType); }}>创建</Button>
                            ]}
                        >
                            <Meta title={item.shopTitle} description={item.shopContent} />
                        </Card>);
                    })}
                </div>
            </div>
        </div>
    );
};


ShopActivity.propTypes = {
    history: PropTypes.object, // 路由history
    updateContent: PropTypes.func, // 跳转其它路由页面
};


const mapStateToProps = (state) => ({

});
export default connect(mapStateToProps, {})(memo(ShopActivity));
