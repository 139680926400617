/**
 * 商户人气值支取申请
 */
import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { XInput, XInputNum, XTextArea } from '@/components/xqxc_ui';
import model from '@/reducers/beanshopmanage/beanshop/model';
import { connect } from 'react-redux';

const Create = ({ closeMode, getBeanDrawApplyRatio, beanDrawApplyRatio, refshStarvalueSummary, refshStarvalueInOutSummary, refshProfitInOutSummary }) => {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [starValueNum, setStarValueNum] = useState(0);
    const [remark, setRemark] = useState('');

    useEffect(() => {
        getBeanDrawApplyRatio({ starValue: starValueNum });
    }, [starValueNum]);

    const _handleOk = () => {
        console.log(starValueNum);
        if (Number(starValueNum) < 1 && beanDrawApplyRatio.availableStarValue < 1) {
            message.warn('支取信用值不足!');
            return;
        } else if (Number(starValueNum) < 1 && beanDrawApplyRatio.availableStarValue > 0) {
            message.warn('请输入支取的信用值数量!');
            return;
        }
        setConfirmLoading(true);
        http('/xiudou/withdrawXiudou', { remark, starValueNum }, 'POST')
            .then(() => {
                message.success('商户申请信用值支取成功');
                setConfirmLoading(false);
                refshStarvalueSummary();
                refshStarvalueInOutSummary();
                refshProfitInOutSummary();
                closeMode(true, beanDrawApplyRatio.payStarValue);
            }).catch((error) => {
                message.error(error.message);
                setConfirmLoading(false);
            });
    };

    const _handleCancel = () => {
        setConfirmLoading(false);
        closeMode('');
    };

    return (
        <Modal
            width={500}
            centered
            title='商户申请信用值支取'
            visible={true}
            confirmLoading={confirmLoading}
            onOk={_handleOk}
            onCancel={_handleCancel}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认支取'
        >
            <div className={styles.container}>
                <div className={StyleSheet.top}>信用值支取服务费{beanDrawApplyRatio.serviceRate ? beanDrawApplyRatio.serviceRate / 100 : 0}%，按本次支取的信用值数额外扣除服务费需要的信用值</div>
                <XInput
                    label='商户本次可支取信用值'
                    style={{ marginTop: '20px' }}
                    inputStyle={{ width: '300px' }}
                    disabled={true}
                    value={beanDrawApplyRatio.availableStarValue} />
                <XInputNum
                    isRequired={true}
                    label='本次支取信用值'
                    style={{ marginTop: '20px' }}
                    inputStyle={{ marginLeft: '35px', width: '300px' }}
                    // bindThis={setStarValueNum}
                    // bindName='starValueNum'
                    min={0}
                    precision={0}
                    onChange={(e) => {
                        if (typeof e === 'number') {
                            if (e < beanDrawApplyRatio.availableStarValue || e == beanDrawApplyRatio.availableStarValue) {
                                setStarValueNum(e);
                            } else if (e > beanDrawApplyRatio.availableStarValue) {
                                setStarValueNum(beanDrawApplyRatio.availableStarValue);
                            }
                        } else {
                            setStarValueNum(0);
                        }
                    }}
                    value={starValueNum} />
                <XInput
                    label='服务费'
                    style={{ marginTop: '20px' }}
                    disabled={true}
                    inputStyle={{ marginLeft: '80px', width: '300px' }}
                    value={beanDrawApplyRatio.serviceFee} suffix='信用值' />
                <XInput
                    label='支付信用值'
                    style={{ marginTop: '20px' }}
                    disabled={true}
                    inputStyle={{ marginLeft: '66px', width: '300px' }}
                    value={beanDrawApplyRatio.payStarValue} />
                <XTextArea
                    label='申请备注'
                    style={{ marginTop: '20px' }}
                    placeholder='请输入申请备注'
                    onChange={(e) => {
                        console.log(e);
                        if (e.length > 255) {
                            message.warn('备注长度不超过255个汉字');
                        } else {
                            setRemark(e);
                        }
                    }}
                    inputStyle={{ marginLeft: '66px', width: '300px' }}
                    value={remark} />
            </div>
        </Modal>
    );
};

Create.propTypes = {
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    getBeanDrawApplyRatio: PropTypes.func,
    beanDrawApplyRatio: PropTypes.object,
    refshStarvalueSummary: PropTypes.func,
    refshStarvalueInOutSummary: PropTypes.func,
    refshProfitInOutSummary: PropTypes.func,
};

const mapStateToProps = (state) => {
    return ({
        beanDrawApplyRatio: state.beanshop.beanDrawApplyRatio,
    });
};
export default connect(mapStateToProps, { ...model.actions })(Create);