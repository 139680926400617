/**
 * 评论管理  Saga集合
 */
import { getCommentList, getCommentDetail, getProductClassify } from './productcomment/saga';
import { getHotelCommentList, getHotelCommentDetail, getHotelClassify } from './hotelcomment/saga';

export default [
    getCommentList,//商品评论列表
    getCommentDetail,// 商品评论详情
    getProductClassify, // 商品分类
    getHotelCommentList,
    getHotelCommentDetail,
    getHotelClassify
];