/**
 * 音乐分类的创建、编辑弹框
 */
import React from 'react';
import { Modal, Radio, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './TypeModal.module.scss';
import { XInput } from '@/components/xqxc_ui';
// import CustomUpload, { generateFileList } from '@/components/NewUpload';
import http from '@/assets/api/http';
import { formValids } from '@/assets/js/utils';

const RadioGroup = Radio.Group;
class TypeModal extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        //typeInput: this.props.data.className,
        radioValue: this.props.data.state,
        //imgs: []
    }

    constructor(props) {
        super(props);
        const { type } = props.data || {};
        //this.state.imgs = generateFileList(props.data.img);
        this.state.typeInput = props.data[type + 'CategoryName'];
    }

    _handleOk = () => {

        try {
            //const { imgs } = this.state;
            const { type } = this.props.data || {};
            const className = type + 'CategoryName',
                id = type + 'CategoryId';

            if (this.state.typeInput === '本地上传') {
                message.warning('本地上传是系统分类，请用其他名字');
                return;
            }

            let fields = {
                [id]: (this.props.data || {})[id],
                state: this.state.radioValue,
                [className]: this.state.typeInput,
                //img: imgs.length ? (imgs[0].response ? imgs[0].response.key : imgs[0].url) : ''
            };
            if (formValids(fields, {
                [className]: '请输入分类名称',
                //img: '请上传分类图片'
            })) {
                this.setState({ visible: false });
                http(`/custom/${type}Category/save`, {
                    ...fields
                }, 'POST').catch((res) => message.error('保存数据异常')).finally(() => this.props.closeMode(true));
            }
        } catch (e) {
            message.error('数据保存异常');
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _radioValueChangeHandle = (e) => {
        this.setState({ radioValue: e.target.value });
    }

    render() {
        const { visible, confirmLoading, title, radioValue, typeInput } = this.state;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <XInput
                        style={{ width: '408px' }}
                        inputStyle={{ width: '340px' }}
                        label='分类名称'
                        placeholder='请输入分类名称'
                        onChange={typeInput => this.setState({ typeInput })}
                        value={typeInput}
                    />

                    <div className={styles.radio}>
                        <div className={styles.label}>状态</div>
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._radioValueChangeHandle} value={radioValue}>
                            <Radio value='1'>启用</Radio>
                            <Radio value='0'>禁用</Radio>
                        </RadioGroup>
                    </div>

                    {/* <div className={styles.upload}>
                        <div className={styles.label}>分类图标</div>
                        <div className={styles.icon}>
                            <CustomUpload
                                accept='.png, .jpg, .jpeg'
                                fileList={imgs}
                                listType="picture-card"
                                wallMode="single"
                                qiniuUploadProps={{
                                    uploadType: 'image'
                                }}
                                onChange={(info) => {
                                    const newImgs = info.fileList.length > 1 ? [info.fileList.pop()] : info.fileList;
                                    this.setState({ imgs: newImgs });
                                }}
                            >
                                <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                            </CustomUpload>
                        </div>
                    </div> */}
                </div>
            </Modal>
        );
    }
}

TypeModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    form: PropTypes.object
};

export default TypeModal;