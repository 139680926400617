export const GET_ADVOCACYLIST_DATA = 'ADVOCACY/GET_ADVOCACYLIST_DATA'; // 平台活动列表
export const SET_ADVOCACYLIST_DATA = 'ADVOCACY/SET_ADVOCACYLIST_DATA'; // 平台活动列表

export const GET_ADVOCACYDETAILS_DATA = 'ADVOCACY/GET_ADVOCACYDETAILS_DATA'; // 平台活动参与编辑、详情获取数据
export const SET_ADVOCACYDETAILS_DATA = 'ADVOCACY/SET_ADVOCACYDETAILS_DATA'; //平台活动参与编辑、详情获取数据

export const GET_DROPDIWN_DATA = 'ADVOCACY/GET_DROPDIWN_DATA'; // 平台活动下拉框
export const SET_DROPDIWN_DATA = 'ADVOCACY/SET_DROPDIWN_DATA'; //平台活动下拉框

export const SET_RAISETABALE_DATA = 'ADVOCACY/SET_RAISETABALE_DATA'; //平台活动新建选择商品表格数据
export const GET_RAISETABALE_DATA = 'ADVOCACY/GET_RAISETABALE_DATA'; //平台活动新建选择商品表格数据

export const GET_ADVOCACYPARTICIDETAILS_DATA = 'ADVOCACY/GET_ADVOCACYPARTICIDETAILS_DATA'; // 平台活动详情获取数据
export const SET_ADVOCACYPARTICIDETAILS_DATA = 'ADVOCACY/SET_ADVOCACYPARTICIDETAILS_DATA'; //平台活动详情获取数据
