/**
 * 发货
 */
import React from 'react';
import { Modal, Input, Form, message, Select } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './DeliveryModal.module.scss';
import http from '@/assets/api/http';
import { getExpressCompanyList } from '@/reducers/ordermanage/shopOrder/actions';

const { Option } = Select;
const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};
class DeliveryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
        };
    }
    componentDidMount() {
        // 物流公司
        this.props.getExpressCompanyList({expressState: 1, pageSize: 10000 });
    }

    // 提交 
    _handleOk = () => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                console.log('提交', fieldsValue);
                const { recordInfo, expressCompanyList: { dataList = []} } = this.props;
                const logisticsCompany = dataList.filter(i => i.expressCode === fieldsValue.logisticsCode);
                const param  = {
                    ...recordInfo,
                    ...fieldsValue,
                    expressNo: fieldsValue.expressNo.trim(),
                    logisticsCompany: logisticsCompany[0].expressName,
                };
                http('/merchant/order/createStarValueOrderShipment', param, 'POST')
                    .then(() => {
                        message.success('发货成功。');
                        this.setState({
                            visible: false,
                        });
                        this.props.closeMode(true);
                        this.props.refresh();
                    }).catch((reject) => {
                        message.error(reject.message);
                    });
            }
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { expressCompanyList: { dataList = []} } = this.props;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} className="create-form">
                        <Form.Item
                            colon={false}
                            htmlFor='logisticsCode'
                            label={<span>物流公司</span>}
                        >
                            {getFieldDecorator('logisticsCode', {
                                rules: [{ required: true, message: '请输入' }],
                            })(<Select style={{ width: 220 }} placeholder='请选择'>
                                {dataList.map((i) => {
                                   return <Option value={i.expressCode} key={i.id}>{i.expressName}</Option>;
                                })}
                               
                            </Select>)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='expressNo'
                            label={<span>物流单号</span>}
                        >
                            {getFieldDecorator('expressNo', {
                                rules: [{ required: true, message: '请输入' }],
                            })(<Input placeholder='请输入' style={{ width: 220 }} />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='sellerRemark'
                            label={<span>备注</span>}
                        >
                            {getFieldDecorator('sellerRemark', {
                                // rules: [{ required: true, message: '请输入' }],
                            })(<TextArea rows={4} style={{ width: 220 }} />)}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );

    }
}

DeliveryModal.propTypes = {
    data: PropTypes.number, // 弹框入参
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
    refresh: PropTypes.func,
    getExpressCompanyList: PropTypes.func,
    expressCompanyList: PropTypes.object,
    recordInfo: PropTypes.object,
};
const mapStateToProps = (state) => ({
    shopOrderList: state.ShopOrder.shopOrderList,
    expressCompanyList: state.ShopOrder.expressCompanyList,
});
const ForgotProducDetails = Form.create({ name: 'create-form' })(DeliveryModal);// 校验

export default connect(mapStateToProps, {getExpressCompanyList})(ForgotProducDetails);
