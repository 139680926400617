import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { regFenToYuan } from '@/util/money';
import { XTabNav } from '@/components/xqxc_ui';
import { keepThirdNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/beanshopmanage/beanshop/model';
import Income from './income';//收支明细
import Settlement from './settlement';//支取记录

class Main extends React.Component {

    state = {
        tab: [
            { id: 1, label: '收益明细' },
            { id: 2, label: '支取记录' },
        ],
        curTabID: 1,  //
    }

    componentDidMount() {
        this.props.keepThirdNavBreadcrumb(); // 面包屑导航保留为三级
         this.props.getProfitInOutSummary();
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        switch (this.state.curTabID) {
            case 1:
                return <Income history={this.props.history} curTabID={this.state.curTabID} />;
            case 2:
                return <Settlement history={this.props.history} curTabID={this.state.curTabID} />;
            default:
                return <Income history={this.props.history} curTabID={this.state.curTabID} />;
        }
    }

    render() {
        const { ProfitInOutSummary } = this.props;
        return (
            <div className={styles.flexBoxContainer}>
             <div className={styles.titles}>
             <span className={styles.titleStyle}>总收入</span>
             <span className={styles.titleStyle}>总支出</span>
              <span className={styles.titleStyle}>支取中</span>
            </div>
            <div>
             <span className={styles.titleStyles} style={{ color: '#03A613', verticalAlign: 'top', fontSize: 24, fontWeight: 'bold' }}>
            {regFenToYuan(ProfitInOutSummary.totalIncome)}
             </span>
             <span className={styles.titleStyles} style={{ color: '#EC808D', verticalAlign: 'top', fontSize: 24, fontWeight: 'bold' }}>
               {regFenToYuan(ProfitInOutSummary.totalExpend)}
             </span>
              <span className={styles.titleStyles} style={{ color: '#252525', verticalAlign: 'top', fontSize: 24, fontWeight: 'bold' }}>
                 {regFenToYuan(ProfitInOutSummary.totalWithdrawing)} 
              </span>
            </div>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={styles.line} style={{ marginBottom: 10 }}></div>
                <div className={styles.flexBoxContainer}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 跳转其它路由页面
    keepThirdNavBreadcrumb: PropTypes.func,
    getProfitInOutSummary: PropTypes.func,
    ProfitInOutSummary: PropTypes.object,
};

const mapStateToProps = (state) => {
    return ({
        ProfitInOutSummary: state.beanshop.ProfitInOutSummary,
    });
};
export default connect(mapStateToProps, { keepThirdNavBreadcrumb, ...model.actions })(Main);