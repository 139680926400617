/**
 * 【仓库商品列表】
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, XExpandOrContract, showConfirm, XTextArea } from '@/components/xqxc_ui';
import ProductTable from './components/ProductTable';
import ProductClassifyModal from '../../modal/ProductClassifyModal';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { GoodsStateEnum, GoodsTypeEnum, GoodsTags, GoodsCategory, GDCGoodsState } from '@/vcomps';
import { getProdList } from '@/reducers/warehousemanage/productlist/actions';
import { setLocalStorage, setSessionStorage } from '@/assets/js/storage';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum } from '@/assets/config';
import Fail from '../../modal/Fail';
import { getIsGDCPromise, isFenLai, isHouNiao, isGY } from '@/assets/js/authType';

// 弹框枚举类型
const Mode = {
    PRODUCT_CREATE: 'PRODUCT_CREATE', // 选择商品分类弹框
    FAIL_TABLE: 'FAIL_TABLE'
};

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isGDC: false,
            modeParams: null,//弹框参数
            expandBoolean: false, // 是否展开
            goodsName: '', // 商品名称
            categoryId: '', // 商品联级分类
            goodsNos: '', // 商品货号
            tags: [], // 商品标签
            goodsStateEnum: {}, // 商品状态
            virtualFlag: {},
            goodsTypeEnum: {}, // 商品业务类型
            currentMode: '', // 当前弹框
            goodsIds: [],//批量商品ID
            hasFreight: true,//是否有运费模板
            barcode: ''
        };
        this.searchCache = { pageNum: 1, pageSize: 100 }; // 查询缓存
        this.goodsCategory = React.createRef(); // 商品分类组件ref
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留二级
        http('/goods/freightTemplate/isExsitFreightTemplate', {}, 'POST')
            .then(res => {
                this.setState({ hasFreight: res.result });
            });
        getIsGDCPromise().then((isGDC) => {
            this.setState({ isGDC });
        });
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        // this.searchCache = { ...this.searchCache, pageSize, pageNum };
        // this.props.getProdList(this.searchCache);
        // KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【！！！！！！查询缓存第5步：缓存分页状态】
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { goodsName, goodsStateEnum, virtualFlag, goodsNos, goodsTypeEnum, tags, categoryId, pageNum, pageSize, barcode } = this.state;
        const tagIds = tags.map((item) => {
            return item.id;
        });
        this.searchCache = {
            goodsName,
            goodsNos: goodsNos.replace(/，/g, ','),
            tagIds,
            categoryId,
            goodsState: goodsStateEnum.code,
            goodsType: goodsTypeEnum.code,
            virtualFlag: virtualFlag.code,
            batchData: [], barcode,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【！！！！！！查询缓存第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : 100, // 【！！！！！！查询缓存第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getProdList(this.searchCache); // 发起请求

        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【！！！！！！查询缓存第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.goodsCategory.current.clean();
        this.setState({ goodsName: '', goodsStateEnum: {}, virtualFlag: {}, goodsNos: '', goodsTypeEnum: {}, tags: [], categoryId: '', barcode: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【！！！！！！查询缓存第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 更多搜索条件的展开与收缩
    _expandChangeHandle = (expandBoolean) => {
        this.setState({ expandBoolean });
    }

    // 新建
    _createHandle = () => {
        if (isGY() && !this.state.hasFreight) {
            showConfirm('提示', '你还没有运费模板，请先创建运费模板', () => {
                this.props.history.push({
                    pathname: '/home/productmanage/freightTemplate/create'
                });
            }, () => { }, '去创建');
            return;
        }
        this.setState({ currentMode: Mode.PRODUCT_CREATE });
    }

    // 导出
    _exportHandle = () => { }

    // 渲染弹框
    _renderMode = () => {
        const { currentMode, modeParams } = this.state;
        switch (currentMode) {
            case Mode.PRODUCT_CREATE:
                return <ProductClassifyModal type='ONLINE' visible={true} closeMode={this._closeMode} />;
            case Mode.FAIL_TABLE:
                return <Fail visible={true} modeParams={modeParams} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (type, productCascades, typeCode) => {
        const { history, addOneBreadcrumbPath } = this.props;
        this.setState({ currentMode: '' });
        switch (type) {
            case 'ok':
                // 存储当前选中的商品分类至LocalStorage
                setLocalStorage('productCascades', productCascades);
                addOneBreadcrumbPath({
                    title: '新建商品',
                    path: '/home/warehousemanage/wplist/prodcreate/' + typeCode
                });
                // 跳转至新建商品的页面 
                history.push({
                    pathname: '/home/warehousemanage/wplist/prodcreate/' + typeCode
                });
                break;
        }
    }

    _sendRequest = (url, data, winTxt) => {
        http(url, data, 'POST')
            .then(() => {
                this.props.getProdList(this.searchCache);
                message.success(winTxt);
            }).catch((error) => {
                message.error(error.message);
            });
    }

    // 表格操作
    _tableAction = (key, type, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                if (value.goodsTypeCode === 'FAST_GOODS') {
                    addOneBreadcrumbPath({
                        title: '商品详情',
                        path: '/home/warehousemanage/wplist/fastdetail/'
                    });
                    history.push({
                        pathname: '/home/warehousemanage/wplist/fastdetail/' + key
                    });
                } else {
                    addOneBreadcrumbPath({
                        title: '商品详情',
                        path: '/home/warehousemanage/wplist/proddetail/'
                    });
                    history.push({
                        pathname: '/home/warehousemanage/wplist/proddetail/' + key
                    });
                }
                break;
            case 'edit':
                addOneBreadcrumbPath({
                    title: '编辑商品',
                    path: '/home/warehousemanage/wplist/prodedit/'
                });
                history.push({
                    pathname: '/home/warehousemanage/wplist/prodedit/' + key
                });
                break;
            case 'delect':
                showConfirm('您是否要删除该商品', '', () => {
                    http('/goods/goods/delGoods', { goodsId: [key] }, 'POST').then((response) => {
                        if (response.status == 200) {
                            message.success('删除成功');
                            this.props.getProdList(this.searchCache);
                        }
                    }).catch((e) => {
                        message.error(e.message);
                    });
                }, () => { });
                break;
            case 'putaway':
                if (this.state.isGDC) {
                    this._putaway([key]);
                } else {
                    setSessionStorage('goodsIds', [key]);
                    http('/goods/goods/checkSettlePrice', { goodsIds: [key] }, 'POST').then((response) => {
                        if (response.status == 200) {
                            addOneBreadcrumbPath({
                                title: '批量上架确认服务清单',
                                path: '/home/warehousemanage/wplist/ratemodification/'
                            });
                            history.push({
                                pathname: '/home/warehousemanage/wplist/ratemodification/'
                            });
                        } else if (response.status == 201) {
                            this.setState({ currentMode: Mode.FAIL_TABLE, modeParams: response.result });
                        } else {
                            message.error(response.message);
                        }
                    }).catch((e) => {
                        message.error(e.message);
                    });
                }
                // this._sendRequest('/goods/goods/putOnSaleGoods', { goodsIds: [key] }, '该商品上架成功。');
                break;
            case 'soldOut':
                this._sendRequest('/goods/goods/pullOffShelvesGoods', { goodsIds: [key] }, '该商品下架成功。');
                break;
            case 'batch':
                this.setState({ goodsIds: value });
                break;
        }
    }

    _getMixInfo = (goodsIds) => {
        const { plist: { dataList } } = this.props;

        let selecteds = (dataList || []).filter((v) => goodsIds.indexOf(v.goodsId) > -1);
        let mixs = selecteds.filter(v => (v.virtualFlag || {}).code == '1');

        return { isMix: !(mixs.length === selecteds.length || mixs.length === 0), isGDC: !!mixs.length };
    }

    _batch = (type) => {
        const { goodsIds } = this.state;
        const { history, addOneBreadcrumbPath } = this.props;
        if (goodsIds.length === 0) {
            message.warning('请勾选商品！');
            return;
        }
        let mixInfo = this._getMixInfo(goodsIds);
        switch (type) {
            case 'putaway':
                if (mixInfo.isMix) {
                    message.warning('虚拟商品不能和实物商品一起上架！');
                } else {
                    if (mixInfo.isGDC) {
                        this._putaway();
                    } else {
                        setSessionStorage('goodsIds', goodsIds);
                        http('/goods/goods/checkSettlePrice', { goodsIds }, 'POST').then((response) => {
                            if (response.status == 200) {
                                addOneBreadcrumbPath({
                                    title: '批量上架确认服务清单',
                                    path: '/home/warehousemanage/wplist/ratemodification/'
                                });
                                history.push({
                                    pathname: '/home/warehousemanage/wplist/ratemodification/'
                                });
                            } else if (response.status == 201) {
                                this.setState({ currentMode: Mode.FAIL_TABLE, modeParams: response.result });
                            } else {
                                message.error(response.message);
                            }
                        }).catch((e) => {
                            message.error(e.message);
                        });
                    }
                }
                // this._sendRequest('/goods/goods/putOnSaleGoods', { goodsIds: batchData }, '批量上架成功。');
                break;
            case 'soldOut':
                this._sendRequest('/goods/goods/pullOffShelvesGoods', { goodsIds }, '批量下架成功。');
                break;
            case 'lock':
                this._sendRequest('/goods/inventory/sync/lock', { goodsIds }, '锁定商品同步操作成功');
                break;
        }

    }

    _putaway = (goodsIds) => {
        goodsIds = goodsIds ? goodsIds : this.state.goodsIds;

        http('/goods/goods/putOnSaleGoods', { goodsIds }, 'POST')
            .then(() => {
                message.success('批量上架成功。');
                this._searchHandle();
            }).catch((error) => {
                message.error(error.message);
            });
    }

    render() {
        const { goodsName, goodsNos, expandBoolean, goodsTypeEnum, goodsStateEnum, virtualFlag, tags, batchData, isGDC, goodsIds, barcode } = this.state;
        const { plist } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle(''); // 【！！！！！！查询缓存第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria}>
                        <XTextArea
                            label='货号集合'
                            inputStyle={{ width: '300px' }}
                            placeholder='请输入货号，多个逗号隔开,最多100个'
                            value={goodsNos}
                            rows={1}
                            onChange={value => {
                                if (value.replace(/，/g, ',').split(',').length < 100) {
                                    this.setState({ goodsNos: value });
                                } else {
                                    message.warning('货号集合最多100个');
                                }
                            }}
                        />
                        <XInput
                            style={{ marginLeft: '20px', width: '248px' }}
                            inputStyle={{ width: '180px' }}
                            label='商品名称'
                            placeholder='请输入商品名称'
                            value={goodsName}
                            bindThis={this}
                            bindName='goodsName'
                        />
                        <GoodsStateEnum
                            style={{ marginLeft: '20px', width: '258px' }}
                            selectStyle={{ width: '180px' }}
                            label='商品状态'
                            placeholder='请输入商品状态'
                            bindThis={this}
                            bindName='goodsStateEnum'
                            value={goodsStateEnum}
                        />
                        {isGDC &&
                            <GDCGoodsState
                                style={{ marginLeft: '20px', width: '273px' }}
                                selectStyle={{ width: '180px' }}
                                label='是否虚拟商品'
                                placeholder='请请选择'
                                bindThis={this}
                                bindName='virtualFlag'
                                value={virtualFlag}
                            />
                        }
                        {isHouNiao() && <XInput
                            style={{ marginLeft: '20px', width: '248px' }}
                            inputStyle={{ width: '180px' }}
                            label='商品条码'
                            placeholder='请输入货品条码'
                            value={barcode}
                            bindThis={this}
                            bindName='barcode'
                        />}
                        <XOKButton style={{ marginLeft: '10px' }} label='查询' onClick={this._searchHandle} />
                        <XCancelButton style={{ marginLeft: '10px' }} label='重置' onClick={this._resetHandle} />
                        <XExpandOrContract style={{ marginLeft: '20px' }} onChange={this._expandChangeHandle} />
                    </div>
                    <div className={`${styles.searchCriteriaHidden} ${expandBoolean ? styles.moreShow : styles.moreHidden}`}>
                        <div className={styles.criteria1}>
                            <GoodsTypeEnum
                                style={{ width: '258px' }}
                                selectStyle={{ width: '180px' }}
                                label='业务类型'
                                placeholder='请输入业务类型'
                                bindThis={this}
                                bindName='goodsTypeEnum'
                                value={goodsTypeEnum}
                                enumType='FromSaveGoodsTypeEnum'
                            />
                            <GoodsTags
                                style={{ width: '516px' }}
                                selectStyle={{ width: '448px' }}
                                label='商品标签'
                                placeholder='请输入商品标签'
                                bindThis={this}
                                bindName='tags'
                                value={tags}
                            />
                        </div>
                        <GoodsCategory
                            label='商品分类'
                            ref={this.goodsCategory}
                            style={{ marginTop: '20px' }}
                            onChange={(value) => this.setState({ categoryId: value })}
                        />
                    </div>
                    <div className={styles.otherFeatures}>
                        {
                            !isFenLai() && !isHouNiao() && <XOKButton style={{ width: '120px' }} label='新建商品' onClick={this._createHandle} />
                        }
                        <XOKButton style={{ width: '120px', marginLeft: '20px' }} label='批量上架' onClick={() => this._batch('putaway')} />
                        <XOKButton style={{ width: '120px', marginLeft: '20px' }} label='批量下架' onClick={() => this._batch('soldOut')} />
                        <XOKButton style={{ width: '120px', marginLeft: '20px' }} label='锁定商品同步' onClick={() => this._batch('lock')} />
                    </div>
                    <ProductTable
                        goodsIds={goodsIds}
                        isGDC={isGDC}
                        batchData={batchData}
                        renderData={plist}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // router history
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getProdList: PropTypes.func, // 请求商品列表
    plist: PropTypes.object, // 商品列表
};

const mapStateToProps = (state) => ({
    plist: state.productlist.plist,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getProdList })(Main);