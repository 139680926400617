/**
 * 售后管理 - 全部退款
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, Button, message } from 'antd';
import ConfirBill from '../../modal/ConfirBill';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination, XDatePicker, showConfirm } from '@/components/xqxc_ui';
import { takeColumns } from './components/TableData';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getValidateStatementTableSource, getSupplierSelectSource } from '@/reducers/storehousefinancialmanage/suppliersettlement/actions';
import CreateBatchPayModal from '../../modal/CreateBatchPayModal';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';
import { SearchBox, SearchItem } from '@/components/Layout';

const Mode = {
	CREATE: 'CREATE',// 受理
	ACCEPT: 'ACCEPT',
	DETAIL: 'DETAIL',
	Reconciliation: 'Reconciliation',
	IncomeRevenue: 'IncomeRevenue',
	DEL: 'DEL'
};

class ValidateStatement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			reconciliationId: props.orderId,
			startDate: null, // 订单发货开始时间
			endDate: null, // 订单发货结束时间
			status: {}, // 结算状态
			supplier: {},

			currentOpenMode: '', // 当前需要打开的弹窗
			openModeParam: {}, // 当前需要打开的弹窗的入参

			selectDataSource: [
				// { code: 'INIT', value: '新建' },
				// { code: 'TO_CONFIRM', value: '待确认' },
				// { code: 'CONFIRM_SUCCESS', value: '确认成功' },
				// { code: 'CONFIRM_FAIL', value: '确认失败' }
			], settleItem: {}
		};
	}

	componentDidMount() {
		this.props.getSupplierSelectSource();

		http('/enum/ReconciliationStatusEnum').then((res) => {
			if (res.code === 200) {
				this.setState({ selectDataSource: res.result });
			}
		});
	}
	// 分页
	_paginationChange = (pageSize, pageNum) => {
		this.setState({ pageNum, pageSize }, () => {
			this._searchHandle('useCache');
		});
	}

	// 查询
	_searchHandle = (useCache) => {

		const { reconciliationId, supplier, startDate, endDate, status, pageNum, pageSize, settleItem } = this.state;

		this.searchCache = {
			reconciliationId,
			status: status.code,
			supplierId: supplier.id, separateMethod: settleItem.code,
			startDate: startDate ? startDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
			endDate: endDate ? endDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
			pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
			pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
		};
		KeepAlive.saveCache({ reconciliationId, supplier, startDate, endDate, status, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
		this.props.getValidateStatementTableSource(this.searchCache); // 发起请求
	}

	// 重置触发 
	_ruleresetHandle = () => {
		this.setState({ reconciliationId: undefined, status: {}, supplier: {}, startDate: undefined, endDate: undefined, settleItem: {} }, () => {
			KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
		});
	}

	// 表格复选框
	_rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {

			let totalPay = 0;
			selectedRows.forEach(p => totalPay += (Number(p.unpaidAmount) || 0));

			this.setState({ openModeParam: { selectedRowKeys, totalPay, selectedRows } });
		}
	};

	// 新建按钮被点击
	_recommendHandle = () => {
		const { selectedRowKeys = [], selectedRows = [] } = this.state.openModeParam;
		if (selectedRowKeys.length) {
			if (selectedRows.some((k) => k.status === 'TO_CONFIRM')) {
				message.warning('包含待确认数据');
			} else {
				this.setState({
					currentOpenMode: Mode.CREATE,
				});
			}
		} else {
			message.warning('请选择需要对账的记录');
		}
	}

	// 表格操作功能回调
	_actionClickHandle = (id, type, value) => {

		const { history, addOneBreadcrumbPath } = this.props;

		switch (type) {
			case Mode.ACCEPT://受理
				this.setState({
					currentOpenMode: Mode.ACCEPT,
					openModeParam: { id }
				});
				break;
			case Mode.RESULT://处理结果
				this.setState({
					currentOpenMode: Mode.RESULT,
				});
				break;
			case Mode.DETAIL://查看详情
				addOneBreadcrumbPath({
					title: '对账详情',
					path: '/home/suppliersettlement/suppliersettlement/validatestatement/detail/' + id + '/' + value,
				});
				history.push({
					pathname: '/home/storehousefinancialmanage/suppliersettlement/validatestatement/detail/' + id + '/' + value
				});
				break;
			case Mode.Reconciliation:
				this.exportExcel('/settle/reconciliationInfo/exportReconciliation', { reconciliationId: id });
				break;
			case Mode.IncomeRevenue:
				this.exportExcel('/settle/reconciliationInfo/exportIncomeRevenue', { reconciliationId: id });
				break;
			case Mode.DEL: // 删除
				showConfirm('是否确定删除？', '', () => {
					http('/settle/reconciliationInfo/delReconciliationByIds', { reconciliationIds: [id] }, 'POST').then((response) => {
						if (response.status == 200) {
							message.success('删除成功!');
							this._searchHandle('useCache');
						}
					}).catch((e) => {
						message.error(e.message);
					});
				});
				break;

		}
	}

	// 打开弹窗（创建/编辑）
	_renderMode = () => {
		switch (this.state.currentOpenMode) {
			case Mode.CREATE:
				return <CreateBatchPayModal title='批量新建付款' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
			case Mode.ACCEPT:
				return <ConfirBill title='确认对账单' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
			default:
				return null;
		}
	}

	// 关闭弹框
	_closeMode = (status) => {
		if (status) {
			this._searchHandle();
		}
		this.setState({ currentOpenMode: '' });
	}

	exportExcel = (url, params) => {
		http(url, params, 'POST').then((response) => {
			if (response.status == 200) {
				window.location.href = response.result;
			}
		}).catch((e) => {
			message.error(e.message);
		});
	}

	generateTimer = null

	render() {
		const { reconciliationId, status, supplier, selectDataSource, startDate, endDate, settleItem } = this.state;
		const { pageNum, pageSize, resultTotal, dataList } = this.props.validateStatementTableSource;

		let generating = !!dataList.filter(v => (v.createStatus || {}).code === '1').length;
		if (generating) {
			if (this.generateTimer === null) {
				this.generateTimer = setInterval(() => this._searchHandle('useCache'), 3000);
			}
		} else {
			this.generateTimer && clearInterval(this.generateTimer);
			this.generateTimer = null;
		}

		// 排查一下不通过一直弹出 是否是被KeepAlive缓存
		return (
			<KeepAlive id='validatestatement' render={({ currentOpenMode, ...state }) => {
				if (!this.state.__aliveInit) {
					this.setState({ ...state, __aliveInit: true }, () => {
						this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
					});
				}
			}}>
				<div className={styles.flexBoxContainer}>
					<SearchBox>
						<SearchItem>
							<XInput
								style={{ width: '250px' }}
								inputStyle={{ width: '170px' }}
								label='对账单号'
								placeholder='请输入对账单号'
								value={reconciliationId}
								bindThis={this}
								bindName='reconciliationId'
								mode='number'
								maxLength={16}
								showLength={false}
							/>
						</SearchItem>
						<SearchItem>
							<XDatePicker
								style={{ marginLeft: '20px', marginRight: '10px' }}
								label='对账周期'
								value={startDate}
								bindThis={this}
								bindName='startDate'
							/>
							<XDatePicker
								value={endDate}
								bindThis={this}
								bindName='endDate'
							/>
						</SearchItem>
						<SearchItem>
							<XSelect
								style={{ marginLeft: '20px', width: '266px' }}
								selectStyle={{ width: '200px' }}
								label='供应商'
								placeholder='请选择供应商'
								renderData={this.props.supplierSelectSource}
								dataIndex='optionName'
								keyIndex='id'
								showSearch={true}
								value={supplier.optionName}
								bindThis={this}
								bindName='supplier'
							/>
						</SearchItem>
						<SearchItem>
							<XSelect
								style={{ marginLeft: '20px', width: '216px' }}
								selectStyle={{ width: '150px' }}
								label='状态'
								placeholder='请选择状态'
								renderData={selectDataSource}
								dataIndex='value'
								keyIndex='code'
								value={status.value}
								bindThis={this}
								bindName='status'
							/>
						</SearchItem>
						<SearchItem>
							<XSelect style={{ marginLeft: '20px', width: '216px', marginRight: '20px' }} selectStyle={{ width: '150px' }} label='结算方式' placeholder='请选择结算方式'
								renderData={[{ code: 'ONLINE_SETTLE', value: '线上结算' }, { code: 'OFFLINE_SETTLE', value: '线下结算' }]}
								dataIndex='value' keyIndex='code' value={settleItem.value} bindThis={this} bindName='settleItem'
							/>
						</SearchItem>
						<SearchItem>
							<XOKButton style={{ marginRight: '20px' }}
								label='查询'
								onClick={this._searchHandle}
							/>
							<XCancelButton
								label='重置'
								onClick={this._ruleresetHandle}
							/>
						</SearchItem>
					</SearchBox>
					<div>
						<Button type="primary" onClick={this._recommendHandle}>批量新建付款</Button>
					</div>
					<div className={`${styles.table_body} ${styles.tableContainer}`}>
						<Table
							// bordered
							rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
							rowSelection={this._rowSelection}
							rowKey={(record, index) => record.id}
							columns={takeColumns(this._actionClickHandle)}
							dataSource={dataList}
							pagination={false}
							scroll={{ y: true, x: 'max-content' }}
						/>
						<XPagination
							resultTotal={resultTotal}
							pageSize={pageSize}
							pageNum={pageNum}
							onChange={this._paginationChange}
						/>
					</div>
					<div>{this._renderMode()}</div>
				</div>
			</KeepAlive>
		);
	}
}

ValidateStatement.propTypes = {
	updateContent: PropTypes.func, // 路由跳转
	history: PropTypes.object, // 路由history
	addOneBreadcrumbPath: PropTypes.func,
	keepSecondNavBreadcrumb: PropTypes.func,
	orderId: PropTypes.string,
	validateStatementTableSource: PropTypes.object,
	getValidateStatementTableSource: PropTypes.func,

	supplierSelectSource: PropTypes.array,
	getSupplierSelectSource: PropTypes.func,
};

const mapStateToProps = (state) => ({
	validateStatementTableSource: state.suppliersettlement.validateStatementTableSource,
	supplierSelectSource: state.suppliersettlement.supplierSelectSource,
});


export default connect(mapStateToProps, {
	addOneBreadcrumbPath,
	keepSecondNavBreadcrumb,
	getValidateStatementTableSource,
	getSupplierSelectSource
})(ValidateStatement);