/**
 *  店铺管理 - 店铺商品
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Typography, Tree, Input, Icon, Popover, message, Tabs } from 'antd';
import { XOKButton, XCancelButton, XInput, XSelect } from '@/components/xqxc_ui';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum } from '@/assets/config';
import TableContent from './components/TableContent';
import CreateClassify from './modol/CreateClassify';
import EditClassify from './modol/EditClassify';
import GoodsClassify from './modol/GoodsClassify';
import UpdatePrice from './modol/UpdatePrice';
import UpdateReserveModal from '@/views/warehousemanage/productreserve/modal/UpdateReserveModal';
import { setSessionStorage } from '@/assets/js/storage';
import model from '@/reducers/businessmanage/cityshopgoods/model';
import Agreement from '@/vcomps/MerchantAgreement/Agreement';

const { Text } = Typography;
const { TreeNode } = Tree;
const { TabPane } = Tabs;
const { Search } = Input;

//同步线上数据
const onlineList = [
    { key: 0, value: '未同步' },
    { key: 1, value: '已同步' },
];

class ShopGoods extends Component {

    static propTypes = {
        history: PropTypes.object, // 路由history
        addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
        keepSecondNavBreadcrumb: PropTypes.func, // 保持2级面包屑导航信息
        getCityClassTree: PropTypes.func,//获取分类树
        cityClassTree: PropTypes.array,//分类树
        classtateChenge: PropTypes.func,//分类状态发射变化
        getCityGoodsList: PropTypes.func,//获取表格数据
        cityGoodsList: PropTypes.object,//表格数据
        getCityGoodsTabs: PropTypes.func,//获取tab数据
        cityGoodsTabs: PropTypes.array,//tab数据
        getCityShopH5Url: PropTypes.func,//获取店铺H5地址
        cityShopH5Url: PropTypes.string,//店铺H5地址
    };

    static defaultProps = {
        cityGoodsList: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
        cityClassTree: [],
        cityClassSele: [],
        cityGoodsTabs: [],
        cityShopH5Url: ''
    };

    state = {
        searchKey: '',
        shopCategoryId: '',
        curTabID: 0,
        expandedKeys: [],
        searchValue: '',
        visible: false,
        renderModal: '',
        modalParams: null,
        goodsIds: [],
        onlines: {},// 同步线上
        agreementInfo: {}
    }

    componentDidMount() {
        const { shopCategoryId } = this.state;
        const { keepSecondNavBreadcrumb, getCityClassTree, getCityShopH5Url } = this.props;
        keepSecondNavBreadcrumb();
        getCityClassTree();
        getCityShopH5Url();
    }

    // 查询触发
    _searchOperate = (useCache) => {
        const { searchKey, shopCategoryId, curTabID, pageNum, onlines } = this.state;
        this.searchCache = {
            searchKey, statusType: curTabID, shopCategoryId,
            isSynchronize: onlines.key,//同步线上
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: 1000000, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getCityGoodsTabs({ shopCategoryId });
        this.props.getCityGoodsList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, }); // 【第3步，缓存查询状态】
    }

    //重置
    _searchReset = () => {
        this.setState({ onlines: {}, searchKey: '', curTabID: 0, shopCategoryId: '', }, () => {
            KeepAlive.saveResetFunc(this._searchOperate); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 分页
    _pageChange = (pageSize, pageNum) => {
        this.setState({ pageNum }, () => {
            this._searchOperate('useCache');
        });
    }

    //请求函数
    _reqFunc = (url, data, update) => {
        const { getCityClassTree } = this.props;
        http(url, data, 'POST')
            .then((res) => {
                if (update == 'openModal') {
                    this.setState({
                        renderModal: 'updateInventory',
                        modalParams: res.result,
                    });
                    return;
                }
                if (update === 'online') message.success(`${res.result}，请耐心等待`, 5);//批量同步到线上
                else message.success(res.message);
                if (update == 'reqClass') getCityClassTree();
                this._searchOperate('useCache');
            })
            .catch(e => {
                message.error(e.message);
            });
    }

    //分类树渲染
    _loop = data => {
        return data.map(item => {
            return <TreeNode
                key={item.categoryId}
                title={
                    <>
                        <Text style={{ paddingRight: 20 }} >{item.categoryName}</Text>
                        <Popover
                            content={
                                <>
                                    <XCancelButton
                                        label='修改分类'
                                        type='link'
                                        onClick={e => {
                                            e && e.stopPropagation();
                                            this.setState({
                                                renderModal: 'editClassify',
                                                modalParams: {
                                                    parentId: item.parentId,
                                                    categoryId: item.categoryId,
                                                    categoryName: item.categoryName
                                                }
                                            }, () => {
                                                this.props.classtateChenge(item.categoryId);
                                            });
                                        }}
                                    />
                                    {
                                        item.categoryGrade == 1 ?
                                            <XCancelButton
                                                label='新增二级分类'
                                                type='link'
                                                onClick={e => {
                                                    e && e.stopPropagation();
                                                    this.setState({
                                                        renderModal: 'childClass',
                                                        modalParams: {
                                                            parentId: item.parentId,
                                                            categoryId: item.categoryId,
                                                            categoryName: item.categoryName
                                                        }
                                                    }, () => {
                                                        this.props.classtateChenge(item.categoryId);
                                                    });
                                                }}
                                            /> :
                                            null
                                    }
                                    <XCancelButton
                                        label='删除分类'
                                        type='link'
                                        onClick={e => {
                                            e && e.stopPropagation();
                                            if (item.categoryId == this.state.shopCategoryId) {
                                                this.setState({ shopCategoryId: '' }, () => {
                                                    this._reqFunc(`/city/shopCategoryInfo/delShopCategory/${item.categoryId}`, {}, 'reqClass');
                                                });
                                            } else {
                                                this._reqFunc(`/city/shopCategoryInfo/delShopCategory/${item.categoryId}`, {}, 'reqClass');
                                            }
                                        }}
                                    />
                                </>
                            }
                            trigger='click'
                            placement='right'
                        >
                            <Icon
                                type='setting'
                                onClick={e => {
                                    e && e.stopPropagation();
                                    this.props.classtateChenge(item.categoryId);
                                }}
                            />
                        </Popover>
                    </>
                } >
                {
                    item.categoryChilds ?
                        this._loop(item.categoryChilds) :
                        null
                }
            </TreeNode>;
        });
    }

    //树形结构展开
    _onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
        });
    }

    //下架商品
    _soldOutGoods = () => {
        const { goodsIds } = this.state;
        if (!goodsIds.length) {
            message.warning('请勾选商品');
            return;
        }
        this._reqFunc('/goods/cityShopGoods/pullOffShelvesGoods', { goodsIds }, 'reqTabs');
    }

    // 打开弹窗（创建/编辑）
    _renderModal = () => {
        const { renderModal, modalParams, goodsIds } = this.state;
        switch (renderModal) {
            case 'createClassify':
                return <CreateClassify
                    closeMode={this._closeMode}
                    modalParams={modalParams}
                />;
            case 'editClassify':
                return <EditClassify
                    closeMode={this._closeMode}
                    modalParams={modalParams}
                />;
            case 'childClass':
                return <CreateClassify
                    closeMode={this._closeMode}
                    modalParams={modalParams}
                />;
            case 'editGoodsClassify':
                return <GoodsClassify
                    closeMode={this._closeMode}
                    modalParams={goodsIds}
                />;
            case 'updatePrice':
                return <UpdatePrice
                    modalParams={modalParams}
                    closeMode={this._closeMode}
                />;
            case 'updateInventory':
                return <UpdateReserveModal
                    visible={true}
                    data={modalParams}
                    closeMode={this._closeMode}
                />;
            case 'agreement':
                return <Agreement
                    visible={true}
                    data={modalParams}
                    closeMode={this._closeMode}
                />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        const { getCityClassTree } = this.props;
        this.setState({ renderModal: '', modalParams: null, goodsIds: [] }, () => {
            getCityClassTree();
            this._searchOperate('useCache');
        });
    }

    //更新页面
    _updatePage = (title, url) => {
        const { history, addOneBreadcrumbPath } = this.props;
        const baseUrl = '/home/businessmanage/shopgoods';
        addOneBreadcrumbPath({
            title,
            path: baseUrl
        });
        history.push({
            pathname: `${baseUrl}${url}`
        });
    }

    //表格操作
    _tableOperate = (type, params) => {
        switch (type) {
            case 'updatePrice':
                this.setState({ renderModal: 'updatePrice', modalParams: params });
                break;
            case 'updateInventory':
                this._reqFunc('/goods/inventory/findGoodsInventoryInfo', { goodsId: params }, 'openModal');
                break;
            case 'goodsIds':
                this.setState({ goodsIds: params });
                break;
            case 'putaway':
                http('/goods/cityShopGoods/putOnSaleGoods', { goodsIds: [params.goodsId] }, 'POST')
                    .then(res => {
                        if(res.result.merchantSettleAgreementId){
                            // 签署协议
                            this.setState({
                                renderModal: 'agreement',
                                modalParams: res.result
                            });
                        }else{
                            message.success(res.message); 
                            this._searchOperate('useCache');
                        }
                    }).catch(err=>{
                        message.error(err.message);
                    });
                break;
            case 'soldOut':
                this._reqFunc('/goods/cityShopGoods/pullOffShelvesGoods', { goodsIds: params }, 'reqTabs');
                break;
            case 'recommend':
                this._reqFunc('/goods/cityShopGoods/recommendGoods', {
                    operation: params.checked ? 'ADD' : 'REMOVE',
                    goodsIds: [params.goodsId]
                });
                break;
            case 'detail':
                this._updatePage('同城商品详情', `/goodsinfo/${params}`);
                break;
            default:
                break;
        }
    }

    //满足跳转条件跳转页面
    _specialPageJump = (title, baseUrl, type) => {
        const { cityClassTree } = this.props;
        const { shopCategoryId } = this.state;
        let fullUrl = '';
        if (!shopCategoryId) {
            message.warning('请选择销售分类。');
            return;
        }
        cityClassTree.forEach(element => {
            if (element.categoryId == shopCategoryId.toString()) {
                message.warning('请选择二级销售分类请创建二级分类并在二级分类下创建商品。');
            } else {
                element.categoryChilds && element.categoryChilds.forEach(item => {
                    if (item.categoryId == shopCategoryId.toString()) {
                        fullUrl = type ?
                            `${baseUrl}${item.categoryId}/${item.categoryName}` :
                            `${baseUrl}${item.categoryId}`;
                        this._updatePage(title, fullUrl);
                    }
                });
            }
        });
    }

    _batchPutaway = (goodsIds = []) => {
        const { cityGoodsList: { dataList = [] } } = this.props;
        if (!goodsIds.length) {
            message.warning('请勾选商品');
            return;
        }
        http('/goods/cityShopGoods/putOnSaleGoods', { goodsIds }, 'POST')
            .then(res => { 
                if(res.result.merchantSettleAgreementId){
                    // 签署协议
                    this.setState({
                        renderModal: 'agreement',
                        modalParams: res.result
                    });
                }else{
                    message.success(res.message); 
                    this._searchOperate('useCache');
                }
            }).catch(err=>{
                message.error(err.message);
            });
    }

    //批量同步到线上
    _batchOnline = () => {
        const { goodsIds } = this.state;
        if (!goodsIds.length) {
            message.warning('请勾选商品');
            return;
        }
        this.setState({ goodsIds: [] }, () => {
            this._reqFunc('/goods/cityShopGoods/copyGoodsFromCity', { goodsIds }, 'online');
        });
    }

    render() {
        const { cityGoodsList, cityGoodsTabs, cityClassTree, cityShopH5Url } = this.props;
        const { curTabID, searchKey, shopCategoryId, expandedKeys, goodsIds, onlines } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchOperate('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.containerBox}>
                    <div className={styles.classifyBox}>
                        <XOKButton
                            style={{ width: 90, margin: '10px 0' }}
                            label='新建分类'
                            onClick={() => {
                                this.setState({ renderModal: 'createClassify' });
                            }}
                        />
                        <Tree
                            style={{ height: 'calc(100% - 50px)', overflowY: 'auto', padding: '10px 0' }}
                            showLine={true}
                            onExpand={this._onExpand}
                            expandedKeys={expandedKeys}
                            selectedKeys={shopCategoryId ? [shopCategoryId] : []}
                            onSelect={value => {
                                this.setState({ shopCategoryId: value.toString(), goodsIds: [] }, () => {
                                    this._searchOperate('useCache');
                                });
                            }}
                        >
                            {this._loop(cityClassTree)}
                        </Tree>
                    </div>
                    <div className={styles.tableBox}>
                        <div className={styles.navBox}>
                            <Tabs
                                onChange={key => {
                                    this.setState({ curTabID: key }, () => {
                                        this._searchOperate('useCache');
                                    });
                                }}
                                activeKey={curTabID.toString()}>
                                {
                                    cityGoodsTabs.map(item => {
                                        return <TabPane
                                            tab={`${item.goodsStateName}（${item.goodsNumber}）`}
                                            key={item.goodsState}
                                        />;
                                    })
                                }
                            </Tabs>
                            {/* <Search
                                placeholder='请输入货品名称/卖点搜索'
                                value={searchKey}
                                enterButton
                                style={{ width: 360, paddingLeft: 30 }}
                                onChange={e => this.setState({ searchKey: e.target.value })}
                                onSearch={() => this._searchOperate('useCache')}
                            /> */}
                            <XInput
                                style={{ marginLeft: '20px' }}
                                inputStyle={{ width: '200px' }}
                                label=''
                                placeholder='请输入货品名称/卖点搜索'
                                value={searchKey}
                                bindThis={this}
                                bindName='searchKey'
                            />
                            <XSelect
                                style={{ marginLeft: '20px', }}
                                selectStyle={{ width: '150px' }}
                                label='同步线上'
                                placeholder='请选择'
                                renderData={onlineList}
                                dataIndex='value'
                                keyIndex='key'
                                value={onlines.value}
                                bindThis={this}
                                bindName='onlines'
                            />
                            <XOKButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='查询'
                                onClick={this._searchOperate}
                            />
                            <XCancelButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='重置'
                                onClick={this._searchReset}
                            />
                        </div>
                        <div className={styles.navBox}>
                            <div style={{ display: 'flex', marginBottom: 10 }}>
                                <XOKButton
                                    style={{ width: 90 }}
                                    label='新建商品'
                                    onClick={() => this._specialPageJump('创建店铺商品', '/creategoods/', 'double')}
                                />
                                < XOKButton
                                    style={{ marginLeft: 20, width: 150 }}
                                    label='从商品库上架商品'
                                    onClick={() => this._specialPageJump('从商品库上架商品', '/goodslibrary/')}
                                />
                                < XOKButton
                                    style={{ marginLeft: 20, width: 150 }}
                                    label='批量同步到线上'
                                    onClick={this._batchOnline}
                                />
                            </div>
                            <div style={{ display: 'flex', marginBottom: 10 }}>
                                <XOKButton
                                    style={{ marginLeft: 20, width: 90 }}
                                    label='店铺预览'
                                    onClick={() => window.open(cityShopH5Url)}
                                />
                                <XOKButton
                                    style={{ marginLeft: 20, width: 60 }}
                                    label='上架'
                                    onClick={() => this._batchPutaway(goodsIds)}
                                />
                                <XCancelButton
                                    style={{ marginLeft: 20, width: 60 }}
                                    label='下架'
                                    onClick={this._soldOutGoods}
                                />
                                <XCancelButton
                                    style={{ marginLeft: 20, width: 120 }}
                                    label='修改销售分类'
                                    onClick={() => {
                                        console.log(goodsIds);
                                        if (!goodsIds.length) {
                                            message.warning('请选择商品');
                                            return;
                                        }
                                        this.setState({ renderModal: 'editGoodsClassify' });
                                    }}
                                />
                            </div>
                        </div>
                        <TableContent
                            renderData={cityGoodsList}
                            pageChange={this._pageChange}
                            tableOperate={this._tableOperate}
                            updatePage={this._updatePage}
                            goodsIds={goodsIds}
                        />
                    </div>
                    {this._renderModal()}
                </div>
            </KeepAlive>
        );
    }
}

export default connect(state => ({
    cityGoodsList: state.cityShopGoods.cityGoodsList,
    cityGoodsTabs: state.cityShopGoods.cityGoodsTabs,
    cityClassTree: state.cityShopGoods.cityClassTree,
    cityClassSele: state.cityShopGoods.cityClassSele,
    cityShopH5Url: state.cityShopGoods.cityShopH5Url,
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(ShopGoods);