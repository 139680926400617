import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { getIllegalProductList, getIllegalSele } from '@/reducers/illegalRecord/illegalList/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class ProductIllegal extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: [], // 当前需要打开的弹窗的入参
        productName: '',
        productNo: '',
        productStatus: {}
    }

    componentDidMount() {
        this.props.getIllegalSele();
    }

    //店铺改变
    _productNameChange = (value) => {
        this.setState({ productName: value });
    }
    //店铺改变
    _productNoChange = (value) => {
        this.setState({ productNo: value });
    }
    //状态改变
    _productStatusChange = (selectedItem) => {
        this.setState({ productStatus: selectedItem });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { productName, productStatus, productNo, pageNum, pageSize } = this.state;
        const query = {
            goodsName: productName,
            goodsNo: productNo,
            violationState: productStatus.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getIllegalProductList(query);
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ productName: '', productNo: '', productStatus: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'appeal':
                console.log(id, type, params);
                this.props.updateContent('productAppeal', params.violationId);
                break;
            case 'detail':
                console.log(id, type, params);
                this.props.updateContent('productDetail', params.violationId);
                break;
        }
    }

    // 分页 
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { illegalProductList, illegalSele } = this.props;
        const { productName, productStatus, productNo } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='商品名称'
                            placeholder='请输入'
                            onChange={this._productNameChange}
                            value={productName}
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '200px' }}
                            label='商品货号'
                            placeholder='请输入'
                            onChange={this._productNoChange}
                            value={productNo}
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '200px' }}
                            label='状态'
                            placeholder='请选择'
                            renderData={illegalSele}
                            onChange={this._productStatusChange}
                            dataIndex='value'
                            keyIndex='code'
                            value={productStatus.value}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    {/* <div className={styles.operate_box}>
                    <XOKButton style={{ width: '90px' }} label='导出Excel' />
                </div> */}
                    <TableContent paginationChange={this._paginationChange} renderData={illegalProductList} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }

}


ProductIllegal.propTypes = {
    updateContent: PropTypes.func,
    getIllegalProductList: PropTypes.func,
    getIllegalSele: PropTypes.func,

    illegalProductList: PropTypes.object,
    illegalSele: PropTypes.array
};

const mapStateToProps = (state) => ({
    illegalProductList: state.illegalRecordData.illegalProductList,
    illegalSele: state.illegalRecordData.illegalSele
});

export default connect(mapStateToProps, { getIllegalProductList, getIllegalSele })(ProductIllegal);