/**
 * 绑定银行卡
 */
import React from 'react';
import styles from './step.module.scss';
import PropTypes from 'prop-types';
import { Form, Button, Input, message } from 'antd';
import Ensure from './../components/Ensure';
import http from '@/assets/api/http';

const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 10 },
        sm: { span: 10 },
    },
};

class step extends React.Component {

    state = {
        loading: false,
        required: true,
        visible: false,
        bindCardBoolean: false, // 是否绑定过银行卡，如果已绑定过则无需再填写信息
        show: false, // 显示界面，优化用户体验防止界面多次闪动
        bankAccount: '', // 银行卡账号
        tel: '', // 手机号
        captcha: '', // 验证码
        alreadySetLoading: false, // 加载中
        alreadySetCaptcha: false, // 已经提前发送验证码
        time: 60, // 发送验证码倒计时
        bankName: '', // 开户银行
        bankCode: '', // 银行代码
        cardType: '', // 银行卡类型
        cardTypeName: '', // 银行卡名称
        tranceNum: '', // 流水号
    }

    componentDidMount() {
        http('/merchant/info/yums/queryBankCard', {}).then((response) => {
            if (response.status == 200) {
                const bankList = response.result;
                if (Array.isArray(bankList) && bankList.length > 0) {
                    this.setState({ bindCardBoolean: true });
                }
                this.setState({ show: true });
            }
        }).catch((e) => {
            message.error(e.message);
            this.setState({ show: true });
        });
    }

    UNSAFE_componentWillMount() {
        clearInterval(this.timeID);
    }

    // 下一步
    _handleSubmit = e => {
        this.setState({ loading: true });
        if (this.state.bindCardBoolean) {
            // 如果已绑定则直接请求电子协议
            this._signContract();
        } else {
            //如果未绑定则先绑定数据
            if (e) e.preventDefault();
            this.props.form.validateFields((err, fieldsValue) => {
                if (!err) {
                    const { bankAccount, tel, captcha } = fieldsValue;
                    const { bankName, bankCode, cardType, tranceNum } = this.state;
                    this._bindBankCard(bankAccount, cardType, bankName, tel, tranceNum, captcha, bankCode);
                }
            });
        }
    }

    // 绑定银行卡 
    _bindBankCard = (bankAccount, cardType, openBankName, phone, tranceNum, verificationCode, bankCode) => {
        http('/merchant/info/yums/bindBankCard', { bankAccount, cardType, openBankName, phone, tranceNum, verificationCode, bankCode }).then((response) => {
            if (response.status == 200) {
                this._signContract();
                this.setState({ visible: true });
            }
        }).catch((e) => {
            this.setState({ loading: false });
            message.error(e.message);
        });
    }

    // 请求电子协议
    _signContract = () => {
        const successUrl = window.location.origin + '/#/paysucess';
        const errorUrl = window.location.origin + '/#/payfail';
        http('/merchant/info/yums/signContract', { successUrl, errorUrl }).then((response) => {
            if (response.status == 200) {
                const http = (response.result.toString()).substring(0, 4);
                if (http == null || http == 'null') {
                    message.error('无法获取签约地址或地址有误');
                    this.setState({ loading: false });
                } else {
                    try{
                        var tempwin = window.open('about:blank');
                        tempwin.location = response.result;
                        this.setState({ visible: true, loading: false });
                    }catch(e){
                        this.setState({ loading: false });
                        message.error('弹出窗口被拦截，请点击地址栏下”允许阻止的内容“设置');
                    }
                }
            }
        }).catch((e) => {
            this.setState({ loading: false });
            message.error(e.message);
        });
    }

    // 渲染签约确认弹框
    _renderEnsureComp = () => {
        const { visible } = this.state;
        return <Ensure signSuccess={this.props.signSuccess} visible={visible} closeMode={() => { this.setState({ visible: false }); }} />;
    }

    // 获取验证码
    _getcaptcha = () => {
    
        const _this = this;
        const tel = this.props.form.getFieldsValue().tel;
        const bankAccount = this.props.form.getFieldsValue().bankAccount;
        const pattern = /^[1-9]\d{10}$/;
        clearInterval(_this.timeID);
        this.setState({ alreadySetLoading: true });
        if (pattern.test(tel) && bankAccount) {
            http('/merchant/info/yums/applyBindBankCard', { phone: tel, cardNo: bankAccount, memberType: '3' }).then((response) => {
                this.setState({ alreadySetLoading: false });
                if (response.status == 200) {
                    message.success('验证码发送成功');
                    this.setState({ alreadySetCaptcha: true });
                    this.timeID = setInterval(function () {
                        const { time } = _this.state;
                        if (time > 0) {
                            _this.setState({ time: time - 1 });
                        } else {
                            clearInterval(_this.timeID);
                            _this.setState({ alreadySetCaptcha: false, time: 60 });
                        }
                    }, 1000);
                    const { bankName, bankCode, cardType, tranceNum } = response.result;
                    this.setState({ bankName, bankCode, tranceNum });
                    if (cardType == 1) {
                        this.setState({ cardType, cardTypeName: '储蓄卡' });
                    } else if (cardType == 2) {
                        this.setState({ cardType, cardTypeName: '信用卡' });
                    } else {
                        this.setState({ cardType, cardTypeName: '无法识别' });
                    }
                }
            }).catch((e) => {
                this.setState({ alreadySetLoading: false });
                message.error(e.message);
            });
        } else {
            this.setState({ alreadySetLoading: false });
            message.error('请正确填写手机号和银行卡账号');
        }
    }

    render() {
        const { loading, bindCardBoolean, required, bankName, bankCode, cardTypeName, tranceNum, show, alreadySetCaptcha, alreadySetLoading, time } = this.state;
        const { getFieldDecorator } = this.props.form;
        const captchaLabel = alreadySetCaptcha ? '短信已发送(' + time + ')' : '获取验证码';

        return (
            <div className={styles.step3}>
                {
                    (!bindCardBoolean && show) ? <Form autoComplete='off' {...formItemLayout}>
                        <Form.Item
                            colon={false}
                            htmlFor='bankAccount'
                            label={<span>银行账号：</span>}
                        >
                            {getFieldDecorator('bankAccount', {
                                rules: [
                                    { required, message: '请输入24位或以下数字组成的银行账号' },
                                    {
                                        validator: (rule, value = '', callback) => {
                                            var pattern = /^\d{0,24}$/;
                                            // 去除空格
                                            var str = value.replace(/\s+/g, '');
                                            if (pattern.test(str)) {
                                                callback();
                                            } else {
                                                callback('请输入24位或以下数字组成的银行账号');
                                            }
                                        }
                                    }
                                ],
                            })(<Input placeholder='请输入24位或以下数字组成的银行账号' />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='tel'
                            label={<span>银行预留手机：</span>}
                        >
                            {getFieldDecorator('tel', {
                                rules: [
                                    { required, message: '请输入11位手机号' },
                                    { pattern: /^[1-9]\d{10}$/, message: '请输入11位正确的手机号' },
                                ],
                            })(<Input placeholder='请输入11位手机号' />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='captcha'
                            label={<span>验证码：</span>}
                        >
                            <div className={styles.yzm}>
                                {getFieldDecorator('captcha', {
                                    rules: [
                                        { required, message: '请输入验证码' },
                                    ],
                                })(<Input placeholder='请输入验证码' />)}
                                <Button type="primary" loading={alreadySetLoading}  disabled={alreadySetCaptcha} className={styles.getcaptcha} onClick={this._getcaptcha}>{captchaLabel}</Button>
                            </div>
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='bankName'
                            label={<span>开户银行：</span>}
                        >
                            {getFieldDecorator('bankName', {
                                initialValue: bankName
                            })(<Input placeholder='成功获取验证码后，由系统自动填充' disabled />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='bankCode'
                            label={<span>银行代码：</span>}
                        >
                            {getFieldDecorator('bankCode', {
                                initialValue: bankCode
                            })(<Input placeholder='成功获取验证码后，由系统自动填充' disabled />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='cardTypeName'
                            label={<span>银行卡类型：</span>}
                        >
                            {getFieldDecorator('cardTypeName', {
                                initialValue: cardTypeName
                            })(<Input placeholder='成功获取验证码后，由系统自动填充' disabled />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='tranceNum'
                            label={<span>流水号：</span>}
                        >
                            {getFieldDecorator('tranceNum', {
                                initialValue: tranceNum
                            })(<Input placeholder='成功获取验证码后，由系统自动填充' disabled />)}
                        </Form.Item>
                    </Form> :
                        <div className={styles.txt}>
                            您已经绑定过银行卡，点击下一步签订电子协议
                        </div>
                }

                <div className={styles.btn}>
                    {/* <Button className={styles.previous} onClick={this.props.previous}>上一步</Button> */}
                    <Button type="primary" htmlType="submit" onClick={this._handleSubmit} loading={loading}>下一步</Button>
                </div>
                {this._renderEnsureComp()}
            </div>
        );
    }
}

step.propTypes = {
    signSuccess: PropTypes.func, // 签约成功
    previous: PropTypes.func, // 上一步
    next: PropTypes.func, // 下一步
    form: PropTypes.object, // 表单校验
};
const stepForm = Form.create({ name: 'step-form' })(step);
export default stepForm;

