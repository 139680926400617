import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { getIllegalShopList, getIllegalSele } from '@/reducers/illegalRecord/illegalList/actions';

class Main extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: [], // 当前需要打开的弹窗的入参
        shopName: '',
        pageNum: 1,
        pageSize: 20,
        isCheck: false,
        shopStatus: {}
    }

    componentDidMount() {
        const { pageNum, pageSize } = this.state;
        this.props.getIllegalShopList({ pageNum, pageSize });
        this.props.getIllegalSele();
    }

    //商品改变
    _shopNameChange = (value) => {
        this.setState({ shopName: value, isCheck: false });
    }
    //状态改变
    _shopStatusChange = (selectedItem) => {
        this.setState({ shopStatus: selectedItem, isCheck: false });
    }

    // 查询
    _searchHandle = () => {
        const { shopName, shopStatus, pageNum, pageSize } = this.state;
        this.props.getIllegalShopList({ violationState: shopStatus.code, shopName, pageNum, pageSize });
        this.setState({ isCheck: true });
    }

    // 重置
    _resetHandle = () => {
        this.setState({ shopName: '', pageNum: 1, pageSize: 20, isCheck: false, shopStatus: {} });
        const { pageNum, pageSize } = this.state;
        this.props.getIllegalShopList({ pageNum, pageSize });
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'appeal':
                console.log(id, type, params);
                this.props.updateContent('appeal', params.id);
                break;
            case 'detail':
                console.log(id, type, params);
                this.props.updateContent('detail', params.id);
                break;
        }
    }

    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        const { shopName, shopStatus, isCheck } = this.state;
        if (!isCheck) {
            this.props.getIllegalShopList({ pageNum, pageSize });
        } else {
            this.props.getIllegalShopList({ violationState: shopStatus.code, shopName, pageNum, pageSize });
        }
    }

    render() {
        const { illegalShopList, illegalSele } = this.props;
        const { shopName, shopStatus } = this.state;
        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.search_box}>
                    <XInput
                        inputStyle={{ width: '200px' }}
                        label='店铺名称'
                        placeholder='请输入'
                        onChange={this._shopNameChange}
                        value={shopName}
                    />
                    <XSelect
                        style={{ marginLeft: '20px' }}
                        selectStyle={{ width: '200px' }}
                        label='状态'
                        placeholder='请选择'
                        renderData={illegalSele}
                        onChange={this._shopStatusChange}
                        dataIndex='value'
                        keyIndex='code'
                        value={shopStatus.merchantName}
                    />
                    <XOKButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='查询'
                        onClick={this._searchHandle}
                    />
                    <XCancelButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='重置'
                        onClick={this._resetHandle}
                    />
                </div>
                {/* <div className={styles.operate_box}>
                    <XOKButton style={{ width: '90px' }} label='导出Excel' />
                </div> */}
                <TableContent paginationChange={this._paginationChange} renderData={illegalShopList} tableAction={this._tableAction} />
            </div>
        );
    }

}

Main.propTypes = {
    updateContent: PropTypes.func,
    getIllegalShopList: PropTypes.func,
    getIllegalSele: PropTypes.func,

    illegalShopList: PropTypes.object,
    illegalSele: PropTypes.array
};

const mapStateToProps = (state) => ({
    illegalShopList: state.illegalRecordData.illegalShopList,
    illegalSele: state.illegalRecordData.illegalSele
});

export default connect(mapStateToProps, { getIllegalShopList, getIllegalSele })(Main);