/**
 * 酒店订单
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as Order from './actionTypes';

//酒店订单列表
export const getHotelOrderList = function* () {
    yield takeEvery(Order.GET_HOTELORDER_LIST, function* requestData(action) {
        try {
            let result = yield http('/hotel/order/list', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_HOTELORDER_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 支付方式
export const getPayWays = function* () {
    yield takeEvery(Order.GET_PAY_WAYS, function* requestData(action) {
        try {
            let result = yield http('/hotel/order/rechargeChannelQuery', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_PAY_WAYS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 房间数
export const getRoomNum = function* () {
    yield takeEvery(Order.GET_ROOM_NUM, function* requestData(action) {
        try {
            let result = yield http('/hotel/order/getRoomNum', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_ROOM_NUM, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 房型
export const getRoomInfo = function* () {
    yield takeEvery(Order.GET_ROOM_INFO, function* requestData(action) {
        try {
            let result = yield http('/hotel/houseListItem', action.payload, 'POST'); // action.payload为传入的参数对象
            yield put({ type: Order.SET_ROOM_INFO, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};