/**
 * 仓库商品管理 Saga集合
 */
import { getProdList } from './productlist/saga';
import { WarehouseStockList, } from './productreserve/saga';
import { WarehousePriceList, WarehouseAuditList, auditSelectData, } from './productprice/saga';
import { getOuterGoodsClassifyList, getOuterGoodsClassifySele } from './productClassify/saga';
import { getGoodsConnectList, getGoodsConnectImport, getGoodsConnectGoodsState, getGoodsConnectDetail } from './goodsConnect/saga';
import goodsConnectInfo from './goodsConnect/model';
import { getInventoryConnectList, getInventoryConnectState } from './inventoryConnect/saga';
import { getBalanceHintList,getNotBalanceHintList, getAutoAdjustInfo } from './balanceHint/saga';

export default [
    getProdList,
    WarehouseStockList,//仓库商品库存列表
    WarehousePriceList,//仓库商品结算价列表
    WarehouseAuditList,//仓库商品结算价审核列表
    auditSelectData,//审核状态

    getOuterGoodsClassifyList,
    getOuterGoodsClassifySele,

    getGoodsConnectList,
    getGoodsConnectImport,
    getGoodsConnectGoodsState,
    getGoodsConnectDetail,

    getInventoryConnectList,
    getInventoryConnectState,

    getBalanceHintList,
    getNotBalanceHintList,
    getAutoAdjustInfo,
    ...Object.values(goodsConnectInfo.sagas),
];
