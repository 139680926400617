import React from 'react';
import CellFenToYuan from '@/vcomps/ShowCellMoney';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import styles from './TableData.module.scss';

/* eslint-disable react/display-name */
const columns = () => [
  {
    title: '支取流水号',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: '10%',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '申请日期',
    dataIndex: 'gmtCreate',
    key: 'gmtCreate',
    align: 'center',
    width: '10%',
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '支取金额',
    dataIndex: 'applyFee',
    key: 'applyFee',
    align: 'center',
    width: '10%',
    onCell: tooltipStyle,
    render: CellFenToYuan
  },
  {
    title: '个税金额',
    dataIndex: 'taxFee',
    key: 'taxFee',
    align: 'center',
    width: '10%',
    onCell: tooltipStyle,
    render: CellFenToYuan
  },
  {
    title: '手续费',
    dataIndex: 'serviceFee',
    key: 'serviceFee',
    align: 'center',
    width: '10%',
    onCell: tooltipStyle,
    render: CellFenToYuan
  },
  {
    title: '实际到账金额',
    dataIndex: 'settleFee',
    key: 'settleFee',
    align: 'center',
    width: '10%',
    onCell: tooltipStyle,
    render: CellFenToYuan
  },
  {
    title: '状态',
    dataIndex: 'withdrawState',
    key: 'withdrawState',
    align: 'center',
    width: '10%',
    onCell: tooltipStyle,
    render: (text, item) => {
    return <span>{text && text.value}</span>;
    }
  },
  {
    title: '失败原因',
    dataIndex: 'failReason',
    key: 'failReason',
    align: 'center',
    width: '10%',
    onCell: tooltipStyle,
    render: tooltip
  },
];

export const takeDataSource = (data) => {
  // 解析表格数据，使符合columns格式要求
  // let tableSource = data.map((item)=>{

  //   return tableCell;
  // });
  return data;
};



export const takeColumns = () => {
  return columns();
};

const TableCell = (text) => {
  return (
    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
      {text}
    </div>
  );
};
