import { createModel } from '@/store/tools';

const model = {
    namespace: 'purchasing',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getserviceLevelList': T('getserviceLevelList'),
            'getCollectiveLevelList': T('getCollectiveLevelList'),
        },
        actions: {
            'getserviceLevelList': A('getserviceLevelList'), // 服务商等级列表
            'getCollectiveLevelList': A('getCollectiveLevelList'),
        },
        sagas: {
            'getserviceLevelList': S('getserviceLevelList', '/service/level/list'),
            'getCollectiveLevelList': S('getCollectiveLevelList', '/marketing/activityMerchantInfo/collectiveLevel'),
        },
        reducers: {
            'serviceLevel': R('getserviceLevelList', []),
            'collectiveLevelList': R('getCollectiveLevelList', []),
        }
    })
};
export default createModel(model);