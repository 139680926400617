import * as T from './actiontypes';

// 存储奢批商品分类列表
export const getOuterGoodsClassifyList = (params = {}) => ({
    type: T.GET_OUTERGOODS_CLASSIFYLIST,
    payload: params
});

export const getOuterGoodsClassifySele = () => ({
    type: T.GET_OUTERGOODS_CLASSIFYLIST,
    payload: {}
});