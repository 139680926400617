import React from 'react';
import { Tooltip, Switch } from 'antd';
import { regFenToYuan } from '@/util/money';
import { XMediaFn } from '@/components/xqxc_ui';
import { isEmpty } from '@/assets/js/utils';
import { FloatConversion } from '@/util/conversion';

export const tooltipStyle = () => {
    return {
        style: {
            maxWidth: 100,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        }
    };
};

const preStyles = {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
};

const titleStyles = {
    display: 'inline-block',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    wordBreak: 'break-all'
};

const preStylesClick = {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    color: '#40a9ff',
    cursor: 'pointer',
};

const preStylesClickLeft = {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    color: '#40a9ff',
    cursor: 'pointer',
    textAlign: 'left',
    paddingLeft: '20px'
};

const preStylesLeft = {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    textAlign: 'left',
    paddingLeft: '20px'
};

/**
 * 文本项
 * value：文本内容
 * <pre/>预格式化标签，避免所有连续的空格或空行（换行）都会被显示为一个空格
 */
 export const tooltip = (value, align) => {
    return <Tooltip placement="top" title={<pre style={titleStyles}>{value}</pre>}><pre style={align == 'left' ? preStylesLeft : preStyles}>{isEmpty(value) ? '-' : value}</pre></Tooltip>;
};

/**
 * 文本项 - 可点击
 * value：文本内容
 * <pre/>预格式化标签，避免所有连续的空格或空行（换行）都会被显示为一个空格
 */
export const tooltipClick = (value, callback, align) => {
    return <Tooltip placement="top" title={<pre style={titleStyles}>{value}</pre>}><pre style={align == 'left' ? preStylesClickLeft : preStylesClick} onClick={callback}>{isEmpty(value) ? '-' : value}</pre></Tooltip>;
};

/**
 * 带单位的文本项
 * value：文本内容
 */
export const tooltipWithUnit = (value, unit) => {
    return <Tooltip placement="top" title={<pre style={titleStyles}>{value}</pre>}><pre style={preStyles}>{value}{unit}</pre></Tooltip>;
};

/**
 * 开关项
 * value：0关、1开
 */
export const switchCell = (value, callback) => {
    let checked = true;
    if (value == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                checkedChildren="开"
                unCheckedChildren="关"
                checked={checked}
                onChange={(value) => {
                    const result = value ? 1 : 0;
                    callback(result);
                }}
            />
        </div>
    );
};

/**
 * 金额(分转元)
 */
export const priceFenToYuanCell = (value) => {
    const price = regFenToYuan(value);
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {price}
        </div>
    );
};

/**
 * 比率(千分转百分)
 */
export const rateCell = (value) => {
    const floatConversion = new FloatConversion();

    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {tooltip(floatConversion.divide(Number(value), 10))}
        </div>
    );
};

/**
 * 图片项
 * @param {*} url 
 */
export const ImgCell = (url) => {
    return (
        <div style={{ display: 'inline-flex' }}>
            <XMediaFn
                style={{ width: '48px', height: '48px', marginRight: '0' }}
                dataType='image'
                dataSource={url}
                key={url}
            />
        </div>
    );
};

/**
 * 表格宽度3中初始规范
 */
export const cellWidth = {
    small: 100,
    normal: 150,
    time: 180,
    big: 300
};