import React, { Component } from 'react';
import styles from './index.module.scss';
import http from '@/assets/api/http';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message } from 'antd';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContnet from './components/TableContent';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/businessmanage/cityshopgoods/model';

class ShopRecGoods extends Component {

    static propTypes = {
        history: PropTypes.object, // 路由history
        getCityRecList: PropTypes.func,//获取推荐列表
        cityRecList: PropTypes.object,//推荐列表数据
    }

    static defaultProps = {
        cityRecList: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }
    }

    state = {
        goodsName: '',
        goodsNo: '',
        goodsIds: []
    }

    // 查询
    _searchHandle = (useCache) => {
        const { goodsName, goodsNo, pageNum, pageSize } = this.state;
        this.searchCache = {
            goodsName, goodsNo, isCity: 1, isShopReco: 1,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getCityRecList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ goodsName: '', goodsNo: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    _reqFunc = (url, data) => {
        http(url, data, 'POST')
            .then(res => {
                message.success(res.message);
                this.setState({ goodsIds: [] }, () => {
                    this._searchHandle('useCache');
                });
            })
            .catch(error => {
                message.error(error.message);
            });
    }

    // 表格操作
    _tableOperate = (type, params) => {
        switch (type) {
            case 'top':
                this._reqFunc('/goods/cityShopGoods/changeShopGoodsSort', { goodsId: params, sortNo: 1 });
                break;
            case 'updateSortNo':
                this._reqFunc('/goods/cityShopGoods/changeShopGoodsSort', { goodsId: params.goodsId, sortNo: params.sortNo });
                break;
            case 'goodsIds':
                this.setState({ goodsIds: params });
                break;
            default:
                break;
        }
    }

    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _pageChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { cityRecList } = this.props;
        const { goodsName, goodsNo, goodsIds } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XInput
                            inputStyle={{ width: 150 }}
                            label='货号'
                            placeholder='请输入商品货号'
                            bindThis={this}
                            bindName='goodsNo'
                            value={goodsNo}
                        />
                        <XInput
                            style={{ marginLeft: 20 }}
                            inputStyle={{ width: 150 }}
                            label='商品名称'
                            placeholder='请输入商品名称'
                            bindThis={this}
                            bindName='goodsName'
                            value={goodsName}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: 150 }}
                            label='批量取消推荐'
                            onClick={() => {
                                if (!goodsIds.length) {
                                    message.warning('请勾选商品');
                                    return;
                                }
                                if (goodsIds.length > 30) {
                                    message.warning('最多推荐30个商品');
                                    return;
                                }
                                this._reqFunc('/goods/cityShopGoods/recommendGoods', { goodsIds, operation: 'REMOVE' });
                            }} />
                    </div>
                    <TableContnet
                        pageChange={this._pageChange}
                        renderData={cityRecList}
                        goodsIds={goodsIds}
                        tableOperate={this._tableOperate} />
                </div>
            </KeepAlive>
        );
    }

}

export default connect(state => ({
    cityRecList: state.cityShopGoods.cityRecList,
}), {
    ...model.actions
})(ShopRecGoods);