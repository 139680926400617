/**
 *  集采活动审核
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

class Purchasing extends React.Component {

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value, params) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '集采活动详情',
                    path: '/home/auditmanage/purchasing/detail/'
                });
                history.push({
                    pathname: '/home/auditmanage/purchasing/detail/' + value
                });
                break;
            case 'audit':
                addOneBreadcrumbPath({
                    title: '集采活动审核',
                    path: '/home/auditmanage/purchasing/audit/'
                });
                history.push({
                    pathname: '/home/auditmanage/purchasing/audit/' + params.activityGoodsId + '/' + params.activityId,
                });
                break;
        }
    }

    render() {
        return (
            <Main updateContent={this._updateContent} match={this.props.match} history={this.props.history} />
        );
    }
}

Purchasing.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Purchasing);