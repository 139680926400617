import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

// 获取物流公司状态
export const getLogisticsState = function* () {
    yield takeEvery(T.GET_LOGISTICS_STATE, function* requestData(action) {
        try {
            let result = yield http('/enum/StateEnum', action.payload, 'POST');
            yield put({ type: T.SET_LOGISTICS_STATE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 获取物流公司列表
export const getLogisticsList = function* () {
    yield takeEvery(T.GET_LOGISTICS_LIST, function* requestData(action) {
        try {
            let result = yield http('/merchantexpress/list', action.payload, 'POST');
            yield put({ type: T.SET_LOGISTICS_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

