import React, { Component } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import styles from './LiveTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

export default class LiveTable extends Component {

    //props校验
    static propTypes = {
        onRowSelect: PropTypes.func,
        renderData: PropTypes.object.isRequired, // 表格数据源
        tableAction: PropTypes.func.isRequired, // 表格操作
        paginationChange: PropTypes.func.isRequired, // 分页操作
    };

    //表头
    _columns = () => [
        {
            title: '结算单号',
            dataIndex: 'id',
            key: 'id',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '结算金额',
            dataIndex: 'settAmout',
            key: 'settAmout',
            width: 150,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '手续费',
            dataIndex: 'feeAmout',
            key: 'feeAmout',
            width: 150,
            align: 'center',
            render: priceFenToYuanCell
        },
        {
            title: '银行卡',
            dataIndex: 'cardNo',
            key: 'cardNo',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'status.value',
            key: 'status.value',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '摘要',
            dataIndex: 'remark',
            key: 'remark',
            width: 300,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '日期',
            dataIndex: 'gmtSett',
            key: 'gmtSett',
            width: 160,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (id, record) => this._tableAction(record)
        },
    ];

    //表格操作
    _tableAction = (record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction('detail', record)}>订单明细</div>
            </div>
        );
    };

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys, selectedRows });
            this.props.tableAction('rowSelect', selectedRowKeys);
        },
    };

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    // 表格复选输入
                    rowSelection={this._rowSelection}
                    columns={this._columns()}
                    dataSource={dataList}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}