/**
 * 【发货设置】
 */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import { Form, Input, Button, message } from 'antd';
import model from '@/reducers/systemmanage/delivergoodsset/model';

const DeliverGoodsSet = ({
    form: {
        getFieldDecorator = () => { },
        validateFields = () => { }
    } = {},
    getContactsInfo,
    contactsInfo: {
        contactName = '',
        idCard = '',
        mobile = '',
        phone = '',
    } = {}
}) => {

    useEffect(() => { getContactsInfo(); }, []);

    const onSubmit = e => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                http('/merchant/cainiao/setting/person/save', { ...values }, 'POST')
                    .then(res => {
                        message.success(res.message);
                        getContactsInfo();
                    });
            }
        });
    };

    return <Form
        autoComplete='off'
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 8 }}
        labelAlign='left'
        onSubmit={onSubmit}
    >
        <Form.Item label='联系人姓名'>
            {getFieldDecorator('contactName', {
                initialValue: contactName,
                rules: [
                    { required: true, message: '请输入联系人姓名' }
                ],
            })(<Input placeholder='请输入' style={{ width: '300px' }} />)}
        </Form.Item>
        <Form.Item label='手机号'>
            {getFieldDecorator('phone', {
                initialValue: phone,
                rules: [{
                    required: true, message: '请输入手机号'
                }, {
                    pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
                    message: '请输入正确的手机号'
                }],
            })(<Input placeholder='请输入' style={{ width: '300px' }} />)}
        </Form.Item>
        <Form.Item label='身份证号码'>
            {getFieldDecorator('idCard', {
                initialValue: idCard,
                rules: [{ pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确的身份证号' }],
            })(<Input placeholder='请输入' style={{ width: '300px' }} />)}
        </Form.Item>
        <Form.Item label='固定电话'>
            {getFieldDecorator('mobile', {
                initialValue: mobile,
                rules: [],
            })(<Input placeholder='请输入' style={{ width: '300px' }} />)}
        </Form.Item>
        <Form.Item>
            <Button style={{ width: '90px' }} type='primary' htmlType='submit'>保存</Button>
        </Form.Item>
    </Form>;
};
DeliverGoodsSet.propTypes = {
    history: PropTypes.object,
    form: PropTypes.object, // 表单校验
    getContactsInfo: PropTypes.func,
    contactsInfo: PropTypes.object
};
export default connect(state => ({
    contactsInfo: state.delivergoodsset.contactsInfo
}), {
    ...model.actions
})(Form.create({ name: 'deliverGoodsSet' })(DeliverGoodsSet));
