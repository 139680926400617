import { createModel } from '@/store/tools';

const List = {
    dataList: [],
    pageNum: 1,
    pageSize: 30,
    resultTotal: 0
};

const model = {
    namespace: 'supplierDeliveryOrder',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getSupplierDeliveryOrderData': T('getSupplierDeliveryOrderData'),
            'getSupplierDeliveryOrderInfo': T('getSupplierDeliveryOrderInfo'),
            'getSupplierDeliveryOrderEnum': T('getSupplierDeliveryOrderEnum')
        },
        actions: {
            'getSupplierDeliveryOrderData': A('getSupplierDeliveryOrderData'),
            'getSupplierDeliveryOrderInfo': A('getSupplierDeliveryOrderInfo'),
            'getSupplierDeliveryOrderEnum': A('getSupplierDeliveryOrderEnum'),
        },
        sagas: {
            'getSupplierDeliveryOrderData': S('getSupplierDeliveryOrderData', '/third/salestock/list'),
            'getSupplierDeliveryOrderInfo': S('getSupplierDeliveryOrderInfo', '/third/salestock/detail', 'POST', 'id'),
            'getSupplierDeliveryOrderEnum': S('getSupplierDeliveryOrderEnum', '/enum/OutboundStatusEnum'),
        },
        reducers: {
            'supplierDeliveryOrderData': R('getSupplierDeliveryOrderData', List),
            'supplierDeliveryOrderInfo': R('getSupplierDeliveryOrderInfo', { goodsList: [], operaLogList: [], invokeLogList: [] }),
            'supplierDeliveryOrderEnum': R('getSupplierDeliveryOrderEnum', []),
        }
    })
};
export default createModel(model);