import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, message, Modal } from 'antd';
import styles from './index.module.scss';
import http from '@/assets/api/http';

const InvoiceModal = ({ data = {}, closeModal }) => {

    const [loading, setLoading] = useState(false);
    const [invoiceUrl, setInvoiceUrl] = useState(data.invoiceUrl);

    const onSubmit = () => {
        setLoading(true);
        http('/merchant/orderInvoice/update', { orderId: data.orderId, invoiceUrl }, 'POST')
            .then(res => { message.success(res.message); setLoading(false); closeModal(); })
            .catch(err => { setLoading(false); message.error(err.message); });
    };

    return <Modal width={560} centered title={data.title} visible={true} confirmLoading={loading}
        onCancel={() => closeModal()} destroyOnClose={true} keyboard={true} maskClosable={false}
        cancelText='关闭' okText='确认' onOk={onSubmit} bodyStyle={{ paddingBottom: '10px' }}
    >
        <div className={styles.modalContainer}>
            <div className={styles.row}>
                <div className={styles.label}>订单编号：</div>
                <div className={styles.other}>{data.orderId}</div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>发票下载地址：</div>
                <Input className={styles.other} placeholder='请输入发票下载地址' value={invoiceUrl} onChange={e => setInvoiceUrl(e.target.value)} />
            </div>
        </div>
    </Modal>;
};
InvoiceModal.propTypes = {
    data: PropTypes.object,
    closeModal: PropTypes.func,
};
export default InvoiceModal;