/**
 * 代言任务数据
 */
import React from 'react';
import { Modal, Slider, Alert } from 'antd';
import { connect } from 'react-redux';
import { XSelect, XOKButton, XCancelButton, XInput } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import styles from './ActivityDate.module.scss';
import ActivityDateTable from '../components/ActivityDateTable';
import OnlinePlayModal from './OnlinePlayModal';
import model from '@/reducers/marketingmanage/advocacyMerchants/model';
import http from '@/assets/api/http';
import { message } from 'antd';

const marks = {
    0: {
        style: {
            color: '#f50',
        },
        label: <strong>0%</strong>,
    },
    100: {
        style: {
            color: '#f50',
        },
        label: <strong>100%</strong>,
    },
};

class ActivityDate extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        person: '',//领取人
        type: {},//类型
        pageNum: 1,
        pageSize: 10,
        goodsList: [],
        inputserviceValue: this.props.data.receiveNumPercentage,//领取数量
        inputfeesValue: this.props.data.completeNumPercentage,//完成数量
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
        taskId: this.props.data.id,//代言任务id
    }

    componentDidMount() {
        const { getReceiveState } = this.props;
        getReceiveState();
        this._searchHandle();
    }

    componentWillUnmount() {//清除数据渲染
        for (const key in this.props.receiveDetail) {
            delete this.props.receiveDetail[key];
        }
    }

    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    //自定义提示
    formatter = (value) => {
        return `${value}%`;
    }

    _tableAction = (id, type, record) => {
        if (type == 'works') {
            const { bizId, executionType } = record;
            http('/show/getUrl', {
                id: bizId,
                worksType: executionType
            }, 'POST')
                .then((res) => {
                    this.setState({
                        currentOpenMode: 'works',
                        openModeParam: { ...res, executionType }
                    });
                }).catch((reject = {}) => {
                    message.error(reject.message);
                });
        }
    }

    // 打开弹窗  
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'works':
                return <OnlinePlayModal visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        const { person, type } = this.state;
        this.searchCache = {
            ...this.searchCache,
            // receiveId: person,//领取人  	  
            state: type.code,//状态  
            pageNum,
            pageSize,
        };
        this.setState({
            pageSize, pageNum
        });
        this.props.getReceiveDetail(this.searchCache);
    }

    // 查询触发  
    _searchHandle = () => {
        const { person, type, pageNum, pageSize, taskId } = this.state;
        this.searchCache = {
            spokesmanTaskConfigId: taskId, //代言任务id
            // receiveId: person,//领取人  	  
            state: type.code,//状态  
            pageNum,
            pageSize,
        };
        this.props.getReceiveDetail(this.searchCache);
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ type: {}, person: '' }, () => {
            const { type, person } = this.state;
            this.searchCache = { ...this.searchCache, state: type.code, };
        });
    }

    render() {
        const { visible, title, confirmLoading, person, type, inputserviceValue, inputfeesValue, } = this.state;
        const { data, receiveDetail, receiveState } = this.props;

        return (
            <Modal
                width={860}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                onOk={this._handleOk}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                footer={null}
            >
                <div className={styles.container}>
                    <div className={styles.alert}><Alert message='任务未完成、审核未通过，信用值将退回您的信用值账户。' type='warning' showIcon /></div>
                    <div className={`${styles.layout} ${styles.dataTop}`}>
                        <div className={`${styles.layout}`}>
                            <div><h4>任务数量：</h4><span>{data.publishNum}单</span></div>
                            <div><h4>奖励总量：</h4><span>{data.totalRewardNum}信用值</span></div>
                            <div><h4>剩余信用值：</h4><span>{data.leftTotalRewardNum}</span></div>
                        </div>
                    </div>
                    <div className={`${styles.layout} ${styles.rangemark}`}>
                        <p>领取数量：</p>
                        <div className={styles.left}>
                            <Slider
                                marks={marks}
                                min={0}
                                max={100}
                                tipFormatter={this.formatter}
                                // tooltipVisible 
                                disabled
                                defaultValue={typeof inputserviceValue === 'number' ? inputserviceValue : 0}
                            />
                        </div>
                        <div className={styles.right}>领取率：{inputserviceValue}%，共{data.receiveNum}人领取了任务</div>
                    </div>
                    <div className={`${styles.layout} ${styles.rangemark}`}>
                        <p>完成数量：</p>
                        <div className={styles.left}>
                            <Slider
                                marks={marks}
                                min={0}
                                max={100}
                                tipFormatter={this.formatter}
                                // tooltipVisible 
                                disabled
                                defaultValue={typeof inputfeesValue === 'number' ? inputfeesValue : 0}
                            />
                        </div>
                        <div className={styles.right}>完成率：{inputfeesValue}%，共{data.completeNum}人完成任务</div>
                    </div>
                    <div className={styles.top_title}>领取详情</div>
                    <div className={styles.search_box}>
                        {/* <XInput
                            inputStyle={{ width: '150px' }}
                            label='领取人'
                            placeholder='请输入'
                            value={person}
                            bindThis={this}
                            bindName='person'
                        /> */}
                        <XSelect
                            // style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '150px' }}
                            label='类型'
                            placeholder='请选择'
                            renderData={receiveState}
                            dataIndex='name'
                            keyIndex='code'
                            value={type.name}
                            bindThis={this}
                            bindName='type'
                            showSearch={true}
                        />
                        <XOKButton
                            style={{ marginLeft: '30px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '30px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <ActivityDateTable tableAction={this._tableAction} paginationChange={this._paginationChange} renderData={receiveDetail} />
                    {this._renderMode()}
                </div>
            </Modal >
        );
    }
}

ActivityDate.propTypes = {
    title: PropTypes.string,// 弹框标题
    data: PropTypes.object, // 数组
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数

    getReceiveDetail: PropTypes.func,//列表数据(action)
    receiveDetail: PropTypes.object,
    getReceiveState: PropTypes.func,//领取详情列表查询状态(action)
    receiveState: PropTypes.array,

};

const mapStateToProps = (state) => ({
    receiveDetail: state.advocacyMerchants.receiveDetail, // 领取详情列表数据
    receiveState: state.advocacyMerchants.receiveState, // 领取详情列表查询状态数据
});

export default connect(mapStateToProps, { ...model.actions })(ActivityDate);