import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Pagination, InputNumber } from 'antd';
import styles from './index.module.scss';


const AutoPaginationSource = (Wraper, settings) => {
    class target extends React.PureComponent {
        state = {
            pageSize: 10, // 每页显示多少条
            pageNum: 1, // 当前第几页
            jumpPage: 1,
            conditions: {},
            realConditions: {}
        }

        componentDidMount() {
            if (this.props.initConditions) {
                this.updateConditions(this.props.initConditions, { updateData: true });
            } else {
                this.props.isRequestPageData && this._updatePageData();
            }
        }

        // 更新表格数据
        _updatePageData = (callback) => {
            let { pageSize, pageNum, realConditions } = this.state;
            this.props.paginationAction({ pageSize, pageNum, ...realConditions }, settings.sagaProps);
        }

        // 更新搜索条件
        updateConditions = (changes, otherProps = {}) => {
            const { conditions } = this.state;
            let newState = {}, newConditions = { ...conditions, ...changes };
            if (otherProps.clearConditions) {
                newState.conditions = {};
                newState.realConditions = {};

                if (otherProps.keepConditions) {
                    otherProps.keepConditions.forEach(cond => {
                        newState.conditions[cond] = newConditions[cond];
                        newState.realConditions[cond] = newConditions[cond];
                    });
                }
            } else {
                newState.conditions = newConditions;
                if (otherProps.updateData) {
                    newState.realConditions = {};
                    Object.keys(newState.conditions).filter(k => !k.startsWith('__')).forEach(k => newState.realConditions[k] = newState.conditions[k]);
                }
            }
            this.setState(newState, () => {
                if (otherProps.updateData) {
                    this._updatePageData(otherProps.callback);
                }
                newState.realConditions && this.props.updateConditionCallBack && this.props.updateConditionCallBack(newState.conditions);
            });
        }

        // ========== 通用分页修改操作 ==========

        // 每页显示多少条输入框修改时回调，此时还未请求新数据直至输入框失去焦点
        pageSizeChange = (pageSize) => {
            this.setState({ pageSize });
        }

        // 每页显示多少条输入框失去焦点回调（请求新数据）
        pageSizeBlur = (e, callback) => {
            this._updatePageData(callback);
        }

        // 跳到第N页值改变时触发，此时还未请求新数据
        jumpToChange = (jumpPage) => {
            this.setState({ jumpPage });
        }

        // 跳到第N页值失去焦点时触发，开始请求新数据
        jumpToBlur = (e, callback) => {
            this.setState({ pageNum: this.state.jumpPage }, () => {
                this._updatePageData(callback);
            });
        }

        // 点击页面下标切换页面回调（请求新数据）
        onPaginationChange = (pageNum, pageSize, callback) => {
            this.setState({ pageNum }, () => {
                this._updatePageData(callback);
            });
        }

        render() {
            const { conditions, ...otherProps } = this.state;
            const paginationProps = {
                ...conditions,
                ...otherProps,
                pageSizeChange: this.pageSizeChange,
                pageSizeBlur: this.pageSizeBlur,
                jumpToChange: this.jumpToChange,
                jumpToBlur: this.jumpToBlur,
                onPaginationChange: this.onPaginationChange,
                updateConditions: this.updateConditions,
                updatePageData: this._updatePageData
            };
            return <Wraper
                paginationProps={paginationProps}
                {...this.props}
            />;
        }
    }

    target.propTypes = {
        initConditions: PropTypes.object,
        updateConditionCallBack: PropTypes.func,
        isRequestPageData: PropTypes.bool,
        pageTableFields: PropTypes.object,
        paginationAction: PropTypes.func
    };
    return connect(settings.mapStateToProps, { ...settings.mapDispatchToProps })(target);
};


class PaginationModule extends React.PureComponent {

    state = {
        resultTotal: 100000
    }

    render() {
        const { pageSize, pageNum, jumpPage, pageSizeBlur, onPaginationChange, pageSizeChange, jumpToChange, jumpToBlur } = this.props;
        const { resultTotal } = this.state;
        return (
            <div className={styles.container}>
                <div className={styles.pagecount}>
                    {/* 共
                    <div className={styles.total}>{resultTotal}</div>
                    条， */}
                    每页
                    <div className={styles.pagesize}>
                        <InputNumber min={1} max={50} value={pageSize} onChange={pageSizeChange} onBlur={(value) => {
                            if (value > resultTotal) {
                                this.setState({ resultTotal: resultTotal + 100000 });
                            }
                            pageSizeBlur(value);
                        }} />
                    </div>
                    条
                </div>
                <div className={styles.pageaction}>
                    <div className={styles.pagejump}>
                        到第
                        <InputNumber min={1} value={jumpPage} onChange={jumpToChange} onBlur={(value) => {
                            if (value > resultTotal) {
                                this.setState({ resultTotal: resultTotal + 100000 });
                            }
                            jumpToBlur(value);
                        }} />
                        页
                    </div>
                    <div className={styles.pagination}>
                        <Pagination defaultCurrent={1} current={pageNum} total={resultTotal} pageSize={pageSize} onChange={(pageNum, pageSize) => {
                            if (pageNum >= (resultTotal / pageSize) - 10) {
                                this.setState({ resultTotal: resultTotal + 100000 });
                            }
                            onPaginationChange(pageNum, pageSize);
                        }} />
                    </div>
                </div>
            </div>
        );
    }

}

PaginationModule.defaultProps = {
    resultTotal: 0,
    pageSize: 10,
    pageNum: 1
};

PaginationModule.propTypes = {
    resultTotal: PropTypes.number, // 总条数（双向绑定）
    pageSize: PropTypes.number, // 每页显示多少条（双向绑定）
    pageNum: PropTypes.number, // 当前第几页（双向绑定）
    jumpPage: PropTypes.number, // 跳到第N页值
    pageSizeChange: PropTypes.func, // 每页显示多少条输入框修改时回调，此时还未请求新数据直至输入框失去焦点
    pageSizeBlur: PropTypes.func, // 每页显示多少条输入框失去焦点时回调，并请求新数据
    jumpToChange: PropTypes.func, // 跳到第N页值失去焦点时触发，并请求新数据
    jumpToBlur: PropTypes.func,
    onPaginationChange: PropTypes.func // 点击页面下标切换页面回调，并请求新数据
};

export { PaginationModule as default, AutoPaginationSource };