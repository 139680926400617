/* eslint-disable no-unused-vars */
/**
 * 【商品对接页】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { isYG, isFenLai, isHouNiao } from '@/assets/js/authType';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XInput, XSelect, XOKButton, XCancelButton, XTextArea } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import ProductClassifyModal from '../../modal/ProductClassifyModal';
import { getGoodsConnectList, getGoodsConnectImport, getGoodsConnectGoodsState } from '@/reducers/warehousemanage/goodsConnect/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum } from '@/assets/config';
import SPDModal from './modal/SPDModal';
import VisitantModal from './modal/VisitantModal';

// 弹框枚举类型
const Mode = {
    PRODUCT_DOWNLOAD: 'PRODUCT_DOWNLOAD', // 选择商品分类弹框
};

class Main extends React.Component {

    static defaultProps = {
        goodsConnectList: { dataList: [], pageNum: 0, pageSize: 30, resultTotal: 0 }
    }

    constructor(props) {
        super(props);
        this.state = {
            goodsName: '',//商品名称
            outerGoodsIds: '', // 商品规格ID
            importState: {}, // 导入状态
            isSale: {}, // 是否上架
            // deleteState: {}, // 删除状态
            currentMode: '', // 当前弹框
            modeParam: '', // 弹框入参
            batch: [],
            categoryName: '',
            goodsNos: '',
            pageSize: 100,
            barcode: ''
        };
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getGoodsConnectGoodsState();
        this.props.getGoodsConnectImport();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, goodsName, outerGoodsIds, importState, isSale, categoryName, goodsNos, barcode } = this.state;
        this.searchCache = {
            goodsNos: goodsNos.replace(/，/g, ','),
            goodsName,
            outerGoodsIds: outerGoodsIds.replace(/，/g, ','),
            goodsState: isSale.code,
            importState: importState.code,
            categoryName, barcode,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : 100 // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getGoodsConnectList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ goodsName: '', outerGoodsIds: '', importState: {}, isSale: {}, categoryName: '', goodsNos: '', barcode: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //请求函数
    _sendReq = (url, data, winText) => {
        http(url, data, 'POST')
            .then(() => {
                message.success(winText);
                this._searchHandle('useCache');
            })
            .catch(error => {
                message.error(error.message);
            });
    }

    // 下载奢批商品
    _downloadHandle = () => {
        console.log(isYG());
        if (isYG()) {
            //蚁购，选择蚁购分类
            this.setState({ currentMode: Mode.PRODUCT_DOWNLOAD });
        } else {
            this.setState({ currentMode: 'spd' });
            // this.setState({ currentMode: 'visitant' });
        }

    }

    // 导入仓库商品列表
    _importHandle = () => {
        const { batch } = this.state;
        this._sendReq('/goods/outer/importOuterGoods', { ids: batch }, '导入成功。');
    }

    _jumpPage = (title, url) => {
        const { history, addOneBreadcrumbPath } = this.props;
        const baseUrl = '/home/supplierwarehouse/goodsconnect';
        addOneBreadcrumbPath({ title, path: `${baseUrl}${url}` });
        history.push({ pathname: `${baseUrl}${url}` });
    }

    // 表格操作
    _tableAction = (key, type, params) => {
        switch (type) {
            case 'sku':
                isHouNiao() ?
                    this._jumpPage('SKU详情', `/visitantInfo/${key}`) :
                    this._jumpPage('SKU详情', `/goodsinfo/${key}`);
                break;
            case 'import':
                this._sendReq('/goods/outer/importOuterGoods', { ids: [key] }, '操作成功，商品导入中！');
                break;
            case 'delete':
                this._sendReq('/goods/outer/delOuterGoods', { ids: [key] }, '删除成功。');
                break;
            case 'batch':
                this.setState({ batch: params });
                break;
        }
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 渲染弹框
    _renderMode = () => {
        const { currentMode, modeParam } = this.state;
        switch (currentMode) {
            case Mode.PRODUCT_DOWNLOAD:
                return <ProductClassifyModal visible={true} closeMode={this._closeMode} modeParam={modeParam} />;
            case 'spd':
                return <SPDModal closeMode={this._closeMode} />;
            case 'visitant':
                return <VisitantModal closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (type, productCascades, prodCascade, key) => {
        this.setState({ currentMode: '' });
        switch (type) {
            case 'ok':
                this._sendReq('/goods/outer/downloadOutGoods', { paramJson: `{ category: ${prodCascade} }` }, '下载成功。');
                break;
        }
    }

    //更新仓库商品
    _updateGoods = () => {
        const { batch } = this.state;
        if (!batch.length) {
            message.warning('请勾选商品');
            return;
        }
        if (batch.length > 10) {
            message.warning('更新商品信息不能超过10个商品');
            return;
        }
        this._sendReq('/goods/outer/updateOuterGoods', { outerGoodsIds: batch }, '更新仓库商品成功');
    }

    render() {
        const { goodsConnectList, goodsConnectImport, goodsConnectState } = this.props;
        const { goodsName, outerGoodsIds, categoryName, importState, isSale, goodsNos, batch, barcode } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle(''); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria1}>
                        <XTextArea
                            label='货号集合'
                            inputStyle={{ width: '300px' }}
                            placeholder='请输入货号，多个逗号隔开,最多100个'
                            value={goodsNos}
                            rows={1}
                            onChange={value => {
                                if (value.replace(/，/g, ',').split(',').length < 100) {
                                    this.setState({ goodsNos: value });
                                } else {
                                    message.warning('货号集合最多100个');
                                }
                            }}
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '200px' }}
                            label='货品名称'
                            placeholder='请输入货品名称'
                            value={goodsName}
                            bindThis={this}
                            bindName='goodsName'
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '200px' }}
                            label='商品分类'
                            placeholder='请输入商品分类'
                            value={categoryName}
                            bindThis={this}
                            bindName='categoryName'
                        />
                        <XOKButton style={{ marginLeft: '20px', width: '60px' }} label='查询' onClick={this._searchHandle} />
                        <XCancelButton style={{ marginLeft: '20px', width: '60px' }} label='重置' onClick={this._resetHandle} />
                    </div>
                    <div className={styles.searchCriteria1} style={{ marginTop: '10px' }}>
                        <XTextArea
                            label='货品ID集合'
                            inputStyle={{ width: '300px' }}
                            placeholder='请输入货品ID，多个逗号隔开,最多100个'
                            value={outerGoodsIds}
                            rows={1}
                            onChange={value => {
                                if (value.replace(/，/g, ',').split(',').length < 100) {
                                    this.setState({ outerGoodsIds: value });
                                } else {
                                    message.warning('货品ID最多100个');
                                }
                            }}
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '200px' }}
                            label='导入状态'
                            placeholder='请选择导入状态'
                            renderData={goodsConnectImport}
                            dataIndex='value'
                            keyIndex='code'
                            value={importState.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='importState'
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '200px' }}
                            label='上架状态'
                            placeholder='请选择上架状态'
                            renderData={goodsConnectState}
                            dataIndex='value'
                            keyIndex='code'
                            value={isSale.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='isSale'
                        />
                        {
                            isHouNiao() && <XInput
                                style={{ marginLeft: '20px' }}
                                inputStyle={{ width: '200px' }}
                                label='商品条码'
                                placeholder='请输入货品条码'
                                value={barcode}
                                bindThis={this}
                                bindName='barcode'
                            />
                        }
                        {/* <XDatePicker
                        style={{ width: '264px', marginLeft: '20px' }}
                        label='更新时间'
                        value={orderStartTime}
                        bindThis={this}
                        bindName='orderStartTime'
                    />
                    <div className={styles.timeLine} />
                    <XDatePicker
                        label=''
                        value={orderEndTime}
                        bindThis={this}
                        bindName='orderEndTime'
                    /> */}
                    </div>
                    {/* <div className={styles.searchCriteria2}>
                    <XInput
                        style={{ width: '250px', marginLeft: '14px' }}
                        inputStyle={{ width: '200px' }}
                        label='品牌名称'
                        placeholder='请输入品牌名称'
                        value={bardName}
                        bindThis={this}
                        bindName='bardName'
                    />
                    <XSelect
                        style={{ width: '248px' }}
                        selectStyle={{ width: '200px' }}
                        label='上架状态'
                        placeholder='请选择上架状态'
                        renderData={goodsConnectState}
                        dataIndex='value'
                        keyIndex='code'
                        value={isSale.value}
                        showSearch={true}
                        bindThis={this}
                        bindName='isSale'
                    />
                    <XSelect
                        style={{ marginLeft: '20px', width: '248px' }}
                        selectStyle={{ width: '200px' }}
                        label='删除状态'
                        placeholder='请选择删除状态'
                        renderData={orderStateSelect}
                        dataIndex='name'
                        keyIndex='id'
                        value={deleteState.name}
                        showSearch={true}
                        bindThis={this}
                        bindName='deleteState'
                    />
                    <XOKButton style={{ marginLeft: '20px' }} label='查询' onClick={this._searchHandle} />
                    <XCancelButton style={{ marginLeft: '10px' }} label='重置' onClick={this._resetHandle} />
                </div> */}
                    <div className={styles.export}>
                        {
                            !isFenLai() && !isHouNiao() && <>
                                <XOKButton label='下载商品' style={{ marginRight: '20px' }} onClick={this._downloadHandle} />
                                <XOKButton label='更新仓库商品' style={{ marginRight: '20px' }} onClick={this._updateGoods} />
                            </>

                        }
                        {
                            isHouNiao() && <XOKButton label='下载供应商商品' style={{ marginRight: '20px' }} onClick={() => this.setState({ currentMode: 'visitant' })} />
                        }
                        <XOKButton label='导入仓库商品列表' onClick={this._importHandle} />
                    </div>
                    <TableContent
                        renderData={goodsConnectList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                        batch={batch}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getGoodsConnectList: PropTypes.func,
    getGoodsConnectImport: PropTypes.func,
    getGoodsConnectGoodsState: PropTypes.func,

    goodsConnectList: PropTypes.object,
    goodsConnectImport: PropTypes.array,
    goodsConnectState: PropTypes.array
};

const mapStateToProps = (state) => ({
    goodsConnectList: state.goodsConnect.goodsConnectList,
    goodsConnectImport: state.goodsConnect.goodsConnectImport,
    goodsConnectState: state.goodsConnect.goodsConnectState
});

const allReq = { getGoodsConnectList, getGoodsConnectImport, getGoodsConnectGoodsState, addOneBreadcrumbPath, keepSecondNavBreadcrumb };
export default connect(mapStateToProps, allReq)(Main);