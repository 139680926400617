/**
 *  店铺管理 - 店铺列表
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, message, } from 'antd';
import http from '@/assets/api/http';
import ClosedModal from '../../modal/ClosedModal';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import * as actions from '@/reducers/businessmanage/merchantlist/actions';
import { setLocalStorage, setSessionStorage } from '@/assets/js/storage';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

const Mode = {
    MER_CLOSED: 'MER_CLOSED', // 停业
    MER_EDITOR: 'MER_EDITOR', //编辑
    MER_STOR: 'MER_STOR', //进入店铺
    MER_DELETE: 'MER_DELETE' //删除
};

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectDataSource: [],//模拟下拉列数据
            goodsDataSource: [],// 模拟表格数据
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
            saleState: {}, // 状态
            saleNames: '', // 商户名称,
            pageSize: 20,//默认
            pageNum: 1,//默认
        };
    }

    componentDidMount() {
        const { getmerchantselectData, } = this.props;
        getmerchantselectData();
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.merchantListTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 状态
    _saleChangeHandle = (value) => {
        this.setState({ saleState: value });
    }
    // 商户名称
    _saleItemChangeHandle = (value) => {
        this.setState({ saleNames: value });
    }

    // 查询触发
    _saleSearchHandle = (useCache) => {
        const { saleNames, saleState, pageSize, pageNum, } = this.state;
        this.searchCache = {
            shopName: saleNames,//商户名称  
            shopState: saleState.code,//状态  
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.merchantListTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _saleresetHandle = () => {
        this.setState({ saleState: {}, saleNames: '' }, () => {
            KeepAlive.saveResetFunc(this._saleSearchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 新建按钮被点击
    _newCreateHandle = () => {
        this.props.updateContent('NEWCREATE');
    }

    // 表格操作功能回调
    _actionClickHandle = (id, item, type) => {
        const { updateContent, merchantListTableSource } = this.props;
        switch (type) {
            case Mode.MER_CLOSED: //停业
                this.setState({
                    currentOpenMode: Mode.MER_CLOSED,
                });
                break;
            case Mode.MER_EDITOR: //编辑
                updateContent('MER_EDITOR', id);
                break;
            case 'switch': //表格状态
                http('/shop/update/state', {
                    id: item.id,
                    merchantId: '',
                    shopState: id == '1' ? 0 : 1,
                }, 'POST').then(() => {
                    message.success('状态修改成功');
                    merchantListTableSource(this.searchCache);// 重新渲染更新列表
                }).catch((reject) => {
                    message.error(reject.message);
                });
                break;
            case Mode.MER_DELETE: //删除
                http('/shop/del', {
                    id: item.id,
                    merchantId: '',
                }, 'POST').then(() => {
                    message.success('删除成功');
                    merchantListTableSource(this.searchCache);// 重新渲染更新列表
                }).catch((reject) => {
                    message.error(reject.message);
                });
                break;
            case Mode.MER_STOR: // 进入店铺
                var tempwin = window.open('about:blank', 'SP');
                http('/admin/empl/quickLogin', {
                    accountType: 'SP',
                    sourceId: id,
                }, 'POST').then((response) => {
                    setLocalStorage('auth', response.result, 'SP');
                    setSessionStorage('cityshoppreviewh5id', item.id, 'SP');
                    tempwin.location = '/#/home';
                }).catch((e) => {
                    message.error(e.message);
                    tempwin.close();
                });
                break;
        }
    }

    // 打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.MER_CLOSED:
                return <ClosedModal title='商户停业确认' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }

    render() {
        const { tableSource, paginations, selectData } = this.props;
        const { saleState, saleNames, } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._saleSearchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.pro_commont}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='店铺名称'
                            placeholder='请输入店铺名称'
                            onChange={this._saleItemChangeHandle}
                            value={saleNames}
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '200px' }}
                            label='店铺状态'
                            placeholder='请选择'
                            renderData={selectData}
                            onChange={this._saleChangeHandle}
                            dataIndex='value'
                            keyIndex='code'
                            value={saleState.value}
                            showSearch={true}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._saleSearchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._saleresetHandle}
                        />
                    </div>
                    {/* 
                        <div className={styles.operate_box}>
                            <XOKButton style={{ width: '80px' }} label='创建店铺' onClick={this._newCreateHandle} />
                        </div> 
                    */}
                    <div className={styles.tableContainer}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowKey={(record, index) => index}
                            columns={takeColumns(this._actionClickHandle)}
                            dataSource={takeDataSource(tableSource)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={paginations.resultTotal}
                            pageSize={paginations.pageSize}
                            pageNum={paginations.pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    merchantListTableSource: PropTypes.func, // 通过Saga异步请求表格数据源的Action
    getmerchantselectData: PropTypes.func,// 通过Saga异步请求下拉选项数据方法Action
    selectData: PropTypes.array,
    paginations: PropTypes.object,//fenye 
    tableSource: PropTypes.array,//店铺列表数据
};


const mapStateToProps = (state) => {
    return {
        tableSource: state.merchantlist.merchantDate.tableSource, // 表格数据源
        selectData: state.merchantlist.merchantDate.selectData, // 状态下拉选项
        paginations: state.merchantlist.merchantDate.paginations, // 分页数据
    };
};

export default connect(mapStateToProps, actions)(Main);