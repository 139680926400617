import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { message } from 'antd';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableData from './components/TableData';
import CreateEdit from '../../modal/CreateEdit';
import { getProductLabel, getProductLabelDetail } from '@/reducers/productmanage/labellist/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
class ProductLabellist extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: '', // 当前需要打开的弹窗的入参
        labelName: '',
        labelInfo: {},
        ids: []
    }

    // 查询
    _searchHandle = (useCache) => {
        const { labelName, pageNum, pageSize } = this.state;
        this.searchCache = {
            tagName: labelName,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getProductLabel(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ labelName: '', }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //创建标签
    _openCreateModal = () => {
        this.setState({ currentOpenMode: 'CREAT' });
    }
    _detaleReq = (url, data, winTxt) => {
        http(url, data, 'POST')
            .then(() => {
                message.success(winTxt);
                const { labelName, pageNum, pageSize } = this.state;
                this.props.getProductLabel({ tagName: labelName, pageNum, pageSize });
            }).catch((e) => { message.error(e.message); });
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'edit':
                http('/goods/tag/infoTag', { id }, 'POST')
                    .then((resole) => {
                        this.setState({ currentOpenMode: 'EDIT', labelInfo: resole.result });
                    }).catch(() => { message.error('请求失败！'); });
                break;
            case 'delete':
                this._detaleReq('/goods/tag/delTag', { ids: [id] }, '删除成功。', '删除失败！');
                break;
            case 'batch':
                console.log(id, type, params);
                this.setState({ ids: params });
                break;
        }
    }

    _batchDelete = () => {
        const { ids } = this.state;
        if (ids.length === 0) {
            message.error('删除标签数量不能为零');
            return;
        }
        this._detaleReq('/goods/tag/delTag', { ids }, '批量删除成功。', '批量删除失败！');
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { labelInfo } = this.state;
        switch (this.state.currentOpenMode) {
            case 'CREAT':
                return <CreateEdit title='创建商品标签' visible={true}
                    data={{ action: 'create', url: '/goods/tag/addTag', labelInfo: {} }}
                    closeMode={this._closeMode} />;
            case 'EDIT':
                return <CreateEdit title='编辑商品标签' visible={true}
                    data={{ action: 'edit', url: '/goods/tag/editTag', labelInfo }}
                    closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        const { labelName, pageNum, pageSize } = this.state;
        update ? this.props.getProductLabel({ tagName: labelName, pageNum, pageSize }) : null;
        this.setState({ currentOpenMode: '' });
    }

    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        const { labelName } = this.state;
        this.props.getProductLabel({ tagName: labelName, pageNum, pageSize });
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { labelName } = this.state;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <XInput
                            style={{ width: '266px' }}
                            inputStyle={{ width: '200px' }}
                            label='商品标签'
                            placeholder='请输入商品标签'
                            bindThis={this}
                            bindName='labelName'
                            value={labelName}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '90px' }} label='创建标签' onClick={this._openCreateModal} />
                        <XOKButton style={{ width: '90px', marginLeft: '10px' }} label='批量删除' onClick={this._batchDelete} />
                    </div>
                    <TableData paginationChange={this._paginationChange} renderData={this.props.productLabel} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }

}
ProductLabellist.propTypes = {
    getProductLabel: PropTypes.func,
    getProductLabelDetail: PropTypes.func,
    productLabel: PropTypes.object,
    productLabelDetail: PropTypes.object

};
const mapStateToProps = (state) => ({
    productLabel: state.productLabelData.productLabel,
    productLabelDetail: state.productLabelData.productLabelDetail,
});
export default connect(mapStateToProps, { getProductLabel, getProductLabelDetail })(ProductLabellist);