/**
 * 【蚁购对接参数设置创建页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, Form, Button, message, Radio, InputNumber, Row } from 'antd';
import styles from './index.module.scss';
import { XCancelButton } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { getLocalStorage } from '@/assets/js/storage';

const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const RadioGroup = Radio.Group;
class addFinancialContent extends React.Component {
    state = {
        openDock: 1,
        //----------
        isNew: true, // 是否为新建
        id: '', // 配置ID
        paramFlag: true,
        apiUrl: '',
        configKey: '',
        configValue: '',
        appKey: '',
        appSecret: '',
        autoProductTime: '',
        remark: '',
        autoInventoryTime: '',
        autoQueryOrderTime: '',
        autoOrderTime: '',
    }
    componentDidMount() {
        this._init();
    }

    // 初始化信息
    _init = () => {
        http('/thirdpaty/query', {}, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result && response.result.id != '') {
                    const { apiUrl, appKey, appSecret, configKey, configValue, autoProductTime, autoInventoryTime, autoQueryOrderTime, autoOrderTime, remark, openDock, id } = response.result;
                    this.setState({
                        isNew: false,
                        id,
                        apiUrl,
                        appKey,
                        appSecret,
                        configKey,
                        configValue,
                        autoProductTime,
                        autoInventoryTime,
                        autoQueryOrderTime,
                        autoOrderTime,
                        remark,
                        openDock,
                        paramFlag: openDock === 1 ? true : false,
                    });
                } else {
                    this.setState({ isNew: true });
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 重置
    _resetHandle = () => {
        this.props.form.resetFields();
        this.setState({
            openDock: 1,
            paramFlag: true,
            apiUrl: '',
            configKey: '',
            configValue: '',
            appKey: '',
            appSecret: '',
            autoProductTime: '',
            remark: '',
            autoInventoryTime: '',
            autoQueryOrderTime: '',
            autoOrderTime: '',
        });
    }

    // 保存
    _handleSubmit = () => {
        const { isNew, id } = this.state;
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                var param = {};
                if (fieldsValue.openDock === 1) {
                    param = {
                        ...fieldsValue, appSessionKey: fieldsValue.appSecret
                    };
                } else {
                    param = {
                        ...fieldsValue
                    };
                }
                if (isNew) {
                    http('/thirdpaty/save', param, 'POST').then((response) => {
                        if (response.status == 200) {
                            message.success('创建成功');
                            this._init();
                        }
                    }).catch((e) => {
                        message.error(e.message);
                    });
                } else {
                    http('/thirdpaty/update', { ...param, id }, 'POST').then((response) => {
                        if (response.status == 200) {
                            message.success('编辑成功');
                        }
                    }).catch((e) => {
                        message.error(e.message);
                    });
                }
            }
        });

    }
    // 开启
    openDockFun = e => {
        var val = e.target.value;
        if (val === 1) {
            this.setState({ paramFlag: true });
        } else {
            this.setState({ paramFlag: false });
        }
    }
    render() {
        const { paramFlag, openDock, apiUrl, configKey, configValue, appKey, appSecret, autoProductTime, remark, autoInventoryTime, autoQueryOrderTime, autoOrderTime } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <div style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }} >
                <div className={styles.title}>蚁购对接参数设置</div>
                <div className={styles.line}></div>

                <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                    <Form.Item
                        colon={false}
                        htmlFor='openDock'
                        label={<span>开启对接</span>}
                    >
                        {getFieldDecorator('openDock', {
                            // rules: [{ required: true, message: '请选择开启对接' }],
                            initialValue: openDock
                        })(
                            <RadioGroup style={{ marginLeft: '8px' }} onChange={this.openDockFun}>
                                <Radio value={1}>开启</Radio>
                                <Radio value={0}>不开启</Radio>
                            </RadioGroup>
                        )}
                    </Form.Item>
                    {paramFlag &&
                        <div>
                            <Form.Item
                                colon={false}
                                htmlFor='apiUrl'
                                label={<span>访问路径</span>}
                            >
                                {getFieldDecorator('apiUrl', {
                                    initialValue: apiUrl,
                                    rules: [{ required: true, message: '请输入api路径' }],
                                })(<Input placeholder='给目标起个名字' style={{ width: 400 }} />)}
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                htmlFor='configKey'
                                label={<span>用户名</span>}
                            >
                                {getFieldDecorator('configKey', {
                                    initialValue: configKey,
                                    rules: [{ required: true, message: '请输入用户名' }],
                                })(<Input placeholder='必须，由蚁购工作人员提供' style={{ width: 400 }} />)}
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                htmlFor='configValue'
                                label={<span>密码</span>}
                            >
                                {getFieldDecorator('configValue', {
                                    initialValue: configValue,
                                    rules: [{ required: true, message: '请输入密码' }],
                                })(<Input.Password autoComplete='new-password' placeholder='必须，由蚁购工作人员提供' style={{ width: 400 }} />)}
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                htmlFor='appKey'
                                label={<span>client_id</span>}
                            >
                                {getFieldDecorator('appKey', {
                                    initialValue: appKey,
                                    rules: [{ required: true, message: '请输入client_id' }],
                                })(<Input placeholder='必须，由蚁购工作人员提供' style={{ width: 400 }} />)}
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                htmlFor='appSecret'
                                label={<span>client_secret</span>}
                            >
                                {getFieldDecorator('appSecret', {
                                    initialValue: appSecret,
                                    rules: [{ required: true, message: '请输入client_secret' }],
                                })(<Input placeholder='必须，由蚁购工作人员提供' style={{ width: 400 }} />)}
                            </Form.Item>
                        
                    <Form.Item
                        colon={false}
                        htmlFor='remark'
                        label={<span>备注</span>}
                    >
                        {getFieldDecorator('remark', {
                            initialValue: remark,
                        })(<Input.TextArea placeholder='请输入你的阶段性工作目标' style={{ width: 400 }} rows={4} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='autoInventoryTime'
                        label={<span>库存接口自动执行间隔</span>}
                    >
                        {getFieldDecorator('autoInventoryTime', {
                            initialValue: autoInventoryTime,
                        })(
                            <InputNumber min={0} placeholder='输入时间后，系统自动按频率执行接口操作，建议30分钟' style={{ width: 400 }} />
                        )}
                        <span style={{ marginLeft: 10 }}>分钟</span>
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='autoOrderTime'
                        label={<span>订单查询接口自动执行间隔</span>}
                    >
                        {getFieldDecorator('autoOrderTime', {
                            initialValue: autoOrderTime,
                        })(
                            <InputNumber min={0} placeholder='输入时间后，系统自动按频率执行接口操作，建议30分钟' style={{ width: 400 }} />
                        )}
                        <span style={{ marginLeft: 10 }}>分钟</span>
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='autoQueryOrderTime'
                        label={<span>物流跟踪自动执行间隔</span>}
                    >
                        {getFieldDecorator('autoQueryOrderTime', {
                            initialValue: autoQueryOrderTime,
                        })(
                            <InputNumber min={0} placeholder='输入时间后，系统自动按频率执行接口操作，建议30分钟' style={{ width: 400 }} />
                        )}
                        <span style={{ marginLeft: 10 }}>分钟</span>
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='autoProductTime'
                        label={<span>消息接口自动执行间隔</span>}
                    >
                        {getFieldDecorator('autoProductTime', {
                            initialValue: autoProductTime,
                        })(
                            <InputNumber min={0} placeholder='输入时间后，系统自动按频率执行接口操作，建议30分钟' style={{ width: 400 }} />

                        )}
                        <span style={{ marginLeft: 10 }}>分钟</span>
                    </Form.Item>
                    <div style={{ display: 'flex' }}>
                        <Button style={{ marginLeft: '17%', marginRight: '20px', width: '120px', display: 'inline-block' }} type="primary" htmlType="submit">保存</Button>
                        <XCancelButton style={{ width: '120px', display: 'inline-block' }} label='重置' onClick={this._resetHandle} />
                        {/* <Button style={{ marginLeft: '20px', width: '220px', display: 'inline-block' }} disabled={isNew} type="primary" onClick={this._okHandle}>确认已按要求打款后的激活</Button> */}
                    </div>
                    </div>}
                </Form>
            </div >
        );
    }
}
addFinancialContent.propTypes = {
    history: PropTypes.object, // 路由history对象
    form: PropTypes.object.isRequired, // 校验
    match: PropTypes.object, // 路由match对象
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(addFinancialContent);// 校验

export default connect(null, null)(ForgotProducDetails);