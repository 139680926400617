/**
 * 业务类型（商品）下拉搜索框（枚举类型）
 */
import React from 'react';
import PropTypes from 'prop-types';
import { XSelect } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

class GoodsTypeEnum extends React.Component {

    state = {
        renderData: [], // 数据源
    }

    componentDidMount() {
        const { enumType } = this.props;
        http(`/enum/${enumType}`, {}, 'POST').then((response) => {
            if (response.status == 200) {
                this.setState({ renderData: response.result });
            } else {
                console.log(`/enum/${enumType}请求失败`);
            }
        }).catch(() => {
            console.log(`/enum/${enumType}请求失败`);
        });
    }

    _onChange = (value) => {
        const { bindThis, bindName } = this.props;

        if (bindThis && bindName) {
            bindThis.setState({ [bindName]: value });
        }

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const { renderData } = this.state;
        const { style, selectStyle, label, placeholder, isRequired, value, disabled } = this.props;

        return (
            <XSelect
                style={style}
                selectStyle={selectStyle}
                label={label}
                placeholder={placeholder}
                renderData={renderData}
                dataIndex='value'
                keyIndex='code'
                value={value.value}
                onChange={this._onChange}
                isRequired={isRequired}
                disabled={disabled}
            />
        );
    }
}

GoodsTypeEnum.defaultProps = {
    style: { width: '218px' },
    selectStyle: { width: '150px' },
    disabled: false,
    enumType: 'FromSaveGoodsTypeEnum',
};

GoodsTypeEnum.propTypes = {
    bindThis: PropTypes.object, // 父级this
    bindName: PropTypes.string, // 双向绑定的属性名称
    value: PropTypes.object,
    style: PropTypes.object,
    selectStyle: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    defaultValue: PropTypes.object,
    disabled: PropTypes.bool,
    enumType: PropTypes.string
};

export default GoodsTypeEnum;