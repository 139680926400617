/**
 * 商品管理 - 品牌申请异步请求
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

export const getBrandApplyList = function* (){
    yield takeEvery(T.GET_BRANDAPPLY_LIST, function* requestData(action){
        try{
            let result = yield http('/goods/brand/apply/list', action.payload, 'POST');
            yield put({type:T.SET_BRANDAPPLY_LIST, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    }); 
};

export const getBrandApplyStateSele = function* (){
    yield takeEvery(T.GET_BRANDAPPLY_STATESELE, function* requestData(action){
        try{
            let result = yield http('/enum/GoodsAuditProcessEnum', action.payload, 'POST');
            yield put({type:T.SET_BRANDAPPLY_STATESELE, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    }); 
};

export const getBrandApplyBrandSele = function* (){
    yield takeEvery(T.GET_BRANDAPPLY_BRANDSELE, function* requestData(action){
        try{
            let result = yield http('/goods/brand/listBrandItem', action.payload, 'POST');
            yield put({type:T.SET_BRANDAPPLY_BRANDSELE, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    }); 
};

export const getBrandApplyDetail = function* (){
    yield takeEvery(T.GET_BRANDAPPLY_DETAIL, function* requestData(action){
        try{
            let result = yield http('/goods/brand/auditInfo', action.payload, 'POST');
            yield put({type:T.SET_BRANDAPPLY_DETAIL, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    }); 
};