// 分转化为元 - 正则解决精度

export const regFenToYuan = (fen, comma = false) => {
    const toDecimal2 = (x) => {
        var f = parseFloat(x);
        if (isNaN(f)) {
            return false;
        }
        f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf('.');
        if (rs < 0) {
            rs = s.length;
            s += '.';
        }
        while (s.length <= rs + 2) {
            s += '0';
        }
        return s;
    };
    var num = fen;
    if (num === undefined || num === null) {
        return 0.00;
    } else {
        num = fen * 0.01;
        num += '';
        var reg = num.indexOf('.') > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;
        num = num.replace(reg, '$1');
        num = toDecimal2(num);
        return comma ? toThousands(num) : num;
    }
};
// 强制保留2位小数，如：2，会在2后面补上00.即2.00

export const toDecimal2 = (x) => {
    var f = parseFloat(x);
    if (isNaN(f)) {
        return false;
    }
    f = Math.round(x * 100) / 100;
    var s = f.toString();
    var rs = s.indexOf('.');
    if (rs < 0) {
        rs = s.length;
        s += '.';
    }
    while (s.length <= rs + 2) {
        s += '0';
    }
    return s;
};
// 元转分 - 解决精度问题 yuan:要转换的钱，单位元； digit：转换倍数

export const regYuanToFen = (yuan) => {
    if (!yuan) {
        return 0;
    }
    const index = yuan.toString().indexOf(',');
    if (index !== -1) {
        yuan = Number(yuan.replace(/,/g, ''));
    }
    

    const arg2 = 100;

    let m = 0;
    let s1 = yuan.toString();
    let s2 = arg2.toString();

    if (s1.split('.')[1]) {
        m += s1.split('.')[1].length;
    }
    if (s2.split('.')[1]) {
        m += s2.split('.')[1].length;
    }
    return parseInt((Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)).toFixed(2));
};
// 格式化金钱，三位加一个逗号
// 格式化金钱，三位加一个逗号

function toThousands(number) {
    number = number + '';
    number = number.replace(/,/g, '');
    if (isNaN(number) || number == '') return '';
    number = Math.round(number * 100) / 100;
    if (number < 0)
        return '-' + outputdollars(Math.floor(Math.abs(number) - 0) + '') + outputcents(Math.abs(number) - 0);
    else
        return outputdollars(Math.floor(number - 0) + '') + outputcents(number - 0);
}
function outputdollars(number) {
    if (number.length <= 3)
        return (number == '' ? '0' : number);
    else {
        var mod = number.length % 3;
        var output = (mod == 0 ? '' : (number.substring(0, mod)));
        for (var i = 0; i < Math.floor(number.length / 3); i++) {
            if ((mod == 0) && (i == 0))
                output += number.substring(mod + 3 * i, mod + 3 * i + 3);
            else
                output += ',' + number.substring(mod + 3 * i, mod + 3 * i + 3);
        }
        return (output);
    }
}
function outputcents(amount) {
    amount = Math.round(((amount) - Math.floor(amount)) * 100);
    return (amount < 10 ? '.0' + amount : '.' + amount);
}
// 金额转为大写汉子

export const priceUppercase = (money) => {
    var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'); //汉字的数字
    var cnIntRadice = new Array('', '拾', '佰', '仟'); //基本单位
    var cnIntUnits = new Array('', '万', '亿', '兆'); //对应整数部分扩展单位
    var cnDecUnits = new Array('角', '分', '毫', '厘'); //对应小数部分单位
    //var cnInteger = '整'; //整数金额时后面跟的字符
    var cnIntLast = '元'; //整型完以后的单位
    var maxNum = 999999999999999.9999; //最大处理的数字

    var IntegerNum; //金额整数部分
    var DecimalNum; //金额小数部分
    var ChineseStr = ''; //输出的中文金额字符串
    var parts; //分离金额后用的数组，预定义
    if (money == '') {
        return '';
    }
    money = parseFloat(money);
    if (money >= maxNum) {
        // $.alert('超出最大处理数字');
        return '';
    }
    if (money == 0) {
        //ChineseStr = cnNums[0]+cnIntLast+cnInteger;
        ChineseStr = cnNums[0] + cnIntLast;
        //document.getElementById('show').value=ChineseStr;
        return ChineseStr;
    }
    money = money.toString(); //转换为字符串
    if (money.indexOf('.') == -1) {
        IntegerNum = money;
        DecimalNum = '';
        cnIntLast = '元整';
    } else {
        parts = money.split('.');
        IntegerNum = parts[0];
        DecimalNum = parts[1].substr(0, 4);
    }
    let zeroCount = 0;
    let IntLen = 0;
    let n;
    let p;
    let m;
    let q;
    let decLen = 0;
    if (parseInt(IntegerNum, 10) > 0) {//获取整型部分转换
        zeroCount = 0;
        IntLen = IntegerNum.length;
        for (var i = 0; i < IntLen; i++) {
            n = IntegerNum.substr(i, 1);
            p = IntLen - i - 1;
            q = p / 4;
            m = p % 4;
            if (n == '0') {
                zeroCount++;
            } else {
                if (zeroCount > 0) {
                    ChineseStr += cnNums[0];
                }
                zeroCount = 0; //归零
                ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m == 0 && zeroCount < 4) {
                ChineseStr += cnIntUnits[q];
            }
        }
        ChineseStr += cnIntLast;
        //整型部分处理完毕
    }
    if (DecimalNum != '') {//小数部分
        decLen = DecimalNum.length;
        for (i = 0; i < decLen; i++) {
            n = DecimalNum.substr(i, 1);
            if (n != '0') {
                ChineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
        }
    }
    if (ChineseStr == '') {
        //ChineseStr += cnNums[0]+cnIntLast+cnInteger;
        ChineseStr += cnNums[0] + cnIntLast;
    }/* else if( DecimalNum == '' ){
              ChineseStr += cnInteger;
              ChineseStr += cnInteger;
          } */
    return ChineseStr;
};