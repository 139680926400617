import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Empty } from 'antd';
import styles from './CoverList.module.scss';

const CoverList = ({
    defaultValue,
    value,
    onChange,
    fields = { key: 'key', src: 'src' },
    dataSource,
    hasMore,
    onLoadMore
}) => {

    let [defaultValueS, setDefaultValueS] = useState(value === undefined ? defaultValue : value);
    let realValue = value === undefined ? defaultValueS : value;
    let pageIndex = useRef(1);
    let hasData = dataSource && dataSource.length > 0;
    let loadMore = hasMore && hasData && <div style={{ textAlign: 'center' }}><Button onClick={onClickLoadMore}>加载更多</Button></div>;
    let list = useMemo(() => hasData && dataSource.map((item) => {
        let selected = realValue && realValue[fields.key] == item[fields.key]
            ? styles.selected
            : '';

        return (
            <div key={item[fields.key]} className={`${styles.coverItem} ${selected}`} onClick={() => onCoverClick(item)}>
                <img src={item[fields.src]} />
            </div>
        );
    }) || <Empty description='暂无数据' />, [dataSource, realValue]);

    function onCoverClick(item) {
        value === undefined && setDefaultValueS(item);
        onChange && onChange(item);
    }

    function onClickLoadMore() {
        pageIndex.current = +1;
        onLoadMore && onLoadMore(pageIndex.current);
    }

    return (
        <div className={styles.coverListWrap}>
            <div className={styles.coverList}>
                {list}
            </div>
            {loadMore}
        </div>
    );
};

CoverList.propTypes = {
    pageSize: PropTypes.number,
    onChange: PropTypes.func,
    defaultValue: PropTypes.object,
    value: PropTypes.object,
    fields: PropTypes.object,
    dataSource: PropTypes.array,
    hasMore: PropTypes.bool,
    onLoadMore: PropTypes.func
};

export { CoverList };