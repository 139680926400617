import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Radio, InputNumber, message } from 'antd';
import styles from './Charge.module.scss';

const Mode = {
    /** 开启 */
    Start: 1,
    /** 禁用 */
    Stop: 0,

    /** 观看有效期：永久有效 */
    Infinite: 0,
    /** 观看有效期：限定有效 */
    Finite: -1
};

const Null = { indate: 0, liveCost: 0, liveFreeTime: 0 };
const Default = { indate: 0, liveCost: -1, liveFreeTime: -1 };

const ChargeConfig = ({
    onChange,
    value = {
        indateType: Mode.Infinite,
        indateDisabled: true
    },
    count,
    money
}) => {

    function updateConfig(changes) {
        onChange({ ...value, ...changes }, Mode.Start);
    }

    function validateInputNumber(input) {
        return input && input != '0' ? input : undefined;
    }

    function getNumberValue(input) {
        return input && input != '-1' ? input : undefined;
    }

    return (
        <div>
            <div><span style={{ color: '#ff0000', marginLeft: 28 }}>*</span>免费观看 <InputNumber value={getNumberValue(value.liveFreeTime)} min={0} placeholder='请输入' style={{ width: 100 }} onChange={(v) => updateConfig({ liveFreeTime: validateInputNumber(v) })} /> 分钟后开始收费（设置后根据设置的时间校验收费）</div>
            <div>
                <span style={{ color: '#ff0000' }}>*</span>观看直播收费 <InputNumber value={getNumberValue(value.liveCost)} min={0} placeholder='请输入' style={{ width: 100 }} onChange={(v) => updateConfig({ liveCost: validateInputNumber(v) })} /> 魅力值 {count} 魅力值={money}元（本场次有效）</div>
            <div>
                <span style={{ color: '#ff0000' }}>*</span>有效期：
                <Radio.Group value={value.indateType} onChange={(e) => {
                    updateConfig({
                        indate: e.target.value === Mode.Infinite ? 0 : undefined,
                        indateType: e.target.value,
                        indateDisabled: e.target.value === Mode.Infinite
                    });
                }}>
                    <Radio value={Mode.Infinite}>永久有效</Radio>
                    <Radio value={Mode.Finite}>观看有效期</Radio>
                </Radio.Group>
                <InputNumber min={0} value={value.indate || undefined} disabled={value.indateDisabled} placeholder='请输入' style={{ width: 100 }} onChange={(v) => updateConfig({ indate: validateInputNumber(v) })} /> 天
        </div>
        </div>
    );
};

ChargeConfig.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object,
    count: PropTypes.number,
    money: PropTypes.number
};

const Charge = ({
    value,
    onChange,
    count,
    money
}) => {

    const cache = useRef({});

    let realValue = getValue(value);

    const [modeState, setModeState] = useState(realValue ? Mode.Start : Mode.Stop);
    modeState === Mode.Stop && realValue && realValue.liveCost != '0' && setModeState(Mode.Start);

    function onModeChange(e) {
        setModeState(e.target.value);
        onValueChange(e.target.value === Mode.Start ? Default : Null, e.target.value);
    }

    function getModeState(target) {
        return (target.indate == '0' || target.indate === undefined)
            && (target.liveCost == '0' || target.liveCost === undefined)
            && (target.liveFreeTime == '0' || target.liveFreeTime === undefined) ? Mode.Stop : Mode.Start;
    }

    function getValue(target = {}) {
        if (
            cache.current.indateType === undefined
            && getModeState(target) === Mode.Stop
        ) {
            return undefined;
        }

        let { indateType = Mode.Infinite, indateDisabled = true } = cache.current;

        if (target.indate > 0) {
            indateType = Mode.Finite;
            indateDisabled = false;
        }

        return {
            indateType,
            indateDisabled,
            ...target
        };
    }

    function setValue(changes) {
        let target = undefined;
        if (changes) {
            let { indateType, indateDisabled, ...params } = changes;
            cache.current = { indateType, indateDisabled };
            target = params;
        }
        return target;
    }

    function onValueChange(changes, ms) {
        let target = ms === Mode.Start
            ? setValue(changes)
            : Null;
        onChange && onChange(target);
    }

    return (
        <div>
            <Radio.Group value={modeState} onChange={onModeChange} style={{ marginBottom: 15 }}>
                <Radio value={Mode.Start}>是</Radio>
                <Radio value={Mode.Stop}>否</Radio>
            </Radio.Group>
            {modeState === Mode.Start
                ? <ChargeConfig count={count} money={money} onChange={onValueChange} value={realValue} />
                : null
            }
        </div>
    );
};

Charge.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object,
    count: PropTypes.number,
    money: PropTypes.number
};

export { Charge };