/**
 * 【系统设置页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XInputNum, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Button, Icon, InputNumber, message, Tooltip, Typography } from 'antd';
import { isCity } from '@/assets/js/authType';

const { Text } = Typography;

class Main extends React.Component {

    state = {
        inventtory: 0,
        loading: false,
        platPointsGiveUserRate: null,
        pointsGiveUserRate: null,
        rootRate: null,
        rateLoad: false
    }

    componentDidMount() {
        this._init();
    }

    _init = () => {
        http('/goods/inventory/getAnchorAssistantInventoryParam', {}, 'POST')
            .then((response) => { this.setState({ inventtory: response.result }); });
        http('/goods/inventory/getPointsGiveUserRate', {}, 'POST')
            .then(res => {
                const { platPointsGiveUserRate, pointsGiveUserRate } = res.result;
                this.setState({ platPointsGiveUserRate, pointsGiveUserRate, rootRate: pointsGiveUserRate });
            });
    }

    // 保存
    _saveHandle = () => {
        const { inventtory } = this.state;
        this.setState({ loading: true });
        http('/goods/inventory/updateAnchorAssistantInventoryParam', { inventtory }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('设置成功！');
                this.setState({ loading: false });
                this._init();
            }
        }).catch((e) => {
            message.error(e.message);
            this.setState({ loading: false });
        });
    }

    // 重置
    _resetHandle = () => {
        this.setState({ inventtory: 0 });
    }

    //保存比例
    _saveData = () => {
        this.setState({ rateLoad: true }, () => {
            http('/goods/inventory/updatePointsGiveUserRate', { pointsGiveUserRate: this.state.pointsGiveUserRate }, 'POST')
                .then(() => {
                    message.success('设置成功！');
                    this.setState({ rateLoad: false });
                    this._init();
                })
                .catch((e) => { message.error(e.message); this.setState({ rateLoad: false }); });
        });
    }

    render() {
        const { inventtory, loading, platPointsGiveUserRate, pointsGiveUserRate, rootRate, rateLoad } = this.state;
        return (
            <div className={styles.flexBoxContainer} >
                <div className={styles.searchBox}>
                    <XInputNum
                        style={{ width: '860px', marginLeft: '14px' }}
                        inputStyle={{ width: '800px' }}
                        label='商品最小库存'
                        placeholder='商品最小库存'
                        value={inventtory}
                        bindThis={this}
                        min={0}
                        max={10000}
                        precision={0}
                        bindName='inventtory'
                        isRequired={true}
                    />
                    <XOKButton style={{ marginLeft: '74px', width: 120, }} label='提交' onClick={this._saveHandle} loading={loading} />
                    <XCancelButton style={{ marginLeft: '26px', width: 120, }} label='重置' onClick={this._resetHandle} />
                </div>
                <div className={styles.tips}>当主播商品可用剩余库存小于或等于本设置的值时，系统给出提示。在每日说APP补库存显示对应商品。要求大于0；按SKU校验。</div>
                {isCity() && <div style={{ margin: '20px' }}>
                    <Text type='danger' style={{ fontWeight: 'bold' }}>*</Text><Text style={{ color: '#252525' }}>设置赠送用户积分比例</Text>
                    <Text type='secondary'>（每笔订单商品销售后，返给购买者的积分比例）</Text>
                    <Tooltip title='每销售一笔订单，依据平台规则，可设置积分返还用户的比例。默认不能低于平台最低比例，最大不能超过100%'>
                        <Icon style={{ fontSize: '16px', cursor: 'pointer' }} type='question-circle' theme='filled' />
                    </Tooltip>
                    <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                        <Text style={{ color: '#252525' }}>积分返还比例（%）</Text>
                        <InputNumber placeholder='请输入' min={platPointsGiveUserRate} max={100} style={{ width: '200px' }}
                            value={pointsGiveUserRate} onChange={pointsGiveUserRate => this.setState({ pointsGiveUserRate })} />
                        <Button loading={rateLoad} type='primary' style={{ margin: '0 20px' }} onClick={this._saveData}>确认</Button>
                        <Button disabled={rateLoad} onClick={() => this.setState({ pointsGiveUserRate: rootRate })}>重置</Button>
                    </div>
                    <div><Text type='danger'>注：积分返还比例不能低于 {platPointsGiveUserRate}%，最大不能超过100%</Text></div>
                </div>}
            </div>
        );
    }
}

Main.propTypes = {
    getListAreaByParentId: PropTypes.func, // 获取省市区
    address: PropTypes.object, // 省市区
};

const mapStateToProps = (state) => ({
    address: state.supplierlist.address,
});

export default connect(mapStateToProps, {})(Main);