import * as T from './actiontypes';

//获取供应商结算价审核列表
export const getBalanceAuditList = (params = {}) => ({
    type: T.GET_BALANCEAUDIT_LIST,
    payload: params
});

////供应商审核结算价详情
export const getBalanceAuditDetail = (goodsId) => ({
    type: T.GET_BALANCEAUDIT_DETAIL,
    payload: { goodsId }
});

//供应商审核结算价业务类型下拉
export const getBalanceAuditBusinessSele = () => ({
    type: T.GET_BALANCEAUDIT_BUSINESSSELE,
    payload: {}
});

//供应商审核结算价标签下拉
export const getBalanceAuditLabelSele = () => ({
    type: T.GET_BALANCEAUDIT_LABELSELS,
    payload: {}
});

//供应商审核结算价状态下拉
export const getBalanceAuditStateSele = () => ({
    type: T.GET_BALANCEAUDIT_STATESELE,
    payload: {}
});