/**
 * 平台活动
 */
import React from 'react';
import styles from './index.module.scss';
import { XSelect, XOKButton, XCancelButton, XInput, XTabNav } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import DeleteModal from '../../modal/DeleteModal';
// import Linkcopy from '../../modal/Linkcopy';
// import PopconfirmModal from '../../modal/PopconfirmModal';
import ActivityDate from '../../modal/ActivityDate';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getadvocacytaskDropDown, getadvocacytaskList, } from '@/reducers/marketingmanage/advocacytask/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import PDF from '../../modal/PDF';
import http from '@/assets/api/http';
import { message } from 'antd';

const Mode = {
    EDITE: 'EDITE',//删除
    DELETE: 'DELETE',//详情
    EDIT: 'EDIT',//编辑
};

class Marketing extends React.Component {

    state = {
        type: {},//活动类型
        state: {},//活动状态
        task: '',//活动名称

        currentOpenMode: '', // 当前需要打开的弹窗signTime
        openModeParam: {}, // 当前需要打开的弹窗的入参
        popconParam: {},//启用停用入参

        tabs: [
            { id: 1, label: '全部平台活动' },
            { id: 2, label: '参与的活动' },
            { id: 3, label: '参与已结束' },
        ],
        curt: 1,//tab默认
    }


    componentDidMount() {
        const { getadvocacytaskDropDown, } = this.props;
        getadvocacytaskDropDown();//分类状态
    }

    // 导航切换回调
    _anchorClickHandle = (id) => {
        this.setState({ type: {}, state: {}, task: '', curt: id, }, () => {
            this._searchHandle();// 重新渲染更新列表
        });
    }
    // 导航切换
    _anchorMoveHandle = () => {
        const { curt } = this.state;
        return (
            this.state.tabs.map((item, index) => {
                if (index == 0) {
                    return (
                        <span key={item.id} onClick={() => this._anchorClickHandle(item.id)} className={curt == item.id ? styles.curt : ''}>{item.label}</span>
                    );
                } else {
                    return (
                        <span key={item.id} onClick={() => this._anchorClickHandle(item.id)} className={curt == item.id ? styles.curt : ''}>{item.label}</span>
                    );
                }
            })
        );
    }
    // 新建攻略
    _createStudioRoom = () => {
        this.props.updateContent('CREAT');
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { task, type, state, pageSize, pageNum, curt, } = this.state;
        let curType;//活动时间状态
        if (curt == '1') {
            curType = '';
        } else if (curt == '2') {
            curType = '1';
        } else if (curt == '3') {
            curType = '2';
        }
        this.searchCache = {
            activityTitle: task,//活动名称  
            activityType: type.key,//活动类型  
            state: state.key,//状态  
            attendState: curType,//活动时间状态
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getadvocacytaskList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ type: {}, state: {}, task: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getadvocacytaskList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    // 表格操作  
    _tableAction = (id, type, value) => {
        switch (type) {
            case 'detail':
                this.props.updateContent(Mode.DELETE, id, value.activityState);
                break;
            case 'edit':
                this.props.updateContent(Mode.EDIT, id);
                break;
            case 'data':
                this.setState({
                    currentOpenMode: 'data'
                });
                break;
            case 'listing'://服务清单
                http(`/marketing/activityMerchantInfo/findGoodsRatePDFByActivityId/${id}`, { activityId: id }, 'POST').then((res = {}) => {
                    let regular = /(http|https):\/\/([\w.]+\/?)\S*/;
                    let pathUrl = '';
                    if (res.result != '' && regular.test(res.result)) {
                        pathUrl = res.result;
                    }
                    this.setState({
                        currentOpenMode: 'listing',
                        openModeParam: pathUrl
                    });
                }).catch((e = {}) => {
                    message.error(e.message);
                });
                break;
            case 'fight'://抢拍记录
                this.props.updateContent('fight', value);
                break;
            case 'goods'://追加商品
                this.props.updateContent('goods', value);
                break;
        }
    }

    // 打开弹窗  
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case Mode.DELETE:
                return <DeleteModal title='系统信息' visible={true} closeMode={this._closeMode} />;
            case 'data':
                return <ActivityDate title='代言数据统计' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'listing':
                return <PDF title='查看清单' data={this.state.openModeParam} visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getadvocacytaskList(this.searchCache);
        }
    }

    render() {
        const { type, task, state, tabs, curt } = this.state;
        const { selectData, tableSource, paginations, } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    {/* <div className={`${styles.top_title} ${styles.page}`}>
                        {this._anchorMoveHandle()}
                    </div> */}
                    <XTabNav renderData={tabs} onChange={tab => this._anchorClickHandle(tab)} activedID={curt} />
                    <div className={styles.search_box} style={{ marginTop: '10px' }}>
                        <XInput
                            style={{ width: '216px' }}
                            inputStyle={{ width: '150px' }}
                            label='活动名称'
                            placeholder='请输入活动名称'
                            value={task}
                            bindThis={this}
                            bindName='task'
                        />
                        <XSelect
                            style={{ marginLeft: '30px', width: '216px' }}
                            selectStyle={{ width: '150px' }}
                            label='活动类型'
                            placeholder='请选择活动类型'
                            renderData={selectData.result ? selectData.result : []}
                            dataIndex='value'
                            keyIndex='key'
                            value={type.value}
                            bindThis={this}
                            bindName='type'
                        />
                        <XSelect
                            style={{ marginLeft: '30px', width: '216px' }}
                            selectStyle={{ width: '150px' }}
                            label='活动状态'
                            placeholder='请选择活动状态'
                            renderData={selectData.activeState ? selectData.activeState : []}
                            dataIndex='value'
                            keyIndex='key'
                            value={state.value}
                            bindThis={this}
                            bindName='state'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    {/* <div className={styles.operate_box}>
                    <XOKButton style={{ width: '140px' }} label='新建代言' onClick={this._createStudioRoom} />
                </div> */}
                    <TableContent renderData={tableSource} tableAction={this._tableAction} pagiNation={paginations} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Marketing.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 路由跳转
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getadvocacytaskDropDown: PropTypes.func,// 通过Saga异步请求下拉选项数据方法Action
    getadvocacytaskList: PropTypes.func,// 通过Saga异步请求列表查询方法Action
    tableSource: PropTypes.array, // 表格数据源
    selectData: PropTypes.object, // 分类状态下拉选项
    paginations: PropTypes.object, // 分页数据
};

const mapStateToProps = (state) => {
    return {
        tableSource: state.advocacytask.advocacytaskDate.tableSource,
        selectData: state.advocacytask.advocacytaskDate.selectData,
        paginations: state.advocacytask.advocacytaskDate.paginations,
    };
};

export default connect(mapStateToProps, { getadvocacytaskDropDown, getadvocacytaskList, })(Marketing);
