/**
 * 【分配权限】
 */
import React from 'react';
import { Row, Col, Tree, Table, Icon, message } from 'antd';
import TableContent from './components/TableContent';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XOKButton, XSelect, XCancelButton, XInput, XTabNav } from '@/components/xqxc_ui';
import AddRole from './modal/AddRole';
import DeleteModal from './modal/DeleteModal';
import EditRole from './modal/EditRole';
import AddStaff from './modal/AddStaff';
import EditStaff from './modal/EditStaff';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getRolesList, getEmplsByRole, getAdminEmpl, getUnEmpl } from '@/reducers/permissionsmanage/assignauthority/actions';
import { getDeptSelect } from '@/reducers/permissionsmanage/employeelist/actions';
import http from '@/assets/api/http';

const { TreeNode } = Tree;
class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: [
                { id: 1, label: '员工角色分配' },
                { id: 2, label: '角色权限设置' },
            ],
            curTabID: 1,  // 当前显示的Tab页ID
            loginName: '', // 员工账号（搜索条件）
            dept: {}, // 所属部门（搜索条件）
            hoverRecord: {}, // 角色hover时传递的参数
            treeData: [], // 角色权限设置
            checkedKeys: [], // （受控）选中复选框的树节点
            currentOpenMode: '', // 弹框类型
            openModeParam: {}, // 弹框参数
            roleName: '', // 角色名称

        };
        this.emplTableType = 0; // 0: 本公司管理员员工  1：本公司子级部门或子级公司员工  2：未分类员工
        this.isEmplEdit = false;  // 该角色下的员工是否可编辑（系统默认的顶级角色不可编辑，新建的二级角色可以编辑）
        this.searchCache = { pageNum: 1, pageSize: 25 }; // 分页查询缓存
        this.roleCache = {}; // 角色查询缓存
        this.checkedKeys = [];
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
        this.props.getRolesList(); // 获取角色列表
        this.props.getDeptSelect();
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 表格操作
    _tableAction = (id, type) => {
        let data = {};
        switch (type) {
            case 'del':
                data = {
                    emplId: id,
                    roleId: this.roleCache.roleId
                };
                http('/admin/empl/delRoleEmpl', data, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('移除成功！');
                        this.props.getRolesList(); // 获取角色列表
                        this.props.getEmplsByRole({ ...this.searchCache, ...this.roleCache });
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
            case 'edit':
                this.setState({ currentOpenMode: 'edit', openModeParam: { id } });
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        const { currentOpenMode, openModeParam } = this.state;
        switch (currentOpenMode) {
            case 'addRole':
                return <AddRole title='新建角色' visible={true} closeMode={this._closeMode} />;
            case 'addStaff':
                return <AddStaff title='新建员工' visible={true} closeMode={this._closeMode} />;
            case 'delRole':
                return <DeleteModal title='删除角色' visible={true} closeMode={this._closeMode} data={openModeParam} />;
            case 'del':
                return <DeleteModal title='移除关联' visible={true} closeMode={this._closeMode} />;
            case 'editRole':
                return <EditRole title='编辑角色' visible={true} closeMode={this._closeMode} data={openModeParam} />;
            case 'edit':
                return <EditStaff title='编辑员工' visible={true} closeMode={this._closeMode} data={openModeParam} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' });
        if (update) {
            this.props.getRolesList(); // 获取角色列表
            this.searchCache = { ...this.searchCache, ...this.roleCache };
            switch (this.emplTableType) {
                case 0:
                    this.props.getAdminEmpl(this.searchCache);
                    break;
                case 1:
                    this.props.getEmplsByRole(this.searchCache);
                    break;
                case 2:
                    this.props.getUnEmpl(this.searchCache);
                    break;
            }
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, ...this.roleCache, pageSize, pageNum };
        switch (this.emplTableType) {
            case 0:
                this.props.getAdminEmpl(this.searchCache);
                break;
            case 1:
                this.props.getEmplsByRole(this.searchCache);
                break;
            case 2:
                this.props.getUnEmpl(this.searchCache);
                break;
        }
    }

    // 添加角色
    _addRoleHandle = () => {
        this.setState({ currentOpenMode: 'addRole' });
    };

    // 删除角色
    _delRoleHandle = (record) => {
        this.setState({ currentOpenMode: 'delRole', openModeParam: { id: record.id } });
    };

    // 编辑角色
    _editRoleHandle = (record) => {
        this.setState({ currentOpenMode: 'editRole', openModeParam: { id: record.id } });
    };

    // 新建员工
    _newHandle = () => {
        this.setState({ currentOpenMode: 'addStaff' });
    }

    // 渲染角色是否可操作
    _htmlHandle = (record) => {
        if (record.parentId == undefined) {
            return null; // 顶级角色不允许编辑和删除，由系统默认创建
        }
        const { hoverRecord } = this.state;
        return (
            <div>
                {hoverRecord.id === record.id &&
                    <span>
                        {record.parent && record.parent === '0' ? <Icon type='plus-circle' onClick={() => this._addRoleHandle({})} /> : null}<Icon type='minus-circle' style={{ marginLeft: 10 }} onClick={() => this._delRoleHandle(record)} /><Icon type='edit' style={{ marginLeft: 10 }} onClick={() => this._editRoleHandle(record)} />
                    </span>
                }
            </div>
        );
    }

    // 查询员工
    _searchHandle = () => {
        const { loginName, dept } = this.state;
        this.searchCache = {
            roleId: this.roleCache.roleId ? this.roleCache.roleId : undefined,
            roleType: this.roleCache.roleType ? this.roleCache.roleType : undefined,
            loginName,
            deptId: dept.id,
            pageNum: 1,
            pageSize: 25
        };
        switch (this.emplTableType) {
            case 0:
                this.props.getAdminEmpl(this.searchCache);
                break;
            case 1:
                this.props.getEmplsByRole(this.searchCache);
                break;
            case 2:
                this.props.getUnEmpl(this.searchCache);
                break;
        }
    }

    // 重置员工
    _resetHandle = () => {
        this._cleanState();
        const data = { ...this.roleCache, pageNum: 1, pageSize: 25 };
        switch (this.emplTableType) {
            case 0:
                this.props.getAdminEmpl(data);
                break;
            case 1:
                this.props.getEmplsByRole(data);
                break;
            case 2:
                this.props.getUnEmpl(data);
                break;
        }
    }

    // 清理状态
    _cleanState = () => {
        this.setState({
            loginName: '', // 员工账号（搜索条件）
            dept: {}, // 所属部门（搜索条件）
        });
    }

    // 树状结构渲染
    renderTreeNodes = (data) => {
        return data.map(item => {
            if (item.children) {
                return (
                    <TreeNode title={item.menuName} key={item.id} dataRef={item}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode title={item.menuName} key={item.id} />;
        });
    }

    // 切换权限菜单
    _onCheck = (keys, event) => {
        let checkedKeys = keys.checked;
        let isParentNode = false;
        const { treeData } = this.state;
        const { checked, node } = event;
        const { eventKey } = node.props;


        let childrenEventKeys = []; // 如切换的是父节点，则为该父节点关联的子节点

        // 原方法：检测是否切换父节点
        // for (let i = 0; i < treeData.length; i++) {
        //     if (treeData[i].id == eventKey) {
        //         isParentNode = true;
        //         childrenEventKeys = treeData[i].children.map((item) => {
        //             return item.id;
        //         });
        //         break;
        //     }
        // }

        // 新方法：递归父子级关联受控检测是否切换父节点
        childrenEventKeys = this._strictlyFor(treeData, eventKey);
        if (childrenEventKeys && childrenEventKeys.length > 0) {
            isParentNode = true;
        } else {
            childrenEventKeys = [];
        }

        // 当切换父节点时自动关联子节点
        if (isParentNode) {
            if (checked) {
                checkedKeys = Array.from(new Set([...checkedKeys, ...childrenEventKeys]));
            } else {
                checkedKeys = checkedKeys.filter((item) => {
                    let f = true;
                    for (let n = 0; n < childrenEventKeys.length; n++) {
                        if (childrenEventKeys[n] == item) {
                            f = false;
                            break;
                        }
                    }
                    return f;
                });
            }
        }

        const addKeys = (childList, parentId) => {
            for (const i of childList) {
                if (eventKey != i.id) {
                    if (i.children.length) addKeys(i.children, i.parentId);
                } else {
                    if (checked) {
                        checkedKeys.push(i.id, i.parentId);
                        if (parentId) checkedKeys.push(parentId);
                    } else {
                        checkedKeys = checkedKeys.filter(i => i != eventKey && i != i.parentId);
                    }
                }
            }
        };

        for (const i of treeData) {
            if (eventKey != i.id && i.children.length > 0) addKeys(i.children);
        }

        this.setState({ checkedKeys: [...new Set(checkedKeys)] });
    };

    // 递归父子级关联受控
    _strictlyFor = (treeData, eventKey) => {
        let childrenEventKeys = [];
        for (let i = 0; i < treeData.length; i++) {
            if (treeData[i].id == eventKey) {
                const array = this._strictlyForChildren(treeData[i]);
                childrenEventKeys = Array.from(new Set([...childrenEventKeys, ...array]));
                break;
            } else {
                const array = this._strictlyFor(treeData[i].children, eventKey);
                childrenEventKeys = Array.from(new Set([...childrenEventKeys, ...array]));
            }
        }
        return childrenEventKeys;
    }

    // 递归子级关联受控
    _strictlyForChildren = (treeData) => {
        let children = [];
        treeData.children && treeData.children.forEach((item) => {
            children = [...children, item.id, item.parentId];
        });
        for (let i = 0; i < treeData.children.length; i++) {
            const array = this._strictlyForChildren(treeData.children[i]);
            children = Array.from(new Set([...children, ...array]));
        }
        return children;
    }

    // 请求某角色ID的权限菜单
    _requestMenuList = (roleId, roleType) => {
        if (roleId) {
            http('/admin/menu/listMenuByRole', { roleId, roleType }, 'POST').then((response) => {
                if (response.status == 200) {
                    const treeData = response.result;
                    this.checkedKeys = [];

                    // 原方法：递归检查是否选中
                    this._checkedFor(treeData);

                    // 新方法：循环检查是否选中
                    // for (let i = 0; i < treeData.length; i++) {
                    //     if (treeData[i].isChecked == 1) {
                    //         checkedKeys.push(treeData[i].id);
                    //     }
                    //     const children = treeData[i].children;
                    //     for (let m = 0; m < children.length; m++) {
                    //         if (children[m].isChecked == 1) {
                    //             checkedKeys.push(children[m].id);
                    //         }
                    //     }
                    // }
                    this.setState({ treeData, checkedKeys: this.checkedKeys });
                }
            }).catch((e) => {
                message.error(e.message);
            });
        } else {
            this.setState({ treeData: [] });
        }
    }

    // 递归检查是否选中
    _checkedFor = (treeData) => {
        for (let i = 0; i < treeData.length; i++) {
            if (treeData[i].isChecked == 1) {
                this.checkedKeys.push(treeData[i].id);
            }
            const children = treeData[i].children || [];
            this._checkedFor(children);
        }
    }

    // 保存角色权限菜单
    _updateMenuPower = () => {
        const roleId = this.roleCache.roleId;
        const menuIds = this.state.checkedKeys.toString();
        if (roleId) {
            http('/admin/role/createRoleMenu', { roleId, menuIds }, 'POST').then((response) => {
                if (response.status == 200) {
                    message.success('设置成功！');
                }
            }).catch((e) => {
                message.error(e.message);
            });
        }
    }

    _renderTreeText = () => {
        if (this.emplTableType == 1) {
            return (
                <div>
                    <p className={styles.smallHeader}>预设<span style={{ color: '#3094FB', fontWeight: 'bold' }}>{this.state.roleName}</span>拥有的菜单权限</p>
                    <XOKButton style={{ width: '80px' }} label='设置' onClick={this._updateMenuPower} />
                </div>
            );
        } else {
            return (
                <p className={styles.smallHeader}>无法修改该角色权限</p>
            );
        }

    }

    render() {
        const { curTabID, loginName, dept } = this.state;
        const { rolesList, emplsByRole, adminEmpl, unempl, deptSelect } = this.props;
        const ownRole = rolesList.ownRole ? [rolesList.ownRole] : []; // 本公司的员工
        const totalEmpl = rolesList.emplCount ? rolesList.emplCount : 0; // 全部员工数量
        const unassortedResp = rolesList.unassortedResp ? rolesList.unassortedResp.emplCount : 0; // 未分类员工

        let emplDataSource = []; // 员工表格
        if (this.emplTableType == 0) { // 本公司管理员员工 
            emplDataSource = adminEmpl;
        } else if (this.emplTableType == 1) { // 子级部门或子级公司员工
            emplDataSource = emplsByRole;
        } else if (this.emplTableType == 2) { // 未分类员工
            emplDataSource = unempl;
        }

        const columns = [{
            title: 'roleName',
            dataIndex: 'roleName',
            key: 'roleName',
            render: (text, record) => {
                return <span>{text}({record.emplNum})</span>;
            }
        }, {
            title: 'action',
            dataIndex: 'action',
            key: 'action',
            align: 'right',
            render: (text, record) => {
                return this._htmlHandle(record);
            },
        }];

        return (
            <div style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <Row gutter={24} >
                    <Col span={5} style={{ padding: 0, borderRight: '1px solid #ccc' }}>
                        <div className={styles.cursorStyles}>
                            <XOKButton style={{ width: '100px' }} label='新建角色' onClick={() => this._addRoleHandle({})} />
                        </div>
                        <div style={{ marginLeft: '12px' }}>{rolesList.companyName}({totalEmpl})</div>
                        <Table
                            onRow={record => {
                                return {
                                    // 点击行
                                    onClick: () => {
                                        this.roleCache = { roleId: record.id, roleType: record.roleType };
                                        this.searchCache = { ...this.roleCache, pageNum: 1, pageSize: 25 };
                                        this._cleanState();
                                        if (record.parentId) {
                                            this.isEmplEdit = true;
                                            this.emplTableType = 1;
                                            this.props.getEmplsByRole(this.searchCache);
                                            this._requestMenuList(record.id, record.roleType);
                                        } else {
                                            this.isEmplEdit = false;
                                            this.emplTableType = 0;
                                            this.props.getAdminEmpl(this.searchCache);
                                            this._requestMenuList();
                                        }
                                        this.setState({ roleName: record.roleName });
                                    },
                                    onMouseEnter: () => { this.setState({ hoverRecord: record }); }, // 鼠标移入行
                                    onMouseLeave: () => { this.setState({ hoverRecord: {} }); }, // 鼠标移出行
                                };
                            }}
                            defaultExpandAllRows={true}
                            rowKey={(record) => record.id}
                            childrenColumnName='list'
                            rowClassName={styles.tableRows}
                            dataSource={ownRole}
                            columns={columns}
                            pagination={false}
                            showHeader={false}
                            style={{ paddingLeft: '10px' }}
                        />
                        <div className={styles.unassorted} onClick={() => {
                            this.isEmplEdit = true;
                            this.emplTableType = 2;
                            this.roleCache = {};
                            this.searchCache = { ...this.roleCache, pageNum: 1, pageSize: 25 };
                            this.props.getUnEmpl(this.searchCache);
                            this._requestMenuList();
                        }}>未分类角色({unassortedResp})</div>
                    </Col>
                    <Col span={18} style={{ height: '100%', paddingLeft: 12 }}>
                        {curTabID === 1 &&
                            <div>
                                <div className={styles.row1}>
                                    <XInput
                                        style={{ width: '252px' }}
                                        inputStyle={{ width: '180px' }}
                                        label='员工账号'
                                        placeholder='请输入'
                                        value={loginName}
                                        bindThis={this}
                                        bindName='loginName'
                                    />
                                    <XSelect
                                        style={{ width: '252px', marginLeft: '18px' }}
                                        selectStyle={{ width: '180px' }}
                                        label='所属部门'
                                        placeholder='请选择'
                                        renderData={deptSelect}
                                        dataIndex='deptName'
                                        keyIndex='id'
                                        value={dept.deptName}
                                        bindThis={this}
                                        bindName='dept'
                                    />
                                    <XOKButton
                                        style={{ marginLeft: '20px', width: '60px' }}
                                        label='查询'
                                        onClick={this._searchHandle}
                                    />
                                    <XCancelButton
                                        style={{ marginLeft: '20px', width: '60px' }}
                                        label='重置'
                                        onClick={this._resetHandle}
                                    />
                                </div>
                                <div className={styles.row2} style={(!this.isEmplEdit && this.emplTableType != 0) || this.emplTableType == 2 ? { display: 'none' } : { display: 'block' }}>
                                    <XOKButton
                                        style={{ width: '100px' }}
                                        label='添加员工'
                                        onClick={this._newHandle}
                                    />
                                </div>
                                <TableContent
                                    renderData={emplDataSource}
                                    tableAction={this._tableAction}
                                    paginationChange={this._paginationChange}
                                    curTabID={curTabID}
                                    emplTableType={this.emplTableType}
                                    isEmplEdit={this.isEmplEdit}
                                />
                            </div>
                        }
                        {curTabID === 2 &&
                            <div>
                                {this._renderTreeText()}
                                <Tree
                                    checkable={true}
                                    onCheck={this._onCheck}
                                    checkStrictly={true}
                                    checkedKeys={this.state.checkedKeys}
                                >
                                    {this.renderTreeNodes(this.state.treeData)}
                                </Tree>
                            </div>
                        }
                    </Col>
                </Row>
                {this._renderMode()}
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getRolesList: PropTypes.func, // 获取角色列表
    rolesList: PropTypes.object, // 角色列表
    getEmplsByRole: PropTypes.func, // 获取某角色下的员工列表
    emplsByRole: PropTypes.object, // 某角色下的员工列表
    getAdminEmpl: PropTypes.func, // 获取本公司超级管理员
    adminEmpl: PropTypes.object, // 本公司超级管理员
    getUnEmpl: PropTypes.func, // 获取未分类角色员工
    unempl: PropTypes.object, // 未分类角色员工
    getDeptSelect: PropTypes.func, // 获取部门下拉列 
    deptSelect: PropTypes.array, // 部门下拉列 
};

const mapStateToProps = (state) => ({
    rolesList: state.assignauthority.getRolesList,
    emplsByRole: state.assignauthority.getEmplsByRole,
    adminEmpl: state.assignauthority.getAdminEmpl,
    unempl: state.assignauthority.getUnEmpl,
    deptSelect: state.employee.getDeptSelect,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getRolesList, getEmplsByRole, getAdminEmpl, getUnEmpl, getDeptSelect })(Main);
