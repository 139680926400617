/**
 * 商品费率编辑弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { Modal, message, InputNumber, Form, Select } from 'antd';
import styles from './edit.module.scss';
import { getProductRateDetail } from '@/reducers/productmanage/rate/actions';

const { Option } = Select;

class Edit extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,

        platRateState: '',
        saleRateState: '',
        rateHide: false,

        salePrice: '',
        platRate: '',
        agentRate: '',
        rateMode: [],
        rateModeItem: '',
        platCost: '',
        agentCost: '',
    }

    componentDidMount() {
        this.props.getProductRateDetail(this.props.data.goodsId);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const { productRateDetail } = newProps;
        let platCost = productRateDetail.platRate * Number(productRateDetail.salePrice);
        let agentCost = productRateDetail.agentRate * Number(productRateDetail.salePrice);
        const rateMode = (productRateDetail.rateMode || []).filter(item => item.isCheck);
        if (rateMode.length > 0 && rateMode[0].rateModeCode === 'BASIS') {
            this.setState({ rateHide: true });
        }
        this.setState({
            salePrice: Number(productRateDetail.salePrice),
            platRate: productRateDetail.platRate,
            agentRate: productRateDetail.agentRate,
            rateMode: productRateDetail.rateMode,
            platCost,
            agentCost,
            rateModeItem: (rateMode[0] || {}).rateModeCode
        });
    }

    _sendReq = (url, data, winTxt) => {
        http(url, data, 'POST').then(() => {
            message.success(winTxt);
            this.setState({
                visible: false,
            });
            this.props.closeMode(true);
        }).catch((reject) => {
            message.error(reject.message);
        });
    }

    // 点击确认
    _handleOk = () => {
        const { data } = this.props;
        const { agentRate, platRate, rateModeItem } = this.state;
        this._sendReq('/goods/goods/editGoodsRate', { agentRate, goodsId: data.goodsId, platRate, rateMode: rateModeItem }, '修改费率成功。');
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode(false);
    }

    _rateChange = (value, type) => {
        const { salePrice } = this.state;
        switch (type) {
            case 'platRate':
                if (/^(\d{1,9}(\.\d{1,1})?|100)$/.test(value)) {
                    let platCost = value * salePrice;
                    this.setState({ platRateState: 'success', platRate: value, platCost });
                } else {
                    message.warn('请输入1-100的数字，支持一位小数！');
                    this.setState({ platRateState: 'error' });
                }
                break;
            case 'saleRate':
                if (/^(\d{1,9}(\.\d{1,1})?|100)$/.test(value)) {
                    let agentCost = value * salePrice;
                    this.setState({ saleRateState: 'success', agentRate: value, agentCost });
                } else {
                    message.warn('请输入1-100的数字，支持一位小数！');
                    this.setState({ saleRateState: 'error' });
                }
                break;
            case 'pattern':
                if (value == 'BASIS') {
                    this.setState({ rateHide: true });
                } else {
                    this.setState({ rateHide: false });
                }
                console.log(value);
                this.setState({ rateModeItem: value });
                break;
        }
    }

    render() {
        const { visible, confirmLoading, title, platRateState, saleRateState, salePrice, platRate, rateModeItem, agentRate, platCost, agentCost, rateHide, rateMode } = this.state;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'>
                <div className={styles.wrapper}>
                    <div className={styles.row}>
                        <div className={styles.label}>费用模式：</div>
                        <div className={styles.inputBox}>
                            <Select style={{ width: '250px' }} onChange={value => this._rateChange(value, 'pattern')} value={rateModeItem}>
                                {
                                    rateMode.map(item => {
                                        return <Option key={item.rateModeCode} value={item.rateModeCode}>{item.rateModeValue}</Option>;
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>商品销售价格：</div>
                        <div className={styles.inputBox}>
                            <InputNumber value={salePrice} style={{ width: '250px' }} disabled></InputNumber>
                        </div>
                    </div>
                    <div className={styles.rowNew}>
                        <div className={styles.label}><b>*</b>技术服务费率%：</div>
                        <Form>
                            <Form.Item hasFeedback validateStatus={platRateState} help="可以输入1-100的数字，支持一位小数！">
                                <InputNumber disabled={rateHide} value={platRate} precision={1} style={{ width: '250px' }} onChange={value => this._rateChange(value, 'platRate')} />
                            </Form.Item>
                        </Form>
                    </div>
                    <div className={styles.rowNew}>
                        <div className={styles.label}><b>*</b>平台服务费率%：</div>
                        <Form>
                            <Form.Item hasFeedback validateStatus={saleRateState} help="可以输入1-100的数字，支持一位小数！">
                                <InputNumber disabled={rateHide} value={agentRate} precision={1} style={{ width: '250px' }} onChange={value => this._rateChange(value, 'saleRate')} />
                            </Form.Item>
                        </Form>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>技术服务费：</div>
                        <div className={styles.inputBox}>
                            <InputNumber value={platCost} style={{ width: '250px' }} disabled></InputNumber>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>平台服务费：</div>
                        <div className={styles.inputBox}>
                            <InputNumber value={agentCost} style={{ width: '250px' }} disabled></InputNumber>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

Edit.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    getProductRateDetail: PropTypes.func,//获取详情

    productRateDetail: PropTypes.object//详情
};

const mapStateToProps = (state) => ({
    productRateDetail: state.productRate.productRateDetail
});

export default connect(mapStateToProps, { getProductRateDetail })(Edit);
