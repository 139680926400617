/**
 * 模板管理
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import TemplateTable from './components/TemplateTable';
import { XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import model from '@/reducers/statementmanage/template/model';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',//模板名称
            status: {},//状态
        };
    }

    componentDidMount() {
        this.props.getTemplateState();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, name, status } = this.state;
        this.searchCache = {
            state: status.code,//状态
            templateName: name,//模板名称
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getTemplateTableList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ name: '', status: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getTemplateTableList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作
    _tableAction = (item, type, value) => {
        switch (type) {
            case 'detail'://详情
                this.props.updateContent('detail', item);
                break;
            case 'down'://下载模板
                if (value.templateUrl) {
                    const regular = /(http|https):\/\/([\w.]+\/?)\S*/;
                    regular.test(value.templateUrl) ? location.href = value.templateUrl : message.warning('模板路径无效');
                } else {
                    message.warning('暂无数据');
                }
                break;
        }
    }

    render() {
        const { name, status } = this.state;
        const { tableList, states } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XInput
                            inputStyle={{ width: '150px' }}
                            label='模板名称'
                            placeholder='请输入模板名称'
                            value={name}
                            bindThis={this}
                            bindName='name'
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '150px' }}
                            label='状态'
                            placeholder='请选择状态'
                            renderData={states}
                            dataIndex='value'
                            keyIndex='code'
                            value={status.value}
                            bindThis={this}
                            bindName='status'
                            isOptionBindID={true}
                            showSearch={true}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <TemplateTable renderData={tableList} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}


Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    updateContent: PropTypes.func, // 路由跳转

    tableList: PropTypes.object,
    getTemplateTableList: PropTypes.func,//表格数据(action)
    states: PropTypes.array,
    getTemplateState: PropTypes.func,//状态数据(action)
};

const mapStateToProps = (state) => ({
    tableList: state.template.templateList, // 表格数据
    states: state.template.templateState, // 状态
});

export default connect(mapStateToProps, { ...model.actions })(Main);
