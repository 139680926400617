import { createModel } from '@/store/tools';

const model = {
    namespace: 'advocacytask',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getAdvocacyUnionPay': T('getAdvocacyUnionPay'),
            'getAdvocacyHisaverage': T('getAdvocacyHisaverage'),
            'getAdvocacyHisType': T('getAdvocacyHisType'),
            'getActivityGoodsList': T('getActivityGoodsList'),
        },
        actions: {
            'getAdvocacyUnionPay': A('getAdvocacyUnionPay'), // 获取银联手续费率
            'getAdvocacyHisaverage': A('getAdvocacyHisaverage'), // 活动历史记录
            'getAdvocacyHisType': A('getAdvocacyHisType'), // 活动类型
            'getActivityGoodsList': A('getActivityGoodsList'), // 获取活动商品列表
            'cleanActivityGoodsList':C('getActivityGoodsList'), 
        },
        sagas: {
            'getAdvocacyUnionPay': S('getAdvocacyUnionPay', '/merchant/getConfig'),
            'getAdvocacyHisaverage': S('getAdvocacyHisaverage', '/activity/info/findActivityGoodsHistoryPage'),
            'getAdvocacyHisType': S('getAdvocacyHisType', '/activity/info/selectActivityType'),
            'getActivityGoodsList': S('getActivityGoodsList', '/marketing/activityMerchantInfo/activityGoodsList'),
        },
        reducers: {
            'advocacyUnionPay': R('getAdvocacyUnionPay', {}),
            'advocacyHisaverage': R('getAdvocacyHisaverage', { dataList: [], pageNum: 1, pageSize: 10, resultTotal: 0 }),
            'advocacyHisType': R('getAdvocacyHisType', []),
            'activityGoodsList': R('getActivityGoodsList',  { dataList: [], pageNum: 1, pageSize: 10, resultTotal: 0 }),
        }
    })
};
export default createModel(model);