import * as T from './actiontypes';

/**
 * 仓库对账单列表
 */
const plistInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
// 仓库对账单列表
export const warehouseList = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_WAREHOUSE_LIST:
            return payload.result;
        default:
            return state;
    }
};
// 仓库对账单详情
export const warehouseDetail = (state = {}, { type, payload }) => {
    switch (type) {
        case T.SET_WAREHOUSE_DETAIL:
            return payload.result;
        default:
            return state;
    }
};
// 应收，应付明细
export const warehouseChargeDetail = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_WAREHOUSE_CHARGE_DETAIL:
            return payload.result;
        default:
            return state;
    }
};
// 奢批订单列表
export const warehouseLuxuryOrders = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_WAREHOUSE_LUXURYORDERS:
            return payload.result;
        default:
            return state;
    }
};