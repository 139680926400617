/**
 * 平台活动 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

/**
 *  平台活动列表 Saga  
 */
export const advocacytaskList = function* () {
    yield takeEvery(T.GET_ADVOCACYLIST_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/marketing/activityMerchantInfo/activitylist', action.payload, 'POST');
            yield put({ type: T.SET_ADVOCACYLIST_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 *  平台活动参与活动详情获取数据Saga
 */
export const advocacyDetails = function* () {
    yield takeEvery(T.GET_ADVOCACYDETAILS_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let result = yield http('/marketing/activityMerchantInfo/detail', action.payload, 'POST');
            yield put({ type: T.SET_ADVOCACYDETAILS_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

/**
 * 平台活动下拉框 Saga
 */
export const advocacyDropDown = function* () {
    yield takeEvery(T.GET_DROPDIWN_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let activity, activeState;
            if (action.payload.only == 'only') {
                activity = yield http('/activity/info/selectActivityType', action.payload, 'POST');//活动类型
            } else {
                activity = yield http('/activity/info/selectActivityType', action.payload, 'POST');//活动类型
                activeState = yield http('/activity/info/selectActivityState', action.payload, 'POST');//活动状态
                activity.activeState = activeState.result;
            }
            console.log(activity);
            yield put({ type: T.SET_DROPDIWN_DATA, payload: activity });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};


/**
 * 平台活动新建-选择商品表格Saga
 */
export const advocacyRaiseTable = function* () {
    yield takeEvery(T.GET_RAISETABALE_DATA, function* requestData(action) {
        console.log(action.payload); // e为saga传递的参数对象
        try {
            let table = yield http('/marketing/activityMerchantInfo/findGoodsSkuAndStock', action.payload, 'POST');//代言任务新建选择商品表格数据
            yield put({ type: T.SET_RAISETABALE_DATA, payload: table });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
/**
 *  平台活动详情获取数据Saga
 */
export const advocacyParticipateDetails = function* () {
    yield takeEvery(T.GET_ADVOCACYPARTICIDETAILS_DATA, function* requestData(action) {
        try {
            let result = yield http('/marketing/activityMerchantInfo/detailAndGoods', action.payload, 'POST');
            yield put({ type: T.SET_ADVOCACYPARTICIDETAILS_DATA, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};