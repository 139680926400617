import React from 'react';
import styles from './ProductTable.module.scss';
import { XPagination, XMediaFn, } from '@/components/xqxc_ui';
import { Table, Tooltip, } from 'antd';
import { regFenToYuan } from '@/util/money';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, priceFenToYuanCell, ImgCell } from '@/components/TableCell';

class ProductTable extends React.Component {

    state = {
        selectedRowKeys: [], // 选中的单元行
    };

    _takeColumns = () => {
        let colums = [
            {
                title: '商品图片',
                dataIndex: 'mainImgUrl',
                key: 'mainImgUrl',
                width: 100,
                align: 'center',
                onCell: tooltipStyle,
                render: ImgCell
            },
            {
                title: '商品标题',
                dataIndex: 'goodsName',
                key: 'goodsName',
                width: 150,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '商品货号',
                dataIndex: 'goodsNo',
                key: 'goodsNo',
                width: 150,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '商家编码',
                dataIndex: 'skuNo',
                key: 'skuNo',
                width: 150,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '商品规格',
                dataIndex: 'skuSpec',
                key: 'skuSpec',
                width: 150,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '结算价(元)',
                dataIndex: 'settlePrice',
                key: 'settlePrice',
                width: 150,
                align: 'center',
                onCell: tooltipStyle,
                render: priceFenToYuanCell
            },
            {
                title: '更新时间',
                dataIndex: 'gmtModified',
                key: 'gmtModified',
                width: 150,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            }
        ];

        if (this.props.curTabID == 1) {
            colums.splice(1, 0, {
                title: '操作',
                dataIndex: 'goodsId',
                key: 'goodsId',
                align: 'center',
                width: 150,
                onCell: tooltipStyle,
                render: (text) => this._renderActionCell(text)
            });
        } else {
            colums.push({
                title: '审核时间',
                dataIndex: 'auditTime',
                key: 'auditTime',
                width: 150,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            });
            colums.push({
                title: '审核状态',
                dataIndex: 'auditState',
                key: 'auditState',
                width: 150,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            });
            colums.push({
                title: '备注/原因',
                dataIndex: 'auditDesc',
                key: 'auditDesc',
                width: 150,
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            });
        }

        return colums;
    };

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderActionCell = (key) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'updatePrice')}>更新结算价</div>
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    render() {
        const { paginationChange, paginations, renderData } = this.props;
        // const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ x: 1800, y: true }}
                />
                <XPagination
                    resultTotal={paginations.resultTotal}
                    pageSize={paginations.pageSize}
                    pageNum={paginations.pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

ProductTable.propTypes = {
    curTabID: PropTypes.number, // 当前TabID
    renderData: PropTypes.array.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    paginations: PropTypes.object,//分页数据
};

export default ProductTable;