import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './TableBox.module.scss';
import { Table, Tooltip, Icon } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';
import { regFenToYuan } from '@/util/money';
import { getConfigInfo } from '@/assets/js/authType.js';

const SoldOutedTable = ({
    tableAction,
    renderData: {
        dataList,
        pageNum,
        pageSize,
        resultTotal
    },
    paginationChange
}) => {

    const [limit, setLimit] = useState(false);

    getConfigInfo()
        .then(res => {
            setLimit(res.isWhiteAudit);
        })
        .catch(err => {
            console.log(err.message);
        });

    const renderPrice = (min, max) => {
        if (!min && !max) {
            return tooltip('');
        } else if (min !== max) {
            return tooltip(`${regFenToYuan(min)} - ${regFenToYuan(max)}`);
        } else if (min === max) {
            return tooltip(regFenToYuan(min));
        }
    };

    const tableOperate = (id, item) => {
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => tableAction(id, 'detail')}>详情</div>
            <div className={styles.item} onClick={() => tableAction(id, 'goodslist', item)}>添加批发商品</div>
        </div>;
    };

    const limitTitle = <Fragment>
        <span>限购（每人/件）</span>
        <Tooltip
            placement='topRight'
            title='每件商品用户在每个销售渠道的可购买数量'>
            <Icon
                type='question-circle'
                theme='filled'
            />
        </Tooltip>
    </Fragment>;

    let columns = [
        {
            title: '商品图片',
            dataIndex: 'mainImgUrl',
            key: 'mainImgUrl',
            align: 'center',
            width: '9%',
            render: ImgCell
        }, {
            title: '货号',
            dataIndex: 'goodsNo',
            key: 'goodsNo',
            align: 'center',
            width: '9%',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'center',
            width: '9%',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: 'SKU',
            dataIndex: 'skuNumber',
            key: 'skuNumber',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: limitTitle,
            dataIndex: 'limitNumber',
            key: 'limitNumber',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '销售价（元）',
            dataIndex: 'salaPrice',
            key: 'salaPrice',
            align: 'center',
            width: '9%',
            onCell: tooltipStyle,
            render: (text, item) => renderPrice(item.minSalePrice, item.maxSalePrice)
        }, {
            title: '批发价（元）',
            dataIndex: 'wholesalePrice',
            key: 'wholesalePrice',
            align: 'center',
            width: '9%',
            onCell: tooltipStyle,
            render: (text, item) => renderPrice(item.minWholesalePrice, item.maxWholesalePrice)
        }, {
            title: '批发库存',
            dataIndex: 'totalQuantity',
            key: 'totalQuantity',
            align: 'center',
            width: '9%',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '状态',
            dataIndex: 'statusValue',
            key: 'statusValue',
            align: 'center',
            width: '9%',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '下架时间',
            dataIndex: 'autoOffTime',
            key: 'autoOffTime',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '上架时间',
            dataIndex: 'onOffTime',
            key: 'onOffTime',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        }, {
            title: '操作',
            dataIndex: 'goodsId',
            key: 'goodsId',
            align: 'center',
            width: '9%',
            render: tableOperate
        }];

    return (
        <div className={styles.tableContainer}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={record => record.goodsId}
                columns={limit ? columns : columns.filter(item => item.key != 'limitNumber')}
                pagination={false}
                dataSource={dataList}
                scroll={{ x: limit ? '108%' : '100%', y: true }} />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange} />
        </div>
    );
};

SoldOutedTable.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
};

export default SoldOutedTable;