import * as T from './actiontypes';

//获取运费模板列表
export const getTemplateData = (params = {}) => ({
    type: T.GET_TEMPLATE_DATA,
    payload: params
});

//获取运费模板详情
export const getTemplateDetail = (id) => ({
    type: T.GET_TEMPLATE_DETAIL,
    payload: {
        id
    }
});