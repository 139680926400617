import React from 'react';
import CellFenToYuan from '@/vcomps/ShowCellMoney';
import styles from './TableData.module.scss';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
  {
    title: '对账单号',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: 150,
    render: (text) => (TableCell(text))
  },
  {
    title: '供应商',
    dataIndex: 'targetName',
    key: 'targetName',
    align: 'center',
    width: 200,
    render: (text) => (TableCell(text))
  },
  {
    title: '对账周期',
    dataIndex: 'rangeDate',
    key: 'rangeDate',
    align: 'center',
    width: 150,
    render: (text, record) => (
      <>
        <div>{record.startDate}</div>
        <div>{record.endDate}</div>
      </>
    )
  },
  {
    title: '应收(元)',
    dataIndex: 'revenue',
    key: 'revenue',
    align: 'center',
    width: 100,
    render: CellFenToYuan
  },
  {
    title: '应付(元)',
    dataIndex: 'expense',
    key: 'expense',
    align: 'center',
    width: 100,
    render: CellFenToYuan
  },
  {
    title: '结算金额(元)',
    dataIndex: 'settleAmount',
    key: 'settleAmount',
    align: 'center',
    width: 100,
    render: CellFenToYuan
  },
  {
    title: '已付金额(元)',
    dataIndex: 'paidAmount',
    key: 'paidAmount',
    align: 'center',
    width: 100,
    render: CellFenToYuan
  },
  {
    title: '未付金额(元)',
    dataIndex: 'unpaidAmount',
    key: 'unpaidAmount',
    align: 'center',
    width: 100,
    render: CellFenToYuan
  },

  {
    title: '关联明细单据',
    dataIndex: 'relationItems',
    key: 'relationItems',
    align: 'center',
    width: 100,
    render: (text) => (TableCell(text))
  },

  {
    title: '创建时间',
    dataIndex: 'gmtCreate',
    key: 'gmtCreate',
    align: 'center',
    width: 150,
    render: (text) => (TableCell(text))
  },
  {
    title: '结算方式',
    dataIndex: 'separateMethod',
    key: 'separateMethod',
    align: 'center',
    width: 150,
    render: (text) => (TableCell(text))
  },
  {
    title: '结算单生成',
    dataIndex: 'createUserName',
    key: 'createUserName',
    align: 'center',
    width: 100,
    render: (text) => (TableCell(text))
  },

  {
    title: '状态',
    dataIndex: 'statusDescript',
    key: 'statusDescript',
    align: 'center',
    width: 100,
    render: (text) => (TableCell(text))
  },

  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    width: 360,
    render: (id, record) => TableAction(record.id, actionClickHandle, record)
  },
];

export const takeDataSource = (data) => {
  // 解析表格数据，使符合columns格式要求
  // let tableSource = data.map((item)=>{

  //   return tableCell;
  // });
  return data;
};



export const takeColumns = (actionClickHandle) => {
  return columns(actionClickHandle);
};

const TableCell = (text) => {
  return (
    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
      {text}
    </div>
  );
};

const TableAction = (id, actionClickHandle, record) => {
  return (
    <div className={styles.action}>
      {record.status !== 'CONFIRM_SUCCESS' ? <div className={styles.item} onClick={() => actionClickHandle(id, 'ACCEPT')}>确认账单</div> : null}
      <div className={styles.item} onClick={() => actionClickHandle(id, 'DETAIL', record.separateMethodValue)}>查看详情</div>
      <div className={styles.item} onClick={() => actionClickHandle(id, 'Reconciliation')}>导出付款通知单</div>
      <div className={styles.item} onClick={() => actionClickHandle(id, 'IncomeRevenue')}>导出对账单模板</div>
      {record.status === 'CONFIRM_FAIL' && <div className={styles.item} onClick={() => actionClickHandle(id, 'DEL')}>删除</div>}
    </div>
  );
};