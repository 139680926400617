/**
 * 法大大弹框
 */
import React from 'react';
import styles from './index.module.scss';
import { Modal, message, Button, Spin } from 'antd';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import * as QRCode from 'easyqrcodejs';

class FadadaModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            loading: false,
            qrcodeState: 0, // 0：二维码生成中  1：二维码生成成功  2：二维码生成失败
            contentext: '',
        };
        this.qrcodeDowload = React.createRef();
    }

    componentDidMount() {
        http('/merchantEntering/merchantEnteringFinish', {}, 'POST')
            .then(res => {
                if (res.result) {
                    this.setState({ contentext: res.result.desc });
                    new QRCode(this.qrcodeDowload.current, { text: res.result.url });
                }
            })
            .catch(err => {
                message.error(err.message);
            });
        // this._httpSignStatus((response) => {
        //     const { isUnionFinish, isFadadaFinish, h5Url } = response;
        //     if (h5Url) {
        //         setTimeout(() => {
        //             this.setState({ qrcodeState: 1 });
        //             new QRCode(this.qrcodeDowload.current, { text: h5Url });
        //         }, 1000);
        //     } else {
        //         this.setState({ qrcodeState: 2 });
        //     }
        // });
    }

    _httpSignStatus = (callback) => {
        http('/contract/fadada/contractSignStatus').then((response) => {
            if (response.status == 200) {
                callback(response.result);
            }
        }).catch((e) => {
            message.error(e);
        });
    }

    _renderState = () => {
        const { qrcodeState } = this.state;
        switch (qrcodeState) {
            case 0:
                return <div className={styles.qrcode}><Spin size="large" tip='二维码生成中' /></div>;
            case 1:
                return <div className={styles.qrcode} ref={this.qrcodeDowload}></div>;
            case 2:
                return <div className={styles.qrcode}>请求二维码路径失败</div>;
            default:
                return null;
        }
    }

    _handleCancel = () => {
        this.props.closeMode();
    }

    _clickHandle = () => {
        this._httpSignStatus((response) => {
            const { isUnionFinish, isFadadaFinish, h5Url } = response;
            if (isFadadaFinish == 1 && isUnionFinish == 0) {
                message.error('电子合同签署成功');
                this.props.closeMode('yl');
            } else if (isFadadaFinish == 1 && isUnionFinish == 1) {
                message.error('电子合同签署成功');
                this.props.closeMode();
            } else if (isFadadaFinish == 0) {
                message.error('查询不到您的签署信息，请稍后再试');
            }
        });
    }

    render() {
        const { visible, loading, text, contentext } = this.state;

        return (
            <Modal
                width={640}
                centered
                title='系统提示'
                visible={visible}
                destroyOnClose={true}
                keyboard={false}
                maskClosable={false}
                footer={null}
                onCancel={this._handleCancel}
            >
                <div className={styles.container}>
                    <div className={styles.title}>{contentext}</div>
                    <div className={styles.qrcode} ref={this.qrcodeDowload}></div>
                    {/* {this._renderState()}商户入驻改造
 task2408                    <div className={styles.btn}>
                         <Button style={{ width: 150 }} type="primary" loading={loading} onClick={this._clickHandle}>下一步</Button>
                     </div> */}
                </div>
            </Modal>
        );
    }
}

FadadaModal.propTypes = {
    h5Url: PropTypes.string, // 法大大注册二维码地址
    isUnionFinish: PropTypes.bool, // 是否已经完成银联注册
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框回调事件
};

export default FadadaModal;