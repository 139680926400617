/**
 * 已核销
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import model from '@/reducers/chargeoffmanage/chargeofflist/model';
import TableComponent from './TableComponent.';

const { getTableData } = model.actions;

const Overchargeoff = ({
    history, addOneBreadcrumbPath, getTableData, tableData = {}
}) => {

    const [tel, setTel] = useState();
    const [orderId, setOrderId] = useState();
    const [pageNum, setPageNum] = useState(autoPageNum);
    const [pageSize, setPageSize] = useState(autopageSize);

    const onSearch = (params = {}) => {
        const query = { tel, orderId, state: 'CONSUMED', pageNum, pageSize, ...params };
        getTableData(query);
        KeepAlive.saveCache(query);
    };

    const onReset = () => {
        setTel(); setOrderId();
        KeepAlive.saveResetFunc(onSearch);
    };

    const onInit = (p = {}) => {
        setTel(p.tel); setOrderId(p.orderId); setPageNum(p.pageNum); setPageSize(p.pageSize);
        onSearch({ tel: p.tel, orderId: p.orderId, pageNum: p.pageNum, pageSize: p.pageSize });
    };

    const onOpearte = (type, params) => {
        const funcs = {
            page: p => {
                setPageNum(p.pageNum); setPageSize(p.pageSize); onSearch({ pageNum: p.pageNum, pageSize: p.pageSize });
            }
        };
        funcs[type](params);
    };

    return <KeepAlive id='Overchargeoff' render={onInit}>
        <div className={styles.flexBoxContainer}>
            <div className={styles.searchBox}>
                <XInput inputStyle={{ width: '150px' }} label='预留手机号' value={tel}
                    placeholder='请输入会员账号' bindThis={setTel} bindName='tel'
                />
                <XInput style={{ marginLeft: '20px' }} inputStyle={{ width: '150px' }} label='订单号' value={orderId}
                    placeholder='请输入订单号' bindThis={setOrderId} bindName='orderId'
                />
                <XOKButton style={{ marginLeft: '20px', width: '60px' }} label='查询' onClick={() => onSearch({})} />
                <XCancelButton style={{ marginLeft: '20px', width: '60px' }} label='重置' onClick={onReset} />
            </div>
            <TableComponent renderData={tableData} opearteFunc={onOpearte} history={history} addOneBreadcrumbPath={addOneBreadcrumbPath} />
        </div>
    </KeepAlive>;
};
Overchargeoff.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    getTableData: PropTypes.func,
    tableData: PropTypes.object,
};
export default connect(state => ({
    tableData: state.chargeofflist.tableData,
}), { addOneBreadcrumbPath, getTableData })(Overchargeoff);

