//获取并保存商品品牌列表
export const GET_BRANDAPPLY_LIST = 'BRANDAPPLY/GET_BRANDAPPLY_LIST';
export const SET_BRANDAPPLY_LIST = 'BRANDAPPLY/SET_BRANDAPPLY_LIST';

export const GET_BRANDAPPLY_STATESELE = 'BRANDAPPLY/GET_BRANDAPPLY_STATESELE';
export const SET_BRANDAPPLY_STATESELE = 'BRANDAPPLY/SET_BRANDAPPLY_STATESELE';

export const GET_BRANDAPPLY_BRANDSELE = 'BRANDAPPLY/GET_BRANDAPPLY_BRANDSELE';
export const SET_BRANDAPPLY_BRANDSELE = 'BRANDAPPLY/SET_BRANDAPPLY_BRANDSELE';

export const GET_BRANDAPPLY_DETAIL = 'BRANDAPPLY/GET_BRANDAPPLY_DETAIL';
export const SET_BRANDAPPLY_DETAIL = 'BRANDAPPLY/SET_BRANDAPPLY_DETAIL';