import React from 'react';
import styles from './OrderTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { regFenToYuan } from '@/util/money';

class OrderTable extends React.Component {
    _takeColumns = () => ([
        {
            title: '订单编号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: (text, record) => {
                return <div style={{ textAlign: 'right' }}>{record.merchantSponsorFlag == 1 && <img src={require('@/assets/image/drainage.png')} style={{ verticalAlign: 'top' }} />}{text}</div>;
            }
        },
        {
            title: '会员账号',
            dataIndex: 'accountTel',
            key: 'accountTel',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '批发金额(元)',
            dataIndex: 'wholesalePrice',
            key: 'wholesalePrice',
            width: 140,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        // {
        //     title: '订单金额(元)',
        //     dataIndex: 'payFee',
        //     key: 'payFee',
        //     width: 140,
        //     align: 'center',
        //     render: (text) => (this._renderTextMoneyCell(text))
        // },
        {
            title: '商品数量',
            dataIndex: 'number',
            key: 'number',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单状态',
            dataIndex: 'status',
            key: 'status',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单类型',
            dataIndex: 'orderType',
            key: 'orderType',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '消费类型',
            dataIndex: 'consumeType',
            key: 'consumeType',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否开票',
            dataIndex: 'isInvoice',
            key: 'isInvoice',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '支付方式',
            dataIndex: 'payType',
            key: 'payType',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '支付单号',
            dataIndex: 'payNumber',
            key: 'payNumber',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '付款时间',
            dataIndex: 'payTime',
            key: 'payTime',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '下单时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 160,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'orderId',
            key: 'id',
            align: 'center',
            width: 260,
            render: (text, record) => this._renderActionCell(text, record)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderActionCell = (key, record) => {
        const { tableAction } = this.props;
        // ****注意 
        // 待付款：查看详情 操作历史 取消
        // 已付款（待发货）：查看详情 操作历史 发货 取消
        // 部分发货：查看详情 操作历史 查看物流 取消
        // 已发货(待收货)：查看详情 操作历史 查看物流 取消
        // 已收货：查看详情 操作历史 查看物流 取消
        // 已完成：查看详情 操作历史 查看物流
        // 已取消：查看详情 操作历史

        switch (record.status) {
            case '待付款':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
                        {/* <div className={styles.item} onClick={() => tableAction(key, 'business')}>联系商户</div> */}
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>
                    </div>
                );
            case '已付款':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
                        {/* <div className={styles.item} onClick={() => tableAction(key, 'business')}>联系商户</div> */}
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>
                    </div>
                );
            case '部分发货':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'logistics', record)}>查看物流</div>
                        {/* <div className={styles.item} onClick={() => tableAction(key, 'business')}>联系商户</div> */}
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>
                    </div>
                );
            case '已发货':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'logistics', record)}>查看物流</div>
                        {/* <div className={styles.item} onClick={() => tableAction(key, 'business')}>联系商户</div> */}
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>
                    </div>
                );
            case '已收货':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'logistics', record)}>查看物流</div>
                        {/* <div className={styles.item} onClick={() => tableAction(key, 'business')}>联系商户</div> */}
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>
                    </div>
                );
            case '已完成':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'logistics', record)}>查看物流</div>
                        {/* <div className={styles.item} onClick={() => tableAction(key, 'business')}>联系商户</div> */}
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>
                    </div>
                );
            case '已取消':
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'history', record)}>操作记录</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>
                    </div>
                );
            default:
                return (
                    <div className={styles.action}>
                        <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>查看详情</div>
                        <div className={styles.item} onClick={() => tableAction(key, 'leaveMsg', record)}>卖家留言</div>
                    </div>
                );
        }
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
        }
    };

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: '140%', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default OrderTable;