/**
 * 【银联绑定银行卡】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Form, Button, Input, message, Select, Spin } from 'antd';
import { XTitle } from '@/components/xqxc_ui';

const Option = Select.Option;
const formItemLayout = {
    labelCol: {
        xs: { span: 6 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 18 },
        sm: { span: 18 },
    },
};
class Main extends React.Component {

    state = {
        loading: false, // 加载中
        required: true,
        legalName: '', // 法人姓名
        bankCardNo: '', // 银行卡账号
        proviceList: [], // 省份下拉列
        proviceData: { code: '', areaName: undefined }, // 省份选项
        cityList: [], // 城市下拉列
        cityData: { code: '', areaName: undefined }, // 城市选项
        areaList: [], // 区域下拉列
        areaData: { code: '', areaName: undefined }, // 区域选项
        branchList: [],  // 开户行支行数据
        branchBankName: undefined, // 开户支行名称
        bankBranchCode: '', // 开户行支行号
    }

    componentDidMount() {
        this._getAddressList();
        this._init();
    }

    // 初始化信息
    _init = () => {
        http('/walletUnion/getMerchantLegalBindBankCard', {}, 'GET').then((response) => {
            if (response.status == 200) {
                const { legalName, bankCardNo, bankBranchName, bankBranchCode, province, provinceId, city, cityId, country, countryId } = response.result;
                this.setState({
                    legalName,
                    bankCardNo,
                    branchBankName: bankBranchName,
                    bankBranchCode: bankBranchCode,
                    proviceData: { code: provinceId, areaName: province ? province : undefined },
                    cityData: { code: cityId, areaName: city ? city : undefined },
                    areaData: { code: countryId, areaName: country ? country : undefined },
                });
                if (provinceId) {
                    this._getAddressList(2, provinceId);
                }
                if (cityId) {
                    this._getAddressList(3, cityId);
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 请求省市区
    _getAddressList = (levelType = 1, parentId = undefined) => {
        http('/union/merchant/areaList', { levelType, parentId }).then((response) => {
            if (response.status == 200) {
                if (levelType == 1) {
                    this.setState({ proviceList: response.result });
                } else if (levelType == 2) {
                    this.setState({ cityList: response.result });
                } else if (levelType == 3) {
                    this.setState({ areaList: response.result });
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 请求支行下拉列
    _getBankList = (cityId = '', keyword = '') => {
        http('/union/merchant/branchBankList', { cityId, keyword }).then((response) => {
            if (response.status == 200) {
                this.setState({ branchList: response.result, fetching: false });
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _handleSubmit = e => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const { proviceData, cityData, areaData } = this.state;
                const query = {
                    legalName: fieldsValue.legalName, // 法人姓名
                    bankCardNo: fieldsValue.bankCardNo, // 法人银行卡号
                    bankBranchName: fieldsValue.branchBankName, // 开户行支行名称
                    bankBranchCode: this.state.bankBranchCode, // 开户行支行号
                    province: proviceData.areaName, // 省份名称
                    provinceId: proviceData.code, // 省份code
                    city: cityData.areaName, // 市名称
                    cityId: cityData.code, // 市code
                    country: areaData.areaName, // 区名称
                    countryId: areaData.code, // 区code
                };
                http('/walletUnion/merchantLegalBindBankCard', query).then((response) => {
                    if (response.status == 200) {
                        message.success('提交成功');
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
            }
        });
    }

    render() {
        const { loading, required, legalName, bankCardNo, branchBankName, bankBranchCode } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <div className={styles.container}>
                <XTitle label='绑定银行卡' />
                <br />
                <Form autoComplete='off' {...formItemLayout} onSubmit={this._handleSubmit}>
                    <Form.Item
                        colon={false}
                        htmlFor='legalName'
                        label={<span>法人姓名：</span>}
                    >
                        {getFieldDecorator('legalName', {
                            rules: [
                                { required, message: '系统无法获取法人信息' }
                            ],
                            initialValue: legalName
                        })(<Input disabled placeholder='由系统自动获取' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='bankCardNo'
                        label={<span>银行账号：</span>}
                    >
                        {getFieldDecorator('bankCardNo', {
                            rules: [
                                { required, message: '请输入24位或以下数字组成的银行账号(法人个人银行卡账号)' },
                                {
                                    validator: (rule, value = '', callback) => {
                                        var pattern = /^\d{0,24}$/;
                                        // 去除空格
                                        var str = value.replace(/\s+/g, '');
                                        if (pattern.test(str)) {
                                            callback();
                                        } else {
                                            callback('请输入24位或以下数字组成的银行账号(法人个人银行卡账号)');
                                        }
                                    }
                                }
                            ],
                            initialValue: bankCardNo
                        })(<Input placeholder='请输入24位或以下数字组成的银行账号(法人个人银行卡账号)' />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        label={<span><span style={{ color: 'red' }}> * </span>省份/城市/区域：</span>}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Select
                                style={{ marginRight: '10px' }}
                                placeholder="省份"
                                showSearch
                                value={this.state.proviceData.areaName}
                                onChange={(value, item) => {
                                    this.setState({ proviceData: item.props.data, cityData: {}, areaData: {}, cityList: [], areaList: [], branchBankName: '', bankBranchCode: '' }, () => {
                                        this.props.form.setFieldsValue({ branchBankName: undefined });
                                        this._getAddressList(2, item.props.data.id);
                                    });
                                }}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.proviceList && this.state.proviceList.map((item, index) => {
                                    return <Option key={index} value={item.areaName} data={item}>{item.areaName}</Option>;
                                })}
                            </Select>
                            <Select
                                style={{ marginRight: '10px' }}
                                placeholder="城市"
                                showSearch
                                value={this.state.cityData.areaName}
                                onChange={(value, item) => {
                                    this.setState({ cityData: item.props.data, areaData: {}, areaList: [], branchBankName: '', bankBranchCode: '' }, () => {
                                        this.props.form.setFieldsValue({ branchBankName: undefined });
                                        this._getAddressList(3, item.props.data.id);
                                    });
                                }}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.cityList && this.state.cityList.map((item, index) => {
                                    return <Option key={index} value={item.areaName} data={item}>{item.areaName}</Option>;
                                })}
                            </Select>
                            <Select
                                placeholder="区域"
                                showSearch
                                value={this.state.areaData.areaName}
                                onChange={(value, item) => {
                                    this.setState({ areaData: item.props.data, branchBankName: '', bankBranchCode: '' }, () => {
                                        this.props.form.setFieldsValue({ branchBankName: undefined });
                                    });
                                }}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.areaList && this.state.areaList.map((item, index) => {
                                    return <Option key={index} value={item.areaName} data={item}>{item.areaName}</Option>;
                                })}
                            </Select>
                        </div>
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='branchBankName'
                        label={<span>开户行支行名称：</span>}
                    >
                        {getFieldDecorator('branchBankName', {
                            rules: [
                                { required, message: '请填写开户行支行名称' },
                            ],
                            initialValue: branchBankName
                        })(
                            <Select
                                showSearch
                                showArrow={false}
                                placeholder="根据输入结果智能搜索开户行支行名称"
                                notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                                filterOption={false}
                                onSearch={(value) => {
                                    if (this.state.areaData.code) {
                                        this.setState({ data: [], fetching: true });
                                        this._getBankList(this.state.areaData.code, value);
                                    } else {
                                        message.error('请先选择开户支行对应的省、市、区位置');
                                    }
                                }}
                                onChange={(value, item) => {
                                    this.setState({ branchBankName: value, bankBranchCode: item.key, branchList: [], fetching: false });
                                }}
                                style={{ width: '100%' }}
                            >
                                {this.state.branchList.map(item => (
                                    <Option key={item.code} value={item.bankBranchName}>{item.bankBranchName}</Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <div className={styles.btn}>
                        <Button style={{ width: '150px' }} type="primary" htmlType="submit" loading={loading}>提交</Button>
                    </div>
                </Form>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    form: PropTypes.object, // 表单校验
};
const stepForm = Form.create({ name: 'step-form' })(Main);
export default stepForm;
