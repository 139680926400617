/**
 * 客服列表
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import http from '@/assets/api/http';
import { XInput, XDatePicker, XOKButton, XCancelButton, XSelect, showConfirm } from '@/components/xqxc_ui';
import KeepAlive from '@/routes/KeepAlive';
import { message } from 'antd';
import TableContent from './components/TableContent';
import { autoPageNum, autopageSize } from '@/assets/config';
import model from '@/reducers/customermanage/customerList/model';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { setBreadcrumbPaths } from '@/reducers/home/actions';
import { connect } from 'react-redux';

class Main extends React.Component {

    state = {
        startTime: null,
        endTime: null,
        entity: {}
    };

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
        this.props.getfindShopDropDownInfoList();
    }
    _searchHandle = (useCache) => {
        const { entity, groupNo, startTime, endTime, pageNum, pageSize } = this.state;
        this.searchCache = {
            groupNo,
            startTime,
            endTime,
            entityId: entity.shopId,
            entityType: entity.shopType,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.props.getlistShopKfGroupInfo(this.searchCache);
    }
    // 重置
    _resetHandle = () => {
        this.setState({
            groupNo: undefined,
            entity: {},
            startTime: null,
            endTime: null,
            pageNum: 1,
            pageSize: 30,
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    _newHandle = () => {
        const { addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建绑定',
            path: '/home/customermanage/customerlist/create'
        });
        this.props.history.push({
            pathname: '/home/customermanage/customerlist/create',
        });
    }
    _tableAction = (id, type, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'edit':
                addOneBreadcrumbPath({
                    title: '编辑绑定',
                    path: `/home/customermanage/customerlist/edit/${record.entityId}/${record.entityType}`
                });
                history.push({
                    pathname: `/home/customermanage/customerlist/edit/${record.entityId}/${record.entityType}`,
                });
                break;
            case 'del':
                showConfirm('删除确认', '客服绑定信息删除后无法恢复，确定删除此客服绑定信息吗？', () => {
                    http('/shopKfGroupInfo/delShopKfGroupInfo', { entityId: record.entityId, entityType: record.entityType }, 'POST')
                        .then((res) => {
                            if (res.status == 200) {
                                message.success('删除成功!');
                                this._searchHandle('useCache');
                            }
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
        }
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {

        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });

    }
    render() {
        const { entity, groupNo, startTime, endTime } = this.state;
        const { listShopKfGroupInfo, ShopDropDownInfoList } = this.props;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.table_body}>
                        <XSelect
                            style={{ width: '216px' }}
                            selectStyle={{ width: '150px' }}
                            label='店铺名称'
                            placeholder='请输入店铺名称'
                            renderData={ShopDropDownInfoList}
                            dataIndex='shopName'
                            keyIndex='shopId'
                            value={entity.shopName}
                            showSearch={true}
                            bindThis={this}
                            bindName='entity'
                        />

                        <XInput
                            style={{ width: '248px', marginLeft: '20px' }}
                            inputStyle={{ width: '180px' }}
                            label='分组编号'
                            placeholder='请输入分组编号'
                            value={groupNo}
                            bindThis={this}
                            bindName='groupNo'
                        />

                        <XDatePicker
                            style={{ width: '248px', marginLeft: '20px' }}
                            label='更新时间'
                            value={startTime}
                            bindThis={this}
                            bindName='startTime'
                            isFormat
                        />
                        <XDatePicker
                            style={{ marginLeft: '20px', marginRight: '20px', color: '#252525' }}
                            value={endTime}
                            bindThis={this}
                            bindName='endTime'
                            isFormat
                        />

                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.table_body}>
                        <XOKButton style={{ width: 90 }} label='新建绑定' onClick={this._newHandle} />
                    </div>
                    <TableContent
                        renderData={listShopKfGroupInfo}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                    />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    setBreadcrumbPaths: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getlistShopKfGroupInfo: PropTypes.func,
    listShopKfGroupInfo: PropTypes.object,
    getfindShopDropDownInfoList: PropTypes.func,
    ShopDropDownInfoList: PropTypes.array
};
const mapStateToProps = (state) => ({
    listShopKfGroupInfo: state.customerList.listShopKfGroupInfo,
    ShopDropDownInfoList: state.customerList.ShopDropDownInfoList
});

export default connect(mapStateToProps, { ...model.actions, addOneBreadcrumbPath, setBreadcrumbPaths, keepSecondNavBreadcrumb })(Main);