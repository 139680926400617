/**
 * 增加销量
 */
import React from 'react';
import { Modal, InputNumber, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './AddSalesModal.module.scss';
import http from '@/assets/api/http';

class AddSalesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            count: this.props.data.currentAddedSales,//增加销量
            sales: this.props.data.currentAddedSales + this.props.data.virtualSalesVolume + this.props.data.realSalesVolume || 0,//前端展示销量
            virtualCount: this.props.data.virtualSalesVolume,//虚拟规则销量
            actual: this.props.data.realSalesVolume,//实际销量
        };
    }
    _handleOk = () => {
        const { count, sales } = this.state;
        const { activityGoodsId, activityId } = this.props.data;

        http('/marketing/activityMerchantInfo/addSalesVolume', {
            activityGoodsId,
            activityId,
            addedSales: count,
            pageShowSalesVolume: sales
        }, 'POST').then(() => {
            message.success('提交成功');
            this.setState({
                visible: false,
            });
            this.props.closeMode(true);
        }).catch((reject) => {
            message.warn(reject.message);
            this.setState({
                visible: false,
            });
            this.props.closeMode();
        });
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title, count, sales, virtualCount, actual } = this.state;

        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.label}><label>实际销量</label><span>{actual} 件</span></div>
                    <div className={styles.label}><label>虚拟规则销量</label><span>{virtualCount} 件</span></div>
                    <div>
                        <span>增加销量</span>
                        <InputNumber
                            className={styles.top}
                            placeholder='请输入(件)'
                            precision={0}
                            min={0}
                            step={1}
                            value={count}
                            onChange={(value) => {
                                const dataCount = actual + virtualCount + value;
                                console.log('jisuan', dataCount);
                                this.setState({
                                    count: value,
                                    sales: dataCount
                                });
                            }}
                        />
                        <span>件</span>
                    </div>
                    <div className={styles.label}><label>前端展示销量</label><span>{sales} 件</span></div>
                </div>
            </Modal>
        );

    }
}

AddSalesModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default AddSalesModal;