import * as T from './actiontypes';

export const getSupplierListTableSource = (value) => ({
    type: T.GET_SUPPLIERLIST_TABLESOURCE,
    payload: value
});

export const getShopTypes = (value) => ({
    type: T.GET_SHOPTYPES,
    payload: value
});

export const getGoodsTypes = (value) => ({
    type: T.GET_GOODSTYPES,
    payload: value
});

/**
 * 获取仓库类型
 */
export const getWarehouseType = (value = {}) => ({
    type: T.GET_WAREHOUSE_TYPE,
    payload: value
});

/**
 * 获取省市区
 */
export const getListAreaByParentId = (value = {}) => ({
    type: T.GET_ACCOUNT_AREA,
    payload: value
});

/**
 * 获取开票单位下拉列
 */
export const getInvoicelSelect = (value = {}) => ({
    type: T.GET_INVOICEL_SELECT,
    payload: value
});
/**
 * 获取服务商下拉列
 */
export const getlistServiceProviderKetName = (value = []) => ({
    type: T.GET_SUPPLIER_SELECT,
    payload: value
});

/**
 * 实体加盟中运营中心、区域、数据依据地址的市，区参数
 * @param cityId 市级
 * @param areaId 区域级
 */
export const getradiotype = (dataload) => ({
    type: T.GET_RADIOTYPE_DATA,
    payload: dataload
});
