import React from 'react';
import { Modal, Input, DatePicker, message } from 'antd';
import PropTypes from 'prop-types';
import { XSelect, XTextArea } from '@/components/xqxc_ui';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { formValids } from '@/assets/js/utils';
import http from '@/assets/api/http';
import moment from 'moment';
import styles from './GenerateStatement.module.scss';

class AcceptModal extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,

        remark: '',
        suppler: []
    }

    _handleOk = () => {

        const { suppler, startDate, endDate, remark } = this.state;
        const saveProps = {
            targetId: suppler.id,
            targetName: suppler.optionName,
            startDate: startDate ? startDate.format('YYYY-MM-DD 00:00:00') : undefined,
            endDate: endDate ? endDate.format('YYYY-MM-DD 23:59:59') : undefined,
            remark
        };

        if (formValids(saveProps, {
            targetId: '请选择供应商',
            startDate: '请选择对账周期的时间范围',
            endDate: '请选择对账周期的时间范围'
        })) {
            http('/settle/reconciliationInfo/addInvoiceList', saveProps).then((res) => {
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }).catch(res => {
                message.error(res.message);
            });
        }
    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _radioValueChangeHandle = (e) => {
        this.setState({ radioValue: e.target.value });
    }

    _areaChangeHandle = (e) => {
        this.setState({ areaValue: e.target.value });
    }

    render() {
        const { visible, confirmLoading, title, remark, suppler } = this.state;

        return (
            <Modal
                width={520}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.top}>
                        <span>供应商：</span>
                        <p>
                            <XSelect
                                selectStyle={{ width: '380px' }}
                                placeholder='请选择组供应商'
                                showSearch
                                renderData={this.props.supplierList}
                                onChange={this._cityChangeHandle}
                                dataIndex='optionName'
                                keyIndex='id'
                                value={suppler.optionName}
                                bindThis={this}
                                bindName='suppler'
                            />
                        </p>
                    </div>
                    <div className={styles.top}>
                        <span>对账周期：</span>
                        <p>
                            <DatePicker.RangePicker locale={locale} style={{ width: '380px', marginLeft: 10 }} format='YYYY-MM-DD' showTime
                                onChange={(e) => { if (!e.length) this.setState({ startDate: undefined, endDate: undefined }); }}
                                onOk={(e) => { this.setState({ startDate: e[0], endDate: e[1] }); }}
                            />
                        </p>
                    </div>
                    <div className={styles.top}>
                        <span>备注：</span>
                        <p>
                            <XTextArea
                                style={{ width: '100%' }}
                                inputStyle={{ width: '380px' }}
                                value={remark}
                                bindThis={this}
                                bindName='remark'
                            />
                        </p>
                    </div>
                </div>
            </Modal>
        );
    }
}

AcceptModal.propTypes = {
    supplierList: PropTypes.array,
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string // 弹框标题
};

export default AcceptModal;