import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import KeepAlive from '@/routes/KeepAlive';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { autoPageNum, autopageSize } from '@/assets/config';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { SearchBox, SearchItem } from '@/components/Layout';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';
import { XOKButton, XCancelButton, XInput, XDatePicker, XPagination } from '@/components/xqxc_ui';
import { Form, Input, message, Modal, Radio, Table } from 'antd';
import model from '@/reducers/storehousefinancialmanage/reconciliationdata/model';
import BigNumber from 'bignumber.js';

const { getAdvanceTableData } = model.actions;

const Reconciliation = ({
    tableAction, data: { id, renderModal, finishTransferAmount } = {},
    form: { getFieldDecorator, validateFields } = {}
}) => {

    const [loading, setLodaing] = useState(false);

    const onSubmit = e => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                const { recordId, operationType, remark } = values;
                setLodaing(true);
                http('/settle/reconciliationInfo/merchant/prepaid/updateReconciliationStatus', {
                    recordId, operationType, remark
                }, 'POST').then(res => {
                    message.success(res.message);
                    setLodaing(false);
                    tableAction(true, 'closeModal');
                }).catch(err => {
                    message.error(err.message);
                    setLodaing(false);
                });
            }
        });
    };

    return <Modal
        width={460}
        bodyStyle={{ paddingBottom: 0 }}
        centered
        visible={renderModal}
        confirmLoading={loading}
        title='确认对账'
        onCancel={() => tableAction(false, 'closeModal')}
        onOk={onSubmit}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
    >
        <div className={styles.modalContainer}>
            <Form
                onSubmit={onSubmit}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
            >
                <Form.Item label='对账单号'>
                    {getFieldDecorator('recordId', {
                        initialValue: id,
                        rules: [{ required: true, message: '请输入对账单号' }],
                    })(<Input placeholder='请输入对账单号' disabled={true} />)}
                </Form.Item>
                <Form.Item label='对账金额'>
                    {getFieldDecorator('finishTransferAmount', {
                        initialValue: new BigNumber(finishTransferAmount || 0).dividedBy(100).toString(),
                        rules: [{ required: true, message: '请输入对账金额' }],
                    })(<Input placeholder='请输入对账金额' disabled={true} />)}
                </Form.Item>
                <Form.Item label='对账确认'>
                    {getFieldDecorator('operationType', {
                        initialValue: 'MERCHANT_RECONCILIATION',
                        rules: [{ required: true, message: '请选择汇款状态' }],
                    })(<Radio.Group>
                        <Radio value='MERCHANT_RECONCILIATION'>通过</Radio>
                        <Radio value='MERCHANT_REJECTED'>不通过</Radio>
                    </Radio.Group>)}
                </Form.Item>
                <Form.Item label='对账说明'>
                    {getFieldDecorator('remark', {
                        rules: [{ type: 'string', max: 100, message: '对账说明最大长度是100' }]
                    })(<Input.TextArea placeholder='请输入对账说明' autoSize={{ minRows: 3, maxRows: 5 }} />)}
                </Form.Item>
            </Form>
        </div>
    </Modal>;
};
Reconciliation.propTypes = {
    form: PropTypes.object,
    tableAction: PropTypes.func,
    data: PropTypes.object
};
const ReconciliationForm = Form.create()(Reconciliation);

const TableComponent = ({
    renderData: {
        pageNum = 1,
        pageSize = 30,
        resultTotal = 0,
        dataList = []
    } = {},
    paginationChange,
    tableAction,
    selectedRowKeys = []
}) => {

    const tableOperate = (id, r) => {
        return <div className={styles.action}>
            <div className={styles.item} onClick={() => tableAction(r, 'detail')}>对账详情</div>
            {r.status === 'AWAITING_RECONCILIATION' && <div className={styles.item} onClick={() => tableAction(r, 'reconciliation')}>确认对账</div>}
        </div>;
    };

    const columns = [
        {
            title: '对账单号',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '对账周期',
            dataIndex: 'billingCycle',
            key: 'billingCycle',
            align: 'center',
            width: 300,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            align: 'center',
            width: 240,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '完成交易金额(元)',
            dataIndex: 'finishOrderAmount',
            key: 'finishOrderAmount',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '收单手续费金额(元)',
            dataIndex: 'finishHandlingAmount',
            key: 'finishHandlingAmount',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '服务费(元)',
            dataIndex: 'finishServiceAmount',
            key: 'finishServiceAmount',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '应收金额(元)',
            dataIndex: 'finishTransferAmount',
            key: 'finishTransferAmount',
            align: 'center',
            width: 100,
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '订单数',
            dataIndex: 'finishOrderNum',
            key: 'finishOrderNum',
            align: 'center',
            width: 100,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '生成时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            align: 'center',
            width: 100,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'subjectId',
            key: 'subjectId',
            align: 'center',
            fixed: 'right',
            width: 180,
            onCell: tooltipStyle,
            render: tableOperate
        }
    ];

    // 表格复选输入
    const rowSelection = {
        selectedRowKeys,
        onChange: keys => tableAction(keys, 'batchKeys')
    };

    return <div className={styles.tableContainer}>
        <Table
            rowClassName={(r, i) => i % 2 === 0 ? '' : styles.tdcolor}
            rowKey='id'
            columns={columns}
            rowSelection={rowSelection}
            dataSource={dataList}
            pagination={false}
            scroll={{ x: 'max-content', y: true }}
        />
        <XPagination
            resultTotal={resultTotal}
            pageSize={pageSize}
            pageNum={pageNum}
            onChange={paginationChange}
        />
    </div>;
};
TableComponent.propTypes = {
    history: PropTypes.object,
    renderData: PropTypes.object,
    paginationChange: PropTypes.func,
    tableAction: PropTypes.func,
    selectedRowKeys: PropTypes.array
};

const AdvanceTable = ({
    history,
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getAdvanceTableData,
    tableData
}) => {

    const [id, setId] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [pageNum, setPageNum] = useState(autoPageNum);
    const [pageSize, setPageSize] = useState(autopageSize);
    const [renderModal, setRenderModal] = useState(false);
    const [modalParams, setModalParams] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        keepSecondNavBreadcrumb();
    }, []);

    const getTableData = (params = {}) => {
        getAdvanceTableData({
            id, startTime: params.startTime, endTime: params.endTime,
            pageNum: params.pageNum, pageSize: params.pageSize
        });
        KeepAlive.saveCache(params);
    };

    const paginationChange = (ps, pn) => {
        setPageNum(pn);
        setPageSize(ps);
        getTableData({ id, startTime, endTime, pageNum: pn, pageSize: ps });
    };

    const resetSearch = () => {
        setId(); setStartTime(); setEndTime();
        KeepAlive.saveResetFunc(() => getTableData({ id, startTime, endTime, pageNum, pageSize }));
    };

    const tableAction = (key, type) => {
        const baseUrl = '/home/storehousefinancialmanage/dailystatement';
        const jumpPage = (path, title) => {
            addOneBreadcrumbPath({ title, path: `${baseUrl}${path}` });
            history.push({ pathname: `${baseUrl}${path}` });
        };

        const eventObject = {
            detail: p => jumpPage(`/advanceinfo/${p.id}/${p.merchantId}/${p.billingCycle}`, '明细列表'),
            reconciliation: p => {
                setRenderModal(true);
                setModalParams(p);
            },
            closeModal: p => {
                setRenderModal(false);
                setModalParams();
                p && getTableData({ id, startTime, endTime, pageNum, pageSize });
            },
            batchKeys: p => setSelectedRowKeys(p)
        };
        eventObject[type](key);
    };

    const onChange = (p = {}) => {
        if (p.id) setId(p.id);
        if (p.startTime) setStartTime(p.startTime);
        if (p.endTime) setEndTime(p.endTime);
        if (p.pageNum != autoPageNum) setPageNum(p.pageNum);
        if (p.pageSize != autopageSize) setPageSize(p.pageSize);
        getTableData(p);
    };

    const generateReport = () => {
        if (selectedRowKeys.length) {
            setLoading(true);
            http('/report/template/info', { id: 202102283201812 }).then(res => {
                if (res.code === 200) {
                    const queryCriteriaList = res.result.queryCriteriaList || [];
                    const purpose = '预付款对账单';
                    const values = {
                        recIds: selectedRowKeys.join(','),
                        startTime, endTime
                    };
                    queryCriteriaList.forEach(r => r.value = values[r.conditionFiled]);
                    http('/report/record/addRecord', { templateId: 132, queryCriteriaList, purpose }).then(res => {
                        if (res.code === 200) message.success('生成报表成功');
                    }).catch((res = {}) => message.error(res.message || '生成报表异常'));
                }
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                message.error(err.message || '获取模板数据异常');
            });
        } else {
            message.warning('请选择一条记录');
        }
    };

    return <KeepAlive render={onChange}>
        <div className={styles.flexBoxContainer}>
            <SearchBox>
                <SearchItem>
                    <XInput
                        label='对账单号'
                        placeholder='请输入'
                        style={{ marginRight: '20px' }}
                        value={id}
                        bindThis={setId}
                        bindName='id'
                    />
                </SearchItem>
                <SearchItem>
                    <XDatePicker
                        placeholder='请选择开始时间'
                        label='对账日期'
                        value={startTime}
                        bindThis={setStartTime}
                        bindName='startTime'
                        isFormat={true}
                        showTime={false}
                        defaultTime='00:00:00'
                    />
                    <XDatePicker
                        placeholder='请选择结束时间'
                        style={{ marginRight: '20px' }}
                        value={endTime}
                        bindThis={setEndTime}
                        bindName='endTime'
                        isFormat={true}
                        showTime={false}
                        defaultTime='23:59:59'
                    />
                </SearchItem>
                <SearchItem>
                    <XOKButton
                        label='查询'
                        style={{ marginRight: '20px' }}
                        onClick={() => getTableData({ id, startTime, endTime, pageNum, pageSize })}
                    />
                    <XCancelButton
                        label='重置'
                        onClick={resetSearch}
                    />
                </SearchItem>
            </SearchBox>
            <div><XOKButton
                label='生成报表'
                loading={loading}
                style={{ width: '100px' }}
                onClick={generateReport}
            /></div>
            <TableComponent renderData={tableData} selectedRowKeys={selectedRowKeys}
                tableAction={tableAction} paginationChange={paginationChange} />
            <ReconciliationForm tableAction={tableAction} data={{ ...modalParams, renderModal, selectedRowKeys }} />
        </div>
    </KeepAlive>;
};
AdvanceTable.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getAdvanceTableData: PropTypes.func,
    tableData: PropTypes.object,
};
export default connect(state => ({
    tableData: state.reconciliationdata.advanceTableData,
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getAdvanceTableData
})(AdvanceTable);