/**
 *预览弹框
 */
import React, { useState, useRef, useEffect } from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import styles from './PreviewModal.module.scss';
import { ImageLoad } from '../imageLoad';
import ViewerExtension from '../Viewer';

const bodyStyle = {//样式
    padding: '5px'
};
const imageLoad = new ImageLoad();

const initWheelStyle = {
    transformOrigin: 'initial',
    msTransformOrigin: 'initial',
    WebkitTransformOrigin: 'initial',
    transition: 'width 0.3s ease-out',
    MozTransition: 'width 0.3s ease-out',
    WebkitTransition: 'width 0.3s ease-out',
    OTransition: 'width 0.3s ease-out',
};

export default function PreviewModal({ data, previewVisible, previewClose }) {
    const { dataType, previewItem } = data;
    const [visible, setVisible] = useState(previewVisible);//是否显示modal
    const [picturePreWidth, setPicturePreWidth] = useState(620);//默认Modal宽度 620px
    const videoRef = useRef();//视频
    const audioRef = useRef(); //音频
    const imgRef = useRef(); //图片
    const modalRef = useRef(); //modal
    const extensionRef = useRef(); //ViewerExtension组件
    const [modalStyles, setModalStyles] = useState({});//默认Modal样式
    const [visibleExtension, setVisibleExtension] = useState(false);
    const saveWidthModalRef = useRef(); //初始化保存默认Modal宽度

    //拖拽Modal初始值 left-0;top:100,由于全局定义 top:0,
    const [styleTop, setStyleTop] = useState(0);
    const [styleLeft, setStyleLeft] = useState(0);

    const previewHandleCancel = () => {
        setVisible(false);
        if (dataType == 'audio') {
            previewClose(audioRef.current);
        } else if (dataType == 'video') {
            previewClose(videoRef.current);
        } else {
            previewClose();
        }
    };

    //Modal渲染时imgRef.current获取不到，dom没有渲染完成,需加载完成
    const imageOnLoad = () => {
        //默认Modal宽度 620px
        //图片类型预览Modal宽度自适应 Modal渲染时imgRef.current获取不到，dom没有渲染完成
        if (dataType == 'image' && (previewItem.toLowerCase().indexOf('.pdf') < 0)) {
            imageLoad.setImgSize(imgRef.current, (imgDom) => {
                imgDom.naturalWidth >= 620 ? setPicturePreWidth(620) : setPicturePreWidth(imgDom.naturalWidth <= 416 ? 416 : imgDom.naturalWidth);//宽度
                imgDom.naturalWidth >= 620 ? saveWidthModalRef.current = 620 : saveWidthModalRef.current = (imgDom.naturalWidth <= 416 ? 416 : imgDom.naturalWidth);//保存宽度
            });
        } else {
            return 620;
        }
    };

    //Modal渲染时有过渡效果，ViewerExtension组件position：fixed布局错乱
    useEffect(() => {
        const timer = setTimeout(() => {
            if (visible && imgRef.current) {
                setVisibleExtension(true);
            }
        }, 450);
        return () => {
            clearTimeout(timer);
        };
    }, [imgRef.current]);


    //拖拽Modal事件---由于图片height可能会有y轴滚动条，这边暂不做超出屏幕边界处理和监听resize
    const onMouseDownModal = e => {
        if (e) e.preventDefault();
        //记录初始移动的鼠标位置 
        let startPosX = e.clientX;
        let startPosY = e.clientY;

        //添加鼠标移动事件
        document.body.onmousemove = e => {
            imgRef.current.style.cursor = 'move';
            let left = e.clientX - startPosX + styleLeft;
            let top = e.clientY - startPosY + styleTop;
            setStyleTop(top);
            setStyleLeft(left);
        };
        //鼠标放开时去掉移动事件
        document.body.onmouseup = function () {
            document.body.onmousemove = null;
        };
    };

    //Modalstyle属性
    const modalstyle = (styleStr) => {
        setModalStyles(styleStr);
    };

    //重置拖拽原坐标
    const dragDropFunc = (coordinate = {}) => {
        setStyleTop(coordinate.top);
        setStyleLeft(coordinate.left);
    };

    //重置时还原初始化保存默认Modal宽度
    const saveWidthModalFunc = (saveProps) => {
        if (saveProps != picturePreWidth) {
            setPicturePreWidth(saveProps);
        }
    };

    //鼠标滚轮缩放事件:这边暂不做超出屏幕边界处理和监听resize 方向：上下 ；浏览器360，谷歌nativeEvent.deltaY(+-100)，火狐+-3
    const wheelZoomModal = (e) => {
        let { clientWidth, style = {} } = imgRef.current;
        //可以设置一些属性：left
        style.transition = 'width 0.3s ease-out';
        //避免该组件ViewerExtension未挂载
        if (extensionRef.current) setModalStyles(extensionRef.current.transformStyle);
        if (e.nativeEvent.deltaY <= 0 && clientWidth + 10 < 1000) {//向上 放大max-size:1000
            //图片宽度+40
            let widthZoom = `${clientWidth + 40}px`;
            style.width = widthZoom;
            setPicturePreWidth(clientWidth + 50);
        } else if (e.nativeEvent.deltaY <= 0 && clientWidth + 10 >= 1000) {
            return message.warning('已滚动缩放至最大范围');
        } else if (e.nativeEvent.deltaY > 0) {//向下
            if (clientWidth > 416) {//这边图片min-Size：416px：由于设置padding属性原因需-40，
                //图片宽度-10
                let widthZoom = `${clientWidth - 40}px`;
                style.width = widthZoom;
                setPicturePreWidth(clientWidth - 30);
            } else if (clientWidth <= 416) {
                setPicturePreWidth(416);
                return message.warning('已滚动缩放至最小范围');
            }
        }
    };

    //图片点击缩放事件 ZoomIn ZoomOut 这边暂不做超出屏幕边界处理和监听resize  @zoomIn-加，zoomOut-减;
    const handleZoomEvent = (zoom) => {
        let { clientWidth, style = {} } = imgRef.current;
        //可以设置一些属性：left
        style.transition = 'width 0.3s ease-out';
        if (zoom == 'zoomIn' && clientWidth + 10 < 1000) {//向上 放大max-size:1000
            //图片宽度+40
            let widthZoom = `${clientWidth + 40}px`;
            style.width = widthZoom;
            setPicturePreWidth(clientWidth + 50);
        } else if (zoom == 'zoomIn' && clientWidth + 10 >= 1000) {
            return message.warning('已滚动缩放至最大范围');
        } else if (zoom == 'zoomOut') {//向下
            if (clientWidth > 416) {//这边图片min-Size：416px：由于设置padding属性原因需-40，
                //图片宽度-10
                let widthZoom = `${clientWidth - 40}px`;
                style.width = widthZoom;
                setPicturePreWidth(clientWidth - 30);
            } else if (clientWidth <= 416) {
                setPicturePreWidth(416);
                return message.warning('已滚动缩放至最小范围');
            }
        }
    };

    return (
        <Modal
            style={{ ...modalStyles, left: styleLeft, top: styleTop }}
            width={picturePreWidth}
            visible={visible}
            closable={false}
            bodyStyle={bodyStyle}
            maskStyle={{ backgroundColor: 'rgba(0,0,0,.45)' }}
            centered
            destroyOnClose={true}
            keyboard={true}
            footer={null}
            ref={modalRef}
            id='modalDomElement'
            wrapClassName='previewtype'
            // getContainer={() => document.getElementById('previewtype')}
            onCancel={() => { previewHandleCancel(); }}
        >
            <div className={styles.container}>
                <div onClick={previewHandleCancel} className={styles.closeicon}><img src={require('@/assets/image/nodata/modalClose.png')} /></div>
                {dataType == 'image' && (previewItem.indexOf('.pdf') > -1 ? <div className={styles.pdf}><object data={previewItem} type='application/pdf' width='100%' height='100%'>
                    <iframe src={previewItem} width='100%' height='100%' style={{ border: 'none' }}>
                        This browser does not support PDFs. Please download the PDF to view it: <a href={previewItem}>Download PDF</a>
                    </iframe>
                </object></div> : <img onWheel={wheelZoomModal} onMouseDown={onMouseDownModal} style={{ width: picturePreWidth - 10, cursor: 'move' }} ref={imgRef} onLoad={imageOnLoad} alt="缩略图片" src={previewItem} />)}
                {dataType == 'video' && <video ref={videoRef} className={styles.videos} controls src={previewItem} type="video/mp4" />}
                {dataType == 'audio' && <audio ref={audioRef} controls className={styles.audios} src={previewItem} />}
                {((dataType == 'pdf') || (dataType == 'file' && previewItem.indexOf('.pdf') > -1)) && <div className={styles.pdf}><object data={previewItem} type='application/pdf' width='100%' height='100%'>
                    <iframe src={previewItem} width='100%' height='100%' style={{ border: 'none' }}>
                        This browser does not support PDFs. Please download the PDF to view it: <a href={previewItem}>Download PDF</a>
                    </iframe>
                </object></div>}
            </div>
            {visibleExtension ? <div className={styles.containerViewer}>
                <ViewerExtension
                    ref={extensionRef}
                    modalstyle={modalstyle}
                    dragDropCoordinate={{ left: styleLeft, top: styleTop }}
                    dragDropFunc={dragDropFunc}
                    saveWidthModalFunc={saveWidthModalFunc}
                    saveWidthRef={saveWidthModalRef.current}
                    handleZoomEvent={handleZoomEvent}
                />
            </div>
                : null}
        </Modal>
    );
}

PreviewModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    previewVisible: PropTypes.bool, // 是否显示弹框
    previewClose: PropTypes.func, // 关闭弹框的回调函数
};
