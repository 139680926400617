import * as S from './actiontypes';

// 存储已分配出库单列表信息
export const getOutDeliveryOrderList = (value) => ({
    type: S.GET_OUTDELIVERYORDER_LIST,
    payload: value
});
// 存储已取消出库单列表信息
export const getCancelledDeliveryOrderList = (value) => ({
    type: S.GET_CANCELDELIVERYORDER_LIST,
    payload: value
});