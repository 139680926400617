import React from 'react';
import styles from './OrderTable.module.scss';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';

class OrderTable extends React.Component {

    state = {
        selectedRowKeys: [],
    };

    static getDerivedStateFromProps(props, state) {
        if (props.selectedRowKeys !== state.selectedRowKeys) {
            return {
                selectedRowKeys: props.selectedRowKeys
            };
        }
        return null;
    }

    _takeColumns = () => ([
        {
            title: '出库单号',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '订单编号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '会员账号',
            dataIndex: 'accountTel',
            key: 'accountTel',
            width: '8%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '商品数量',
            dataIndex: 'number',
            key: 'number',
            width: '5%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '发货仓库',
            dataIndex: 'storehouseName',
            key: 'storehouseName',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '订单状态',
            dataIndex: 'status',
            key: 'status',
            width: '5%',
            align: 'center',
            render: (text) => (this._renderStatusCell(text))
        },
        {
            title: '订单类型',
            dataIndex: 'orderType',
            key: 'orderType',
            width: '5%',
            align: 'center',
            render: (text) => (this._renderTypeCell(text))
        },
        {
            title: '下单时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        // {
        //     title: '订单分配时间',
        //     dataIndex: 'c9',
        //     key: 'c9',
        //     width: '10%',
        //     align: 'center',
        //     render: (text) => (this._renderTextCell(text))
        // },
        {
            title: '是否开票',
            dataIndex: 'isInvoice',
            key: 'isInvoice',
            width: '5%',
            align: 'center',
            render: (text) => (this._renderIsinvoice(text))
        },
        {
            title: '发货时间',
            dataIndex: 'logisticsCreateTime',
            key: 'logisticsCreateTime',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '物流公司',
            dataIndex: 'logisticsCompany',
            key: 'logisticsCompany',
            width: '8%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '物流单号',
            dataIndex: 'expressNo',
            key: 'expressNo',
            width: '8%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'action',
            align: 'center',
            width: '10%',
            render: (text, item) => this._renderActionCell(item.id, item)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    // 订单状态
    _renderStatusCell = (text) => {
        const { orderStateSelect = [] } = this.props;
        var statusText = orderStateSelect.filter(i => i.code === text) || [];
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {statusText[0] && statusText[0].value}
            </div>
        );
    }

    // 订单类型
    _renderTypeCell = (text) => {
        const { orderTypeSelect = [] } = this.props;
        var statusText = orderTypeSelect.filter(i => i.code === text) || [];
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {statusText[0] && statusText[0].value}
            </div>
        );
    }

    // 是否开票
    _renderIsinvoice = (text) => {
        const { invoiceSelect = [] } = this.props;
        var statusText = invoiceSelect.filter(i => i.code === text) || [];
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {statusText[0] && statusText[0].value}
            </div>
        );
    };

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderActionCell = (key) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'info')}>查看详情</div>
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
        }
    };

    // 表格复选输入
    _rowSelection = () => {
        return {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys) => {
                this.setState({ selectedRowKeys });
                this.props.selectedRowKeysChange(selectedRowKeys);
            }
        };
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] }, () => {
            this.props.selectedRowKeysChange([]);
        });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <XTableSelectionCount selectedNum={this.state.selectedRowKeys.length} onClean={this._cleanAllSelected} />
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection()}
                    rowKey='id'
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

OrderTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    orderTypeSelect: PropTypes.array,
    invoiceSelect: PropTypes.array,
    orderStateSelect: PropTypes.array,
    selectedRowKeys: PropTypes.array,
    selectedRowKeysChange: PropTypes.func
};

export default OrderTable;