import * as T from './actiontypes';
/** 
 * 卡劵管理列表数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} couponName 卡券名称	
* @param {string} couponType 卡劵类型(优惠卷、折扣卷、包邮卷、礼品卷)	
 * @param {string} state 状态
 * @param {date} effectiveTime 有效期开始
* @param {date} overdueTime 有效期结束
 */

export const getcardticketList = (dataload) => ({
    type: T.GET_CARDTICKETLIST_DATA,
    payload: {
        ...dataload,
    }
});
/**
 * 卡劵管理下拉框'数据源(saga)-代言类型、代言状态
 * @param only 新建卡劵只需要类型
 */
export const getcardticketDropDown = (dataload) => ({
    type: T.GET_CARDTICKDROPDIWN_DATA,
    payload: {
        ...dataload,
    }
});

/**
 * 卡劵管理新建-使用门店、和全选复选、商品范围、有限期固定时长'数据源
 * @param {number}  暂无
 */
export const getcardticketRaiseAll = () => ({
    type: T.GET_RAISEALL_DATA,
});
/**
 *卡劵管理新建-选择商品表格
 * @param {number}  暂无
 */
export const getcardtickRaiseTable = (dataload) => ({
    type: T.GET_RAISETABALE_DATA,
    payload: {
        ...dataload,
    }
});

export const getcardtickAllGoods = (dataload) => ({
    type: T.GET_CARDTICKALLGOODS_DATA,
    payload: {
        ...dataload,
    }
});
export const getCardtickShopModeData = (shopName) => ({
    type: T.GET_CARDTICKSHOPMODE_DATA,
    payload: { shopName }
});
/**
 * 卡劵管理编辑、详情获取数据'数据源(saga)
 * @param {number} id  获取某个用户情对应的id
 */
export const getcardtickDetails = (id) => ({
    type: T.GET_CARDTICKDETAILS_DATA,
    payload: {
        id,
    }
});
