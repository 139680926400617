import React from 'react';
import styles from './AdvertTable.module.scss';
import { XTableSelectionCount, XPagination } from '@/components/xqxc_ui';
import { Table, Badge } from 'antd';
import PropTypes from 'prop-types';
import ButtonPerssion from '@/routes/ButtonPerssion';

class AdvertTable extends React.Component {
    state = {
        account: '',
        selectedRowKeys: [],
    }

    _takeColumns = () => ([
        {
            title: '排序',
            dataIndex: 'warehouseSort',
            key: 'warehouseSort',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '仓库名称',
            dataIndex: 'warehouseName',
            key: 'warehouseName',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '仓库类型',
            dataIndex: 'warehouseType',
            key: 'warehouseType',
            width: '10%',
            align: 'center',
            render: (text) => (<span>{text.value}</span>)
        },
        {
            title: '供应商',
            dataIndex: 'supplierName',
            key: 'supplierName',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '管理员账号',
            dataIndex: 'loginName',
            key: 'loginName',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '状态',
            dataIndex: 'warehouseState',
            key: 'warehouseState',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderStatusCell(text))
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '操作',
            dataIndex: 'c9',
            key: 'c9',
            width: '20%',
            align: 'center',
            render: (text, item) => this._renderActionCell(item.id, item.warehouseState.code)
        },
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderStatusCell = (text) => {
        var statusArr = ['0', '1'];
        // var statusText = ['停用', '进行中'];
        var statusBadge = ['default', 'processing'];
        if (statusArr.indexOf(text.code) !== -1) {
            return (
                <Badge status={statusBadge[text.code]} text={text.value} />
            );
        } else {
            return null;
        }
    }
    _renderActionCell = (key, status) => {
        // status 0 停用 1进行中
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <ButtonPerssion code='storehousemanage/storehouselist/lbbjcz'>
                    <div className={styles.item} onClick={() => tableAction(key, 'edit')}>编辑</div>
                </ButtonPerssion>
                <ButtonPerssion code='storehousemanage/storehouselist/lbqytycz'>
                    {status === '0' && <div className={styles.item} onClick={() => tableAction(key, 'start', status)}>启用</div>}
                    {status === '1' && <div className={styles.item} onClick={() => tableAction(key, 'stop', status)}>停用</div>}
                </ButtonPerssion>
                <ButtonPerssion code='storehousemanage/storehouselist/lbjrckcz'>
                    {status === '1' && <div className={styles.item} onClick={() => tableAction(key, 'goin')}>进入仓库</div>}
                </ButtonPerssion>
                <ButtonPerssion code='storehousemanage/storehouselist/lbsccz'>
                    <div className={styles.item} onClick={() => tableAction(key, 'del')}>删除</div>
                </ButtonPerssion>
            </div>
        );
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    onChange = (pagination, filters, sorter) => {
        console.log('params', pagination, filters, sorter);
    };
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }
    render() {
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    // onChange={this.onChange}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default AdvertTable;