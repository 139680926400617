import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, XExpandOrContract } from '@/components/xqxc_ui';
import Audit from '../../modal/Audit';
import Batch from '../../modal/Batch';
import { GoodsCategory } from '@/vcomps';
import TableContent from './components/TableContent';
import * as actions from '@/reducers/auditManage/balanceAudit/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';
import { message } from 'antd';

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
            isShowMore: false,
            goodsName: '',
            goodsNo: '',
            categoryId: '',
            businessType: {},
            goodsLabel: {},
            status: {},
            batch: [],
            rows: []
        };
        this.goodsCategory = React.createRef();
    }
    componentDidMount() {
        this.props.getBalanceAuditBusinessSele();
        this.props.getBalanceAuditStateSele();
        this.props.getBalanceAuditLabelSele();

    }
    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, businessType, goodsLabel, status, goodsName, goodsNo, categoryId } = this.state;
        const tagIds = goodsLabel.id ? [goodsLabel.id] : [];
        this.searchCache = {
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            auditState: status.code, businessType: businessType.code, goodsLabel: goodsLabel.id, tagIds, categoryId, goodsName, goodsNo
        };
        this.props.getBalanceAuditList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ businessType: {}, goodsLabel: {}, status: {}, goodsName: '', goodsNo: '', categoryId: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        this.goodsCategory.current.clean();
    }

    _unfold = () => {
        const { isShowMore } = this.state;
        this.setState({ isShowMore: !isShowMore });
    }

    // 表格操作
    _tableAction = (id, type, params, rows) => {
        switch (type) {
            case 'audit':
                console.log(id, type, params);
                this.setState({ currentOpenMode: 'AUDIT', openModeParam: params });
                break;
            case 'batch':
                console.log(id, type, params);
                this.setState({ batch: params, rows });
                break;
        }
    }

    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum });
        const { businessType, goodsLabel, status, goodsName, goodsNo, categoryId } = this.state;
        const tagIds = goodsLabel.id ? [goodsLabel.id] : [];
        this.props.getBalanceAuditList({ pageNum, pageSize, auditState: status.code, tagIds, businessType: businessType.code, goodsLabel: goodsLabel.id, categoryId, goodsName, goodsNo });
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'AUDIT':
                return <Audit visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'BATCH':
                return <Batch visible={true} batch={this.state.batch} closeMode={this._closeMode} />;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ currentOpenMode: '' });
        const { businessType, goodsLabel, status, goodsName, goodsNo, categoryId, pageNum, pageSize } = this.state;
        if (update) {
            this.props.getBalanceAuditList({ pageNum, pageSize, auditState: status.code, businessType: businessType.code, goodsLabel: goodsLabel.id, categoryId, goodsName, goodsNo });
        }
    }

    _batchChange = () => {
        this.setState({ currentOpenMode: 'BATCH' });
    }

    _generateReport = () => {

        let params = {};
        if (this.state.batch.length) {
            this.setState({ generating: true });
            // let goodsIds = this.state.rows.map(v => v.goodsId);
            // let skuIds = [];
            // goodsIds.length && new Set(this.state.rows.map(v => v.skuId)).forEach(v => skuIds.push(v));
            params.goodsIds = this.state.rows.map(v => v.goodsId);
            params.settleRecordIds = this.state.rows.map(v => v.settleRecordId);
            params.skuIds = [];
            params.goodsIds.length && new Set(this.state.rows.map(v => v.skuId)).forEach(v => params.skuIds.push(v));

        } else {
            // message.warning('请选择一条记录');
            params = this.searchCache;
            params.pageNum = this.state.pageNum;
            params.pageSize = this.state.pageSize;
        }
        http('/goods/inventory/findGoodsSettleAuditListExcel', params).then(res => {
            if ((res.result || '').startsWith('http')) {
                window.location = res.result;
            }
            message.success(res.result, 5);
            this.setState({ generating: false });
        }).catch((res = {}) => {
            this.setState({ generating: false });
            message.error(res.message);
        });
    }

    _clearRows = () => {
        this.setState({ rows: [], batch: [] });
    }

    render() {
        const { balanceAuditList, balanceAuditLabelSele, balanceAuditStateSele, balanceAuditBusinessSele } = this.props;
        const { goodsName, isShowMore, goodsNo, businessType, goodsLabel, status, generating } = this.state;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='商品名称'
                            placeholder='请输入商品名称'
                            bindThis={this}
                            bindName='goodsName'
                            value={goodsName}
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '200px' }}
                            label='商品货号'
                            placeholder='请输入商品货号'
                            bindThis={this}
                            bindName='goodsNo'
                            value={goodsNo}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                        <XExpandOrContract style={{ marginLeft: '30px' }} onChange={this._unfold} />
                    </div>
                    <div className={isShowMore ? styles.moreSearch1 : styles.moreSearch2}>
                        <XSelect
                            style={{ marginLeft: '30px' }}
                            selectStyle={{ width: '200px' }}
                            label='状态'
                            placeholder='请选择状态'
                            renderData={balanceAuditStateSele}
                            bindThis={this}
                            bindName='status'
                            dataIndex='value'
                            keyIndex='code'
                            value={status.value}
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '200px' }}
                            label='业务类型'
                            placeholder='请选择业务类型'
                            renderData={balanceAuditBusinessSele}
                            bindThis={this}
                            bindName='businessType'
                            dataIndex='value'
                            keyIndex='code'
                            value={businessType.value}
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '200px' }}
                            label='商品标签'
                            placeholder='请选择商品标签'
                            renderData={balanceAuditLabelSele.dataList}
                            bindThis={this}
                            bindName='goodsLabel'
                            dataIndex='tagName'
                            keyIndex='id'
                            value={goodsLabel.tagName}
                        />
                    </div>
                    <div className={isShowMore ? null : styles.moreSearch2}>
                        <GoodsCategory
                            label='商品分类'
                            ref={this.goodsCategory}
                            style={{ marginTop: '20px' }}
                            onChange={value => this.setState({ categoryId: value })}
                        />
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '90px' }} onClick={this._batchChange} label='批量审核' />
                        <XOKButton loading={generating} style={{ width: '90px', marginLeft: '30px' }} label='导出' onClick={this._generateReport} />
                    </div>
                    <TableContent paginationChange={this._paginationChange} renderData={balanceAuditList} tableAction={this._tableAction} clearRows={this._clearRows} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }

}

Main.propTypes = {
    updateContent: PropTypes.func,
    getBalanceAuditList: PropTypes.func,
    getBalanceAuditBusinessSele: PropTypes.func,
    getBalanceAuditLabelSele: PropTypes.func,
    getBalanceAuditStateSele: PropTypes.func,
    balanceAuditList: PropTypes.object,
    balanceAuditLabelSele: PropTypes.object,
    balanceAuditStateSele: PropTypes.array,
    balanceAuditBusinessSele: PropTypes.array
};

const mapStateToProps = (state) => ({
    balanceAuditList: state.balanceAudit.balanceAuditList,
    balanceAuditLabelSele: state.balanceAudit.balanceAuditLabelSele,
    balanceAuditStateSele: state.balanceAudit.balanceAuditStateSele,
    balanceAuditBusinessSele: state.balanceAudit.balanceAuditBusinessSele
});

export default connect(mapStateToProps, actions)(Main);