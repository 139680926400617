/**
 * 【仓库列表】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import WalletButton from '@/vcomps/WalletButton';
import { Alert, message } from 'antd';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getPlatformReconciliationTableSource } from '@/reducers/storehousefinancialmanage/walletreconciliation/actions';
import AdvertTable from './components/AdvertTable';
import KeepAlive from '@/routes/KeepAlive';
import Moment from 'moment';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getYearRange, getMonthRange, getDayAndWeek } from '@/util/format';
import { regFenToYuan } from '@/util/money';
import http from '@/assets/api/http';


class Main extends React.Component {

    state = {
        settledAmount: 0,
        toBeSettledAmount: 0,
        orderId: undefined,
        payItemId: undefined,
        startDate: undefined,
        endDate: undefined,
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.refreshPayData();
    }
    // 查询
    _searchHandle = (useCache) => {
        const { orderId, payItemId, startDate, endDate, pageNum, pageSize } = this.state;
        this.searchCache = {
            orderId,
            payItemId,
            payStartDate: startDate ? startDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
            payEndDate: endDate ? endDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getPlatformReconciliationTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.refreshPayData();
    }

    refreshPayData = () => {
        const { orderId, payItemId, startDate, endDate } = this.state;
        http('/settle/reconciliationSource/walletSettleOrderDetailSummary', {
            orderId,
            payItemId,
            payStartDate: startDate ? startDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
            payEndDate: endDate ? endDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
        }).then(res => {
            if (res.code === 200) {
                this.setState({ ...res.result });
            }
        }).catch((e = {}) => message.error(e.message || '加载数据异常'));
    }

    // 重置
    _resetHandle = () => {
        this.setState({ orderId: undefined, payItemId: undefined, startDate: undefined, endDate: undefined, pageNum: 1, pageSize: 10 }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageSize,
            pageNum,
        }, () => {
            this._searchHandle('useCache');
        });
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys, selectedRows });
        },
    };

    export = () => {
        const { startDate, endDate } = this.state;
        let payStartDate = startDate ? startDate.format('YYYY-MM-DD HH:mm:ss') : undefined;
        let payEndDate = endDate ? endDate.format('YYYY-MM-DD HH:mm:ss') : undefined;
        http('/settle/reconciliationSource/exportWalletSettleOrderDetail', { payStartDate, payEndDate }).then(res => {
            if (res.code === 200) {
                window.location.href = res.result;
            }
        }).catch((e = {}) => message.error(e.message || '数据加载异常'));
    }

    setDateRange = (type) => {
        let startDate, endDate, range;
        let start = ' 00:00:00', end = ' 23:59:59';
        switch (type) {
            case 'day':
                range = new Date().toLocaleDateString().replace(/\//g, '-');
                range = { begin: range, over: range };
                break;
            case 'week':
                range = getDayAndWeek('week');
                break;
            case 'month':
                range = getMonthRange();
                break;
            case 'year':
                range = getYearRange();
                break;
        }
        startDate = Moment(range.begin + start), endDate = Moment(range.over + end);
        this.setState({ startDate, endDate });
    }

    render() {
        const { startDate, endDate, orderId, payItemId, settledAmount, toBeSettledAmount } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.row1}>
                        <XInput
                            style={{ width: '248px' }}
                            inputStyle={{ width: '180px' }}
                            label='订单编号'
                            placeholder='请输入'
                            value={orderId}
                            bindThis={this}
                            bindName='orderId'
                            mode='number'
                            maxLength={16}
                            showLength={false}
                        />
                        <XInput
                            style={{ width: '248px', marginLeft: '15px' }}
                            inputStyle={{ width: '180px' }}
                            label='支付流水号'
                            placeholder='请输入'
                            value={payItemId}
                            bindThis={this}
                            bindName='payItemId'
                            mode='number'
                            maxLength={16}
                            showLength={false}
                        />
                        <XDatePicker
                            style={{ marginLeft: '30px', marginRight: '15px', color: '#000' }}
                            label='付款日期'
                            value={startDate}
                            bindThis={this}
                            bindName='startDate'
                        />
                        <span>—</span>
                        <XDatePicker
                            style={{ marginLeft: '5px' }}
                            value={endDate}
                            bindThis={this}
                            bindName='endDate'
                        />
                        <div className={styles.timeRange}>
                            <a onClick={() => this.setDateRange('day')}>今日</a>
                            <a onClick={() => this.setDateRange('week')}>本周</a>
                            <a onClick={() => this.setDateRange('month')}>本月</a>
                            <a onClick={() => this.setDateRange('year')}>本年</a>
                        </div>

                        <XOKButton
                            style={{ width: '60px', marginLeft: '20px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.row2}>
                        {/* <XOKButton
                            style={{ width: '80px', marginRight: '15px' }}
                            label='导出'
                            onClick={this.export}
                        /> */}
                        <WalletButton />
                        <Alert description={`待结算金额：${regFenToYuan(toBeSettledAmount, true)}元，已结算金额：${regFenToYuan(settledAmount, true)}元`} style={{ marginLeft: '15px', paddingTop: 0, paddingBottom: 4, display: 'inline-block', width: '100%' }} />
                    </div>
                    <AdvertTable renderData={this.props.platformReconciliationTableSource} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航

    platformReconciliationTableSource: PropTypes.object,
    getPlatformReconciliationTableSource: PropTypes.func
};

const mapStateToProps = (state) => ({
    platformReconciliationTableSource: state.walletreconciliation.platformReconciliationTableSource
});

export default connect(mapStateToProps, {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getPlatformReconciliationTableSource
})(Main);