/**
 *  店铺管理 - 店铺列表
 * */ 
import React from 'react';
import PropTypes from 'prop-types'; 
import Main from './pages/main';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const Mode = {
    MER_EDITOR: 'MER_EDITOR',//编辑
    MER_DETAIL: 'MER_DETAIL',//详情
    MER_STOR: 'MER_STOR',//店铺
    NEWCREATE: 'NEWCREATE'//新建
};

class merchantlist extends React.Component {

    componentDidMount(){
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }

    // 更新界面
    _updateContent = (mode, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch(mode){
            case Mode.MER_STOR:  //店铺列表
                addOneBreadcrumbPath({
                    title:'店铺列表',
                    path:'/home/businessmanage/merchantlist/shopInfo/',
                });
                history.push({
                    pathname: '/home/businessmanage/merchantlist/shopInfo/',
                });
                break;
            case Mode.MER_DETAIL: //商户详情
                addOneBreadcrumbPath({
                    title:'商户详情',
                    path:'/home/businessmanage/merchantlist/detailInfo/',
                });
                history.push({
                    pathname: '/home/businessmanage/merchantlist/detailInfo/',
                });
                break;
            case Mode.NEWCREATE: 
                console.log('新建', value);
                addOneBreadcrumbPath({
                    title:'创建店铺',
                    path:'/home/businessmanage/merchantlist/createInfo/'
                });
                history.push({
                    pathname: '/home/businessmanage/merchantlist/createInfo/',
                });
                break;
                case Mode.MER_EDITOR: 
                console.log('编辑', value);
                addOneBreadcrumbPath({
                    title:'编辑',
                    path:'/home/businessmanage/merchantlist/editorInfo/'
                });
                history.push({
                    pathname: `${'/home/businessmanage/merchantlist/editorInfo/'}${value}`,
                });
                break;
        }
    }
    
    render(){
        return(   
            <Main updateContent={this._updateContent} history = {this.props.history} />    
        );
    }
}

merchantlist.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(merchantlist);