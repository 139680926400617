import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';

const List = {
    dataList: [],
    pageNum: 1,
    pageSize: 20,
    resultTotal: 0
};

const model = {
    namespace: 'saleorderwarehouse',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getSupplierSaleOrder': T('getSupplierSaleOrder'),
            'getOperationList': T('getOperationList'),
            'getFenlaiAfterSaleStatusEnum': T('getFenlaiAfterSaleStatusEnum')
        },
        actions: {
            'getSupplierSaleOrder': A('getSupplierSaleOrder'),
            'getOperationList': A('getOperationList'),
            'getFenlaiAfterSaleStatusEnum': A('getFenlaiAfterSaleStatusEnum'),
        },
        sagas: {
            'getSupplierSaleOrder': S('getSupplierSaleOrder', '/out/supplier/list'),
            'getOperationList': S('getOperationList', '/out/supplier/list/operation'),
            'getFenlaiAfterSaleStatusEnum': S('getFenlaiAfterSaleStatusEnum', '/enum/FenlaiAfterSaleStatusEnum'),
        },
        reducers: {
            'SupplierSaleOrder': R('getSupplierSaleOrder', List),
            'OperationList': R('getOperationList', List),
            'FenlaiAfterSaleStatusEnum': R('getFenlaiAfterSaleStatusEnum', []),
        }
    })
};
export default createModel(model);