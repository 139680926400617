/**
 * 选择商品分类弹框 
 * */
import React from 'react';
import { Modal, Button, message, Radio, Select, Icon, Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './ProductClassifyModal.module.scss';
import GoodsCategory from '@/vcomps/AntGoodsCategory';
import http from '@/assets/api/http';

const { Option } = Select;
const { Search } = Input;

class ProductClassifyModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
            prodCascade: '', // 商品联级分类
            prodCascadeDetail: [], // 商品联级分类详细信息            
            value: 0,
            SearchDataSource: [],
        };
    }

    // 确认
    _handleOk = () => {
        const { prodCascadeDetail, value, selectedKey } = this.state;
        if (value === 0) {
            const trueList = prodCascadeDetail.filter((item) => {
                return item.categoryId == '' || item.categoryId == undefined || item.categoryId == null;
            });
            if (prodCascadeDetail.length == 0 || trueList.length > 0) {
                message.warning('需选择三级分类');
                return;
            }

            this.props.closeMode('ok', this.state.prodCascadeDetail, this.state.prodCascade);
        } else {
            if (selectedKey) {
                this.props.closeMode('ok', null, selectedKey);
            } else {
                message.warning('需选择分类');
                return;
            }
        }
        this.setState({
            visible: false,
        });
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode('cancel');
    }

    // 分类搜索
    _onCascadeSearch = (value) => {
        console.log(value);
        this.setState({ prodCascade: value });
    }
    // 搜索模式
    onChange = e => {
        this.setState({ value: e.target.value }, () => {
            // 清空三级模糊
            this.setState({
                SearchDataSource: [], selectedKey: '',
            });
        });
    };
    // 选中值
    onValueChange = id => {
        this.setState({ selectedKey: id });
    }
    onInputChange = e => {
        this.setState({ Selectvalue2: e }, () => {
            if (e) {
                this.onSelectChange();
            } else {
                this.setState({ SearchDataSource: [], selectedKey: '' });
            }

        });
    }
    onSelectChange = () => {
        const { Selectvalue2 } = this.state;
        if (Selectvalue2) {
            http('/goods/outer/findAntBuyCategory', { categoryName: Selectvalue2 }, 'POST').then((response) => {
                if (response.status == 200) {
                    this.setState({ SearchDataSource: response.result });
                } else {
                    console.log('请求商品状态失败');
                }
            }).catch(() => {
                console.log('请求商品状态失败');
            });
        } else {
            this.setState({ SearchDataSource: [], selectedKey: '' });
        }

    }
    render() {
        const { visible, confirmLoading, value, SearchDataSource } = this.state;
        return (
            <Modal
                width={706}
                style={{ height: 450 }}
                centered
                title='商品分类'
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                footer={[
                    <Button key="back" type="primary" onClick={this._handleOk}>
                        确认
                    </Button>
                ]}
            >
                <div className={styles.container}>
                    <div className={styles.productClassifyModalStyles}>
                        <div className={styles.title}>
                            搜索模式:
                    <Radio.Group style={{ marginLeft: 10 }} onChange={this.onChange} value={this.state.value}>
                                <Radio value={0}>多级分类</Radio>
                                <Radio value={1}>三级模糊</Radio>
                            </Radio.Group>
                        </div>
                        {value === 0 ?
                            <div>
                                <div className={styles.title}>请选择商品的所属分类：</div>
                                <GoodsCategory
                                    style={{ marginTop: '0px' }}
                                    onChange={(value) => this.setState({ prodCascade: value })}
                                    onGetItems={(value) => this.setState({ prodCascadeDetail: value })}
                                />
                            </div>
                            :
                            <div style={{ height: 272 }} >
                                <Search className={styles.searchStyle} placeholder="请输入" onSearch={this.onInputChange} enterButton />
                                <Select
                                    style={{ width: 600, }}
                                    // showSearch
                                    open
                                    className={styles.selectStyle}
                                    // defaultActiveFirstOption={false}
                                    // value={Selectvalue}
                                    placeholder={'请输入'}
                                    onChange={this.onValueChange}
                                // filterOption={(input, option) =>
                                //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                // }
                                >
                                    {SearchDataSource.map(i => {
                                        return <Option key={i.categoryId}>{i.categoryName}</Option>;
                                    })}
                                </Select>
                            </div>
                        }
                    </div>
                </div>
            </Modal>
        );
    }
}

ProductClassifyModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    closeMode: PropTypes.func.isRequired,
};

export default ProductClassifyModal;