//供应商合同审核列表
export const GET_CONTRACTAUDIT_LIST = 'AUDITMANAGE/GET_CONTRACTAUDIT_LIST';
export const SET_CONTRACTAUDIT_LIST = 'AUDITMANAGE/SET_CONTRACTAUDIT_LIST';

//合同状态下拉
export const GET_CONTRACTSTATE_SELE = 'AUDITMANAGE/GET_CONTRACTSTATE_SELE';
export const SET_CONTRACTSTATE_SELE = 'AUDITMANAGE/SET_CONTRACTSTATE_SELE';

//供应商下拉
export const GET_SUPPLIER_SELE = 'AUDITMANAGE/GET_SUPPLIER_SELE';
export const SET_SUPPLIER_SELE = 'AUDITMANAGE/SET_SUPPLIER_SELE';

//供应商合同审核详情
export const GET_CONTRACTAUDIT_DETAIL = 'AUDITMANAGE/GET_CONTRACTAUDIT_DETAIL';
export const SET_CONTRACTAUDIT_DETAIL = 'AUDITMANAGE/SET_CONTRACTAUDIT_DETAIL';
