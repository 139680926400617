/**
 * 活动修改时间
 */
import React, { Fragment } from 'react';
import { Modal, message, Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './ActivityTime.module.scss';
import { XDatePicker, } from '@/components/xqxc_ui';
import http from '@/assets/api/http';
import { DatePicker } from 'antd';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

const { TextArea } = Input;
const disabledDate = (current) => {
    return current < moment().startOf('day');
};
const disabledEedDate = (current) => {
    return current < moment().endOf('day');
    // return current < moment().startOf('day');
};
class ActivityTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,

            activityStartTime: moment(this.props.data.modifyTime.startTime) || null, // 活动报名开始时间
            startPickerDisabled: false, // 开始时间不可选
            activityEndTime: moment(this.props.data.modifyTime.endTime) || null, // 活动报名开始时间
            endPickerDisabled: false, //结束时间不可选

            areaValue: '',//延长理由
            datePickerType: this.props.data.type, //修改与发布区分
        };

    }

    componentDidMount() {

    }

    _areaChangeHandle = (e) => {
        this.setState({ areaValue: e.target.value });
    }
    disabledDate = (current) => {
        return current < moment().startOf('day');
    };
    disabledEedDate = (current) => {
        const { activityStartTime, launchStartTime, datePickerType, } = this.state;
        return current < moment(datePickerType == 'signTime' ? activityStartTime : launchStartTime).endOf('day');
    };
    //活动报名时间  
    _activityStartTime = (value) => {
        this.setState({
            activityStartTime: value,
        });
    }
    _activityEndTime = (value) => {
        this.setState({
            activityEndTime: value,
        });
    }
    //活动发布时间  
    _launchStartTime = (value) => {
        this.setState({
            launchStartTime: value,
        });
    }
    _launchEndTime = (value) => {
        this.setState({
            launchEndTime: value,
        });
    }

    //修改与发布区分
    ActivityDatePicker = () => {
        const { activityStartTime, startPickerDisabled, activityEndTime,
            endPickerDisabled, launchStartTime, launchTimePickerDisabled, launchEndTime, launchendPickerDisabled, } = this.state;
        if (this.state.datePickerType == 'signTime') {
            return (
                <Fragment>
                    <div className={styles.contentItem}>
                        <span className={styles.label}>活动报名开始时间:</span>
                        <span className={styles.text}>
                            <DatePicker
                                locale={locale}
                                disabledDate={this.disabledDate}
                                format="YYYY-MM-DD HH:mm:ss"
                                disabled={startPickerDisabled}
                                value={activityStartTime}
                                onChange={this._activityStartTime}
                                showTime
                                placeholder="活动报名开始时间"
                            />
                        </span>
                    </div>
                    <div className={styles.contentItem}>
                        <span className={styles.label}>活动报名结束时间:</span>
                        <span className={styles.text}>
                            <DatePicker
                                locale={locale}
                                disabledDate={this.disabledEedDate}
                                format="YYYY-MM-DD HH:mm:ss"
                                disabled={endPickerDisabled}
                                value={activityEndTime}
                                onChange={this._activityEndTime}
                                showTime
                                placeholder="活动报名结束时间"
                            />
                        </span>
                    </div>

                </Fragment>
            );
        } else {
            return (
                <Fragment />
            );
        }
    }

    _handleOk = () => {
        const { data } = this.props;
        const { activityStartTime, activityEndTime, datePickerType, areaValue, } = this.state;
        let paramData = {};
        // if (areaValue == '') return message.warn('请填写延长理由');
        if (datePickerType == 'signTime') {
            if (activityStartTime === null || activityEndTime === null) {
                return message.warn('请填写报名时间');
            }
            if (activityStartTime.valueOf() > activityEndTime.valueOf()) {
                return message.warn('开始时间必须小于结束时间');
            }
            paramData.id = data.modifyTime.id;
            paramData.startTime = activityStartTime.format('YYYY-MM-DD HH:mm:ss');
            paramData.endTime = activityEndTime.format('YYYY-MM-DD HH:mm:ss');
            // paramData.reason = areaValue;
        }
        http('/marketing/activityMerchantInfo/updateStartTime', {
            ...paramData,
        }, 'POST').then(() => {
            message.success('修改成功');
            this.setState({
                visible: false,
            });
            this.props.closeMode(true);
        }).catch((reject) => {
            message.error(reject.message);
        });

    }

    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title, areaValue, } = this.state;
        const { data, } = this.props;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.label}>
                        <label>活动名称</label>
                        <span>{data.modifyTime.activityTitle || ''}</span>
                    </div>
                    <div className={styles.label}>
                        <label>活动标签</label>
                        <span>{data.modifyTime.activityType || ''}</span>
                    </div>
                    {this.ActivityDatePicker()}
                    {/* <div className={styles.result}>
                        <span>延长理由</span>
                        <p>
                            <TextArea
                                className={styles.textArea}
                                placeholder='系统推送消息给商家'
                                rows={4}
                                onChange={this._areaChangeHandle}
                                value={areaValue}
                            />
                        </p>
                    </div> */}
                </div>
            </Modal>
        );

    }
}

ActivityTime.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
};

export default ActivityTime;