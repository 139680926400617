import { createModel, createSaga, createReducer } from '@/store/tools';
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import { regFenToYuan } from '@/util/money';

const getCityClassTree = (T) => createSaga(T('getCityClassTree'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/city/shopCategoryInfo/findShopCategoryList', { ...action.payload }, 'POST');
                yield put({ type: T('originalData'), payload: result.result });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

const cityClassTree = (T) => createReducer((autostate) => {
    return (state = autostate, { type, payload }) => {
        let newTree = [];
        switch (type) {
            case T('originalData'):
                return payload.map(item => {
                    newTree = item.categoryChilds && item.categoryChilds.map(child => {
                        return { ...child };
                    });
                    return { ...item, hasPopover: false, categoryChilds: newTree };
                });
            case T('classtateChenge/GET'):
                return state.map(item => {
                    if (item.categoryId == payload) {
                        return { ...item };
                    }
                    newTree = item.categoryChilds && item.categoryChilds.map(child => {
                        if (child.categoryId == payload) {
                            return { ...child };
                        }
                        return { ...child, hasPopover: false };
                    });
                    return { ...item, categoryChilds: newTree, };
                });
            default:
                return state;
        }
    };
}, []);

//更新销售价
const getCitySkuGoodsList = (T) => createSaga(T('getCitySkuGoodsList'), (type) => {
    return function* () {
        yield takeEvery(type, function* requestData(action) {
            try {
                let result = yield http('/goods/cityShopGoods/findGoodsSKUInfo', { ...action.payload }, 'POST');
                yield put({ type: T('originalData'), payload: result.result });
            } catch (e) {
                yield put({ type: 'SAGA_ERROR', payload: { error: e } });
            }
        });
    };
});

const citySkuGoodsList = (T) => createReducer((autostate) => {
    return (state = autostate, { type, payload }) => {
        switch (type) {
            case T('originalData'):
                return payload.map(item => {
                    return {
                        ...item,
                        salePrice: regFenToYuan(item.salePrice),
                        marketPrice: regFenToYuan(item.marketPrice),
                    };
                });
            case T('editPrice/GET'):
                return state.map(item => {
                    if (payload.isBatch) {
                        return {
                            ...item,
                            salePrice: payload.salePrice,
                            marketPrice: payload.marketPrice
                        };
                    } else {
                        if (item.skuId == payload.skuId) {
                            return {
                                ...item,
                                salePrice: payload.salePrice,
                                marketPrice: payload.marketPrice
                            };
                        }
                        return { ...item };
                    }
                });
            default:
                return state;
        }
    };
}, []);

const model = {
    namespace: 'cityShopGoods',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getCityClassTree': T('getCityClassTree'),
            'classtateChenge': T('classtateChenge'),
            'getCityGoodsList': T('getCityGoodsList'),
            'getCityGoodsTabs': T('getCityGoodsTabs'),
            'getCityClassSele': T('getCityClassSele'),
            'getLibraryList': T('getLibraryList'),
            'getLibraryBrands': T('getLibraryBrands'),
            'getCitySkuGoodsList': T('getCitySkuGoodsList'),
            'editPrice': T('editPrice'),
            'getCityGoodsInfo': T('getCityGoodsInfo'),

            'getCityRecList': T('getCityRecList'),
            'getCityShopH5Url': T('getCityShopH5Url'),
            'getGoodsSalesTypelEnum': T('getGoodsSalesTypelEnum'),
        },
        actions: {
            'getCityClassTree': A('getCityClassTree'),
            'classtateChenge': A('classtateChenge'),
            'getCityGoodsList': A('getCityGoodsList'),
            'getCityGoodsTabs': A('getCityGoodsTabs'),
            'getCityClassSele': A('getCityClassSele'),
            'getLibraryList': A('getLibraryList'),
            'getLibraryBrands': A('getLibraryBrands'),
            'getCitySkuGoodsList': A('getCitySkuGoodsList'),
            'editPrice': A('editPrice'),
            'getCityGoodsInfo': A('getCityGoodsInfo'),
            'getCityCleanInfo': C('getCityGoodsInfo'), // 清除商品详情数据

            'getCityRecList': A('getCityRecList'),
            'getCityShopH5Url': A('getCityShopH5Url'),
            'getGoodsSalesTypelEnum': A('getGoodsSalesTypelEnum'),
        },
        sagas: {
            'getCityClassTree': getCityClassTree(T),
            'getCityGoodsList': S('getCityGoodsList', '/goods/cityShopGoods/findShopGoodsPage'),
            'getCityGoodsTabs': S('getCityGoodsTabs', '/goods/cityShopGoods/getGoodsNumber'),
            'getCityClassSele': S('getCityClassSele', '/city/shopCategoryInfo/findShopCategoryListItem'),
            'getLibraryList': S('getLibraryList', '/goods/goods/simpleGoodsList'),
            'getLibraryBrands': S('getLibraryBrands', '/goods/brand/listBrandItem'),
            'getCitySkuGoodsList': getCitySkuGoodsList(T),
            'getCityGoodsInfo': S('getCityGoodsInfo', '/goods/goods/infoGoods'),

            'getCityRecList': S('getCityRecList', '/goods/shopGoods/findShopGoodsList'),
            'getCityShopH5Url': S('getCityShopH5Url', '/goods/cityShopGoods/getH5ShopUrl'),
            'getGoodsSalesTypelEnum': S('getGoodsSalesTypelEnum', '/enum/GoodsSalesTypelEnum'),
        },
        reducers: {
            'cityClassTree': cityClassTree(T),
            'cityGoodsList': R('getCityGoodsList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'cityGoodsTabs': R('getCityGoodsTabs', []),
            'cityClassSele': R('getCityClassSele', []),
            'libraryList': R('getLibraryList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'libraryBrands': R('getLibraryBrands', []),
            'citySkuGoodsList': citySkuGoodsList(T),
            'cityGoodsInfo': R('getCityGoodsInfo', { goodsImagesList: [], goodsLongImagesList: [], goodsVideoList: [], skuInfoList: [] }),

            'cityRecList': R('getCityRecList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'cityShopH5Url': R('getCityShopH5Url', ''),
            'goodsSalesTypelEnum': R('getGoodsSalesTypelEnum', []),
        }
    })
};
export default createModel(model);