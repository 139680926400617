import React, { useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './BanHouse.module.scss';
import { Modal, message, Input, Alert } from 'antd';

const { TextArea } = Input;

const BanHouse = ({ modeParams, closeMode }) => {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [reason, setReason] = useState('');

    // 确认
    const _handleOk = () => {
        setConfirmLoading(true);
        http('', { id: modeParams.id }, 'POST').then(() => {
            setConfirmLoading(false);
            message.success('禁用房源成功');
            closeMode(true);
        }).catch((e) => {
            setConfirmLoading(false);
            message.error(e.message);
        });
    };

    // 取消
    const _handleCancel = () => {
        closeMode(false);
    };

    return (

        <Modal
            width={480}
            centered
            title='房源禁用'
            visible={true}
            confirmLoading={confirmLoading}
            onOk={_handleOk}
            onCancel={_handleCancel}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'
        >
            <div className={styles.container}>
                <div className={styles.warning}>
                    <Alert
                        message="警告"
                        description="禁用后前端APP将不展示该房源。"
                        type="warning"
                        showIcon
                    />
                </div>
                <div className={styles.textArea}>
                    <div className={styles.label}>原因</div>
                    <div className={styles.content}>
                        <TextArea
                            style={{ width: '350px' }}
                            value={reason}
                            onChange={e => setReason(e.target.value)}
                            placeholder="请输入原因"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

BanHouse.propTypes = {
    history: PropTypes.object, // 路由history对象
    modeParams: PropTypes.object,//弹框入参
    closeMode: PropTypes.func,//关闭弹框参数
};

export default BanHouse;