import * as T from './actiontypes';

//获取标准商品列表
export const getStandardProduct = (params = {}) => ({
    type: T.GET_STANDARDPRODUCT_LIST,
    payload: params
});
//获取标准商品申请
export const getStandardProductDetail = (id) => ({
    type: T.GET_STANDARDPRODUCT_DETAIL,
    payload: { id }
});
