/**
 * 余额预警提醒
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/previewcollectionmanage/model';
import { Button, InputNumber, message } from 'antd';
import http from '@/assets/api/http';

class Main extends React.Component {

    state = {
        warnAmount: '',
        saveLoading: false
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        http('/settle/advances/received/get').then(res => {
            if (res.code === 200) {
                this.setState({ warnAmount: res.result });
            }
        });
    }

    onChange = (warnAmount) => {
        this.setState({ warnAmount });
    }

    onSave = () => {
        const { warnAmount } = this.state;

        if (typeof warnAmount !== 'number' || warnAmount <= 0) {
            message.warning('请输入规范金额');
            return;
        }

        this.setState({ saveLoading: true });
        http('/settle/advances/received/set', {
            amount: this.state.warnAmount
        }).then(res => {
            if (res.code === 200) {
                message.success('设置成功');
            }
        }).finally(() => this.setState({ saveLoading: false }));
    }

    render() {
        const { warnAmount, saveLoading } = this.state;

        return (
            <div style={{ marginTop: 15 }}>
                <span>预警提醒金额：</span>
                <InputNumber style={{ marginTop: 15, width: 200 }} min={0.01} precision={2} value={warnAmount} onChange={this.onChange} />
                <div style={{ marginTop: 10, color: '#808080' }}>提示：当余额低于提醒金额时 ，系统将会发送短信通知</div>
                <div style={{ marginTop: 15 }}>
                    <Button loading={saveLoading} type='primary' onClick={this.onSave}>保存</Button>
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
