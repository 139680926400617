/**
 * 店铺商品列表 Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

export const merchantStoreSource = function* (){
    yield takeEvery(T.GET_MERSTORE_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象
        try{
            let result = yield http('/goods/shopGoods/findShopGoodsList', action.payload,'POST');
            yield put({type:T.SET_MERSTORE_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};
/**
 * 店铺状态下拉状态选项数据方法 Saga
 */
export const merchantSelectData = function* (){
    yield takeEvery(T.GET_MERCHANTSELECT_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象 
        try{
            let result = yield http('/enum/StateEnum', action.payload,'POST');
            yield put({type:T.SET_MERCHANTSELECT_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

/**
 * 新建-店铺类型数据方法 Saga
 */
export const merchantstoreData = function* (){
    yield takeEvery(T.GET_CREATEINFO_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象 
        try{
            let result = yield http('/enum/ShopTypeEnum', action.payload,'POST');
            yield put({type:T.SET_CREATEINFO_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};

/** 店铺编辑 Saga
  */
export const storeEditor = function* (){
    yield takeEvery(T.GET_MERCHANTEDITOR_DATA, function* requestData(action){
        console.log(action.payload); // e为saga传递的参数对象 merchantId
        try{
            let result = yield http('/shop/getShopDetails',action.payload,'POST');
            yield put({type:T.SET_MERCHANTEDITOR_DATA, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    });
};




