import React, { Component } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { XInput, XOKButton, XCancelButton, XTextArea } from '@/components/xqxc_ui';
import LowInventoryTable from './components/LowInventoryTable';
import TipInventory from './modal/TipInventory';
import AddInventory from './modal/AddInventory';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/productmanage/wholesalegoods/model';

class LowInventory extends Component {

    static propTypes = {
        history: PropTypes.object,
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getLowInventoryList: PropTypes.func,
        lowInventoryList: PropTypes.object,
        showHideEdit: PropTypes.func
    }

    static defaultProps = {
        lowInventoryList: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }
    }

    state = {
        renderModal: '', // 当前需要打开的弹窗
        modalParams: null, // 当前需要打开的弹窗的入参
        goodsName: '',
        skuNos: '',
        batchKeys: []
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const {
            goodsName,
            skuNos,
            pageNum,
            pageSize
        } = this.state;
        this.searchCache = {
            goodsName,
            skuNos: skuNos && skuNos.replace(/，/g, ','),
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getLowInventoryList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({
            goodsName: '',
            skuNos: '',
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    //请求函数
    _request = (url, data) => {
        http(url, data, 'POST')
            .then(res => {
                message.success(res.message);
                this._searchHandle('useCache');
            })
            .catch(err => {
                message.error(err.message);
            });
    }

    // 渲染弹窗
    _renderMode = () => {
        const { renderModal, batchKeys } = this.state;
        switch (renderModal) {
            case 'tipInventory':
                return <TipInventory closeMode={this._closeMode} />;
            case 'addInventory':
                return <AddInventory
                    modalParams={batchKeys}
                    closeMode={this._closeMode} />;
            default:
                return null;
        }
    }
    // 关闭弹窗
    _closeMode = (refresh) => {
        this.setState({ renderModal: '', modalParams: null, batchKeys: [] }, () => {
            if (refresh) {
                this._searchHandle('useCache');
            }
        });
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    //表格操作
    _tableOperate = (type, { wholesaleSkuIds = [], batchKeys = [] }) => {
        switch (type) {
            case 'inventory':
                this._request('/goods/wholesale/addTotalQuantity', { wholesaleSkuIds });
                break;
            case 'batchKeys':
                this.setState({ batchKeys });
                break;
            default:
                break;
        }
    };

    render() {
        const { lowInventoryList, showHideEdit } = this.props;
        const {
            goodsName,
            skuNos,
            batchKeys
        } = this.state;
        return (
            <KeepAlive id='lowInventory' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XTextArea
                            label='SKU编码'
                            inputStyle={{ marginRight: 20, width: 250 }}
                            placeholder='请输入SKU编码，多个用逗号隔开，最多100条'
                            value={skuNos}
                            rows={2}
                            onChange={value => {
                                if (value.replace(/，/g, ',').split(',').length < 100) {
                                    this.setState({ skuNos: value });
                                } else {
                                    message.warning('SKU编码最多100条');
                                }
                            }}
                        />
                        <XInput
                            label='商品名称'
                            inputStyle={{ marginRight: 20, width: 150 }}
                            placeholder='请输入商品名称'
                            bindThis={this}
                            bindName='goodsName'
                            value={goodsName}
                        />
                        <XOKButton
                            label='查询'
                            style={{ marginRight: 10 }}
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.searchBox}>
                        <XOKButton
                            style={{ width: 120, marginRight: 20 }}
                            label='库存提醒设置'
                            onClick={() => {
                                this.setState({ renderModal: 'tipInventory' });
                            }} />
                        {/* <XOKButton
                            style={{ width: 120 }}
                            label='批量添加库存'
                            onClick={() => {
                                if (!batchKeys.length) {
                                    message.warning('请勾选商品');
                                    return;
                                }
                                this.setState({ renderModal: 'addInventory' });
                            }} /> */}
                    </div>
                    <LowInventoryTable
                        paginationChange={this._paginationChange}
                        screenY={screenY}
                        renderData={lowInventoryList}
                        showHideEdit={showHideEdit}
                        batchKeys={batchKeys}
                        tableOperate={this._tableOperate} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

export default connect(state => ({
    lowInventoryList: state.wholesaleGoods.lowInventoryList
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(LowInventory);