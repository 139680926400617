import React from 'react';
import { Table, Typography } from 'antd';
import PropTypes from 'prop-types';
import styles from './LiveTable.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';
import { Operation, LiveState } from '../../../_utils';

const { Paragraph } = Typography;

const handlesComps = (tableAction, key, record) => [
    <Paragraph key={0} className={styles.item} copyable={{ text: record.publishUrl }}>复制推流码</Paragraph>,
    <a key={1} className={styles.item} onClick={() => tableAction(key, Operation.Publish, record)}>发布直播</a>,
    <a key={2} className={styles.item} onClick={() => tableAction(key, Operation.Finish, record)}>结束直播</a>,
    <a key={3} className={styles.item} onClick={() => tableAction(key, Operation.Live, record)}>播放</a>,
    <a key={4} className={styles.item} onClick={() => tableAction(key, Operation.Play, record)}>回放</a>,
    <a key={5} className={styles.item} onClick={() => tableAction(key, Operation.Update, record)}>编辑</a>,
    <a key={6} className={styles.item} onClick={() => tableAction(key, Operation.Delete, record)}>删除</a>
];

class TableContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    //表头
    _columns = () => [
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'left',
            width: 250,
            fixed: 'left',
            render: (id, record) => this._tableAction(record.liveChargeId, record)
        },
        {
            title: '主播昵称',
            dataIndex: 'anchorNickName',
            key: 'anchorNickName',
            width: 150,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '封面',
            dataIndex: 'coverPage',
            key: 'coverPage',
            width: 150,
            align: 'left',
            render: ImgCell
        },
        {
            title: '直播间标题',
            dataIndex: 'broadcasTitle',
            key: 'broadcasTitle',
            width: 150,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所属分类',
            dataIndex: 'className',
            key: 'className',
            width: 150,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'stateName',
            key: 'stateName',
            width: 150,
            align: 'left'
        },
        {
            title: '购买人气',
            dataIndex: 'virtualName',
            key: 'virtualName',
            width: 150,
            align: 'left'
        },
        {
            title: '收费',
            dataIndex: 'costDesc',
            key: 'costDesc',
            width: 150,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '直播时间',
            dataIndex: 'startTime',
            key: 'startTime',
            width: 150,
            align: 'left',
            onCell: tooltipStyle,
            render: (text, record) => tooltip(<span>{record.startTime}<br />{record.endTime}</span>)
        },
        {
            title: '直播时长（秒）',
            dataIndex: 'liveTimes',
            key: 'liveTimes',
            width: 150,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '观看人数',
            dataIndex: 'realOnlineNumber',
            key: 'realOnlineNumber',
            width: 150,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '魅力值收入',
            dataIndex: 'starlightNumber',
            key: 'starlightNumber',
            width: 150,
            align: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '知识付费收益',
            dataIndex: 'chargeBonus',
            key: 'chargeBonus',
            align: 'left',
            width: 150,
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    // 处理返回数据
    _takeDataSource = (data) => {
        return data ? data : [];
    }

    // 表格操作
    _tableAction = (key, record) => {
        const { tableAction } = this.props;

        let handles = {
            [LiveState.NONE]: [0, 1, 5, 6],
            [LiveState.LIVE]: [0, 2, 3],
            [LiveState.END]: [4],
            [LiveState.NO_VIEWING]: [],
        }[record.state || LiveState.NO_VIEWING];

        let comps = handlesComps(tableAction, key, record);

        return (
            <div className={styles.action}>{handles.map(index => comps[index])}</div>
        );
    };

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.liveChargeId}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true, x: 2050 }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;