import React from 'react';
import styles from './ProductTable.module.scss';
import { XPagination, XMediaFn, } from '@/components/xqxc_ui';
import { Table, Tooltip, } from 'antd';
import PropTypes from 'prop-types';

class ProductTable extends React.Component {

    state = {
        selectedRowKeys: [], // 选中的单元行
    };

    _takeColumns = () => ([
        {
            title: '商品图片',
            dataIndex: 'mainImgUrl',
            key: 'mainImgUrl',
            width: '8%',
            align: 'center',
            render: (text) => (this._renderImgCell(text))
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: '12%',
            align: 'center',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 70,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                };
            },
            render: (text) => (this.TableTipCell(text))
        },
        {
            title: '商品货号',
            dataIndex: 'goodsNo',
            key: 'goodsNo',
            width: '10%',
            align: 'center',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 70,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                };
            },
            render: (text) => (this.TableTipCell(text))
        },
        {
            title: '商品分类',
            dataIndex: 'categoryName',
            key: 'categoryName',
            width: '15%',
            align: 'center',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 70,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                };
            },
            render: (text) => (this.TableTipCell(text))
        },
        {
            title: '商品货存',
            dataIndex: 'totalQuantity',
            key: 'totalQuantity',
            width: '11%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '业务类型',
            dataIndex: 'goodsType',
            key: 'goodsType',
            width: '11%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '商品状态',
            dataIndex: 'goodsState',
            key: 'goodsState',
            width: '11%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '更新时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '操作',
            dataIndex: 'goodsId',
            key: 'goodsId',
            align: 'center',
            width: '10%',
            render: (text) => this._renderActionCell(text)
        }
    ]);

    // 表格项内容为文本
    TableTipCell = (text) => {
        return (
            <Tooltip placement='topLeft' title={text}>{text}</Tooltip>
        );
    };
    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderImgCell = (url) => {
        return (
            <div style={{ display: 'inline-flex' }}>
                <XMediaFn
                    style={{ width: '50px', height: '50px', marginRight: '0' }}
                    dataType='image'
                    dataSource={url}
                />
            </div>
        );
    };

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderActionCell = (key) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'updateReserve')}>更新库存</div>
                {/* <div className={styles.item} onClick={() => tableAction(key, 'updatePrice')}>更新结算价</div> */}
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }

    render() {
        const { paginationChange, paginations, renderData } = this.props;
        // const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(renderData)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={paginations.resultTotal}
                    pageSize={paginations.pageSize}
                    pageNum={paginations.pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

ProductTable.propTypes = {
    renderData: PropTypes.array.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    paginations: PropTypes.object,//分页数据
};

export default ProductTable;