import React from 'react';
import { isEmpty } from '@/assets/js/utils';
import CellFenToYuan from '@/vcomps/ShowCellMoney';
import { tooltipStyle, tooltip, priceFenToYuanCell, ImgCell } from '@/components/TableCell';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
  {
    title: '订单编号',
    dataIndex: 'orderId',
    key: 'orderId',
    align: 'center',
    width: 160,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '出库单号',
    dataIndex: 'stockId',
    key: 'stockId',
    align: 'center',
    width: 160,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '售后单号',
    dataIndex: 'saleId',
    key: 'saleId',
    align: 'center',
    width: 160,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '供应商',
    dataIndex: 'merchantSupplier',
    key: 'merchantSupplier',
    align: 'center',
    width: 160,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '仓库',
    dataIndex: 'warehouseName',
    key: 'warehouseName',
    align: 'center',
    width: 160,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '商品名称',
    dataIndex: 'goodsName',
    key: 'goodsName',
    align: 'center',
    width: 300,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '商品数量',
    dataIndex: 'goodsNumer',
    key: 'goodsNumer',
    align: 'center',
    width: 100,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '规格',
    dataIndex: 'goodsSpec',
    key: 'goodsSpec',
    align: 'center',
    width: 100,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '商家编码',
    dataIndex: 'skuCode',
    key: 'skuCode',
    align: 'center',
    width: 100,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '结算价(元)',
    dataIndex: 'settlePrice',
    key: 'settlePrice',
    align: 'center',
    width: 100,
    render: CellFenToYuan
  },
  {
    title: '合计(元)',
    dataIndex: 'totalFee',
    key: 'totalFee',
    align: 'center',
    width: 100,
    render: CellFenToYuan
  },
  {
    title: '发货时间',
    dataIndex: 'deliveryTime',
    key: 'deliveryTime',
    align: 'center',
    width: 150,
    onCell: tooltipStyle,
    render: tooltip
  },
  {
    title: '结算状态',
    dataIndex: 'settleStatus',
    key: 'settleStatus',
    align: 'center',
    width: 100,
    render: (text) => (TableCell({
      TO_RECON: '待对账',
      IN_RECON: '对账中',
      DONE: '已对账',
    }[text] || '-'))
  },
  {
    title: '结算时间',
    dataIndex: 'chargeTime',
    key: 'chargeTime',
    align: 'center',
    width: 150,
    onCell: tooltipStyle,
    render: tooltip
  }
];

export const takeColumns = (actionClickHandle) => {
  return columns(actionClickHandle);
};

const TableCell = (text) => {
  return (
    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
      {isEmpty(text) ? '-' : text}
    </div>
  );
};