import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { XInput, XOKButton, XCancelButton, XSelect } from '@/components/xqxc_ui';
import TableData from './components/TableData';
import { getBrandApplyList, getBrandApplyStateSele } from '@/reducers/productmanage/brandApply/actions';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,
            pageSize: 20,
            isCheck: false,

            soucreName: null,
            auditState: {},
            brandNameSele: {}
        };
    }

    componentDidMount() {
        const { pageNum, pageSize } = this.state;
        this.props.getBrandApplyList({ pageNum, pageSize });
        this.props.getBrandApplyStateSele();
    }

    //名称改变
    _nameChange = (value) => {
        this.setState({ soucreName: value, isCheck: false });
    }

    _auditStateChange = (value) => {
        this.setState({ auditState: value, isCheck: false });
    }

    // 查询
    _searchHandle = () => {
        const { soucreName, auditState, pageNum, pageSize } = this.state;
        this.props.getBrandApplyList({ soucreName, auditState: auditState.code, pageNum, pageSize });
        this.setState({ isCheck: true });
    }

    // 重置
    _resetHandle = () => {
        this.setState({ soucreName: null, auditState: {}, pageNum: 1, pageSize: 20, isCheck: false });
        this.props.getBrandApplyList({ pageNum: 1, pageSize: 20 });
    }

    //申请商品品牌
    _applyBrand = () => {
        this.props.updateContent('BRAND_APPLY');
    }

    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        const { soucreName, auditState, isCheck } = this.state;
        this.setState({ pageNum, pageSize });
        if (!isCheck) {
            this.props.getBrandApplyList({ pageNum, pageSize });
        } else {
            this.props.getBrandApplyList({ soucreName, auditState: auditState.code, pageNum, pageSize });
        }
    }

    _tableHandle = (id, type, params) => {
        switch (type) {
            case 'detail':
                this.props.updateContent('BRAND_DETAIL', params.id);
                break;
        }
    }

    render() {
        const { brandApplyList, brandApplyStateSele } = this.props;
        const { soucreName, auditState } = this.state;
        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.search_box}>
                    <XInput
                        inputStyle={{ width: '200px' }}
                        label='品牌名称'
                        placeholder='请输入品牌名称'
                        onChange={this._nameChange}
                        value={soucreName}
                    />
                    <XSelect
                        style={{ marginLeft: '20px' }}
                        selectStyle={{ width: '200px' }}
                        placeholder='请选择品牌名称'
                        label='状态'
                        renderData={brandApplyStateSele}
                        onChange={this._auditStateChange}
                        dataIndex='value'
                        keyIndex='code'
                        value={auditState.value}
                    />
                    <XOKButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='查询'
                        onClick={this._searchHandle}
                    />
                    <XCancelButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='重置'
                        onClick={this._resetHandle}
                    />
                </div>
                <TableData paginationChange={this._paginationChange} tableHandle={this._tableHandle} renderData={brandApplyList} />
            </div>
        );
    }

}

Main.propTypes = {
    updateContent: PropTypes.func,
    getBrandApplyList: PropTypes.func,
    getBrandApplyStateSele: PropTypes.func,

    brandApplyList: PropTypes.object,
    brandApplyStateSele: PropTypes.array
};

const mapStateToProps = (state) => ({
    brandApplyList: state.brandApply.brandApplyList,
    brandApplyStateSele: state.brandApply.brandApplyStateSele
});

export default connect(mapStateToProps, { getBrandApplyList, getBrandApplyStateSele })(Main);