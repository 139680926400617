/**
 * 【仓库奢批结算价调整提示页-已调整】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XInput, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { getBalanceHintList } from '@/reducers/warehousemanage/balanceHint/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            skuId: '', // 商品规格ID
            goodsNo: '',
            updateStartTime: undefined, // 更新时间开始日期
            updateEndTime: undefined, // 更新结束日期
        };
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, skuId, goodsNo, updateStartTime, updateEndTime } = this.state;
        const startDate = updateStartTime;
        const endDate = updateEndTime;
        this.searchCache = {
            skuId,
            goodsNo,
            startDate,
            endDate,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getBalanceHintList({ ...this.searchCache, updateState: 'UPDATED' });
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
        this.forceUpdate();
    }

    // 重置
    _resetHandle = () => {
        this.setState({ skuId: '', updateStartTime: null, updateEndTime: null, goodsNo: '' }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { balanceHintList } = this.props;
        const { skuId, updateStartTime, updateEndTime, goodsNo } = this.state;
        return (
            <KeepAlive id='2' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria1}>
                        <XInput
                            style={{ width: '265px' }}
                            inputStyle={{ width: '180px' }}
                            label='商品规格ID'
                            placeholder='请输入商品规格ID'
                            value={skuId}
                            bindThis={this}
                            bindName='skuId'
                        />
                        <XInput
                            style={{ width: '265px', marginLeft: '20px' }}
                            inputStyle={{ width: '180px' }}
                            label='商品货号'
                            placeholder='请输入商品货号'
                            value={goodsNo}
                            bindThis={this}
                            bindName='goodsNo'
                        />
                        <XDatePicker
                            style={{ width: '264px', marginLeft: '20px' }}
                            label='下单时间'
                            value={updateStartTime}
                            bindThis={this}
                            bindName='updateStartTime'
                            isFormat={true}
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            label=''
                            value={updateEndTime}
                            bindThis={this}
                            bindName='updateEndTime'
                            isFormat={true}
                        />
                        <XOKButton style={{ marginLeft: '20px' }} label='查询' onClick={this._searchHandle} />
                        <XCancelButton style={{ marginLeft: '10px' }} label='重置' onClick={this._resetHandle} />
                    </div>
                    <TableContent
                        curTabID={this.state.curTabID}
                        renderData={balanceHintList}
                        paginationChange={this._paginationChange}
                    />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getBalanceHintList: PropTypes.func,
    balanceHintList: PropTypes.object,
};

const mapStateToProps = (state) => ({
    balanceHintList: state.balanceHint.balanceHintList,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getBalanceHintList })(Main);