/**
 * 【消息中心】
 */
import React from 'react';
import OrderTable from './components/OrderTable';
import OrderTableUnRead from './components/OrderTableUnRead';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XOKButton, XSelect, XCancelButton, XInput, XTabNav, showConfirm } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message, Badge } from 'antd';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/messagemanage/messagelist/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

const tagStyle = {//自定义标签样式
    position: 'absolute',
    top: '-12px',
    right: '-29px'
};
class Main extends React.Component {
    state = {
        tab: [
            { id: 1, isState: 1, label: '未读' },
            { id: 2, isState: 2, label: '已读' },
        ],
        curTabID: 1,  // 当前显示的Tab页ID
        orderID: '', // 当前操作的订单ID 
        messageTypeObj: {},
        messageName: '',
        pageSize: 10,
        pageNum: 1,
        selectedRowKeys: [],
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // 获取消息类型
        this.props.getMessageType();
        // 数量
        this.props.getUnReadMessageCount({ systemType: 'HOTEL_MERCHANT' });
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID }, () => {
            this._searchHandle('useCache');
        });
    }

    // 表格操作
    _tableAction = (id, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'del':
                showConfirm('是否确定删除？', '', () => {
                    http('/user/messageinfomis/deleteMessage', { id }, 'POST')
                        .then(() => {
                            message.success('删除成功!');
                            this.props.getUnReadMessageCount({ systemType: 'HOTEL_MERCHANT' });
                            // 消息列表
                            this.props.getlistMessage({ pageSize: 10, pageNum: 1 });
                            this._searchHandle('useCache');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
            case 'info':
                window.localStorage.setItem('messageId', id);
                addOneBreadcrumbPath({
                    title: '消息详情',
                    path: '/home/messagemanage/messageList/detail/'
                });
                history.push({
                    pathname: '/home/messagemanage/messageList/detail/' + id,
                });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        const { messageTitle, messageTypeObj, curTabID } = this.state;
        this.setState({
            pageSize,
            pageNum,
        });
        this.searchCache = {
            messageTitle,
            pageNum,
            pageSize,
            messageTypeId: messageTypeObj.id,
        };
        if (curTabID === 1) {
            this.props.getlistUnReadMessage(this.searchCache);
        } else {
            this.props.getlistReadMessage(this.searchCache);
        }
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }
    // 查询
    _searchHandle = (useCache) => {
        const { messageTitle, messageTypeObj, curTabID, pageNum, pageSize } = this.state;
        this.searchCache = {
            messageTitle,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            messageTypeId: messageTypeObj.id,
        };
        if (curTabID === 1) {
            this.props.getlistUnReadMessage(this.searchCache);
        } else {
            this.props.getlistReadMessage(this.searchCache);
        }
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 重置
    _resetHandle = () => {
        // const { curTabID } = this.state;
        this.setState({
            messageTypeObj: {},
            messageTitle: '',
            pageNum: 1,
            pageSize: 10,
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    getSelectedRowKeys = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    }
    // 批量删除
    _delHandle = () => {
        const { selectedRowKeys } = this.state;
        if (selectedRowKeys.length == 0) {
            message.warn('请至少选择一项!');
        } else {
            showConfirm('是否确定删除选择的项？', '', () => {
                http('/user/messageinfomis/batchDeleteMessage', { idList: selectedRowKeys }, 'POST')
                    .then(() => {
                        message.success('删除成功!');
                        this.props.getUnReadMessageCount({ systemType: 'HOTEL_MERCHANT' });
                        // 消息列表
                        this.props.getlistMessage({ pageSize: 10, pageNum: 1 });
                        this._searchHandle('useCache');
                    }).catch((reject) => {
                        message.error(reject.message);
                    });
            });
        }

    }
    render() {
        const { curTabID, messageTitle, messageTypeObj } = this.state;
        const { listReadMessage, listUnReadMessage, MessageType, UnReadMessageCount: { unReadCount } } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <XTabNav
                        typeNode={true}
                        optionalNode={<Badge style={tagStyle} count={unReadCount}></Badge>}
                        isTagNodePro='1'
                        renderData={this.state.tab}
                        onChange={this._tabChangeHandle}
                        activedID={this.state.curTabID} />
                    <div className={styles.search_box}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='消息名称'
                            placeholder='请输入'
                            value={messageTitle}
                            bindThis={this}
                            bindName='messageTitle'
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '200px' }}
                            label='消息类型'
                            placeholder='请选择'
                            renderData={MessageType}
                            dataIndex='messageTypeName'
                            keyIndex='id'
                            bindThis={this}
                            bindName='messageTypeObj'
                            value={messageTypeObj.messageTypeName}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <XOKButton
                        style={{ width: '60px' }}
                        label='删除'
                        onClick={this._delHandle}
                    />
                    {curTabID === 1 ? <OrderTableUnRead getSelectedRowKeys={this.getSelectedRowKeys} renderData={listUnReadMessage} tableAction={this._tableAction} paginationChange={this._paginationChange} curTabID={curTabID} /> :
                        <OrderTable getSelectedRowKeys={this.getSelectedRowKeys} renderData={listReadMessage} tableAction={this._tableAction} paginationChange={this._paginationChange} curTabID={curTabID} />}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getUnReadMessageCount: PropTypes.func,
    getlistMessage: PropTypes.func,
    getlistReadMessage: PropTypes.func,
    getlistUnReadMessage: PropTypes.func,
    getMessageType: PropTypes.func,
    UnReadMessageCount: PropTypes.object,
    listMessage: PropTypes.object,
    listReadMessage: PropTypes.object,
    listUnReadMessage: PropTypes.object,
    MessageType: PropTypes.array,
};
const mapStateToProps = (state) => ({
    UnReadMessageCount: state.messagelist.UnReadMessageCount,
    listMessage: state.messagelist.listMessage,
    listReadMessage: state.messagelist.listReadMessage,
    listUnReadMessage: state.messagelist.listUnReadMessage,
    MessageType: state.messagelist.MessageType,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
