/**
 * 资料补充
 */
import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import http from '@/assets/api/http';
import { message, Modal } from 'antd';
import * as QRCode from 'easyqrcodejs';

const UpdateEnterInfo = () => {

    const [renderModal, setRenderModal] = useState(false);
    const qrcodeDowload = useRef(null);
    const [contentext, setContentext] = useState('');

    useEffect(() => {
        http('/merchantEdit/getEditQRCode', {}, 'POST')
            .then(res => {
                if (res.result.url) {
                    setRenderModal(true);
                    setContentext(res.result.desc);
                    new QRCode(qrcodeDowload.current, { text: res.result.url });
                }
            })
            .catch(err => {
                message.error(err.message);
            });
    }, []);

    return <div className={styles.container}>
        <Modal
            width={640}
            centered
            title='修改入驻资料'
            visible={renderModal}
            confirmLoading={false}
            onCancel={() => setRenderModal(false)}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            footer={null}
        >
            <div className={styles.inner}>
                <p className={styles.title}>{contentext}</p>
                <div className={styles.qrcode} ref={qrcodeDowload}></div>
            </div>
        </Modal>
    </div>;
};

export default UpdateEnterInfo;

