import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { Tag, message } from 'antd';
import { XInput, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { setSessionStorage, setLocalStorage, getLocalStorage } from '@/assets/js/storage';
import Fail from '@/views/warehousemanage/productlist/modal/Fail';
import UpdateReserveModal from '@/views/warehousemanage/productreserve/modal/UpdateReserveModal';
import model from '@/reducers/productmanage/anchorgoods/model';
import ProductClassifyModal from './modal/ProductClassifyModal';

class AnchorGoods extends Component {

    static propTypes = {
        history: PropTypes.object, // 路由history对象
        addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
        keepSecondNavBreadcrumb: PropTypes.func, // 保持2级面包屑导航信息
        getAnchorGoodsList: PropTypes.func,
        anchorGoodsList: PropTypes.object,
        getGoodsNumber: PropTypes.func,
        goodsNumber: PropTypes.array,
        goodsNumberChange: PropTypes.func,
        resetGoodsNumber: PropTypes.func
    }

    static defaultProps = {
        anchorGoodsList: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
        goodsNumber: []
    }

    state = {
        currentMode: '',
        modeParams: null,//弹框参数
        goodsNo: '',
        goodsName: '',
        statusType: 0,
        goodsIds: []
    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getGoodsNumber } = this.props;
        this.setState({ goodsIds: [], statusType: 0 });
        getGoodsNumber();
        keepSecondNavBreadcrumb();
    }

    // 查询触发
    onSearch = (useCache) => {
        const { goodsName, goodsNo, statusType, pageNum, pageSize } = this.state;
        this.searchCache = {
            goodsName, goodsNo, statusType,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getAnchorGoodsList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 重置触发
    onReset = () => {
        this.setState({ goodsName: '', goodsNo: '', statusType: 0 }, () => {
            KeepAlive.saveResetFunc(this.onSearch); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        this.props.resetGoodsNumber();
    }

    //更新页面
    onUpdatePage = (title, url) => {
        const { history, addOneBreadcrumbPath } = this.props;
        const baseUrl = '/home/productmanage/anchorgoods';
        addOneBreadcrumbPath({
            title,
            path: `${baseUrl}${url}`
        });
        history.push({
            pathname: `${baseUrl}${url}`
        });
    }

    //表格操作
    onTableOperate = (id, value, type) => {
        switch (type) {
            case 'detail':
                this.onUpdatePage('主播商品详情', `/detail/${id}`);
                break;
            case 'edit':
                this.onUpdatePage('编辑主播商品', `/editgoods/${id}`);
                break;
            case 'updateReserve':
                http('/goods/inventory/findGoodsInventoryInfo', {
                    goodsId: id,
                }, 'POST').then((res) => {
                    this.setState({
                        currentMode: 'updateReserve',
                        modeParams: res.result,
                    });
                }).catch(e => {
                    message.error(e.message);
                });
                break;
            case 'batch':
                this.setState({ goodsIds: value });
                break;
            case 'delete':
                showConfirm('您是否要删除该商品', '', () => {
                    http('/goods/goods/delGoods', { goodsId: [id] }, 'POST')
                        .then(() => {
                            message.success('删除成功');
                            this.onSearch('useCache');
                            this.props.getGoodsNumber();
                        }).catch((e) => {
                            message.error(e.message);
                        });
                }, () => { });
                break;
        }
    }

    // 页面发生变化
    onPagination = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this.onSearch('useCache');
        });
    }


    //批量上架
    onPutaway = () => {
        const { goodsIds } = this.state;
        if (!goodsIds.length) {
            message.warning('请选择商品');
            return;
        }
        setSessionStorage('goodsIds', goodsIds);
        http('/goods/goods/checkSettlePrice', { goodsIds }, 'POST')
            .then((response) => {
                if (response.status == 200) {
                    this.onUpdatePage('批量上架确认服务清单', '/ratemodification/anchorGoods');
                } else if (response.status == 201) {
                    this.setState({
                        currentMode: 'fail',
                        modeParams: response.result,
                    });
                } else {
                    message.error(response.message);
                }
            }).catch((e) => {
                message.error(e.message);
            });
    }

    //批量下架
    onSoldOut = () => {
        const { goodsIds } = this.state;
        if (!goodsIds.length) {
            message.warning('请选择商品');
            return;
        }
        showConfirm('确认商品操作下架？', '', () => {
            http('/goods/goods/pullOffShelvesGoods', { goodsIds }, 'POST')
                .then(() => {
                    message.success('批量下架成功');
                    this.props.getGoodsNumber();
                    this.onSearch('useCache'); // 【第1步：调用查询接口初始化数据】
                }).catch((e) => {
                    message.error(e.message);
                });
        });
    }

    // 渲染弹框
    onRenderMode = () => {
        const { currentMode, modeParams } = this.state;
        switch (currentMode) {
            case 'fail':
                return <Fail
                    visible={true}
                    modeParams={modeParams}
                    closeMode={this.onCloseMode} />;
            case 'updateReserve':
                return <UpdateReserveModal
                    visible={true}
                    data={modeParams}
                    closeMode={this.onCloseMode} />;
            case 'productClassify':
                return <ProductClassifyModal
                    visible={true}
                    closeMode={this.onCloseMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    onCloseMode = (type, data) => {
        if (type === 'ok') {
            setLocalStorage('productCascades', data);
            this.setState({ currentMode: '', modeParams: null, goodsIds: [] });
            this.onUpdatePage('创建主播商品', '/creategoods');
        } else {
            this.setState({ currentMode: '', modeParams: null, goodsIds: [] }, () => {
                this.onSearch('useCache'); // 【第1步：调用查询接口初始化数据】
            });
        }
    }

    render() {
        const { anchorGoodsList, goodsNumber, goodsNumberChange } = this.props;
        const { goodsNo, goodsName, goodsIds } = this.state;
        return (
            <KeepAlive id='anchorGoods' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this.onSearch('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }
            }>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='货号'
                            placeholder='请输入货号'
                            bindThis={this}
                            bindName='goodsNo'
                            value={goodsNo}
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '200px' }}
                            label='商品名称'
                            placeholder='请输入商品名称'
                            bindThis={this}
                            bindName='goodsName'
                            value={goodsName}
                        />
                        <div className={styles.other}>
                            {
                                goodsNumber.map(item => {
                                    return <Tag
                                        key={item.goodsState}
                                        style={{ cursor: 'pointer' }}
                                        color={item.active ? '#FF6A0A' : ''}
                                        onClick={() => {
                                            goodsNumberChange(item.goodsState);
                                            this.setState({ statusType: item.goodsState }, () => {
                                                this.onSearch('useCache');
                                            });
                                        }}
                                    >{`${item.goodsStateName}(${item.goodsNumber})`}</Tag>;
                                })
                            }
                        </div>
                        <XOKButton
                            style={{ marginLeft: '20px', width: '90px' }}
                            label='查询'
                            onClick={this.onSearch}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '90px' }}
                            label='重置'
                            onClick={this.onReset}
                        />
                    </div>
                    <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: '100px' }}
                            label='新建'
                            onClick={() => {
                                if (getLocalStorage('lastGoodsClassify')) {
                                    setLocalStorage('productCascades', getLocalStorage('lastGoodsClassify'));
                                    this.onUpdatePage('创建主播商品', '/creategoods');
                                } else {
                                    this.setState({ currentMode: 'productClassify' });
                                }
                            }} />
                        <XOKButton
                            style={{ width: '100px', marginLeft: '20px' }}
                            label='批量上架'
                            onClick={this.onPutaway} />
                        <XOKButton
                            style={{ width: '100px', marginLeft: '20px' }}
                            label='批量下架'
                            onClick={this.onSoldOut} />
                    </div>
                    <TableContent
                        paginationChange={this.onPagination}
                        renderData={anchorGoodsList}
                        goodsIds={goodsIds}
                        tableOperate={this.onTableOperate} />
                    {this.onRenderMode()}
                </div>
            </KeepAlive >
        );
    }
}

export default connect(state => ({
    ...state.anchorGoods
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(AnchorGoods);