// 获去银联信息和类型
import http from '@/assets/api/http';

const getUnionInfo = () => {
    let type = '';
    return new Promise((resolve, reject) => {
        http('/union/merchant/applyQry').then((response) => {
            if (response.status == 200) {
                const { subjectType } = response.result;
                if (subjectType == 'COMPANY') {
                    type = 'BUSINESS';
                } else if (subjectType == 'PERSON') {
                    type = 'PERSON';
                } else {
                    type = '';
                }
                resolve({ type, data: response.result });
            } else {
                resolve({ type: '', data: {} });
            }
        }).catch((e) => {
            resolve({ type: '', data: {} });
        });
    });
};

export default getUnionInfo;


