/**
 * [门店审核]
 * @author: Fu Xiaochun
 * @date: 2023-04-12 
 */

import React, { useState, useEffect, useRef } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, message, Modal, Button } from 'antd';
import { XInput, XOKButton, XCancelButton, XSelect, XRangePicker } from '@/components/xqxc_ui';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import TableData from './components/TableData';
import http from '@/assets/api/http';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { useHistory } from 'react-router-dom';

function MainList(props){

    const history = useHistory();

    const [result, setResult] = useState({dataList:[]});
    const [aliveInit, setAliveInit] = useState(false);

    const [loginName, setLoginName] = useState();  // 手机号
    const [shopName, setShopName] = useState();    // 营业执照
    const [province, setProvince] = useState({});  // 所在区域-省
    const [city, setCity] = useState({});  // 所在区域-市
    const [area, setArea] = useState({});  // 所在区域-区
    const [status, setStatus] = useState({});  // 审核状态
    const [postTime, setPostTime] = useState([]); // 提交时间

    const [resetCode, setResetCode] = useState(0);
    const page = useRef({ pageNum: autoPageNum, pageSize: autopageSize });

    const [statusEnum, setStatusEnum] = useState([]);
    const [provinceEnum, setProvinceEnum] = useState([]);  // 省
    const [cityEnum, setCityEnum] = useState([]);  // 市
    const [areaEnum, setAreaEnum] = useState([]);  // 区


    useEffect(() => {
        props.keepSecondNavBreadcrumb();
        getStateEnum();
        getListAreaByParentId(100000, setProvinceEnum);
    }, []);

    useEffect(()=>{
        province.id && getListAreaByParentId(province.id, setCityEnum);
    }, [province]);

    useEffect(()=>{
        city.id && getListAreaByParentId(city.id, setAreaEnum);
    }, [city]);

    useEffect(() => {
        aliveInit && onSearch();
    }, [aliveInit]);

    // 重置后请求
    useEffect(() => {
        if (resetCode > 0) {
            onSearch();
        }
    }, [resetCode]);

    // 数据来源枚举
    const getListAreaByParentId = (id, cb)=>{
        http('/merchant/area/listAreaByParentId/'+id).then(res=>{
            cb(res.result);
        }).catch(err=>{
            message.error(err.message);
        });
    };
    // 审核状态枚举
    const getStateEnum = ()=>{
        http('/enum/MerchantAuditStateEnum').then(res=>{
            setStatusEnum(res.result);
        }).catch(err=>{
            message.error(err.message);
        });
    };

    const initData = (data) => {
        page.current = { pageNum: data.pageNum, pageSize: data.pageSize };
    };


    // 查询
    const onSearch = (usecache) => {
        if (usecache !== 'usecache') {
            page.current = { pageNum: autoPageNum, pageSize: autopageSize };
        }
        let { pageNum, pageSize } = page.current;
        let searchCache = {
            loginName,
            shopName,
            provinceId: province.id,
            cityId: city.id,
            areaId: area.id,
            auditState: status.code,
            startDate: postTime[0] ? postTime[0] + ' 00:00:00' : undefined,
            endDate: postTime[1] ? postTime[1] + ' 23:59:59' : undefined,
            shopType: 'MLX_CE',
            pageNum,
            pageSize,
        };

        http('/shop/audit/listAuditShop', searchCache).then(res=>{
            setResult(res.result);
        }).catch(err=>{
            message.error(err.message);
        });
        
        KeepAlive.saveCache({ loginName, shopName, province, city, area, status, pageNum, pageSize });
    };

    // 重置
    const onReset = () => {
        
        setStatus({});
        setResetCode(resetCode + 1);
        page.current = { pageNum: autoPageNum, pageSize: autopageSize };
    };

    const onPaginationChange = (size, num) => {
        page.current = { pageSize: size, pageNum: num };
        onSearch('usecache');
    };

    const _tableAction = (val, type, data) => {
        switch (type) {
            case 'detail':
                props.addOneBreadcrumbPath({
                    title: '详情',
                    path: '/home/auditmanage/storeAudit/detail/' + val
                });
                history.push({
                    pathname: '/home/auditmanage/storeAudit/detail/' + val
                });
                break;
            case 'audit':
                props.addOneBreadcrumbPath({
                    title: '审核详情',
                    path: '/home/auditmanage/storeAudit/audit/' + val
                });
                history.push({
                    pathname: '/home/auditmanage/storeAudit/audit/' + val
                });
                break;
            default:
            // nothing to do
        }
    };

    return (
        <KeepAlive render={(state) => {
            if (!state.aliveInit) {
                initData(state);
                setAliveInit(true);
            }
        }}>
            <div className={styles.flexBoxContainer}>
                <SearchBox>
                    <SearchItem>
                        <XInput
                            style={{marginRight: '30px'}}
                            inputStyle={{width: '120px'}}
                            label='手机号'
                            placeholder='请输入手机号'
                            bindName='loginName'
                            value={loginName}
                            bindThis={setLoginName}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XInput
                            style={{marginRight: '30px'}}
                            inputStyle={{width: '150px'}}
                            label='营业执照'
                            placeholder='请输入营业执照'
                            bindName='shopName'
                            value={shopName}
                            bindThis={setShopName}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            style={{ marginRight: '0px' }}
                            label='所在区域'
                            selectStyle={{width: '100px'}}
                            placeholder='省份'
                            renderData={provinceEnum}
                            onChange={setProvince}
                            dataIndex='areaName'
                            keyIndex='id'
                            showSearch={true}
                            value={province.areaName}
                        />
                        <XSelect
                            style={{ marginRight: '0px' }}
                            selectStyle={{width: '100px'}}
                            placeholder='城市'
                            renderData={cityEnum}
                            onChange={setCity}
                            dataIndex='areaName'
                            keyIndex='id'
                            showSearch={true}
                            value={city.areaName}
                        />
                        <XSelect
                            placeholder='区域'
                            style={{marginRight: '30px'}}
                            selectStyle={{width: '100px'}}
                            renderData={areaEnum}
                            onChange={setArea}
                            dataIndex='areaName'
                            keyIndex='id'
                            showSearch={true}
                            value={area.areaName}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XRangePicker
                            style={{marginRight: '30px'}}
                            label='查询时间'
                            placeholder='请输入查询时间'
                            bindThis={setPostTime}
                            bindName='postTime'
                            value={postTime}
                            format="YYYY-MM-DD"
                        />
                    </SearchItem>
                    <SearchItem>
                        <XSelect
                            selectStyle={{ width: '150px' }}
                            style={{marginRight: '30px'}}
                            label='审核状态'
                            placeholder='请选择'
                            renderData={statusEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={status.value}
                            bindName='status'
                            bindThis={setStatus}
                        />
                    </SearchItem>
                    <SearchItem>
                        <XOKButton
                            style={{marginRight: '20px'}}
                            label='查询'
                            onClick={onSearch}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={onReset}
                        />
                    </SearchItem>
                </SearchBox>
                <div className={styles.tableContainer}>
                    <TableData renderData={result} paginationChange={onPaginationChange} tableAction={_tableAction} />
                </div>
            </div>
        </KeepAlive>
    );
}

MainList.propTypes = {
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
};

export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb})(MainList);