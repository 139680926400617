/**
 * 抢拍记录
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import FightTable from './components/FightTable';
import { XInput, XDatePicker, XOKButton, XCancelButton, XInputNum, XExpandOrContract } from '@/components/xqxc_ui';
import styles from './index.module.scss';
import model from '@/reducers/marketingmanage/fightrecord/model';
import { parse } from 'qs';
import { Statistic, Card, Row, Col, Icon, Modal } from 'antd';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import CostDate from './modal/CostDate';
import SelectScroll from './components/selectScroll';
import { regFenToYuan } from '@/util/money';

const cardStyle = {
    color: 'rgba(0, 0, 0, 0.85)',
};

class FightRecord extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            telNumber: '',//手机号
            goodsNo: '',//货号
            orderId: '',//订单号
            startTime: null,//开始时间
            endTime: null,//结束时间
            titleName: undefined,//营销活动页面跳转带参(活动名称)
            id: undefined,//营销活动页面跳转带参(活动名称id)
            expandBoolean: false,//更多
            showTitle: {},//活动名称

            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参

            text1: '总销售额：选定条件下，抢拍活动中产生的已成功支付订单，在扣除优惠减免金额及优惠抵扣金额后，客户实际支付的金额，包含运费、税费（统计场景包含商品订单和批发订单）（按支付成功时间）。不扣除售后。',//总销售额
            text2: '总成交量：选定条件下，活动中产生的已成功支付订单单数（按支付成功时间）不扣除售后。',//总成交量
            text4: '支付人数：选定条件下，在活动中有过支付行为的去重人数（按支付成功时间）',//总支付人数
            text5: '客单价：选定条件下，平均每个客户支付的金额（总销售额/支付人数）',//客单价
        };
    }

    componentDidMount() {
        const { location, keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        let { titleName, id } = parse(location.search.substr(1));
        if (titleName) {
            this.setState({
                showTitle: { id, activityTitle: titleName },
                titleName,
                id
            });
        }
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, telNumber, goodsNo, startTime, endTime, orderId, showTitle, } = this.state;
        this.searchCache = {
            accountTel: telNumber,//手机号
            itemNo: goodsNo,//货号
            orderId: orderId,//订单号
            activityId: showTitle.id,//活动名称
            payTimeF: startTime != null ? startTime.format('YYYY-MM-DD HH:mm:ss') : null, //开始时间
            payTimeT: endTime != null ? endTime.format('YYYY-MM-DD HH:mm:ss') : null, // 结束时间
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getFightrecordList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        const { titleName, id } = this.state;
        this.setState({ telNumber: '', orderId: '', goodsNo: '', showTitle: titleName === undefined ? {} : { id, activityTitle: titleName }, startTime: null, endTime: null }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getFightrecordList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    //info信息
    cardHandle = (text) => {
        Modal.info({
            title: '帮助提示',
            content: <div>{text}</div>,
        });
    }

    // 更多搜索条件的展开与收缩
    _expandChangeHandle = (expandBoolean) => {
        this.setState({ expandBoolean });
    }

    // 表格操作
    _tableAction = (type, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        const { buyerId, orderId } = record;
        switch (type) {
            case 'detail':// 订单号
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: '/home/ordermanage/productorder/orderdetail/'
                });
                history.push({
                    pathname: '/home/ordermanage/productorder/orderdetail',
                    search: `?buyerId=${buyerId}&&orderId=${orderId}`,
                });
                break;
            case 'cost'://平台费用明细
                this.setState({
                    currentOpenMode: 'cost',
                    openModeParam: record,
                });
                break;
        }
    }

    // 打开弹窗  
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'cost':
                return <CostDate title='平台费用明细' data={this.state.openModeParam} visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getFightrecordList(this.searchCache);
        }
    }

    render() {
        const { telNumber, goodsNo, startTime, endTime, text1, text2, text4, text5, orderId, expandBoolean, showTitle, } = this.state;
        let { fightrecordList, } = this.props;
        let { totalSales, totalVolume, totalPayersNumber, customerUnitPrice } = fightrecordList;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.card}>
                        <Row gutter={{ xs: 16, sm: 16 }}>
                            <Col span={4}>
                                <Card>
                                    <Statistic
                                        title={<div className={styles.laycard}>
                                            <span>总销售额</span>
                                            <Icon
                                                style={{ fontSize: '24px', }}
                                                onClick={() => this.cardHandle(text1)}
                                                type="info-circle"
                                            />
                                        </div>}
                                        value={regFenToYuan(totalSales)}
                                        precision={2}
                                        valueStyle={cardStyle}
                                    />
                                </Card>
                            </Col>
                            <Col span={4}>
                                <Card>
                                    <Statistic
                                        title={<div className={styles.laycard}>
                                            <span>总成交量</span>
                                            <Icon
                                                style={{ fontSize: '24px', }}
                                                onClick={() => this.cardHandle(text2)}
                                                type="info-circle"
                                            />
                                        </div>}
                                        value={totalVolume}
                                        precision={0}
                                        valueStyle={cardStyle}
                                    />
                                </Card>
                            </Col>
                            <Col span={4}>
                                <Card>
                                    <Statistic
                                        title={<div className={styles.laycard}>
                                            <span>总支付人数</span>
                                            <Icon
                                                style={{ fontSize: '24px', }}
                                                onClick={() => this.cardHandle(text4)}
                                                type="info-circle"
                                            />
                                        </div>}
                                        value={totalPayersNumber}
                                        precision={0}
                                        valueStyle={cardStyle}
                                    />
                                </Card>
                            </Col>
                            <Col span={4}>
                                <Card>
                                    <Statistic
                                        title={<div className={styles.laycard}>
                                            <span>客单价</span>
                                            <Icon
                                                style={{ fontSize: '24px', }}
                                                onClick={() => this.cardHandle(text5)}
                                                type="info-circle"
                                            />
                                        </div>}
                                        value={regFenToYuan(customerUnitPrice)}
                                        precision={2}
                                        valueStyle={cardStyle}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className={styles.searchBox}>
                        <SelectScroll
                            selectStyle={{ width: '150px' }}
                            label='活动名称'
                            placeholder='请选择'
                            bindThis={this}
                            bindName='showTitle'
                            value={showTitle.activityTitle}
                            isOptionBindID={true}
                            dataIndex='activityTitle'
                            keyIndex='id'
                            apiUrl='/marketing/activityMerchantInfo/activitylist'
                            scrollParams={{
                                activityTitle: '',
                                attendState: '1',
                                activityType: 'QP_ACTIVITY',
                                pageNum: 1,
                                pageSize: 10
                            }}
                        />
                        <XInput
                            style={{ marginLeft: '20px', }}
                            inputStyle={{ width: '150px' }}
                            label='货号'
                            placeholder='请输入'
                            value={goodsNo}
                            bindThis={this}
                            bindName='goodsNo'
                        />
                        <XInput
                            style={{ marginLeft: '20px', }}
                            inputStyle={{ width: '150px' }}
                            label='会员手机号'
                            placeholder='请输入'
                            value={telNumber}
                            bindThis={this}
                            bindName='telNumber'
                        />
                        <XInputNum
                            style={{ margin: '0 20px', }}
                            inputStyle={{ width: '150px' }}
                            label='订单号'
                            placeholder='请输入订单编号'
                            value={orderId}
                            bindThis={this}
                            bindName='orderId'
                            min={0}
                            precision={0}
                            maxLength={16}
                        />
                        <XOKButton
                            style={{ marginRight: '20px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                        <XExpandOrContract style={{ marginLeft: '20px' }} onChange={this._expandChangeHandle} />
                    </div>
                    <div className={`${styles.searchCriteriaHidden} ${expandBoolean ? styles.moreShow : styles.moreHidden}`}>
                        <div style={{ marginTop: 10 }} className={styles.searchBox}>
                            <XDatePicker
                                label='付款日期'
                                value={startTime}
                                bindThis={this}
                                bindName='startTime'
                            />
                            <XDatePicker
                                style={{ marginRight: '20px' }}
                                // label=''
                                value={endTime}
                                bindThis={this}
                                bindName='endTime'
                            />
                        </div>
                    </div>
                    <FightTable renderData={fightrecordList} paginationChange={this._paginationChange} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

FightRecord.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    location: PropTypes.object,// 路由location对象
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,

    fightrecordList: PropTypes.object,
    getFightrecordList: PropTypes.func,//表格数据(action)
};

const mapStateToProps = (state) => ({
    fightrecordList: state.fightrecord.fightrecordList,
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(FightRecord);
