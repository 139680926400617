import { createModel } from '@/store/tools';

const model = {
    namespace: 'merchantnotice',
    build: (T, A, S, R) => ({
        actiontypes: {
            // 商户须知
            'getQuestionList': T('getQuestionList'),
            'getSearchList': T('getSearchList'),

            // 促销推广
            'getTitleList': T('getTitleList'),

            // 详情
            'getDetail': T('getDetail'),
        },
        actions: {
            'getQuestionList': A('getQuestionList'),
            'getSearchList': A('getSearchList'),
            'getTitleList': A('getTitleList'),
            'getDetail': A('getDetail'),
        },
        sagas: {
            'getQuestionList': S('getQuestionList', '/instruction/listFAQ'),
            'getSearchList': S('getSearchList', '/instruction/listQuestion'),
            'getTitleList': S('getTitleList', '/instruction/listTitle'),
            'getDetail': S('getDetail', '/instruction/questionDetail'),
        },
        reducers: {
            'questionList': R('getQuestionList', []),
            'searchList': R('getSearchList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'titleList': R('getTitleList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'detail': R('getDetail', {}),
        }
    })
};
export default createModel(model);