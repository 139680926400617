/**
 * 确认对账单 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { Modal, Input, Form, Radio, message } from 'antd';
import styles from './ConfirBill.module.scss';

const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
    // labelAlign: 'left',
};
class NoLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
        };
    }
    state = {
    }
    // 点击确认
    _handleOk = () => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                http('/settle/paymentApplication/audit', { ...fieldsValue, paymentApplicationId: this.props.data }).then((res) => {
                    if (res.code === '200') {
                        message.success('通过成功');
                    }
                    this.props.closeMode(true);
                });
            }
        });

    };
    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    render() {
        const { visible, confirmLoading, title } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                width={800}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        <Form.Item
                            colon
                            htmlFor='toStatus'
                            label={<span>对账确认</span>}
                            style={{ textAlign: 'left' }}
                        >
                            {getFieldDecorator('toStatus', {
                                rules: [{ required: true, message: '请选择' }],
                                initialValue: 'PASS_AUDIT'
                            })(
                                <Radio.Group>
                                    <Radio value='PASS_AUDIT'>通过</Radio>
                                    <Radio value='WAIT_AUDIT'>不通过</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item
                            colon
                            htmlFor='comment'
                            label={<span>备注</span>}
                        >
                            {getFieldDecorator('comment', {
                                // rules: [{ required: true, message: '请输入策略名称' }],
                            })(
                                <TextArea rows={4} />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}

NoLogin.propTypes = {
    data: PropTypes.object, // 弹框入参
    form: PropTypes.object.isRequired,
    getListAreaByParentId: PropTypes.func, // 请求地区信息
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(NoLogin);// 校验
export default connect(null, null)(ForgotProducDetails);