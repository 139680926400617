import React, { useState, useEffect } from 'react';
import styles from './tableStyle.module.scss';
import PropTypes from 'prop-types';
import { Table, Icon, Typography, Switch, Button } from 'antd';
import { tooltipStyle, tooltip } from '@/components/TableCell';
import { regFenToYuan } from '@/util/money';

const { Text } = Typography;

const TableContent = ({
    tableOperate,
    updatePage,
    goodsIds,
    renderData: { dataList }
}) => {

    const [keys, setKeys] = useState([]);

    useEffect(() => { setKeys(goodsIds); }, [goodsIds]);

    const renderLink = (goodName, item) => {
        return <Button
            type='link'
            onClick={() => tableOperate('detail', item.goodsId)}>
            {tooltip(goodName)}</Button>;
    };

    //是否同步
    const renderOnline = (text, record) => {
        return (tooltip(text == '1' ? '已同步' : '未同步'));
    };

    const priceCell = (text, item) => {
        const priceText = item.minSalePrice == item.maxSalePrice ?
            regFenToYuan(item.maxSalePrice) :
            `${regFenToYuan(item.minSalePrice)} - ${regFenToYuan(item.maxSalePrice)}`;
        return <>
            <Text>{priceText}</Text>
            <Icon
                type='edit'
                theme='twoTone'
                style={{ marginLeft: 10 }}
                onClick={() => tableOperate('updatePrice', item.goodsId)}
            />
        </>;
    };

    const operateCell = (text, item) => {
        return <div className={styles.action}>
            {(item.goodsState && item.goodsState.code == 'PUT_ON_SALE') ? 
                null : (
                    <div className={styles.item}
                        onClick={() => updatePage('编辑店铺商品', `/editgoods/${item.goodsId}`)}
                    >编辑</div>
                )
            }
            
            {
                (item.goodsState && item.goodsState.code == 'PUT_ON_SALE') ?
                    <div
                        className={styles.item}
                        onClick={() => tableOperate('soldOut', [item.goodsId])}
                    >下架</div>
                    : <div
                        className={styles.item}
                        onClick={() => tableOperate('putaway', item)}
                    >上架</div>
            }
            <div
                className={styles.item}
                onClick={() => tableOperate('updateInventory', item.goodsId)}
            >更新库存</div>
        </div>;
    };

    const renderSwitch = (text, item) => {
        const flag = text ? true : false;
        return <Switch
            checked={flag}
            onChange={checked => tableOperate('recommend', { checked, goodsId: item.goodsId })}
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<Icon type="close" />}
        />;
    };

    const columns = [
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'center',
            width: '20%',
            onCell: tooltipStyle,
            render: renderLink
        },
        {
            title: '价格',
            dataIndex: 'price',
            key: 'price',
            align: 'center',
            width: '15%',
            onCell: tooltipStyle,
            render: priceCell
        },
        {
            title: '剩余库存',
            dataIndex: 'inventory',
            key: 'inventory',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: (text, item) => tooltip(item.totalQuantity - item.occupyQuantity)
        },
        {
            title: '占用库存',
            dataIndex: 'occupyQuantity',
            key: 'occupyQuantity',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '是否同步到线上',
            dataIndex: 'isSynchronize',
            key: 'isSynchronize',
            align: 'center',
            width: '10%',
            onCell: tooltipStyle,
            render: renderOnline
        },
        {
            title: '同城推荐',
            dataIndex: 'isShopReco',
            key: 'isShopReco',
            align: 'center',
            width: '15%',
            onCell: tooltipStyle,
            render: renderSwitch
        },
        {
            title: '操作',
            dataIndex: 'goodsId',
            key: 'goodsId',
            align: 'center',
            width: '20%',
            onCell: tooltipStyle,
            render: operateCell
        },
    ];

    const onSelectChange = selectedRowKeys => {
        setKeys(selectedRowKeys);
        tableOperate('goodsIds', selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys: keys,
        onChange: onSelectChange,
    };

    return <Table
        style={{ height: 'calc(100% - 100px)' }}
        rowSelection={rowSelection}
        columns={columns}
        pagination={false}
        rowKey={record => record.goodsId}
        dataSource={dataList}
        scroll={{ y: true }}
    />;
};

TableContent.propTypes = {
    tableOperate: PropTypes.func,
    renderData: PropTypes.object,
    updatePage: PropTypes.func,
    goodsIds: PropTypes.array
};

export default TableContent;