import React, { Component } from 'react';
import { Table, Alert } from 'antd';
import PropTypes from 'prop-types';
import styles from './LiveTable.module.scss';
import { regFenToYuan } from '@/util/money';
import { XPagination } from '@/components/xqxc_ui';
import { secToHms } from '@/util/timeSwitch';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

export default class GroupTable extends Component {

    //初始化
    constructor(props) {
        super(props);
        this.state = {};
    }

    //props校验
    static propTypes = {
        renderData: PropTypes.object.isRequired, // 表格数据源
        tableAction: PropTypes.func.isRequired, // 表格操作
        paginationChange: PropTypes.func.isRequired, // 分页操作
    };

    //表头
    _columns = () => [
        {
            title: '手机号码',
            dataIndex: 'tel',
            key: 'tel',
            width: '5%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '主播昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            width: '7%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '封面',
            dataIndex: 'coverPage',
            key: 'coverPage',
            width: 50,
            align: 'center',
            render: ImgCell
        },
        {
            title: '直播间标题',
            dataIndex: 'broadcasTitle',
            key: 'broadcasTitle',
            width: '7%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '所属分类',
            dataIndex: 'broadcasClass',
            key: 'broadcasClass',
            width: '7%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '直播间状态',
            dataIndex: 'liveState',
            key: 'liveState',
            width: '7%',
            align: 'center',
            onCell: tooltipStyle,
            render: (text, record) => {
                return <span>{text && text.value}</span>;
            }
        },
        {
            title: '总人数',
            dataIndex: 'viewerNum',
            key: 'viewerNum',
            width: '7%',
            align: 'center'
        },
        {
            title: '魅力值收入',
            dataIndex: 'starlightNumber',
            key: 'starlightNumber',
            width: '7%',
            align: 'center',
            render: (text, record) => <a onClick={() => this.props.tableAction(record.id, 'starValue', record)}>{text}</a>
        },
        {
            title: '销售商品分红',
            dataIndex: 'saleBonus',
            key: 'saleBonus',
            width: '7%',
            align: 'center',
            render: (text, record) => <a onClick={() => this.props.tableAction(record.id, 'salesValue', record)}>{this._renderTextMoneyCell(text, record)}</a>
        },
        {
            title: '总销售额（元）',
            dataIndex: 'totalSales',
            key: 'totalSales',
            width: '7%',
            align: 'center',
            render: (text, record) => <a onClick={() => this.props.tableAction(record.id, 'salesValue', record)}>{this._renderTextMoneyCell(text, record)}</a>
        },
        {
            title: '直播时间',
            dataIndex: 'liveTimes',
            key: 'liveTimes',
            width: '7%',
            align: 'center',
            render: (text) => secToHms(text)
        },
        {
            title: '直播开始时间',
            dataIndex: 'startTime',
            key: 'startTime',
            width: '10%',
            align: 'center'
        },
        {
            title: '直播结束时间',
            dataIndex: 'endTime',
            key: 'endTime',
            width: '10%',
            align: 'center'
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: '5%',
            align: 'center',
            render: (id, record) => this._tableAction(record.id, record)
        }
    ];

    //处理返回数据
    _takeDataSource = (data) => {
        return data ? data : [];
    }
    _renderTextMoneyCell = (text, record) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }

    //表格操作
    _tableAction = (key, record) => {
        const { tableAction } = this.props;
        let isLive = record.liveState.code == 'LIVE';
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'play', record)}>{isLive ? '播放' : '回放'}</div>
            </div>

        );
    };

    //渲染组件
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    columns={this._columns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}