/**
 * 发货管理
 */
import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actionTypes';

// 获取已取消出库单
export const getCancelDeliverList = function* () {
    yield takeEvery(T.GET_CANCELDELIVER_LIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/cancelledOutboundOrderList', action.payload);
            yield put({ type: T.SET_CANCELDELIVER_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};