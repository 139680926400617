/**
 * 秀场管理 Saga集合
 */

import { getCategoryTableSource } from './materialcategory/saga';
import { getMaterialTableSource } from './materiallist/saga';
import { getLogisticsState, getLogisticsList } from './logistics/saga';
import delivergoodsset from './delivergoodsset/model';
import delivertemplate from './delivertemplate/model';
import businessCategory from './businessCategory/model';

export default [
    getCategoryTableSource,
    getMaterialTableSource,
    getLogisticsState,
    getLogisticsList,
    ...Object.values(delivergoodsset.sagas),
    ...Object.values(delivertemplate.sagas),
    ...Object.values(businessCategory.sagas),
];
