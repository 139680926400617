import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as C from './actiontypes';

export const getHotelCommentList = function* (){
    yield takeEvery(C.GET_HOTELCOMMENT_LIST, function* requestData(action){
        try{
            let result = yield http('/mer/comment/goods/list', action.payload, 'POST');
            yield put({type:C.SET_HOTELCOMMENT_LIST, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    }); 
};
export const getHotelCommentDetail = function* (){
    yield takeEvery(C.GET_HOTELCOMMENT_DETAIL, function* requestData(action){
        try{
            let result = yield http('/mer/comment/goods/detail', action.payload, 'POST');
            yield put({type:C.SET_HOTELCOMMENT_DETAIL, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    }); 
};
export const getHotelClassify = function* (){
    yield takeEvery(C.GET_HOTELCOMMENT_CLASSIFY, function* requestData(action){
        try{
            let result = yield http('/goods/category/hotelMerchantCategory', action.payload, 'POST');
            yield put({type:C.SET_HOTELCOMMENT_CLASSIFY, payload: result});
        }catch(e){
            yield put({type:'SAGA_ERROR', payload:{error:e}});
        }
    }); 
};