import * as Order from './actionTypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

//订单列表数据
export const productOrderList = (state = initData, action) => {
    switch (action.type) {
        case Order.SET_PRODUCTORDER_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
//分销订单列表数据
export const fenxiaoOrderList = (state = initData, action) => {
    switch (action.type) {
        case Order.SET_FENXIAOORDER_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
//商品订单类型
export const productOrderType = (state = [], action) => {
    switch (action.type) {
        case Order.SET_PRODUCTORDER_TYPE:
            return action.payload.result;
        default:
            return state;
    }
};

//商品订单状态
export const productOrderStatus = (state = [], action) => {
    switch (action.type) {
        case Order.SET_PRODUCTORDER_STATUS:
            return action.payload.result;
        default:
            return state;
    }
};

//商品是否开票
export const productOrderFlagEnum = (state = [], action) => {
    switch (action.type) {
        case Order.SET_PRODUCTORDER_FLAGENUM:
            return action.payload.result;
        default:
            return state;
    }
};
//认购订单状态
export const productSubOrderStatus = (state = [], action) => {
    switch (action.type) {
        case Order.SET_PRODUCTSUBORDER_STATUS:
            return action.payload.result;
        default:
            return state;
    }
};
//业务类型
export const businessType = (state = [], action) => {
    switch (action.type) {
        case Order.SET_BUSINESS_TYPE:
            return action.payload.result;
        default:
            return state;
    }
};