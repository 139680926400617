import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, Popconfirm, Checkbox, Empty, Tag } from 'antd';


export class HotelSetting extends React.PureComponent {

    state = {
        selectAll: false,
        tempSelecteds: []
    }

    onAdd = (item) => {
        const { dataSource = [], value = [] } = this.props;
        let tempSelecteds = (this.state.tempSelecteds).concat();
        let index = tempSelecteds.indexOf(item.id);
        index > -1
            ? tempSelecteds.splice(index, 1)
            : tempSelecteds.push(item.id);
        let unselects = dataSource.filter(item => value.indexOf(item.id) === -1);
        this.setState({ tempSelecteds, selectAll: tempSelecteds.length === unselects.length });
    }

    onSelectAll = () => {
        let selectAll = !this.state.selectAll;
        let tempSelecteds = selectAll ? this.props.dataSource.map(item => item.id) : [];
        this.setState({ selectAll, tempSelecteds });
    }

    onConfirm = () => {
        this.props.onChange(this.state.tempSelecteds.concat(this.props.value || []));
        this.setState({ tempSelecteds: [], selectAll: false });
    }

    onRemove = (item) => {
        let changes = (this.props.value || []).filter(id => item.id !== id);
        this.props.onChange(changes);
    }

    onClear = () => {
        this.props.onChange(undefined);
    }

    render() {
        let { selectAll, tempSelecteds } = this.state;
        const { dataSource = [], value = [] } = this.props;

        let selecteds = dataSource.filter(item => value.indexOf(item.id) > -1);
        let unselects = dataSource.filter(item => value.indexOf(item.id) === -1);

        let empty = !unselects.length;

        return (
            <>
                <div>
                    <Popover
                        placement="top"
                        trigger="click"
                        overlayStyle={{ maxWidth: '400px', minWidth: '250px' }}
                        title={
                            <div>
                                提示：点击添加设施
                                {!empty && <Checkbox checked={selectAll} style={{ float: 'right' }} onClick={this.onSelectAll}>全选</Checkbox>}
                            </div>
                        }
                        content={
                            <div>
                                {empty
                                    ? <Empty description='设施已选完' />
                                    : unselects.map(item =>
                                        <Tag key={item.id} color={tempSelecteds.indexOf(item.id) > -1 ? '#108ee9' : undefined}
                                            style={{ marginRight: 5, marginBottom: 5, cursor: 'pointer' }} onClick={() => this.onAdd(item)}>{item.name}
                                        </Tag>)
                                }
                                {!empty &&
                                    <div style={{ textAlign: 'right' }}>
                                        <Button size='small' type='primary' onClick={this.onConfirm}>确定</Button>
                                    </div>
                                }

                            </div>
                        }
                    >
                        <Button size='small' type='primary'>增加</Button>
                    </Popover>

                </div>
                <div>
                    {selecteds.length
                        ? <>
                            {selecteds.map(item => <Button key={item.id} size='small' type='primary' style={{ marginRight: 5 }} onClick={() => this.onRemove(item)}>{item.name} <span>&nbsp;x</span></Button>)}
                            {selecteds.length > 1
                                ? <Popconfirm placement='top' title='您是否删除全部设施，清除后无法恢复' onConfirm={this.onClear} okText='是' cancelText='否'>
                                    <Button size='small' type='primary'>清除全部</Button>
                                </Popconfirm>
                                : null
                            }
                        </>
                        : <span style={{ marginRight: 5, color: '#808080' }}>请先添加酒店设施</span>
                    }
                </div>
            </>
        );
    }
}

HotelSetting.propTypes = {
    value: PropTypes.array,
    dataSource: PropTypes.array,
    onChange: PropTypes.func,
};

export default HotelSetting;