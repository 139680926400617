/**
 * 订单应收金额明细（供应链）
 */
import React from 'react';
import { Tag, message } from 'antd';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import WalletButton from '@/vcomps/WalletButton';
import { XInput, XSelect, XOKButton, XCancelButton, XExpandOrContract, XDatePicker } from '@/components/xqxc_ui';
import { connect } from 'react-redux';
import { keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import TableContent from './TableContent';
import { getTranscationList2, getStoreList, getMerchantOrderAmount } from '@/reducers/ordermanage/transcation/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { regFenToYuan } from '@/util/money';

const tags = [{ id: 0, color: '#f50', value: '今日' }, { id: 1, color: '#2db7f5', value: '本周' }, { id: 2, color: '#87d068', value: '本月' }, { id: 3, color: '#108ee9', value: '全年' }];
const orderStatus = [{
    code: 'SETTLED',
    value: '已结算',
}, {
    code: 'UNSETTLED',
    value: '未结算',
}];

class Main extends React.Component {

    state = {
        payStartDate: undefined,
        payEndDate: undefined,
        curTagID: 1,
        period: 'week',
        expandBoolean: false, // 是否展开
        orderId: '', // 订单交易号
        orderState: {}, // 订单状态
        userState: {}, // 商户
        itemNo: '', // 货号
        goodsName: '',// 商品名称
        payItemId: '', // 支付流水号
    };

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getStoreList();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, period, payItemId, payStartDate, payEndDate, orderState, userState, orderId, itemNo, goodsName } = this.state;
        let query = {};
        if (payStartDate && payEndDate) {
            query = {
                settleType: orderState.code,
                shopId: userState.shopId,
                orderId,
                itemNo,
                goodsName,
                payStartDate,
                payItemId,
                payEndDate,
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            };
            this.setState({ period: undefined, curTagID: -1 });
        } else if (period) {
            query = {
                settleType: orderState.code,
                shopId: userState.shopId,
                orderId,
                itemNo,
                goodsName, 
                payItemId,
                period,
                pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
                pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            };
            this.setState({ payStartDate: undefined, payEndDate: undefined });
        } else {
            message.error('请选择付款日期范围');
        }
        this.props.getTranscationList2({ ...query });
        this.props.getMerchantOrderAmount({ ...query });
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            payStartDate: undefined,
            payEndDate: undefined,
            curTagID: 1,
            period: 'week',
            orderState: {},
            userState: {},
            orderId: '',
            itemNo: '',
            goodsName: '',
            payItemId: '',
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 展开或收缩搜索条件
    _expandChangeHandle = (expandBoolean) => {
        this.setState({ expandBoolean });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 渲染Tags
    _renderTags = () => {
        const { curTagID } = this.state;
        return (
            <div style={{ marginLeft: '20px' }}>
                {tags.map((item, index) => {
                    let color = curTagID == item.id ? item.color : '';
                    return <Tag style={{ width: '50px', textAlign: 'center', cursor: 'pointer' }} key={index} color={color} onClick={() => { this._tagClick(item.id); }}>{item.value}</Tag>;
                })}
            </div>
        );
    }

    // 点击Tags
    _tagClick = (id) => {
        this.setState({ curTagID: id, payStartDate: undefined, payEndDate: undefined, payItemId: '' }, () => {
            switch (id) {
                case 0:
                    this.setState({ period: 'day' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
                case 1:
                    this.setState({ period: 'week' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
                case 2:
                    this.setState({ period: 'month' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
                case 3:
                    this.setState({ period: 'year' }, () => {
                        this._searchHandle('useCache');
                    });
                    break;
            }
        });
    }

    render() {
        const { payStartDate, payEndDate, expandBoolean, orderId, itemNo, goodsName, orderState, userState, payItemId } = this.state;
        const { transcationList, merchantOrderAmount, storeList } = this.props;
        const { toBeSettledAmount, settledAmount } = merchantOrderAmount;

        console.log('transcationList:', transcationList);
        console.log('merchantOrderAmount', merchantOrderAmount);

        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria}>
                        <XInput
                            style={{ marginRight: 10 }}
                            inputStyle={{ width: '180px' }}
                            label='支付流水号'
                            placeholder='请输入支付流水号'
                            value={payItemId}
                            bindThis={this}
                            bindName='payItemId'
                        />
                        <XDatePicker
                            label='收付款日期'
                            value={payStartDate}
                            bindThis={this}
                            bindName='payStartDate'
                            isFormat={true}
                            showTime={false}
							defaultTime='00:00:00'
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            label=''
                            value={payEndDate}
                            bindThis={this}
                            bindName='payEndDate'
                            isFormat={true}
                            showTime={false}
							defaultTime='23:59:59'
                        />
                        {this._renderTags()}
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                        <XExpandOrContract style={{ marginLeft: '20px' }} onChange={this._expandChangeHandle} />
                    </div>
                    <div className={`${styles.searchCriteriaHidden} ${expandBoolean ? styles.moreShow : styles.moreHidden}`}>
                        <div className={styles.criteria}>
                            <XInput
                                style={{ marginLeft: 13 }}
                                inputStyle={{ width: '180px' }}
                                label='商品名称'
                                placeholder='请输入商品名称'
                                value={goodsName}
                                bindThis={this}
                                bindName='goodsName'
                            />
                            <XInput
                                style={{ marginLeft: '20px' }}
                                inputStyle={{ width: '180px' }}
                                label='商品货号'
                                placeholder='请输入货号'
                                value={itemNo}
                                bindThis={this}
                                bindName='itemNo'
                            />
                            <XSelect
                                style={{ marginLeft: '20px', width: '265px' }}
                                selectStyle={{ width: '180px' }}
                                label='店铺'
                                placeholder='请选择店铺'
                                renderData={storeList || []}
                                dataIndex='shopName'
                                keyIndex='shopId'
                                value={userState.shopName}
                                showSearch={true}
                                bindThis={this}
                                bindName='userState'
                            />
                        </div>
                        <div className={styles.criteria}>
                            <XInput
                                inputStyle={{ width: '180px' }}
                                label='交易订单号'
                                placeholder='请输入交易订单号'
                                value={orderId}
                                bindThis={this}
                                bindName='orderId'
                            />
                            <XSelect
                                style={{ marginLeft: '20px' }}
                                selectStyle={{ width: '180px' }}
                                label='订单状态'
                                placeholder='请选择订单状态'
                                renderData={orderStatus}
                                dataIndex='value'
                                keyIndex='code'
                                value={orderState.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='orderState'
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                        <WalletButton />
                        <div style={{ marginLeft: '20px' }}>待结算金额：{regFenToYuan(toBeSettledAmount)}元</div>
                        <div style={{ marginLeft: '20px' }}>已结算金额：{regFenToYuan(settledAmount)}元</div>

                    </div>
                    <TableContent renderData={transcationList || {}} paginationChange={this._paginationChange} />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getTranscationList2: PropTypes.func,
    getMerchantOrderAmount: PropTypes.func,
    getStoreList: PropTypes.func,
    transcationList: PropTypes.object,
    merchantOrderAmount: PropTypes.object,
    storeList: PropTypes.array,
};
const mapStateToProps = (state) => {
    return ({
        storeList: state.Transcation.storeList,
        transcationList: state.Transcation.transcationList2,
        merchantOrderAmount: state.Transcation.merchantOrderAmount
    });
};
export default connect(mapStateToProps, { keepSecondNavBreadcrumb, getTranscationList2, getMerchantOrderAmount, getStoreList })(Main);