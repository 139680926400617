import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { XInput, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import model from '@/reducers/productmanage/classify/model';

class VirtualClassify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryName: '',
            expandedKeys: [],
            keys: []
        };
    }

    // 查询
    _searchHandle = (useCache) => {
        const { categoryName, pageNum, pageSize } = this.state;
        this.searchCache = {
            categoryName, categoryType: 'VIRTUAL',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getVirtualClassList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ categoryName: '', }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //keys
    _expandAll = () => {
        const { tableData: { dataList } } = this.props;
        let expandedKeys = [];
        for (let item of dataList) {
            expandedKeys.push(item.id);
            if (item.childCategory) {
                for (let cc of item.childCategory) {
                    expandedKeys.push(cc.id);
                }
            }
        }
        this.setState({ expandedKeys });
    }

    _closeAll = () => {
        this.setState({ expandedKeys: [] });
    }

    _handleOnExpand = (expanded, record) => {
        console.log(expanded);
        const { expandedKeys } = this.state;
        if (expanded) {
            expandedKeys.push(record.id);
            this.setState({ expandedKeys });
        } else {
            this.setState({ expandedKeys: expandedKeys.filter(item => item != record.id) });
        }
    }

    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    render() {
        const { tableData } = this.props;
        const { categoryName, expandedKeys } = this.state;
        return (
            <KeepAlive id='virtualClassify' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <XInput
                            style={{ width: '266px' }}
                            inputStyle={{ width: '200px' }}
                            label='一级分类名称'
                            placeholder='请输入分类名称'
                            bindThis={this}
                            bindName='categoryName'
                            value={categoryName}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '90px' }} label='全部展开' onClick={this._expandAll} />
                        <XOKButton style={{ width: '90px', marginLeft: '20px' }} label='全部收起' onClick={this._closeAll} />
                    </div>
                    <TableContent
                        paginationChange={this._paginationChange}
                        renderData={tableData}
                        tableAction={this._tableOperate}
                        handleOnExpand={this._handleOnExpand}
                        expandedKeys={expandedKeys} />
                </div>
            </KeepAlive>
        );
    }
}

VirtualClassify.propTypes = {
    history: PropTypes.object,
    getVirtualClassList: PropTypes.func,
    tableData: PropTypes.object
};

const mapStateToProps = (state) => ({
    tableData: state.productClassifyData.virtualClassList,
});
export default connect(mapStateToProps, { ...model.actions })(VirtualClassify);