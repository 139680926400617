import React from 'react';
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
import Marketing from '../marketing';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';

const Mode = {
    CREAT: 'CREAT',//新建
    DELETE: 'DELETE',//详情
    EDIT: 'EDIT',//编辑
};

class Main extends React.Component {

    state = {

    }

    componentDidMount() {
        const { keepSecondNavBreadcrumb } = this.props;
        keepSecondNavBreadcrumb();
    }
    // 更新界面
    _updateContent = (mode, value, code) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (mode) {
            case Mode.CREAT:
                addOneBreadcrumbPath({
                    title: '参与活动',
                    path: '/home/marketingmanage/advocacytask/createInfo/'
                });
                history.push({
                    pathname: '/home/marketingmanage/advocacytask/createInfo/',
                });
                break;
            case Mode.DELETE:
                addOneBreadcrumbPath({
                    title: '平台活动详情',
                    path: '/home/marketingmanage/advocacytask/detailInfo/'
                });
                history.push({
                    pathname: `/home/marketingmanage/advocacytask/detailInfo/${value}/${code}`,
                });
                break;
            case Mode.EDIT:
                addOneBreadcrumbPath({
                    title: '参与活动',
                    path: '/home/marketingmanage/advocacytask/editorInfo'
                });
                history.push({
                    pathname: '/home/marketingmanage/advocacytask/editorInfo/' + value,
                });
                break;
            case 'fight'://抢拍记录
                addOneBreadcrumbPath({
                    title: '抢拍记录',
                    path: '/home/marketingmanage/fightrecord',
                });
                history.push({
                    pathname: '/home/marketingmanage/fightrecord',
                    search: `titleName=${value.activityTitle}&id=${value.id}`,
                });
                break;
            case 'goods'://追加商品
                addOneBreadcrumbPath({
                    title: '参与活动',
                    path: '/home/marketingmanage/advocacytask/editorInfo',
                });
                history.push({
                    pathname: '/home/marketingmanage/advocacytask/editorInfo/' + value.id,
                    search: 'handle=add',
                });
                break;
        }
    }

    render() {
        return (
            <div className={`${styles.flexBoxContainer}`}>
                <Marketing updateContent={this._updateContent} history={this.props.history} />
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    addOneBreadcrumbPath: PropTypes.func, // 新增3级面包屑导航信息
    keepSecondNavBreadcrumb: PropTypes.func // 保持2级面包屑导航信息
};


export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);