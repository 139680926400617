import React, { useState, useEffect } from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const TableContent = ({
    renderData: {
        dataList,
        pageNum,
        pageSize,
        resultTotal
    },
    paginationChange,
    tableAction,
    batchKeys = []
}) => {

    const [keys, setKeys] = useState([]);

    useEffect(() => { setKeys(batchKeys); }, [batchKeys]);

    // 表格复选输入
    const rowSelection = {
        selectedRowKeys: keys,
        onChange: (values) => {
            tableAction(null, 'batch', values);
        }
    };
    // 清空表格复选框
    const cleanAllSelected = () => {
        tableAction(null, 'batch', []);
    };

    const tableOperate = (text, { stockId, status: { code = '' } = {} }) => {
        return (
            <div className={styles.action}>
                <div
                    className={styles.item}
                    onClick={() => tableAction(stockId, 'detail')}
                >详情</div>
                {
                    (code == 'PLACE_ORDER_ABNORMAL_FAILURE' ||
                        code == 'ORDER_PAYMENT_FAILURE') &&
                    <div
                        className={styles.item}
                        onClick={() => tableAction(stockId, 'resubmit')}
                    >
                        重新推送</div>
                }
            </div>
        );
    };

    const columns = [
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: tableOperate
        },
        {
            title: '出库单号',
            dataIndex: 'stockId',
            key: 'stockId',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '订单号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '收货人电话',
            dataIndex: 'consigneePhone',
            key: 'consigneePhone',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '物流公司',
            dataIndex: 'logisticsCompany',
            key: 'logisticsCompany',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '物流单号',
            dataIndex: 'shipmentNumber',
            key: 'shipmentNumber',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '供应商订单号',
            dataIndex: 'outOrderId',
            key: 'outOrderId',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'status.value',
            key: 'status.value',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '响应消息',
            dataIndex: 'respMsg',
            key: 'respMsg',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        // {
        //     title: '下单校验时间',
        //     dataIndex: 'orderCheckTime',
        //     key: 'orderCheckTime',
        //     width: 150,
        //     align: 'center',
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        {
            title: '创建订单时间',
            dataIndex: 'orderCreateTime',
            key: 'orderCreateTime',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '取消时间',
            dataIndex: 'cancelTime',
            key: 'cancelTime',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '支付时间',
            dataIndex: 'orderPayTime',
            key: 'orderPayTime',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '清关或打包时间',
            dataIndex: 'customsClearanceTime',
            key: 'customsClearanceTime',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        // {
        //     title: '状态同步时间',
        //     dataIndex: 'statusSyncTime',
        //     key: 'statusSyncTime',
        //     width: 150,
        //     align: 'center',
        //     onCell: tooltipStyle,
        //     render: tooltip
        // },
        {
            title: '发货时间',
            dataIndex: 'shipTime',
            key: 'shipTime',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '收货时间',
            dataIndex: 'receiptTime',
            key: 'receiptTime',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    return (
        <div className={styles.tableContainer}>
            <XTableSelectionCount
                selectedNum={keys.length}
                onClean={cleanAllSelected}
            />
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(record, index) => index}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={dataList}
                pagination={false}
                scroll={{ x: 2100, y: true }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};
TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    batchKeys: PropTypes.array
};
export default TableContent;