/**
 * 【仓库奢批结算价调整提示页】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XTabNav } from '@/components/xqxc_ui';
import NotUpdated from '../notUpdated';
import Updated from '../updated';
import AutoUpdate from '../autoUpdate';
class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: [
                { id: 1, label: '结算价未调整' },
                { id: 2, label: '结算价已调整' },
                { id: 3, label: '自动调整设置' },
            ],
            curTabID: 1,  // 当前显示的Tab页ID
        };
    }

    componentDidMount() {
        const { history } = this.props;
        if (history.location.state && history.location.state.tabID) {
            this.setState({ curTabID: history.location.state.tabID }); // 如果路由过来的state参数带tabID值，则渲染该tabID值下的内容
        }
    }

    // Tab页切换
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        switch (this.state.curTabID) {
            case 1:
                return <NotUpdated curTabID={this.state.curTabID} />;
            case 2:
                return <Updated curTabID={this.state.curTabID} />;
            default:
                return <AutoUpdate curTabID={this.state.curTabID} />;
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={styles.line}></div>
                <div className={`${styles.page} ${styles.flexBoxContainer}`}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导
};


export default connect(null, { addOneBreadcrumbPath, keepSecondNavBreadcrumb })(Main);