import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DatePicker } from 'antd';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { getContractAuditList, getContractStateSele, getSupplierSele } from '@/reducers/auditManage/contractAudit/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
class Main extends Component {
    state = {
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: [], // 当前需要打开的弹窗的入参
        contractName: '',
        contractNo: '',
        signDateStart: null,
        signDateEnd: null,
        contractState: {},
        supplier: {}
    }

    componentDidMount() {
        this.props.getContractStateSele();
        this.props.getSupplierSele();
    }

    //时间改变
    _dateChange(date, dateString, type) {
        switch (type) {
            case 'signDateStart':
                this.setState({ signDateStart: dateString });
                break;
            case 'signDateEnd':
                this.setState({ signDateEnd: dateString });
                break;
        }
    }
    // 查询
    _searchHandle = (useCache) => {
        this.setState({ isCheck: true });
        const { contractName, contractNo, signDateStart, signDateEnd, contractState, supplier, pageNum, pageSize } = this.state;
        this.searchCache = {
            contractName, contractNo, signDateStart, signDateEnd, contractState: contractState.code, supplierId: supplier.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getContractAuditList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 重置
    _resetHandle = () => {
        this.setState({ contractName: '', contractState: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    //新建合同
    _createContract = () => {
        this.props.updateContent('CREATE');
    }

    // 表格操作
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'audit':
                console.log(id, type, params);
                this.props.updateContent('AUDIT', id);
                break;
            case 'detail':
                console.log(id, type, params);
                this.props.updateContent('DETAIL', id);
                break;
        }
    }

    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        const { contractName, contractNo, signDateStart, signDateEnd, contractState, supplier } = this.state;
        this.props.getContractAuditList({ contractName, contractNo, signDateStart, signDateEnd, contractState: contractState.code, supplierId: supplier.id, pageNum, pageSize });
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { contractAuditList, supplierSele, contractStateSele } = this.props;
        const { contractName, contractNo, contractState, supplier } = this.state;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <XInput
                            inputStyle={{ width: '150px' }}
                            label='合同名称'
                            placeholder='请输入'
                            bindThis={this}
                            bindName='contractName'
                            value={contractName}
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '150px' }}
                            label='合同号'
                            placeholder='请输入'
                            bindThis={this}
                            bindName='contractNo'
                            value={contractNo}
                        />
                        <div className={styles.date} >
                            <span className={styles.dateLabel}>签订日期</span>
                            <DatePicker
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '130px' }}
                                locale={locale}
                                placeholder='选择开始日期'
                                onChange={(date, dateString) => this._dateChange(date, dateString, 'signDateStart')} />
                            <span style={{ padding: '0 10px' }}>—</span>
                            <DatePicker
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '130px' }}
                                placeholder='选择结束日期'
                                locale={locale}
                                onChange={(date, dateString) => this._dateChange(dateString, 'signDateEnd')} />
                        </div>
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '150px' }}
                            label='合同状态'
                            placeholder='请选择'
                            renderData={contractStateSele}
                            bindThis={this}
                            bindName='contractState'
                            dataIndex='value'
                            keyIndex='code'
                            value={contractState.value}
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '150px' }}
                            label='供应商'
                            placeholder='请选择'
                            renderData={supplierSele}
                            bindThis={this}
                            bindName='supplier'
                            dataIndex='optionName'
                            keyIndex='id'
                            value={supplier.optionName}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    {/* <div className={styles.operate_box}>
                        <XOKButton style={{ width: '90px' }} label='新建合同' onClick={this._createContract} />
                        <XOKButton style={{ width: '90px' }} label='导出Excel' />
                    </div> */}
                    <TableContent paginationChange={this._paginationChange} renderData={contractAuditList} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }

}

Main.propTypes = {
    updateContent: PropTypes.func,
    getContractAuditList: PropTypes.func,
    getContractStateSele: PropTypes.func,
    getSupplierSele: PropTypes.func,

    contractAuditList: PropTypes.object,
    contractStateSele: PropTypes.array,
    supplierSele: PropTypes.array
};

const mapStateToProps = (state) => ({
    contractAuditList: state.contractAudit.contractAuditList,
    contractStateSele: state.contractAudit.contractStateSele,
    supplierSele: state.contractAudit.supplierSele

});

export default connect(mapStateToProps, { getContractAuditList, getContractStateSele, getSupplierSele })(Main);