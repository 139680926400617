import * as SALE from './actionTypes';

//售后列表
export const getSaleList = (param) => ({
    type: SALE.GET_SALELIST_LIST,
    payload: param
});
//受理详情
export const getProcessDetail = (param) => ({
    type: SALE.GET_PROCESS_DETAIL,
    payload: param
});
//售后仅退款详情
export const getSaleOnlyMoneyDetail = (param) => ({
    type: SALE.GET_SALELIST_DETAIL_ONLYMONEY,
    payload: param
});
//售后退货退款详情
export const getSaleGoodsandmoneyDetail = (param) => ({
    type: SALE.GET_SALELIST_DETAIL_GOODSMONEY,
    payload: param
});
//售后换货详情
export const getSaleChangeGoodsDetail = (param) => ({
    type: SALE.GET_SALELIST_DETAIL_CHANGEGOODS,
    payload: param
});
// 售后状态
export const getSaleStatus = (param) => ({
    type: SALE.GET_SALESTATUS,
    payload: param
});
// 售后类型
export const getSaleType = (param) => ({
    type: SALE.GET_SALETYPE,
    payload: param
});
// 仓库售后地址
export const getSaleAddressList = (param) => ({
    type: SALE.GET_SALE_ADRESS_LIST,
    payload: param
});
// 仓库售后地址详情
export const getSaleAddressDetail = (param) => ({
    type: SALE.GET_SALE_ADRESS_DETAIL,
    payload: param
});
// 是否批发
export const getDistributionFlagEnum = () => ({
    type: SALE.GET_SALEPIFA_FLAGENUM,
    payload: {}
});