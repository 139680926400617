import * as T from './actiontypes';

/**
 * 获取一级、二级菜单列表
 */
export const getMenuList = (value = {}) => ({
    type: T.GET_MENU_LIST,
    payload: value
});

/**
 * 获取全部菜单列表
 */
export const getAllMenuList = (value = {}) => ({
    type: T.GET_MENU_ALL_LIST,
    payload: value
});

/**
 * 获取菜单详情
 */
export const getMenuDetail = (value = {}) => ({
    type: T.GET_MENU_DETAIL,
    payload: value
});

/**
 * 获取一级菜单下拉列表
 */
export const getMenuSelect = (value = {}) => ({
    type: T.GET_MENU_SELECT,
    payload: value
});


