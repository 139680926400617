/**
 * 结算明细
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, Button, message } from 'antd';
import model from '@/reducers/beanshopmanage/beanshop/model';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination, XDatePicker, showConfirm } from '@/components/xqxc_ui';
import { takeColumns } from './components/TableData';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';

class ValidateStatement extends Component {
	state = {
		startDate: null,
		endDate: null,
		feeTypeobj: {},
		orderId: '',
		id: '',
	}

	componentDidMount() {
		this.props.getFeeTypeEnum();
	}
	// 分页
	_paginationChange = (pageSize, pageNum) => {
		this.setState({ pageNum, pageSize }, () => {
			this._searchHandle('useCache');
		});
	}

	// 查询
	_searchHandle = (useCache) => {

		const { id, orderId, startDate, endDate, feeTypeobj, pageNum, pageSize } = this.state;

		this.searchCache = {
			id,
			orderId,
			feeType: feeTypeobj.code,
			startDate: startDate ? startDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
			endDate: endDate ? endDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
			pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
			pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
		};
		KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
		this.props.getStarValueSettle(this.searchCache);
	}

	// 重置触发 
	_ruleresetHandle = () => {
		this.setState({ id: '', orderId: undefined, feeTypeobj: {}, startDate: undefined, endDate: undefined, pageSize: 10, pageNum: 1 }, () => {
			KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
		});
	}


	exportExcel = (url, params) => {
		http(url, params, 'POST').then((response) => {
			if (response.status == 200) {
				window.location.href = response.result;
			}
		}).catch((e) => {
			message.error(e.message);
		});
	}

	render() {
		const { id, orderId, feeTypeobj, startDate, endDate } = this.state;
		const { pageNum, pageSize, resultTotal, dataList } = this.props.StarValueSettle;
		const { FeeTypeEnum } = this.props;

		// 排查一下不通过一直弹出 是否是被KeepAlive缓存
		return (
			<KeepAlive id='validatestatement' render={({ currentOpenMode, ...state }) => {
				if (!this.state.__aliveInit) {
					this.setState({ ...state, __aliveInit: true }, () => {
						this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
					});
				}
			}}>
				<div className={styles.flexBoxContainer}>
					<div className={styles.pro_commont}>
						<XInput
							style={{ width: '250px' }}
							inputStyle={{ width: '170px' }}
							label='交易订单号'
							placeholder='请输入交易订单号'
							value={id}
							bindThis={this}
							bindName='id'
						/>
						<XDatePicker
							style={{ marginLeft: '30px', marginRight: '15px' }}
							label='业务日期'
							value={startDate}
							bindThis={this}
							bindName='startDate'
						/>
						<span>—</span>
						<XDatePicker
							style={{ marginLeft: '5px' }}
							value={endDate}
							bindThis={this}
							bindName='endDate'
						/>
						<XSelect
							style={{ marginLeft: '30px', width: '266px' }}
							selectStyle={{ width: '200px' }}
							label='状态'
							placeholder='请选择状态'
							renderData={FeeTypeEnum}
							dataIndex='value'
							keyIndex='code'
							showSearch={true}
							value={feeTypeobj.value}
							bindThis={this}
							bindName='feeTypeobj'
						/>
						<XInput
							style={{ width: '250px', marginLeft: '30px', }}
							inputStyle={{ width: '170px' }}
							label='关联订单号'
							placeholder='请输入关联订单号'
							value={orderId}
							bindThis={this}
							bindName='orderId'
						/>
						<XOKButton
							style={{ marginLeft: '30px', width: '60px' }}
							label='查询'
							onClick={this._searchHandle}
						/>
						<XCancelButton
							style={{ marginLeft: '30px', width: '60px' }}
							label='重置'
							onClick={this._ruleresetHandle}
						/>
					</div>
					<div className={`${styles.table_body} ${styles.tableContainer}`}>
						<Table
							// bordered
							rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
							rowSelection={this._rowSelection}
							rowKey={(record, index) => record.id}
							columns={takeColumns()}
							dataSource={dataList}
							pagination={false}
							scroll={{ y: true }}
						/>
						<XPagination
							resultTotal={resultTotal}
							pageSize={pageSize}
							pageNum={pageNum}
							onChange={this._paginationChange}
						/>
					</div>
				</div>
			</KeepAlive>
		);
	}
}

ValidateStatement.propTypes = {
	updateContent: PropTypes.func, // 路由跳转
	history: PropTypes.object, // 路由history
	addOneBreadcrumbPath: PropTypes.func,
	keepSecondNavBreadcrumb: PropTypes.func,
	getStarValueSettle: PropTypes.func,
	StarValueSettle: PropTypes.object,
	getFeeTypeEnum: PropTypes.func,
	FeeTypeEnum: PropTypes.array,
};

const mapStateToProps = (state) => ({
	StarValueSettle: state.beanshop.StarValueSettle,
	FeeTypeEnum: state.beanshop.FeeTypeEnum,
});


export default connect(mapStateToProps, {
	addOneBreadcrumbPath,
	keepSecondNavBreadcrumb,
	...model.actions,
})(ValidateStatement);