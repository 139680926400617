import { createModel } from '@/store/tools';

const model = {
    namespace: 'authorization',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getAuthorizationInfo': T('getAuthorizationInfo'),
        },
        actions: {
            'getAuthorizationInfo': A('getAuthorizationInfo'),
        },
        sagas: {
            'getAuthorizationInfo': S('getAuthorizationInfo', '/merchant/cainiao/authorization'),
        },
        reducers: {
            'authorizationInfo': R('getAuthorizationInfo', {}),
        }
    })
};
export default createModel(model);