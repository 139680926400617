import { combineReducers } from 'redux';
import {
	productListData, productStatus, productUsername, productBusinessType, productLabelSele,
	productClassifyOne, productClassifyTwo, productClassifyThree,
	productDetail, productAuditLog, productFreeSele,productWareHouse
} from './reducer';
import model from './model';

export default combineReducers({
	productListData,
	productStatus,
	productUsername,
	productBusinessType,
	productLabelSele,
	productDetail,
	productAuditLog,
	productClassifyOne,
	productClassifyTwo,
	productClassifyThree,
	productFreeSele,
	productWareHouse,
	...model.reducers
});