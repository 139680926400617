/**
 * 仓库管理saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as S from './actiontypes';
import http from '@/assets/api/http';

// 获取仓库列表
export const getStoreHouseList = function* () {
    yield takeEvery(S.GET_STOREHOUSE_LIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/warehouse/list', action.payload, 'POST');
            yield put({ type: S.SET_STOREHOUSE_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 获取仓库类型
export const getStoreHouseType = function* () {
    yield takeEvery(S.GET_STOREHOUSE_TYPE, function* requestData(action) {
        try {
            let result = yield http('/enum/MerchantWarehouseTypeEnum', action.payload, 'POST');
            yield put({ type: S.SET_STOREHOUSE_TYPE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};
// 获取仓库详情
export const getStoreHouseDetail = function* () {
    yield takeEvery(S.GET_STOREHOUSE_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/merchant/warehouse/detail', action.payload, 'POST');
            yield put({ type: S.SET_STOREHOUSE_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

