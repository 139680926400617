import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { Mode } from '../_utils';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';


class TableContent extends Component {
	state = {
		selectedRowKeys: [],
	};

	_columns = () => [
		{
			title: '发布时间',
			dataIndex: 'gmtCreate',
			key: 'gmtCreate',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '会员账号',
			dataIndex: 'userPhone',
			key: 'userPhone',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '预算价格/晚/间',
			dataIndex: 'budgetPrice',
			key: 'budgetPrice',
			align: 'center',
			width: '10%',
			render: priceFenToYuanCell
		},
		{
			title: '入住天数',
			dataIndex: 'days',
			key: 'days',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '房间数',
			dataIndex: 'rooms',
			key: 'rooms',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '房型',
			dataIndex: 'houseType',
			key: 'houseType',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '入住时间',
			dataIndex: 'inTime',
			key: 'inTime',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '离店时间',
			dataIndex: 'outTime',
			key: 'outTime',
			align: 'center',
			width: '10%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'action',
			key: 'action',
			align: 'center',
			width: '20%',
			render: (text, record) => this._tableAction(record.id, record)
		},
	];

	// 表格操作
	_tableAction = (key, record) => {
		const { tableAction } = this.props;
		const canReceive = record.status === 'WAIT_CONFIRM';
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => tableAction(key, Mode.Detail, record)}>详情</div>
				{canReceive && <div className={styles.item} onClick={() => tableAction(key, Mode.Receive, record)}>接单</div>}
			</div>
		);
	};

	render() {
		const { renderData, paginationChange } = this.props;
		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowKey={record => record.id}
					columns={this._columns()}
					pagination={false}
					dataSource={renderData.dataList}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}


TableContent.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
};

export default TableContent;