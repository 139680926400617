/**
 * 【财务设置创建页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, Form, Button, Row, Col, Select, Icon, Radio, DatePicker, message, TimePicker } from 'antd';
import { XCancelButton } from '@/components/xqxc_ui';
import HotelSetting from './components/hotelSetting';
import CustomUpload, { generateFileList } from '@/components/NewUpload';
import { RangeNumber } from './components/rangeNumber';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import moment from 'moment';
import CutVideoToImg from '@/components/CutVideoToImg';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};


class addFinancialContent extends React.Component {
    state = {
        isNew: true,
        id: '',
        shopName: '',
        hotelStar: '',
        shopAddr: '',
        shopDetailAddr: '',
        openBank: '',
        bankAccount: '',
        legalName: '',
        hotelFacilities: '',
        checkInTime: undefined,
        leaveTime: undefined,
        isPets: '',
        breakfastPolicy: '',
        receivePhone: '',

        hotelTypes: [],
        hotelLevelList: [],
        hotelFacilityList: [],

        videoSrc: undefined,
        // fileTarget: undefined,
        // videoPreviews: [],
    }

    componentDidMount() {

        // 酒店星级下拉
        http('/hotel/hotelStarLevel/hotelLevelItem').then((res = {}) => {
            if (res.code === 200) {
                this.setState({ hotelLevelList: res.result || [] });
            }
        }).catch((res = {}) => message.error(res.message || '获取详情异常'));

        // 酒店类型下拉
        http('/enum/HotelShopTypeEnum').then(res => {
            if (res.code === 200) {
                this.setState({ hotelTypes: res.result || [] });
            }
        }).catch((res = {}) => message.error(res.message || '获取详情异常'));

        // 酒店设施
        http('/hotel/installation/hotelFacilityItem').then((res = {}) => {
            if (res.code === 200) {
                this.setState({ hotelFacilityList: res.result || [] });
            }
        }).catch((res = {}) => message.error(res.message || '获取详情异常'));

        // 酒店详细
        http('/hotel/findHotelInfo').then((res = {}) => {
            if (res.code === 200) {
                let {
                    shopName,
                    provinceName,
                    cityName,
                    areaName,
                    addrDetail,
                    hotelMin,
                    hotelMax,
                    hotelAttachedInfo = [],
                    hotelAttachedInfoVideo = [],
                    checkInTime,
                    leaveTime,
                    hotelStar,
                    starId,
                    hotelFacilities = '',
                    shopId,
                    ...formFields
                } = res.result || {};

                this.setState({
                    shopId,
                    shopName,
                    shopAddr: `${provinceName} - ${cityName} - ${areaName}`,
                    shopDetailAddr: addrDetail,
                });

                let mainImg = generateFileList(hotelAttachedInfo.map(v => v.value).join(','));

                let mainVideo = generateFileList(hotelAttachedInfoVideo.map(v => v.cover).join(','));
                mainVideo.length && hotelAttachedInfoVideo.forEach((v, i) => {
                    mainVideo[i].video = v.value;
                    mainVideo[i].cover = v.cover;
                });

                this.props.form.setFieldsValue({
                    ...formFields,
                    hotelRange: [hotelMin, hotelMax],
                    checkInTime: checkInTime && moment(checkInTime, 'HH:mm'),
                    leaveTime: leaveTime && moment(leaveTime, 'HH:mm'),
                    mainImg,
                    mainVideo,
                    hotelStar: { key: shopId, label: hotelStar },
                    hotelFacilities: hotelFacilities.split(',').map(id => Number(id))
                });
            }
        }).catch((res = {}) => message.error(res.message || '获取详情异常'));
    }

    getFileList = (files, type) => {
        return files.map((img, i) => ({
            code: img.code,
            value: img.response ? img.response.key : type === 'VIDEO' ? img.video : img.url,
            name: img.name,
            type,
            sorting: i,
            cover: img.cover
        }));
    }

    // 提交
    _handleSubmit = e => {
        this.props.form.validateFieldsAndScroll((err, { hotelRange = [], mainImg = [], mainVideo = [], hotelStar, hotelFacilities = [], checkInTime, leaveTime, ...values }) => {

            if (!err) {
                http('/hotel/editHotelInfo', {
                    shopName: this.state.shopName,
                    shopId: this.state.shopId,
                    hotelMin: hotelRange[0],
                    hotelMax: hotelRange[1],
                    hotelAttachedInfo: this.getFileList(mainImg, 'IMG'),
                    hotelAttachedInfoVideo: this.getFileList(mainVideo, 'VIDEO'),
                    checkInTime: checkInTime ? checkInTime.format('HH:mm') : undefined,
                    leaveTime: leaveTime ? leaveTime.format('HH:mm') : undefined,
                    hotelStar: hotelStar.label,
                    starId: hotelStar.key,
                    hotelFacilities: hotelFacilities.join(','),
                    ...values
                }).then((res = {}) => {
                    if (res.code === 200) {
                        message.success('保存设置成功');
                    }
                }).catch((res = {}) => message.error(res.message || '保存设置异常'));
            }
        });
    }

    // 重置
    _resetHandle = () => {
        this.props.form.resetFields();
    }

    render() {
        const { shopName, shopAddr, shopDetailAddr, hotelLevelList, hotelFacilityList, hotelTypes } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <div style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }} >
                <Form {...formItemLayout} className='create-form'>
                    <div className={styles.title}>酒店设置</div>
                    <div className={styles.line}></div>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                colon={false}
                                htmlFor='shopName'
                                label={<span>酒店名称</span>}
                            >
                                {shopName}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                colon={false}
                                htmlFor='hotelStar'
                                label={<span>酒店星级</span>}
                            >
                                {getFieldDecorator('hotelStar', {
                                    rules: [{ required: true, message: '请选择酒店星级' }]
                                })(
                                    <Select labelInValue allowClear placeholder='请选择酒店星级'>
                                        {hotelLevelList.map(item => <Select.Option key={item.hotelLevelId} value={item.hotelLevelId}>{item.name}</Select.Option>)}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                htmlFor='shopAddr'
                                label={<span>地址</span>}
                            >
                                {shopAddr}
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                htmlFor='shopDetailAddr'
                                label={<span>详细地址</span>}
                            >
                                {shopDetailAddr}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                colon={false}
                                htmlFor='hotelType'
                                label={<span>酒店类型</span>}
                            >
                                {getFieldDecorator('hotelType', {
                                    rules: [{ required: true, message: '请输入酒店名称' }],
                                })(
                                    <Select allowClear placeholder='请选择酒店类型'>
                                        {hotelTypes.map(item => <Select.Option key={item.code} value={item.code}>{item.value}</Select.Option>)}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                htmlFor='hotelRange'
                                label={<span>客房售价区间</span>}
                            >
                                {getFieldDecorator('hotelRange', {
                                    rules: [{ required: true, message: '请输入售价价格区间' }],
                                })(<RangeNumber />)}
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                htmlFor='contactTel'
                                label={<span>前台电话</span>}
                            >
                                {getFieldDecorator('contactTel', {
                                    rules: [{ required: true, message: '请输入前台电话' }],
                                })(<Input placeholder='请输入前台电话' />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item label='酒店图片' labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                                {getFieldDecorator('mainImg', {
                                    valuePropName: 'fileList',
                                    trigger: 'onFileListChange',
                                    getValueFromEvent: ({ fileList = [] }) => {
                                        if (fileList.length > 9) {
                                            message.warning('最多上传9张');
                                            return fileList.slice(0, 9);
                                        }
                                        return fileList;
                                    },
                                    rules: [{ required: true, message: '请上传酒店图片' }],
                                })(
                                    <CustomUpload
                                        startDragSort
                                        accept='.png, .jpg, .jpeg'
                                        listType='picture-card'
                                        qiniuUploadProps={{ uploadType: 'image' }}
                                        beforeUpload={(file) => {
                                            const isLt5M = file.size / 1024 / 1024 < 3;
                                            if (!isLt5M) {
                                                message.warn('图片必须小于 3MB!');
                                                file.status = 'error';
                                                return false;
                                            } else {
                                                return isLt5M;
                                            }
                                        }}
                                        onDragSortChange={(fileList) => {
                                            this.props.form.setFieldsValue({ mainImg: fileList });
                                        }}
                                    >
                                        <Icon type='plus' style={{ fontSize: 32, color: '#999' }} />
                                    </CustomUpload>
                                )}
                                <div className={styles.mainImgText}>主图</div>
                                <div>请上传 JPG、JPEG、PNG 单张大小不能超过3M，建议尺寸800*800，最多上传9张</div>
                            </Form.Item>
                            <Form.Item label='酒店视频' labelCol={{ span: 2 }}>
                                {getFieldDecorator('mainVideo', {
                                    valuePropName: 'fileList',
                                    trigger: 'onFileListChange',
                                    getValueFromEvent: ({ fileList = [] }) => {
                                        if (fileList.length > 20) {
                                            message.warning('最多上传20个');
                                            return fileList.slice(0, 20);
                                        }
                                        return fileList;
                                    },
                                })(
                                    <CustomUpload
                                        accept='.mp4'
                                        removeError
                                        listType='picture-card'
                                        startPreview
                                        previewType='video'
                                        previewField='video'
                                        qiniuUploadProps={{ uploadType: 'image' }}
                                        beforeUpload={(file) => {
                                            const isLt5M = file.size / 1024 / 1024 < 100;
                                            if (!isLt5M) {
                                                message.warn('视频必须小于 10MB!');
                                                file.status = 'error';
                                                return false;
                                            } else {
                                                return isLt5M;
                                            }
                                        }}
                                        onFileListChange={({ fileList }) => {
                                            if (fileList.length) {
                                                let file = fileList[fileList.length - 1];
                                                if (file.status === 'success') {
                                                    this.setState({ videoSrc: file.url, fileTarget: file });
                                                }
                                            }
                                        }}
                                    >
                                        上传视频
                                    </CustomUpload>
                                )}
                                <div>建议上传1:1或6:9比例视频，大小不能超过10M（支持 mp4 格式）</div>
                                <CutVideoToImg
                                    value={this.state.videoSrc}
                                    onChange={(key, url) => {
                                        let { fileTarget } = this.state;
                                        if (fileTarget) {
                                            fileTarget.cover = key;
                                            fileTarget.video = fileTarget.url;
                                            fileTarget.url = url;
                                            this.forceUpdate();
                                        }
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                htmlFor='shopDesc'
                                label={<span>酒店介绍</span>}
                                labelCol={{ span: 2 }}
                            >
                                {getFieldDecorator('shopDesc')(<Input.TextArea placeholder='请输入酒店介绍' rows={5} />)}
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className={styles.title}>酒店设施</div>
                    <div className={styles.line}></div>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                colon={false}
                                htmlFor='hotelFacilities'
                                label={<span>酒店设施</span>}
                            >
                                {getFieldDecorator('hotelFacilities', {
                                    rules: [{ required: true, message: '请选择酒店设施' }]
                                })(<HotelSetting dataSource={hotelFacilityList} />)}
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className={styles.title}>入住须知</div>
                    <div className={styles.line}></div>

                    <Row><Col span={12}>
                        <Form.Item
                            colon={false}
                            htmlFor='checkInTime'
                            label={<span>入住时间</span>}
                        >
                            {getFieldDecorator('checkInTime', {
                                rules: [{ required: true, message: '请选择入住时间' }]
                            })(<TimePicker format='HH:mm' style={{ width: 400 }} />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='leaveTime'
                            label={<span>离开时间</span>}
                        >
                            {getFieldDecorator('leaveTime', {
                                rules: [{ required: true, message: '请选择离开时间' }]
                            })(<TimePicker format='HH:mm' style={{ width: 400 }} />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='isPets'
                            label={<span>携带宠物</span>}
                        >
                            {getFieldDecorator('isPets')(
                                <Radio.Group style={{ marginLeft: 5 }}>
                                    <Radio value={1}>是</Radio>
                                    <Radio value={0}>否</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='breakfastPolicy'
                            label={<span>早餐政策</span>}
                        >
                            {getFieldDecorator('breakfastPolicy')(<Input placeholder='请输入' style={{ width: 400 }} />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='hotelTips'
                            label={<span>酒店提示</span>}
                        >
                            {getFieldDecorator('hotelTips')(<Input.TextArea rows={5} placeholder='请输入酒店提示' style={{ width: 400 }} />)}
                        </Form.Item>
                        <div style={{ display: 'flex' }}>
                            <Button style={{ marginLeft: '24%', marginRight: '20px', width: '120px', display: 'inline-block' }} type='primary' onClick={this._handleSubmit}>保存</Button>
                            <XCancelButton style={{ width: '120px', display: 'inline-block' }} label='重置' onClick={this._resetHandle} />
                        </div>
                    </Col>
                    </Row>
                </Form >
            </div >
        );
    }
}
addFinancialContent.propTypes = {
    history: PropTypes.object, // 路由history对象
    form: PropTypes.object.isRequired, // 校验
    match: PropTypes.object, // 路由match对象
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(addFinancialContent);// 校验

export default connect(null, null)(ForgotProducDetails);