/**
 * [标准商品库]
 * @author: Fu Xiaochun
 * @date: 2021-06-03 
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import { message, Modal } from 'antd';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import TableData from './components/TableData';
import { getStandardProduct } from '@/reducers/businessmanage/standardproduct/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { SearchBox, SearchItem } from '@/components/Layout';
import { NewGoodsCategory } from '@/vcomps';
import { addOneBreadcrumbPath } from '@/reducers/home/actions';

class StandardProductList extends Component {
    constructor(props){
        super(props);
        this.state = {
            productName: '',
            brandName: '',
            categoryId1: '',
            categoryId2: '',
            categoryId3: '',
            syncStatus: {},
            productIds: [],
        };
        this.syncStatusOptions = [
            {text: '未同步', value: 0},
            {text: '已同步', value: 1}
        ];
        this.newGoodsCategoryRef = React.createRef();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { productName, brandName, categoryId1, categoryId2, categoryId3, syncStatus, pageNum, pageSize } = this.state;
        this.searchCache = {
            productName,
            brandName,
            categoryId1,
            categoryId2,
            categoryId3,
            isSync: syncStatus.value,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getStandardProduct(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize });
        this.setState({
            productIds: []
        });
    }

    // 重置
    _resetHandle = () => {
        this.newGoodsCategoryRef.current.clean();
        this.setState({ productName: '', brandName: '', categoryId1: '', categoryId2: '', categoryId3: '', syncStatus: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
        this.setState({
            productIds: []
        });
    }

    // 表格操作  选择，状态开关，详情，编辑
    _tableAction = (id, type, params) => {
        switch (type) {
            case 'select':
                this.setState({
                    productIds: [...params]
                });
                break;
            case 'detail':
                
                // 增加面包屑导航
                this.props.addOneBreadcrumbPath({
                    title: '商品详情',
                    path: '/home/businessmanage/standardproduct/detail/'
                });
                this.props.history.push({
                    pathname: '/home/businessmanage/standardproduct/detail/' + id
                });
                
                break;
        }
    }
    
    // 分页 '每页'+pageSize+'条，当前第'+pageNum+'页'
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    // 批量同步
    _batchSync = ()=>{
        let ids = this.state.productIds;
        if(ids.length == 0){
            Modal.error({
                title: '你还未选择要同步的商品',
                content: '请先选择要同步的商品，再执行批量同步'
            });
            return false;
        }
        showConfirm('这是进行一项操作时必须了解的重要信息', '确定要同步商品库，你还要继续吗？', () => {
            http('/goods/baseProduct/batchAddBaseGoods2Plat', { productIds: ids }, 'POST')
                .then(() => {
                    message.success('操作成功');
                    this.setState({
                        productIds: []
                    }, ()=>{
                        this._searchHandle('useCache');
                    });
                }).catch((reject) => {
                    message.error(reject.message);
                });
        });
    };

    _onClassifyChange = (cid, level)=>{
        switch (level) {
            case 'first':
                this.setState({
                    categoryId1: cid
                });
                break;
            case 'second':
                this.setState({
                    categoryId2: cid
                });
                break;
            case 'third':
                this.setState({
                    categoryId3: cid
                });
                break;
            default: 
                // nothing to do
        }
    }

    render() {
        const { productName, brandName, syncStatus, productIds } = this.state;
        const { standardProduct } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                style={{width: '200px', marginRight: '20px'}}
                                label='商品名称'
                                placeholder='请输入商品名称'
                                bindThis={this}
                                bindName='productName'
                                value={productName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <NewGoodsCategory
                                style={{ marginRight: '20px' }}
                                label='平台分类'
                                ref={this.newGoodsCategoryRef}
                                onClassifyChange={this._onClassifyChange}
                                />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='品牌名称'
                                placeholder='请输入品牌名称'
                                bindThis={this}
                                bindName='brandName'
                                value={brandName}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                style={{ marginLeft: '20px' }}
                                selectStyle={{ width: '100px' }}
                                label='状态'
                                placeholder='请选择'
                                renderData={this.syncStatusOptions}
                                bindThis={this}
                                bindName='syncStatus'
                                dataIndex='text'
                                keyIndex='value'
                                isOptionBindID={true}
                                value={syncStatus.value}
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton
                                style={{marginLeft: '20px', marginRight: '20px'}}
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </SearchItem>
                    </SearchBox>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '160px', marginRight: '30px' }} label='批量同步到同城商品' onClick={this._batchSync}/>
                    </div>
                    <TableData selectedRowKeys={productIds} paginationChange={this._paginationChange} renderData={standardProduct} tableAction={this._tableAction} />
                </div>
            </KeepAlive>
        );
    }
}

StandardProductList.propTypes = {
    getStandardProduct: PropTypes.func,
    standardProduct: PropTypes.object,
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
};

const mapStateToProps = (state) => ({
    standardProduct: state.standardProduct.standardProduct
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, getStandardProduct })(StandardProductList);