/**
 * 【供应商出库单】
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XDatePicker, XSelect, XOKButton, XCancelButton, XInputNum, XInput } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import model from '@/reducers/supplierwarehouse/deliveryorder/model';
import http from '@/assets/api/http';
import { message } from 'antd';

class OutDeliveryOrder extends Component {

    static propTypes = {
        history: PropTypes.object, // history router
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getSupplierDeliveryOrderData: PropTypes.func,
        tableData: PropTypes.object,
        getSupplierDeliveryOrderEnum: PropTypes.func,
        supplierDeliveryOrderEnum: PropTypes.array
    }

    static defaultProps = {
        tableData: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
        supplierDeliveryOrderEnum: []
    }

    state = {
        stockId: null,
        orderId: null,
        outOrderId: null,
        shipmentNumber: null,
        consigneePhone: null,
        orderGmtCreateStart: null,
        orderGmtCreateEnd: null,
        shipDateStart: null,
        shipDateEnd: null,
        receiptDateStart: null,
        receiptDateEnd: null,
        stateItem: {},
        batchKeys: []
    };

    componentDidMount() {
        this.props.getSupplierDeliveryOrderEnum();
    }

    // 查询
    _searchHandle = (useCache) => {
        const {
            stockId,
            orderId,
            outOrderId,
            shipmentNumber,
            consigneePhone,
            stateItem,
            orderGmtCreateStart,
            orderGmtCreateEnd,
            shipDateStart,
            shipDateEnd,
            receiptDateStart,
            receiptDateEnd,
            pageNum,
            pageSize
        } = this.state;
        this.searchCache = {
            stockId,
            orderId,
            outOrderId,
            shipmentNumber,
            consigneePhone,
            orderGmtCreateStart,
            orderGmtCreateEnd,
            shipDateStart,
            shipDateEnd,
            receiptDateStart,
            receiptDateEnd,
            status: stateItem.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getSupplierDeliveryOrderData(this.searchCache);
        KeepAlive.saveCache(this.searchCache); // 【第3步，缓存查询状态】
    }

    // 重置触发 
    _ruleresetHandle = () => {
        this.setState({
            stockId: null,
            orderId: null,
            outOrderId: null,
            shipmentNumber: null,
            consigneePhone: null,
            orderGmtCreateStart: null,
            orderGmtCreateEnd: null,
            shipDateStart: null,
            shipDateEnd: null,
            receiptDateStart: null,
            receiptDateEnd: null,
            stateItem: {}
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: '/home/supplierwarehouse/outdeliveryorder/detail/'
                });
                history.push({
                    pathname: '/home/supplierwarehouse/outdeliveryorder/detail/' + id
                });
                break;
            case 'resubmit':
                http(`/third/salestock/reRequest/${id}`, {}, 'POST')
                    .then(res => {
                        message.success(res.message);
                        this._searchHandle('useCache');
                    })
                    .catch(err => message.error(err.message));
                break;
            case 'batch':
                this.setState({ batchKeys: value });
                break;
        }
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }


    render() {
        const {
            stockId,
            orderId,
            outOrderId,
            shipmentNumber,
            consigneePhone,
            orderGmtCreateStart,
            orderGmtCreateEnd,
            shipDateStart,
            shipDateEnd,
            receiptDateStart,
            receiptDateEnd,
            stateItem,
            batchKeys
        } = this.state;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XInputNum
                            style={{ marginRight: 20, marginBottom: 10 }}
                            inputStyle={{ width: 150 }}
                            precision={0}
                            label='出库单号'
                            placeholder='请输入出库单号'
                            value={stockId}
                            bindThis={this}
                            bindName='stockId'
                        />
                        <XInputNum
                            style={{ marginRight: 20, marginBottom: 10 }}
                            inputStyle={{ width: 150 }}
                            precision={0}
                            label='订单号'
                            placeholder='请输入订单号'
                            value={orderId}
                            bindThis={this}
                            bindName='orderId'
                        />
                        <XInput
                            style={{ marginRight: 20, marginBottom: 10 }}
                            inputStyle={{ width: 150 }}
                            label='供应商订单号'
                            placeholder='请输入供应商订单号'
                            value={outOrderId}
                            bindThis={this}
                            bindName='outOrderId'
                        />
                        <XSelect
                            style={{ marginRight: 20, marginBottom: 10 }}
                            selectStyle={{ width: 150 }}
                            label='状态'
                            placeholder='请选择状态'
                            allowClear
                            renderData={this.props.supplierDeliveryOrderEnum}
                            dataIndex='value'
                            keyIndex='code'
                            value={stateItem.code}
                            showSearch={true}
                            bindThis={this}
                            bindName='stateItem'
                            isOptionBindID={true}
                        />
                        <div className={styles.dateStyle}>
                            <XDatePicker
                                label='创建订单日期'
                                bindThis={this}
                                bindName='orderGmtCreateStart'
                                isFormat={true}
                                value={orderGmtCreateStart}
                            />
                            <XDatePicker
                                bindThis={this}
                                bindName='orderGmtCreateEnd'
                                isFormat={true}
                                value={orderGmtCreateEnd}
                            />
                        </div>
                        <div className={styles.dateStyle}>
                            <XDatePicker
                                label='发货日期'
                                bindThis={this}
                                bindName='shipDateStart'
                                isFormat={true}
                                value={shipDateStart}
                            />
                            <XDatePicker
                                bindThis={this}
                                bindName='shipDateEnd'
                                isFormat={true}
                                value={shipDateEnd}
                            />
                        </div>
                        <div className={styles.dateStyle}>
                            <XDatePicker
                                label='收货日期'
                                bindThis={this}
                                bindName='receiptDateStart'
                                isFormat={true}
                                value={receiptDateStart}
                            />
                            <XDatePicker
                                bindThis={this}
                                bindName='receiptDateEnd'
                                isFormat={true}
                                value={receiptDateEnd}
                            />
                        </div>
                        <XInput
                            style={{ marginRight: 20, marginBottom: 10 }}
                            inputStyle={{ width: 150 }}
                            label='收货人电话'
                            placeholder='请输入收货人电话'
                            value={consigneePhone}
                            bindThis={this}
                            bindName='consigneePhone'
                        />
                        <XInputNum
                            style={{ marginRight: 20, marginBottom: 10 }}
                            inputStyle={{ width: 150 }}
                            precision={0}
                            label='物流单号'
                            placeholder='请输入物流单号'
                            value={shipmentNumber}
                            bindThis={this}
                            bindName='shipmentNumber'
                        />
                        <div className={styles.dateStyle}>
                            <XOKButton
                                style={{ marginRight: 20 }}
                                label='查询'
                                onClick={this._searchHandle} />
                            <XCancelButton
                                label='重置'
                                onClick={this._ruleresetHandle} />
                        </div>
                    </div>
                    {/* <XOKButton style={{ width: '120px' }} label='+查订单信息' onClick={() => { console.log('哈哈'); }} /> */}
                    <TableContent
                        renderData={this.props.tableData}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                        batchKeys={batchKeys}
                    />
                </div>
            </KeepAlive>
        );
    }
}
export default connect((state) => ({
    tableData: state.supplierDeliveryOrder.supplierDeliveryOrderData,
    supplierDeliveryOrderEnum: state.supplierDeliveryOrder.supplierDeliveryOrderEnum,
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(OutDeliveryOrder);