/**
 * 银联注册弹框
 */
import React from 'react';
import styles from './index.module.scss';
import { Modal, Steps, Spin, Alert } from 'antd';
import PropTypes from 'prop-types';
import getUnionInfo from './components/getUnionInfo';
import { Step1, Step2, Step3, Step4 } from './step';

const { Step } = Steps;
class UnionPay extends React.Component {

    state = {
        show: false,
        visible: this.props.visible,
        confirmLoading: false,
        unitType: '', // 类型：PERSON个体户、BUSINESS企业
        curStep: 1, // 当前指引步骤数
    }

    componentDidMount() {
        getUnionInfo().then((response) => {
            const { type, data } = response;
            // commitInfoStatus是否提交基本信息，companyAccountVerifyStatus是否对公打款，signStatus是否签署协议，auditStatus是否审核通过
            if (type == 'PERSON') {
                const { commitInfoStatus, signStatus, auditStatus, status } = data;
                if (commitInfoStatus != 1 || status == 'FAILD') {
                    // 未提交基本信息
                    this.setState({ unitType: 'PERSON', curStep: 1 });
                } else if (commitInfoStatus == 1 && signStatus != 1) {
                    // 未签署协议
                    this.setState({ unitType: 'PERSON', curStep: 2 });
                } else if (signStatus == 1 && auditStatus != 1) {
                    // 审核中
                    this.setState({ unitType: 'PERSON', curStep: 3 });
                } else if (auditStatus == 1) {
                    // 审核通过，进入通联注册
                    this.props.closeMode();
                }
            } else {
                const { commitInfoStatus, companyAccountVerifyStatus, signStatus, auditStatus } = data;
                // const status = 'FAILD'; // --------------------模拟审核失败 --------------------
                if (commitInfoStatus != 1 || status == 'FAILD') {
                    // 未提交基本信息
                    this.setState({ unitType: 'BUSINESS', curStep: 1 });
                } else if (commitInfoStatus == 1 && companyAccountVerifyStatus != 1) {
                    // 未打款验证
                    this.setState({ unitType: 'BUSINESS', curStep: 2 });
                } else if (companyAccountVerifyStatus == 1 && signStatus != 1) {
                    // 未签署协议
                    this.setState({ unitType: 'BUSINESS', curStep: 3 });
                } else if (signStatus == 1 && auditStatus != 1) {
                    // 未审核通过
                    this.setState({ unitType: 'BUSINESS', curStep: 4 });
                } else if (auditStatus == 1) {
                    // 已经签署协议，进入通联注册
                    this.props.closeMode();
                }
            }
            this.setState({ show: true });
        });
    }

    _handleCancel = () => {
        this.props.closeMode(); // 进入通联注册
    }

    _renderStepContent = () => {
        const { curStep, unitType } = this.state;
        if (unitType == 'PERSON') {
            switch (curStep) {
                case 1:
                    return <Step1 next={() => { this.setState({ curStep: 2 }); }} onChange={(utype) => this.setState({ unitType: utype })} />;
                case 2:
                    return <Step3 previous={() => { this.setState({ curStep: 1 }); }} next={() => { this.setState({ curStep: 3 }); }} />;
                case 3:
                    return <Step4 previous={() => { this.setState({ curStep: 2 }); }} next={() => { this.props.closeMode(); }} />;
            }
        } else {
            switch (curStep) {
                case 1:
                    return <Step1 next={() => { this.setState({ curStep: 2 }); }} onChange={(utype) => this.setState({ unitType: utype })} />;
                case 2:
                    return <Step2 previous={() => { this.setState({ curStep: 1 }); }} next={() => { this.setState({ curStep: 3 }); }} />;
                case 3:
                    return <Step3 previous={() => { this.setState({ curStep: 2 }); }} next={() => { this.setState({ curStep: 4 }); }} />;
                case 4:
                    return <Step4 previous={() => { this.setState({ curStep: 3 }); }} next={() => { this.props.closeMode(); }} />;
            }
        }
    }

    render() {
        const { show, visible, unitType, curStep } = this.state;
        return (
            <Modal
                width={1000}
                centered
                title='注册财务账号（流程一）'
                visible={visible}
                destroyOnClose={true}
                keyboard={false}
                maskClosable={false}
                closable={this.props.showUnionClose}
                footer={null}
                onCancel={this._handleCancel}
            >
                {show ? <>
                    <Steps current={(curStep - 1)}>
                        <Step title="填写基础信息" />
                        {unitType == 'PERSON' ? null : <Step title="打款验证" />}
                        <Step title="签订电子协议" />
                        <Step title="审核" />
                    </Steps>
                    <div className={styles.stepContent}>{this._renderStepContent()}</div>
                </> : <Spin tip="加载财务配置信息..."><div className={styles.spinContent} /></Spin>}
            </Modal>
        );
    }
}

UnionPay.defaultProps = {
    showUnionClose: false
};

UnionPay.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框回调事件
    showUnionClose: PropTypes.bool
};

export default UnionPay;

