/**
 * 服务清单管理-服务清单列表
 */
import React from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message, Progress } from 'antd';
import { XSelect, XOKButton, XCancelButton, XInput, XExpandOrContract, XUploadExcel } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { GoodsCategory } from '@/vcomps';
import PDF from '../../modal/PDF';
import Fail from '../../modal/Fail';
import * as actions from '@/reducers/productmanage/rate/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            goodsName: '',
            goodsNo: '',
            status: {},
            label: {},
            username: {},
            professionType: {},
            tagIds: [],
            prodCascade: '',
            expandBoolean: false, // 是否展开
            goodsIds: [],
            percent: 0,
            progressStatus: 'normal',
            renderMode: '',
            modeParams: null,
            loading: false
        };
        this.goodsCategory = React.createRef();
    }

    componentDidMount() {
        this.props.getProductRateStatus();
        this.props.getProductRateBusinessType();
        this.props.getProductRateLabelSele();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { goodsName, goodsNo, professionType, prodCascade, pageNum, pageSize, status, label } = this.state;
        const tagIds = label.id ? [label.id] : [];
        this.searchCache = {
            goodsName, goodsNo, goodsState: status.code, goodsType: professionType.code, categoryId: prodCascade, tagIds,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getProductRateListData(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ goodsName: '', goodsNo: '', professionType: {}, status: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        this.goodsCategory.current.clean();
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        switch (type) {
            case 'detail':
                this.props.updateContent('detail', id);
                break;
            case 'batch':
                this.setState({
                    goodsIds: value
                });
                break;
        }
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        const { goodsName, goodsNo, professionType, prodCascade, status, tagIds } = this.state;
        this.props.getProductRateListData({ goodsName, goodsNo, goodsState: status.code, goodsType: professionType.code, categoryId: prodCascade, tagIds, pageNum, pageSize });
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 更多搜索条件的展开与收缩
    _expandChangeHandle = (expandBoolean) => {
        this.setState({ expandBoolean });
    }

    //导出当前费率
    _exportOldRate = () => {
        const { goodsName, goodsNo, professionType, prodCascade, pageNum, pageSize, status, label, goodsIds } = this.state;
        const tagIds = label.id ? [label.id] : [];
        const data = {
            goodsName, goodsNo,
            goodsState: status.code,
            goodsType: professionType.code,
            categoryId: prodCascade, goodsIds,
            tagIds, pageNum, pageSize
        };
        http('/goods/goodsrate/findRateGoodsListExport', data, 'POST').then((response) => {
            if (response.status == 200) {
                window.location.href = response.result;
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    //计时器
    _countTime = (data) => {
        const _this = this;
        this.interval = setInterval(() => {
            http('/goods/goodsrate/findGoodsRateAndWaitAuditState', { goodsRateId: data.result.goodsRateId }, 'POST').then((response) => {
                if (response.result.contractState == 'EDIT' || response.result.contractState == '') {
                    if (_this.state.percent < 99) {
                        let percent = _this.state.percent + 1;
                        _this.setState({ percent, loading: true });
                    } else {
                        _this.setState({ percent: 99, loading: true });
                    }
                } else {
                    message.success('导入成功。');
                    clearInterval(_this.interval);
                    _this.setState({ renderMode: 'PDF', percent: 0, progressStatus: 'normal', loading: false, modeParams: response.result });
                }
            }).catch((e) => {
                clearInterval(_this.interval);
                _this.setState({
                    loading: false,
                    progressStatus: 'exception'
                });
                message.error(e.message);
            });
        }, 1000);
    }

    //渲染进度条
    _renderProgress = (data) => {
        if (data.status == 200) {
            this.setState({ percent: 0 }, () => {
                this._countTime(data);
            });
        } else if (data.status == 201) {
            this.setState({ loading: false, renderMode: 'FAIL', modeParams: data.result });
        }
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { modeParams, renderMode } = this.state;
        switch (renderMode) {
            case 'PDF':
                return <PDF visible={true}
                    modeParams={modeParams}
                    closeMode={this._closeMode} />;
            case 'FAIL':
                return <Fail visible={true}
                    modeParams={modeParams}
                    closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ renderMode: '' });
    }

    //卸载组件执行
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { expandBoolean, goodsName, goodsNo, status, professionType, label, loading, percent, progressStatus } = this.state;
        const { productRateListData, productRateStatus, productRateBusinessType, productRateLabelSele } = this.props;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='商品名称'
                            placeholder='请输入商品名称'
                            bindThis={this}
                            bindName='goodsName'
                            value={goodsName}
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '200px' }}
                            label='商品货号'
                            placeholder='请输入商品货号'
                            bindThis={this}
                            bindName='goodsNo'
                            value={goodsNo}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                        <XExpandOrContract style={{ marginLeft: '30px' }} onChange={this._expandChangeHandle} />
                    </div>
                    <div className={`${styles.searchCriteriaHidden} ${expandBoolean ? styles.moreShow : styles.moreHidden}`}>
                        <div className={styles.criteria1}>
                            <XSelect
                                style={{ marginLeft: '30px' }}
                                selectStyle={{ width: '200px' }}
                                label='状态'
                                placeholder='请选择状态'
                                renderData={productRateStatus}
                                bindThis={this}
                                bindName='status'
                                dataIndex='value'
                                keyIndex='code'
                                value={status.value}
                            />
                            <XSelect
                                style={{ marginLeft: '20px' }}
                                selectStyle={{ width: '200px' }}
                                label='业务类型'
                                placeholder='请选择业务类型'
                                renderData={productRateBusinessType}
                                bindThis={this}
                                bindName='professionType'
                                dataIndex='value'
                                keyIndex='code'
                                value={professionType.value}
                            />
                            <XSelect
                                style={{ marginLeft: '20px' }}
                                selectStyle={{ width: '200px' }}
                                label='商品标签'
                                placeholder='请选择商品标签'
                                renderData={productRateLabelSele}
                                bindThis={this}
                                bindName='label'
                                dataIndex='tagName'
                                keyIndex='id'
                                value={label.tagName}
                            />
                        </div>
                        <GoodsCategory
                            label='商品分类'
                            ref={this.goodsCategory}
                            style={{ marginTop: '20px' }}
                            onChange={(value) => this.setState({ prodCascade: value })}
                        />
                    </div>
                    <div className={styles.operateBox}>
                        <XOKButton style={{ width: '150px' }} label='导出当前商品费率' onClick={this._exportOldRate} />
                        {/* <XUploadExcel
                            style={{ width: '150px', marginLeft: '20px' }}
                            label='导入最新商品费率'
                            apiurl='goods/goodsrate/rateGoodsListImportExcel'
                            loading={loading}
                            failHandle={(info) => {
                                this.setState({
                                    loading: true
                                });
                                this._renderProgress(info.file.response);
                            }}
                            successHandle={(info) => {
                                this.setState({
                                    loading: true
                                });
                                this._renderProgress(info.file.response);
                            }}
                        /> */}
                        {
                            loading && <div className={styles.progress}>
                                <Progress type="circle" width={30} percent={percent} status={progressStatus} />
                            </div>
                        }
                    </div>
                    <TableContent paginationChange={this._paginationChange} renderData={productRateListData} tableAction={this._tableAction} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // 路由history对象
    match: PropTypes.object, // 路由match对象
    updateContent: PropTypes.func,
    getProductRateListData: PropTypes.func,
    getProductRateStatus: PropTypes.func,
    getProductRateBusinessType: PropTypes.func,
    getProductRateLabelSele: PropTypes.func,
    productRateListData: PropTypes.object,
    productRateStatus: PropTypes.array,
    productRateBusinessType: PropTypes.array,
    productRateLabelSele: PropTypes.array,
};

const mapStateToProps = (state) => ({
    productRateListData: state.productRate.productRateListData,
    productRateStatus: state.productRate.productRateStatus,
    productRateBusinessType: state.productRate.productRateBusinessType,
    productRateLabelSele: state.productRate.productRateLabelSele.dataList || [],
});

export default connect(mapStateToProps, actions)(Main);