import React, { Component } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { XInput, XOKButton, XCancelButton, XTextArea } from '@/components/xqxc_ui';
import AllGoodsTable from './components/AllGoodsTable';
import SkuInventory from './modal/SkuInventory';
import Delay from './modal/Delay';
import SetPrice from './modal/SetPrice';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/productmanage/wholesalegoods/model';
import Putaway from './modal/Putaway';

class AllGoods extends Component {

    static propTypes = {
        history: PropTypes.object,
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getWholesaleGoodsList: PropTypes.func,
        wholesaleGoodsList: PropTypes.object
    }

    static defaultProps = {
        wholesaleGoodsList: { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 },
    }

    state = {
        renderModal: 'default', // 当前需要打开的弹窗
        modalParams: null, // 当前需要打开的弹窗的入参
        goodsNos: '',
        goodsName: '',
        skuNos: '',
        wholesaleGoodsIdList: []
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const {
            goodsName,
            goodsNos,
            skuNos,
            pageNum,
            pageSize
        } = this.state;
        this.searchCache = {
            goodsName,
            goodsNos: goodsNos && goodsNos.replace(/，/g, ','),
            skuNos: skuNos && skuNos.replace(/，/g, ','),
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getWholesaleGoodsList(this.searchCache);
        KeepAlive.saveCache({ goodsName, goodsNos, skuNos, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({
            goodsName: '',
            goodsNos: '',
            skuNos: '',
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle);
        });
    }

    //请求函数
    _request = (url, data) => {
        http(url, data, 'POST')
            .then(res => {
                message.success(res.message);
                this._searchHandle('useCache');
            })
            .catch(err => {
                message.error(err.message);
            });
    }

    // 渲染弹窗
    _renderMode = () => {
        const { renderModal, modalParams } = this.state;
        const obj = {
            'skuInventory': <SkuInventory
                modalParams={modalParams}
                closeMode={this._closeMode} />,
            'delay': <Delay
                modalParams={modalParams}
                closeMode={this._closeMode} />,
            'setPrice': <SetPrice
                modalParams={modalParams}
                closeMode={this._closeMode} />,
            'putaway': <Putaway
                modalParams={modalParams}
                closeMode={this._closeMode} />,
            'default': null,
        };
        return obj[renderModal];
    }
    // 关闭弹窗
    _closeMode = (refresh) => {
        this.setState({ renderModal: 'default', modalParams: null, wholesaleGoodsIdList: [] }, () => {
            if (refresh) {
                this._searchHandle('useCache');
            }
        });
    }

    //路由跳转
    _routeJump = (title, url) => {
        const { addOneBreadcrumbPath, history } = this.props;
        const baseURL = '/home/productmanage/wholesalegoods';
        addOneBreadcrumbPath({ title, path: baseURL });
        history.push({ pathname: `${baseURL}${url}` });
    }


    // 表格操作
    _tableAction = (id, type, rowValue) => {
        switch (type) {
            case 'detail':
                this._routeJump('商品详情', `/goodsinfo/${id}/1`);
                break;
            case 'delay':
                this.setState({ renderModal: 'delay', modalParams: { autoOffTime: rowValue.autoOffTime, wholesaleGoodsId: rowValue.wholesaleGoodsId } });
                break;
            case 'skuInventory':
                this.setState({ renderModal: 'skuInventory', modalParams: rowValue });
                break;
            case 'again':
                this.setState({ renderModal: 'setPrice', modalParams: rowValue });
                break;
            case 'putaway':
                this.setState({ renderModal: 'putaway', modalParams: { wholesaleGoodsIds: [id] } });
                break;
            default:
                break;
        }
    }
    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => {
            this._searchHandle('useCache');
        });
    }

    onKeysChange = (wholesaleGoodsIdList = []) => {
        this.setState({ wholesaleGoodsIdList });
    }

    render() {
        const { wholesaleGoodsList } = this.props;
        const {
            goodsName,
            goodsNos,
            skuNos, wholesaleGoodsIdList = []
        } = this.state;
        return (
            <KeepAlive id='allGoods' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchBox}>
                        <XTextArea
                            label='货号'
                            inputStyle={{ marginRight: 20, width: 250 }}
                            placeholder='请输入货号，多个用逗号隔开，最多100条'
                            value={goodsNos}
                            rows={2}
                            onChange={value => {
                                if (value.replace(/，/g, ',').split(',').length < 100) {
                                    this.setState({ goodsNos: value });
                                } else {
                                    message.warning('商品货号最多100条');
                                }
                            }}
                        />
                        <XTextArea
                            label='SKU编码'
                            inputStyle={{ marginRight: 20, width: 250 }}
                            placeholder='请输入SKU编码，多个用逗号隔开，最多100条'
                            value={skuNos}
                            rows={2}
                            onChange={value => {
                                if (value.replace(/，/g, ',').split(',').length < 100) {
                                    this.setState({ skuNos: value });
                                } else {
                                    message.warning('SKU编码最多100条');
                                }
                            }}
                        />
                        <XInput
                            label='商品名称'
                            inputStyle={{ marginRight: 20, width: 150 }}
                            placeholder='请输入商品名称'
                            bindThis={this}
                            bindName='goodsName'
                            value={goodsName}
                        />
                        <XOKButton
                            label='查询'
                            style={{ marginRight: 10 }}
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.operateBox}>
                        <XOKButton
                            style={{ width: 120 }}
                            label='添加批发商品'
                            onClick={() => {
                                this._routeJump('商品列表', '/goodslist');
                            }} />
                        <XOKButton style={{ width: '120px', marginLeft: '20px' }} label='批量延长下架'
                            onClick={() => this.setState({ renderModal: 'delay', modalParams: wholesaleGoodsIdList })} />
                    </div>
                    <AllGoodsTable paginationChange={this._paginationChange}
                        screenY={screenY} renderData={wholesaleGoodsList}
                        tableAction={this._tableAction}
                        wholesaleGoodsIdList={wholesaleGoodsIdList} onKeysChange={this.onKeysChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

export default connect(state => ({
    wholesaleGoodsList: state.wholesaleGoods.wholesaleGoodsList
}), {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    ...model.actions
})(AllGoods);