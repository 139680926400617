import React from 'react';
import styles from './TableData.module.scss';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';
import { Table } from 'antd';
import PropTypes from 'prop-types';

class TableData extends React.Component {
    _takeColumns = () => ([
        {
            title: '序号',
            dataIndex: 'key',
            key: 'key',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: (text, item, index) => {
                return <span>{index + 1}</span>;
            }
        },
        {
            title: '合同名称',
            dataIndex: 'contractName',
            key: 'contractName',
            width: '15%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '版本号',
            dataIndex: 'version',
            key: 'version',
            width: '8%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '签署时间',
            dataIndex: 'signDate',
            key: 'signDate',
            width: '10%',
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'key',
            key: 'action',
            align: 'center',
            width: '10%',
            render: (text, item) => this._renderActionCell(item.id)
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderActionCell = (key) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'info')}>查看详情</div>
            </div>
        );
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
        }
    };

    render() {
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => record.id}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(this.props.renderData)}
                    pagination={false}
                    scroll={{ y: true }}
                />
                {/*<XPagination*/}
                {/*    resultTotal={resultTotal}*/}
                {/*    pageSize={pageSize}*/}
                {/*    pageNum={pageNum}*/}
                {/*    onChange={paginationChange}*/}
                {/*/>*/}
            </div>
        );
    }
}

TableData.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func, // 分页操作
};

export default TableData;
