/**
 * 选择商品分类弹框 
 * */
import React from 'react';
import { Modal, Button, message, Radio } from 'antd';
import PropTypes from 'prop-types';
import styles from './ProductClassifyModal.module.scss';
import { GoodsCategory, GDCGoodsCategory } from '@/vcomps';
import { getIsGDCPromise } from '@/assets/js/authType';

class ProductClassifyModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isGDC: false,
            prodType: 'VIRTUAL',
            visible: this.props.visible,
            confirmLoading: false,
            prodCascade: '', // 商品联级分类
            prodCascadeDetail: [], // 商品联级分类详细信息
        };
    }

    componentDidMount() {
        getIsGDCPromise().then((isGDC) => {
            this.setState({ isGDC });
        });
    }

    // 确认
    _handleOk = () => {
        const { prodCascadeDetail, prodType } = this.state;
        const trueList = prodCascadeDetail.filter((item) => {
            return item.id == '' || item.id == undefined || item.id == null;
        });
        if (prodCascadeDetail.length == 0 || trueList.length > 0) {
            message.warning('需选择三级分类');
            return;
        }
        this.setState({
            visible: false,
        });
        this.props.closeMode('ok', this.state.prodCascadeDetail, prodType);
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode('cancel');
    }

    // 分类搜索
    _onCascadeSearch = (value) => {
        console.log(value);
        this.setState({ prodCascade: value });
    }

    render() {
        const { isGDC, prodType, visible, confirmLoading } = this.state;

        return (
            <Modal
                width={706}
                centered
                title='商品分类'
                visible={visible}
                confirmLoading={confirmLoading}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                footer={[
                    <Button key="back" type="primary" onClick={this._handleOk}>
                        确认
                    </Button>
                ]}
            >
                <div className={styles.container}>

                    {isGDC &&
                        <>
                            <div className={styles.title}>商品类型：</div>
                            <Radio.Group
                                value={prodType}
                                style={{ marginLeft: 10, marginBottom: 15 }}
                                onChange={(e) => this.setState({ prodType: e.target.value })}>
                                {/* <Radio value='MALL'>实物商品</Radio> */}
                                <Radio value='VIRTUAL'>虚拟商品</Radio>
                            </Radio.Group>
                        </>
                    }
                    <div className={styles.title}>请选择商品的所属分类：</div>
                    {isGDC
                        ? <GDCGoodsCategory
                            categoryType={prodType}
                            style={{ marginTop: '0px' }}
                            onChange={(value) => this.setState({ prodCascade: value })}
                            onGetItems={(value) => this.setState({ prodCascadeDetail: value })}
                        />
                        : <GoodsCategory
                            type={this.props.type}
                            style={{ marginTop: '0px' }}
                            onChange={(value) => this.setState({ prodCascade: value })}
                            onGetItems={(value) => this.setState({ prodCascadeDetail: value })}
                        />
                    }
                </div>
            </Modal>
        );
    }
}

ProductClassifyModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    closeMode: PropTypes.func.isRequired,
    type: PropTypes.string
};

export default ProductClassifyModal;