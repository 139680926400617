import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';

class TableContent extends Component {
	state = {
		selectedRowKeys: [],
	};

	_columns = () => [
		{
			title: '供应商代码',
			dataIndex: 'supplierCode',
			key: 'supplierCode',
			align: 'center',
		},
		{
			title: '供应商名称',
			dataIndex: 'supplierName',
			key: 'supplierName',
			align: 'center',
		},
		{
			title: '经营品类',
			dataIndex: 'categoryName',
			key: 'categoryName',
			align: 'center',
		},
		{
			title: '结算方式',
			dataIndex: 'settleType',
			key: 'settleType',
			align: 'center',
		},
		{
			title: '联系人',
			dataIndex: 'contact',
			key: 'contact',
			align: 'center',
		},
		{
			title: '联系方式',
			dataIndex: 'contactPhone',
			key: 'contactPhone',
			align: 'center',
		},
		{
			title: '操作',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			render: (id, record) => this._tableAction(id, record)
		},
	];

	// 表格复选输入
	_rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			console.log(selectedRows);
			this.setState({ selectedRowKeys });
			this._actionHandle(null, 'batchBan', selectedRowKeys);
		}
	};

	// 清空表格复选框
	_cleanAllSelected = () => {
		this.setState({ selectedRowKeys: [] });
	}

	// 表格操作
	_tableAction = (key, record) => {
		const { tableAction } = this.props;
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => tableAction(key, 'detail', record)}>详情</div>
				<div className={styles.item} onClick={() => tableAction(key, 'audit', record)}>审核</div>
			</div>
		);
	};

	_tableData = (data) => {
		return data;
	}

	render() {
		const { renderData, paginationChange } = this.props;
		const { selectedRowKeys } = this.state;
		this._rowSelection.selectedRowKeys = selectedRowKeys;
		return (
			<div className={styles.tableContainer}>
				{/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					// rowSelection={this._rowSelection}
					rowKey={record => record.id}
					columns={this._columns()}
					pagination={false}
					dataSource={this._tableData(renderData.dataList)}
					scroll={{ y: true }} />
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange} />
			</div>
		);
	}
}

TableContent.propTypes = {
	tableAction: PropTypes.func,
	paginationChange: PropTypes.func,
	renderData: PropTypes.object,
};

export default TableContent;