import * as T from './actiontypes';

const defaultState = {
    tableSource: [],//列表数据源
    selectData: [],// 列表下拉状态选项
    paginations: {},//分页对象
    storeData: [],//新建店铺类型下拉
    mereditor: {},//店铺编辑

    // ListDetail: {}, //详情
    // radiusData: [],// 新建商户商户类型
    // storeListTable: [],//店铺
    // province: [], // 省份
    // city: [], // 城市
    // street: [], // 地区
    // protion: [],// 新建商户商户类型
};


/**
 * 存储'商户列表'数据源
 * @param {*} state 
 * @param {*} action 
 */
export const merchantDate = (state = defaultState, action) => {
    let newTableSource, storeData, newSelectData, newPaginations;
    switch (action.type) {
        case T.SET_MERCHANT_DATA: //存储'店铺列表'数据源
            newTableSource = action.payload.result.pager.dataList || [];
            newPaginations = {
                pageNum: action.payload.result.pager.pageNum,
                pageSize: action.payload.result.pager.pageSize,
                resultTotal: action.payload.result.pager.resultTotal
            };
            return { ...state, tableSource: newTableSource, paginations: newPaginations };
        case T.SET_MERCHANTSELECT_DATA: //存储'店铺列表下拉选项'数据源
            newSelectData = action.payload.result || [];
            return { ...state, selectData: newSelectData };
        case T.SET_CREATEINFO_DATA: //存储'新建店铺类型下拉'数据源
            storeData = action.payload.result || [];
            return { ...state, storeData };
        case T.SET_MERCHANTEDITOR_DATA: //存储'店铺编辑'数据源
            return { ...state, mereditor: action.payload.result|| {} };
        default:
            return state;
    }
};
//SET_MERCHANTEDITOR_DATA


