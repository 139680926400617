import React from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip } from '@/components/TableCell';

const TableContent = ({
    renderData: {
        dataList,
        pageNum,
        pageSize,
        resultTotal
    },
    paginationChange,
}) => {
    const columns = [
        {
            title: '操作',
            dataIndex: 'status.value',
            key: 'status.value',
            align: 'center',
            width: 150,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '备注',
            dataIndex: 'msg',
            key: 'msg',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 150,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
    ];

    return (
        <div className={styles.tableContainer} style={{ height: 420 }}>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowKey={(record, index) => index}
                columns={columns}
                dataSource={dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={resultTotal}
                pageSize={pageSize}
                pageNum={pageNum}
                onChange={paginationChange}
            />
        </div>
    );
};
TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
};
export default TableContent;