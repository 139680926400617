import * as Order from './actionTypes';

//商品订单列表
export const getProductOrderList = (param) => ({
    type: Order.GET_PRODUCTORDER_LIST,
    payload: param
});

//商品订单类型
export const getProductOrderType = () => ({
    type: Order.GET_PRODUCTORDER_TYPE,
    payload: {}
});

//商品订单状态
export const getProductOrderStatus = () => ({
    type: Order.GET_PRODUCTORDER_STATUS,
    payload: {}
});

//商品是否开票
export const getProductOrderFlagEnum = () => ({
    type: Order.GET_PRODUCTORDER_FLAGENUM,
    payload: {}
});
//认购订单状态
export const getProductSubOrderStatus = () => ({
    type: Order.GET_PRODUCTSUBORDER_STATUS,
    payload: {}
});
// 分销订单
export const getFenxiaoOrderList = (param) => ({
    type: Order.GET_FENXIAOORDER_LIST,
    payload: param
});
// 业务类型
export const getBusinessType = (param) => ({
    type: Order.GET_BUSINESS_TYPE,
    payload: param
});