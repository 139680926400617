/**
 *  【财务设置】入口页
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';
import AllPay from './pages/AllPay';

class Financialsetting extends React.Component {

    state = {
        type: 'tl' // zlx中联信、tl通联
    }

    // 渲染弹框组件
    _renderContent = () => {
        const { type } = this.state;
        if (type == 'zlx') {
            // 打开中联信注册弹框
            return <Main history={this.props.history} />;
        } else if (type == 'tl') {
            return <AllPay history={this.props.history} />;
        } else {
            return null;
        }
    }

    render() {
        return (
            <>
                {this._renderContent()}
            </>
        );
    }
}

Financialsetting.propTypes = {
    history: PropTypes.object
};

export default Financialsetting;