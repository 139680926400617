import * as Order from './actionTypes';

const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

//订单列表数据
export const transcationList = (state = initData, action) => {
    switch (action.type) {
        case Order.SET_TRANSCATION_LIST:
            return action.payload.result;
        default:
            return state;
    }
};
//店铺列表数据
export const storeList = (state = [], action) => {
    switch (action.type) {
        case Order.SET_STORE_LIST:
            return action.payload.result;
        default:
            return state;
    }
};

//订单列表数据（统计用）
export const transcationList2 = (state = { ...initData, dataList: [] }, action) => {
    switch (action.type) {
        case Order.SET_TRANSCATION_LIST2:
            return action.payload.result;
        default:
            return state;
    }
};
// 订单应收明细合计
export const merchantOrderAmount = (state = {}, action) => {
    switch (action.type) {
        case Order.SET_ORDER_AMOUNT:
            return action.payload.result;
        default:
            return state;
    }
};

