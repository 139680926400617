/**
 * 编辑销售分类
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message, Input, Form } from 'antd';

const EditClassify = ({ closeMode, form: { validateFieldsAndScroll, getFieldDecorator }, modalParams }) => {

    const [confirmLoading, setConfirmLoading] = useState(false);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
    };

    const handleSubmit = e => {
        e.preventDefault();
        validateFieldsAndScroll((err, values) => {
            if (!err) {
                setConfirmLoading(true);
                http('/city/shopCategoryInfo/saveShopCategory',
                    {
                        categoryId: modalParams.categoryId,
                        categoryName: values.categoryName,
                        parentCategoryId: modalParams.parentId
                    }, 'POST')
                    .then(res => {
                        message.success(res.message);
                        setConfirmLoading(false);
                        closeMode('reqClass');
                    })
                    .catch(err => {
                        message.error(err.message);
                        setConfirmLoading(false);
                    });
            }
        });
    };

    return (
        <Modal
            width={500}
            bodyStyle={{ paddingBottom: 0 }}
            centered
            title='编辑分类'
            visible={true}
            confirmLoading={confirmLoading}
            onOk={handleSubmit}
            onCancel={closeMode}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'>
            <Form id='editClassify' {...formItemLayout} onSubmit={handleSubmit}>
                <Form.Item label='分类名称'>
                    {getFieldDecorator('categoryName', {
                        initialValue: modalParams && modalParams.categoryName,
                        rules: [
                            {
                                required: true,
                                message: '请输入分类名称',
                            },
                            {
                                pattern: /^(?!.*\/.*$)/,
                                message: '分类名称不能含有斜杠',
                            }
                        ],
                    })(
                        <Input placeholder='4个字以内展示最佳' />
                    )}
                </Form.Item>
            </Form>
        </Modal>
    );
};

EditClassify.propTypes = {
    modalParams: PropTypes.object,
    closeMode: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
};

export default Form.create({ name: 'editClassify' })(EditClassify);