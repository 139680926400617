/**
 * 店铺管理  Saga集合
 */
import {
    merchantListSource,
    merchantSelectData,
    merchantstoreData,
    storeEditor,
} from './merchantlist/saga';
import {
    merchantStoreSource,
} from './storelist/saga';
import cityShopGoods from './cityshopgoods/model';
import { getStandardProduct, getStandardProductDetail } from './standardproduct/saga';

export default [
    merchantListSource,//店铺列表
    merchantSelectData,//下拉选项
    merchantstoreData, //新建店铺类型
    storeEditor,//店铺编辑
    getStandardProduct,  //标准商品库
    getStandardProductDetail, //标准商品详情

    merchantStoreSource,//同城店铺商品列表
    ...Object.values(cityShopGoods.sagas)
];