import * as T from './actiontypes';
/** 
 * 集采管理列表数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {string} activityTitle 集采名称
 * @param {string} goodsNo 商品货号
 * @param {string} goodsName 商品名称
 */

export const getPurchasingList = (dataload) => ({
    type: T.GET_PURCHASINGLIST_DATA,
    payload: {
        ...dataload
    }
});

/**
 *集采管理新增商品
 */
export const getPurchasingTable = (dataload) => ({
    type: T.GET_RPURCHASINGTABALE_DATA,
    payload: {
        ...dataload,
    }
});

/**
 * 集采管理详情'数据源
 * @param {number} activityId  获取某个用户情对应的id
 */
export const getPurchasingDetails = (id) => ({
    type: T.GET_PURCHASINGDETAIL_DATA,
    payload: {
        activityId: id,
    }
});