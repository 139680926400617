import React, { useState } from 'react';
import styles from './TableStyle.module.scss';
import PropTypes from 'prop-types';
import { Table, Tag, Switch, Tooltip } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { priceFenToYuanCell, tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';

const TableContent = ({ renderData, tableAction, paginationChange }) => {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    //表头
    const _columns = () => {
        return [
            {
                title: '房源封面',
                dataIndex: 'mainImgUrl',
                key: 'mainImgUrl',
                width: '10%',
                align: 'center',
                render: ImgCell
            },
            {
                title: '房源名称',
                dataIndex: 'goodsName',
                key: 'goodsName ',
                width: '15%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '房源房型',
                dataIndex: 'categoryName',
                key: 'categoryName',
                width: '10%',
                align: 'center',
                onCell: tooltipStyle,
                render: tooltip
            },
            {
                title: '基础设施',
                dataIndex: 'installationList',
                key: 'installationList',
                width: '20%',
                align: 'center',
                render: _tagCell
            },
            {
                title: '房源价格（元）',
                dataIndex: 'marketPrice',
                key: 'marketPrice',
                width: '10%',
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '房源最低平台价（元）',
                dataIndex: 'minPrice',
                key: 'minPrice',
                width: '15%',
                align: 'center',
                render: priceFenToYuanCell
            },
            {
                title: '列表推荐',
                dataIndex: 'isHomeReco',
                key: 'isHomeReco',
                width: '8%',
                align: 'center',
                render: (text, item) => (_switchCell(text, item))
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                width: '12%',
                align: 'center',
                render: (id, item) => _tableAction(id, item)
            }
        ];
    };

    // 表格复选输入
    const _rowSelection = {
        onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
            tableAction(null, 'batch', selectedRowKeys);
        }
    };
    // 清空表格复选框
    const _cleanAllSelected = () => {
        setSelectedRowKeys([]);
        tableAction(null, 'batch', []);
    };

    //标签显示
    const _tagCell = (tags) => {
        let tagList = [];
        let tagArr = [];
        for (let t = 0; t < tags.length; t++) {
            const keys = Object.keys(tags[t]);
            const values = Object.values(tags[t]);
            let tag = {};
            tag.id = keys[0];
            tag.value = values[0];
            tagList.push(tag);
            tagArr.push(values[0]);
        }
        if (tagList.length > 2) {
            let tags = tagList.slice(0, 2);
            tags.push({ id: 0, value: '...' });
            return (
                <div>
                    {tags.map(tag => {
                        if (tag.id) {
                            return <Tag color="blue" key={tag.id}>
                                {tag.value}
                            </Tag>;
                        } else {
                            return (
                                <Tooltip key={tag.id} placement="topRight" title={tagArr.join(' ')} >
                                    <Tag color="blue" key={tag.id}>
                                        {tag.value}
                                    </Tag>
                                </Tooltip>
                            );
                        }
                    })}
                </div>
            );
        } else {
            return (
                <div>
                    {tagList.map(tag => (
                        <Tag color="blue" key={tag.id}>
                            {tag.value}
                        </Tag>
                    ))}
                </div>
            );
        }
    };

    // 表格项内容为开关
    const _switchCell = (sw, item) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={sw}
                    onChange={() => {
                        tableAction(item.id, 'switch', item);
                    }}
                />
            </div>
        );
    };

    //表格操作
    const _tableAction = (id, item) => {
        return (
            <div className={styles.action}>
                {
                    item.goodsState.code === 'PULL_OFF_SHELVES' ?
                        <div className={styles.item} onClick={() => tableAction(id, 'putaway', item)}>上架</div> :
                        <div className={styles.item} onClick={() => tableAction(id, 'soldOut', item)}>下架</div>
                }
                <div className={styles.item} onClick={() => tableAction(id, 'detail', item)}>详情</div>
                <div className={styles.item} onClick={() => tableAction(id, 'edit', item)}>编辑</div>
                <div className={styles.item} onClick={() => tableAction(id, 'delete', item)}>删除</div>
            </div>
        );
    };

    _rowSelection.selectedRowKeys = selectedRowKeys;
    return (
        <div className={styles.tableContainer}>
            <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={_cleanAllSelected} />
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                rowSelection={_rowSelection}
                rowKey={record => record.id}
                columns={_columns()}
                dataSource={renderData.dataList}
                pagination={false}
                scroll={{ y: true }}
            />
            <XPagination
                resultTotal={renderData.resultTotal}
                pageSize={renderData.pageSize}
                pageNum={renderData.pageNum}
                onChange={paginationChange} />
        </div>
    );
};

TableContent.propTypes = {
    renderData: PropTypes.object, // 渲染数据源(双向绑定)
    tableAction: PropTypes.func, // 对表格的操作
    paginationChange: PropTypes.func
};

TableContent.default = {
    dataList: [
        {
            installationList: [],
        }
    ],
    pageNum: 1,
    pageSize: 20,
    resultTotal: 0
};

export default TableContent;