import React, { useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './Verify.module.scss';
import { Modal, message, Input } from 'antd';
import { XInfoLabel } from '@/components/xqxc_ui';
import { regFenToYuan } from '@/util/money';

const Verify = ({ modeParams, closeMode }) => {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [liveCode, setLiveCode] = useState('');
    const [title, setTitle] = useState('验证入驻');
    const [isCheck, setIsCheck] = useState(true);
    const [verifyInfo, setVerifyInfo] = useState({});
    const [id, setID] = useState(modeParams.id);
    const [buyerId, setBuyerId] = useState(modeParams.buyerId);

    // 确认
    const _handleOk = () => {
        setConfirmLoading(true);
        if (isCheck) {
            let url = '';
            let data = {};
            if (modeParams.buyerId || modeParams.id) {
                url = '/hotel/verification/verificationLive';
                data = { buyerId, id, liveCode };
            } else {
                url = '/hotel/verification/verificationLiveByLiveCode';
                data = { liveCode };
            }
            http(url, data, 'POST')
                .then((response) => {
                    setConfirmLoading(false);
                    setTitle('入住信息');
                    setIsCheck(false);
                    setVerifyInfo(response.result);
                    setBuyerId(response.result.buyerId);
                    setID(response.result.id);
                }).catch((e) => {
                    setConfirmLoading(false);
                    message.error(e.message);
                });
        } else {
            http('/hotel/verification/confirmCancellation', { buyerId, id, liveCode }, 'POST')
                .then(() => {
                    closeMode(true);
                    setConfirmLoading(false);
                    setIsCheck(true);
                    message.success('验证入住信息操作成功');
                }).catch((e) => {
                    setConfirmLoading(false);
                    message.error(e.message);
                });
        }
    };

    // 取消
    const _handleCancel = () => {
        closeMode(false);
    };

    const _renderPage = (isCheck) => {
        if (isCheck) {
            return (
                <div>
                    <div className={styles.titleBox}>请输入入住码</div>
                    <div className={styles.tipBox}>提示可以让客户打开“每日说APP-约宿-酒店订单”查看入住码</div>
                    <div className={styles.inptBox}>
                        <Input placeholder='请输入入住码' value={liveCode} onChange={e => setLiveCode(e.target.value)} />
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles.checked}>
                    <div className={styles.checked_col}>
                        <XInfoLabel style={{ marginBottom: '20px' }} labelWith={80} label='订单号' value={verifyInfo.id} />
                        <XInfoLabel style={{ marginBottom: '20px' }} labelWith={80} label='联系方式' value={verifyInfo.hostPhone} />
                        <XInfoLabel style={{ marginBottom: '20px' }} labelWith={80} label='入住房型' value={verifyInfo.goodsName} />
                        <XInfoLabel style={{ marginBottom: '20px' }} labelWith={80} label='入住时间' value={verifyInfo.startDate} />
                    </div>
                    <div className={styles.checked_col}>
                        <XInfoLabel style={{ marginBottom: '20px' }} labelWith={80} label='下单时间' value={verifyInfo.createTime} />
                        <XInfoLabel style={{ marginBottom: '20px' }} labelWith={80} label='入住人' value={verifyInfo.hostName} />
                        <XInfoLabel style={{ marginBottom: '20px' }} labelWith={80} label='支付金额' value={`￥${regFenToYuan(verifyInfo.payFee)}`} />
                        <XInfoLabel style={{ marginBottom: '20px' }} labelWith={80} label='离开时间' value={verifyInfo.endDate} />
                    </div>
                </div>
            );
        }
    };

    return (

        <Modal
            width={680}
            centered
            title={title}
            visible={true}
            confirmLoading={confirmLoading}
            onOk={_handleOk}
            onCancel={_handleCancel}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'
        >
            <div className={styles.container}>
                {
                    _renderPage(isCheck)
                }
            </div>
        </Modal>
    );
};

Verify.propTypes = {
    history: PropTypes.object, // 路由history对象
    modeParams: PropTypes.object,//弹框入参
    closeMode: PropTypes.func,//关闭弹框参数
};

export default Verify;