import * as T from './actiontypes';

/**
 * 商户商品列表
 */
const initData = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

const detail={
    skuList:[]
};

//商品对接列表
export const goodsConnectList = (state = initData, { type, payload }) => {
    switch (type) {
        case T.SET_GOODSCONNECT_LIST:
            return payload.result;
        default:
            return state;
    }
};

//商品对接导入状态
export const goodsConnectImport = (state = [], { type, payload }) => {
    switch (type) {
        case T.SET_GOODSCONNECT_IMPORT:
            return payload.result;
        default:
            return state;
    }
};

//商品对接商品状态
export const goodsConnectState = (state = [], { type, payload }) => {
    switch (type) {
        case T.SET_GOODSCONNECT_GOODSSTATE:
            return payload.result;
        default:
            return state;
    }
};

//商品对接详情
export const goodsConnectDetail = (state = detail, { type, payload }) => {
    switch (type) {
        case T.SET_GOODSCONNECT_DETAIL:
            return payload.result;
        default:
            return state;
    }
};