/**
 * 更新商品结算价格弹框 
 * */
import React from 'react';
import { Modal, Table, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import styles from './UpdataPriceModal.module.scss';

// 模拟会员表格数据
const tableDataSource = [];
for (var i = 0; i < 20; i++) {
    tableDataSource.push({
        key: i,
        c1: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1058609816049&di=111d05f867aaaa2724365b2458f7b7e2&imgtype=0&src=http%3A%2F%2Fbpic.588ku.com%2Felement_origin_min_pic%2F17%2F07%2F28%2F7f1dc8f645c9ca6cd5fdc80a66a95e88.jpg',
        c2: 'SUK1',
        c3: '红色，M',
        c5: '100',
        c6: '10.01',
        c7: '2019-06-24 10:30:00',
    });
}

class UpdataPriceModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
    }

    // 确认
    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _columns = () => ([
        {
            title: '图片',
            dataIndex: 'c1',
            key: 'c1',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderImgCell(text))
        },
        {
            title: '商品编码',
            dataIndex: 'c2',
            key: 'c2 ',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '规格',
            dataIndex: 'c3',
            key: 'c3',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '数量',
            dataIndex: 'c5',
            key: 'c5',
            width: '10%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '结算价格',
            dataIndex: 'c6',
            key: 'c6',
            width: '20%',
            align: 'center',
            render: (text, item) => (this._renderInputCell(text, item))
        },
        {
            title: '更新时间',
            dataIndex: 'c7',
            key: 'c7',
            width: '20%',
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
    ]);

    _renderInputCell = (text, item) => {
        return (
            <div>
                <InputNumber
                    className={styles.input}
                    defaultValue={text}
                    onChange={(value) => {
                        item.c6 = value;
                        this.forceUpdate();
                    }}
                />
            </div>
        );
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderImgCell = (url) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                <div className={styles.imgbox}>
                    <img className={styles.img} src={url} />
                </div>
            </div>
        );
    };

    render() {
        const { visible, confirmLoading } = this.state;

        return (
            <Modal
                width={1105}
                centered
                title='更新SKU结算价'
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.header}>
                        {/* <div className={styles.title}>
                            销售规格
                        </div> */}
                        <div className={styles.update}>
                            <div>批量修改结算价格：</div>
                            <div className={styles.updateNum}>
                                <InputNumber
                                    className={styles.input}
                                    placeholder='请输入库结算价格'
                                    onChange={(value) => {
                                        console.log(value);
                                    }}
                                />
                            </div>
                            <div className={styles.button}>
                                全部修改
                            </div>
                        </div>
                    </div>
                    <div className={styles.table}>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            rowSelection={this._rowSelection}
                            columns={this._columns()}
                            dataSource={tableDataSource}
                            pagination={false}
                            scroll={{ y: 530 }}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

UpdataPriceModal.propTypes = {
    data: PropTypes.string,
    visible: PropTypes.bool.isRequired,
    closeMode: PropTypes.func.isRequired,
};

export default UpdataPriceModal;