/**
 * 【财务设置创建页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Row, Col, Switch } from 'antd';
import { XCancelButton } from '@/components/xqxc_ui';
import styles from './index.module.scss';

class addFinancialContent extends React.Component {

    state = {
        dataSource: [{
            name: '接收消息通知',
            description: '控制是否接收所有消息（开启后可接受到系统推送的消息）',
            start: true
        }, {
            name: '语音提醒管理',
            description: '控制是否接收语音提醒（开启后接收到消息会有相关语音提示）',
            start: true
        }]
    }

    render() {
        const { dataSource } = this.state;
        return (
            <div style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }} >
                <div className={styles.title}>通知设置</div>
                <div className={styles.line}></div>
                <List
                    bordered
                    dataSource={dataSource}
                    renderItem={item => (
                        <List.Item>
                            <Row style={{ width: '100%' }}>
                                <Col span={6}><a>{item.name}</a></Col>
                                <Col span={15}>{item.description}</Col>
                                <Col span={3}><Switch checkedChildren="开" unCheckedChildren="关" checked={item.start} /></Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </div >
        );
    }
}
addFinancialContent.propTypes = {
    history: PropTypes.object, // 路由history对象
    form: PropTypes.object.isRequired, // 校验
    match: PropTypes.object, // 路由match对象
};
export default addFinancialContent;