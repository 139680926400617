/**
 * 已打款
 * */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XInput, XOKButton, XCancelButton, XDatePicker, XSelect } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { connect } from 'react-redux';
import { getAlreadyPayList } from '@/reducers/storehousefinancialmanage/collectpay/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import http from '@/assets/api/http';

class AlreadySettle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            servicerName: '', // 服务商名称
            applyStartDate: undefined, // 申请开始时间
            applyEndDate: undefined, // 申请结束时间
            remitStartDate: undefined, // 打款开始时间
            remitEndDate: undefined, // 打款结束时间
            sourceType: {},
            sourceTypeList: []
        };
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留二级
        this.getSourceTypeList();
    }

    getSourceTypeList = () => {
        http('/enum/GatherWithdrawSourceEnum').then(res => {
            this.setState({ sourceTypeList: res.result || [] });
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, isAnchor: 0, pageSize, pageNum };
        this.props.getAlreadyPayList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 查询触发
    _searchHandle = (useCache) => {
        const { servicerName, applyStartDate, applyEndDate, remitStartDate, remitEndDate, sourceType, pageNum, pageSize } = this.state;
        this.searchCache = {
            servicerName,
            applyStartDate,
            applyEndDate,
            remitStartDate,
            remitEndDate,
            sourceType: sourceType.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getAlreadyPayList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _resetHandle = () => {
        this.setState({ servicerName: '', applyStartDate: undefined, applyEndDate: undefined, remitStartDate: undefined, remitEndDate: undefined, sourceType: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (key, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: '/home/storehousefinancialmanage/collectpay/detail/'
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/collectpay/detail/' + key
                });
                break;
        }
    }

    render() {
        const { servicerName, applyStartDate, applyEndDate, remitStartDate, remitEndDate, sourceTypeList, sourceType } = this.state;
        const { alreadyPayList } = this.props;

        return (
            <KeepAlive id='alreadypay' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='服务商'
                            placeholder='请输入服务商'
                            bindThis={this}
                            bindName='servicerName'
                            value={servicerName}
                        />
                        <XDatePicker
                            style={{ marginLeft: '20px' }}
                            label='申请时间'
                            bindThis={this}
                            bindName='applyStartDate'
                            value={applyStartDate}
                            isFormat={true}
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            bindThis={this}
                            bindName='applyEndDate'
                            value={applyEndDate}
                            isFormat={true}
                        />
                        <XDatePicker
                            style={{ marginLeft: '20px' }}
                            label='打款时间'
                            bindThis={this}
                            bindName='remitStartDate'
                            value={remitStartDate}
                            isFormat={true}
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            bindThis={this}
                            bindName='remitEndDate'
                            value={remitEndDate}
                            isFormat={true}
                        />
                        <XSelect
                            style={{ marginLeft: 20 }}
                            selectStyle={{ width: '200px' }}
                            label='申请来源'
                            placeholder='请选择'
                            renderData={sourceTypeList}
                            dataIndex='value'
                            keyIndex='code'
                            value={sourceType.value}
                            showSearch={true}
                            bindThis={this}
                            bindName='sourceType'
                        />
                        <XOKButton
                            style={{ marginLeft: '30px', marginTop: 10, width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', marginTop: 10, width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <TableContent
                        tableAction={this._tableAction}
                        renderData={alreadyPayList}
                        paginationChange={this._paginationChange}
                    />
                </div>
            </KeepAlive>
        );
    }
}

AlreadySettle.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,
    getAlreadyPayList: PropTypes.func,
    alreadyPayList: PropTypes.object
};
const mapStateToProps = (state) => ({
    alreadyPayList: state.collectpay.alreadyPayList
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getAlreadyPayList })(AlreadySettle);