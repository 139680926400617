import { put, takeEvery } from 'redux-saga/effects';
import http from '@/assets/api/http';
import * as T from './actiontypes';

//获取供应商结算价审核列表
export const getBalanceAuditList = function* () {
    yield takeEvery(T.GET_BALANCEAUDIT_LIST, function* requestData(action) {
        try {
            let result = yield http('/goods/inventory/findGoodsSettleAuditList', action.payload, 'POST');
            yield put({ type: T.SET_BALANCEAUDIT_LIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//供应商审核结算价详情
export const getBalanceAuditDetail = function* () {
    yield takeEvery(T.GET_BALANCEAUDIT_DETAIL, function* requestData(action) {
        try {
            let result = yield http('/goods/inventory/findGoodsSettleInfo', action.payload, 'POST');
            yield put({ type: T.SET_BALANCEAUDIT_DETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//供应商审核结算价业务类型下拉
export const getBalanceAuditBusinessSele = function* () {
    yield takeEvery(T.GET_BALANCEAUDIT_BUSINESSSELE, function* requestData(action) {
        try {
            let result = yield http('/enum/GoodsTypeEnum', action.payload, 'POST');
            yield put({ type: T.SET_BALANCEAUDIT_BUSINESSSELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//供应商审核结算价标签下拉
export const getBalanceAuditLabelSele = function* () {
    yield takeEvery(T.GET_BALANCEAUDIT_LABELSELS, function* requestData(action) {
        try {
            let result = yield http('/goods/tag/listTagItem', action.payload, 'POST');
            yield put({ type: T.SET_BALANCEAUDIT_LABELSELS, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

//供应商审核结算价状态下拉
export const getBalanceAuditStateSele = function* () {
    yield takeEvery(T.GET_BALANCEAUDIT_STATESELE, function* requestData(action) {
        try {
            let result = yield http('/enum/GoodsSettleRecordAuditStatusEnum', action.payload, 'POST');
            yield put({ type: T.SET_BALANCEAUDIT_STATESELE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};



