/**
 * 【物流管理页】
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import LogisticsTable from './components/LogisticsTable';
import { message } from 'antd';
import { connect } from 'react-redux';
import { getLogisticsState, getLogisticsList } from '@/reducers/systemmanage/logistics/actions';
import http from '@/assets/api/http';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expressName: '',
            expressState: {},
            selectedRows: []
        };
        this.searchCache = { pageNum: 1, pageSize: 25 }; // 查询缓存
    }

    componentDidMount() {
        this.props.getLogisticsState();
        this.props.getLogisticsList(this.searchCache);
    }

    // 查询
    _searchHandle = () => {
        const { expressName, expressState } = this.state;

        this.searchCache = {
            expressName,
            expressState: expressState.code,
            pageNum: 1,
            pageSize: 25
        };
        this.props.getLogisticsList(this.searchCache);
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            expressName: '',
            expressState: {},
            selectedRows: []
        });
        this.searchCache = { pageNum: 1, pageSize: 25 };
        this.props.getLogisticsList(this.searchCache);
    }

    // 批量开通服务
    _openServiceHandle = () => {
        const { selectedRows } = this.state;
        if (selectedRows.length == 0) {
            message.error('请选择物流公司');
            return;
        }
        http('/merchantexpress/save/batch', { merchantExpressList: selectedRows }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('批量开通成功');
                this.props.getLogisticsList(this.searchCache);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 表格操作
    _tableAction = (key, type) => {
        switch (type) {
            case 'openService':
                http('/merchantexpress/save', { expressCode: key }, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('开通成功');
                        this.props.getLogisticsList(this.searchCache);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
            case 'closeService':
                http('/merchantexpress/update/state', { id: key, expressState: 0 }, 'POST').then((response) => {
                    if (response.status == 200) {
                        message.success('停止成功');
                        this.props.getLogisticsList(this.searchCache);
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
        }
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getLogisticsList(this.searchCache);
    }

    // 批量复选框
    _rowSelection = (selectedRows) => {
        this.setState({ selectedRows });
    }

    render() {
        const { expressName, expressState } = this.state;
        const { logisticsState, logisticsList } = this.props;

        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.searchInputs}>
                    <XInput
                        style={{ width: '248px' }}
                        inputStyle={{ width: '180px' }}
                        label='物流公司'
                        placeholder='请输入物流公司'
                        value={expressName}
                        bindThis={this}
                        bindName='expressName'
                    />
                    <XSelect
                        style={{ marginLeft: '20px', width: '200px' }}
                        selectStyle={{ width: '150px' }}
                        label='状态'
                        placeholder='请选择状态'
                        renderData={logisticsState}
                        dataIndex='value'
                        keyIndex='code'
                        value={expressState.value}
                        showSearch={true}
                        bindThis={this}
                        bindName='expressState'
                    />
                    <XOKButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='查询'
                        onClick={this._searchHandle}
                    />
                    <XCancelButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='重置'
                        onClick={this._resetHandle}
                    />
                </div>
                <XOKButton
                    style={{ marginTop: '10px', width: '120px' }}
                    label='批量开通服务'
                    onClick={this._openServiceHandle}
                />
                <LogisticsTable
                    renderData={logisticsList}
                    tableAction={this._tableAction}
                    paginationChange={this._paginationChange}
                    onRowSelection={this._rowSelection}
                />
            </div>
        );
    }
}

Main.propTypes = {
    getLogisticsState: PropTypes.func,
    getLogisticsList: PropTypes.func,
    logisticsState: PropTypes.array,
    logisticsList: PropTypes.object,
};

const mapStateToProps = (state) => ({
    logisticsState: state.logistics.getLogisticsState,
    logisticsList: state.logistics.getLogisticsList,
});

export default connect(mapStateToProps, { getLogisticsState, getLogisticsList })(Main);