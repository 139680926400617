import * as T from './actiontypes';

/**
 * 获取'店铺列表'数据源(saga)
 * @param {number} pageSize 表格每页显示多少条
 * @param {number} pageNum 表格当前第几页
 * @param {sting} shopName 
 * @param {sting} shopState 
 * @param {number} merchantId 商户id 
 */
export const merchantListTableSource = (dataload) => ({
    type: T.GET_MERCHANT_DATA,
    payload: {
       ...dataload,
    }
});

/**
 * 获取'店铺列表下拉列数据源'数据源(saga)
 * @param 暂无
 * 
 */
export const getmerchantselectData = () => ({
    type: T.GET_MERCHANTSELECT_DATA,
});

/**
 * 获取'新建店铺类型拉列数据源'数据源(saga)
 * @param 暂无
 * 
 */
export const getcreatinfoselectData = () => ({
    type: T.GET_CREATEINFO_DATA,
});


/**
 * 获取'编辑创建店铺'数据源(saga)
 * @param {number} id 店铺id
 * @param {number} merchantId 商户id 
 * 
 */
export const getmerchanteditor = (id,merchantId) => ({
    type: T.GET_MERCHANTEDITOR_DATA,
    payload: {
        id,
        merchantId,
    }
});


