/**
 * 【商品分类对接页】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getSupplierListTableSource } from '@/reducers/storehousefinancialmanage/supplierlist/actions';
import { getSupplierAuditSele } from '@/reducers/auditManage/supplierAudit/actions';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import StopContract from '../../modal/StopContract';
import NewWarehouse from '../../modal/NewWarehouse';

// 弹框枚举类型
const Mode = {
    STOP_CONTRACT: 'STOP_CONTRACT', // 终止合作
    NEW_WAREHOUSE: 'NEW_WAREHOUSE', // 新建仓库
};

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            supplierName: '', // 供应商名称
            operateType: '', // 经营品类
            currentMode: '', // 当前弹框
            modeParam: '', // 弹框入参
        };
        this.searchCache = { pageNum: 1, pageSize: 20 }; // 查询缓存
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
        this.props.getSupplierListTableSource(this.searchCache);
        this.props.getSupplierAuditSele();
    }

    // 新建供应商
    _newSupplierHandle = () => {
        const { history, addOneBreadcrumbPath } = this.props;
        addOneBreadcrumbPath({
            title: '新建店铺',
            path: '/home/storehousefinancialmanage/supplierlist/create'
        });
        history.push({
            pathname: '/home/storehousefinancialmanage/supplierlist/create'
        });
    }

    // 查询
    _searchHandle = () => {
        const { operateType, supplierName } = this.state;
        this.searchCache = {

            supplierName,
            categoryId: operateType.id,

            pageNum: 1,
            pageSize: 15
        };
        this.props.getSupplierListTableSource(this.searchCache);
    }

    // 重置触发 
    _ruleresetHandle = () => {
        this.setState({ supplierName: '', operateType: {} }, () => {
            this._searchHandle();
        });
    }

    // 表格操作
    _tableAction = (id, type, value) => {

        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'stop': // 终止合同
                this.setState({ modeParam: id, currentMode: Mode.STOP_CONTRACT });
                break;
            case 'newWarehouse': // 新建仓库
                this.setState({ modeParam: id, currentMode: Mode.NEW_WAREHOUSE });
                break;
            case 'newShop': // 新建店铺
                addOneBreadcrumbPath({
                    title: '新建店铺',
                    path: '/home/storehousefinancialmanage/supplierlist/newshop'
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/supplierlist/newshop/' + id
                });
                break;
            case 'detail': // 查看详情
                addOneBreadcrumbPath({
                    title: '查看详情',
                    path: '/home/storehousefinancialmanage/supplierlist/detail/'
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/supplierlist/detail/' + id
                });
                break;
        }
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
    }

    // 渲染弹框
    _renderMode = () => {
        switch (this.state.currentMode) {
            case Mode.STOP_CONTRACT:
                return <StopContract visible={true} closeMode={this._closeMode} modeParam={this.state.modeParam} />;
            case Mode.NEW_WAREHOUSE:
                return <NewWarehouse visible={true} closeMode={this._closeMode} modeParam={this.state.modeParam} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentMode: '' });
    }

    render() {
        const { supplierName, operateType } = this.state;

        return (
            <div className={styles.flexBoxContainer}>
                <div className={styles.searchCriteria}>
                    <XInput
                        style={{ width: '264px' }}
                        inputStyle={{ width: '180px' }}
                        label='供应商名称'
                        placeholder='请输入供应商名称'
                        value={supplierName}
                        bindThis={this}
                        bindName='supplierName'
                    />
                    <XSelect
                        style={{ marginLeft: '20px', width: '250px' }}
                        selectStyle={{ width: '180px' }}
                        label='经营品类'
                        placeholder='请选择品类'
                        renderData={this.props.supplierAuditSele}
                        dataIndex='categoryName'
                        keyIndex='id'
                        value={operateType.categoryName}
                        showSearch={true}
                        bindThis={this}
                        bindName='operateType'
                    />
                    <XOKButton style={{ marginLeft: '20px' }} label='查询' onClick={this._searchHandle} />
                    <XCancelButton style={{ marginLeft: '10px' }} label='重置' onClick={this._ruleresetHandle} />
                </div>
                <div className={styles.export}>
                    <XOKButton label='新建供应商' onClick={this._newSupplierHandle} />
                </div>
                <TableContent renderData={this.props.supplierListTableSource} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                {this._renderMode()}
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object, // history router
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航

    supplierListTableSource: PropTypes.object,
    getSupplierListTableSource: PropTypes.func,

    supplierAuditSele: PropTypes.array,
    getSupplierAuditSele: PropTypes.func,
};

const mapStateToProps = (state) => ({
    supplierListTableSource: state.supplierlist.supplierListTableSource,
    supplierAuditSele: state.supplierAudit.supplierAuditSele
});


export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getSupplierListTableSource, getSupplierAuditSele })(Main);