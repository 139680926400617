/**
 * 延迟下架
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import styles from './modalStyle.module.scss';
import { Modal, message, InputNumber, Alert, Typography, Row, Col } from 'antd';

const Delay = ({ closeMode, modalParams }) => {

    const [loading, setLoading] = useState(false);
    const [day, setDay] = useState(0);
    const [isBatch] = useState(modalParams instanceof Array);

    const onSubmit = () => {
        let data = {};
        let url = '/goods/wholesale/delayOffTime';
        if (isBatch) {
            data = { wholesaleGoodsIdList: modalParams };
            url = '/goods/wholesale/batchDelayOffTime';
        } else {
            data = { wholesaleGoodsId: modalParams.wholesaleGoodsId };
        }
        setLoading(true);
        http(url, { ...data, day }, 'POST')
            .then(res => {
                message.success(res.message);
                setLoading(false);
                closeMode(true);
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            });
    };

    return <Modal
        width={400}
        centered
        title='商品延长操作'
        visible={true}
        confirmLoading={loading}
        onOk={onSubmit}
        onCancel={() => closeMode(false)}
        destroyOnClose={true}
        keyboard={true}
        maskClosable={false}
        cancelText='取消'
        okText='确认'
    >
        <div className={styles.container}>
            {!isBatch && <Alert style={{ marginBottom: '20px' }} message={`当前商品剩余下架时间：${modalParams.autoOffTime}`} type='error' />}
            <Row>
                <Col span={24}>
                    <div>
                        <InputNumber
                            placeholder='请输入数字'
                            value={day}
                            style={{ width: 300, marginRight: 10 }}
                            onChange={value => setDay(value)}
                        />
                        <Typography.Text strong>天</Typography.Text>
                    </div>
                </Col>
                <Col span={24}>
                    <div>
                        <Typography.Text type='secondary'>您是否延长商品上架周期</Typography.Text>
                    </div>
                </Col>
            </Row>

        </div>
    </Modal >;
};
Delay.propTypes = {
    closeMode: PropTypes.func,
    modalParams: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ])
};
export default Delay;