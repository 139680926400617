import { createModel } from '@/store/tools';

const dataList = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };

const model = {
    namespace: 'previewcollectionmanage',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getDrawIncomeList': T('getDrawIncomeList'),
            'getDrawIncomeEnum': T('getDrawIncomeEnum'),

            'getUploadRechargeKeyList': T('getUploadRechargeKeyList'),
        },
        actions: {
            'getDrawIncomeList': A('getDrawIncomeList'),
            'getDrawIncomeEnum': A('getDrawIncomeEnum'),

            'getUploadRechargeKeyList': A('getUploadRechargeKeyList'),
        },
        sagas: {
            'getDrawIncomeList': S('getDrawIncomeList', '/settle/advances/received/item'),
            'getDrawIncomeEnum': S('getDrawIncomeEnum', '/enum/AdvancesReceivedItemTypeEnum'),

            'getUploadRechargeKeyList': S('getUploadRechargeKeyList', '/settle/advances/received/list'),
        },
        reducers: {
            'drawIncomeList': R('getDrawIncomeList', dataList),
            'drawIncomeEnum': R('getDrawIncomeEnum', []),

            'uploadRechargeKeyList': R('getUploadRechargeKeyList', [])
        }
    })
};
export default createModel(model);