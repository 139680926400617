import * as T from './actiontypes';

const plistInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0,
};

// 已分配出库单
export const supplierListTableSource = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_SUPPLIERLIST_TABLESOURCE:
            return payload.result;
        default:
            return state;
    }
};

export const shopTypes = (state = [], { type, payload }) => {
    switch (type) {
        case T.SET_SHOPTYPES:
            return payload.result;
        default:
            return state;
    }
};

export const goodsTypes = (state = [], { type, payload }) => {
    switch (type) {
        case T.SET_GOODSTYPES:
            return payload.result;
        default:
            return state;
    }
};


/**
 * 获取仓库类型
 */
export const getWarehouseType = (state = [], action) => {
    switch (action.type) {
        case T.SET_WAREHOUSE_TYPE:
            return action.payload.result;
        default:
            return state;
    }
};

/**
 * 获取地域：省市区
 */
const addressInstal = {
    province: [], // 省份
    city: [], // 城市
    street: [], // 地区
};

export const address = (state = addressInstal, action) => {
    switch (action.type) {
        case T.SET_PROVINCE:
            return { ...state, province: action.payload.result };
        case T.SET_CITY:
            return { ...state, city: action.payload.result };
        case T.SET_STREET:
            return { ...state, street: action.payload.result };
        default:
            return state;
    }
};

/**
 * 获取开票单位下拉列
 */
export const getInvoicelSelect = (state = [], action) => {
    switch (action.type) {
        case T.SET_INVOICEL_SELECT:
            return action.payload.result;
        default:
            return state;
    }
};
/**
 * 获取服务商下拉列
 */
export const listServiceProviderKetName = (state = [], action) => {
    switch (action.type) {
        case T.SET_SUPPLIER_SELECT:
            return action.payload.result;
        default:
            return state;
    }
};

// 在实体加盟中运营中心、区域公司
export const radiotypes = (state = {}, action) => {
    switch (action.type) {
        case T.SET_RADIOTYPE_DATA:
            return action.payload.result;
        default:
            return state;
    }
};
