import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableContent.module.scss';
import { Table } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

class TableContent extends Component {

    _columns = () => [
        {
            title: '集采活动',
            dataIndex: 'activityTitle',
            key: 'activityTitle',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '集采总数',
            dataIndex: 'goodsTotalNum',
            key: 'goodsTotalNum',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '集采价格(元)',
            dataIndex: 'goodsPrice',
            key: 'goodsPrice',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '交易总额(元)',
            dataIndex: 'orderTotalAmount',
            key: 'orderTotalAmount',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '结算比例区间(%)',
            dataIndex: 'rate',
            key: 'rate',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '实际结算比例(%)',
            dataIndex: 'feeRatePer',
            key: 'feeRatePer',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '结算金额',
            dataIndex: 'settleAmount',
            key: 'settleAmount',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: priceFenToYuanCell
        },
        {
            title: '活动开始时间',
            dataIndex: 'startTime',
            key: 'startTime',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '活动结束时间',
            dataIndex: 'endTime',
            key: 'endTime',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '结算时间',
            dataIndex: 'settleTime',
            key: 'settleTime',
            align: 'center',
            width: '8%',
            onCell: tooltipStyle,
            render: tooltip
        }
    ];

    _imgCell = (text) => {
        return <img src={text} width='50px' height='50px'></img>;
    }

    _takeDataSource = (data = []) => {
        return data.map((item) => {
            item.rate = (item.minRate / 10).toFixed(1) + ' ~ ' + (item.maxRate / 10).toFixed(1);
            item.feeRatePer = (item.feeRate / 10).toFixed(1);
            return item;
        });
    }

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;

        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={(record, index) => index}
                    columns={this._columns()}
                    pagination={false}
                    dataSource={this._takeDataSource(dataList)}
                    scroll={{ y: true }} />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
};

export default TableContent;