import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import styles from './index.module.scss';
import { Input, Typography, Button } from 'antd';
import model from '@/reducers/expresswaybill/authorization/model';
import moment from 'moment';

const { Text } = Typography;

const Authorization = ({
    history,
    getAuthorizationInfo,
    authorizationInfo: {
        appName = '',
        appKey = '',
        accessToken = '',
        warehouseId = '',
        expireDate = ''
    } = {}
}) => {

    useEffect(() => { getAuthorizationInfo(); }, []);

    const toAuthorization = () => {
        http('/merchant/cainiao/auth', { appkey: appKey, warehouseId }, 'POST')
            .then(res => {
                window.open(res.result);
            });
    };

    return <div className={styles.container}>
        <div className={styles.inner}>
            <div className={styles.test}>
                <Text>应用名称：</Text>
                <Text>{appName}</Text>
            </div>
            <div className={styles.test}>
                <Text>AppKey：</Text>
                <Text>{appKey}</Text>
            </div>
            <div className={styles.test}>
                <Text>Token：</Text>
                <Input disabled={true} style={{ width: '480px' }} value={accessToken} />
                <Button type='primary' style={{ marginLeft: '20px' }} onClick={toAuthorization}>授权</Button>
            </div>
            <div className={styles.test} style={{ marginTop: '30px' }}>
                <Text type='danger'>预计到期时间：</Text>
                <Text type='danger'>{expireDate && moment(expireDate).format('YYYY-MM-DD')}</Text>
            </div>
            <div className={styles.test}>
                <Text type='danger'>注意事项：</Text>
                <Text type='danger'>授权有效期为1年，授权到期后，如不重新授权 ，则会影响电子面单功能正常使用</Text>
            </div>
        </div>
    </div>;
};
Authorization.propTypes = {
    history: PropTypes.object,
    getAuthorizationInfo: PropTypes.func,
    authorizationInfo: PropTypes.object
};
export default connect(state => ({
    authorizationInfo: state.authorization.authorizationInfo
}), {
    ...model.actions
})(Authorization);