import { Typography, Divider, message } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import styles from './index.module.scss';
import model from '@/reducers/marketingmanage/invitemember/model';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import http from '@/assets/api/http';

const { Text, Title } = Typography;

class InviteMember extends Component {
    componentDidMount() {
        this.props.getMerchantShareAccountInfo();
    }
    toQRCode = () => {
        const { addOneBreadcrumbPath, history } = this.props;
        addOneBreadcrumbPath({
            title: '下载二维码',
            path: '/home/marketingmanage/invitemember/qrcode'
        });
        history.push({
            pathname: '/home/marketingmanage/invitemember/qrcode',
        });
    };
    gotoMerchant = () => {
        const { history } = this.props;
        history.push({
            pathname: '/home/marketingmanage/memberlist',
        });
    }
    download = () => {
        http('/merchant/getYlbUrl', {}, 'POST')
            .then(res => {
                // window.location.href = res.result;
                window.open(res.result);
            })
            .catch(err => {
                message.error(err.message);
            });
    };
    render() {
        const { MerchantShareAccountInfo } = this.props;
        console.log(MerchantShareAccountInfo.shareUrl, 'url');
        return <div className={styles.container}>
            <div className={styles.topInfo}>
                <Title level={4}>邀请会员</Title>
                <Title level={4}>（已邀请<Text type='danger' onClick={this.gotoMerchant}>{MerchantShareAccountInfo.merchantInviteAccountNum}</Text>人）</Title>
            </div>
            <div className={styles.imgbox}>
                <img className={styles.image} src={require('./bgimg.png')} />
                <div className={styles.topBox}>
                    {MerchantShareAccountInfo.shareUrl && <QRCode
                        className={styles.qrcodesub}
                        value={MerchantShareAccountInfo.shareUrl}
                        fgColor='#000'
                        level='Q'
                        imageSettings={{
                            src: require('@/assets/image/pig-logo.png'),
                            x: null,
                            y: null,
                            height: 40,
                            width: 40,
                            excavate: true,
                        }}
                    />}
                    <div className={styles.bottomBox}>
                        <div className={styles.btn} onClick={this.toQRCode}>预览二维码</div>
                        <div className={styles.btn} onClick={this.download}>下载易拉宝</div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
InviteMember.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getMerchantShareAccountInfo: PropTypes.func,
    MerchantShareAccountInfo: PropTypes.object
};
const mapStateToProps = (state) => ({
    MerchantShareAccountInfo: state.invitemember.MerchantShareAccountInfo,
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(InviteMember);