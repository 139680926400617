import React from 'react';
import styles from './AdvertTable.module.scss';
import { XTableSelectionCount, XPagination } from '@/components/xqxc_ui';
import { regFenToYuan } from '@/util/money';
import { Table, Badge } from 'antd';
import PropTypes from 'prop-types';

class AdvertTable extends React.Component {
    state = {
        account: '',
        selectedRowKeys: [],
    }

    _takeColumns = () => ([
        {
            title: '对账单号',
            dataIndex: 'id',
            key: 'id',
            width: 150,
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '供应商',
            dataIndex: 'targetName',
            key: 'targetName',
            width: 150,
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '对账周期',
            dataIndex: 'startDate',
            key: 'startDate',
            width: 150,
            align: 'center',
            render: (text, item) => (
                <>
                    <div>{text}</div>
                    <div>{item.endDate}</div>
                </>
            )
        },
        {
            title: '应收(元)',
            dataIndex: 'revenue',
            key: 'revenue',
            align: 'center',
            width: 100,
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '应付(元)',
            dataIndex: 'expense',
            key: 'expense',
            align: 'center',
            width: 100,
            render: (text) => (this._renderTextMoneyCell(text))
        },
        // {
        //     title: '结算金额(元)',
        //     dataIndex: 'settleAmount',
        //     key: 'settleAmount',
        //     width: 100,
        //     align: 'center',
        //     render: (text) => (this._renderTextMoneyCell(text))
        // },
        {
            title: '已付金额(元)',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
            align: 'center',
            width: 100,
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '未付金额(元)',
            dataIndex: 'unpaidAmount',
            key: 'unpaidAmount',
            align: 'center',
            width: 100,
            render: (text) => (this._renderTextMoneyCell(text))
        },

        {
            title: '关联明细单据',
            dataIndex: 'relationItems',
            key: 'relationItems',
            align: 'center',
            width: 100,
            render: (text) => (this._renderTextCell(text))
        },

        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: 150,
            align: 'center',
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '结算单生成',
            dataIndex: 'separateMethod',
            key: 'separateMethod',
            align: 'center',
            width: 100,
            render: (text) => (this._renderTextCell(text))
        },
        {
            title: '状态',
            dataIndex: 'statusDescript',
            key: 'statusDescript',
            width: 100,
            align: 'center',
            render: (text) => (this._renderStatusCell(text))
        },
        {
            title: '操作',
            dataIndex: 'c7',
            key: 'c7',
            width: 150,
            align: 'center',
            render: (text, item) => this._renderActionCell(item.id, item.statusDescript, item.luxury)
        },
    ]);
    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    };
    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderStatusCell = (text) => {
        const statusArr = ['新建', '待确认', '确认成功', '确认失败', '部分付款', '付款完成', '汇款成功'];
        // var statusText = ['未开始', '审核中', '审核成功', '审核失败'];
        const index = statusArr.findIndex(i => i === text);
        var statusBadge = ['default', 'processing', 'success', 'error', 'success', 'success', 'success'];
        if (statusArr.indexOf(text) !== -1) {
            return (
                <Badge status={statusBadge[index]} text={text} />
            );
        } else {
            return null;
        }
    }
    _renderActionCell = (key, status, provider) => {
        // status 0 未开始 1 审核中 2 审核成功 3 审核失败
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                {provider ? <div className={styles.item} onClick={() => tableAction(key, 'info', provider)}>奢批对账单</div>
                    : <div className={styles.item} onClick={() => tableAction(key, 'info', provider)}>详情</div>
                }
                {status === '待确认' && <div className={styles.item} onClick={() => tableAction(key, 'confir')}>确认对账单</div>}
            </div>
        );
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    onChange = (pagination, filters, sorter) => {
        console.log('params', pagination, filters, sorter);
    };
    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }
    render() {
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._takeColumns()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    // onChange={this.onChange}
                    scroll={{ y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

AdvertTable.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default AdvertTable;