export function DateFormat(date, format) {
    var o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        'q+': Math.floor((date.getMonth() + 3) / 3),
        'S': date.getMilliseconds() //毫秒   
    };
    if (/(y+)/.test(format))
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp('(' + k + ')').test(format))
            format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
    return format;
}
export function timeToMinute(times) {
    let result = '00:00:00';
    let hour, minute, second;
    if (times > 0) {
        hour = Math.floor(times / 3600);
        if (hour < 10) {
            hour = '0' + hour;
        }
        minute = Math.floor((times - 3600 * hour) / 60);
        if (minute < 10) {
            minute = '0' + minute;
        }
        second = Math.floor((times - 3600 * hour - 60 * minute) % 60);
        if (second < 10) {
            second = '0' + second;
        }
        result = hour + ':' + minute + ':' + second;
    }
    return result;
}
export function moneyFormat(value, opts = { fractionDigits: 2 }) {
    return typeof value === 'number' ? (value / 100).toFixed(opts.fractionDigits) : (opts.empty || '???');
}

export function getCurrentDate() {
    let date = new Date();
    let year = date.getFullYear(); //获取年
    let month = date.getMonth() + 1; //获取月
    let day = date.getDate(); //获取日
    let weekDay = date.getDay(); // 星期
    let timesStamp = date.getTime(); //getTime() 方法可返回距 1970 年 1 月 1 日之间的毫秒数。
    return {
        year,
        month,
        day,
        weekDay,
        //没用到
        timesStamp
    };
}

function myformatStr(date, separator) {
    let year;
    let month;
    let day;
    if (typeof date == 'string') {
        year = date.split('/')[0];
        month = date.split('/')[1];
        day = date.split('/')[2];
    } else if (typeof date == 'object') {
        year = date.year;
        month = date.month;
        day = date.day;
    }
    let mStr = month < 10 ? '0' + month : month + '';
    let dStr = day < 10 ? '0' + day : day + '';
    if (separator) {
        return year + separator + mStr + separator + dStr;
    }
    return year + '-' + mStr + '-' + dStr;
}

export function getDayAndWeek(type) {
    let date = new Date();
    let pre = 0;
    let next = 0;
    let startTime = '';
    let endTime = '';
    let begin;
    let over;
    switch (type) {
        case 'day':
            next++;
            break;
        case 'week':
            pre = 1 - date.getDay();
            next = 7 - date.getDay();
            break;
        default:
    }
    startTime = new Date(date.getTime() + 24 * 60 * 60 * 1000 * pre).toLocaleDateString();
    endTime = new Date(date.getTime() + 24 * 60 * 60 * 1000 * next).toLocaleDateString();
    begin = myformatStr(startTime);
    over = myformatStr(endTime);
    return { begin, over };
}

export function getMonthRange() {
    let date = getCurrentDate();
    let monthStart = {};
    let monthEnd = {};
    let begin;
    let over;
    if (date.month == 12) {
        monthEnd = { year: date.year + 1, month: date.month + 1, day: 1 };
    } else {
        monthEnd = { year: date.year, month: date.month + 1, day: 1 };
    }
    monthStart = { year: date.year, month: date.month, day: 1 };
    begin = myformatStr(monthStart);
    over = myformatStr(monthEnd);
    return { begin, over };
}

export function getYearRange() {
    let year = new Date().getFullYear();
    return { begin: year + '-01-01', over: year + '-12-31' };
}