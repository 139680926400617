/**
 *  【订单交易明细】入口页
 * */
import React from 'react';
import PropTypes from 'prop-types';
import Main from './pages/main';

const Transaction = ({ history, match }) => {
    return <Main history={history} match={match} />;
};

Transaction.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object
};

export default Transaction;