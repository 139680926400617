import React, { useEffect, useState } from 'react';
import http from '@/assets/api/http';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { braftEditorMedia } from '@/assets/config';
import BraftEditor from 'braft-editor'; // 引入编辑器组件与引入编辑器样式
import 'braft-editor/dist/index.css';

const OperationManual = () => {

    const [content, setContent] = useState(null);

    useEffect(() => {
        http('/merchant/cainiao/manual/ELECTRONIC', {}, 'POST')
            .then(res => {
                setContent(res.result.content);
            });
    }, []);

    return <div className={styles.container}>
        <BraftEditor
            contentStyle={{ height: '100%', width: '100%' }}
            controls={[]}
            readOnly={true}
            media={braftEditorMedia}
            value={BraftEditor.createEditorState(content)}
        />
    </div>;
};
OperationManual.propTypes = {

};
export default connect(state => ({

}), {

})(OperationManual);