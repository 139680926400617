export const SET_BREADCRUMB_PATHS = 'HOME/SET_BREADCRUMB_PATHS';
export const ADD_ONE_BREADCRUMB_PATH = 'HOME/ADD_ONE_BREADCRUMB_PATH';
export const KEEP_SECOND_NAV_BREADCRUMB = 'HOME/KEEP_SECOND_NAV_BREADCRUMB';
export const KEEP_THIRD_NAV_BREADCRUMB = 'HOME/KEEP_THIRD_NAV_BREADCRUMB';
export const SET_ACTIVED_CARD_PATH = 'HOME/SET_ACTIVED_CARD_PATH';
export const SET_ACTIVED_ROUTE_PATH = 'HOME/SET_ACTIVED_ROUTE_PATH';
export const SET_STRETCHOPEN = 'HOME/SET_STRETCHOPEN';
export const SET_LOADINGSTATE = 'HOME/SET_LOADINGSTATE';
export const GET_MENUS_LIST = 'HOME/GET_MENUS_LIST';
export const SET_MENUS_LIST = 'HOME/SET_MENUS_LIST';
export const SET_REGISTER_ENUM = 'HOME/SET_REGISTER_ENUM';
export const GET_REGISTER_ENUM = 'HOME/GET_REGISTER_ENUM';
export const SET_YUNSTAUTH_ENUM = 'HOME/SET_YUNSTAUTH_ENUM';
export const GET_YUNSTAUTH_ENUM = 'HOME/GET_YUNSTAUTH_ENUM';
export const GET_BANKNAME_LIST = 'HOME/GET_BANKNAME_LIST';
export const SET_BANKNAME_LIST = 'HOME/SET_BANKNAME_LIST';
export const GET_BANKPROVICE_LIST = 'HOME/GET_BANKPROVICE_LIST';
export const SET_BANKPROVICE_LIST = 'HOME/SET_BANKPROVICE_LIST';
export const GET_BANKAREA_LIST = 'HOME/GET_BANKAREA_LIST';
export const SET_BANKAREA_LIST = 'HOME/SET_BANKAREA_LIST';


