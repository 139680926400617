/**
 * 仓库管理saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import * as T from './actiontypes';
import http from '@/assets/api/http';

// 获取已分配出库单列表
export const getInvoiceList = function* () {
    yield takeEvery(T.GET_INVOICELIST, function* requestData(action) {
        try {
            let result = yield http('/merchant/order/distributionOutboundOrderList', action.payload, 'POST');
            yield put({ type: T.SET_INVOICELIST, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

// 发票管理列表
export const getPayManageTableSource = function* () {
    yield takeEvery(T.GET_PAYMANAGETABLESOURCE, function* requestData(action) {
        try {
            let result = yield http('/settle/paymentApplication/query', action.payload, 'POST');
            yield put({ type: T.SET_PAYMANAGETABLESOURCE, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};

export const getPayManageDetail = function* () {
    yield takeEvery(T.GET_PAYMANAG_EDETAIL, function* requestData(action) {
        try {
            let result = yield http('/settle/paymentApplication/get', action.payload, 'POST');
            yield put({ type: T.SET_PAYMANAG_EDETAIL, payload: result });
        } catch (e) {
            yield put({ type: 'SAGA_ERROR', payload: { error: e } });
        }
    });
};