import { createModel } from '@/store/tools';

const model = {
    namespace: 'purchasecontract',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getPurchaseContractList': T('getPurchaseContractList'),
            'getPurchaseContractDetail': T('getPurchaseContractDetail')
        },
        actions: {
            'getPurchaseContractList': A('getPurchaseContractList'),
            'getPurchaseContractDetail': A('getPurchaseContractDetail')
        },
        sagas: {
            'getPurchaseContractList': S('getPurchaseContractList', '/purchase/ctr/list'),
            'getPurchaseContractDetail': S('getPurchaseContractDetail', '/purchase/ctr/', 'POST', 'id')
        },
        reducers: {
            'purchaseContractList': R('getPurchaseContractList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
            'purchaseContractDetail': R('getPurchaseContractDetail', {})
        }
    })
};
export default createModel(model);