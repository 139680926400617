import * as T from './actiontypes';

/**
 * 商户商品列表
 */
const plistInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};

export const plist = (state = plistInital, { type, payload }) => {
    switch (type) {
        case T.SET_PRO_LIST:
            return payload.result;
        default:
            return state;
    }
};