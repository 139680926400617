/**
 *  店铺管理 - 店铺商品列表
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Table, } from 'antd';
import http from '@/assets/api/http';
import { message } from 'antd';
import { XInput, XSelect, XOKButton, XCancelButton, XPagination, } from '@/components/xqxc_ui';
import { takeDataSource, takeColumns } from './components/TableData';
import * as actions from '@/reducers/businessmanage/storelist/actions';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

// 固定店铺商品状态下拉列数据
const selectDataSource = [
    {
        id: 'PUT_ON_SALE',
        name: '上架',
    },
    {
        id: 'PULL_OFF_SHELVES',
        name: '下架',
    },
];

const Mode = {
    MER_SHELVES: 'MER_SHELVES', //上架
    MER_SHELVES_BOT: 'MER_SHELVES_BOT',//下架
    MER_DETAIL: 'MER_DETAIL',//详情
};

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],//表格选择和操作
            selectDataSource: [],//模拟下拉列数据
            currentOpenMode: '', // 当前需要打开的弹窗
            openModeParam: {}, // 当前需要打开的弹窗的入参
            isShowMore: true,//是否展开
            storeState: {}, // 点铺状态
            storeNames: '', // 商户名称
            storeNumber: '', // 商户货号
            pageSize: 20,//默认
            pageNum: 1,//默认

        };
    }

    componentDidMount() {
        this.setState({ selectDataSource, });// 初始化数据源
    }

    // 状态
    _storeStateChangeHandle = (value) => {
        this.setState({ storeState: value });
    }
    // 货号
    _storeNumberChangeHandle = (value) => {
        this.setState({ storeNumber: value });
    }
    // 名称
    _storeItemChangeHandle = (value) => {
        this.setState({ storeNames: value });
    }
    // 标签
    _storetabChangeHandle = (value) => {
        this.setState({ storetab: value });
    }
    // 类型
    _storetypeChangeHandle = (value) => {
        this.setState({ storetype: value });
    }
    // 查询触发
    _saleSearchHandle = (useCache) => {
        const { storeState, storeNames, storeNumber, pageSize, pageNum, } = this.state;
        const { merchantStoreTableSource } = this.props;
        this.searchCache = {
            shopGoodsState: storeState.id,//点铺状态  
            goodsName: storeNames,//商户名称  
            goodsNo: storeNumber,//商户货号  
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        merchantStoreTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置触发
    _saleresetHandle = () => {
        this.setState({ storeState: {}, storeNames: '', storeNumber: '', }, () => {
            KeepAlive.saveResetFunc(this._saleSearchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }


    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };


    // 表格操作功能回调
    _actionClickHandle = (id, type) => {
        const { merchantStoreTableSource, updateContent } = this.props;
        let saleGoods = new Array();
        switch (type) {
            case Mode.MER_SHELVES://上架
                console.log('MER_SHELVES');
                saleGoods.push(id.toString());
                http('/goods/shopGoods/putOnSaleGoods', { goodsIds: saleGoods }, 'POST').then(() => {
                    message.success('上架成功');
                    merchantStoreTableSource(this.searchCache);// 重新渲染更新列表
                }).catch((res) => {
                    if (res.code == '500') {
                        message.error(res.message);
                    }
                });

                break;
            case Mode.MER_SHELVES_BOT://下架
                console.log('MER_SHELVES_BOT');
                saleGoods.push(id);
                http('/goods/shopGoods/pullOffShelvesGoods', { goodsIds: saleGoods }, 'POST').then(() => {
                    message.success('下架成功');
                    merchantStoreTableSource(this.searchCache);// 重新渲染更新列表
                }).catch((res) => {
                    if (res.code == '500') {
                        message.error(res.message);
                    }
                });
                break;
            case Mode.MER_DETAIL://查看详情
                updateContent('MER_DETAIL', id);
                break;
        }

    }

    // 关闭弹框
    _closeMode = () => {
        this.setState({ currentOpenMode: '' });
    }
    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    }
    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.merchantStoreTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    render() {
        const { tableSource, paginations } = this.props;
        const { storeNames, storeNumber, storeState, selectDataSource, } = this.state;
        const { selectedRowKeys } = this.state;
        this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._saleSearchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.pro_commont}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='商品名称'
                            placeholder='请输入'
                            onChange={this._storeItemChangeHandle}
                            value={storeNames}
                        />
                        <XInput
                            style={{ marginLeft: '20px' }}
                            inputStyle={{ width: '200px' }}
                            label='商品货号'
                            placeholder='请输入'
                            onChange={this._storeNumberChangeHandle}
                            value={storeNumber}
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '200px' }}
                            label='店铺商品状态'
                            placeholder='请选择'
                            renderData={selectDataSource}
                            onChange={this._storeStateChangeHandle}
                            dataIndex='name'
                            keyIndex='id'
                            value={storeState.name}
                            showSearch={true}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._saleSearchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._saleresetHandle}
                        />
                    </div>
                    <div className={`${styles.tableContainer}`}>
                        {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                            // rowSelection={this._rowSelection}
                            rowKey={(record, index) => index}
                            columns={takeColumns(this._actionClickHandle)}
                            dataSource={takeDataSource(tableSource)}
                            pagination={false}
                            scroll={{ y: true }}
                        />
                        <XPagination
                            resultTotal={paginations.resultTotal}
                            pageSize={paginations.pageSize}
                            pageNum={paginations.pageNum}
                            onChange={this._paginationChange}
                        />
                    </div>
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    updateContent: PropTypes.func, // 路由跳转
    history: PropTypes.object, // 路由history
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,

    merchantStoreTableSource: PropTypes.func,// 通过Saga异步请求列表查询方法Action
    tableSource: PropTypes.array,
    paginations: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        tableSource: state.merstorelist.merStoreDate.tableSource, // 表格数据源
        paginations: state.merstorelist.merStoreDate.paginations, // 分页数据
    };
};


export default connect(mapStateToProps, actions)(Main);