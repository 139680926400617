import { createModel, createSaga, createReducer } from '@/store/tools';

const dataList = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };

const model = {
    namespace: 'reconciliationdata',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getReconciliationData': T('getReconciliationData'),
            'getReconciliationDetail': T('getReconciliationDetail'),
            'getReconciliationNewData': T('getReconciliationNewData'),
            'getReconciliationDailyData': T('getReconciliationDailyData'),
            'getwithdrawListData': T('getwithdrawListData'),
            'getAccountingStatusEnum': T('getAccountingStatusEnum'),
            'getFinancialTypeEnum': T('getFinancialTypeEnum'),
            'getYunstPayTypeEnum': T('getYunstPayTypeEnum'), 
            'getOutAccountWithdrawStatusEnum': T('getOutAccountWithdrawStatusEnum'), 
            'getAdvanceTableData': T('getAdvanceTableData'),
            'getAdvanceDetailData': T('getAdvanceDetailData'),
            'getAdvanceDetailList': T('getAdvanceDetailList'),
        },
        actions: {
            'getReconciliationData': A('getReconciliationData'),
            'getReconciliationDetail': A('getReconciliationDetail'),
            'getReconciliationNewData': A('getReconciliationNewData'),
            'getReconciliationDailyData': A('getReconciliationDailyData'),
            'getwithdrawListData': A('getwithdrawListData'),
            'getAccountingStatusEnum': A('getAccountingStatusEnum'),
            'getFinancialTypeEnum': A('getFinancialTypeEnum'),
            'getYunstPayTypeEnum': A('getYunstPayTypeEnum'),
            'getOutAccountWithdrawStatusEnum': A('getOutAccountWithdrawStatusEnum'),
            'getAdvanceTableData': A('getAdvanceTableData'),
            'getAdvanceDetailData': A('getAdvanceDetailData'),
            'getAdvanceDetailList': A('getAdvanceDetailList'),
        },
        sagas: {
            'getReconciliationData': S('getReconciliationData', '/reconciliation/list'), //列表
            'getReconciliationDetail': S('getReconciliationDetail', '/merchant/reconciliation/detail/list'), //明细
            'getReconciliationNewData': S('getReconciliationNewData', '/reconciliation/new/record'), //新数据
            'getReconciliationDailyData': S('getReconciliationDailyData', '/merchant/reconciliation/list/settlement'), //日结对账
            'getwithdrawListData': S('getwithdrawListData', '/merchant/withdraw/yums/withdrawList'), //商户提现记录
            'getAccountingStatusEnum': S('getAccountingStatusEnum', '/enum/AccountingStatusEnum'), //核算状态
            'getFinancialTypeEnum': S('getFinancialTypeEnum', '/enum/FinancialTypeEnum'), //财务类型枚举
            'getYunstPayTypeEnum': S('getYunstPayTypeEnum', '/enum/YunstPayTypeEnum'), //支付方式枚举
            'getOutAccountWithdrawStatusEnum': S('getOutAccountWithdrawStatusEnum', '/enum/OutAccountWithdrawStatusEnum'), //状态枚举 
            'getAdvanceTableData': S('getAdvanceTableData', '/settle/reconciliationInfo/merchant/prepaid/reconciliationList'),
            'getAdvanceDetailData': S('getAdvanceDetailData', '/settle/reconciliationInfo/merchant/prepaid/reconciliationDetail'),
            'getAdvanceDetailList': S('getAdvanceDetailList', '/v2/merchant/reconciliation/prepaidReconciliationDetail'),
        },
        reducers: {
            'ReconciliationData': R('getReconciliationData', dataList),
            'ReconciliationDetail': R('getReconciliationDetail', {}),
            'ReconciliationNewData':  R('getReconciliationNewData', dataList),
            'ReconciliationDailyData':  R('getReconciliationDailyData', dataList),
            'withdrawListData':  R('getwithdrawListData', dataList),
            'AccountingStatusEnum':  R('getAccountingStatusEnum', []),
            'FinancialTypeEnum':  R('getFinancialTypeEnum', []),
            'YunstPayTypeEnum':  R('getYunstPayTypeEnum', []),
            'OutAccountWithdrawStatusEnum':  R('getOutAccountWithdrawStatusEnum', []),
            'advanceTableData': R('getAdvanceTableData', dataList),
            'advanceDetailData': R('getAdvanceDetailData', {}),
            'advanceDetailList': R('getAdvanceDetailList', dataList),
        }
    })
};
export default createModel(model);