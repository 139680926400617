/**
 * 集采管理
 */
import React from 'react';
import styles from './index.module.scss';
import { XOKButton, XCancelButton, XInput, XSelect } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message, } from 'antd';
import VirtualSalesModal from '../../modal/VirtualSalesModal';
import AddSalesModal from '../../modal/AddSalesModal';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import { getPurchasingList, } from '@/reducers/marketingmanage/purchasing/actions';

const Mode = {
    DELETE: 'DELETE',//详情
};
const purchaseRender = [//自购
    { code: '1', name: '是' },
    { code: '0', name: '否' }
];

class Marketing extends React.Component {

    state = {
        purchaseName: '',//集采名称  
        shopName: '',//商品名称  
        shopNo: '',//商品货号  
        purchase: {},//自购
        currentOpenMode: '', // 当前需要打开的弹窗
        openModeParam: {}, // 当前需要打开的弹窗的入参
    }


    componentDidMount() { }

    // 新建集采
    _createStudioRoom = () => {
        this.props.updateContent('CREAT');
    }
    // 查询触发
    _searchHandle = (useCache) => {
        const { purchaseName, shopName, shopNo, purchase, pageNum, pageSize } = this.state;
        this.searchCache = {
            activityTitle: purchaseName,//集采名称  
            goodsName: shopName,//商品名称  
            goodsNo: shopNo,//商品货号  
            hasLimit: purchase.code,//自购  
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getPurchasingList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 重置触发
    _resetHandle = () => {
        this.setState({ purchaseName: '', shopName: '', shopNo: '', purchase: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }
    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getPurchasingList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 表格操作  
    _tableAction = (id, type, value) => {
        switch (type) {
            case 'detail':
                this.props.updateContent(Mode.DELETE, id);
                break;
            case 'switch':
                http('/marketing/activityMerchantInfo/updateState', { id, state: value === false ? '0' : '1' }, 'POST').then(() => {
                    message.success('状态修改成功');
                    this.props.getPurchasingList(this.searchCache);
                }).catch((rej) => {
                    message.warn(rej.message);
                });
                break;
            case 'virtualSales'://虚拟销量规则
                this.setState({
                    currentOpenMode: type,
                    openModeParam: value,
                });
                break;
            case 'addSales'://增加销量
                http('/marketing/activityMerchantInfo/showSalesVolume', {
                    activityId: id
                }, 'POST').then((res) => {
                    this.setState({
                        currentOpenMode: type,
                        openModeParam: { ...res.result, ...value },
                    });
                }).catch((rej) => {
                    message.warn(rej.message);
                });
                break;
            default:
                return null;
        }
    }

    //弹框
    renderModal = () => {
        switch (this.state.currentOpenMode) {
            case 'virtualSales'://虚拟销量规则
                return <VirtualSalesModal title='虚拟销量规则' data={this.state.openModeParam} visible={true} closeMode={this._closeMode} />;
            case 'addSales'://增加销量
                return <AddSalesModal title='增加销量' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹框
    _closeMode = (status) => {
        this.setState({ currentOpenMode: '' });
        if (status) {
            this.props.getPurchasingList(this.searchCache);
        }
    }

    render() {
        const { purchaseName, shopName, shopNo, purchase } = this.state;
        const { paginations, tableSource, } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.search_box}>
                        <XInput
                            style={{ marginRight: '20px' }}
                            inputStyle={{ width: '150px' }}
                            label='集采名称'
                            placeholder='请输入集采名称'
                            value={purchaseName}
                            bindThis={this}
                            bindName='purchaseName'
                        />
                        <XInput
                            style={{ marginRight: '20px' }}
                            inputStyle={{ width: '150px' }}
                            label='商品名称'
                            placeholder='请输入商品名称'
                            value={shopName}
                            bindThis={this}
                            bindName='shopName'
                        />
                        <XInput
                            style={{ marginRight: '20px' }}
                            inputStyle={{ width: '150px' }}
                            label='商品货号'
                            placeholder='请输入商品货号'
                            value={shopNo}
                            bindThis={this}
                            bindName='shopNo'
                        />
                        <XSelect
                            selectStyle={{ width: '150px' }}
                            label='自购'
                            placeholder='请选择'
                            renderData={purchaseRender}
                            dataIndex='name'
                            keyIndex='code'
                            value={purchase.name}
                            bindThis={this}
                            bindName='purchase'
                            showSearch={true}
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.operate_box}>
                        <XOKButton style={{ width: '140px' }} label='新增集采' onClick={this._createStudioRoom} />
                    </div>
                    <TableContent renderData={tableSource} tableAction={this._tableAction} pagiNation={paginations} paginationChange={this._paginationChange} />
                    {this.renderModal()}
                </div>
            </KeepAlive>
        );
    }
}

Marketing.propTypes = {
    history: PropTypes.object, // 路由history对象
    updateContent: PropTypes.func, // 路由跳转
    addOneBreadcrumbPath: PropTypes.func,
    keepSecondNavBreadcrumb: PropTypes.func,

    getPurchasingList: PropTypes.func,// 通过Saga异步请求列表查询方法Action
    tableSource: PropTypes.array, // 表格数据源
    paginations: PropTypes.object, // 分页数据
};

const mapStateToProps = (state) => {
    return {
        tableSource: state.purchasing.purchasingDate.tableSource,
        paginations: state.purchasing.purchasingDate.paginations,
    };
};

export default connect(mapStateToProps, { getPurchasingList, })(Marketing);
