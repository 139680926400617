/**
 * 下载奢批商品弹框 
 * */
import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import styles from './DownloadNrModal.module.scss';
import { XInput, XSelect, XCancelButton, XDatePicker } from '@/components/xqxc_ui';

const orderStateSelect = [{ id: 1, name: '微信' }, { id: 2, name: '支付宝' }];

class DownloadNrModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            confirmLoading: false,
            orderStartTime: null, // 更新开始日期
            orderEndTime: null, // 更新结束日期
            skuid: '', // 商品规格ID
        };
    }

    // 确认
    _handleOk = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode('ok');
    }

    // 取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode('cancel');
    }

    // 重置
    _resetHandle = () => {

    }

    render() {
        const { visible, confirmLoading, orderStartTime, orderEndTime, skuid } = this.state;

        return (
            <Modal
                width={554}
                centered
                title='请选择下载奢批商品的条件'
                visible={visible}
                confirmLoading={confirmLoading}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                cancelText='取消'
                okText='下载'
            >
                <div className={styles.container}>
                    <div className={styles.searchCriteria1}>
                        <XDatePicker
                            style={{ width: '264px' }}
                            label='更新时间'
                            value={orderStartTime}
                            bindThis={this}
                            bindName='orderStartTime'
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            label=''
                            value={orderEndTime}
                            bindThis={this}
                            bindName='orderEndTime'
                        />
                    </div>
                    <div className={styles.searchCriteria2}>
                        <XInput
                            style={{ width: '250px' }}
                            inputStyle={{ width: '180px' }}
                            label='商品规格ID'
                            placeholder='请输入商品规格ID'
                            value={skuid}
                            bindThis={this}
                            bindName='skuid'
                        />
                        <XCancelButton style={{ marginLeft: '20px' }} label='重置' onClick={this._resetHandle} />
                    </div>
                </div>
            </Modal>
        );
    }
}

DownloadNrModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    closeMode: PropTypes.func.isRequired,
    modeParam: PropTypes.string,
};

export default DownloadNrModal;