import * as S from './actiontypes';

// 存储仓库列表信息
export const getStoreHouseList = (value) => ({
    type: S.GET_STOREHOUSE_LIST,
    payload: value
});
// 仓库类型
export const getStoreHouseType = (value) => ({
    type: S.GET_STOREHOUSE_TYPE,
    payload: value
});
// 详情
export const getStoreHouseDetail = (value) => ({
    type: S.GET_STOREHOUSE_DETAIL,
    payload: value
});