export const GET_MERSTORE_DATA = 'MERSTORELIST/GET_MERSTORE_DATA'; // 异步获取'店铺商品列表'数据源
export const SET_MERSTORE_DATA = 'MERSTORELIST/SET_MERSTORE_DATA'; // 将'店铺商品列表'存储至store.state中

//getcreatinfoselectData  

export const GET_MERCHANTEDITOR_DATA = 'MERCHANTLIST/GET_MERCHANTEDITOR_DATA'; // 异步获取'店铺编辑'数据源
export const SET_MERCHANTEDITOR_DATA = 'MERCHANTLIST/SET_MERCHANTEDITOR_DATA'; // 将'店铺编辑'存储至store.state中


export const GET_CREATEINFO_DATA = 'MERCHANTLIST/GET_CREATEINFO_DATA'; // 异步获取'新建店铺类型'数据源
export const SET_CREATEINFO_DATA = 'MERCHANTLIST/SET_CREATEINFO_DATA'; // 将'新建店铺类型'存储至store.state中

export const GET_MERCHANTSELECT_DATA = 'MERCHANTLIST/GET_MERCHANTSELECT_DATA'; // 异步获取'店铺列表下拉选项'数据源
export const SET_MERCHANTSELECT_DATA = 'MERCHANTLIST/SET_MERCHANTSELECT_DATA'; // 将'店铺列表下拉选项'存储至store.state中
