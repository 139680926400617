/**
 * 【售后地址管理】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, Radio, Form, Button, Select, message } from 'antd';
import { XCancelButton } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

class addAdvertContent extends React.Component {
    state = {
        province: [],
        city: [],
        county: [],
        areaObj: {},
        validateflag: true,
    }

    componentDidMount() {
        this._getAreahandle(100000, 'province');
        this._getAreaDetail();
    }
    // 获取售后地址
    _getAreaDetail = () => {
        http('/merchant/after/info', {}, 'GET')
            .then((obj) => {
                this.setState({
                    areaObj: obj.result || {},
                });
                if (obj.result.id) {
                    this._getAreahandle(obj.result.provinceId, 'city');
                    this._getAreahandle(obj.result.cityId, 'county');
                }
            }).catch((reject) => {
                message.error(reject.message);
            });
    }
    // 获取区域
    _getAreahandle = (parentId, type) => {
        http(`/merchant/area/listAreaByParentId/${parentId}`, {}, 'POST')
            .then((obj) => {
                var res = obj.result || [];
                if (type === 'province') {
                    this.setState({
                        province: res || [],
                    });
                } else if (type === 'city') {
                    this.setState({
                        city: res || [],
                    });
                } else if (type === 'county') {
                    this.setState({
                        county: res || [],
                    });
                }
            }).catch((reject) => {
                message.error(reject.message);
            });
    }
    handleProvinceChange = id => {
        this._getAreahandle(id, 'city');
        // this.setState({
        //     oneCity: id,
        // });
        this.setState({ validateflag: false });
        this.props.form.setFieldsValue({
            cityId: undefined,
            areaId: undefined,
        });
    };

    onSecondCityChange = id => {
        this._getAreahandle(id, 'county');
        // this.setState({
        //     secondCity: id,
        // });
        this.setState({ validateflag: false });
        this.props.form.setFieldsValue({
            areaId: undefined,
        });
    };
    onThreeCityChange = id => {
        this.setState({ validateflag: true });
    }
    // 提交
    _handleSubmit = e => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                console.log('提交', fieldsValue);

                const { areaObj } = this.state;
                if (areaObj && areaObj.id) {
                    const param = {
                        id: areaObj.id,
                        ...fieldsValue,
                    };
                    http('/merchant/after/update', param, 'POST')
                        .then(() => {
                            message.success('修改成功!');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });

                } else {
                    const param = {
                        ...fieldsValue,
                    };
                    http('/merchant/after/save', param, 'POST')
                        .then(() => {
                            message.success('新建成功!');
                        }).catch((reject) => {
                            message.error(reject.message);
                        });

                }

            }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { province, city, county, areaObj, validateflag } = this.state;
        return (
            <div>
                <Form {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                    <Form.Item
                        colon={false}
                        htmlFor='recipient'
                        label={<span>收件人</span>}
                    >
                        {getFieldDecorator('recipient', {
                            rules: [{ required: true, message: '请输入' }],
                            initialValue: areaObj.recipient,
                        })(<Input placeholder="请输入" style={{ width: 520 }} />)}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='recipientPhone'
                        label={<span>收件电话</span>}
                    >
                        {getFieldDecorator('recipientPhone', {
                            rules: [{ required: true, message: '请输入' }],
                            initialValue: areaObj.recipientPhone,
                        })(
                            <Input placeholder="请输入" style={{ width: 520 }} />
                        )}
                    </Form.Item>
                    <Form.Item
                        validateStatus={validateflag ? 'success' : 'error'}
                        label={<span>收件地址</span>}
                        style={{ textAlign: 'left' }}
                        help={validateflag ? '' : '请选择'}
                    >
                        {getFieldDecorator('provinceId', {
                            rules: [{ required: true, message: '请选择' }],
                            initialValue: areaObj.provinceId,
                        })(
                            <Select
                                // defaultValue={provinceData[0]}
                                // value={this.state.oneCity}
                                placeholder='请选择'
                                showSearch
                                style={{ width: 160, marginRight: 19 }}
                                onChange={this.handleProvinceChange}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {province.map(province => (
                                    <Option value={province.id} key={province.id}>{province.areaName}</Option>
                                ))}
                            </Select>
                        )}
                        {getFieldDecorator('cityId', {
                            rules: [{ required: true, message: '请选择' }],
                            initialValue: areaObj.cityId,
                        })(
                            <Select
                                style={{ width: 160, marginRight: 20 }}
                                showSearch
                                placeholder='请选择'
                                onChange={this.onSecondCityChange}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {city.map(city => (
                                    <Option key={city.id} value={city.id}>{city.areaName}</Option>
                                ))}
                            </Select>
                        )}
                        {getFieldDecorator('areaId', {
                            rules: [{ required: true, message: '请选择' }],
                            initialValue: areaObj.areaId,
                        })(
                            <Select
                                style={{ width: 160 }}
                                showSearch
                                placeholder='请选择'
                                onChange={this.onThreeCityChange}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {county.map(county => (
                                    <Option key={county.id} value={county.id}>{county.areaName}</Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='recipientAddr'
                        label={<span>详细地址</span>}
                    >
                        {getFieldDecorator('recipientAddr', {
                            rules: [{ required: true, message: '请输入' }],
                            initialValue: areaObj.recipientAddr,
                        })(
                            <Input placeholder="请输入" style={{ width: 520 }} />
                        )}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='zipCode'
                        label={<span>邮编</span>}
                    >
                        {getFieldDecorator('zipCode', {
                            initialValue: areaObj.zipCode,
                            // rules: [{ required: true, message: '请输入' }],
                        })(
                            <Input placeholder="请输入" style={{ width: 520 }} />
                        )}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        htmlFor='remark'
                        label={<span>售后说明</span>}
                    >
                        {getFieldDecorator('remark', {
                            initialValue: areaObj.remark,
                            // rules: [{ required: true, message: '请输入' }],
                        })(
                            <Input placeholder="请输入" style={{ width: 520 }} />
                        )}
                    </Form.Item>
                    <FormItem>
                        <Button style={{ marginLeft: '18%', marginRight: '50px', width: '120px', display: 'inline-block' }} type="primary" htmlType="submit">保存</Button>
                        {/* <XCancelButton style={{ width: '120px', display: 'inline-block' }} label='返回' onClick={this._backHandle} /> */}
                    </FormItem>
                </Form>
            </div>
        );
    }
}
addAdvertContent.propTypes = {
    data: PropTypes.object, // 弹框入参
    history: PropTypes.object, // 路由history对象
    form: PropTypes.object.isRequired, // 校验
    match: PropTypes.object, // 路由match对象
    curTabID: PropTypes.number,
};
const ForgotProducDetails = Form.create({ name: 'create-form' })(addAdvertContent);// 校验
// const mapStateToProps = (state) => ({
// });
export default connect(null, {})(ForgotProducDetails);