/**
 * 【第三方出库单页】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isYG } from '@/assets/js/authType';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { XInput, XOKButton, XCancelButton, XDatePicker, showConfirm } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { SpOrderState } from '@/vcomps';
import { getSpDeliverList } from '@/reducers/delivermanage/spdeliver/actions';
import { message } from 'antd';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orderSn: '', // 奢批订单号
            splitSn: '', // 出库单号
            startPlaceTime: null, // 下单开始日期
            endPlaceTime: null, // 下单结束日期
            orderState: {}, // 奢批订单状态
            shippingCode: '', // 物流单号
            outSn: '', // 订单号
            deliverStartTime: null, // 发货开始日期
            deliverEndTime: null, // 发货结束日期
            selectedRows: [], // 复选框
            rows: [],
        };
        this.searchCache = { pageNum: 1, pageSize: 20 }; // 查询缓存
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb();
    }

    // 导出
    _exportHandle = () => {

    }

    // 推送奢批
    _pspHandle = () => {
        const { selectedRows } = this.state;
        http('/merchant/luxury/pushLuxury', { saleOuterIdList: selectedRows }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('推送成功');
                this.props.getSpDeliverList(this.searchCache);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _searchOrderState = () => {
        const { selectedRows } = this.state;
        console.log(selectedRows);
        http('/merchant/luxury/queryLuxuryOrder', { saleOuterIdList: selectedRows }, 'POST').then((response) => {
            if (response.status == 200) {
                message.success('推送成功');
                this.props.getSpDeliverList(this.searchCache);
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, orderSn, splitSn, startPlaceTime, endPlaceTime, orderState, shippingCode, outSn, deliverStartTime, deliverEndTime } = this.state;
        this.searchCache = {
            orderSn,
            splitSn,
            startPlaceTime: startPlaceTime ? startPlaceTime.format('YYYY-MM-DD HH:mm:ss') : '',
            endPlaceTime: endPlaceTime ? endPlaceTime.format('YYYY-MM-DD HH:mm:ss') : '',
            orderState: orderState.code,
            shippingCode,
            outSn,
            deliverStartTime: deliverStartTime ? deliverStartTime.format('YYYY-MM-DD HH:mm:ss') : '',
            deliverEndTime: deliverEndTime ? deliverEndTime.format('YYYY-MM-DD HH:mm:ss') : '',
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getSpDeliverList(this.searchCache); // 发起请求
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            orderSn: '', // 奢批订单号
            splitSn: '', // 出库单号
            startPlaceTime: null, // 下单开始日期
            endPlaceTime: null, // 下单结束日期
            orderState: {}, // 奢批订单状态
            shippingCode: '', // 物流单号
            outSn: '', // 订单号
            deliverStartTime: null, // 发货开始日期
            deliverEndTime: null, // 发货结束日期
            selectedRows: [], // 复选框
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格操作
    _tableAction = (key, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'detail':
                if (isYG()) {
                    addOneBreadcrumbPath({
                        title: '出库单详情',
                        path: '/home/delivermanage/spdeliver/skuinfo/'
                    });
                    history.push({
                        pathname: '/home/delivermanage/spdeliver/info/' + key
                    });
                } else {
                    addOneBreadcrumbPath({
                        title: '出库单详情',
                        path: '/home/delivermanage/spdeliver/detail/'
                    });
                    history.push({
                        pathname: '/home/delivermanage/spdeliver/detail/' + key
                    });
                }

                break;
            case 'send':
                showConfirm('是否确定重新推送？', '', () => {
                    http(`/merchant/luxury/pushLuxury/${key}`, {}, 'GET')
                        .then(() => {
                            message.success('推送成功!');
                            this._searchHandle();
                        }).catch((reject) => {
                            message.error(reject.message);
                        });
                });
                break;
        }
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize });
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getSpDeliverList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    // 批量复选框
    _rowSelection = (selectedRows, rows) => {
        this.setState({ selectedRows, rows });
    }
    // 发票申请
    invoiceHandle = () => {
        const { selectedRows, rows } = this.state;
        if (selectedRows.length > 0) {
            var index = -1;
            index = rows.findIndex(i => i.code !== 'CONFIRMED');
            if (index !== -1) {
                message.warn('已确认的出库单才可申请发票!');
            } else {
                http('/merchant/luxury/addInvoice', { saleOuterIdList: selectedRows }, 'POST')
                    .then(() => {
                        message.success('推送成功!');
                        this._searchHandle('useCache');
                    }).catch((reject) => {
                        message.error(reject.message);
                    });
            }

        } else {
            message.warn('请选择需发票申请的项!');
            return false;
        }


    }
    render() {
        const { orderSn, splitSn, startPlaceTime, endPlaceTime, orderState, shippingCode, outSn, deliverStartTime, deliverEndTime } = this.state;
        const { spDeliverList } = this.props;
        console.log(spDeliverList);

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.searchCriteria1}>
                        <XInput
                            style={{ width: '265px' }}
                            inputStyle={{ width: '180px' }}
                            label='供应商订单号'
                            placeholder='请输入供应商订单号'
                            value={orderSn}
                            bindThis={this}
                            bindName='orderSn'
                        />
                        <XInput
                            style={{ width: '250px', marginLeft: '20px' }}
                            inputStyle={{ width: '180px' }}
                            label='出库单号'
                            placeholder='请输入出库单号'
                            value={splitSn}
                            bindThis={this}
                            bindName='splitSn'
                        />
                        <XDatePicker
                            style={{ width: '264px', marginLeft: '20px' }}
                            label='下单时间'
                            value={startPlaceTime}
                            bindThis={this}
                            bindName='startPlaceTime'
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            label=''
                            value={endPlaceTime}
                            bindThis={this}
                            bindName='endPlaceTime'
                        />
                        <SpOrderState
                            style={{ marginLeft: '20px', width: '282px' }}
                            selectStyle={{ width: '180px' }}
                            label='订单状态'
                            placeholder='请选择订单状态'
                            bindThis={this}
                            bindName='orderState'
                            value={orderState}
                        />
                    </div>
                    <div className={styles.searchCriteria2}>
                        <XInput
                            style={{ width: '279px', }}
                            inputStyle={{ width: '180px', marginLeft: '29px' }}
                            label='物流单号'
                            placeholder='请输入物流单号'
                            value={shippingCode}
                            bindThis={this}
                            bindName='shippingCode'
                        />
                        <XInput
                            style={{ width: '236px', marginLeft: '20px' }}
                            inputStyle={{ width: '180px' }}
                            label='订单号'
                            placeholder='请输入订单号'
                            value={outSn}
                            bindThis={this}
                            bindName='outSn'
                        />
                        <XDatePicker
                            style={{ width: '264px', marginLeft: '20px' }}
                            label='发货时间'
                            value={deliverStartTime}
                            bindThis={this}
                            bindName='deliverStartTime'
                        />
                        <div className={styles.timeLine} />
                        <XDatePicker
                            label=''
                            value={deliverEndTime}
                            bindThis={this}
                            bindName='deliverEndTime'
                        />
                        <XOKButton style={{ marginLeft: '20px' }} label='查询' onClick={this._searchHandle} />
                        <XCancelButton style={{ marginLeft: '10px' }} label='重置' onClick={this._resetHandle} />
                    </div>
                    <div className={styles.export}>
                        <XOKButton label='推送给供应商' onClick={this._pspHandle} />
                        <XOKButton label='订单状态查询' style={{ marginLeft: '20px' }} onClick={this._searchOrderState} />
                        {isYG() &&
                            <XOKButton label='发票申请' style={{ marginLeft: '20px' }} onClick={this.invoiceHandle} />
                        }
                    </div>
                    <TableContent
                        renderData={spDeliverList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                        onRowSelection={this._rowSelection}
                    />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getSpDeliverList: PropTypes.func, // 获取奢批单列表
    spDeliverList: PropTypes.object, // 奢批单列表
};

const mapStateToProps = (state) => ({
    spDeliverList: state.spdeliver.getSpDeliverList
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getSpDeliverList })(Main);