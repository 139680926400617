/**
 * 审核
 */
import React from 'react';
import { Modal, Radio, message } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import styles from './AcceptModal.module.scss';
import { getProcessDetail } from '@/reducers/salemanage/saleorder/actions';
import { regFenToYuan } from '@/util/money';

const RadioGroup = Radio.Group;

class AcceptModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      visible: this.props.visible,
      data: this.props.data,
      status: '',
      nodeContent: '',
    };
  }

  componentDidMount() {
    const { data: { id, sellerId } } = this.state;
    this.props.getProcessDetail({ orderSaleId: id, sellerId });
  }
  _handleOk = () => {
    const { status, data: { id, sellerId, version } } = this.state;
    const { processDetail: { salesStatus } } = this.props;
    const param = {
      salesStatus: status,
    //   nodeContent,
      orderSaleId: id,
      sellerId,
      version
    };
    var url = '';
    if (salesStatus === 'WAIT_GOODS') {
       url = '/merchant/order/saleAfterAuditLogistics';
    } else {
        url = '/merchant/order/afterSaleAudit';
    }
    
    http(url, param, 'POST')
      .then(() => {
        message.success('审核成功!');
        this.props.refresh();
        this.props.closeMode();
        this.setState({
            visible: false,
          });
      }).catch((reject) => {
        message.error(reject.message);
      });
  }

  _handleCancel = () => {
    this.setState({
      visible: false,
    });
    this.props.closeMode();
  }

  _radioValueChangeHandle = (e) => {
    this.setState({ status: e.target.value });
  }

  _renderImages = (attachList) => {
    return (
      attachList.map((item, index) => {
        return (
          <img key={index} src={item} alt='凭证图片' />
        );

      })
    );
  }
  _areaChangeHandle = (e) => {
    this.setState({ nodeContent: e.target.value });
  }

    render() {
        const { visible, confirmLoading, title, status } = this.state;
        const { processDetail, processDetail: { attachList = [] } } = this.props;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                <div className={styles.container}>
                    <div className={styles.top}>
                        <span>售后类型</span>
                        <p>{processDetail.salesType}</p>
                    </div>
                    <div className={styles.top}>
                        <span>数&nbsp;&nbsp;量</span>
                        <p>{processDetail.goodsCount}</p>
                    </div>
                    <div className={styles.top}>
                        <span>退款金额</span>
                        <p>{regFenToYuan(processDetail.refundAmount)}元</p>
                    </div>
                    <div className={styles.top}>
                        <span>售后原因</span>
                        <p>{processDetail.salesReason}</p>
                    </div>
                    <div className={styles.top}>
                        <span>售后说明</span>
                        <p>{processDetail.salesExplain}</p>
                    </div>
                    <div className={styles.items}>
                        <p>图片凭证</p>
                        <div className={styles.imgs}>
                            {this._renderImages(attachList)}
                        </div>
                    </div>
                    <div className={styles.radio}>
                        <div className={styles.label}>审核状态</div>
                        { processDetail.salesType === 'ONLY_REFUND' ?
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._radioValueChangeHandle} value={status}>
                            <Radio value={'AGREE_REFUND'}>同意</Radio>
                            <Radio value={'UNAGREE_REFUND'}>拒绝</Radio>
                        </RadioGroup> : processDetail.salesType === 'REFUND' ? 
                        (processDetail.salesStatus === 'WAIT_GOODS' ?
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._radioValueChangeHandle} value={status}>
                        <Radio value={'RECEIVING_MONEY'}>同意</Radio>
                        <Radio value={'UNAGREE_GOODSMON'}>拒绝</Radio>
                    </RadioGroup> : <RadioGroup style={{ marginLeft: '12px' }} onChange={this._radioValueChangeHandle} value={status}>
                        <Radio value={'WAITING_GOODS'}>同意</Radio>
                        <Radio value={'UNAGREE_GOODSMON'}>拒绝</Radio>
                        </RadioGroup>) : processDetail.salesType === 'EXCHANGE_GOODS' ?
                        (processDetail.salesStatus === 'WAIT_GOODS' ? 
                        <RadioGroup style={{ marginLeft: '12px' }} onChange={this._radioValueChangeHandle} value={status}>
                        <Radio value={'RECEIVING_GOODS'}>同意</Radio>
                        <Radio value={'UNAGREE_GOODS'}>拒绝</Radio>
                    </RadioGroup> : <RadioGroup style={{ marginLeft: '12px' }} onChange={this._radioValueChangeHandle} value={status}>
                        <Radio value={'WAITING_GOODS'}>同意</Radio>
                        <Radio value={'UNAGREE_GOODS'}>拒绝</Radio>
                    </RadioGroup>) : null
                        }
                    </div>
                </div>
            </Modal>
        );
    }
}

AcceptModal.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
    refresh: PropTypes.func,
    getProcessDetail: PropTypes.func,
    processDetail: PropTypes.object,
};
const mapStateToProps = (state) => {
    return {
      processDetail: state.saleorder.processDetail,
    };
  };
export default connect(mapStateToProps, { getProcessDetail })(AcceptModal);
