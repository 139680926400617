/**
 * 集采打款
 * */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { XTabNav } from '@/components/xqxc_ui';
import ReadyPay from '../readypay';
import AlreadyPay from '../alreadypay';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: [
                { id: 1, label: '待打款' },
                { id: 2, label: '已打款' },
            ],
            curTabID: 1
        };
    }

    // Tab导航切换操作回调
    _tabChangeHandle = (tabID) => {
        this.setState({ curTabID: tabID });
    }

    // 渲染相应Tab导航下的内容
    _renderPage = () => {
        const { history } = this.props;
        switch (this.state.curTabID) {
            case 1:
                return <ReadyPay curTabID={this.state.curTabID} history={history} />;
            case 2:
                return <AlreadyPay curTabID={this.state.curTabID} history={history} />;
            default:
                return <ReadyPay curTabID={this.state.curTabID} history={history} />;
        }
    }

    render() {
        return (
            <div className={styles.flexBoxContainer}>
                <XTabNav renderData={this.state.tab} onChange={this._tabChangeHandle} activedID={this.state.curTabID} />
                <div className={`${styles.page} ${styles.flexBoxContainer}`}>
                    {this._renderPage()}
                </div>
            </div>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
};

export default Main;