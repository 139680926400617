/**
 * 银联签约操作确认弹框
 */
import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import getUnionInfo from './getUnionInfo';

class Ensure extends React.Component {

    state = {
        confirmLoading: false,
        loading: false
    }

    // 检测是否已经签约成功
    _handleOk = () => {
        this.setState({ loading: true });
        getUnionInfo().then((response) => {
            const { data } = response;
            const { signStatus } = data;
            if (signStatus == 1) {
                message.success('签约成功');
                this.props.signSuccess();
            } else {
                message.error('查询不到签约数据，请重新签约');
                this.props.closeMode();
            }
            this.setState({ loading: false });
        });
    }

    _handleCancel = () => {
        this.props.closeMode();
    }

    render() {
        const { visible } = this.props;
        return (
            <Modal
                confirmLoading={this.state.loading}
                width={300}
                centered
                title='签约操作确认'
                visible={visible}
                destroyOnClose={true}
                keyboard={false}
                maskClosable={false}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
            >
                我已成功签订电子协议
            </Modal>
        );
    }
}

Ensure.propTypes = {
    signSuccess: PropTypes.func.isRequired, // 签约成功
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框回调事件
};

export default Ensure;

