import { createModel } from '@/store/tools';

const model = {
    namespace: 'stockearOrder',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getStockState': T('getStockState'),
            'getStockTableList': T('getStockTableList'),
        },
        actions: {
            'getStockState': A('getStockState'), // 状态
            'getStockTableList': A('getStockTableList'), // 股票收益列表数据
        },
        sagas: {
            'getStockState': S('getStockState', '/enum/StockOrderStatusEnum'),
            'getStockTableList': S('getStockTableList', '/merchant/xiudou/order/listXiudouOrder'),
        },
        reducers: {
            'stockState': R('getStockState', []),
            'stockTableList': R('getStockTableList', { dataList: [], pageNum: 1, pageSize: 25, resultTotal: 0 }),
        }
    })
};
export default createModel(model);