import React from 'react';
import styles from './TableData.module.scss';
import { XMediaFn, } from '@/components/xqxc_ui';
import { Tooltip, } from 'antd';

/* eslint-disable react/display-name */
const columns = (actionClickHandle) => [
    {
        title: '商户图片',
        dataIndex: 'mainImgUrl',
        key: 'mainImgUrl',
        width: '10%',
        align: 'center',
        render: (text, record) => (ImgCell(text, record))
    },
    {
        title: '商品标题',
        dataIndex: 'goodsName',
        key: 'goodsName',
        width: '20%',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    maxWidth: 70,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            };
        },
        render: (text) => (TableTipCell(text))
    },
    {
        title: '货号',
        dataIndex: 'goodsNo',
        key: 'goodsNo',
        width: '10%',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    maxWidth: 70,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            };
        },
        render: (text) => (TableTipCell(text))
    },
    {
        title: '商品分类',
        dataIndex: 'categoryName',
        key: 'categoryName',
        width: '20%',
        align: 'center',
        onCell: () => {
            return {
                style: {
                    maxWidth: 70,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            };
        },
        render: (text) => (TableTipCell(text))
    },
    {
        title: '销售价格（元）',
        dataIndex: 'salePrice',
        key: 'salePrice',
        width: '10%',
        align: 'center',
        render: (text, record) => (TableCell(text, record))
    },
    {
        title: '商品状态',
        dataIndex: 'goodsState',
        key: 'goodsState',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '店铺商品状态',
        dataIndex: 'shopGoodsState',
        key: 'shopGoodsState',
        width: '10%',
        align: 'center',
        render: (text) => (TableCell(text))
    },
    {
        title: '操作',
        dataIndex: 'goodsId',
        key: 'goodsId',
        align: 'center',
        width: '20%',
        render: (id, record) => TableAction(id, record, actionClickHandle)
    },
];

export const takeDataSource = (data) => {
    return data;
};
export const takeColumns = (actionClickHandle) => {
    return columns(actionClickHandle);
};
// 表格项内容为文本
const TableTipCell = (text) => {
    return (
        <Tooltip placement='topLeft' title={text}>{text}</Tooltip>
    );
};
// 表格项内容为图片
const ImgCell = (url, record) => {
    return (
        <div style={{ display: 'inline-flex' }}>
            <XMediaFn
                key={record.goodsId}
                style={{ width: '50px', height: '50px', marginRight: '0' }}
                dataType='image'
                dataSource={url}
            />
        </div>
    );
};

const TableCell = (text, record) => {
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {record ? (record.salePrice ? text / 100 : text) : text}
        </div>
    );
};

const TableAction = (id, record, actionClickHandle) => {
    if (record.shopGoodsState == '上架') {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => actionClickHandle(id, 'MER_SHELVES_BOT')}>下架</div>
                <div className={styles.item} onClick={() => actionClickHandle(id, 'MER_DETAIL')}>详情</div>
            </div>
        );
    } else {
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => actionClickHandle(id, 'MER_SHELVES')}>上架</div>
                <div className={styles.item} onClick={() => actionClickHandle(id, 'MER_DETAIL')}>详情</div>
            </div>
        );
    }
};
