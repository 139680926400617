/**
 * 【仓库奢批结算价调整提示页】
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { message, InputNumber, Button } from 'antd';
import { getAutoAdjustInfo } from '@/reducers/warehousemanage/balanceHint/actions';

class AutoUpdate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            adjustRatio: 0,
            intervalTime: 30,
            isFirst: true,
            warehouseId: 0
        };
    }

    componentDidMount() {
        this.props.getAutoAdjustInfo();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.isFirst) {
            this.setState({
                adjustRatio: nextProps.autoAdjustInfo.adjustRatio,
                warehouseId: nextProps.autoAdjustInfo.warehouseId,
                isFirst: false
            });
        }
    }

    _inputChange = (data, type) => {
        switch (type) {
            case 'adjustRatio':
                this.setState({ adjustRatio: data });
                break;
            case 'intervalTime':
                this.setState({ intervalTime: data });
                break;
        }
    }

    _okHandle = () => {
        const { adjustRatio, intervalTime, warehouseId } = this.state;
        http('/goods/outer/updateAdjustRatio', { adjustRatio, intervalTime, warehouseId }, 'POST')
            .then(() => {
                message.success('设置成功。');
            })
            .catch(error => {
                message.error(error.message);
            });
    }

    render() {
        const { adjustRatio, intervalTime } = this.state;
        return (
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <div className={styles.label}><b>*</b>调整比例幅度：</div>
                    <div className={styles.inputBox}>
                        <div>
                            <InputNumber min={0} value={adjustRatio} onChange={(e) => this._inputChange(e, 'adjustRatio')} />
                        </div>
                        <p className={styles.suffix}>%</p>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.label}><b>*</b>间隔时间：</div>
                    <div className={styles.inputBox}>
                        <div>
                            <InputNumber min={30} disabled={true} value={intervalTime} onChange={(e) => this._inputChange(e, 'intervalTime')} />
                        </div>
                        <p className={styles.suffix}>每30分钟分钟执行一次。（固定值）</p>
                    </div>
                </div>
                <div className={styles.suffix} style={{ marginTop: '20px', paddingLeft: '30px' }}>
                    设置在什么条件下按什么频率自动执行价格调整；执行后，会员APP看到最新价格
                </div>
                <div className={styles.btn}>
                    <Button type="primary" onClick={this._okHandle}>确定</Button>
                </div>
            </div>
        );
    }
}

AutoUpdate.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getAutoAdjustInfo: PropTypes.func,
    autoAdjustInfo: PropTypes.object
};

const mapStateToProps = (state) => ({
    autoAdjustInfo: state.balanceHint.autoAdjustInfo
});

export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getAutoAdjustInfo })(AutoUpdate);