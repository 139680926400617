import * as Order from './actionTypes';

//酒店订单列表
export const getHotelOrderList = (param) => ({
    type: Order.GET_HOTELORDER_LIST,
    payload: param
});
// 支付方式
export const getPayWays = (param) => ({
    type: Order.GET_PAY_WAYS,
    payload: param,
});
// 获取房间数
export const getRoomNum = (param) => ({
    type: Order.GET_ROOM_NUM,
    payload: param,
});
// 房型
export const getRoomInfo = (param) => ({
    type: Order.GET_ROOM_INFO,
    payload: param,
});