import React, { Component } from 'react';
import styles from './TableContent.module.scss';
import PropTypes from 'prop-types';
import { Table, Tooltip } from 'antd';
import { XPagination } from '@/components/xqxc_ui';
import { tooltipStyle, tooltip, priceFenToYuanCell } from '@/components/TableCell';

export default class TypeTable extends Component {
	//props校验
	static propTypes = {
		renderData: PropTypes.object.isRequired, // 渲染数据源(双向绑定)
		tableAction: PropTypes.func.isRequired, // 对表格的操作
		paginationChange: PropTypes.func.isRequired,//分页
	};
	//表头
	_columns = () => [
		{
			title: '活动名称',
			dataIndex: 'activityTitle',
			key: 'activityTitle',
			width: '8%',
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '活动类型',
			dataIndex: 'activityTypeValue',
			key: 'activityTypeValue ',
			width: '8%',
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '商品货号',
			dataIndex: 'itemNo',
			key: 'itemNo',
			align: 'center',
			width: '8%',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '商品名称',
			dataIndex: 'goodsName',
			key: 'goodsName',
			width: '8%',
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '所属店铺',
			dataIndex: 'shopName',
			key: 'shopName',
			width: '8%',
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '商品价格(元)',
			dataIndex: 'goodsPrice',
			key: 'goodsPrice',
			width: '8%',
			align: 'center',
			render: priceFenToYuanCell
		},
		{
			title: '技术服务费(%)',
			dataIndex: 'serviceFee',
			key: 'serviceFee',
			width: '8%',
			align: 'center',
			render: (text) => this._rateCell(text)
		},
		{
			title: '平台服务费(%)',
			dataIndex: 'distributionFee',
			key: 'distributionFee',
			width: '8%',
			align: 'center',
			render: (text) => this._rateCell(text)
		},
		{
			title: '商品库存',
			dataIndex: 'stock',
			key: 'stock',
			width: '8%',
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '商品状态',
			dataIndex: 'stateValue',
			key: 'stateValue',
			width: '8%',
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '审核意见',
			dataIndex: 'opinion',
			key: 'opinion',
			width: '8%',
			align: 'center',
			onCell: tooltipStyle,
			render: tooltip
		},
		{
			title: '操作',
			dataIndex: 'activityGoodsId',
			key: 'activityGoodsId',
			align: 'center',
			width: '8%',
			render: (text, item) => this._tableAction(text, item)
		}
	];
	//过长提示
	_tableTipCell = (text) => {
		return <Tooltip placement='topLeft' title={text}>{text}</Tooltip>;
	}
	//费率装换
	_rateCell = (text) => {
		return text / 10;
	}
	//表格操作
	_tableAction = (text, item) => {
		const { tableAction } = this.props;
		if (item.state == 'PASS') {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(text, 'exit', item)}>申请退出</div>
				</div>
			);

		} else if (item.state == 'FAIL') {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(text, 'reupload', item)}>重新上传</div>
					<div className={styles.item} onClick={() => tableAction(text, 'delete', item)}>删除</div>
				</div>
			);
		} else {
			return (
				<div className={styles.action}>
					<div className={styles.item} onClick={() => tableAction(text, 'delete', item)}>删除</div>
				</div>
			);
		}
	};
	// 解析表格数据
	_takeDataSource = (data) => {
		return data ? data : [];
	}
	//渲染页面
	render() {
		const { renderData, paginationChange } = this.props;
		return (
			<div className={styles.tableContainer}>
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowSelection={this._rowSelection}
					rowKey={record => record.activityGoodsId}
					columns={this._columns()}
					dataSource={this._takeDataSource(renderData.dataList)}
					pagination={false}
					scroll={{ y: true }}
				/>
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange}
				/>
			</div>
		);
	}
}