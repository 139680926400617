/**
 * 商品列表-正常商品
 */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { isGY, getIsGDCPromise, isJM, isMT } from '@/assets/js/authType';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { message, Spin, Progress } from 'antd';
import { XSelect, XOKButton, XCancelButton, XInput, XExpandOrContract, showConfirm, XUploadExcel } from '@/components/xqxc_ui';
import TableContent from './components/TableContent';
import { GoodsCategory, GDCGoodsState } from '@/vcomps';
import * as actions from '@/reducers/productmanage/list/actions';
import model from '@/reducers/productmanage/list/model';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import FailMode from './modal/FailMode';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isGDC: false,
            selectDataSource: [],
            renderMode: false,
            isShowMore: false,
            goodsName: '',
            username: {},
            goodsNo: '',
            status: {},
            professionType: {},
            label: {},
            goodsIds: [],
            parentId: 0,
            tagIds: [],
            warehouse: {},
            prodCascade: '',
            brandObj: {},
            virtualFlag: {},
            batchData: [],
            expandBoolean: false,
            distributionRec: {},
            percent: 0,
            importing: false,
            failList: [],
            exportState: 'normal',
            categorySubType: {}
        };
        this.goodsCategory = React.createRef();
    }
    componentDidMount() {
        const { getProductStatus, getProductBusinessType, getProductLabelSele, getProductWareHouse, getDistributionRec, getDistributionBrand } = this.props;
        getProductStatus();
        getProductBusinessType();
        getProductLabelSele();
        getProductWareHouse();
        getDistributionRec();
        getDistributionBrand();
        getIsGDCPromise().then(isGDC => this.setState({ isGDC }));
    }
    // 查询触发
    _searchHandle = (useCache) => {
        const { goodsName, goodsNo, professionType, prodCascade, pageNum, pageSize, status, label, warehouse, distributionRec, brandObj, virtualFlag, categorySubType } = this.state;
        const tagIds = label.id ? [label.id] : [];
        this.searchCache = {
            goodsName, goodsNo, goodsState: status.code, goodsType: professionType.code, brandId: brandObj.id, virtualFlag: virtualFlag.code,
            categoryId: prodCascade, tagIds, warehouseId: warehouse.warehouseId, retailReco: distributionRec.code, categorySubType: categorySubType.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getProductListData(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }
    // 重置触发
    _resetHandle = () => {
        this.setState({ goodsName: '', goodsNo: '', professionType: {}, status: {}, label: {}, distributionRec: {}, brandObj: {}, virtualFlag: {}, warehouse: {}, categorySubType: {} }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
        this.goodsCategory.current.clean();
    }

    // 更多搜索条件的展开与收缩
    _expandChangeHandle = (expandBoolean) => {
        this.setState({ expandBoolean });
    }

    _sendRequest = (url, data, winTxt) => {
        http(url, data, 'POST')
            .then(() => {
                message.success(winTxt);
                this._searchHandle('useCache');
            }).catch((e) => {
                message.error(e.message);
            });
    }

    _batch = (type) => {
        const { batchData } = this.state;
        if (batchData.length === 0) {
            message.warning('请勾选商品！');
            return;
        }
        switch (type) {
            case 'submit':
                this._sendRequest('/goods/goods/submitAuditGoods', { goodsIds: batchData }, '批量提交平台成功。');
                break;
            case 'putaway':
                this._sendRequest('/goods/goods/putOnSaleGoods', { goodsIds: batchData }, '批量上架成功。');
                break;
            case 'soldOut':
                this._sendRequest('/goods/goods/pullOffShelvesGoods', { goodsIds: batchData }, '批量下架成功。');
                break;
        }
    }

    // 表格操作
    _tableAction = (id, type, value) => {
        switch (type) {
            case 'detail':
                console.log(id, type, value);
                this.props.updateContent('LIST_DETAIL', id);
                break;
            case 'delete':
                console.log(id, type, value);
                showConfirm('您是否要删除该商品', '', () => {
                    this._sendRequest('/goods/goods/delGoods', { goodsId: [id] }, '删除成功。');
                });
                break;
            case 'edit':
                console.log(id, type, value);
                this.props.updateContent('LIST_EDIT', id);
                break;
            case 'submit':
                console.log(id, type, value);
                this._sendRequest('/goods/goods/submitAuditGoods', { goodsIds: [id] }, '提交平台成功。');
                break;
            case 'putaway':
                console.log(id, type, value);
                this._sendRequest('/goods/goods/putOnSaleGoods', { goodsIds: [id] }, '上架成功。');
                break;
            case 'soldOut':
                console.log(id, type, value);
                this._sendRequest('/goods/goods/pullOffShelvesGoods', { goodsIds: [id] }, '下架成功。');
                break;
            case 'batch':
                this.setState({ batchData: value });
                break;
            case 'switch':
                this._sendRequest('/goods/goods/retail/reco', { goodsId: id, state: value ? 1 : 0 }, '分销推荐操作成功');
                break;
            case 'skuList':
                this.props.updateContent('skuList', id);
                break;
        }
    }

    // 页面发生变化
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    //一键导出
    _onExport = (url) => {
        const { goodsName, goodsNo, professionType, prodCascade, status, warehouse, distributionRec, brandObj, batchData, virtualFlag, categorySubType } = this.state;
        const data = {
            brandId: brandObj.id, categoryId: prodCascade, goodsIds: [...batchData],
            goodsName, goodsNo, goodsState: status.code, goodsType: professionType.code,
            isReco: distributionRec.code, searchType: 1, warehouseId: warehouse.warehouseId,
            virtualFlag: virtualFlag.code, categorySubType: categorySubType.code,
        };
        this.setState({ percent: 0, exportState: 'normal', importing: false }, () => {
            http(url, data, 'POST')
                .then((response) => {
                    if (response.status == 200) {
                        this.setState({ importing: true }, () => {
                            this.timer = setInterval(() => {
                                http(url, { searchType: 3, downloadCode: response.result }, 'POST')
                                    .then((response2) => {
                                        if (response2.result < 0) {
                                            message.error('导出数据为空');
                                            clearTimeout(this.timer);
                                            this.setState({ exportState: 'exception' }, () => {
                                                this.setState({ importing: false });
                                            });
                                        } else if (response2.result < 100 && response2.result > 0) {
                                            const percent = Number(response2.result);
                                            this.setState({ percent });
                                        } else {
                                            clearTimeout(this.timer);
                                            this.setState({ percent: 100 }, () => {
                                                http(url, { searchType: 2, downloadCode: response.result }, 'POST')
                                                    .then((info) => {
                                                        this.setState({ importing: false });
                                                        if (info.result) {
                                                            window.location.href = info.result;
                                                        } else {
                                                            message.error('导出失败');
                                                        }
                                                    }).catch((e) => {
                                                        message.error(e.message);
                                                    });
                                            });
                                        }
                                    }).catch((e) => {
                                        this.setState({ importing: false });
                                        clearTimeout(this.timer);
                                        message.error(e.message);
                                    });
                            }, 1000);
                        });
                    }
                }).catch((e) => {
                    message.error(e.message);
                });
        });
    }

    //查看所有分销SKU
    _onCheckSKU = () => {
        this.props.updateContent('allSkuList');
    }

    //分销推荐列表
    _onDistributionRec = () => {
        this.props.updateContent('distributionRec');
    }

    // 打开弹窗（创建/编辑）
    _renderMode = () => {
        const { renderMode, failList } = this.state;
        if (renderMode) {
            return <FailMode closeMode={this._closeMode} failList={failList} />;
        }
    }

    // 关闭弹窗
    _closeMode = () => {
        this.setState({ renderMode: false }, () => {
            this._searchHandle('useCache');
        });
    }


    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const { goodsName, goodsNo, status, professionType, label, batchData, warehouse,
            expandBoolean, distributionRec, percent, importing, brandObj, virtualFlag, exportState, isGDC, categorySubType } = this.state;
        const { productListData, productStatus, productBusinessType, productLabelSele,
            productWareHouse, distributionRecSele, distributionBrand } = this.props;
        return (
            <KeepAlive id='goodsList' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <Spin spinning={importing} indicator={<Progress type="circle" percent={percent} status={exportState} />}>
                    <div className={styles.flexBoxContainer}>
                        <div className={styles.searchCriteria}>
                            <XInput
                                inputStyle={{ width: '200px' }}
                                label='商品名称'
                                placeholder='请输入商品名称'
                                bindThis={this}
                                bindName='goodsName'
                                value={goodsName}
                                showSearch
                            />
                            <XInput
                                style={{ marginLeft: '20px' }}
                                inputStyle={{ width: '200px' }}
                                label='商品货号'
                                placeholder='请输入商品货号'
                                bindThis={this}
                                bindName='goodsNo'
                                value={goodsNo}
                            />
                            {
                                isGY() && <XSelect
                                    style={{ marginLeft: '20px' }}
                                    selectStyle={{ width: '200px' }}
                                    label='分销推荐'
                                    placeholder='请选择'
                                    renderData={distributionRecSele}
                                    bindThis={this}
                                    bindName='distributionRec'
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={distributionRec.value}
                                />
                            }
                            <XSelect
                                style={{ marginLeft: '20px' }}
                                selectStyle={{ width: '200px' }}
                                label='品牌'
                                placeholder='请选择'
                                renderData={distributionBrand}
                                bindThis={this}
                                bindName='brandObj'
                                dataIndex='brandName'
                                keyIndex='id'
                                value={brandObj.brandName}
                            />
                            {isGDC &&
                                <GDCGoodsState
                                    style={{ marginLeft: '20px', width: '193px' }}
                                    selectStyle={{ width: '100px' }}
                                    label='是否虚拟商品'
                                    placeholder='请请选择'
                                    bindThis={this}
                                    bindName='virtualFlag'
                                    value={virtualFlag}
                                />
                            }
                            <XOKButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='重置'
                                onClick={this._resetHandle}
                            />
                            <XExpandOrContract style={{ marginLeft: '20px' }} onChange={this._expandChangeHandle} />
                        </div>
                        <div className={`${styles.searchCriteriaHidden} ${expandBoolean ? styles.moreShow : styles.moreHidden}`}>
                            <div className={styles.criteria1}>
                                <XSelect
                                    selectStyle={{ width: '200px' }}
                                    label='商品状态'
                                    placeholder='请选择状态'
                                    renderData={productStatus}
                                    bindThis={this}
                                    bindName='status'
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={status.value}
                                />
                                <XSelect
                                    style={{ marginLeft: '20px' }}
                                    selectStyle={{ width: '200px' }}
                                    label='仓库名称'
                                    placeholder='请选择仓库名称'
                                    renderData={productWareHouse ? productWareHouse : []}
                                    bindThis={this}
                                    bindName='warehouse'
                                    dataIndex='warehouseName'
                                    keyIndex='warehouseId'
                                    value={warehouse.warehouseName}
                                    showSearch
                                />
                                <XSelect
                                    style={{ marginLeft: '20px' }}
                                    selectStyle={{ width: '200px' }}
                                    label='业务类型'
                                    placeholder='请选择业务类型'
                                    renderData={productBusinessType}
                                    bindThis={this}
                                    bindName='professionType'
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={professionType.value}
                                />
                                <XSelect
                                    style={{ marginLeft: '20px' }}
                                    selectStyle={{ width: '200px' }}
                                    label='商品标签'
                                    placeholder='请选择商品标签'
                                    renderData={productLabelSele}
                                    bindThis={this}
                                    bindName='label'
                                    dataIndex='tagName'
                                    keyIndex='id'
                                    value={label.tagName}
                                />
                                <XSelect
                                    selectStyle={{ width: '150px' }}
                                    style={{ marginLeft: '20px' }}
                                    label='分类类型'
                                    placeholder='请选择商品分类'
                                    renderData={[
                                        { code: 'COMMON', value: '通用分类' },
                                        { code: 'ONLINE', value: '线上分类' },
                                        { code: 'CITY', value: '同城分类' }
                                    ]}
                                    bindThis={this}
                                    bindName='categorySubType'
                                    dataIndex='value'
                                    keyIndex='code'
                                    value={categorySubType.value}
                                />
                            </div>
                            <GoodsCategory
                                label='商品分类'
                                ref={this.goodsCategory}
                                type={categorySubType.code}
                                style={{ marginTop: '20px' }}
                                onChange={(value) => this.setState({ prodCascade: value })}
                            />
                        </div>
                        <div className={styles.otherFeatures}>
                            {/* <XOKButton style={{ width: '120px' }} label='批量提交平台' onClick={() => this._batch('submit')} /> */}
                            {/* <XOKButton style={{ width: '120px', marginLeft: '20px' }} label='批量上架' onClick={() => this._batch('putaway')} /> */}
                            <XOKButton
                                style={{ width: '100px', marginRight: 20 }}
                                label='批量下架'
                                onClick={() => this._batch('soldOut')} />
                            {
                                isJM() && isMT() && <XOKButton
                                    style={{ width: '100px', marginRight: 20 }}
                                    label='一键导出'
                                    onClick={() => this._onExport('/goods/wholesale/wholesaleGoodsExportExcel2')} />
                            }
                            {
                                isGY() && <Fragment>
                                    <XUploadExcel
                                        style={{ width: '150px' }}
                                        label='导入分销商品'
                                        apiurl='goods/goods/importDistributionGoodsListExcel'
                                        failHandle={(info) => {
                                            this.setState({ renderMode: true, failList: info.file.response.result });
                                        }}
                                        successHandle={(info) => {
                                            message.success('导入成功');
                                        }}
                                    />
                                    <XOKButton
                                        style={{ width: '100px', margin: '0 20px' }}
                                        label='一键导出'
                                        onClick={() => this._onExport('/goods/goods/findDistributionGoodsListExcel')} />
                                    <XOKButton
                                        style={{ width: '150px', marginRight: 20 }}
                                        label='查看所有分销SKU'
                                        onClick={this._onCheckSKU} />
                                    <XOKButton
                                        style={{ width: '120px' }}
                                        label='分销推荐列表'
                                        onClick={this._onDistributionRec} />
                                </Fragment>
                            }
                        </div>
                        <TableContent isGDC={isGDC} paginationChange={this._paginationChange} renderData={productListData} batchData={batchData} tableAction={this._tableAction} />
                        {this._renderMode()}
                    </div>
                </Spin>
            </KeepAlive>
        );
    }
}
Main.propTypes = {
    updateContent: PropTypes.func,
    getProductListData: PropTypes.func,
    getProductStatus: PropTypes.func,
    getProductBusinessType: PropTypes.func,
    getProductLabelSele: PropTypes.func,
    getProductWareHouse: PropTypes.func,
    getDistributionRec: PropTypes.func,
    productListData: PropTypes.object,
    productStatus: PropTypes.array,
    productBusinessType: PropTypes.array,
    productLabelSele: PropTypes.array,
    productWareHouse: PropTypes.array,
    distributionRecSele: PropTypes.array,
    getDistributionBrand: PropTypes.func,
    distributionBrand: PropTypes.array
};

Main.defaultProps = {
    productWareHouse: []
};

const mapStateToProps = (state) => ({
    productListData: state.productListData.productListData,
    productStatus: state.productListData.productStatus,
    productBusinessType: state.productListData.productBusinessType,
    productLabelSele: state.productListData.productLabelSele.dataList || [],
    productWareHouse: state.productListData.productWareHouse,
    distributionRecSele: state.productListData.distributionRec,
    distributionBrand: state.productListData.distributionBrand
});
export default connect(mapStateToProps, { ...actions, ...model.actions })(Main);