/**
 * 【商户协议】
 */
import React, { useEffect, useState } from 'react';
import { Button, Empty, message } from 'antd';
import styles from './index.module.scss';
import http from '@/assets/api/http';
import { Fragment } from 'react';

const MerchantDeal = () => {

    const [prviewUrl, setPrviewUrl] = useState('');
    const [downloadUrl, setDownloadUrl] = useState('');

    useEffect(() => {
        http('/contract/fadada/contractInfo', {}, 'POST')
            .then(({ result: { viewpdfUrl = '', dowloadUrl = '' } = {} }) => {
                setPrviewUrl(viewpdfUrl);
                setDownloadUrl(dowloadUrl);
            })
            .catch(err => message.error(err.message));
    }, []);

    const iframeStyle = {
        width: '100%',
        height: '100%'
    };

    const preview = <Fragment>
        <div className={styles.contentBox}>
            <iframe style={{ ...iframeStyle, border: 'none' }} src={prviewUrl} />
        </div>
        <div className={styles.buttonBox}>
            <Button type='primary' onClick={() => window.open(downloadUrl)}>下载协议</Button>
        </div>
    </Fragment>;

    return <div className={styles.container}>{!prviewUrl ? <Empty style={{...iframeStyle,paddingTop:'200px'}} /> : preview}</div>;
};
export default MerchantDeal;