/**
 * 中联信注册弹框
 */
import React from 'react';
import styles from './RegisterModal.module.scss';
import { Modal, Input, Form, Button, message, Radio } from 'antd';
import { XCancelButton } from '@/components/xqxc_ui';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { getLocalStorage } from '@/assets/js/storage';
import { priceFenToYuanCell } from '@/components/TableCell';
import { connect } from 'react-redux';
import * as AllActions from '@/reducers/home/actions';

const formItemLayout = {
    labelCol: {
        xs: { span: 6 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 18 },
        sm: { span: 18 },
    },
};
const RadioGroup = Radio.Group;

class RegisterModal extends React.Component {

    state = {
        visible: this.props.visible,
        confirmLoading: false,
        isNew: true, // 是否为新建
        id: '', // 配置ID
        unitName: '', // 单位名称
        taxNum: '', // 社会统一信用代码
        registerAddr: '', // 注册地址
        registerTel: '', // 注册电话
        openBank: '', // 开户银行
        bankAccount: '', // 银行账号
        legalName: '', // 法人姓名
        legalTel: '', // 法人手机号
        legalIdentity: '', // 法人身份证
        receiveMail: '', // 收件人邮箱地址
        receiveAddr: '', // 收件地址
        receiveName: '', // 收票人姓名
        receivePhone: '', // 收票人手机号
        childModalVisible: false, // 二级弹框
        childModalData: {}, // 二级弹框数据
        unitType: 'BUSINESS',//类型（个体户/企业）
    }

    componentDidMount() {
        this.accountType = 'MT';
        const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')).userInfo : null;
        if (userInfo) {
            this.accountType = userInfo.accountType;
        }
        this.props.getRegisterEnum();
        this._init();
    }

    // 初始化
    _init = () => {
        let url = '';
        if (this.accountType == 'MT') {
            url = '/merchant/info/getFinancial';
        } else {
            url = '/warehouse/info/getFinancial';
        }
        http(url, {}, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result && response.result.id != '') {
                    const { id, accountType, unitName, taxNum, registerAddr, registerTel, openBank, bankAccount, legalName, legalTel, legalIdentity, receiveMail, receiveAddr, receiveName, receivePhone } = response.result;
                    this.setState({
                        isNew: false,
                        id,
                        unitType: accountType,
                        unitName,
                        taxNum,
                        registerAddr,
                        registerTel,
                        openBank,
                        bankAccount,
                        legalName,
                        legalTel,
                        legalIdentity,
                        receiveMail,
                        receiveAddr,
                        receiveName,
                        receivePhone
                    });
                } else {
                    this.setState({ isNew: true });
                }
            }
        }).catch((e) => {
            if (e) message.error(e.message);
        });
    }

    // 提交
    _handleSubmit = e => {
        let saveURL = '';
        let updateURL = '';

        if (this.accountType == 'MT') {
            saveURL = '/merchant/info/saveMerchantFinancial';
            updateURL = '/merchant/info/updateMerchantFinancial';
        } else {
            saveURL = '/warehouse/info/saveWarehouseFinancial';
            updateURL = '/warehouse/info/updateWarehouseFinancial';
        }

        const { isNew, id, unitType } = this.state;
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                if (isNew) {
                    http(saveURL, { ...fieldsValue, accountType: unitType }, 'POST').then((response) => {
                        if (response.status == 200) {
                            message.success('创建成功');
                            this._init();
                            window.open(response.result.url, 'blank');
                        }
                    }).catch((e) => {
                        if (e) message.error(e.message);
                    });
                } else {
                    http(updateURL, { ...fieldsValue, id, accountType: unitType }, 'POST').then((response) => {
                        if (response.status == 200) {
                            message.success('编辑成功');
                            this._init();
                            if (response.result.url == '') {
                                message.error('请打款并确认激活');
                            } else {
                                window.open(response.result.url, 'blank');
                            }
                        }
                    }).catch((e) => {
                        if (e) message.error(e.message);
                    });
                }
            }
        });
    }

    //类型（个体户/企业）清除数据
    _unitType = (e) => {
        const { resetFields } = this.props.form;
        resetFields();
        this.setState({
            unitType: e.target.value,//类型
            ...this._resetData
        });
    }

    //重置数据
    _resetData = {
        unitName: '', // 单位名称
        taxNum: '', // 社会统一信用代码
        registerAddr: '', // 注册地址
        registerTel: '', // 注册电话
        openBank: '', // 开户银行
        bankAccount: '', // 银行账号
        legalName: '', // 法人姓名
        legalTel: '', // 法人手机号
        legalIdentity: '', // 法人身份证
        receiveMail: '', // 收件人邮箱地址
        receiveAddr: '', // 收件地址
        receiveName: '', // 收票人姓名
        receivePhone: '', // 收票人手机号
    }

    // 重置
    _resetHandle = () => {
        const { resetFields, getFieldValue } = this.props.form;
        const soho = getFieldValue('unitType');
        resetFields();
        this.setState({
            unitType: soho,//类型（个体户/企业@对应的code,value固定写死）
            ...this._resetData
        });
    }

    _okHandle = () => {
        let url = '';
        if (this.accountType == 'MT') {
            url = '/merchant/info/acvite';
        } else {
            url = '/warehouse/info/acvite';
        }

        http(url, {}, 'GET').then((response) => {
            if (response.status == 200) {
                if (response.result.status == 'WAIT_ACTIVE') {
                    // console.log(response.result);
                    this.setState({ childModalVisible: true, childModalData: response.result });
                } else if (response.result.status == 'WAIT_REGISTER') {
                    message.success('请先提交注册开户信息，再打款并确认激活');
                } else {
                    message.success(response.message);
                }
            }
        }).catch((e) => {
            if (e) message.error(e.message);
        });
    }

    _handleCancel = () => {
        this.props.closeMode('gg');
    }

    _renderContent = () => {
        const { unitName, taxNum, registerAddr, registerTel, openBank, bankAccount, legalName, legalTel, legalIdentity, unitType, isNew } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { menuRegister } = this.props;

        // 企业
        if (this.state.unitType == 'BUSINESS') {
            return (<>
                {this.accountType == 'MT' ? <Form.Item
                    colon={false}
                    htmlFor='unitType'
                    label={<span></span>}
                >
                    {getFieldDecorator('unitType', {
                        initialValue: unitType,
                    })(<RadioGroup disabled={!isNew} onChange={this._unitType}>
                        {menuRegister.map((item) => (<Radio key={item.code} value={item.code}>{item.value}</Radio>))}
                    </RadioGroup>)}
                </Form.Item> : null}
                <Form.Item
                    colon={false}
                    htmlFor='unitName'
                    label={<span>单位名称</span>}
                >
                    {getFieldDecorator('unitName', {
                        rules: [{ required: true, message: '请输入单位名称' }],
                        initialValue: unitName
                    })(<Input placeholder='请输入单位名称' style={{ width: 400 }} />)}
                </Form.Item>
                <Form.Item
                    colon={false}
                    htmlFor='taxNum'
                    label={<span>社会统一信用代码</span>}
                >
                    {getFieldDecorator('taxNum', {
                        rules: [
                            { required: true, message: '请输入社会统一信用代码' },
                            { pattern: /[A-Z0-9]{18}/g, message: '请输入18位数字加大写字母的社会统一信用代码' },
                        ],
                        initialValue: taxNum
                    })(<Input placeholder='请输入18位数字加大写字母的社会统一信用代码' style={{ width: 400 }} />)}
                </Form.Item>
                <Form.Item
                    colon={false}
                    htmlFor='registerAddr'
                    label={<span>注册地址</span>}
                >
                    {getFieldDecorator('registerAddr', {
                        rules: [{ required: true, message: '请输入注册地址' }],
                        initialValue: registerAddr
                    })(<Input placeholder='请输入注册地址' style={{ width: 400 }} />)}
                </Form.Item>
                <Form.Item
                    colon={false}
                    htmlFor='registerTel'
                    label={<span>注册电话</span>}
                >
                    {getFieldDecorator('registerTel', {
                        rules: [
                            { required: true, message: '请输入注册电话' },
                            {
                                validator: (rule, value = '', callback) => {
                                    var RegExp = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/;
                                    if (value == '') {
                                        callback();
                                    } else if (RegExp.test(value) == false) {
                                        callback('请输入正确的固话（格式：区号+号码,如010-1234567）或正确的手机号码');
                                    } else {
                                        callback();
                                    }
                                }
                            }
                        ],
                        initialValue: registerTel
                    })(<Input placeholder='请输入正确的固话（格式：区号+号码,如010-1234567）或正确的手机号码' style={{ width: 400 }} />)}
                </Form.Item>
                <Form.Item
                    colon={false}
                    htmlFor='openBank'
                    label={<span>开户银行</span>}
                >
                    {getFieldDecorator('openBank', {
                        rules: [
                            { required: true, message: '请输入开户银行' },
                            { pattern: /^[\u4e00-\u9fa5]+$/, message: '请输入中文' },
                        ],
                        initialValue: openBank
                    })(<Input placeholder='请输入开户银行' style={{ width: 400 }} />)}
                </Form.Item>
                <Form.Item
                    colon={false}
                    htmlFor='bankAccount'
                    label={<span>银行账号</span>}
                >
                    {getFieldDecorator('bankAccount', {
                        rules: [
                            { required: true, message: '请输入银行账号' },
                            { validator: this.regAccount, message: '请输入25位以下的银行卡号' }
                        ],
                        initialValue: bankAccount
                    })(<Input placeholder='请输入25位以下的银行卡号' style={{ width: 400 }} />)}
                </Form.Item>
                <Form.Item
                    colon={false}
                    htmlFor='legalName'
                    label={<span>法人姓名</span>}
                >
                    {getFieldDecorator('legalName', {
                        rules: [
                            { required: true, message: '请输入法人姓名(仅支持中文)' },
                            { pattern: /^[\u4e00-\u9fa5]{2,6}$/, message: '请输入正确2-6位的姓名(仅支持中文)' },
                        ],
                        initialValue: legalName
                    })(<Input placeholder='请输入正确2-6位的姓名(仅支持中文)' style={{ width: 400 }} />)}
                </Form.Item>
                <Form.Item
                    colon={false}
                    htmlFor='legalTel'
                    label={<span>法人手机</span>}
                >
                    {getFieldDecorator('legalTel', {
                        rules: [
                            { required: true, message: '请输入法人手机' },
                            { pattern: /^[1-9]\d{10}$/, message: '请输入正确的手机号' },
                        ],
                        initialValue: legalTel
                    })(<Input placeholder='请输入法人手机' style={{ width: 400 }} />)}
                </Form.Item>
                <Form.Item
                    colon={false}
                    htmlFor='legalIdentity'
                    label={<span>法人身份证</span>}
                >
                    {getFieldDecorator('legalIdentity', {
                        rules: [
                            { required: true, message: '请输入法人身份证' },
                            { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确的身份证' },
                        ],
                        initialValue: legalIdentity
                    })(<Input placeholder='请输入法人身份证' style={{ width: 400 }} />)}
                </Form.Item>
            </>);
        } else {
            return (<>
                <Form.Item
                    colon={false}
                    htmlFor='unitType'
                    label={<span></span>}
                >
                    {getFieldDecorator('unitType', {
                        initialValue: unitType,
                    })(<RadioGroup disabled={!isNew} onChange={this._unitType}>
                        {menuRegister.map((item) => (<Radio key={item.code} value={item.code}>{item.value}</Radio>))}
                    </RadioGroup>)}
                </Form.Item>
                <Form.Item
                    colon={false}
                    htmlFor='legalName'
                    label={<span>经营者/法人姓名</span>}
                >
                    {getFieldDecorator('legalName', {
                        rules: [
                            { required: true, message: '请输入经营者/法人姓名(仅支持中文)' },
                            { pattern: /^[\u4e00-\u9fa5]{2,6}$/, message: '请输入正确2-6位的姓名(仅支持中文)' },
                        ],
                        initialValue: legalName
                    })(<Input placeholder='请输入正确2-6位的姓名(仅支持中文)' style={{ width: 400 }} />)}
                </Form.Item>
                <Form.Item
                    colon={false}
                    htmlFor='legalIdentity'
                    label={<span>身份证号码</span>}
                >
                    {getFieldDecorator('legalIdentity', {
                        rules: [
                            { required: true, message: '请输入身份证号码' },
                            { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确的身份证' },
                        ],
                        initialValue: legalIdentity
                    })(<Input placeholder='请输入身份证号码' style={{ width: 400 }} />)}
                </Form.Item>
                <Form.Item
                    colon={false}
                    htmlFor='legalTel'
                    label={<span>银行预留手机号</span>}
                >
                    {getFieldDecorator('legalTel', {
                        rules: [
                            { required: true, message: '请输入银行预留手机号' },
                            { pattern: /^[1-9]\d{10}$/, message: '请输入正确的手机号' },
                        ],
                        initialValue: legalTel
                    })(<Input placeholder='请输入银行预留手机号' style={{ width: 400 }} />)}
                </Form.Item>
                <Form.Item
                    colon={false}
                    htmlFor='openBank'
                    label={<span>开户银行</span>}
                >
                    {getFieldDecorator('openBank', {
                        rules: [
                            { required: true, message: '请输入开户银行' },
                            { pattern: /^[\u4e00-\u9fa5]+$/, message: '请输入中文' },
                        ],
                        initialValue: openBank
                    })(<Input placeholder='请输入开户银行' style={{ width: 400 }} />)}
                </Form.Item>
                <Form.Item
                    colon={false}
                    htmlFor='bankAccount'
                    label={<span>银行账号</span>}
                >
                    {getFieldDecorator('bankAccount', {
                        rules: [
                            { required: true, message: '请输入银行账号' },
                            { validator: this.regAccount, message: '请输入25位以下的银行卡号' }
                        ],
                        initialValue: bankAccount
                    })(<Input placeholder='请输入25位以下的银行卡号' style={{ width: 400 }} />)}
                </Form.Item>
            </>);
        }
    }

    render() {
        const { visible, isNew, receiveMail, receiveAddr, receiveName, receivePhone, childModalData } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <Modal
                width={680}
                centered
                title='注册财务账号'
                visible={visible}
                destroyOnClose={true}
                keyboard={false}
                maskClosable={false}
                closable={false}
                footer={null}
                onCancel={this._handleCancel}
            >
                <div className={styles.container}>
                    <div className={styles.title}>请认真填写企业的财务信息，用于销售款相关的结算。按系统提示完成注册开户和账户验证操作。</div>
                    <div style={{ color: 'red' }}>温馨提示：单位名称必须与营业执照的企业名称一致</div>
                    <div className={styles.line}></div>
                    <Form autoComplete='off' {...formItemLayout} onSubmit={this._handleSubmit} className="create-form">
                        {this._renderContent()}
                        <Form.Item
                            colon={false}
                            htmlFor='receiveMail'
                            label={<span>收件人邮箱地址</span>}
                        >
                            {getFieldDecorator('receiveMail', {
                                rules: [
                                    { required: true, message: '请输入收件人邮箱地址' },
                                    { pattern: /^[0-9a-zA-Z._-]+[@][0-9a-zA-Z._-]+([.][a-zA-Z]+){1,2}$/, message: '请输入正确的邮箱地址' },
                                ],
                                initialValue: receiveMail
                            })(<Input placeholder='请输入收件人邮箱地址' style={{ width: 400 }} />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='receiveAddr'
                            label={<span>收件人地址</span>}
                        >
                            {getFieldDecorator('receiveAddr', {
                                rules: [
                                    { required: true, message: '请输入收件人地址' },
                                    { pattern: /^[^\s]*$/, message: '禁止输入空格' }
                                ],
                                initialValue: receiveAddr
                            })(<Input placeholder='请输入收件人地址' style={{ width: 400 }} />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='receiveName'
                            label={<span>收票人姓名</span>}
                        >
                            {getFieldDecorator('receiveName', {
                                rules: [
                                    { required: true, message: '请输入收票人姓名(仅支持中文)' },
                                    { pattern: /^[\u4e00-\u9fa5]{2,6}$/, message: '请输入正确2-6位的姓名(仅支持中文)' },
                                ],
                                initialValue: receiveName
                            })(<Input placeholder='请输入正确2-6位的姓名(仅支持中文)' style={{ width: 400 }} />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='receivePhone'
                            label={<span>收票人手机</span>}
                        >
                            {getFieldDecorator('receivePhone', {
                                rules: [
                                    { required: true, message: '请输入收票人手机' },
                                    { pattern: /^[1-9]\d{10}$/, message: '请输入正确的手机号' },
                                ],
                                initialValue: receivePhone
                            })(<Input placeholder='请输入收票人手机' style={{ width: 400 }} />)}
                        </Form.Item>
                        <div style={{ display: 'flex' }}>
                            <Button style={{ marginLeft: '24%', marginRight: '20px', width: '120px', display: 'inline-block' }} type="primary" htmlType="submit">提交</Button>
                            <XCancelButton style={{ width: '120px', display: 'inline-block' }} label='重置' onClick={this._resetHandle} />
                            <Button style={{ marginLeft: '20px', width: '220px', display: 'inline-block' }} disabled={isNew} type="primary" onClick={this._okHandle}>确认已按要求打款后的激活</Button>
                        </div>
                    </Form>
                </div>
                <Modal
                    title="请打款并确认激活"
                    centered
                    visible={this.state.childModalVisible}
                    destroyOnClose={true}
                    keyboard={false}
                    maskClosable={false}
                    closable={true}
                    onOk={() => {
                        this.setState({
                            childModalVisible: false,
                        });
                    }}
                    onCancel={() => {
                        this.setState({
                            childModalVisible: false,
                        });
                    }}
                    cancelText='取消'
                    okText='确认'
                >
                    <div>账户名：{childModalData.outAccountName}</div>
                    <div>资金账号：{childModalData.outAccountId}</div>
                    <div>开户银行：{childModalData.openBankName}</div>
                    <div style={{ display: 'flex' }}>打款激活金额：{priceFenToYuanCell(childModalData.activeAmount)}元</div>
                    <div>打款激活截止日期：{childModalData.activeDeadline}</div>
                </Modal>
            </Modal>
        );
    }
}

RegisterModal.propTypes = {
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框回调事件
    form: PropTypes.object.isRequired, // 表单校验
    getRegisterEnum: PropTypes.func,
    menuRegister: PropTypes.array
};
const ForgotRegisterModal = Form.create({ name: 'create-form' })(RegisterModal);

const mapStateToProps = (state) => ({
    menuRegister: state.home.getRegisterEnum,
});

export default connect(mapStateToProps, AllActions)(ForgotRegisterModal);