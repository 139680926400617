import { createModel } from '@/store/tools';

const dataList = { dataList: [], pageNum: 1, pageSize: 20, resultTotal: 0 };
var num = 0;

const model = {
    namespace: 'messagelist',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getUnReadMessageCount': T('getUnReadMessageCount'), //获取未读消息数量
            'getlistMessage': T('getlistMessage'), //查询消息(右上角)
            'getlistReadMessage': T('getlistReadMessage'), //查询已读消息列表
            'getlistUnReadMessage': T('getlistUnReadMessage'),//查询未读消息列表
            'getMessageType': T('getMessageType'),//查询消息类型
            'getMessageInfo': T('getMessageInfo'),//查询消息详情
        },
        actions: {
            'getUnReadMessageCount': A('getUnReadMessageCount'), //获取未读消息数量
            'getlistMessage': A('getlistMessage'), //查询消息(右上角)
            'getlistReadMessage': A('getlistReadMessage'), //查询已读消息列表
            'getlistUnReadMessage': A('getlistUnReadMessage'),//查询未读消息列表
            'getMessageType': A('getMessageType'),//查询消息类型
            'getMessageInfo': A('getMessageInfo'),//查询消息详情
        },
        sagas: {
            'getUnReadMessageCount': S('getUnReadMessageCount', '/user/messageinfomis/getUnReadMessageCount'), //获取未读消息数量
            'getlistMessage': S('getlistMessage', '/user/messageinfomis/listMessage'), //查询消息(右上角)
            'getlistReadMessage': S('getlistReadMessage', '/user/messageinfomis/listReadMessage'), //查询已读消息列表
            'getlistUnReadMessage': S('getlistUnReadMessage', '/user/messageinfomis/listUnReadMessage'), //查询未读消息列表
            'getMessageType': S('getMessageType', '/user/messageType/listMessageType4DropDown'), //查询消息类型
            'getMessageInfo': S('getMessageInfo', '/user/messageinfomis/getMessageInfo'), //查询消息详情
        },
        reducers: {
            'UnReadMessageCount': R('getUnReadMessageCount', {}),
            'listMessage': R('getlistMessage', dataList),
            'listReadMessage': R('getlistReadMessage', dataList),
            'listUnReadMessage': R('getlistUnReadMessage', dataList),
            'MessageType': R('getMessageType', []),
            'MessageInfo': R('getMessageInfo', {}),
        }
    })
};
export default createModel(model);