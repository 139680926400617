/**
 * [表格数据列表]
 * @author: Fu Xiaochun
 * @date: 2021-11-09 
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Button, Table, Tooltip } from 'antd';
import XPagination from '@/components/Pagination';
import { tooltipStyle, tooltip, cellWidth, priceFenToYuanCell, priceDecimal2Cell, priceDecimal3Cell } from '@/components/TableCell';
import { XRangePicker, XMediaFn } from '@/components/xqxc_ui';


const columns = (TableAction, actionClickHandle) => [
    {
        title: '账号',
        dataIndex: 'loginName',
        key: 'loginName',
        align: 'left',
        width: '120px',
        render: tooltip
    },
    {
        title: '营业执照名称',
        dataIndex: 'shopName',
        key: 'shopName',
        align: 'left',
        width: '150px',
        render: tooltip
    },
    {
        title: '所在省',
        dataIndex: 'provinceName',
        key: 'provinceName',
        align: 'left',
        width: '100px',
        render: tooltip
    },
    {
        title: '所在城市',
        dataIndex: 'cityName',
        key: 'cityName',
        align: 'left',
        width: '100px',
    },
    {
        title: '所在区',
        dataIndex: 'areaName',
        key: 'areaName',
        align: 'left',
        width: '80px',
        render: tooltip
    },
    {
        title: '详细地址',
        dataIndex: 'address',
        key: 'address',
        align: 'left',
        width: '80px',
        render: tooltip
    },
    {
        title: '审核状态',
        dataIndex: 'auditStateName',
        key: 'auditStateName',
        align: 'left',
        width: '100px',
        render: tooltip
    },
    {
        title: '提交时间',
        dataIndex: 'auditTime',
        key: 'auditTime',
        align: 'left',
        width: '150px',
        render: tooltip
    },
    {
        title: '操作',
        dataIndex: 'shopId',
        key: 'shopId',
        align: 'left',
        width: '100px',
        render: (id, item) => TableAction(item, actionClickHandle)
    }
];

// 表格操作
const TableAction = (item, actionClickHandle) => {
    return (
        <div className={styles.tableActionCell}>
            <div className={styles.item} onClick={() => actionClickHandle(item.shopId, 'detail', item)}>查看</div>
            {item.auditState == 'WAIT_AUDIT' && <div className={styles.item} onClick={() => actionClickHandle(item.shopId, 'audit', item)}>审核</div>}
        </div>
    );
};

class TableData extends Component {
    constructor(props) {
        super(props);
    }

    // 操作表格
    _actionHandle = (id, type, value) => {
        const { tableAction } = this.props;
        tableAction(id, type, value);
    }

    render() {
        const { renderData } = this.props;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowKey='shopId'
                    columns={columns(TableAction, this._actionHandle)}
                    pagination={false}
                    dataSource={renderData.dataList}
                    scroll={{y: true}}
                />
                <XPagination
                    resultTotal={renderData.resultTotal}
                    pageSize={renderData.pageSize}
                    pageNum={renderData.pageNum}
                    onChange={this.props.paginationChange}
                />
            </div>
        );
    }
}

TableData.propTypes = {
    tableAction: PropTypes.func,
    paginationChange: PropTypes.func,
    renderData: PropTypes.object,
    setRowSelections: PropTypes.func,
    selectedRowKeys: PropTypes.array
};

export default TableData;