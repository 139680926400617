/**
 * 更新价格
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './modalStyle.module.scss';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { Modal, message, InputNumber, Table, Typography, Row, Col, Button } from 'antd';
import { tooltipStyle, tooltip, ImgCell } from '@/components/TableCell';
import model from '@/reducers/businessmanage/cityshopgoods/model';
import { regYuanToFen } from '@/util/money';

const { Text } = Typography;

const UpdatePrice = ({ closeMode, getCitySkuGoodsList, citySkuGoodsList, modalParams, editPrice }) => {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [salePrice, setSalePrice] = useState(0);
    const [marketPrice, setMarketPrice] = useState(0);

    useEffect(() => {
        getCitySkuGoodsList({ goodsId: modalParams });
    }, []);

    const salaPriceCell = (text, item) => {
        return <InputNumber
            key={item.skuId}
            precision={2}
            value={text}
            onChange={value => editPrice({ salePrice: value, marketPrice: item.marketPrice, skuId: item.skuId })}
        />;
    };

    const marketPriceCell = (text, item) => {
        return <InputNumber
            key={item.skuId}
            precision={2}
            value={text}
            onChange={value => editPrice({ salePrice: item.salePrice, marketPrice: value, skuId: item.skuId })}
        />;
    };

    const columns = () => ([
        {
            title: '商品图片',
            dataIndex: 'imgUrl',
            key: 'imgUrl',
            align: 'center',
            width: '15%',
            onCell: tooltipStyle,
            render: ImgCell
        },
        {
            title: '商品编码',
            dataIndex: 'skuNo',
            key: 'skuNo',
            align: 'center',
            width: '20%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '规格',
            dataIndex: 'skuSpec',
            key: 'skuSpec',
            align: 'center',
            width: '15%',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '销售价格',
            dataIndex: 'salePrice',
            key: 'salePrice',
            align: 'center',
            width: '15%',
            onCell: tooltipStyle,
            render: salaPriceCell
        },
        {
            title: '市场价格',
            dataIndex: 'marketPrice',
            key: 'marketPrice',
            align: 'center',
            width: '15%',
            onCell: tooltipStyle,
            render: marketPriceCell
        },
        {
            title: '更新时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            align: 'center',
            width: '20%',
            onCell: tooltipStyle,
            render: tooltip
        }
    ]);

    const handleSubmit = () => {
        setConfirmLoading(true);
        let reqParams = [];
        for (let item of citySkuGoodsList) {
            let rItem = {};
            rItem.marketPrice = regYuanToFen(item.marketPrice);
            rItem.salePrice = regYuanToFen(item.salePrice);
            rItem.skuId = item.skuId;
            reqParams.push(rItem);
        }
        http('/goods/cityShopGoods/batchUpdateSalePrice', reqParams, 'POST')
            .then(res => {
                message.success(res.message);
                setConfirmLoading(false);
                closeMode();
            })
            .catch(err => {
                message.error(err.message);
                setConfirmLoading(false);
            });
    };

    return (
        <Modal
            width={1000}
            centered
            title='更新商品价格'
            visible={true}
            confirmLoading={confirmLoading}
            onOk={handleSubmit}
            onCancel={closeMode}
            destroyOnClose={true}
            keyboard={true}
            maskClosable={false}
            cancelText='取消'
            okText='确认'>
            <div className={styles.container}>
                <Row>
                    <Col span={6}>
                        <div className={styles.left}>
                            <Text>销售规格：</Text>
                        </div>
                    </Col>
                    <Col span={18}>
                        <div className={styles.right}>
                            <InputNumber
                                style={{ width: 120 }}
                                placeholder='销售价格'
                                precision={2}
                                onChange={value => setSalePrice(value)}
                            />
                            <InputNumber
                                style={{ width: 120, margin: '0 10px' }}
                                placeholder='市场价格'
                                precision={2}
                                onChange={value => setMarketPrice(value)}
                            />
                            <Button
                                type='primary'
                                onClick={() => {
                                    if (!salePrice || !marketPrice) {
                                        message.warning('请输入价格');
                                        return;
                                    }
                                    editPrice({ salePrice, marketPrice, isBatch: true });
                                }}
                            >批量填写</Button>
                        </div>
                    </Col>
                </Row>
                <Table
                    style={{ height: 400, marginTop: 10 }}
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    columns={columns()}
                    pagination={false}
                    rowKey={record => record.skuId}
                    dataSource={citySkuGoodsList}
                    scroll={{ y: true }}
                />
            </div>
        </Modal>
    );
};

UpdatePrice.propTypes = {
    closeMode: PropTypes.func.isRequired,
    modalParams: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    getCitySkuGoodsList: PropTypes.func,
    citySkuGoodsList: PropTypes.array,
    editPrice: PropTypes.func,
};
UpdatePrice.defaultProps = {
    citySkuGoodsList: [],
};
export default connect(state => ({
    citySkuGoodsList: state.cityShopGoods.citySkuGoodsList,
}), {
    ...model.actions
})(UpdatePrice);