/**
 * 带复选框的表格头部计数组件
 */
import React from 'react';
import styles from './index.module.scss';
import { Icon } from 'antd';
import PropTypes from 'prop-types';

const TableSelectionCount = ({ selectedNum, onClean, suffixText }) => {
    return (
        <div className={styles.selectedDetail}>
            <Icon type="exclamation-circle" style={{ fontSize: '14px', color: '#FF6A0A' }} />
            <div className={styles.info}>
                {`已选择 ${selectedNum} 项`}
            </div>
            <div className={styles.clean} onClick={onClean} style={selectedNum > 0 ? { display: 'block' } : { display: 'none' }}>
                清空
            </div>
            <div className={styles.suffixText}>
                {suffixText}
            </div>
        </div >
    );
};

TableSelectionCount.propTypes = {
    selectedNum: PropTypes.number, // 已选择项的数量
    onClean: PropTypes.func, // 清空点击事件
    suffixText: PropTypes.string,//后缀说明内容
};

export default TableSelectionCount;