import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import { message, Button, Tooltip, Alert, Checkbox } from 'antd';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import LiveTable from './components/LiveTable';
import PayConfirmate from '../../modal/PayConfirmate';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import model from '@/reducers/storehousefinancialmanage/merchantwallet/model';
import { XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import { regFenToYuan } from '@/util/money';
import styles from './index.module.scss';
import { getLocalStorage } from '@/assets/js/storage';
import { isHOTEL } from '@/assets/js/authType';

class Main extends Component {

    state = {
        renderMode: '',//渲染弹框
        modeParams: {},//弹框入参

        beginTm: undefined,
        endTm: undefined,

        pageSize: 10,
        pageNum: 1,

        settleAmount: 0,
        settledAmount: 0,
        toSettleAmount: 0,
        totalOrders: 0,
        withdrawable: 0,
        settIds: [],
        merchantId: '',
        isDay: false
    };

    constructor(props) {
        super(props);
        const { userInfo } = JSON.parse(getLocalStorage('auth'));
        this.state.merchantId = userInfo.merchantId;
    }

    //props校验
    static propTypes = {
        history: PropTypes.object,//路由对象
        addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
        keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
        getReconciliationData: PropTypes.func,//获取列表
        reconciliationData: PropTypes.object,//列表数据
        getSettleConfigInfo: PropTypes.func,
        settleConfigInfo: PropTypes.object
    };

    //挂载后
    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        // this._navdata();
    }

    _navdata = () => {
        http('/wallet/data').then(res => {
            if (res.code === 200) {
                this.setState(res.result);
            }
        }).catch((res = {}) => {
            message.error(res.message || '获取数据异常');
        });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { pageNum, pageSize, beginTm, endTm } = this.state;
        this.searchCache = {
            beginTm,
            endTm,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getSettleConfigInfo(this.searchCache);
        this.props.getReconciliationData(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    };

    // 重置
    _resetHandle = () => {
        this.setState({
            beginTm: undefined,
            endTm: undefined,
            pageNum: 1,
            pageSize: 10,
        }, () => KeepAlive.saveResetFunc(this._searchHandle)); // 【第4步：将是否调用重置交给KeepAlive控制】
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageSize, pageNum }, () => this._searchHandle('useCache'));
    };

    // 表格操作
    _tableAction = (type, record) => {
        const { history, addOneBreadcrumbPath } = this.props;
        let detailUrl = `detail/${record.id}`;
        switch (type) {
            case 'detail':
                if (record.accessType === 'THIRD_UNION_PAY' || record.accessType === 'LL_PAY') {
                    detailUrl = `detailYL/${record.accessType}/${record.merOrderId}`;
                }
                if (record.accessType === 'ADA_PAY') {
                    detailUrl = `detailCommon/${record.accessType}/${record.id}/${record.merOrderId}`;
                }
                addOneBreadcrumbPath({
                    title: '订单明细',
                    path: '/home/storehousefinancialmanage/merchantwallet/' + detailUrl
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/merchantwallet/' + detailUrl
                });
                break;
            case 'rowSelect':
                this.setState({ settIds: record });
                break;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        this.setState({ renderMode: '' });
        if (update) {
            this._searchHandle('useCache');
            this._navdata();
        }
    }

    _withdraw = () => {
        let { settleAmount, bankCardNum } = this.state;
        if (parseFloat(settleAmount) > 100) {
            this.setState({ modeParams: { settleAmount, bankCardNum }, renderMode: 'withdraw' });
        } else {
            message.warning('可结算金额大于1元时才能操作，手续费需要1元');
        }
    }

    _goto = (type) => {
        let url = {
            wait: { path: '/home/storehousefinancialmanage/dailystatement', title: '商户日结对账单' },
            total: { path: '/home/ordermanage/transaction', title: '订单交易明细' }
        };
        this.props.addOneBreadcrumbPath({ title: url[type].title, path: url[type].path });
        this.props.history.push({ pathname: url[type].path });
    }

    _generateReport = () => {
        if (this.state.settIds.length) {
            this.setState({ generating: true });
            let templateId = isHOTEL() ? 55 : 44;
            http('/report/template/info', { id: templateId }).then(res => {
                if (res.code === 200) {
                    const queryCriteriaList = res.result.queryCriteriaList || [];
                    queryCriteriaList.forEach(r => {
                        if (r.conditionFiled === 'settIds') {
                            r.value = (Array.isArray(this.state[r.conditionFiled]) ? this.state[r.conditionFiled] : []).join(',');
                        } else {
                            r.value = this.state[r.conditionFiled];
                        }
                    });
                    const purpose = '钱包报表';
                    http('/report/record/addRecord', { templateId, queryCriteriaList, purpose }).then(res => {
                        if (res.code === 200) {
                            message.success('生成报表成功');
                        }
                    }).catch((res = {}) => message.error(res.message || '生成报表异常'));
                }
                this.setState({ generating: false });
            }).catch((res = {}) => {
                this.setState({ generating: false });
                message.error(res.message || '获取模板数据异常');
            });
        } else {
            message.warning('请选择一条记录');
        }
    }

    // 打开弹窗
    _renderMode = () => {
        const { modeParams, renderMode } = this.state;
        switch (renderMode) {
            case 'withdraw': // 付款
                return <PayConfirmate title='商户结算' visible={true} data={modeParams} closeMode={this._closeMode} />;
            default:
                return null;
        }
    };

    //渲染组件
    render() {
        const { beginTm, endTm, settleAmount, settledAmount, toSettleAmount, withdrawable, totalOrders, isDay } = this.state;
        const { settleConfigInfo: { amouontMin = 0, amount = 0, withdrawConf = false } = {} } = this.props;
        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    {/* 任务2369：商户后台-商户钱包调整 */}
                    {/* <div className={styles.infoBox}>
                        <div className={styles.metaInfo} style={{ backgroundColor: 'rgb(17, 142, 233)' }}>
                            <div>
                                <span className={styles.text}>可结算</span>
                                {withdrawable == '1' && <Button style={{ color: 'rgb(17, 142, 233)' }} onClick={this._withdraw}>结算</Button>}
                            </div>
                            <div>{regFenToYuan(settleAmount)}</div>
                        </div>
                        <div className={styles.metaInfo} style={{ backgroundColor: 'rgb(255, 102, 0)', marginLeft: 15 }}>
                            <div>
                                <span className={styles.text}>待结算</span>
                                <Button style={{ color: 'rgb(255, 102, 0)' }} onClick={() => this._goto('total')}>查看</Button>
                            </div>
                            <div>{regFenToYuan(toSettleAmount)}</div>
                        </div>
                        <div className={styles.metaInfo} style={{ backgroundColor: 'rgb(43, 179, 138)', marginLeft: 15 }}>
                            <div>
                                <span className={styles.text}>已结算</span>
                            </div>
                            <div>{regFenToYuan(settledAmount)}</div>
                        </div>
                        <div className={styles.metaInfo} style={{ backgroundColor: 'rgba(255, 45, 71)', marginLeft: 15 }}>
                            <div>
                                <span className={styles.text}>总订单</span>
                                <Button style={{ color: 'rgba(255, 45, 71)' }} onClick={() => this._goto('total')}>查看</Button>
                            </div>
                            <div>{totalOrders}</div>
                        </div>
                    </div> */}
                    <div className={styles.searchBox}>
                        <XDatePicker
                            label='日期'
                            showTime={false}
                            value={beginTm}
                            bindThis={this}
                            bindName='beginTm'
                            defaultTime='00:00:00'
                            isFormat
                        />
                        <span style={{ paddingLeft: 10 }}>—</span>
                        <XDatePicker
                            showTime={false}
                            value={endTm}
                            bindThis={this}
                            bindName='endTm'
                            defaultTime='23:59:59'
                            isFormat
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={this._searchHandle}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                        {/* <div style={{ color: '#000', marginLeft: 35 }}>
                            <Checkbox style={{ color: '#000' }}>开启自动提现，最小金额：</Checkbox><InputNumber min={0} max={99999} precision={2} style={{ width: 100 }} />
                            <div style={{ color: '#808080', marginTop: 5 }}>每天AI系统定时执行当前账户余额提现</div>
                        </div> */}
                        {
                            amouontMin != 0 ?
                                <Fragment>
                                    <Alert
                                        type='info'
                                        style={{ height: '30px', lineHeight: '30px', padding: '0 10px', margin: '0 20px' }}
                                        message={<div> 已完成订单的结算资金超过{regFenToYuan(amouontMin)}元将自动提现到银行账户，当前已完成订单的待结算资金{regFenToYuan(amount)}元</div>}
                                    />
                                    <div className={styles.setContainer}>
                                        <div className={styles.label}>更改结算方式：</div>
                                        <div className={styles}>
                                            <Checkbox defaultChecked={withdrawConf == 1 ? true : false} onChange={e => this.setState({ isDay: e.target.checked })}>按天结算</Checkbox>
                                        </div>
                                        <XOKButton
                                            style={{ width: '60px', marginLeft: '10px' }}
                                            label='保存'
                                            onClick={() => {
                                                http('/wallet/setWithdrawConf', { withdrawConf: isDay ? 1 : 0 })
                                                    .then(() => {
                                                        message.success('保存成功');
                                                        this.props.getSettleConfigInfo();
                                                    });
                                            }}
                                        />
                                    </div>
                                </Fragment> :
                                <Alert
                                    type='info'
                                    style={{ height: '30px', lineHeight: '30px', padding: '0 10px', marginLeft: '20px' }}
                                    message={<div> 当前待结算资金{regFenToYuan(amount)}元</div>}
                                />
                        }
                    </div>
                    <div className={styles.operateBox}>
                        <Button type='primary' onClick={this._generateReport}>生成报表</Button>
                        <span style={{ marginLeft: 10, color: '#808080' }}>生成报表后请到“报表管理”下载报表</span>
                    </div>
                    <LiveTable renderData={this.props.reconciliationData} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

//映射数据源
const mapStateToProps = (state) => ({
    reconciliationData: state.merchantwallet.reconciliationData,
    settleConfigInfo: state.merchantwallet.settleConfigInfo,
});

//暴露组件
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, ...model.actions })(Main);
