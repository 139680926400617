/**
 * 待发货出库单
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XInput, XSelect, XOKButton, XCancelButton, XDatePicker, XUploadExcel, showConfirm } from '@/components/xqxc_ui';
import OrderTable from './components/OrderTable';
import DeliverModal from '../../modal/DeliverModal';
import ImportFail from '../../modal/ImportFail';
import { connect } from 'react-redux';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getReadyDeliverList, getWholesaleSelect } from '@/reducers/delivermanage/readydeliver/actions';
import { OrderType } from '@/vcomps';
import { Checkbox, message, Progress, Modal, Spin } from 'antd';
import http from '@/assets/api/http';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import model1 from '@/reducers/systemmanage/delivertemplate/model';
import model2 from '@/reducers/systemmanage/delivergoodsset/model';
import Preview from './modal/Preview';
import model3 from '@/reducers/expresswaybill/authorization/model';
import BatchRemark from './modal/BatchRemark';

const { getDeliverTemplateData } = model1.actions;
const { getContactsInfo } = model2.actions;
const { getAuthorizationInfo } = model3.actions;
const invoiceSelect = [{ id: 1, name: '是' }, { id: 0, name: '否' }];
const saleStatus = [{ code: 'PART_GOODS_SENT', value: '部分发货' }, { code: 'PAIED', value: '已付款' }];

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.printData = [];
        this.timeout = undefined;
    }

    state = {
        showDeliverModal: '', // 打开弹窗类型
        openModalParam: {}, // 弹框传参
        accountTel: '', // 会员手机
        orderId: '', // 订单编号
        startDate: undefined, // 下单开始时间
        endDate: undefined, // 下单结束时间
        status: {}, // 订单状态
        orderType: {}, // 订单类型
        isInvoice: {}, // 是否开票
        selectedRowKeys: [], // 复选框
        selectedRowValues: [], // 复选框
        loading: false,
        progressStatus: 'normal',
        percent: 0,
        wholesaleOrder: { code: '2', value: '全部' }, // 批发订单
        batchNo: '',//批次号
        templateItem: {},// 面单打印模板
        isPreview: false,//是否预览
        tipInfo: '',
        isLoading: false,
        hasFill: false
    };

    componentDidMount() {
        const { keepSecondNavBreadcrumb, getWholesaleSelect, getDeliverTemplateData, getContactsInfo, getAuthorizationInfo } = this.props;
        keepSecondNavBreadcrumb();
        getWholesaleSelect();
        getDeliverTemplateData({ templateStatus: 1 });
        getContactsInfo();
        getAuthorizationInfo();

    }

    componentDidUpdate() {
        const { deliverTemplateData } = this.props;
        const { hasFill } = this.state;
        if (!deliverTemplateData.length) return;
        let list = deliverTemplateData.filter(i => i.defaultStatus == 1);
        if (list.length > 0 && !hasFill) {
            this.setState({ templateItem: list[0] ? list[0] : {}, hasFill: true });
        }
    }

    // 查询
    _searchHandle = (useCache) => {
        const { accountTel, orderId, startDate, endDate, status, orderType, isInvoice, pageNum, pageSize, wholesaleOrder, batchNo } = this.state;
        this.searchCache = {
            accountTel,
            orderId,
            batchNo,
            startDate: startDate,
            endDate: endDate,
            status: status.code,
            orderType: orderType.code,
            isInvoice: isInvoice.id,
            isDistribution: wholesaleOrder.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.setState({ selectedRowKeys: [], selectedRowValues: [] });
        this.props.getReadyDeliverList(this.searchCache); // 发起请求
        KeepAlive.saveCache({ ...this.searchCache, status, orderType, isInvoice, wholesaleOrder }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            accountTel: '',
            orderId: '',
            startDate: undefined,
            endDate: undefined,
            status: {},
            orderType: {},
            isInvoice: {},
            openModalParam: {},
            wholesaleOrder: { code: '2', value: '全部' },
            batchNo: ''
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize });
        this.searchCache = { ...this.searchCache, pageSize, pageNum };
        this.props.getReadyDeliverList(this.searchCache);
        KeepAlive.saveCache({ ...this.state, pageSize, pageNum }); // 【第5步：缓存分页状态】
    }

    //打印
    _toPrint = (list, preview) => {
        const { templateItem } = this.state;
        let defaultPrinter;
        const socket = new WebSocket('ws://localhost:13528');
        /***
       * 
       * 获取请求的UUID，指定长度和进制,如 
       * getUUID(8, 2)   //'01001010' 8 character (base=2)
       * getUUID(8, 10) // '47473046' 8 character ID (base=10)
       * getUUID(8, 16) // '098F4D35'。 8 character ID (base=16)
       *   
       */
        const getUUID = (len, radix) => {
            let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
            let uuid = [],
                i;
            radix = radix || chars.length;
            if (len) {
                for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
            } else {
                let r;
                uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
                uuid[14] = '4';
                for (i = 0; i < 36; i++) {
                    if (!uuid[i]) {
                        r = 0 | Math.random() * 16;
                        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
                    }
                }
            }
            return uuid.join('');
        };

        /***
       * 构造request对象
       */
        const getRequestObject = (cmd) => {
            let request = new Object();
            request.requestID = getUUID(8, 16);
            request.version = '1.0';
            request.cmd = cmd;
            return request;
        };

        /**
        * 请求打印机列表demo
        * */
        const getPrintList = () => {
            let request = getRequestObject('getPrinters');
            if (socket.readyState === 1) {
                this.setState({ tipInfo: '正在链接打印机...', isLoading: true }, () => {
                    socket.send(JSON.stringify(request));
                });
            }
        };

        /**
       * 请求任务状态
       * */
        const getTaskStatus = (params) => {
            let request = getRequestObject('getTaskStatus');
            request.taskID = [params.taskID];
            if (socket.readyState === 1) {
                socket.send(JSON.stringify(request));
            }
        };

        /**
      * 打印电子面单
      * printer 指定要使用那台打印机
      * waybillArray 要打印的电子面单的数组
      */

        const doPrint = (printer, isPreview) => {
            let request = getRequestObject('print');
            request.task = new Object();
            request.task.taskID = getUUID(8, 10);
            request.task.preview = isPreview;
            request.task.printer = printer;
            let documents = new Array();
            for (let i = 0; i < list.length; i++) {
                let doc = new Object();
                doc.documentID = list[i].waybillCode;
                doc.contents = JSON.parse(list[i].printData);
                documents.push(doc);
            }
            request.task.documents = documents;
            console.log('打印传参原始数据：', request);
            console.log('打印传参JSON数据：', JSON.stringify(request));
            socket.send(JSON.stringify(request));
        };

        //如果是htps
        //socket = new WebSocket('ws://127.0.0.1:13529');

        // 打开Socket
        socket.onopen = (event) => {
            clearTimeout(this.timeout);
            this.setState({ tipInfo: '', isLoading: false }, () => {
                getPrintList(); //打印机列表
            });
            // 监听消息
            socket.onmessage = (event) => {
                let request = JSON.parse(event.data);
                if (request.cmd === 'getPrinters') {
                    defaultPrinter = list[0].printer ? list[0].printer : request.defaultPrinter;
                    this.setState({ tipInfo: '', isLoading: false }, () => {
                        doPrint(defaultPrinter, preview);
                    });
                } else if (request.cmd === 'print') {
                    if (request.previewURL && preview) {
                        this.setState({ showDeliverModal: 'preview', openModalParam: { previewURL: request.previewURL }, tipInfo: '', isLoading: false });
                        return;
                    }
                    this.setState({ tipInfo: '正在打印电子面单...', isLoading: true }, () => {
                        getTaskStatus(request);
                    });
                } else if (request.cmd === 'getTaskStatus') {
                    this.setState({ tipInfo: '', isLoading: false });
                    if (request.status === 'failed') {
                        console.log('getTaskStatus，打印失败结果：', request);
                        showConfirm('打印失败', request.msg, () => {
                            doPrint(defaultPrinter, false);
                        }, () => { }, '重新打印', '关闭');
                    } else if (request.status === 'success') {
                        console.log('getTaskStatus，打印成功结果：', request);
                        const mid = this.printData[0] && this.printData[0].objectId;
                        if (preview) return;
                        message.success('打印成功');
                        http('/merchant/order/cainiao/callback', { ids: mid ? [mid] : [] }, 'POST')
                            .then(res => {
                                if (res.result) {
                                    console.log('通知服务端打印成功', res.result);
                                    this._searchHandle('useCache');
                                }
                            });
                    }
                }
            };
            // 监听Socket的关闭
            socket.onclose = function (event) {
                console.log('Client notified socket has closed', event);
            };
        };
        this.timeout = setTimeout(() => {
            message.warning('您的打印插件未开启，请先开启后，再进行打印');
            this.setState({ tipInfo: '', isLoading: false });
        }, 3000);
    }

    // 表格操作
    _tableAction = (id, item, type) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'deliver':
                this.setState({ showDeliverModal: 'DeliverModal', openModalParam: { item } });
                break;
            case 'info':
                addOneBreadcrumbPath({
                    title: '出库单详情',
                    path: '/home/delivermanage/readydeliver/info/'
                });
                history.push({
                    pathname: '/home/delivermanage/readydeliver/info/' + id
                });
                break;
            case 'expressWaybill':
                this._onExpressWaybill([id]);
                break;
            case 'batchNo':
                this.setState({ batchNo: id }, () => {
                    this._searchHandle('useCache');
                });
                break;
        }
    }

    // 打开弹框
    _renderMode = () => {
        const { showDeliverModal, openModalParam } = this.state;
        switch (showDeliverModal) {
            case 'DeliverModal':
                return <DeliverModal visible={true} data={this.state.openModalParam} closeMode={this._closeMode} />;
            case 'ImportFail':
                return <ImportFail visible={true} data={this.state.openModalParam} closeMode={this._closeMode} />;
            case 'preview':
                return <Preview data={openModalParam.previewURL} closeMode={this._closeMode} />;
            case 'BatchRemark':
                return <BatchRemark closeMode={this._closeMode} />;
            default:
                break;
        }
    }

    // 关闭弹框
    _closeMode = (update, type) => {
        this.setState({ showDeliverModal: '' }, () => {
            if (update) {
                this.props.getReadyDeliverList(this.searchCache);
                if (type) {
                    this.setState({ tipInfo: '正在链接菜鸟物流...', isLoading: true }, () => {
                        this._toPrint(this.printData, false);
                    });
                }
            }
        });

    }

    // 订单状态
    _StatusChangeHandle = e => {
        this.setState({
            status: e,
        });
    }

    // 下载导入模板(出库单)
    _downloadTemplate = () => {
        http('/admin/param/getParamValueByCode/EXECL_WAREHOSE_DELIVERY_TEMPLATE', {}, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result == '') {
                    message.error('没有订单数据');
                } else {
                    window.location.href = response.result;
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 导出出库单
    _exportOrder = () => {
        const { accountTel, orderId, startDate, endDate, status, orderType, isInvoice, selectedRowKeys } = this.state;
        const data = {
            accountTel,
            orderId,
            startDate: startDate,
            endDate: endDate,
            status: status.code,
            orderType: orderType.code,
            isInvoice: isInvoice.id,
            ids: selectedRowKeys
        };
        http('/merchant/order/exportboundQueryOrderList', data, 'POST').then((response) => {
            if (response.status == 200) {
                if (response.result == '') {
                    message.error('没有订单数据');
                } else {
                    window.location.href = response.result;
                }
            }
        }).catch((e) => {
            message.error(e.message);
        });
    }

    //渲染进度条
    _renderProgress = (data) => {
        if (data.status == 200) {
            this.setState({ percent: 0, loading: true, progressStatus: 'normal' }, () => {
                this._countTime(data);
            });
        }
    }

    //计时器
    _countTime = (data) => {
        const _this = this;
        this.interval = setInterval(() => {
            http('/merchant/order/getImportProgress', { key: data.result.key }, 'POST').then((response) => {
                if (response.result.code == 0) {
                    if (_this.state.percent < 99) {
                        let percent = _this.state.percent + 1;
                        _this.setState({ percent, loading: true });
                    } else {
                        _this.setState({ percent: 99, loading: true });
                    }
                } else if (response.result.code == 2) {
                    clearInterval(_this.interval);
                    _this.setState({
                        loading: false,
                        progressStatus: 'exception',
                        showDeliverModal: 'ImportFail',
                        openModalParam: { ...response.result }
                    });
                } else if (response.result.code == 1) {
                    message.success(response.result.msg);
                    _this.setState({
                        loading: false,
                        progressStatus: 'success',
                        percent: 100
                    });
                    clearInterval(_this.interval);
                } else {
                    message.error(response.result.msg);
                    _this.setState({
                        loading: false,
                        progressStatus: 'normal',
                        percent: 0
                    });
                    clearInterval(_this.interval);
                }
            }).catch((e) => {
                message.error(e.msg);
                clearInterval(_this.interval);
                _this.setState({
                    loading: false,
                    progressStatus: 'exception',
                    percent: 0
                });
            });
        }, 1000);
    }

    //清除定时器
    componentWillUnmount() {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
    }

    //请求批量打印
    _requestDelivery = (params = {}, isPreview) => {
        http('/merchant/order/cainiao/print', { ...params }, 'POST')
            .then(res => {
                if (res.result) {
                    this.printData = res.result || [];
                    this._searchHandle('useCache');
                    this.setState({ tipInfo: '正在链接菜鸟物流...', isLoading: true }, () => {
                        this._toPrint(res.result, isPreview);
                    });
                }
            })
            .catch(err => {
                // message.error('物流模板已被管理员停止服务，请联系管理员开通');
                message.error(err.message);
                this.setState({ tipInfo: '', isLoading: false });
                this._searchHandle('useCache');
            });
    }

    //批量菜鸟面单出库
    _onExpressWaybill = (list) => {
        const { contactsInfo, history } = this.props;
        const { templateItem, selectedRowValues, isPreview } = this.state;
        if (!contactsInfo.contactName) {
            showConfirm('', '你还未设置发货联系人，请先去设置', () => {
                history.push({ pathname: '/home/systemmanage/delivergoodsset' });
            }, () => { }, '去设置', '关闭');
        } else {
            if (!list.length) {
                Modal.warning({
                    okText: '关闭',
                    content: '请选择需要打印的订单数据',
                });
                return;
            }
            for (let i of selectedRowValues) {
                if (i.afterSaleId || i.status == 'CANCELLED') return message.warning('批量菜鸟面单出库不能包括申请退款和取消订单的数据');
            }
            const keyList = selectedRowValues.map(i => { return i.batchNo; });
            let isSame = true;
            keyList.forEach(i => {
                keyList.forEach(e => {
                    if (i !== e) {
                        isSame = false;
                        return;
                    }
                });
            });
            if (!isSame) {
                Modal.warning({
                    okText: '关闭',
                    content: '一次只能打印相同批次的订单数据，请重新选择',
                });
                return;
            }
            this._requestDelivery({ outOrderIds: list, templateId: templateItem.id }, isPreview);
        }
    }

    render() {
        const { accountTel, orderId, startDate, endDate,
            status, orderType, isInvoice, selectedRowKeys,
            selectedRowValues, loading, percent, progressStatus,
            wholesaleOrder, batchNo, templateItem, isPreview, tipInfo, isLoading } = this.state;
        const { wholesaleSelectList, deliverTemplateData, authorizationInfo } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <Spin tip={tipInfo} spinning={isLoading}>
                    <div className={styles.flexBoxContainer}>
                        <div className={styles.searchCriteria1}>
                            <XInput
                                style={{ width: '248px' }}
                                inputStyle={{ width: '180px' }}
                                label='会员手机'
                                placeholder='请输入会员手机'
                                value={accountTel}
                                bindThis={this}
                                bindName='accountTel'
                            />
                            <XInput
                                style={{ marginLeft: '20px', width: '248px' }}
                                inputStyle={{ width: '180px' }}
                                label='订单编号'
                                placeholder='请输入订单编号'
                                value={orderId}
                                bindThis={this}
                                bindName='orderId'
                            />
                            <XDatePicker
                                style={{ width: '264px', marginLeft: '20px' }}
                                label='下单时间'
                                value={startDate}
                                bindThis={this}
                                bindName='startDate'
                                isFormat={true}
                            />
                            <XDatePicker
                                label=''
                                value={endDate}
                                bindThis={this}
                                bindName='endDate'
                                isFormat={true}
                            />
                            <XSelect
                                style={{ marginLeft: '30px', width: '216px' }}
                                selectStyle={{ width: '150px' }}
                                label='订单状态'
                                placeholder='请选择'
                                renderData={saleStatus}
                                onChange={this._StatusChangeHandle}
                                dataIndex='value'
                                keyIndex='code'
                                value={status.value}
                                showSearch={true}
                            />
                            <OrderType
                                style={{ marginLeft: '20px', width: '258px' }}
                                selectStyle={{ width: '180px' }}
                                label='订单类型'
                                placeholder='请输入订单类型'
                                bindThis={this}
                                bindName='orderType'
                                value={orderType}
                            />
                        </div>
                        <div className={styles.searchCriteria2}>
                            <XSelect
                                style={{ width: '248px' }}
                                selectStyle={{ width: '180px' }}
                                label='是否开票'
                                placeholder='请选择是否开票'
                                renderData={invoiceSelect}
                                dataIndex='name'
                                keyIndex='id'
                                value={isInvoice.name}
                                showSearch={true}
                                bindThis={this}
                                bindName='isInvoice'
                            />
                            <XSelect
                                style={{ marginLeft: '20px', width: '248px' }}
                                selectStyle={{ width: '180px' }}
                                label='批发订单'
                                placeholder='请选择'
                                renderData={wholesaleSelectList}
                                dataIndex='value'
                                keyIndex='code'
                                value={wholesaleOrder.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='wholesaleOrder'
                            />
                            <XInput
                                style={{ marginLeft: '20px', width: '248px' }}
                                inputStyle={{ width: '180px' }}
                                label='批次号'
                                placeholder='请输入批次号'
                                value={batchNo}
                                bindThis={this}
                                bindName='batchNo'
                            />
                            <XOKButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='查询'
                                onClick={this._searchHandle}
                            />
                            <XCancelButton
                                style={{ marginLeft: '20px', width: '60px' }}
                                label='重置'
                                onClick={this._resetHandle}
                            />
                        </div>
                        <div className={styles.otherFeatures}>
                            {
                                authorizationInfo.status == 1 &&
                                <div className={styles.print}>
                                    <XSelect
                                        style={{ width: '308px' }}
                                        selectStyle={{ width: '200px' }}
                                        label='面单打印模板'
                                        placeholder='请选择'
                                        renderData={deliverTemplateData}
                                        dataIndex='templateName'
                                        keyIndex='id'
                                        value={templateItem.templateName}
                                        showSearch={true}
                                        bindThis={this}
                                        bindName='templateItem'
                                    />
                                    <Checkbox checked={isPreview} onChange={e => this.setState({ isPreview: e.target.checked })} style={{ margin: '0 20px' }}>预览</Checkbox>
                                    <XOKButton
                                        style={{ width: '150px' }}
                                        label='批量菜鸟面单出库'
                                        onClick={() => this._onExpressWaybill(selectedRowKeys)}
                                    />
                                </div>
                            }
                            <XOKButton
                                style={{ width: '120px', marginLeft: '0px', marginRight: '20px' }}
                                label='下载导入模板'
                                onClick={this._downloadTemplate}
                            />
                            <XUploadExcel
                                style={{ width: '120px' }}
                                label='导入确认发货'
                                apiurl='merchant/order/outboundByImportExcel'
                                successHandle={(info) => {
                                    this._renderProgress(info.file.response);
                                }}
                            />
                            {
                                loading && <div className={styles.progress}>
                                    <Progress type='circle' width={30} percent={percent} status={progressStatus} />
                                </div>
                            }
                            <XOKButton
                                style={{ width: '120px', marginLeft: '20px' }}
                                label='导出出库单'
                                onClick={this._exportOrder}
                            />
                            <XOKButton
                                style={{ width: '100px', marginLeft: '20px' }}
                                label='批量备注'
                                onClick={() => this.setState({ showDeliverModal: 'BatchRemark' })}
                            />
                        </div>
                        <OrderTable
                            renderData={this.props.readyDeliverList}
                            tableAction={this._tableAction}
                            paginationChange={this._paginationChange}
                            selectedRowKeys={selectedRowKeys}
                            selectedRowValues={selectedRowValues}
                            status={authorizationInfo.status}
                            selectedRowKeysChange={(selectedRowKeys, selectedRowValues) => { this.setState({ selectedRowKeys, selectedRowValues }); }}
                        />
                        {this._renderMode()}
                    </div>
                </Spin>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航
    getReadyDeliverList: PropTypes.func, // 获取待发货的出库单 
    readyDeliverList: PropTypes.object, // 待发货出库单
    getOrderSourceSelect: PropTypes.func, // 订单来源下拉列action 
    orderList: PropTypes.array, // 订单来源下拉列数据
    getWholesaleSelect: PropTypes.func, // 批发订单下拉列action 
    wholesaleSelectList: PropTypes.array, // 批发订单下拉列数据
    getDeliverTemplateData: PropTypes.func,
    deliverTemplateData: PropTypes.array,
    getContactsInfo: PropTypes.func,
    contactsInfo: PropTypes.object,
    getAuthorizationInfo: PropTypes.func,
    authorizationInfo: PropTypes.object
};

const mapStateToProps = (state) => ({
    readyDeliverList: state.readydeliver.getReadyDeliverList,
    wholesaleSelectList: state.readydeliver.getWholesaleSelect,
    deliverTemplateData: state.delivertemplate.deliverTemplateData,
    contactsInfo: state.delivergoodsset.contactsInfo,
    authorizationInfo: state.authorization.authorizationInfo
});

export default connect(mapStateToProps, {
    addOneBreadcrumbPath,
    keepSecondNavBreadcrumb,
    getReadyDeliverList,
    getWholesaleSelect,
    getDeliverTemplateData,
    getContactsInfo,
    getAuthorizationInfo
})(Main);