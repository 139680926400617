/**
 * [协议签署]
 * @author: Fu Xiaochun
 * @date: 2022-10-28 
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message, Button } from 'antd';
import styles from './Agreement.module.scss';
import SignatureModal from './SignatureModal';
import { useState } from 'react';

function Agreement(props){

    const [data, setData] = useState(props.data || {});
    const agreementCopy = useRef(props.data ? props.data.agreementContent : '');
    const signatureImg = useRef();
    const [signatureVisible, setSignatureVisible] = useState(false);

    const onSubmit = ()=>{
        if(!signatureImg.current){
            message.error('请签署协议！');
            return false;
        }
        let formData = {
            signImgUrl: signatureImg.current,
            settleAgreementId: data.merchantSettleAgreementId,
        };
        http('/merchant/contract/confirmMerchantSettleAgreement', formData).then(res=>{
            message.success('协议签署成功！');
            props.closeMode();
        }).catch(err=>{
            message.error(err.message);
        });
    };

    const onClose = ()=>{
        props.closeMode();
    };

    const onSignature = ()=>{
        setSignatureVisible(true);
    };

    const renderFooter=()=>{
        return <>
            <div className={styles.footer}>
                <Button type="primary" onClick={onSignature}>手动签名</Button>
                <div>
                    <Button onClick={onClose} style={{marginRight: '10px'}}>取消</Button>
                    <Button onClick={onSubmit} type="primary">确认</Button>
                </div>
            </div>
        </>;
    };

    const onSignatureOk = (signUrl)=>{
        signatureImg.current = signUrl;
        let agreementStr = agreementCopy.current;
        let newContent = agreementStr.replace(/\$\{sign\}/g, signUrl);
        setData({...data, agreementContent: newContent});
        setSignatureVisible(false);
    };

    return <Modal title='协议确认' visible={true} width={800} centered bodyStyle={{padding: 0, height: '500px', overflow:'hidden'}} onCancel={onClose} footer={renderFooter()} >
        <div className={styles.container} dangerouslySetInnerHTML={{__html: data.agreementContent}}></div>
        {signatureVisible && <SignatureModal data={data} onOk={onSignatureOk} onClose={()=>{setSignatureVisible(false);}}/>}
    </Modal>;
}

Agreement.propTypes = {
    data: PropTypes.object,
    closeMode: PropTypes.func
};


export default Agreement;