import * as T from './actiontypes';

//获取商品品牌列表
export const getBrandApplyList = (params = {}) => ({
    type: T.GET_BRANDAPPLY_LIST,
    payload: params
});

export const getBrandApplyStateSele = () => ({
    type: T.GET_BRANDAPPLY_STATESELE,
    payload: {}
});

export const getBrandApplyBrandSele = (brandName, isApply) => ({
    type: T.GET_BRANDAPPLY_BRANDSELE,
    payload: { brandName, isApply }
});

export const getBrandApplyDetail = ({ auditId, merchantId }) => ({
    type: T.GET_BRANDAPPLY_DETAIL,
    payload: {
        auditId, merchantId
    }
});