import { createModel } from '@/store/tools';
const plistInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0
};
const model = {
    namespace: 'contractlist',
    build: (T, A, S, R) => ({
        actiontypes: {
            'getContractList': T('getContractList'),
            'getContractDetail': T('getContractDetail'),
        },
        actions: {
            'getContractList': A('getContractList'),
            'getContractDetail': A('getContractDetail'),
        },
        sagas: {
            'getContractList': S('getContractList', '/merchant/contract/listMerchantContract'),
            'getContractDetail': S('getContractDetail', '/merchant/contract/infoMerchantContract'),
        },
        reducers: {
            'getContractList': R('getContractList', []),
            'getContractDetail': R('getContractDetail', {}),
        }
    })
};
export default createModel(model);