import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { regFenToYuan } from '@/util/money';
import { getIsMarketingPromise } from '@/assets/js/authType';
import { tooltipStyle, tooltip } from '@/components/TableCell';

class TableContent extends React.Component {
    state = {
        selectedRowKeys: [], // 选中的单元行
        showMarket: true,
    };
    componentDidMount() {
        getIsMarketingPromise().then((response)=>{
            this.setState({ showMarket: response });
            console.log(response); // false:隐藏营销服务费信息 true:营销服务费信息
        });
    }

    _takeColumns = () => ([
        {
            title: '商户',
            dataIndex: 'targetName',
            key: 'targetName',
            width: 140,
            align: 'center',
            // fixed: 'left',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '交易订单号',
            dataIndex: 'orderId',
            key: 'orderId',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '业务日期',
            dataIndex: 'finishTime',
            key: 'finishTime',
            width: 180,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '付款日期',
            dataIndex: 'payTime',
            key: 'payTime',
            width: 180,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
            align: 'center',
            width: 250,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '货号',
            dataIndex: 'itemNo',
            key: 'itemNo',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '数量',
            dataIndex: 'goodsNumber',
            key: 'goodsNumber',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '单价(元)',
            dataIndex: 'retailPrice',
            key: 'retailPrice',
            width: 140,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '金额(元)',
            dataIndex: 'totalFee',
            key: 'totalFee',
            width: 140,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '优惠金额(元)',
            dataIndex: 'discount',
            key: 'discount',
            width: 140,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '人气值',
            dataIndex: 'starDiscount',
            key: 'starDiscount',
            width: 100,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '实付金额(元)',
            dataIndex: 'realPrice',
            key: 'realPrice',
            width: 140,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '付款渠道',
            dataIndex: 'payType',
            key: 'payType',
            width: 140,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '结算单价(元)',
            dataIndex: 'settlePrice',
            key: 'settlePrice',
            width: 140,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '结算金额(元)',
            dataIndex: 'settleSumPrice',
            key: 'settleSumPrice',
            width: 140,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '技术服务费(元)',
            dataIndex: 'serviceFee',
            key: 'serviceFee',
            width: 140,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '平台服务费(元)',
            dataIndex: 'distributionServiceFee',
            key: 'distributionServiceFee',
            width: 140,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '秀场服务费(元)',
            dataIndex: 'showServiceFee',
            key: 'showServiceFee',
            width: 140,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '心选推荐费(元)',
            dataIndex: 'heartSelectionRecommendationFee',
            key: 'heartSelectionRecommendationFee',
            width: 140,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '免单均摊金额(元)',
            dataIndex: 'freeOfCharge',
            key: 'freeOfCharge',
            width: 140,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '营销服务费(元)',
            dataIndex: 'marketServiceFee',
            key: 'marketServiceFee',
            width: 140,
            align: 'center',
            render: (text) => (this._renderTextMoneyCell(text))
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            // width: 140,
            // fixed: 'right',
            onCell: tooltipStyle,
            render: tooltip
        }
    ]);

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }
    _renderTextMoneyCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {regFenToYuan(text)}
            </div>
        );
    }
    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };

    _renderNumberCell = (text) => {
        const price = parseInt(text) / 100;
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {price}
            </div>
        );
    };

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys });
        }
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
    };
    _newColumn = () => {
        const { showMarket } = this.state;
        const tempData = this._takeColumns();
        const newTempData = tempData.filter((item, index) => index !== 20);
        if (showMarket) {
            return tempData;
        } else {
            return newTempData;
        }
    };
    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        // this._rowSelection.selectedRowKeys = selectedRowKeys;
        return (
            <div className={styles.tableContainer}>
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection}
                    rowKey={(record, index) => index}
                    columns={this._newColumn()}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: '200%', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    paginationChange: PropTypes.func.isRequired, // 分页操作
};

export default TableContent;