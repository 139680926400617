/**
 * 商品标签创建或编辑弹框 
 */
import React from 'react';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { Modal, message, Input } from 'antd';
import styles from './CreateEdit.module.scss';
import { XInput, XUpload } from '@/components/xqxc_ui';

const { TextArea } = Input;

class CreateEdit extends React.Component {

    state = {
        title: this.props.title,
        visible: this.props.visible,
        confirmLoading: false,
        labelName: this.props.data.labelInfo.tagName,
        describe: this.props.data.labelInfo.tagDesc,
        labelImgs: [],
        labelImg: this.props.data.labelInfo.imgUrl
    }

    constructor(props) {
        super(props);
        if ((props.data.labelInfo || {}).imgUrl) {
            this.state.labelImgs = [{
                uid: '0',
                name: 'default',
                status: 'done',
                url: props.data.labelInfo.imgUrl,
            }];
        }
    }

    _sendReq = (url, data, winTxt, fallTxt) => {
        http(url, data, 'POST').then(() => {
            message.success(winTxt);
            this.setState({
                visible: false,
            });
            this.props.closeMode(true);
        }).catch((reject) => {
            message.error(reject.message);
        });
    }

    // 点击确认
    _handleOk = () => {
        const { labelImg, labelName, describe } = this.state;
        const { url, action, labelInfo } = this.props.data;
        switch (action) {
            case 'create':
                if (!labelImg || !labelName || !describe) {
                    message.error('请补全信息！');
                    return;
                }
                this._sendReq(url, { imgUrl: labelImg, tagName: labelName, tagDesc: describe }, '创建成功。', '创建失败！');
                break;
            case 'edit':
                if (!labelImg || !labelName || !describe) {
                    message.error('请补全信息！');
                    return;
                }
                this._sendReq(url, { id: labelInfo.id, imgUrl: labelImg, tagName: labelName, tagDesc: describe }, '编辑成功。', '编辑失败！');
                break;
        }
    }

    // 点击取消
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }

    _labelNameChange = (value) => {
        this.setState({ labelName: value });
    }

    _describeChange = (e) => {
        this.setState({ describe: e.target.value });
    }

    render() {
        const { visible, confirmLoading, title, labelName, describe, labelImgs } = this.state;
        return (
            <Modal
                width={470}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'>
                <div className={styles.container}>
                    <div className={styles.labelName}>
                        <XInput
                            style={{ width: '400px' }}
                            inputStyle={{ width: '320px' }}
                            label='商品标签'
                            placeholder='请输入商品标签'
                            onChange={this._labelNameChange}
                            value={labelName}
                        />
                    </div>
                    <div className={styles.describe}>
                        <div className={styles.label}>标签描述</div>
                        <TextArea
                            style={{ width: '320px', marginLeft: '10px' }}
                            placeholder='请输入标签描述'
                            value={describe}
                            onChange={this._describeChange}
                            autosize={{ minRows: 2, maxRows: 6 }}
                        />
                    </div>
                    <div className={styles.upload}>
                        <div className={styles.label}>上传图片</div>
                        <div className={styles.icon}>
                            <XUpload
                                uploadButton="singleImg"
                                handleChange={(file) => {
                                    if (file.status === 'done') {
                                        this.setState({
                                            labelImg: file.qiniuInfo.key,
                                        });
                                    } //保存保存
                                    if (file.status === 'removed') {
                                        this.setState({
                                            labelImg: '',
                                        });
                                    } //移除逻辑
                                }}
                                antdUploadProps={{
                                    listType: 'picture-card',
                                    showUploadList: false,
                                    // 这个是初始化值
                                    defaultFileList: labelImgs
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

CreateEdit.propTypes = {
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string, // 弹框标题
};

export default CreateEdit;