/**
 * 商品分类三级联查
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { XSearchcascade } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

class GoodsCategory extends React.Component {

    state = {
        categoryType: this.props.categoryType,
        SCData1: [],
        SCItem1: {},
        SCData2: [],
        SCItem2: {},
        SCData3: [],
        SCItem3: {},
    }

    clean = () => {
        this.setState({ SCItem1: {}, SCData2: [], SCItem2: {}, SCData3: [], SCItem3: {} });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.categoryType !== this.state.categoryType) {
            this.setState({
                categoryType: nextProps.categoryType,
                SCItem1: {},
                SCData2: [],
                SCItem2: {},
                SCData3: [],
                SCItem3: {},
            }, () => {
                this._searchLinkage();
            });
        }
    }

    componentDidMount() {
        this._searchLinkage();
    }

    _searchLinkage = () => {
        http('/goods/category/linkage', { categoryGrade: 1, parentId: 0, categoryType: this.state.categoryType }, 'POST').then((response) => {
            if (response.status == 200) {
                this.setState({
                    SCData1: response.result
                });
            } else {
                console.log('请求商品状态失败');
            }
        }).catch(() => {
            console.log('请求商品状态失败');
        });
    }

    _onChange1 = (value) => {
        this.setState({ SCItem1: value });
        if (this.props.onChange) this.props.onChange(value.id);
        if (this.props.onGetItems) this.props.onGetItems([value, {}, {}]);
        http('/goods/category/linkage', { categoryGrade: 2, parentId: value.id, categoryType: this.state.categoryType }, 'POST').then((response) => {
            if (response.status == 200) {
                this.setState({ SCData2: response.result, SCItem2: {}, SCData3: [], SCItem3: {} });
            } else {
                console.log('请求商品状态失败');
            }
        }).catch(() => {
            console.log('请求商品状态失败');
        });
    }

    _onChange2 = (value) => {
        this.setState({ SCItem2: value });
        if (this.props.onChange) this.props.onChange(value.id);
        if (this.props.onGetItems) this.props.onGetItems([this.state.SCItem1, value, {}]);
        http('/goods/category/linkage', { categoryGrade: 3, parentId: value.id, categoryType: this.state.categoryType }, 'POST').then((response) => {
            if (response.status == 200) {
                this.setState({ SCData3: response.result });
            } else {
                console.log('请求商品状态失败');
            }
        }).catch(() => {
            console.log('请求商品状态失败');
        });
    }

    _onChange3 = (value) => {
        this.setState({ SCItem3: value });
        if (this.props.onChange) this.props.onChange(value.id);
        if (this.props.onGetItems) this.props.onGetItems([this.state.SCItem1, this.state.SCItem2, value]);
    }

    render() {
        const { SCData1, SCItem1, SCData2, SCItem2, SCData3, SCItem3 } = this.state;
        const { style, label } = this.props;

        return (
            <div className={styles.criteria2} style={style}>
                <div className={styles.title}>{label}</div>
                <XSearchcascade
                    style={{ marginLeft: '10px' }}
                    dataIndex='categoryName'
                    keyIndex='id'
                    placeholder='商品一级分类搜索'
                    value={SCItem1.categoryName}
                    SearchDataSource={SCData1}
                    onSearchChange={this._onChange1}
                />
                <XSearchcascade
                    style={{ marginLeft: '10px' }}
                    dataIndex='categoryName'
                    keyIndex='id'
                    placeholder='商品二级分类搜索'
                    value={SCItem2.categoryName}
                    SearchDataSource={SCData2}
                    onSearchChange={this._onChange2}
                />
                <XSearchcascade
                    style={{ marginLeft: '10px' }}
                    dataIndex='categoryName'
                    keyIndex='id'
                    placeholder='商品三级分类搜索'
                    value={SCItem3.categoryName}
                    SearchDataSource={SCData3}
                    onSearchChange={this._onChange3}
                />
            </div>
        );
    }
}

GoodsCategory.defaultProps = {
    style: {},
};

GoodsCategory.propTypes = {
    categoryType: PropTypes.string,
    style: PropTypes.object,
    onChange: PropTypes.func,
    onGetItems: PropTypes.func,
    label: PropTypes.string,
};

export default GoodsCategory;