import { createModel } from '@/store/tools';

const model = {
    namespace: 'businessCategory',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getCategoryList': T('getCategoryList'),
        },
        actions: {
            'getCategoryList': A('getCategoryList'),
        },
        sagas: {
            'getCategoryList': S('getCategoryList', '/goods/category/apply/list'),
        },
        reducers: {
            'categoryList': R('getCategoryList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
        }
    })
};
export default createModel(model);