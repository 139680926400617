/**
 * 【仓库列表】
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { XInput, XSelect, XOKButton, XCancelButton, XDatePicker } from '@/components/xqxc_ui';
import { addOneBreadcrumbPath, keepSecondNavBreadcrumb } from '@/reducers/home/actions';
import { getInvoiceListTableSource } from '@/reducers/storehousefinancialmanage/invoicelist/actions';
import { getSupplierSelectSource } from '@/reducers/storehousefinancialmanage/suppliersettlement/actions';
import AdvertTable from './components/AdvertTable';
import DeleteModal from '../../modal/DeleteModal';
import GenerateStatement from '../../modal/GenerateStatement';
import KeepAlive from '@/routes/KeepAlive';
import { autoPageNum, autopageSize } from '@/assets/config';
import http from '@/assets/api/http';

import styles from './index.module.scss';
import { message } from 'antd';

class Main extends React.Component {

    state = {
        selectedRowKeys: [],
        selectedRows: [],

        reconciliationId: undefined,
        targetName: undefined,
        startDate: undefined,
        endDate: undefined,

        units: [],
        status: {},
    }

    componentDidMount() {
        this.props.keepSecondNavBreadcrumb(); // 面包屑导航保留为二级
        this.props.getSupplierSelectSource();
        http('/merchant/supplier/invoicelUnitList').then((res = {}) => {
            this.setState({ units: res.result || [] });
        });
    }

    getCache = () => ({
        reconciliationId: this.state.reconciliationId,
        targetName: this.state.targetName,
        startDate: this.state.startDate,
        endDate: this.state.endDate
    })

    // 查询
    _searchHandle = (useCache) => {
        const { reconciliationId, status, startDate, endDate, pageNum, pageSize } = this.state;
        this.searchCache = {
            reconciliationId,
            startDate: startDate ? startDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
            endDate: endDate ? endDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
            targetId: status.id,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        this.props.getInvoiceListTableSource(this.searchCache);
        KeepAlive.saveCache({ ...this.getCache(), pageNum: this.searchCache.pageNum, pageSize: this.searchCache.pageSize }); // 【第3步，缓存查询状态】
    }

    // 重置
    _resetHandle = () => {
        this.setState({ reconciliationId: undefined, status: {}, startDate: undefined, endDate: undefined, pageNum: 1, pageSize: 10 }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 表格分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({
            pageNum,
            pageSize,
        }, () => {
            this._searchHandle('useCache');
        });
    }

    // 表格复选输入
    _rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRows);
            this.setState({ selectedRowKeys, selectedRows });
        },
    };
    _newHandle = () => {
        this.setState({ currentOpenMode: 'new' });
    };

    // 表格操作
    _tableAction = (id, type, item) => {
        const { history, addOneBreadcrumbPath } = this.props;
        switch (type) {
            case 'info':
                addOneBreadcrumbPath({
                    title: '对账详情',
                    path: '/home/storehousefinancialmanage/suppliersettlement/validatestatement/detail/',
                });
                history.push({
                    pathname: '/home/storehousefinancialmanage/suppliersettlement/validatestatement/detail/' + id
                });
                break;
            case 'delete':
                this.setState({ currentOpenMode: 'confir', openModeParam: id });
                break;
            case 'createinvoice':
                if (this.state.selectedRowKeys.length) {
                    addOneBreadcrumbPath({
                        title: '新建发票',
                        path: '/home/storehousefinancialmanage/invoicelist/createinvoice'
                    });
                    history.push({
                        pathname: '/home/storehousefinancialmanage/invoicelist/createinvoice',
                        state: {
                            selectedRowKeys: this.state.selectedRowKeys,
                            selectedRows: this.state.selectedRows
                        }
                    });
                } else {
                    message.warning('请选择一条清单记录');
                }
                break;
        }
    }
    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'confir':
                return <DeleteModal title='确认对账单' visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            case 'new':
                return <GenerateStatement title='生成对账单' supplierList={this.props.supplierSelectSource} visible={true} data={this.state.openModeParam} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (update) => {
        if (update) {
            this._searchHandle();
        }
        this.setState({ currentOpenMode: '' });
    }

    onSelectionChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
    }

    render() {
        const { reconciliationId, startDate, endDate, units, status } = this.state;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.row1}>
                        <XInput
                            inputStyle={{ width: '200px' }}
                            label='清单编号'
                            placeholder='请输入'
                            value={reconciliationId}
                            bindThis={this}
                            bindName='reconciliationId'
                            mode='number'
                            maxLength={16}
                            showLength={false}
                        />
                        <XSelect
                            style={{ marginLeft: '20px' }}
                            selectStyle={{ width: '200px' }}
                            label='单位'
                            showSearch
                            placeholder='请选择单位'
                            renderData={units}
                            dataIndex='optionName'
                            keyIndex='id'
                            value={status.optionName}
                            bindThis={this}
                            bindName='status'
                        />
                        <XDatePicker
                            style={{ marginLeft: '20px', marginRight: '10px' }}
                            label='对账周期'
                            value={startDate}
                            bindThis={this}
                            bindName='startDate'
                        />
                        —
                    <XDatePicker
                            value={endDate}
                            bindThis={this}
                            bindName='endDate'
                        />
                        <XOKButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='查询'
                            onClick={() => this._searchHandle()}
                        />
                        <XCancelButton
                            style={{ marginLeft: '20px', width: '60px' }}
                            label='重置'
                            onClick={this._resetHandle}
                        />
                    </div>
                    <div className={styles.row2}>
                        <XOKButton
                            style={{ width: '120px' }}
                            label='生成发票清单'
                            onClick={this._newHandle}
                        />
                        &nbsp;&nbsp;
                    <XOKButton
                            style={{ width: '90px' }}
                            label='新建发票'
                            onClick={() => this._tableAction('', 'createinvoice')}
                        />
                    </div>
                    <AdvertTable onSelectionChange={this.onSelectionChange} renderData={this.props.invoiceListTableSource} tableAction={this._tableAction} paginationChange={this._paginationChange} />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    history: PropTypes.object,
    addOneBreadcrumbPath: PropTypes.func, // 添加一个面包屑导航子级
    keepSecondNavBreadcrumb: PropTypes.func, // 保持两级面包屑导航

    invoiceListTableSource: PropTypes.object,
    getInvoiceListTableSource: PropTypes.func,

    supplierSelectSource: PropTypes.object,
    getSupplierSelectSource: PropTypes.func
};
const mapStateToProps = (state) => ({
    invoiceListTableSource: state.invoicelist.invoiceListTableSource,
    supplierSelectSource: state.suppliersettlement.supplierSelectSource
});
export default connect(mapStateToProps, { addOneBreadcrumbPath, keepSecondNavBreadcrumb, getInvoiceListTableSource, getSupplierSelectSource })(Main);