import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TableData.module.scss';
import { Table } from 'antd';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { ImgCell } from '@/components/TableCell';

export default class TableData extends Component {
	state = {
		selectedRowKeys: [],
	};

	static propTypes = {
		tableAction: PropTypes.func, // 对表格的操作
		paginationChange: PropTypes.func,

		renderData: PropTypes.object
	};

	_columns = () => [
		{
			title: '标签图标',
			dataIndex: 'imgUrl',
			key: 'imgUrl',
			align: 'center',
			width: '10%',
			render: ImgCell
		},
		{
			title: '商品标签',
			dataIndex: 'tagName',
			key: 'tagName',
			align: 'center',
			width: '10%',
		},
		{
			title: '描述',
			dataIndex: 'tagDesc',
			key: 'tagDesc',
			align: 'center',
			width: '10%',
		},
		// {
		//   title: '创建时间',
		//   dataIndex: 'date',
		//   key:'date',
		//   align: 'center',
		//   width: '10%',
		// },
		{
			title: '操作',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			width: '10%',
			render: (id, record) => this._tableAction(id, record)
		},
	];


	// 表格项内容为图片
	_imgCell = (url) => {
		return (
			<div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
				<div className={styles.imgbox}>
					<img className={styles.img} src={url} />
				</div>
			</div>
		);
	};

	// 表格操作
	_tableAction = (key) => {
		const { tableAction } = this.props;
		return (
			<div className={styles.action}>
				<div className={styles.item} onClick={() => tableAction(key, 'edit')}>编辑</div>
				<div className={styles.item} onClick={() => tableAction(key, 'delete')}>删除</div>
			</div>
		);
	};

	_takeDataSource = (data) => {
		return data;
	}

	// 表格复选输入
	_rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			console.log(selectedRows);
			this.setState({ selectedRowKeys });
			this.props.tableAction(null, 'batch', selectedRowKeys);
		}
	};

	// 清空表格复选框
	_cleanAllSelected = () => {
		this.setState({ selectedRowKeys: [] });
	}

	render() {
		const { renderData, paginationChange } = this.props;
		const { selectedRowKeys } = this.state;
		this._rowSelection.selectedRowKeys = selectedRowKeys;
		return (
			<div className={styles.tableContainer}>
				<XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} />
				<Table
					rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
					rowSelection={this._rowSelection}
					rowKey={record => record.id}
					columns={this._columns()}
					dataSource={this._takeDataSource(renderData.dataList)}
					pagination={false}
					scroll={{ y: true }}
				/>
				<XPagination
					resultTotal={renderData.resultTotal}
					pageSize={renderData.pageSize}
					pageNum={renderData.pageNum}
					onChange={paginationChange}
				/>
			</div>
		);
	}
}