/**
 * 绑定手机
 */
import React from 'react';
import styles from './step.module.scss';
import PropTypes from 'prop-types';
import { Form, Button, Input, message } from 'antd';
import Ensure from './../components/Ensure';
import getMemberInfo from './../components/getMemberInfo';
import http from '@/assets/api/http';

const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 10 },
        sm: { span: 10 },
    },
};

class step extends React.Component {

    state = {
        unitType: '', // 商户类型(BUSINESS企业，PERSON个人)
        bindPhoneBoolean: false, // 是否已经绑定过
        show: false, // 显示界面，优化用户体验防止界面多次闪动
        visible: false,
        required: true,
        tel: '', // 手机号
        captcha: '', // 验证码
        alreadySetLoading: false, // 加载中
        alreadySetCaptcha: false, // 已经提前发送验证码
        loading: false, // 加载中
        time: 60, // 发送验证码倒计时
    }

    componentDidMount() {
        getMemberInfo().then((response) => {
            const { type, data } = response;
            if (type == 'BUSINESS') {
                // 如果为企业类型已经绑定过手机，则直接签约
                this.setState({ tel: data.legalPhone, bindPhoneBoolean: data.phoneChecked, unitType: 'BUSINESS', show: true });
            } else if (type == 'PERSON') {
                this.setState({ tel: data.phone, unitType: 'PERSON', show: true });
            } else {
                this.setState({ show: true });
                message.error('无法获取手机号码，请先完成上一步实名认证');
            }
        });
    }

    UNSAFE_componentWillMount() {
        clearInterval(this.timeID);
    }

    // 下一步
    _handleSubmit = e => {
        if (e) e.preventDefault();
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const { tel, captcha } = fieldsValue;
                this.setState({ loading: true });
                getMemberInfo().then((response) => {
                    const { type, data } = response;
                    if (type == 'BUSINESS' && data.phoneChecked) {
                        // 如果已经绑定过手机号，则直接签约
                        this._signContract();
                    } else if (type == 'PERSON' && data.phoneChecked) {
                        // 如果已经绑定过手机号，则直接下一步
                        this.props.next();
                        this.setState({ loading: false });
                    } else {
                        this._bindPhone(tel, captcha); // 如果未绑定过手机号，则先绑定手机号
                    }
                });
            }
        });
    }

    // 渲染签约确认弹框
    _renderEnsureComp = () => {
        const { visible } = this.state;
        return <Ensure signSuccess={this.props.signSuccess} visible={visible} closeMode={() => { this.setState({ visible: false }); }} />;
    }

    // 获取验证码
    _getcaptcha = () => {
        const _this = this;
        const tel = this.props.form.getFieldsValue().tel;
        const pattern = /^[1-9]\d{10}$/;
        clearInterval(_this.timeID);
        this.setState({ alreadySetLoading: true });
        if (pattern.test(tel)) {
            http('/merchant/info/yums/sendVerificationCode', { phone: tel, verificationCodeType: 9 }).then((response) => {
                this.setState({ alreadySetLoading: false });
                if (response.status == 200) {
                    message.success('验证码发送成功');
                    this.setState({ alreadySetCaptcha: true });
                    this.timeID = setInterval(function () {
                        const { time } = _this.state;
                        if (time > 0) {
                            _this.setState({ time: time - 1 });
                        } else {
                            clearInterval(_this.timeID);
                            _this.setState({ alreadySetCaptcha: false, time: 60 });
                        }
                    }, 1000);
                }
            }).catch((e) => {
                this.setState({ alreadySetLoading: false });
                message.error(e.message);
            });
        } else {
            this.setState({ alreadySetLoading: false });
            message.error('请输入11位正确的手机号');
        }
    }

    // 绑定手机
    _bindPhone = (phone, verificationCode) => {
        http('/merchant/info/yums/bindPhone', { phone, verificationCode }).then((response) => {
            if (response.status == 200) {
                message.success('手机绑定成功');
                if (this.state.unitType == 'PERSON') {
                    this.props.next();
                } else if (this.state.unitType == 'BUSINESS') {
                    this._signContract();
                } else {
                    message.error('无法识别用户类型，请先完成上一步实名认证');
                }
                this.setState({ loading: false });
            }
        }).catch((e) => {
            message.error(e.message);
            this.setState({ loading: false });
        });
    }

    // 请求电子协议
    _signContract = () => {
        const successUrl = window.location.origin + '/#/paysucess';
        const errorUrl = window.location.origin + '/#/payfail';
        http('/merchant/info/yums/signContract', { successUrl, errorUrl }).then((response) => {
            if (response.status == 200) {
                const http = (response.result.toString()).substring(0, 4);
                if (http == null || http == 'null') {
                    message.error('无法获取签约地址或地址有误');
                    this.setState({ loading: false });
                } else {
                    try{
                        var tempwin = window.open('about:blank');
                        tempwin.location = response.result;
                        this.setState({ visible: true, loading: false });
                    }catch(e){
                        this.setState({ loading: false });
                        message.error('弹出窗口被拦截，请点击地址栏下”允许阻止的内容“设置');
                    }
                }
            }
        }).catch((e) => {
            this.setState({ loading: false });
            message.error(e.message);
        });
    }

    render() {
        const { bindPhoneBoolean, required, tel, loading, show, alreadySetCaptcha, alreadySetLoading, time } = this.state;
        const { getFieldDecorator } = this.props.form;
        const captchaLabel = alreadySetCaptcha ? '短信已发送(' + time + ')' : '获取验证码';

        return (
            <div className={styles.step2}>
                {
                    (!bindPhoneBoolean && show) ? <Form autoComplete='off' {...formItemLayout} onSubmit={this._handleSubmit}>
                        <Form.Item
                            colon={false}
                            htmlFor='tel'
                            label={<span>手机号：</span>}
                        >
                            {getFieldDecorator('tel', {
                                initialValue: tel,
                                rules: [
                                    { required, message: '无法获取手机号码' }, // disabled
                                    { pattern: /^[1-9]\d{10}$/, message: '请输入正确的手机号码' },
                                ],
                            })(<Input placeholder='由系统自动获取' />)}
                        </Form.Item>
                        <Form.Item
                            colon={false}
                            htmlFor='captcha'
                            label={<span>验证码：</span>}
                        >
                            <div className={styles.yzm}>
                                {getFieldDecorator('captcha', {
                                    rules: [
                                        { required, message: '请输入验证码' },
                                    ],
                                })(<Input placeholder='请输入验证码' />)}
                                <Button type="primary" loading={alreadySetLoading} disabled={alreadySetCaptcha} className={styles.getcaptcha} onClick={this._getcaptcha}>{captchaLabel}</Button>
                            </div>
                        </Form.Item>
                    </Form> :
                        <div className={styles.txt}>
                            您已经绑定过手机，点击下一步签订电子协议
                        </div>
                }
                <div className={styles.btn}>
                    {/* <Button className={styles.previous} onClick={this.props.previous}>上一步</Button> */}
                    <Button type="primary" htmlType="submit" loading={loading} onClick={this._handleSubmit}>下一步</Button>
                </div>
                {this._renderEnsureComp()}
            </div>
        );
    }
}

step.propTypes = {
    signSuccess: PropTypes.func, // 签约成功
    previous: PropTypes.func, // 上一步
    next: PropTypes.func, // 下一步
    form: PropTypes.object, // 表单校验
};
const stepForm = Form.create({ name: 'step-form' })(step);
export default stepForm;


