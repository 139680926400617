/**
 * 订单状态下拉搜索框（枚举类型）
 */
import React from 'react';
import PropTypes from 'prop-types';
import { XSelect } from '@/components/xqxc_ui';
import http from '@/assets/api/http';

class OrderState extends React.Component {

    state = {
        renderData: [], // 数据源
    }

    componentDidMount() {
        http('/enum/OrderStatusEnum', {}, 'POST').then((response) => {
            if (response.status == 200) {
                this.setState({ renderData: response.result });
            } else {
                console.log('/enum/OrderStatusEnum请求失败');
            }
        }).catch(() => {
            console.log('/enum/OrderStatusEnum请求失败');
        });
    }

    _onChange = (value) => {
        const { bindThis, bindName } = this.props;

        if (bindThis && bindName) {
            bindThis.setState({ [bindName]: value });
        }

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const { renderData } = this.state;
        const { style, selectStyle, label, placeholder, isRequired, value } = this.props;

        return (
            <XSelect
                style={style}
                selectStyle={selectStyle}
                label={label}
                placeholder={placeholder}
                renderData={renderData}
                dataIndex='value'
                keyIndex='code'
                value={value.value}
                onChange={this._onChange}
                isRequired={isRequired}
                showSearch={true}
            />
        );
    }
}

OrderState.defaultProps = {
    style: { width: '218px' },
    selectStyle: { width: '150px' },
    isApply: 0,
};

OrderState.propTypes = {
    bindThis: PropTypes.object,
    bindName: PropTypes.string,
    value: PropTypes.object,
    style: PropTypes.object,
    selectStyle: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    defaultValue: PropTypes.object,
};

export default OrderState;