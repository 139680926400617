import * as T from './actiontypes';

/**
 * 商品分类列表
 */
const productClassifyListInital = {
    dataList: [],
    pageNum: 1,
    pageSize: 10,
    resultTotal: 0,
};

/**
 * 商品分类的数据格式
 */
const classify = {
    id: '',
    parentId: '',
    categoryName:'',
    sortNo: '',
    gmtCreate: '',
    imgUrl: '',
    platRate:'',
    agentRate:''
};

/**
 * 新增一个空白分类项
 */
const addNullCell = (list, parentID) => {
    if(parentID === 0){
        let nullCell = {...classify};
        // nullCell.id = RandomNumBoth(1, 100000); .
        nullCell.id = ''; 
        nullCell.parentId = parentID;
        nullCell.categoryInfoList = [];
        nullCell.dotype = 'save'; // 新增空白分类的操作类型(删除、保存)
        list.push(nullCell);
    }else{
        for(let i=0;i<list.length;i++){
            if(list[i].id === parentID){
                if(list[i].categoryInfoList){
                    let nullCell = {...classify};
                    // nullCell.id = RandomNumBoth(1, 100000); 
                    nullCell.id = ''; 
                    nullCell.parentId = parentID;
                    nullCell.categoryInfoList = [];
                    nullCell.dotype = 'save'; // 新增空白分类的操作类型(删除、保存)
                    list[i].categoryInfoList.push(nullCell);
                    break;
                }
            }else{
                if(list[i].categoryInfoList && list[i].categoryInfoList.length > 0){
                    addNullCell(list[i].categoryInfoList, parentID);
                }else{
                    break;
                }
            }
        }
    }
    return list;
};

// 生成一个随机数
const RandomNumBoth = (Min,Max) => {
    var Range = Max - Min;
    var Rand = Math.random();
    var num = Min + Math.round(Rand * Range); //四舍五入
    return num;
};

export const productClassifyList = (state = productClassifyListInital, action) => {
    let newState = {...state};
    switch(action.type){
        case T.SET_PRODUCT_CLASSIFY:
            return action.payload.result;
        case T.ADD_PRODUCT_NULLCELL:
            newState.dataList = addNullCell({...state}.dataList, action.payload.parentId);
            return newState;
        default:
            return state;
    }
};

export const productClassifySele = (state = [], action) => {
    switch(action.type){
        case T.SET_PRODUCT_CLASSIFYSELE:
            return action.payload.result;
        default:
            return state;
    }
};

// const productClassifyListInital = {
//     dataList: [
//         {
//            id: 100,
//            parentId: 0,
//            categoryName:'鞋子',
//            sortNo: 1,
//            gmtCreate: '2019-06-20 10:00',
//            imgUrl: '',
//            platRate:'',
//            agentRate:'',
//            categoryInfoList: [
//                {
//                    id: 10,
//                    parentId: 100,
//                    categoryName:'男士鞋子',
//                    sortNo: 1,
//                    gmtCreate: '2019-06-20 10:00',
//                    imgUrl: '',
//                    platRate:'',
//                    agentRate:'',
//                    categoryInfoList: [
//                         {
//                             id: 100101,
//                             parentId: 10,
//                             categoryName:'运动鞋',
//                             sortNo: 1,
//                             gmtCreate: '2019-06-20 10:00',
//                             imgUrl: '',
//                             platRate:'',
//                             agentRate:'',
//                             categoryInfoList: null
//                         },
//                    ]
//                },
//                {
//                     id: 11,
//                     parentId: 100,
//                     categoryName:'女士鞋子',
//                     sortNo: 2,
//                     gmtCreate: '2019-06-20 10:00',
//                     imgUrl: '',
//                     platRate:'',
//                     agentRate:'',
//                     categoryInfoList: [
//                         {
//                             id: 1001101,
//                             parentId: 11,
//                             categoryName:'高跟鞋',
//                             sortNo: 1,
//                             gmtCreate: '2019-06-20 10:00',
//                             imgUrl: '',
//                             platRate:'',
//                             agentRate:'',
//                             categoryInfoList: null
//                         },
//                         {
//                             id: 1001102,
//                             parentId: 11,
//                             categoryName:'帆布鞋',
//                             sortNo: 2,
//                             gmtCreate: '2019-06-20 10:00',
//                             imgUrl: '',
//                             platRate:'',
//                             agentRate:'',
//                             categoryInfoList: null
//                         }
//                     ]
//                 },
//            ]
//         },
//         {
//             id: 101,
//             parentId: 0,
//             categoryName:'衣服',
//             sortNo: 2,
//             gmtCreate: '2019-06-20 10:00',
//             imgUrl: '',
//             platRate:'',
//             agentRate:'',
//             categoryInfoList: []
//         },
//         {
//             id: 102,
//             parentId: 0,
//             categoryName:'帽子',
//             sortNo: 3,
//             gmtCreate: '2019-06-20 10:00',
//             imgUrl: '',
//             platRate:'',
//             agentRate:'',
//             categoryInfoList: []
//         }
//     ],
//     pageNum: 1,
//     pageSize: 10,
//     resultTotal: 0,
// };

