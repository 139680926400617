import React from 'react';
import styles from './OrderSearch.module.scss';
import PropTypes from 'prop-types';
import { XInput, XOKButton, XCancelButton, XDatePicker, XSelect } from '@/components/xqxc_ui';
import model from '@/reducers/ordermanage/wholesaleorder/model';
import { getDistributionFlagEnum } from '@/reducers/salemanage/saleorder/actions';
import { connect } from 'react-redux';

class OrderSearch extends React.Component {

    state = {
        expandBoolean: false, // 是否展开
        telephone: '', // 会员手机
        orderCode: '', // 订单编号
        orderStartTime: null, // 下单时间开始日期
        orderEndTime: null, // 下单结束日期
        payItemId: '', // 支付流水号
        payType: {},
        ismerchantSponsorFlag: { code: 2, value: '全部' }, // 是否引流
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState(newProps.searchState.param);
    }

    componentDidMount() {
        this.props.getDistributionFlagEnum(); // 引流
        // 支付方式
        this.props.getPaymentTypeEnum();
    }

    // 查询
    _searchHandle = () => {
        const { telephone, orderCode, orderStartTime, payType, orderEndTime, payItemId, ismerchantSponsorFlag } = this.state;
        this.searchCache = {
            telephone,
            orderCode,
            orderStartTime,
            orderEndTime,
            payItemId,
            ismerchantSponsorFlag,
            payType
        };
        this.props.searchOrder({
            buyerTel: telephone, startTime: orderStartTime, endTime: orderEndTime, id: orderCode,
            payItemId, channelType: payType.code,
            isSelfSponsor: ismerchantSponsorFlag.code ? ismerchantSponsorFlag.code : '',
        }, this.searchCache);
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            telephone: '',
            orderCode: '',
            orderStartTime: null,
            orderEndTime: null,
            payItemId: null,
            pageNum: 1,
            pageSize: 50,
            payType: {},
            ismerchantSponsorFlag: { code: 2, value: '全部' },
        });
        this.searchCache = {
            telephone: '',
            orderCode: '',
            orderStartTime: null,
            orderEndTime: null,
            payItemId: null,
            payType: {},
            pageNum: 1,
            pageSize: 50,
            ismerchantSponsorFlag: { code: 2, value: '全部' },
        };
        this.props.searchOrder({}, this.searchCache, true);
    }

    render() {
        const { payType, payItemId, telephone, orderCode, orderStartTime, orderEndTime, ismerchantSponsorFlag } = this.state;
        const { DistributionFlagEnum, paymentTypeEnum } = this.props;
        return (
            <div className={styles.container}>
                <div className={styles.row1}>
                    <XInput
                        style={{ width: '256px' }}
                        inputStyle={{ width: '180px', marginLeft: 12 }}
                        label='会员账号'
                        placeholder='请输入会员账号'
                        value={telephone}
                        bindThis={this}
                        bindName='telephone'
                    />
                    <XInput
                        style={{ width: '256px', marginLeft: '20px' }}
                        inputStyle={{ width: '180px', marginLeft: 16 }}
                        label='订单号'
                        placeholder='请输入订单号'
                        value={orderCode}
                        bindThis={this}
                        bindName='orderCode'
                    />
                    <XInput
                        style={{ marginLeft: '20px' }}
                        inputStyle={{ width: '180px' }}
                        label='支付流水号'
                        placeholder='请输入支付流水号'
                        value={payItemId}
                        bindThis={this}
                        bindName='payItemId'
                    />
                    <XSelect
                        style={{ marginRight: '20px', width: '218px', marginLeft: '20px' }}
                        selectStyle={{ width: '150px' }}
                        label='支付方式'
                        placeholder='请选择支付方式'
                        renderData={paymentTypeEnum}
                        dataIndex='value'
                        keyIndex='code'
                        value={payType.value}
                        showSearch={true}
                        bindThis={this}
                        bindName='payType'
                    />
                </div>
                <div className={styles.row2}>
                    <XDatePicker
                        style={{ width: '264px'}}
                        label='下单时间'
                        value={orderStartTime}
                        bindThis={this}
                        bindName='orderStartTime'
                        isFormat
                    />
                    <XDatePicker
                        label=''
                        value={orderEndTime}
                        bindThis={this}
                        bindName='orderEndTime'
                        isFormat
                    />
                    <XSelect
                        style={{ marginLeft: '20px', width: '218px' }}
                        selectStyle={{ width: '150px' }}
                        label='商户引流'
                        placeholder='请选择'
                        renderData={DistributionFlagEnum}
                        dataIndex='value'
                        keyIndex='code'
                        value={ismerchantSponsorFlag.value}
                        showSearch={true}
                        bindThis={this}
                        bindName='ismerchantSponsorFlag'
                    />
                    <XOKButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='查询'
                        onClick={this._searchHandle}
                    />
                    <XCancelButton
                        style={{ marginLeft: '20px', width: '60px' }}
                        label='重置'
                        onClick={this._resetHandle}
                    />
                </div>

            </div>
        );
    }
}

OrderSearch.propTypes = {
    orderState: PropTypes.number,
    searchOrder: PropTypes.func,
    getDistributionFlagEnum: PropTypes.func,
    DistributionFlagEnum: PropTypes.array,
    getPaymentTypeEnum: PropTypes.func,
    paymentTypeEnum: PropTypes.array,
};

const mapStateToProps = (state) => ({
    DistributionFlagEnum: state.saleorder.DistributionFlagEnum,
    paymentTypeEnum: state.wholesaleOrder.paymentTypeEnum,
});

export default connect(mapStateToProps, { ...model.actions, getDistributionFlagEnum })(OrderSearch);